import React, { useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import {
  FormFieldWrapper,
  FormTextField,
  FormContext,
  useEventCallback,
  useFormSelector,
} from '@eas/common-web';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';
import { HelpLabel } from '../../../help/help-label';
import { Address } from '../../../../models';

export function ZipCell() {
  const { code, disabled, name, isForeign } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);
  const { setFieldValue, errors } = useContext(FormContext);

  const { addressPlaceRuian } = useFormSelector(
    (data: { [index: string]: Address | undefined }) => ({
      addressPlaceRuian: get(data, name)?.addressPlaceRuian,
    })
  );

  const notifyChange = useEventCallback(() => {
    if (addressPlaceRuian?.id) {
      unstable_batchedUpdates(() => {
        setFieldValue(`${name}.addressPlaceRuian`, undefined);
        setFieldValue(`${name}.houseNumberType`, undefined);
        setFieldValue(`${name}.houseNumber`, undefined);
        setFieldValue(`${name}.orientationNumber`, undefined);
        setFieldValue(`${name}.orientationNumberMark`, undefined);
      });
    }
  });

  return (
    <FormFieldWrapper
      disabled={disabled ?? false}
      required={false}
      label={
        <HelpLabel
          label={defaults?.zip?.label ?? 'PSČ'}
          required={defaults?.zip?.required ?? !isForeign}
          code={`${code}_ZIP`}
        />
      }
      labelOptions={{}}
      layoutOptions={{}}
      errorOptions={{ hide: true }}
      errors={errors.filter((error) => error.key === `${name}.zip`)}
    >
      <Grid item xs={3}>
        <FormTextField
          name="zip"
          disabled={disabled ?? false}
          notifyChange={notifyChange}
          labelOptions={{ hide: true }}
          layoutOptions={{ noUnderline: true, noSpacing: true }}
          inputProps={{ maxLength: isForeign ? undefined : 5 }}
          onChangeEvent={(e) => {
            if (!isForeign) {
              e.target.value = e.target.value.replace(/[^0-9]/gi, '');
            }
          }}
        />
      </Grid>
    </FormFieldWrapper>
  );
}

import { FileRef } from '@eas/common-web';

export interface ArchivedFile extends FileRef {
  /**
   * Číslo dokumentu
   */
  sequenceNumber?: string;

  /**
   * Výsledek validace souboru
   */
  validationResult?: ValidationResult;

  /**
   * Soubor
   */
  file?: FileRef;
}

export enum ValidationResult {
  VALID = 'VALID',
  MISSING_CRZP_SIGN = 'MISSING_CRZP_SIGN',
  DIFFERENT_HASHES = 'DIFFERENT_HASHES',
  MISSING_USER_SIGN = 'MISSING_USER_SIGN',
  UNTRUSTED_USER_SIGN = 'UNTRUSTED_USER_SIGN',
}

import React from 'react';
import { NotificationBtn } from '../../components/notification/notification-btn';
import { OutagesButton } from '../../modules/outage/outages-button';

export function MenubarActions() {
  return (
    <>
      <OutagesButton />
      <NotificationBtn />
    </>
  );
}

import React from 'react';

import { ProcessForm } from '../irz-facility-request-change-types';
import { CompareIrzRequestFields } from '../../../composite/compare-irz-request/compare-irz-request-fields';
import { TextArea, FormCustomField } from '@eas/common-web';

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  return (
    <>
      <CompareIrzRequestFields
        originalData={allValues?.irzFacility}
        requestData={allValues?.requestFacilityData}
      />
      <FormCustomField label="Poznámka">
        <TextArea value={allValues.note} disabled />
      </FormCustomField>
    </>
  );
}

import * as Yup from 'yup';
import { RoleRequest, Role } from '../../models';

export function useValidationSchema() {
  return Yup.object<RoleRequest>().shape({
    approvedRoles: Yup.array<Role>()
      .nullable()
      .min(1, 'Zadejte alespoň 1 schválenou roli')
      .required(),
  });
}

import {
  AbortableFetch,
  ListSource,
  defaultGetItem,
  defaultUpdateItem,
  DomainObject,
} from '@eas/common-web';
import { Note } from '../../../models/note';
import { post } from '../../../utils/custom-crud';
import { EvidenceAPI } from '../../../enums';
import { EntityType } from '../../../enums/entity-type';

type GetItemType =
  | ((api: string, itemId: string) => AbortableFetch)
  | undefined;

/**
 * Factory method used in EvidenceProps -> apiProps, that enhances
 * get of entity with loading of notes
 * @param originalGet Original getItem function
 * @param entityType type of entity from EntityType enum
 */
export const noteGetFactory = (
  originalGet: GetItemType = defaultGetItem,
  entityType: EntityType
) => (api: string, itemId: string) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const originalFetch = originalGet(api, itemId);
  return {
    abort: () => {
      controller.abort();
      originalFetch.abort();
    },
    response: new Promise<Response>(() => {}),
    json: async () => {
      const originalData = await originalFetch.json();

      const [notes] = await post<ListSource<Note>>({
        url: `${EvidenceAPI.NOTES}/list?entityId=${itemId}&entityType=${entityType}`,
        signal,
      });
      return { ...originalData, notes: notes?.items ?? [] };
    },
    // not needed
    raw: async () => new Promise<Response>(() => {}),
    text: async () => {},
    none: async () => {},
  };
};

type UpdateItemType<Item> =
  | ((api: string, item: Item, _initialItem: Item) => AbortableFetch)
  | undefined;

/**
 * Factory method used in EvidenceProps -> apiProps, that enhances
 * update call of entity with note loading
 * @param originalUpdate Original update function
 * @param entityType type of entity from EntityType enum
 */
export function noteUpdateFactory<Item extends DomainObject>(
  originalUpdate: UpdateItemType<Item> = defaultUpdateItem,
  entityType: EntityType
) {
  return (api: string, item: Item, _initialItem: Item) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const originalFetch = originalUpdate(api, item, _initialItem);
    return {
      abort: () => {
        controller.abort();
        originalFetch.abort();
      },
      response: new Promise<Response>(() => {}),
      json: async () => {
        const originalData = await originalFetch.json();
        const [notes] = await post<ListSource<Note>>({
          url: `${EvidenceAPI.NOTES}/list?entityId=${item.id}&entityType=${entityType}`,
          signal,
        });
        return { ...originalData, notes: notes?.items ?? [] };
      },
      // not needed
      raw: async () => new Promise<Response>(() => {}),
      text: async () => {},
      none: async () => {},
    };
  };
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Water management decision part messages
 */
const CREATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE'> = {
  ERROR: ['Chyba při vytváření části rozhodnutí MUV.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: [
    'Konec platnosti nesmí být před začátkem platnosti.',
    SnackbarVariant.ERROR,
  ],
};

const UPDATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE'> = {
  ERROR: ['Chyba při editaci části rozhodnutí MUV.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: [
    'Konec platnosti nesmí být před začátkem platnosti.',
    SnackbarVariant.ERROR,
  ],
};

export default { CREATE, UPDATE };

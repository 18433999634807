/**
 * User permissions
 */
export enum PermissionUser {
  /** Přehled uživatelů všech subjektů */
  USER_LIST_ALL = 'USER_LIST_ALL',

  /** Přehled uživatelů mých subjektů */
  USER_LIST_OWN = 'USER_LIST_OWN',

  /** Přehled uživatelů všech subjektů bez OÚ */
  USER_LIST_WITHOUT_PERSONAL = 'USER_LIST_WITHOUT_PERSONAL',

  /** Autocomplete výběr uživatelů všech subjektů */
  USER_AUTOCOMPLETE_ALL = 'USER_AUTOCOMPLETE_ALL',

  /** Autocomplete výběr uživatelů mých subjektů */
  USER_AUTOCOMPLETE_OWN = 'USER_AUTOCOMPLETE_OWN',

  /** Detail uživatele všech subjektů */
  USER_DETAIL_ALL = 'USER_DETAIL_ALL',

  /** Detail uživatele mých subjektů */
  USER_DETAIL_OWN = 'USER_DETAIL_OWN',

  /** Detail uživatele bez OÚ */
  USER_DETAIL_WITHOUT_PERSONAL = 'USER_DETAIL_WITHOUT_PERSONAL',

  /** Vytvoření (registrace) uživatele */
  USER_CREATE = 'USER_CREATE',

  /** Editace uživatele všech subjektů */
  USER_UPDATE_ALL = 'USER_UPDATE_ALL',

  /** Editace uživatele mých subjektů */
  USER_UPDATE_OWN = 'USER_UPDATE_OWN',

  /** Zneplatnění uživatele */
  USER_INVALIDATE = 'USER_INVALIDATE',

  /** Obnovení uživatele */
  USER_REVALIDATE = 'USER_REVALIDATE',

  /** Resetovaní hesla uživatele */
  USER_RESET_PASSWORD = 'USER_RESET_PASSWORD',

  /** Přiřazení uživatele k libovolnému subjektu */
  USER_ASSIGN_ALL = 'USER_ASSIGN_ALL',

  /** Přiřazení uživatele k mému subjektu */
  USER_ASSIGN_OWN = 'USER_ASSIGN_OWN',

  /** Zrušení přiřazení uživatele k libovolnému subjektu */
  USER_UNASSIGN_ALL = 'USER_UNASSIGN_ALL',

  /** Zrušení přiřazení uživatele k mému subjektu */
  USER_UNASSIGN_OWN = 'USER_UNASSIGN_OWN',

  /** Přehled všech registrací uživatelů */
  USER_REGISTRATION_LIST = 'USER_REGISTRATION_LIST',

  /** Detail registrací všech uživatelů */
  USER_REGISTRATION_DETAIL = 'USER_REGISTRATION_DETAIL',
}

/**
 * Help permissions
 */
export enum PermissionHelp {
  /** Prehled nápovědy */
  HELP_LIST = 'HELP_LIST',

  /** Detail nápovědy */
  HELP_DETAIL = 'HELP_DETAIL',

  /** Vytvoření nápovědy */
  HELP_CREATE = 'HELP_CREATE',

  /** Editace nápovědy */
  HELP_UPDATE = 'HELP_UPDATE',

  /** Mazání nápovědy */
  HELP_DELETE = 'HELP_DELETE',

  /** Zneplatnění nápovědy */
  HELP_INVALIDATE = 'HELP_INVALIDATE',

  /** Obnovení nápovědy */
  HELP_REVALIDATE = 'HELP_REVALIDATE',
}

import * as Yup from 'yup';
import { SubjectType, PersonalData, Subject, DocumentType } from '../../models';
import {
  validatePhoneNotRequired,
  validateEmailNotRequired,
  validateWebsiteNotRequired,
} from '../profile/profile-utils';
import { addressFieldSchema } from '../../components/form/address-field/address-field-schema';
import {
  validateIco,
  identityCardRegex,
  identityCardMessage,
} from './fields/subjects-utils';

export function useValidationSchema() {
  return Yup.object<Subject>().shape({
    type: Yup.mixed<SubjectType>().nullable().required('Povinné pole'),
    phoneNumber: validatePhoneNotRequired,
    email: Yup.object<PersonalData>().shape({
      value: validateEmailNotRequired(),
    }),
    webSite: validateWebsiteNotRequired,
    ico: Yup.mixed()
      .when('type', {
        is: SubjectType.LEGAL_ENTITY,
        then: validateIco,
      })
      .when('type', {
        is: SubjectType.BUSINESS_NATURAL_PERSON,
        then: Yup.object<PersonalData>().shape({
          value: validateIco,
        }),
      }),
    identityDocuments: Yup.mixed().when('type', {
      is: SubjectType.NATURAL_PERSON,
      then: identityDocumentSchema,
    }),
    name: Yup.string().when('type', {
      is: (type) =>
        type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO ||
        type === SubjectType.LEGAL_ENTITY ||
        type === SubjectType.FOREIGN_LEGAL_ENTITY ||
        type === SubjectType.BUSINESS_NATURAL_PERSON,
      then: Yup.string().nullable().required('Povinné pole'),
    }),
    firstName: Yup.mixed().when('type', {
      is: (type) =>
        type === SubjectType.NATURAL_PERSON ||
        type === SubjectType.FOREIGN_NATURAL_PERSON,
      then: personalDataValueSchema,
    }),
    lastName: Yup.mixed().when('type', {
      is: (type) =>
        type === SubjectType.NATURAL_PERSON ||
        type === SubjectType.FOREIGN_NATURAL_PERSON,
      then: personalDataValueSchema,
    }),
    residence: addressFieldSchema(),
  }) as Yup.Schema<Subject>; // TODO fix TS error if the right time comes
}

const personalDataValueSchema = Yup.object<PersonalData>().shape({
  value: Yup.string().nullable().required('Povinné pole'),
});

const identityDocumentSchema = Yup.array()
  .of(
    Yup.mixed().test('', '', function (val) {
      const { createError } = this;

      if (!val?.type) {
        return createError({
          path: 'identityDocuments',
          message: 'Typ je povinná položka dokladu',
        });
      }

      if (!val?.idNumber?.value) {
        return createError({
          path: 'identityDocuments',
          message: 'Číslo je povinná položka dokladu',
        });
      }

      if (
        val?.type === DocumentType.IDENTITY_CARD &&
        val?.idNumber?.value &&
        !identityCardRegex.test(val.idNumber.value)
      ) {
        return createError({
          path: 'identityDocuments',
          message: identityCardMessage,
        });
      }

      return true;
    })
  )
  .required('Povinné pole');

import { TableColumn, TableCells, TableFilterCells } from '@eas/common-web';
import { useExportTemplatesAutocomplete } from '../export-api';
import { ExportAccessRule } from '../../../models/dict-export';

export function useColumns(): TableColumn<ExportAccessRule>[] {
  return [
    {
      datakey: 'template',
      filterkey: 'template.id',
      sortkey: 'template.name',
      displaykey: 'template.name',
      name: 'Šablona',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useExportTemplatesAutocomplete
      ),
      sortable: true,
      filterable: true,
    },
  ];
}

import { abortableFetch, Filter } from '@eas/common-web';
import { EvidenceAPI, IncludeOptions } from '../../enums';

export function getCoordinates(
  leftCorner: { x: number; y: number },
  rightCorner: { x: number; y: number },
  filters: Filter[],
  api: EvidenceAPI
) {
  return abortableFetch(`${api}/coordinates`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      include: [IncludeOptions.DEACTIVATED],
      filters: [
        {
          operation: 'GEO_BOUNDING_BOX',
          field: 'wgs84Point',
          topLeft: {
            lat: leftCorner.y,
            lon: leftCorner.x,
          },
          bottomRight: {
            lat: rightCorner.y,
            lon: rightCorner.x,
          },
        },
        ...filters,
      ],
    }),
  });
}

import {
  DictionaryAutocomplete,
  useStaticListSource,
  useAutocompleteSource,
  ApiFilterOperation,
  updateItemFactory,
  ExportTemplate,
  createItemFactory,
  getItemFactory,
} from '@eas/common-web';
import { ExportTags } from '../../enums/export-tags';
import { RoleAutocomplete, RoleType } from '../../models';
import { IncludeOptions } from '../../enums';

export function useExportTags() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: ExportTags.AGENDAS, name: 'Agendy' },
    { id: ExportTags.BOILER_MANUFACTURERS, name: 'Výrobci kotlů' },
    { id: ExportTags.BOILER_PERMISSIONS, name: 'Oprávnění OZO' },
    {
      id: ExportTags.DICTIONARY_BOILER_BRANDS,
      name: 'Značky kotlů',
    },
    {
      id: ExportTags.DICTIONARY_BOILER_MODELS,
      name: 'Modely kotlů',
    },
    { id: ExportTags.DICTIONARY_BOILER_TYPES, name: 'Typy kotlů' },
    {
      id: ExportTags.DICTIONARY_COMPETENT_AUTHORITIES,
      name: 'Kompetentní orgány IRZ',
    },
    {
      id: ExportTags.DICTIONARY_COMPETENT_CONTROL_AUTHORITIES,
      name: 'Kompetentní kontrolní orgány',
    },
    { id: ExportTags.DICTIONARY_CZ_NACES, name: 'CZ-NACE' },
    {
      id: ExportTags.DICTIONARY_EPRTR_ACTIVITIES,
      name: 'Aktivity EPRTR',
    },
    {
      id: ExportTags.DICTIONARY_IPPC_ACTIVITIES,
      name: 'Aktivity IPPC',
    },
    {
      id: ExportTags.DICTIONARY_IRZ_ACTIVITIES,
      name: 'Aktivity IRZ',
    },
    { id: ExportTags.DICTIONARY_NACES, name: 'Nace' },
    {
      id: ExportTags.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS,
      name: 'Oblasti povodí ČR',
    },
    {
      id: ExportTags.DICTIONARY_RIVER_BASIN_EU_DISTRICTS,
      name: 'Oblasti povodí EU',
    },
    { id: ExportTags.DICTIONARY_RIVER_SIDES, name: 'Břehy vodních toků' },
    { id: ExportTags.DICTIONARY_SEWER_TYPES, name: 'Typy kanalizace' },
    {
      id: ExportTags.DICTIONARY_TERRITORIAL_TECHNICAL_UNITS,
      name: 'Územní technické jednotky (ÚTJ)',
    },
    { id: ExportTags.DICTIONARY_WATER_FLOWS, name: 'Vodné toky' },
    {
      id: ExportTags.DICTIONARY_WATER_ORIGINS,
      name: 'Původy odebrané vody',
    },
    {
      id: ExportTags.DICTIONARY_WATER_TREATMENT_METHODS,
      name: 'Způsoby úpravy vody',
    },
    { id: ExportTags.EMPOWERMENTS, name: 'Zmocnění' },
    { id: ExportTags.EMPOWERMENT_REQUESTS, name: 'Žádosti zmocnění' },
    { id: ExportTags.IRZ_FACILITIES, name: 'Provozovny IRZ' },
    { id: ExportTags.IRZ_FACILITY_REQUESTS, name: 'Žádosti provozoven IRZ' },
    {
      id: ExportTags.NOTIFICATION_BATCHES,
      name: 'Notifikační dávky',
    },
    {
      id: ExportTags.NOTIFICATION_TEMPLATES,
      name: 'Šablony notifikací',
    },
    { id: ExportTags.OVZ_FACILITIES, name: 'Provozovny OVZ' },
    { id: ExportTags.OVZ_FACILITY_REQUESTS, name: 'Žádosti provozoven OVZ' },
    {
      id: ExportTags.PROTECTED_DEPOSIT_AREAS,
      name: 'Chráněné ložiskové území',
    },
    {
      id: ExportTags.PROTECTED_DEPOSIT_AREAS_DETAIL,
      name: 'Chráněné ložiskové území - detail',
    },
    { id: ExportTags.PRODUCTION_SITES, name: 'Průmyslové místa' },
    { id: ExportTags.REPORTING, name: 'Reporting' },
    {
      id: ExportTags.REPORTING_ACCESS_RULES,
      name: 'Pravidla přístupu k reportům',
    },
    { id: ExportTags.EXPORT_TEMPLATE, name: 'Exporty' },
    { id: ExportTags.ROLES, name: 'Role' },
    { id: ExportTags.ROLE_INSTANCES, name: 'Přidělené role' },
    { id: ExportTags.SUBJECTS, name: 'Subjekty' },
    { id: ExportTags.SUBJECT_REQUESTS, name: 'Žádosti o registraci' },
    { id: ExportTags.SYSTEMS, name: 'Systémy (AIS)' },
    { id: ExportTags.USERS, name: 'Přehled uživatelů' },
    { id: ExportTags.USER_ROLES, name: 'Přehled uživatelů - Role' },
    {
      id: ExportTags.USER_ROLES_OTHER,
      name: 'Přehled uživatelů - Role ostatní',
    },
    { id: ExportTags.WATER_USAGE_PLACES, name: 'Místa užívání vody' },
  ]);
}

export function useRoles() {
  return useAutocompleteSource<RoleAutocomplete>({
    url: '/api/crzp/role/autocomplete',
    params: {
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'registeredForCrzp',
          value: 'true',
        },
        {
          operation: ApiFilterOperation.IN,
          field: 'type.id',
          values: [
            RoleType.SECURITY_ROLE,
            RoleType.USER_ROLE,
            RoleType.RELATIONSHIP_ROLE,
          ],
        },
      ],
      include: [IncludeOptions.ALL],
    },
  });
}

export function useExportTemplatesAutocomplete() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/crzp/export/templates/autocomplete',
  });
}

function embedDocx4jscript(template: ExportTemplate) {
  if (template.docx4jscript != null && template.docx4jscript != '') {
    const configuration = JSON.parse(template.configuration ?? '{}');
    configuration['docx4jscript'] = template.docx4jscript;
    template.configuration = JSON.stringify(configuration);
  }
}

export const updateItem = updateItemFactory<ExportTemplate>({
  preProcess: (template) => {
    embedDocx4jscript(template);
    return template;
  },
});

export const createItem = createItemFactory<ExportTemplate>({
  preProcess: (template) => {
    embedDocx4jscript(template);
    return template;
  },
});

export const getItem = getItemFactory<ExportTemplate>({
  postProcess: (template) => {
    try {
      const configuration = JSON.parse(template.configuration!);
      template.docx4jscript = configuration['docx4jscript'];
    } catch (e) {
      // do nothing
    }

    return template;
  },
});

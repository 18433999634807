import { Filter, abortableFetch } from '@eas/common-web';

interface ResultStatus {
  status: boolean;
  error?: string;
}

export async function bulkDeleteApiCall(
  url: string,
  filters: Filter[],
  ids: string[]
): Promise<ResultStatus> {
  try {
    if (ids.length > 0) {
      await abortableFetch(url, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(ids),
      }).raw();
    }

    return {
      status: true,
    };
  } catch (err) {
    console.log('Bulk action error: ', err);

    return {
      status: false,
      error: err.message ?? err,
    };
  }
}

import React, { useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ClearAllIcon from '@material-ui/icons/Clear';
import {
  TableFieldToolbarButton,
  FormContext,
  useEventCallback,
  TableFieldContext,
  useFormSelector,
} from '@eas/common-web';
import { SpecificationTableCtx } from './specification-table-context';
import { AgendaSpecification } from '../../models';
import { get } from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({
    tableActions: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      height: 30,
      width: '100%',
      boxSizing: 'border-box',
      verticalAlign: 'top',
      display: 'flex',
      padding: '0 12px',
      '& svg': {
        cursor: 'pointer',
        width: 20,
        height: 20,
        padding: '2pt',
      },
    },
    buttonGroup: {
      display: 'flex',
      marginLeft: -5, // offset first icon left padding
    },
  })
);

export function SpecificationToolbarFactory({
  specificationsFieldName,
}: {
  specificationsFieldName: string;
}) {
  return function SpecificationToolbar() {
    /**
     * Styles.
     */
    const classes = useStyles();

    /**
     * Context stuff.
     */
    const { disabled } = useContext(TableFieldContext);

    const { setFieldValue } = useContext(FormContext);
    const { items } = useContext(SpecificationTableCtx);

    /**
     * Retrieve `specifications` from FORM.
     */
    const { specifications } = useFormSelector(
      (data: { specifications?: AgendaSpecification[] }) => ({
        specifications: get(data, specificationsFieldName),
      })
    );

    const handleGetAll = useEventCallback(() => {
      if (items?.length !== undefined && specifications?.length !== undefined) {
        setFieldValue(specificationsFieldName, [...items, ...specifications]);
      }
    });

    const handleClearAll = useEventCallback(() => {
      setFieldValue(specificationsFieldName, []);
    });

    return (
      <div className={classes.tableActions}>
        <ButtonGroup
          size="small"
          variant="outlined"
          className={classes.buttonGroup}
        >
          <TableFieldToolbarButton
            color="primary"
            title="Označit vše"
            IconComponent={DoneAllIcon}
            onClick={handleGetAll}
            show={true}
            disabled={disabled || items.length === 0}
          />
          <TableFieldToolbarButton
            color="primary"
            title="Odznačit vše"
            IconComponent={ClearAllIcon}
            onClick={handleClearAll}
            show={true}
            disabled={disabled || specifications?.length === 0}
          />
        </ButtonGroup>
      </div>
    );
  };
}

import React from 'react';
import { FormAutocomplete } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useIppcActivities } from '../dict-ippc-activity/dict-ippc-activities-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';

export function DictionaryEPrtrActivitiesFields() {
  const ippcActivities = useIppcActivities();

  return (
    <>
      <FormAutocomplete
        name="ippcActivities"
        label={<HelpLabel label="IPPC činnost" code="IPPC_ACTIVITY" />}
        source={ippcActivities}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
        multiple={true}
      />
    </>
  );
}

import React, { useContext, useMemo } from 'react';
import { Evidence2, UserContext } from '@eas/common-web';
import { Agenda, Me } from '../../models';
import { AgendasFields } from './agendas-fields';
import { useColumns } from './agendas-columns';
import { useValidationSchema } from './agendas-schema';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  IncludeOptions,
  Messages,
  EntityName,
} from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { ExportTags } from '../../enums/export-tags';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { DictionaryActionbar } from '../../components/evidence/dictionary-evidence/dictionary-actionbar';
import { useHistoryDescriptors } from './agendas-history';
import { createItemFactory, updateItemFactory } from '../../utils/custom-crud';
import { getErrorMessage } from '../../utils/get-message';

export function Agendas() {
  const validationSchema = useValidationSchema();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const permissions = usePermission<Agenda>({
    options: {
      NEW: [Permission.Agenda.AGENDA_CREATE],
      EDIT: [Permission.Agenda.AGENDA_UPDATE],
      REMOVE: [],
      CHECK: [Permission.Agenda.AGENDA_CREATE, Permission.Agenda.AGENDA_UPDATE],
      SAVE: [Permission.Agenda.AGENDA_CREATE, Permission.Agenda.AGENDA_UPDATE],
      CLOSE: [Permission.Agenda.AGENDA_CREATE, Permission.Agenda.AGENDA_UPDATE],
    },
  });

  const canSeeDeactivated = useMemo(
    () => hasPermission(Permission.Agenda.AGENDA_INVALIDATE),
    [hasPermission]
  );

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<Agenda>({
    identifier: PageKey.AGENDAS,
    apiProps: {
      url: EvidenceAPI.AGENDAS,
      createItem: createItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.Agenda.CREATE,
              data.code
            )[0];

            throw { message };
          }
        },
      }),
      updateItem: updateItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.Agenda.UPDATE,
              data.code
            )[0];

            throw { message };
          }
        },
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled agend',
      reportTag: ExportTags.AGENDAS,
      include,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.AGENDA,
      FieldsComponent: AgendasFields,
      validationSchema,
      toolbarProps: {
        title: 'Agenda',
        ActionBar: function ActionBar() {
          return (
            <DictionaryActionbar
              url={EvidenceAPI.AGENDAS}
              invalidatePerm={Permission.Agenda.AGENDA_INVALIDATE}
              revalidatePerm={Permission.Agenda.AGENDA_REVALIDATE}
            />
          );
        },
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Agenda.AGENDA_ASSIGN_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}

import React from 'react';
import {
  FormPanel,
  FormSelect,
  FormTextField,
  useFormSelector,
  useEventCallback,
  FormContext,
} from '@eas/common-web';
import { useSubjectTypes } from '../../subject/subjects-api';
import { StepIdentificatorForm } from './step-identificator-types';
import { SubjectType, SubjectIdentificator } from '../../../models';
import { useNaturalPersonIdentificators } from './step-identificator-api';
import { useContext } from 'react';
import { icoMaxLength } from '../../subject/fields/subjects-utils';
import { noop } from 'lodash';
import { HelpLabel } from '../../../components/help/help-label';

export function StepIdentificatorContent() {
  const subjectTypes = useSubjectTypes();
  const { setFieldValue } = useContext(FormContext);
  const naturalPersonIdentificators = useNaturalPersonIdentificators();

  const { subjectType, type } = useFormSelector(
    (data: StepIdentificatorForm) => ({
      subjectType: data.subjectType,
      type: data.type,
    })
  );

  //Functions to find out identifier based on subject type
  const hasIco = (subjectType: SubjectType | undefined) =>
    subjectType === SubjectType.BUSINESS_NATURAL_PERSON ||
    subjectType === SubjectType.LEGAL_ENTITY;

  const hasUID = (subjectType: SubjectType | undefined) =>
    subjectType === SubjectType.FOREIGN_LEGAL_ENTITY ||
    subjectType === SubjectType.FOREIGN_NATURAL_PERSON ||
    subjectType === SubjectType.LEGAL_ENTITY_WITHOUT_ICO;

  // Ensure the identificator type is correctly set when there is only one option
  const handleSubjectTypeChanged = useEventCallback(() => {
    if (hasIco(subjectType)) {
      setFieldValue('type', SubjectIdentificator.ICO);
    } else if (hasUID(subjectType)) {
      setFieldValue('type', SubjectIdentificator.ARTIFICIAL_ID);
    } else {
      setFieldValue('type', undefined);
    }
  });

  //Properties of FormTextField (label, inputProps, onChangeEvent) based on subjectType
  let identifierLabel = 'Identifikátor';
  if (hasIco(subjectType)) {
    identifierLabel = 'IČO';
  } else if (hasUID(subjectType)) {
    identifierLabel = 'UID';
  }

  let inputProps = {};
  if (hasIco(subjectType)) {
    inputProps = { maxLength: icoMaxLength };
  }

  let onChangeEvent = noop;
  if (hasIco(subjectType)) {
    onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.target.value = e.target.value.replace(/[^0-9]/gi, '');
    };
  }

  return (
    <>
      <FormPanel label="Vyplňte">
        <FormSelect
          name="subjectType"
          source={subjectTypes}
          notifyChange={handleSubjectTypeChanged}
          valueIsId
          label={
            <HelpLabel
              label="Typ subjektu"
              code="BOILER_MANUFACTURER_NEW_SUBJECT_TYPE"
              required
            />
          } // Povinné pole
        />
        {subjectType === SubjectType.NATURAL_PERSON && (
          <FormSelect
            name="type"
            source={naturalPersonIdentificators}
            valueIsId
            label={
              <HelpLabel
                label="Typ identifikátoru"
                code="BOILER_MANUFACTURER_NEW_TYPE"
                required
              />
            } // Povinné pole
          />
        )}
        {subjectType && type && (
          <FormTextField
            name="value"
            label={
              <HelpLabel
                label={identifierLabel}
                code="BOILER_MANUFACTURER_NEW_VALUE"
                required
              />
            } // Povinné pole
            inputProps={inputProps}
            onChangeEvent={onChangeEvent}
          />
        )}
      </FormPanel>
    </>
  );
}

import React from 'react';
import { FormPanel, FormTextField, FormSelect } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';
import { useSubjectIdentificators } from '../../subject/subjects-api';

export function StepNewOperatorContent() {
  const subjectIdentificators = useSubjectIdentificators();

  return (
    <FormPanel label="Nový provozovatel">
      <FormSelect
        name="identifierType"
        label={
          <HelpLabel
            label="Typ identifikátoru"
            code="OVZ_FACILITY_REQUEST_OPERATOR_CHANGE_NEW_OPERATOR_TYPE"
            required
          />
        } // Povinné pole
        source={subjectIdentificators}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
      />
      <FormTextField
        name="newOperatorIdentifier"
        label={
          <HelpLabel
            label="Identifikátor"
            code="OVZ_FACILITY_REQUEST_OPERATOR_CHANGE_NEW_OPERATOR_IDENTIFIER"
            required
          />
        } // Povinné pole
      />
    </FormPanel>
  );
}

import React, { ReactNode } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { DashboardCardCustom } from '@eas/common-web';

interface CustomCardBaseProps {
  title: string;
  value: string;
  icon: ReactNode;
  navigate: () => void;
  children?: ReactNode;
  definitionId: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    cardSpecialTitle: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 1.6,
      paddingTop: 10,
      paddingBottom: 10,
    },
    cardSpecialValue: {
      fontFamily: 'montserrat',
      fontWeight: 600,
      fontSize: 22,
    },
  })
);

export function CustomCardBase({
  icon,
  title,
  navigate,
  value,
  children,
  definitionId,
}: CustomCardBaseProps) {
  const classes = useStyles();

  return (
    <DashboardCardCustom
      definitionId={definitionId}
      navigate={navigate}
      actions={<>{children}</>}
    >
      <Avatar>{icon}</Avatar>
      <Typography className={classes.cardSpecialTitle}>{title}</Typography>
      <Typography className={classes.cardSpecialValue}>{value}</Typography>
    </DashboardCardCustom>
  );
}

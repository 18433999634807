import React from 'react';
import { CustomRenderLeafProps } from '../../../global';

export function Leaf({ attributes, children, leaf }: CustomRenderLeafProps) {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.link) {
    children = (
      <a
        href={leaf.link.url}
        data-name={leaf.link.name}
        data-content-type={leaf.link.contentType}
        data-size={leaf.link.size}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }

  return <span {...attributes}>{children}</span>;
}

import React, { useContext } from 'react';
import MapIcon from '@material-ui/icons/Map';
import RefreshIcon from '@material-ui/icons/Refresh';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SplitIcon from '@material-ui/icons/CallSplit';
import MergeIcon from '@material-ui/icons/CallMerge';
import PlaceIcon from '@material-ui/icons/Place';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButtonType,
  DetailActionbarButton,
  DetailActionButton,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { useCreateNoteDialog } from '../../components/evidence/noted-evidence/dialog-actions/create-note-hook';
import { EntityType } from '../../enums/entity-type';
import { useActivateDialog } from './dialog-actions/activate-hook';
import { useDeactivateDialog } from './dialog-actions/deactivate-hook';
import { useDeactivateRequestDialog } from './dialog-actions/request-deactivate-hook';
import { useRedirect } from './actions/redirect-hook';
import {
  MapEvidenceContext,
  MapEvidenceView,
} from '../../components/evidence/map-evidence/map-context';
import { useOperatorChangeDialog } from './dialog-actions/operator-change-hook';
import { useEuStateChangeDialog } from './dialog-actions/eu-state-change-hook';
import { useAssignProductionSiteDialog } from './dialog-actions/assign-production-site-hook';
import { useShowOnMap } from './actions/show-on-map';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function IrzFacilitiesActionbar() {
  const { mode, source } = useContext(DetailContext);
  const { view } = useContext(MapEvidenceContext);

  const {
    handleCreateNote,
    showCreateButton: showCreateNote,
  } = useCreateNoteDialog({
    entityType: EntityType.IRZ,
  });
  const { handleActivate, showActivateButton } = useActivateDialog();
  const { handleDeactivate, showDeactivateButton } = useDeactivateDialog();
  const {
    handleDeactivateRequest,
    showDeactivateRequestButton,
  } = useDeactivateRequestDialog();

  const {
    handleChangeRequest,
    showChangeRequestButton,
    handleSplit,
    showSplitButton,
    handleMerge,
    showMergeButton,
  } = useRedirect();

  const { showShowOnMapButton, handleShowOnMap } = useShowOnMap();

  const {
    handleOperatorChange,
    showOperatorChangeButton,
  } = useOperatorChangeDialog();

  const {
    handleEuStateChange,
    showEuStateChangeButton,
  } = useEuStateChangeDialog();

  const {
    callApi: assignProductionSiteApiCall,
    showBtn: showAssignProductionSiteButton,
    FormFields: AssignProductionSiteFields,
    loadNearbyProductionSitesCoordinates,
  } = useAssignProductionSiteDialog();

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          {showActivateButton && (
            <DetailActionbarButton
              startIcon={<RefreshIcon fontSize="small" />}
              label="Obnovit platnost"
              onClick={handleActivate}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showDeactivateButton && (
            <DetailActionbarButton
              startIcon={<CloseIcon fontSize="small" />}
              label="Zneplatnit"
              onClick={handleDeactivate}
              type={DetailToolbarButtonType.SECONDARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showShowOnMapButton && (
            <DetailActionbarButton
              startIcon={<MapIcon fontSize="small" />}
              label="Zobrazit na mapě"
              onClick={handleShowOnMap}
              type={DetailToolbarButtonType.NORMAL}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showAssignProductionSiteButton && (
            <DetailActionButton
              promptKey="ASSIGN_PRODUCTION_SITE"
              apiCall={assignProductionSiteApiCall}
              buttonLabel="Přidělit průmyslové místo"
              dialogTitle="Přidělení průmyslového místa"
              dialogText="Vyberte průmyslové místo"
              FormFields={AssignProductionSiteFields}
              dialogShowClose={false}
              dialogShowConfirm={false}
              ButtonComponent={(props) => (
                <PrimaryDetailActionbarButton
                  {...props}
                  startIcon={<PlaceIcon />}
                />
              )}
              formInitialValues={{
                productionSite: source?.data?.productionSite,
              }}
              onBeforeDialog={loadNearbyProductionSitesCoordinates}
              dialogWidth={1000}
            />
          )}
          {showCreateNote && (
            <DetailActionbarButton
              startIcon={<NoteAddIcon fontSize="small" />}
              label="Přidat poznámku"
              onClick={handleCreateNote}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showSplitButton && (
            <DetailActionbarButton
              startIcon={<SplitIcon fontSize="small" />}
              label="Rozdělit"
              onClick={handleSplit}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showMergeButton && (
            <DetailActionbarButton
              startIcon={<MergeIcon fontSize="small" />}
              label="Sloučit"
              onClick={handleMerge}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showOperatorChangeButton && (
            <DetailActionbarButton
              startIcon={<EditIcon fontSize="small" />}
              label="Změna provozovatele"
              onClick={handleOperatorChange}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showEuStateChangeButton && (
            <DetailActionbarButton
              startIcon={<EditIcon fontSize="small" />}
              label="Změna stavu EU"
              onClick={handleEuStateChange}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showChangeRequestButton && (
            <DetailActionbarButton
              startIcon={<EditIcon fontSize="small" />}
              label="Žádost o změnu údajů"
              onClick={handleChangeRequest}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showDeactivateRequestButton && (
            <DetailActionbarButton
              startIcon={<CloseIcon fontSize="small" />}
              label="Žádost o zneplatnění"
              onClick={handleDeactivateRequest}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
        </div>
      )}
    </>
  );
}

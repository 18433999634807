import React from 'react';
import {
  FormAutocomplete,
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
  AuthoredFields,
  DatedFields,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useMineralGroups } from '../dict-mineral-group/dict-mineral-groups-api';

export function DictionaryDepositsFields() {
  const mineralGroups = useMineralGroups();

  return (
    <>
      <FormPanel label="Nerosty">
        <FormAutocomplete
          name="mineralGroups"
          label={
            <HelpLabel
              label="Skupiny nerostů"
              code="DICT_DEPOSITS_MINERAL_GROUPS"
            />
          }
          multiple={true}
          source={mineralGroups}
        />
      </FormPanel>
      <FormPanel label="Obecné">
        <FormTextField
          name="name"
          label={
            <HelpLabel
              label="Název ložiska"
              code="DICT_DEPOSITS_NAME"
              required
            />
          } // Povinné pole
        />
        <FormTextField
          name="code"
          label={
            <HelpLabel
              label="Číslo ložiska"
              code="DICT_DEPOSITS_CODE"
              required
            />
          } // Povinné pole
        />
        <FormCheckbox
          name="active"
          label={<HelpLabel label="Aktivní" code="DICT_DEPOSITS_ACTIVE" />}
          disabled={true}
        />
        <FormDateTimeField
          name="validFrom"
          label={
            <HelpLabel label="Platný od" code="DICT_DEPOSITS_VALID_FROM" />
          }
        />
        <FormDateTimeField
          name="validTo"
          label={<HelpLabel label="Platný do" code="DICT_DEPOSITS_VALID_TO" />}
        />
        <AuthoredFields />
        <DatedFields />
      </FormPanel>
    </>
  );
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './history-field-styles';
import { useHistoryFieldTooltip } from './history-field-tooltip-hook';
import { Tooltip } from '@eas/common-web';

export function LegacyHistoryField({ value }: { value?: string }) {
  const classes = useStyles();
  const { ref, useTooltip } = useHistoryFieldTooltip();

  const content = (
    <span className={classes.text} ref={ref}>
      {value}
    </span>
  );

  return (
    <>
      <Typography variant="body1" className={classes.content}>
        {useTooltip ? (
          <Tooltip title={value} placement="top-start" type="HOVER">
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </Typography>
    </>
  );
}

import * as Yup from 'yup';
import { StepEditForm } from './step-edit-types';
import {
  addressFieldSchema,
  wgs84CoordinatesSchema,
} from '../../../components/form/address-field/address-field-schema';

export function stepEditSchema() {
  return Yup.object<StepEditForm>().shape({
    requestFacilityData: Yup.object().shape({
      address: addressFieldSchema(),
      wgs84Coordinates: wgs84CoordinatesSchema(),
    }),
  });
}

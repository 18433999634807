import React, { useRef, useContext } from 'react';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  DomainObject,
  useFormSelector,
} from '@eas/common-web';
import { Note } from '../../../../models/note';
import { EvidenceAPI, Messages, Permission } from '../../../../enums';
import { unstable_batchedUpdates } from 'react-dom';
import Typography from '@material-ui/core/Typography';

/**
 * Api call
 */
function callApi(note: Note) {
  return abortableFetch(`${EvidenceAPI.NOTES}/${note.id}`, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
}

/**
 * Smazání poznámky
 */
const PROMPT_KEY = 'DELETE_NOTE';

export function useDeleteNoteDialog({
  selectedIndex,
  setSelectedIndex,
}: {
  selectedIndex?: number;
  setSelectedIndex: (index: number | undefined) => void;
}) {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext<DetailHandle<DomainObject>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { reload, hasPermission } = useContext(UserContext);

  /**
   * Notes table data.
   */
  const { notes } = useFormSelector((data: { notes: Note[] }) => ({
    notes: data?.notes,
  }));

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Smazání poznámky',
        dialogText: (
          <>
            <Typography gutterBottom={true}>
              Skutečně chcete smazat následující poznámku?
            </Typography>
            <Typography variant="body1" style={{ whiteSpace: 'break-spaces' }}>
              {selectedIndex !== undefined ? notes[selectedIndex]?.text : ''}
            </Typography>
          </>
        ),
        dialogWidth: 600,
      },
    ],
    [selectedIndex, notes]
  );

  const handleDeleteNote = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callApi(notes[selectedIndex!]);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Note.DELETE.SUCCESS);
            source.setLoading(false);
          });

          reload();
          setSelectedIndex(undefined);
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Note.DELETE.ERROR);
          }

          return true;
        }
      },
    });
  });

  const showDeleteButton = hasPermission(Permission.Note.NOTES_DELETE);

  return {
    handleDeleteNote,
    showDeleteButton,
  };
}

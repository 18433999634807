import React, { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { set, omit } from 'lodash';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  DetailMode,
  PromptContext,
  usePrompts,
  FormSelect,
  useFormSelector,
  FormInlineTableField,
  TableFieldCellProps,
  FormContext,
  Checkbox,
  FormPanel,
  FormTextField,
  FormTextArea,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  EmpowermentRequestUnion,
  Subject,
  SpecifiedEmpowermentRequest,
  SubjectType,
  SubjectIdentificator,
  EmpowermentSpecificationRequest,
  DocumentType,
  EmpowermentRequestState,
} from '../../../models';
import { useValidationSchema } from './../empowerment-requests-schema';
import { EvidenceAPI, Permission, Messages } from '../../../enums';
import { useSubjectTypes } from '../../subject/subjects-api';
import {
  EmpowermentSpecificationApprovalState,
  AuthorRole,
} from '../../../models/empowerment-request';
import { EmpowermentSpecificationToolbarFactory } from '../empowerment-specification-toolbar';
import { AddressField } from '../../../components/form/address-field/address-field';
import { LegalEntityBasicFields } from '../../subject/fields/legal-entity-fields/legal-entity-basic-fields';
import { LegalEntityAdvancedFields } from '../../subject/fields/legal-entity-fields/legal-entity-advanced-fields';
import { NaturalPersonBasicFields } from '../../subject/fields/natural-person-fields/natural-person-basic-fields';
import { NaturalPersonAdvancedFields } from '../../subject/fields/natural-person-fields/natural-person-advanced-fields';
import { LegalEntityWithoutIcoBasicFields } from '../../subject/fields/legal-entity-without-ico-fields/legal-entity-without-ico-basic-fields';
import { LegalEntityWithoutIcoAdvancedFields } from '../../subject/fields/legal-entity-without-ico-fields/legal-entity-without-ico-advanced-fields';
import { BusinessNaturalPersonBasicFields } from '../../subject/fields/business-natural-person-fields/business-natural-person-basic-fields';
import { BusinessNaturalPersonAdvancedFields } from '../../subject/fields/business-natural-person-fields/business-natural-person-advanced-fields';
import { ForeignLegalEntityBasicFields } from '../../subject/fields/foreign-legal-entity-fields/foreign-legal-entity-basic-fields';
import { ForeignLegalEntityAdvancedFields } from '../../subject/fields/foreign-legal-entity-fields/foreign-legal-entity-advanced-fields';
import { ForeignNaturalPersonBasicFields } from '../../subject/fields/foreign-natural-person-fields/foreign-natural-person-basic-fields';
import { ForeignNaturalPersonAdvancedFields } from '../../subject/fields/foreign-natural-person-fields/foreign-natural-person-advanced-fields';
import { SubjectRequestFileFields } from '../../subject-request/fields/subject-request-file-fields';
import { HelpLabel } from '../../../components/help/help-label';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme) => ({
  warning: {
    lineHeight: 'inherit',
    padding: '0 2pt',
    color: theme.palette.error.main,
  },
}));

/**
 * Api call
 *
 * @param id
 */
export function callApi(id: string, values: Record<string, any>) {
  return abortableFetch(`${EvidenceAPI.EMPOWERMENT_REQUESTS}/${id}/resolve`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(values),
  });
}

type GeneralRequestForm = EmpowermentRequestUnion & {
  subject: Subject;
};

type SpecifiedRequestForm = SpecifiedEmpowermentRequest & {
  subject: Subject;
};

function createInitialValues(
  data: GeneralRequestForm | SpecifiedRequestForm | null
) {
  const formInitialValues = {
    resolveState: 'APPROVE',
    subject: {},
    specificationRequests:
      (data as SpecifiedRequestForm)?.specificationRequests
        ?.filter(
          (spec) =>
            spec.approvalState === EmpowermentSpecificationApprovalState.NONE
        )
        .sort((a, b) => {
          if (a.specification.name! < b.specification.name!) {
            return -1;
          }
          if (a.specification.name! > b.specification.name!) {
            return 1;
          }
          return 0;
        }) ?? [],
  };

  if (data?.authorRole === AuthorRole.EMPOWERER && data?.agent?.approved) {
    set(formInitialValues, 'subject', data?.agent);
  }

  if (data?.authorRole === AuthorRole.AGENT && data?.empowerer?.approved) {
    set(formInitialValues, 'subject', data?.empowerer);
  }
  if (data?.authorRole === AuthorRole.AGENT && !data?.empowerer?.approved) {
    set(formInitialValues, 'subject', {
      ...omit(data?.empowerer, 'id'),
      ...data.otherSubject?.foreignSubjectInfo,
    });
  }

  if (!data?.subject) {
    set(formInitialValues, 'subject.type', data?.otherSubject?.subjectType);

    if (
      data?.otherSubject?.type === SubjectIdentificator.ID_CARD_NUMBER ||
      data?.otherSubject?.type === SubjectIdentificator.PASSPORT_NUMBER
    ) {
      set(formInitialValues, 'subject.identityDocuments', [
        {
          type:
            data?.otherSubject?.type === SubjectIdentificator.ID_CARD_NUMBER
              ? DocumentType.IDENTITY_CARD
              : DocumentType.PASSPORT,
          idNumber: {
            value: data?.otherSubject?.value,
          },
        },
      ]);
    }

    if (
      data?.otherSubject?.type === SubjectIdentificator.ICO &&
      data?.otherSubject?.subjectType === SubjectType.BUSINESS_NATURAL_PERSON
    ) {
      set(formInitialValues, 'subject.ico.value', data?.otherSubject?.value);
    }

    if (
      data?.otherSubject?.type === SubjectIdentificator.ICO &&
      data?.otherSubject?.subjectType === SubjectType.LEGAL_ENTITY
    ) {
      set(formInitialValues, 'subject.ico', data?.otherSubject?.value);
    }

    if (data?.otherSubject?.type === SubjectIdentificator.ARTIFICIAL_ID) {
      set(formInitialValues, 'subject.artificialId', data?.otherSubject?.value);
    }
  }

  return formInitialValues;
}

/**
 * Approve `BASIC Empowerment request`.
 */
const PROMPT_KEY = 'APPROVE';

export function useApproveDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source, mode, cancelEditing } = useContext<
    DetailHandle<GeneralRequestForm | SpecifiedRequestForm>
  >(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Schválení žádosti',
        dialogText: 'Skutečně chcete schválit žádost o zmocnění?',
        FormFields: function Body() {
          const { editing, setFieldValue } = useContext(FormContext);
          const detailCtx = useContext(DetailContext);

          const classes = useStyles();

          const { subject, specificationRequests } = useFormSelector(
            (data: {
              subject: Subject;
              specificationRequests: EmpowermentSpecificationRequest[];
            }) => ({
              subject: data.subject,
              specificationRequests: data.specificationRequests,
            })
          );

          const subjectTypes = useSubjectTypes(source.data?.otherSubject?.type);

          const checked = specificationRequests.filter(
            (spec) =>
              spec.approvalState ===
              EmpowermentSpecificationApprovalState.APPROVED
          );

          const CheckboxCell = ({
            index,
          }: TableFieldCellProps<EmpowermentSpecificationRequest>) => {
            const request = specificationRequests[index];

            const isActive =
              request.approvalState !==
              EmpowermentSpecificationApprovalState.NONE;

            const handleChange = () => {
              if (!isActive) {
                setFieldValue(`specificationRequests[${index}]`, {
                  ...request,
                  approvalState: EmpowermentSpecificationApprovalState.APPROVED,
                });
              } else {
                setFieldValue(`specificationRequests[${index}]`, {
                  ...request,
                  approvalState: EmpowermentSpecificationApprovalState.NONE,
                });
              }
            };

            return (
              <Checkbox
                value={!!isActive}
                onChange={handleChange}
                disabled={!editing}
              />
            );
          };

          return (
            <>
              {!subject?.id && (
                <>
                  <Typography>
                    Subjekt zmocnitele v CRŽP neexistuje a po schválení žádosti
                    bude založen.
                  </Typography>
                  <br />
                  <br />
                  <FormPanel label="Subjekt">
                    <FormSelect
                      name="subject.type"
                      label="Typ"
                      source={subjectTypes}
                      tooltipMapper={(o) => o.name}
                      valueIsId={true}
                      disabled={subjectTypes.items.length === 1}
                    />
                    {/* fyzicka osoba */}
                    {subject?.type === SubjectType.BUSINESS_NATURAL_PERSON && (
                      <>
                        <BusinessNaturalPersonBasicFields
                          prefix="subject"
                          disabled={false}
                        />
                        <BusinessNaturalPersonAdvancedFields
                          prefix="subject"
                          disabled={false}
                        />
                      </>
                    )}
                    {subject?.type === SubjectType.FOREIGN_NATURAL_PERSON && (
                      <>
                        <ForeignNaturalPersonBasicFields
                          prefix="subject"
                          disabled={false}
                        />
                        <ForeignNaturalPersonAdvancedFields
                          prefix="subject"
                          disabled={false}
                        />
                      </>
                    )}
                    {subject?.type === SubjectType.NATURAL_PERSON && (
                      <>
                        <NaturalPersonBasicFields
                          prefix="subject"
                          disabled={false}
                        />
                        <NaturalPersonAdvancedFields
                          prefix="subject"
                          disabled={false}
                        />
                      </>
                    )}

                    {/* pravnicka osoba */}
                    {subject?.type === SubjectType.FOREIGN_LEGAL_ENTITY && (
                      <>
                        <ForeignLegalEntityBasicFields
                          prefix="subject"
                          disabled={false}
                        />
                        <FormTextField
                          name="subject.foreignId"
                          label={
                            <HelpLabel
                              label="Zahraniční identifikátor (obdoba IČO)"
                              code="APPROVE_SUBJECT_FOREIGN_ID"
                            />
                          }
                        />
                        <ForeignLegalEntityAdvancedFields
                          prefix="subject"
                          disabled={false}
                        />
                        <SubjectRequestFileFields
                          type={SubjectType.FOREIGN_LEGAL_ENTITY}
                          prefix="subject"
                        />
                      </>
                    )}
                    {subject?.type === SubjectType.LEGAL_ENTITY && (
                      <>
                        <LegalEntityBasicFields
                          prefix="subject"
                          disabled={false}
                        />
                        <LegalEntityAdvancedFields
                          prefix="subject"
                          disabled={false}
                        />
                      </>
                    )}
                    {subject?.type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO && (
                      <>
                        <LegalEntityWithoutIcoBasicFields
                          prefix="subject"
                          disabled={false}
                        />
                        <LegalEntityWithoutIcoAdvancedFields
                          prefix="subject"
                          disabled={false}
                        />
                      </>
                    )}
                  </FormPanel>
                  <FormPanel label="Adresa">
                    <DetailContext.Provider
                      value={{
                        ...detailCtx,
                        mode: DetailMode.EDIT,
                      }}
                    >
                      <AddressField
                        name="subject.residence"
                        disabled={false}
                        disableForeignCountry={
                          subject?.type === SubjectType.LEGAL_ENTITY ||
                          subject?.type ===
                            SubjectType.LEGAL_ENTITY_WITHOUT_ICO ||
                          subject?.type === SubjectType.NATURAL_PERSON ||
                          subject?.type === SubjectType.BUSINESS_NATURAL_PERSON
                        }
                        disableRuian={
                          subject?.type === SubjectType.FOREIGN_LEGAL_ENTITY
                        }
                      />
                    </DetailContext.Provider>
                  </FormPanel>
                </>
              )}
              {specificationRequests?.length !== 0 && (
                <FormPanel
                  label="Typ hlášení"
                  summary={`[${checked.length} / ${specificationRequests?.length}]`}
                  expandable={true}
                  defaultExpanded={true}
                >
                  <FormInlineTableField<EmpowermentSpecificationRequest>
                    name="specificationRequests"
                    disabled
                    labelOptions={{ hide: true }}
                    layoutOptions={{ noSpacing: true }}
                    maxRows={10}
                    ToolbarComponent={EmpowermentSpecificationToolbarFactory(
                      EmpowermentSpecificationApprovalState.APPROVED
                    )}
                    columns={[
                      {
                        name: '',
                        datakey: 'active',
                        width: 50,
                        CellComponent: CheckboxCell,
                      },
                      {
                        name: 'Název',
                        datakey: 'specification.name',
                        width: 200,
                      },
                    ]}
                  />
                </FormPanel>
              )}
              <FormPanel label="Poznámka">
                <FormTextArea
                  name="note"
                  labelOptions={{
                    hide: true,
                  }}
                />
              </FormPanel>
              {!subject.residence && (
                <Typography variant="body1" className={classes.warning}>
                  {`Upozornění: Subjekt zmocnění nemá správně vyplněny údaje, opravte hodnoty subjektu UID: ${subject.artificialId}.`}
                </Typography>
              )}
            </>
          );
        },
        formValidationSchema: useValidationSchema(),
        formInitialValues: createInitialValues(source.data),
        dialogWidth: 800,
      },
    ],
    [source.data]
  );

  const handleApprove = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: {
        resolveState: 'APPROVE';
        subject: Subject;
        note?: string;
        specificationRequests: EmpowermentSpecificationRequest[];
      }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          const resolveObj: Record<string, any> = {
            resolveState: values?.resolveState,
            subject: values?.subject,
            note: values?.note,
          };

          if (values?.specificationRequests?.length) {
            resolveObj[
              'approvedSpecifications'
            ] = values?.specificationRequests?.filter(
              (spec) =>
                spec.approvalState ===
                EmpowermentSpecificationApprovalState.APPROVED
            );
          }

          fetch.current = callApi(source.data!.id, resolveObj);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.EmpowermentRequest.APPROVE.SUCCESS);
            source.setLoading(false);
          });

          cancelEditing();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            if (err.code === 'INCOMPATIBLE_RESOLUTION_TYPE') {
              values.specificationRequests.length
                ? showSnackbar(
                    ...Messages.EmpowermentRequest.APPROVE
                      .INCOMPATIBLE_RESOLUTION_TYPE_BASIC
                  )
                : showSnackbar(
                    ...Messages.EmpowermentRequest.APPROVE
                      .INCOMPATIBLE_RESOLUTION_TYPE_SPECIFICATIONS
                  );
            } else {
              const message = getErrorMessage(
                Messages.EmpowermentRequest.APPROVE,
                err.code
              );

              showSnackbar(...message);
            }
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  /**
   * Btn "Schválit žádost".
   */
  const showApproveButton =
    mode === DetailMode.VIEW &&
    source.data?.state === EmpowermentRequestState.AWAITING_DECISION &&
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_FINISH);

  return {
    handleApprove,
    showApproveButton,
  };
}

import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionarySampleType } from '../../models';
import { useValidationSchema } from './dict-sample-types-schema';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';

export function DictionarySampleTypes() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<DictionarySampleType>({
    options: {
      NEW: [Permission.MuvSampleType.MUV_SAMPLE_TYPE_CREATE],
      EDIT: [Permission.MuvSampleType.MUV_SAMPLE_TYPE_UPDATE],
      REMOVE: [Permission.MuvSampleType.MUV_SAMPLE_TYPE_DELETE],
      CHECK: [
        Permission.MuvSampleType.MUV_SAMPLE_TYPE_CREATE,
        Permission.MuvSampleType.MUV_SAMPLE_TYPE_UPDATE,
      ],
      SAVE: [
        Permission.MuvSampleType.MUV_SAMPLE_TYPE_CREATE,
        Permission.MuvSampleType.MUV_SAMPLE_TYPE_UPDATE,
      ],
      CLOSE: [
        Permission.MuvSampleType.MUV_SAMPLE_TYPE_CREATE,
        Permission.MuvSampleType.MUV_SAMPLE_TYPE_UPDATE,
      ],
    },
  });

  const evidence = useDictionaryEvidence<DictionarySampleType>(
    {
      identifier: PageKey.DICTIONARY_SAMPLE_TYPES,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_SAMPLE_TYPES,
      },
      tableProps: {
        tableName: 'Typy vzorků',
        reportTag: ExportTags.DICTIONARY_SAMPLE_TYPES,
        defaultSorts: [],
      },
      detailProps: {
        entityName: EntityName.SAMPLE_TYPE,
        codePrefix: 'SAMPLE_TYPE',
        validationSchema,
        toolbarProps: {
          title: 'Typ vzorku',
          showBtn: permissions,
        },
      },
    },
    Permission.MuvSampleType.MUV_SAMPLE_TYPE_INVALIDATE,
    Permission.MuvSampleType.MUV_SAMPLE_TYPE_REVALIDATE
  );

  return <Evidence2 {...evidence} />;
}

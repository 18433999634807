import { AuthoredObject } from './authored-object';
import { Empowerment } from './empowerment';
import {
  AgendaType,
  BasicAgenda,
  SpecifiedAgenda,
  AgendaSpecification,
} from './agenda';
import {
  Subject,
  SubjectIdentificator,
  SubjectType,
  SubjectUnion,
} from './subject';
import { ArchivedFile } from './archived-file';
import { CeniaDetails } from './subject-registration';
import { DictionaryCountry } from './dict-country';

/**
 * Stav schválení žádosti o zmocnění
 */
export enum EmpowermentSpecificationApprovalState {
  /**
   * Neschválena
   */
  NONE = 'NONE',

  /**
   * Schválena
   */
  APPROVED = 'APPROVED',

  /**
   * Zamítnuta
   */
  REJECTED = 'REJECTED',

  /**
   * Částečně schválena a/alebo částečně zamítnuta
   */
  BOTH = 'BOTH',
}

export enum EmpowermentRequestState {
  /**
   * Čeká na ověření
   */
  AWAITING_DECISION = 'AWAITING_DECISION',

  /**
   * Schválena
   */
  APPROVED = 'APPROVED',

  /**
   * Zamítnuta
   */
  REJECTED = 'REJECTED',

  /**
   * Schválena / Zamítnuta
   */
  BOTH = 'BOTH',
}

/**
 * Role autora žádosti o zmocnění
 */
export enum AuthorRole {
  /**
   * Zmocnitel
   */
  EMPOWERER = 'EMPOWERER',

  /**
   * Zmocněnec
   */
  AGENT = 'AGENT',
}

/**
 * Žádost o zmocnění
 */
export interface EmpowermentRequest extends AuthoredObject {
  /**
   * Autor žádosti
   */
  author?: SubjectUnion;

  /**
   * Role autora žádosti o zmocnění
   */
  authorRole?: AuthorRole;

  /**
   * Zmoncnitel
   */
  empowerer?: Subject;

  /**
   * Zmocnitel
   */
  agent?: Subject;

  /**
   * Identifikátor druhého subjektu
   */
  otherSubject?: {
    /**
     * Typ identifikátoru subjektu
     */
    type?: SubjectIdentificator;

    /**
     * Typ subjektu
     */
    subjectType?: SubjectType;

    /**
     * Hodnota
     */
    value?: string;

    /**
     * Informace pro registraci zahraniční osoby
     */
    foreignSubjectInfo?: ForeignSubjectInfo;
  };

  /**
   * Stav žádosti
   */
  state?: EmpowermentRequestState;

  /**
   * Zmocnění (vytvořeno po schválení žádosti)
   */
  empowerment?: Empowerment;

  /**
   * Dokument zmocnění
   */
  empowermentDocument?: ArchivedFile;

  /**
   * Typ žádosti o zmocnění
   */
  type?: AgendaType;

  /**
   * Datum platnosti: od
   */
  validFrom?: string;

  /**
   * Datum platnosti: do
   */
  validTo?: string;

  /**
   * Poznámka
   */
  note?: string;

  /**
   * Spisová služba CENIA
   */
  ceniaDetails?: CeniaDetails;

  /**
   * Výpis z obchodního rejstříku
   */
  certificateOfIncorporation?: ArchivedFile;
}

/**
 * Žádost o specifikaci zmocnění
 */
export interface EmpowermentSpecificationRequest {
  /**
   * Typ hlášení
   */
  specification: AgendaSpecification;

  /**
   * Stav schválení žádosti
   */
  approvalState: EmpowermentSpecificationApprovalState;
}

/**
 * Žádost o zmocnění bez specifikací
 */
export interface BasicEmpowermentRequest extends EmpowermentRequest {
  /**
   * Typ žádosti o zmocnění
   */
  type: AgendaType.BASIC;

  /**
   * Agenda
   */
  agenda: BasicAgenda;
}

/**
 * Žádost o zmocnění se specifikacemi
 */
export interface SpecifiedEmpowermentRequest extends EmpowermentRequest {
  /**
   * Typ žádosti o zmocnění
   */
  type: AgendaType.SPECIFIED;

  /**
   * Agenda
   */
  agenda: SpecifiedAgenda;

  /**
   * Seznam žádostí o specifikaci zmocnění
   */
  specificationRequests: EmpowermentSpecificationRequest[];
}

export type EmpowermentRequestUnion =
  | BasicEmpowermentRequest
  | SpecifiedEmpowermentRequest;

export interface ResolveEmpowermentDto {
  subject?: Subject;
  resolveState?: 'APPROVE' | 'REJECT';
  approvedSpecifications?: EmpowermentSpecificationRequest[];
  rejectedSpecifications?: EmpowermentSpecificationRequest[];
}

/**
 * Identifikátor subjektu
 */
export interface ForeignSubjectInfo {
  /**
   * Název (pro zahraniční právnické osoby)
   */
  name?: string;

  /**
   * Jméno (pro zahraniční fyzické osoby)
   */
  firstName?: string;

  /**
   * Přijmení (pro zahraniční fyzické osoby)
   */
  lastName?: string;

  /**
   * Stát
   */
  country?: DictionaryCountry;

  /**
   * Obec
   */
  municipality?: string;

  /**
   * Ulice
   */
  street?: string;

  /**
   * PSČ (ZIP code)
   */
  zip?: string;

  /**
   * Zahraniční identifikátor (obdoba IČO)
   */
  foreignId?: string;

  /**
   * Pověření k zastupování
   */
  powerOfAttorney?: ArchivedFile;

  /**
   * Výpis z živnostenského nebo obchodního rejstříku (nebo podobný dokument)
   */
  registerExtract?: ArchivedFile;

  /**
   * Kopie dokladu totožnosti
   */
  identityDocumentCopy?: ArchivedFile;
}

import React from 'react';
import { Process } from '../../components/process/process';
import { useOvzFacilityNew } from './ovz-facility-new-hook';
import { ProcessForm } from './ovz-facility-new-types';
import { TranslationKeys } from '../../enums';

export function OvzFacilityNew() {
  const { steps } = useOvzFacilityNew();

  return (
    <Process<ProcessForm>
      steps={steps}
      title="Registrace provozovny OVZ"
      translationKey={TranslationKeys.OVZ_FACILITY_NEW}
    />
  );
}

import React from 'react';
import { DetailToolbarProps, DetailToolbar2 } from '@eas/common-web';
import { RoleInstance } from '../../models';
import { useSaveDialog } from './dialog-actions/save-hook';

export function RoleInstancesToolbar(props: DetailToolbarProps<RoleInstance>) {
  const { handleSave } = useSaveDialog();

  return <DetailToolbar2 {...props} handleSave={handleSave} />;
}

import React, { useRef, useContext } from 'react';
import * as Yup from 'yup';
import { unstable_batchedUpdates } from 'react-dom';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  FormTextArea,
  FormDateField,
} from '@eas/common-web';
import { SubjectRegistration } from '../../../models';
import { EvidenceAPI, Messages } from '../../../enums';
import { parseISO, isBefore, format } from 'date-fns';
import { useIntl } from 'react-intl';

/**
 * Api call
 *
 * @param id
 */
function callApi(id: string, values: { date: string; note?: string }) {
  console.log(values.date);
  return abortableFetch(`${EvidenceAPI.SUBJECT_REQUESTS}/${id}/reject`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      note: `${format(parseISO(values.date), 'dd.MM.yyyy')} ${values.note}`,
    }),
  });
}

/**
 * Reject subject registration.
 */
const PROMPT_KEY = 'REJECT';

export function useRejectDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext<DetailHandle<SubjectRegistration>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const intl = useIntl();
  const { testPrompt } = useContext(PromptContext);
  const { reload } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Zamítnout registraci',
        dialogText: 'Skutečně chcete zamítnout registraci subjektu?',
        FormFields: function Body() {
          return (
            <>
              <FormDateField
                name="date"
                label="Datum"
                helpLabel="Nesmí být starší než datum vytvoření registrace"
                minDatePicker={source.data?.created}
              />
              <FormTextArea name="note" label="Poznámka" />
            </>
          );
        },
        formValidationSchema: Yup.object().shape({
          date: Yup.date()
            .nullable()
            .required('Povinné pole')
            .test('', '', function (dateValue) {
              const { createError, path } = this;
              if (source.data?.created && dateValue) {
                const minDate = parseISO(source.data?.created);
                minDate.setHours(0, 0, 0, 0);
                if (isBefore(dateValue, minDate)) {
                  return createError({
                    path,
                    message: `Datum musí být po datumu vytvoření registrace - ${intl.formatDate(
                      minDate
                    )}`,
                  });
                }
              }
              return true;
            }),
        }),
        formInitialValues: {
          // Prefill today's date to FormDateField
          date: new Date().toISOString().slice(0, 10),
        },
        dialogWidth: 600,
      },
    ],
    [source.data?.created]
  );

  const handleReject = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { date: string; note?: string }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(source.data!.id, values);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.SubjectRegistration.REJECT.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.SubjectRegistration.REJECT.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  return {
    handleReject,
  };
}

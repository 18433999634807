import React, { useContext } from 'react';
import { AgendaSpecification } from '../../models';
import {
  useFormSelector,
  TableFieldCellProps,
  FormContext,
  Checkbox,
} from '@eas/common-web';
import { get } from 'lodash';

export function checkBoxCellFactory(name: string) {
  return function CheckBoxCell({
    rowValue,
  }: TableFieldCellProps<AgendaSpecification>) {
    const { setFieldValue, editing } = useContext(FormContext);
    const { specifications } = useFormSelector(
      (data: { [key: string]: AgendaSpecification[] }) => ({
        specifications: get(data, name),
      })
    );
    const isActive = (specifications ?? []).find((s) => s.id === rowValue.id);

    const handleChange = () => {
      if (isActive) {
        setFieldValue(
          name,
          specifications?.filter((s) => s.id !== rowValue.id)
        );
      } else {
        setFieldValue(name, [...(specifications ?? []), rowValue]);
      }
    };

    return (
      <Checkbox
        value={!!isActive}
        onChange={handleChange}
        disabled={!editing}
      />
    );
  };
}

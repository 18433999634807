import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useAccessTypes, useDataSources, useStates } from './public-lists-api';
import { useRoles } from '../roles/roles-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const accessTypes = useAccessTypes();
  const dataSources = useDataSources();
  const states = useStates();
  const roles = useRoles();

  return [
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platný od',
    },
    {
      key: 'validTo',
      label: 'Platný do',
    },
    {
      key: 'code',
      label: 'Kód',
    },
    {
      key: 'accessType',
      label: 'Omezení přístupu',
      source: accessTypes,
    },
    {
      key: 'allowedRoles',
      label: 'Role',
      source: roles,
    },
    {
      key: 'dataSource',
      label: 'Datový zdroj',
      source: dataSources,
    },
    {
      key: 'updateInterval',
      label: 'Interval aktualizace',
    },
    {
      key: 'state',
      label: 'Stav',
      source: states,
    },
    {
      key: 'publisher',
      label: 'Vydavatel',
    },
    {
      key: 'firstPublishedDate',
      label: 'První publikace',
    },
    {
      key: 'lastPublishedDate',
      label: 'Poslední publikace',
    },
  ];
}

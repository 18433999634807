import React from 'react';
import { WebService } from '../../models';
import {
  Evidence2,
  listItemsFactory,
  abortableFetch,
  Source,
} from '@eas/common-web';
import { PageKey, EvidenceAPI } from '../../enums';
import { useColumns } from './web-services-columns';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { WebServicesFields } from './web-services-fields';
import { stubFalse } from 'lodash';
import { getItemFactory } from '../../utils/custom-crud';
import { combineGetCalls } from './web-services-api';

type EasListPostProcess = (
  data: Source<WebService>
) => Promise<Source<WebService>>;

export function WebServices() {
  const evidence = useDatedEvidence<WebService>({
    identifier: PageKey.WEB_SERVICES,
    version: 3,
    apiProps: {
      url: EvidenceAPI.WEB_SERVICES,
      getItem: getItemFactory({ combineGetCalls }),
      listItems: listItemsFactory<WebService>({
        listItemsMethod: (url) =>
          abortableFetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }),
        // ts hack: list post process is not implemented to handle other type then `ResultDto` extended with `Source`
        postProcess: (((items: WebService[]) => ({
          items: items,
          count: items.length,
        })) as unknown) as EasListPostProcess,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled webových služeb',
      showFilterButton: false,
      showReportButton: false,
      showBulkActionButton: false,
      showColumn: (column) => {
        if (column === 'CREATED' || column === 'UPDATED') {
          return false;
        }
        return true;
      },
    },
    detailProps: {
      FieldsComponent: WebServicesFields,
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Webová služba',
        showBtn: stubFalse,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useSlate } from 'slate-react';
// utils
import { isMarkActive } from '../utils/is-mark-active';
import { toggleMark } from '../utils/toggle-mark';
import { useStyles } from './button-styles';
// types
import { TextFormat } from '../rich-editor-types';

export function MarkButton({
  format,
  icon,
}: {
  format: TextFormat;
  icon: ReactNode;
}) {
  const classes = useStyles();
  const editor = useSlate();

  const active = isMarkActive(editor, format);

  return (
    <button
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      className={clsx(classes.button, { [classes.active]: active })}
    >
      {icon}
    </button>
  );
}

import React, { useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import {
  FormFieldWrapper,
  FormTextField,
  useFormSelector,
  FormContext,
  useEventCallback,
  DetailContext,
  DetailMode,
} from '@eas/common-web';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';
import { Address } from '../../../../models';
import { HelpLabel } from '../../../help/help-label';

export function OrientationNumberMarkCell() {
  const { code, name, disabled, isRuian } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);
  const { setFieldValue } = useContext(FormContext);
  const detailCtx = useContext(DetailContext);

  const { ruianCitySelected, addressPlaceRuian } = useFormSelector(
    (data: { [index: string]: Address | undefined }) => ({
      ruianCitySelected: get(data, name)?.municipalityRuian?.id,
      addressPlaceRuian: get(data, name)?.addressPlaceRuian,
    })
  );

  const isDisabled = disabled || (isRuian && !ruianCitySelected);

  const notifyChange = useEventCallback(() => {
    if (addressPlaceRuian?.id) {
      unstable_batchedUpdates(() => {
        setFieldValue(`${name}.addressPlaceRuian`, undefined);
        setFieldValue(`${name}.zip`, undefined);
      });
    }
  });

  return (
    <>
      {!isDisabled && (!detailCtx || detailCtx.mode !== DetailMode.VIEW) && (
        <FormFieldWrapper
          disabled={isDisabled}
          required={false}
          label={
            <HelpLabel
              label={
                defaults?.orientationNumberMark?.label ??
                'Znak čísla orientačního'
              }
              required={defaults?.orientationNumberMark?.required ?? false}
              code={`${code}_ORIENTATION_NUMBER_MARK`}
            />
          }
          labelOptions={{}}
          layoutOptions={{}}
          errorOptions={{}}
        >
          <Grid item xs={3}>
            <FormTextField
              disabled={isDisabled}
              notifyChange={notifyChange}
              name="orientationNumberMark"
              labelOptions={{ hide: true }}
              layoutOptions={{ noUnderline: true, noSpacing: true }}
            />
          </Grid>
        </FormFieldWrapper>
      )}
    </>
  );
}

import { TableColumn, TableCells } from '@eas/common-web';
import {
  PublicRecord,
  PublicListParams,
  DynamicFieldType,
} from '../../../models/public-list';

export function useColumns(
  params: PublicListParams
): TableColumn<PublicRecord>[] {
  const columns: TableColumn<PublicRecord>[] = [];

  for (const column of params.columns) {
    columns.push({
      datakey: `data.${column.datakey}`,
      name: column.name,
      CellComponent: fieldTypeToCellComponent(column.type),
      width: column.width ?? 200,
      sortable: column.sortable ?? true,
      filterable: column.filterable ?? true,
      filterGroup: column.filterGroup ?? 1,
    });
  }

  return columns;
}

function fieldTypeToCellComponent(type: DynamicFieldType) {
  switch (type) {
    case DynamicFieldType.TEXT:
      return TableCells.TextCell;
    case DynamicFieldType.NUMBER:
      return TableCells.NumberCell;
    case DynamicFieldType.BOOLEAN:
      return TableCells.BooleanCell;
    case DynamicFieldType.DATE:
      return TableCells.DateCell;
    case DynamicFieldType.DATETIME:
      return TableCells.DateTimeCell;
    case DynamicFieldType.TIME:
      return TableCells.TimeCell;
  }
}

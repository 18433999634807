import { RuianObject } from '../../../models';
import { inactiveColoredFactory } from '../../form/inactive-colored/inactive-colored';
import {
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { RuianEvidenceProps } from './ruian-types';

export function createColumns<OBJECT extends RuianObject>({
  columns = [],
  showColumn = () => true,
  columnLabelMapper = () => undefined,
  columnWrapper = inactiveColoredFactory,
}: RuianEvidenceProps<OBJECT>['tableProps']) {
  return [
    ...(showColumn('ISUICODE')
      ? [
          {
            datakey: 'isuiCode',
            name: columnLabelMapper('ISUICODE') ?? 'Kód v ISÚI',
            width: 150,
            CellComponent: columnWrapper(TableCells.NumberCell),
            FilterComponent: TableFilterCells.FilterNumberCell,
            sortable: true,
            filterable: true,
          },
        ]
      : []),
    ...(showColumn('NAME')
      ? [
          {
            datakey: 'name',
            name: columnLabelMapper('NAME') ?? 'Název',
            width: 150,
            CellComponent: columnWrapper(TableCells.TextCell),
            FilterComponent: TableFilterCells.FilterTextCell,
            filterOperation: ApiFilterOperation.CONTAINS,
            sortable: true,
            filterable: true,
          },
        ]
      : []),
    ...columns,
  ];
}

import React from 'react';
import { StepAuthorForm } from './step-author-types';
import { AuthorRole } from '../../../models';
import { ProcessForm } from '../empowerment-requests-types';

export function StepAuthorLabel({
  stepValues,
  allValues,
}: {
  stepValues?: StepAuthorForm;
  allValues?: ProcessForm;
}) {
  const authorRole = allValues?.authorRole;

  const label =
    authorRole === AuthorRole.EMPOWERER
      ? 'zmocnitele'
      : authorRole === AuthorRole.AGENT
      ? 'zmocněnce'
      : 'subjektu';

  const subjectLabel = stepValues?.author?.label;

  return (
    <>
      Výběr {label}: {subjectLabel && <b>{subjectLabel}</b>}
    </>
  );
}

import { useRef, useContext } from 'react';
import {
  useEventCallback,
  AbortableFetch,
  abortableFetch,
  usePrompts,
  PromptContext,
} from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
function callApi(id: string) {
  return abortableFetch(`${EvidenceAPI.NOTIFICATION_RECEIVED}/${id}`, {
    method: 'DELETE',
  });
}

const PROMPT_KEY = 'DELETE_NOTIFICATION';

export function useDelete({ refresh }: { refresh: () => void }) {
  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Varování',
      dialogText: 'Skutečně chcete smazat notifikaci?',
    },
  ]);

  const handleDelete = useEventCallback(async (id: string) => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(id);

          await fetch.current.raw();

          refresh();
        } catch (err) {
          if (err.name !== 'AbortError') {
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  return { handleDelete };
}

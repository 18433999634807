import React from 'react';
import { Process } from '../../components/process/process';
import { useEmpowermentRequests } from './empowerment-requests-hook';
import { ProcessForm } from './empowerment-requests-types';
import { TranslationKeys } from '../../enums';

export function EmpowermentRequestsNew() {
  const { steps } = useEmpowermentRequests();

  return (
    <Process<ProcessForm>
      steps={steps}
      title="Žádost o zmocnění"
      translationKey={TranslationKeys.EMPOWERMENT_REQUEST}
    />
  );
}

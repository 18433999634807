import React from 'react';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TelegramIcon from '@material-ui/icons/Telegram';
import { DetailToolbarButtonMenuItem } from '@eas/common-web';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon';
import { useRedirect } from './actions/redirect-hook';
import { DetailToolbarButtonMenu } from '../../components/evidence/evidence/detail-toolbar-button-menu';

type IconType = OverridableComponent<
  SvgIconTypeMap<Record<string, unknown>, 'svg'>
>;

function useToolbarItems() {
  /**
   * Array of SUBJECT ACTIONS.
   */
  const actionItems: DetailToolbarButtonMenuItem[] = [];

  const addAction = (
    label: string,
    Icon: IconType,
    onClick: () => void,
    warning?: boolean
  ) =>
    actionItems.push({
      label,
      Icon: <Icon fontSize="small" htmlColor="black" />,
      onClick,
      warning: !!warning,
    });

  /**
   * Redirect actions.
   */
  const {
    showRedirect,
    handleRedirect,
    handleAllSubjects,
    handleNewRegistration,
  } = useRedirect();

  addAction('Nová registrace subjektu', PlusOneIcon, handleNewRegistration);
  if (showRedirect) {
    addAction('Navázaný subjekt', TelegramIcon, handleRedirect);
  }
  addAction('Všechny subjekty', ListAltIcon, handleAllSubjects);

  return {
    actionItems,
  };
}

export function SubjectRequestToolbar() {
  const { actionItems } = useToolbarItems();

  return (
    <>
      <DetailToolbarButtonMenu label="Otevřít" items={actionItems} />
    </>
  );
}

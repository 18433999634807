import React, { useContext, useEffect } from 'react';
// import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  initialBreadcrumbs,
  BreadcrumbCtx,
} from '../../components/breadcrumb/breadcrumb-ctx';

// const useStyles = makeStyles(() =>
//   createStyles({
//     todo: {},
//   })
// );

export function Homepage() {
  const { setBreadcrumbs } = useContext(BreadcrumbCtx);

  useEffect(() => {
    setBreadcrumbs(initialBreadcrumbs.breadcrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { NotificationTemplate } from '../../models';
import { useNotificationEvents } from '../notification-templates/notification-templates-api';

export function useColumns(): TableColumn<NotificationTemplate>[] {
  return [
    {
      datakey: 'event',
      sortkey: 'event.name',
      filterkey: 'event.id',
      name: 'Událost',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useNotificationEvents
      ),
      valueMapper: TableCells.useSelectCellFactory(useNotificationEvents),
      sortable: true,
      filterable: true,
      filterGroup: 10,
    },
  ];
}

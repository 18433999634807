import {
  useStaticListSource,
  DictionaryAutocomplete,
  abortableFetch,
} from '@eas/common-web';
import { MailCampaignTestDto, ImportDto } from '../../models/mail-campaign';

export function useStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'CONCEPT', name: 'Koncept' },
    { id: 'RUNNING', name: 'Spuštěná' },
    { id: 'FINISHED', name: 'Ukončená' },
    { id: 'CANCELED', name: 'Zrušená' },
  ]);
}

export function run(id: string) {
  return abortableFetch(`/api/crzp/mailing/campaign/${id}/run`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}

export function stop(id: string) {
  return abortableFetch(`/api/crzp/mailing/campaign/${id}/stop`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}

export function test(id: string, dto: MailCampaignTestDto) {
  return abortableFetch(`/api/crzp/mailing/campaign/${id}/test`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}

export function importCsv(id: string, dto: ImportDto) {
  return abortableFetch(`/api/crzp/mailing/campaign/${id}/import`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}

export function clear(id: string) {
  return abortableFetch(`/api/crzp/mailing/campaign/${id}/clear`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}

export function exportCsv(id: string) {
  return abortableFetch(`/api/crzp/mailing/campaign/${id}/export`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}

export function checkEmails(id: string) {
  return abortableFetch(`/api/crzp/mailing/campaign/${id}/check-emails`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}

import { Exception } from '../../models';
import { CombineCallsFunction } from './custom-crud-types';
import { post } from './post';

export function createItemFactory<TYPE>({
  preprocessCall = async (item) => item,
  postprocessCall = (data, response) => {
    if (!response?.ok) {
      throw { message: (data as Exception).message ?? 'Uložení selhalo.' };
    }
  },
  combineGetCalls = async ({ mainItem }) => ({ data: mainItem, text: '' }),
}: {
  preprocessCall?: (item: TYPE) => Promise<any>;
  postprocessCall?: (item: TYPE | Exception, response: Response) => void;
  combineGetCalls?: CombineCallsFunction;
}) {
  return function createItem(api: string, item: TYPE) {
    const controller = new AbortController();
    const signal = controller.signal;

    return {
      abort: () => controller.abort(),
      response: new Promise<Response>(() => {}),
      json: async () => {
        const processedItem = await preprocessCall(item);

        const [json, response] = await post<TYPE | Exception>({
          url: `${api}`,
          body: processedItem,
          signal,
        });

        postprocessCall(json!, response!);

        const { data } = await combineGetCalls({
          api,
          itemId: (json as any).id,
          signal,
          mainItem: json,
        });

        return data;
      },
      // not needed
      raw: async () => new Promise<Response>(() => {}),
      text: async () => {},
      none: async () => {},
    };
  };
}

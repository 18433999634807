import React, { useMemo, ComponentType } from 'react';
import { useEventCallback } from '@eas/common-web';
import { useMarkerData } from '../../../components/map/hook/marker-data-hook';
import { fetchProductionSite } from '../production-sites-api';
import {
  ProductionSiteMarkerTitle,
  ProductionSiteMarkerBody,
} from '../production-sites-map';
import { IrzFacility } from '../../../models';

export function useCardData(
  setActive: (id: string) => void,
  Actions?: ComponentType<{ data: IrzFacility }>
) {
  const { data } = useMarkerData<IrzFacility>({
    getData: fetchProductionSite,
  });

  const handleSetActive = useEventCallback((id: string) => {
    setActive(id);
  });

  const cardData = useMemo(() => {
    return {
      title: <ProductionSiteMarkerTitle name={data?.name ?? ''} />,
      body: (
        <ProductionSiteMarkerBody
          data={data!}
          setActive={handleSetActive}
          Actions={Actions}
        />
      ),
    };
  }, [data, handleSetActive]);

  return { cardData };
}

import { Street } from '../../models';
import { TableColumn, TableCells, ApiFilterOperation } from '@eas/common-web';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export const useColumns = (): TableColumn<Street, any>[] => {
  const columns = [
    {
      datakey: 'municipality.name',
      name: 'Obec',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory<Street>(TableCells.TextCell),

      filterable: true,
      sortable: true,
    },
  ];

  return columns;
};

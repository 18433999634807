import { DictionaryMineralGroup } from './../../models/dict-mineral-group';
import * as Yup from 'yup';
import {
  ProtectedDepositArea,
  AreaType,
  DictionaryDeposit,
} from './../../models';

export function useValidationSchema() {
  return Yup.object<ProtectedDepositArea>().shape({
    type: Yup.mixed<AreaType>().nullable().required(),
    name: Yup.string().nullable().required(),
    area: Yup.number().nullable().required(),
    minerals: Yup.string().when('type', {
      is: AreaType.CHLU,
      then: Yup.string().nullable().required(),
    }),
    mineralGroups: Yup.array<DictionaryMineralGroup>().when('type', {
      is: AreaType.CHLU,
      then: Yup.array<DictionaryMineralGroup>()
        .nullable()
        .min(1, 'Povinné pole')
        .required(),
    }),
    deposits: Yup.array<DictionaryDeposit>().when('type', {
      is: AreaType.CHLU,
      then: Yup.array<DictionaryDeposit>()
        .nullable()
        .min(1, 'Povinné pole')
        .required(),
    }),
    referenceNumber: Yup.string().nullable().required(),
    dateIssued: Yup.string().nullable().required(),
    dateLegalForce: Yup.string().nullable().required(),
    coordinates: Yup.string()
      .nullable()
      .required(
        'Souřadnice musí být vyplněny - použijte "Upravit souřadnice" nad mapou.'
      ),
  });
}

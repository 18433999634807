import { useContext, useEffect } from 'react';
import { GeometryLayerContext } from './map-ctx';
import { GeometryEnum, GeometryOptions } from './map-types';

interface PathProps {
  id?: string;
  // Components of path based on https://www.w3.org/TR/SVG11/paths.html specification
  path: any[];
  options?: GeometryOptions;
}

export function Path({ id, path, options = {} }: PathProps) {
  const geometryLayer = useContext(GeometryLayerContext);
  useEffect(() => {
    const sPath = new window.SMap.Geometry(
      GeometryEnum.PATH,
      id ?? false,
      path,
      options
    );

    geometryLayer?.addGeometry(sPath);

    return () => {
      geometryLayer?.removeGeometry(sPath);
    };
  }, [geometryLayer, id, path, options]);

  return null;
}

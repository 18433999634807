import * as Yup from 'yup';
import { FileRef } from '@eas/common-web';
import {
  DictionaryDirpType,
  DictionaryDirpAuthor,
  DirpRecord,
  DictionaryLaw,
  DictionaryDirpArea,
} from './../../models';

export function useValidationSchema() {
  return Yup.object<DirpRecord>().shape({
    referenceNumber: Yup.string().nullable().required('Povinné pole'),
    type: Yup.mixed<DictionaryDirpType>().nullable().required('Povinné pole'),
    laws: Yup.array<DictionaryLaw>()
      .nullable()
      .min(1, 'Povinné pole')
      .required(),
    areas: Yup.array<DictionaryDirpArea>()
      .nullable()
      .min(1, 'Povinné pole')
      .required(),
    author: Yup.mixed<DictionaryDirpAuthor>()
      .nullable()
      .required('Povinné pole'),
    issuedDate: Yup.string().nullable().required('Povinné pole'),
    verdict: Yup.mixed<FileRef>().nullable().required('Povinné pole'),
  });
}

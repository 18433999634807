import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { eqFilterParams } from '@eas/common-web';
import { useAgendas } from '../agenda/agendas-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const params = eqFilterParams({ field: 'empowerable', value: true });
  const agendas = useAgendas(params);

  return [
    {
      key: 'type',
      label: 'Typ',
    },
    {
      key: 'empowerer',
      label: 'Zmocnitel',
    },
    {
      key: 'agent',
      label: 'Zmocněnec',
    },
    {
      key: 'agenda',
      label: 'Agenda',
      source: agendas,
    },
    {
      key: 'specifications',
      label: 'Typ hlášení',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platnost od',
    },
    {
      key: 'validTo',
      label: 'Platnost do',
    },
    {
      key: 'empowermentDocument',
      label: 'Příloha (plná moc konvertovaná)',
    },
  ];
}

import React, { useContext } from 'react';
import { parseISO, formatISO, format } from 'date-fns';
import { LocaleContext, Tooltip } from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import { LocalDateTimeHistoryField as LocalDateTimeHistoryFieldProps } from '../../../../models';
import { useStyles } from './history-field-styles';
import { HistoryFieldProps } from '../history-types';
import { useHistoryFieldTooltip } from './history-field-tooltip-hook';

export function parseISOTime(time: string) {
  const now = new Date();
  const dateTime = formatISO(now, { representation: 'date' }) + 'T' + time;
  return parseISO(dateTime);
}

export function LocalDateTimeHistoryField({
  valueAfter,
  valueBefore,
}: HistoryFieldProps<LocalDateTimeHistoryFieldProps>) {
  const classes = useStyles();

  const { locale } = useContext(LocaleContext);

  const parsedBefore = valueBefore?.value
    ? format(parseISO(valueBefore.value), locale.dateTimeFormat)
    : undefined;

  const parsedAfter = valueAfter?.value
    ? format(parseISO(valueAfter.value), locale.dateTimeFormat)
    : undefined;

  const {
    ref: afterRef,
    useTooltip: useAfterTooltip,
  } = useHistoryFieldTooltip();

  const {
    ref: beforeRef,
    useTooltip: useBeforeTooltip,
  } = useHistoryFieldTooltip();

  const beforeContent = (
    <span className={classes.textDeleted} ref={beforeRef}>
      - {parsedBefore}
    </span>
  );

  const afterContent = (
    <span className={classes.textAdded} ref={afterRef}>
      + {parsedAfter}
    </span>
  );

  return (
    <Typography variant="body1" className={classes.content}>
      {parsedBefore &&
        (useBeforeTooltip ? (
          <Tooltip title={parsedBefore} placement="top-start" type="HOVER">
            - {beforeContent}
          </Tooltip>
        ) : (
          beforeContent
        ))}
      {parsedAfter &&
        (useAfterTooltip ? (
          <Tooltip title={parsedAfter} placement="top-start" type="HOVER">
            + {afterContent}
          </Tooltip>
        ) : (
          afterContent
        ))}
    </Typography>
  );
}

import React from 'react';
import { FormPanel, FormTextArea, FormDateField } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';

export function StepOtherInformationContent() {
  return (
    <FormPanel label="Ostatní informace">
      <FormTextArea
        name="note"
        label={
          <HelpLabel
            label="Poznámka"
            code="OVZ_FACILITY_REQUEST_OPERATOR_CHANGE_NOTE"
          />
        }
      />
      <FormDateField
        name="date"
        label={
          <HelpLabel
            label="Datum odkdy změna platí"
            code="OVZ_FACILITY_REQUEST_OPERATOR_CHANGE_DATE"
          />
        }
      />
    </FormPanel>
  );
}

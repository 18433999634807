import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      color: 'white !important',
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    button: {
      backgroundColor: '#dedede',
      color: '#000',
      border: 0,
      display: 'inline-flex',
      width: 30,
      height: 23,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 5,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#d5d5d5',
      },
    },
    disabled: {
      backgroundColor: 'inherit',
      cursor: 'default',
      '& svg': {
        fill: '#dedede',
      },
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  })
);

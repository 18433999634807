import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { ProtectedDepositArea } from './../../models';
import {
  useChluStates,
  useAreaTypes,
  useChluRuianStates,
} from './protected-deposit-areas-api';
import { useOvss } from '../dict-ovss/dict-ovss-api';

export function useColumns(): TableColumn<ProtectedDepositArea>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'number',
      name: 'Číslo CHLÚ',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'referenceNumber',
      name: 'Číslo jednací',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'ruianEntryTime',
      name: 'Datum zapsání do RÚIAN',
      width: 200,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'dateIssued',
      name: 'Datum vydání rozhodnutí',
      width: 200,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'dateLegalForce',
      name: 'Datum nabytí právní moci',
      width: 200,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'ruianCode',
      name: 'RÚIAN kód CHLÚ',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useChluStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useChluStates
      ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'ruianState',
      sortkey: 'ruianState.name',
      filterkey: 'ruianState.id',
      name: 'Stav RÚIAN',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useChluRuianStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useChluRuianStates
      ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useAreaTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useAreaTypes
      ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'ovss',
      sortkey: 'ovss.name',
      filterkey: 'ovss.id',
      name: 'Odbor výkonu státní správy',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useOvss
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'note',
      name: 'Poznámka',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
      filterGroup: 1,
    },
    {
      datakey: 'ruianDescription',
      name: 'Popis pro RÚIAN',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
      filterGroup: 1,
    },
  ];
}

import React, { useEffect, useContext, useMemo } from 'react';
import {
  FormAutocomplete,
  FormPanel,
  FormInlineTableField,
  useFormSelector,
  FormContext,
  FormCheckbox,
} from '@eas/common-web';
import { useNaceItems } from '../../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import { useCzRiverBasins } from '../../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { useEuRiverBasins } from '../../dict-river-basin-eu-district/dict-river-basin-eu-district-api';

import { useIppcPidColumns } from './step-identificators-columns';
import {
  DictionaryNaceCzAutocomplete,
  DictionaryNaceAutocomplete,
} from '../../../models';
import {
  dictionaryLabelMapper,
  dictionaryNoCodeLabelMapper,
} from '../../../components/form/mappers/label-mappers';
import { useStepIdentificators } from './step-identificators-hook';
import { ProcessForm } from '../irz-facility-split-types';
import { HelpLabel } from '../../../components/help/help-label';
import { StepIdentificatorsForm } from './step-identificators-types';
import { EvidenceAPI } from '../../../enums';

export function StepIdentificatorsContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  useStepIdentificators(allValues);
  const { setFieldValue } = useContext(FormContext);
  const naceItems = useNaceItems();
  const naceCzItems = useNaceCzItems();
  const czRiverBasins = useCzRiverBasins(
    EvidenceAPI.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS
  );
  const euRiverBasins = useEuRiverBasins();

  const ippcPidColumns = useIppcPidColumns();

  const {
    czRiverBasinDistrict,
    czNace,
    hasIntegratedPermission,
  } = useFormSelector((data: StepIdentificatorsForm) => ({
    czRiverBasinDistrict: data?.identificators?.czRiverBasinDistrict,
    czNace: data?.identificators?.czNace,
    hasIntegratedPermission:
      data?.identificators?.integratedPermission?.hasPermission,
  }));

  useEffect(() => {
    if (allValues.identificators?.czRiverBasinDistrict !== czRiverBasinDistrict)
      setFieldValue(
        'identificators.euRiverBasinDistrict',
        czRiverBasinDistrict?.euRiverBasinDistrict
      );
  }, [czRiverBasinDistrict]);

  useEffect(() => {
    setFieldValue('identificators.nace', czNace?.nace);
  }, [czNace]);

  const IppcIds = useMemo(
    () =>
      function IppcIds() {
        return (
          <FormInlineTableField<string>
            name="identificators.integratedPermission.ippcPids"
            label={
              <HelpLabel
                label="Seznam PID (IPPC)"
                code="IRZ_FACILITY_SPLIT_IDENTIFICATORS_IPPC_PIDS"
                required
              />
            } // Povinné pole, musí začínat písmeny 'MZP' a následovat 9 velkých alfanumerických znaků
            columns={ippcPidColumns}
            initNewItem={() => ''}
          />
        );
      },
    []
  );

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete<DictionaryNaceCzAutocomplete>
        name="identificators.czNace"
        label={
          <HelpLabel
            label="CZ-NACE"
            code="IRZ_FACILITY_SPLIT_IDENTIFICATORS_CZ_NACE"
            required
          />
        } // Povinné pole
        source={naceCzItems}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
      />
      <FormAutocomplete<DictionaryNaceAutocomplete>
        name="identificators.nace"
        label={
          <HelpLabel
            label="NACE"
            code="IRZ_FACILITY_SPLIT_IDENTIFICATORS_NACE"
            required
          />
        } // Povinné pole
        source={naceItems}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
        disabled={true}
      />
      <FormAutocomplete
        name="identificators.czRiverBasinDistrict"
        label={
          <HelpLabel
            label="Oblast povodí ČR"
            code="IRZ_FACILITY_SPLIT_IDENTIFICATORS_CZ_RIVER_BASIN_DISTRICT"
            required
          />
        } // Povinné pole
        source={czRiverBasins}
        tooltipMapper={dictionaryNoCodeLabelMapper}
        labelMapper={dictionaryNoCodeLabelMapper}
      />
      <FormAutocomplete
        name="identificators.euRiverBasinDistrict"
        label={
          <HelpLabel
            label="Oblast povodí Evropy"
            code="IRZ_FACILITY_SPLIT_EU_RIVER_BASIN_DISTRICT"
            required
          />
        } // Povinné pole
        source={euRiverBasins}
        labelMapper={dictionaryNoCodeLabelMapper}
        tooltipMapper={dictionaryNoCodeLabelMapper}
      />
      <FormCheckbox
        name="identificators.integratedPermission.hasPermission"
        label="Provozovna/Zařízení má Integrované povolení"
      />
      {hasIntegratedPermission && <IppcIds />}
    </FormPanel>
  );
}

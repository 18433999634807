import { PersonalData } from './personal-data';
import { PhoneNumber } from './phone-number';

/**
 * Typ preferovaného způsobu ověření
 */
export enum PreferredChannelType {
  /**
   * E-mail
   */
  EMAIL = 'EMAIL',

  /**
   * Telefonní číslo
   */
  PHONE = 'PHONE',
}

/**
 * Detaily dvoufaktorové autentizace
 */
export interface TwoFactorDetails {
  /**
   * E-mail
   */
  email?: PersonalData;

  /**
   * Telefonní číslo
   */
  phoneNumber?: PhoneNumber;

  /**
   * Preferovaný způsob ověření
   */
  preferredChannel?: PreferredChannelType;
}

import React from 'react';
import { Evidence2, ApiFilterOperation } from '@eas/common-web';
import { BoilerManufacturer } from '../../models';
import { BoilerManufacturersFields } from './boiler-manufacturers-fields';
import { useColumns } from './boiler-manufacturers-columns';
import { useValidationSchema } from './boiler-manufacturers-schema';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { BoilerManufacturersActionbar } from './boiler-manufacturers-actionbar';
import { EntityType } from '../../enums/entity-type';
import { BoilerManufacturersTableToolbar } from './boiler-manufacturers-table-toolbar';
import { useHistoryDescriptors } from './boiler-manufacturers-history';

export function BoilerManufacturers() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<BoilerManufacturer>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<BoilerManufacturer>({
    identifier: PageKey.BOILER_MANUFACTURERS,
    noteEntityType: EntityType.BOILER_MANUFACTURER,
    apiProps: {
      url: EvidenceAPI.BOILER_MANUFACTURERS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled výrobců kotlů',
      reportTag: ExportTags.BOILER_MANUFACTURERS,
      defaultSorts: [],
      toolbarProps: {
        after: <BoilerManufacturersTableToolbar />,
      },
      defaultPreFilters: [
        {
          field: 'active',
          operation: ApiFilterOperation.EQ,
          value: 'true',
        },
      ],
    },
    detailProps: {
      entityName: EntityName.BOILER_MANUFACTURER,
      FieldsComponent: BoilerManufacturersFields,
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Výrobce kotlů',
        showBtn: permissions,
        ActionBar: BoilerManufacturersActionbar,
      },
      validationSchema,
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Ozo.OZO_MANUFACTURER_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}

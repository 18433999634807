import React, { useContext } from 'react';
import {
  FormPanel,
  FormTextArea,
  FormSelect,
  FormTableField,
  FormFieldContext,
  DetailContext,
  DetailMode,
  TextArea,
  useFormSelector,
  FormTextField,
  FormCustomField,
  TextField,
} from '@eas/common-web';
import { useDictionaryBoilerTypes } from '../dict-boiler-type/dict-boiler-types-api';
import { SubjectFormPanel } from '../../composite/subject-fields/subject-form-panel';
import { BoilerBrandsToolbar } from './boiler-brands-toolbar';
import { BoilerModelsToolbar } from './boiler-models-toolbar';
import { useBoilerBrandsColumns } from './boiler-brands-columns';
import { useBoilerModelsColumns } from './boiler-models-columns';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import { AuthoredFields } from '../../components/evidence/authored-evidence/authored-fields';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { BoilerManufacturer, SubjectType } from '../../models';
import { useDocumentTypes } from '../subject/subjects-api';
import { HelpLabel } from '../../components/help/help-label';
import { Permission } from '../../enums';

export function BoilerManufacturersFields(props: NotedEvidenceFieldsProps) {
  const { mode } = useContext(DetailContext);

  const boilerTypes = useDictionaryBoilerTypes();
  const boilerBrandsColumns = useBoilerBrandsColumns();
  const boilerModelsColumns = useBoilerModelsColumns();
  const documentTypes = useDocumentTypes();

  const { subjectApproved, subjectType } = useFormSelector(
    (data: BoilerManufacturer) => ({
      subjectApproved: data.subject?.approved,
      subjectType: data.subject?.type,
    })
  );

  return (
    <>
      <FormPanel label="Základní informace">
        {mode !== DetailMode.NEW && (
          <>
            <FormSelect
              name="allowedBoilerTypes"
              source={boilerTypes}
              label={
                <HelpLabel
                  label="Povolené typy kotlů"
                  code="BOILER_MANUFACTURER_ALLOWED_BOILER_TYPES"
                />
              }
              disabled={true}
              multiple={true}
              DisabledComponent={TextArea}
            />
            <FormSelect
              name="boilerTypes"
              source={boilerTypes}
              label={
                <HelpLabel
                  label="Vyráběné typy kotlů"
                  code="BOILER_MANUFACTURER_BOILER_TYPES"
                />
              }
              disabled={true}
              multiple={true}
              DisabledComponent={TextArea}
            />
          </>
        )}
        <FormTextArea
          name="note"
          label={<HelpLabel label="Poznámka" code="BOILER_MANUFACTURER_NOTE" />}
        />
      </FormPanel>
      {subjectApproved && (
        <SubjectFormPanel
          label="Informace o subjektu"
          codePrefix="BOILER_MANUFACTURER"
          autocomplete={{
            name: 'subject',
            label: 'Subjekt', // Povinné pole
            required: true,
          }}
        />
      )}
      {!subjectApproved && (
        <FormPanel label="Informace o subjektu">
          <>
            {subjectType === SubjectType.LEGAL_ENTITY && (
              <FormTextField
                name="subject.ico"
                label={
                  <HelpLabel
                    label="IČO"
                    code="BOILER_MANUFACTURER_SUBJECT_ICO"
                  />
                }
                disabled
              />
            )}

            {subjectType === SubjectType.BUSINESS_NATURAL_PERSON && (
              <FormTextField
                name="subject.ico.value"
                label={
                  <HelpLabel
                    label="IČO"
                    code="BOILER_MANUFACTURER_SUBJECT_ICO"
                  />
                }
                disabled
              />
            )}

            {subjectType === SubjectType.NATURAL_PERSON && (
              <>
                <FormTextField
                  name="subject.identityDocuments[0].idNumber.value"
                  label={
                    <HelpLabel
                      label="Identifikátor"
                      code="BOILER_MANUFACTURER_SUBJECT_IDENTITY_DOCUMENT_ID_NUMBER"
                    />
                  }
                />
                <FormSelect
                  source={documentTypes}
                  valueIsId={true}
                  name="subject.identityDocuments[0].type"
                  label={
                    <HelpLabel
                      label="Typ identifikátoru"
                      code="BOILER_MANUFACTURER_SUBJECT_IDENTITY_DOCUMENT_TYPE"
                    />
                  }
                />
              </>
            )}
            <FormCustomField>
              <TextField disabled value="Probíhá registrace subjektu" />
            </FormCustomField>
          </>
        </FormPanel>
      )}
      {mode !== DetailMode.NEW && (
        <FormFieldContext.Provider value={{ disabled: false }}>
          <FormPanel
            label="Značky kotlů"
            expandable
            defaultExpanded={false}
            summary={<FormPanelCountByName name="boilerBrands" />}
          >
            <FormTableField
              name="boilerBrands"
              ToolbarComponent={BoilerBrandsToolbar}
              labelOptions={{
                hide: true,
              }}
              layoutOptions={{
                noSpacing: true,
              }}
              maxRows={15}
              showDetailBtnCond={() => false}
              columns={boilerBrandsColumns}
            />
          </FormPanel>
          <FormPanel
            label="Modely kotlů"
            expandable
            defaultExpanded={false}
            summary={
              <FormPanelCountByName
                name="boilerModels"
                columns={boilerModelsColumns}
                permission={Permission.Ozo.OZO_MANUFACTURER_LIST_ALL}
                permissionState={{
                  searchInSubjectPerms: true,
                }}
              />
            }
          >
            <FormTableField
              name="boilerModels"
              ToolbarComponent={BoilerModelsToolbar}
              labelOptions={{
                hide: true,
              }}
              layoutOptions={{
                noSpacing: true,
              }}
              maxRows={15}
              showDetailBtnCond={() => false}
              columns={boilerModelsColumns}
            />
          </FormPanel>
        </FormFieldContext.Provider>
      )}

      {props.Notes}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <AuthoredFields FieldsComponent={DatedFields} />
      </FormPanel>
    </>
  );
}

import { useMemo } from 'react';
import { StepOriginalOperatorLabel } from './step-original-operator/step-original-operator-label';
import { StepOriginalOperatorContent } from './step-original-operator/step-original-operator-content';
import { stepOriginalOperatorSchema } from './step-original-operator/step-original-operator-schema';
import { StepOvzFacilitiesLabel } from './step-ovz-facilities/step-ovz-facilities-label';
import { stepOvzFacilitiesSchema } from './step-ovz-facilities/step-ovz-facilities-schema';
import { StepOvzFacilitiesContent } from './step-ovz-facilities/step-ovz-facilities-content';
import { StepOtherInformationLabel } from './step-other-information/step-other-information-label';
import { StepOtherInformationContent } from './step-other-information/step-other-information-content';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepNewOperatorLabel } from './step-new-operator/step-new-operator-label';
import { StepNewOperatorContent } from './step-new-operator/step-new-operator-content';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepFinishActions } from './step-finish/step-finish-actions';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { stepNewOperatorSchema } from './step-new-operator/step-new-operator-schema';

export function useOvzFacilityRequestOperatorChange() {
  const steps = useMemo(
    () => [
      {
        Label: StepOriginalOperatorLabel,
        Content: StepOriginalOperatorContent,
        validationSchema: stepOriginalOperatorSchema,
      },
      {
        Label: StepOvzFacilitiesLabel,
        Content: StepOvzFacilitiesContent,
        validationSchema: stepOvzFacilitiesSchema,
      },
      {
        Label: StepNewOperatorLabel,
        Content: StepNewOperatorContent,
        validationSchema: stepNewOperatorSchema,
      },
      {
        Label: StepOtherInformationLabel,
        Content: StepOtherInformationContent,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    []
  );

  return {
    steps,
  };
}

import React from 'react';
import {
  FormPanel,
  FormTextField,
  FormSelect,
  useFormSelector,
} from '@eas/common-web';
import { FormikProps } from 'formik';
import { ProcessForm } from '../subject-requests-types';
import {
  SubjectRegistrationType,
  SubjectType,
  DocumentType,
} from '../../../models';
import { StepData } from '../../../components/process/process-types';
import { SubjectRequestFileFields } from '../../subject-request/fields/subject-request-file-fields';
import { AddressField } from '../../../components/form/address-field/address-field';
import {
  icoMaxLength,
  identityCardMaxLength,
} from '../../subject/fields/subjects-utils';
import { useDocumentTypes } from '../../subject/subjects-api';
import { StepSubjectForm } from './step-subject-types';
import { HelpLabel } from '../../../components/help/help-label';

export function StepSubjectContent(
  props: FormikProps<StepSubjectForm> & {
    allValues: ProcessForm;
    allSteps: StepData<ProcessForm>[];
  }
) {
  const documentTypes = useDocumentTypes();

  const { identityDocumentType } = useFormSelector((data: StepSubjectForm) => ({
    identityDocumentType: data.identityDocumentType,
  }));

  return (
    <FormPanel label="Vyplňte">
      {props.allValues.type ===
        SubjectRegistrationType.NATURAL_PERSON_REGISTRATION && (
        <>
          <FormSelect
            name="identityDocumentType"
            label={
              <HelpLabel
                label="Typ dokladu"
                code="SUBJECT_REGISTRATION_NEW_DOCUMENT_TYPE"
                required // Povinné pole
              />
            }
            source={documentTypes}
            valueIsId
          />
          <FormTextField
            name="idNumber.value"
            label={
              <HelpLabel
                label="Číslo dokladu"
                code="SUBJECT_REGISTRATION_NEW_DOCUMENT_NUMBER"
                required // Povinné pole
              />
            }
            inputProps={{
              maxLength:
                identityDocumentType !== DocumentType.PASSPORT
                  ? identityCardMaxLength
                  : undefined,
            }}
          />
        </>
      )}
      {props.allValues.type ===
        SubjectRegistrationType.BUSINESS_NATURAL_PERSON_REGISTRATION && (
        <>
          <FormTextField
            name="ico.value"
            label={
              <HelpLabel
                label="IČO"
                code="SUBJECT_REGISTRATION_NEW_ICO"
                required
              /> // Povinné pole
            }
            inputProps={{ maxLength: icoMaxLength }}
            onChangeEvent={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/gi, '');
            }}
          />
        </>
      )}
      {props.allValues.type ===
        SubjectRegistrationType.LEGAL_ENTITY_REGISTRATION && (
        <>
          <FormTextField
            name="ico"
            label={
              <HelpLabel
                label="IČO"
                code="SUBJECT_REGISTRATION_NEW_ICO"
                required
              /> // Povinné pole
            }
            inputProps={{ maxLength: icoMaxLength }}
            onChangeEvent={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/gi, '');
            }}
          />
        </>
      )}
      {props.allValues.type ===
        SubjectRegistrationType.LEGAL_ENTITY_WITHOUT_ICO_REGISTRATION && (
        <>
          <FormTextField
            name="name"
            label={
              <HelpLabel
                label="Název"
                code="SUBJECT_REGISTRATION_NEW_NAME"
                required
              /> // Povinné pole
            }
          />
          <AddressField name="residence" disableForeignCountry={true} />
          <SubjectRequestFileFields
            type={SubjectType.LEGAL_ENTITY_WITHOUT_ICO}
          />
        </>
      )}

      {/* Foreign subjects */}
      {props.allValues.type ===
        SubjectRegistrationType.FOREIGN_LEGAL_ENTITY_REGISTRATION && (
        <>
          <FormTextField
            name="name"
            label={
              <HelpLabel
                label="Název"
                code="SUBJECT_REGISTRATION_NEW_NAME"
                required
              /> // Povinné pole
            }
          />
          <AddressField name="residence" disableRuian={true} />
          <FormTextField
            name="foreignId"
            label={
              <HelpLabel
                label="Zahraniční identifikátor (obdoba IČO)"
                code="SUBJECT_REGISTRATION_NEW_FOREIGN_ID"
                required
              />
            }
          />
          <SubjectRequestFileFields type={SubjectType.FOREIGN_LEGAL_ENTITY} />
        </>
      )}
      {props.allValues.type ===
        SubjectRegistrationType.FOREIGN_NATURAL_PERSON_REGISTRATION && (
        <>
          <FormTextField
            name="firstName.value"
            label={
              <HelpLabel
                label="Jméno"
                code="SUBJECT_REGISTRATION_NEW_FIRSTNAME"
                required // Povinné pole
              />
            }
          />
          <FormTextField
            name="lastName.value"
            label={
              <HelpLabel
                label="Příjmení"
                code="SUBJECT_REGISTRATION_NEW_LASTNAME"
                required // Povinné pole
              />
            }
          />
          <AddressField name="residence" disableRuian={true} />
          <SubjectRequestFileFields type={SubjectType.FOREIGN_NATURAL_PERSON} />
        </>
      )}
    </FormPanel>
  );
}

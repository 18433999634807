import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { useStylesWrapper } from '../../components/form/protected-field/protected-field-styles';
import Button from '@material-ui/core/Button/Button';
import { IdentityProvider } from '../../enums';
import { IdentityProviderContext } from '../../components/identity-provider/identity-provider-context';
import { useIdpRemoveDialog } from './idp-remove-dialog-hook';
import { UserExternalId } from '../../models';
import { AuthContext } from '../../components/auth/auth-context';

export default function ExternalIdentityProviderRow({
  label,
  userExternalId,
  identityProvider,
  reloadProfile,
}: {
  label: string;
  userExternalId?: UserExternalId;
  identityProvider: IdentityProvider;
  reloadProfile?: VoidFunction;
}) {
  const {
    wrapper,
    spacing,
    labelText,
    labelRoot,
    boxRoot,
  } = useStylesWrapper();
  const { baseEnviiamUrl } = useContext(AuthContext);
  const { redirectUrl } = useContext(IdentityProviderContext);

  const { handleRemoveConnection } = useIdpRemoveDialog(
    userExternalId,
    reloadProfile
  );

  return (
    <Grid item xs={12} className={wrapper}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        classes={{ root: spacing }}
      >
        <Grid item xs={12} sm={3}>
          <Box className={boxRoot}>
            <InputLabel
              classes={{
                root: labelRoot,
              }}
            >
              <Typography
                variant="body2"
                classes={{
                  root: labelText,
                }}
              >
                {label}
              </Typography>
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">
              {userExternalId ? 'Propojeno' : 'Nepropojeno'}
            </Typography>
            {!userExternalId && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                component="a"
                href={redirectUrl(
                  identityProvider,
                  '/crzp/pridani-idp',
                  baseEnviiamUrl!
                )}
              >
                Propojit
              </Button>
            )}
            {userExternalId && (
              <Button
                variant="contained"
                size="small"
                onClick={handleRemoveConnection}
              >
                Odpojit
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { ProcessForm } from '../irz-facility-request-change-types';
import React, { useContext } from 'react';
import {
  SnackbarContext,
  useEventCallback,
  useFormSelector,
} from '@eas/common-web';
import { isIrzEqual } from '../../../composite/compare-irz-request/compare-irz-request-diff';
import Button from '@material-ui/core/Button';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { StepEditForm } from './step-edit-types';
import { Messages } from '../../../enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function StepEditActions({
  handleNext,
  handleExit,
  allValues,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Context stuff
   */
  const { showSnackbar } = useContext(SnackbarContext);

  const requestFacility = useFormSelector(
    ({ requestFacilityData }: StepEditForm) => requestFacilityData
  );

  const handleNextWithChangeCheck = useEventCallback(() => {
    if (isIrzEqual(allValues.irzFacility, requestFacility)) {
      showSnackbar(
        ...Messages.IrzFacilityRequest.REQUEST_CHANGE_SUBMIT.NO_CHANGES
      );
    } else {
      handleNext();
    }
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithChangeCheck}
          className={classes.button}
          disableElevation
        >
          Další
        </Button>
        <ExitProcessAction handleExit={handleExit} />
      </div>
    </div>
  );
}

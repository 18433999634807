import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryRiverBasinEuDistrict } from '../../models';
import { useValidationSchema } from './dict-river-basin-eu-districts-schema';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';

export function DictionaryRiverBasinEuDistricts() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryRiverBasinEuDistrict>({
    identifier: PageKey.DICTIONARY_RIVER_BASIN_EU_DISTRICTS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_RIVER_BASIN_EU_DISTRICTS,
    },
    tableProps: {
      tableName: 'Oblasti povodí Evropy',
      reportTag: ExportTags.DICTIONARY_RIVER_BASIN_EU_DISTRICTS,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.EU_RIVER_BASIN_DISTRICT,
      codePrefix: 'EU_RIVER_BASIN_DISTRICT',
      validationSchema,
      toolbarProps: {
        title: 'Oblast povodí Evropy',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import React from 'react';
import { ProcessForm } from '../role-requests-type';

export function StepSummaryLabel({ allValues }: { allValues?: ProcessForm }) {
  const state = allValues?.isSent ? 'Odesláno' : '-';

  return (
    <>
      Stav žádosti: <b>{state}</b>
    </>
  );
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useAddressFieldsFactory } from '../../components/form/address-field/address-field-history-fields';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    ...useAddressFieldsFactory('address'),
    {
      key: 'phoneNumber.prefix',
      label: 'Telefonní číslo - předvolba',
    },
    {
      key: 'phoneNumber.number',
      label: 'Telefonní číslo',
    },
    {
      key: 'description',
      label: 'Popis',
    },
    {
      key: 'number',
      label: 'Číslo oprávnění',
    },
    {
      key: 'boilerBrand',
      label: 'Značka kotle',
    },
    {
      key: 'boilerTypes',
      label: 'Typy kotle',
    },
    {
      key: 'ozoSubject',
      label: 'Subjekt OZO',
    },
    {
      key: 'manufacturerSubject',
      label: 'Subjekt výrobce',
    },
    {
      key: 'validFrom',
      label: 'Platnost od',
    },
    {
      key: 'validTo',
      label: 'Platnost do',
    },
    {
      key: 'ozoIco',
      label: 'IČO OZO',
    },
    {
      key: 'email',
      label: 'E-mail',
    },
  ];
}

import { ResultDto } from './../../../../common-web/src/common/common-types';
import {
  DictionaryAutocomplete,
  RuianObject,
  ProductionSiteAutocomplete,
  IrzFacilityCoordinate,
} from '../../models';
import {
  useAutocompleteSource,
  abortableFetch,
  useStaticListSource,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import { post } from '../../utils/custom-crud';

export function useProductionSites() {
  return useAutocompleteSource<ProductionSiteAutocomplete>({
    url: '/api/crzp/production/site/autocomplete',
  });
}

export function fetchProductionSite(id: string) {
  return abortableFetch(`${EvidenceAPI.PRODUCTION_SITES}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export async function combineGetCalls({ mainItem, signal }: CombineCallsType) {
  const json = {
    ...mainItem,
  };

  const [irzFacilitiesCoordinates] = await post<
    ResultDto<IrzFacilityCoordinate>
  >({
    url: `${EvidenceAPI.IRZ_FACILITIES}/coordinates`,
    signal,
    body: {
      filters: [
        {
          operation: 'EQ',
          field: 'productionSite.id',
          value: mainItem.id,
        },
      ],
    },
  });

  if (irzFacilitiesCoordinates?.items?.length) {
    json['irzFacilitiesCoordinates'] = irzFacilitiesCoordinates.items.map(
      (coord) => ({
        ...coord,
        assigned: true,
      })
    );
  }

  const text = JSON.stringify(json);

  return { data: json, text };
}

export function useBooleans() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: 'true',
      name: 'Ano',
    },
    {
      id: 'false',
      name: 'Ne',
    },
  ]);
}

export function useDistricts() {
  return useAutocompleteSource<RuianObject>({
    url: `${EvidenceAPI.ADDRESS_DISCTRICT}/autocomplete`,
  });
}

export function useRegions() {
  return useAutocompleteSource<RuianObject>({
    url: `${EvidenceAPI.ADDRESS_REGION}/autocomplete`,
  });
}

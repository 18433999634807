import React, { useRef, useState, useEffect, useContext } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  useEventCallback,
  DetailContext,
  EvidenceContext,
  ApiFilterOperation,
  Filter,
} from '@eas/common-web';
import { useMap } from './hook/map-hook';
import {
  LayerEnum,
  SMap as SMapType,
  MapProps,
  MouseControl,
  MouseControlsEnum,
  SMap,
  ZoomControl,
} from './map-types';
import { MapContext } from './map-ctx';
import { getCoordinates } from './map-api';
import { useStyles } from './map-styles';
import { MapSuggest } from './map-suggest';
import { MapEvidenceContext } from '../evidence/map-evidence/map-context';

const defaultWgs84CenterPoint = { lat: 50.0755, lon: 14.4378 };
const defaultJtskCenterPoint = { x: 1044493.079, y: 741818.445 };

const ADDITIONAL_LAYERS = [LayerEnum.OPHOTO, LayerEnum.BASE_NEW];

function useHandleMapRedraw() {
  const evidence = useContext(EvidenceContext);
  const mapEvidence = useContext(MapEvidenceContext);
  const detail = useContext(DetailContext);

  const handleMapRedraw = useEventCallback(async (sMap, setItems, api) => {
    if (sMap && api) {
      detail?.source?.setLoading(true);
      const size = sMap.getSize();
      const leftCornerPixel = new window.SMap.Pixel(-size.x / 2, -size.y / 2);
      const leftCorner = leftCornerPixel.toCoords(sMap, sMap.getZoom());

      const rightCornerPixel = new window.SMap.Pixel(size.x / 2, size.y / 2);
      const rightCorner = rightCornerPixel.toCoords(sMap, sMap.getZoom());

      const params = evidence?.tableSource?.getParams();

      const filters = mapEvidence?.withFilters
        ? params.filters?.map((filter: Filter) =>
            filter.operation === ApiFilterOperation.CONTAINS
              ? { ...filter, asciiFolding: false }
              : filter
          )
        : [];

      const { items } = await getCoordinates(
        leftCorner,
        rightCorner,
        filters,
        api
      ).json();
      setItems(items);
      detail?.source.setLoading(false);
    }
  });

  return handleMapRedraw;
}

export function Map({
  api,
  children,
  width = '100%',
  mode = 'PREVIEW',
  height = 'calc(100vh - 263px)',
  onPointAdded,
  defaultWgs84Position = defaultWgs84CenterPoint,
  defaultJtskPosition = defaultJtskCenterPoint,
  defaultZoom = 7,
  coordinatesFormat = 'WGS84',
  handleMapRedraw: mapRedraw,
}: MapProps) {
  const classes = useStyles();

  const mapRef = useRef<HTMLDivElement>(null);
  const [sMap, setSMap] = useState<SMapType | null>(null);

  const detail = useContext(DetailContext);

  const [items, setItems] = useState<Coordinates[]>([]);

  const { isLoaded } = useMap();
  const defaultMapRedraw = useHandleMapRedraw();
  const handleMapRedraw = useEventCallback(() =>
    mapRedraw
      ? mapRedraw(sMap, setItems, api)
      : defaultMapRedraw(sMap, setItems, api)
  );

  const handleMapClick = useEventCallback((e: any) => {
    if (mode === 'POINT_ADD' && sMap) {
      const coords = window.SMap.Coords.fromEvent(e.data.event, sMap).toWGS84();
      onPointAdded?.({
        lon: coords[0],
        lat: coords[1],
      });
    }
  });

  useEffect(() => {
    detail?.addRefreshListener(handleMapRedraw);

    return () => detail?.removeRefreshListener(handleMapRedraw);
  }, []);

  const centerMapToJtskDefaults = useEventCallback((sMap: SMapType) => {
    if (Array.isArray(defaultJtskPosition)) {
      const mappedCoords = defaultJtskPosition.map(({ x, y }) =>
        window.SMap.Coords.fromJTSK(Number(x), Number(y))
      );
      const [coords, zoom] = sMap.computeCenterZoom(mappedCoords, true);
      sMap.setCenterZoom(coords, zoom);
    } else {
      sMap.setCenter(
        window.SMap.Coords.fromJTSK(
          Number(defaultJtskPosition.x),
          Number(defaultJtskPosition.y)
        )
      );
    }
  });

  const centerMapWgs84ToDefaults = useEventCallback((sMap: SMapType) => {
    if (Array.isArray(defaultWgs84Position)) {
      const mappedCoords = defaultWgs84Position.map(({ lat, lon }) =>
        window.SMap.Coords.fromWGS84(Number(lon), Number(lat))
      );
      const [coords, zoom] = sMap.computeCenterZoom(mappedCoords, true);
      sMap.setCenterZoom(coords, zoom);
    } else {
      sMap.setCenter(
        window.SMap.Coords.fromWGS84(
          Number(defaultWgs84Position.lon),
          Number(defaultWgs84Position.lat)
        )
      );
    }
  });

  const setupMapControls = useEventCallback((sMap: SMapType) => {
    centerMapWgs84ToDefaults(sMap);
    const layerSwitch = new window.SMap.Control.Layer({
      items: 4,
      page: 4,
      width: 65,
    });
    sMap.addDefaultLayer(LayerEnum.GEOGRAPHY).enable();
    layerSwitch.addDefaultLayer(LayerEnum.GEOGRAPHY);
    for (const layer of ADDITIONAL_LAYERS) {
      sMap.addDefaultLayer(layer);
      layerSwitch.addDefaultLayer(layer);
    }
    sMap.addDefaultControls();
    const compassControl = sMap
      .getControls()
      .find((c) => c instanceof window.SMap.Control.Compass);
    sMap.removeControl(compassControl);
    sMap.addControl(layerSwitch, {
      left: '8px',
      top: '8px',
    });
    disableZoomMenu(sMap);
    disableZoom(sMap);
  });

  const createMap = useEventCallback(() => {
    try {
      if (mapRef.current) {
        const sMap = new window.SMap(mapRef.current, undefined, defaultZoom);
        setupMapControls(sMap);
        setSMap(sMap);
      } else {
        throw 'Map is not ready yet.';
      }
    } catch (e) {
      console.log('ERROR:', e);
    }
  });

  const enableZoom = useEventCallback((sMap: SMap) => {
    const mouseControl = sMap
      .getControls()
      .find((c) => c instanceof window.SMap.Control.Mouse) as
      | MouseControl
      | undefined;
    if (mouseControl) {
      mouseControl.configure(MouseControlsEnum.PAN | MouseControlsEnum.ZOOM);
    }
  });

  const disableZoom = useEventCallback((sMap: SMap) => {
    const mouseControl = sMap
      .getControls()
      .find((c) => c instanceof window.SMap.Control.Mouse) as
      | MouseControl
      | undefined;
    if (mouseControl) {
      mouseControl.configure(MouseControlsEnum.PAN);
    }
  });

  const disableZoomMenu = useEventCallback((sMap: SMap) => {
    const zoomControl = sMap
      .getControls()
      .find((c) => c instanceof window.SMap.Control.Zoom) as
      | ZoomControl
      | undefined;
    if (zoomControl) {
      zoomControl.removeZoomMenu();
    }
  });

  useEffect(() => {
    if (isLoaded) {
      createMap();
    }
  }, [createMap, isLoaded]);

  /**
   * Zmena kurzoru dle módu
   */
  useEffect(() => {
    if (isLoaded && sMap) {
      if (mode === 'POINT_ADD') {
        sMap.setCursor('crosshair');
      } else {
        sMap.setCursor('grab');
      }
    }
  }, [mode, isLoaded, sMap]);

  /**
   * Add event listeners
   */
  useEffect(() => {
    if (isLoaded && sMap) {
      const eMapClick = sMap
        .getSignals()
        .addListener(window, 'map-click', handleMapClick);

      return () => {
        sMap.getSignals().removeListener(eMapClick);
      };
    }
  }, [sMap, isLoaded, handleMapClick, handleMapRedraw]);

  useEffect(() => {
    if (api && sMap) {
      const listener = sMap
        .getSignals()
        .addListener(window, 'map-redraw', handleMapRedraw);
      return () => {
        sMap.getSignals().removeListener(listener);
      };
    }
  }, [sMap, api, handleMapRedraw]);

  useEffect(() => {
    if (sMap) {
      const enableZoomOnCTRLDown = (e: KeyboardEvent) => {
        if (e.key === 'Control') {
          enableZoom(sMap);
        }
      };
      const disableZoomOnCTRLDown = (e: KeyboardEvent) => {
        if (e.key === 'Control') {
          disableZoom(sMap);
        }
      };
      window.addEventListener('keydown', enableZoomOnCTRLDown);
      window.addEventListener('keyup', disableZoomOnCTRLDown);
      return () => {
        window.removeEventListener('keydown', enableZoomOnCTRLDown);
        window.removeEventListener('keyup', disableZoomOnCTRLDown);
      };
    }
  }, [sMap]);

  useEffect(() => {
    if (sMap && coordinatesFormat === 'WGS84') {
      centerMapWgs84ToDefaults(sMap);
    }
  }, [defaultWgs84Position, sMap]);

  useEffect(() => {
    if (sMap && coordinatesFormat === 'JTSK') {
      centerMapToJtskDefaults(sMap);
    }
  }, [defaultJtskPosition, sMap]);

  const [syncMapSize] = useDebouncedCallback(() => sMap?.syncPort(), 1000);
  useEffect(() => {
    if (mapRef.current && sMap) {
      const mapElement = mapRef.current;
      const observer = new ResizeObserver(syncMapSize);
      observer.observe(mapElement);
      return () => {
        observer.disconnect();
      };
    }
  }, [sMap]);

  return (
    <MapContext.Provider value={{ map: sMap, items, isLoaded }}>
      {api && (
        <div className={classes.root}>
          {/*
          <Typography variant="body1" noWrap={true}>
            Počet: <b>{count}</b>
          </Typography>
          */}
          <MapSuggest />
        </div>
      )}
      <div style={{ width, height }} ref={mapRef}>
        {!isLoaded && <>Načítám mapu</>}
        {sMap && children}
      </div>
    </MapContext.Provider>
  );
}

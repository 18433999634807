import React from 'react';
import { FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export function DictionaryWaterFlowsFields() {
  return (
    <>
      <FormTextField
        name="length"
        type="number"
        label={
          <HelpLabel label="Délka toku (v km)" code="DICT_WATER_FLOW_LENGTH" />
        }
      />
    </>
  );
}

import React, { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { differenceBy } from 'lodash';
import * as Yup from 'yup';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  FormSelect,
} from '@eas/common-web';
import { useAssignableAgendas } from '../subjects-api';
import { agendaLabelMapper } from '../../../components/form/mappers/label-mappers';
import { createBoilerManufacturerForSubject } from '../../boiler-manufacturer/boiler-manufacturers-api';
import { EnhancedSubject } from '../subjects-types';
import { getErrorMessage } from '../../../utils/get-message';
import { Me, Agenda, Subject, AgendaTag } from '../../../models';
import { Messages, EvidenceAPI } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
export function callApi(agenda: Agenda, subject: Subject) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECTS}/${subject.id}/assign/${agenda.id}`,
    {
      method: 'POST',
    }
  );
}

/**
 * Přiřazení agendy
 */
const PROMPT_KEY = 'ASSIGN_AGENDA';

export function useAssignAgendaDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source, isExisting } = useContext<DetailHandle<Subject>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { reload } = useContext<UserContext<Me>>(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Přiřazení agendy subjektu',
        dialogText: 'Subjekt vykonává agendu:',
        FormFields: function Body() {
          const assignableAgendas =
            (source.data as EnhancedSubject)?.assignableAgendas ?? [];

          const activeAssignedAgendas =
            (source.data as EnhancedSubject)?.agendas
              ?.filter((agenda) => agenda.active)
              ?.map((assigned) => assigned.agenda) ?? [];

          const agendaSource = useAssignableAgendas(
            differenceBy(assignableAgendas, activeAssignedAgendas, 'id')
          );
          agendaSource!.loadDetail = async (item) => item;

          return (
            <FormSelect
              name="agenda"
              source={agendaSource}
              labelOptions={{ hide: true }}
              labelMapper={agendaLabelMapper}
              layoutOptions={{ noUnderline: true, noSpacing: true }}
            />
          );
        },
        formValidationSchema: Yup.object().shape({
          agenda: Yup.mixed<Agenda>().nullable().required('Povinné pole'),
        }),
        dialogWidth: 600,
      },
    ],
    [source.data]
  );

  const handleAssignAgenda = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { agenda: Agenda }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(values.agenda, source.data!);

          const response = await fetch.current.response;

          if (response.status !== 200) {
            await fetch.current.json();
          }

          if (
            values.agenda?.tags?.includes(AgendaTag.OZO_MANUFACTURER) &&
            !(source.data as EnhancedSubject).agendas?.find(
              (a) => a.agenda!.code === values.agenda.code
            )
          ) {
            await createBoilerManufacturerForSubject(source.data!).raw();
          }

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Subject.AGENDA_ASSIGN.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.Subject.AGENDA_ASSIGN,
              err?.code
            );

            showSnackbar(...message);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const canAssign =
    isExisting &&
    source.data?.active &&
    !!(source.data as EnhancedSubject)?.assignableAgendas?.length;

  return {
    handleAssignAgenda,
    canAssign,
  };
}

import { useStaticListSource, DictionaryAutocomplete } from '@eas/common-web';
import { SubjectIdentificator } from '../../../models';

export function useNaturalPersonIdentificators() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: SubjectIdentificator.ARTIFICIAL_ID,
      name: 'Umělý identifikátor ("ID_xxxxxx")',
    },
    {
      id: SubjectIdentificator.ID_CARD_NUMBER,
      name: 'Číslo občanského průkazu',
    },
    { id: SubjectIdentificator.PASSPORT_NUMBER, name: 'Číslo pasu' },
  ]);
}

import { useRef, useContext } from 'react';
import {
  useEventCallback,
  AbortableFetch,
  abortableFetch,
  usePrompts,
  PromptContext,
  SnackbarContext,
} from '@eas/common-web';
import { EvidenceAPI, Messages } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
function callApi(event: string) {
  return abortableFetch(
    `${EvidenceAPI.NOTIFICATION_PREFERENCES}/unsubscribe/${event}`,
    {
      method: 'PUT',
    }
  );
}

const PROMPT_KEY = 'UNSUBSCRIBE_NOTIFICATION_EVENT';

export function useUnsubscribe({ refresh }: { refresh: () => void }) {
  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const { showSnackbar } = useContext(SnackbarContext);

  /**
   * Dialogs.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Varování',
      dialogText: 'Skutečně nechcete být upozorňováni o podobných událostech?',
    },
  ]);

  const handleUnsubscribe = useEventCallback(async (event: string) => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(event);
          const response = await fetch.current.json();

          refresh();

          if (response.mandatory) {
            showSnackbar(...Messages.Notification.UNSUBSCRIBE.MANDATORY);
          } else {
            const state: 'UNSUBSCRIBED' | 'ALREADY_UNSUBSCRIBED' =
              response.state ?? 'UNSUBSCRIBED';

            showSnackbar(...Messages.Notification.UNSUBSCRIBE[state]);
          }
        } catch (err) {
          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Notification.UNSUBSCRIBE.ERROR);

            throw err;
          }
          return undefined;
        }
      },
    });
  });

  return { handleUnsubscribe };
}

export enum PermissionExportAccessRule {
  /** Přehled nastavení exportů */
  EXPORT_ACCESS_RULE_LIST = 'EXPORT_ACCESS_RULE_LIST',

  /** Detail nastavení exportů */
  EXPORT_ACCESS_RULE_DETAIL = 'EXPORT_ACCESS_RULE_DETAIL',

  /** Vytvoření nastavení exportů */
  EXPORT_ACCESS_RULE_CREATE = 'EXPORT_ACCESS_RULE_CREATE',

  /** Editace nastavení exportů */
  EXPORT_ACCESS_RULE_UPDATE = 'EXPORT_ACCESS_RULE_UPDATE',

  /** Mazání nastavení exportů */
  EXPORT_ACCESS_RULE_DELETE = 'EXPORT_ACCESS_RULE_DELETE',
}

export enum PermissionExport {
  /** Přehled exportů */
  EXPORT_REQUEST_LIST = 'EXPORT_REQUEST_LIST',

  /** Plný přístup k správě exportů */
  EXPORT_ADMIN = 'EXPORT_ADMIN',
}

// export enum PermissionReporting {
//   /** Přehled, generování a export reportů */
//   REPORTING_DETAIL = 'REPORTING_DETAIL',

//   /** Přehled, generování a export reportů z vlastních subjektů */
//   REPORTING_DETAIL_OWN = 'REPORTING_DETAIL_OWN',
// }

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { useDirpLaws } from '../dict-dirp-law/dict-dirp-laws-api';
import { DictionaryLawParagraph } from './../../models';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export function useColumns(): TableColumn<DictionaryLawParagraph>[] {
  return [
    {
      datakey: 'law',
      sortkey: 'law.name',
      filterkey: 'law.id',
      name: 'Předpis',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryLawParagraph>(
        TableCells.TextCell
      ),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDirpLaws
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'name',
      name: 'Číslo paragrafu',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryLawParagraph>(
        TableCells.TextCell
      ),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'description',
      name: 'Popis',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryLawParagraph>(
        TableCells.TextCell
      ),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import React from 'react';
import { FormAutocomplete, FormTextField } from '@eas/common-web';
import { useDictionaryDecisionTypes } from './dict-decision-types-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { HelpLabel } from '../../components/help/help-label';

export function DictionaryDecisionTypesFields() {
  const decisionTypes = useDictionaryDecisionTypes();

  return (
    <>
      <FormAutocomplete
        label={
          <HelpLabel
            label="Nadřazený prvek"
            code="DICT_DECISION_TYPES_PARENT"
          />
        }
        name="parent"
        source={decisionTypes}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
      />
      <FormTextField
        name="description"
        label={
          <HelpLabel label="Popis" code="DICT_DECISION_TYPES_DESCRIPTION" />
        }
      />
      <FormTextField
        name="paragraph"
        label={
          <HelpLabel label="Paragraf" code="DICT_DECISION_TYPES_PARAGRAPH" />
        }
      />
    </>
  );
}

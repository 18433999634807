import React, { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  DetailMode,
  PromptContext,
  usePrompts,
  FormTextArea,
} from '@eas/common-web';
import { SubjectRegistration, SubjectRegistrationState } from '../../../models';
import { EvidenceAPI, Permission, Messages } from '../../../enums';
import { preprocessPhoneNumber } from '../../../components/form/phone-field/phone-field-utils';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call to approve the request
 *
 * @param id
 */
export function callApproveApi(
  id: string,
  values: { note?: string; ceniaNumber?: string }
) {
  return abortableFetch(`${EvidenceAPI.SUBJECT_REQUESTS}/${id}/approve`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      note: values.note,
      ceniaNumber: values.ceniaNumber,
    }),
  });
}

/**
 * Api call to save the subject
 *
 * @param id
 */
export function callSaveApi(values: SubjectRegistration) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECT_REQUESTS}/${values.id}/subject`,
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(values.registrationSubject),
    }
  );
}

/**
 * Approve subject registration.
 */
const PROMPT_KEY = 'APPROVE';

export function useApproveDialog() {
  /**
   * Context stuff.
   */
  const {
    onPersisted,
    source,
    mode,
    validate,
    cancelEditing,
    formRef,
  } = useContext<DetailHandle<SubjectRegistration>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { reload, hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Schválit registraci',
        dialogText: 'Skutečně chcete manuálně schválit registraci subjektu?',
        FormFields: function Body() {
          return (
            <>
              <FormTextArea name="note" label="Poznámka" />
              <FormTextArea
                name="ceniaNumber"
                label="Spisová služba CENIA (Ev.č./Č.j.)"
              />
            </>
          );
        },
        dialogWidth: 600,
      },
    ],
    [source.data?.created]
  );

  const handleApprove = useEventCallback(async () => {
    const errors = await validate();
    if (errors.length > 0) {
      return;
    }

    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (dialogValues: {
        note?: string;
        ceniaNumber?: string;
      }) => {
        try {
          source.setLoading(true);
          const values = formRef?.getFieldValues();

          if (values) {
            if (fetch.current !== null) {
              fetch.current.abort();
            }

            const preprocessedValues = preprocessPhoneNumber(
              values,
              'registrationSubject.phoneNumber'
            );

            fetch.current = callSaveApi(preprocessedValues);

            await fetch.current.raw();
          }

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApproveApi(source.data!.id, dialogValues);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.SubjectRegistration.APPROVE.SUCCESS);
            source.setLoading(false);
            cancelEditing();
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.SubjectRegistration.APPROVE,
              err.code
            );

            showSnackbar(...message);

            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showApproveButton =
    mode === DetailMode.EDIT &&
    (source.data?.state === SubjectRegistrationState.MANUAL_FINISH_REQUIRED ||
      source.data?.state === SubjectRegistrationState.WAITING_FOR_ZR_SYNC) &&
    hasPermission(Permission.Subject.SUBJECT_REQUEST_FINISH);

  return {
    handleApprove,
    showApproveButton,
  };
}

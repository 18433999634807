import {
  ApiFilterOperation,
  useAutocompleteSource,
  UserContext,
} from '@eas/common-web';
import { EvidenceAPI, Permission } from '../../enums';
import { IrzFacilityAutocomplete, Me } from '../../models';
import { useContext, useMemo } from 'react';
import { getSubjectsWithPermission } from '../../components/permission/perm-utils';

/**
 * Source of available irz facilities that current user can create update request for.
 */
export function useUserIrzFacilities() {
  const availableSubjectIds = useAvailableSubjects();

  return useAutocompleteSource<IrzFacilityAutocomplete>({
    url: `${EvidenceAPI.IRZ_FACILITIES}/autocomplete`,
    params: {
      filters: [
        {
          field: 'operator.id',
          operation: ApiFilterOperation.IN,
          values: availableSubjectIds,
        },
      ],
    },
  });
}

/**
 * Returns list of subject ids that user can create IRZ update request for.
 */
export function useAvailableSubjects() {
  const { user }: UserContext<Me> = useContext(UserContext);

  const availableSubjectIds = useMemo(() => {
    if (!user) {
      return [];
    } else {
      const subjects = getSubjectsWithPermission(
        user,
        Permission.IrzFacility.IRZ_REQUEST_UPDATE_SUBMIT
      );

      return subjects;
    }
  }, [user]);

  return availableSubjectIds;
}

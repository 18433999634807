/**
 * Irz Facility permissions
 */
export enum PermissionIrzFacility {
  /** Přehled provozoven IRZ s vazbou na všechny subjekty */
  IRZ_LIST_ALL = 'IRZ_LIST_ALL',

  /** Přehled provozoven IRZ s vazbou na své subjekty */
  IRZ_LIST_OWN = 'IRZ_LIST_OWN',

  /** Autocomplete výber hodnot provozoven IRZ s vazbou na všechny subjekty */
  IRZ_AUTOCOMPLETE_ALL = 'IRZ_AUTOCOMPLETE_ALL',

  /** Autocomplete výber hodnot provozoven IRZ s vazbou na své subjekty */
  IRZ_AUTOCOMPLETE_OWN = 'IRZ_AUTOCOMPLETE_OWN',

  /** Detail provozovny IRZ s vazbou na všechny subjekty */
  IRZ_DETAIL_ALL = 'IRZ_DETAIL_ALL',

  /** Detail provozovny IRZ s vazbou na své subjekty */
  IRZ_DETAIL_OWN = 'IRZ_DETAIL_OWN',

  /** Vytvoření (registrace) provozovny IRZ */
  IRZ_CREATE = 'IRZ_CREATE',

  /** Editace provozovny IRZ */
  IRZ_UPDATE = 'IRZ_UPDATE',

  /** Editace průmyslového místa IRZ */
  IRZ_UPDATE_PRODUCTION_SITE = 'IRZ_UPDATE_PRODUCTION_SITE',

  /** Editace provozovny IRZ - omezené fieldy */
  IRZ_UPDATE_NON_APPROVED_FIELDS = 'IRZ_UPDATE_NON_APPROVED_FIELDS',

  /** Editace stavů EU provozovny IRZ */
  IRZ_UPDATE_EU_STATUS = 'IRZ_UPDATE_EU_STATUS',

  /** Zneplatnění provozovny IRZ */
  IRZ_INVALIDATE = 'IRZ_INVALIDATE',

  /** Obnovení provozovny IRZ */
  IRZ_REVALIDATE = 'IRZ_REVALIDATE',

  /** Rozdělení provozovny IRZ */
  IRZ_SPLIT = 'IRZ_SPLIT',

  /** Sloučení provozovny IRZ */
  IRZ_MERGE = 'IRZ_MERGE',

  /** Změna provozovatele */
  IRZ_CHANGE_OPERATOR = 'IRZ_CHANGE_OPERATOR',

  /** Přehled žádostí provozoven IRZ s vazbou na všechny subjekty */
  IRZ_REQUEST_LIST_ALL = 'IRZ_REQUEST_LIST_ALL',

  /** Přehled žádostí provozoven IRZ s vazbou na své subjekty */
  IRZ_REQUEST_LIST_OWN = 'IRZ_REQUEST_LIST_OWN',

  /** Detail žádosti provozovny IRZ s vazbou na všechny subjekty */
  IRZ_REQUEST_DETAIL_ALL = 'IRZ_REQUEST_DETAIL_ALL',

  /** Detail žádosti provozovny IRZ s vazbou na své subjekty */
  IRZ_REQUEST_DETAIL_OWN = 'IRZ_REQUEST_DETAIL_OWN',

  /** Žádost o vytvoření (registrace) provozovny IRZ */
  IRZ_REQUEST_CREATE_SUBMIT = 'IRZ_REQUEST_CREATE_SUBMIT',

  /** Dokončení žádosti o vytvoření (registrace) provozovny IRZ */
  IRZ_REQUEST_CREATE_FINISH = 'IRZ_REQUEST_CREATE_FINISH',

  /** Žádost o editaci provozovny IRZ */
  IRZ_REQUEST_UPDATE_SUBMIT = 'IRZ_REQUEST_UPDATE_SUBMIT',

  /** Dokončení žádosti o editaci provozovny IRZ */
  IRZ_REQUEST_UPDATE_FINISH = 'IRZ_REQUEST_UPDATE_FINISH',

  /** Žádost o zneplatnění provozovny IRZ */
  IRZ_REQUEST_INVALIDATE_SUBMIT = 'IRZ_REQUEST_INVALIDATE_SUBMIT',

  /** Dokončení žádosti o zneplatnění provozovny IRZ */
  IRZ_REQUEST_INVALIDATE_FINISH = 'IRZ_REQUEST_INVALIDATE_FINISH',

  /** Žádost o změnu provozovatele provozovny IRZ */
  IRZ_REQUEST_CHANGE_OPERATOR_SUBMIT = 'IRZ_REQUEST_CHANGE_OPERATOR_SUBMIT',

  /** Dokončení žádosti o změnu provozovatele provozovny IRZ s vazbou na všechny subjekty */
  IRZ_REQUEST_CHANGE_OPERATOR_FINISH_ALL = 'IRZ_REQUEST_CHANGE_OPERATOR_FINISH_ALL',

  /** Dokončení žádosti o změnu provozovatele provozovny IRZ s vazbou na své subjekty */
  IRZ_REQUEST_CHANGE_OPERATOR_FINISH_OWN = 'IRZ_REQUEST_CHANGE_OPERATOR_FINISH_OWN',
}

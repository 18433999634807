import React, { useContext } from 'react';
import { get } from 'lodash';
import OpacityIcon from '@material-ui/icons/Opacity';
import {
  useEventCallback,
  NavigationContext,
  DashboardCardProps,
} from '@eas/common-web';
import { EvidenceUrl } from '../../../enums';
import { CustomCardBase } from './custom-card-base';

export function CardMUV({ report, definitionId }: DashboardCardProps) {
  const { navigate } = useContext(NavigationContext);

  const count = get(report, 'data[0].count');

  const navigateMain = useEventCallback(() => {
    navigate(EvidenceUrl.WATER_USAGE_PLACES);
  });

  return (
    <CustomCardBase
      title="Registr MUV"
      definitionId={definitionId}
      value={count}
      icon={<OpacityIcon />}
      navigate={navigateMain}
    />
  );
}

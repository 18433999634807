import * as Yup from 'yup';
import { User, PersonalData } from '../../models';
import {
  validateUsername,
  validateFirstName,
  validateLastName,
  validatePhone,
  validateEmail,
} from '../profile/profile-utils';

export function useValidationSchema() {
  return Yup.object<User>().shape({
    username: validateUsername(/^[a-zA-Z0-9+=_\-()!?.:#*%@]{4,50}$/),
    firstName: Yup.object<PersonalData>().shape({
      value: validateFirstName,
    }),
    lastName: Yup.object<PersonalData>().shape({
      value: validateLastName,
    }),
    email: Yup.object<PersonalData>().shape({
      value: validateEmail({ requiredMessage: 'Email musí být vyplněný' }),
    }),
    phoneNumber: validatePhone,
  }) as Yup.Schema<User>;
}

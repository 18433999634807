import { OvzFacilityRequest, Subject } from '../../models';
import {
  useAutocompleteSource,
  DictionaryAutocomplete,
  useListSource,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import { get } from '../../utils/custom-crud';

export function useOvzFacilityRequests() {
  return useAutocompleteSource<OvzFacilityRequest>({
    url: `${EvidenceAPI.OVZ_FACILITY_REQUESTS}/list`,
  });
}

export function useRequestStates() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.OVZ_FACILITY_REQUESTS + '/state/list',
    method: 'GET',
  });
}

export async function combineGetCalls({ signal, mainItem }: CombineCallsType) {
  const json: OvzFacilityRequest = {
    ...mainItem,
  };

  /**
   * Append newOperator to request
   */
  const [newOperatorSubject, request] = await get<Subject>({
    url: `${EvidenceAPI.SUBJECTS}/${mainItem?.newOperator?.id}`,
    signal,
  });

  if (request?.ok && newOperatorSubject) {
    json['newOperator'] = newOperatorSubject;
  }

  json?.ovzFacilities?.forEach((facility) => {
    const newName =
      json.nameMappings?.find((nm) => nm.id === facility.id)?.name ??
      facility.name;

    facility['newName' as 'name'] = newName;
  });

  const text = JSON.stringify(json);

  return {
    data: json as any,
    text,
  };
}

import React, { useContext } from 'react';
import {
  FormTextField,
  FormAutocomplete,
  FormPanel,
  FormInlineTableField,
  InlineTableFieldCells,
  FormSelect,
  FormDateField,
  useFormSelector,
  FormCustomField,
  UserContext,
  FormDecimalField2,
} from '@eas/common-web';
import {
  dictionaryLabelMapper,
  waterManagementDecisionLabelMapper,
} from '../../components/form/mappers/label-mappers';
import {
  useDictionaryDecisionTypes,
  useDecisionTypeGroups,
} from '../dict-decision-type/dict-decision-types-api';
import { useDictionaryIndicators } from '../dict-indicator/dict-indicators-api';
import { useDictionaryBalanceUnits } from '../dict-balance-unit/dict-balance-units-api';
import { useDictionarySampleTypes } from '../dict-sample-type/dict-sample-types-api';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import {
  useBasinEnterprises,
  useWaterUsagePlaces,
  useTypes,
} from '../water-usage-place/water-usage-places-api';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { useWaterManagementDecisions } from '../water-management-decision/water-management-decisions-api';
import { WaterManagementDecisionAutocomplete } from '../../models/water-management-decision';
import { HelpLabel } from '../../components/help/help-label';
import { DetailNavigationButton } from '../../components/detail-navigation-button/detail-navigation-button';
import { EvidenceUrl, Permission } from '../../enums';
import {
  WaterManagementDecisionPart,
  Me,
  WaterUsagePlacesType,
} from '../../models';

const IndiactorRecords = () => (
  <FormPanel
    label="Hodnota ukazatele při vypouštění vody"
    summary={<FormPanelCountByName name="indicatorRecords" />}
  >
    <FormInlineTableField
      name="indicatorRecords"
      labelOptions={{ hide: true }}
      layoutOptions={{ noSpacing: true }}
      columns={[
        {
          name: 'Ukazatel',
          datakey: 'indicator',
          width: 150,
          CellComponent: InlineTableFieldCells.useInlineAutocompleteCellFactory(
            {
              dataHook: useDictionaryIndicators,
              collectionDatakey: 'indicatorRecords',
            }
          ),
        },
        // {
        //   name: 'Čas vytvoření',
        //   datakey: 'created',
        //   width: 100,
        //   CellComponent: InlineTableFieldCells.useInlineDateFieldFactory({
        //     collectionDatakey: 'indicatorRecords',
        //   }),
        // },
        {
          name: 'Hodnota "p"',
          datakey: 'p',
          width: 150,
          CellComponent: InlineTableFieldCells.useInlineTextFieldFactory({
            collectionDatakey: 'indicatorRecords',
            type: 'number',
          }),
        },
        {
          name: 'Hodnota "m"',
          datakey: 'm',
          width: 150,
          CellComponent: InlineTableFieldCells.useInlineTextFieldFactory({
            collectionDatakey: 'indicatorRecords',
            type: 'number',
          }),
        },
        {
          name: 'Jednotka "p", "m"',
          datakey: 'indicator.unit',
          width: 150,
          CellComponent: InlineTableFieldCells.useInlineTextFieldFactory({
            collectionDatakey: 'indicatorRecords',
            disabled: true,
          }),
        },
        {
          name: 'Bilance',
          datakey: 'balance',
          width: 200,
          CellComponent: InlineTableFieldCells.useInlineTextFieldFactory({
            collectionDatakey: 'indicatorRecords',
            type: 'number',
          }),
        },
        {
          name: 'Jednotka bilance',
          datakey: 'balanceUnit',
          width: 100,
          CellComponent: InlineTableFieldCells.useInlineAutocompleteCellFactory(
            {
              dataHook: useDictionaryBalanceUnits,
              collectionDatakey: 'indicatorRecords',
            }
          ),
        },
        {
          name: 'Četnost',
          datakey: 'rate',
          width: 100,
          CellComponent: InlineTableFieldCells.useInlineNumberFieldFactory({
            collectionDatakey: 'indicatorRecords',
          }),
        },
        {
          name: 'Typ vzorku',
          datakey: 'sampleType',
          width: 150,
          CellComponent: InlineTableFieldCells.useInlineAutocompleteCellFactory(
            {
              dataHook: useDictionarySampleTypes,
              collectionDatakey: 'indicatorRecords',
            }
          ),
        },
      ]}
    />
  </FormPanel>
);

export function WaterManagementDecisionPartFields(
  props: NotedEvidenceFieldsProps
) {
  const types = useDictionaryDecisionTypes();
  const typeGroups = useDecisionTypeGroups();
  const wupTypes = useTypes();
  const basinEnterprises = useBasinEnterprises();
  const waterUsagePlaces = useWaterUsagePlaces();
  const waterManagementDecisions = useWaterManagementDecisions();

  const { waterUsagePlace, decision, type } = useFormSelector(
    (decisionPart: WaterManagementDecisionPart) => ({
      waterUsagePlace: decisionPart?.waterUsagePlace,
      decision: decisionPart?.decision,
      type: decisionPart?.type,
    })
  );

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const showRedirectToWup =
    hasPermission(Permission.WaterUsagePlace.WATER_USAGE_PLACE_LIST_ALL) ||
    hasPermission(Permission.WaterUsagePlace.WATER_USAGE_PLACE_LIST_OWN);

  const showRedirectToDecision = hasPermission(
    Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_LIST_ALL
  );

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="ispopId"
          label={
            <HelpLabel
              label="ID v systému ISPOP"
              code="WATER_MANAGEMENT_DECISION_ISPOP_ID"
              required
            />
          }
        />
        <FormTextField
          name="ppId.id"
          label={
            <HelpLabel
              label="ID v databázi PP"
              code="WATER_MANAGEMENT_DECISION_PART_PPID_ID"
              required
            />
          }
        />
        <FormSelect
          source={basinEnterprises}
          name="ppId.enterprise"
          label={
            <HelpLabel
              label="Podnik povodí"
              code="WATER_MANAGEMENT_DECISION_PART_ENTERPRISE"
              required
            />
          }
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          valueIsId={true}
        />

        <FormAutocomplete
          name="type"
          label={
            <HelpLabel
              label="Druh rozhodnutí"
              code="WATER_MANAGEMENT_DECISION_PART_TYPE"
            />
          }
          source={types}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormSelect
          name="typeGroup"
          label={
            <HelpLabel
              label="Skupina typu rozhodnutí"
              code="WATER_MANAGEMENT_DECISION_PART_TYPE_GROUP"
            />
          }
          source={typeGroups}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          valueIsId={true}
          disabled
        />
        <FormDateField
          name="validFrom"
          label={
            <HelpLabel
              label="Datum platnosti: od"
              code="WATER_MANAGEMENT_DECISION_PART_VALID_FROM"
            />
          }
        />
        <FormDateField
          name="validTo"
          label={
            <HelpLabel
              label="Datum platnosti: do"
              code="WATER_MANAGEMENT_DECISION_PART_VALID_TO"
            />
          }
        />
        <FormTextField
          name="waterUsagePurpose"
          label={
            <HelpLabel
              label="Účel"
              code="WATER_MANAGEMENT_DECISION_PART_WATER_USAGE_PURPOSE"
            />
          }
        />
      </FormPanel>
      <FormPanel label="Rozhodnutí">
        <FormAutocomplete<WaterManagementDecisionAutocomplete>
          source={waterManagementDecisions}
          name="decision"
          label={
            <HelpLabel
              label="Číslo jednací"
              code="WATER_MANAGEMENT_DECISION_PART_DECISION"
            />
          }
          labelMapper={waterManagementDecisionLabelMapper}
          tooltipMapper={waterManagementDecisionLabelMapper}
        />
        <FormDateField
          name="decision.issuedDate"
          disabled
          label={
            <HelpLabel
              label="Datum vydání"
              code="WATER_MANAGEMENT_DECISION_PART_DECISION_ISSUED_DATE"
            />
          }
        />
        <FormTextField
          name="decision.issuedBy"
          disabled
          label={
            <HelpLabel
              label="Vydal"
              code="WATER_MANAGEMENT_DECISION_PART_DECISION_ISSUED_BY"
            />
          }
        />
        {decision?.id && showRedirectToDecision && (
          <FormCustomField>
            <DetailNavigationButton
              url={EvidenceUrl.WATER_MANAGEMENT_DECISIONS}
              id={decision?.id}
            />
          </FormCustomField>
        )}
      </FormPanel>
      <FormPanel label="Místo užívání vody">
        <FormAutocomplete
          source={waterUsagePlaces}
          name="waterUsagePlace"
          label={
            <HelpLabel
              label="Název místa"
              code="WATER_MANAGEMENT_DECISION_PART_WATER_USAGE_PLACE"
            />
          }
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormTextField
          name="waterUsagePlace.operator.name"
          disabled
          label={
            <HelpLabel
              label="Název provozovatele"
              code="WATER_MANAGEMENT_DECISION_PART_WATER_USAGE_PLACE_OPERATOR_NAME"
            />
          }
        />
        <FormTextField
          name="waterUsagePlace.operator.identifier"
          disabled
          label={
            <HelpLabel
              label="IČO provozovatele"
              code="WATER_MANAGEMENT_DECISION_PART_WATER_USAGE_PLACE_OPERATOR_IDENTIFIER"
            />
          }
        />
        <FormSelect
          name="waterUsagePlace.type"
          label={
            <HelpLabel
              label="Typ místa"
              code="WATER_MANAGEMENT_DECISION_PART_WATER_USAGE_PLACE_TYPE"
            />
          }
          disabled
          source={wupTypes}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          valueIsId={true}
        />
        <FormTextField
          name="waterUsagePlace.vhbNumber"
          disabled
          label={
            <HelpLabel
              label="Číslo VHB"
              code="WATER_MANAGEMENT_DECISION_PART_WATER_USAGE_PLACE_VHB"
            />
          }
        />
        {waterUsagePlace?.id && showRedirectToWup && (
          <FormCustomField>
            <DetailNavigationButton
              url={EvidenceUrl.WATER_USAGE_PLACES}
              id={waterUsagePlace?.id}
            />
          </FormCustomField>
        )}
      </FormPanel>
      {waterUsagePlace?.type !== WaterUsagePlacesType.WATER_ACCUMULATION &&
        type?.name !== 'Stanovení ochranného pásma vodních zdrojů' && (
          <FormPanel label="Limity objemu při odběru a vypouštění vody">
            <FormDecimalField2
              name="limits.thousandM3PerYear"
              label={
                <HelpLabel
                  label="tis. m3 za rok"
                  code="WATER_MANAGEMENT_DECISION_PART_LIMITS_M3_PER_YEAR"
                />
              }
              decimalDigits={6}
              fixedDecimalDigits={false}
            />
            <FormDecimalField2
              name="limits.thousandM3PerMonth"
              label={
                <HelpLabel
                  label="tis. m3 za měsíc"
                  code="WATER_MANAGEMENT_DECISION_PART_LIMITS_M3_PER_MONTH"
                />
              }
              decimalDigits={6}
              fixedDecimalDigits={false}
            />
            <FormDecimalField2
              name="limits.m3PerDayAverage"
              label={
                <HelpLabel
                  label="prům. m3/den"
                  code="WATER_MANAGEMENT_DECISION_PART_LIMITS_M3_PER_DAY_AVERAGE"
                />
              }
            />
            <FormDecimalField2
              name="limits.m3PerDayMax"
              label={
                <HelpLabel
                  label="max. m3/den"
                  code="WATER_MANAGEMENT_DECISION_PART_LIMITS_M3_PER_DAY_MAX"
                />
              }
            />
            <FormDecimalField2
              name="limits.litrePerSecondAverage"
              label={
                <HelpLabel
                  label="litrů za sekundu průměrně"
                  code="WATER_MANAGEMENT_DECISION_PART_LIMITS_LITRE_PER_SECOND_AVERAGE"
                />
              }
            />
            <FormDecimalField2
              name="limits.litrePerSecondMax"
              label={
                <HelpLabel
                  label="litrů za sekundu maximálně"
                  code="WATER_MANAGEMENT_DECISION_PART_LIMITS_LITRE_PER_SECOND_MAX"
                />
              }
            />
          </FormPanel>
        )}
      {waterUsagePlace?.type !==
        WaterUsagePlacesType.SURFACE_WATER_ABSTRACTION &&
        waterUsagePlace?.type !==
          WaterUsagePlacesType.GROUNDWATER_ABSTRACTION &&
        waterUsagePlace?.type !== WaterUsagePlacesType.WATER_ACCUMULATION && (
          <IndiactorRecords />
        )}
      {props.HistoryRecords}
    </>
  );
}

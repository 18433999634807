import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { historyValueMapper } from '../../components/evidence/history-evidence/history-utils';
import { useSubjectRoles, useRoles } from '../roles/roles-api';
import { useAgendaTypes } from './agendas-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const subjectRoles = useSubjectRoles();
  const roles = useRoles();
  const agendaTypes = useAgendaTypes();

  return [
    {
      key: 'type',
      label: 'Typ agendy',
      source: agendaTypes,
    },
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'code',
      label: 'Kód',
    },
    {
      key: 'description',
      label: 'Popis podporované agendy dle legislativy',
    },
    {
      key: 'selfAssignable',
      label: 'Příznak, jestli se subjekt může sám rozhodnout agendu vykonávat',
    },
    {
      key: 'empowerable',
      label: 'Příznak, jestli může být agenda předmětem zmocnění',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platnost od',
    },
    {
      key: 'validTo',
      label: 'Platnost do',
    },
    {
      key: 'assignerRoles',
      label: 'Role, které mohou agendu přidělit',
      source: roles,
    },
    {
      key: 'subjectRoles',
      label: 'Subjektové role',
      source: subjectRoles,
    },
    {
      key: 'specifications',
      label: 'Specifikace',
      valueMapper: (change) =>
        historyValueMapper(change, getSpecificationLabel),
    },
    {
      key: 'tags',
      label: 'Štítky agendy',
    },
    {
      key: 'rppAttributes.activityRole',
      label: 'Činnostní role',
    },
    {
      key: 'rppAttributes.agenda',
      label: 'Agenda RPP',
    },
    {
      key: 'rppAttributes.legalReference',
      label: 'Zákonná reference',
    },
    {
      key: 'autoAssign',
      label: 'Příznak automatického přiřazení agendy k subjektu',
    },
  ];
}

function getSpecificationLabel(key: string) {
  switch (key) {
    case 'name': {
      return 'Název';
    }
    case 'description': {
      return 'Popis';
    }
    case 'subjectRole': {
      return 'Subjektové role';
    }
  }
}

import {
  ProtectedDepositArea,
  ChluState,
} from './../../../models/protected-deposit-area';
import { useContext } from 'react';
import {
  abortableFetch,
  UserContext,
  DetailHandle,
  DetailContext,
} from '@eas/common-web';
import { EvidenceAPI, Permission } from './../../../enums';
import { Me } from './../../../models';

export function useSendToRuianDialog() {
  const { source } = useContext<DetailHandle<ProtectedDepositArea>>(
    DetailContext
  );
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = (id: string) => {
    return abortableFetch(`${EvidenceAPI.PROTECTED_DEPOSIT_AREAS}/${id}/send`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
  };

  const canApproveForRuian =
    hasPermission(Permission.Chlu.CHLU_PUBLISH) &&
    source?.data?.state === ChluState.CONCEPT &&
    !source?.data?.inRuianOrEntering;

  const canEditForRuian =
    hasPermission(Permission.Chlu.CHLU_PUBLISH) &&
    source?.data?.state === ChluState.CONCEPT &&
    source?.data?.inRuian;

  return { callApi, canApproveForRuian, canEditForRuian };
}

import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryEPrtrActivity } from '../../models';
import { useValidationSchema } from './dict-e-prtr-activities-schema';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { DictionaryEPrtrActivitiesFields } from './dict-e-prtr-activities-fields';

export function DictionaryEprtrActivities() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryEPrtrActivity>({
    identifier: PageKey.DICTIONARY_EPRTR_ACTIVITIES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_EPRTR_ACTIVITIES,
    },
    tableProps: {
      tableName: 'Činnosti E-PRTR',
      reportTag: ExportTags.DICTIONARY_EPRTR_ACTIVITIES,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.E_PRTR_ACTIVITY,
      codePrefix: 'E_PRTR_ACTIVITY',
      validationSchema,
      GeneralFieldsComponent: DictionaryEPrtrActivitiesFields,
      toolbarProps: {
        title: 'Činnost E-PRTR',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import React, { useContext, useEffect, useState } from 'react';
import {
  FormTextField,
  FormPanel,
  FormAutocomplete,
  FormDateField,
  FormCheckbox,
  FormInlineTableField,
  useFormSelector,
  FormTextArea,
  DetailContext,
  DetailMode,
  FormSelect,
  FormNumberField,
  FormCustomField,
  FormTableField,
  EmptyComponent,
  FormContext,
  eqFilterParams,
  useUpdateEffect,
} from '@eas/common-web';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTerritorialTechnicalUnits } from '../dict-territorial-technical-unit/dict-territorial-technical-units-api';
import { useIrzFacilities } from '../irz-facility/irz-facilities-api';
import { useIppcPidColumns, useRequestColumns } from './ovz-facilities-columns';
import { LocationPanel } from '../../components/form/location-panel/location-panel';
import {
  OvzFacility,
  RuianObjectAutocomplete,
  OvzFacilityRequest,
} from '../../models';
import { SubjectFormPanel } from '../../composite/subject-fields/subject-form-panel';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { AuthoredFields } from '../../components/evidence/authored-evidence/authored-fields';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { useExpandableFormPanel } from '../../composite/form-panel/expandable-form-panel-hook';
import { useLandPlotTypes } from './ovz-facilities-api';
import { useCadastralTerritories } from '../../components/form/address-field/address-field-api';
import {
  calculateDistance,
  formatDistance,
} from '../../components/form/location-panel/location-utils';
import clsx from 'clsx';
import { HelpLabel } from '../../components/help/help-label';
import { EvidenceAPI } from '../../enums';
import { useTradesInEmissionsColumns } from '../irz-facility/irz-facilities-columns';

const useStyles = makeStyles((theme) => ({
  distance: {
    lineHeight: 'inherit',
    padding: '0 2pt',
  },
  warning: {
    color: theme.palette.error.main,
  },
}));

export function OvzFacilitiesFields(props: NotedEvidenceFieldsProps) {
  /**
   * Ctx stuff
   */
  const { mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  /**
   * Styles
   */
  const classes = useStyles();

  /**
   * Calculate distance from AddressPlace to UTJ.
   *
   * In case of distance > 10km, show warning
   */
  const [distance, setDistance] = useState(0);

  const territorialTechnicalUnits = useTerritorialTechnicalUnits();
  const landPlotTypes = useLandPlotTypes();
  const cadastralTerritories = useCadastralTerritories();

  const requestColumns = useRequestColumns();
  const tradesInEmissionsColumns = useTradesInEmissionsColumns();
  const ippcPidColumns = useIppcPidColumns();

  const {
    wgs84Coordinates,
    territorialTechnicalUnit,
    hasValidationExpiration,
    address,
    requests,
    operator,
    tradesInEmissions,
    hasIntegratedPermission,
  } = useFormSelector(
    (data: OvzFacility & { requests: OvzFacilityRequest[] }) => ({
      wgs84Coordinates: data.wgs84Coordinates,
      territorialTechnicalUnit: data.territorialTechnicalUnit,
      hasValidationExpiration: Boolean(data.validTo),
      address: data.address,
      requests: data.requests,
      operator: data.operator,
      tradesInEmissions: data.emissionsTradingSystem?.tradesInEmissions,
      hasIntegratedPermission: data?.integratedPermission?.hasPermission,
    })
  );

  useEffect(() => {
    const facilityCoors = wgs84Coordinates;
    const ttuCoors = territorialTechnicalUnit?.wgs84Coordinates;

    if (facilityCoors && ttuCoors) {
      const distance = calculateDistance(facilityCoors, ttuCoors);

      setDistance(distance);
    } else {
      setDistance(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wgs84Coordinates,
    territorialTechnicalUnit?.wgs84Coordinates,
    address?.addressPlaceRuian,
  ]);

  useUpdateEffect(() => {
    if (mode === DetailMode.NEW) {
      setFieldValue('irzFacility', undefined);
    }
  }, [operator]);

  const { ref: locationPanelRef } = useExpandableFormPanel();

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="name"
          label={
            <HelpLabel
              label="Název provozovny"
              code="OVZ_FACILITY_NAME"
              required
            />
          }
          // Povinné pole, prvních 5 písmen v názvu provozovny musí odpovídat názvu provozovatele (subjektu)
        />
        <FormTextField
          name="facilityId"
          label={
            <HelpLabel
              label="IČP provozovny OVZ"
              code="OVZ_FACILITY_FACILITY_ID"
            />
          }
          disabled={true}
        />
        {mode === DetailMode.VIEW && (
          <>
            <FormCheckbox
              name="active"
              label={<HelpLabel label="Aktivní" code="OVZ_FACILITY_ACTIVE" />}
              disabled
            />
            <FormDateField
              name="registrationDate"
              label={
                <HelpLabel
                  label="Datum registrace"
                  code="OVZ_FACILITY_REGISTRATION_DATE"
                />
              }
              disabled={true}
            />
          </>
        )}
        {hasValidationExpiration && (
          <FormDateField
            name="validTo"
            label={
              <HelpLabel
                label="Datum zneplatnění"
                code="OVZ_FACILITY_VALID_TO"
              />
            }
            disabled
          />
        )}
        <FormAutocomplete
          name="irzFacility"
          label={
            <HelpLabel
              label="Provozovna IRZ"
              code="OVZ_FACILITY_IRZ_FACILITY"
            />
          }
          source={useIrzFacilities(
            eqFilterParams({
              field: 'operator.id',
              value: operator?.id,
            }),
            EvidenceAPI.IRZ_FACILITIES
          )}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          disabled={!operator}
        />
        <FormTextArea
          name="note"
          label={<HelpLabel label="Poznámky" code="OVZ_FACILITY_NOTE" />}
        />
      </FormPanel>

      <SubjectFormPanel
        label="Provozovatel"
        codePrefix="OVZ_FACILITY_OPERATOR"
        autocomplete={{
          name: 'operator',
          label: 'Název provozovatele',
          required: true,
        }}
        addressLabel="Adresa sídla"
        disabled={mode !== DetailMode.NEW}
      />

      {requests?.length > 0 && mode === DetailMode.VIEW && (
        <FormPanel label="Nabídky na změnu provozovatele">
          <FormTableField
            name="requests"
            labelOptions={{ hide: true }}
            layoutOptions={{
              noSpacing: true,
            }}
            showDetailBtnCond={() => false}
            showRadioCond={() => false}
            visibleActionsColumn={false}
            ToolbarComponent={EmptyComponent}
            columns={requestColumns}
          />
        </FormPanel>
      )}

      <FormPanel
        label="ÚTJ; Lokalita provozovny (zadání výběrem parcely)"
        expandable
        defaultExpanded={false}
        ref={locationPanelRef}
      >
        <FormAutocomplete
          name="territorialTechnicalUnit"
          label={
            <HelpLabel
              label="ÚTJ"
              code="OVZ_FACILITY_TERRITORIAL_TECHNICAL_UNIT"
              required
            />
          } // Povinné pole
          source={territorialTechnicalUnits}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormCheckbox
          name="multipleTTU"
          label={
            <HelpLabel
              label="Areál se nachází na více ÚTJ"
              code="OVZ_FACILITY_MULTIPLE_TTU"
            />
          }
        />
        <FormAutocomplete<RuianObjectAutocomplete>
          source={cadastralTerritories}
          label={
            <HelpLabel
              label="KÚ (povinné, pokud není vyplněno adresní místo)"
              code="OVZ_FACILITY_CADASTRAL_TERRITORY"
              required
            />
          } // Povinné pole (pokud není vyplněno adresní místo)
          name="cadastralTerritory"
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormSelect
          name="landPlotType"
          label={
            <HelpLabel
              label="Druh číslování parcel (povinné, pokud není vyplněno adresní místo)"
              code="OVZ_FACILITY_LAND_PLOT_TYPE"
              required
            />
          } // Povinné pole (pokud není vyplněno adresní místo)
          source={landPlotTypes}
          valueIsId
        />
        <FormNumberField
          name="primaryLandPlotNumber"
          label={
            <HelpLabel
              label="Kmenové číslo parcely (povinné, pokud není vyplněno adresní místo)"
              code="OVZ_FACILITY_PRIMARY_LAND_PLOT_NUMBER"
              required
            />
          } // Povinné pole (pokud není vyplněno adresní místo)
          inputProps={{
            maxLength: 5,
          }}
        />
        <FormNumberField
          name="secondaryLandPlotNumber"
          label={
            <HelpLabel
              label="Pořadové číslo parcely"
              code="OVZ_FACILITY_SECONDARY_LAND_PLOT_NUMBER"
            />
          }
          inputProps={{
            maxLength: 3,
          }}
        />
        <FormCustomField
          name="warning"
          label="Vzdálenost polohy provozovny od středu ÚTJ"
        >
          <Typography
            variant="body1"
            className={clsx(classes.distance, {
              [classes.warning]: distance > 10000,
            })}
          >
            {distance > 10000
              ? `Upozornění: Vybrané ÚTJ (střed) se nachází ve větší vzdálenosti než 10km (${formatDistance(
                  distance
                )}) od polohy provozovny`
              : formatDistance(distance)}
          </Typography>
        </FormCustomField>
      </FormPanel>
      <LocationPanel
        locationName="wgs84Coordinates"
        locationValue={wgs84Coordinates}
        locationPanelLabel="Poloha provozovny"
        addressName="address"
        addressPanelLabel="Adresa provozovny"
        address={address}
        showCoordinates={true}
        disableForeignCountry={true}
        expandable
        defaultExpanded={false}
      />
      <FormPanel label="Identifikátory">
        <FormTextField
          name="facilityId"
          label={
            <HelpLabel
              label="IČP provozovny OVZ"
              code="OVZ_FACILITY_FACILITY_ID"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="originalFacilityId"
          label={
            <HelpLabel
              label="Původní IČP provozovny OVZ"
              code="OVZ_FACILITY_ORIGINAL_FACILITY_ID"
            />
          }
        />
        <FormTextField
          name="irzFacility.facilityId"
          label={
            <HelpLabel
              label="IČP provozovny IRZ"
              code="OVZ_FACILITY_IRZ_FACILITY_ID"
            />
          }
          disabled={true}
        />
        <FormCheckbox
          name="integratedPermission.hasPermission"
          label={
            <HelpLabel
              label="Provozovna/Zařízení má Integrované povolení"
              code="OVZ_FACILITY_INTEGRATED_PERMISSION_HAS_PERMISSION"
            />
          }
        />
        {hasIntegratedPermission && (
          <FormInlineTableField<string>
            name="integratedPermission.ippcPids"
            label={
              <HelpLabel
                label="Seznam PID (IPPC)"
                code="OVZ_FACILITY_IPPC_PIDS"
              />
            }
            columns={ippcPidColumns}
            initNewItem={() => ''}
          />
        )}
        <FormCheckbox
          name="emissionsTradingSystem.tradesInEmissions"
          label={
            <HelpLabel
              label="Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi"
              code="OVZ_FACILITY_EMISSIONS_TRADING_SYSTEM_TRADES_IN_EMISSIONS"
            />
          }
        />
        {tradesInEmissions === true && (
          <FormInlineTableField<string>
            name="emissionsTradingSystem.ids"
            label={
              <HelpLabel
                label="ID zařízení (EU ETS)"
                code="OVZ_FACILITY_EMISSIONS_TRADING_SYSTEM_IDS"
              />
            } // Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9
            columns={tradesInEmissionsColumns}
            initNewItem={() => ''}
          />
        )}
      </FormPanel>

      {props.Notes}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <AuthoredFields FieldsComponent={DatedFields} />
      </FormPanel>
    </>
  );
}

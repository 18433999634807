import * as Yup from 'yup';
import { ScheduleJob } from '../../../models';

export function useValidationSchema() {
  return Yup.object<ScheduleJob>().shape({
    name: Yup.string().nullable().required(),
    timer: Yup.string().nullable().required(),
    script: Yup.string().nullable().required(),
  });
}

import {
  useEventCallback,
  SnackbarContext,
  AbortableFetch,
  DetailHandle,
  DetailContext,
  usePrompts,
  PromptContext,
} from '@eas/common-web';
import { cancelCall } from '../job/schedule-jobs-api';
import { unstable_batchedUpdates } from 'react-dom';
import { useContext, useRef } from 'react';
import { ScheduleJob } from '../../../models';
import { Messages } from '../../../enums';

const PROMPT_KEY = 'CANCEL';

export function useCancelDialog() {
  const { isExisting, source, onPersisted } = useContext<
    DetailHandle<ScheduleJob>
  >(DetailContext);
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const fetch = useRef<AbortableFetch | null>(null);

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Varování',
      dialogText:
        'Zastavení běžící úlohy může spůsobit inkonzistenci dat. Skutečně chcete zastavit běžící úlohu?',
    },
  ]);

  const handleCancel = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = cancelCall(source.url, source.data!.id);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.ScheduleJob.CANCEL.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.ScheduleJob.CANCEL.ERROR);

            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showCancelButton = isExisting && source.data?.running;

  return { handleCancel, showCancelButton };
}

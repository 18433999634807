import React, { useMemo } from 'react';
import {
  TableColumn,
  TableCells,
  TableFieldCells,
  TableFieldColumn,
  TableFieldCellProps,
  TableFilterCells,
  ApiFilterOperation,
  InlineTableFieldCells,
  FilterComponentProps,
  Select,
  useStaticListSource,
  useEventCallback,
} from '@eas/common-web';
import { User, RoleInstance, UserExternalId } from '../../models';
import { useRoles } from '../roles/roles-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { rolesValueMapper } from '../../composite/user-column-mappers/roles-mapper';
import { externalIdsValueMapper } from '../../composite/user-column-mappers/external-ids-mapper';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { roleConsentMapper } from '../../composite/user-column-mappers/role-consent-mapper';
import { useLocalRoleInstanceTypes } from '../role-instance/role-instances-api';
import Typography from '@material-ui/core/Typography';
import { useIdentityProviders } from '../../components/identity-provider/identity-provider-api';
import { IdentityProvider } from '../../enums';

export function useColumns(
  canSeeDeactivated: boolean,
  canSeeUuid: boolean
): TableColumn<User>[] {
  const columns: TableColumn<User>[] = [
    {
      datakey: 'username',
      name: 'Uživatelské jméno',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'roleInstances',
      name: 'Role',
      width: 300,
      filterkey: 'roleInstances.role.id',
      sortkey: 'roleInstances.role.name',
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      valueMapper: rolesValueMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useRoles,
        dictionaryLabelMapper
      ),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'firstName.value',
      name: 'Jméno',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'lastName.value',
      name: 'Příjmení',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'phoneNumber.number.value',
      name: 'Telefonní číslo',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'email.value',
      name: 'Email',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'systemAccount',
      name: 'Systémový účet',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'roleConsent',
      name: 'Souhlas s nahlížením na data',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: roleConsentMapper,
      CellComponent: inactiveColoredFactory(TableCells.TextCell, false),
      FilterComponent: RoleConsentFilterCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'lastLogin.timestamp',
      name: 'Poslední přihlášení',
      width: 200,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      FilterComponent: TableFilterCells.FilterInstantCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'twoFactorEnabled',
      name: 'Dvoufaktorová autentizace',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'externalIds',
      filterkey: 'externalIds.identityProvider.id',
      name: 'Externí IdP',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell, false),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useIdentityProviders
      ),
      valueMapper: externalIdsValueMapper,
      sortable: false,
      filterable: true,
      filterGroup: 1,
    },
  ];
  if (canSeeUuid) {
    columns.push({
      datakey: 'id',
      name: 'UUID',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
      visible: false,
    });
  }
  if (canSeeDeactivated) {
    columns.push({
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    });
  }
  return columns;
}

export function useAdministratorRoleColumns(): TableFieldColumn<
  RoleInstance
>[] {
  return useMemo(
    () => [
      {
        name: 'Název',
        datakey: 'subject.name',
        width: 200,
      },
      {
        name: 'IČO / UID',
        datakey: 'subject.identifier',
        width: 200,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        name: 'Platnost od',
        datakey: 'validFrom',
        width: 200,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        name: 'Platnost do',
        datakey: 'validTo',
        width: 200,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        name: 'Role',
        datakey: 'id',
        width: 300,
        CellComponent: function Roles(
          props: TableFieldCellProps<RoleInstance>
        ) {
          return (
            <Typography variant="body1">
              {/* {props.rowValue?.userRoles
                ?.map((roleInstance) => roleInstance?.role?.name)
                .join(', ')} */}
            </Typography>
          );
        },
      },
    ],
    []
  );
}

export function useUserRoleColumns(): TableFieldColumn<RoleInstance>[] {
  return useMemo(
    () => [
      {
        name: 'Název',
        datakey: 'subject.name',
        width: 200,
      },
      {
        name: 'IČO / UID',
        datakey: 'subject.identifier',
        width: 200,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        name: 'Platnost od',
        datakey: 'validFrom',
        width: 200,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        name: 'Platnost do',
        datakey: 'validTo',
        width: 200,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        name: 'Role',
        datakey: 'id',
        width: 300,
        CellComponent: function Roles(
          props: TableFieldCellProps<RoleInstance>
        ) {
          return (
            <Typography variant="body1">
              {/* {props.rowValue?.userRoles
                ?.map((roleInstance) => roleInstance?.role?.name)
                .join(', ')} */}
            </Typography>
          );
        },
      },
    ],
    []
  );
}

export function useRoleInstancesColumns(): TableFieldColumn<RoleInstance>[] {
  return [
    {
      name: 'Systém',
      datakey: 'role.registeredFor.name',
      width: 300,
    },
    {
      name: 'Typ',
      datakey: 'type',
      width: 300,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useLocalRoleInstanceTypes
      ),
    },
    {
      name: 'Role',
      datakey: 'role.name',
      width: 300,
    },
    {
      name: 'Subjekt',
      datakey: 'subject.label',
      width: 300,
    },
    {
      name: 'Platnost od',
      datakey: 'validFrom',
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: 'Platnost do',
      datakey: 'validTo',
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
  ];
}

export function useExternalIdsColumns(): TableFieldColumn<UserExternalId>[] {
  return [
    {
      datakey: 'identityProvider',
      name: 'Poskytovatel identity',
      width: 400,
      CellComponent: InlineTableFieldCells.useInlineSelectCellFactory<
        UserExternalId,
        { id: IdentityProvider; name: string }
      >({
        valueIsId: true,
        dataHook: useIdentityProviders,
        collectionDatakey: 'externalIds',
      }),
    },
  ];
}

function RoleConsentFilterCell({
  disabled,
  value,
  state,
  onChangeState,
}: FilterComponentProps) {
  const handleChange = useEventCallback((value) => {
    if (value === 'true' || value === 'false') {
      onChangeState({
        ...state,
        operation: ApiFilterOperation.EQ,
        value: value,
      });
    } else {
      onChangeState({
        ...state,
        operation: ApiFilterOperation.IS_NULL,
        value: 'null',
      });
    }
  });

  return (
    <Select<{ id: string; name: string }>
      disabled={disabled}
      source={useStaticListSource([
        {
          id: 'true',
          name: 'Udělen',
        },
        {
          id: 'false',
          name: 'Neudělen',
        },
        {
          id: 'null',
          name: 'Nepožadován',
        },
      ])}
      value={value}
      onChange={handleChange}
      valueIsId={true}
    />
  );
}

import React from 'react';
import { ProcessForm } from '../irz-facility-merge-types';

export function StepMainFacilityLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const irzFacility = allValues?.mainFacility;

  return (
    <>
      Cílová provozovna: <b>{irzFacility?.name}</b>
    </>
  );
}

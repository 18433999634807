import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { Subject } from '../../models';
import { addressValueMapperFactory } from '../../composite/subject-column-mappers/address-mapper';
import { useSubjectTypes } from '../subject/subjects-api';

export function useColumns(): TableColumn<Subject>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ subjektu',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useSubjectTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useSubjectTypes),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'identifier',
      name: 'IČO / UID',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'identityDocumentsConcatenated',
      name: 'OP / pas',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'foreignId',
      name: 'Zahr. identifikátor (obdoba IČO)',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'residence.municipality',
      sortkey: 'residence.municipality.name',
      filterkey: 'residence.municipality.name',
      name: 'Obec',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.cityDistrict',
      sortkey: 'residence.cityDistrict.name',
      filterkey: 'residence.cityDistrict.name',
      name: 'Městská část',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.street',
      sortkey: 'residence.street.name',
      filterkey: 'residence.street.name',
      name: 'Ulice',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.houseNumber',
      name: 'Číslo popisné',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.orientationNumber',
      name: 'Číslo orientační',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.addressPlaceRuian.isuiCode',
      sortkey: 'residence.addressPlace.isuiCode',
      filterkey: 'residence.addressPlace.isuiCode',
      name: 'Adresní kód',
      width: 200,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'author.username',
      name: 'Autor',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 10,
    },
  ];
}

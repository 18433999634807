import { useScrollableSource } from '@eas/common-web';
import { useColumns } from './role-other-columns';
import { User, RoleInstance } from '../../../../models';
import { EvidenceAPI } from '../../../../enums';

export function useOtherRoles(profile: User) {
  const source = useScrollableSource<RoleInstance>({
    url: `${EvidenceAPI.USERS}/${profile.id}/role/instance/list`,
  });

  const columns = useColumns();

  return {
    source,
    columns,
  };
}

import React from 'react';
import { FormTextField, FormAutocomplete } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useDistricts } from '../production-site/production-sites-api';

export function DictionaryCompetentControlAuthoritiesFields() {
  const districts = useDistricts();

  return (
    <>
      <FormTextField
        name="organizationName"
        label={
          <HelpLabel
            label="Název organizace"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_ORGANIZATION_NAME"
          />
        }
      />
      <FormTextField
        name="email"
        label={
          <HelpLabel
            label="E-mail"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_EMAIL"
          />
        }
      />
      <FormTextField
        name="phoneNumber"
        label={
          <HelpLabel
            label="Telefonní číslo"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_PHONE_NUMBER"
          />
        }
      />
      <FormTextField
        name="faxNumber"
        label={
          <HelpLabel
            label="Fax"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_FAX_NUMBER"
          />
        }
      />
      <FormTextField
        name="city"
        label={
          <HelpLabel
            label="Město"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_CITY"
          />
        }
      />
      <FormTextField
        name="street"
        label={
          <HelpLabel
            label="Ulice"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_STREET"
          />
        }
      />
      <FormTextField
        name="descriptiveNumber"
        label={
          <HelpLabel
            label="Číslo popisné"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_DESCRIPTIVE_NUMBER"
          />
        }
      />
      <FormTextField
        name="orientationNumber"
        label={
          <HelpLabel
            label="Číslo orientační"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_ORIENTATION_NUMBER"
          />
        }
      />
      <FormTextField
        name="zip"
        label={
          <HelpLabel
            label="PSČ"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_ZIP"
          />
        }
      />
      <FormTextField
        name="dataBox"
        label={
          <HelpLabel
            label="Datová schránka"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_DATA_BOX"
          />
        }
      />
      <FormAutocomplete
        name="districts"
        label={
          <HelpLabel
            label="Okresy"
            code="DICT_COMPETENT_CONTROL_AUTHORITIES_DISTRICTS"
          />
        }
        multiple={true}
        source={districts}
      />
    </>
  );
}

import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryIppcActivity } from '../../models';
import { useValidationSchema } from './dict-ippc-activities-schema';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { DictionaryIppcActivitiesFields } from './dict-ippc-activities-fields';

export function DictionaryIppcActivities() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryIppcActivity>({
    identifier: PageKey.DICTIONARY_IPPC_ACTIVITIES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_IPPC_ACTIVITIES,
    },
    tableProps: {
      tableName: 'Činnosti IPPC',
      reportTag: ExportTags.DICTIONARY_IPPC_ACTIVITIES,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.IPPC_ACTIVITY,
      codePrefix: 'IPPC_ACTIVITY',
      validationSchema,
      GeneralFieldsComponent: DictionaryIppcActivitiesFields,
      toolbarProps: {
        title: 'Činnost IPPC',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

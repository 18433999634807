import React from 'react';
import {
  FormTextField,
  FormPanel,
  FormCheckbox,
  FormContext,
  useFormSelector,
} from '@eas/common-web';
import { useContext } from 'react';
import { StepNameChangeForm } from './step-name-change-types';
import { HelpLabel } from '../../../components/help/help-label';

export function StepNameChangeContent() {
  const { setFieldValue } = useContext(FormContext);
  const { keepOriginalName } = useFormSelector(
    ({ keepOriginalName }: StepNameChangeForm) => ({
      keepOriginalName,
    })
  );

  return (
    <FormPanel label="Změna názvu provozovny">
      <FormCheckbox
        name="keepOriginalName"
        label={
          <HelpLabel
            label="Ponechat původní název"
            code="IRZ_FACILITY_REQUEST_OPERATOR_CHANGE_KEEP_ORIGINAL_NAME"
          />
        }
        notifyChange={() => setFieldValue('newName', '')}
      />
      {!keepOriginalName && (
        <FormTextField
          name="newName"
          label={
            <HelpLabel
              label="Nový název"
              code="IRZ_FACILITY_REQUEST_OPERATOR_CHANGE_NEW_NAME"
              required
            />
          } // Povinné pole, zadejte nový název, nebo zvolte možnost "Ponechat původní název"
        />
      )}
    </FormPanel>
  );
}

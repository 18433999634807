import { HistoryFieldDescriptor } from '../../../components/evidence/history-evidence/history-types';
import { useRoles } from '../reporting-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const roles = useRoles();

  return [
    {
      key: 'allowedRoles',
      label: 'Role',
      source: roles,
    },
  ];
}

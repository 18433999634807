import React, { useMemo } from 'react';
import { ListSource, Filter, ApiFilterOperation } from '@eas/common-web';
import { DomainObject } from '../../../../models';
import { protectedSelectFactory } from '../../../form/protected-select/protected-select';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface FilterCellProps {
  value: string;
  onChange: (value: any) => void;
}

export const useStyles = makeStyles(() => ({
  root: {
    padding: '0 8px',
    paddingRight: '50px !important',
    width: '100%',
    lineHeight: '32px',
  },
  input: {
    backgroundColor: '#fff',
    padding: '0 !important',
    height: 32,
    width: '100%',
  },
  item: {
    height: 25,
  },
}));

export function useSpecialFilterSelectCellFactory<OBJECT extends DomainObject>(
  dataHook: () => ListSource<OBJECT>,
  multiple?: boolean
) {
  return useMemo(
    () =>
      function FilterSelectCell({ value, onChange }: FilterCellProps) {
        const source = dataHook();
        const classes = useStyles();

        const Select = protectedSelectFactory<OBJECT>({
          variant: 'outlined',
          classesOverrides: {
            input: classes.input,
            root: classes.root,
            item: classes.item,
          },
        });

        return (
          <Select
            source={source}
            value={value}
            onChange={onChange}
            valueIsId={true}
            multiple={multiple}
          />
        );
      },
    [dataHook, multiple]
  );
}

export function constructMultiFilter(
  field: string,
  values: string[] | null
): Filter {
  return {
    field,
    value: values,
    operation: ApiFilterOperation.OR,
    filters:
      values?.map((value) => ({
        field,
        operation: ApiFilterOperation.EQ,
        value,
      })) ?? [],
  };
}

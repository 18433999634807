import React, { useContext } from 'react';
import { SubjectFormPanel } from '../../../composite/subject-fields/subject-form-panel';
import {
  FormCustomField,
  FormPanel,
  FormTextField,
  FormAutocomplete,
  useFormSelector,
  FormDateField,
  UserContext,
} from '@eas/common-web';
import {
  dictionaryLabelMapper,
  dictionaryNoCodeLabelMapper,
} from '../../../components/form/mappers/label-mappers';
import { EvidenceUrl, Permission } from '../../../enums';
import { IrzFacilityRequest } from '../../../models';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import { useCzRiverBasins } from '../../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { CompareText } from '../../../composite/comparator-fields/compare-text';
import { AddressLabel } from '../../../components/form/address-field/address-label';
import { DetailNavigationButton } from '../../../components/detail-navigation-button/detail-navigation-button';
import { HelpLabel } from '../../../components/help/help-label';

export function OperatorChangeFields() {
  const { hasPermission } = useContext(UserContext);

  const naceCzItems = useNaceCzItems();
  const czRiverBasins = useCzRiverBasins();
  const {
    irzFacility,
    newName,
    originalName,
    relatedSubject,
  } = useFormSelector(
    ({
      irzFacility,
      requestFacilityData,
      relatedSubject,
    }: IrzFacilityRequest) => ({
      irzFacility,
      originalName: irzFacility?.name,
      newName: requestFacilityData?.name,
      relatedSubject: relatedSubject,
    })
  );

  const isAdmin = hasPermission(
    Permission.IrzFacility.IRZ_REQUEST_CHANGE_OPERATOR_FINISH_ALL
  );

  const isOriginalOperator =
    hasPermission(
      Permission.IrzFacility.IRZ_REQUEST_CHANGE_OPERATOR_FINISH_OWN,
      {
        subjectId: relatedSubject?.id,
      }
    ) && !isAdmin;

  return (
    <>
      <FormPanel label="Datum změny provozovatele">
        <FormDateField
          name="date"
          label={
            <HelpLabel
              label="Datum, od kterého změna platí"
              code="IRZ_FACILITY_REQUESTS_DATE"
            />
          }
          disabled
        />
      </FormPanel>

      <SubjectFormPanel
        codePrefix="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA"
        autocomplete={{
          name: 'requestFacilityData.operator',
          label: 'Název provozovatele',
        }}
        label="Nový provozovatel"
        addressLabel="Adresa sídla"
      />
      <FormPanel label="Provozovna">
        {isAdmin && (
          <CompareText
            label="Název"
            newValue={newName}
            originalValue={originalName}
          />
        )}
        {!isAdmin && (
          <FormTextField
            name="requestFacilityData.name"
            label={
              <HelpLabel
                label="Název"
                code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_NAME"
              />
            }
          />
        )}
        <FormTextField
          name="requestFacilityData.facilityId"
          label={
            <HelpLabel
              label="IČP"
              code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_FACILITY_ID"
            />
          }
        />
        {(isAdmin || isOriginalOperator) && (
          <>
            <FormAutocomplete
              name="requestFacilityData.czNace"
              label={
                <HelpLabel
                  label="CZ-NACE"
                  code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_CZ_NACE"
                />
              }
              source={naceCzItems}
              tooltipMapper={dictionaryLabelMapper}
              labelMapper={dictionaryLabelMapper}
            />
            <FormAutocomplete
              name="requestFacilityData.czRiverBasinDistrict"
              label={
                <HelpLabel
                  label="Oblast povodí ČR"
                  code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_CZ_RIVER_BASIN_DISTRICT"
                />
              }
              source={czRiverBasins}
              tooltipMapper={dictionaryNoCodeLabelMapper}
              labelMapper={dictionaryNoCodeLabelMapper}
            />
            <AddressLabel
              name="requestFacilityData.address"
              code="IRZ_FACILITY_REQUEST_IRZ_FACILITY_ADDRESS"
            />
            <FormCustomField>
              <DetailNavigationButton
                url={EvidenceUrl.IRZ_FACILITIES}
                id={irzFacility?.id}
              />
            </FormCustomField>
          </>
        )}
      </FormPanel>

      {(isOriginalOperator || isAdmin) && (
        <SubjectFormPanel
          codePrefix="IRZ_FACILITY_REQUESTS"
          autocomplete={{
            name: 'relatedSubject',
            label: 'Název provozovatele',
          }}
          label="Původní provozovatel"
          addressLabel="Adresa sídla"
        />
      )}
    </>
  );
}

type TObj = Record<string, any>;

interface TOpts {
  delimiter?: string;
  maxDepth?: number;
  transformKey?: (k: string) => string;
  valueKey?: string;
  safe?: boolean;
}

function isBuffer(obj: any) {
  return (
    obj &&
    obj?.constructor &&
    typeof obj?.constructor?.isBuffer === 'function' &&
    obj?.constructor?.isBuffer(obj)
  );
}

function keyIdentity(key: string) {
  return key;
}

export function flatten(target: TObj, opts?: TOpts) {
  const delimiter = opts?.delimiter ?? '.';
  const maxDepth = opts?.maxDepth;
  const transformKey = opts?.transformKey ?? keyIdentity;
  const output: TObj = {};

  function step(object: TObj, prev?: string, currentDepth?: number) {
    currentDepth = currentDepth || 1;
    Object.keys(object).forEach(function (key) {
      const value = object[opts?.valueKey ?? key];
      const isarray = opts?.safe && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isbuffer = isBuffer(value);
      const isobject = type === '[object Object]' || type === '[object Array]';

      const newKey = prev
        ? prev + delimiter + transformKey(key)
        : transformKey(key);

      if (
        !isarray &&
        !isbuffer &&
        isobject &&
        Object.keys(value).length &&
        (!opts?.maxDepth || currentDepth! < maxDepth!)
      ) {
        return step(value, newKey, currentDepth! + 1);
      }

      output[newKey] = value;
    });
  }

  step(target);

  return output;
}

import React, { useRef, useContext } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  DomainObject,
  FormTextArea,
} from '@eas/common-web';
import { Note, NoteType } from '../../../../models/note';
import { EvidenceAPI, Messages, Permission } from '../../../../enums';
import { EntityType } from '../../../../enums/entity-type';
import { unstable_batchedUpdates } from 'react-dom';

/**
 * Api call
 */
function callApi(note: Omit<Note, 'id'>) {
  return abortableFetch(`${EvidenceAPI.NOTES}`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(note),
  });
}

/**
 * Vytvoření poznámky
 */
const PROMPT_KEY = 'CREATE_NOTE';

export function useCreateNoteDialog({
  entityType,
}: {
  entityType: EntityType;
}) {
  /**
   * Context stuff.
   */
  const { onPersisted, source, isExisting } = useContext<
    DetailHandle<DomainObject>
  >(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { reload, hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Vytvoření poznámky',
        dialogText: 'Vyplňte text poznámky',
        FormFields: function Body() {
          return (
            <>
              <FormTextArea labelOptions={{ hide: true }} name="noteText" />
            </>
          );
        },
        formValidationSchema: Yup.object().shape({
          noteText: Yup.string().nullable().required('Text je povinný'),
        }),
        dialogWidth: 600,
      },
    ],
    []
  );

  const handleCreateNote = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { noteText: string }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi({
            text: values.noteText,
            type: NoteType.PRIVATE, // Private are currently the only supported
            entityReference: {
              entityType,
              id: source.data?.id,
            },
          });

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Note.CREATE.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Note.CREATE.ERROR);
          }

          return true;
        }
      },
    });
  });

  const showCreateButton =
    isExisting && hasPermission(Permission.Note.NOTES_CREATE);

  return {
    handleCreateNote,
    showCreateButton,
  };
}

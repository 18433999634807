import { StepMergingFacilityForm } from './step-merging-facility/step-merging-facility-types';
import { StepEditForm } from './step-edit/step-edit-types';
import { StepSummaryForm } from './step-summary/step-summary-types';
import { StepMainFacilityForm } from './step-main-facility/step-main-facility-types';
import { StepOtherInformationForm } from './step-other-information/step-other-information-types';

export type ProcessForm = StepMainFacilityForm &
  StepMergingFacilityForm &
  StepEditForm &
  StepOtherInformationForm &
  StepSummaryForm;

export enum IrzMergeStateAction {
  INIT_MAIN_IRZ = 'INIT_MAIN_IRZ',
}

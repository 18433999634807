export enum PermissionMailing {
  /** Výpis mailových kampaní */
  MAILING_LIST = 'MAILING_LIST',

  /** Vytvoření mailové kampaně */
  MAILING_CREATE = 'MAILING_CREATE',

  /** Úprava mailové kampaně */
  MAILING_UPDATE = 'MAILING_UPDATE',

  /** Smazání mailové kampaně */
  MAILING_DELETE = 'MAILING_DELETE',

  /** Odeslání/Zrušení/Test mailové kampaně */
  MAILING_PUBLISH = 'MAILING_PUBLISH',
}

import React, { useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  DetailContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
  DetailMode,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useCreateNoteDialog } from './dialog-actions/create-note-hook';
import { useDeleteNoteDialog } from './dialog-actions/delete-note-hook';
import { useUpdateNoteDialog } from './dialog-actions/update-note-hook';
import { EntityType } from '../../../enums/entity-type';

export const useStyles = makeStyles((theme) => ({
  textButton: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableActions: {
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    // height: 30,
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 16px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  buttonGroup: {
    display: 'flex',
    marginBottom: 8,
    // marginLeft: -5, // offset first icon left padding

    '& button:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.grey['400']}`,
    },
  },
}));

export const notedToolbarFactory = (entityType: EntityType) =>
  function NotedToolbar({
    selectedIndex,
    setSelectedIndex,
  }: TableFieldToolbarProps) {
    const classes = useStyles();

    const { mode } = useContext(DetailContext);

    const { handleCreateNote, showCreateButton } = useCreateNoteDialog({
      entityType,
    });
    const { handleUpdateNote, showUpdateButton } = useUpdateNoteDialog({
      entityType,
      selectedIndex: selectedIndex,
    });
    const { handleDeleteNote, showDeleteButton } = useDeleteNoteDialog({
      selectedIndex: selectedIndex!,
      setSelectedIndex,
    });

    return (
      <div className={classes.tableActions}>
        <ButtonGroup className={classes.buttonGroup}>
          <TableFieldToolbarButton
            color="primary"
            variant="contained"
            title="Vytvoření poznámky"
            disabled={mode !== DetailMode.VIEW}
            show={showCreateButton}
            IconComponent={ControlPointIcon}
            onClick={handleCreateNote}
          >
            <Typography variant="body2">Přidat</Typography>
          </TableFieldToolbarButton>
          <TableFieldToolbarButton
            color="default"
            variant="text"
            title="Editace poznámky"
            disabled={mode !== DetailMode.VIEW || selectedIndex === undefined}
            show={showUpdateButton}
            IconComponent={EditIcon}
            onClick={handleUpdateNote}
          >
            <Typography variant="body2">Upravit</Typography>
          </TableFieldToolbarButton>
          <TableFieldToolbarButton
            color="secondary"
            variant="text"
            IconComponent={RemoveIcon}
            disabled={mode !== DetailMode.VIEW || selectedIndex === undefined}
            show={showDeleteButton}
            title="Smazání poznámky"
            onClick={handleDeleteNote}
          >
            <Typography variant="body2">Smazat</Typography>
          </TableFieldToolbarButton>
        </ButtonGroup>
      </div>
    );
  };

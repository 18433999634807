import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import {
  FormTextField,
  FormPanel,
  FormSelect,
  useFormSelector,
  FormAutocomplete,
  DetailContext,
  DetailHandle,
  FormInlineTableField,
  DetailMode,
  eqFilterParams,
  FormDateField,
  Checkbox,
  FormCustomField,
  FilesProvider,
  UserContext,
  EmptyComponent,
  Tooltip,
  FormFileField,
} from '@eas/common-web';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import {
  useAuthorRoles,
  useEmpowermentStates,
} from './empowerment-requests-api';
import {
  useSubjectTypes,
  useSubjectIdentificators,
} from '../subject/subjects-api';
import {
  EmpowermentRequest,
  AgendaType,
  SpecifiedEmpowermentRequest,
  EmpowermentSpecificationApprovalState,
  EmpowermentSpecificationRequest,
  AuthorRole,
  Me,
  PermissionsState,
  ValidationResult,
} from '../../models';
import { useAgendas, useAgendaTypes } from '../agenda/agendas-api';
import { highlightTableFieldRowFactory } from '../../components/form/inline-table-field/highlight-table-field-row';
import { useEmpowermentSpecificationRequestColumns } from './empowerment-requests-columns';
import { agendaLabelMapper } from '../../components/form/mappers/label-mappers';
import { noop } from 'lodash';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { SubjectBasicFields } from './fields/subject-basic-fields';
import { SubjectExtendedFields } from './fields/subject-extended-fields';
import { HelpLabel } from '../../components/help/help-label';
import { Permission } from '../../enums';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  validationResult: {
    cursor: 'pointer',
  },
  invalid: {
    fill: theme.palette.error.main,
  },
  valid: {
    fill: theme.palette.primary.main,
  },
}));

export const getValidationResultMessage = (result: ValidationResult) => {
  switch (result) {
    case ValidationResult.VALID:
      return 'Podpis dokumentu je platný';
    case ValidationResult.MISSING_USER_SIGN:
      return 'Dokument neobsahuje podpis';
    case ValidationResult.UNTRUSTED_USER_SIGN:
      return 'Podpis dokumentu není platný';
  }
};

export function EmpowermentRequestsFields(props: NotedEvidenceFieldsProps) {
  const classes = useStyles();

  const empowermentStates = useEmpowermentStates();
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const {
    authorRole,
    type,
    subject,
    otherSubject,
    validTo,
    empowermentDocument,
    certificateOfIncorporation,
  } = useFormSelector((data: EmpowermentRequest) => ({
    id: data.id,
    author: data.author,
    authorRole: data.authorRole,
    type: data.type,
    subject:
      data.authorRole === AuthorRole.AGENT ? !!data.agent : !!data.empowerer,
    otherSubject: data.otherSubject,
    validTo: data?.validTo,
    state: data.state,
    empowermentDocument: data.empowermentDocument,
    certificateOfIncorporation: data.certificateOfIncorporation,
  }));

  const authorRoles = useAuthorRoles();
  const subjectTypes = useSubjectTypes(otherSubject?.type);
  const subjectIdentificators = useSubjectIdentificators();
  const agendaTypes = useAgendaTypes();

  const params = eqFilterParams({ field: 'empowerable', value: true });
  const agendas = useAgendas(params);

  const { mode, source } = useContext<
    DetailHandle<SpecifiedEmpowermentRequest>
  >(DetailContext);

  const authorRoleLabel = authorRoles.items.find(
    (role) => role.id === authorRole
  )?.name;

  const otherSubjectRoleLabel = authorRoles.items.find(
    (role) => role.id !== authorRole
  )?.name;

  // TODO not needed
  const empowermentSpecificationRequestColumns = useEmpowermentSpecificationRequestColumns(
    {
      disabledState: (index: number) =>
        source.data?.specificationRequests?.[index]?.approvalState !==
        EmpowermentSpecificationApprovalState.NONE,
    }
  );

  const isAdmin = hasPermission(Permission.Empowerment.EMPOWERMENT_LIST_ALL, {
    searchInSubjectPerms: true,
  });

  const ceniaDetailsColumns = useMemo(
    () => [
      {
        datakey: 'number',
        name: 'Ev.č./Č.j.',
        width: 300,
      },
      {
        datakey: 'note',
        name: 'Poznámka',
        width: 300,
      },
    ],
    []
  );

  return (
    <>
      <FormPanel label="Žádost o zmocnění">
        <FormSelect
          name="state"
          label={
            <HelpLabel label="Stav žádosti" code="EMPOWERMENT_REQUEST_STATE" />
          }
          source={empowermentStates}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled={true}
        />
        <FormDateField
          name="validFrom"
          label={
            <HelpLabel
              label="Platnost od"
              code="EMPOWERMENT_REQUEST_VALID_FROM"
            />
          }
          disabled={true}
        />
        <FormDateField
          name="validTo"
          label={
            <HelpLabel
              label="Platnost do"
              code="EMPOWERMENT_REQUEST_VALID_TO"
            />
          }
          disabled={true}
        />
        {mode === DetailMode.VIEW && (
          <FormCustomField label="Na neurčito">
            <Checkbox onChange={noop} value={!validTo} disabled={true} />
          </FormCustomField>
        )}
        <FilesProvider url="/api/crzp/archived/file">
          <FormFileField
            name="empowermentDocument"
            label={
              <HelpLabel
                label="Příloha (plná moc konvertovaná)"
                code="EMPOWERMENT_REQUEST_EMPOWERMENT_DOCUMENT"
              />
            }
            // disabled={true}
            accept={['.pdf']}
            after={
              empowermentDocument?.validationResult && (
                <Tooltip
                  title={getValidationResultMessage(
                    empowermentDocument.validationResult
                  )}
                >
                  {empowermentDocument?.validationResult ===
                    ValidationResult.VALID && (
                    <CheckIcon
                      className={clsx(classes.validationResult, classes.valid)}
                    />
                  )}
                  {empowermentDocument?.validationResult !==
                    ValidationResult.VALID && (
                    <CloseIcon
                      className={clsx(
                        classes.validationResult,
                        classes.invalid
                      )}
                    />
                  )}
                </Tooltip>
              )
            }
          />
          {certificateOfIncorporation && (
            <FormFileField
              name="certificateOfIncorporation"
              label={
                <HelpLabel
                  label="Příloha (výpis z obchodního rejstříku)"
                  code="EMPOWERMENT_REQUEST_CERTIFICATE_OF_INCORPORATION"
                />
              }
            />
          )}
        </FilesProvider>
        <FormSelect
          name="type"
          label={
            <HelpLabel label="Typ agendy" code="EMPOWERMENT_REQUEST_TYPE" />
          }
          source={agendaTypes}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled={true}
        />
        <FormAutocomplete
          name="agenda"
          label={<HelpLabel label="Agenda" code="EMPOWERMENT_REQUEST_AGENDA" />}
          source={agendas}
          disabled={true}
          labelMapper={agendaLabelMapper}
          tooltipMapper={agendaLabelMapper}
        />
      </FormPanel>
      {authorRoleLabel && (
        <>
          <FormPanel
            label={`${authorRoleLabel} (autor žádosti) - základní informace`}
          >
            <SubjectBasicFields
              prefix="author"
              codePrefix="EMPOWERMENT_REQUEST"
              isAuthor={true}
            />
          </FormPanel>
          <FormPanel
            label={`${authorRoleLabel} (autor žádosti) - rozšířené`}
            expandable={true}
            defaultExpanded={false}
          >
            <SubjectExtendedFields
              prefix="author"
              codePrefix="EMPOWERMENT_REQUEST"
              isAuthor={true}
            />
          </FormPanel>
        </>
      )}
      {otherSubjectRoleLabel && (
        <>
          {!subject && (
            <FormPanel
              label={`${otherSubjectRoleLabel} - informace ze žádosti`}
            >
              <FormSelect
                name="otherSubject.type"
                label={
                  <HelpLabel
                    label="Typ identifikátoru subjektu"
                    code="EMPOWERMENT_REQUEST_OTHER_SUBJECT_TYPE"
                  />
                } // Přebráno ze žádosti
                source={subjectIdentificators}
                tooltipMapper={(o) => o.name}
                valueIsId={true}
                disabled={true}
              />
              <FormTextField
                name="otherSubject.value"
                label={
                  <HelpLabel
                    label="Číslo identifikátoru"
                    code="EMPOWERMENT_REQUEST_OTHER_SUBJECT_VALUE"
                  />
                } // Přebráno ze žádosti
                disabled={true}
              />
              <FormSelect
                name="otherSubject.subjectType"
                label={
                  <HelpLabel
                    label="Typ subjektu"
                    code="EMPOWERMENT_REQUEST_OTHER_SUBJECT_SUBJECT_TYPE"
                  />
                } // Přebráno ze žádosti
                source={subjectTypes}
                tooltipMapper={(o) => o.name}
                valueIsId={true}
                disabled={true}
              />
            </FormPanel>
          )}
          {subject && (
            <>
              <FormPanel
                label={`${otherSubjectRoleLabel} - základní informace`}
              >
                {authorRole === AuthorRole.AGENT && (
                  <SubjectBasicFields
                    prefix="empowerer"
                    codePrefix="EMPOWERMENT_REQUEST"
                    isAuthor={false}
                  />
                )}
                {authorRole === AuthorRole.EMPOWERER && (
                  <SubjectBasicFields
                    prefix="agent"
                    codePrefix="EMPOWERMENT_REQUEST"
                    isAuthor={false}
                  />
                )}
              </FormPanel>
              <FormPanel
                label={`${otherSubjectRoleLabel} - rozšířené`}
                expandable={true}
                defaultExpanded={false}
              >
                {authorRole === AuthorRole.AGENT && (
                  <SubjectExtendedFields
                    prefix="empowerer"
                    codePrefix="EMPOWERMENT_REQUEST"
                    isAuthor={false}
                  />
                )}
                {authorRole === AuthorRole.EMPOWERER && (
                  <SubjectExtendedFields
                    prefix="agent"
                    codePrefix="EMPOWERMENT_REQUEST"
                    isAuthor={false}
                  />
                )}
              </FormPanel>
            </>
          )}
        </>
      )}

      {type === AgendaType.SPECIFIED && (
        <FormPanel
          label="Typ hlášení"
          expandable
          defaultExpanded={false}
          summary={<FormPanelCountByName name="specificationRequests" />}
        >
          <FormInlineTableField<EmpowermentSpecificationRequest>
            name="specificationRequests"
            disabled
            labelOptions={{ hide: true }}
            layoutOptions={{ noSpacing: true }}
            RowComponent={highlightTableFieldRowFactory({
              isApprovedRow: (values: EmpowermentSpecificationRequest) =>
                values.approvalState ===
                EmpowermentSpecificationApprovalState.APPROVED,
              isRejectedRow: (values: EmpowermentSpecificationRequest) =>
                values.approvalState ===
                EmpowermentSpecificationApprovalState.REJECTED,
            })}
            columns={empowermentSpecificationRequestColumns}
          />
        </FormPanel>
      )}

      {isAdmin && (
        <FormPanel
          label="Dokumenty ve spisové službě"
          expandable
          defaultExpanded={false}
        >
          <FormTextField
            name="empowermentDocument.sequenceNumber"
            label={
              <HelpLabel
                label="Příloha (plná moc konvertovaná)"
                code="EMPOWERMENT_REQUEST_EMPOWERMENT_DOCUMENT"
              />
            }
            disabled
          />
          <FormInlineTableField
            name="ceniaDetails"
            label={
              <HelpLabel
                label="Spisová služba CENIA"
                code="SUBJECT_CENIA_DETAILS"
              />
            }
            disabled={true}
            ToolbarComponent={EmptyComponent}
            columns={ceniaDetailsColumns}
            showDetailBtnCond={() => false}
            showRadioCond={() => false}
          />
        </FormPanel>
      )}

      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <DatedFields />
      </FormPanel>
    </>
  );
}

import { DetailMode, DetailContext, PanelHandle } from '@eas/common-web';
import { useContext, useRef, useEffect } from 'react';

export function useExpandableFormPanel() {
  const ctx = useContext(DetailContext);
  const ref = useRef<PanelHandle>(null);
  const isEditing = ctx?.mode === DetailMode.EDIT;
  const isCreating = ctx?.mode === DetailMode.NEW;

  useEffect(() => {
    if (isCreating || isEditing) {
      ref?.current?.setExpanded(true);
    } else {
      ref?.current?.setExpanded(false);
    }
  }, [isCreating, isEditing]);

  return { ref };
}

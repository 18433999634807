import React, { useRef, useContext, useState } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  FormTextField,
  FormTextArea,
  FormSelect,
  useFormSelector,
  FormContext,
  useUpdateEffect,
  FormDateField,
  Checkbox,
  FormCustomField,
} from '@eas/common-web';
import {
  Me,
  BoilerManufacturer,
  DictionaryBoilerModel,
  DictionaryBoilerBrandAutocomplete,
  DictionaryBoilerBrand,
  DictionaryBoilerTypeAutocomplete,
} from '../../../models';
import { Messages, EvidenceAPI, Permission } from '../../../enums';
import { unstable_batchedUpdates } from 'react-dom';
import {
  useManufacturerBoilerBrands,
  useBoilerModelAllowedTypes,
} from '../../dict-boiler-brand/dict-boiler-brands-api';
import { HelpLabel } from '../../../components/help/help-label';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call
 *
 * @param model
 */
export function callAddApi(model: DictionaryBoilerModel) {
  return abortableFetch(`${EvidenceAPI.DICTIONARY_BOILER_MODELS}/`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(model),
  });
}

export function callUpdateApi(model: DictionaryBoilerModel) {
  return abortableFetch(`${EvidenceAPI.DICTIONARY_BOILER_MODELS}/${model.id}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(model),
  });
}

/**
 * Přidání modelu
 */
const PROMPT_KEY = 'ADD_MODEL';

export function useAddModelDialog({
  boilerModels,
  boilerBrands,
  selectedIndex,
  inDetailToolbar = false,
}: {
  boilerModels?: DictionaryBoilerModel[];
  boilerBrands?: DictionaryBoilerBrand[];
  selectedIndex: number | undefined;
  inDetailToolbar?: boolean;
}) {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext<DetailHandle<BoilerManufacturer>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission, reload } = useContext<UserContext<Me>>(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: `${PROMPT_KEY}${inDetailToolbar ? '_IN_TOOLBAR' : ''}`,
        dialogTitle:
          selectedIndex !== undefined
            ? 'Editace modelu'
            : 'Vytvoření modelu k výrobci',
        dialogText: 'Vyplňte údaje:',
        formInitialValues:
          selectedIndex !== undefined ? boilerModels?.[selectedIndex] : {},
        FormFields: function Body() {
          const { setFieldValue } = useContext(FormContext);
          const [withoutEnd, setWithoutEnd] = useState(false);

          const { brand, allowedTypes } = useFormSelector(
            (data: DictionaryBoilerModel) => ({
              brand: data.brand,
              allowedTypes: data.brand?.allowedTypes,
            })
          );

          const toggleWithoutEnd = useEventCallback(() => {
            setFieldValue('validTo', undefined);
            setWithoutEnd((current) => !current);
          });

          const boilerBrandsSource = useManufacturerBoilerBrands(
            boilerBrands as DictionaryBoilerBrandAutocomplete[]
          );

          const boilerTypes = useBoilerModelAllowedTypes(
            (allowedTypes || []) as DictionaryBoilerTypeAutocomplete[]
          );

          useUpdateEffect(() => {
            setFieldValue('type', undefined);
          }, [brand]);

          return (
            <>
              <FormTextField
                name="name"
                label={
                  <HelpLabel
                    label="Název"
                    code="BOILER_MANUFACTURER_ADD_MODEL_NAME"
                    required
                  />
                }
              />
              <FormSelect
                name="brand"
                source={boilerBrandsSource}
                label={
                  <HelpLabel
                    label="Značka kotle"
                    code="BOILER_MANUFACTURER_ADD_MODEL_BRAND"
                    required
                  />
                }
              />
              <FormSelect
                name="types"
                label={
                  <HelpLabel
                    label="Typy kotle"
                    code="BOILER_MANUFACTURER_ADD_MODEL_TYPES"
                    required
                  />
                }
                multiple={true}
                source={boilerTypes}
              />
              <FormDateField
                name="validFrom"
                label={
                  <HelpLabel
                    label="Platnost od"
                    code="BOILER_MANUFACTURER_ADD_MODEL_VALID_FROM"
                  />
                }
                representation="instant"
              />
              <FormDateField
                name="validTo"
                disabled={withoutEnd}
                label={
                  <HelpLabel
                    label="Platnost do"
                    code="BOILER_MANUFACTURER_ADD_MODEL_VALID_TO"
                  />
                }
                representation="instant"
              />
              <FormCustomField
                label={
                  <HelpLabel
                    label="Na neurčito"
                    code="BOILER_MANUFACTURER_ADD_MODEL_WITHOUT_END"
                  />
                }
              >
                <Checkbox onChange={toggleWithoutEnd} value={withoutEnd} />
              </FormCustomField>
              <FormTextArea
                name="description"
                label={
                  <HelpLabel
                    label="Popis"
                    code="BOILER_MANUFACTURER_ADD_MODEL_DESCRIPTION"
                  />
                }
              />
            </>
          );
        },
        formValidationSchema: Yup.object().shape({
          name: Yup.string().nullable().required('Povinné pole'),
          brand: Yup.mixed<DictionaryBoilerBrandAutocomplete>()
            .nullable()
            .required('Povinné pole'),
          types: Yup.array<DictionaryBoilerTypeAutocomplete>()
            .nullable()
            .min(1, 'Vyberte alespoň 1 typ kotle')
            .required('Povinné pole'),
        }),
        dialogWidth: 600,
      },
    ],
    [source.data, selectedIndex, boilerModels]
  );

  const handleAddModel = useEventCallback(async () => {
    testPrompt({
      key: `${PROMPT_KEY}${inDetailToolbar ? '_IN_TOOLBAR' : ''}`,
      submitCallback: async (model: DictionaryBoilerModel) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          const manufacturerId = source?.data?.id;

          if (!manufacturerId) {
            return;
          }

          const completedModel = {
            manufacturer: { id: manufacturerId },
            ...model,
          };

          fetch.current =
            selectedIndex === undefined
              ? callAddApi(completedModel)
              : callUpdateApi(completedModel);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Ozo.ADD_MODEL.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(Messages.Ozo.ADD_MODEL, err.code);

            showSnackbar(...message);

            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const canAddModel =
    (boilerBrands?.length ?? 0) > 0 &&
    hasPermission(Permission.Ozo.OZO_MODEL_UPDATE, {
      searchInSubjectPerms: true,
    });

  const canEditModel =
    canAddModel &&
    selectedIndex !== undefined &&
    boilerModels?.[selectedIndex]?.active === true;

  return {
    handleAddModel,
    canAddModel,
    canEditModel,
  };
}

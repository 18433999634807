import React from 'react';
import { BoilerPermissionContext } from './boiler-permission-context';
import { useBoilerPermissions } from './boiler-permission-hook';

export function BoilerPermissionProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const contextData = useBoilerPermissions();

  return (
    <BoilerPermissionContext.Provider value={contextData}>
      {children}
    </BoilerPermissionContext.Provider>
  );
}

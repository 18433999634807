import React, { useEffect, useContext } from 'react';
import { useFormSelector, FormContext } from '@eas/common-web';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { ROSAddressPanel } from '../../../components/form/address-field/ros-address/ros-address-panel';
import { StepLocationForm } from './step-location-types';
import { ProcessForm } from '../irz-facility-requests-new-types';

export function StepLocationContent({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const { setFieldValue } = useContext(FormContext);
  const { wgs84Coordinates, districtRuian } = useFormSelector(
    (data: StepLocationForm) => ({
      wgs84Coordinates: data?.wgs84Coordinates,
      districtRuian: data.address?.districtRuian,
    })
  );

  useEffect(() => {
    setFieldValue('competentControlAuthorities', undefined);
  }, [districtRuian]);

  return (
    <>
      <ROSAddressPanel operatorId={allValues?.operator?.id} />
      <LocationPanel
        locationName="wgs84Coordinates"
        locationValue={wgs84Coordinates}
        addressName="address"
        disableForeignCountry={true}
        locationPanelLabel="Poloha provozovny"
        addressPanelLabel="Adresa provozovny"
        prefillCoordinates={false}
      />
    </>
  );
}

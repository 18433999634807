import { useState, useEffect, useMemo } from 'react';
import { AuthContextType, AuthTypeResponse, AuthType } from './auth-types';
import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

/**
 *  Endpoint for getting information about types of currently supported authentications
 */
function authTypeCall() {
  return abortableFetch(EvidenceAPI.AUTH_TYPE, {
    method: 'GET',
  });
}

export function useAuth(): AuthContextType {
  const [authType, setAuthType] = useState<AuthTypeResponse>({});
  const [isLoggingOut, setLoggingOut] = useState(false);

  const reloadAuthType = useEventCallback(async () => {
    try {
      const response = authTypeCall();
      const data = (await response.json()) as AuthTypeResponse;

      if (data.type === AuthType.FORM) {
        setAuthType({ ...data, loginUrl: '/prihlaseni' });
      } else {
        setAuthType(data);
      }
    } catch (e) {
      console.log(e);
    }
  });

  const logout = useEventCallback(
    async (automatic = false, samlLogout = false) => {
      // Prevent websocket events that fire during logout request
      // to fire logout action multiple times
      if (!isLoggingOut) {
        setLoggingOut(true);
        const form = document.createElement('form');
        const queries = [];
        if (automatic) {
          queries.push('automatic');
        }
        if (samlLogout) {
          queries.push('samlLogout=true');
        }
        const query = queries.join('&');
        form.method = 'POST';
        form.action = `/api/crzp/logout${query ? `?${query}` : ''}`;

        document.body.appendChild(form);
        form.submit();
      }
    }
  );

  useEffect(() => {
    reloadAuthType();
  }, []);

  return useMemo(
    () => ({
      loginUrl: authType.loginUrl,
      type: authType.type,
      baseEnviiamUrl: authType.baseEnviiamUrl,
      logout,
      twoFactorEnabled: authType.twoFactorEnabled,
    }),
    [authType, logout]
  );
}

import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useUnsubscribe } from './unsubscribe-hook';

export function NotificationPreferences() {
  const { secret } = useParams();
  const { push } = useHistory();
  const { unsubscribe } = useUnsubscribe();

  useEffect(() => {
    unsubscribe(secret);
    push('/crzp');
  }, []);

  return null;
}

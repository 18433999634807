import React, { useContext } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { UserContext, useFirstRender } from '@eas/common-web';
import { Homepage } from './modules/auth/homepage';
import { Login } from './modules/auth/login';
import { PublicPage } from './components/public-page/public-page';
import { RegistrationCompleted } from './modules/auth/registration-completed';
import { Registration } from './modules/auth/registration';
import { RegistrationInformation } from './modules/auth/registration-data';
import { PasswordReset } from './modules/auth/password-reset';
import { PasswordResetConfirm } from './modules/auth/password-reset-confirm';
import { PasswordResetRequestSent } from './modules/auth/password-reset-request-sent';
import { PasswordResetCompleted } from './modules/auth/password-reset-completed';
import { EmailVerification } from './modules/auth/email-verification';
import { DataboxVerification } from './modules/auth/databox-verification';
import { EvidenceUrl } from './enums';
import { NotificationPreferences } from './modules';

export function PublicApp() {
  const { user } = useContext(UserContext);
  const { push } = useHistory();
  const { pathname } = useLocation();

  useFirstRender(() => {
    if (
      user &&
      !pathname.includes(EvidenceUrl.EMAIL_VERIFICATION) &&
      !pathname.includes(EvidenceUrl.DATABOX_VERIFICATION)
    ) {
      push('/crzp');
    }
  });

  return (
    <PublicPage>
      <Switch>
        <Route path="/" exact>
          <Homepage />
        </Route>
        <Route path="/prihlaseni">
          <Login />
        </Route>
        <Route path="/registrace" exact={true}>
          <Registration />
        </Route>
        <Route path="/registrace/udaje" exact={true}>
          <RegistrationInformation />
        </Route>
        <Route
          path={[
            '/registrace/dokonceni',
            '/registrace/dokonceni/:activationCode',
          ]}
          exact={true}
        >
          <RegistrationCompleted />
        </Route>
        <Route path="/obnova-hesla" exact={true}>
          <PasswordReset />
        </Route>
        <Route path="/obnova-hesla/zadost-odeslana" exact={true}>
          <PasswordResetRequestSent />
        </Route>
        <Route path="/obnova-hesla/heslo-obnoveno" exact={true}>
          <PasswordResetCompleted />
        </Route>
        <Route path="/obnova-hesla/:secret" exact={true}>
          <PasswordResetConfirm />
        </Route>
        <Route path={`${EvidenceUrl.NOTIFICATION_PREFERENCES}/:secret`}>
          <NotificationPreferences />
        </Route>
        <Route path={`${EvidenceUrl.EMAIL_VERIFICATION}/:token`} exact={true}>
          <EmailVerification />
        </Route>
        <Route path={EvidenceUrl.DATABOX_VERIFICATION}>
          <DataboxVerification />
        </Route>
      </Switch>
    </PublicPage>
  );
}

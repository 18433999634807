import React, { useContext } from 'react';
import {
  FormTextField,
  FormPanel,
  FormSelect,
  FormNumberField,
  FormAutocomplete,
  useFormSelector,
  FormCustomField,
  TextField,
  FormCheckbox,
  FormTableField,
  EmptyComponent,
  DetailContext,
  DetailMode,
} from '@eas/common-web';
import { useTypes, useBasinEnterprises } from './water-usage-places-api';
import {
  WaterUsagePlace,
  WaterUsagePlacesType,
  RuianObjectAutocomplete,
} from '../../models';
import { WaterUsagePlacesGroundWaterAbstractionFields } from './fields/ground-water-abstraction-fields';
import { WaterUsagePlacesSurfaceWaterAbstractionFields } from './fields/surface-water-abstraction-fields';
import { WaterUserPlaceWaterAccumulationFields } from './fields/water-accumulation-fields';
import { WaterUserPlaceStreamDischargeFields } from './fields/stream-discharge-fields';
import { SubjectFormPanel } from '../../composite/subject-fields/subject-form-panel';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import {
  useMunicipalities,
  useCadastralTerritories,
} from '../../components/form/address-field/address-field-api';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { useNaceCzItems } from '../dict-nace-cz-item/dict-nace-cz-items-api';
import { useDictionaryWaterFlows } from '../dict-water-flow/dict-water-flows-api';
import { useDictionaryRiverSides } from '../dict-river-side/dict-river-sides-api';
import { WaterUserPlaceUndergroundDischargeFields } from './fields/underground-discharge-fields';
import { HelpLabel } from '../../components/help/help-label';
import { icoMaxLength } from '../subject/fields/subjects-utils';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import { usePartColumns } from '../water-management-decision/water-management-decisions-fields';
import { InactiveColoredTableFieldRow } from '../../components/form/inactive-colored/inactive-colored-table-field-row';

export function WaterUsagePlacesFields(props: NotedEvidenceFieldsProps) {
  const types = useTypes();
  const { mode } = useContext(DetailContext);

  const waterFlows = useDictionaryWaterFlows();
  const riverSides = useDictionaryRiverSides();
  const naceCzItems = useNaceCzItems();

  const { type, jtskCoordinates, wgs84Coordinates } = useFormSelector(
    (data: WaterUsagePlace) => ({
      type: data.type,
      municipality: data.municipality,
      jtskCoordinates: data.jtskCoordinates,
      wgs84Coordinates: data.wgs84Coordinates,
    })
  );

  const municipalities = useMunicipalities();
  const cadastralTerritories = useCadastralTerritories();
  const basinEnterprises = useBasinEnterprises();
  const columns = usePartColumns();

  return (
    <>
      <FormPanel label="Základní informace">
        <FormNumberField
          name="vhbNumber"
          label={
            <HelpLabel
              label="Číslo VHB"
              code="WATER_USAGE_PLACE_VHB_NUMBER"
              required
            />
          } // Povinné pole
        />
        <FormTextField
          name="name"
          label={
            <HelpLabel
              label="Název místa"
              code="WATER_USAGE_PLACE_NAME"
              required
            />
          } // Povinné pole
        />
        <FormCheckbox
          name="active"
          label={<HelpLabel label="Aktivní" code="WATER_USAGE_PLACE_ACTIVE" />}
          disabled
        />
        <FormSelect
          name="type"
          label={
            <HelpLabel
              label="Typ místa"
              code="WATER_USAGE_PLACE_TYPE"
              required
            />
          } // Povinné pole
          source={types}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          valueIsId={true}
        />
        <FormTextField
          name="hydrologicalSequenceNumber"
          label={
            <HelpLabel
              label="Číslo hydrologického pořadí"
              code="WATER_USAGE_PLACE_HYDROLOGICAL_SEQUENCE_NUMBER"
            />
          }
        />
        <FormTextField
          name="ispopId"
          label={
            <HelpLabel
              label="ID v systému ISPOP"
              code="WATER_USAGE_PLACE_ISPOP_ID"
            />
          }
        />
        <FormTextField
          name="ppId.id"
          label={
            <HelpLabel
              label="ID v databázi PP"
              code="WATER_USAGE_PLACE_PPID_ID"
              required
            />
          }
          disabled={mode !== DetailMode.NEW}
        />
        <FormSelect
          source={basinEnterprises}
          name="ppId.enterprise"
          label={
            <HelpLabel
              label="Podnik povodí"
              code="WATER_USAGE_PLACE_PPID_ENTERPRISE"
              required
            />
          }
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          valueIsId={true}
          disabled={mode !== DetailMode.NEW}
        />
        {mode === DetailMode.VIEW && (
          <>
            <FormCustomField
              name="wgs84Coordinates"
              label={
                <HelpLabel
                  label="Poloha WGS-84"
                  code="WATER_USAGE_PLACE_WGS_84_COORDINATES"
                />
              }
            >
              <TextField
                disabled
                value={
                  wgs84Coordinates
                    ? `[${wgs84Coordinates?.lat ?? '-'}, ${
                        wgs84Coordinates?.lon ?? '-'
                      }]`
                    : ''
                }
              />
            </FormCustomField>
            <FormCustomField
              name="jtskCoordinates"
              label={
                <HelpLabel
                  label="Poloha S-JTSK"
                  code="WATER_USAGE_PLACE_GEO_LOCATION"
                />
              }
            >
              <TextField
                disabled
                value={
                  jtskCoordinates
                    ? `[${jtskCoordinates?.x}, ${jtskCoordinates?.y}]`
                    : ''
                }
              />
            </FormCustomField>
          </>
        )}
        {mode !== DetailMode.VIEW && (
          <>
            <FormTextField
              label={
                <HelpLabel
                  label="Poloha WGS-84 - Zeměpisná šířka"
                  code="WATER_USAGE_PLACE_WGS_84_LAT"
                />
              }
              name="wgs84Coordinates.lat"
            />
            <FormTextField
              label={
                <HelpLabel
                  label="Poloha WGS-84 - Zeměpisná délka"
                  code="WATER_USAGE_PLACE_WGS_84_LON"
                />
              }
              name="wgs84Coordinates.lon"
            />
            <FormTextField
              type="number"
              label={
                <HelpLabel
                  label="Poloha S-JTSK - X"
                  code="WATER_USAGE_PLACE_WGS_JTSK_X"
                />
              }
              name="jtskCoordinates.x"
            />
            <FormTextField
              type="number"
              label={
                <HelpLabel
                  label="Poloha S-JTSK - Y"
                  code="WATER_USAGE_PLACE_JTSK_Y"
                />
              }
              name="jtskCoordinates.y"
            />
          </>
        )}
        <FormAutocomplete
          name="waterFlow"
          label={
            <HelpLabel label="Vodní tok" code="WATER_USAGE_PLACE_WATER_FLOW" />
          }
          source={waterFlows}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormTextField
          label={
            <HelpLabel
              label="Říční kilometr"
              code="WATER_USAGE_PLACE_RIVER_KM"
            />
          }
          name="riverKm"
          type="number"
        />
        {(type === WaterUsagePlacesType.SURFACE_WATER_ABSTRACTION ||
          type === WaterUsagePlacesType.STREAM_DISCHARGE) && (
          <FormAutocomplete
            label={
              <HelpLabel label="Břeh" code="WATER_USAGE_PLACE_RIVER_SIDE" />
            }
            name="riverSide"
            source={riverSides}
            labelMapper={dictionaryLabelMapper}
            tooltipMapper={dictionaryLabelMapper}
          />
        )}
        <FormAutocomplete
          source={naceCzItems}
          label={<HelpLabel label="CZ-NACE" code="WATER_USAGE_PLACE_CZ_NACE" />}
          name="czNace"
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete<RuianObjectAutocomplete>
          source={municipalities}
          label={
            <HelpLabel
              label="Obec"
              code="WATER_USAGE_PLACE_MUNICIPALITY"
              required
            />
          }
          name="municipality"
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete<RuianObjectAutocomplete>
          source={cadastralTerritories}
          label={
            <HelpLabel
              label="Katastrální území"
              code="WATER_USAGE_PLACE_CADASTRAL_TERRITORY"
            />
          }
          name="cadastralTerritory"
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormCheckbox
          name="requireVhPermission"
          label={
            <HelpLabel
              label="Požadovat VH povolení"
              code="WATER_USAGE_PLACE_REQUIRE_VH_PERMISSION"
            />
          }
        />
        <FormCheckbox
          name="requireVhProtectionZonePermission"
          label={
            <HelpLabel
              label="Požadovat VH povolení k ochranným pásmům"
              code="WATER_USAGE_PLACE_REQUIRE_VH_PROTECTION_ZONE_PERMISSION"
            />
          }
        />
        <FormCheckbox
          name="requireMap"
          label={
            <HelpLabel
              label="Požadovat mapu"
              code="WATER_USAGE_PLACE_REQUIRE_MAP"
            />
          }
        />
      </FormPanel>
      <SubjectFormPanel
        label="Provozovatel"
        codePrefix="WATER_USAGE_PLACE_OPERATOR"
        autocomplete={{
          name: 'operator',
          label: 'Provozovatel',
          required: true,
        }}
      />
      <FormPanel label="Povinný subjekt">
        <FormTextField
          name="obligatorySubject.ico"
          label={
            <HelpLabel
              label="IČO"
              code="WATER_USAGE_PLACE_OBLIGATORY_SUBJECT_ICO"
            />
          }
          onChangeEvent={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/gi, '');
          }}
          inputProps={{ maxLength: icoMaxLength }}
        />
        <FormTextField
          name="obligatorySubject.companyName"
          label={
            <HelpLabel
              label="Název firmy"
              code="WATER_USAGE_PLACE_OBLIGATORY_SUBJECT_COMPANY_NAME"
            />
          }
        />
        <FormTextField
          name="obligatorySubject.branchName"
          label={
            <HelpLabel
              label="Název pobočky"
              code="WATER_USAGE_PLACE_OBLIGATORY_SUBJECT_BRANCH_NAME"
            />
          }
        />
        <FormTextField
          name="obligatorySubject.address"
          label={
            <HelpLabel
              label="Sídlo"
              code="WATER_USAGE_PLACE_OBLIGATORY_SUBJECT_ADDRESS"
            />
          }
        />
      </FormPanel>
      <FormPanel
        label="Části rozhodnutí MUV"
        summary={<FormPanelCountByName name="waterManagementDecisionParts" />}
      >
        <FormTableField
          name="waterManagementDecisionParts"
          ToolbarComponent={EmptyComponent}
          labelOptions={{
            hide: true,
          }}
          layoutOptions={{
            noSpacing: true,
          }}
          maxRows={15}
          showDetailBtnCond={() => false}
          showRadioCond={() => false}
          visibleActionsColumn={false}
          columns={columns}
          RowComponent={InactiveColoredTableFieldRow}
        />
      </FormPanel>
      {type === WaterUsagePlacesType.GROUNDWATER_ABSTRACTION && (
        <WaterUsagePlacesGroundWaterAbstractionFields />
      )}
      {type === WaterUsagePlacesType.SURFACE_WATER_ABSTRACTION && (
        <WaterUsagePlacesSurfaceWaterAbstractionFields />
      )}
      {type === WaterUsagePlacesType.WATER_ACCUMULATION && (
        <WaterUserPlaceWaterAccumulationFields />
      )}
      {type === WaterUsagePlacesType.STREAM_DISCHARGE && (
        <WaterUserPlaceStreamDischargeFields />
      )}
      {type === WaterUsagePlacesType.UNDERGROUND_DISCHARGE && (
        <WaterUserPlaceUndergroundDischargeFields />
      )}

      <FormPanel label="Kontaktní údaje">
        <FormTextField
          name="contactDetails.companyName"
          label={
            <HelpLabel
              label="Název firmy"
              code="WATER_USAGE_PLACE_CONTACT_DETAILS_COMPANY_NAME"
            />
          }
        />
        <FormTextField
          name="contactDetails.branchName"
          label={
            <HelpLabel
              label="Název pobočky"
              code="WATER_USAGE_PLACE_CONTACT_DETAILS_BRANCH_NAME"
            />
          }
        />
        <FormTextField
          name="contactDetails.municipality"
          label={
            <HelpLabel
              label="Obec"
              code="WATER_USAGE_PLACE_CONTACT_DETAILS_MUNICIPALITY"
            />
          }
        />
        <FormTextField
          name="contactDetails.street"
          label={
            <HelpLabel
              label="Ulice"
              code="WATER_USAGE_PLACE_CONTACT_DETAILS_STREET"
            />
          }
        />
        <FormTextField
          name="contactDetails.houseNumber"
          label={
            <HelpLabel
              label="Číslo domovní"
              code="WATER_USAGE_PLACE_CONTACT_DETAILS_HOUSE_NUMBER"
            />
          }
        />
        <FormTextField
          name="contactDetails.orientationNumber"
          label={
            <HelpLabel
              label="Číslo orientační"
              code="WATER_USAGE_PLACE_CONTACT_DETAILS_ORIENTATION_NUMBER"
            />
          }
        />
        <FormTextField
          name="contactDetails.zip"
          label={
            <HelpLabel
              label="PSČ"
              code="WATER_USAGE_PLACE_CONTACT_DETAILS_ZIP"
            />
          }
        />
      </FormPanel>

      {props.Notes}
      {props.HistoryRecords}
    </>
  );
}

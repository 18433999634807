import { useEffect, useContext } from 'react';
import {
  FormContext,
  useFormSelector,
  useEventCallback,
} from '@eas/common-web';
import { StepNewProductionSiteForm } from './step-new-production-site-types';
import { ProcessForm } from '../production-site-split-types';

export function useStepNewProductionSite(allValues: ProcessForm) {
  const { setFieldValue } = useContext(FormContext);

  const { productionSiteId } = useFormSelector(
    ({ newProductionSite }: StepNewProductionSiteForm) => ({
      productionSiteId: newProductionSite?.id,
    })
  );

  const resetValues = useEventCallback(() => {
    setFieldValue('newProductionSite', allValues.originalProductionSite);
  });

  /**
   * If the facility was changed in the first step, we need to reset the form,
   * to have correct default values.
   */
  useEffect(() => {
    if (productionSiteId !== allValues.originalProductionSite?.id) {
      resetValues();
    }
  }, []);
}

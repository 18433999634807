import {
  useEventCallback,
  NavigationContext,
  DetailContext,
  EvidenceStateAction,
} from '@eas/common-web';
import { useContext } from 'react';
import { EvidenceUrl } from '../../../enums';

export function useRedirect() {
  /**
   * Context stuff.
   */
  const { navigate } = useContext(NavigationContext);
  const { source } = useContext(DetailContext);

  /**
   * Callback to redirect into subject registration detail.
   */
  const handleRedirect = useEventCallback(() => {
    navigate(EvidenceUrl.SUBJECT_REQUESTS, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: source.data?.subjectRegistration?.id,
    });
  });

  /**
   * Callback to redirect into new registration.
   */
  const handleNewRegistration = useEventCallback(() => {
    navigate(EvidenceUrl.SUBJECT_REQUESTS_NEW);
  });

  /**
   * Callback to redirect into evidence of subject registrations.
   */
  const handleAllRegistrations = useEventCallback(() => {
    navigate(EvidenceUrl.SUBJECT_REQUESTS);
  });

  return {
    handleRedirect,
    handleNewRegistration,
    handleAllRegistrations,
  };
}

export enum ExportTags {
  ALOG = 'ALOG',
  AGENDAS = 'AGENDAS',
  BOILER_MANUFACTURERS = 'BOILER_MANUFACTURERS',
  BOILER_PERMISSIONS = 'BOILER_PERMISSIONS',
  DICTIONARY_ADDRESS_PLACES = 'DICTIONARY_ADDRESS_PLACES',
  DICTIONARY_BALANCE_UNITS = 'DICTIONARY_BALANCE_UNITS',
  DICTIONARY_BOILER_BRANDS = 'DICTIONARY_BOILER_BRANDS',
  DICTIONARY_BOILER_MODELS = 'DICTIONARY_BOILER_MODELS',
  DICTIONARY_BOILER_TYPES = 'DICTIONARY_BOILER_TYPES',
  DICTIONARY_CADASTRAL_TERRITORY = 'DICTIONARY_CADASTRAL_TERRITORY',
  DICTIONARY_COHESION_REGION = 'DICTIONARY_COHESION_REGION',
  DICTIONARY_COMPETENT_AUTHORITIES = 'DICTIONARY_COMPETENT_AUTHORITIES',
  DICTIONARY_COMPETENT_CONTROL_AUTHORITIES = 'DICTIONARY_COMPETENT_CONTROL_AUTHORITIES',
  DICTIONARY_COUNTRIES = 'DICTIONARY_COUNTRIES',
  DICTIONARY_CZ_NACES = 'DICTIONARY_CZ_NACES',
  DICTIONARY_DECISION_TYPES = 'DICTIONARY_DECISION_TYPES',
  DICTIONARY_DEPOSITS = 'DICTIONARY_DEPOSITS',
  DICTIONARY_DIRP_AREAS = 'DIRP_AREAS',
  DICTIONARY_DIRP_AUTHORS = 'DIRP_AUTHORS',
  DICTIONARY_DIRP_LAWS = 'DIRP_LAWS',
  DICTIONARY_DIRP_LAW_PARAGRAPHS = 'DIRP_PARAGRAPHS',
  DICTIONARY_DIRP_TYPES = 'DIRP_TYPES',
  DICTIONARY_DISTRICT = 'DICTIONARY_DISTRICT',
  DICTIONARY_DISTRICT_ADMINISTRATION_PRAGUE = 'DICTIONARY_DISTRICT_ADMINISTRATION_PRAGUE',
  DICTIONARY_DISTRICT_CITY = 'DICTIONARY_DISTRICT_CITY',
  DICTIONARY_DISTRICT_CITY_PRAGUE = 'DICTIONARY_DISTRICT_CITY_PRAGUE',
  DICTIONARY_EPRTR_ACTIVITIES = 'DICTIONARY_EPRTR_ACTIVITIES',
  DICTIONARY_INDICATORS = 'DICTIONARY_INDICATORS',
  DICTIONARY_INFOS = 'DICTIONARY_INFOS',
  DICTIONARY_IPPC_ACTIVITIES = 'DICTIONARY_IPPC_ACTIVITIES',
  DICTIONARY_IRZ_ACTIVITIES = 'DICTIONARY_IRZ_ACTIVITIES',
  DICTIONARY_HYDROGEOLOGICAL_ZONES = 'DICTIONARY_HYDROGEOLOGICAL_ZONES',
  DICTIONARY_LEGAL_FORMS = 'DICTIONARY_LEGAL_FORMS',
  DICTIONARY_MINERAL_GROUPS = 'DICTIONARY_MINERAL_GROUPS',
  DICTIONARY_MUNICIPALITY = 'DICTIONARY_MUNICIPALITY',
  DICTIONARY_MUNICIPALITY_ORP = 'DICTIONARY_MUNICIPALITY_ORP',
  DICTIONARY_MUNICIPALITY_PARTS = 'DICTIONARY_MUNICIPALITY_PARTS',
  DICTIONARY_MUNICIPALITY_POU = 'DICTIONARY_MUNICIPALITY_POU',
  DICTIONARY_NACES = 'DICTIONARY_NACES',
  DICTIONARY_OVSS = 'DICTIONARY_OVSS',
  DICTIONARY_PROCESSING_PURPOSE = 'DICTIONARY_PROCESSING_PURPOSE',
  DICTIONARY_REGION = 'DICTIONARY_REGION',
  DICTIONARY_RIVER_BASIN_CZ_DISTRICTS = 'DICTIONARY_RIVER_BASIN_CZ_DISTRICTS',
  DICTIONARY_RIVER_BASIN_EU_DISTRICTS = 'DICTIONARY_RIVER_BASIN_EU_DISTRICTS',
  DICTIONARY_RIVER_SIDES = 'DICTIONARY_RIVER_SIDES',
  DICTIONARY_SAMPLE_TYPES = 'DICTIONARY_SAMPLE_TYPES',
  DICTIONARY_SEQUENCES = 'DICTIONARY_SEQUENCES',
  DICTIONARY_SEWER_TYPES = 'DICTIONARY_SEWER_TYPES',
  DICTIONARY_STREET = 'DICTIONARY_STREET',
  DICTIONARY_TERRITORIAL_TECHNICAL_UNITS = 'DICTIONARY_TERRITORIAL_TECHNICAL_UNITS',
  DICTIONARY_WATER_FLOWS = 'DICTIONARY_WATER_FLOWS',
  DICTIONARY_WATER_ORIGINS = 'DICTIONARY_WATER_ORIGINS',
  DICTIONARY_WATER_TREATMENT_METHODS = 'DICTIONARY_WATER_TREATMENT_METHODS',
  DIRP_RECORDS = 'DIRP_RECORDS',
  EMPOWERMENTS = 'EMPOWERMENTS',
  EMPOWERMENT_REQUESTS = 'EMPOWERMENT_REQUESTS',
  EXPORT_ACCESS_RULES = 'EXPORT_ACCESS_RULES',
  IRZ_FACILITIES = 'IRZ_FACILITIES',
  IRZ_FACILITY_REQUESTS = 'IRZ_FACILITY_REQUESTS',
  ISPOP_DOCUMENTS = 'ISPOP_DOCUMENTS',
  MAILING = 'MAILING',
  NOTIFICATIONS = 'NOTIFICATIONS',
  NOTIFICATION_TEMPLATES = 'NOTIFICATION_TEMPLATES',
  NOTIFICATION_BATCHES = 'NOTIFICATION_BATCHES',
  OUTAGES = 'OUTAGES',
  OVZ_FACILITIES = 'OVZ_FACILITIES',
  OVZ_FACILITY_REQUESTS = 'OVZ_FACILITY_REQUESTS',
  PERSONAL_DATA = 'PERSONAL_DATA',
  PRODUCTION_SITES = 'PRODUCTION_SITES',
  PROTECTED_DEPOSIT_AREAS = 'PROTECTED_DEPOSIT_AREAS',
  PROTECTED_DEPOSIT_AREAS_DETAIL = 'PROTECTED_DEPOSIT_AREAS_DETAIL',
  PUBLIC_LISTS = 'PUBLIC_LISTS',
  PUBLIC_LIST_PUBLISHINGS = 'PUBLIC_LIST_PUBLISHINGS',
  REPORTING = 'REPORTING',
  REPORTING_ACCESS_RULES = 'REPORTING_ACCESS_RULES',
  EXPORT_TEMPLATE = 'EXPORT_TEMPLATE',
  ROLES = 'ROLES',
  ROLE_INSTANCES = 'ROLE_INSTANCES',
  ROLE_REQUESTS = 'ROLE_REQUESTS',
  SUBJECTS = 'SUBJECTS',
  SUBJECT_REQUESTS = 'SUBJECT_REQUESTS',
  SYSTEMS = 'SYSTEMS',
  THIRD_PARTY_SYSTEMS = 'THIRD_PARTY_SYSTEMS',
  TRANSLATIONS = 'TRANSLATIONS',
  UNCONFIRMED_USER_REGISTRATIONS = 'UNCONFIRMED_USER_REGISTRATIONS',
  USERS = 'USERS',
  USER_ROLES = 'USER_ROLES',
  USER_ROLES_EMPOWERED = 'USER_ROLES_EMPOWERED',
  USER_ROLES_OTHER = 'USER_ROLES_OTHER',
  WATER_MANAGEMENT_DECISIONS = 'WATER_MANAGEMENT_DECISIONS',
  WATER_MANAGEMENT_DECISION_PARTS = 'WATER_MANAGEMENT_DECISION_PARTS',
  WATER_USAGE_PLACES = 'WATER_USAGE_PLACES',
}

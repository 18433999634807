import React, { useRef, useContext } from 'react';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  InlineTableField,
  Checkbox,
  TableFieldCells,
} from '@eas/common-web';
import { SubjectRegistration } from '../../../models';
import { EvidenceAPI, Permission, Messages } from '../../../enums';
import {
  useNotificationEvents,
  useNotificationEventOptions,
} from '../notification-templates-api';

/**
 * Api call
 *
 * @param id
 */
export function callApi(id: string, value: boolean) {
  return abortableFetch(
    `${EvidenceAPI.NOTIFICATION_EVENTS}/${id}?optional=${value.toString()}`,
    {
      method: 'PUT',
    }
  );
}

/**
 * Approve subject registration.
 */
const PROMPT_KEY = 'NOTIFICATION_EVENTS';

export function useNotificationEventsDialog() {
  /**
   * Context stuff.
   */
  const { source } = useContext<DetailHandle<SubjectRegistration>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Notifikačné události',
      dialogText: 'Seznam notifikačních událostí',
      dialogWidth: 800,
      FormFields: function Body() {
        const eventSource = useNotificationEvents();
        const [options, , resetOptions] = useNotificationEventOptions();

        return (
          <InlineTableField
            onChange={() => {}}
            value={eventSource.items}
            withRemove={false}
            showToolbar={false}
            columns={[
              {
                name: 'Název',
                datakey: 'name',
                width: 200,
              },
              {
                name: 'Volitelná',
                datakey: 'canBeOptional',
                width: 200,
                CellComponent: function Cell(props) {
                  if (!props.value) {
                    return (
                      <TableFieldCells.TextCell
                        {...props}
                        value="Ne a nemožno změnit"
                      />
                    );
                  }

                  return (
                    <Checkbox
                      onChange={() => {
                        handleCanBeOptional(
                          props.rowValue.id,
                          !options?.[props.rowValue.id]
                        );

                        resetOptions();
                      }}
                      value={!!options?.[props.rowValue.id]}
                    />
                  );
                },
              },
            ]}
          />
        );
      },
    },
  ]);

  const handleCanBeOptional = useEventCallback(
    async (id: string, value: boolean) => {
      try {
        source.setLoading(true);

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callApi(id, value);

        await fetch.current.raw();
      } catch (err) {
        if (err.name !== 'AbortError') {
          showSnackbar(...Messages.Notification.SET_OPTIONAL.ERROR);
          throw err;
        }
        return undefined;
      } finally {
        source.setLoading(false);
      }
    }
  );

  const handleNotificationEvents = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {},
    });
  });

  const showNotificationEventsButton = hasPermission(
    Permission.Notification.NOTIFICATION_EVENT_LIST
  );

  return {
    handleNotificationEvents,
    showNotificationEventsButton,
  };
}

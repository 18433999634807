import React, { useContext, useRef } from 'react';
import { noop } from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailMode,
  DetailHandle,
  DetailToolbarButtonType,
  useEventCallback,
  DialogHandle,
  DetailActionbarButton,
} from '@eas/common-web';
import { PublicList } from '../../models/public-list';
import { ExternalShareDialog } from './share-dialog/external-share-dialog';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function TranslationsActionbar() {
  const { mode, source } = useContext<DetailHandle<PublicList>>(DetailContext);

  const externalShareDeleteDialog = useRef<DialogHandle>(null);

  const showExternalShare = useEventCallback(() => {
    const list = source.data;

    if (list != null) {
      externalShareDeleteDialog.current?.open();
    }
  });

  const code = source.data?.code;

  return (
    <>
      {mode === DetailMode.VIEW && (
        <DetailActionbarButton
          label="Externí odkaz"
          type={DetailToolbarButtonType.NORMAL}
          onClick={showExternalShare}
        />
      )}
      <ExternalShareDialog
        ref={externalShareDeleteDialog}
        onCancel={noop}
        code={code}
      />
    </>
  );
}

import { DomainObject, DomainObjectAutocomplete } from './domain-object';
import { DictionaryObject } from './dictionary-object';
import { SubjectRole, Role } from './role';

/**
 * Typ agendy
 */
export enum AgendaType {
  /**
   * Základní agenda
   */
  BASIC = 'BASIC',

  /**
   * Agenda se specifikacemi
   */
  SPECIFIED = 'SPECIFIED',
}

/**
 * Stav schválení žádosti o specifikaci zmocnění
 */
export enum ApprovalState {
  /**
   * Nevyřešená
   */
  NONE = 'NONE',

  /**
   * Schválena
   */
  APPROVED = 'APPROVED',

  /**
   * Zamítnuta
   */
  REJECTED = 'REJECTED',
}

export enum AgendaTag {
  OZO_PERSON = 'OZO_PERSON',
  OZO_MANUFACTURER = 'OZO_MANUFACTURER',
}

/**
 * Povinnost přiřazení agendy na subjektech
 */
export enum EmpowermentAgendaBehavior {
  /**
   * Neřešit stav agendy
   */
  NOTHING = 'NOTHING',

  /**
   * Přidat agendu zmocněnci i zmocniteli
   */
  ADD_TO_BOTH = 'ADD_TO_BOTH',

  /**
   * Ověřovat zda zmocnitel má agendu
   */
  VALIDATE_EMPOWERER = 'VALIDATE_EMPOWERER',
}

/**
 * Specifikace
 */
export interface AgendaSpecification extends DomainObject {
  /**
   * Název
   */
  name?: string;

  /**
   * Popis
   */
  description?: string;

  /**
   * Subjektová role
   */
  subjectRole?: SubjectRole;
}

/**
 * Agenda
 */
export interface Agenda extends DictionaryObject {
  /**
   * Typ agendy
   */
  type?: AgendaType;

  /**
   * Popis podporované agendy dle legislativy
   */
  description?: string;

  /**
   * Příznak automatického priřazení agendy k subjektu
   * pokud je true, agenda se automaticky přiřadí k subjektu při jeho vytvoření
   */
  autoAssign?: boolean;

  /**
   * Příznak, jestli se subjekt může sám rozhodnout agendu vykonávat
   */
  selfAssignable?: boolean;

  /**
   * Příznak, jestli může být agenda předmětem zmocnění
   */
  empowerable?: boolean;

  /**
   * Role, které mohou agendu přidělit
   */
  assignerRoles?: Role[];

  /**
   * Štítky agendy
   */
  tags?: AgendaTag[];

  /**
   * Povinnost přiřazení agendy na subjektech
   */
  empowermentBehavior?: EmpowermentAgendaBehavior;
}

/**
 * Agenda bez specifikací
 */
export interface BasicAgenda extends Agenda {
  /**
   * Typ agendy
   */
  type: AgendaType.BASIC;

  /**
   * Subjektové role, které subjekt získá pokud má přidělenou tuhle agendu
   */
  subjectRole?: SubjectRole;
}

/**
 * Agenda se specifikacemi
 */
export interface SpecifiedAgenda extends Agenda {
  /**
   * Typ agendy
   */
  type: AgendaType.SPECIFIED;

  /**
   * Seznam specifikací
   */
  specifications?: AgendaSpecification[];
}

export interface AgendaAutocomplete extends DomainObjectAutocomplete {
  /**
   * Typ agendy
   */
  type?: AgendaType;
}

export type AgendaUnion = BasicAgenda | SpecifiedAgenda;

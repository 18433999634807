import React, { useMemo } from 'react';
import { Tooltip } from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import { SimpleHistoryField as SimpleHistoryFieldProps } from '../../../../models';
import { useStyles } from './history-field-styles';
import { HistoryFieldProps } from '../history-types';
import { useHistoryFieldTooltip } from './history-field-tooltip-hook';

export function SimpleHistoryField({
  valueBefore,
  valueAfter,
  source,
}: HistoryFieldProps<SimpleHistoryFieldProps>) {
  const classes = useStyles();

  const parsedBefore = useMemo(() => {
    if (valueBefore && source) {
      return (
        source.items.find((i) => i.id === valueBefore?.value)?.name ??
        valueBefore?.value
      );
    } else {
      return valueBefore?.value;
    }
  }, [source, valueBefore]);

  const parsedAfter = useMemo(() => {
    if (valueAfter && source) {
      return (
        source.items.find((i) => i.id === valueAfter?.value)?.name ??
        valueAfter?.value
      );
    } else {
      return valueAfter?.value;
    }
  }, [source, valueAfter]);

  const {
    ref: afterRef,
    useTooltip: useAfterTooltip,
  } = useHistoryFieldTooltip();

  const {
    ref: beforeRef,
    useTooltip: useBeforeTooltip,
  } = useHistoryFieldTooltip();

  const beforeContent = (
    <span className={classes.textDeleted} ref={beforeRef}>
      {' '}
      - {parsedBefore}
    </span>
  );

  const afterContent = (
    <span className={classes.textAdded} ref={afterRef}>
      {' '}
      + {parsedAfter}
    </span>
  );

  return (
    <Typography variant="body1" className={classes.content}>
      {parsedBefore &&
        (useBeforeTooltip ? (
          <Tooltip title={parsedBefore} placement="top-start" type="HOVER">
            {beforeContent}
          </Tooltip>
        ) : (
          beforeContent
        ))}
      {parsedAfter &&
        (useAfterTooltip ? (
          <Tooltip title={parsedAfter} placement="top-start" type="HOVER">
            {afterContent}
          </Tooltip>
        ) : (
          afterContent
        ))}
    </Typography>
  );
}

import React from 'react';
import { DetailActionbarButton } from '@eas/common-web';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useNotificationEventsDialog } from './dialog-actions/event-hook';
import { useTestNotification } from './actions/test-hook';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function NotificationTemplatesActionbar() {
  const {
    handleNotificationEvents,
    showNotificationEventsButton,
  } = useNotificationEventsDialog();

  const {
    handleTestNotification,
    showTestNotificationButton,
  } = useTestNotification();

  return (
    <>
      {showNotificationEventsButton && (
        <DetailActionbarButton
          startIcon={<DateRangeIcon />}
          label="Notifikační události"
          // tooltip="Seznam notifikačních událostí"
          onClick={handleNotificationEvents}
        />
      )}
      {showTestNotificationButton && (
        <DetailActionbarButton
          label="Ukázka"
          // tooltip="Ukázka notifikace"
          onClick={handleTestNotification}
        />
      )}
    </>
  );
}

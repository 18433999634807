import React, { useContext, useMemo, ComponentType } from 'react';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Button from '@material-ui/core/Button';
import { DetailContext } from '@eas/common-web';
import { Map } from './../../components/map/map';
import { MarkerLayer } from '../../components/map/marker-layer';
import { Markers } from '../../components/map/markers';
import { MapContext } from '../../components/map/map-ctx';
import {
  MapEvidenceContext,
  MapEvidenceView,
} from '../../components/evidence/map-evidence/map-context';
import { useCardData } from './actions/irz-facility-map-hook';
import { IrzFacility } from '../../models';
import { EvidenceAPI } from '../../enums';

export function FacilityMarkerTitle({ name }: { name: string }) {
  return (
    <>
      <Typography variant="body2">
        Název provozovny: <b>{name}</b>
      </Typography>
    </>
  );
}

export function FacilityMarkerBody({
  data,
  setActive,
  Actions,
}: {
  data: IrzFacility;
  setActive: (id: string) => void;
  Actions?: ComponentType<{ data: IrzFacility }>;
}) {
  return (
    <>
      <Typography variant="body2">
        IČP: <b>{data?.facilityId}</b>
      </Typography>
      <br />
      <Typography variant="body2">
        Adresní místo: <b>{data?.address?.addressPlaceRuian?.label}</b>
      </Typography>
      <Typography component="div" variant="body2">
        <>
          <p>
            Zeměpisná šířka: <b>{data?.wgs84Coordinates?.lat}</b>
          </p>
          <p>
            Zeměpisná délka: <b>{data?.wgs84Coordinates?.lon}</b>
          </p>
        </>
      </Typography>
      <br />
      <ButtonGroup variant="contained">
        <Button
          size="small"
          variant="contained"
          onClick={() => setActive(data.id)}
        >
          Spravovat
        </Button>
        {Actions && <Actions data={data} />}
      </ButtonGroup>
      <br />
      <br />
    </>
  );
}

export function IrzFacilitiesMap() {
  return (
    <Map api={EvidenceAPI.IRZ_FACILITIES}>
      <IrzFacilitiesMapBody />
    </Map>
  );
}

function IrzFacilitiesMapBody() {
  const { items, map } = useContext(MapContext);
  const { setView } = useContext(MapEvidenceContext);
  const { setActive, onPersisted, source } = useContext(DetailContext);

  const activateFacility = (id: string) => {
    setActive(id);
    onPersisted(id);
    setView(MapEvidenceView.DETAIL);
  };
  const { cardData } = useCardData(activateFacility);

  const maxZoom = map?.getZoom() === map?.getZoomRange()[1];

  const markers = useMemo(() => {
    if (maxZoom) {
      const markers = items.map((item) => ({
        id: item.id,
        lat: item.lat,
        lng: item.lon,
        active: item.active,
        selected: item.id === source.data?.id,
      }));

      const object: { [key: string]: number } = {};
      const result: typeof markers = [];

      markers.forEach((marker) => {
        const key = `${marker.lat}+${marker.lng}`;
        if (object[key] === undefined) {
          object[key] = 0;
          result.push(marker);
        } else {
          object[key] += 1;
          result.push({ ...marker, lng: marker.lng + 0.0001 * object[key] });
        }
      });

      return result;
    } else {
      return items.map((item) => ({
        id: item.id,
        lat: item.lat,
        lng: item.lon,
        active: item.active,
        selected: item.id === source.data?.id,
      }));
    }
  }, [items, source.data, maxZoom]);

  return (
    <MarkerLayer>
      <Markers markers={markers} card={cardData} />
    </MarkerLayer>
  );
}

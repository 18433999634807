import React from 'react';
import { DetailToolbarProps, DetailToolbar2 } from '@eas/common-web';
import { useSaveDialog } from './dialog-actions/save-hook';
import { User } from '../../models';

export function UsersToolbar(props: DetailToolbarProps<User>) {
  const { handleSave } = useSaveDialog();

  return <DetailToolbar2 {...props} handleSave={handleSave} />;
}

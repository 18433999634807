import React, { useContext } from 'react';
import {
  FormPanel,
  FormTableField,
  TableFieldColumn,
  Tooltip,
  TableFieldCellProps,
  SnackbarContext,
  useEventCallback,
  SnackbarVariant,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { FormPanelCountByName } from '../../form/form-panel-summary/form-panel-count';
import { HistoryFieldType, SimpleLog, SimpleLogChange } from '../../../models';
import { HistoryFieldDescriptor } from './history-types';
import { SimpleLogDetail } from './fields/simple-log-detail';

interface HistoryFieldsProps {
  descriptors: HistoryFieldDescriptor[];
  permission: string;
  name?: string;
}

export function historyContentCell(
  descriptors: HistoryFieldDescriptor[],
  value: SimpleLogChange
) {
  const changeDescriptor = descriptors.find((d) => d.key === value.key);
  if (changeDescriptor?.valueMapper) {
    if (value.valueAfter) {
      value.valueAfter = {
        value: changeDescriptor.valueMapper(value.valueAfter),
        type: HistoryFieldType.STRING,
      };
    }
    if (value.valueBefore) {
      value.valueBefore = {
        value: changeDescriptor.valueMapper(value.valueBefore),
        type: HistoryFieldType.STRING,
      };
    }
  }

  return <SimpleLogDetail change={value} descriptors={descriptors} />;
}

export function HistoryFields({
  descriptors,
  permission,
  name = 'history',
}: HistoryFieldsProps) {
  const columns: TableFieldColumn<SimpleLog>[] = [
    {
      name: '',
      datakey: 'copy',
      width: 50,
      CellComponent: CopyCell,
    },
    {
      name: 'Datum',
      datakey: 'created',
      width: 200,
      CellComponent: function Cell({ value }) {
        return (
          <Typography variant="body1">
            {new Date(value)?.toLocaleString()}
          </Typography>
        );
      },
    },
    {
      name: 'Autor',
      datakey: 'createdBy.name',
      width: 150,
      CellComponent: function Cell({ value }) {
        return (
          <Typography variant="body1">{value || 'Systém CRŽP'}</Typography>
        );
      },
    },
    {
      name: 'Typ změny',
      datakey: 'content.key',
      width: 250,
      CellComponent: function Cell({ value }) {
        const changeDescriptor = descriptors.find((d) => d.key === value);

        return (
          <Typography variant="body1">
            {changeDescriptor?.label || value}
          </Typography>
        );
      },
    },
    {
      name: 'Změna',
      datakey: 'content',
      width: 250,
      CellComponent: ({ value }) => historyContentCell(descriptors, value),
    },
    {
      name: 'Poznámka',
      datakey: 'note',
      width: 250,
    },
  ];

  return (
    <FormPanel
      label="Historie"
      expandable
      defaultExpanded={false}
      summary={
        <FormPanelCountByName
          name={name}
          columns={columns}
          permission={permission}
        />
      }
    >
      <FormTableField<SimpleLog>
        name={name}
        labelOptions={{
          hide: true,
        }}
        showToolbar={false}
        showRadioCond={() => false}
        showDetailBtnCond={() => false}
        visibleActionsColumn={false}
        maxRows={15}
        disabled={true}
        columns={columns}
        layoutOptions={{
          noSpacing: true,
        }}
      />
    </FormPanel>
  );
}

function CopyCell({ rowValue }: TableFieldCellProps<SimpleLog>) {
  const { showSnackbar } = useContext(SnackbarContext);

  const handleCopy = useEventCallback(() => {
    if (rowValue.note) {
      navigator.clipboard.writeText(rowValue.note);

      const message = 'Úspěšně jste zkopírovali poznámku.';

      showSnackbar(message, SnackbarVariant.SUCCESS);
    }
  });

  return (
    <>
      {rowValue?.note && (
        <Tooltip title="Kopírovat poznámku">
          <FileCopyIcon
            fontSize="small"
            color="primary"
            style={{ cursor: 'pointer', marginRight: 10 }}
            onClick={handleCopy}
          />
        </Tooltip>
      )}
    </>
  );
}

export async function get<TYPE>({
  url,
  signal,
}: {
  url: string;
  signal: AbortSignal;
}) {
  try {
    const response = await fetch(`${url}`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      signal,
    });

    const json: TYPE = await response.json();

    return [json, response] as const;
  } catch (e) {
    return [undefined];
  }
}

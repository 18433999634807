import React, { useContext, ElementType } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButtonType,
  EmptyComponent,
  DetailActionbarButton,
} from '@eas/common-web';
import { useActivateDialog } from './dialog-actions/activate-hook';
import { useDeactivateDialog } from './dialog-actions/deactivate-hook';
import { Permission } from '../../../enums';
import { stubTrue } from 'lodash';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function DictionaryActionbar({
  url,
  invalidatePerm = Permission.Dictionary.DICTIONARY_INVALIDATE,
  revalidatePerm = Permission.Dictionary.DICTIONARY_REVALIDATE,
  showBtn = stubTrue,
  CustomActions = EmptyComponent,
}: {
  url: string;
  invalidatePerm?: string;
  revalidatePerm?: string;
  showBtn?: (button: 'INVALIDATE' | 'REVALIDATE') => boolean;
  CustomActions?: ElementType;
}) {
  const { mode } = useContext(DetailContext);

  const { handleActivate, showActivateButton } = useActivateDialog({
    url,
    revalidatePerm,
  });
  const { handleDeactivate, showDeactivateButton } = useDeactivateDialog({
    url,
    invalidatePerm,
  });

  const showInvalidate = showBtn('INVALIDATE');
  const showRevalidate = showBtn('REVALIDATE');

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          {showActivateButton && showRevalidate && (
            <DetailActionbarButton
              startIcon={<CheckIcon fontSize="small" />}
              label="Obnovit platnost"
              onClick={handleActivate}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {showDeactivateButton && showInvalidate && (
            <DetailActionbarButton
              startIcon={<CloseIcon fontSize="small" />}
              label="Zneplatnit"
              onClick={handleDeactivate}
              type={DetailToolbarButtonType.SECONDARY}
            />
          )}
          {CustomActions && <CustomActions />}
        </div>
      )}
    </>
  );
}

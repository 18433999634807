import React, { useCallback, useState, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import {
  Editable,
  withReact,
  Slate,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor, Descendant } from 'slate';

import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import CodeIcon from '@material-ui/icons/Code';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import { deserialize } from './utils/deserialize';
import { serialize } from './utils/serialize';
import { toggleMark } from './utils/toggle-mark';

import { Element } from './parts/element';
import { Leaf } from './parts/leaf';
import { MarkButton } from './parts/mark-button';
import { BlockButton } from './parts/block-button';
import { WrapLinkButton } from './parts/wrap-link-button';
import { UnwrapLinkButton } from './parts/unwrap-link-button';

import { TextFormat } from './rich-editor-types';
import { useStyles as useTranslationStyles } from '../help/help-block';

const HOTKEYS: { [key: string]: TextFormat } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

export function RichEditor({
  initialValue,
  onChange,
  disabled,
}: {
  initialValue: string;
  onChange: (value: string) => void;
  disabled: boolean;
}) {
  const classes = useTranslationStyles();

  const [value, setValue] = useState<Descendant[]>(() => {
    const document = new DOMParser().parseFromString(initialValue, 'text/html');
    const slateValue = deserialize(document.body);

    return slateValue as Descendant[];
  });

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );

  return (
    <div style={{ width: '100%' }}>
      <Slate
        editor={editor}
        value={value}
        onChange={(value) => {
          setValue(value);
          onChange(serialize(value));
        }}
      >
        <div>
          <MarkButton
            format="bold"
            icon={<FormatBoldIcon fontSize="small" />}
          />
          <MarkButton
            format="italic"
            icon={<FormatItalicIcon fontSize="small" />}
          />
          <MarkButton
            format="underline"
            icon={<FormatUnderlinedIcon fontSize="small" />}
          />
          <MarkButton format="code" icon={<CodeIcon fontSize="small" />} />
          <WrapLinkButton />
          <UnwrapLinkButton />
          <BlockButton
            format="heading-one"
            icon={<Filter1Icon fontSize="small" />}
          />
          <BlockButton
            format="heading-two"
            icon={<Filter2Icon fontSize="small" />}
          />
          <BlockButton
            format="block-quote"
            icon={<FormatQuoteIcon fontSize="small" />}
          />
          <BlockButton
            format="numbered-list"
            icon={<FormatListNumberedIcon fontSize="small" />}
          />
          <BlockButton
            format="bulleted-list"
            icon={<FormatListBulletedIcon fontSize="small" />}
          />
        </div>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck={true}
          autoFocus={false}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
          readOnly={disabled}
          className={classes.editor}
        />
      </Slate>
    </div>
  );
}

import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { SoapMessageLog } from '../../models';
import { SoapMessageLogsFields } from './soap-message-logs-fields';
import { useColumns } from './soap-message-logs-columns';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';

export function SoapMessageLogs() {
  const permissions = usePermission({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });
  const evidence = useDatedEvidence<SoapMessageLog>({
    version: 2,
    identifier: PageKey.SOAP_MESSAGE_LOGS,
    apiProps: {
      url: EvidenceAPI.SOAP_MESSAGE_LOGS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Logy SOAP zpráv',
      showReportButton: false,
      showColumn: (col: string) => {
        if (col === 'UPDATED' || col === 'CREATED') {
          return false;
        }
        return true;
      },
    },
    detailProps: {
      entityName: EntityName.SOAP_MESSAGE_LOG,
      FieldsComponent: SoapMessageLogsFields,
      toolbarProps: {
        showBtn: permissions,
        title: 'SOAP zpráva',
        ActionBar: DetailEmptyActionbar,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import React from 'react';
import { stubFalse } from 'lodash';
import { Evidence2 } from '@eas/common-web';
import { PublicListPublishingsFields } from './public-list-publishings-fields';
import { useColumns } from './public-list-publishings-columns';
import { EvidenceAPI, PageKey } from '../../../enums';
import { ExportTags } from '../../../enums/export-tags';
import { Publishing } from '../../../models/public-list';
import { useDatedEvidence } from '../../../components/evidence/dated-evidence/dated-evidence';

export function PublicListPublishings() {
  const evidence = useDatedEvidence<Publishing>({
    identifier: PageKey.PUBLIC_LIST_PUBLISHINGS,
    apiProps: {
      url: EvidenceAPI.PUBLIC_LIST_PUBLISHINGS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Publikace veřejných seznamů',
      reportTag: ExportTags.PUBLIC_LIST_PUBLISHINGS,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: PublicListPublishingsFields,
      toolbarProps: {
        title: 'Publikace veřejného seznamu',
        showBtn: stubFalse,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import { useContext, useState, useRef, useEffect } from 'react';
import {
  SnackbarContext,
  FormHandle,
  useEventCallback,
  AbortableFetch,
  abortableFetch,
  SnackbarVariant,
} from '@eas/common-web';
import { useIntl } from 'react-intl';
import { unstable_batchedUpdates } from 'react-dom';
import { useFormError } from './form-error-hook';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import { validatePassword } from '../../profile/profile-utils';
import { useAbortableFetch } from '../../../utils/abortable-fetch';
import { PasswordResetConfirmData } from '../auth-types';

interface RouteParams {
  secret: string;
}

export function usePasswordResetConfirm() {
  const { setErrors, getFieldError } = useFormError();

  const { push } = useHistory();

  const { showSnackbar } = useContext(SnackbarContext);

  const { secret } = useParams<RouteParams>();

  const [loading, setLoading] = useState<boolean>(false);

  const ref = useRef<FormHandle<PasswordResetConfirmData>>(null);

  const intl = useIntl();

  const fetch = useRef<AbortableFetch | null>(null);

  const [secretIsValid, setSecretIsValid] = useState<boolean | undefined>(
    undefined
  );

  const validationSchema = Yup.object<PasswordResetConfirmData>().shape({
    newPassword: validatePassword,
    newPasswordAgain: Yup.string()
      .nullable()
      .required('Heslo znovu musí být vyplněné')
      .oneOf(
        [Yup.ref('newPassword')],
        'Zopakované heslo se neshoduje s heslem'
      ),
  });

  const handleNavigateBack = useEventCallback(() => {
    push('/prihlaseni');
  });

  const handleNavigateToReset = useEventCallback(() => {
    push('/obnova-hesla');
  });

  const callSubmit = (data: PasswordResetConfirmData) => {
    return abortableFetch('/api/crzp/user/password/reset/confirm', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        newPassword: data.newPassword,
        secret,
      }),
    });
  };

  const submitPasswordResetConfirm = useEventCallback(
    async (data: PasswordResetConfirmData) => {
      try {
        setLoading(true);

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callSubmit(data);

        await fetch.current.raw();

        unstable_batchedUpdates(() => {
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);

        if (e.name !== 'AbortError') {
          const message = intl.formatMessage(
            {
              id: 'KS_P_REGISTRATION_MSG_ERROR',
              defaultMessage: 'Chyba vytvoření hesla: {detail}',
            },
            { detail: e.message }
          );

          showSnackbar(message, SnackbarVariant.ERROR);

          throw e;
        }
        return undefined;
      }
    }
  );

  const handleSubmit = useEventCallback(
    async (data: PasswordResetConfirmData) => {
      if (ref.current != undefined) {
        const errors = await ref.current.validateForm();

        setErrors(errors);

        if (errors.length) {
          return;
        }

        await submitPasswordResetConfirm(data);

        push('/obnova-hesla/heslo-obnoveno');
      }
    }
  );

  const sendSecretIsValid = () => {
    return abortableFetch('/api/crzp/user/password/reset/secret', {
      method: 'POST',
      body: secret,
    });
  };

  const getSecretIsValid = useAbortableFetch(async (fetch) => {
    try {
      fetch = sendSecretIsValid();

      await fetch.raw();

      unstable_batchedUpdates(() => {
        setSecretIsValid(true);
      });
    } catch (err) {
      setSecretIsValid(false);
      return undefined;
    }
  });

  useEffect(() => {
    getSecretIsValid?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ref,
    validationSchema,
    loading,
    handleNavigateBack,
    handleNavigateToReset,
    handleSubmit,
    getFieldError,
    secretIsValid,
  };
}

import { IrzFacilityRequest, SimpleLog } from '../../models';
import {
  useAutocompleteSource,
  DictionaryAutocomplete,
  useListSource,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { post } from '../../utils/custom-crud';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import { parseHistoryItems } from '../../components/evidence/history-evidence/history-api';

export async function combineGetCalls({
  api,
  itemId,
  signal,
  mainItem,
}: CombineCallsType) {
  const json = {
    ...mainItem,
  };

  /**
   * Append agendas by itemId.
   */
  const [diff] = await post<SimpleLog>({
    url: `${api}/${itemId}/diff`,
    signal,
  });

  if (diff) {
    json['diff'] = parseHistoryItems([
      {
        ...diff,
        content: JSON.parse((diff?.content ?? '[]') as any),
      },
    ]);
  }

  const text = JSON.stringify(json);

  return {
    data: json,
    text,
  };
}

export function useIrzFacilityRequests() {
  return useAutocompleteSource<IrzFacilityRequest>({
    url: `${EvidenceAPI.IRZ_FACILITY_REQUESTS}/list`,
  });
}

export function useRequestTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.IRZ_FACILITY_REQUESTS + '/type/list',
    method: 'GET',
  });
}

export function useRequestStates() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.IRZ_FACILITY_REQUESTS + '/state/list',
    method: 'GET',
  });
}

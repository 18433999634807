import React, { useEffect, useState, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import { SnackbarContext, SnackbarVariant } from '@eas/common-web';
import { completeRegistration } from './auth-api';
import {
  BreadcrumbCtx,
  initialBreadcrumbs,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { PageKey, Messages } from '../../enums';

const useStyles = makeStyles((theme) =>
  createStyles({
    actions: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '18px',
    },
    buttonText: {
      color: 'white',
      fontWeight: 600,
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
    },
  })
);

export function RegistrationCompleted() {
  const classes = useStyles();

  const { push } = useHistory();
  const { showSnackbar } = useContext(SnackbarContext);
  const { setBreadcrumbs } = useContext(BreadcrumbCtx);

  const { activationCode } = useParams<{
    activationCode: string;
  }>();

  const [pending, setPending] = useState<boolean>(false);
  const [status, setStatus] = useState<number | undefined>();

  /**
   * If `activationCode` is present in url params, call CompleteRegistrationApi
   */
  useEffect(() => {
    const callCompleteRegistration = async () => {
      setPending(true);

      const response = await completeRegistration(activationCode).response;

      setStatus(response.status);

      if (!response.ok) {
        showSnackbar(...Messages.User.REGISTRATION.COMPLETION_ERROR);
      } else {
        showSnackbar(
          Messages.User.REGISTRATION_STATUS_MESSAGE[200],
          SnackbarVariant.SUCCESS
        );
      }

      setPending(false);
    };

    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Dokončení registrace',
        key: PageKey.REGISTRATION_COMPLETED,
      },
    ]);

    if (activationCode) {
      callCompleteRegistration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToLogin = () => {
    push('/prihlaseni');
  };

  const getStatusMessage = (status?: number) => {
    switch (status) {
      case 200:
        return Messages.User.REGISTRATION_STATUS_MESSAGE[200];
      case 403:
        return Messages.User.REGISTRATION_STATUS_MESSAGE[403];
      case 404:
        return Messages.User.REGISTRATION_STATUS_MESSAGE[404];
      default:
        return Messages.User.REGISTRATION_STATUS_MESSAGE.PENDING;
    }
  };

  return (
    <>
      {activationCode && !pending && (
        <>
          <Typography variant="body1" align="center">
            {getStatusMessage(status)}
          </Typography>
          {status === 200 && (
            <div className={classes.actions}>
              <Button
                onClick={handleGoToLogin}
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                <Typography
                  component="span"
                  variant="body1"
                  className={classes.buttonText}
                >
                  Přihlásit se
                </Typography>
              </Button>
            </div>
          )}
        </>
      )}
      {!activationCode && (
        <Typography variant="body1" align="center">
          Potvrďte registraci kliknutím na zaslaný odkaz.
        </Typography>
      )}
    </>
  );
}

import { StepOtherInformationForm } from './../ovz-facility-request-operator-change/step-other-information/step-other-information-types';
import { StepIdentificatorsForm } from './step-identificators/step-identificators-types';
import { StepSubjectForm } from './step-subject/step-subject-types';
import { StepMainFacilityForm } from './step-main-facility/step-main-facility-types';
import { StepLocationForm } from './step-location/step-location-types';
import { StepActivitiesForm } from './step-activities/step-activities-types';
import { StepSummaryForm } from './step-summary/step-summary-types';
import { StepMainFacilityEditForm } from './step-main-facility-edit/step-main-facility-edit-types';
import { StepBasicInfoForm } from './step-basic-info/step-basic-info-types';

export type ProcessForm = StepMainFacilityForm &
  StepSubjectForm &
  StepBasicInfoForm &
  StepIdentificatorsForm &
  StepLocationForm &
  StepActivitiesForm &
  StepMainFacilityEditForm &
  StepOtherInformationForm &
  StepSummaryForm;

export enum IrzSplitStateAction {
  INIT_MAIN_IRZ = 'INIT_MAIN_IRZ',
}

import React from 'react';
import { FormPanel, FormAutocomplete } from '@eas/common-web';
import { SubjectAutocomplete } from '../../../models';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useStepOriginalOperator } from './step-original-operator-hook';
import { HelpLabel } from '../../../components/help/help-label';

export function StepOriginalOperatorContent() {
  const { isOnlySubject, subjectSource } = useStepOriginalOperator(
    'oldOperator'
  );

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete<SubjectAutocomplete>
        name="oldOperator"
        label={
          <HelpLabel
            label="Původní provozovatel"
            code="OVZ_FACILITY_REQUEST_OPERATOR_CHANGE_OLD_OPERATOR"
            required
          />
        } // Povinné pole pokud subjekt nebyl automaticky přidělen
        source={subjectSource}
        disabled={isOnlySubject}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

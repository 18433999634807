import React from 'react';
import { FormAutocomplete } from '@eas/common-web';
import { useEuRiverBasins } from '../dict-river-basin-eu-district/dict-river-basin-eu-district-api';
import { HelpLabel } from '../../components/help/help-label';
import { dictionaryNoCodeLabelMapper } from '../../components/form/mappers/label-mappers';

export function DictRiverBasinCzDistrictsFields() {
  const euRiverBasins = useEuRiverBasins();

  return (
    <FormAutocomplete
      name="euRiverBasinDistrict"
      label={
        <HelpLabel
          label="Oblast povodí Evropy"
          code="DICT_RIVER_BASIN_CZ_EU_RIVER_BASIN_DISTRICT"
        />
      }
      source={euRiverBasins}
      labelMapper={dictionaryNoCodeLabelMapper}
      tooltipMapper={dictionaryNoCodeLabelMapper}
    />
  );
}

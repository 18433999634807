import React from 'react';
import { StepSummaryForm } from './step-summary-types';
import { ProcessForm } from '../subject-requests-types';

export function StepSummaryLabel({
  stepValues,
}: {
  stepValues?: StepSummaryForm;
  allValues?: ProcessForm;
}) {
  const state = stepValues?.isSent ? 'ČEKÁ NA AUTORIZACI' : 'NEODESLÁNO';

  return (
    <>
      Stav registrace: <b>{state}</b>
    </>
  );
}

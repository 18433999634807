import React from 'react';
import { stubFalse } from 'lodash';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  Detail,
  DetailToolbar2,
  EvidenceContext,
  SplitScreen,
} from '@eas/common-web';
import { makeStyles } from '@material-ui/core/styles';
import { Map } from './overview-map';
import { ActionBar } from './overview-map-actionbar';
import { Tables } from './overview-map-tables';
import { OverviewMapProvider } from './overview-map-provider';

const useStyles = makeStyles({
  evidence: {
    flexGrow: 1,
    flexShrink: 1,
  },
});

export function OverviewMap() {
  const classes = useStyles();

  return (
    <EvidenceContext.Provider value={{} as never}>
      <OverviewMapProvider>
        <div className={classes.evidence} data-cy="EAS__EVIDENCE">
          <AutoSizer disableWidth={true}>
            {({ height }) => (
              <div style={{ height, display: 'flex' }}>
                <SplitScreen
                  rightLabel="Mapa"
                  leftLabel="Přehled"
                  midPoint={600}
                  defaultTitle="Přehledová mapa"
                >
                  <Tables height={height} />
                  <Detail
                    source={{} as never}
                    FieldsComponent={Map}
                    ToolbarComponent={DetailToolbar2}
                    toolbarProps={{
                      ActionBar: ActionBar,
                      title: 'Přehledová mapa',
                      showBtn: stubFalse,
                    }}
                  />
                </SplitScreen>
              </div>
            )}
          </AutoSizer>
        </div>
      </OverviewMapProvider>
    </EvidenceContext.Provider>
  );
}

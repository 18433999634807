import React from 'react';
import { usePersonalEvents } from './personal-events-hook';
import { Table } from '@eas/common-web';

export function PersonalEvents() {
  const { source, columns } = usePersonalEvents();

  return (
    <Table
      tableName="Události"
      columns={columns}
      source={source}
      height={300}
      showBulkActionButton={false}
      showSelectBox={false}
      showSearchbar={false}
      defaultSorts={[
        {
          field: 'created',
          datakey: 'created',
          type: 'FIELD',
          order: 'DESC',
        },
      ]}
    />
  );
}

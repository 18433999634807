import React from 'react';
import {
  Evidence2,
  ApiFilterOperation,
  DetailEmptyActionbar,
} from '@eas/common-web';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useColumns } from './unconfirmed-user-registrations-columns';
import { UnconfirmedUserRegistrationsFields } from './unconfirmed-user-registrations-fields';
import { EntityType } from '../../enums/entity-type';
import { ExportTags } from '../../enums/export-tags';
import { EvidenceAPI, PageKey } from '../../enums';
import { User, UserRegistration } from '../../models';

export function UnconfirmedUserRegistrations() {
  const permissions = usePermission<User>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useDatedEvidence<UserRegistration>({
    version: 2,
    identifier: PageKey.UNCONFIRMED_USER_REGISTRATIONS,
    noteEntityType: EntityType.USER_REGISTRATION,
    apiProps: {
      url: EvidenceAPI.USERS_REGISTRATIONS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled neaktivovaných uživatelů',
      reportTag: ExportTags.UNCONFIRMED_USER_REGISTRATIONS,
      defaultSorts: [],
      defaultPreFilters: [
        {
          field: 'confirmed',
          operation: ApiFilterOperation.EQ,
          value: 'false',
        },
      ],
    },
    detailProps: {
      FieldsComponent: UnconfirmedUserRegistrationsFields,
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Uživatel',
        subtitle: useSubTitle<UserRegistration>((data) => data?.username ?? ''),
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import React, { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import * as Yup from 'yup';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  FormFileField,
  FileRef,
  abortableFetch,
  DetailMode,
  PromptContext,
  usePrompts,
  FilesProvider,
} from '@eas/common-web';
import {
  SubjectRegistration,
  SubjectRegistrationState,
  Me,
} from '../../../models';
import { EvidenceAPI, Messages } from '../../../enums';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call
 *
 * @param id
 * @param documentId
 */
function callApi(id: string, documentId: string) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECT_REQUESTS}/${id}/authorize/signed-document/${documentId}`,
    {
      method: 'POST',
    }
  );
}

/**
 * Authorize subject registration.
 */
const PROMPT_KEY = 'AUTHORIZE';

export function useAuthorizeDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source, mode } = useContext<
    DetailHandle<SubjectRegistration>
  >(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { user, reload } = useContext<UserContext<Me>>(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Autorizovat registraci',
      dialogText: 'Dokončete registraci nahráním podepsaného dokumentu.',
      FormFields: function Body() {
        return (
          <FilesProvider url={EvidenceAPI.ARCHIVED_FILES}>
            <FormFileField
              name="documentId"
              labelOptions={{
                hide: true,
              }}
            />
          </FilesProvider>
        );
      },
      formValidationSchema: Yup.object().shape({
        documentId: Yup.mixed()
          .nullable()
          .required('Nahrajte podepsaný dokument'),
      }),
      dialogWidth: 600,
    },
  ]);

  const handleAuthorize = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { documentId: FileRef }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(source.data!.id, values.documentId.id);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.SubjectRegistration.AUTHORIZE.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.SubjectRegistration.AUTHORIZE,
              err?.details?.validationResult ?? err?.code
            );

            showSnackbar(...message);

            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showAuthorizeButton =
    mode === DetailMode.VIEW &&
    source.data?.state === SubjectRegistrationState.WAITING_FOR_AUTHORIZATION &&
    source.data?.createdBy?.id === user?.id;

  return {
    handleAuthorize,
    showAuthorizeButton,
  };
}

import { EvidenceAPI } from './../../enums/evidence-api';
import { abortableFetch } from '@eas/common-web';
import { useEffect, useState } from 'react';
import { BoilerPermissionContextType } from './boiler-permission-context';

export function useBoilerPermissions(): BoilerPermissionContextType {
  const [hasBoilerPermissions, setHasBoilerPermissions] = useState(false);

  useEffect(() => {
    async function getBoilerPermissions() {
      try {
        const boilerPermissions = abortableFetch(
          `${EvidenceAPI.BOILER_PERMISSIONS}/list`,
          {
            method: 'POST',
          }
        );

        const data = await boilerPermissions.json();
        setHasBoilerPermissions(data.count > 0);
      } catch (e) {
        setHasBoilerPermissions(false);
      }
    }

    getBoilerPermissions();
  }, []);

  return {
    hasBoilerPermissions: hasBoilerPermissions,
  };
}

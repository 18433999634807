import React from 'react';
import { CommissionedLocalAuthorityMunicipality } from '../../models';
import { PageKey, EvidenceAPI, EntityName } from '../../enums';

import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';
import DictionaryRuianCommissionedLocalAuthorityMunicipalityFields from './dict-ruian-commissioned-local-authority-municipality-fields';

export function DictionaryRuianCommissionedLocalAuthorityMunicipality() {
  const evidence = useRuianEvidence<CommissionedLocalAuthorityMunicipality>({
    identifier:
      PageKey.DICTIONARY_RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY,
    apiProps: {
      url: EvidenceAPI.ADDRESS_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY,
    },
    tableProps: {
      tableName: 'RUIAN - Správní obvody obcí s pověřeným obecním úřadem (POU)',
      reportTag: ExportTags.DICTIONARY_MUNICIPALITY_POU,
    },
    detailProps: {
      entityName: EntityName.RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY,
      FieldsComponent: DictionaryRuianCommissionedLocalAuthorityMunicipalityFields,
      toolbarProps: {
        title: 'RUIAN - Správní obvody obcí s pověřeným obecním úřadem (POU)',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

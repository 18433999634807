import * as Yup from 'yup';
import {
  DictionaryBoilerModel,
  DictionaryBoilerType,
  BoilerManufacturer,
  DictionaryBoilerBrand,
} from '../../models';

export function useValidationSchema() {
  return Yup.object<DictionaryBoilerModel>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    brand: Yup.object<DictionaryBoilerBrand>()
      .nullable()
      .required('Povinné pole'),
    types: Yup.array<DictionaryBoilerType>()
      .nullable()
      .required('Povinné pole'),
    manufacturer: Yup.object<BoilerManufacturer>()
      .nullable()
      .required('Povinné pole'),
  });
}

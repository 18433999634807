import { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
} from '@eas/common-web';
import { SubjectRegistration } from '../../../models';
import { EvidenceAPI, Messages } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
export function callApi(id: string) {
  return abortableFetch(`${EvidenceAPI.SUBJECT_REQUESTS}/${id}/sync`, {
    method: 'POST',
  });
}

export enum ApiResult {
  SUCCESS = 'SUCCESS',
  NOT_FOUND = 'NOT_FOUND',
  FAILED = 'FAILED',
}

export function useSync() {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext<DetailHandle<SubjectRegistration>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { reload } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleSynchronize = useEventCallback(async () => {
    try {
      source.setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(source.data!.id);

      const result: ApiResult = await fetch.current.json();

      unstable_batchedUpdates(() => {
        showSnackbar(...Messages.SubjectRegistration.SYNC[result]);

        source.setLoading(false);
      });

      reload();
      onPersisted(source.data!.id);
      await source.refresh();
    } catch (err) {
      source.setLoading(false);

      if (err.name !== 'AbortError') {
        showSnackbar(...Messages.SubjectRegistration.SYNC.ERROR);
        throw err;
      }
      return undefined;
    }
  });

  return {
    handleSynchronize,
  };
}

import React, { useEffect, useState } from 'react';
import {
  useFormSelector,
  FormPanel,
  FormAutocomplete,
  FormCheckbox,
  FormSelect,
  FormNumberField,
  FormCustomField,
} from '@eas/common-web';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { StepLocationForm } from './step-location-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  formatDistance,
  calculateDistance,
} from '../../../components/form/location-panel/location-utils';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useTerritorialTechnicalUnits } from '../../dict-territorial-technical-unit/dict-territorial-technical-units-api';
import { useLandPlotTypes } from '../../ovz-facility/ovz-facilities-api';
import { useCadastralTerritories } from '../../../components/form/address-field/address-field-api';
import { RuianObjectAutocomplete } from '../../../models';
import { HelpLabel } from '../../../components/help/help-label';
import { ProcessForm } from '../ovz-facility-new-types';
import { ROSAddressPanel } from '../../../components/form/address-field/ros-address/ros-address-panel';

const useStyles = makeStyles((theme) => ({
  distance: {
    lineHeight: 'inherit',
    padding: '0 2pt',
  },
  warning: {
    color: theme.palette.error.main,
  },
}));

export function StepLocationContent({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  /**
   * Calculate distance from AddressPlace to UTJ.
   *
   * In case of distance > 10km, show warning
   */
  const [distance, setDistance] = useState(0);

  const classes = useStyles();

  const territorialTechnicalUnits = useTerritorialTechnicalUnits();
  const landPlotTypes = useLandPlotTypes();
  const cadastralTerritories = useCadastralTerritories();

  const {
    wgs84Coordinates,
    territorialTechnicalUnit,
    address,
  } = useFormSelector((data: StepLocationForm) => ({
    wgs84Coordinates: data.wgs84Coordinates,
    territorialTechnicalUnit: data.territorialTechnicalUnit,
    address: data.address,
  }));

  useEffect(() => {
    const facilityCoors = wgs84Coordinates;
    const ttuCoors = territorialTechnicalUnit?.wgs84Coordinates;

    if (facilityCoors && ttuCoors) {
      const distance = calculateDistance(facilityCoors, ttuCoors);

      setDistance(distance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wgs84Coordinates,
    territorialTechnicalUnit?.wgs84Coordinates,
    address?.addressPlaceRuian,
  ]);

  return (
    <>
      <ROSAddressPanel operatorId={allValues?.operator?.id} />
      <FormPanel label="ÚTJ; Lokalita provozovny (zadání výběrem parcely)">
        <FormAutocomplete
          name="territorialTechnicalUnit"
          label={
            <HelpLabel
              label="Územně technická jednotka – ÚTJ"
              code="OVZ_FACILITY_REQUEST_NEW_TERRITORIAL_TECHNICAL_UNIT"
              required
            />
          } // Povinné pole
          source={territorialTechnicalUnits}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormCheckbox
          name="multipleTTU"
          label={
            <HelpLabel
              label="Areál se nachází na více ÚTJ"
              code="OVZ_FACILITY_REQUEST_NEW_MULTIPLE_TTU"
            />
          }
        />
        <FormAutocomplete<RuianObjectAutocomplete>
          source={cadastralTerritories}
          label={
            <HelpLabel
              label="Katastrální území – KÚ (povinné, pokud není vyplněno adresní místo)"
              code="OVZ_FACILITY_REQUEST_NEW_CADASTRAL_TERRITORY"
              required={!address?.addressPlaceRuian}
            />
          }
          name="cadastralTerritory"
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormSelect
          name="landPlotType"
          label={
            <HelpLabel
              label="Druh číslování parcel (povinné, pokud není vyplněno adresní místo)"
              code="OVZ_FACILITY_REQUEST_NEW_LAND_PLOT_TYPE"
              required={!address?.addressPlaceRuian}
            />
          }
          source={landPlotTypes}
          valueIsId
        />
        <FormNumberField
          name="primaryLandPlotNumber"
          label={
            <HelpLabel
              label="Kmenové číslo parcely (povinné, pokud není vyplněno adresní místo)"
              code="OVZ_FACILITY_REQUEST_NEW_PRIMARY_LAND_PLOT_NUMBER"
              required={!address?.addressPlaceRuian}
            />
          }
          inputProps={{
            maxLength: 5,
          }}
        />
        <FormNumberField
          name="secondaryLandPlotNumber"
          label={
            <HelpLabel
              label="Pořadové číslo parcely"
              code="OVZ_FACILITY_REQUEST_NEW_SECONDARY_LAND_PLOT_NUMBER"
            />
          }
          inputProps={{
            maxLength: 3,
          }}
        />
        <FormCustomField
          name="warning"
          label="Vzdálenost polohy provozovny od středu ÚTJ"
        >
          <Typography
            variant="body1"
            className={clsx(classes.distance, {
              [classes.warning]: distance > 10000,
            })}
          >
            {distance > 10000
              ? `Upozornění: Vybrané ÚTJ (střed) se nachází ve větší vzdálenosti než 10km (${formatDistance(
                  distance
                )}) od polohy provozovny`
              : formatDistance(distance)}
          </Typography>
        </FormCustomField>
      </FormPanel>
      <LocationPanel
        locationName="wgs84Coordinates"
        locationValue={wgs84Coordinates}
        locationPanelLabel="Poloha provozovny (zadání v mapovém náhledu nebo provedení kontroly polohy)"
        addressName="address"
        addressPanelLabel="Adresa provozovny"
        disableForeignCountry={true}
      />
    </>
  );
}

import React from 'react';
import { icoMaxLength } from '../subjects-utils';
import { FormTextField, FormAutocomplete } from '@eas/common-web';
import { useDictionaryLegalForms } from '../../../dict-legal-form/dict-legal-forms-api';
import { dictionaryLabelMapper } from '../../../../components/form/mappers/label-mappers';
import { HelpLabel } from '../../../../components/help/help-label';

export function BusinessNaturalPersonBasicFields({
  prefix,
  codePrefix = 'SUBJECT',
  disabled,
  disabledIco,
}: {
  prefix?: string;
  codePrefix?: string;
  disabled?: boolean;
  disabledIco?: boolean;
}) {
  const names = {
    ico: prefix ? `${prefix}.ico.value` : 'ico.value',
    name: prefix ? `${prefix}.name` : 'name',
    legalForm: prefix ? `${prefix}.legalForm` : 'legalForm',
  };

  const legalForms = useDictionaryLegalForms();

  return (
    <>
      <FormTextField
        name={names.ico}
        disabled={disabledIco ? disabledIco : disabled}
        label={<HelpLabel label="IČO" code={`${codePrefix}_ICO`} required />} // Povinné pole
        inputProps={{ maxLength: icoMaxLength }}
        onChangeEvent={(e) => {
          e.target.value = e.target.value.replace(/[^0-9]/gi, '');
        }}
      />
      <FormAutocomplete
        name={names.legalForm}
        source={legalForms}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
        label={
          <HelpLabel label="Právní forma" code={`${codePrefix}_LEGAL_FORM`} />
        }
        disabled={disabled}
      />
      <FormTextField
        name={names.name}
        label={<HelpLabel label="Název" code={`${codePrefix}_NAME`} required />} // Povinné pole
        disabled={disabled}
      />
    </>
  );
}

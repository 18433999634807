import { TableColumn, TableCells, ApiFilterOperation } from '@eas/common-web';
import { ThirdPartySystem } from '../../models/third-party-system';

export function useColumns(): TableColumn<ThirdPartySystem>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'contact.name',
      name: 'Kontaktní osoba - jméno',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'contact.email',
      name: 'Kontaktní osoba - e-mail',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'contact.phone',
      name: 'Kontaktní osoba - telefon',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
  ];
}

import React from 'react';
import { PublicListDataSource } from '../../models/public-list';
import { publicListToolbarButtonFactory } from '../../composite/public-list-toolbar-button/public-list-toolbar-button';
import { mailingToolbarButtonFactory } from '../../composite/mailing-toolbar-button/mailing-toolbar-button';
import { RecipientsSource } from '../../models/mail-campaign';

const PublicListToolbarButton = publicListToolbarButtonFactory({
  dataSource: PublicListDataSource.SUBJECT_EVIDENCE,
  name: 'Veřejný seznam subjektů',
});

const MailingToolbarButton = mailingToolbarButtonFactory({
  source: RecipientsSource.SUBJECT_EVIDENCE,
});

export function SubjectsTableToolbar() {
  return (
    <>
      <PublicListToolbarButton />
      <MailingToolbarButton />
    </>
  );
}

import React from 'react';
import {
  FormTextField,
  FormPanel,
  FormAutocomplete,
  FormCheckbox,
} from '@eas/common-web';
import { useDictionarySewerTypes } from '../../dict-sewer-type/dict-sewer-types-api';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useDictionaryHydrogeologicalZones } from '../../dict-hydrogeological-zone/dict-hydrogeological-zones-api';
import { HelpLabel } from '../../../components/help/help-label';

export function WaterUserPlaceStreamDischargeFields() {
  const hydrogeologicalZones = useDictionaryHydrogeologicalZones();
  const sewerTypes = useDictionarySewerTypes();

  return (
    <FormPanel label="Dlouhodobé provozní údaje specifické pro typ MUV">
      <FormTextField
        label={
          <HelpLabel
            label="Zdroj vypouštění"
            code="WATER_USAGE_PLACE_DISCHARGE_SOURCE"
          />
        }
        name="dischargeSource"
      />
      <FormAutocomplete
        label={
          <HelpLabel
            label="Hydrogeologický rajon"
            code="WATER_USAGE_PLACE_HYDROGEOLOGICAL_ZONE"
          />
        }
        name="hydrogeologicalZone"
        source={hydrogeologicalZones}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
      <FormCheckbox
        label={
          <HelpLabel
            label="Chemické čištění odpadních vod"
            code="WATER_USAGE_PLACE_CHEMICAL_TREATMENT"
          />
        }
        name="chemicalTreatment"
      />
      <FormCheckbox
        label={
          <HelpLabel
            label="Mechanické čištění odpadních vod"
            code="WATER_USAGE_PLACE_MECHANICAL_TREATMENT"
          />
        }
        name="mechanicalTreatment"
      />
      <FormCheckbox
        label={
          <HelpLabel
            label="Biologické čištění odpadních vod"
            code="WATER_USAGE_PLACE_BIOLOGICAL_TREATMENT"
          />
        }
        name="biologicalTreatment"
      />
      <FormCheckbox
        label={
          <HelpLabel
            label="Čistírna odpadních vod"
            code="WATER_USAGE_PLACE_WATER_TREATMENT_PLANT"
          />
        }
        name="waterTreatmentPlant"
      />
      <FormAutocomplete
        label={
          <HelpLabel
            label="Typ kanalizace"
            code="WATER_USAGE_PLACE_SEWER_TYPE"
          />
        }
        name="sewerType"
        source={sewerTypes}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

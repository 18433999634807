import React from 'react';
import { ProcessForm } from '../irz-facility-request-operator-change-types';

export function StepNameChangeLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const label = allValues?.keepOriginalName
    ? 'Ponechat původní'
    : allValues?.newName;
  return (
    <>
      Změna názvu: <b>{label}</b>
    </>
  );
}

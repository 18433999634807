import { useRef } from 'react';
import {
  useEventCallback,
  AbortableFetch,
  abortableFetch,
} from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
function callApi() {
  return abortableFetch(`${EvidenceAPI.NOTIFICATION_RECEIVED}/read-all`, {
    method: 'POST',
  });
}

export function useReadAll({ refresh }: { refresh: () => void }) {
  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleReadAll = useEventCallback(async () => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi();

      await fetch.current.raw();

      refresh();
    } catch (err) {
      if (err.name !== 'AbortError') {
        throw err;
      }
      return undefined;
    }
  });

  return { handleReadAll };
}

import * as Yup from 'yup';
import { StepManufacturerSubjectForm } from './step-manufacturer-subject-types';
import { BoilerManufacturer } from '../../../models';

export const stepManufacturerSubjectSchema = () =>
  Yup.object<StepManufacturerSubjectForm>().shape({
    boilerManufacturer: Yup.mixed<BoilerManufacturer>()
      .nullable()
      .required('Povinné pole'),
  });

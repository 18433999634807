import React, { useContext } from 'react';
import {
  FormPanel,
  FormTableField,
  TableFieldCells,
  FormFieldContext,
  DetailContext,
  DetailMode,
} from '@eas/common-web';
import { FormPanelCountByName } from '../../form/form-panel-summary/form-panel-count';
import { Note } from '../../../models/note';
import { EntityType } from '../../../enums/entity-type';
import { notedToolbarFactory } from './noted-toolbar';
import { NotesTableFieldRow } from './overrides/noted-table-field-row';
import { Permission } from '../../../enums';

export function NotedFields({ entityType }: { entityType: EntityType }) {
  const { mode } = useContext(DetailContext);

  const columns = [
    {
      name: 'Autor',
      datakey: 'createdBy.name',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Vytvořeno',
      datakey: 'created',
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: 'Autor úpravy',
      datakey: 'updatedBy.name',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Poslední úprava',
      datakey: 'updated',
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
  ];

  return (
    <FormPanel
      label="Poznámky"
      expandable
      defaultExpanded={false}
      summary={
        <FormPanelCountByName
          name="notes"
          columns={[
            ...columns,
            {
              name: 'Poznámka',
              datakey: 'text',
              width: 150,
              CellComponent: TableFieldCells.TextCell,
            },
          ]}
          permission={Permission.Note.NOTES_LIST}
        />
      }
    >
      <FormFieldContext.Provider value={{ disabled: false }}>
        <FormTableField<Note>
          name="notes"
          labelOptions={{
            hide: true,
          }}
          maxRows={15}
          showDetailBtnCond={() => false}
          showRadioCond={() => mode === DetailMode.VIEW}
          visibleActionsColumn={mode === DetailMode.VIEW}
          RowComponent={NotesTableFieldRow}
          ToolbarComponent={notedToolbarFactory(entityType)}
          disabled={false}
          columns={columns}
          layoutOptions={{
            noSpacing: true,
          }}
        />
      </FormFieldContext.Provider>
    </FormPanel>
  );
}

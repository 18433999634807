import {
  abortableFetch,
  Params,
  DictionaryAutocomplete,
  useAutocompleteSource,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function getAgendaSpecifications(params?: Params) {
  return abortableFetch(`${EvidenceAPI.AGENDAS}/specification/list`, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(params),
  });
}

export function useAgendaSpecifications() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.AGENDAS}/specification/autocomplete`,
  });
}

import { useState } from 'react';
import { Breadcrumb } from './breadcrumb-types';
import { initialBreadcrumbs } from './breadcrumb-ctx';

export function useBreadcrumb() {
  /**
   * Array of breadcrumbs currently stored in ctx.
   */
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(
    initialBreadcrumbs.breadcrumbs
  );

  const currentPage = breadcrumbs[breadcrumbs.length - 1].key;

  return {
    breadcrumbs,
    setBreadcrumbs,
    currentPage,
  };
}

import React, { useContext, useMemo, useRef } from 'react';
import {
  useEventCallback,
  SnackbarContext,
  DetailContext,
  UserContext,
  DetailHandle,
  abortableFetch,
  AbortableFetch,
  PromptContext,
  usePrompts,
  FormInlineTableField,
  InlineTableFieldCells,
  useFormSelector,
  FormContext,
} from '@eas/common-web';
import { Permission, EvidenceAPI, Messages } from '../../../enums';
import {
  OvzFacilityRequest,
  OvzFacilityIdToNameMapping,
  OvzFacility,
} from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { OvzFacilityRequestState } from '../../../models/ovz-facility-request';
import { useChangeNotifyingInlineCheckboxFactory } from '../../../components/form/inline-table-field/change-notifing-inline-checkbox';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call
 * @param id
 */
export function apiCall(
  id: string,
  nameMappings: OvzFacilityIdToNameMapping[]
) {
  return abortableFetch(`${EvidenceAPI.OVZ_FACILITY_REQUESTS}/${id}/approve`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(nameMappings),
    method: 'POST',
  });
}

const PROMPT_KEY = 'APPROVE';

export function useApproveDialog() {
  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, onPersisted } = useContext<DetailHandle<OvzFacilityRequest>>(
    DetailContext
  );
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Přijetí nabídky',
        dialogText: 'Před přijetím prosím zvolte nová jména provozoven',
        FormFields,
        formInitialValues: {
          nameChanges: source?.data?.ovzFacilities?.map((ovzFacility) => ({
            keepName: true,
            ovzFacility,
            newName: '',
          })),
        } as ApproveFormType,
        dialogWidth: 1000,
      },
    ],
    [source?.data?.ovzFacilities]
  );

  const handleApprove = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async ({ nameChanges }: ApproveFormType) => {
        if (source.data) {
          try {
            source.setLoading(true);
            if (fetch.current !== null) {
              fetch.current.abort();
            }
            const toBeRenamed = nameChanges.filter(({ keepName }) => !keepName);
            const parsedNameChanges = toBeRenamed.map(
              ({ newName, ovzFacility }) => ({
                id: ovzFacility?.id,
                name: newName,
              })
            );
            fetch.current = apiCall(source.data.id, parsedNameChanges);

            await fetch.current.raw();

            unstable_batchedUpdates(() => {
              showSnackbar(...Messages.OvzFacilityRequest.APPROVE.SUCCESS);
              source.setLoading(false);
            });

            await source.refresh();
            onPersisted(source.data.id);
          } catch (err) {
            source.setLoading(false);

            if (err.name !== 'AbortError') {
              const message = getErrorMessage(
                Messages.OvzFacilityRequest.APPROVE,
                err.code
              );

              showSnackbar(...message);
              throw err;
            }
            return undefined;
          }
        }
      },
    });
  });

  const canFinish = useMemo(() => {
    return (
      hasPermission(
        Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_FINISH_ALL
      ) ||
      hasPermission(
        Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_FINISH_OWN,
        {
          subjectId: source.data?.newOperator?.id,
        }
      )
    );
  }, [source.data?.newOperator?.id, hasPermission]);

  const showApproveButton =
    canFinish &&
    source.data?.state === OvzFacilityRequestState.WAITING_FOR_AUTHORIZATION;

  return {
    showApproveButton,
    handleApprove,
  };
}

interface NameChangeFormRow {
  ovzFacility?: OvzFacility;
  keepName?: boolean;
  newName?: string;
}

interface ApproveFormType {
  nameChanges: NameChangeFormRow[];
}

function FormFields() {
  const { keepNameMap } = useFormSelector(
    ({ nameChanges }: ApproveFormType) => ({
      keepNameMap: nameChanges.map((row) => row?.keepName),
    })
  );
  const { setFieldValue } = useContext(FormContext);

  //Needs to be memoized to avoid rerender of Table during typing
  const memoizedMap = useMemo(() => keepNameMap, [...keepNameMap]);

  const handleKeepNameChanged = useEventCallback((index: number) => {
    setFieldValue(`nameChanges[${index}].newName`, '');
  });

  return (
    <>
      <FormInlineTableField<NameChangeFormRow>
        name="nameChanges"
        labelOptions={{ hide: true }}
        disabledAdd={true}
        disabledRemove={true}
        withRemove={false}
        columns={[
          {
            name: 'Původní název',
            datakey: 'ovzFacility.name',
            width: 200,
          },
          {
            name: 'Ponechat původní',
            datakey: 'keepName',
            CellComponent: useChangeNotifyingInlineCheckboxFactory({
              collectionDatakey: 'nameChanges',
              notifyChange: handleKeepNameChanged,
            }),
            width: 200,
          },
          {
            name: 'Nový název',
            datakey: 'newName',
            CellComponent: InlineTableFieldCells.useInlineTextFieldFactory({
              collectionDatakey: 'nameChanges',
              disabled: (index) => memoizedMap[index] ?? true,
            }),
            width: 200,
          },
        ]}
      />
    </>
  );
}

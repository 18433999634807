import React, { useMemo } from 'react';
import { noop } from 'lodash';
import {
  FormPanel,
  FormAutocomplete,
  Form,
  FormCustomField,
} from '@eas/common-web';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ProcessForm } from '../production-site-merge-types';
import { useProductionSites } from '../../production-site/production-sites-api';
import { isProductionSiteEqual } from '../../../composite/compare-production-site/compare-production-site-diff';
import { CompareProductionSiteFields } from '../../../composite/compare-production-site/compare-production-site-fields';

const useStyles = makeStyles((theme) => ({
  warning: {
    lineHeight: 'inherit',
    padding: '0 2pt',
    color: theme.palette.error.main,
  },
}));

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const classes = useStyles();

  const productionSites = useProductionSites();

  const isAnyValueUpdated = useMemo(
    () =>
      !isProductionSiteEqual(
        allValues?.mainProductionSite,
        allValues?.editData
      ),
    [allValues?.mainProductionSite, allValues?.editData]
  );

  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Průmyslové místo ke sloučení">
        <FormAutocomplete
          name="mergingProductionSite"
          label="Průmyslové místo"
          helpLabel="Průmyslové místo, které bude s dále vybraným průmyslovým místem sloučeno"
          source={productionSites}
        />
      </FormPanel>
      <FormPanel label="Cílové průmyslové místo">
        <FormAutocomplete
          name="mainProductionSite"
          label="Průmyslové místo"
          helpLabel="Průmyslové místo, se kterým bude výše zvolené průmyslové místo sloučeno"
          source={productionSites}
        />
      </FormPanel>
      <FormPanel label="Cílové průmyslové místo - Editace údajů">
        {isAnyValueUpdated ? (
          <CompareProductionSiteFields
            originalData={allValues?.mainProductionSite}
            requestedData={allValues?.editData}
          />
        ) : (
          <FormCustomField>
            <Typography variant="body1" className={classes.warning}>
              Beze změny
            </Typography>
          </FormCustomField>
        )}
      </FormPanel>
    </Form>
  );
}

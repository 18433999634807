import React, { useContext, useMemo, useRef } from 'react';
import {
  useEventCallback,
  SnackbarContext,
  DetailContext,
  UserContext,
  DetailHandle,
  abortableFetch,
  AbortableFetch,
  PromptContext,
  usePrompts,
  FormTextArea,
  FormDateField,
} from '@eas/common-web';
import { Permission, EvidenceAPI, Messages } from '../../../enums';
import { IrzFacilityRequest, IrzRequestType } from '../../../models';
import { IrzRequestState } from '../../../models/irz-facility-request';
import { unstable_batchedUpdates } from 'react-dom';
import { getErrorMessage } from '../../../utils/get-message';

interface FormData {
  date?: string;
  note?: string;
}

/**
 * Api call
 * @param id
 */
export function apiCall(id: string, data: FormData) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITY_REQUESTS}/${id}/approve`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(data),
  });
}

const PROMPT_KEY = 'APPROVE';

export function useApproveDialog() {
  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, onPersisted } = useContext<DetailHandle<IrzFacilityRequest>>(
    DetailContext
  );
  const { hasPermission } = useContext(UserContext);

  const formInitialValues = useMemo(() => {
    switch (source.data?.type) {
      case IrzRequestType.INVALIDATION:
        return { date: source.data?.requestFacilityData?.validTo };
      case IrzRequestType.OPERATOR_CHANGE:
        return { date: source.data?.date };
      default:
        return undefined;
    }
  }, [
    source.data?.type,
    source.data?.requestFacilityData?.validTo,
    source.data?.date,
  ]);

  const todayISO = new Date().toISOString();

  /**
   * Dialogs
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogWidth: 600,
        dialogTitle: 'Schválení žádosti',
        dialogText: 'Skutečně chcete schválit žádost provozovny IRZ?',
        formInitialValues,
        FormFields: function Body() {
          return (
            <>
              <FormTextArea name="note" label="Poznámka" />
              {source.data?.type === IrzRequestType.INVALIDATION && (
                <FormDateField
                  name="date"
                  label="Datum odkdy bude provozovna zneplatněna"
                />
              )}
              {source.data?.type === IrzRequestType.OPERATOR_CHANGE && (
                <FormDateField
                  name="date"
                  label="Datum odkdy změna platí"
                  maxDatePicker={todayISO}
                />
              )}
            </>
          );
        },
      },
    ],
    [source.data, formInitialValues]
  );

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleApprove = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: FormData) => {
        if (source.data) {
          try {
            source.setLoading(true);
            if (fetch.current !== null) {
              fetch.current.abort();
            }
            fetch.current = apiCall(source.data.id, values);

            await fetch.current.raw();

            unstable_batchedUpdates(() => {
              showSnackbar(...Messages.IrzFacilityRequest.APPROVE.SUCCESS);
              source.setLoading(false);
            });

            await source.refresh();
            onPersisted(source.data.id);
          } catch (err) {
            source.setLoading(false);
            if (err.name !== 'AbortError') {
              const message = getErrorMessage(
                Messages.IrzFacilityRequest.APPROVE,
                err.code
              );

              showSnackbar(...message);
              throw err;
            }
            return undefined;
          }
        }
      },
    });
  });

  const canFinish = useMemo(() => {
    switch (source.data?.type) {
      case IrzRequestType.REGISTRATION:
        return hasPermission(Permission.IrzFacility.IRZ_REQUEST_CREATE_FINISH);
      case IrzRequestType.CHANGE:
        return hasPermission(Permission.IrzFacility.IRZ_REQUEST_UPDATE_FINISH);
      case IrzRequestType.OPERATOR_CHANGE:
        return (
          hasPermission(
            Permission.IrzFacility.IRZ_REQUEST_CHANGE_OPERATOR_FINISH_ALL
          ) ||
          hasPermission(
            Permission.IrzFacility.IRZ_REQUEST_CHANGE_OPERATOR_FINISH_OWN,
            {
              subjectId: source.data.relatedSubject?.id,
            }
          )
        );
      case IrzRequestType.INVALIDATION:
        return hasPermission(
          Permission.IrzFacility.IRZ_REQUEST_INVALIDATE_FINISH
        );
    }
  }, [source.data?.type, source.data?.relatedSubject?.id]);

  const showApproveButton =
    canFinish &&
    source.data?.state === IrzRequestState.WAITING_FOR_AUTHORIZATION;

  return {
    showApproveButton,
    handleApprove,
  };
}

import React from 'react';
import { HistoryFieldDescriptor } from '../history-types';
import { LocalDateHistoryField } from './local-date-history-field';
import { LocalDateTimeHistoryField } from './local-date-time-history-field';
import { IdLabelHistoryField } from './id-label-history-field';
import { SimpleHistoryField } from './simple-history-field';
import { BooleanHistoryField } from './boolean-history-field';

import {
  HistoryFieldType,
  LocalDateHistoryField as LocalDateChangeValueType,
  LocalDateTimeHistoryField as LocalDateTimeChangeValueType,
  IdLabelHistoryField as IdLabelChangeValueType,
  SimpleHistoryField as SimpleChangeValueType,
  BooleanHistoryField as BooleanChangeValueType,
  SimpleLogChange,
} from '../../../../models';
import { LegacyHistoryField } from './legacy-history-field';

export function SimpleLogDetail({
  descriptors,
  change,
}: {
  change?: SimpleLogChange | null;
  descriptors: HistoryFieldDescriptor[];
}) {
  const changeDescriptor = descriptors.find((d) => d.key === change?.key);
  if (change?.value) {
    return <LegacyHistoryField value={change?.value} />;
  }

  switch (change?.valueAfter?.type || change?.valueBefore?.type) {
    case HistoryFieldType.STRING:
      return (
        <SimpleHistoryField
          source={changeDescriptor?.source}
          valueAfter={change?.valueAfter as SimpleChangeValueType}
          valueBefore={change?.valueBefore as SimpleChangeValueType}
        />
      );
    case HistoryFieldType.BOOLEAN:
      return (
        <BooleanHistoryField
          source={changeDescriptor?.source}
          valueAfter={change?.valueAfter as BooleanChangeValueType}
          valueBefore={change?.valueBefore as BooleanChangeValueType}
        />
      );
    case HistoryFieldType.ID_LABEL:
      return (
        <IdLabelHistoryField
          valueAfter={change?.valueAfter as IdLabelChangeValueType}
          valueBefore={change?.valueBefore as IdLabelChangeValueType}
        />
      );
    case HistoryFieldType.LOCAL_DATE:
      return (
        <LocalDateHistoryField
          valueAfter={change?.valueAfter as LocalDateChangeValueType}
          valueBefore={change?.valueBefore as LocalDateChangeValueType}
        />
      );
    case HistoryFieldType.LOCAL_DATE_TIME:
      return (
        <LocalDateTimeHistoryField
          valueAfter={change?.valueAfter as LocalDateTimeChangeValueType}
          valueBefore={change?.valueBefore as LocalDateTimeChangeValueType}
        />
      );
    default:
      return null;
  }
}

import React, { useContext, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import {
  Form,
  formFieldFactory,
  FormSubmitButton,
  DictionaryAutocomplete,
} from '@eas/common-web';
import { useRegistration } from './hook/registration-hook';
import {
  BreadcrumbCtx,
  initialBreadcrumbs,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { PageKey } from '../../enums';
import {
  publicFieldFactory,
  EmptyWrapper,
} from '../../components/form/public-field/public-field';
import { publicCheckboxFactory } from '../../components/form/public-checkbox/public-checkbox';
import { publicSelectFactory } from '../../components/form/public-select/public-select';
import { CaptchaWrapper } from '../../components/captcha/captcha-wrapper';
import { RegistrationData } from './auth-types';
import {
  usernameRules,
  passwordRules,
  phoneRules,
  prefixRules,
} from '../profile/profile-utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { adjustWhitespaces } from '../user/users-utils';
import { usePreferredChannelTypes } from '../profile/profile-api';

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      marginBottom: 50,
    },
    buttonText: {
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
      color: 'white',
      fontWeight: 600,
    },
    phoneNumberRow: {
      display: 'inline-flex',
    },
    narrowerField: {
      width: 'calc(40% - 3px)',
      marginRight: '3px',
    },
    narrowerFieldAfter: {
      width: 'calc(40% - 3px)',
      marginLeft: '3px',
    },
    widerField: {
      width: '60%',
    },
    header: {
      fontSize: 20,
      fontWeight: 600,
      color: theme.palette.primary.dark,
    },
  })
);

const RegistrationFields = {
  UsernameField: formFieldFactory(
    publicFieldFactory({
      label: 'Uživatelské jméno *',
      autoComplete: 'none',
    }),
    EmptyWrapper
  ),
  DegreeBeforeField: formFieldFactory(
    publicFieldFactory({
      label: 'Titul před',
      autoComplete: 'none',
    }),
    EmptyWrapper
  ),
  FirstNameField: formFieldFactory(
    publicFieldFactory({
      label: 'Jméno *',
      onChangeEvent: adjustWhitespaces,
      autoComplete: 'none',
    }),
    EmptyWrapper
  ),
  LastNameField: formFieldFactory(
    publicFieldFactory({
      label: 'Příjmení *',
      onChangeEvent: adjustWhitespaces,
      autoComplete: 'none',
    }),
    EmptyWrapper
  ),
  DegreeAfterField: formFieldFactory(
    publicFieldFactory({
      label: 'Titul za',
      autoComplete: 'none',
    }),
    EmptyWrapper
  ),
  EmailField: formFieldFactory(
    publicFieldFactory({
      label: 'Email *',
      autoComplete: 'none',
    }),
    EmptyWrapper
  ),
  CountryCodeField: formFieldFactory(
    publicFieldFactory({
      label: 'Předvolba *',
      autoComplete: 'none',
      fullWidth: false,
      onChangeEvent: (e) => {
        if (e.target.value?.length > 4) {
          e.target.value = e.target.value.slice(0, 4);
        }

        e.target.value = e.target.value.replace(/[^+0-9]/gi, '');
      },
    }),
    EmptyWrapper
  ),
  PhoneNumberField: formFieldFactory(
    publicFieldFactory({
      label: 'Telefonní číslo *',
      autoComplete: 'none',
      fullWidth: false,
      onChangeEvent: (e) => {
        if (e.target.value?.length > 12) {
          e.target.value = e.target.value.slice(0, 12);
        }

        e.target.value = e.target.value.replace(/[^0-9]/gi, '');
      },
    }),
    EmptyWrapper
  ),
  PasswordField: formFieldFactory(
    publicFieldFactory({
      label: 'Heslo *',

      inputProps: {
        type: 'password',
      },
      autoComplete: 'new-password',
    }),
    EmptyWrapper
  ),
  PasswordAgainField: formFieldFactory(
    publicFieldFactory({
      label: 'Heslo znovu *',

      inputProps: {
        type: 'password',
      },
      autoComplete: 'new-password',
    }),
    EmptyWrapper
  ),
  PersonalDataProcessingConsentField: formFieldFactory(
    publicCheckboxFactory('Souhlas se zpracováním osobních údajů *', true),
    EmptyWrapper
  ),
  MailingConsentField: formFieldFactory(
    publicCheckboxFactory('Souhlas se zasíláním kampaní', true),
    EmptyWrapper
  ),
  TwoFactorEmailField: formFieldFactory(
    publicFieldFactory({
      label: 'Email *',
      autoComplete: 'none',
    }),
    EmptyWrapper
  ),
  TwoFactorPhonePrefixField: formFieldFactory(
    publicFieldFactory({
      label: 'Předvolba *',
      autoComplete: 'none',
      fullWidth: false,
      onChangeEvent: (e) => {
        if (e.target.value?.length > 4) {
          e.target.value = e.target.value.slice(0, 4);
        }

        e.target.value = e.target.value.replace(/[^+0-9]/gi, '');
      },
    }),
    EmptyWrapper
  ),
  TwoFactorPhoneField: formFieldFactory(
    publicFieldFactory({
      label: 'Telefonní číslo *',
      autoComplete: 'none',
      fullWidth: false,
      onChangeEvent: (e) => {
        if (e.target.value?.length > 12) {
          e.target.value = e.target.value.slice(0, 12);
        }

        e.target.value = e.target.value.replace(/[^0-9]/gi, '');
      },
    }),
    EmptyWrapper
  ),
  TwoFactorPrefferedChannel: formFieldFactory(
    publicSelectFactory<DictionaryAutocomplete>({
      label: 'Preferovaný způsob ověření *',
    }),
    EmptyWrapper
  ),
};

export function RegistrationInformation() {
  return (
    <CaptchaWrapper>
      <RegistrationInformationWithoutCaptcha />
    </CaptchaWrapper>
  );
}

function RegistrationInformationWithoutCaptcha() {
  const classes = useStyles();

  const preferredChannelTypes = usePreferredChannelTypes();

  const {
    ref,
    validationSchema,
    loading,
    handleNavigateBack,
    handleSubmit,
    getFieldError,
    initialData,
    tokenDataLoading,
  } = useRegistration();

  const { setBreadcrumbs } = useContext(BreadcrumbCtx);

  useEffect(() => {
    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Typ registrace',
        key: PageKey.REGISTRATION,
        to: '/registrace',
      },
      {
        title: 'Registrační údaje',
        key: PageKey.REGISTRATION_FORM,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (tokenDataLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        margin="20px"
      >
        <CircularProgress />
        <Typography>
          Načítaní údajů od externího poskytovatele identity
        </Typography>
      </Box>
    );
  }

  return (
    <Form<RegistrationData>
      ref={ref}
      editing={true}
      validationSchema={validationSchema}
      initialValues={initialData}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <>
          <Grid container spacing={5}>
            {/* ROW 1 */}
            <Grid item md={3} xs={1} />
            <Grid item md={6} xs={10}>
              <Typography
                variant="h5"
                align="center"
                component="div"
                className={classes.header}
              >
                Kontaktní a přihlašovací údaje
              </Typography>
            </Grid>
            <Grid item md={3} xs={1} />

            {/* ROW 2 */}
            <Grid item md={3} xs={1} />
            <Grid item md={3} xs={10}>
              <RegistrationFields.UsernameField
                name="username"
                error={getFieldError('username') ? true : false}
                helperText={
                  getFieldError('username') ?? `Musí splňovat: ${usernameRules}`
                }
              />
            </Grid>
            <Grid item md={6} xs={1} />

            {/* ROW 3 */}
            <Grid item md={3} xs={1} />
            <Grid direction="row" item md={3} xs={10}>
              <RegistrationFields.DegreeBeforeField
                name="degreeBefore.value"
                error={getFieldError('degreeBefore') ? true : false}
                helperText={getFieldError('degreeBefore')}
                className={classes.narrowerField}
              />
              <RegistrationFields.FirstNameField
                name="firstName"
                error={getFieldError('firstName') ? true : false}
                helperText={getFieldError('firstName')}
                className={classes.widerField}
              />
            </Grid>
            <Hidden mdUp>
              <Grid item md={3} xs={1} />
              <Grid item md={3} xs={1} />
            </Hidden>
            <Grid direction="row" item md={3} xs={10}>
              <RegistrationFields.LastNameField
                name="lastName"
                error={getFieldError('lastName') ? true : false}
                helperText={getFieldError('lastName')}
                className={classes.widerField}
              />
              <RegistrationFields.DegreeAfterField
                name="degreeAfter.value"
                error={getFieldError('degreeAfter') ? true : false}
                helperText={getFieldError('degreeAfter')}
                className={classes.narrowerFieldAfter}
              />
            </Grid>
            <Grid item md={3} xs={1} />

            {/* ROW 4 */}
            <Grid item md={3} xs={1} />
            <Grid item md={3} xs={10}>
              <RegistrationFields.EmailField
                name="email"
                error={getFieldError('email') ? true : false}
                helperText={getFieldError('email')}
              />
            </Grid>
            <Hidden mdUp>
              <Grid item md={3} xs={1} />
              <Grid item md={3} xs={1} />
            </Hidden>
            <Grid direction="row" item md={3} xs={10}>
              <RegistrationFields.CountryCodeField
                className={classes.narrowerField}
                name="prefix"
                error={getFieldError('prefix') ? true : false}
                helperText={getFieldError('prefix') ?? `${prefixRules}`}
              />
              <RegistrationFields.PhoneNumberField
                className={classes.widerField}
                name="phoneNumber"
                error={getFieldError('phoneNumber') ? true : false}
                helperText={getFieldError('phoneNumber') ?? `${phoneRules}`}
              />
            </Grid>
            <Grid item md={3} xs={1} />

            {!values?.externalIdp && (
              <>
                {/* ROW 5 */}
                <Grid item md={3} xs={1} />
                <Grid item md={3} xs={10}>
                  <RegistrationFields.PasswordField
                    name="password"
                    error={getFieldError('password') ? true : false}
                    helperText={
                      getFieldError('password') ??
                      `Musí splňovat: ${passwordRules}`
                    }
                  />
                </Grid>
                <Hidden mdUp>
                  <Grid item md={3} xs={1} />
                  <Grid item md={3} xs={1} />
                </Hidden>
                <Grid item md={3} xs={10}>
                  <RegistrationFields.PasswordAgainField
                    name="passwordAgain"
                    error={getFieldError('passwordAgain') ? true : false}
                    helperText={getFieldError('passwordAgain')}
                  />
                </Grid>
                <Grid item md={3} xs={1} />{' '}
              </>
            )}

            {/* ROW 6 */}
            <Grid item md={3} xs={1} />
            <Grid item md={6} xs={10}>
              <Typography
                variant="h5"
                align="center"
                component="div"
                className={classes.header}
              >
                Údaje pro dvoufaktorové ověření
              </Typography>
            </Grid>
            <Grid item md={3} xs={1} />

            {/* ROW 7 */}
            <Grid item md={3} xs={1} />
            <Grid item md={3} xs={10}>
              <RegistrationFields.TwoFactorPrefferedChannel
                source={preferredChannelTypes}
                name="twoFactorDetails.preferredChannel"
                valueIsId={true}
                error={
                  getFieldError('twoFactorDetails.preferredChannel')
                    ? true
                    : false
                }
                helperText={getFieldError('twoFactorDetails.preferredChannel')}
              />
            </Grid>
            <Hidden mdUp>
              <Grid item md={3} xs={1} />
              <Grid item md={3} xs={1} />
            </Hidden>
            <Grid direction="row" item md={3} xs={10}>
              <RegistrationFields.TwoFactorEmailField
                name="twoFactorDetails.email.value"
                error={
                  getFieldError('twoFactorDetails.email.value') ? true : false
                }
                helperText={getFieldError('twoFactorDetails.email.value')}
              />
            </Grid>
            <Grid item md={3} xs={1} />

            {/* ROW 8 */}
            <Grid item md={3} xs={1} />
            <Grid item md={3} xs={10}>
              <RegistrationFields.TwoFactorPhonePrefixField
                className={classes.narrowerField}
                name="twoFactorDetails.phoneNumber.prefix"
                error={
                  getFieldError('twoFactorDetails.phoneNumber.prefix')
                    ? true
                    : false
                }
                helperText={
                  getFieldError('twoFactorDetails.phoneNumber.prefix') ??
                  `${prefixRules}`
                }
              />
              <RegistrationFields.TwoFactorPhoneField
                className={classes.widerField}
                name="twoFactorDetails.phoneNumber.number.value"
                error={
                  getFieldError('twoFactorDetails.phoneNumber.number.value')
                    ? true
                    : false
                }
                helperText={
                  getFieldError('twoFactorDetails.phoneNumber.number.value') ??
                  `${phoneRules}`
                }
              />
            </Grid>
            <Grid direction="row" item md={3} xs={10} />
            <Grid item md={3} xs={1} />

            {/* ROW 9 */}
            <Grid item md={3} xs={1} />
            <Grid item md={6} xs={10}>
              <Typography align="center" component="div">
                <Button
                  variant="contained"
                  color="default"
                  disableElevation
                  onClick={handleNavigateBack}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.buttonText}
                  >
                    Změnit typ registrace
                  </Typography>
                </Button>
                <FormSubmitButton
                  variant="contained"
                  disableElevation
                  disabled={loading}
                  color="primary"
                  type="submit"
                  style={{
                    marginLeft: 32,
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.buttonText}
                  >
                    Pokračovat
                  </Typography>
                </FormSubmitButton>
              </Typography>
            </Grid>
            <Grid item md={3} xs={1} />
          </Grid>
        </>
      )}
    </Form>
  );
}

import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { useEventCallback, NavigationContext, Tooltip } from '@eas/common-web';
import { getEnvironmentColor } from '../../app-protected';
import { EvidenceUrl } from '../../enums';

export const useStyles = makeStyles((theme) => ({
  notificationIcon: {
    color: 'white',
    width: '20px',
    height: '20px',
    padding: 0,
    margin: 0,
  },
  button: {
    height: 33,
    minWidth: 40,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: getEnvironmentColor(theme),
    '&:hover': {
      backgroundColor: getEnvironmentColor(theme, true),
    },
  },
}));

export function OutagesButton() {
  const classes = useStyles();

  const { navigate } = useContext(NavigationContext);

  const navigateToOutages = useEventCallback(() => {
    navigate(EvidenceUrl.OUTAGES_PUBLIC);
  });

  return (
    <>
      <Tooltip title="Odstávky">
        <Button
          variant="contained"
          color="primary"
          onClick={navigateToOutages}
          disableElevation
          className={classes.button}
        >
          <FlashOnIcon className={classes.notificationIcon} />
        </Button>
      </Tooltip>
    </>
  );
}

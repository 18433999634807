import React from 'react';
import { FormPanel, FormRadioGroup } from '@eas/common-web';
import { useAuthorRoles } from '../empowerment-requests-api';
import { HelpLabel } from '../../../components/help/help-label';

export function StepRoleContent() {
  const authorRoles = useAuthorRoles();

  return (
    <FormPanel label="Vyplňte">
      <FormRadioGroup
        name="authorRole"
        label={
          <HelpLabel
            label="Vystupuji v roli"
            code="EMPOWERMENT_REQUEST_NEW_AUTHOR_ROLE"
            required
          />
        } // Povinné pole
        source={authorRoles}
        valueIsId={true}
      />
    </FormPanel>
  );
}

import React, { useContext, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import {
  useEventCallback,
  DetailContext,
  DetailMode,
  PromptContext,
  Prompt,
  UserContext,
  DetailToolbarButtonType,
  DetailActionbarButton,
} from '@eas/common-web';
import { usePasswordReset } from './users-hook';
import { useDeactivateUser } from './dialog-actions/deactivate-hook';
import { Permission } from '../../enums';
import { useCreateNoteDialog } from '../../components/evidence/noted-evidence/dialog-actions/create-note-hook';
import { EntityType } from '../../enums/entity-type';

export function UsersActionbar() {
  const { mode, source, onPersisted } = useContext(DetailContext);
  const { registerPrompts, unregisterPrompts, testPrompt } = useContext(
    PromptContext
  );
  const { hasPermission } = useContext(UserContext);
  const { loading, handleSubmit } = usePasswordReset();
  const { handleDeactivate, handleActivate } = useDeactivateUser({
    source,
    onPersisted,
  });

  const {
    handleCreateNote,
    showCreateButton: showCreateNote,
  } = useCreateNoteDialog({
    entityType: EntityType.USER,
  });

  useEffect(() => {
    const prompt: Prompt = {
      key: 'RESET_PASSWORD',
      dialogTitle: 'Resetovat heslo',
      dialogText: 'Skutečně chcete uživateli resetovat heslo?',
    };
    registerPrompts([prompt]);

    return () => {
      unregisterPrompts([prompt]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetPassword = useEventCallback(() => {
    testPrompt({
      key: 'RESET_PASSWORD',
      submitCallback: () => {
        handleSubmit();
      },
    });
  });

  if (mode !== DetailMode.VIEW) {
    return null;
  }

  const showButton = hasPermission(Permission.User.USER_RESET_PASSWORD);

  const isActive = Boolean(source.data.active);

  const canInvalidate = hasPermission(Permission.User.USER_INVALIDATE);

  const canRevalidate = hasPermission(Permission.User.USER_REVALIDATE);

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          {mode === DetailMode.VIEW && isActive && canInvalidate && (
            <DetailActionbarButton
              startIcon={<CloseIcon fontSize="small" />}
              label="Zneplatnit"
              // tooltip={'Zneplatní aktuálního uživatele'}
              onClick={handleDeactivate}
              type={DetailToolbarButtonType.SECONDARY}
            />
          )}
          {mode === DetailMode.VIEW && !isActive && canRevalidate && (
            <DetailActionbarButton
              startIcon={<CheckIcon fontSize="small" />}
              label="Obnovit platnost"
              // tooltip={'Obnoví aktuálního uživatele'}
              type={DetailToolbarButtonType.PRIMARY}
              onClick={handleActivate}
            />
          )}
          {showButton && isActive && (
            <DetailActionbarButton
              label="Resetovat heslo"
              startIcon={<VpnKeyIcon fontSize="small" />}
              disabled={loading}
              // tooltip={
              //   'Uživateli bude odeslán e-mail s odkazem pro nastavení nového hesla'
              // }
              onClick={handleResetPassword}
            />
          )}
          {showCreateNote && (
            <DetailActionbarButton
              startIcon={<NoteAddIcon fontSize="small" />}
              label="Přidat poznámku"
              onClick={handleCreateNote}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
        </div>
      )}
    </>
  );
}

import React from 'react';
import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
  TableFieldColumn,
  UserContext,
  TableCellProps,
} from '@eas/common-web';
import {
  OvzFacilityRequest,
  OvzFacility,
  Me,
  PermissionsState,
} from '../../models';
import { useRequestStates } from '../ovz-facility-request/ovz-facility-requests-api';
import { redirectToDetailCellFactory } from '../../composite/subject-cells/redirect-to-detail-cell';
import { EvidenceUrl, Permission } from '../../enums';
import { useContext, ComponentType } from 'react';
import { requestColoredFactory } from '../../components/form/request-colored/request-colored';
import { OvzFacilityRequestState } from '../../models/ovz-facility-request';

function isApproved(req: OvzFacilityRequest) {
  return (
    req?.state === OvzFacilityRequestState.APPROVED ||
    req?.state === OvzFacilityRequestState.COMPLETE
  );
}

function isRejected(req: OvzFacilityRequest) {
  return req?.state === OvzFacilityRequestState.REJECTED;
}

function isWaitingForAuthorization(req: OvzFacilityRequest) {
  return req?.state === OvzFacilityRequestState.WAITING_FOR_AUTHORIZATION;
}

export function ovzFacilityRequestColumnFactory(
  cell: ComponentType<TableCellProps<OvzFacilityRequest>>
) {
  return requestColoredFactory(
    cell,
    isApproved,
    isRejected,
    isWaitingForAuthorization
  );
}

export function useColumns(): TableColumn<OvzFacilityRequest>[] {
  return [
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav nabídky',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: ovzFacilityRequestColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useRequestStates
      ),
      valueMapper: TableCells.useSelectCellFactory(useRequestStates),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'ovzFacilitiesConcatenated',
      name: 'Provozovny',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: ovzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'oldOperator.identifier',
      name: 'Původní provozovatel - IČO / UID',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: ovzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'oldOperator.identityDocumentsConcatenated',
      name: 'Původní provozovatel - OP / pas',
      width: 300,
      CellComponent: ovzFacilityRequestColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'oldOperator.name',
      name: 'Původní provozovatel - Název',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: ovzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'newOperator.identifier',
      name: 'Nový provozovatel - IČO / UID',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: ovzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    // {
    //   datakey: 'newOperator.identityDocumentsConcatenated',
    //   name: 'Nový provozovatel - OP / pas',
    //   width: 300,
    //   CellComponent: ovzFacilityRequestColumnFactory(TableCells.TextCell),
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 3,
    // },
    {
      datakey: 'newOperator.name',
      name: 'Nový provozovatel - Název',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: ovzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
  ];
}

export function useOvzFacilitiesColumns(): TableFieldColumn<OvzFacility>[] {
  return [
    {
      name: 'Odkaz',
      datakey: 'id',
      width: 60,
      CellComponent: function Cell(props) {
        const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
          UserContext
        );

        const showRedirect =
          hasPermission(Permission.OvzFacility.OVZ_LIST_ALL) ||
          hasPermission(Permission.OvzFacility.OVZ_LIST_OWN, {
            subjectId: props.rowValue.operator?.id,
          });

        if (showRedirect) {
          const CellComponent = redirectToDetailCellFactory(
            EvidenceUrl.OVZ_FACILITIES
          );

          return <CellComponent {...props} />;
        } else {
          return <>{'-'}</>;
        }
      },
    },
    {
      name: 'Název',
      datakey: 'name',
      width: 200,
    },
    {
      name: 'Nový název',
      datakey: 'newName',
      width: 200,
    },
    {
      name: 'IČP',
      datakey: 'facilityId',
      width: 200,
    },
  ];
}

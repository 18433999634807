import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useAddressFieldsFactory } from '../../components/form/address-field/address-field-history-fields';
import { ListSource, DictionaryAutocomplete } from '@eas/common-web';
import { useDocumentTypes } from './subjects-api';
import { HistoryFieldUnion, HistoryFieldType } from '../../models';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const documentTypes = useDocumentTypes();

  return [
    ...useAddressFieldsFactory('residence'),
    {
      key: 'phoneNumber.prefix',
      label: 'Telefonní číslo - předvolba',
    },
    {
      key: 'phoneNumber.number',
      label: 'Telefonní číslo',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'website',
      label: 'Webová stránka',
    },
    {
      key: 'inCrzpSince',
      label: 'Datum registrace',
    },
    {
      key: 'isdsIds',
      label: 'Identifikátor datové schránky',
    },
    {
      key: 'allowIszrSync',
      label: 'Automatická synchronizace se ZR',
    },
    {
      key: 'lastIszrSync',
      label: 'Datum poslední synchronizace',
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
    {
      key: 'subjectAgendas',
      label: 'Agendy',
    },
    {
      key: 'degreeBefore',
      label: 'Titul před',
    },
    {
      key: 'firstName',
      label: 'Jméno',
    },
    {
      key: 'lastName',
      label: 'Příjmení',
    },
    {
      key: 'degreeAfter',
      label: 'Titul za',
    },
    {
      key: 'birthDate',
      label: 'Datum narození',
    },
    {
      key: 'deceaseDate',
      label: 'Datum úmrtí',
    },
    {
      key: 'identityDocuments',
      label: 'Dokumenty',
      valueMapper: (historyField) =>
        documentsValueMapper(historyField, documentTypes),
    },
    {
      key: 'ico',
      label: 'IČO',
    },
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'artificialId',
      label: 'UID',
    },
    {
      key: 'foreignId',
      label: 'Zahraniční identifikátor (obdoba IČO)',
    },
    {
      key: 'originDate',
      label: 'Datum vzniku',
    },
    {
      key: 'endDate',
      label: 'Datum zániku',
    },
    {
      key: 'legalForm',
      label: 'Právní forma',
    },
    { key: 'active', label: 'Aktivní' },
    { key: 'type', label: 'Typ' },
  ];
}

function documentsValueMapper(
  change: HistoryFieldUnion,
  documentTypes: ListSource<DictionaryAutocomplete>
) {
  let value = '';
  switch (change.type) {
    case HistoryFieldType.ID_LABEL: {
      value = change.label;
      break;
    }
    case HistoryFieldType.STRING: {
      const documentType = documentTypes.items.find(
        (type) => type.id === change.value
      );
      value = documentType?.name ?? change.value;
      break;
    }
    case HistoryFieldType.NESTED:
      Object.entries(change.map).forEach(([_, nestedValue]) => {
        value += ` ${documentsValueMapper(nestedValue, documentTypes)}`;
      });
  }
  return value;
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'event.code',
      label: 'ID události',
    },
    {
      key: 'event.label',
      label: 'Událost',
    },
    {
      key: 'subject',
      label: 'Předmět',
    },
    {
      key: 'content',
      label: 'Text',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'html',
      label:
        'Příznak, zda-li je obsah (tělo) notifikace formátované pomocí HTML',
    },
  ];
}

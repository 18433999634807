import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { IrzFacilityRequest } from '../../models';
import { IrzFacilityRequestsFields } from './irz-facility-requests-fields';
import {
  useColumns,
  irzFacilityRequestColumnFactory,
} from './irz-facility-requests-columns';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { EntityType } from '../../enums/entity-type';
import { IrzFacilityRequestsActionbar } from './irz-facility-requests-actionbar';
import { IrzFacilityRequestsToolbar } from './irz-facility-requests-toolbar';
import { useSpecialFilters } from './irz-facility-requests-special-filters';
import { useHistoryDescriptors } from './irz-facility-requests-history';
import { combineGetCalls } from './irz-facility-requests-api';
import { getItemFactory } from '../../utils/custom-crud';

export function IrzFacilityRequests() {
  const permissions = usePermission<IrzFacilityRequest>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<IrzFacilityRequest>({
    version: 4,
    identifier: PageKey.IRZ_FACILITY_REQUESTS,
    noteEntityType: EntityType.IRZ_FACILITY_REQUEST,
    apiProps: {
      url: EvidenceAPI.IRZ_FACILITY_REQUESTS,
      getItem: getItemFactory({
        combineGetCalls,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled žádostí provozoven IRZ',
      reportTag: ExportTags.IRZ_FACILITY_REQUESTS,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.IrzFacility.IRZ_LIST_ALL,
      columnWrapper: irzFacilityRequestColumnFactory,
    },
    detailProps: {
      entityName: EntityName.IRZ_FACILITY_REQUEST,
      FieldsComponent: IrzFacilityRequestsFields,
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Žádost provozovny IRZ',
        After: IrzFacilityRequestsToolbar,
        ActionBar: IrzFacilityRequestsActionbar,
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.IrzFacility.IRZ_REQUEST_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}

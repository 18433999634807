import React from 'react';
import {
  DetailToolbarButtonType,
  DetailActionbarButton,
} from '@eas/common-web';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { useApproveDialog } from './dialog-actions/approve-hook';
import { useRejectDialog } from './dialog-actions/reject-hook';
import { useEditCeniaDetailsDialog } from './dialog-actions/edit-cenia-details-hook';

export function EmpowermentRequestActionbar() {
  const { handleApprove, showApproveButton } = useApproveDialog();
  const { handleReject, showRejectButton } = useRejectDialog();
  const {
    handleEdit,
    showEditCeniaDetailsButton,
  } = useEditCeniaDetailsDialog();

  return (
    <>
      {showApproveButton && (
        <DetailActionbarButton
          startIcon={<DoneIcon />}
          label="Schválit"
          onClick={handleApprove}
          type={DetailToolbarButtonType.PRIMARY}
        />
      )}
      {showRejectButton && (
        <DetailActionbarButton
          startIcon={<CloseIcon />}
          label="Zamítnout"
          onClick={handleReject}
          type={DetailToolbarButtonType.SECONDARY}
        />
      )}
      {showEditCeniaDetailsButton && (
        <DetailActionbarButton
          startIcon={<EditIcon />}
          label="Upravit Ev.č./Č.j."
          type={DetailToolbarButtonType.PRIMARY}
          onClick={handleEdit}
        />
      )}
    </>
  );
}

import React, { useContext } from 'react';
import MapIcon from '@material-ui/icons/Map';
import RefreshIcon from '@material-ui/icons/Refresh';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloseIcon from '@material-ui/icons/Close';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButtonType,
  DetailActionbarButton,
} from '@eas/common-web';
import { useCreateNoteDialog } from '../../components/evidence/noted-evidence/dialog-actions/create-note-hook';
import { EntityType } from '../../enums/entity-type';
import { useActivateDialog } from './dialog-actions/activate-hook';
import { useDeactivateDialog } from './dialog-actions/deactivate-hook';
import {
  MapEvidenceContext,
  MapEvidenceView,
} from '../../components/evidence/map-evidence/map-context';
import { useShowOnMap } from './actions/show-on-map';

export function OvzFacilitiesActionbar() {
  const { mode } = useContext(DetailContext);
  const { view } = useContext(MapEvidenceContext);

  const {
    handleCreateNote,
    showCreateButton: showCreateNote,
  } = useCreateNoteDialog({
    entityType: EntityType.OVZ,
  });

  const { showShowOnMapButton, handleShowOnMap } = useShowOnMap();

  const { handleActivate, showActivateButton } = useActivateDialog();
  const { handleDeactivate, showDeactivateButton } = useDeactivateDialog();

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          {showActivateButton && (
            <DetailActionbarButton
              startIcon={<RefreshIcon fontSize="small" />}
              label="Obnovit platnost"
              onClick={handleActivate}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showDeactivateButton && (
            <DetailActionbarButton
              startIcon={<CloseIcon fontSize="small" />}
              label="Zneplatnit"
              onClick={handleDeactivate}
              type={DetailToolbarButtonType.SECONDARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showShowOnMapButton && (
            <DetailActionbarButton
              startIcon={<MapIcon fontSize="small" />}
              label="Zobrazit na mapě"
              onClick={handleShowOnMap}
              type={DetailToolbarButtonType.NORMAL}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
          {showCreateNote && (
            <DetailActionbarButton
              startIcon={<NoteAddIcon fontSize="small" />}
              label="Přidat poznámku"
              onClick={handleCreateNote}
              type={DetailToolbarButtonType.PRIMARY}
              disabled={view === MapEvidenceView.MAP}
            />
          )}
        </div>
      )}
    </>
  );
}

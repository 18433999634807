import {
  useEventCallback,
  NavigationContext,
  DetailContext,
  EvidenceStateAction,
} from '@eas/common-web';
import { useContext } from 'react';
import { EvidenceUrl } from '../../../enums';

export function useRedirect() {
  /**
   * Context stuff.
   */
  const { navigate } = useContext(NavigationContext);
  const { source } = useContext(DetailContext);

  /**
   * Callback to redirect into registered subject detail.
   */
  const handleRedirect = useEventCallback(() => {
    navigate(EvidenceUrl.SUBJECTS, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: source.data?.approvedSubject?.id,
    });
  });

  const showRedirect = !!source.data?.approvedSubject?.id;

  /**
   * Callback to redirect into evidence of subjects.
   */
  const handleAllSubjects = useEventCallback(() => {
    navigate(EvidenceUrl.SUBJECTS);
  });

  /**
   * Callback to redirect into new registration.
   */
  const handleNewRegistration = useEventCallback(() => {
    navigate(EvidenceUrl.SUBJECT_REQUESTS_NEW);
  });

  return {
    showRedirect,
    handleRedirect,
    handleAllSubjects,
    handleNewRegistration,
  };
}

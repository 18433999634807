import * as React from 'react';
import { useBreadcrumb } from './breadcrumb-hook';
import { BreadcrumbCtx } from './breadcrumb-ctx';

export function BreadcrumbProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const bStuff = useBreadcrumb();

  return (
    <BreadcrumbCtx.Provider value={bStuff}>{children}</BreadcrumbCtx.Provider>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { MapContext, GeometryLayerContext } from './map-ctx';
import { GeometryLayer as GeometryLayerType } from './map-types';

export function GeometryLayer({ children }: { children: React.ReactNode }) {
  const { map } = useContext(MapContext);

  const [geometryLayer, setGeometryLayer] = useState<GeometryLayerType | null>(
    null
  );

  useEffect(() => {
    const geometryLayer = new window.SMap.Layer.Geometry();
    map?.addLayer(geometryLayer);
    geometryLayer.enable();

    setGeometryLayer(geometryLayer);

    return () => {
      map?.removeLayer(geometryLayer);
    };
  }, []);

  return (
    <GeometryLayerContext.Provider value={geometryLayer}>
      {children}
    </GeometryLayerContext.Provider>
  );
}

import * as Yup from 'yup';
import {
  BoilerPermission,
  DictionaryBoilerBrand,
  DictionaryBoilerType,
  Subject,
} from '../../models';
import { addressFieldSchema } from '../../components/form/address-field/address-field-schema';
import {
  validatePhoneNotRequired,
  validateEmailNotRequired,
} from '../profile/profile-utils';

export function useValidationSchema() {
  return Yup.object<BoilerPermission>().shape({
    number: Yup.string().nullable().required('Povinné pole'),
    boilerBrand: Yup.mixed<DictionaryBoilerBrand>()
      .nullable()
      .required('Povinné pole'),
    boilerTypes: Yup.array<DictionaryBoilerType>()
      .nullable()
      .min(1, 'Zadejte alespoň 1 typ kotle')
      .required('Povinné pole'),
    ozoSubject: Yup.mixed<Subject>().nullable().required('Povinné pole'),
    address: addressFieldSchema(),
    manufacturerSubject: Yup.mixed<Subject>()
      .nullable()
      .required('Povinné pole'),
    phoneNumber: validatePhoneNotRequired,
    email: Yup.object().shape({
      value: validateEmailNotRequired(),
    }),
    validFrom: Yup.string().nullable().required('Povinné pole'),
    validTo: Yup.string().when('withoutEnd', {
      is: (val) => !val,
      then: Yup.string()
        .nullable()
        .required('Zaznačte platnost na neurčito, nebo vyplňte platnost'),
    }),
  }) as Yup.Schema<BoilerPermission>;
}

import React, { useContext, useMemo } from 'react';
import { uniqBy } from 'lodash';
import {
  FormPanel,
  FormCheckbox,
  FormTextField,
  FormTextArea,
  FormLocalDateField,
  FormFileField,
  FormAutocomplete,
  useFormSelector,
  ApiFilterOperation,
  FormContext,
  UserContext,
  FormTableField,
  DetailContext,
  DetailMode,
  FormFieldContext,
} from '@eas/common-web';
import { HistoryEvidenceFieldsProps } from '../../components/evidence/history-evidence/history-types';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { HelpLabel } from '../../components/help/help-label';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import { useDirpTypes } from '../dict-dirp-type/dict-dirp-types-api';
import { useDirpAuthors } from '../dict-dirp-author/dict-dirp-authors-api';
import { useDirpLaws } from '../dict-dirp-law/dict-dirp-laws-api';
import { useDirpAreas } from '../dict-dirp-area/dict-dirp-areas-api';
import { useDirpLawParagraphs } from '../dict-dirp-law-paragraph/dict-dirp-law-paragraphs-api';
import { AttachmentsToolbar } from './overrides/dirp-records-toolbar-attachments';
import { useAttachmentsColumns } from './dirp-records-columns';
import { DirpRecord, DictionaryLaw } from '../../models';
import { Permission } from '../../enums';

export function DirpRecordsFields(props: HistoryEvidenceFieldsProps) {
  const { setFieldValue, getFieldValues } = useContext(FormContext);
  const { hasPermission } = useContext(UserContext);
  const { mode } = useContext(DetailContext);

  const attachmentsColumns = useAttachmentsColumns();

  const { laws, type, areas, paragraphs } = useFormSelector(
    (data: DirpRecord) => ({
      laws: data.laws ?? [],
      type: data.type,
      areas: data.areas ?? [],
      paragraphs: data.paragraphs ?? [],
    })
  );

  const isAdmin = useMemo(
    () => hasPermission(Permission.Dirp.DIRP_UPDATE_ALL),
    [hasPermission]
  );

  const dirpTypes = useDirpTypes();
  const dirpAuthors = useDirpAuthors();
  const dirpAreas = useDirpAreas();
  const dirpLaws = useDirpLaws(
    areas.length > 0
      ? {
          filters: [
            {
              operation: ApiFilterOperation.OR,
              filters: [
                {
                  field: 'area.id',
                  operation: ApiFilterOperation.IN,
                  values: areas.map((area) => area.id),
                },
                {
                  field: 'area.id',
                  operation: ApiFilterOperation.IS_NULL,
                },
              ],
            },
          ],
        }
      : undefined
  );
  const dirpLawParagraphs = useDirpLawParagraphs({
    filters: [
      {
        field: 'law.id',
        operation: ApiFilterOperation.IN,
        values: laws.map((law) => law.id),
      },
    ],
  });

  return (
    <>
      <FormPanel label="Záznam">
        <FormTextField
          name="evidenceNumber"
          label={
            <HelpLabel
              label="Evidenční číslo DIRP"
              code="DIRP_RECORD_EVIDENCE_NUMBER"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="referenceNumber"
          label={
            <HelpLabel
              label="Číslo jednací"
              code="DIRP_RECORD_REFERENCE_NUMBER"
              required
            />
          }
        />
        <FormAutocomplete
          name="type"
          label={<HelpLabel label="Typ" code="DIRP_RECORD_TYPE" required />}
          source={dirpTypes}
          notifyChange={() => {
            const formType = getFieldValues().type;
            if (!formType.includesChallengedVerdictReferenceNumber) {
              setFieldValue('challengedVerdictReferenceNumber', undefined);
            }
          }}
        />
        {type?.includesChallengedVerdictReferenceNumber && (
          <FormTextField
            name="challengedVerdictReferenceNumber"
            label={
              <HelpLabel
                label="Č. j. napadeného rozhodnutí"
                code="DIRP_RECORD_CHALLENGED_VERDICT_REFERENCE_NUMBER"
              />
            }
          />
        )}
        <FormAutocomplete
          name="author"
          label={<HelpLabel label="Autor" code="DIRP_RECORD_AUTHOR" required />}
          source={dirpAuthors}
        />
        <FormTextArea
          name="authorNote"
          label={
            <HelpLabel label="Poznámka autora" code="DIRP_RECORD_AUTHOR_NOTE" />
          }
        />
        <FormTextArea
          name="comment"
          label={<HelpLabel label="Komentář" code="DIRP_RECORD_COMMENT" />}
          disabled={!isAdmin}
        />
        <FormAutocomplete
          name="areas"
          label={<HelpLabel label="Oblasti" code="DIRP_RECORD_AREA" required />}
          source={dirpAreas}
          labelMapper={dictionaryLabelMapper}
          multiple
        />
        <FormAutocomplete
          name="laws"
          label={
            <HelpLabel
              label="Dotčené předpisy - Předpis"
              code="DIRP_RECORD_LAW"
              required
            />
          }
          notifyChange={() => {
            const formLaws = getFieldValues().laws;
            setFieldValue(
              'paragraphs',
              paragraphs.filter((paragraph) =>
                formLaws
                  .map((law: DictionaryLaw) => law.id)
                  .includes(paragraph?.law?.id)
              )
            );
            setFieldValue(
              'areas',
              uniqBy(
                [
                  ...areas,
                  ...formLaws
                    .filter((law: DictionaryLaw) => !!law.area)
                    .map((law: DictionaryLaw) => law.area),
                ],
                'id'
              )
            );
          }}
          multiple
          source={dirpLaws}
          labelMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          name="paragraphs"
          label={
            <HelpLabel
              label="Dotčené předpisy - Paragraf"
              code="DIRP_RECORD_PARAGRAPH"
            />
          }
          disabled={laws.length === 0}
          multiple
          source={dirpLawParagraphs}
          labelMapper={dictionaryLabelMapper}
        />
        <FormLocalDateField
          name="issuedDate"
          label={
            <HelpLabel
              label="Datum vydání"
              code="DIRP_RECORD_ISSUED_DATE"
              required
            />
          }
        />
        <FormFileField
          name="verdict"
          label={
            <HelpLabel label="Rozhodnutí" code="DIRP_RECORD_VERDICT" required />
          }
        />
        <FormCheckbox
          name="important"
          label={
            <HelpLabel
              label="Rozhodnutí významné pro rozhodovací praxi"
              code="DIRP_RECORD_IMPORTANT"
            />
          }
        />
        <FormCheckbox
          name="challengedAtCourt"
          label={
            <HelpLabel
              label="Rozhodnutí napadeno u soudu"
              code="DIRP_RECORD_CHALLENGED_AT_COURT"
            />
          }
        />
      </FormPanel>
      <FormPanel
        label="Přílohy"
        expandable
        defaultExpanded={false}
        summary={<FormPanelCountByName name="attachments" />}
      >
        <FormFieldContext.Provider value={{ disabled: false }}>
          <FormTableField
            name="attachments"
            labelOptions={{
              hide: true,
            }}
            maxRows={15}
            columns={attachmentsColumns}
            showRadioCond={() =>
              [DetailMode.VIEW, DetailMode.EDIT].includes(mode) &&
              hasPermission(Permission.Dirp.DIRP_UPDATE)
            }
            showDetailBtnCond={() => false}
            ToolbarComponent={(props) => (
              <AttachmentsToolbar
                {...props}
                disabled={!hasPermission(Permission.Dirp.DIRP_UPDATE)}
              />
            )}
            layoutOptions={{
              noSpacing: true,
            }}
          />
        </FormFieldContext.Provider>
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const CREATE: MessageType<'CODE_NOT_UNIQUE' | 'SUCCESS' | 'ERROR' | 'NAME_NOT_UNIQUE'> = {
  SUCCESS: ['Role byla úspěšně vytvořena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při vytváření role.', SnackbarVariant.ERROR],
  CODE_NOT_UNIQUE: ['Role, kterou se snažíte založit má duplicitní kód k již existující roli.', SnackbarVariant.ERROR],
  NAME_NOT_UNIQUE: ['Role, kterou se snažíte založit má duplicitní název k již existující roli.', SnackbarVariant.ERROR],
};

const UPDATE: MessageType<'CODE_NOT_UNIQUE' | 'ERROR' | 'SUCCESS' | 'NAME_NOT_UNIQUE'> = {
  SUCCESS: ['Role byla úspěšně upravena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při editaci role.', SnackbarVariant.ERROR],
  CODE_NOT_UNIQUE: ['Role, kterou se snažíte upravit má duplicitní kód k již existující roli.', SnackbarVariant.ERROR],
  NAME_NOT_UNIQUE: ['Role, kterou se snažíte upravit má duplicitní název k již existující roli.', SnackbarVariant.ERROR],
}

export default { CREATE, UPDATE };

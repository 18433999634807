import React from 'react';
import { noop, sortBy } from 'lodash';
import clsx from 'clsx';
import {
  Form,
  FormSubmitButton,
  formFieldFactory,
  DictionaryAutocomplete,
} from '@eas/common-web';
import { ProfileForm } from './profile-types';
import { useProfile } from './profile-hook';
import { useStyles } from './profile-styles';
import { Title } from '../../components/title/title';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PasswordChangeDialog } from './password-change-dialog';
import { protectedFieldFactory } from '../../components/form/protected-field/protected-field';
import { ProtectedFieldWrapper } from '../../components/form/protected-field/protected-field-wrapper';
import { protectedSelectFactory } from '../../components/form/protected-select/protected-select';
import { protectedCheckboxFactory } from '../../components/form/protected-checkbox/protected-checkbox';
import { ProtectedCheckboxWrapper } from '../../components/form/protected-checkbox/protected-checkbox-wrapper';
import Typography from '@material-ui/core/Typography';
import { usePreferredChannelTypes } from './profile-api';
import ExternalIdentityProviderRow from './external-identity-provider-row';
import { PersonalEvents } from './fields/personal-events/personal-events';
import { useNotificationEvents } from '../notification-templates/notification-templates-api';
import { protectedPhoneFactory } from '../../components/form/protected-phone/protected-phone';
import { AdminRoles } from './fields/role-admin/role-admin';
import { UserRoles } from './fields/role-user/role-user';
import { OtherRoles } from './fields/role-other/role-other';
import { History } from './fields/history/history';
import { EmpoweredRoles } from './fields/role-empowered/role-empowered';
import { ProtectedPage } from '../../components/protected-page/protected-page';
import { useIdentityProviders } from '../../components/identity-provider/identity-provider-api';
import { IdentityProvider } from '../../enums';

export function ProfilePage() {
  const classes = useStyles();

  const {
    notificationPreferences,
    loading,
    contactInfoEditing,
    secondFactorInfoEditing,
    refContactInfo,
    refSecondFactorInfo,
    refLoginForm,
    handleSubmitContactInfo,
    handleSubmitSecondarFactorInfo,
    handleCancelSecondFactorInfoEditing,
    handleCancelContactInfoEditing,
    handleStartContactInfoEditing,
    handleStartSecondFactorInfoEditing,
    handleUserSettingsClear,
    handlePwdChange,
    handleSubscribeToEvent,
    pwdChangeDialog,
    contactInfoValidationSchema,
    secondFactorInfoValidationSchema,
    profile,
    getProfile,
  } = useProfile();

  const identityProvidersSource = useIdentityProviders();

  const preferredChannelTypes = usePreferredChannelTypes();

  const events = useNotificationEvents();

  const ProfileFields = {
    UserNameField: formFieldFactory(
      protectedFieldFactory(),
      ProtectedFieldWrapper
    ),
    FakePasswordField: formFieldFactory(
      protectedFieldFactory({
        inputProps: {
          type: 'password',
          value: '************',
        },
      }),
      ProtectedFieldWrapper
    ),
    DegreeBeforeField: formFieldFactory(
      protectedFieldFactory(),
      ProtectedFieldWrapper
    ),
    DegreeAfterField: formFieldFactory(
      protectedFieldFactory(),
      ProtectedFieldWrapper
    ),
    FirstNameField: formFieldFactory(
      protectedFieldFactory(),
      ProtectedFieldWrapper
    ),
    LastNameField: formFieldFactory(
      protectedFieldFactory(),
      ProtectedFieldWrapper
    ),
    EmailField: formFieldFactory(
      protectedFieldFactory(),
      ProtectedFieldWrapper
    ),
    PhoneField: formFieldFactory(
      protectedPhoneFactory(),
      ProtectedFieldWrapper
    ),
    TfdEmailField: formFieldFactory(
      protectedFieldFactory(),
      ProtectedFieldWrapper
    ),
    TfdPhoneField: formFieldFactory(
      protectedPhoneFactory(),
      ProtectedFieldWrapper
    ),
    TfdPrefferedChannel: formFieldFactory(
      protectedSelectFactory<DictionaryAutocomplete>({}),
      ProtectedFieldWrapper
    ),
    PersonalDataProcessingConsentField: formFieldFactory(
      protectedCheckboxFactory('Souhlas se zpracováním osobních údajů *', true),
      ProtectedCheckboxWrapper
    ),
    MailingConsentField: formFieldFactory(
      protectedCheckboxFactory('Souhlas se zasíláním kampaní', false),
      ProtectedCheckboxWrapper
    ),
  };

  const getFullName = () => {
    let fullName = '';

    if (profile?.degreeBefore?.value) {
      fullName = `${profile.degreeBefore.value} `;
    }
    if (profile?.firstName?.value) {
      fullName = `${fullName}${profile.firstName.value} `;
    }
    if (profile?.lastName?.value) {
      fullName = `${fullName}${profile.lastName.value} `;
    }
    if (profile?.degreeAfter?.value) {
      fullName = `${fullName}${profile.degreeAfter.value}`;
    }

    return fullName;
  };

  return (
    <ProtectedPage>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Title name="Profil uživatele" />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Form
              editing={!loading && contactInfoEditing}
              initialValues={{}}
              onSubmit={noop}
              ref={refLoginForm}
            >
              <Box mb={3} className={classes.formBox}>
                <div className={classes.formHeaderWrapper}>
                  <Typography variant="h5" className={classes.formHeader}>
                    Přihlašovací údaje
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={
                      loading || contactInfoEditing || secondFactorInfoEditing
                    }
                    onClick={handlePwdChange}
                    className={classes.button}
                  >
                    Změnit heslo
                  </Button>
                </div>

                <ProfileFields.UserNameField
                  name="username"
                  label="Uživatelské jméno"
                  required
                  disabled
                />

                <ProfileFields.FakePasswordField
                  name="fakePassword"
                  label="Heslo"
                  disabled
                />
              </Box>
              <Form<Omit<ProfileForm, 'twoFactorDetails'>>
                editing={!loading && contactInfoEditing}
                initialValues={{
                  id: '',
                  degreeBefore: {
                    id: '',
                    value: '',
                  },
                  email: {
                    id: '',
                    value: '',
                  },
                  idNumber: {
                    id: '',
                    value: '',
                  },
                  phoneNumber: {
                    number: {
                      id: '',
                      value: '',
                    },
                  },
                  administrators: [],
                  users: [],
                  personalDataProcessingConsent: false,
                  mailingConsent: false,
                }}
                onSubmit={handleSubmitContactInfo}
                ref={refContactInfo}
                validationSchema={contactInfoValidationSchema}
              >
                <Box mb={3} className={classes.formBox}>
                  <div className={classes.formHeaderWrapper}>
                    <Typography variant="h5" className={classes.formHeader}>
                      Kontaktní údaje
                    </Typography>
                    {!contactInfoEditing && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={loading || secondFactorInfoEditing}
                          onClick={handleStartContactInfoEditing}
                          className={classes.button}
                        >
                          Upravit
                        </Button>
                      </>
                    )}
                    {contactInfoEditing && (
                      <div className={classes.buttonGroup}>
                        <FormSubmitButton
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                          startIcon={
                            loading && (
                              <CircularProgress size="20px" color="inherit" />
                            )
                          }
                        >
                          Uložit
                        </FormSubmitButton>
                        <Box width={12} />
                        <Button
                          variant="contained"
                          disabled={loading}
                          onClick={handleCancelContactInfoEditing}
                        >
                          Zrušit
                        </Button>
                      </div>
                    )}
                  </div>

                  {contactInfoEditing && (
                    <>
                      <ProfileFields.DegreeBeforeField
                        name="degreeBefore.value"
                        label="Titul před"
                      />

                      <ProfileFields.FirstNameField
                        name="firstName.value"
                        label="Jméno"
                        disabled
                      />

                      <ProfileFields.LastNameField
                        name="lastName.value"
                        label="Příjmení"
                        disabled
                      />

                      <ProfileFields.DegreeAfterField
                        name="degreeAfter.value"
                        label="Titul za"
                      />
                    </>
                  )}
                  {!contactInfoEditing && (
                    <ProtectedFieldWrapper
                      label="Jméno a příjmení"
                      disabled={false}
                    >
                      <Typography
                        variant="body1"
                        style={{ width: '100%', padding: '0px 8px' }}
                      >
                        {getFullName()}
                      </Typography>
                    </ProtectedFieldWrapper>
                  )}

                  <ProfileFields.EmailField
                    name="email.value"
                    label="E-mail"
                    required
                  />

                  <ProfileFields.PhoneField
                    name="phoneNumber"
                    label="Telefon (předvolba, číslo)"
                    required
                  />
                </Box>
              </Form>

              <Box mb={3} className={classes.formBox}>
                <Typography
                  variant="h5"
                  className={clsx(classes.formHeader, classes.bordered)}
                >
                  Odhlášení odběru notifikací
                </Typography>

                {notificationPreferences.length === 0 && (
                  <Typography variant="body1" style={{ padding: '8px 24px' }}>
                    Žádné
                  </Typography>
                )}
                {notificationPreferences.map((pref, i) => (
                  <ProtectedFieldWrapper
                    key={pref}
                    disabled={false}
                    label={i + 1}
                  >
                    <div className={classes.notificationRow}>
                      <Typography variant="body1">
                        {events.items.find((i) => i.id === pref)?.name}
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={contactInfoEditing}
                        onClick={() => handleSubscribeToEvent(pref)}
                      >
                        Zrušit
                      </Button>
                    </div>
                  </ProtectedFieldWrapper>
                ))}
              </Box>
              <PasswordChangeDialog ref={pwdChangeDialog} />
            </Form>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box mb={3} className={classes.formBox}>
              <Typography
                variant="h5"
                className={clsx(classes.formHeader, classes.bordered)}
              >
                Přihlášení pomocí externího poskytovatele identity
              </Typography>
              {!profile && (
                <div className={classes.boxLoading}>
                  <CircularProgress />
                </div>
              )}
              {profile &&
                sortBy(identityProvidersSource.items, ['name'])
                  .filter(
                    (idp) =>
                      idp.id !== IdentityProvider.MOJE_ID ||
                      profile?.externalIds?.find(
                        (externalId) => externalId.identityProvider === idp.id
                      )
                  )
                  .map((idp) => (
                    <ExternalIdentityProviderRow
                      key={idp.id}
                      label={idp.name}
                      reloadProfile={getProfile}
                      userExternalId={profile?.externalIds?.find(
                        (externalId) => externalId.identityProvider === idp.id
                      )}
                      identityProvider={idp.id}
                    />
                  ))}
            </Box>

            <Form<Pick<ProfileForm, 'twoFactorDetails'>>
              editing={!loading && secondFactorInfoEditing}
              initialValues={{
                twoFactorDetails: {
                  email: {
                    id: '',
                    value: '',
                  },
                  phoneNumber: {
                    prefix: '',
                    number: {
                      id: '',
                      value: '',
                    },
                  },
                  preferredChannel: undefined,
                },
              }}
              onSubmit={handleSubmitSecondarFactorInfo}
              ref={refSecondFactorInfo}
              validationSchema={secondFactorInfoValidationSchema}
            >
              <Box mb={3} className={classes.formBox}>
                <div className={classes.formHeaderWrapper}>
                  <Typography variant="h5" className={classes.formHeader}>
                    Údaje pro dvoufaktorové ověření
                  </Typography>
                  {!secondFactorInfoEditing && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading || contactInfoEditing}
                        onClick={handleStartSecondFactorInfoEditing}
                        className={classes.button}
                      >
                        Upravit
                      </Button>
                    </>
                  )}
                  {secondFactorInfoEditing && (
                    <div className={classes.buttonGroup}>
                      <FormSubmitButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                        startIcon={
                          loading && (
                            <CircularProgress size="20px" color="inherit" />
                          )
                        }
                      >
                        Uložit
                      </FormSubmitButton>
                      <Box width={12} />
                      <Button
                        variant="contained"
                        disabled={loading}
                        onClick={handleCancelSecondFactorInfoEditing}
                      >
                        Zrušit
                      </Button>
                    </div>
                  )}
                </div>

                <ProfileFields.TfdPrefferedChannel
                  name="twoFactorDetails.preferredChannel"
                  label="Preferovaný způsob ověření"
                  source={preferredChannelTypes}
                  valueIsId={true}
                />

                <ProfileFields.TfdEmailField
                  name="twoFactorDetails.email.value"
                  label="E-mail"
                />

                <ProfileFields.TfdPhoneField
                  name="twoFactorDetails.phoneNumber"
                  label="Telefon (předvolba, číslo)"
                />
              </Box>
            </Form>

            <Box mb={3} className={classes.formBox}>
              <Typography
                variant="h5"
                className={clsx(classes.formHeader, classes.bordered)}
              >
                Uživatelské nastavení
              </Typography>

              <ProtectedFieldWrapper disabled={false} label="Nastavení sloupců">
                <Button
                  variant="contained"
                  size="small"
                  disabled={contactInfoEditing || secondFactorInfoEditing}
                  onClick={handleUserSettingsClear}
                >
                  obnovit výchozí nastavení přehledů
                </Button>
              </ProtectedFieldWrapper>
            </Box>
          </Grid>
        </Grid>

        <Divider />

        <Box>
          {profile && <AdminRoles profile={profile} showReportButton={false} />}
        </Box>

        <Box>
          {profile && <UserRoles profile={profile} showReportButton={false} />}
        </Box>

        <Box>
          {profile && <OtherRoles profile={profile} showReportButton={false} />}
        </Box>

        <Box>{profile && <EmpoweredRoles profile={profile} />}</Box>

        <Box>{profile && <History profile={profile} />}</Box>

        <Box>{profile && <PersonalEvents />}</Box>
      </Container>
    </ProtectedPage>
  );
}

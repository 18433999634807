import React from 'react';
import {
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
  DatedFields,
  FormPanel,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export function TerritorialTechnicalUnitsFields() {
  return (
    <FormPanel label="Obecné">
      <FormTextField
        name="name"
        label={
          <HelpLabel
            label="Název"
            code="DICT_TERRITORIAL_TECHNICAL_UNITS_NAME"
            required
          />
        }
      />
      <FormTextField
        name="code"
        label={
          <HelpLabel
            label="Kód"
            code="DICT_TERRITORIAL_TECHNICAL_UNITS_CODE"
            required
          />
        } // Povinné pole
      />
      <FormCheckbox
        name="active"
        label={
          <HelpLabel
            label="Aktivní"
            code="DICT_TERRITORIAL_TECHNICAL_UNITS_ACTIVE"
          />
        }
        disabled={true}
      />
      <FormDateTimeField
        name="validFrom"
        label={
          <HelpLabel
            label="Platný od"
            code="DICT_TERRITORIAL_TECHNICAL_UNITS_VALID_FROM"
          />
        }
      />
      <FormDateTimeField
        name="validTo"
        label={
          <HelpLabel
            label="Platný do"
            code="DICT_TERRITORIAL_TECHNICAL_UNITS_VALID_TO"
          />
        }
      />
      <DatedFields />
    </FormPanel>
  );
}

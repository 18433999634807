import React from 'react';
import { Wgs84Coordinates } from '../../models';
import { Path } from './path';
import { useMemo } from 'react';
import { GeometryStyle } from './map-types';

interface LineProps {
  from?: Wgs84Coordinates;
  to?: Wgs84Coordinates;
}

export function Line({ from, to }: LineProps) {
  const coordsFrom = useMemo(() => {
    return window.SMap.Coords.fromWGS84(Number(from?.lon), Number(from?.lat));
  }, [from]);

  const coordsTo = useMemo(() => {
    return window.SMap.Coords.fromWGS84(Number(to?.lon), Number(to?.lat));
  }, [to]);

  const pathDefinition = useMemo(() => {
    return ['M', coordsFrom, 'L', coordsTo];
  }, [coordsFrom, coordsTo]);
  return (
    <Path
      path={pathDefinition}
      options={{
        color: '#f00',
        width: 3,
        style: GeometryStyle.DASH,
      }}
    />
  );
}

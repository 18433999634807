import { DomainObject, User } from '@eas/common-web';
import { EntityType } from '../enums/entity-type';

interface SimpleLogBase extends DomainObject {
  /**
   * Reference to owning entity
   */
  entityReference?: {
    id?: string;
    entityType?: EntityType;
  };
}

export type CrzpSimpleLog = SimpleLogBase & {
  /**
   * Time of change
   */
  created?: string;

  /**
   * Author of change
   */
  createdBy?: User;

  /**
   * Type of modification
   */
  type?: Exclude<SimpleLogEventType, SimpleLogEventType.LEGACY>;

  /**
   * Detail of changes
   */
  content?: SimpleLogChange[];

  note?: string;
};

export type LegacySimpleLog = SimpleLogBase & {
  /**
   * Time of change
   */
  created?: string;

  /**
   * Author of change
   */
  createdBy?: User;

  type: SimpleLogEventType.LEGACY;
  content: {
    type: string;
    info?: string;
  };
};

export type ObtainedSimpleLog = CrzpSimpleLog | LegacySimpleLog;

export type SimpleLog = SimpleLogBase & {
  /**
   * Time of change
   */
  created?: string;

  /**
   * Author of change
   */
  createdBy?: User;

  /**
   * Type of modification
   */
  type?: SimpleLogEventType;

  /**
   * Detail of change
   */
  content?: SimpleLogChange;
  note?: string;
};

/**
 * Type of modification
 */
export enum SimpleLogEventType {
  MIGRATION = 'MIGRATION',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  LEGACY = 'LEGACY',
}

/**
 * Enum of possible Change objects
 */
export enum HistoryFieldType {
  STRING = 'STRING',
  ID_LABEL = 'ID_LABEL',
  LOCAL_DATE = 'LOCAL_DATE',
  LOCAL_DATE_TIME = 'LOCAL_DATE_TIME',
  COLLECTION = 'COLLECTION',
  NONE = 'NONE',
  NESTED = 'NESTED',
  BOOLEAN = 'BOOLEAN',
}

export interface SimpleHistoryField {
  type: HistoryFieldType.STRING;
  value: string;
}

export interface NestedHistoryField {
  type: HistoryFieldType.NESTED;
  map: {
    [key: string]: HistoryFieldUnion;
  };
}

export interface LocalDateHistoryField {
  type: HistoryFieldType.LOCAL_DATE;
  value: string;
}

export interface LocalDateTimeHistoryField {
  type: HistoryFieldType.LOCAL_DATE_TIME;
  value: string;
}

export interface BooleanHistoryField {
  type: HistoryFieldType.BOOLEAN;
  value: boolean;
}

export interface IdLabelHistoryField {
  type: HistoryFieldType.ID_LABEL;
  id: string;
  label: string;
}

export interface CollectionHistoryField {
  type: HistoryFieldType.COLLECTION;
  list: HistoryFieldUnion[];
}

export type HistoryFieldUnion =
  | SimpleHistoryField
  | NestedHistoryField
  | LocalDateHistoryField
  | LocalDateTimeHistoryField
  | BooleanHistoryField
  | IdLabelHistoryField
  | CollectionHistoryField;

export interface SimpleLogChange<TYPE = HistoryFieldUnion> {
  key: string;
  valueBefore?: TYPE;
  valueAfter?: TYPE;
  value?: string;
}

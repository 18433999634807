import React, { useRef, useContext } from 'react';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
} from '@eas/common-web';
import { Me, BoilerManufacturer, DictionaryBoilerBrand } from '../../../models';
import { Messages, EvidenceAPI, Permission } from '../../../enums';
import { unstable_batchedUpdates } from 'react-dom';

/**
 * Api call
 *
 * @param brand
 */
export function callApi(brand: DictionaryBoilerBrand) {
  return abortableFetch(
    `${EvidenceAPI.DICTIONARY_BOILER_BRANDS}/${brand.id}/active`,
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    }
  );
}

/**
 * Obnovení značky
 */
const PROMPT_KEY = 'ACTIVATE_BRAND';

export function useActivateBrandDialog({
  boilerBrands,
  selectedIndex,
  setSelectedIndex,
}: {
  boilerBrands: DictionaryBoilerBrand[];
  selectedIndex: number;
  setSelectedIndex: (index: number | undefined) => void;
}) {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext<DetailHandle<BoilerManufacturer>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission, reload } = useContext<UserContext<Me>>(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Obnovení značky',
        dialogText: (
          <>
            Skutečně chcete obnovit značku{' '}
            <b>
              {selectedIndex !== undefined
                ? boilerBrands[selectedIndex]?.name
                : ''}
            </b>
            ?
          </>
        ),
        dialogWidth: 600,
      },
    ],
    [selectedIndex, boilerBrands]
  );

  const handleActivateBrand = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(boilerBrands[selectedIndex]);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Ozo.ACTIVATE_BRAND.SUCCESS);
            source.setLoading(false);
          });

          setSelectedIndex(undefined);
          onPersisted(source.data!.id);
          await source.refresh();
          await reload();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Ozo.ACTIVATE_BRAND.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const canActivateBrand =
    selectedIndex !== undefined &&
    boilerBrands?.[selectedIndex]?.active === false &&
    hasPermission(Permission.Ozo.OZO_BRAND_UPDATE, {
      searchInSubjectPerms: true,
    });

  return {
    handleActivateBrand,
    canActivateBrand,
  };
}

import React, { useMemo, useContext } from 'react';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../../enums';
import { useDictionaryEvidence } from '../../../components/evidence/dictionary-evidence/dictionary-evidence';
import { useValidationSchema } from './schedule-jobs-schema';
import { Evidence2, UserContext } from '@eas/common-web';
import { useColumns } from './schedule-jobs-columns';
import { ScheduleJob, Me } from '../../../models';
import { ScheduleJobsFields } from './schedule-jobs-fields';
import { ScheduleJobsActionbar } from './schedule-jobs-actionbar';
import { DictionaryActionbar } from '../../../components/evidence/dictionary-evidence/dictionary-actionbar';
import { usePermission } from '../../../components/permission/perm-evidence-hook';

export function ScheduleJobs() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission({
    options: {
      NEW: [],
      EDIT: [Permission.Default.ADMINISTRATOR],
      REMOVE: [],
      CHECK: [Permission.Default.ADMINISTRATOR],
      SAVE: [Permission.Default.ADMINISTRATOR],
      CLOSE: [Permission.Default.ADMINISTRATOR],
    },
  });

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const canSeeDeactivated = useMemo(
    () => hasPermission(Permission.ScheduledTask.SCHEDULED_TASK_REVALIDATE),
    [hasPermission]
  );

  const evidence = useDictionaryEvidence<ScheduleJob>({
    version: 3,
    identifier: PageKey.SCHEDULE_JOBS,
    apiProps: {
      url: EvidenceAPI.SCHEDULE_JOBS,
    },
    tableProps: {
      columns: useColumns(canSeeDeactivated),
      tableName: 'Pravidelně spouštěné úlohy',
      showColumn: (column) => column !== 'ACTIVE',
    },
    detailProps: {
      entityName: EntityName.SCHEDULE_JOB,
      codePrefix: 'SCHEDULE_JOB',
      toolbarProps: {
        title: 'Úloha',
        ActionBar: function ActionBar() {
          return (
            <DictionaryActionbar
              url={EvidenceAPI.SCHEDULE_JOBS}
              invalidatePerm={
                Permission.ScheduledTask.SCHEDULED_TASK_INVALIDATE
              }
              revalidatePerm={
                Permission.ScheduledTask.SCHEDULED_TASK_REVALIDATE
              }
              CustomActions={ScheduleJobsActionbar}
            />
          );
        },
        showBtn: permissions,
      },
      validationSchema,
      FieldsComponent: ScheduleJobsFields,
    },
  });

  return <Evidence2 {...evidence} />;
}

import React, { useContext, useEffect } from 'react';
import { cloneDeep, noop } from 'lodash';
import {
  FormTextField,
  FormPanel,
  FormAutocomplete,
  useFormSelector,
  FormNumberField,
  FormDateField,
  FormTextArea,
  FormInlineTableField,
  FormContext,
  FormCheckbox,
  EmptyComponent,
  InlineTableField,
  useEventCallback,
  DetailMode,
  DetailContext,
  FormCheckboxGroup,
  UserContext,
  ApiFilterOperation,
  eqFilterParams,
  FormTableField,
} from '@eas/common-web';
import LinkIcon from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useNaceItems } from '../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../dict-nace-cz-item/dict-nace-cz-items-api';
import { useCzRiverBasins } from '../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { useEuRiverBasins } from '../dict-river-basin-eu-district/dict-river-basin-eu-district-api';
import { useIrzActivities } from '../dict-irz-activity/dict-irz-activities-api';
import { useProductionSites } from '../production-site/production-sites-api';
import {
  useMainActivities,
  useEPrtrAndIppcActivities,
} from './irz-facilities-api';
import {
  useSecondaryIrzActivityColumns,
  useSecondaryEPrtrActivityColumns,
  useTradesInEmissionsColumns,
  authoritiesColumns,
  useCompetentControlAuthoritiesColumns,
  useIppcPidColumns,
  useEuStatesColumns,
} from './irz-facilities-columns';
import { LocationPanel } from '../../components/form/location-panel/location-panel';
import { SubjectFormPanel } from '../../composite/subject-fields/subject-form-panel';
import { useOvzFacilities } from '../ovz-facility/ovz-facilities-api';
import {
  dictionaryLabelMapper,
  dictionaryNoCodeLabelMapper,
} from '../../components/form/mappers/label-mappers';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import {
  FormPanelCountByName,
  FormPanelCount,
} from '../../components/form/form-panel-summary/form-panel-count';
import { AuthoredFields } from '../../components/evidence/authored-evidence/authored-fields';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { HelpLabel } from '../../components/help/help-label';
import { useCompetentControlAuthorities } from '../irz-facility-request-new/step-control-authorities/step-control-authorities-api';
import { Permission, EvidenceAPI } from '../../enums';
import {
  IrzFacility,
  ActivityRecord,
  MainActivityType,
  Me,
  LegalEntity,
  BusinessNaturalPerson,
} from '../../models';

const useStyles = makeStyles(() => ({
  generateButton: {
    padding: '0px 20px',
  },
  maxWidth: {
    maxWidth: '55%',
  },
  fullWidth: {
    width: '100%',
  },
}));

export function IrzFacilitiesFields(props: NotedEvidenceFieldsProps) {
  const classes = useStyles();

  const naceItems = useNaceItems();
  const naceCzItems = useNaceCzItems();
  const types = useMainActivities();
  const czRiverBasins = useCzRiverBasins(
    EvidenceAPI.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS
  );
  const euRiverBasins = useEuRiverBasins();
  const irzActivities = useIrzActivities();
  const { ePrtrActivities, ippcActivities } = useEPrtrAndIppcActivities();
  const productionSites = useProductionSites();
  const controlAuthorities = useCompetentControlAuthorities();

  const { setFieldValue } = useContext(FormContext);
  const { mode, source } = useContext(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const canEditAll = hasPermission(Permission.IrzFacility.IRZ_UPDATE, {
    searchInSubjectPerms: true,
  });

  const canEditLimited = hasPermission(
    Permission.IrzFacility.IRZ_UPDATE_NON_APPROVED_FIELDS,
    {
      searchInSubjectPerms: true,
    }
  );

  const isLimitedEdit =
    mode === DetailMode.EDIT && canEditLimited && !canEditAll;

  const {
    hasValidationExpiration,
    activityTypes,
    wgs84Coordinates,
    tradesInEmissions,
    competentAuthority,
    competentControlAuthorities,
    address,
    mainIrzActivity,
    secondaryIrzActivities,
    czRiverBasinDistrict,
    czNace,
    operator,
    webSite,
    hasIntegratedPermission,
  } = useFormSelector((data: IrzFacility) => ({
    hasValidationExpiration: Boolean(data.validTo),
    activityTypes: data.activityTypes,
    wgs84Coordinates: data.wgs84Coordinates,
    tradesInEmissions: data.emissionsTradingSystem?.tradesInEmissions,
    competentAuthority: data.competentAuthority,
    competentControlAuthorities: data.competentControlAuthorities,
    address: data.address,
    mainIrzActivity: data.mainIrzActivity,
    secondaryIrzActivities: data.secondaryIrzActivities ?? [],
    czRiverBasinDistrict: data.czRiverBasinDistrict,
    czNace: data.czNace,
    operator: data.operator,
    webSite: data.webSite,
    hasIntegratedPermission: data.integratedPermission?.hasPermission,
  }));

  const ovzFacilities = useOvzFacilities(
    eqFilterParams({ field: 'operator.id', value: operator?.id })
  );
  const euStatesColumns = useEuStatesColumns();
  const secondaryIrzActivitiesColumns = useSecondaryIrzActivityColumns();
  const secondaryEPrtrActivitiesColumns = useSecondaryEPrtrActivityColumns();
  const tradesInEmissionsColumns = useTradesInEmissionsColumns();
  const competentControlAuthoritiesColumns = useCompetentControlAuthoritiesColumns();
  const ippcPidColumns = useIppcPidColumns();
  const hasOperatorIco = operator && 'ico' in operator;

  const handleMainActivityTypeChanged = useEventCallback(() => {
    if (activityTypes?.includes(MainActivityType.E_PRTR)) {
      if (mainIrzActivity) {
        const newSecondaryIrzActivities = cloneDeep(secondaryIrzActivities);
        newSecondaryIrzActivities?.push(mainIrzActivity);

        setFieldValue('secondaryIrzActivities', newSecondaryIrzActivities);
        setFieldValue('mainIrzActivity', undefined);
      }
    }
    if (!activityTypes?.includes(MainActivityType.IRZ)) {
      setFieldValue('secondaryIrzActivities', undefined);
    }
    if (!activityTypes?.includes(MainActivityType.E_PRTR)) {
      setFieldValue('mainEprtrActivity', undefined);
      setFieldValue('secondaryEprtrActivities', undefined);
    }
  });

  useEffect(() => {
    if (
      mode !== DetailMode.VIEW &&
      source.data.czRiverBasinDistrict !== czRiverBasinDistrict
    ) {
      setFieldValue(
        'euRiverBasinDistrict',
        czRiverBasinDistrict?.euRiverBasinDistrict
      );
    }
  }, [czRiverBasinDistrict]);

  useEffect(() => {
    if (mode !== DetailMode.VIEW) {
      setFieldValue('nace', czNace?.nace);
    }
  }, [czNace]);

  useEffect(() => {
    const getAuthorities = async () => {
      controlAuthorities.reset();
      controlAuthorities.setParams({
        filters: [
          {
            field: 'districts.id',
            operation: ApiFilterOperation.EQ,
            value: address?.districtRuian?.id,
          },
        ],
      });
      await controlAuthorities.loadMore();
    };

    if (address?.districtRuian) {
      getAuthorities();
    } else {
      setFieldValue('competentControlAuthorities', undefined);
    }
  }, [address?.districtRuian?.id]);

  useEffect(() => {
    if (mode === DetailMode.NEW) {
      setFieldValue(
        'competentControlAuthorities',
        controlAuthorities?.items ?? []
      );
    }
  }, [controlAuthorities.items]);

  useEffect(() => {
    if ([DetailMode.NEW, DetailMode.EDIT].includes(mode)) {
      setFieldValue('ovzFacility', undefined);
    }
  }, [operator?.id]);

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="name"
          label={
            <HelpLabel
              label="Název provozovny"
              code="IRZ_FACILITY_NAME"
              required
            />
          } // Povinné pole
        />
        <FormTextField
          name="facilityId"
          label={
            <HelpLabel
              label="IČP provozovny IRZ"
              code="IRZ_FACILITY_FACILITY_ID"
            />
          }
          disabled={true}
        />
        {mode === DetailMode.VIEW && (
          <>
            <FormCheckbox
              name="active"
              label={<HelpLabel label="Aktivní" code="IRZ_FACILITY_ACTIVE" />}
              disabled
            />
          </>
        )}
        <FormTableField
          name="euStateRecords"
          label={
            <HelpLabel label="Stav EU" code="IRZ_FACILITY_EU_STATE_RECORDS" />
          }
          columns={euStatesColumns}
          disabled={true}
          ToolbarComponent={EmptyComponent}
          showDetailBtnCond={() => false}
        />
        <FormTextField
          name="webSite"
          label={
            <HelpLabel
              label="Web stránka"
              code="IRZ_FACILITY_WEB_SITE"
              required={hasOperatorIco}
            />
          } // Povinné pole, musí být platný odkaz url
          after={
            [DetailMode.NEW, DetailMode.EDIT].includes(mode) && (
              <Button
                onClick={() => {
                  const website = hasOperatorIco
                    ? `https://wwwinfo.mfcr.cz/cgi-bin/ares/sh_reg.cgi?ico=${
                        (operator as LegalEntity | BusinessNaturalPerson).ico
                      }`
                    : '';

                  setFieldValue('webSite', website);
                }}
                size="small"
                startIcon={<LinkIcon />}
                className={classes.generateButton}
                disabled={!!webSite || !hasOperatorIco}
              >
                Generovat
              </Button>
            )
          }
        />
        <FormTextField
          name="publicEmail"
          label={
            <HelpLabel
              label="Veřejný e-mail"
              code="IRZ_FACILITY_PUBLIC_EMAIL"
            />
          }
        />
        <FormTextField
          name="publicPhoneNumber"
          label={
            <HelpLabel
              label="Veřejný telefon"
              code="IRZ_FACILITY_PUBLIC_PHONE_NUMBER"
            />
          }
        />
        <FormDateField
          name="registrationDate"
          label={
            <HelpLabel
              label="Datum registrace"
              code="IRZ_FACILITY_REGISTRATION_DATE"
            />
          }
          disabled
        />
        {hasValidationExpiration && (
          <FormDateField
            name="validTo"
            label={
              <HelpLabel
                label="Datum zneplatnění"
                code="IRZ_FACILITY_VALID_TO"
              />
            }
            disabled
          />
        )}
        <FormTextArea
          name="note"
          disabled={isLimitedEdit}
          label={<HelpLabel label="Poznámky" code="IRZ_FACILITY_NOTE" />}
        />
        <FormDateField
          name="commissioningDate"
          disabled={isLimitedEdit}
          label={
            <HelpLabel
              label="Datum uvedení do provozu"
              code="IRZ_FACILITY_COMMISSION_DATE"
            />
          }
        />
      </FormPanel>

      <SubjectFormPanel
        label="Provozovatel"
        codePrefix="IRZ_FACILITY"
        autocomplete={{
          name: 'operator',
          label: 'Název provozovatele',
          required: true, // Povinné pole
        }}
        addressLabel="Adresa sídla"
        disabled={mode !== DetailMode.NEW}
      />

      <LocationPanel
        locationName="wgs84Coordinates"
        locationValue={wgs84Coordinates}
        addressName="address"
        address={address}
        disableForeignCountry={true}
        expandable={true}
        defaultExpanded={false}
        disabled={isLimitedEdit}
        locationPanelLabel="Poloha provozovny"
        addressPanelLabel="Adresa provozovny"
        showEtrs89Coordinates={mode === DetailMode.VIEW && canEditAll}
        prefillCoordinates={false}
      />

      <FormPanel label="Klasifikace, Identifikátory">
        <FormTextField
          name="facilityId"
          label={
            <HelpLabel
              label="IČP provozovny IRZ"
              code="IRZ_FACILITY_FACILITY_ID"
            />
          }
          disabled={true}
        />
        <FormAutocomplete
          name="productionSite"
          disabled={isLimitedEdit}
          label={
            <HelpLabel
              label="Průmyslové místo"
              code="IRZ_FACILITY_PRODUCTION_SITE"
            />
          }
          source={productionSites}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormTextField
          name="inspireId"
          label={
            <HelpLabel label="Inspire ID" code="IRZ_FACILITY_INSPIRE_ID" />
          }
          disabled={true}
        />
        <FormTextField
          name="thematicId"
          label={
            <HelpLabel label="Thematic ID" code="IRZ_FACILITY_THEMATIC_ID" />
          }
          disabled={true}
        />
        <FormAutocomplete
          name="czNace"
          label={
            <HelpLabel label="CZ-NACE" code="IRZ_FACILITY_CZ_NACE" required />
          } // Povinné pole
          source={naceCzItems}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          name="nace"
          label={<HelpLabel label="NACE" code="IRZ_FACILITY_NACE" required />} // Povinné pole
          source={naceItems}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
          disabled={true}
        />
        <FormAutocomplete
          name="czRiverBasinDistrict"
          label={
            <HelpLabel
              label="Oblast povodí ČR"
              code="IRZ_FACILITY_CZ_RIVER_BASIN_DISTRICT"
              required
            />
          } // Povinné pole
          source={czRiverBasins}
          tooltipMapper={dictionaryNoCodeLabelMapper}
          labelMapper={dictionaryNoCodeLabelMapper}
        />
        <FormAutocomplete
          name="euRiverBasinDistrict"
          disabled={isLimitedEdit}
          label={
            <HelpLabel
              label="Oblast povodí Evropy"
              code="IRZ_FACILITY_EU_RIVER_BASIN_DISTRICT"
              required
            />
          } // Povinné pole
          source={euRiverBasins}
          tooltipMapper={dictionaryNoCodeLabelMapper}
          labelMapper={dictionaryNoCodeLabelMapper}
        />
        <FormAutocomplete
          name="ovzFacility"
          label={
            <HelpLabel
              label="IČP provozovny ovzduší"
              code="IRZ_FACILITY_OVZ_FACILITY"
            />
          }
          source={ovzFacilities}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
          disabled={!operator}
        />
        <FormCheckbox
          name="integratedPermission.hasPermission"
          label={
            <HelpLabel
              label="Provozovna/Zařízení má Integrované povolení"
              code="IRZ_FACILITY_INTEGRATED_PERMISSION_HAS_PERMISSION"
            />
          }
        />
        {hasIntegratedPermission && (
          <FormInlineTableField<string>
            name="integratedPermission.ippcPids"
            label={
              <HelpLabel
                label="Seznam PID (IPPC)"
                code="IRZ_FACILITY_IPPC_PIDS"
              />
            }
            columns={ippcPidColumns}
            initNewItem={() => ''}
          />
        )}
        <FormCheckbox
          name="emissionsTradingSystem.tradesInEmissions"
          label={
            <HelpLabel
              label="Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi"
              code="IRZ_FACILITY_EMISSIONS_TRADING_SYSTEM_TRADES_IN_EMISSIONS"
            />
          }
        />
        {tradesInEmissions === true && (
          <FormInlineTableField<string>
            name="emissionsTradingSystem.ids"
            label={
              <HelpLabel
                label="ID zařízení (EU ETS)"
                code="IRZ_FACILITY_EMISSIONS_TRADING_SYSTEM_IDS"
              />
            } // Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9
            columns={tradesInEmissionsColumns}
            initNewItem={() => ''}
          />
        )}
      </FormPanel>

      <FormPanel label="Činnosti">
        <FormCheckboxGroup
          source={types}
          label={
            <HelpLabel
              label="Typ činnosti"
              code="IRZ_FACILITY_ACTIVITY_TYPES"
              required
            />
          } // Povinné pole
          name="activityTypes"
          labelMapper={dictionaryLabelMapper}
          notifyChange={handleMainActivityTypeChanged}
        />
        {activityTypes?.includes(MainActivityType.E_PRTR) && (
          <>
            <FormAutocomplete
              name="mainEprtrActivity.activity"
              label={
                <HelpLabel
                  label="Hlavní E-PRTR činnost"
                  code="IRZ_FACILITY_MAIN_EPRTR_ACTIVITY_ACTIVITY"
                />
              }
              source={ePrtrActivities}
              tooltipMapper={dictionaryLabelMapper}
              labelMapper={dictionaryLabelMapper}
            />
            <FormTextField
              name="mainEprtrActivity.facilityCount"
              type="number"
              label={
                <HelpLabel
                  label="Počet zařízení"
                  code="IRZ_FACILITY_MAIN_EPRTR_ACTIVITY_COUNT"
                />
              }
            />
            <FormAutocomplete
              name="ippcActivity"
              label={
                <HelpLabel
                  label="Činnost IPPC"
                  code="IRZ_FACILITY_IPPC_ACTIVITY"
                />
              }
              source={ippcActivities}
              tooltipMapper={dictionaryLabelMapper}
              labelMapper={dictionaryLabelMapper}
            />
            <FormInlineTableField<ActivityRecord>
              name="secondaryEprtrActivities"
              label={
                <HelpLabel
                  label="Seznam vedlejších E-PRTR činností"
                  code="IRZ_FACILITY_SECONDARY_EPRTR_ACTIVITIES"
                />
              } // Povinné pole, nesmí obsahovat hlavní činnost
              columns={secondaryEPrtrActivitiesColumns}
              maxRows={5}
            />
          </>
        )}
        {activityTypes?.includes(MainActivityType.IRZ) && (
          <>
            {!activityTypes?.includes(MainActivityType.E_PRTR) && (
              <>
                <FormAutocomplete
                  name="mainIrzActivity.activity"
                  label={
                    <HelpLabel
                      label="Hlavní činnost dle přílohy zákona o IRZ a ISPOP"
                      code="IRZ_FACILITY_MAIN_IRZ_ACTIVITY_ACTIVITY"
                      required
                    />
                  } // Povinné pole
                  source={irzActivities}
                  tooltipMapper={dictionaryLabelMapper}
                  labelMapper={dictionaryLabelMapper}
                />
                <FormTextField
                  name="mainIrzActivity.facilityCount"
                  label={
                    <HelpLabel
                      label="Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP"
                      code="IRZ_FACILITY_MAIN_IRZ_ACTIVITY_FACILITY_COUNT"
                      required
                    />
                  } // Povinné pole, nejméně 1 zařízení
                />
              </>
            )}
            <FormInlineTableField<ActivityRecord>
              name="secondaryIrzActivities"
              label={
                <HelpLabel
                  label="Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP"
                  code="IRZ_FACILITY_SECONDARY_IRZ_ACTIVITIES"
                />
              } // Povinné pole, nesmí obsahovat hlavní činnost
              columns={secondaryIrzActivitiesColumns}
              maxRows={5}
            />
          </>
        )}
        {!activityTypes?.includes(MainActivityType.E_PRTR) && (
          <FormAutocomplete
            name="ippcActivity"
            label={
              <HelpLabel
                label="Činnost IPPC"
                code="IRZ_FACILITY_IPPC_ACTIVITY"
              />
            }
            source={ippcActivities}
            tooltipMapper={dictionaryLabelMapper}
            labelMapper={dictionaryLabelMapper}
          />
        )}
        <FormNumberField
          name="facilityCount"
          label={
            <HelpLabel
              label="Celkový počet zařízení"
              code="IRZ_FACILITY_FACILITY_COUNT"
            />
          }
        />
      </FormPanel>
      {mode === DetailMode.VIEW && (
        <FormPanel
          label="Kompetentní orgán - IRZ"
          summary={<FormPanelCount count={1} />}
        >
          <InlineTableField
            disabled={isLimitedEdit}
            onChange={noop}
            value={[competentAuthority]}
            maxRows={2}
            ToolbarComponent={EmptyComponent}
            withRemove={false}
            columns={authoritiesColumns}
          />
        </FormPanel>
      )}

      <FormPanel
        label="Kompetentní kontrolní a ostatní orgány"
        summary={<FormPanelCountByName name="competentControlAuthorities" />}
      >
        <FormInlineTableField
          disabled={isLimitedEdit}
          name="competentControlAuthorities"
          labelOptions={{
            hide: true,
          }}
          layoutOptions={{
            noSpacing: true,
          }}
          disabledAdd={
            competentControlAuthorities &&
            competentControlAuthorities?.length >= 3
          }
          maxRows={3}
          columns={competentControlAuthoritiesColumns}
        />
      </FormPanel>

      {props.Notes}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <AuthoredFields FieldsComponent={DatedFields} />
      </FormPanel>
    </>
  );
}

import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useIrzFacilityMerge } from './irz-facility-merge-hook';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Process } from '../../components/process/process';
import { ProcessForm } from './irz-facility-merge-types';
import { TranslationKeys } from '../../enums';
const useStyles = makeStyles(() =>
  createStyles({
    loaderWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      padding: '10px',
    },
  })
);

export function IrzFacilityMerge() {
  const classes = useStyles();

  const { steps, isLoading } = useIrzFacilityMerge();

  return isLoading ? (
    <div className={classes.loaderWrapper}>
      <CircularProgress />
    </div>
  ) : (
    <Process<ProcessForm>
      title="Sloučení provozoven IRZ"
      steps={steps}
      translationKey={TranslationKeys.IRZ_FACILITY_MERGE}
    />
  );
}

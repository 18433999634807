import { IncludeOptions } from './../../enums/include-option';
import {
  useFetch,
  useStaticListSource,
  DictionaryAutocomplete,
  useAutocompleteSource,
  eqFilterParams,
  useListSource,
  ListSource,
  Filter,
} from '@eas/common-web';
import {
  RoleType,
  RelationshipType,
  RoleAutocomplete,
  DomainObject,
} from '../../models';
import { EvidenceAPI } from '../../enums';
import { noop } from 'lodash';
import { useMemo } from 'react';

export interface Permission extends DomainObject {
  code: string;
  label: string;
  description: string;
}

interface PermissionGroup extends Permission {
  permissions: Permission[];
}

interface PermissionCheckboxGroup extends Permission {
  permissions: ListSource<Permission>;
}

const identity = async <T>(item: T) => item;

export function usePermissions() {
  const [result] = useFetch<PermissionGroup[]>('/api/crzp/public/permissions', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const permissionGroups: PermissionCheckboxGroup[] = [];

  result?.forEach((item, i) => {
    permissionGroups.push({
      ...item,
      permissions: {
        loading: false,
        reset: noop,
        loadDetail: identity,
        items: item.permissions ?? [],
      },
    });
  });

  return useMemo(() => permissionGroups, [result]);
}

export function useRoleTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.ROLES + '/type/list',
    method: 'GET',
  });
}

export function useCreateableRoleTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: RoleType.SECURITY_ROLE, name: 'Bezpečnostní role' },
    { id: RoleType.SUBJECT_ROLE, name: 'Subjektová role' },
    { id: RoleType.SYSTEM_ROLE, name: 'Systémová role' },
    { id: RoleType.USER_ROLE, name: 'Uživatelská role' },
  ]);
}

export function useRoleRelationships() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: RelationshipType.ADMINISTRATOR, name: 'Správce subjektu' },
    { id: RelationshipType.USER, name: 'Běžný uživatel subjektu' },
  ]);
}

export function useRoles(filters: Filter[] = []) {
  return useAutocompleteSource<RoleAutocomplete>({
    url: '/api/crzp/role/autocomplete',
    params: {
      include: [IncludeOptions.ALL],
      filters,
    },
  });
}

export function useSubjectRoles() {
  return useAutocompleteSource<RoleAutocomplete>({
    url: EvidenceAPI.ROLES + '/autocomplete',
    params: {
      ...eqFilterParams({ field: 'type.id', value: RoleType.SUBJECT_ROLE }),
      include: [IncludeOptions.ALL],
    },
  });
}

export function useSecurityRoles() {
  return useAutocompleteSource<RoleAutocomplete>({
    url: EvidenceAPI.ROLES + '/autocomplete',
    params: {
      ...eqFilterParams({ field: 'type.id', value: RoleType.SECURITY_ROLE }),
      include: [IncludeOptions.ALL],
    },
  });
}

export function useAssignableSecurityRoles() {
  return useAutocompleteSource<RoleAutocomplete>({
    url: `${EvidenceAPI.ROLES}/autocomplete`,
    params: {
      ...eqFilterParams({ field: 'type.id', value: RoleType.SECURITY_ROLE }),
      sort: [{ type: 'FIELD', field: 'registeredFor.name', order: 'ASC' }],
    },
  });
}

export function useAssignableSystemRoles() {
  return useAutocompleteSource<RoleAutocomplete>({
    url: `${EvidenceAPI.ROLES}/autocomplete`,
    params: {
      ...eqFilterParams({ field: 'type.id', value: RoleType.SYSTEM_ROLE }),
      sort: [{ type: 'FIELD', field: 'registeredFor.name', order: 'ASC' }],
    },
  });
}

export function useAssignableUserRoles() {
  return useAutocompleteSource<RoleAutocomplete>({
    url: `${EvidenceAPI.ROLES}/autocomplete`,
    params: {
      ...eqFilterParams({ field: 'type.id', value: RoleType.USER_ROLE }),
      sort: [{ type: 'FIELD', field: 'registeredFor.name', order: 'ASC' }],
    },
  });
}

export function useAssignableRelationshipRoles() {
  return useAutocompleteSource<RoleAutocomplete>({
    url: `${EvidenceAPI.ROLES}/autocomplete`,
    params: {
      ...eqFilterParams({
        field: 'type.id',
        value: RoleType.RELATIONSHIP_ROLE,
      }),
      sort: [{ type: 'FIELD', field: 'registeredFor.name', order: 'ASC' }],
    },
  });
}

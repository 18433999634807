import React from 'react';
import { useIntl } from 'react-intl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useAdminLink } from './help-admin-link-hook';
import { Tooltip } from '@eas/common-web';

export const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    padding: '0 6px',
    color: theme.palette.error.main,
    lineHeight: '20px',
    height: 20,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  editor: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: 'white',
    width: '100%',
    padding: '0 6px',
    '& *': {
      lineHeight: '2em',
      minHeight: '2em',
    },
    '& ul, & ol': {
      marginLeft: '20px',
    },
  },
}));

export function HelpBlock({
  code,
  title,
  defaultMessage,
}: {
  code: string;
  title: string;
  defaultMessage: string;
}) {
  const classes = useStyles();
  const intl = useIntl();

  const { handleRedirect, handleRefresh, showEditButton } = useAdminLink({
    code,
    title,
  });

  return (
    <>
      {showEditButton && (
        <div className={classes.linkWrapper}>
          <Typography
            variant="body1"
            component="a"
            className={classes.link}
            onClick={handleRedirect}
          >
            Upravit nápovědu
          </Typography>
          <Typography variant="body1">(klíč: {code})</Typography>
          <Tooltip title="Obnovit text nápovědy">
            <IconButton onClick={handleRefresh} size="small">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Typography variant="body1" component="div" className={classes.editor}>
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: code,
              defaultMessage,
            }),
          }}
        />
      </Typography>
    </>
  );
}

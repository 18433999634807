import React, { useContext } from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButtonType,
  DetailActionbarButton,
} from '@eas/common-web';
import { useSync } from './actions/sync-hook';
import { useAssignAgendaDialog } from './dialog-actions/assign-agenda-hook';
import { useAssignUserDialog } from './dialog-actions/assign-user/assign-user-hook';
import { useCreateNoteDialog } from '../../components/evidence/noted-evidence/dialog-actions/create-note-hook';
import { EntityType } from '../../enums/entity-type';
import { useActivateDialog } from './dialog-actions/activate-hook';
import { useDeactivateDialog } from './dialog-actions/deactivate-hook';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function SubjectsActionbar() {
  const { mode } = useContext(DetailContext);

  const {
    showSyncButton,
    showManualSyncButton,
    handleManualSynchronize,
    handleSynchronize,
    allowIszrSync,
  } = useSync();

  const {
    handleAssignAgenda,
    canAssign: showAssignAgenda,
  } = useAssignAgendaDialog();

  const {
    handleCreateNote,
    showCreateButton: showCreateNote,
  } = useCreateNoteDialog({
    entityType: EntityType.SUBJECT,
  });

  const { handleActivate, showActivateButton } = useActivateDialog();

  const { handleDeactivate, showDeactivateButton } = useDeactivateDialog();

  const { handleAssignUser, canAssign: showAssignUser } = useAssignUserDialog({
    users: [],
    selectedIndex: undefined,
    inDetailToolbar: true,
  });

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          {showActivateButton && (
            <DetailActionbarButton
              startIcon={<RefreshIcon fontSize="small" />}
              label="Obnovit"
              onClick={handleActivate}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {showDeactivateButton && (
            <DetailActionbarButton
              startIcon={<CloseIcon fontSize="small" />}
              label="Zneplatnit"
              onClick={handleDeactivate}
              type={DetailToolbarButtonType.SECONDARY}
            />
          )}
          {showAssignAgenda && (
            <DetailActionbarButton
              startIcon={<PlaylistAddIcon fontSize="small" />}
              label="Přidat agendu"
              onClick={handleAssignAgenda}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {showAssignUser && (
            <DetailActionbarButton
              startIcon={<PersonAddIcon fontSize="small" />}
              label="Přidat uživatele"
              onClick={handleAssignUser}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {showCreateNote && (
            <DetailActionbarButton
              startIcon={<NoteAddIcon fontSize="small" />}
              label="Přidat poznámku"
              onClick={handleCreateNote}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {showSyncButton && (
            <DetailActionbarButton
              startIcon={
                allowIszrSync ? (
                  <SyncDisabledIcon fontSize="small" />
                ) : (
                  <SyncIcon fontSize="small" />
                )
              }
              label={
                allowIszrSync
                  ? 'Vypnout pravidelnou synchronizaci se ZR'
                  : 'Zapnout pravidelnou synchronizaci se ZR'
              }
              onClick={handleSynchronize}
            />
          )}
          {showManualSyncButton && (
            <DetailActionbarButton
              startIcon={<SyncAltIcon fontSize="small" />}
              label="Synchronizovat se ZR"
              onClick={handleManualSynchronize}
            />
          )}
        </div>
      )}
    </>
  );
}

import React, { useContext } from 'react';
import MapIcon from '@material-ui/icons/Map';
import {
  DetailMode,
  DetailContext,
  DetailToolbarButtonType,
  DetailHandle,
  DetailActionbarButton,
} from '@eas/common-web';
import SplitIcon from '@material-ui/icons/CallSplit';
import MergeIcon from '@material-ui/icons/CallMerge';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { ProductionSite } from '../../models';
import { useProductionSite } from './production-sites-hook';
import {
  MapEvidenceContext,
  MapEvidenceView,
} from '../../components/evidence/map-evidence/map-context';
import { useCreateNoteDialog } from '../../components/evidence/noted-evidence/dialog-actions/create-note-hook';
import { EntityType } from '../../enums/entity-type';
import { useShowOnMap } from './actions/show-on-map';

export function ProductionSitesActionbar() {
  const { mode, source, onPersisted } = useContext<
    DetailHandle<ProductionSite>
  >(DetailContext);

  const { view } = useContext(MapEvidenceContext);

  const {
    showMergeButton,
    showSplitButton,
    canInvalidate,
    canRevalidate,
    handleDeactivate,
    handleActivate,
    handleMerge,
    handleSplit,
  } = useProductionSite({
    source,
    onPersisted,
  });

  const { showShowOnMapButton, handleShowOnMap } = useShowOnMap();

  const {
    handleCreateNote,
    showCreateButton: showCreateNote,
  } = useCreateNoteDialog({
    entityType: EntityType.PRODUCTION_SITE,
  });

  if (!source.data) {
    return null;
  }

  const isValid = Boolean(source.data.active);

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          <>
            {!isValid && canRevalidate && (
              <DetailActionbarButton
                startIcon={<RefreshIcon fontSize="small" />}
                label="Obnovit platnost"
                // tooltip={'Obnoví aktivaci aktuální průmyslového místa'}
                onClick={handleActivate}
                type={DetailToolbarButtonType.PRIMARY}
                disabled={view === MapEvidenceView.MAP}
              />
            )}
            {isValid && canInvalidate && (
              <DetailActionbarButton
                startIcon={<CloseIcon fontSize="small" />}
                label="Zneplatnit"
                // tooltip={'Zneplatní aktuální Průmyslové místo'}
                onClick={handleDeactivate}
                type={DetailToolbarButtonType.SECONDARY}
                disabled={view === MapEvidenceView.MAP}
              />
            )}
            {showShowOnMapButton && (
              <DetailActionbarButton
                startIcon={<MapIcon fontSize="small" />}
                label="Zobrazit na mapě"
                onClick={handleShowOnMap}
                type={DetailToolbarButtonType.NORMAL}
                disabled={view === MapEvidenceView.MAP}
              />
            )}
            {showMergeButton && (
              <DetailActionbarButton
                startIcon={<MergeIcon fontSize="small" />}
                label="Sloučit"
                // tooltip={
                //   'Otevře proces sloučení průmyslového místa do aktuálního průmyslového místa.'
                // }
                onClick={handleMerge}
                type={DetailToolbarButtonType.PRIMARY}
                disabled={view === MapEvidenceView.MAP}
              />
            )}
            {showSplitButton && (
              <DetailActionbarButton
                startIcon={<SplitIcon fontSize="small" />}
                label="Rozdělit"
                // tooltip={
                //   'Otevře proces rozdělení aktuálního průmyslového místa.'
                // }
                onClick={handleSplit}
                type={DetailToolbarButtonType.PRIMARY}
                disabled={view === MapEvidenceView.MAP}
              />
            )}
            {showCreateNote && (
              <DetailActionbarButton
                startIcon={<NoteAddIcon fontSize="small" />}
                label="Přidat poznámku"
                onClick={handleCreateNote}
                type={DetailToolbarButtonType.PRIMARY}
              />
            )}
          </>
        </div>
      )}
    </>
  );
}

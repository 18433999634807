import React from 'react';
import { StepAgendaForm } from './step-agenda-types';

export function StepAgendaLabel({
  stepValues,
}: {
  stepValues?: StepAgendaForm;
}) {
  return (
    <>
      Agenda:{' '}
      <b>
        {stepValues?.withoutAgenda
          ? 'Bez agendy'
          : stepValues?.agendas?.map((agenda) => agenda.name).join(', ')}
      </b>
    </>
  );
}

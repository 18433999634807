/**
 * Typ entity
 */
export enum EntityType {
  /**
   * Subjekt
   */
  SUBJECT = 'SUBJECT',

  /**
   * Žádost o registraci subjektu
   */
  SUBJECT_REGISTRATION = 'SUBJECT_REGISTRATION',

  /**
   * Uživatel
   */
  USER = 'USER',

  /**
   * Registrace uživatele
   */
  USER_REGISTRATION = 'USER_REGISTRATION',

  /**
   * IRZ
   */
  IRZ = 'IRZ',

  /**
   * Žádosti IRZ
   */
  IRZ_FACILITY_REQUEST = 'IRZ_FACILITY_REQUEST',

  /**
   * OVZ
   */
  OVZ = 'OVZ',

  /**
   * Žádosti OVZ
   */
  OVZ_FACILITY_REQUEST = 'OVZ_FACILITY_REQUEST',

  /**
   * Místa užívání vody
   */
  WUP = 'WUP',

  /**
   * Průmyslové místo
   */
  PRODUCTION_SITE = 'PRODUCTION_SITE',

  /*
   * Výrobci kotlů OZO
   */
  BOILER_MANUFACTURER = 'BOILER_MANUFACTURER',

  /**
   * Povolení OZO
   */
  OZO_PERMISSION = 'OZO_PERMISSION',

  /**
   * Zmocnění
   */
  EMPOWERMENT = 'EMPOWERMENT',

  /**
   * Systém třetí strany
   */
  THIRD_PARTY_SYSTEM = 'THIRD_PARTY_SYSTEM',
}

import { useEffect, useContext } from 'react';
import {
  FormContext,
  useFormSelector,
  useEventCallback,
} from '@eas/common-web';
import { StepActivitiesForm, ActivitiesData } from './step-activities-types';
import { ProcessForm } from '../irz-facility-split-types';

export function useStepActivities(allValues: ProcessForm) {
  const { setFieldValue } = useContext(FormContext);

  const { irzId } = useFormSelector(
    ({ defaultActivitiesIrzId }: StepActivitiesForm) => ({
      irzId: defaultActivitiesIrzId,
    })
  );

  const resetValues = useEventCallback(() => {
    setFieldValue('defaultActivitiesIrzId', allValues.mainFacility?.id);
    const activityData: ActivitiesData = {
      activityTypes: allValues.mainFacility?.activityTypes,
      ippcActivity: allValues.mainFacility?.ippcActivity,
      mainEprtrActivity: allValues.mainFacility?.mainEprtrActivity,
      mainIrzActivity: allValues.mainFacility?.mainIrzActivity,
      secondaryEprtrActivities:
        allValues.mainFacility?.secondaryEprtrActivities,
      secondaryIrzActivities: allValues.mainFacility?.secondaryIrzActivities,
    };
    setFieldValue('activities', activityData);
  });
  /**
   * If the facility was changed in the first step, we need to reset the form,
   * to have correct default values.
   */
  useEffect(() => {
    if (irzId !== allValues.mainFacility?.id) {
      resetValues();
    }
  }, [allValues.mainFacility?.id]);
}

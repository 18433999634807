import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { historyValueMapper } from '../../components/evidence/history-evidence/history-utils';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'code',
      label: 'Kód',
    },
    {
      key: 'description',
      label: 'Popis',
    },
    {
      key: 'location',
      label: 'Umístění',
    },
    {
      key: 'supportedAgenda',
      label: 'Podporovaná agenda',
    },
    {
      key: 'administrator',
      label: 'Správce - kontaktní osoba',
    },
    {
      key: 'isvsId',
      label: 'Identifikátor ISVS',
    },
    {
      key: 'isvsName',
      label: 'Název ISVS',
    },
    {
      key: 'ovmAdministrator',
      label: 'OVM správce ISVS',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platný od',
    },
    {
      key: 'validTo',
      label: 'Platný do',
    },
    {
      key: 'certificates',
      label: 'Certifikáty',
      valueMapper: (change) => {
        console.log(change);
        return historyValueMapper(change, getCertificateLabel);
      },
    },
  ];
}

function getCertificateLabel(key: string) {
  switch (key) {
    case 'active': {
      return 'Aktivní';
    }
    case 'validFrom': {
      return 'Platnost od';
    }
    case 'validTo': {
      return 'Platnost do';
    }
    case 'description': {
      return 'Popis';
    }
  }
}

export enum PermissionNote {
  /** Výpis poznámek */
  NOTES_LIST = 'NOTE_LIST',

  /** Vytvoření poznámky */
  NOTES_CREATE = 'NOTE_CREATE',

  /** Úprava poznámky */
  NOTES_UPDATE = 'NOTE_UPDATE',
  
  /** Smazání poznámky */
  NOTES_DELETE = 'NOTE_DELETE',
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const CREATE: MessageType<'SUCCESS' | 'ERROR' | 'BOILER_MANUFACTURER_ALREADY_EXISTS'> = {
  SUCCESS: ['Výrobce OZO byl vytvořen', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při vytváření výrobce OZO', SnackbarVariant.ERROR],
  BOILER_MANUFACTURER_ALREADY_EXISTS: ['Chyba při vytváření výrobce OZO: Pro zadaný identifikátor již výrobce kotlů existuje.', SnackbarVariant.ERROR],
};

export default { CREATE };

import React, { useContext } from 'react';
import { cloneDeep, set, get } from 'lodash';
import {
  FormFieldProps,
  TextFieldProps,
  useEventCallback,
  useFormSelector,
  FormContext,
  TextField,
  FormCustomField,
} from '@eas/common-web';
import { useStyles } from './phone-field-styles';
import { PhoneNumber } from '../../../models';

export function PhoneField(props: FormFieldProps<TextFieldProps>) {
  /**
   * Styles
   */
  const classes = useStyles();

  /**
   * Ctx stuff
   */
  const { setFieldValue, editing } = useContext(FormContext);

  /**
   * Get phone number from formik
   */
  const { phoneNumber } = useFormSelector((data: any) => ({
    phoneNumber: get(data, props.name) as PhoneNumber,
  }));

  /**
   * phoneNumber.prefix onChange handler
   */
  const handlePrefixValue = useEventCallback((prefix: string) => {
    const newValue = cloneDeep(phoneNumber ?? {});

    if (prefix?.length > 4) {
      prefix = prefix.slice(0, 4);
    }

    if (prefix) {
      set(newValue, 'prefix', prefix);
    } else {
      set(newValue, 'prefix', null);
    }

    setFieldValue!(props.name, newValue);
  });

  /**
   * phoneNumber.number onChange handler
   */
  const handleNumberValue = useEventCallback((number: string) => {
    const newValue = cloneDeep(phoneNumber ?? {});

    if (number?.length > 12) {
      number = number.slice(0, 12);
    }

    if (number) {
      set(newValue, 'number.value', number);
    } else {
      set(newValue, 'number', null);
    }

    setFieldValue!(props.name, newValue);
  });

  const prefix = phoneNumber?.prefix ?? '';
  const number = phoneNumber?.number?.value ?? '';
  const fullNumber = `${prefix} ${number}`;

  return (
    <FormCustomField label={props.label} name={props.name}>
      {editing && (
        <>
          <div className={classes.prefix}>
            <TextField
              value={prefix}
              onChange={(value) => handlePrefixValue(value!)}
              onChangeEvent={(e) => {
                e.target.value = e.target.value.replace(/[^+0-9]/gi, '');
              }}
              disabled={!editing}
            />
          </div>
          <div className={classes.number}>
            <TextField
              value={number}
              onChange={(value) => handleNumberValue(value!)}
              onChangeEvent={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/gi, '');
              }}
              disabled={!editing}
            />
          </div>
        </>
      )}
      {!editing && (
        <TextField
          value={fullNumber}
          onChange={(value) => handleNumberValue(value!)}
          disabled={!editing}
        />
      )}
    </FormCustomField>
  );
}

import { useAutocompleteSource, Params } from '@eas/common-web';
import { DictionaryLawAutocomplete } from './../../models';
import { EvidenceAPI } from '../../enums';

export function useDirpLaws(params?: Params) {
  return useAutocompleteSource<DictionaryLawAutocomplete>({
    url: `${EvidenceAPI.DICTIONARY_DIRP_LAWS}/autocomplete`,
    params,
    apiUrl: EvidenceAPI.DICTIONARY_DIRP_LAWS,
  });
}

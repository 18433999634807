import { useEffect, useContext } from 'react';
import {
  FormContext,
  useFormSelector,
  useEventCallback,
} from '@eas/common-web';
import {
  StepIdentificatorsForm,
  IdentificatorsData,
} from './step-identificators-types';
import { ProcessForm } from '../irz-facility-split-types';

export function useStepIdentificators(allValues: ProcessForm) {
  const { setFieldValue } = useContext(FormContext);

  const { irzId } = useFormSelector(
    ({ identificatorsDefaultIrzId }: StepIdentificatorsForm) => ({
      irzId: identificatorsDefaultIrzId,
    })
  );

  const resetValues = useEventCallback(() => {
    setFieldValue('identificatorsDefaultIrzId', allValues.mainFacility?.id);
    const identificators: IdentificatorsData = {
      czNace: allValues?.mainFacility?.czNace,
      nace: allValues?.mainFacility?.nace,
      czRiverBasinDistrict: allValues?.mainFacility?.czRiverBasinDistrict,
      euRiverBasinDistrict: allValues?.mainFacility?.euRiverBasinDistrict,
      integratedPermission: allValues?.mainFacility?.integratedPermission,
    };
    setFieldValue('identificators', identificators);
  });

  /**
   * If the facility was changed in the first step, we need to reset the form,
   * to have correct default values.
   */
  useEffect(() => {
    if (irzId !== allValues.mainFacility?.id) {
      resetValues();
    }
  }, [allValues.mainFacility?.id]);
}

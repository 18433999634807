import { useEventCallback, NavigationContext } from '@eas/common-web';
import { useContext } from 'react';
import { EvidenceUrl } from '../../../enums';

export function useRedirect() {
  /**
   * Context stuff.
   */
  const { navigate } = useContext(NavigationContext);

  /**
   * Callback to redirect into evidence of ovz facilities.
   */
  const handleAllFacilities = useEventCallback(() => {
    navigate(EvidenceUrl.OVZ_FACILITIES);
  });

  /**
   * Callback to redirect into new change operator request.
   */
  const handleNewRequest = useEventCallback(() => {
    navigate(EvidenceUrl.OVZ_FACILITY_REQUEST_OPERATOR_CHANGE);
  });

  return {
    handleAllFacilities,
    handleNewRequest,
  };
}

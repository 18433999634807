import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryNace } from '../../models';
import { useValidationSchema } from './dict-nace-items-schema';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';

export function DictionaryNaceItems() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryNace>({
    identifier: PageKey.DICTIONARY_NACES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_NACES,
    },
    tableProps: {
      tableName: 'NACE',
      reportTag: ExportTags.DICTIONARY_NACES,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.NACE,
      codePrefix: 'NACE',
      validationSchema,
      toolbarProps: {
        title: 'NACE',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

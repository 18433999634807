export enum PermissionMuvBalanceUnit {
  /** Přehled jednotek bilance */
  MUV_BALANCE_UNIT_LIST = 'MUV_BALANCE_UNIT_LIST',

  /** Autocomplete výběr jednotek bilance */
  MUV_BALANCE_UNIT_AUTOCOMPLETE = 'MUV_BALANCE_UNIT_AUTOCOMPLETE',

  /** Detail jednotky bilance */
  MUV_BALANCE_UNIT_DETAIL = 'MUV_BALANCE_UNIT_DETAIL',

  /** Vytvoření nové jednotky bilance */
  MUV_BALANCE_UNIT_CREATE = 'MUV_BALANCE_UNIT_CREATE',

  /** Editace jednotky bilance */
  MUV_BALANCE_UNIT_UPDATE = 'MUV_BALANCE_UNIT_UPDATE',

  /** Mazání jednotky bilance */
  MUV_BALANCE_UNIT_DELETE = 'MUV_BALANCE_UNIT_DELETE',

  /** Zneplatnění jednotky bilance */
  MUV_BALANCE_UNIT_INVALIDATE = 'MUV_BALANCE_UNIT_INVALIDATE',

  /** Obnovení jednotky bilance */
  MUV_BALANCE_UNIT_REVALIDATE = 'MUV_BALANCE_UNIT_REVALIDATE',
}

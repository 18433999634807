import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useStates } from './mail-campaigns-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const states = useStates();

  return [
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'emailSubject',
      label: 'Předmět emailu',
    },
    {
      key: 'emailContent',
      label: 'html',
    },
    {
      key: 'state',
      label: 'Stav',
      source: states,
    },
    {
      key: 'startTime',
      label: 'Začátek',
    },
    {
      key: 'endTime',
      label: 'Konec',
    },
    {
      key: 'forced',
      label: 'Povinný',
    },
    {
      key: 'recipients',
      label: 'Příjemci',
    },
  ];
}

import React, { useMemo, useContext } from 'react';
import {
  EvidenceProps,
  useEventCallback,
  DetailToolbarProps,
  TableSort,
  UserContext,
} from '@eas/common-web';
import { DictionaryFields } from './dictionary-fields';
import { Me, DictionaryObject } from '../../../models';
import { Permission, IncludeOptions, Messages } from '../../../enums';
import { useColumns } from './dictionary-columns';
import { useAuthoredEvidence } from '../authored-evidence/authored-evidence';
import { DictionaryActionbar } from './dictionary-actionbar';
import { DictionaryEvidenceProps } from './dictionary-type';
import { useSubTitle } from '../evidence/sub-title-hook';
import {
  createItemFactory,
  updateItemFactory,
} from '../../../utils/custom-crud';
import { getErrorMessage } from '../../../utils/get-message';
import { usePermission } from '../../permission/perm-evidence-hook';

export function useDictionaryEvidence<OBJECT extends DictionaryObject>(
  options: DictionaryEvidenceProps<OBJECT>,
  invalidatePerm?: string,
  revalidatePerm?: string
): EvidenceProps<OBJECT> {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const canSeeDeactivated = useMemo(
    () =>
      [
        revalidatePerm ?? Permission.Dictionary.DICTIONARY_REVALIDATE,
      ].some((perm) => hasPermission(perm)),
    [hasPermission]
  );

  const defaultPermissions = usePermission<DictionaryObject>({
    options: {
      NEW: [Permission.Dictionary.DICTIONARY_CREATE],
      EDIT: [Permission.Dictionary.DICTIONARY_UPDATE],
      REMOVE: [Permission.Dictionary.DICTIONARY_DELETE],
      CHECK: [
        Permission.Dictionary.DICTIONARY_CREATE,
        Permission.Dictionary.DICTIONARY_UPDATE,
      ],
      SAVE: [
        Permission.Dictionary.DICTIONARY_CREATE,
        Permission.Dictionary.DICTIONARY_UPDATE,
      ],
      CLOSE: [
        Permission.Dictionary.DICTIONARY_CREATE,
        Permission.Dictionary.DICTIONARY_UPDATE,
      ],
    },
  });

  const showButton =
    options.detailProps.toolbarProps?.showBtn ?? defaultPermissions;

  const columns = useColumns({
    columns: options.tableProps?.columns,
    canSeeDeactivated,
    showColumn: options.tableProps?.showColumn,
    columnLabelMapper: options.tableProps?.columnLabelMapper,
  });

  const defaultSorts: TableSort[] = useMemo(
    () =>
      options.tableProps?.defaultSorts ?? [
        { field: 'order', datakey: 'order', order: 'ASC', type: 'FIELD' },
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    [options.tableProps?.defaultSorts]
  );

  const GeneralFieldsComponent = useMemo(
    () =>
      function Fields() {
        return (
          <DictionaryFields
            codePrefix={options.detailProps.codePrefix}
            FieldsComponent={options.detailProps?.GeneralFieldsComponent}
          />
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.detailProps?.GeneralFieldsComponent]
  );

  const toolbarProps: DetailToolbarProps<OBJECT> = useMemo(
    () => ({
      ActionBar: function ActionBar() {
        return (
          <DictionaryActionbar
            url={options.apiProps.url}
            invalidatePerm={invalidatePerm}
            revalidatePerm={revalidatePerm}
          />
        );
      },
      ...options.detailProps?.toolbarProps,
    }),
    [options.apiProps.url, options.detailProps?.toolbarProps]
  );

  const initNewItem = useEventCallback(() => {
    const obj: OBJECT = {
      active: true,
    } as any;

    if (options.detailProps?.initNewItem !== undefined) {
      return {
        ...obj,
        ...options.detailProps?.initNewItem(),
      };
    } else {
      return obj;
    }
  });

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  return useAuthoredEvidence({
    ...options,
    tableProps: {
      ...options.tableProps,
      columns,
      defaultSorts,
      include,
    },
    apiProps: {
      ...options.apiProps,
      createItem:
        options.apiProps.createItem ??
        createItemFactory<DictionaryObject>({
          postprocessCall: (data, response) => {
            if (!response?.ok) {
              const message = getErrorMessage(
                Messages.Dictionary.CREATE,
                data.code
              )[0];

              throw { message };
            }
          },
        }),
      updateItem:
        options.apiProps.updateItem ??
        updateItemFactory<DictionaryObject>({
          postprocessCall: (data, response) => {
            if (!response?.ok) {
              const message = getErrorMessage(
                Messages.Dictionary.UPDATE,
                data.code
              )[0];

              throw { message };
            }
          },
        }),
    },
    detailProps: {
      ...options.detailProps,
      initNewItem,
      GeneralFieldsComponent: options.detailProps.hideGeneralFields
        ? undefined
        : GeneralFieldsComponent,
      toolbarProps: {
        ...toolbarProps,
        subtitle: useSubTitle<DictionaryObject>((data) => data?.name ?? ''),
        showButton: ({ button, source }) => {
          if (button === 'EDIT') {
            return !!source.data?.active && showButton(button);
          }
          return showButton(button);
        },
      },
    },
  });
}

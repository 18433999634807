import { SpecialFilter } from '../../components/evidence/history-evidence/overrides/table-searchbar';
import { SpecialFilterTextCell } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-text-cell';
import { useSpecialFilterSelectCellFactory } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-select-cell';
import { useRequestStates, useRequestTypes } from './irz-facility-requests-api';
import { ApiFilterOperation } from '@eas/common-web';

export function useSpecialFilters(): SpecialFilter[] {
  return [
    {
      label: 'Provozovatel - IČO /  UID',
      filterkey: 'requestFacilityData.operator.identifier',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Provozovatel - Název',
      filterkey: 'requestFacilityData.operator.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Název provozovny',
      filterkey: 'requestFacilityData.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'IČP',
      filterkey: 'requestFacilityData.facilityId',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Obec',
      filterkey: 'requestFacilityData.address.municipality.name',
      FilterCell: SpecialFilterTextCell,
    },
    // {
    //   label: 'Nový provozovatel - IČO / UID',
    //   filterkey: 'requestFacilityData.operator.identifier',
    //   FilterCell: SpecialFilterTextCell,
    // },
    // {
    //   label: 'Nový provozovatel - Název',
    //   filterkey: 'requestFacilityData.operator.name',
    //   FilterCell: SpecialFilterTextCell,
    // },
    {
      label: 'Typ žádosti',
      filterkey: 'type.id',
      operation: ApiFilterOperation.EQ,
      FilterCell: useSpecialFilterSelectCellFactory(useRequestTypes),
    },
    {
      label: 'Stav žádosti',
      filterkey: 'state.id',
      operation: ApiFilterOperation.EQ,
      FilterCell: useSpecialFilterSelectCellFactory(useRequestStates),
    },
  ];
}

import {
  useEventCallback,
  NavigationContext,
  EvidenceStateAction,
  DetailContext,
  DetailHandle,
} from '@eas/common-web';
import { useContext } from 'react';
import { EvidenceUrl } from '../../../enums';
import { IrzFacilityRequest } from '../../../models';

export function useRedirect() {
  /**
   * Context stuff.
   */
  const { navigate } = useContext(NavigationContext);
  const { source } = useContext<DetailHandle<IrzFacilityRequest>>(
    DetailContext
  );

  /**
   * Callback to redirect into evidence of irz facilities.
   */
  const handleAllFacilities = useEventCallback(() => {
    navigate(EvidenceUrl.IRZ_FACILITIES);
  });

  /**
   * Callback to redirect into facility detail.
   */
  const handleRedirect = useEventCallback(() => {
    navigate(EvidenceUrl.IRZ_FACILITIES, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: source.data?.irzFacility?.id,
    });
  });

  const showRedirect = !!source.data?.irzFacility?.id;

  return {
    handleAllFacilities,
    handleRedirect,
    showRedirect,
  };
}

import React, {
  useContext,
  useRef,
  useState,
  useLayoutEffect,
  RefObject,
} from 'react';
import Typography from '@material-ui/core/Typography';
import { useDebounce } from 'use-debounce/lib';
import {
  TableContext,
  TableCellProps,
  useComponentSize,
  Tooltip,
} from '@eas/common-web';

function isEllipsisActive(e: RefObject<HTMLInputElement>) {
  const current = e.current;
  return current !== null ? current.offsetWidth < current.scrollWidth : false;
}

export function TextCell<OBJECT>({ value }: TableCellProps<OBJECT>) {
  const { classes } = useContext(TableContext);

  const textRef = useRef<HTMLInputElement>(null);
  const [useTooltip, setUseTooltip] = useState(false);

  const { width } = useComponentSize(textRef);
  const [debouncedWidth] = useDebounce(width, 500);

  useLayoutEffect(() => {
    setUseTooltip(isEllipsisActive(textRef));
  }, [value, debouncedWidth]);

  const content = (
    <Typography ref={textRef} className={classes.tableCell}>
      {value}
    </Typography>
  );

  return useTooltip ? (
    <Tooltip title={value} placement="top-start" type="HOVER">
      {content}
    </Tooltip>
  ) : (
    <>{content}</>
  );
}

TextCell.displayName = 'TextCell';

import * as Yup from 'yup';
import { Subject } from '../../../models';
import { StepBasicInfoForm } from './step-basic-info-types';
import { validateOvzFacilityName } from '../../ovz-facility/ovz-facilities-schema';

export const stepBasicInfoSchema = () =>
  Yup.object<StepBasicInfoForm>().shape({
    name: validateOvzFacilityName,
    operator: Yup.mixed<Subject>().nullable().required('Povinné pole'),
  });

import React, { useContext, useEffect, useState } from 'react';
import { MapContext, MarkerLayerContext } from './map-ctx';
import { MarkerLayer as MarkerLayerType } from './map-types';

export function MarkerLayer({
  children,
  enableClusterer = true,
}: {
  children: React.ReactNode;
  enableClusterer?: boolean;
}) {
  const { map } = useContext(MapContext);

  const [markerLayer, setMarkerLayer] = useState<MarkerLayerType | null>(null);

  const clustererOn =
    enableClusterer && map?.getZoom() !== map?.getZoomRange()[1];

  useEffect(() => {
    const markerLayer = new window.SMap.Layer.Marker();
    if (clustererOn) {
      const clusterer = new window.SMap.Marker.Clusterer(map!);
      markerLayer.setClusterer(clusterer);
    }

    map?.addLayer(markerLayer);
    markerLayer.enable();

    setMarkerLayer(markerLayer);

    return () => {
      map?.removeLayer(markerLayer);
    };
  }, [clustererOn]);

  return (
    <MarkerLayerContext.Provider value={markerLayer}>
      {children}
    </MarkerLayerContext.Provider>
  );
}

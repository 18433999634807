import { AgendaSpecification } from '../../models';

export const specificationsValueMapper = ({
  value,
}: {
  value: AgendaSpecification[];
}) =>
  value ? value.map((specification) => specification.name).join(', ') : '';

specificationsValueMapper.displayName = 'specificationsMapper';

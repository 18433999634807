import React from 'react';
import { Process } from '../../components/process/process';
import { ProcessForm } from './irz-facility-requests-new-types';
import { useIrzFacilityRequestNew } from './irz-facility-requests-new-hook';
import { TranslationKeys } from '../../enums';

export function IrzFacilityRequestNew() {
  const { steps } = useIrzFacilityRequestNew();

  return (
    <Process<ProcessForm>
      title="Registrace provozovny IRZ"
      steps={steps}
      translationKey={TranslationKeys.IRZ_FACILITY_REQUEST_NEW}
    />
  );
}

import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: '0px 0px 10px #ccc',
    zIndex: 98,
  },
  outerWrapper: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    backgroundColor: '#f1f3f4',
    padding: 10,
    flex: '1 1 auto',
    overflowY: 'scroll',
  },
  toolbarWrapper: {
    backgroundColor: '#fafafa',
    paddingLeft: 10,
    display: 'flex',
    flex: '0 0 auto',
    // minHeight: 52,
    zIndex: 99,
  },
  toolbarButton: {
    height: '100%',
    minWidth: 40,
    cursor: 'pointer',
    padding: '4px 10px',
    border: 0,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#d0d0d0',
    },
  },
  toolbarButtonLabel: {
    flexDirection: 'column',
    fontSize: 12,
    fontWeight: 600,
    width: 60,
  },
  toolbarButtonIcon: {
    margin: 0,
  },
  toolbarButtonMenu: {
    width: '100%',
    textTransform: 'none',
  },
  toolbarMainButton: {
    height: '100%',
    minWidth: 40,
    cursor: 'pointer',
    padding: '4px 10px',
    boxShadow: '0 0 0',
    border: 0,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      border: 0,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  toolbarDisabledButton: {
    borderTop: '0 !important',
    borderBottom: '0 !important',
    borderRight: '0 !important',
    borderLeft: 'inherit !important',
    backgroundColor: '#e0e0e0 !important',
  },
  toolbarWarningButton: {
    border: 0,
    padding: '4px 10px',
    backgroundColor: `${theme.palette.error.dark}40`,
    '&:hover': {
      border: 0,
      backgroundColor: `${theme.palette.error.dark}60`,
    },
  },
  actionbarWrapper: {
    backgroundColor: '#fafafa',
    padding: '6px 4px',
    display: 'flex',
    flex: '0 0 auto',
    // minHeight: 38,
    width: '100%',
    overflowX: 'auto',
  },
  actionbarButton: {
    height: 25,
    minWidth: 40,
    width: 'max-content',
    cursor: 'pointer',
    padding: '0px 10px',
    marginTop: 1,
    marginLeft: 6,
    backgroundColor: '#e0e0e0',
    border: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#d0d0d0',
    },
  },
  actionbarMainButton: {
    height: 25,
    minWidth: 40,
    width: 'max-content',
    cursor: 'pointer',
    padding: '0px 10px',
    marginTop: 1,
    marginLeft: 6,
    border: 0,
    textTransform: 'none',
    boxShadow: '0 0 0',
    color: 'white',
  },
  toolbarIndentLeft: {
    marginLeft: 10,
  },
  loaderWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#e1e2e340',
    zIndex: 1000,
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
  title: {
    margin: 10,
    fontSize: 25,
    fontWeight: 500,
  },
  subTitle: {
    marginLeft: 30,
    fontSize: 16,
  },
}));

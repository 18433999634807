import React, { useContext } from 'react';
import { StepEmpowermentSubForm } from './step-empowerment-sub-form';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FieldArray, PromptContext, usePrompts } from '@eas/common-web';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import Grid from '@material-ui/core/Grid';
import { StepEmpowermentForm } from './step-empowerment-types';

const useStyles = makeStyles((theme) =>
  createStyles({
    addButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    removeButton: {
      marginTop: theme.spacing(0.5),
    },
  })
);

export function StepEmpowermentContent() {
  const classes = useStyles();

  const { testPrompt } = useContext(PromptContext);

  usePrompts([
    {
      key: 'REMOVE_PANEL',
      dialogTitle: 'Odstranit zmocnění',
      dialogText:
        'Skutečně chcete odstranit tuto agendu ve formuláři zmocnění? Tato akce je nevratná.',
    },
  ]);

  return (
    <FieldArray
      name="subForms"
      render={({ push, remove, form, name }) => {
        const { subForms } = form.values as StepEmpowermentForm;

        const addPanel = () => {
          if (subForms) {
            /**
             * Most commonly the empowerment document is shared between empowerments,
             * so it is automatically copied from the last one when adding new subform
             */
            if (subForms.length > 0) {
              const lastSubForm = subForms[subForms.length - 1];
              push({
                empowermentDocument: lastSubForm?.empowermentDocument,
              });
            } else {
              push({});
            }
          } else {
            push({});
          }
        };

        const removePanel = (index: number) => {
          testPrompt({
            key: 'REMOVE_PANEL',
            submitCallback: () => {
              remove(index);
            },
          });
        };

        return (
          <>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                size="small"
                className={classes.addButton}
                startIcon={<AddBoxOutlinedIcon />}
                disableElevation
                onClick={addPanel}
              >
                Přidat další
              </Button>
            </Grid>

            {subForms?.map((_, index) => {
              return (
                <div key={index} style={{ width: '100%' }}>
                  <StepEmpowermentSubForm name={`${name}.${index}`} />

                  {index > 0 && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      className={classes.removeButton}
                      startIcon={<IndeterminateCheckBoxOutlinedIcon />}
                      disableElevation
                      onClick={() => removePanel(index)}
                    >
                      Odstranit
                    </Button>
                  )}
                </div>
              );
            })}
          </>
        );
      }}
    />
  );
}

import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryBoilerBrand } from '../../models';
import { DictionaryBoilerBrandsFields } from './dict-boiler-brands-fields';
import { useValidationSchema } from './dict-boiler-brands-schema';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useColumns } from './dict-boiler-brands-columns';
import { useHistoryDescriptors } from './dict-boiler-brands-history';

export function DictionaryBoilerBrands() {
  const validationSchema = useValidationSchema();
  const permissions = usePermission({
    options: {
      NEW: [],
      CHECK: [],
      EDIT: [],
      REMOVE: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<DictionaryBoilerBrand>({
    identifier: PageKey.DICTIONARY_BOILER_BRANDS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_BOILER_BRANDS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled značek kotlů',
      reportTag: ExportTags.DICTIONARY_BOILER_BRANDS,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.BOILER_BRAND,
      FieldsComponent: DictionaryBoilerBrandsFields,
      validationSchema,
      toolbarProps: {
        title: 'Značka kotle',
        subtitle: useSubTitle<DictionaryBoilerBrand>(
          (data) => data?.name ?? ''
        ),
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Ozo.OZO_BRAND_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}

import { useMemo } from 'react';
import { StepIdentificatorLabel } from './step-identificator/step-identificator-label';
import { StepIdentificatorContent } from './step-identificator/step-identificator-content';
import { stepIdentificatorSchema } from './step-identificator/step-identificator-schema';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepFinishActions } from './step-finish/step-finish-actions';

export function useNewBoilerManufacturer() {
  const steps = useMemo(
    () => [
      {
        Label: StepIdentificatorLabel,
        Content: StepIdentificatorContent,
        validationSchema: stepIdentificatorSchema,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    []
  );

  return { steps };
}

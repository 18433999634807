import {
  abortableFetch,
  NavigationContext,
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceAPI, Messages } from '../../enums';
import { useContext, useState, useRef, useEffect, useMemo } from 'react';
import {
  ProductionSiteSplitStateAction,
  ProcessForm,
} from './production-site-split-types';
import { unstable_batchedUpdates } from 'react-dom';
import { ProductionSite } from '../../models';
import { StepOriginalProductionSiteLabel } from './step-original-production-site/step-original-production-site-label';
import { StepOriginalProductionSiteContent } from './step-original-production-site/step-original-production-site-content';
import { StepOriginalProductionSiteActions } from './step-original-production-site/step-original-production-site-actions';
import { stepOriginalProductionSiteSchema } from './step-original-production-site/step-original-production-site-schema';
import { StepNewProductionSiteLabel } from './step-new-production-site/step-new-production-site-label';
import { StepNewProductionSiteContent } from './step-new-production-site/step-new-production-site-content';
import { useStepNewProductionSiteSchema } from './step-new-production-site/step-new-production-site-schema';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepFinishActions } from './step-finish/step-finish-actions';

function fetchProductionSite(id: string) {
  return abortableFetch(`${EvidenceAPI.PRODUCTION_SITES}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export function useProductionSiteSplit() {
  const { stateAction } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const fetch = useRef<AbortableFetch | null>(null);
  const [initialValues, setInitialValues] = useState<ProcessForm>();
  const [isLoading, setIsLoading] = useState(
    stateAction?.action === ProductionSiteSplitStateAction.INIT_MAIN_PS
  );

  const loadProductionSite = useEventCallback(async () => {
    try {
      setIsLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = fetchProductionSite(stateAction?.data);
      const productionSite: ProductionSite = await fetch.current.json();

      unstable_batchedUpdates(() => {
        setIsLoading(false);

        setInitialValues({
          originalProductionSite: productionSite,
        });
      });
    } catch (e) {
      setIsLoading(false);

      if (e.name !== 'AbortError') {
        showSnackbar(...Messages.ProductionSite.PRODUCTION_SITE_PRELOAD.ERROR);
        throw e;
      }

      return undefined;
    }
  });

  useEffect(() => {
    if (stateAction?.action === ProductionSiteSplitStateAction.INIT_MAIN_PS) {
      loadProductionSite();
    }
  }, []);

  const steps = useMemo(
    () => [
      {
        Label: StepOriginalProductionSiteLabel,
        Content: StepOriginalProductionSiteContent,
        Actions: StepOriginalProductionSiteActions,
        validationSchema: stepOriginalProductionSiteSchema,
        initialValues,
      },

      {
        Label: StepNewProductionSiteLabel,
        Content: StepNewProductionSiteContent,
        validationSchema: useStepNewProductionSiteSchema,
      },

      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    [initialValues]
  );

  return {
    steps,
    isLoading,
  };
}

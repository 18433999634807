import { DictionaryCompetentControlAuthorityAutocomplete } from '../../../models';
import { useAutocompleteSource, Params } from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';

export function useCompetentControlAuthorities(params?: Params) {
  return useAutocompleteSource<DictionaryCompetentControlAuthorityAutocomplete>(
    {
      url:
        EvidenceAPI.DICTIONARY_COMPETENT_CONTROL_AUTHORITIES + '/autocomplete',
      params,
    }
  );
}

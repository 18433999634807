import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { WaterUsagePlace } from '../../models';
import { useTypes, useBasinEnterprises } from './water-usage-places-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { addressValueMapperFactory } from '../../composite/subject-column-mappers/address-mapper';
import {
  useMunicipalities,
  useCadastralTerritories,
} from '../../components/form/address-field/address-field-api';

export function useColumns(): TableColumn<WaterUsagePlace>[] {
  return [
    {
      datakey: 'type',
      filterkey: 'type.id',
      sortkey: 'type.name',
      name: 'Typ',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'vhbNumber',
      name: 'Číslo VHB',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'ppId.enterprise',
      filterkey: 'ppId.enterprise.id',
      sortkey: 'ppId.enterprise.name',
      name: 'Podnik povodí',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useBasinEnterprises
      ),
      valueMapper: TableCells.useSelectCellFactory(useBasinEnterprises),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'ispopId',
      name: 'ID v systému ISPOP',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'operator.name',
      name: 'Provozovatel - Název',
      width: 300,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'operator.identifier',
      name: 'Provozovatel - IČO / UID',
      width: 300,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'operator.identityDocumentsConcatenated',
      name: 'Provozovatel - OP / pas',
      width: 300,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'municipality',
      filterkey: 'municipality.id',
      sortkey: 'municipality.name',
      name: 'Obec',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useMunicipalities
      ),
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(''),
    },
    {
      datakey: 'cadastralTerritory',
      filterkey: 'cadastralTerritory.id',
      sortkey: 'cadastralTerritory.name',
      name: 'Katastrální území',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useCadastralTerritories
      ),
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(''),
    },
    {
      datakey: 'obligatorySubject.ico',
      name: 'Povinný subjekt - IČO',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'obligatorySubject.companyName',
      name: 'Povinný subjekt - Název firmy',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'jtskCoordinates.x',
      name: 'S-JTSK X',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      filterOperation: ApiFilterOperation.AND,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'jtskCoordinates.y',
      name: 'S-JTSK Y',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      filterOperation: ApiFilterOperation.AND,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'wgs84Coordinates.lat',
      name: 'Zeměpisná šířka',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.NumberCell),
      sortable: true,
      filterable: false,
      filterGroup: 4,
    },
    {
      datakey: 'wgs84Coordinates.lon',
      name: 'Zeměpisná délka',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.NumberCell),
      sortable: true,
      filterable: false,
      filterGroup: 4,
    },
    {
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import * as Yup from 'yup';
import { BasinEnterpriseIdentifier } from '../../models/water-usage-place';
import { WaterManagementDecision } from '../../models/water-management-decision';

export function useValidationSchema() {
  return Yup.object<WaterManagementDecision>().shape({
    ppId: Yup.object<BasinEnterpriseIdentifier>().shape({
      id: Yup.string().nullable().required('Povinné pole'),
      enterprise: Yup.string().nullable().required('Povinné pole'),
    }),
  });
}

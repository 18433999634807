import {
  DictionaryAutocomplete,
  useListSource,
  ResultDto,
  ApiFilterOperation,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import { post } from '../../utils/custom-crud';
import { Subject } from '../../models';

export function useRoleRequestStates() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.ROLE_REQUESTS}/state/list`,
    method: 'GET',
  });
}

export function useRoleRequestTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.ROLE_REQUESTS}/type/list`,
    method: 'GET',
  });
}

export async function combineGetCalls({ signal, mainItem }: CombineCallsType) {
  const [administrators] = await post<ResultDto<Subject>>({
    url: `${EvidenceAPI.USERS}/${mainItem.requester.id}/role/instance/relationship/list`,
    signal,
    body: {
      filters: [
        {
          field: 'role.relationship.id',
          operation: ApiFilterOperation.EQ,
          value: 'ADMINISTRATOR',
        },
      ],
      size: 0,
    },
  });

  const [users] = await post<ResultDto<Subject>>({
    url: `${EvidenceAPI.USERS}/${mainItem.requester.id}/role/instance/relationship/list`,
    signal,
    body: {
      filters: [
        {
          field: 'role.relationship.id',
          operation: ApiFilterOperation.EQ,
          value: 'USER',
        },
      ],
      size: 0,
    },
  });

  const json = {
    ...mainItem,
    asAdministratorCount: administrators?.count ?? 0,
    asUserCount: users?.count ?? 0,
  };

  const text = JSON.stringify(json);

  return {
    data: json,
    text,
  };
}

export enum PermissionOutage {
  /** Výpis odstávek */
  OUTAGE_LIST = 'OUTAGE_LIST',

  /** Vytvoření odstávky */
  OUTAGE_CREATE = 'OUTAGE_CREATE',

  /** Úprava odstávky */
  OUTAGE_UPDATE = 'OUTAGE_UPDATE',

  /** Smazání odstávky */
  OUTAGE_DELETE = 'OUTAGE_DELETE',
}

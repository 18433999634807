import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';

export function useDeleteAttachmentDialog() {
  const callApi = useEventCallback((id: string, attachmentId: string) =>
    abortableFetch(
      `${EvidenceAPI.DIRP_RECORDS}/${id}/attachments/${attachmentId}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'DELETE',
      }
    )
  );

  return {
    callApi,
  };
}

import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useSlate } from 'slate-react';
// utils
import { isBlockActive } from '../utils/is-block-active';
import { toggleBlock } from '../utils/toggle-block';
import { useStyles } from './button-styles';
// types
import { AllFormats } from '../rich-editor-types';

export function BlockButton({
  format,
  icon,
}: {
  format: AllFormats;
  icon: ReactNode;
}) {
  const classes = useStyles();
  const editor = useSlate();

  const active = isBlockActive(editor, format);

  return (
    <button
      onMouseDown={(event) => {
        event.preventDefault();

        toggleBlock(editor, format);
      }}
      className={clsx(classes.button, { [classes.active]: active })}
    >
      {icon}
    </button>
  );
}

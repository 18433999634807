import React from 'react';
import { NaturalPersonDocuments } from './natural-person-documents';
import { FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../../../components/help/help-label';

export function NaturalPersonBasicFields({
  prefix,
  codePrefix = 'SUBJECT',
  disabled,
  disabledDocuments,
  hideUid = false,
}: {
  prefix?: string;
  codePrefix?: string;
  disabled?: boolean;
  disabledDocuments?: boolean;
  /**
   * UID is hidden in registration, because UID is generated AFTER approved registration
   */
  hideUid?: boolean;
}) {
  const names = {
    artificialId: prefix ? `${prefix}.artificialId` : 'artificialId',
    firstName: prefix ? `${prefix}.firstName.value` : 'firstName.value',
    lastName: prefix ? `${prefix}.lastName.value` : 'lastName.value',
  };

  return (
    <>
      {!hideUid && (
        <FormTextField
          name={names.artificialId}
          label={<HelpLabel label="UID" code={`${codePrefix}_UID`} />} //Formát: ID_xxxxxx
          disabled
        />
      )}
      <FormTextField
        name={names.firstName}
        label={
          <HelpLabel label="Jméno" code={`${codePrefix}_FIRSTNAME`} required />
        } // Povinné pole
        disabled={disabled}
      />
      <FormTextField
        name={names.lastName}
        label={
          <HelpLabel
            label="Příjmení"
            code={`${codePrefix}_LAST_NAME`}
            required
          />
        } // Povinné pole
        disabled={disabled}
      />
      <NaturalPersonDocuments
        disabled={disabledDocuments ? disabledDocuments : disabled}
        prefix={prefix}
      />
    </>
  );
}

import React from 'react';
import { FormPanel, FormAutocomplete } from '@eas/common-web';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { SubjectAutocomplete } from '../../../models';
import { useSubjects } from '../../subject/subjects-api';
import { ProcessForm } from '../irz-facility-split-types';
import { useStepSubject } from './step-subject-hook';
import { HelpLabel } from '../../../components/help/help-label';

export function StepSubjectContent({ allValues }: { allValues: ProcessForm }) {
  useStepSubject(allValues);

  const subjectSource = useSubjects();

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete<SubjectAutocomplete>
        name="operator"
        label={
          <HelpLabel label="Provozovatel" code="IRZ_FACILITY_SPLIT_OPERATOR" />
        }
        source={subjectSource}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

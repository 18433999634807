import React from 'react';
import { ProcessForm } from '../irz-facility-request-operator-change-types';
import {
  FormPanel,
  FormCustomField,
  TextField,
  Checkbox,
  TextArea,
  DateField,
} from '@eas/common-web';
import { SubjectAutocomplete } from '../../../models/subject';
import { noop } from 'lodash';
import { useAddressLabel } from '../../../components/form/address-field/hooks/address-label-hook';

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const addressLabel = useAddressLabel(allValues.facility?.address);
  let originalSubjectLabel = allValues?.subjectIdentifier;

  if (allValues?.subject && 'ico' in allValues.subject) {
    originalSubjectLabel = `${originalSubjectLabel} - ${allValues.subject.name}`;
  }

  return (
    <>
      <FormPanel label="Provozovna IRZ">
        <FormCustomField label="IČP">
          <TextField value={allValues.facility?.facilityId} disabled={true} />
        </FormCustomField>
        <FormCustomField label="Identifikátor provozovatele">
          <TextField value={originalSubjectLabel} disabled={true} />
        </FormCustomField>
        <FormCustomField label="Adresa">
          <TextArea value={addressLabel} disabled={true} />
        </FormCustomField>
      </FormPanel>
      <SubjectAutocompleteFormPanel
        subject={allValues?.newOperator}
        label="Nový provozovatel"
      />
      <FormPanel label="Ostatní">
        {allValues.keepOriginalName && (
          <FormCustomField label="Ponechat původní název provozovny">
            <Checkbox
              value={allValues.keepOriginalName}
              onChange={noop}
              disabled={true}
            />
          </FormCustomField>
        )}
        {!allValues.keepOriginalName && (
          <FormCustomField label="Nový název provozovny">
            <TextField
              value={allValues.newName}
              onChange={noop}
              disabled={true}
            />
          </FormCustomField>
        )}
        <FormCustomField label="Poznámka">
          <TextArea value={allValues.note} disabled={true} />
        </FormCustomField>
        <FormCustomField label="Datum změny provozovatele">
          <DateField onChange={noop} value={allValues.date} disabled={true} />
        </FormCustomField>
      </FormPanel>
    </>
  );
}

function SubjectAutocompleteFormPanel({
  subject,
  label,
}: {
  subject?: SubjectAutocomplete;
  label: string;
}) {
  return (
    <FormPanel label={label}>
      <FormCustomField label="Název">
        <TextField value={subject?.label} disabled={true} />
      </FormCustomField>
    </FormPanel>
  );
}

import React, { useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import {
  FormFieldWrapper,
  FormTextField,
  useFormSelector,
  FormContext,
  useEventCallback,
  DetailContext,
  DetailMode,
  TextField,
} from '@eas/common-web';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';
import { Address } from '../../../../models';
import { HelpLabel } from '../../../help/help-label';

export function OrientationNumberCell() {
  const { code, name, disabled, isRuian } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);
  const { setFieldValue } = useContext(FormContext);
  const detailCtx = useContext(DetailContext);

  const {
    ruianCitySelected,
    addressPlaceRuian,
    orientationNumberWithMark,
  } = useFormSelector((data: { [index: string]: Address | undefined }) => ({
    ruianCitySelected: get(data, name)?.municipalityRuian?.id,
    addressPlaceRuian: get(data, name)?.addressPlaceRuian,
    orientationNumberWithMark: get(data, name)?.orientationNumberWithMark,
  }));

  const isDisabled = disabled || (isRuian && !ruianCitySelected);

  const notifyChange = useEventCallback(() => {
    if (addressPlaceRuian?.id) {
      unstable_batchedUpdates(() => {
        setFieldValue(`${name}.addressPlaceRuian`, undefined);
        setFieldValue(`${name}.orientationNumberMark`, undefined);
        setFieldValue(`${name}.zip`, undefined);
      });
    }
  });

  return (
    <FormFieldWrapper
      disabled={isDisabled}
      required={false}
      label={
        <HelpLabel
          label={defaults?.orientationNumber?.label ?? 'Číslo orientační'}
          required={defaults?.orientationNumber?.required ?? false}
          code={`${code}_ORIENTATION_NUMBER`}
        />
      }
      labelOptions={{}}
      layoutOptions={{}}
      errorOptions={{}}
    >
      <Grid item xs={3}>
        {!isDisabled && (!detailCtx || detailCtx.mode !== DetailMode.VIEW) ? (
          <FormTextField
            disabled={isDisabled}
            notifyChange={notifyChange}
            name="orientationNumber"
            labelOptions={{ hide: true }}
            layoutOptions={{ noUnderline: true, noSpacing: true }}
          />
        ) : (
          <TextField disabled value={orientationNumberWithMark} />
        )}
      </Grid>
    </FormFieldWrapper>
  );
}

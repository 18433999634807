import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  DetailToolbarButtonType,
  DetailContext,
  DetailHandle,
  DetailMode,
  DetailActionbarButton,
} from '@eas/common-web';

import { useDeactivateDialog } from './dialog-actions/deactivate-hook';
import { useContext } from 'react';
import { Empowerment } from '../../models';
import { useActivateDialog } from './dialog-actions/activate-hook';

export function BoilerPermissionActionbar() {
  const { mode } = useContext<DetailHandle<Empowerment>>(DetailContext);

  const { handleDeactivate, showDeactivateButton } = useDeactivateDialog();
  const { handleActivate, showActivateButton } = useActivateDialog();

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          {showDeactivateButton && (
            <DetailActionbarButton
              startIcon={<CloseIcon fontSize="small" />}
              label="Zneplatnit"
              onClick={handleDeactivate}
              type={DetailToolbarButtonType.SECONDARY}
            />
          )}
          {showActivateButton && (
            <DetailActionbarButton
              startIcon={<RefreshIcon />}
              label="Obnovit platnost"
              onClick={handleActivate}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
        </div>
      )}
    </>
  );
}

import React, { useContext } from 'react';
import {
  FormTextField,
  FormPanel,
  ApiFilterOperation,
  UserContext,
} from '@eas/common-web';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { FormPreFilledAutocomplete } from '../../../components/form/pre-filled-autocomplete/pre-filled-autocomplete';
import { HelpLabel } from '../../../components/help/help-label';
import { useSubjects } from '../../subject/subjects-api';
import { SubjectAutocomplete } from '../../../models';
import { Permission } from '../../../enums';

export function StepBasicInfoContent() {
  const { hasPermission } = useContext(UserContext);

  const subjectSource = useSubjects(
    hasPermission(Permission.OvzFacility.OVZ_CREATE_ALL)
      ? undefined
      : {
          filters: [
            {
              operation: ApiFilterOperation.CUSTOM,
              customJsonModel: JSON.stringify({
                operation: 'HAS_SUBJECT_PERMISSION',
                permissions: [Permission.OvzFacility.OVZ_CREATE_OWN],
                includeEmpowered: true,
              }),
            },
          ],
        }
  );

  return (
    <FormPanel label="Vyplňte">
      <FormPreFilledAutocomplete<SubjectAutocomplete>
        name="operator"
        label={
          <HelpLabel
            label="Provozovatel"
            code="OVZ_FACILITY_REQUEST_NEW_OPERATOR"
            required
          />
        } // Povinné pole
        source={subjectSource}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
      />
      <FormTextField
        name="name"
        label={
          <HelpLabel
            label="Název provozovny"
            code="OVZ_FACILITY_REQUEST_NEW_NAME"
            required
          />
        } // Povinné pole
      />
    </FormPanel>
  );
}

export enum PermissionMuvWaterTreatment {
  /** Přehled způsobů úpravy vody */
  MUV_WATER_TREATMENT_METHOD_LIST = 'MUV_WATER_TREATMENT_METHOD_LIST',

  /** Autocomplete výběr způsobů úpravy vody */
  MUV_WATER_TREATMENT_METHOD_AUTOCOMPLETE = 'MUV_WATER_TREATMENT_METHOD_AUTOCOMPLETE',

  /** Detail způsobu úpravy vody */
  MUV_WATER_TREATMENT_METHOD_DETAIL = 'MUV_WATER_TREATMENT_METHOD_DETAIL',

  /** Vytvoření nového způsobu úpravy vody */
  MUV_WATER_TREATMENT_METHOD_CREATE = 'MUV_WATER_TREATMENT_METHOD_CREATE',

  /** Editace způsobu úpravy vody */
  MUV_WATER_TREATMENT_METHOD_UPDATE = 'MUV_WATER_TREATMENT_METHOD_UPDATE',

  /** Mazání způsobu úpravy vody */
  MUV_WATER_TREATMENT_METHOD_DELETE = 'MUV_WATER_TREATMENT_METHOD_DELETE',

  /** Zneplatnění způsobu úpravy vody */
  MUV_WATER_TREATMENT_METHOD_INVALIDATE = 'MUV_WATER_TREATMENT_METHOD_INVALIDATE',

  /** Obnovení způsobu úpravy vody */
  MUV_WATER_TREATMENT_METHOD_REVALIDATE = 'MUV_WATER_TREATMENT_METHOD_REVALIDATE',
}

import React from 'react';
import { Municipality } from '../../models';
import { PageKey, EvidenceAPI, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import DictionaryRuianMunicipalityFields from './dict-ruian-municipality-fields';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';

export function DictionaryRuianMunicipality() {
  const evidence = useRuianEvidence<Municipality>({
    identifier: PageKey.DICTIONARY_RUIAN_MUNICIPALITY,
    apiProps: {
      url: EvidenceAPI.ADDRESS_MUNICIPALITY,
    },
    tableProps: {
      tableName: 'RUIAN - Obce',
      reportTag: ExportTags.DICTIONARY_MUNICIPALITY,
    },
    detailProps: {
      entityName: EntityName.RUIAN_MUNICIPALITY,
      FieldsComponent: DictionaryRuianMunicipalityFields,
      toolbarProps: {
        title: 'RUIAN - Obec',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import {
  TableFieldColumn,
  InlineTableFieldCells,
  DictionaryAutocomplete,
} from '@eas/common-web';
import { useMemo } from 'react';
import { useIrzActivities } from '../../dict-irz-activity/dict-irz-activities-api';
import { useEPrtrActivities } from '../../dict-e-prtr-activity/dict-e-prtr-activities-api';
import { ActivityRecord } from '../../../models';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';

export function useSecondaryEPrtrActivityColumns(): TableFieldColumn<
  ActivityRecord
>[] {
  const CountCell = InlineTableFieldCells.useInlineNumberFieldFactory<
    ActivityRecord
  >({
    collectionDatakey: 'requestFacilityData.secondaryEprtrActivities',
  });

  const ActivityCell = InlineTableFieldCells.useInlineAutocompleteCellFactory<
    ActivityRecord,
    DictionaryAutocomplete
  >({
    dataHook: useEPrtrActivities,
    collectionDatakey: 'requestFacilityData.secondaryEprtrActivities',
    labelMapper: dictionaryLabelMapper,
  });

  return useMemo(
    () => [
      {
        name: 'Vedlejší E-PRTR činnost',
        datakey: 'activity',
        width: 400,
        CellComponent: ActivityCell,
      },
      {
        name: 'Počet zařízení',
        datakey: 'facilityCount',
        width: 200,
        CellComponent: CountCell,
      },
    ],
    [CountCell, ActivityCell]
  );
}

export function useSecondaryIrzActivityColumns(): TableFieldColumn<
  ActivityRecord
>[] {
  const CountCell = InlineTableFieldCells.useInlineNumberFieldFactory<
    ActivityRecord
  >({
    collectionDatakey: 'requestFacilityData.secondaryIrzActivities',
  });

  const ActivityCell = InlineTableFieldCells.useInlineAutocompleteCellFactory<
    ActivityRecord,
    DictionaryAutocomplete
  >({
    dataHook: useIrzActivities,
    collectionDatakey: 'requestFacilityData.secondaryIrzActivities',
    labelMapper: dictionaryLabelMapper,
  });

  return useMemo(
    () => [
      {
        name: 'Vedlejší činnost dle zákona o IRZ a ISPOP',
        datakey: 'activity',
        width: 400,
        CellComponent: ActivityCell,
      },
      {
        name: 'Počet zařízení',
        datakey: 'facilityCount',
        width: 200,
        CellComponent: CountCell,
      },
    ],
    [CountCell, ActivityCell]
  );
}

export function useIppcPidColumns(): TableFieldColumn<string>[] {
  const IppcPidCell = InlineTableFieldCells.useInlineTextFieldFactory<string>({
    collectionDatakey: 'requestFacilityData.integratedPermission.ippcPids',
    isSubkey: false,
  });

  return useMemo(
    () => [
      {
        name: 'PID (IPPC)',
        datakey: '',
        width: 600,
        CellComponent: IppcPidCell,
      },
    ],
    [IppcPidCell]
  );
}

export function useTradesInEmissionsColumns(): TableFieldColumn<string>[] {
  const IdentifierCell = InlineTableFieldCells.useInlineTextFieldFactory<
    string
  >({
    collectionDatakey: 'requestFacilityData.emissionsTradingSystem.ids',
    isSubkey: false,
  });
  return useMemo(
    () => [
      {
        name: 'Identifikátor',
        datakey: 'ids',
        width: 400,
        CellComponent: IdentifierCell,
      },
    ],
    [IdentifierCell]
  );
}

import React from 'react';
import { FormPanel, FormTextField, FormDateField } from '@eas/common-web';
import { useStepBasicInfoHook } from './step-basic-info-hook';
import { ProcessForm } from '../irz-facility-split-types';
import { HelpLabel } from '../../../components/help/help-label';

export function StepBasicInfoContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  useStepBasicInfoHook(allValues);
  return (
    <FormPanel label="Vyplňte">
      <FormTextField
        name="basicInfo.name"
        label={
          <HelpLabel
            label="Název provozovny"
            code="IRZ_FACILITY_SPLIT_BASIC_INFO_NAME"
            required
          />
        } // Povinné pole
      />
      <FormTextField
        name="basicInfo.webSite"
        label={
          <HelpLabel
            label="Web stránka"
            code="IRZ_FACILITY_SPLIT_BASIC_INFO_WEB_SITE"
            required
          />
        } // Povinné pole, musí být platný odkaz url
      />
      <FormTextField
        name="basicInfo.publicEmail"
        label={
          <HelpLabel
            label="Veřejný e-mail"
            code="IRZ_FACILITY_SPLIT_BASIC_INFO_PUBLIC_EMAIL"
          />
        }
      />
      <FormTextField
        name="basicInfo.publicPhoneNumber"
        label={
          <HelpLabel
            label="Veřejný telefon"
            code="IRZ_FACILITY_SPLIT_BASIC_INFO_PUBLIC_PHONE_NUMBER"
          />
        }
      />
      <FormDateField
        name="basicInfo.commissioningDate"
        label={
          <HelpLabel
            label="Datum uvedení do provozu"
            code="IRZ_FACILITY_SPLIT_BASIC_INFO_COMMISSIONING_DATE"
          />
        }
      />
    </FormPanel>
  );
}

import * as Yup from 'yup';
import { NotificationTemplate, NotificationType } from '../../models';

export function useValidationSchema() {
  return Yup.object<NotificationTemplate>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    subject: Yup.string().nullable().required('Povinné pole'),
    content: Yup.string().nullable().required('Povinné pole'),
    event: Yup.string().nullable().required('Povinné pole'),
    type: Yup.string<NotificationType>().nullable().required('Povinné pole'),
  });
}

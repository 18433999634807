import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryNaceCz } from '../../models';
import { useValidationSchema } from './dict-nace-cz-items-schema';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';

export function DictionaryNaceCzItems() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryNaceCz>({
    identifier: PageKey.DICTIONARY_CZ_NACES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_CZ_NACES,
    },
    tableProps: {
      tableName: 'CZ-NACE',
      reportTag: ExportTags.DICTIONARY_CZ_NACES,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.CZ_NACE,
      codePrefix: 'CZ_NACE',
      validationSchema,
      toolbarProps: {
        title: 'CZ-NACE',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import React from 'react';
import {
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export default function DictionaryRuianDistrictFields() {
  const prefix = 'RUIAN_DISTRICT';

  return (
    <>
      <FormPanel label="Okres">
        <FormTextField
          name="nutsLauCode"
          label={
            <HelpLabel label="Kód NUTS/LAU" code={`DICT_${prefix}_CODE`} />
          }
        />
      </FormPanel>
      <FormPanel label="Kraj">
        <FormTextField
          name="region.name"
          label={
            <HelpLabel label="Název" code={`DICT_${prefix}_REGION_NAME`} />
          }
        />
        <FormTextField
          name="region.isuiCode"
          label={
            <HelpLabel label="Kód v ISÚI" code={`DICT_${prefix}_REGION_CODE`} />
          }
        />
        <FormCheckbox
          name="region.active"
          label={
            <HelpLabel label="Aktivní" code={`DICT_${prefix}_REGION_ACTIVE`} />
          }
        />
        <FormDateTimeField
          name="region.created"
          label={
            <HelpLabel
              label="Vytvoření"
              code={`DICT_${prefix}_REGION_CREATED`}
            />
          }
        />
      </FormPanel>
    </>
  );
}

import { StepOriginalProductionSiteForm } from './step-original-production-site/step-original-production-site-types';
import { StepNewProductionSiteForm } from './step-new-production-site/step-new-production-site-types';
import { StepSummaryForm } from './step-summary/step-summary-types';

export type ProcessForm = StepOriginalProductionSiteForm &
  StepNewProductionSiteForm &
  StepSummaryForm;

export enum ProductionSiteSplitStateAction {
  INIT_MAIN_PS = 'INIT_MAIN_PS',
}

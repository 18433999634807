import {
  identityCardRegex,
  identityCardMessage,
} from './../../subject/fields/subjects-utils';
import * as Yup from 'yup';
import 'yup-phone';

import { StepSubjectForm } from './step-subject-types';
import { SubjectRegistrationType, DocumentType } from '../../../models';
import { icoRegexp, icoRules } from '../../subject/fields/subjects-utils';
import { addressFieldSchema } from '../../../components/form/address-field/address-field-schema';

const residenceRequiredTypes = [
  SubjectRegistrationType.LEGAL_ENTITY_WITHOUT_ICO_REGISTRATION,
  SubjectRegistrationType.FOREIGN_LEGAL_ENTITY_REGISTRATION,
  SubjectRegistrationType.FOREIGN_NATURAL_PERSON_REGISTRATION,
];

export const stepSubjectSchema = (values: any) => {
  const residenceRequired = residenceRequiredTypes.includes(values?.type);

  return Yup.object<StepSubjectForm>().shape({
    identityDocumentType: Yup.mixed().test('', '', function (field) {
      const { createError } = this;

      if (
        values?.type === SubjectRegistrationType.NATURAL_PERSON_REGISTRATION &&
        !field
      ) {
        return createError({
          path: 'identityDocumentType',
          message: 'Povinné pole',
        });
      }

      return true;
    }),

    idNumber: Yup.mixed().test('', '', function (field) {
      const { createError, parent } = this;

      if (
        values?.type === SubjectRegistrationType.NATURAL_PERSON_REGISTRATION &&
        !field?.value
      ) {
        return createError({
          path: 'idNumber.value',
          message: 'Povinné pole',
        });
      }

      if (
        parent?.identityDocumentType === DocumentType.IDENTITY_CARD &&
        field?.value &&
        !identityCardRegex.test(field.value)
      ) {
        return createError({
          path: 'idNumber.value',
          message: identityCardMessage,
        });
      }

      return true;
    }),
    ico: Yup.mixed().test('', '', function (field) {
      const { createError } = this;

      if (
        values?.type ===
        SubjectRegistrationType.BUSINESS_NATURAL_PERSON_REGISTRATION
      ) {
        if (!field?.value) {
          return createError({
            path: 'ico.value',
            message: 'Povinné pole',
          });
        }

        const isInvalid = !field?.value || !icoRegexp.test(field?.value);

        if (isInvalid) {
          return createError({
            path: 'ico.value',
            message: `Nesplňuje pravidla: ${icoRules}`,
          });
        }
      }

      if (values?.type === SubjectRegistrationType.LEGAL_ENTITY_REGISTRATION) {
        if (!field) {
          return createError({
            path: 'ico',
            message: 'Povinné pole',
          });
        }

        const isInvalid = !field || !icoRegexp.test(field);

        if (isInvalid) {
          return createError({
            path: 'ico',
            message: `Nesplňuje pravidla: ${icoRules}`,
          });
        }
      }

      return true;
    }),
    name: Yup.mixed().test('', '', function (field) {
      const { createError } = this;

      if (
        (values?.type ===
          SubjectRegistrationType.FOREIGN_LEGAL_ENTITY_REGISTRATION ||
          values?.type ===
            SubjectRegistrationType.LEGAL_ENTITY_WITHOUT_ICO_REGISTRATION) &&
        !field
      ) {
        return createError({
          path: 'name',
          message: 'Povinné pole',
        });
      }

      return true;
    }),
    firstName: Yup.mixed().test('', '', function (field) {
      const { createError } = this;

      if (
        values?.type ===
          SubjectRegistrationType.FOREIGN_NATURAL_PERSON_REGISTRATION &&
        !field?.value
      ) {
        return createError({
          path: 'firstName.value',
          message: 'Povinné pole',
        });
      }

      return true;
    }),
    lastName: Yup.mixed().test('', '', function (field) {
      const { createError } = this;

      if (
        values?.type ===
          SubjectRegistrationType.FOREIGN_NATURAL_PERSON_REGISTRATION &&
        !field?.value
      ) {
        return createError({
          path: 'lastName.value',
          message: 'Povinné pole',
        });
      }

      return true;
    }),
    residence: residenceRequired ? addressFieldSchema() : Yup.mixed(),
    identityDocumentCopy: Yup.mixed().test('', '', function (field) {
      const { createError, path } = this;

      if (
        values?.type ===
          SubjectRegistrationType.FOREIGN_NATURAL_PERSON_REGISTRATION &&
        !field
      ) {
        return createError({
          path,
          message: 'Povinné pole',
        });
      }

      return true;
    }),
    foreignId: Yup.string().test('', '', function (field) {
      const { createError, path } = this;

      if (
        values?.type ===
          SubjectRegistrationType.FOREIGN_LEGAL_ENTITY_REGISTRATION &&
        !field
      ) {
        return createError({
          path,
          message: 'Povinné pole',
        });
      }

      return true;
    }),
    registerExtract: Yup.mixed().test('', '', function (field) {
      const { createError, path } = this;

      if (
        values?.type ===
          SubjectRegistrationType.FOREIGN_LEGAL_ENTITY_REGISTRATION &&
        !field
      ) {
        return createError({
          path,
          message: 'Povinné pole',
        });
      }

      return true;
    }),
  });
};

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Subject request messages
 */
const SYNC: MessageType<'SUCCESS' | 'NOT_FOUND' | 'FAILED' | 'ERROR'> = {
  SUCCESS: ['Registrace byla úspěšně synchronizována a schválena.', SnackbarVariant.SUCCESS],
  NOT_FOUND: ['Subjekt nebyl nalezen v Základních registrech.', SnackbarVariant.ERROR],
  FAILED: ['Při pokusu o synchronizaci a schválení registrace subjektu došlo k chybě.', SnackbarVariant.ERROR],
  ERROR: ['Chyba synchronizace.', SnackbarVariant.ERROR],
};

const APPROVE: MessageType<'SUCCESS' | 'ERROR' | 'NOT_UNIQUE'> = {
  SUCCESS: ['Registrace subjektu byla úspěšně schválena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při schvalování.', SnackbarVariant.ERROR],
  NOT_UNIQUE: ['Subjekt se zadaným identifikátorem je již registrován, má však jiný typ.', SnackbarVariant.ERROR],
};

const BULK_AUTHORIZE: MessageType<'SUCCESS' | 'ERROR' | 'ALREADY_AUTHORIZED' | 'NOT_FOUND' | 'ALREADY_REGISTERED' | 'SUBJECT_APPROVED_DATE_BEFORE_CREATED_DATE'> = {
  SUCCESS: ['Dokumenty vložené k hromadné autorizaci byly odeslány. Výsledek akce můžete vidět v přehledu.', SnackbarVariant.WARNING],
  ERROR: ['Chyba při hromadné autorizaci.', SnackbarVariant.ERROR],
  ALREADY_AUTHORIZED: ['Chyba: Žádost již byla autorizována.', SnackbarVariant.ERROR],
  NOT_FOUND: ['Chyba: Registrace nebyla nalezena.', SnackbarVariant.ERROR],
  ALREADY_REGISTERED: ['Chyba: Dokument se zadaným evidenčním číslem již byl vložen k autorizaci.', SnackbarVariant.ERROR],
  SUBJECT_APPROVED_DATE_BEFORE_CREATED_DATE: ['Chyba: Datum autorizace nesmí být před datumem vytvoření žádosti.', SnackbarVariant.ERROR]
}

const AUTHORIZE: MessageType<'SUCCESS' | 'ERROR' | 'ALREADY_RESOLVED' | 'MISSING_CRZP_SIGN' | 'DIFFERENT_HASHES' | 'MISSING_USER_SIGN' | 'UNTRUSTED_USER_SIGN' | 'REGISTRATION_FORM_NOT_PROPERLY_SIGNED' | 'ONLY_DATA_BOX_AUTHORIZATION_ALLOWED'> = {
  SUCCESS: ['Registrace subjektu byla úspěšně autorizována.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při autorizaci.', SnackbarVariant.ERROR],
  ALREADY_RESOLVED: ['Chyba autorizace: Žádost již byla autorizována.', SnackbarVariant.ERROR],
  MISSING_CRZP_SIGN: ['Chyba autorizace: Dokument nemá podpis CRŽP - nahraný dokument neodpovídá staženému dokumentu z předchozího kroku. Podepište dokument z předchozího kroku a znovu jej nahrajte.', SnackbarVariant.ERROR],
  DIFFERENT_HASHES: ['Chyba autorizace: CRŽP podpis se neshoduje - dokument byl změněn. Podepište dokument z předchozího kroku a znovu jej nahrajte.', SnackbarVariant.ERROR],
  MISSING_USER_SIGN: ['Chyba autorizace: Chybí podpis uživatele. Podepište dokument z předchozího kroku a znovu jej nahrajte.', SnackbarVariant.ERROR],
  UNTRUSTED_USER_SIGN: ['Chyba autorizace: Elektronický podpis nemá dostatečnou důvěru. Použijte elektronický podpis vydaný certifikační autoritou. Podepište dokument z předchozího kroku a znovu jej nahrajte.', SnackbarVariant.ERROR],
  REGISTRATION_FORM_NOT_PROPERLY_SIGNED: ['Chyba autorizace: Žádost nebyla správně podepsána.', SnackbarVariant.ERROR],
  ONLY_DATA_BOX_AUTHORIZATION_ALLOWED: ['Žádost lze autorizovat pouze pomocí datové schránky.', SnackbarVariant.ERROR]
};

const CONFIRM: MessageType<'SUCCESS' | 'ERROR' | 'ONLY_DATA_BOX_AUTHORIZATION_ALLOWED' | 'ALREADY_SUBJECT_ADMINISTRATOR'> = {
  SUCCESS: ['Registrace subjektu byla úspěšně autorizována.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při autorizaci.', SnackbarVariant.ERROR],
  ONLY_DATA_BOX_AUTHORIZATION_ALLOWED: ['Žádost lze autorizovat pouze pomocí datové schránky.', SnackbarVariant.ERROR],
  ALREADY_SUBJECT_ADMINISTRATOR: ['Chyba autorizace:  Uživatel má již vztah správce subjektu k danému subjektu.', SnackbarVariant.ERROR],
};

const REJECT: MessageType = {
  SUCCESS: ['Registrace subjektu byla úspěšně zamítnuta.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zamítnutí.', SnackbarVariant.ERROR],
};

const SUBMIT_REQUEST: MessageType<'ERROR' | 'ALREADY_SUBJECT_ADMINISTRATOR' | 'UNRESOLVED_REGISTRATION_PRESENT' | 'NOT_UNIQUE'> = {
  ERROR: ['Chyba při odesílaní registrace subjektu.', SnackbarVariant.ERROR],
  ALREADY_SUBJECT_ADMINISTRATOR: ['Chyba registrace: Subjekt s těmito údaji již máte registrován (najdete jej ve Správě subjektů).', SnackbarVariant.ERROR],
  UNRESOLVED_REGISTRATION_PRESENT: ['Chyba registrace: Subjekt s těmito údaji má již podanou žádost (najdete ji v Žádostech o registraci).', SnackbarVariant.ERROR],
  NOT_UNIQUE: ['Chyba registrace: Subjekt se zadaným identifikátorem je již registrován, má však jiný typ. Upravte typ subjektu (krok 1) nebo typ identifikátoru (krok 2).', SnackbarVariant.ERROR],
}

const LINK_TO: MessageType<'SUCCESS' | 'ERROR' |  'ENTITY_NOT_EXIST' | 'SUBJECT_NOT_ACTIVE' | 'LINKED_SUBJECT_WRONG_TYPE' | 'ALREADY_SUBJECT_ADMINISTRATOR'> = {
  SUCCESS: ['Registrace byla úspěšně propojena se zadaným subjektem.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při propojování registrace.', SnackbarVariant.ERROR],
  ENTITY_NOT_EXIST: ['Chyba při propojování registrace. Subjekt nebyl nalezen.', SnackbarVariant.ERROR],
  SUBJECT_NOT_ACTIVE: ['Zadaný subjekt není aktivní.', SnackbarVariant.SUCCESS],
  LINKED_SUBJECT_WRONG_TYPE: ['Nesprávný typ subjektu.', SnackbarVariant.ERROR],
  ALREADY_SUBJECT_ADMINISTRATOR: ['Zadaný subjekt je již propojen s jinou žádostí o registraci subjektu.', SnackbarVariant.ERROR],
}

const EDIT: MessageType = {
  SUCCESS: ['Registrace subjektu byla úspěšně upravena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při úpravě.', SnackbarVariant.ERROR],
};

const CREATE_WITH_AIFO: MessageType<'ERROR' | 'NO_SUITABLE_EXTERNAL_ID_PRESENT' | 'AGENDA_NOT_SELF_ASSIGNABLE' | 'NO_SUBJECT_IDENTIFIER_PRESENT' | 'UNRESOLVED_REGISTRATION_PRESENT'> = {
  ERROR: ['Chyba při vytváření subjektu.', SnackbarVariant.ERROR],
  NO_SUITABLE_EXTERNAL_ID_PRESENT: ['Nenalezen NIA identifikátor.', SnackbarVariant.ERROR],
  AGENDA_NOT_SELF_ASSIGNABLE: ['Vybranou agendu nelze přiřadit.', SnackbarVariant.ERROR],
  NO_SUBJECT_IDENTIFIER_PRESENT: ['Nebyl nalezen identifikátor pro vytvoření fyzické osoby.', SnackbarVariant.ERROR],
  UNRESOLVED_REGISTRATION_PRESENT: ['Subjekt fyzické již byl registrován.', SnackbarVariant.ERROR],
};

export default { SYNC, APPROVE, AUTHORIZE, BULK_AUTHORIZE, CONFIRM, REJECT, SUBMIT_REQUEST, LINK_TO, EDIT, CREATE_WITH_AIFO };



import { BoilerManufacturerAutocomplete, Subject } from '../../models';
import {
  useAutocompleteSource,
  abortableFetch,
  Params,
  ApiFilterOperation,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useBoilerManufacturers() {
  return useAutocompleteSource<BoilerManufacturerAutocomplete>({
    url: `${EvidenceAPI.BOILER_MANUFACTURERS}/autocomplete`,
    params: {
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'subject.approved',
          value: true,
        },
      ],
    },
  });
}

export function createBoilerManufacturerForSubject(subject: Subject) {
  return abortableFetch(EvidenceAPI.BOILER_MANUFACTURERS, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ subject: subject }),
  });
}

export function deleteBoilerManufacturer(id: string) {
  return abortableFetch(`${EvidenceAPI.BOILER_MANUFACTURERS}/${id}/active`, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
}

export function getBoilerManufacturersForSubject(subject: Subject) {
  return abortableFetch(`${EvidenceAPI.BOILER_MANUFACTURERS}/autocomplete`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      size: 1,
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'subject.id',
          value: subject.id,
        },
      ],
    } as Params),
  });
}

import { useListSource, DictionaryAutocomplete } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useAreaTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.PROTECTED_DEPOSIT_AREAS}/type/list`,
    method: 'GET',
  });
}

export function useChluStates() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.PROTECTED_DEPOSIT_AREAS}/state/list`,
    method: 'GET',
  });
}

export function useChluRuianStates() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.PROTECTED_DEPOSIT_AREAS}/ruian-state/list`,
    method: 'GET',
  });
}

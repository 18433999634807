import React from 'react';
import { FormSelect, FormPanel } from '@eas/common-web';
import { useSubjectRegistrationTypes } from '../subject-requests-api';
import { HelpLabel } from '../../../components/help/help-label';

export function StepTypeContent() {
  const subjectRegistrationTypes = useSubjectRegistrationTypes();

  return (
    <FormPanel label="Vyplňte">
      <FormSelect
        name="type"
        label={
          <HelpLabel
            label="Typ subjektu"
            code="SUBJECT_REGISTRATION_NEW_TYPE"
            required
          /> // Povinné pole
        }
        source={subjectRegistrationTypes}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
      />
    </FormPanel>
  );
}

import React from 'react';
import {
  DetailToolbarButtonType,
  DetailActionbarButton,
} from '@eas/common-web';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { useStartDialog } from '../dialog-hooks/start-hook';
import { useCancelDialog } from '../dialog-hooks/cancel-hook';

export function ScheduleJobsActionbar() {
  const { handleStart, showStartButton } = useStartDialog();
  const { handleCancel, showCancelButton } = useCancelDialog();

  return (
    <>
      {showStartButton && (
        <DetailActionbarButton
          label="Spustit"
          tooltip="Otevře dialog pro potvrzení spustění úlohy"
          onClick={handleStart}
          startIcon={<PlayArrowIcon fontSize="small" />}
          type={DetailToolbarButtonType.PRIMARY}
        />
      )}
      {showCancelButton && (
        <DetailActionbarButton
          label="Zastavit"
          tooltip="Otevře dialog pro potvrzení zastavení úlohy"
          onClick={handleCancel}
          startIcon={<StopIcon fontSize="small" />}
          type={DetailToolbarButtonType.SECONDARY}
        />
      )}
    </>
  );
}

import React, { useContext } from 'react';
import {
  FormSelect,
  FormPanel,
  useFormSelector,
  DetailContext,
  FormDateField,
  FormCheckbox,
  FormContext,
  DetailMode,
} from '@eas/common-web';
import { RoleInstance, RoleInstanceType, UserRoleInstance } from '../../models';
import { useRoleInstanceTypes } from './role-instances-api';
import { HelpLabel } from '../../components/help/help-label';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { RelationshipRoleFields } from './fields/relationship-role-fields';
import { SecurityRoleFields } from './fields/security-role-fields';
import { SystemRoleFields } from './fields/system-role-fields';
import { UserRoleFields } from './fields/user-role-fields';
import { SepnoDetails } from './fields/sepno-details-fields';
import { SubjectFormPanel } from '../../composite/subject-fields/subject-form-panel';

export function RoleInstancesFields(props: NotedEvidenceFieldsProps) {
  //ctx stuff
  const { mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  const { type, subject, role } = useFormSelector((values: RoleInstance) => ({
    type: values.type,
    subject: (values as UserRoleInstance)?.subject,
    role: values.role,
  }));

  // types
  const types = useRoleInstanceTypes();

  return (
    <>
      <FormPanel label="Role">
        <FormSelect
          name="type"
          label={<HelpLabel label="Typ" code="ROLE_INSTANCES_TYPE" required />} // Povinné pole
          source={types}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled={mode === DetailMode.EDIT}
          notifyChange={() => {
            if (mode === DetailMode.NEW) {
              setFieldValue('role', null);
              setFieldValue('user', null);
              setFieldValue('subject', null);
              setFieldValue('system', null);
            }
          }}
        />
        <FormDateField
          name="validFrom"
          label={
            <HelpLabel label="Platnost od" code="ROLE_INSTANCES_VALID_FROM" />
          }
          representation="instant"
        />
        <FormDateField
          name="validTo"
          label={
            <HelpLabel label="Platnost do" code="ROLE_INSTANCES_VALID_TO" />
          }
          representation="instant"
        />
        {mode === DetailMode.VIEW && (
          <FormCheckbox
            name="active"
            label={<HelpLabel label="Aktivní" code="ROLE_INSTANCES_ACTIVE" />}
            disabled
          />
        )}
      </FormPanel>
      {type === RoleInstanceType.RELATIONSHIP_ROLE_INSTANCE && (
        <RelationshipRoleFields />
      )}
      {type === RoleInstanceType.USER_ROLE_INSTANCE && (
        <>
          <SubjectFormPanel
            disabled={mode === DetailMode.EDIT}
            codePrefix="ROLE_INSTANCES"
            label="Informace o subjektu"
            autocomplete={{
              name: 'subject',
              label: 'Subjekt', // Povinné pole
              required: true,
              notifyChange: () => {
                if (mode === DetailMode.NEW) {
                  setFieldValue('role', null);
                  setFieldValue('user', null);
                }
              },
            }}
          />
          {!!subject && <UserRoleFields />}
        </>
      )}
      {type === RoleInstanceType.SECURITY_ROLE_INSTANCE && (
        <SecurityRoleFields />
      )}
      {type === RoleInstanceType.SYSTEM_ROLE_INSTANCE && <SystemRoleFields />}
      {role?.allowSepnoDetails && <SepnoDetails />}
      {props.HistoryRecords}
    </>
  );
}

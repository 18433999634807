import React, { useContext, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  DetailContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
  DetailMode,
  DetailActionButton,
  UserContext,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import { useAddAttachmentDialog } from '../dialog-actions/add-attachment-hook';
import { useDeleteAttachmentDialog } from '../dialog-actions/delete-attachment-hook';

export const useStyles = makeStyles((theme) => ({
  textButton: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableActions: {
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    // height: 30,
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 16px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  buttonGroup: {
    display: 'flex',
    marginBottom: 8,
    // marginLeft: -5, // offset first icon left padding

    '& button:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.grey['400']}`,
    },
  },
}));

export function AttachmentsToolbar({
  selectedIndex,
  setSelectedIndex,
  disabled,
}: TableFieldToolbarProps & { disabled?: boolean }) {
  const classes = useStyles();

  const { source, onPersisted } = useContext(DetailContext);
  const { reload } = useContext(UserContext);

  const {
    callApi: addAttachmentApiCall,
    AttachmentDialog,
  } = useAddAttachmentDialog();

  useEffect(() => {
    setSelectedIndex(undefined);
  }, [source]);

  const { callApi: deleteAttachmentApiCall } = useDeleteAttachmentDialog();

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_ATTACHMENT"
          apiCall={addAttachmentApiCall}
          buttonLabel="Přidat novou přílohu"
          dialogTitle="Příloha"
          dialogText="Vyplňte údaje."
          buttonDisabled={disabled}
          ButtonComponent={({ onClick, label, disabled }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              disabled={disabled ?? false}
              show={true}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">Přidat</Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={AttachmentDialog}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          dialogWidth={600}
          onSuccess={async () => {
            reload();
            onPersisted(source.data!.id);
            await source.refresh();
          }}
        />
        <DetailActionButton
          promptKey="DELETE_ATTACHMENT"
          apiCall={() =>
            deleteAttachmentApiCall(
              source?.data?.id,
              source.data.attachments[selectedIndex!].id
            )
          }
          buttonLabel="Smazat přílohu"
          dialogTitle="Smazání přílohy"
          dialogText="Opravdu si přejete smazat přílohu?"
          ButtonComponent={({ onClick, label, disabled }) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              disabled={disabled ?? false}
              show={true}
              title={label}
              onClick={onClick}
              color="secondary"
              variant="text"
            >
              <Typography variant="body2">Smazat</Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          buttonDisabled={selectedIndex === undefined || disabled}
          onSuccess={async () => {
            reload();
            onPersisted(source.data!.id);
            await source.refresh();
          }}
        />
      </ButtonGroup>
    </div>
  );
}

import { AuthoredObject } from './authored-object';
import { Address } from './address';
import {
  Wgs84Coordinates,
  JtskCoordinates,
  Etrs89Coordinates,
} from './coordinate';
import { SubjectUnion } from './subject';
import { ProductionSite } from './production-site';
import { OvzFacility } from './ovz-facility';
import { DictionaryEPrtrActivity } from './dict-e-prtr-activity';
import { DictionaryIrzActivity } from './dict-irz-activity';
import { DictionaryIppcActivity } from './dict-ippc-activity';
import { DictionaryNace } from './dict-nace';
import { DictionaryNaceCz } from './dict-nace-cz';
import { DictionaryRiverBasinCzDistrict } from './dict-river-basin-cz-district';
import { DictionaryRiverBasinEuDistrict } from './dict-river-basin-eu-district';
import { DictionaryCompetentAuthority } from './dict-competent-authority';
import { DictionaryCompetentControlAuthority } from './dict-competent-control-authority';
import { IrzEmissionsTradingSystem } from './irz-emissions-trading-system';
import { DomainObjectAutocomplete } from './domain-object';
import { IrzFacilityIntegratedPermission } from './irz-facility-integrated-permission';

/**
 * Typ hlavní činnosti
 */
export enum MainActivityType {
  /**
   * Činnost dle přílohy zákona o IRZ a ISPOP
   */
  IRZ = 'IRZ',

  /**
   * Činnost E-PRTR (European Pollutant Release and Transfer Register)
   */
  E_PRTR = 'E_PRTR',
}

/**
 * Činnost
 */
export interface ActivityRecord {
  /**
   * Činnost
   */
  activity?: DictionaryEPrtrActivity | DictionaryIrzActivity;

  /**
   * Počet zařízení
   */
  facilityCount?: number;

  /**
   * Činnost IPPC
   */
  ippcActivity?: DictionaryIppcActivity;
}

/**
 * Stav EU k poslednímu roku, za který bylo podáno hlášení
 */
export interface EuStateRecord {
  /**
   * Stav EU
   */
  euState?: EuState;

  /**
   * Rok
   */
  year?: number;
}

export enum EuState {
  /**
   * Funkční
   */
  OPERATIONAL = 'OPERATIONAL',

  /**
   * Zrušená
   */
  CANCELLED = 'CANCELLED',

  /**
   * Neregistrována
   */
  NOT_REGISTERED = 'NOT_REGISTERED',

  /**
   * Neregulována
   */
  NOT_REGULATED = 'NOT_REGULATED',

  /**
   * Nepoužívána
   */
  NOT_USED = 'NOT_USED',
}

/**
 * Provozovna IRZ (Integrovaný registr znečišťování)
 */
export interface IrzFacility extends AuthoredObject {
  /**
   * Název provozovny
   */
  name?: string;

  /**
   * Příznak platnosti
   */
  active?: boolean;

  /**
   * Adresa provozovny (výběr z RÚIAN)
   */
  address?: Address;

  /**
   * IČP (identifikační číslo provozovny)
   */
  facilityId?: string;

  /**
   * Tvořeno: CZ pro označení státu, MZP jako označení kompetentního orgánu a poslední část se skláda z písmene, které
   * označuje kraj (u adresy provozovny; tvar jako na registračních značkách aut) a třímístné číslo označující okres
   * dle CZ-NUTS bez počátečního CZ0 (např. pro Prahu je plné označení CZ0100), např. {@code CZ.MZP.A100}
   */
  inspireIdNamespace?: string;

  /**
   * Identifikátor systému obchodování s emisemi
   */
  emissionsTradingSystem?: IrzEmissionsTradingSystem;

  /**
   * NACE
   */
  nace?: DictionaryNace;

  /**
   * CZ-NACE
   */
  czNace?: DictionaryNaceCz;

  /**
   * Oblast povodí ČR
   */
  czRiverBasinDistrict?: DictionaryRiverBasinCzDistrict;

  /**
   * Oblast povodí Evropy
   */
  euRiverBasinDistrict?: DictionaryRiverBasinEuDistrict;

  /**
   * Poloha WGS-84
   */
  wgs84Coordinates?: Wgs84Coordinates;

  /**
   * Poloha ETRS-89
   */
  etrs89Coordinates?: Etrs89Coordinates;

  /**
   * Poloha S-JTSK
   */
  jtskCoordinates?: JtskCoordinates;

  /**
   * IČP ovzduší
   */
  ovzFacility?: OvzFacility;

  /**
   * Hlavní E-PRTR činnost
   */
  mainEprtrActivity?: ActivityRecord;

  /**
   * Seznam vedlejších E-PRTR činností
   */
  secondaryEprtrActivities?: ActivityRecord[];

  /**
   * Činnost IPPC
   */
  ippcActivity?: DictionaryIppcActivity;

  /**
   * Integrované povolení provozovny
   */
  integratedPermission?: IrzFacilityIntegratedPermission;

  /**
   * Hlavní činnost dle přílohy zákona o IRZ a ISPOP
   */
  mainIrzActivity?: ActivityRecord;

  /**
   * Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP
   */
  secondaryIrzActivities?: ActivityRecord[];

  /**
   * Celkový počet zařízení
   */
  facilityCount?: number;

  /**
   * Stav EU k poslednímu roku, za který bylo podáno hlášení
   */
  euStateRecord?: EuStateRecord[];

  /**
   * Web stránka
   */
  webSite?: string;

  /**
   * Veřejný e-mail
   */
  publicEmail?: string;

  /**
   * Veřejný telefon
   */
  publicPhoneNumber?: string;

  /**
   * Provozovatel
   */
  operator?: SubjectUnion;

  /**
   * Datum registrace
   */
  registrationDate?: string;

  /**
   * Datum zneplatnění
   */
  invalidationDate?: string;

  /**
   * Poznámky
   */
  note?: string;

  /**
   * Průmyslové místo
   */
  productionSite?: ProductionSite;

  /**
   * Kompetentní orgán
   */
  competentAuthority?: DictionaryCompetentAuthority;

  /**
   * Kompetentní kontrolní a ostatní orgány
   */
  competentControlAuthorities?: DictionaryCompetentControlAuthority[];

  /**
   * Datum uvedení do provozu
   */
  commissioningDate?: string;

  /**
   * Datum platnosti
   */
  validTo?: string;

  /**
   * Platnost
   */
  valid?: boolean;

  /**
   * Souhrnné InspireId (např. {@code CZ.MZP.A100.CZ0001989E.FACILITY})
   */
  inspireId?: string;

  /**
   * Tvořeno hodnotou pole IČP a příponou ".FACILITY", např. {@code CZ0001989E.FACILITY}
   */
  localId?: string;

  /**
   * Automaticky přidělený identifikátor při vzniku provozovny; shodný s LocalId (IČP a příponou ".FACILITY")
   */
  thematicId?: string;

  /**
   * Typy činnosti
   */
  activityTypes?: MainActivityType[];

  label?: string;
}

export type IrzFacilityAutocomplete = DomainObjectAutocomplete;

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { DictionaryBoilerModel } from '../../models';
import { useDictionaryBoilerBrands } from '../dict-boiler-brand/dict-boiler-brands-api';

export function useColumns(): TableColumn<DictionaryBoilerModel>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'brand',
      filterkey: 'brand.id',
      sortkey: 'brand.name',
      displaykey: 'brand.name',
      name: 'Značka',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDictionaryBoilerBrands
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import React from 'react';
import { useAuthorRoles } from '../empowerment-requests-api';
import { StepRoleForm } from './step-role-types';
import { ProcessForm } from '../empowerment-requests-types';

export function StepRoleLabel({
  stepValues,
}: {
  stepValues?: StepRoleForm;
  allValues?: ProcessForm;
}) {
  const authorRoles = useAuthorRoles();

  const roleLabel = authorRoles.items.find(
    (role) => role.id === stepValues?.authorRole
  );

  return (
    <>
      Výběr role: <b>{roleLabel?.name}</b>
    </>
  );
}

import { useRef } from 'react';
import { AbortableFetch, useEventCallback } from '@eas/common-web';
import {
  getRedirectUrl,
  addProviderToUser,
  parseTicket,
} from './identity-provider-api';
import { IdentityProviderUserData } from './identity-provider-types';

export function useIdentityProvider() {
  const addProviderFetch = useRef<AbortableFetch | null>(null);
  const handleAddProviderToUser = useEventCallback(async (token: string) => {
    if (addProviderFetch.current !== null) {
      addProviderFetch.current.abort();
    }
    addProviderFetch.current = addProviderToUser(token);
    await addProviderFetch.current.raw();
  });

  const getDataFromTokenFetch = useRef<AbortableFetch | null>(null);
  const getDataFromToken = useEventCallback(async (token: string) => {
    if (getDataFromTokenFetch.current !== null) {
      getDataFromTokenFetch.current.abort();
    }
    getDataFromTokenFetch.current = parseTicket(token);
    const response = (await getDataFromTokenFetch.current.json()) as IdentityProviderUserData;
    return response;
  });

  return {
    redirectUrl: getRedirectUrl,
    handleAddProviderToUser,
    getDataFromToken,
  };
}

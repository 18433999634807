import React, { useRef, useContext } from 'react';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  NavigationContext,
  RemoteTableFieldContext,
} from '@eas/common-web';
import { User, Subject } from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { Messages, EvidenceAPI, Permission } from '../../../enums';
import { SubjectUserDto } from '../subjects-types';
import { SubjectsDataContext } from '../subjects-context';

/**
 * API call.
 *
 * @param user
 * @param subject
 */
export function callApi(user: User, subject: Subject) {
  return abortableFetch(
    `${EvidenceAPI.USERS}/${user.id}/unassign-from/${subject.id}`,
    {
      method: 'POST',
    }
  );
}

/**
 * Zrušení přiřazení uživatele k subjektu
 */
const PROMPT_KEY = 'UNASSIGN_USER';

export function useUnassignUserDialog({
  selectedIndex,
  setSelectedIndex,
}: {
  selectedIndex: number;
  setSelectedIndex: (index: number | undefined) => void;
}) {
  /**
   * Context stuff.
   */
  const { onPersisted, source, cancelEditing } = useContext<
    DetailHandle<Subject>
  >(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { reload, user, hasPermission } = useContext(UserContext);
  const { testPrompt } = useContext(PromptContext);
  const { navigate } = useContext(NavigationContext);
  const { subjectsCount, setSubjectsCount } = useContext(SubjectsDataContext);

  /**
   * Users table data.
   */
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const users: SubjectUserDto[] = tableSource.items;

  /**
   * Permissinons
   */
  const unassignSelf = users?.[selectedIndex]?.user?.id === user.id;
  const hasAdminAccess = hasPermission(Permission.Subject.SUBJECT_LIST_ALL);
  const isOnlySubject = subjectsCount === 1;

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Odebrání uživatele',
        dialogText: (
          <>
            Skutečně chcete odebrat vazbu uživatele{' '}
            <b>{users?.[selectedIndex]?.user?.username}</b>?
            <br />
            {unassignSelf && !hasAdminAccess && (
              <>
                <br />
                Tímto okamžitě ztratíte přístup k subjektu a editace subjektu
                bude ukončena bez uložení změn. Tato akce je nevratná (pro
                získání přístupu k subjektu je nutné znovu podat žádost).
                {isOnlySubject && (
                  <>
                    <br />
                    <br />
                    Vzhledem k tomu, že subjekt <b>{source.data?.name}</b> je
                    jediný, ke kterému máte přístup, ztratíte také přístup ke
                    správě subjektů a budete přesměrovaní na úvodní stránku.
                  </>
                )}
              </>
            )}
            {!unassignSelf && !hasAdminAccess && (
              <>Uživatel okamžitě ztratí přístup k subjektu.</>
            )}
          </>
        ),
        dialogWidth: 600,
      },
    ],
    [selectedIndex, users]
  );

  const handleUnassignUser = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(users[selectedIndex].user!, source.data!);

          await fetch.current.raw();

          if (unassignSelf && !hasAdminAccess) {
            cancelEditing();
            await reload();

            if (isOnlySubject) {
              navigate('/crzp');
              return undefined;
            }

            onPersisted(null);
            source.setLoading(false);
            setSubjectsCount(subjectsCount - 1);
          } else {
            unstable_batchedUpdates(() => {
              showSnackbar(...Messages.Subject.USER_UNASSIGN.SUCCESS);
              source.setLoading(false);
            });

            setSelectedIndex(undefined);
            onPersisted(source.data!.id);
            await source.refresh();
            await reload();
          }
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Subject.USER_UNASSIGN.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  return {
    handleUnassignUser,
  };
}

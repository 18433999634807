import { makeStyles } from '@material-ui/core/styles';
import React, { forwardRef, Ref, useContext } from 'react';
import {
  TableFieldRowProps,
  TableFieldContext,
  useEventCallback,
  FormCustomField,
  TableFieldCells,
} from '@eas/common-web';
import { Note } from '../../../../models/note';
import { get } from 'lodash';
import Radio from '@material-ui/core/Radio/Radio';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  rowWrapper: {
    cursor: 'pointer',
    background: '#e0e2e3',
    '&:hover': {
      backgroundColor: theme.palette.highlight,
    },
  },
  row: {
    boxSizing: 'border-box',
    minWidth: '100%',
    minHeight: 30,
    padding: '2px 16px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  rowDetails: {
    width: '100%',
    padding: '2px 12px 2px 66px',
    borderBottom: '1px solid #cdcdcd',
  },
  tableRowActions: {
    flexShrink: 0,
    width: 35,
    height: 20,
    verticalAlign: 'top',
    display: 'inline-block',
    padding: 0,
    overflow: 'hidden',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  cellWrapper: {
    flexShrink: 0,
    display: 'flex',
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '11px',
    fontWeight: 600,
    marginRight: 20,
    marginLeft: 80,
  },
  content: {
    display: 'flex',
    whiteSpace: 'break-spaces',
  },
  icon: {
    float: 'left',
    marginTop: -4,
    marginRight: 10,
  },
  radioButton: {
    padding: 0,
  },
}));

export const NotesTableFieldRow = forwardRef(function UsersTableFieldRow(
  { index, value, selected }: TableFieldRowProps<Note>,
  ref: Ref<HTMLDivElement>
) {
  const classes = useStyles();
  const {
    filteredColumns,
    onSelect,
    selectRow,
    showRadioCond,
    visibleActionsColumn,
  } = useContext<TableFieldContext<Note>>(TableFieldContext);

  const handleSelectClick = useEventCallback(() => {
    onSelect?.(value, index);
    selectRow(index);
  });

  return (
    <div ref={ref} onClick={handleSelectClick} className={classes.rowWrapper}>
      <div className={classes.row}>
        {visibleActionsColumn && (
          <div className={classes.tableRowActions}>
            {showRadioCond(value) && (
              <Radio
                className={classes.radioButton}
                checked={selected}
                onChange={handleSelectClick}
                color="primary"
              />
            )}
          </div>
        )}
        {filteredColumns.map((column, i) => {
          const { CellComponent = TableFieldCells.TextCell } = column;
          return (
            <div
              key={i}
              className={classes.cellWrapper}
              style={{
                width: column.width,
              }}
            >
              <CellComponent
                index={index}
                value={get(value, column.datakey, '')}
                rowValue={value}
                column={column as any}
              />
            </div>
          );
        })}
      </div>
      <div className={classes.rowDetails}>
        <FormCustomField
          layoutOptions={{ noUnderline: true }}
          labelOptions={{ hide: true }}
        >
          <Typography variant="body1" className={classes.content}>
            {value?.text}
          </Typography>
        </FormCustomField>
      </div>
    </div>
  );
});

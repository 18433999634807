import {
  useAutocompleteSource,
  DictionaryAutocomplete,
  Params,
  useFetch,
  ListSource,
  ResultDto,
  useListSource,
  ApiFilterOperation,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { AgendaAutocomplete } from '../../models/agenda';
import { useMemo } from 'react';

export function useEmpowerableAgendas() {
  return useAutocompleteSource<AgendaAutocomplete>({
    url: EvidenceAPI.AGENDAS + '/autocomplete',
    params: {
      filters: [
        {
          field: 'empowerable',
          operation: ApiFilterOperation.EQ,
          value: 'true',
        },
      ],
      sort: [
        {
          field: 'name',
          order: 'ASC',
          type: 'FIELD',
        },
      ],
      size: -1,
    },
  });
}

export function useAgendas(params?: Params) {
  return useAutocompleteSource<AgendaAutocomplete>({
    url: EvidenceAPI.AGENDAS + '/autocomplete',
    params,
  });
}

export function useAgendasSource() {
  const [result, loading, reset] = useFetch<ResultDto<AgendaAutocomplete>>(
    EvidenceAPI.AGENDAS + '/autocomplete',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        filters: [
          {
            field: 'empowerable',
            operation: ApiFilterOperation.EQ,
            value: 'true',
          },
        ],
        sort: [
          {
            field: 'name',
            order: 'ASC',
            type: 'FIELD',
          },
        ],
        size: -1,
      }),
    }
  );

  const source: ListSource<AgendaAutocomplete> = useMemo(
    () => ({
      items: result?.items ?? [],
      loading,
      reset,
      loadDetail: async (item) => item,
    }),
    [loading, reset, result]
  );

  return source;
}

export function useAgendaTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.AGENDAS + '/type/list',
    method: 'GET',
  });
}

export function useEmpowermentAgendaBehaviors() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.AGENDAS}/behavior/list`,
    method: 'GET',
  });
}

import React from 'react';
import { FormPanel, FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';

export function WaterUserPlaceWaterAccumulationFields() {
  return (
    <>
      <FormPanel label="Hydrologická data">
        <FormTextField
          label={
            <HelpLabel
              label="Qa - dlouhodobý průměrný průtok"
              code="WATER_USAGE_PLACE_HYDROLOGICAL_DATA_QA"
            />
          }
          name="hydrologicalData.qa"
        />
        <FormTextField
          label={
            <HelpLabel
              label="MQ - minimální průtok pod vodním dílem"
              code="WATER_USAGE_PLACE_HYDROLOGICAL_DATA_MQ"
            />
          }
          name="hydrologicalData.mq"
        />
        <FormTextField
          label={
            <HelpLabel
              label="MZP - minimální zůstatkový průtok (jednotky m3/s)"
              code="WATER_USAGE_PLACE_HYDROLOGICAL_DATA_MZP"
            />
          }
          name="hydrologicalData.mzp"
        />
      </FormPanel>
      <WaterAccumulationAreaDetailFields
        prefix="permanentArea"
        codePrefix="PERMANENT_AREA"
        label="Stálý prostor"
      />
      <WaterAccumulationAreaDetailFields
        prefix="stockArea"
        codePrefix="STOCK_AREA"
        label="Zásobní prostor"
      />
      <WaterAccumulationAreaDetailFields
        prefix="totalArea"
        codePrefix="TOTAL_AREA"
        label="Celkový ovládatelný prostor"
      />
    </>
  );
}

function WaterAccumulationAreaDetailFields({
  prefix,
  label,
  codePrefix,
}: {
  prefix: string;
  label: string;
  codePrefix: string;
}) {
  return (
    <FormPanel label={label}>
      <FormTextField
        label={
          <HelpLabel
            label="Kóta hladiny"
            code={`WATER_USAGE_PLACE_${codePrefix}_WATER_ALTITUDE`}
          />
        }
        name={`${prefix}.waterAltitude`}
      />
      <FormTextField
        label={
          <HelpLabel
            label="Objem"
            code={`WATER_USAGE_PLACE_${codePrefix}_VOLUME`}
          />
        }
        name={`${prefix}.volume`}
      />
      <FormTextField
        label={
          <HelpLabel
            label="Zatopená plocha"
            code={`WATER_USAGE_PLACE_${codePrefix}_FLOODED_AREA`}
          />
        }
        name={`${prefix}.floodedArea`}
      />
    </FormPanel>
  );
}

import React from 'react';
import {
  FormTextArea,
  FormAutocomplete,
  FormPanel,
  FormTextField,
  FormCheckbox,
  AuthoredFields,
  DatedFields,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useDirpLaws } from '../dict-dirp-law/dict-dirp-laws-api';

export function DictionaryDirpLawParagraphsFields() {
  const laws = useDirpLaws();

  return (
    <FormPanel label="Obecné">
      <FormAutocomplete
        name="law"
        label={
          <HelpLabel
            label="Předpis"
            code="DICT_DIRP_LAW_PARAGRAPHS_LAW"
            required
          />
        }
        source={laws}
      />
      <FormTextField
        name="name"
        label={
          <HelpLabel
            label="Číslo paragrafu"
            code="DICT_DIRP_LAW_PARAGRAPHS_NAME"
            required
          />
        }
      />
      <FormTextArea
        name="description"
        label={
          <HelpLabel
            label="Popis"
            code="DICT_DIRP_LAW_PARAGRAPHS_DESCRIPTION"
          />
        }
      />
      <FormCheckbox
        name="active"
        label={
          <HelpLabel label="Aktivní" code="DICT_DIRP_LAW_PARAGRAPHS_ACTIVE" />
        }
        disabled={true}
      />
      <AuthoredFields />
      <DatedFields />
    </FormPanel>
  );
}

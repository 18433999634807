import { useState, useCallback } from 'react';
import { ValidationError } from '@eas/common-web';

export function useFormError() {
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const getFieldError = useCallback(
    (field: string) => {
      if (!errors || !errors.length) {
        return;
      }

      const currentError = errors.find((e) => e.key === field);

      if (currentError) {
        return currentError.value || `Pole "${field}" obsahuje chybu!`;
      }

      return;
    },
    [errors]
  );

  return {
    setErrors,
    getFieldError,
  };
}

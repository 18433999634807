import {
  DictionaryBoilerBrandAutocomplete,
  DictionaryBoilerTypeAutocomplete,
} from '../../models';
import {
  useAutocompleteSource,
  useStaticListSource,
  Params,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useDictionaryBoilerBrands(params?: Params) {
  return useAutocompleteSource<DictionaryBoilerBrandAutocomplete>({
    url: `${EvidenceAPI.DICTIONARY_BOILER_BRANDS}/list`,
    params,
  });
}

/**
 * Returns list of boiler brands from manufacturer.
 */
export function useManufacturerBoilerBrands(
  source: DictionaryBoilerBrandAutocomplete[]
) {
  return useStaticListSource<DictionaryBoilerBrandAutocomplete>(source);
}

/**
 * Returns list of boiler types for brand.
 */
export function useBoilerModelAllowedTypes(
  source: DictionaryBoilerTypeAutocomplete[]
) {
  return useStaticListSource<DictionaryBoilerTypeAutocomplete>(source);
}

import * as Yup from 'yup';
import { StepLocationForm } from './step-location-types';
import {
  addressFieldSchema,
  wgs84CoordinatesSchema,
} from '../../../components/form/address-field/address-field-schema';
import { DictionaryTerritorialTechnicalUnit } from '../../../models';

export const stepLocationSchema = () =>
  Yup.object<StepLocationForm>().shape({
    address: addressFieldSchema(),
    wgs84Coordinates: wgs84CoordinatesSchema(),
    territorialTechnicalUnit: Yup.mixed<DictionaryTerritorialTechnicalUnit>()
      .nullable()
      .required('Povinné pole'),
    cadastralTerritory: Yup.mixed().when('address.addressPlaceRuian', {
      is: (type) => !type,
      then: Yup.mixed().nullable().required('Povinné pole'),
    }),
    landPlotType: Yup.mixed().when('address.addressPlaceRuian', {
      is: (type) => !type,
      then: Yup.mixed().nullable().required('Povinné pole'),
    }),
    primaryLandPlotNumber: Yup.mixed().when('address.addressPlaceRuian', {
      is: (type) => !type,
      then: Yup.mixed().nullable().required('Povinné pole'),
    }),
  });

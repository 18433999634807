import React from 'react';
import { useIdentityProvider } from './identity-provider-hook';
import { IdentityProviderContext } from './identity-provider-context';

export function IdentityProviderProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const ctx = useIdentityProvider();

  return (
    <IdentityProviderContext.Provider value={ctx}>
      {children}
    </IdentityProviderContext.Provider>
  );
}

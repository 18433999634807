import React, { useContext } from 'react';
import {
  FormTextField,
  FormPanel,
  FormSelect,
  useFormSelector,
  DetailContainer,
  UserContext,
  DetailContext,
  DetailMode,
  useEventCallback,
  FormContext,
  FormCheckbox,
  FormDateTimeField,
  FormTextArea,
} from '@eas/common-web';
import { useSubjectTypes } from './subjects-api';
import { SubjectUnion, SubjectType, Me, PermissionsState } from '../../models';
import Grid from '@material-ui/core/Grid';
import { AddressField } from '../../components/form/address-field/address-field';
import { SubjectsAdvancedFields } from './fields/subjects-advanced-fields';
import { SubjectsBasicFields } from './fields/subjects-basic-fields';
import { Permission } from '../../enums';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { FormPanelText } from '../../components/form/form-panel-summary/form-panel-text';
import { useAddressLabel } from '../../components/form/address-field/hooks/address-label-hook';
import { useExpandableFormPanel } from '../../composite/form-panel/expandable-form-panel-hook';
import { LegalEntityBasicFields } from './fields/legal-entity-fields/legal-entity-basic-fields';
import { LegalEntityWithoutIcoBasicFields } from './fields/legal-entity-without-ico-fields/legal-entity-without-ico-basic-fields';
import { NaturalPersonBasicFields } from './fields/natural-person-fields/natural-person-basic-fields';
import { BusinessNaturalPersonBasicFields } from './fields/business-natural-person-fields/business-natural-person-basic-fields';
import { ForeignLegalEntityBasicFields } from './fields/foreign-legal-entity-fields/foreign-legal-entity-basic-fields';
import { ForeignNaturalPersonBasicFields } from './fields/foreign-natural-person-fields/foreign-natural-person-basic-fields';
import { LegalEntityAdvancedFields } from './fields/legal-entity-fields/legal-entity-advanced-fields';
import { LegalEntityWithoutIcoAdvancedFields } from './fields/legal-entity-without-ico-fields/legal-entity-without-ico-advanced-fields';
import { NaturalPersonAdvancedFields } from './fields/natural-person-fields/natural-person-advanced-fields';
import { BusinessNaturalPersonAdvancedFields } from './fields/business-natural-person-fields/business-natural-person-advanced-fields';
import { ForeignLegalEntityAdvancedFields } from './fields/foreign-legal-entity-fields/foreign-legal-entity-advanced-fields';
import { ForeignNaturalPersonAdvancedFields } from './fields/foreign-natural-person-fields/foreign-natural-person-advanced-fields';
import { PhoneField } from '../../components/form/phone-field/phone-field';
import { HelpLabel } from '../../components/help/help-label';
import { IsdsIds } from '../../composite/subject-fields/subject-isds';

export function SubjectsFields(props: NotedEvidenceFieldsProps) {
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );
  const subjectTypes = useSubjectTypes();
  const { ref: addressPanelRef } = useExpandableFormPanel();
  const { ref: extendedInfoPanelRef } = useExpandableFormPanel();

  const { mode, source } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  const isSuperAdmin = hasPermission(Permission.Subject.SUBJECT_UPDATE_ALL, {
    searchInSubjectPerms: true,
  });

  const canSeeHistory =
    hasPermission(Permission.Subject.SUBJECT_DETAIL_OWN, {
      subjectId: source?.data?.id,
      searchOnlyInSubjectPerms: true,
    }) ||
    hasPermission(Permission.Subject.SUBJECT_DETAIL_ALL, {
      searchInSubjectPerms: true,
    });

  const isEditing = mode === DetailMode.EDIT;
  const isCreating = mode === DetailMode.NEW;

  const { type, allowIszrSync, residence } = useFormSelector(
    (data: SubjectUnion) => ({
      type: data.type,
      allowIszrSync: data.allowIszrSync,
      residence: data?.residence,
    })
  );

  const addressLabel = useAddressLabel(residence);

  const isLegalEntity = type === SubjectType.LEGAL_ENTITY;
  const isLegalEntityWithoutIco = type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO;
  const isNaturalPerson = type === SubjectType.NATURAL_PERSON;
  const isBusinessNaturalPerson = type === SubjectType.BUSINESS_NATURAL_PERSON;
  const isForeignLegalEntity = type === SubjectType.FOREIGN_LEGAL_ENTITY;
  const isForeignNaturalPerson = type === SubjectType.FOREIGN_NATURAL_PERSON;

  const showSyncButton =
    isLegalEntity || isNaturalPerson || isBusinessNaturalPerson;

  const lockedByPerms = !isSuperAdmin || (!isCreating && !isEditing);
  const lockedBySync = lockedByPerms || allowIszrSync;
  const lockedSic = !hasPermission(Permission.Subject.SUBJECT_UPDATE_SIC);

  const notifySelectTypeChanged = useEventCallback(() => {
    if (residence?.country) {
      if (
        residence.country.ruian &&
        (isForeignLegalEntity || isForeignNaturalPerson)
      ) {
        setFieldValue('residence', undefined);
      }
      if (
        !residence.country.ruian &&
        (isLegalEntity ||
          isLegalEntityWithoutIco ||
          isNaturalPerson ||
          isBusinessNaturalPerson)
      ) {
        setFieldValue('residence', undefined);
      }
    }
  });

  return (
    <DetailContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormPanel label="Základní informace">
            <FormSelect
              name="type"
              label={<HelpLabel label="Typ" code="SUBJECT_TYPE" required />} // Povinné pole
              source={subjectTypes}
              tooltipMapper={(o) => o.name}
              valueIsId={true}
              notifyChange={notifySelectTypeChanged}
              disabled={lockedBySync}
            />

            {/* Pravnicka osoba / basic fields */}
            {isLegalEntity && (
              <LegalEntityBasicFields
                disabled={lockedBySync}
                disabledIco={isEditing}
              />
            )}

            {isLegalEntityWithoutIco && (
              <LegalEntityWithoutIcoBasicFields disabled={lockedBySync} />
            )}

            {isForeignLegalEntity && (
              <ForeignLegalEntityBasicFields disabled={lockedBySync} />
            )}

            {/* Fyzicka osoba / basic fields */}
            {isNaturalPerson && (
              <NaturalPersonBasicFields
                disabled={lockedBySync}
                disabledDocuments={false}
              />
            )}

            {isBusinessNaturalPerson && (
              <BusinessNaturalPersonBasicFields
                disabled={lockedBySync}
                disabledIco={isEditing}
              />
            )}

            {isForeignNaturalPerson && (
              <ForeignNaturalPersonBasicFields disabled={lockedBySync} />
            )}

            <FormDateTimeField
              name="inCrzpSince"
              label={
                <HelpLabel
                  label="Datum registrace"
                  code="SUBJECT_IN_CRZP_SINCE"
                />
              }
              disabled={true}
            />
            {showSyncButton && (
              <>
                <FormCheckbox
                  name="allowIszrSync"
                  label={
                    <HelpLabel
                      label="Automatická synchronizace se ZR"
                      code="SUBJECT_ALLOW_ISZR_SYNC"
                    />
                  }
                  disabled={true}
                />
                <FormDateTimeField
                  name="lastIszrSync"
                  label={
                    <HelpLabel
                      label="Datum poslední synchronizace"
                      code="SUBJECT_LAST_ISZR_SYNC"
                    />
                  }
                  disabled={true}
                />
              </>
            )}
            <FormTextArea
              name="note"
              label={<HelpLabel label="Poznámky" code="SUBJECT_NOTE" />}
            />
          </FormPanel>
          <FormPanel
            label="Rozšířené informace"
            expandable={true}
            defaultExpanded={false}
            ref={extendedInfoPanelRef}
          >
            {isForeignLegalEntity && (
              <FormTextField
                name="foreignId"
                label={
                  <HelpLabel
                    label="Zahraniční identifikátor (obdoba IČO)"
                    code="SUBJECT_FOREIGN_ID"
                  />
                }
                disabled={mode !== DetailMode.NEW}
              />
            )}
            <PhoneField
              name="phoneNumber"
              label={
                <HelpLabel
                  label="Telefonní číslo (předvolba, číslo)"
                  code="SUBJECT_PHONENUMBER"
                />
              }
            />
            <FormTextField
              name="email.value"
              label={<HelpLabel label="E-mail" code="SUBJECT_EMAIL" />}
            />
            <FormTextField
              name="webSite"
              label={
                <HelpLabel label="Webová stránka" code="SUBJECT_WEBSITE" />
              }
            />
            <IsdsIds disabled={lockedBySync} />
            <FormTextField
              name="sic"
              disabled={lockedSic}
              label={<HelpLabel label="SIČ" code="SUBJECT_SIC" />}
            />
            {/* Pravnicka osoba / advanced fields */}
            {isLegalEntity && (
              <LegalEntityAdvancedFields disabled={lockedBySync} />
            )}
            {isLegalEntityWithoutIco && (
              <LegalEntityWithoutIcoAdvancedFields disabled={lockedBySync} />
            )}
            {isForeignLegalEntity && (
              <ForeignLegalEntityAdvancedFields disabled={lockedBySync} />
            )}
            {/* Fyzicka osoba / advanced fields */}
            {isNaturalPerson && (
              <NaturalPersonAdvancedFields disabled={lockedBySync} />
            )}
            {isBusinessNaturalPerson && (
              <BusinessNaturalPersonAdvancedFields disabled={lockedBySync} />
            )}
            {isForeignNaturalPerson && (
              <ForeignNaturalPersonAdvancedFields disabled={lockedBySync} />
            )}
          </FormPanel>

          <FormPanel
            label="Adresa"
            expandable
            defaultExpanded={false}
            ref={addressPanelRef}
            summary={<FormPanelText value={addressLabel} />}
          >
            <AddressField
              name="residence"
              disabled={lockedByPerms}
              disableForeignCountry={
                isLegalEntity ||
                isLegalEntityWithoutIco ||
                isNaturalPerson ||
                isBusinessNaturalPerson
              }
              disableRuian={isForeignLegalEntity || isForeignNaturalPerson}
              showUnstructured={isSuperAdmin}
            />
          </FormPanel>

          <SubjectsBasicFields />

          <SubjectsAdvancedFields />

          {props.Notes}

          {canSeeHistory && props.HistoryRecords}

          <FormPanel label="Obecné" expandable defaultExpanded={false}>
            <DatedFields />
            <FormTextField
              name="author.username"
              label={<HelpLabel label="Autor" code="SUBJECT_AUTHOR" />}
              disabled={true}
            />
            <FormTextField
              name="updatedBy.name"
              label={
                <HelpLabel label="Autor úpravy" code="SUBJECT_UPDATED_BY" />
              }
              disabled={true}
            />
          </FormPanel>
        </Grid>
      </Grid>
    </DetailContainer>
  );
}

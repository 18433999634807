import {
  useAutocompleteSource,
  abortableFetch,
  Filter,
  UserContext,
  DetailContext,
  DetailHandle,
  useListSource,
  ApiFilterOperation,
  ResultDto,
} from '@eas/common-web';
import { User, Subject, UserAutocomplete } from '../../models';
import { EvidenceAPI, Permission } from '../../enums';
import { useContext } from 'react';
import { get, post } from '../../utils/custom-crud';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import { UserLastLogin } from './users-types';

export function useUsers() {
  return useAutocompleteSource<UserAutocomplete>({
    url: `${EvidenceAPI.USERS}/autocomplete`,
  });
}

export function useUserSource(filters: Filter[] = []) {
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext<DetailHandle<Subject>>(DetailContext);

  let body: any;

  if (
    hasPermission(Permission.User.USER_ASSIGN_OWN, {
      subjectId: source.data?.id,
    })
  ) {
    body = {
      url: `${EvidenceAPI.SUBJECTS}/${source.data?.id}/user/list`,
      method: 'GET',
    };
  }

  if (hasPermission(Permission.User.USER_ASSIGN_ALL)) {
    body = {
      url: `${EvidenceAPI.USERS}/list`,
      params: {
        sort: [{ field: 'created', order: 'ASC', type: 'FIELD' }],
        filters: filters,
        size: 30,
      },
    };
  }

  const userSource = useListSource<User>(body);

  return userSource;
}

export function passwordReset(user: User) {
  return abortableFetch(`${EvidenceAPI.USERS}/${user.id}/password/reset`, {
    method: 'POST',
  });
}

export async function combineGetCalls({
  api,
  itemId,
  signal,
  mainItem,
}: CombineCallsType) {
  const [administrators] = await post<ResultDto<Subject>>({
    url: `${api}/${itemId}/role/instance/relationship/list`,
    signal,
    body: {
      filters: [
        {
          field: 'role.relationship.id',
          operation: ApiFilterOperation.EQ,
          value: 'ADMINISTRATOR',
        },
      ],
      size: 0,
    },
  });

  const [users] = await post<ResultDto<Subject>>({
    url: `${api}/${itemId}/role/instance/relationship/list`,
    signal,
    body: {
      filters: [
        {
          field: 'role.relationship.id',
          operation: ApiFilterOperation.EQ,
          value: 'USER',
        },
      ],
      size: 0,
    },
  });

  const [roles] = await post<ResultDto<Subject>>({
    url: `${api}/${itemId}/role/instance/list`,
    signal,
    body: {
      size: 0,
    },
  });

  const [empoweredRoles] = await post<ResultDto<Subject>>({
    url: `${api}/${itemId}/role/empowered/instance/list`,
    signal,
    body: {
      size: 0,
    },
  });

  const [lastLogin, lastLoginResponse] = await get<UserLastLogin>({
    url: `${api}/${itemId}/last-login`,
    signal,
  });

  const lastLoginTimestamp = lastLoginResponse?.ok
    ? lastLogin?.timestamp
    : undefined;

  const json = {
    ...mainItem,
    asAdministratorCount: administrators?.count ?? 0,
    asUserCount: users?.count ?? 0,
    rolesCount: roles?.count ?? 0,
    empoweredRolesCount: empoweredRoles?.count ?? 0,
    lastLoginTimestamp,
  };

  const text = JSON.stringify(json);

  return {
    data: json,
    text,
  };
}

import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflowY: 'auto',
      paddingBottom: 100,
    },
  })
);

export function ProtectedPage({ children }: { children: React.ReactNode }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

import React from 'react';
import {
  FormTextArea,
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
  TableField,
  EmptyComponent,
  useFormSelector,
  FormTableField,
  FormFieldContext,
  FormDateField,
} from '@eas/common-web';
import { RoleInstance, RoleInstanceType, RoleType, Role } from '../../models';
import { useRoleInstancesColumns } from '../user/users-columns';
import { noop } from 'lodash';
import { System } from '../../models/system';
import { useRoleColumns, useCertificateColumns } from './systems-columns';
import { SystemCertificatesToolbar } from './system-certificates/system-certificates-toolbar';
import {
  FormPanelCountByName,
  FormPanelCount,
} from '../../components/form/form-panel-summary/form-panel-count';
import { SystemCertificate } from '../../models/system-certificate';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { HelpLabel } from '../../components/help/help-label';

export function SystemsFields(props: NotedEvidenceFieldsProps) {
  const { roleInstances, ownRoles, certificates } = useFormSelector(
    (data: System) => ({
      roleInstances: data.roleInstances,
      ownRoles: data.ownRoles,
      certificates: data.certificates,
    })
  );

  // Přiřazené systémové role
  const systemRoleInstances = roleInstances?.filter(
    (role) => role.type === RoleInstanceType.SYSTEM_ROLE_INSTANCE
  );

  // Bezpečnostní role
  const securityRoleInstances = roleInstances?.filter(
    (role) => role.type === RoleInstanceType.SECURITY_ROLE_INSTANCE
  );

  // Vlastní bezpečnostní role
  const ownSecurityRoles = ownRoles?.filter(
    (ownRole) => ownRole.type === RoleType.SECURITY_ROLE
  );

  // Vlastní subjektové role
  const ownSubjectRoles = ownRoles?.filter(
    (ownRole) => ownRole.type === RoleType.SUBJECT_ROLE
  );

  // Vlastní uživatelské role
  const ownUserRoles = ownRoles?.filter(
    (ownRole) => ownRole.type === RoleType.USER_ROLE
  );

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="name"
          label={<HelpLabel label="Název" code="SYSTEM_NAME" required />} // Povinné pole
        />
        <FormTextArea
          name="code"
          label={<HelpLabel label="Kód" code="SYSTEM_CODE" required />} // Povinné pole
        />
        <FormTextField
          name="isvsId"
          label={<HelpLabel label="Identifikátor ISVS" code="SYSTEM_ISVS_ID" />}
        />
        <FormTextField
          name="isvsName"
          label={<HelpLabel label="Název ISVS" code="SYSTEM_ISVS_NAME" />}
        />
        <FormTextField
          name="ovmAdministrator"
          label={
            <HelpLabel
              label="OVM správce ISVS"
              code="SYSTEM_OVM_ADMINISTRATOR"
            />
          }
        />
        <FormCheckbox
          name="active"
          label={<HelpLabel label="Aktivní" code="SYSTEM_ACTIVE" />}
          disabled
        />
        <FormDateTimeField
          name="validFrom"
          label={<HelpLabel label="Platný od" code="SYSTEM_VALID_FROM" />}
        />
        <FormDateTimeField
          name="validTo"
          label={<HelpLabel label="Platný do" code="SYSTEM_VALID_TO" />}
        />
        <FormDateField
          name="earliestCertificateStartDate"
          label={
            <HelpLabel
              label="Platnost certifikátu od"
              code="CERTIFICATE_START_DATE"
            />
          }
          disabled={true}
        />
        <FormDateField
          name="latestCertificateEndDate"
          label={
            <HelpLabel
              label="Platnost certifikátu do"
              code="CERTIFICATE_END_DATE"
            />
          }
          disabled={true}
        />
      </FormPanel>
      <FormPanel label="Doplňující informace">
        <FormTextArea
          name="description"
          label={<HelpLabel label="Popis" code="SYSTEM_DESCRIPTION" />}
        />
        <FormTextField
          name="location"
          label={<HelpLabel label="Umístění" code="SYSTEM_LOCATION" />}
        />
        <FormTextField
          name="supportedAgenda"
          label={
            <HelpLabel
              label="Podporovaná agenda"
              code="SYSTEM_SUPPORTED_AGENDA"
            />
          }
        />
        <FormTextField
          name="administrator"
          label={
            <HelpLabel
              label="Správce - kontaktní osoba"
              code="SYSTEM_ADMINISTRATOR"
            />
          }
        />
      </FormPanel>
      <FormPanel
        label="Systémové role"
        expandable
        defaultExpanded={!!systemRoleInstances?.length}
        summary={<FormPanelCount count={systemRoleInstances?.length ?? 0} />}
      >
        <TableField<RoleInstance>
          value={systemRoleInstances}
          onChange={noop}
          disabled
          maxRows={15}
          ToolbarComponent={EmptyComponent}
          visibleActionsColumn={false}
          showDetailBtnCond={() => false}
          columns={useRoleInstancesColumns()}
        />
      </FormPanel>
      <FormPanel
        label="Vlastní bezpečnostní role"
        expandable
        defaultExpanded={!!ownSecurityRoles?.length}
        summary={<FormPanelCount count={ownSecurityRoles?.length ?? 0} />}
      >
        <TableField<Role>
          value={ownSecurityRoles}
          onChange={noop}
          disabled
          maxRows={15}
          ToolbarComponent={EmptyComponent}
          visibleActionsColumn={false}
          showDetailBtnCond={() => false}
          columns={useRoleColumns()}
        />
      </FormPanel>
      <FormPanel
        label="Vlastní subjektové role"
        expandable
        defaultExpanded={!!ownSubjectRoles?.length}
        summary={<FormPanelCount count={ownSubjectRoles?.length ?? 0} />}
      >
        <TableField<Role>
          value={ownSubjectRoles}
          onChange={noop}
          disabled
          maxRows={15}
          ToolbarComponent={EmptyComponent}
          visibleActionsColumn={false}
          showDetailBtnCond={() => false}
          columns={useRoleColumns()}
        />
      </FormPanel>
      <FormPanel
        label="Vlastní uživatelské role"
        expandable
        defaultExpanded={!!ownUserRoles?.length}
        summary={<FormPanelCount count={ownUserRoles?.length ?? 0} />}
      >
        <TableField<Role>
          value={ownUserRoles}
          onChange={noop}
          disabled
          maxRows={15}
          ToolbarComponent={EmptyComponent}
          showDetailBtnCond={() => false}
          visibleActionsColumn={false}
          columns={useRoleColumns()}
        />
      </FormPanel>
      <FormPanel
        label="Bezpečnostní role definující Správce uživatelů pro AIS"
        expandable
        defaultExpanded={!!securityRoleInstances?.length}
        summary={<FormPanelCount count={securityRoleInstances?.length ?? 0} />}
      >
        <TableField<RoleInstance>
          value={securityRoleInstances}
          onChange={noop}
          disabled
          maxRows={15}
          ToolbarComponent={EmptyComponent}
          visibleActionsColumn={false}
          showDetailBtnCond={() => false}
          columns={useRoleInstancesColumns()}
        />
      </FormPanel>
      <FormFieldContext.Provider value={{ disabled: false }}>
        <FormPanel
          label="Certifikáty pro komunikaci"
          expandable
          defaultExpanded={!!certificates?.length}
          summary={<FormPanelCountByName name="certificates" />}
        >
          <FormTableField<SystemCertificate>
            name="certificates"
            showDetailBtnCond={() => false}
            maxRows={15}
            labelOptions={{ hide: true }}
            layoutOptions={{
              noSpacing: true,
            }}
            ToolbarComponent={SystemCertificatesToolbar}
            columns={useCertificateColumns()}
          />
        </FormPanel>
      </FormFieldContext.Provider>
      {props.HistoryRecords}
    </>
  );
}

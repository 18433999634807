import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  TableFieldToolbarButton,
  TableFieldToolbarProps,
  useFormSelector,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useAddModelDialog } from './dialog-actions/add-model-hook';
import { useDeactivateModelDialog } from './dialog-actions/deactivate-model-hook';
import { useActivateModelDialog } from './dialog-actions/activate-model-hook';
import { BoilerManufacturer } from '../../models';

export const useStyles = makeStyles((theme) => ({
  textButton: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableActions: {
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    // height: 30,
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 16px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  buttonGroup: {
    display: 'flex',
    marginBottom: 8,
    // marginLeft: -5, // offset first icon left padding

    '& button:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.grey['400']}`,
    },
  },
}));

export function BoilerModelsToolbar({
  selectedIndex,
  setSelectedIndex,
}: TableFieldToolbarProps) {
  const classes = useStyles();

  const { boilerBrands, boilerModels } = useFormSelector(
    (data: BoilerManufacturer) => ({
      boilerBrands: data?.boilerBrands,
      boilerModels: data?.boilerModels,
    })
  );

  const { handleAddModel, canAddModel, canEditModel } = useAddModelDialog({
    boilerModels,
    boilerBrands,
    selectedIndex,
  });

  const {
    handleDeactivateModel,
    canDeactivateModel,
  } = useDeactivateModelDialog({
    boilerModels: boilerModels!,
    selectedIndex: selectedIndex!,
    setSelectedIndex,
  });

  const { handleActivateModel, canActivateModel } = useActivateModelDialog({
    boilerModels: boilerModels!,
    selectedIndex: selectedIndex!,
    setSelectedIndex,
  });

  return (
    <div className={classes.tableActions}>
      <ButtonGroup
        size="small"
        variant="outlined"
        className={classes.buttonGroup}
      >
        <TableFieldToolbarButton
          color="primary"
          variant="contained"
          title="Přidání modelu k výrobci"
          disabled={!canAddModel || selectedIndex !== undefined}
          show={true}
          IconComponent={ControlPointIcon}
          onClick={handleAddModel}
        >
          <Typography variant="body2">Přidat</Typography>
        </TableFieldToolbarButton>
        <TableFieldToolbarButton
          color="default"
          variant="text"
          title="Editace modelu"
          disabled={!canEditModel || selectedIndex === undefined}
          show={true}
          IconComponent={EditIcon}
          onClick={handleAddModel}
        >
          <Typography variant="body2">Upravit</Typography>
        </TableFieldToolbarButton>
        <TableFieldToolbarButton
          color="secondary"
          variant="text"
          IconComponent={CloseIcon}
          disabled={!canDeactivateModel || selectedIndex === undefined}
          show={canDeactivateModel || selectedIndex === undefined}
          title="Zneplatnění modelu"
          onClick={handleDeactivateModel}
        >
          <Typography variant="body2">Zneplatnit</Typography>
        </TableFieldToolbarButton>
        <TableFieldToolbarButton
          color="primary"
          variant="text"
          IconComponent={RefreshIcon}
          disabled={!canActivateModel || selectedIndex === undefined}
          show={canActivateModel}
          title="Obnovení modelu"
          onClick={handleActivateModel}
        >
          <Typography variant="body2">Obnovit</Typography>
        </TableFieldToolbarButton>
      </ButtonGroup>
    </div>
  );
}

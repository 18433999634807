import React from 'react';
import { FormPanel, FormSelect, FormAutocomplete } from '@eas/common-web';
import { useReportDefinitionsAutocomplete, useRoles } from '../reporting-api';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { NotedEvidenceFieldsProps } from '../../../components/evidence/noted-evidence/noted-types';
import { HelpLabel } from '../../../components/help/help-label';

export function ReportAccessRuleFields(props: NotedEvidenceFieldsProps) {
  const attributes = useReportDefinitionsAutocomplete();
  const roles = useRoles();

  return (
    <>
      <FormPanel label="Základní informace">
        <FormSelect
          name="definitionId"
          label={
            <HelpLabel label="Report" code="REPORT_ACCESS_RULE_DEFINITION_ID" />
          }
          disabled
          source={attributes}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        <FormAutocomplete
          name="allowedRoles"
          label={
            <HelpLabel label="Role" code="REPORT_ACCESS_RULE_ALLOWED_RULES" />
          }
          multiple={true}
          source={roles}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

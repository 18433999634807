import React from 'react';
import {
  IdLabelHistoryField as IdLabelHistoryFieldProps,
  HistoryFieldType,
} from '../../../../models';
import { SimpleHistoryField } from './simple-history-field';
import { HistoryFieldProps } from '../history-types';

export function IdLabelHistoryField({
  valueAfter,
  valueBefore,
}: HistoryFieldProps<IdLabelHistoryFieldProps>) {
  return (
    <SimpleHistoryField
      valueAfter={
        valueAfter
          ? { type: HistoryFieldType.STRING, value: valueAfter.label }
          : undefined
      }
      valueBefore={
        valueBefore
          ? { type: HistoryFieldType.STRING, value: valueBefore.label }
          : undefined
      }
    />
  );
}

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { WaterManagementDecisionPart } from '../../models';
import { useTypes } from '../water-usage-place/water-usage-places-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export function useColumns(): TableColumn<WaterManagementDecisionPart>[] {
  return [
    {
      datakey: 'waterUsagePlace.vhbNumber',
      name: 'Číslo VHB',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'waterUsagePlace.name',
      name: 'Název MUV',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'waterUsagePlace.type',
      filterkey: 'waterUsagePlace.type.id',
      sortkey: 'waterUsagePlace.type.name',
      name: 'Typ MUV',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'type.name',
      name: 'Druh rozhodnutí',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'waterUsagePlace.operator.name',
      name: 'Název provozovatele',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'waterUsagePlace.operator.identifier',
      name: 'IČO provozovatele',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'validFrom',
      name: 'Datum platnosti: od',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'validTo',
      name: 'Datum platnosti: do',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'ispopId',
      name: 'ID v systému ISPOP',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import {
  abortableFetch,
  DictionaryAutocomplete,
  useListSource,
} from '@eas/common-web';
import { ProfileForm, PasswordChangeRequest } from './profile-types';
import { EvidenceAPI } from '../../enums';

/**
 * Fetches data for logged user.
 *
 * @param id User ID
 */
export function fetchProfile(id: string) {
  return abortableFetch(`${EvidenceAPI.USERS}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

/**
 * Fetches notification preferences for logged user.
 *
 * @param id User ID
 */
export function fetchNotificationPreferences() {
  return abortableFetch(EvidenceAPI.NOTIFICATION_PREFERENCES, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

/**
 * Fetches notification preferences for logged user.
 *
 * @param id User ID
 */
export function subscribeToEvent(event: string) {
  return abortableFetch(
    `${EvidenceAPI.NOTIFICATION_PREFERENCES}/subscribe/${event}`,
    {
      method: 'PUT',
    }
  );
}

export function usePreferredChannelTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.USERS + '/two-factor/channel/list',
    method: 'GET',
  });
}

/**
 * Calls update API method.
 *
 * @param id User ID
 * @param item Object to save
 */
export function updateProfile(id: string, item: Partial<ProfileForm>) {
  return abortableFetch(`${EvidenceAPI.USERS}/${id}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: JSON.stringify(item),
  });
}

/**
 * Calls update API method.
 *
 * @param item Object to save
 */
export function changePassword(id: string, dto: PasswordChangeRequest) {
  return abortableFetch(`${EvidenceAPI.USERS}/${id}/password`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: JSON.stringify(dto),
  });
}

import React, { useContext } from 'react';
import {
  FormTextField,
  FormPanel,
  FormTextArea,
  FormDateTimeField,
  FormCustomField,
  TextField,
  useFormSelector,
  FormSelect,
  TextArea,
  FormCheckbox,
  FormContext,
} from '@eas/common-web';
import {
  BoilerPermission,
  DictionaryBoilerTypeAutocomplete,
} from '../../models';
import { useBoilerModelAllowedTypes } from '../dict-boiler-brand/dict-boiler-brands-api';
import { SubjectFormPanel } from '../../composite/subject-fields/subject-form-panel';
import { PhoneField } from '../../components/form/phone-field/phone-field';
import { HistoryEvidenceFieldsProps } from '../../components/evidence/history-evidence/history-types';
import { HelpLabel } from '../../components/help/help-label';
import { LocationPanel } from '../../components/form/location-panel/location-panel';

export function BoilerPermissionsFields(props: HistoryEvidenceFieldsProps) {
  const { setFieldValue } = useContext(FormContext);

  const {
    ozoSubjectName,
    boilerBrand,
    allowedTypes,
    address,
    wgs84Coordinates,
  } = useFormSelector((data: BoilerPermission) => ({
    ozoSubjectName: data.ozoSubject?.name,
    boilerBrand: data.boilerBrand?.name,
    allowedTypes: data.boilerBrand?.allowedTypes,
    address: data.address,
    wgs84Coordinates: data.wgs84Coordinates,
  }));

  const boilerTypesSource = useBoilerModelAllowedTypes(
    (allowedTypes || []) as DictionaryBoilerTypeAutocomplete[]
  );

  return (
    <>
      {!ozoSubjectName && (
        <FormPanel label="Subjekt OZO">
          <FormCustomField
            label={
              <HelpLabel
                label="Subjekt"
                code="BOILER_PERMISSION_OZO_SUBJECT_NAME"
              />
            }
          >
            <TextField
              value={
                ozoSubjectName === ''
                  ? 'Probíha registrace subjektu'
                  : ozoSubjectName
              }
              onChange={() => {}}
              disabled={true}
            />
          </FormCustomField>
        </FormPanel>
      )}

      {ozoSubjectName && (
        <SubjectFormPanel
          label="Subjekt OZO"
          codePrefix="BOILER_PERMISSION_OZO_SUBJECT"
          autocomplete={{
            name: 'ozoSubject',
            label: 'Subjekt',
          }}
          disabled
        />
      )}

      <SubjectFormPanel
        label="Subjekt výrobce"
        codePrefix="BOILER_PERMISSION_MANUFACTURER"
        autocomplete={{
          name: 'manufacturerSubject',
          label: 'Subjekt',
        }}
        disabled
      />

      <FormPanel label="Základní informace">
        <FormTextField
          name="number"
          label={
            <HelpLabel
              label="Číslo oprávnění"
              code="BOILER_PERMISSION_NUMBER"
              required
            />
          } // Povinné pole
          disabled
        />

        <FormTextField
          name="ozoIco.value"
          label={<HelpLabel label="IČO OZO" code="BOILER_PERMISSION_OZO_ICO" />}
          disabled
        />
        <FormTextArea
          name="description"
          label={
            <HelpLabel label="Popis" code="BOILER_PERMISSION_DESCRIPTION" />
          }
        />
        <FormDateTimeField
          name="validFrom"
          label={
            <HelpLabel
              label="Platnost od"
              code="BOILER_PERMISSION_VALID_FROM"
              required
            />
          }
          representation="local-date-time"
        />
        <FormDateTimeField
          name="validTo"
          label={
            <HelpLabel
              label="Platnost do"
              code="BOILER_PERMISSION_VALID_TO"
              required
            />
          }
          representation="local-date-time"
          notifyChange={() => setFieldValue('withoutEnd', null)}
        />

        <FormCheckbox
          name="withoutEnd"
          label={
            <HelpLabel label="Na neurčito" code="EMPOWERMENT_WITHOUT_DAY" />
          }
          notifyChange={() => setFieldValue('validTo', undefined)}
        />
      </FormPanel>

      <FormPanel label="Kontaktní údaje">
        <FormTextField
          name="email.value"
          label={<HelpLabel label="E-mail" code="BOILER_PERMISSION_EMAIL" />}
        />

        <PhoneField
          name="phoneNumber"
          label={
            <HelpLabel
              label="Telefonní číslo (předvolba, číslo)"
              code="BOILER_PERMISSION_PHONE_NUMBER"
            />
          }
        />
      </FormPanel>
      <LocationPanel
        addressPanelLabel="Adresa podnikání"
        addressName="address"
        locationName="wgs84Coordinates"
        showCoordinates={address?.country?.code !== 'CZ'}
        showCoordinatesDistance={false}
        address={address}
        locationValue={wgs84Coordinates}
        showLocationPanel={address?.country?.code !== 'CZ'}
        locationToolbarProps={{
          showFillAddressBtn: false,
          showFillMapBtn: false,
          showClearAllBtn: false,
        }}
      />
      <FormPanel label="Informace o kotli">
        <FormCustomField
          label={
            <HelpLabel
              label="Značka kotle"
              code="BOILER_PERMISSION_BOILER_BRAND_NAME"
            />
          }
        >
          <TextField value={boilerBrand} onChange={() => {}} disabled={true} />
        </FormCustomField>
        <FormSelect
          label={
            <HelpLabel
              label="Typy kotle"
              code="BOILER_PERMISSION_BOILER_TYPES"
            />
          }
          name="boilerTypes"
          multiple={true}
          source={boilerTypesSource}
          DisabledComponent={TextArea}
        />
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  DetailToolbarButtonType,
  DetailActionbarButton,
} from '@eas/common-web';
import { useDeleteDialog } from './dialog-actions/delete-hook';

export function RoleInstancesActionbar() {
  const { handleDelete, showBtn, disableBtn } = useDeleteDialog();

  return (
    <>
      {showBtn && (
        <DetailActionbarButton
          startIcon={<CloseIcon fontSize="small" />}
          label="Zneplatnit"
          onClick={handleDelete}
          type={DetailToolbarButtonType.SECONDARY}
          disabled={disableBtn}
        />
      )}
    </>
  );
}

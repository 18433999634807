/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Empowerment messages
 */
const DEACTIVATE: MessageType = {
  SUCCESS: ['Zmocnění bylo úspěšně zneplatněno.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zneplatnění zmocnění.', SnackbarVariant.ERROR],
};

const CREATE: MessageType<'SAME_EMPOWERER_AND_AGENT' | 'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE'> = {
  SAME_EMPOWERER_AND_AGENT: ['Zmocněnec a zmocnitel nesmí být stejný subjekt.', SnackbarVariant.ERROR],
  ERROR: ['Chyba při vytváření zmocnění.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR]
}

export default { DEACTIVATE, CREATE };

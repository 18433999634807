import { PersonalData } from '../../../models';

export const numberValueMapper = function numberValueMapper({
  rowValue,
}: {
  rowValue: PersonalData;
}) {
  if (rowValue.entityReference?.boilerPermission) {
    return rowValue.entityReference?.boilerPermission.number;
  }

  return '-';
};

numberValueMapper.displayName = 'numberValueMapper';

import { useMemo } from 'react';
import { EmptyComponent } from '@eas/common-web';

import { StepTypeLabel } from './step-type/step-type-label';
import { StepTypeContent } from './step-type/step-type-content';
import { stepTypeSchema } from './step-type/step-type-schema';
import { StepTypeActions } from './step-type/step-type-actions';

import { StepSubjectLabel } from './step-subject/step-subject-label';
import { StepSubjectContent } from './step-subject/step-subject-content';
import { stepSubjectSchema } from './step-subject/step-subject-schema';
import { StepSubjectActions } from './step-subject/step-subject-actions';

import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';

import { StepAuthorizationLabel } from './step-authorization/step-authorization-label';
import { StepAuthorizationContent } from './step-authorization/step-authorization-content';

import { StepAgendaLabel } from './step-agenda/step-agenda-label';
import { StepAgendaContent } from './step-agenda/step-agenda-content';
import { StepAgendaActions } from './step-agenda/step-agenda-actions';
import { stepAgendaSchema } from './step-agenda/step-agenda-schema';

export function useSubjectRequests() {
  const steps = useMemo(
    () => [
      {
        Label: StepTypeLabel,
        Content: StepTypeContent,
        validationSchema: stepTypeSchema,
        Actions: StepTypeActions,
      },
      {
        Label: StepSubjectLabel,
        Content: StepSubjectContent,
        validationSchema: stepSubjectSchema,
        Actions: StepSubjectActions,
      },
      {
        Label: StepAgendaLabel,
        Content: StepAgendaContent,
        validationSchema: stepAgendaSchema,
        Actions: StepAgendaActions,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepAuthorizationLabel,
        Content: StepAuthorizationContent,
        Actions: EmptyComponent,
      },
    ],
    []
  );

  return { steps };
}

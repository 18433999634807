import {
  useEventCallback,
  NavigationContext,
  UserContext,
  DetailContext,
  EvidenceStateAction,
} from '@eas/common-web';
import { useContext } from 'react';
import { EvidenceUrl, Permission } from '../../../enums';

export function useRedirect() {
  /**
   * Context stuff.
   */
  const { navigate } = useContext(NavigationContext);
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext(DetailContext);

  const handleRedirectToRequest = useEventCallback(() => {
    navigate(EvidenceUrl.EMPOWERMENT_REQUESTS, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: source.data?.empowermentRequest?.id,
    });
  });

  const redirectToRequestButtonVisible = !!source.data?.empowermentRequest;

  const handleNewRequest = useEventCallback(() => {
    navigate(EvidenceUrl.EMPOWERMENT_REQUESTS_NEW);
  });

  const newRequestButtonVisible =
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_ALL) ||
    hasPermission(Permission.Empowerment.EMPOWERMENT_CREATE) ||
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_OWN);

  const handleShowRequests = useEventCallback(() => {
    navigate(EvidenceUrl.EMPOWERMENT_REQUESTS);
  });

  const showRequestsButtonVisible =
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_LIST_ALL) ||
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_LIST_OWN);

  return {
    handleRedirectToRequest,
    redirectToRequestButtonVisible,
    handleNewRequest,
    newRequestButtonVisible,
    handleShowRequests,
    showRequestsButtonVisible,
  };
}

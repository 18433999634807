import React from 'react';
import * as Yup from 'yup';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { ProcessStep } from './process-step';
import { ProcessActions } from './process-actions';
import { ProcessStepperProps } from './process-types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 15,
    },
    step: {
      fontSize: 30,
      color: '#fff',
    },
  })
);

export function ProcessStepper<FORM>({
  steps,
  activeStep,
  process,
  processValues,
  navigationPrompt,
  handleNext,
  handleBack,
  handleExit,
  handleMoveToStep,
}: ProcessStepperProps<FORM>) {
  const classes = useStyles();

  return (
    <>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={classes.root}
      >
        {process.map((step, index) => {
          const {
            Label = () => <>TODO</>,
            Content = () => <>TODO</>,
            Actions = ProcessActions,
            validationSchema = () => Yup.object().shape({}),
          } = steps[index];

          return (
            <Step key={index}>
              <StepLabel
                classes={{
                  root: classes.step,
                }}
              >
                <Typography variant="h1" className={classes.title}>
                  <Label stepValues={step.values} allValues={processValues} />
                </Typography>
              </StepLabel>
              <StepContent>
                <ProcessStep<FORM>
                  ref={step.stepRef}
                  stepValues={step.values}
                  allValues={processValues}
                  validationSchema={validationSchema(processValues)}
                >
                  {(formikProps) => (
                    <>
                      <Content
                        {...formikProps}
                        allValues={processValues}
                        allSteps={process}
                      />
                      <div className={classes.fullWidth}>
                        <Actions<FORM>
                          activeStep={activeStep}
                          stepsCount={process.length}
                          handleBack={handleBack}
                          handleNext={handleNext}
                          handleExit={handleExit}
                          allValues={processValues}
                          allSteps={process}
                          navigationPrompt={navigationPrompt}
                          handleMoveToStep={handleMoveToStep}
                        />
                      </div>
                    </>
                  )}
                </ProcessStep>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
}

import React from 'react';
import {
  FormPanel,
  FormTextField,
  FilesProvider,
  FormFileField,
  FormDateTimeField,
  FormSelect,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { EvidenceAPI } from '../../enums';
import { useStates } from './ispop-documents-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';

export function IspopDocumentsFields() {
  const states = useStates();

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="referenceNumber"
          label={
            <HelpLabel
              label="Číslo jednací"
              code="ISPOP_DOCUMENT_REFERENCE_NUMBER"
            />
          }
        />
        <FormTextField
          name="registrationNumber"
          label={
            <HelpLabel
              label="Číslo evidenční"
              code="ISPOP_DOCUMENT_REGISTRATION_NUMBER"
            />
          }
        />
        <FormTextField
          name="dataMessageId"
          label={
            <HelpLabel
              label="Id datové zprávy"
              code="ISPOP_DOCUMENT_DATA_MESSAGE_ID"
            />
          }
        />
        <FormTextField
          name="dataBoxId"
          label={
            <HelpLabel
              label="Id datové schránky"
              code="ISPOP_DOCUMENT_DATABOX_ID"
            />
          }
        />
        <FormTextField
          name="isdsSubjectName"
          label={
            <HelpLabel
              label="Název subjektu isds"
              code="ISPOP_DOCUMENT_ISDS_SUBJECT_NAME"
            />
          }
        />
        <FormDateTimeField
          name="incomingDate"
          label={
            <HelpLabel
              label="Datum a čas zprávy"
              code="ISPOP_DOCUMENT_INCOMING_DATE"
            />
          }
        />
        <FormSelect
          name="state"
          label={<HelpLabel label="Stav" code="ISPOP_DOCUMENT_STATE" />}
          source={states}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          valueIsId={true}
        />
        <FormTextField
          name="code"
          label={
            <HelpLabel
              label="Způsob doručení - kód"
              code="ISPOP_DOCUMENT_CODE"
            />
          }
        />
        <FormTextField
          name="identifier"
          label={
            <HelpLabel
              label="Způsob doručení - identifikátor"
              code="ISPOP_DOCUMENT_IDENTIFIER"
            />
          }
        />
        <FormTextField
          name="documentId"
          label={
            <HelpLabel label="Id dokumentu" code="ISPOP_DOCUMENT_DOCUMENT_ID" />
          }
        />
        <FilesProvider url={EvidenceAPI.ARCHIVED_FILES}>
          <FormFileField
            name="file"
            label={<HelpLabel label="Dokument" code="ISPOP_DOCUMENT_FILE" />}
          />
        </FilesProvider>
        <FormTextField
          name="validationErrorMessage"
          label={
            <HelpLabel
              label="Chyba validace"
              code="ISPOP_DOCUMENT_VALIDATION_ERROR_MESSAGE"
            />
          }
        />
      </FormPanel>
    </>
  );
}

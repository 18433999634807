import { TableColumn } from '@eas/common-web';
import { get, isString } from 'lodash';

export function addressValueMapperFactory(postfix = 'Ruian') {
  const valueMapper = function addressValueMapper({
    value,
    rowValue,
    column,
  }: {
    value: string;
    rowValue: any;
    column: TableColumn<any, any>;
  }) {
    let result = '';
    const custom = value;
    const ruian = get(rowValue, `${column.datakey}${postfix}.name`);

    if (isString(custom)) {
      result = custom;
    }
    if (isString(ruian)) {
      result = ruian;
    }

    return result;
  };

  valueMapper.displayName = 'addressValueMapper';
  valueMapper.data = postfix;

  return valueMapper;
}

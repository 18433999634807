export function parseIDPPhoneNumber(phone?: string) {
  if (!phone) {
    return undefined;
  }

  let parsed = phone.replace(/[^+0-9]/, '');

  if (parsed.startsWith('00')) {
    parsed = '+' + parsed.slice(2);
  }

  if (parsed.startsWith('+')) {
    return {
      prefix: parsed.slice(0, 4),
      number: parsed.slice(4),
    };
  }
  return {
    number: parsed,
  };
}

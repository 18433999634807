import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import { useEventCallback } from '@eas/common-web';
import { IdentityProvider } from './../../../enums';

const useStyles = makeStyles((theme) =>
  createStyles({
    flexSpace: {
      flex: '1 1 auto',
    },
    box: {
      marginTop: '10px',
      marginBottom: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      justifyContent: 'space-evenly',

      '&:hover': {
        transform: 'scale(1.02)',
        cursor: 'pointer',
        boxShadow: theme.shadows[3],
      },
    },
    smallBox: {
      height: '80%',
      width: '90%',
      borderRadius: 15,
      marginBottom: -10,
    },
    registrationTypeRadio: {
      marginRight: '12px',
    },
    text: {
      fontWeight: 600,
      fontSize: 20,
    },
    smallText: {
      fontWeight: 600,
      fontSize: 15,
    },
    description: {
      marginTop: 5,
    },
    smallDescription: {
      marginTop: 5,
      fontSize: 12,
    },
    labelsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '20px',
    },
    grid: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

interface PublicBoxProps {
  title: string;
  checked: boolean;
  value: IdentityProvider | 'standard';
  onChange: (value: IdentityProvider | 'standard') => void;
  size?: 'medium' | 'small';
  description?: string;
  After?: any;
}

export function PublicBox({
  title,
  description,
  checked,
  value,
  onChange,
  size = 'medium',
  After,
}: PublicBoxProps) {
  const classes = useStyles();

  const handleSelect = useEventCallback(() => {
    onChange(value);
  });

  return (
    <Grid container>
      <Grid item md={2} xs={1} />
      <Grid item md={8} xs={10} className={classes.grid}>
        <Box
          component={Paper}
          onClick={handleSelect}
          className={clsx(classes.box, {
            [classes.smallBox]: size === 'small',
          })}
        >
          <div className={classes.labelsWrapper}>
            <Typography
              className={clsx(classes.text, {
                [classes.smallText]: size === 'small',
              })}
              variant="body1"
              color="textPrimary"
            >
              {title}
            </Typography>
            {description && (
              <Typography
                className={clsx(classes.description, {
                  [classes.smallDescription]: size === 'small',
                })}
                variant="body1"
                color="textPrimary"
              >
                {description}
              </Typography>
            )}
          </div>
          {After && <After />}
          <span className={classes.flexSpace} />
          <Radio
            className={classes.registrationTypeRadio}
            size="medium"
            color="primary"
            checked={checked}
            onChange={handleSelect}
          />
        </Box>
      </Grid>
      <Grid item md={2} xs={1} />
    </Grid>
  );
}

import { Wgs84Coordinates, JtskCoordinates } from '../../models';
import { EvidenceAPI } from '../../enums';
import React from 'react';

export interface Coords {
  lonD: number;
  latD: number;
  fromWGS84: (lonD: number, latD: number) => Coords;
  fromJTSK: (x: number | string, y: number | string) => Coords;
  fromEvent: (e: MouseEvent, map: SMap) => Coords;
  toWGS84: () => [number, number];
}

export enum LayerEnum {
  'BASE' = 1,
  'TURIST',
  'OPHOTO',
  'HYBRID',
  'HISTORIC',
  'BIKE',
  'TRAIL',
  'OPHOTO0203',
  'OPHOTO0406',
  'OBLIQUE' = 12,
  'SMART_BASE' = 14,
  'SMART_OPHOTO',
  'SMART_TURIST',
  'RELIEF',
  'PANO',
  'TURIST_WINTER',
  'SMART_WINTER',
  'SUMMER',
  'SMART_SUMMER',
  'GEOGRAPHY',
  'OPHOTO1012',
  'HYBRID_SPARSE',
  'OPHOTO1415',
  'BASE_NEW',
  'TURIST_NEW',
}

export enum GeometryEnum {
  POLYLINE,
  POLYGON,
  CIRCLE,
  ELLIPSE,
  PATH,
}

export enum GeometryStyle {
  SOLID,
  DASH,
  DOT,
  DASH_DOT,
}

export enum MouseControlsEnum {
  PAN = 1,
  ZOOM = 2,
}

export enum MapLayerEnum {
  TILE,
  SHADOW,
  GEOMETRY,
  MARKER,
  ACTIVE,
}

export interface Layer {
  enable(): void;
}

export interface Layer {
  enable(): void;
}

export interface MarkerLayer extends Layer {
  // Přidá do vrstvy značku nebo značky
  addMarker(marker: Marker | Marker[], noRedraw?: boolean): void;

  // Odebere značku nebo značky z vrstvy
  removeMarker(marker: Marker | Marker[], noRedraw?: boolean): void;

  // Nastaví slučovač značek
  setClusterer(clusterer: Clusterer): void;

  //Zjistí (směrem nahoru) mapu
  getMap(): SMap;
}

export interface GeometryLayer extends Layer {
  // Přidá geometrický tvar do vrstvy
  addGeometry(geometry: Geometry): void;

  // Odebere geometrický tvar z vrstvy
  removeGeometry(geometry: Geometry): void;
}

export interface Geometry {
  //Smaže vizuální reprezentaci této geometrie
  clear(): void;
}

export interface GeometryOptions {
  color?: string;
  width?: number;
  title?: string;
  minDist?: number;
  opacity?: number;
  outlineColor?: string;
  outlineOpacity?: string;
  outlineWidth?: number;
  outlineStyle?: GeometryStyle;
  style?: GeometryStyle;
}

export interface Clusterer {
  // Vše vyčistit
  clear(): void;
}

export interface Pixel {
  // Převod na řetězec
  toString(): string;

  toCoords(map: SMap, zoom?: number): { x: number; y: number };
}
export interface Signals {
  addListener(
    w: Window,
    name: string,
    callback: (data: { event: MouseEvent }) => void
  ): string;

  removeListener(id: string): void;
}

export interface SMap {
  // Zamkne mapu. Pokud je mapa zamknutá, nedochází k automatickému překreslování vrstev, když je potřeba
  lock(): void;

  // Odemkne mapu; tím dojde k překreslení a aktualizaci všech vrstev (typicky po konci pohybu)
  unlock(): void;

  // Přidá do mapy vrstvu
  addLayer(l: Layer, before?: boolean): Layer;

  // Vyrobí výchozí vrstvu dle zadané konstanty
  addDefaultLayer(id: LayerEnum): Layer;

  // Vyrobí typické ovládácí prvky
  addDefaultControls(): void;

  // Přidání/odebrání listeneru
  getSignals(): Signals;

  // Odebere vrstvu z mapy
  removeLayer(l: Layer): void;

  // Nastaví kurzor mapovému rodiči
  setCursor(cursor: string): void;

  // Vrátí rozměry průhledu
  getSize(): { x: number; y: number };

  // Vrátí zoom
  getZoom(): number;

  //Rozsah povoleného zoomu
  getZoomRange(): [number, number];

  // Nastaví mapě nový střed.
  setCenter(coords: Coords, animate?: boolean): void;

  // Nastaví mapě nový střed a zoom
  setCenterZoom(coords: Coords, zoom: number, animate?: boolean): void;

  //Spočítá střed a zoom pro množinu bodů
  computeCenterZoom(coords: Coords[], usePadding?: boolean): [Coords, number];

  getControls(): Control[];

  removeControl(c: any): void;

  //Přidá do mapy ovládací prvek
  addControl(
    c: Control,
    options?: {
      left?: string;
      right?: string;
      top?: string;
      bottom?: string;
    }
  ): void;

  //Sesynchronizuje mapu s portem
  syncPort(): void;
}

type MarkerDecorationFeature = any;

export interface Marker {
  // Vrátí souřadnice značky
  getCoords(): Coords;

  decorate(feature: MarkerDecorationFeature, options?: any): void;
}

export interface Card {
  // Vrátí záhlaví vizitky
  getHeader(): HTMLElement;

  // Vrátí tělo vizitky
  getBody(): HTMLElement;
}

interface Control {
  getMap(): SMap;
}

export interface LayerControl extends Control {
  addDefaultLayer(layer: LayerEnum): void;
}

export interface MouseControl extends Control {
  /**
   * Nastavení ovládaní ovládaní myší
   * @param controlBitMap Bitová maska určující, co všechno myš/prst ovládá - konstanty MouseControlsEnum
   */
  configure(controlBitMap?: number): void;
}

export interface ZoomControl extends Control {
  /**
   * Odebrání zoom menu
   */
  removeZoomMenu(): void;
}

export interface SuggestProvider {
  abort(): void;
}

export interface SuggestData {
  data: {
    latitude: number;
    longitude: number;
    secondRow: string;
  };
}

export interface Suggest {
  addListener(signal: 'suggest', callback: (data: SuggestData) => void): void;
  removeListener(signal: 'suggest'): void;
  open(): void;
  send(): void;
}

export interface MarkerOptions {
  url?: any;
  title?: string;
  anchor?: {
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;
  };
}

export interface SMapClass {
  // Hlavní konstruktor map.
  new (
    // Prvek, do kterého se mapa vyrobí
    container: HTMLDivElement,

    // Souřadnice středu
    center?: Coords,

    // Zoom
    zoom?: number,

    // Konfigurační objekt
    options?: {
      minZoom?: number;
      maxZoom?: number;
      orientation?: number;
      projection?: number;
      animTime?: number;
      zoomTime?: number;
      rotationTime?: number;
      ophotoDate?: number;
    }
  ): SMap;

  Coords: Coords;

  Layer: {
    Marker: {
      new (): MarkerLayer;
    };

    Geometry: {
      new (): GeometryLayer;
    };
  };

  Marker: {
    new (coords: Coords, id: string | false, options?: MarkerOptions): Marker;

    Feature: {
      Card: MarkerDecorationFeature;
      Draggable: MarkerDecorationFeature;
    };

    Clusterer: {
      new (map: SMap): Clusterer;
    };
  };

  Control: {
    Layer: {
      new (settings?: {
        width: number;
        items: number;
        page: number;
      }): LayerControl;
    };
    Mouse: {
      new (
        mode?: number,
        settings?: {
          panAmount?: number;
          focusedOnly?: boolean;
        }
      ): MouseControl;
    };
    Compass: { new (): Control };
    Sync: { new (): Control };
    Zoom: { new (): ZoomControl };
  };

  SuggestProvider: {
    new (options: {
      url?: string;
      limit?: number;
      updateParams?: (params: any) => void;
    }): SuggestProvider;
  };

  Suggest: {
    new (
      inputEl: Element,
      options: {
        provider?: SuggestProvider;
      }
    ): Suggest;
  };

  Geometry: {
    new (
      geom: GeometryEnum,
      id: string | false,
      coords: any[], // Pole souřadnic, čísel či polí souřadnic (liší se dle jednotlivých druhů geometrií)
      options: GeometryOptions
    ): Geometry;
  };

  Card: {
    new (): Card;
  };

  Pixel: {
    new (x: number, y: number): Pixel;
  };
}

export interface JAKClass {
  mel(elementName: string, options?: any, styles?: any): HTMLElement;
}

export interface MapProps {
  api?: EvidenceAPI;
  width?: number | string;
  height?: number | string;
  mode?: 'POINT_ADD' | 'PREVIEW';
  onPointAdded?: (coords: Wgs84Coordinates) => void;
  children?: React.ReactNode;

  /**
   * If single cooridnate is provided uses it as center,
   * If multiple coordinates array is provided, automatically calculates,
   * zoom and position to contain all of them.
   */
  defaultWgs84Position?: Wgs84Coordinates | Wgs84Coordinates[];
  defaultJtskPosition?: JtskCoordinates | JtskCoordinates[];
  coordinatesFormat?: 'WGS84' | 'JTSK';
  defaultZoom?: number;
  handleMapRedraw?: (
    sMap: SMap | null,
    setItems?: (items: Coordinates[]) => void,
    api?: EvidenceAPI
  ) => Promise<void>;
}

export enum MarkerType {
  CUSTOM_RED = 'CUSTOM_RED',
  CUSTOM_GREY = 'CUSTOM_GREY',
  DEFAULT = 'DEFAULT',
}

interface MarkerBaseProps {
  id?: string;
  lng: number | string;
  lat: number | string;
  active?: boolean;
  selected?: boolean;
  innerTitle?: string;
  type?: MarkerType;
  onDragEnd?: (newCoords: Wgs84Coordinates) => void;
  draggable?: boolean;
}

interface MarkerCardProps {
  title?: React.ReactElement;
  body?: React.ReactElement;
}

export type MarkerProps = MarkerBaseProps & MarkerCardProps;

export interface MarkersProps {
  markers: MarkerBaseProps[];
  card?: MarkerCardProps;
}

import React from 'react';
import {
  FormPanel,
  FormSelect,
  useFormSelector,
  FormTextField,
  FormDateTimeField,
  FormCheckbox,
} from '@eas/common-web';
import { useTypes } from './outages-api';
import { Outage } from '../../models/outage';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { HelpLabel } from '../../components/help/help-label';

export function OutagesFields(props: NotedEvidenceFieldsProps) {
  const types = useTypes();

  const sendNotification = useFormSelector(
    (data: Outage) => data.sendNotification
  );

  return (
    <>
      <FormPanel label="Základní informace">
        <FormSelect
          name="type"
          label={<HelpLabel label="Typ" code="OUTAGE_TYPE" required />} // Povinné pole
          source={types}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        <FormDateTimeField
          name="startTime"
          representation="local-date-time"
          label={
            <HelpLabel label="Začátek" code="OUTAGE_START_TIME" required />
          } // Povinné pole
        />
        <FormDateTimeField
          name="endTime"
          representation="local-date-time"
          label={<HelpLabel label="Konec" code="OUTAGE_END_TIME" required />} // Povinné pole
        />
        <FormTextField
          name="reason"
          label={<HelpLabel label="Důvod" code="OUTAGE_REASON" required />} // Povinné pole
        />
      </FormPanel>
      <FormPanel label="Notifikace">
        <FormCheckbox
          name="sendNotification"
          label={
            <HelpLabel
              label="Odeslat notifikaci"
              code="OUTAGE_SEND_NOTIFICATION"
            />
          }
        />
        <FormTextField
          name="notificationText"
          label={
            <HelpLabel
              label="Text notifikace"
              code="OUTAGE_NOTIFICATION_TEXT"
            />
          }
          disabled={!sendNotification}
        />
        <FormCheckbox
          name="processed"
          label={<HelpLabel label="Zpracováno" code="OUTAGE_PROCESSED" />}
          // Příznak, jestli už systém rozeslal notifikace. Po změně záznamu se resetuje
          disabled
        />
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

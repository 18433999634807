import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { useTypes } from './outages-api';
import { Outage } from '../../models/outage';

export function useColumns(): TableColumn<Outage>[] {
  return [
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'startTime',
      name: 'Začátek',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'endTime',
      name: 'Konec',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'reason',
      name: 'Důvod',
      width: 400,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'sendNotification',
      name: 'Odeslat notifikaci',
      width: 200,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

export function usePublicColumns(): TableColumn<Outage>[] {
  return [
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'startTime',
      name: 'Začátek',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'endTime',
      name: 'Konec',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'reason',
      name: 'Důvod',
      width: 400,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import { abortableFetch } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function exportZip() {
  return abortableFetch(EvidenceAPI.DICTIONARY_INFOS + '/export-zip', {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}

import React from 'react';
import { EvidenceAPI, Permission, Messages } from '../../../enums';
import {
  abortableFetch,
  DetailHandle,
  DetailContext,
  SnackbarContext,
  PromptContext,
  UserContext,
  AbortableFetch,
  FormTextField,
  usePrompts,
  useEventCallback,
  FormTextArea,
} from '@eas/common-web';
import { useContext, useRef } from 'react';
import * as Yup from 'yup';
import { HelpLabel } from '../../../components/help/help-label';
import {
  SubjectRegistration,
  SubjectRegistrationState,
  SubjectRegistrationType,
} from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiButton from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    actions: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      padding: '10px 24px',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    buttonLabel: {
      textTransform: 'uppercase',
    },
    delimiter: { width: '100%', marginBottom: 50 },
  })
);

/**
 * Api call
 */
function callApi(
  registrationId: string,
  { subjectId, note }: { subjectId?: string; note?: string }
) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECT_REQUESTS}/${registrationId}/link-to/${subjectId}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        note: note,
      }),
    }
  );
}

/**
 * Propojení registrace subjektu s existujícím subjektem
 */
const PROMPT_KEY = 'LINK-TO';

export function useLinkToDialog() {
  /**
   * Context stuff
   */
  const { onPersisted, source } = useContext<DetailHandle<SubjectRegistration>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { reload, hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogShowClose: false,
      dialogShowConfirm: false,
      dialogTitle: 'Propojení registrace subjektu se subjektem',
      dialogText:
        'Skutečně chcete propojit registraci subjektu se zadaným subjektem?',
      FormFields: function Body({ onConfirm, onCancel, loading }) {
        const classes = useStyles();

        const handleSubmit = useEventCallback(async (e) => {
          const result = await onConfirm!(e);

          if (result !== false) {
            onCancel!();
          }
        });

        return (
          <>
            <FormTextField
              name="subjectId"
              label={
                <HelpLabel
                  label="UID subjektu"
                  code="SUBJECT_REQUEST_LINK_TO_DIALOG_SUBJECT_ID"
                />
              }
            />
            <FormTextArea name="note" label="Poznámka" />
            <div className={classes.delimiter} />
            <MuiDialogActions classes={{ root: classes.actions }}>
              <ButtonGroup size="small" variant="outlined">
                <MuiButton
                  type="submit"
                  onClick={handleSubmit}
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  startIcon={
                    loading && <CircularProgress size="20px" color="inherit" />
                  }
                >
                  <Typography classes={{ root: classes.buttonLabel }}>
                    Propojit a schválit
                  </Typography>
                </MuiButton>
                <MuiButton
                  variant="outlined"
                  onClick={onCancel}
                  disabled={loading}
                >
                  <Typography classes={{ root: classes.buttonLabel }}>
                    Zavřít
                  </Typography>
                </MuiButton>
              </ButtonGroup>
            </MuiDialogActions>
          </>
        );
      },
      formValidationSchema: Yup.object().shape({
        subjectId: Yup.mixed().nullable().required('Povinné pole'),
      }),
      dialogWidth: 600,
    },
  ]);

  const handleLinkTo = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async ({
        subjectId,
        note,
      }: {
        subjectId?: string;
        note?: string;
      }) => {
        try {
          source.setLoading(true);
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(source.data!.id, { subjectId, note });
          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.SubjectRegistration.LINK_TO.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();

          return false;
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.SubjectRegistration.LINK_TO,
              err.code
            );

            showSnackbar(...message);

            throw err;
          }
          return false;
        }
      },
    });
  });

  const showLinkToButton =
    source.data?.state === SubjectRegistrationState.MANUAL_FINISH_REQUIRED &&
    (source.data?.type ===
      SubjectRegistrationType.FOREIGN_LEGAL_ENTITY_REGISTRATION ||
      source.data?.type ===
        SubjectRegistrationType.FOREIGN_NATURAL_PERSON_REGISTRATION ||
      source.data?.type ===
        SubjectRegistrationType.LEGAL_ENTITY_WITHOUT_ICO_REGISTRATION) &&
    hasPermission(Permission.Subject.SUBJECT_REQUEST_FINISH);

  return { handleLinkTo, showLinkToButton };
}

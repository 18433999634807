import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      minWidth: '300px',
      width: '100%',
      height: 32,
    },
  })
);

export const useStylesWrapper = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      '&:not(:last-child)': {
        borderBottom: '1px solid #cdcdcd',
      },
    },
    spacing: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    },
    labelRoot: {
      color: '#333',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    labelError: {
      color: '#CD5360',
    },
    labelText: {
      textAlign: 'left',
      lineHeight: '22px',
      fontSize: 13,
    },
    labelDisabled: {
      color: '#333 !important',
    },
    boxRoot: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    boxError: {
      color: '#CD5360',
    },
  })
);

import React from 'react';
import { FormTextField, FormPanel, FormNumberField } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export function SequencesFields() {
  return (
    <FormPanel label="Číselná řada">
      <FormTextField
        name="description"
        label={<HelpLabel label="Popis" code="DICT_SEQUENCE_DESCRIPTION" />}
      />
      <FormTextField
        name="format"
        label={
          <HelpLabel label="Formát" code="DICT_SEQUENCE_FORMAT" required />
        }
      />
      <FormNumberField
        name="counter"
        label={
          <HelpLabel label="Počítadlo" code="DICT_SEQUENCE_COUNTER" required />
        }
      />
    </FormPanel>
  );
}

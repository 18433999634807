import React, { Fragment, useMemo } from 'react';
import {
  FormInlineTableField,
  FormPanel,
  InlineTableFieldCells,
  FormCustomField,
  useFormSelector,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { HelpLabel } from '../../../components/help/help-label';
import {
  SepnoDetails as SepnoDetailsType,
  DictionaryAutocomplete,
  Role,
} from '../../../models';
import { useSepnoIdentifierTypes } from '../role-instances-api';

export const useStyles = makeStyles((theme) => ({
  warning: {
    lineHeight: 'inherit',
    color: theme.palette.error.main,
    fontSize: 12,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

function SepnoDetailsTable({
  collectionDatakey,
}: {
  collectionDatakey: string;
}) {
  const typeCell = InlineTableFieldCells.useInlineSelectCellFactory<
    SepnoDetailsType,
    DictionaryAutocomplete
  >({
    dataHook: useSepnoIdentifierTypes,
    collectionDatakey,
    valueIsId: true,
    disabled: false,
  });

  const valueCell = InlineTableFieldCells.useInlineTextFieldFactory<
    SepnoDetailsType
  >({
    collectionDatakey,
    isSubkey: true,
  });

  const validFromCell = InlineTableFieldCells.useInlineDateFieldFactory<
    SepnoDetailsType
  >({
    collectionDatakey,
    isSubkey: true,
    representation: 'local-date',
  });

  const validToCell = InlineTableFieldCells.useInlineDateFieldFactory<
    SepnoDetailsType
  >({
    collectionDatakey,
    isSubkey: true,
    representation: 'local-date',
  });

  const columns = useMemo(
    () => [
      {
        name: 'Identifikátor',
        datakey: 'identifier.type',
        width: 120,
        CellComponent: typeCell,
      },
      {
        name: 'Hodnota',
        datakey: 'identifier.value',
        width: 120,
        CellComponent: valueCell,
      },
      {
        name: 'Platnost od',
        datakey: 'validFrom',
        width: 200,
        CellComponent: validFromCell,
      },
      {
        name: 'Platnost do',
        datakey: 'validTo',
        width: 200,
        CellComponent: validToCell,
      },
    ],
    [collectionDatakey]
  );

  return (
    <FormInlineTableField
      name={collectionDatakey}
      labelOptions={{ hide: true }}
      layoutOptions={{ noUnderline: true }}
      showToolbar={true}
      withRemove={true}
      columns={columns}
    />
  );
}

export function SepnoDetails() {
  return (
    <FormPanel
      label={
        <HelpLabel label="SEPNO detaily" code="ROLE_INSTANCES_SEPNO_DETAILS" />
      }
    >
      <SepnoDetailsTable collectionDatakey="sepnoDetails" />
    </FormPanel>
  );
}

export function DialogSepnoDetails() {
  const classes = useStyles();

  const { userRoleSepnoDetails } = useFormSelector(
    (values: {
      userRoleSepnoDetails: { role: Role; sepnoDetails: SepnoDetailsType[] }[];
    }) => ({
      userRoleSepnoDetails: values.userRoleSepnoDetails ?? [],
    })
  );

  return (
    <FormCustomField label="SEPNO detaily">
      <div className={classes.wrapper}>
        {userRoleSepnoDetails.map((details, index) => (
          <Fragment key={index}>
            <Typography variant="body1">
              Role <b>{details.role.name}</b>
            </Typography>

            <SepnoDetailsTable
              collectionDatakey={`userRoleSepnoDetails.${index}.sepnoDetails`}
            />
          </Fragment>
        ))}
      </div>
    </FormCustomField>
  );
}

import React from 'react';
import { FormPanel, FormTextField, FormSelect } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';
import { dictionaryNoCodeLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useStates } from '../../ispop-document/ispop-documents-api';

export function IspopDocumentFields() {
  const prefix = 'ispopDocument';
  const states = useStates();

  return (
    <FormPanel label="Potvrzení z DS">
      <FormTextField
        name={`${prefix}.referenceNumber`}
        label={
          <HelpLabel
            label="Číslo jednací"
            code="SUBJECT_REGISTRATION_ISPOP_DOC_REFERENCE_NUMBER"
          />
        }
      />
      <FormTextField
        name={`${prefix}.registrationNumber`}
        label={
          <HelpLabel
            label="Číslo evidenční"
            code="SUBJECT_REGISTRATION_ISPOP_DOC_REGISTRATION_NUMBER"
          />
        }
      />
      <FormTextField
        name={`${prefix}.dataMessageId`}
        label={
          <HelpLabel
            label="Id datové zprávy"
            code="SUBJECT_REGISTRATION_ISPOP_DOC_DATA_MESSAGE_ID"
          />
        }
      />
      <FormTextField
        name={`${prefix}.dataBoxId`}
        label={
          <HelpLabel
            label="Id datové schránky"
            code="SUBJECT_REGISTRATION_ISPOP_DOC_DATABOX_ID"
          />
        }
      />
      <FormTextField
        name={`${prefix}.isdsSubjectName`}
        label={
          <HelpLabel
            label="Název subjektu isds"
            code="SUBJECT_REGISTRATION_ISPOP_DOC_SUBJECT_NAME"
          />
        }
      />
      <FormSelect
        name={`${prefix}.state`}
        label={
          <HelpLabel label="Stav" code="SUBJECT_REGISTRATION_ISPOP_DOC_STATE" />
        }
        source={states}
        labelMapper={dictionaryNoCodeLabelMapper}
        tooltipMapper={dictionaryNoCodeLabelMapper}
        valueIsId={true}
      />
    </FormPanel>
  );
}

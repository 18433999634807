import { CohesionRegion } from '../../models';
import { TableColumn, TableCells } from '@eas/common-web';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export const useColumns = () => {
  const columns: TableColumn<CohesionRegion, any>[] = [
    {
      datakey: 'nutsLauCode',
      name: 'Kód NUTS/LAU',
      width: 150,
      CellComponent: inactiveColoredFactory<CohesionRegion>(
        TableCells.TextCell
      ),
    },
  ];

  return columns;
};

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useBasinEnterprises } from '../water-usage-place/water-usage-places-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const basinEnterprises = useBasinEnterprises();

  return [
    {
      key: 'ppId.id',
      label: 'ID v databázi PP',
    },
    {
      key: 'ppId.enterprise',
      label: 'Podnik povodí',
      source: basinEnterprises,
    },
    { key: 'referenceNumber', label: 'Číslo jednací' },
    { key: 'issuedDate', label: 'Datum vydání' },
    { key: 'issuedBy', label: 'Vydal' },
  ];
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useAddressFieldsFactory } from '../../components/form/address-field/address-field-history-fields';
import { coordinatesValueMapper } from '../../components/evidence/history-evidence/history-utils';
import { useTerritorialTechnicalUnits } from '../dict-territorial-technical-unit/dict-territorial-technical-units-api';
import { useIrzFacilities } from '../irz-facility/irz-facilities-api';
import { useLandPlotTypes } from './ovz-facilities-api';
import { useCadastralTerritories } from '../../components/form/address-field/address-field-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const territorialTechnicalUnits = useTerritorialTechnicalUnits();
  const irzFacilities = useIrzFacilities();
  const landPlotTypes = useLandPlotTypes();
  const cadastralTerritories = useCadastralTerritories();

  return [
    ...useAddressFieldsFactory('address'),
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'landPlotType',
      label: 'Typ parcely',
      source: landPlotTypes,
    },
    {
      key: 'primaryLandPlotNumber',
      label: 'Kmenové číslo parcely',
    },
    {
      key: 'secondaryLandPlotNumber',
      label: 'Pořadové číslo parcely',
    },
    {
      key: 'extendedCompetenceMunicipality',
      label: 'Obec s rozšířenou působností',
    },
    {
      key: 'cadastralTerritory',
      label: 'Katastrální území',
      source: cadastralTerritories,
    },
    {
      key: 'territorialTechnicalUnit',
      label: 'ÚTJ',
      source: territorialTechnicalUnits,
    },
    {
      key: 'multipleTTU',
      label: 'Areál se nachází na více ÚTJ',
    },
    {
      key: 'facilityId',
      label: 'IČP',
    },
    {
      key: 'integratedPermission.hasPermission',
      label: 'Provozovna/Zařízení má Integrované povolení',
    },
    {
      key: 'integratedPermission.ippcPids',
      label: 'PID (IPPC)',
    },
    {
      key: 'emissionsTradingSystem.tradesInEmissions',
      label:
        'Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi',
    },
    {
      key: 'emissionsTradingSystem.ids',
      label: 'ID zařízení (EU ETS)',
    },
    {
      key: 'irzFacility',
      label: 'Provozovna IRZ',
      source: irzFacilities,
    },
    {
      key: 'operator',
      label: 'Provozovatel',
    },
    {
      key: 'registrationDate',
      label: 'Datum registrace',
    },
    {
      key: 'validTo',
      label: 'Datum zneplatnění',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'wgs84Coordinates.lat',
      label: 'Zeměpisná šířka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'wgs84Coordinates.lon',
      label: 'Zeměpisná délka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'note',
      label: 'Poznámky',
    },
  ];
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useRoleInstanceTypes } from './role-instances-api';
import { useUsers } from '../user/users-api';
import { useSystems } from '../system/systems-api';
import { historyValueMapper } from '../../components/evidence/history-evidence/history-utils';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const types = useRoleInstanceTypes();
  const users = useUsers();
  const systems = useSystems();

  return [
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platnost od',
    },
    {
      key: 'validTo',
      label: 'Platnost do',
    },
    {
      key: 'type',
      label: 'Typ',
      source: types,
    },
    {
      key: 'role',
      label: 'Role',
    },
    {
      key: 'user',
      label: 'Uživatel',
      source: users,
    },
    {
      key: 'subject',
      label: 'Subjekt',
    },
    {
      key: 'system',
      label: 'Systém',
      source: systems,
    },
    {
      key: 'sepnoDetails',
      label: 'SEPNO detaily',
      valueMapper: (change) =>
        historyValueMapper(change, getSpecificationLabel),
    },
  ];
}

function getSpecificationLabel(key: string) {
  switch (key) {
    case 'identifier': {
      return 'Identifikátor';
    }
    case 'type': {
      return 'Typ';
    }
    case 'value': {
      return 'Hodnota';
    }
    case 'validFrom': {
      return 'Platnost od';
    }
    case 'validTo': {
      return 'Platnost do';
    }
  }
}

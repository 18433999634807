import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ProcessActionsProps } from '../../../components/process/process-types';
import {
  useEventCallback,
  FormContext,
  SnackbarContext,
  PromptContext,
  Prompt,
  NavigationContext,
} from '@eas/common-web';
import {
  EmpowermentRequestUnion,
  SubjectType,
  SubjectIdentificator,
  IdType,
  AgendaType,
  EmpowermentSpecificationApprovalState,
} from '../../../models';
import { ProcessForm } from '../empowerment-requests-types';
import { createEmpowerementRequest } from '../empowerment-requests-api';
import { Messages } from '../../../enums';
import { formatIco } from '../../subject/fields/subjects-utils';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function StepSummaryActions({
  handleNext,
  handleBack,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const { setFieldValue } = useContext(FormContext);

  const { registerPrompts, unregisterPrompts, testPrompt } = useContext(
    PromptContext
  );

  const { unregisterPrompt } = useContext(NavigationContext);

  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const prompt: Prompt = {
      key: 'CREATE_REQUEST_PROMPT',
      dialogTitle: 'Žádost o zmocnění',
      dialogText: 'Skutečne chcete odeslat žádost o zmocnění?',
    };

    registerPrompts([prompt]);

    return () => {
      unregisterPrompts([prompt]);
    };
  }, []);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: 'CREATE_REQUEST_PROMPT',
      submitCallback: async () => {
        setLoading(true);

        const hasIco =
          allValues.empowerer?.type === SubjectType.LEGAL_ENTITY ||
          allValues.empowerer?.type === SubjectType.BUSINESS_NATURAL_PERSON;

        const hasUID =
          allValues.empowerer?.type === SubjectType.FOREIGN_NATURAL_PERSON ||
          allValues.empowerer?.type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO;

        const hasIdOrPassport =
          allValues.empowerer?.type === SubjectType.NATURAL_PERSON;

        const processedValuesShared = {
          id: uuidv4(),
          author: allValues.author,
          authorRole: allValues.authorRole,
          otherSubject: {},
          certificateOfIncorporation: allValues.certificateOfIncorporation,
        };

        const processedValues: EmpowermentRequestUnion[] = [];

        (allValues?.subForms || []).forEach((item) => {
          const processedObject = {
            ...processedValuesShared,
            type: item?.agenda?.type,
            agenda: item?.agenda,
            empowermentDocument: item?.empowermentDocument,
            validFrom: item?.validFrom,
            validTo: item?.withoutDay ? null : item?.validTo,
            specificationRequests: [],
          } as EmpowermentRequestUnion;

          processedObject.otherSubject!.subjectType =
            allValues?.empowerer?.type;

          if (allValues.empowerer?.type === SubjectType.FOREIGN_LEGAL_ENTITY) {
            const {
              firstName,
              lastName,
              residence,
              foreignId,
              name,
              powerOfAttorney,
              registerExtract,
            } = allValues.empowerer;

            const { country, street, zip, municipality } = residence || {};

            processedObject.otherSubject!.foreignSubjectInfo = {
              country,
              street,
              municipality,
              zip,
              firstName,
              lastName,
              name,
              registerExtract,
              powerOfAttorney,
              foreignId,
            };
          } else if (
            allValues.empowerer?.type === SubjectType.FOREIGN_NATURAL_PERSON
          ) {
            const { identityDocumentCopy } = allValues.empowerer;

            processedObject.otherSubject!.foreignSubjectInfo = {
              identityDocumentCopy,
            };
          }

          if (hasIco) {
            processedObject.otherSubject!.type = SubjectIdentificator.ICO;
            processedObject.otherSubject!.value =
              typeof allValues.empowerer?.ico === 'string'
                ? formatIco(allValues.empowerer.ico)
                : allValues.empowerer?.ico;
          }

          if (hasUID) {
            processedObject.otherSubject!.type =
              SubjectIdentificator.ARTIFICIAL_ID;
            processedObject.otherSubject!.value =
              allValues.empowerer?.artificialId;
          }

          if (
            hasIdOrPassport &&
            allValues.empowerer?.idType === IdType.ID_CARD
          ) {
            processedObject.otherSubject!.type =
              SubjectIdentificator.ID_CARD_NUMBER;
            processedObject.otherSubject!.value =
              allValues.empowerer.idCardNumber;
          }

          if (
            hasIdOrPassport &&
            allValues.empowerer?.idType === IdType.PASSPORT
          ) {
            processedObject.otherSubject!.type =
              SubjectIdentificator.PASSPORT_NUMBER;
            processedObject.otherSubject!.value =
              allValues.empowerer.passportNumber;
          }

          if (processedObject.type === AgendaType.SPECIFIED) {
            (item?.specifications || []).forEach((specification) => {
              processedObject.specificationRequests.push({
                specification,
                approvalState: EmpowermentSpecificationApprovalState.NONE,
              });
            });
          }

          processedValues.push(processedObject);
        });

        const jsonResponses = processedValues.map(async (item) => {
          try {
            return await createEmpowerementRequest(item).json();
          } catch (err) {
            setLoading(false);
            let error:
              | 'AGENT_SUBJECT_NOT_FOUND'
              | 'SUBJECT_ARTIFICIAL_ID_NOT_FOUND'
              | 'AGENT_WITH_ICO_NOT_FOUND'
              | 'NATURAL_PERSON_AGENT_NOT_FOUND'
              | 'ERROR'
              | 'INVALID_ENTITY_VALIDITY_RANGE'
              | 'NOT_OWN' = err?.code ?? 'ERROR';

            if (
              error === 'AGENT_SUBJECT_NOT_FOUND' &&
              item.otherSubject?.subjectType
            ) {
              error = getAgentNotFoundErrorCode(item.otherSubject?.subjectType);
            }

            if (err.name !== 'AbortError') {
              const message = getErrorMessage(
                Messages.EmpowermentRequest.SUBMIT_REQUEST,
                error
              );

              showSnackbar(...message);

              throw err;
            }
          }
        });

        Promise.all(jsonResponses).then((values) => {
          setLoading(false);
          unregisterPrompt(navigationPrompt);

          // Set only first request
          setFieldValue('empowerement', values[0]);
          setFieldValue('isSent', true);

          handleNext();
        });
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Odeslat žádost
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} disabled={loading} />
      </div>
    </div>
  );
}

function getAgentNotFoundErrorCode(subjectType: SubjectType) {
  switch (subjectType) {
    case SubjectType.NATURAL_PERSON:
      return 'NATURAL_PERSON_AGENT_NOT_FOUND';
    case SubjectType.LEGAL_ENTITY:
    case SubjectType.BUSINESS_NATURAL_PERSON:
      return 'AGENT_WITH_ICO_NOT_FOUND';
    case SubjectType.FOREIGN_LEGAL_ENTITY:
    case SubjectType.FOREIGN_NATURAL_PERSON:
    case SubjectType.LEGAL_ENTITY_WITHOUT_ICO:
      return 'SUBJECT_ARTIFICIAL_ID_NOT_FOUND';
    default:
      return 'AGENT_SUBJECT_NOT_FOUND';
  }
}

import React from 'react';
import { FormDateField, FormSelect, useFormSelector } from '@eas/common-web';
import { useSubjectTypes } from '../../subject/subjects-api';
import { get } from 'lodash';
import { SubjectType, EmpowermentRequest } from '../../../models';
import { NaturalPersonDocuments } from '../../subject/fields/natural-person-fields/natural-person-documents';
import { HelpLabel } from '../../../components/help/help-label';
import { SubjectRequestFileFields } from '../../subject-request/fields/subject-request-file-fields';

export function SubjectExtendedFields({
  prefix,
  codePrefix,
  isAuthor,
}: {
  prefix: string;
  codePrefix: string;
  isAuthor: boolean;
}) {
  const allSubjectTypes = useSubjectTypes();

  const names = {
    originDate: prefix ? `${prefix}.originDate` : 'originDate',
    endDate: prefix ? `${prefix}.endDate` : 'endDate',
    deceaseDate: prefix ? `${prefix}.deceaseDate.value` : 'deceaseDate.value',
    birthDate: prefix ? `${prefix}.birthDate.value` : `birthDate.value`,
    type: prefix ? `${prefix}.type` : 'type',
    identityDocuments: prefix
      ? `${prefix}.identityDocuments`
      : 'identityDocuments',
  };

  const { type } = useFormSelector((data: EmpowermentRequest) => ({
    type: get(data, names.type) as SubjectType,
  }));

  const hasIdentityDocuments = type === SubjectType.NATURAL_PERSON;

  const hasBirthAndDeceaseDates = [
    SubjectType.NATURAL_PERSON,
    SubjectType.FOREIGN_NATURAL_PERSON,
  ].includes(type);

  const hasOriginAndEndDate = !hasBirthAndDeceaseDates;

  return (
    <>
      {hasOriginAndEndDate && (
        <>
          <FormDateField
            name={names.originDate}
            label={
              <HelpLabel
                label="Datum vzniku"
                code={`${codePrefix}_${prefix.toUpperCase()}_ORIGIN_DATE`}
              />
            }
            disabled={true}
          />
          <FormDateField
            name={names.endDate}
            label={
              <HelpLabel
                label="Datum zániku"
                code={`${codePrefix}_${prefix.toUpperCase()}_END_DATE`}
              />
            }
            disabled={true}
          />
        </>
      )}

      {hasBirthAndDeceaseDates && (
        <>
          <FormDateField
            name={names.birthDate}
            label={
              <HelpLabel
                label="Datum narození"
                code={`${codePrefix}_${prefix.toUpperCase()}_BIRTH_DATE`}
              />
            }
            disabled={true}
          />
          <FormDateField
            name={names.deceaseDate}
            label={
              <HelpLabel
                label="Datum úmrtí"
                code={`${codePrefix}_${prefix.toUpperCase()}_DECEASE_DATE`}
              />
            }
            disabled={true}
          />
        </>
      )}

      <FormSelect
        name={names.type}
        label={
          <HelpLabel
            label="Typ"
            code={`${codePrefix}_${prefix.toUpperCase()}_TYPE`}
          />
        }
        source={allSubjectTypes}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
        disabled={true}
      />

      {hasIdentityDocuments && (
        <NaturalPersonDocuments
          prefix={prefix}
          codePrefix={codePrefix}
          disabled={true}
        />
      )}
      {!isAuthor &&
        [
          SubjectType.FOREIGN_LEGAL_ENTITY,
          SubjectType.FOREIGN_NATURAL_PERSON,
        ].includes(type) && (
          <SubjectRequestFileFields
            type={type}
            prefix="otherSubject.foreignSubjectInfo"
          />
        )}
    </>
  );
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useRequestTypes, useRequestStates } from './irz-facility-requests-api';
import { useEuStates } from '../irz-facility/irz-facilities-api';
import { useAddressFieldsFactory } from '../../components/form/address-field/address-field-history-fields';
import {
  historyValueMapper,
  coordinatesValueMapper,
} from '../../components/evidence/history-evidence/history-utils';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const requestTypes = useRequestTypes();
  const requestStates = useRequestStates();
  const euStates = useEuStates();

  return [
    ...useAddressFieldsFactory('requestFacilityData.address'),
    {
      key: 'type',
      label: 'Typ',
      source: requestTypes,
    },
    {
      key: 'state',
      label: 'Stav',
      source: requestStates,
    },
    {
      key: 'userEmail',
      label: 'Email odesílatele',
    },
    {
      key: 'decided',
      label: 'Datum a čas vyřízení',
    },
    {
      key: 'date',
      label: 'Datum',
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
    {
      key: 'relatedSubject',
      label: 'Původní provozovatel',
    },
    {
      key: 'requestFacilityData.irzId',
      label: '',
    },
    {
      key: 'requestFacilityData.name',
      label: 'Název provozovny',
    },
    {
      key: 'requestFacilityData.emissionsTradingSystem.tradesInEmissions',
      label:
        'Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi',
    },
    {
      key: 'requestFacilityData.emissionsTradingSystem.ids',
      label: 'ID zařízení (EU ETS)',
    },
    {
      key: 'requestFacilityData.nace',
      label: 'NACE',
    },
    {
      key: 'requestFacilityData.czNace',
      label: 'CZ-NACE',
    },
    {
      key: 'requestFacilityData.czRiverBasinDistrict',
      label: 'Oblast povodí ČR',
    },
    {
      key: 'requestFacilityData.euRiverBasinDistrict',
      label: 'Oblast povodí Evropy',
    },
    {
      key: 'requestFacilityData.wgs84Coordinates.lat',
      label: 'Zeměpisná šířka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'requestFacilityData.wgs84Coordinates.lon',
      label: 'Zeměpisná délka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'requestFacilityData.mainEprtrActivity.activity',
      label: 'Hlavní E-PRTR činnost',
    },
    {
      key: 'requestFacilityData.mainEprtrActivity.facilityCount',
      label: 'Počet zařízení hlavní E-PRTR činnosti',
    },
    {
      key: 'requestFacilityData.secondaryEprtrActivities',
      label: 'Seznam vedlejších E-PRTR činností',
      valueMapper: (change) => historyValueMapper(change, getActivitiesLabel),
    },
    {
      key: 'requestFacilityData.ippcActivity',
      label: 'Činnost IPPC',
    },
    {
      key: 'requestFacilityData.integratedPermission.hasPermission',
      label: 'Provozovna/Zařízení má Integrované povolení',
    },
    {
      key: 'requestFacilityData.integratedPermission.ippcPids',
      label: 'PID (IPPC)',
    },
    {
      key: 'requestFacilityData.mainIrzActivity.activity',
      label: 'Hlavní činnost dle přílohy zákona o IRZ a ISPOP',
    },
    {
      key: 'requestFacilityData.mainIrzActivity.facilityCount',
      label: 'Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP',
    },
    {
      key: 'requestFacilityData.mainIrzActivity.specifics',
      label: 'Podrobnější specifikace',
    },
    {
      key: 'requestFacilityData.secondaryIrzActivities',
      label: 'Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP',
      valueMapper: (change) => historyValueMapper(change, getActivitiesLabel),
    },
    {
      key: 'requestFacilityData.euStateRecord.euState',
      label: 'Stav EU k předchozímu roku podání',
      source: euStates,
    },
    {
      key: 'requestFacilityData.euStateRecord.year',
      label: 'Předchozí rok podání EU',
    },
    {
      key: 'requestFacilityData.webSite',
      label: 'Web stránka',
    },
    {
      key: 'requestFacilityData.publicEmail',
      label: 'Veřejný e-mail',
    },
    {
      key: 'requestFacilityData.publicPhoneNumber',
      label: 'Veřejný telefon',
    },
    {
      key: 'requestFacilityData.operator',
      label: 'Provozovatel',
    },
    {
      key: 'requestFacilityData.validTo',
      label: 'Neplatná do',
    },
    {
      key: 'requestFacilityData.validFrom',
      label: 'Neplatná od',
    },
    {
      key: 'requestFacilityData.note',
      label: 'Poznámky provozovny',
    },
    {
      key: 'requestFacilityData.competentControlAuthorities',
      label: 'Kompetentní kontrolní a ostatní orgány',
    },
    {
      key: 'requestFacilityData.commissioningDate',
      label: 'Datum uvedení do provozu',
    },
    {
      key: 'irzFacility',
      label: 'Provozovna IRZ',
    },
    {
      key: 'etrs89Coordinates.lat',
      label: 'Zeměpisná šířka (ETRS-89)',
    },
    {
      key: 'etrs89Coordinates.lon',
      label: 'Zeměpisná délka (ETRS-89)',
    },
  ];
}

function getActivitiesLabel(key: string) {
  switch (key) {
    case 'activity':
      return 'Činnost';
    case 'facilityCount':
      return 'Počet zařízení';
  }
}

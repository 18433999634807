import { useMemo, ComponentType } from 'react';
import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableCellProps,
} from '@eas/common-web';
import { inactiveColoredFactory } from '../../form/inactive-colored/inactive-colored';
import { AuthoredObject } from '../../../models';

export function useColumns<OBJECT extends AuthoredObject>({
  columns,
  showColumn = () => true,
  columnLabelMapper = () => undefined,
  columnWrapper = inactiveColoredFactory,
}: {
  columns?: TableColumn<OBJECT>[];
  showColumn?: (col: 'CREATED_BY') => boolean;
  columnLabelMapper?: (col: 'CREATED_BY') => string | undefined;
  columnWrapper?: (
    Component: ComponentType<TableCellProps<OBJECT>>
  ) => ComponentType<TableCellProps<OBJECT>>;
}) {
  const authoredColumns = useMemo(() => {
    const base = columns ?? [];

    if (showColumn('CREATED_BY')) {
      base.push({
        datakey: 'createdBy',
        displaykey: 'createdBy.name',
        sortkey: 'createdBy.name',
        filterkey: 'createdBy.name',
        filterOperation: ApiFilterOperation.CONTAINS,
        name: columnLabelMapper('CREATED_BY') ?? 'Autor',
        width: 150,
        CellComponent: columnWrapper(TableCells.TextCell),
        sortable: true,
        filterable: true,
        filterGroup: 10,
      });
    }

    return base;
  }, [columnLabelMapper, columns, showColumn]) as TableColumn<OBJECT>[];

  return authoredColumns;
}

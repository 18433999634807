import React, { useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Evidence2, UserContext } from '@eas/common-web';
import { User, Me } from '../../models';
import { UsersFields } from './users-fields';
import { useColumns } from './users-columns';
import { useValidationSchema } from './users-schema';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  IncludeOptions,
  EntityName,
} from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { getItemFactory } from '../../utils/custom-crud';
import { ExportTags } from '../../enums/export-tags';
import { combineGetCalls } from './users-api';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { EntityType } from '../../enums/entity-type';
import { UsersActionbar } from './users-actionbar';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useSpecialFilters } from './users-special-filters';
import { UsersTableToolbar } from './users-table-toolbar';
import { useHistoryDescriptors } from './users-history';
import { UsersToolbar } from './users-toolbar';

export function Users() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<User>({
    options: {
      NEW: [Permission.User.USER_CREATE],
      EDIT: [Permission.User.USER_UPDATE_ALL],
      REMOVE: [],
      CHECK: [Permission.User.USER_CREATE, Permission.User.USER_UPDATE_ALL],
      SAVE: [Permission.User.USER_CREATE, Permission.User.USER_UPDATE_ALL],
      CLOSE: [Permission.User.USER_CREATE, Permission.User.USER_UPDATE_ALL],
    },
  });

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const canSeeDeactivated = useMemo(
    () => hasPermission(Permission.User.USER_REVALIDATE),
    [hasPermission]
  );
  const canSeeUuid = useMemo(
    () => hasPermission(Permission.User.USER_DETAIL_ALL),
    [hasPermission]
  );

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useDatedEvidence<User>({
    version: 3,
    identifier: PageKey.USERS,
    noteEntityType: EntityType.USER,
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.User.USER_LIST_ALL,
    },
    apiProps: {
      url: EvidenceAPI.USERS,
      getItem: getItemFactory({ combineGetCalls }),
    },
    tableProps: {
      columns: useColumns(canSeeDeactivated, canSeeUuid),
      tableName: 'Přehled uživatelů',
      reportTag: ExportTags.USERS,
      include,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.User.USER_LIST_ALL,
      toolbarProps: {
        after: <UsersTableToolbar />,
      },
    },
    detailProps: {
      entityName: EntityName.USER,
      ToolbarComponent: UsersToolbar,
      FieldsComponent: UsersFields,
      validationSchema,
      initNewItem: () => ({
        id: uuidv4(),
        phoneNumber: {
          prefix: '+420',
        },
      }),
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Uživatel',
        subtitle: useSubTitle<User>(
          (data) =>
            `${data?.firstName?.value ?? ''} ${data?.lastName?.value ?? ''}`
        ),
        ActionBar: UsersActionbar,
        showBtn: permissions,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

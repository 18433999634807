import React from 'react';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useState, useContext, useRef } from 'react';
import {
  useEventCallback,
  PromptContext,
  usePrompts,
  abortableFetch,
  AbortableFetch,
  FormContext,
  SnackbarContext,
  NavigationContext,
} from '@eas/common-web';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ProcessForm } from '../irz-facility-request-operator-change-types';
import { EvidenceAPI, Messages } from '../../../enums';
import { ExitProcessAction } from '../../../components/process/process-actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

interface IrzFacilityRequestOperatorChange {
  note?: string;
  date?: string;
  irzFacilityId?: string;
  subjectId?: string;
  operatorId?: string;
  name?: string;
}

/**
 * Api call
 *
 * @param body
 */
function callApi(body: IrzFacilityRequestOperatorChange) {
  return abortableFetch(
    `${EvidenceAPI.IRZ_FACILITY_REQUESTS}/operator-change`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(body),
    }
  );
}

const PROMPT_KEY = 'CREATE_OPERATOR_CHANGE_REQUEST_PROMPT';

export function StepSummaryActions({
  handleBack,
  handleNext,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Context stuff
   */
  const { testPrompt } = useContext(PromptContext);
  const { setFieldValue } = useContext(FormContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { unregisterPrompt } = useContext(NavigationContext);

  const [loading, setLoading] = useState(false);

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Změna provozovatele provozovny IRZ',
      dialogText:
        'Skutečně chcete odeslat žádost o změnu provozovatele provozovny IRZ?',
    },
  ]);

  /**
   * Fetch reference.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          const {
            facility,
            keepOriginalName,
            newName,
            newOperator,
            note,
            subject,
            date,
          } = allValues;

          const requestBody: IrzFacilityRequestOperatorChange = {
            irzFacilityId: facility?.id,
            name: keepOriginalName ? undefined : newName,
            date,
            note,
            operatorId: newOperator?.id,
            subjectId: subject?.id,
          };

          fetch.current = callApi(requestBody);

          await fetch.current.raw();

          setFieldValue('isSent', true);
          setLoading(false);
          showSnackbar(
            ...Messages.IrzFacilityRequest.REQUEST_OPERATOR_CHANGE_SUBMIT
              .SUCCESS
          );
          unregisterPrompt(navigationPrompt);
          handleNext();
        } catch (err) {
          if (err.name !== 'AbortError') {
            setLoading(false);
            const error:
              | 'OPERATOR_IDENTIFIER_DOES_NOT_MATCH'
              | 'COORDINATES_NOT_UNIQUE'
              | 'FACILITY_NOT_FOUND'
              | 'ERROR'
              | 'FUTURE_OPERATION_PRESENT' = err?.code ?? 'ERROR';
            showSnackbar(
              ...Messages.IrzFacilityRequest.REQUEST_OPERATOR_CHANGE_SUBMIT[
                error
              ]
            );
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Odeslat žádost
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} disabled={loading} />
      </div>
    </div>
  );
}

import { OvzEmissionsTradingSystem } from './ovz-emissions-trading-system';
import { AuthoredObject } from './authored-object';
import { Address } from './address';
import { SubjectUnion } from './subject';
import { CadastralTerritory } from './cadastral-territory';
import { ExtendedCompetenceMunicipality } from './extended-competence-municipality';
import { DictionaryTerritorialTechnicalUnit } from './dict-territorial-technical-unit';
import { Wgs84Coordinates } from './coordinate';
import { DomainObjectAutocomplete } from './domain-object';
import { IrzFacility } from '.';
import { OvzFacilityIntegratedPermission } from './ovz-facility-integrated-permission';

/**
 * Provozovna OVZ
 */
export interface OvzFacility extends AuthoredObject {
  /**
   * Název provozovny
   */
  name?: string;

  /**
   * Adresa provozovny (výber z RÚIAN)
   */
  address?: Address;

  /**
   * Katastrální území (KÚ)
   */
  cadastralTerritory?: CadastralTerritory;

  /**
   * Druh číslování parcel (povinné, pokud není vyplněno adresní místo)
   */
  landPlotType?: LandPlotType;

  /**
   * Kmenové číslo parcely
   */
  primaryLandPlotNumber?: number;

  /**
   * Pořadové číslo parcely
   */
  secondaryLandPlotNumber?: number;

  /**
   * IČP (identifikační číslo provozovny)
   */
  facilityId?: string;

  /**
   * Poloha WGS-84
   */
  wgs84Coordinates?: Wgs84Coordinates;

  /**
   * Původní IČP (identifikační číslo provozovny)
   */
  originalFacilityId?: string;

  /**
   * Identifikátor systému obchodování s emisemi
   */
  emissionsTradingSystem?: OvzEmissionsTradingSystem;

  /**
   * ÚTJ (územní technická jednotka)
   */
  territorialTechnicalUnit?: DictionaryTerritorialTechnicalUnit;

  /**
   * Příznak, jestli se areál nachází na více ÚTJ
   */
  multipleTTU?: boolean;

  /**
   * Obec s rozšířenou působností (ORP)
   */
  extendedCompetenceMunicipality?: ExtendedCompetenceMunicipality;

  /**
   * Integrované povolení provozovny
   */
  integratedPermission?: OvzFacilityIntegratedPermission;

  /**
   * IČP (identifikační číslo provozovny) provozovny IRZ
   */
  irzFacility?: IrzFacility;

  /**
   * Provozovatel
   */
  operator?: SubjectUnion;

  /**
   * Datum registrace
   */
  registrationDate?: string;

  /**
   * Datum platnosti
   */
  validTo?: string;

  /**
   * Platnost
   */
  active?: boolean;

  /**
   * Poznámky
   */
  note?: string;

  /**
   * Popisek
   */
  label?: string;

  /**
   * Vzdálenost od středu ÚTJ
   */
  distanceFromTerritorialTechnicalUnit?: number;
}

export enum LandPlotType {
  /**
   * Pozemková
   */
  ESTATE = 'ESTATE',

  /**
   * Stavební
   */
  CONSTRUCTION = 'CONSTRUCTION',
}

export type OvzFacilityAutocomplete = DomainObjectAutocomplete & {
  /**
   * Popisek
   */
  label?: string;
};

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { SoapExecutionLog } from '../../models';
import { useSoapExecutionStatuses } from './soap-execution-logs-api';
import {
  useWebServices,
  useWebOperations,
} from '../web-service/web-services-api';

export function useColumns(): TableColumn<SoapExecutionLog>[] {
  return [
    {
      datakey: 'service',
      sortkey: 'service.name',
      filterkey: 'service.id',
      name: 'Název služby',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useWebServices),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useWebServices
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'localPart',
      name: 'Operace',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useWebOperations),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useWebOperations
      ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'version',
      name: 'Verze služby (rozhraní)',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'namespace',
      name: 'Jmenný prostor',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'status',
      name: 'Výsledek operace',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useSoapExecutionStatuses
      ),
      valueMapper: TableCells.useSelectCellFactory(useSoapExecutionStatuses),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'duration',
      name: 'Trvání operace (ms)',
      width: 200,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'createdBy.name',
      name: 'Vykonavatel operace',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
  ];
}

import * as Yup from 'yup';
import {
  DictionaryNace,
  DictionaryNaceCz,
  DictionaryRiverBasinCzDistrict,
  DictionaryRiverBasinEuDistrict,
} from '../../../models';
import {
  StepIdentificatorsForm,
  IdentificatorsData,
} from './step-identificators-types';

export function stepIdentificatorsSchema() {
  return Yup.object<StepIdentificatorsForm>().shape({
    identificators: Yup.object<IdentificatorsData>().shape({
      nace: Yup.mixed<DictionaryNace>().nullable().required('Povinné pole'),
      czNace: Yup.mixed<DictionaryNaceCz>().nullable().required('Povinné pole'),
      czRiverBasinDistrict: Yup.mixed<DictionaryRiverBasinCzDistrict>()
        .nullable()
        .required('Povinné pole'),
      euRiverBasinDistrict: Yup.mixed<DictionaryRiverBasinEuDistrict>()
        .nullable()
        .required('Povinné pole'),

      integratedPermission: Yup.object().shape({
        ippcPids: Yup.array()
          .of<string>(
            Yup.mixed<string>().test('', '', function (val) {
              const { createError, parent } = this;

              const isInvalid = !val || !/^MZP[A-Z0-9]{9}$/.test(val);

              if (isInvalid) {
                return createError({
                  path: 'identificators.integratedPermission.ippcPids',
                  message: `Musí začínat písmeny MZP a následovat 9 velkých alfanumerických znaků.`,
                });
              }

              const duplicates = (parent as string[]).filter(
                (item, index) => parent.indexOf(item) !== index
              );

              if (duplicates.length > 0) {
                return createError({
                  path: 'identificators.integratedPermission.ippcPids',
                  message: `Každý PID je možné vyplnit pouze jednou ${JSON.stringify(
                    duplicates
                  )}`,
                });
              }

              return true;
            })
          )
          .test('', '', function (val) {
            const { createError, parent } = this;
            const { hasPermission } = parent;

            const isInvalid = hasPermission && (!val || val.length < 1);

            if (isInvalid) {
              return createError({
                path: 'identificators.integratedPermission.ippcPids',
                message:
                  'Pokud má provozovna integrované povolení, je nutné přidat alespoň 1 PID',
              });
            }
            return true;
          }),
      }),
    }),
  });
}

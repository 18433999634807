import React from 'react';
import { Process } from '../../components/process/process';
import { useBoilerPermissions } from './boiler-permission-new-hook';
import { ProcessForm } from './boiler-permission-new-types';
import { TranslationKeys } from '../../enums';

export function BoilerPermissionNew() {
  const { steps } = useBoilerPermissions();

  return (
    <Process<ProcessForm>
      translationKey={TranslationKeys.OZO_PERMISSION_NEW}
      steps={steps}
      title="Oprávnění OZO"
    />
  );
}

import {
  useEventCallback,
  NavigationContext,
  UserContext,
} from '@eas/common-web';
import { useContext } from 'react';
import { EvidenceUrl, Permission } from '../../../enums';

export function useRedirect() {
  /**
   * Context stuff.
   */
  const { navigate } = useContext(NavigationContext);
  const { hasPermission } = useContext(UserContext);

  const handleNewRequest = useEventCallback(() => {
    navigate(EvidenceUrl.EMPOWERMENT_REQUESTS_NEW);
  });

  const newRequestButtonVisible =
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_ALL) ||
    hasPermission(Permission.Empowerment.EMPOWERMENT_CREATE) ||
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_OWN);

  const handleShowEmpowerments = useEventCallback(() => {
    navigate(EvidenceUrl.EMPOWERMENTS);
  });

  const showRequestsButtonVisible =
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_LIST_ALL) ||
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_LIST_OWN);

  return {
    handleNewRequest,
    newRequestButtonVisible,
    handleShowEmpowerments,
    showRequestsButtonVisible,
  };
}

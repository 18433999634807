import React, { useRef, useContext } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  abortableFetch,
  PromptContext,
  usePrompts,
  FormFileField,
  FormTextArea,
  FileRef,
  DetailMode,
  DetailHandle,
} from '@eas/common-web';
import { Me } from '../../../../models';
import { Messages, EvidenceAPI, Permission } from '../../../../enums';
import { unstable_batchedUpdates } from 'react-dom';
import { SystemCertificate } from '../../../../models/system-certificate';
import { System } from '../../../../models';

const PROMPT_KEY = 'ADD_CERTIFICATE';

function callApi(data: SystemCertificate, system: System) {
  return abortableFetch(`${EvidenceAPI.SYSTEMS}/${system.id}/certificate`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify({
      description: data.description,
      certificate: data.certificate,
    }),
  });
}

export function useAddSystemCertificateDialog() {
  const { source, mode } = useContext<DetailHandle<System>>(DetailContext);

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const { showSnackbar } = useContext(SnackbarContext);

  const { testPrompt } = useContext(PromptContext);

  const fetch = useRef<AbortableFetch | null>(null);

  const canAddCertificate =
    hasPermission(Permission.System.SYSTEM_CERTIFICATE_CREATE) &&
    mode === DetailMode.VIEW &&
    source.data?.active;

  const handleAddCertificate = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: SystemCertificate) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(values, source.data!);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.System.ADD_CERTIFICATE.SUCCESS);
            source.setLoading(false);
          });

          await source.refresh();
        } catch (e) {
          source.setLoading(false);

          if (e.name !== 'AbortError') {
            switch (e.status) {
              case 400:
                showSnackbar(
                  ...Messages.System.ADD_CERTIFICATE.INVALID_CERTIFICATE
                );
                break;
              case 404:
                showSnackbar(
                  ...Messages.System.ADD_CERTIFICATE.SYSTEM_NOT_FOUND
                );
                break;
              case 409:
                showSnackbar(
                  ...Messages.System.ADD_CERTIFICATE.DUPLICATE_ERROR
                );
                break;
              default:
                showSnackbar(...Messages.System.ADD_CERTIFICATE.ERROR);
                break;
            }
            throw e;
          }
          return undefined;
        }
      },
    });
  });

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogWidth: 600,
      dialogTitle: 'Přidání systémového certifikátu',
      dialogText: 'Nahrajte certifikát pro přístup externích systémů',
      FormFields: function Body() {
        return (
          <>
            <FormFileField
              name="certificate"
              label="Certifikát"
              helpLabel="Povinné pole, povolené formáty .cer, .crt, .der"
              accept={['.cer', '.crt', '.der']}
            />
            <FormTextArea name="description" label="Popis" />
          </>
        );
        [];
      },
      formValidationSchema: Yup.object().shape({
        certificate: Yup.mixed<FileRef>().nullable().required('Povinné pole'),
      }),
    },
  ]);

  return {
    canAddCertificate,
    handleAddCertificate,
  };
}

import { SimpleLog } from '../../../../models';
import { TableCells, TableColumn } from '@eas/common-web';
import { useHistoryDescriptors } from '../../../user/users-history';
import { historyContentCell } from '../../../../components/evidence/history-evidence/history-fields';

export function useColumns(): TableColumn<SimpleLog>[] {
  const descriptors = useHistoryDescriptors();

  return [
    {
      name: 'Datum',
      datakey: 'created',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
    },
    {
      name: 'Autor',
      datakey: 'createdBy.name',
      width: 150,
      CellComponent: TableCells.TextCell,
    },
    {
      name: 'Typ změny',
      datakey: 'content.key',
      width: 250,
      CellComponent: TableCells.TextCell,
      valueMapper: ({ value }) =>
        descriptors.find((d) => d.key === value)?.label || value,
    },
    {
      name: 'Změna',
      datakey: 'content',
      width: 250,
      CellComponent: ({ value }) => historyContentCell(descriptors, value),
    },
    {
      name: 'Poznámka',
      datakey: 'note',
      width: 250,
      CellComponent: TableCells.TextCell,
    },
  ];
}

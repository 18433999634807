import React from 'react';
import { FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../../../components/help/help-label';

export function ForeignLegalEntityBasicFields({
  prefix,
  codePrefix = 'SUBJECT',
  disabled,
  hideUid = false,
}: {
  prefix?: string;
  codePrefix?: string;
  disabled?: boolean;
  /**
   * UID is hidden in registration, because UID is generated AFTER approved registration
   */
  hideUid?: boolean;
}) {
  const names = {
    artificialId: prefix ? `${prefix}.artificialId` : 'artificialId',
    foreignId: prefix ? `${prefix}.foreignId` : 'foreignId',
    name: prefix ? `${prefix}.name` : 'name',
  };

  return (
    <>
      {!hideUid && (
        <FormTextField
          name={names.artificialId}
          label={<HelpLabel label="UID" code={`${codePrefix}_UID`} />} // Formát: ID_xxxxxx
          disabled
        />
      )}
      <FormTextField
        name={names.name}
        label={<HelpLabel label="Název" code={`${codePrefix}_NAME`} required />} // Povinné pole
        disabled={disabled}
      />
    </>
  );
}

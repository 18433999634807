import React from 'react';
import { Process } from '../../components/process/process';
import { TranslationKeys } from '../../enums';
import { useRoleRequests } from './role-requests-hook';
import { ProcessForm } from './role-requests-type';

export function RoleRequestsNew() {
  const { steps } = useRoleRequests();

  return (
    <Process<ProcessForm>
      steps={steps}
      title="Žádost o roli"
      translationKey={TranslationKeys.ROLE_REQUEST}
    />
  );
}

import {
  DictionaryAutocomplete,
  useListSource,
  useFetch,
} from '@eas/common-web';
import { NotificationEvent, NotificationOption } from '../../models';
import { EvidenceAPI } from '../../enums';

export function useNotificationEvents() {
  return useListSource<NotificationEvent>({
    method: 'GET',
    url: EvidenceAPI.NOTIFICATION_EVENTS + '/list',
    apiUrl: EvidenceAPI.NOTIFICATION_EVENTS,
  });
}

export function useNotificationEventOptions() {
  return useFetch<NotificationOption>(
    EvidenceAPI.NOTIFICATION_EVENTS + '/options',
    {
      method: 'GET',
    }
  );
}

export function useNotificationTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.NOTIFICATIONS + '/type/list',
    method: 'GET',
  });
}

export function useNotificationTemplateTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.NOTIFICATION_TEMPLATES + '/type/list',
    method: 'GET',
  });
}

import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import Divider from '@material-ui/core/Divider/Divider';
import IconButton from '@material-ui/core/IconButton';
import { DialogHandle, Dialog } from '@eas/common-web';
import {
  initialBreadcrumbs,
  BreadcrumbCtx,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { PublicBox } from '../../components/form/public-box/public-box';
import { IdentityProviderContext } from '../../components/identity-provider/identity-provider-context';
import { AuthContext } from '../../components/auth/auth-context';
import { PageKey, IdentityProvider } from '../../enums';
import mojeIdLogo from './mojeId.png';
import bankIdLogo from './bankId.png';
import egovernmentLogo from './eGov.png';
import niaIdLogo from './niaId.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 18,
      paddingRight: 45,
    },
    buttonText: {
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
      color: 'white',
      fontWeight: 600,
    },
    dividerWrapper: {
      marginTop: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginBottom: 32,
    },
    divider: {
      width: 300,
      flexShrink: 1,
    },
    dividerText: {
      textAlign: 'center',
      padding: '0 16px',
      whiteSpace: 'nowrap',
      color: theme.palette.grey[600],
    },
    imageWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    image: {
      height: 42,
    },
    roundedImage: {
      borderRadius: 8,
      height: 42,
    },
    container: {
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: 10,
    },
    hintSubtitle: {
      fontWeight: 600,
    },
    hintDescription: {
      display: 'flex',
      flexDirection: 'row',
      gap: '15px',
      alignItems: 'center',
      marginBottom: 5,

      '&:not(:first-of-type)': {
        marginTop: 30,
      },
    },
  })
);

export function Registration() {
  const classes = useStyles();

  const { push } = useHistory();
  const { setBreadcrumbs } = useContext(BreadcrumbCtx);
  const { redirectUrl } = useContext(IdentityProviderContext);
  const { baseEnviiamUrl } = useContext(AuthContext);

  const hintRef = useRef<DialogHandle>(null);

  /**
   * Internal state of selected way of registration.
   */
  const [selectedRegistrationType, selectRegistrationType] = useState<
    IdentityProvider | 'standard' | undefined
  >(IdentityProvider.NIA);

  useEffect(() => {
    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Typ registrace',
        key: PageKey.REGISTRATION,
        to: '/registrace',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinue = () => {
    if (selectedRegistrationType) {
      const registrationURL = '/registrace/udaje';
      if (selectedRegistrationType === 'standard') {
        push(registrationURL);
      } else {
        window.location.replace(
          redirectUrl(
            selectedRegistrationType,
            registrationURL,
            baseEnviiamUrl!
          )
        );
      }
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item md={8} xs={10}>
          <Typography variant="body1">
            Abychom mohli vaši žádost o registraci do CRŽP zpracovat online,
            musíme si být jisti tím, kdo žádost podal.
          </Typography>
          <Typography variant="body1">
            Proto je pro vstup do systému nutné přihlášení prostřednictvím jedné
            z nabízených možností.
          </Typography>
        </Grid>
      </Grid>
      {/* Boxes */}
      <PublicBox
        title="Registrace pomocí Identity občana"
        description="Typ registrace určený pro všechny uživatele. Při registraci využijete svého aktuálního účtu Identity občana (např. Bankovní identita, MojeID, eObčanka, Mobilní klíč eGovernmentu atp.) a pod tímto účtem se budete moci nově přihlašovat i do systému CRŽP a dalších resortních informačních systémů (např. ISPOP)."
        checked={selectedRegistrationType === IdentityProvider.NIA}
        onChange={selectRegistrationType}
        value={IdentityProvider.NIA}
        After={() => (
          <IconButton onClick={() => hintRef.current?.open()}>
            <HelpOutlinedIcon color="primary" />
          </IconButton>
        )}
      />
      <Dialog
        ref={hintRef}
        title="Identita občana"
        showConfirm={false}
        closeLabel="Zavřít"
      >
        {() => <IdentitaObcanaHint />}
      </Dialog>
      <div className={classes.dividerWrapper}>
        <Divider className={classes.divider} />
        <Typography variant="body1" className={classes.dividerText}>
          Alternativní možnosti registrace
        </Typography>
        <Divider className={classes.divider} />
      </div>
      <div>
        <PublicBox
          title="Registrace pomocí JIP/KAAS"
          description="Typ registrace určený pro pracovníky státní správy. Při registraci využijete svého aktuálního účtu JIP/KAAS a pod tímto účtem se budete moci nově přihlašovat i do systému CRŽP a dalších resortních informačních systémů (např. ISPOP)."
          checked={selectedRegistrationType === IdentityProvider.JIP_KAAS}
          onChange={selectRegistrationType}
          value={IdentityProvider.JIP_KAAS}
          size="small"
        />
        <PublicBox
          title="Registrace jménem a heslem"
          description="Preferovaná varianta pro zahraniční subjekty případně pokud nelze z technických důvodů použít registraci výše uvedenými způsoby. Jedná se o vytvoření uživatelského účtu pouze pro potřeby CRŽP a napojených systémů."
          checked={selectedRegistrationType === 'standard'}
          onChange={selectRegistrationType}
          value="standard"
          size="small"
        />
      </div>
      {/* Actions */}
      <Grid container>
        <Grid item md={2} xs={1} />
        <Grid className={classes.actions} item md={8} xs={10}>
          <Button
            disabled={!selectedRegistrationType}
            component="span"
            variant="contained"
            disableElevation
            color="primary"
          >
            <Typography
              component="span"
              variant="body1"
              className={classes.buttonText}
              onClick={handleContinue}
            >
              Pokračovat
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

const IdentitaObcanaHint = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.hintDescription}>
        <div className={classes.imageWrapper}>
          <img
            src={bankIdLogo}
            alt="Bankovní ideantita"
            className={classes.roundedImage}
          />
        </div>
        <Typography variant="subtitle1" className={classes.hintSubtitle}>
          Bankovní identita
        </Typography>
      </div>
      <Typography>
        Bankovní identitu má v České republice přes 5 000 000 lidí (jen o tom
        spousta z nich neví), pravděpodobně ji tak budete mít i vy. Pomocí
        přihlašovacích údajů do vašeho internetového bankovnictví se jednoduše
        přihlásíte. Pokud potřebujete více informací ohledně BankID, najdete je
        na{' '}
        <a href="https://bankid.cz" rel="noreferrer" target="_blank">
          bankid.cz
        </a>
        .
      </Typography>
      <div className={classes.hintDescription}>
        <div className={classes.imageWrapper}>
          <img
            src={mojeIdLogo}
            alt="Bank ID"
            className={classes.roundedImage}
          />
        </div>
        <Typography variant="subtitle1" className={classes.hintSubtitle}>
          MojeID
        </Typography>
      </div>
      <Typography>
        Prostřednictvím služby MojeID s bezpečnostním klíčem a ověřenou
        totožností můžete využívat elektronické služby státní správy a
        samosprávy. Jedná se o jednu z nabízených možností v rámci tzv. Identity
        občana. Účet do služby je možné vytvořit na{' '}
        <a
          href="https://www.mojeid.cz/cs/zalozit-ucet/"
          rel="noreferrer"
          target="_blank"
        >
          webu MojeID
        </a>
        . Více o službách MojeID v kontextu služeb veřejné správy se lze dočíst{' '}
        <a
          href="https://www.mojeid.cz/cs/verejna-sprava/"
          rel="noreferrer"
          target="_blank"
        >
          zde
        </a>
        .
      </Typography>
      <div className={classes.hintDescription}>
        <div className={classes.imageWrapper}>
          <img src={niaIdLogo} alt="NIA ID" className={classes.image} />
        </div>
        <Typography variant="subtitle1" className={classes.hintSubtitle}>
          NIA ID
        </Typography>
      </div>
      <Typography>
        Jde o identifikační prostředek s dvoufaktorovým způsobem ověření.
        Vlastník NIA ID zadává na přihlašovací obrazovce k online službám jméno
        a heslo. Následně mu na jeho mobilní telefon přijde SMS s kódem, který
        je potřeba zadat při přihlášení k dané službě. Více informací na stránce
        o NIA ID, kde najdete přesný popis{' '}
        <a
          href="https://info.identitaobcana.cz/ups/"
          rel="noreferrer"
          target="_blank"
        >
          jak NIA ID vytvořit a aktivovat
        </a>
        .
      </Typography>
      <div className={classes.hintDescription}>
        <div className={classes.imageWrapper}>
          <img
            src={egovernmentLogo}
            alt="Mobilní klíč eGovernmentu"
            className={classes.image}
          />
        </div>
        <Typography variant="subtitle1" className={classes.hintSubtitle}>
          Mobilní klíč eGovernmentu
        </Typography>
      </div>
      <Typography>
        V rámci Identity občana můžete využít Mobilní klíč eGovernmentu. Do
        vašeho mobilu si jednoduše stáhnete aplikaci a následně si na kterémkoli
        Czech Pointu aktivujete možnost přihlášení. Vše je přehledně vysvětleno
        v{' '}
        <a
          href="https://www.youtube.com/watch?v=m-k3-M6tymc"
          rel="noreferrer"
          target="_blank"
        >
          tomto videu
        </a>
        , případně v{' '}
        <a
          href="https://info.identitaobcana.cz/mep/VznikCzP.aspx"
          rel="noreferrer"
          target="_blank"
        >
          tomto návodu
        </a>
        .
      </Typography>
      <div className={classes.hintDescription}>
        <div className={classes.imageWrapper}>
          <img src={egovernmentLogo} alt="Moje ID" className={classes.image} />
        </div>
        <Typography variant="subtitle1" className={classes.hintSubtitle}>
          eObčanka
        </Typography>
      </div>
      <Typography>
        Přihlášení prostřednictvím nového občanského průkazu vydaného po 1. 7.
        2018, který obsahuje čip a jeho elektronická funkcionalita byla
        aktivována. Pro přihlášení tímto občanským průkazem je zapotřebí čtečka
        dokladů a nainstalovaný příslušný software. Více informací o
        přihlašování prostřednictvím občanského průkazu jsme pro Vás připravili
        na našich informačních stránkách.
      </Typography>
    </div>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { formFieldFactory, FormSubmitButton, Form } from '@eas/common-web';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import {
  publicFieldFactory,
  EmptyWrapper,
} from '../../components/form/public-field/public-field';
import { CaptchaWrapper } from '../../components/captcha/captcha-wrapper';
import {
  BreadcrumbCtx,
  initialBreadcrumbs,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { PageKey } from '../../enums';
import { usePasswordReset } from './hook/password-reset-hook';
import Grid from '@material-ui/core/Grid';
import { PasswordResetData } from './auth-types';

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      marginBottom: 50,
    },
    buttonText: {
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
      color: 'white',
      fontWeight: 600,
    },
    input: {
      fontSize: 22,
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  })
);

const initialValues: PasswordResetData = {
  username: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
};

const UsernameField = formFieldFactory(
  publicFieldFactory({
    label: 'Uživatelské jméno *',
  }),
  EmptyWrapper
);

const FirstNameField = formFieldFactory(
  publicFieldFactory({
    label: 'Jméno *',
  }),
  EmptyWrapper
);

const LastNameField = formFieldFactory(
  publicFieldFactory({
    label: 'Příjmení *',
  }),
  EmptyWrapper
);

const EmailField = formFieldFactory(
  publicFieldFactory({
    label: 'E-mail *',
  }),
  EmptyWrapper
);

export function PasswordReset() {
  return (
    <CaptchaWrapper>
      <PasswordResetWithoutCaptcha />
    </CaptchaWrapper>
  );
}

enum PasswordResetVariant {
  USERNAME,
  PERSONAL_DATA,
}

function PasswordResetWithoutCaptcha() {
  const classes = useStyles();

  const { setBreadcrumbs } = useContext(BreadcrumbCtx);

  const [passwordResetVariant, setPasswordResetVariant] = useState<
    PasswordResetVariant
  >(PasswordResetVariant.PERSONAL_DATA);

  const {
    validationSchema,
    ref,
    loading,
    handleSubmit,
    getFieldError,
    setErrors,
  } = usePasswordReset();

  useEffect(() => {
    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Vytvoření nového hesla',
        key: PageKey.PASSWORD_RESET,
        to: '/obnova-hesla',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVariantChange = (newValue: PasswordResetVariant) => {
    setPasswordResetVariant(newValue);
    ref.current?.clearForm();
    setErrors([]);
  };

  return (
    <>
      <Form<PasswordResetData>
        ref={ref}
        editing={true}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={5}>
          {passwordResetVariant === PasswordResetVariant.USERNAME && (
            <>
              <Grid item md={4} xs={1} />
              <Grid item md={4} xs={10}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  align="center"
                >
                  Zadejte uživatelské jméno. <br />
                  Nebo zažádejte o nové heslo pomocí{' '}
                  <span
                    className={classes.link}
                    onClick={() =>
                      handleVariantChange(PasswordResetVariant.PERSONAL_DATA)
                    }
                  >
                    osobních údajů.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={4} xs={1} />

              <Grid item md={4} xs={1} />
              <Grid item md={4} xs={10}>
                <UsernameField
                  name="username"
                  error={getFieldError('username') ? true : false}
                  helperText={getFieldError('username')}
                />
              </Grid>
              <Grid item md={4} xs={1} />
            </>
          )}
          {passwordResetVariant === PasswordResetVariant.PERSONAL_DATA && (
            <>
              <Grid item md={4} xs={1} />
              <Grid item md={4} xs={10}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  align="center"
                >
                  Zadejte osobní údaje. <br />
                  Nebo zažádejte o nové heslo pomocí{' '}
                  <span
                    className={classes.link}
                    onClick={() =>
                      handleVariantChange(PasswordResetVariant.USERNAME)
                    }
                  >
                    uživatelského jména.
                  </span>
                </Typography>
              </Grid>
              <Grid item md={4} xs={1} />

              <Grid item md={4} xs={1} />
              <Grid item md={4} xs={10}>
                <FirstNameField
                  name="firstName"
                  error={getFieldError('firstName') ? true : false}
                  helperText={getFieldError('firstName')}
                />
              </Grid>
              <Grid item md={4} xs={1} />

              <Grid item md={4} xs={1} />
              <Grid item md={4} xs={10}>
                <LastNameField
                  name="lastName"
                  error={getFieldError('lastName') ? true : false}
                  helperText={getFieldError('lastName')}
                />
              </Grid>
              <Grid item md={4} xs={1} />

              <Grid item md={4} xs={1} />
              <Grid item md={4} xs={10}>
                <EmailField
                  name="email"
                  error={getFieldError('email') ? true : false}
                  helperText={getFieldError('email')}
                />
              </Grid>
              <Grid item md={4} xs={1} />
            </>
          )}
          <Grid item md={4} xs={1} />
          <Grid item md={4} xs={10}>
            <Typography align="center">
              <FormSubmitButton
                variant="contained"
                disableElevation
                disabled={loading}
                color="primary"
                type="submit"
              >
                <Typography
                  component="span"
                  variant="body1"
                  className={classes.buttonText}
                >
                  Pokračovat
                </Typography>
              </FormSubmitButton>
            </Typography>
          </Grid>
          <Grid item md={4} xs={1} />
        </Grid>
      </Form>
    </>
  );
}

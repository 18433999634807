import React, { useEffect, useContext } from 'react';
import { FormikProps } from 'formik';
import {
  FormPanel,
  UserContext,
  FormFileField,
  FilesProvider,
} from '@eas/common-web';
import { SubjectAutocomplete } from '../../../composite/subject-fields/subject-autocomplete';
import { HelpLabel } from '../../../components/help/help-label';
import { StepAuthorForm } from './step-author-types';
import { useSubjectSource } from '../../subject/subjects-api';
import { Permission } from '../../../enums';
import { SubjectType } from '../../../models';

export function StepAuthorContent(props: FormikProps<StepAuthorForm>) {
  const { hasPermission } = useContext(UserContext);

  const onwOnly = !hasPermission(
    Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_ALL
  );

  const subjectSource = useSubjectSource([], onwOnly);

  useEffect(() => {
    // Select the only available value
    if (subjectSource.items.length === 1) {
      props.setFieldValue('author', subjectSource.items[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectSource.items]);

  return (
    <FormPanel label="Vyberte subjekt">
      <SubjectAutocomplete
        name="author"
        label="Subjekt" // Povinné pole
        codePrefix="EMPOWERMENT_REQUEST_NEW"
        required
        ownOnly={onwOnly}
      />
      {props.values.author?.type === SubjectType.FOREIGN_LEGAL_ENTITY && (
        <FilesProvider url="/api/crzp/archived/file">
          <FormFileField
            name="certificateOfIncorporation"
            label={
              <HelpLabel
                label="Příloha (výpis z obchodního rejstříku)"
                code="EMPOWERMENT_REQUEST_NEW_CERTIFICATE_OF_INCORPORATION"
              />
            }
          />
        </FilesProvider>
      )}
    </FormPanel>
  );
}

import React from 'react';
import { stubFalse } from 'lodash';
import { Evidence2, EmptyComponent } from '@eas/common-web';
import { DictInfosFields } from './dict-infos-fields';
import { useColumns } from './dict-infos-columns';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { DictionaryInfo } from '../../models/dictionary-info';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { DictInfoTableToolbar } from './dict-info-table-toolbar';

export function DictInfos() {
  const evidence = useDictionaryEvidence<DictionaryInfo>({
    identifier: PageKey.DICTIONARY_INFOS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_INFOS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled číselníků',
      reportTag: ExportTags.DICTIONARY_INFOS,
      defaultSorts: [],
      toolbarProps: {
        after: <DictInfoTableToolbar />,
      },
    },
    detailProps: {
      entityName: EntityName.INFO,
      codePrefix: 'INFO',
      FieldsComponent: DictInfosFields,
      toolbarProps: {
        title: 'Číselník',
        showBtn: stubFalse,
        ActionBar: EmptyComponent,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import * as Yup from 'yup';
import { PublicList, PublicListAccessType } from '../../models/public-list';
import { Role } from '../../models';

export function useValidationSchema() {
  return Yup.object<PublicList>().shape({
    allowedRoles: Yup.array<Role>().test('', '', function (roles) {
      const { createError } = this;
      const parent: PublicList = this.parent;

      if (parent.accessType === PublicListAccessType.FOR_ROLE) {
        if (!roles?.length) {
          return createError({
            message: 'Přidejte alespoň 1 roli',
          });
        }
      }

      return true;
    }),
  });
}

import React from 'react';
import {
  FormTextField,
  FormNumberField,
  FormCheckbox,
  FormDateTimeField,
  FormPanel,
} from '@eas/common-web';
import { HelpLabel } from '../../help/help-label';

const CODE = 'RUIAN_BASE';

export function GeneralRuianFields() {
  return (
    <>
      <FormTextField
        name="name"
        label={<HelpLabel label="Název" code={`DICT_${CODE}_NAME`} />}
      />
      <FormNumberField
        name="isuiCode"
        label={<HelpLabel label="Kód v ISÚI" code={`DICT_${CODE}_CODE`} />}
      />
      <FormCheckbox
        name="active"
        label={<HelpLabel label="Aktivní" code={`DICT_${CODE}_ACTIVE`} />}
        disabled
      />
      <FormDateTimeField
        name="validFrom"
        label={<HelpLabel label="Platný od" code={`DICT_${CODE}_VALID_FROM`} />}
      />
      <FormDateTimeField
        name="validTo"
        label={<HelpLabel label="Platný do" code={`DICT_${CODE}_VALID_TO`} />}
      />
    </>
  );
}

export function MunicipalityFormPanel() {
  return (
    <FormPanel label="Obec">
      <FormTextField
        name="municipality.name"
        label={
          <HelpLabel label="Název" code={`DICT_${CODE}_MUNICIPALITY_NAME`} />
        }
      />
      <FormTextField
        name="municipality.isuiCode"
        label={
          <HelpLabel
            label="Kód v ISÚI"
            code={`DICT_${CODE}_MUNICIPALITY_CODE`}
          />
        }
      />
      <FormCheckbox
        name="municipality.active"
        label={
          <HelpLabel
            label="Aktivní"
            code={`DICT_${CODE}_MUNICIPALITY_ACTIVE`}
          />
        }
      />
      <FormDateTimeField
        name="municipality.created"
        label={
          <HelpLabel
            label="Vytvoření"
            code={`DICT_${CODE}_MUNICIPALITY_CREATED`}
          />
        }
      />
    </FormPanel>
  );
}

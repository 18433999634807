import React from 'react';
import {
  FormPanel,
  FormSelect,
  FormDateTimeField,
  FormAutocomplete,
  DictionaryAutocomplete,
  FormTextField,
} from '@eas/common-web';

import { HelpLabel } from '../../../components/help/help-label';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useStates, usePublicLists } from './public-list-publishings-api';

export function PublicListPublishingsFields() {
  const states = useStates();
  const lists = usePublicLists();

  return (
    <>
      <FormPanel label="Základní informace">
        <FormAutocomplete<DictionaryAutocomplete>
          name="list"
          label={
            <HelpLabel
              label="Veřejný seznam"
              code="PUBLIC_LIST_PUBLISHINGS_LIST"
            />
          }
          source={lists}
          disabled={true}
          labelMapper={dictionaryLabelMapper}
        />
        <FormSelect
          name="state"
          disabled
          label={<HelpLabel label="Stav" code="PUBLIC_LIST_PUBLISHING_STATE" />}
          source={states}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />

        <FormDateTimeField
          name="startTime"
          label={
            <HelpLabel
              label="Začátek"
              code="PUBLIC_LIST_PUBLISHING_START_TIME"
            />
          }
          disabled={true}
        />
        <FormDateTimeField
          name="endTime"
          label={
            <HelpLabel label="Konec" code="PUBLIC_LIST_PUBLISHING_END_TIME" />
          }
          disabled={true}
        />
        <FormTextField
          name="message"
          label={
            <HelpLabel label="Chyba" code="PUBLIC_LIST_PUBLISHING_MESSAGE" />
          }
          disabled={true}
        />
      </FormPanel>
    </>
  );
}

import React from 'react';
import { ProcessForm } from '../ovz-facility-request-operator-change-types';
import { OvzFacility } from '../../../models';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginTop: 5,
  },
});

export function StepOvzFacilitiesLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const classes = useStyles();

  const facilities = allValues?.ovzFacilities;

  const getFacilityLabel = (facility: OvzFacility) =>
    `${facility.name} (IČP ${facility.facilityId})`;

  if (!facilities) {
    return <>Provozovny</>;
  } else if (facilities.length === 1) {
    const facility = facilities[0];
    return (
      <>
        Provozovna: <b>{getFacilityLabel(facility)}</b>
      </>
    );
  } else {
    return (
      <>
        Provozovny:{' '}
        <div className={classes.wrapper}>
          {facilities.map((facility) => (
            <b key={facility.facilityId}>{getFacilityLabel(facility)}</b>
          ))}
        </div>
      </>
    );
  }
}

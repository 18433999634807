import React, { useContext, useRef, useState } from 'react';
import { noop } from 'lodash';
import * as Yup from 'yup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButtonAction,
  DetailHandle,
  DetailToolbarButtonType,
  FormTextField,
  UserContext,
  FormFileField,
  FileRef,
  useEventCallback,
  SnackbarContext,
  MessageDialog,
  DialogHandle,
  FileField,
  DetailActionbarButton,
} from '@eas/common-web';
import {
  run,
  stop,
  test,
  importCsv,
  clear,
  exportCsv,
  checkEmails,
} from './mail-campaigns-api';
import {
  MailCampaign,
  MailCampaignState,
  MailCampaignTestDto,
  ImportDto,
  ImportResultDto,
  CheckResultDto,
} from '../../models/mail-campaign';
import { Me } from '../../models';
import { Permission, Messages } from '../../enums';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function MailCampaignActionbar() {
  const { showSnackbar } = useContext(SnackbarContext);

  const { mode, source } = useContext<DetailHandle<MailCampaign>>(
    DetailContext
  );
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const state = source.data?.state;
  const showButtons = hasPermission(Permission.Mailing.MAILING_PUBLISH);
  const showCheckButton = hasPermission(Permission.Mailing.MAILING_UPDATE);

  const testSchema = useTestValidationSchema();
  const importSchema = useImportValidationSchema();

  const emailSubject = source.data?.emailSubject;
  const emailContent = source.data?.emailContent;

  let length = 0;
  const campaign = source.data;

  if (campaign != null) {
    if (campaign.forced) {
      length =
        (campaign.recipients?.length ?? 0) +
        (campaign.recipientsNoConsent?.length ?? 0);
    } else {
      length = campaign.recipients?.length ?? 0;
    }
  }

  const readyToPublish =
    emailSubject != null && emailContent != null && length > 0;

  const handleImported = useEventCallback(async (result: ImportResultDto) => {
    if (result.withoutConsent > 0) {
      const snackbar = Messages.MailCampaign.IMPORT.WARNING;
      showSnackbar(`${snackbar[0]} ${+result.withoutConsent}`, snackbar[1]);
    }

    source.refresh();
  });

  const handleImportError = useEventCallback(async () => {
    showSnackbar(...Messages.MailCampaign.IMPORT.ERROR);
  });

  const [exportedFile, setExportedFile] = useState<FileRef | undefined>(
    undefined
  );
  const exportDialog = useRef<DialogHandle>(null);

  const handleExported = useEventCallback(async (result: FileRef) => {
    setExportedFile(result);
    exportDialog.current?.open();
  });

  const handleCheckedEmails = useEventCallback(
    async (result: CheckResultDto) => {
      if (
        result.badCount > 0 ||
        result.notExistCount > 0 ||
        result.noConsentCount > 0
      ) {
        const snackbarText =
          `${Messages.MailCampaign.CHECK_EMAILS.ERROR_BAD[0]} ${result.badCount}\n` +
          `${Messages.MailCampaign.CHECK_EMAILS.ERROR_NOT_EXIST[0]} ${result.notExistCount}\n` +
          `${Messages.MailCampaign.CHECK_EMAILS.ERROR_NO_CONSENT[0]} ${result.noConsentCount}`;

        showSnackbar(
          snackbarText,
          Messages.MailCampaign.CHECK_EMAILS.ERROR_BAD[1]
        );
      } else {
        const snackbar = Messages.MailCampaign.CHECK_EMAILS.SUCCESS;
        showSnackbar(`${snackbar[0]}`, snackbar[1]);
      }

      source.refresh();
    }
  );

  return (
    <>
      {mode === DetailMode.VIEW &&
        showCheckButton &&
        state === MailCampaignState.CONCEPT && (
          <DetailToolbarButtonAction
            ButtonComponent={DetailActionbarButton}
            buttonProps={{
              type: DetailToolbarButtonType.PRIMARY,
            }}
            promptKey="MAIL_CAMPAIGN_CHECK"
            apiCall={checkEmails}
            buttonLabel="Kontrola adres"
            buttonTooltip="Otevře dialog"
            dialogTitle="Varování"
            dialogText="Spustí se kontrola všech emailových adres kampaně. Validní adresy budou uloženy v atributu Příjemci. Nevalidní budou uloženy v atributu Chyba adresy."
            onResult={handleCheckedEmails}
          />
        )}
      {mode === DetailMode.VIEW &&
        showButtons &&
        state === MailCampaignState.CONCEPT && (
          <DetailToolbarButtonAction
            ButtonComponent={DetailActionbarButton}
            buttonProps={{
              type: DetailToolbarButtonType.PRIMARY,
            }}
            buttonDisabled={!readyToPublish}
            promptKey="MAIL_CAMPAIGN_RUN"
            apiCall={run}
            buttonLabel="Spustit"
            buttonTooltip="Otevrě dialog s potvrzením"
            dialogTitle="Varování"
            dialogText={`Aplikace začne odesílat emaily. Jejich počet je ${length}. Skutečně chcete spustit mailovou kampaň ?`}
          />
        )}
      {mode === DetailMode.VIEW &&
        showButtons &&
        state === MailCampaignState.RUNNING && (
          <DetailToolbarButtonAction
            ButtonComponent={DetailActionbarButton}
            buttonProps={{
              type: DetailToolbarButtonType.SECONDARY,
            }}
            promptKey="MAIL_CAMPAIGN_STOP"
            apiCall={stop}
            buttonLabel="Zrušit"
            buttonTooltip="Otevrě dialog s potvrzením"
            dialogTitle="Varování"
            dialogText="Aplikace předčasně ukončí odesílání emailů. Skutečně chcete zrušit mailovou kampaň ?"
          />
        )}
      {mode === DetailMode.VIEW && showButtons && (
        <DetailToolbarButtonAction
          ButtonComponent={DetailActionbarButton}
          buttonProps={{
            type: DetailToolbarButtonType.NORMAL,
          }}
          promptKey="MAIL_CAMPAIGN_TEST"
          apiCall={test}
          buttonDisabled={!readyToPublish}
          formInitialValues={{ email: '' }}
          formValidationSchema={testSchema}
          FormFields={TestFormFields}
          buttonLabel="Test"
          buttonTooltip="Otevrě dialog s potvrzením"
          dialogTitle="Varování"
          dialogText="Aplikace odešle testovací email na uvedenou adresu. Skutečně chcete pokračovat ?"
        />
      )}
      {mode === DetailMode.VIEW &&
        showButtons &&
        state === MailCampaignState.CONCEPT && (
          <DetailToolbarButtonAction
            promptKey="MAIL_CAMPAIGN_IMPORT"
            ButtonComponent={DetailActionbarButton}
            apiCall={importCsv}
            onResult={handleImported}
            onError={handleImportError}
            formInitialValues={{ content: null }}
            formValidationSchema={importSchema}
            FormFields={ImportFields}
            buttonLabel="Import"
            buttonTooltip="Otevrě dialog s importem CSV souboru"
            dialogTitle="Import"
            dialogText="Skutečně chcete importovat seznam kontaktů ?"
          />
        )}
      {mode === DetailMode.VIEW &&
        showButtons &&
        state === MailCampaignState.CONCEPT && (
          <DetailToolbarButtonAction
            promptKey="MAIL_CAMPAIGN_CLEAR"
            ButtonComponent={DetailActionbarButton}
            buttonDisabled={length == 0}
            apiCall={clear}
            buttonLabel="Smazání příjemců"
            buttonTooltip="Otevrě dialog s potvrzením"
            dialogTitle="Smazání příjemců"
            dialogText="Skutečně chcete smazat seznam všech příjemců ?"
          />
        )}
      {mode === DetailMode.VIEW && showButtons && (
        <DetailToolbarButtonAction
          promptKey="MAIL_CAMPAIGN_EXPORT"
          ButtonComponent={DetailActionbarButton}
          apiCall={exportCsv}
          onResult={handleExported}
          buttonLabel="Export"
          buttonTooltip="Otevrě dialog s exportem CSV souboru"
          dialogTitle="Export"
          dialogText="Skutečně chcete exportovat seznam kontaktů ?"
        />
      )}
      <MessageDialog
        ref={exportDialog}
        title="Stažení"
        onClose={noop}
        content={<FileField value={exportedFile} onChange={noop} disabled />}
      />
    </>
  );
}

export function TestFormFields() {
  return (
    <>
      <FormTextField name="email" label="Email" required />
    </>
  );
}

function useTestValidationSchema() {
  return Yup.object<MailCampaignTestDto>().shape({
    email: Yup.string().test('', '', function (val) {
      const { createError } = this;

      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isInvalid = !re.test(String(val).toLowerCase());

      if (isInvalid) {
        return createError({
          path: 'email',
          message: `Email je ve špatném formátu`,
        });
      }

      return true;
    }),
  });
}

export function ImportFields() {
  return (
    <>
      <FormFileField
        name="content"
        label="CSV soubor"
        required
        accept={['csv']}
      />
    </>
  );
}

function useImportValidationSchema() {
  return Yup.object<ImportDto>().required().shape({
    content: Yup.object<FileRef>().nullable().required(),
  });
}

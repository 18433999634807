import {
  identityCardRegex,
  identityCardMessage,
} from './../subject/fields/subjects-utils';
import * as Yup from 'yup';
import {
  EmpowermentRequest,
  Subject,
  SubjectType,
  PersonalData,
  EmpowermentSpecificationRequest,
  DocumentType,
} from '../../models';
import { get } from 'lodash';
import { EmpowermentSpecificationApprovalState } from '../../models';
import { addressFieldSchema } from '../../components/form/address-field/address-field-schema';

/**
 *
 */
type SchemeCtx = {
  values: EmpowermentRequest & { subject: Subject };
};

/**
 *
 * @param ctx
 */
function getYupStuff(
  ctx: Yup.TestContext<SchemeCtx>,
  path: string
): {
  field: string;
  values: SchemeCtx['values'];
  createError: Yup.TestContext['createError'];
} {
  const {
    createError,
    options: { context },
  } = ctx;
  const values = context?.values ?? ({} as SchemeCtx['values']);

  const field: string = get(values, path);

  return {
    field,
    values,
    createError,
  };
}

/**
 *
 */
function requiredSubjectField({
  subjectType,
  path,
}: {
  subjectType: SubjectType[];
  path: string;
}) {
  return Yup.mixed<PersonalData, SchemeCtx>().test('', '', function () {
    const { values, field, createError } = getYupStuff(this, path);

    if (
      !values?.subject?.type ||
      (subjectType.includes(values?.subject?.type) && !field)
    ) {
      return createError({
        path,
        message: 'Povinné pole',
      });
    }

    return true;
  });
}

export function useValidationSchema() {
  return Yup.object<EmpowermentRequest>().shape({
    subject: Yup.mixed().when(['resolveState', '$values.subject.id'], {
      is: (resolveState, subjectId) => {
        return resolveState === 'APPROVE' && !subjectId;
      },
      then: Yup.object<Subject>().shape({
        type: Yup.mixed<SubjectType>().required('Povinné pole'),
        firstName: requiredSubjectField({
          subjectType: [
            SubjectType.NATURAL_PERSON,
            SubjectType.FOREIGN_NATURAL_PERSON,
          ],
          path: 'subject.firstName.value',
        }),
        lastName: requiredSubjectField({
          subjectType: [
            SubjectType.NATURAL_PERSON,
            SubjectType.FOREIGN_NATURAL_PERSON,
          ],
          path: 'subject.lastName.value',
        }),
        name: requiredSubjectField({
          subjectType: [
            SubjectType.LEGAL_ENTITY,
            SubjectType.LEGAL_ENTITY_WITHOUT_ICO,
            SubjectType.FOREIGN_LEGAL_ENTITY,
            SubjectType.BUSINESS_NATURAL_PERSON,
          ],
          path: 'subject.name',
        }),
        identityDocuments: Yup.mixed().when('type', {
          is: SubjectType.NATURAL_PERSON,
          then: identityDocumentSchema,
        }),
        residence: addressFieldSchema(),
        ico: Yup.mixed()
          .when('type', {
            is: (type) => type === SubjectType.BUSINESS_NATURAL_PERSON,
            then: requiredSubjectField({
              subjectType: [SubjectType.BUSINESS_NATURAL_PERSON],
              path: 'subject.ico.value',
            }),
          })
          .when('type', {
            is: (type) => type === SubjectType.LEGAL_ENTITY,
            then: requiredSubjectField({
              subjectType: [SubjectType.LEGAL_ENTITY],
              path: 'subject.ico',
            }),
          }),
      }),
    }),
    specificationRequests: Yup.mixed().test('', '', function (value) {
      const { createError } = this;

      const validSpec = (value as EmpowermentSpecificationRequest[])?.find(
        (spec) =>
          spec.approvalState ===
            EmpowermentSpecificationApprovalState.APPROVED ||
          spec.approvalState === EmpowermentSpecificationApprovalState.REJECTED
      );

      if (!validSpec && value?.length > 0) {
        return createError({
          path: 'specificationRequests',
          message: `Vyberte minimálně 1 specifikaci`,
        });
      }

      return true;
    }),
    note: Yup.mixed().when('resolveState', {
      is: (state) => state === 'REJECT',
      then: Yup.mixed().nullable().required('Povinné pole'),
    }),
  });
}

const identityDocumentSchema = Yup.array()
  .of(
    Yup.mixed().test('', '', function (val) {
      const { createError } = this;

      if (!val?.type) {
        return createError({
          path: 'subject.identityDocuments',
          message: 'Typ je povinná položka dokladu',
        });
      }

      if (!val?.idNumber?.value) {
        return createError({
          path: 'subject.identityDocuments',
          message: 'Číslo je povinná položka dokladu',
        });
      }

      if (
        val?.type === DocumentType.IDENTITY_CARD &&
        val?.idNumber?.value &&
        !identityCardRegex.test(val.idNumber.value)
      ) {
        return createError({
          path: 'subject.identityDocuments',
          message: identityCardMessage,
        });
      }

      return true;
    })
  )
  .required('Povinné pole');

import React, { useContext, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { IdentityProviderContext } from './identity-provider-context';
import { useEventCallback, SnackbarContext } from '@eas/common-web';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Messages } from '../../enums';
import { ProtectedPage } from '../protected-page/protected-page';

export function IdentityProviderAdd() {
  const { handleAddProviderToUser } = useContext(IdentityProviderContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const idpTicket = new URLSearchParams(window.location.search).get('ticket');
  const history = useHistory();

  const processTicket = useEventCallback(async () => {
    if (idpTicket) {
      try {
        await handleAddProviderToUser(idpTicket);
        showSnackbar(...Messages.Profile.ADD_IDP.SUCCESS);
      } catch (err) {
        if (err.code === 'IDENTITY_PROVIDER_EXTERNAL_ID_ALREADY_REGISTERED') {
          showSnackbar(
            ...Messages.Profile.ADD_IDP
              .IDENTITY_PROVIDER_EXTERNAL_ID_ALREADY_REGISTERED
          );
        } else {
          showSnackbar(...Messages.Profile.ADD_IDP.ERROR);
        }
      }
      history.push('/crzp/profil');
    }
  });

  useEffect(() => {
    processTicket();
  }, []);

  return (
    <ProtectedPage>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        margin="20px"
      >
        <CircularProgress />
        <Typography>Přidávaní externího poskytovatele identity</Typography>
      </Box>
    </ProtectedPage>
  );
}

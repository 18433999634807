import React from 'react';
import { useJobs, useRunStates } from '../schedule-api';
import {
  FormPanel,
  FormSelect,
  FormDateTimeField,
  FormTextArea,
} from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';

export function ScheduleRunsFields() {
  const jobs = useJobs();
  const states = useRunStates();

  return (
    <FormPanel label="Běh úlohy">
      <FormSelect
        name="job"
        label={<HelpLabel label="Úloha" code="SCHEDULE_RUNS_JOB" />}
        source={jobs}
        tooltipMapper={(o) => o.name}
      />
      <FormSelect
        name="state"
        label={<HelpLabel label="Stav" code="SCHEDULE_RUNS_STATE" />}
        source={states}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
      />
      <FormDateTimeField
        name="startTime"
        label={<HelpLabel label="Začátek" code="SCHEDULE_RUNS_START_TIME" />}
      />
      <FormDateTimeField
        name="endTime"
        label={<HelpLabel label="Konec" code="SCHEDULE_RUNS_END_TIME" />}
      />
      <FormTextArea
        name="result"
        disabled
        label={<HelpLabel label="Výsledek" code="SCHEDULE_RUNS_RESULT" />}
        // Návratová hodnota ze skriptu (ne nutně musí neco obsahovat)
      />
      <FormTextArea
        name="console"
        label={<HelpLabel label="Konzole" code="SCHEDULE_RUNS_CONSOLE" />}
        // Výpis ladící konzole běhu (ne nutně musí neco obsahovat)
      />
    </FormPanel>
  );
}

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ProcessActionsProps } from './process-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function ExitProcessAction({
  handleExit,
  disabled,
}: {
  handleExit: () => void;
  disabled?: boolean;
}) {
  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      onClick={handleExit}
      className={classes.button}
      disableElevation
    >
      Zrušit
    </Button>
  );
}

export function ProcessActions<FORM>({
  activeStep,
  stepsCount,
  handleNext,
  handleBack,
  handleExit,
}: ProcessActionsProps<FORM>) {
  const classes = useStyles();

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          disableElevation
        >
          {activeStep + 1 === stepsCount ? 'Dokončit' : 'Další'}
        </Button>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          disableElevation
        >
          Předchozí
        </Button>
        {/**
         * There is last step as stepper summary
         * It makes ExitProcess irrelevant as form was submitted before
         */}
        {activeStep !== stepsCount && (
          <ExitProcessAction handleExit={handleExit} />
        )}
      </div>
    </div>
  );
}

import React from 'react';
import {
  FormPanel,
  FormSelect,
  FormDateField,
  FormTextArea,
  FormInlineTableField,
  useFormSelector,
  FormTextField,
} from '@eas/common-web';
import { useRequestStates } from './ovz-facility-requests-api';
import { SubjectFormPanel } from '../../composite/subject-fields/subject-form-panel';
import { OvzFacility, OvzFacilityRequest } from '../../models';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import { AuthoredFields } from '../../components/evidence/authored-evidence/authored-fields';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { useOvzFacilitiesColumns } from './ovz-facility-requests-columns';
import { HelpLabel } from '../../components/help/help-label';

export function OvzFacilityRequestsFields(props: NotedEvidenceFieldsProps) {
  const requestStates = useRequestStates();

  const { newOperator } = useFormSelector((data: OvzFacilityRequest) => ({
    newOperator: data.newOperator,
  }));

  const facilitiesColumns = useOvzFacilitiesColumns();

  return (
    <>
      <FormPanel label="Nabídka">
        <FormSelect
          source={requestStates}
          name="state"
          label={<HelpLabel label="Stav" code="OVZ_FACILITY_REQUESTS_STATE" />}
          valueIsId={true}
        />
        <FormTextArea
          name="note"
          label={
            <HelpLabel label="Poznámka" code="OVZ_FACILITY_REQUESTS_NOTE" />
          }
        />
        <FormDateField
          name="decided"
          label={
            <HelpLabel
              label="Datum vyřízení"
              code="OVZ_FACILITY_REQUESTS_DECIDED"
            />
          }
        />
        <FormDateField
          name="date"
          label={
            <HelpLabel
              label="Datum přesunu"
              code="OVZ_FACILITY_REQUESTS_DATE"
            />
          }
        />
      </FormPanel>
      <SubjectFormPanel
        codePrefix="OVZ_FACILITY_REQUESTS"
        autocomplete={{
          name: 'oldOperator',
          label: 'Název provozovatele',
        }}
        label="Původní provozovatel"
        addressLabel="Adresa sídla"
        hideIdentifier={true}
      />
      {/** if newOperator is succesfully get from ovz-facility-requests-api */}
      {newOperator?.updated && (
        <SubjectFormPanel
          codePrefix="OVZ_FACILITY_REQUESTS"
          autocomplete={{
            name: 'newOperator',
            label: 'Název provozovatele',
          }}
          label="Nový provozovatel"
          addressLabel="Adresa sídla"
          hideIdentifier={true}
        />
      )}
      {!newOperator?.updated && (
        <FormPanel label="Nový provozovatel">
          <FormTextField
            label={
              <HelpLabel
                label="Název provozovatele"
                code="OVZ_FACILITY_REQUESTS_NEW_OPERATOR_NAME"
              />
            }
            name="newOperator.name"
          />
          <FormTextField
            label={
              <HelpLabel
                label="Identifikátor"
                code="OVZ_FACILITY_REQUESTS_NEW_OPERATOR_IDENTIFIER"
              />
            }
            name="newOperatorIdentifier"
          />
        </FormPanel>
      )}
      <FormPanel
        label="Provozovny"
        summary={<FormPanelCountByName name="ovzFacilities" />}
      >
        <FormInlineTableField<OvzFacility>
          label="Provozovny"
          labelOptions={{
            hide: true,
          }}
          layoutOptions={{ noSpacing: true }}
          name="ovzFacilities"
          disabled={true}
          showToolbar={false}
          columns={facilitiesColumns}
        />
      </FormPanel>

      {props.Notes}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <AuthoredFields FieldsComponent={DatedFields} />
      </FormPanel>
    </>
  );
}

import React from 'react';
import {
  FormCustomField,
  useFormSelector,
  FormPanel,
  FormTextField,
  FormAutocomplete,
} from '@eas/common-web';
import { SubjectFormPanel } from '../../../composite/subject-fields/subject-form-panel';
import {
  dictionaryLabelMapper,
  dictionaryNoCodeLabelMapper,
} from '../../../components/form/mappers/label-mappers';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import { useCzRiverBasins } from '../../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { AddressLabel } from '../../../components/form/address-field/address-label';
import { DetailNavigationButton } from '../../../components/detail-navigation-button/detail-navigation-button';
import { HelpLabel } from '../../../components/help/help-label';
import { EvidenceUrl } from '../../../enums';
import { FormPanelText } from '../../../components/form/form-panel-summary/form-panel-text';
import { IrzFacilityRequest } from '../../../models';

export function InvalidateFields() {
  const naceCzItems = useNaceCzItems();
  const czRiverBasins = useCzRiverBasins();
  const { wgs84Coordinates, irzFacility, address, operator } = useFormSelector(
    ({ irzFacility, requestFacilityData }: IrzFacilityRequest) => ({
      wgs84Coordinates: irzFacility?.wgs84Coordinates,
      irzFacility,
      address: irzFacility?.address,
      operator: requestFacilityData?.operator,
    })
  );

  return (
    <>
      <SubjectFormPanel
        label="Provozovatel"
        codePrefix="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_OPERATOR"
        autocomplete={{
          name: 'requestFacilityData.operator',
          label: 'Název provozovatele',
        }}
        expandable={true}
        defaultExpanded={false}
        summary={operator?.label && <FormPanelText value={operator?.label} />}
        addressLabel="Adresa sídla"
      />
      <FormPanel label="Provozovna">
        <FormTextField
          name="irzFacility.name"
          label={
            <HelpLabel
              label="Název"
              code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_NAME"
            />
          }
        />
        <FormTextField
          name="irzFacility.facilityId"
          label={
            <HelpLabel
              label="IČP"
              code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_FACILITY_ID"
            />
          }
        />
        <FormAutocomplete
          name="irzFacility.czNace"
          label={
            <HelpLabel
              label="CZ-NACE"
              code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_CZ_NACE"
            />
          }
          source={naceCzItems}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          name="irzFacility.czRiverBasinDistrict"
          label={
            <HelpLabel
              label="Oblast povodí ČR"
              code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_CZ_RIVER_BASIN_DISTRICT"
            />
          }
          source={czRiverBasins}
          tooltipMapper={dictionaryNoCodeLabelMapper}
          labelMapper={dictionaryNoCodeLabelMapper}
        />
        <AddressLabel
          name="irzFacility.address"
          code="IRZ_FACILITY_REQUEST_IRZ_FACILITY_ADDRESS"
        />
        <FormCustomField>
          <DetailNavigationButton
            url={EvidenceUrl.IRZ_FACILITIES}
            id={irzFacility?.id}
          />
        </FormCustomField>
      </FormPanel>
      <LocationPanel
        locationName="irzFacility.wgs84Coordinates"
        addressName="irzFacility.address"
        address={address}
        locationValue={wgs84Coordinates}
        expandable
        defaultExpanded={false}
        locationPanelLabel="Poloha provozovny"
        addressPanelLabel="Adresa provozovny"
        prefillCoordinates={false}
      />
    </>
  );
}

import { dd2dms } from '../../utils/latlng';

export const coordinateValueMapper = ({ value }: { value: number }) => {
  const parsed = dd2dms(value);

  if (!parsed.degrees && !parsed.minutes && !parsed.seconds) {
    return '-';
  }

  return `${parsed.degrees}°${parsed.minutes}'${parsed.seconds}"`;
};

coordinateValueMapper.displayName = 'coordinateMapper';

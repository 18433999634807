import { TableColumn, DictionaryEvidence, TableCells } from '@eas/common-web';
import { DictionaryTerritorialTechnicalUnit } from '../../models';

export function useColumns(): TableColumn<
  DictionaryTerritorialTechnicalUnit
>[] {
  const {
    columnName,
    columnValidFrom,
    columnValidTo,
    columnActive,
    columnCreated,
    columnUpdated,
  } = DictionaryEvidence.useDictionaryColumns<
    DictionaryTerritorialTechnicalUnit
  >();

  const columnCode = {
    datakey: 'code',
    name: 'Kód',
    width: 100,
    CellComponent: TableCells.NumberCell,
    sortable: true,
    filterable: true,
  };

  return [
    columnName,
    columnValidFrom,
    columnValidTo,
    columnCode,
    columnActive,
    columnCreated,
    columnUpdated,
  ];
}

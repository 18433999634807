import { SpecialFilterTextCell } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-text-cell';
import { useAgendasSource } from '../agenda/agendas-api';
import { useSpecialFilterSelectCellFactory } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-select-cell';
import { ApiFilterOperation } from '@eas/common-web';
import { SpecialFilter } from '../../components/evidence/history-evidence/overrides/table-searchbar';

export function useSpecialFilters(): SpecialFilter[] {
  return [
    {
      label: 'Zmocnitel - IČO / UID',
      filterkey: 'empowerer.identifier',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Zmocnitel - Název',
      filterkey: 'empowerer.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Zmocněnec - IČO / UID',
      filterkey: 'agent.identifier',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Zmocněnec - Název',
      filterkey: 'agent.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Agendy',
      filterkey: 'agenda.id',
      operation: ApiFilterOperation.EQ,
      FilterCell: useSpecialFilterSelectCellFactory(useAgendasSource),
    },
  ];
}

import React, { useContext, useRef } from 'react';
import * as Yup from 'yup';
import {
  useEventCallback,
  SnackbarContext,
  DetailContext,
  UserContext,
  DetailHandle,
  abortableFetch,
  AbortableFetch,
  PromptContext,
  usePrompts,
  FormAutocomplete,
  FormDateField,
} from '@eas/common-web';
import { Permission, EvidenceAPI, Messages } from '../../../enums';
import { SubjectAutocomplete, IrzFacility } from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { useSubjects } from '../../subject/subjects-api';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { HelpLabel } from '../../../components/help/help-label';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call
 *
 * @param id
 */
export function apiCall(
  facilityId?: string,
  operatorId?: string,
  date?: string
) {
  const query = date ? `?date=${date}` : '';

  return abortableFetch(
    `${EvidenceAPI.IRZ_FACILITIES}/${facilityId}/operator/${operatorId}${query}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    }
  );
}

function useValidationSchema() {
  return Yup.object().shape({
    operator: Yup.mixed().nullable().required('Povinné pole'),
  });
}

const PROMPT_KEY = 'OPERATOR_CHANGE';

export function useOperatorChangeDialog() {
  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, onPersisted } = useContext<DetailHandle<IrzFacility>>(
    DetailContext
  );
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Změna provozovatele',
      dialogText: '',
      FormFields,
      formValidationSchema: useValidationSchema(),
      formInitialValues: {
        keepName: true,
      },
      dialogWidth: 600,
    },
  ]);

  const handleOperatorChange = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async ({
        operator,
        date,
      }: {
        operator: SubjectAutocomplete;
        date: string;
      }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = apiCall(source.data?.id, operator.id, date);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.IrzFacility.OPERATOR_CHANGE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.IrzFacility.OPERATOR_CHANGE,
              err.code
            );

            showSnackbar(...message);
          }

          return true;
        }
      },
    });
  });

  const showOperatorChangeButton = hasPermission(
    Permission.IrzFacility.IRZ_CHANGE_OPERATOR
  );

  return {
    showOperatorChangeButton,
    handleOperatorChange,
  };
}

function FormFields() {
  const subjects = useSubjects();

  return (
    <>
      <FormAutocomplete<SubjectAutocomplete>
        name="operator"
        label={
          <HelpLabel
            label="Provozovatel"
            code="IRZ_FACILITY_REQUEST_OPERATOR_CHANGE_DIALOG_NEW_OPERATOR"
            required
          />
        }
        source={subjects}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
      />
      <FormDateField
        name="date"
        label={
          <HelpLabel
            label="Datum"
            code="IRZ_FACILITY_REQUEST_OPERATOR_CHANGE_DIALOG_DATE"
          />
        }
      />
    </>
  );
}

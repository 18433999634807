import React from 'react';
import {
  FormPanel,
  FormAutocomplete,
  useAutocompleteSource,
  ApiFilterOperation,
} from '@eas/common-web';
import { ProductionSite, ProductionSiteAutocomplete } from '../../../models';
import { EvidenceAPI } from '../../../enums';
import { ProcessForm } from '../production-site-merge-types';
import { HelpLabel } from '../../../components/help/help-label';

/**
 * Data source for Merging Production sites, that excludes currently selected
 * mainProductionSite from results and orders the results with by distance to mainProductionSite
 */
function useProductionSites(mainProductionSite?: ProductionSite) {
  return useAutocompleteSource<ProductionSiteAutocomplete>({
    url: `${EvidenceAPI.PRODUCTION_SITES}/autocomplete`,
    params: {
      filters: [
        {
          operation: ApiFilterOperation.NOT,
          filters: [
            {
              operation: ApiFilterOperation.EQ,
              field: 'id',
              value: mainProductionSite?.id,
            },
          ],
        },
      ],
      sort: [
        {
          type: 'GEO_DISTANCE',
          field: 'wgs84Point',
          order: 'ASC',
          points: [{ ...mainProductionSite?.wgs84Coordinates }],
          sortMode: 'MIN',
        },
      ],
    },
  });
}

export function StepMainProductionSiteContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  const productionSites = useProductionSites(allValues.mergingProductionSite);

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete
        name="mainProductionSite"
        label={
          <HelpLabel
            label="Průmyslové místo"
            code="PRODUCTION_SITE_MERGE_MAIN_PRODUCTION_SITE"
          />
        } // Průmyslové místo, se kterým bude dříve zvolené průmyslové místo sloučeno
        source={productionSites}
      />
    </FormPanel>
  );
}

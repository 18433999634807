import React from 'react';
import { FormPanel } from '@eas/common-web';
import { FormPreFilledAutocomplete } from '../../../components/form/pre-filled-autocomplete/pre-filled-autocomplete';
import { HelpLabel } from '../../../components/help/help-label';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useBoilerManufacturers } from '../../boiler-manufacturer/boiler-manufacturers-api';
import { DictionaryAutocomplete } from '../../../models';

export function StepManufacturerSubjectContent() {
  const boilerManufacturers = useBoilerManufacturers();

  return (
    <FormPanel label="Vyplňte">
      <FormPreFilledAutocomplete<DictionaryAutocomplete>
        name="boilerManufacturer"
        label={
          <HelpLabel
            label="Subjekt výrobce kotlů"
            code="BOILER_PERMISSION_NEW_BOILER_MANUFACTURER"
            required
          />
        } // Povinné pole
        source={boilerManufacturers}
        labelMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

import React, { useContext } from 'react';
import {
  FormPanel,
  FormAutocomplete,
  DetailMode,
  DetailContext,
} from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';
import {
  userLabelMapper,
  dictionaryLabelMapper,
} from '../../../components/form/mappers/label-mappers';
import { useAssignableSecurityRoles } from '../../roles/roles-api';
import { useUsers } from '../../user/users-api';

export function SecurityRoleFields() {
  const { mode } = useContext(DetailContext);

  const securityRoles = useAssignableSecurityRoles();
  const users = useUsers();

  return (
    <FormPanel label="Bezpečnostní role">
      <FormAutocomplete
        disabled={mode === DetailMode.EDIT}
        name="role"
        label={<HelpLabel label="Role" code="ROLE_INSTANCES_ROLE" required />} // Povinné pole
        source={securityRoles}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
      <FormAutocomplete
        disabled={mode === DetailMode.EDIT}
        name="user"
        label={
          <HelpLabel label="Uživatel" code="ROLE_INSTANCES_USER" required />
        } // Povinné pole
        source={users}
        labelMapper={userLabelMapper}
        tooltipMapper={userLabelMapper}
      />
    </FormPanel>
  );
}

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import {
  DictionaryBoilerModel,
  DictionaryBoilerBrand,
  DictionaryBoilerType,
} from '../../models';
import { useDictionaryBoilerTypes } from '../dict-boiler-type/dict-boiler-types-api';
import { useDictionaryBoilerModels } from '../dict-boiler-model/dict-boiler-models-api';

export function boilerMapperFactory(key: 'allowedTypes' | 'models') {
  type BoilerSpec = DictionaryBoilerModel | DictionaryBoilerType;

  const valueMapper = ({ rowValue }: { rowValue: DictionaryBoilerBrand }) => {
    if ((rowValue[key] as BoilerSpec[])?.length) {
      return (rowValue[key] as BoilerSpec[])?.map((val) => val.name).join(', ');
    }

    return '';
  };

  valueMapper.displayName = 'boilerMapper';
  valueMapper.data = key;

  return valueMapper;
}

export function useColumns(): TableColumn<DictionaryBoilerBrand>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'allowedTypes.id',
      name: 'Typy kotlů',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: boilerMapperFactory('allowedTypes'),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDictionaryBoilerTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'models.id',
      name: 'Modely kotlů',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: boilerMapperFactory('models'),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDictionaryBoilerModels
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import React from 'react';
import { FormAutocomplete } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useEPrtrActivities } from '../dict-e-prtr-activity/dict-e-prtr-activities-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';

export function DictionaryIppcActivitiesFields() {
  const ePrtrActivities = useEPrtrActivities();

  return (
    <>
      <FormAutocomplete
        name="eprtrActivities"
        label={
          <HelpLabel label="E-PRTR činnost" code="EPRTR_ACTIVITY_ACTIVITY" />
        }
        source={ePrtrActivities}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
        multiple={true}
      />
    </>
  );
}

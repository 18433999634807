import React, { useRef, useContext } from 'react';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  useFormSelector,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  ListSource,
} from '@eas/common-web';
import {
  Agenda,
  Subject,
  AgendaTag,
  BoilerManufacturer,
} from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { Messages, EvidenceAPI } from '../../../enums';
import {
  getBoilerManufacturersForSubject,
  deleteBoilerManufacturer,
} from '../../boiler-manufacturer/boiler-manufacturers-api';
import { EnhancedSubject, SubjectAgendaDto } from '../subjects-types';

/**
 * Call api
 *
 * @param agenda
 * @param subject
 */
export function callApi(agenda: Agenda, subject: Subject) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECTS}/${subject.id}/unassign/${agenda.id}`,
    {
      method: 'POST',
    }
  );
}

/**
 * Odebrání agendy
 */
const PROMPT_KEY = 'UNASSIGN_AGENDA';

export function useUnassignAgendaDialog({
  selectedIndex,
  setSelectedIndex,
}: {
  selectedIndex: number;
  setSelectedIndex: (index: number | undefined) => void;
}) {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext<DetailHandle<Subject>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { reload } = useContext(UserContext);
  const { testPrompt } = useContext(PromptContext);

  /**
   * Agenda table data.
   */
  const { agendas } = useFormSelector(
    (data: { agendas: SubjectAgendaDto[] }) => ({
      agendas: data?.agendas,
    })
  );

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Odebrání agendy',
        dialogText: (
          <>
            Skutečně chcete odebrat agendu{' '}
            <b>{agendas?.[selectedIndex]?.agenda?.name}</b>?
          </>
        ),
        dialogWidth: 600,
      },
    ],
    [selectedIndex]
  );

  const handleUnassignAgenda = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(agendas[selectedIndex].agenda!, source.data!);

          await fetch.current.raw();

          if (
            agendas[selectedIndex]?.agenda?.tags?.includes(
              AgendaTag.OZO_MANUFACTURER
            )
          ) {
            const manufacturers: ListSource<BoilerManufacturer> = await getBoilerManufacturersForSubject(
              source.data!
            ).json();
            const manufacturer = manufacturers.items[0];
            if (manufacturer) {
              await deleteBoilerManufacturer(manufacturer.id).raw();
            }
          }

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Subject.AGENDA_UNASSIGN.SUCCESS);
            source.setLoading(false);
          });

          reload();
          setSelectedIndex(undefined);
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Subject.AGENDA_UNASSIGN.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const canUnassign =
    source.data?.active &&
    agendas?.[selectedIndex]?.active &&
    ((source.data as EnhancedSubject)?.assignableAgendas || []).some(
      (agenda) =>
        selectedIndex !== undefined &&
        agenda?.id === agendas?.[selectedIndex]?.agenda?.id
    );

  return {
    handleUnassignAgenda,
    canUnassign,
  };
}

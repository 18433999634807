import React from 'react';
import {
  abortableFetch,
  DetailContext,
  SnackbarContext,
  UserContext,
  PromptContext,
  usePrompts,
  DetailHandle,
  useEventCallback,
  AbortableFetch,
  FormTextArea,
} from '@eas/common-web';
import * as Yup from 'yup';
import { EvidenceAPI, Messages, Permission } from '../../../enums';
import { useContext, useRef } from 'react';
import { Subject } from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { HelpLabel } from '../../../components/help/help-label';

/**
 * Api call
 * @param id
 */
function callApi(id: string, note?: string) {
  return abortableFetch(`${EvidenceAPI.SUBJECTS}/${id}/active`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: JSON.stringify({ note: note }),
  });
}

/**
 * Obnovení platnosti subjektu
 */
const PROMPT_KEY = 'ACTIVATE';

export function useActivateDialog() {
  /**
   * Context stuff
   */
  const { onPersisted, source } = useContext<DetailHandle<Subject>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Obnovení platnosti subjektu',
      dialogText: 'Skutečně chcete obnovit platnost subjektu?',
      FormFields: function Body() {
        return (
          <FormTextArea
            name="note"
            label={
              <HelpLabel label="Poznámka" code="SUBJECT_ACTIVATE_DIALOG_NOTE" />
            }
          />
        );
      },
      formValidationSchema: Yup.object().shape({
        note: Yup.mixed().nullable().required('Povinné pole'),
      }),
      dialogWidth: 600,
    },
  ]);

  const handleActivate = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async ({ note }: { note?: string }) => {
        try {
          source.setLoading(true);
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(source.data!.id, note);
          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Subject.ACTIVATE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);
          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Subject.ACTIVATE.ERROR);
          }
          return undefined;
        }
      },
    });
  });

  const showActivateButton =
    hasPermission(Permission.Subject.SUBJECT_REVALIDATE) &&
    !source.data?.active;

  return {
    handleActivate,
    showActivateButton,
  };
}

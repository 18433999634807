import React from 'react';
import { useOvzFacilityRequestOperatorChange } from './ovz-facility-request-operator-change-hook';
import { Process } from '../../components/process/process';
import { ProcessForm } from './ovz-facility-request-operator-change-types';
import { TranslationKeys } from '../../enums';

export function OvzFacilityRequestOperatorChange() {
  const { steps } = useOvzFacilityRequestOperatorChange();

  return (
    <Process<ProcessForm>
      title="Změna provozovatele provozovny OVZ"
      steps={steps}
      translationKey={TranslationKeys.OVZ_FACILITY_REQUEST_OPERATOR_CHANGE}
    />
  );
}

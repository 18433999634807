import React, { useEffect, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  BreadcrumbCtx,
  initialBreadcrumbs,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { PageKey } from '../../enums';

export function PasswordResetRequestSent() {
  const { setBreadcrumbs } = useContext(BreadcrumbCtx);

  useEffect(() => {
    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Vytvoření nového hesla',
        key: PageKey.PASSWORD_RESET,
        to: '/obnova-hesla',
      },
      {
        title: 'Žádost o vytvoření nového hesla',
        key: PageKey.PASSWORD_RESET_REQUEST_SENT,
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="body1" align="center">
        Žádost o vytvoření nového hesla byla odeslána. Heslo změníte kliknutím
        na odkaz zaslaný na Váš e-mail uvedený v systému.
      </Typography>
    </>
  );
}

import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '@eas/common-web';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BreadcrumbCtx,
  initialBreadcrumbs,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { useVerifyDataboxAndPrepareConcept } from './hook/verify-databox-hook';
import { useStyles } from './email-verification';
import { PageKey } from '../../enums';

export function DataboxVerification() {
  const classes = useStyles();

  const { setBreadcrumbs } = useContext(BreadcrumbCtx);
  const { user } = useContext(UserContext);

  const { push } = useHistory();
  const { loading, error, conceptStatus } = useVerifyDataboxAndPrepareConcept();

  useEffect(() => {
    if (!user) {
      push('/crzp');
    }

    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Ověření datové schránky',
        key: PageKey.DATABOX_VERIFICATION,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = () => {
    push('/crzp');
  };

  return (
    <>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      {!!error && !loading && (
        <Typography variant="body1" align="center" className={classes.warning}>
          {`Došlo k neočekávané chybě: ${error}`}
        </Typography>
      )}
      {!error && !loading && conceptStatus === 'PREPARED' && (
        <Typography variant="body1" align="center">
          <div>Datová schránka byla úspěšně ověřena.</div>
          <div>
            Budete přesměrováni na náhled konceptu autorizačního dokumentu.
          </div>
        </Typography>
      )}
      {!error && !loading && conceptStatus === 'SENT' && (
        <Typography variant="body1" align="center">
          <div>Datová zpráva byla úspěšně odeslána.</div>
        </Typography>
      )}
      {!error && !loading && conceptStatus === 'REJECTED' && (
        <Typography variant="body1" align="center">
          <div>Koncept datové zprávy byl zamítnut.</div>
        </Typography>
      )}
      {!loading && !!error && (
        <div className={classes.actions}>
          <Button
            onClick={handleRedirect}
            variant="contained"
            disableElevation
            color="primary"
          >
            <Typography
              component="span"
              variant="body1"
              className={classes.buttonText}
            >
              Přejít do aplikace
            </Typography>
          </Button>
        </div>
      )}
    </>
  );
}

import React, { useMemo } from 'react';
import { AuthoredFields } from './../authored-evidence/authored-fields';
import { useColumns } from './../authored-evidence/authored-columns';
import { AuthoredObject, EvidenceProps } from '@eas/common-web';
import { useDatedEvidence } from '../dated-evidence/dated-evidence';
import { AuthoredEvidenceProps } from './authored-type';

export function useAuthoredEvidence<OBJECT extends AuthoredObject>(
  options: AuthoredEvidenceProps<OBJECT>
): EvidenceProps<OBJECT> {
  const columns = useColumns({
    columns: options.tableProps?.columns,
    showColumn: options.tableProps?.showColumn,
    columnLabelMapper: options.tableProps?.columnLabelMapper,
    columnWrapper: options.tableProps?.columnWrapper,
  });

  const GeneralFieldsComponent = useMemo(
    () =>
      function Fields() {
        return (
          <AuthoredFields
            FieldsComponent={options.detailProps?.GeneralFieldsComponent}
          />
        );
      },
    [options.detailProps?.GeneralFieldsComponent]
  );

  return useDatedEvidence({
    ...options,
    tableProps: {
      ...options.tableProps,
      columns,
    },
    detailProps: {
      ...options.detailProps,
      GeneralFieldsComponent: options.detailProps.hideGeneralFields
        ? undefined
        : GeneralFieldsComponent,
    },
  });
}

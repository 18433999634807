import React, { useContext } from 'react';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { parseISO, formatDistanceToNow } from 'date-fns';
import { LocaleContext } from '@eas/common-web';
import { useRead } from './actions/read-hook';
import { useUnread } from './actions/unread-hook';
import { useDelete } from './dialog-actions/delete-hook';
import { useUnsubscribe } from './dialog-actions/unsubscribe-hook';
import { Notification as NotificationType } from '../../models';

export const useStyles = makeStyles(() => ({
  notificationIcon: {
    color: 'white',
    width: '20px',
    height: '20px',
    padding: 0,
    margin: 0,
  },
  button: {
    height: 32,
  },
  menu: {
    height: 'fit-content',
    width: 500,
  },
  notification: {
    cursor: 'default',
    width: '100%',
    height: '100%',
    whiteSpace: 'normal',
    boxShadow: '0 0 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  notificationSubheader: {
    fontSize: 11,
    fontWeight: 500,
  },
  notificationTitle: {
    fontSize: 14,
    fontWeight: 400,
  },
  notificationActionButton: {
    alignSelf: 'auto',
    marginRight: 0,
  },
  notificationContent: {
    whiteSpace: 'pre-wrap',
  },
}));

export function Notification({
  item,
  refreshNotifications,
}: {
  item: NotificationType;
  refreshNotifications: () => void;
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { handleRead } = useRead({ refresh: refreshNotifications });
  const { handleUnread } = useUnread({ refresh: refreshNotifications });
  const { handleDelete } = useDelete({ refresh: refreshNotifications });
  const { handleUnsubscribe } = useUnsubscribe({
    refresh: refreshNotifications,
  });

  const { locale } = useContext(LocaleContext);

  const date = formatDistanceToNow(parseISO(item.created!), {
    locale: locale.dateFnsLocale,
    addSuffix: true,
  });

  return (
    <Card className={classes.notification}>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!item.read && (
          <MenuItem onClick={() => handleRead(item.id)}>
            Označit jako přečtené
          </MenuItem>
        )}
        {item.read && (
          <MenuItem onClick={() => handleUnread(item.id)}>
            Označit jako nepřečtené
          </MenuItem>
        )}
        <MenuItem onClick={() => handleDelete(item.id)}>Vymazat</MenuItem>
        <MenuItem onClick={() => handleUnsubscribe(item.event!)}>
          Nechci dostávat podobné notifikace
        </MenuItem>
      </Menu>
      <CardHeader
        avatar={!item.read ? <NewReleasesIcon color="secondary" /> : null}
        action={
          <IconButton size="small" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <div
            className={classes.notificationTitle}
            dangerouslySetInnerHTML={{ __html: item.subject! }}
          />
        }
        subheader={date}
        classes={{
          action: classes.notificationActionButton,
          subheader: classes.notificationSubheader,
        }}
      />
      <CardContent>
        <Typography
          variant="body1"
          color="textSecondary"
          component="div"
          className={classes.notificationContent}
        >
          <div dangerouslySetInnerHTML={{ __html: item.content! }} />
        </Typography>
      </CardContent>
    </Card>
  );
}

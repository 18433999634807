import { DictionaryAutocomplete, DictionaryObject } from './dictionary-object';

/**
 * Účely zpracování
 */
export interface DictionaryProcessingPurpose extends DictionaryObject {
  /**
   * Typ
   */
  type?: ProcessingPurposeType;

  /**
   * Oblast
   */
  domain?: ProcessingPurposeDomain;

  /**
   * Zákonná reference nebo důvod
   */
  legalReference?: string;

  /**
   * Skartační lhůta (v dnech)
   */
  shreddingPeriodDays?: number;

  /**
   * Skartační znak
   */
  shreddingMark?: string;
}

export type DictionaryProcessingPurposeAutocomplete = DictionaryAutocomplete;

export enum ProcessingPurposeType {
  /**
   * Zákonný
   */
  LEGAL = 'LEGAL',
  /**
   * Oprávněný zájem
   */
  LEGITIMATE_INTEREST = 'LEGITIMATE_INTEREST',
}

export enum ProcessingPurposeDomain {
  /**
   * Subjekt
   */
  SUBJECT = 'SUBJECT',

  /**
   * IRZ
   */
  IRZ = 'IRZ',

  /**
   * OVZ
   */
  OVZ = 'OVZ',

  /**
   * MUV
   */
  WATER_USAGE_PLACE = 'WATER_USAGE_PLACE',

  /**
   * OZO
   */
  OZO = 'OZO',

  /**
   * Zmocnění
   */
  EMPOWERMENT = 'EMPOWERMENT',

  /**
   * Uživatel
   */
  USER = 'USER',

  /**
   * Mailing
   */
  MAILING = 'MAILING',
}

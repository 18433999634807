import React, { useState, useEffect, useMemo } from 'react';
import { AgendaSpecification, AgendaType, AgendaUnion } from '../../models';
import { SpecificationTableCtx } from './specification-table-context';
import { cloneDeep, noop } from 'lodash';
import {
  useFormSelector,
  Params,
  ApiFilterOperation,
  ResultDto,
  InlineTableField,
  FormCustomField,
  TableFieldColumn,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import { SpecificationToolbarFactory } from './specification-toolbar';
import { useLocalListSource } from '../../utils/local-list-source';
import { get } from 'lodash';
import { getAgendaSpecifications } from './specification-api';
import { checkBoxCellFactory } from './specification-checkbox';
import { SpecificationTableFieldRow } from './specification-table-row';
import { SpecificationTableHeader } from './specification-table-header';

export function SpecificationTable({
  disabled,
  fieldPrefix,
  hideLabel = false,
  noSpacing = false,
}: {
  disabled: boolean;
  fieldPrefix?: string;
  hideLabel?: boolean;
  noSpacing?: boolean;
}) {
  const specificationsFieldName = fieldPrefix
    ? `${fieldPrefix}.specifications`
    : 'specifications';

  /**
   * Stores detail of SpecifiedAgenda.
   */
  const [allSpecifications, setAllSpecifications] = useState<
    AgendaSpecification[]
  >([]);

  /**
   * Stores SELECT datasource.
   */
  const [specificationSource, setSpecificationSource] = useState<
    AgendaSpecification[]
  >([]);

  /**
   * Retrieve `agenda` and `specifications` from FORM.
   */
  const { agenda, specifications } = useFormSelector(
    (data: {
      agenda: AgendaUnion;
      specifications?: AgendaSpecification[];
    }) => ({
      agenda: fieldPrefix ? get(data, `${fieldPrefix}.agenda`) : data.agenda,
      specifications: get(data, specificationsFieldName),
    })
  );

  /**
   * Refresh SELECT datasource after `agenda` field is changed.
   */
  useEffect(() => {
    const setSelectSource = async () => {
      if (agenda) {
        const params: Params = {
          filters: [
            {
              field: 'agenda.id',
              operation: ApiFilterOperation.EQ,
              value: agenda?.id,
            },
          ],
          sort: [
            {
              type: 'FIELD',
              field: 'name',
              order: 'ASC',
            },
          ],
          size: -1,
        };

        const specs: ResultDto<AgendaSpecification> = await getAgendaSpecifications(
          params
        ).json();

        setAllSpecifications(specs.items ?? []);

        const datasource = cloneDeep(specs.items ?? []);

        setSpecificationSource(datasource);
      }
    };

    if (!disabled && agenda?.type === AgendaType.SPECIFIED) {
      setSelectSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda, disabled]);

  /**
   * Refresh SELECT datasource after `specifications` are changed.
   */
  useMemo(() => {
    if (agenda?.type === AgendaType.SPECIFIED) {
      // filter already used specifications
      const newSpecifications =
        allSpecifications?.filter(
          (spec) =>
            !specifications?.find((req: AgendaSpecification) => {
              return req?.id === spec.id;
            })
        ) ?? [];

      // clone to prevent ref bugs
      const datasource = cloneDeep(newSpecifications);

      setSpecificationSource(datasource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda?.type, specifications]);

  /**
   * Create local datasource from `specifications`.
   */
  const source = useLocalListSource(specificationSource);

  const specificationsColumns: TableFieldColumn<AgendaSpecification>[] = [
    {
      name: '',
      datakey: 'active',
      width: 50,
      visible: !disabled,
      CellComponent: checkBoxCellFactory(specificationsFieldName),
    },
    {
      name: 'Název',
      datakey: 'name',
      width: 200,
    },
    {
      name: 'Popis',
      datakey: 'description',
      width: -1,
      CellComponent: function Cell(props) {
        return <Typography variant="body1">{props.value}</Typography>;
      },
    },
  ];

  return (
    <SpecificationTableCtx.Provider value={source}>
      <FormCustomField
        label="Typ hlášení"
        labelOptions={{ hide: hideLabel }}
        layoutOptions={{ noSpacing }}
      >
        <InlineTableField<AgendaSpecification>
          value={disabled ? specifications : allSpecifications}
          onChange={noop}
          disabled={disabled}
          withRemove={false}
          HeaderComponent={SpecificationTableHeader}
          RowComponent={SpecificationTableFieldRow}
          ToolbarComponent={SpecificationToolbarFactory({
            specificationsFieldName,
          })}
          columns={specificationsColumns}
        />
      </FormCustomField>
    </SpecificationTableCtx.Provider>
  );
}

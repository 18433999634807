import React, { useContext, useMemo } from 'react';
import { flatten } from 'lodash';
import { Filter } from '@eas/common-web';
import { Map as MapComponent } from '../../components/map/map';
import { getCoordinates } from '../../components/map/map-api';
import { MapContext } from '../../components/map/map-ctx';
import { SMap } from '../../components/map/map-types';
import { MarkerLayer } from '../../components/map/marker-layer';
import { Markers } from '../../components/map/markers';
import { useCardData } from './hook/card-data-hook';
import { OverviewMapContext } from './overview-map-provider';
import { Area } from './overview-map-types';
import { EvidenceAPI } from '../../enums';

function handleMapRedraw(
  source: { api: EvidenceAPI; type: Area; filters: Filter[] }[]
) {
  return async function mapRedraw(
    sMap: SMap | null,
    setItems?: (items: Coordinates[]) => void
  ) {
    if (sMap && source) {
      const size = sMap.getSize();
      const leftCornerPixel = new window.SMap.Pixel(-size.x / 2, -size.y / 2);
      const leftCorner = leftCornerPixel.toCoords(sMap, sMap.getZoom());

      const rightCornerPixel = new window.SMap.Pixel(size.x / 2, size.y / 2);
      const rightCorner = rightCornerPixel.toCoords(sMap, sMap.getZoom());

      const items = await Promise.all(
        source.map(async ({ api, type, filters }) => {
          const { items } = await getCoordinates(
            leftCorner,
            rightCorner,
            filters,
            api
          ).json();

          return items.map((item: Coordinates) => ({ ...item, type }));
        })
      );

      setItems?.(items?.length ? flatten(items) : []);
    }
  };
}

export function Map() {
  const { source } = useContext(OverviewMapContext);

  const mapRedraw = useMemo(
    () => handleMapRedraw(source.filter((s) => s.shouldLoad)),
    [source]
  );

  const MemoizedMap = useMemo(
    () =>
      function Map() {
        return (
          <MapComponent
            handleMapRedraw={mapRedraw}
            api={EvidenceAPI.IRZ_FACILITIES}
          >
            <MapBody />
          </MapComponent>
        );
      },
    [source]
  );

  return <MemoizedMap />;
}

function MapBody() {
  const { items, map } = useContext(MapContext);

  const maxZoom = map?.getZoom() === map?.getZoomRange()[1];

  const { cardData } = useCardData();

  const markers = useMemo(() => {
    if (maxZoom) {
      const markers = items.map((item) => ({
        id: `type=${item.type}&id=${item.id}`,
        lat: item.lat,
        lng: item.lon,
        active: item.type === Area.OVZ_FACILITY,
        selected: item.type === Area.IRZ_FACILITY,
      }));

      const object: { [key: string]: number } = {};
      const result: typeof markers = [];

      markers.forEach((marker) => {
        const key = `${marker.lat}+${marker.lng}`;
        if (object[key] === undefined) {
          object[key] = 0;
          result.push(marker);
        } else {
          object[key] += 1;
          result.push({ ...marker, lng: marker.lng + 0.0001 * object[key] });
        }
      });

      return result;
    } else {
      return items.map((item) => ({
        id: `type=${item.type}&id=${item.id}`,
        lat: item.lat,
        lng: item.lon,
        active: item.type === Area.OVZ_FACILITY,
        selected: item.type === Area.IRZ_FACILITY,
      }));
    }
  }, [items, maxZoom]);

  return (
    <MarkerLayer>
      <Markers markers={markers} card={cardData} />
    </MarkerLayer>
  );
}

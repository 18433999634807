import React from 'react';

export function adjustWhitespaces(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) {
  e.target.value = e.target.value.replace(/\s+/g, ' ');
  e.target.value = e.target.value.replace(/^\s/g, '');
}

export function removeTrailingWhitespaces(value: string) {
  return value.replace(/\s$/g, '');
}

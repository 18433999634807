import {
  useStaticListSource,
  DictionaryAutocomplete,
  useAutocompleteSource,
  Params,
} from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';
import { AgendaAutocomplete } from '../../../models';

export function useStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'STARTED', name: 'Běží' },
    { id: 'FAILED', name: 'Chyba' },
    { id: 'PROCESSED', name: 'Skončil' },
  ]);
}

export function usePublicLists(params?: Params) {
  return useAutocompleteSource<AgendaAutocomplete>({
    url: EvidenceAPI.PUBLIC_LISTS + '/autocomplete',
    params,
  });
}

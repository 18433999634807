import React, { useRef, useState, useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormContext,
  useEventCallback,
  SnackbarContext,
  AbortableFetch,
  abortableFetch,
  PromptContext,
  usePrompts,
  NavigationContext,
} from '@eas/common-web';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { EvidenceAPI, Messages } from '../../../enums';
import { ProductionSite } from '../../../models';
import { ProcessForm } from '../production-site-merge-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

interface MergeProductionSiteApiCall {
  mainProductionSiteId?: string;
  endingProductionSiteId?: string;
  updateProductionSite?: ProductionSite;
}

/**
 * Api call
 *
 * @param body
 */
function callApi({
  mainProductionSiteId,
  endingProductionSiteId,
  updateProductionSite,
}: MergeProductionSiteApiCall) {
  return abortableFetch(
    `${EvidenceAPI.PRODUCTION_SITES}/${mainProductionSiteId}/merge/${endingProductionSiteId}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(updateProductionSite),
    }
  );
}

const PROMPT_KEY = 'MERGE_PRODUCTION_SITES';

export function StepSummaryActions({
  handleNext,
  handleBack,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { setFieldValue } = useContext(FormContext);
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const fetch = useRef<AbortableFetch | null>(null);
  const { unregisterPrompt } = useContext(NavigationContext);

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Sloučení průmyslových míst',
      dialogText: 'Opravdu chcete sloučit vybraná průmyslová místa?',
    },
  ]);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const {
            editData,
            mainProductionSite,
            mergingProductionSite,
          } = allValues;

          fetch.current = callApi({
            endingProductionSiteId: mergingProductionSite?.id,
            mainProductionSiteId: mainProductionSite?.id,
            updateProductionSite: editData,
          });

          const mergedProductionSite: ProductionSite = await fetch.current.json();

          showSnackbar(...Messages.ProductionSite.MERGE.SUCCESS);

          setFieldValue('mergedProductionSite', mergedProductionSite);
          setFieldValue('isSent', true);
          unregisterPrompt(navigationPrompt);

          handleNext();
        } catch (err) {
          showSnackbar(...Messages.ProductionSite.MERGE.ERROR);

          throw err;
        } finally {
          setLoading(false);
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Sloučit průmyslová místa
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <Button
          onClick={handleExit}
          className={classes.button}
          disableElevation
        >
          Zrušit
        </Button>
      </div>
    </div>
  );
}

import React from 'react';
import { useFormSelector, FormCustomField, TextField } from '@eas/common-web';
import { Address } from '../../../models';
import { get } from 'lodash';
import { useAddressLabel } from './hooks/address-label-hook';
import { HelpLabel } from '../../help/help-label';

export function AddressLabel({
  name,
  code,
  fieldLabel = 'Adresa',
}: {
  name: string;
  code: string;
  fieldLabel?: string;
}) {
  const address = useFormSelector(
    (data: { [key: string]: Address | undefined }) => get(data, name)
  );

  const label = useAddressLabel(address);

  return (
    <FormCustomField label={<HelpLabel label={fieldLabel} code={code} />}>
      <TextField disabled={true} value={label} />
    </FormCustomField>
  );
}

import React, { useMemo, useContext } from 'react';
import { NavigationContext, EvidenceStateAction } from '@eas/common-web';
import Button from '@material-ui/core/Button/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Markers } from '../../components/map/markers';
import { useCardData } from '../production-site/actions/production-site-map-hook';
import { Wgs84Coordinates, ProductionSite } from '../../models';
import { EvidenceUrl } from '../../enums';

const useStyles = makeStyles(() => ({
  button: {
    boxShadow: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

interface MapMarkersProps {
  items: (Wgs84Coordinates & { id: string; assigned: boolean })[];
  onAddBtnClick: (data: ProductionSite) => void;
  onRemoveBtnClick: (id: string) => void;
  showAddBtn: (id: string) => boolean;
  showRemoveBtn: (id: string) => boolean;
}

export function MapMarkers({
  items,
  onAddBtnClick,
  onRemoveBtnClick,
  showAddBtn,
  showRemoveBtn,
}: MapMarkersProps) {
  const { navigate } = useContext(NavigationContext);

  const navigateToProductionSite = (id: string) => {
    navigate(EvidenceUrl.PRODUCTION_SITES, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: id,
    });
  };

  const Actions = ({ data }: { data?: ProductionSite }) => {
    const classes = useStyles();

    const closeBtn = document.getElementsByClassName('close')[0] as HTMLElement;

    return (
      <>
        {data?.id && showAddBtn(data.id) && (
          <Button
            className={classes.button}
            onClick={() => {
              onAddBtnClick(data);
              closeBtn.click();
            }}
            size="small"
            variant="contained"
          >
            Přidělit
          </Button>
        )}
        {data?.id && showRemoveBtn(data.id) && (
          <Button
            className={classes.button}
            onClick={() => {
              onRemoveBtnClick(data.id);
              closeBtn.click();
            }}
            size="small"
            variant="contained"
          >
            Odebrat
          </Button>
        )}
      </>
    );
  };

  const { cardData } = useCardData(navigateToProductionSite, Actions);

  const markers = useMemo(() => {
    return items.map((item) => ({
      id: item.id,
      key: item.id,
      lat: item.lat!,
      lng: item.lon!,
      active: item.assigned,
      selected: item.assigned,
    }));
  }, [items]);

  return <Markers markers={markers} card={cardData} />;
}

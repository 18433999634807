import React, { useMemo } from 'react';
import {
  useEventCallback,
  TableFieldCellProps,
  FormCheckbox,
} from '@eas/common-web';

export function useChangeNotifyingInlineCheckboxFactory({
  collectionDatakey,
  isSubkey = true,
  disabled,
  notifyChange,
}: {
  collectionDatakey: string;
  isSubkey?: boolean;
  disabled?: ((index: number) => boolean) | boolean;
  notifyChange?: (index: number) => void;
}) {
  return useMemo(
    () =>
      function InlineCheckboxCell({ index, column }: TableFieldCellProps<any>) {
        const handleNotifyChange = useEventCallback(() => {
          if (notifyChange) {
            notifyChange(index);
          }
        });
        return (
          <FormCheckbox
            name={
              isSubkey
                ? `${collectionDatakey}[${index}].${column.datakey}`
                : `${collectionDatakey}[${index}]`
            }
            labelOptions={{ hide: true }}
            layoutOptions={{ noUnderline: true }}
            disabled={
              typeof disabled === 'function' ? disabled(index) : disabled
            }
            notifyChange={handleNotifyChange}
          />
        );
      },
    [collectionDatakey, isSubkey, disabled, notifyChange]
  );
}

import React, { PropsWithChildren } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormFieldWrapperProps } from '../protected-field/protected-field-wrapper-types';
import { FormattedMessage } from 'react-intl';
import { useStylesWrapper } from '../protected-field/protected-field-styles';

export function ProtectedCheckboxWrapper({
  children,
  errors,
}: PropsWithChildren<FormFieldWrapperProps>) {
  const { wrapper, spacing } = useStylesWrapper();

  const hasError = (errors?.length ?? 0) > 0;

  return (
    <Grid item xs={12} className={wrapper}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        classes={{ root: spacing }}
      >
        <Grid item xs={12}>
          <Box display="flex">{children}</Box>
          <Box ml={4}>
            {errors?.map((error, index) => (
              <FormHelperText key={index} error={hasError}>
                <FormattedMessage id={error.value} />
              </FormHelperText>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import { TranslationKeys } from '../../enums';
import { Process } from '../../components/process/process';
import { ProcessForm } from './boiler-manufacturer-new-types';
import { useNewBoilerManufacturer } from './boiler-manufacturer-new-hook';

export function BoilerManufacturerNew() {
  const { steps } = useNewBoilerManufacturer();
  return (
    <Process<ProcessForm>
      translationKey={TranslationKeys.BOILER_MANUFACTURER_NEW}
      steps={steps}
      title="Výrobce kotlů"
    />
  );
}

import {
  abortableFetch,
  NavigationContext,
  SnackbarContext,
  useEventCallback,
  AbortableFetch,
} from '@eas/common-web';
import { EvidenceAPI, Messages } from '../../enums';
import { useMemo, useContext, useState, useRef, useEffect } from 'react';
import { IrzFacility } from '../../models';
import { IrzMergeStateAction } from './irz-facility-merge-types';
import { unstable_batchedUpdates } from 'react-dom';
import { StepMainFacilityLabel } from './step-main-facility/step-main-facility-label';
import { StepMainFacilityContent } from './step-main-facility/step-main-facility-content';
import { StepMainFacilityActions } from './step-main-facility/step-main-facility-actions';
import { StepMergingFacilityLabel } from './step-merging-facility/step-merging-facility-label';
import { StepMergingFacilityContent } from './step-merging-facility/step-merging-facility-content';
import { StepEditLabel } from './step-edit/step-edit-label';
import { StepEditContent } from './step-edit/step-edit-content';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepFinishActions } from './step-finish/step-finish-actions';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { stepMainFacilitySchema } from './step-main-facility/step-main-facility-schema';
import { stepMergingFacilitySchema } from './step-merging-facility/step-merging-facility-schema';
import { useStepEditSchema } from './step-edit/step-edit-schema';
import { StepMergingFacilityActions } from './step-merging-facility/step-merging-facility-actions';
import { formatISO } from 'date-fns';
import { StepOtherInformationLabel } from './step-other-information/step-other-information-label';
import { StepOtherInformationContent } from './step-other-information/step-other-information-content';

function fetchIrzFacility(id: string) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITIES}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export function useIrzFacilityMerge() {
  /**
   * Context stuff
   */
  const { stateAction } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);

  /**
   * IRZ facility preloading state
   */
  const [isLoading, setLoading] = useState(
    stateAction?.action === IrzMergeStateAction.INIT_MAIN_IRZ
  );
  const [initialValues, setInitialValues] = useState<{
    mergingFacility: IrzFacility;
  }>();

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Pre-loads irz facility based on the NavigationContext stateAction data
   */
  const loadIrz = useEventCallback(async () => {
    try {
      setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }
      fetch.current = fetchIrzFacility(stateAction?.data);
      const irzFacility: IrzFacility = await fetch.current.json();

      unstable_batchedUpdates(() => {
        setLoading(false);
        setInitialValues({
          mergingFacility: irzFacility,
        });
      });
    } catch (err) {
      setLoading(false);
      if (err.name !== 'AbortError') {
        showSnackbar(...Messages.IrzFacilityRequest.IRZ_FACILITY_PRELOAD.ERROR);
        throw err;
      }
      return undefined;
    }
  });

  useEffect(() => {
    if (stateAction?.action === IrzMergeStateAction.INIT_MAIN_IRZ) {
      loadIrz();
    }
  }, []);

  const steps = useMemo(
    () => [
      {
        Label: StepMergingFacilityLabel,
        Content: StepMergingFacilityContent,
        validationSchema: stepMergingFacilitySchema,
        Actions: StepMergingFacilityActions,
        initialValues,
      },
      {
        Label: StepMainFacilityLabel,
        Content: StepMainFacilityContent,
        Actions: StepMainFacilityActions,
        validationSchema: stepMainFacilitySchema,
      },
      {
        Label: StepEditLabel,
        Content: StepEditContent,
        validationSchema: useStepEditSchema,
      },
      {
        Label: StepOtherInformationLabel,
        Content: StepOtherInformationContent,
        initialValues: {
          date: formatISO(new Date(), { representation: 'date' }),
        },
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    [initialValues]
  );

  return {
    steps,
    isLoading,
  };
}

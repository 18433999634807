import { createContext } from 'react';
import { PageKey } from '../../enums/page-key';
import { BreadcrumbCtxType } from './breadcrumb-types';

export const initialBreadcrumbs = {
  breadcrumbs: [
    {
      title: 'Domů',
      to: '/',
      key: PageKey.HOME,
    },
  ],
  setBreadcrumbs: () => {},
  currentPage: PageKey.HOME,
};

export const BreadcrumbCtx = createContext<BreadcrumbCtxType>(
  initialBreadcrumbs
);

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'evidenceNumber',
      label: 'Evidenční číslo',
    },
    {
      key: 'referenceNumber',
      label: 'Číslo jednací',
    },
    {
      key: 'type',
      label: 'Typ',
    },
    {
      key: 'challengedVerdictReferenceNumber',
      label: 'Č. j. napadeného rozhodnutí',
    },
    {
      key: 'author',
      label: 'Autor',
    },
    {
      key: 'authorNote',
      label: 'Poznámka autora',
    },
    {
      key: 'comment',
      label: 'Komentář',
    },
    {
      key: 'area',
      label: 'Oblast',
    },
    {
      key: 'issuedDate',
      label: 'Datum vydání',
    },
    {
      key: 'law',
      label: 'Dotčený předpis – Předpis',
    },
    {
      key: 'paragraph',
      label: 'Dotčený předpis – Paragraf',
    },
    {
      key: 'verdict',
      label: 'Rozhodnutí',
    },
    {
      key: 'validFrom',
      label: 'Platnost od',
    },
    {
      key: 'validTo',
      label: 'Platnost do',
    },
    {
      key: 'important',
      label: 'Rozhodnutí významné pro rozhodovací praxi',
    },
    {
      key: 'challengedAtCourt',
      label: 'Rozhodnutí napadeno u soudu',
    },
  ];
}

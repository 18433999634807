import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';
import { usernameRules } from './../../modules/profile/profile-utils';

/**
 * Subject messages
 */
const PASSWORD_RESET: MessageType<
  | 'SUCCESS'
  | 'ERROR'
  | 'USER_DETAILS_NOT_FOUND'
  | 'USERNAME_NOT_FOUND'
  | 'USER_WITHOUT_EMAIL'
> = {
  SUCCESS: [
    'E-mail s odkazem pro nastavení nového hesla byl úspěšně odeslán.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: ['Chyba při vytváření hesla.', SnackbarVariant.ERROR],
  USERNAME_NOT_FOUND: [
    'Chyba při vytváření hesla: Uživatelské jméno neexistuje. Zadejte prosím platné uživatelské jméno.',
    SnackbarVariant.ERROR,
  ],
  USER_DETAILS_NOT_FOUND: [
    'Chyba při vytváření hesla: Kombinace jména, příjmení a e-mailu neexistuje. Zadejte prosím platné údaje.',
    SnackbarVariant.ERROR,
  ],
  USER_WITHOUT_EMAIL: [
    'U tohoto uživatelského účtu není evidován žádný e-mail. Obnova hesla není možná. Proveďte novou registraci uživatele.',
    SnackbarVariant.ERROR,
  ],
};

const REGISTRATION: MessageType<
  | 'SUCCESS'
  | 'ERROR'
  | 'USERNAME_NOT_UNIQUE'
  | 'USER_DETAILS_NOT_UNIQUE'
  | 'COMPLETION_ERROR'
  | 'WRONG_USERNAME_FORMAT'
> = {
  SUCCESS: [
    'Žádost o registraci byla úspěšně odeslána.',
    SnackbarVariant.SUCCESS,
  ],
  USERNAME_NOT_UNIQUE: [
    'Chyba při registraci: Uživatelské jméno již existuje, použijte prosím jiné.',
    SnackbarVariant.ERROR,
  ],
  USER_DETAILS_NOT_UNIQUE: [
    'Chyba při registraci: Kombinace jména, příjmení a e-mailu již existuje, použijte prosím jiné údaje.',
    SnackbarVariant.ERROR,
  ],
  COMPLETION_ERROR: [
    'Při dokončení registrace došlo k chybě, platnost odkazu potvrzení žádosti o registraci vypršela nebo již byla potvrzena.',
    SnackbarVariant.ERROR,
  ],
  ERROR: ['Chyba při registraci.', SnackbarVariant.ERROR],
  WRONG_USERNAME_FORMAT: [
    'Špatný formát uživatelského jména.',
    SnackbarVariant.ERROR,
  ],
};

const DEACTIVATE: MessageType = {
  SUCCESS: ['Uživatel byl úspěšně zneplatněn.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zneplatnění uživatele.', SnackbarVariant.ERROR],
};

const ACTIVATE: MessageType = {
  SUCCESS: ['Uživatel byl úspěšně obnoven.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při obnovení uživatele.', SnackbarVariant.ERROR],
};

const LOGIN: MessageType<
  'ERROR_502' | 'ERROR_503' | 'MANY_REQUESTS' | 'USERNAME_PASSWORD'
> = {
  ERROR_502: [
    'ERROR 502: Proxy server obdržel od hlavního serveru neplatnou odpověď.',
    SnackbarVariant.ERROR,
  ],
  ERROR_503: [
    'ERROR 503: Server dočasně nemůže zpracovat požadavek. Buď je přetížený nebo se provádí údržba.',
    SnackbarVariant.ERROR,
  ],
  MANY_REQUESTS: [
    'Příliš mnoho neúspěšných pokusů o přihlášení. Přihlášení zablokováno na 10 minut.',
    SnackbarVariant.ERROR,
  ],
  USERNAME_PASSWORD: [
    'Špatná kombinace uživatelského jména a hesla.',
    SnackbarVariant.ERROR,
  ],
};

const TWO_FACTOR_AUTH: MessageType<'SUCCESS' | 'ERROR' | 'AUTH_ERROR'> = {
  SUCCESS: ['Ověření bylo úspěšně dokončeno.', SnackbarVariant.SUCCESS],
  ERROR: ['Ve formuláři se nacházejí chyby.', SnackbarVariant.ERROR],
  AUTH_ERROR: ['Chyba ověření.', SnackbarVariant.ERROR],
};

const IDP_PREFILL: MessageType<
  'IDENTITY_PROVIDER_EXTERNAL_ID_ALREADY_REGISTERED' | 'ERROR'
> = {
  IDENTITY_PROVIDER_EXTERNAL_ID_ALREADY_REGISTERED: [
    'Identifikátor externího poskytovatele identity je již v CRŽP evidován. Použijte jiný způsob registrace nebo se přihlaste.',
    SnackbarVariant.ERROR,
  ],
  ERROR: [
    'Nastala chyba při načítaní údajů od externího poskytovatele identity.',
    SnackbarVariant.ERROR,
  ],
};

const REGISTRATION_STATUS_MESSAGE = {
  200: 'Registrace byla dokončena.',
  403: 'Při dokončení registrace došlo k chybě, platnost odkazu potvrzení žádosti o registraci vypršela nebo již byla potvrzena.',
  404: 'Žádost o registraci neexistuje.',
  PENDING: 'Aktivace se vyřizuje, čekejte prosím...',
};

const CREATE: MessageType<
  | 'SUCCESS'
  | 'ERROR'
  | 'USERNAME_NOT_UNIQUE'
  | 'USER_DETAILS_NOT_UNIQUE'
  | 'WRONG_USERNAME_FORMAT'
> = {
  SUCCESS: ['Uživatel byl úspěšně vytvořen', SnackbarVariant.SUCCESS],
  ERROR: ['Při vytváření uživatele došlo k chybě', SnackbarVariant.ERROR],
  USERNAME_NOT_UNIQUE: [
    'Při vytváření uživatele došlo k chybě: Uživatelské jméno již existuje, použijte prosím jiné.',
    SnackbarVariant.ERROR,
  ],
  USER_DETAILS_NOT_UNIQUE: [
    'Při vytváření uživatele došlo k chybě: Kombinace jména, příjmení a e-mailu již existuje, použijte prosím jiné údaje.',
    SnackbarVariant.ERROR,
  ],
  WRONG_USERNAME_FORMAT: [
    `Špatný formát uživatelského jména. Musí splňovat: ${usernameRules}.`,
    SnackbarVariant.ERROR,
  ],
};

const UPDATE: MessageType<
  'SUCCESS' | 'ERROR' | 'USERNAME_NOT_UNIQUE' | 'USER_DETAILS_NOT_UNIQUE'
> = {
  SUCCESS: ['Uživatel byl úspěšně uložen', SnackbarVariant.SUCCESS],
  ERROR: ['Při ukládání dat uživatele došlo k chybě', SnackbarVariant.ERROR],
  USERNAME_NOT_UNIQUE: [
    'Při ukládání dat uživatele došlo k chybě: Uživatelské jméno již existuje, použijte prosím jiné.',
    SnackbarVariant.ERROR,
  ],
  USER_DETAILS_NOT_UNIQUE: [
    'Při ukládání dat uživatele došlo k chybě: Kombinace jména, příjmení a e-mailu již existuje, použijte prosím jiné údaje.',
    SnackbarVariant.ERROR,
  ],
};

export default {
  LOGIN,
  TWO_FACTOR_AUTH,
  PASSWORD_RESET,
  REGISTRATION,
  DEACTIVATE,
  ACTIVATE,
  IDP_PREFILL,
  REGISTRATION_STATUS_MESSAGE,
  CREATE,
  UPDATE,
};

import React, { useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DetailContext, DetailMode } from '@eas/common-web';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function DetailEmptyActionbar() {
  const { mode } = useContext(DetailContext);

  return (
    <>{mode === DetailMode.VIEW && <div style={{ flexWrap: 'wrap' }}></div>}</>
  );
}

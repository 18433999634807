import { AuthoredObject } from '@eas/common-web';

export interface SoapExecutionLog extends AuthoredObject {
  /**
   * Název služby
   */
  service?: string;

  /**
   * Verze služby (rozhraní)
   */
  version?: string;

  /**
   * Jmenný prostor
   */
  namespace?: string;

  /**
   * Payload root
   */
  localPart?: string;

  /**
   * Trvání operace
   */
  duration?: number;

  /**
   * Výsledek operace
   */
  status?: SoapExecutionLogStatus;

  /**
   * Chybové hlášení
   */
  exception?: string;
}

export enum SoapExecutionLogStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum PermissionMuvSewerType {
  /** Přehled typů kanalizace */
  MUV_SEWER_TYPE_LIST = 'MUV_SEWER_TYPE_LIST',

  /** Autocomplete výběr typů kanalizace */
  MUV_SEWER_TYPE_AUTOCOMPLETE = 'MUV_SEWER_TYPE_AUTOCOMPLETE',

  /** Detail typu kanalizace */
  MUV_SEWER_TYPE_DETAIL = 'MUV_SEWER_TYPE_DETAIL',

  /** Vytvoření nového typu kanalizace */
  MUV_SEWER_TYPE_CREATE = 'MUV_SEWER_TYPE_CREATE',

  /** Editace typu kanalizace */
  MUV_SEWER_TYPE_UPDATE = 'MUV_SEWER_TYPE_UPDATE',

  /** Mazání typu kanalizace */
  MUV_SEWER_TYPE_DELETE = 'MUV_SEWER_TYPE_DELETE',

  /** Zneplatnění typu kanalizace */
  MUV_SEWER_TYPE_INVALIDATE = 'MUV_SEWER_TYPE_INVALIDATE',

  /** Obnovení typu kanalizace */
  MUV_SEWER_TYPE_REVALIDATE = 'MUV_SEWER_TYPE_REVALIDATE',
}

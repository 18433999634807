/**
 * Dictionary permissions
 */
export enum PermissionDictionary {
  /** Přehled hodnot číselníků */
  DICTIONARY_LIST = 'DICTIONARY_LIST',

  /** Autocomplete výber hodnot číselníků */
  DICTIONARY_AUTOCOMPLETE = 'DICTIONARY_AUTOCOMPLETE',

  /** Detail hodnoty číselníku */
  DICTIONARY_DETAIL = 'DICTIONARY_DETAIL',

  /** Vytvoření nové hodnoty číselníku */
  DICTIONARY_CREATE = 'DICTIONARY_CREATE',

  /** Editace hodnoty číselníku */
  DICTIONARY_UPDATE = 'DICTIONARY_UPDATE',

  /** Mazání hodnoty číselníku */
  DICTIONARY_DELETE = 'DICTIONARY_DELETE',

  /** Zneplatnění hodnoty číselníku */
  DICTIONARY_INVALIDATE = 'DICTIONARY_INVALIDATE',

  /** Obnovení hodnoty číselníku */
  DICTIONARY_REVALIDATE = 'DICTIONARY_REVALIDATE',

  /** Export hodnot číselníku */
  DICTIONARY_EXPORT = 'DICTIONARY_EXPORT',

  /** Export hodnot všech číselníků do archivu */
  DICTIONARY_EXPORT_ZIP = 'DICTIONARY_EXPORT_ZIP',
}

import { DictionaryBoilerModelAutocomplete } from '../../models';
import { useAutocompleteSource, Filter } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useDictionaryBoilerModels(filters: Filter[] = []) {
  return useAutocompleteSource<DictionaryBoilerModelAutocomplete>({
    url: `${EvidenceAPI.DICTIONARY_BOILER_MODELS}/autocomplete`,
    params: {
      sort: [{ field: 'created', order: 'ASC', type: 'FIELD' }],
      filters: filters,
      size: 30,
    },
  });
}

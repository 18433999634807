import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import {
  useAreaTypes,
  useChluStates,
  useChluRuianStates,
} from './protected-deposit-areas-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const types = useAreaTypes();
  const states = useChluStates();
  const ruianStates = useChluRuianStates();

  return [
    {
      key: 'type',
      label: 'Typ',
      source: types,
    },
    {
      key: 'ruianEntryTime',
      label: 'Datum zapsání do RUIAN',
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'number',
      label: 'Číslo CHLÚ',
    },
    {
      key: 'ruianCode',
      label: 'RÚIAN kód CHLÚ',
    },
    {
      key: 'deposits',
      label: 'Ložiska',
    },
    {
      key: 'minerals',
      label: 'Nerosty ložiska',
    },
    {
      key: 'mineralGroups',
      label: 'Skupiny nerostů',
    },
    {
      key: 'dateIssued',
      label: 'Datum vydání rozhodnutí',
    },
    {
      key: 'dateLegalForce',
      label: 'Datum nabytí právní moci rozhodnutí',
    },
    {
      key: 'referenceNumber',
      label: 'Číslo jednací',
    },
    {
      key: 'coordinates',
      label: 'Souřadnice',
    },
    {
      key: 'area',
      label: 'Plocha',
    },
    {
      key: 'ruianDescription',
      label: 'Popis pro RÚIAN',
    },
    {
      key: 'state',
      label: 'Stav',
      source: states,
    },
    {
      key: 'ruianState',
      label: 'Stav RÚIAN',
      source: ruianStates,
    },
    {
      key: 'ovss',
      label: 'Odbor výkonu státní správy',
    },
  ];
}

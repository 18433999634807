import React, { useContext } from 'react';
import { get } from 'lodash';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import {
  useEventCallback,
  NavigationContext,
  DashboardCardProps,
} from '@eas/common-web';
import { EvidenceUrl } from '../../../enums';
import { CustomCardBase } from './custom-card-base';

export function CardUsers({ report, definitionId }: DashboardCardProps) {
  const { navigate } = useContext(NavigationContext);

  const count = get(report, 'data[0].count');

  const navigateMain = useEventCallback(() => {
    navigate(EvidenceUrl.USERS);
  });

  return (
    <CustomCardBase
      title="Registr uživatelů"
      definitionId={definitionId}
      value={count}
      icon={<EmojiPeopleIcon />}
      navigate={navigateMain}
    />
  );
}

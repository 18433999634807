import React, { useRef, useContext, useMemo } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import * as Yup from 'yup';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  DetailMode,
  PromptContext,
  usePrompts,
  FormInlineTableField,
  InlineTableFieldCells,
  useFormSelector,
} from '@eas/common-web';
import { SubjectRegistration, CeniaDetails } from '../../../models';
import { EvidenceAPI, Permission, Messages } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
function callApi(
  subjectRegistration: SubjectRegistration,
  values: {
    ceniaDetails: { number?: string; note?: string }[];
  }
) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECT_REQUESTS}/${subjectRegistration.id}/cenia-details`,
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        ...subjectRegistration,
        ...values,
      }),
    }
  );
}

const PROMPT_KEY = 'EDIT_CENIA_DETAILS';

export function useEditCeniaDetailsDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source, mode } = useContext<
    DetailHandle<SubjectRegistration>
  >(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { reload, hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Spisová služba CENIA',
        dialogText: 'Úprava údajů',
        FormFields: function Body() {
          const { ceniaDetails } = useFormSelector(
            (values: { ceniaDetails: CeniaDetails[] }) => ({
              ceniaDetails: values.ceniaDetails,
            })
          );
          const ceniaDetailsNumberCell = InlineTableFieldCells.useInlineTextFieldFactory(
            {
              collectionDatakey: 'ceniaDetails',
            }
          );
          const ceniaDetailsNoteCell = InlineTableFieldCells.useInlineTextFieldFactory(
            {
              collectionDatakey: 'ceniaDetails',
              disabled: (index) =>
                ceniaDetails[index]?.allowNoteUpdate === false,
            }
          );
          const ceniaDetailsColumns = useMemo(
            () => [
              {
                datakey: 'number',
                name: 'Ev.č./Č.j.',
                width: 300,
                CellComponent: ceniaDetailsNumberCell,
              },
              {
                datakey: 'note',
                name: 'Poznámka',
                width: 300,
                CellComponent: ceniaDetailsNoteCell,
              },
            ],
            []
          );

          return (
            <>
              <FormInlineTableField
                name="ceniaDetails"
                label="Spisová služba CENIA"
                columns={ceniaDetailsColumns}
                showDetailBtnCond={() => false}
                showRadioCond={() => false}
                labelOptions={{ hide: true }}
              />
            </>
          );
        },
        dialogWidth: 700,
        formInitialValues: {
          ceniaDetails: source.data?.ceniaDetails ?? [],
        },
        formValidationSchema: Yup.object().shape({
          ceniaDetails: Yup.array().of(
            Yup.object<CeniaDetails>().test('', '', function (val) {
              const { createError } = this;

              if (!val?.number) {
                return createError({
                  path: 'ceniaDetails',
                  message: `Pole Ev.č./Č.j. nesmí být prázdné`,
                });
              }

              return true;
            })
          ),
        }),
      },
    ],
    [source.data]
  );

  const handleEdit = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: {
        ceniaDetails: { number?: string; note?: string }[];
      }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(source.data!, values);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.SubjectRegistration.EDIT.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.SubjectRegistration.EDIT.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showEditCeniaDetailsButton =
    mode === DetailMode.VIEW &&
    hasPermission(Permission.Subject.SUBJECT_REQUEST_AUTHORIZE_ALL);

  return {
    handleEdit,
    showEditCeniaDetailsButton,
  };
}

import { AuthoredObject } from './authored-object';
import { BasicAgenda, SpecifiedAgenda, AgendaSpecification } from './agenda';
import { Subject } from './subject';
import { ArchivedFile } from './archived-file';

/**
 * Typ Zmocnění
 */
export enum EmpowermentType {
  /**
   * Bez specifikace
   */
  BASIC = 'BASIC',

  /**
   * Se specifikacemi
   */
  SPECIFIED = 'SPECIFIED',
}

/**
 * Zmocnění
 */
export interface Empowerment extends AuthoredObject {
  /**
   * Zmocnitel
   */
  empowerer?: Subject;

  /**
   * Zmocněnec
   */
  agent?: Subject;

  /**
   * Typ zmocnění
   */
  type?: EmpowermentType;

  /**
   * Agenda
   */
  agenda?: BasicAgenda | SpecifiedAgenda;

  /**
   * Typ hlášení
   */
  specifications?: AgendaSpecification[];

  /**
   * Datum platnosti: od
   */
  validFrom?: string;

  /**
   * Datum platnosti: do
   */
  validTo?: string;

  /**
   * Dokument zmocnění
   */
  empowermentDocument?: ArchivedFile;

  /**
   * Příznak platnosti
   */
  active?: boolean;
}

import { useRef, useContext, useState } from 'react';
import {
  AbortableFetch,
  SnackbarContext,
  useEventCallback,
  DetailHandle,
  DetailContext,
} from '@eas/common-web';
import { unstable_batchedUpdates } from 'react-dom';
import { User } from '../../models';
import { passwordReset } from './users-api';
import { Messages } from '../../enums';

export function usePasswordReset() {
  const { source } = useContext<DetailHandle<User>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState<boolean>(false);
  const fetch = useRef<AbortableFetch | null>(null);

  const submitPasswordReset = useEventCallback(async (user: User) => {
    try {
      setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = passwordReset(user);

      await fetch.current.raw();

      unstable_batchedUpdates(() => {
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);

      if (e.name !== 'AbortError') {
        showSnackbar(...Messages.User.PASSWORD_RESET.ERROR);

        throw e;
      }
      return undefined;
    }
  });

  const handleSubmit = useEventCallback(async () => {
    if (!source.data) {
      return;
    }

    const user = source.data;

    if (user) {
      await submitPasswordReset(user);

      showSnackbar(...Messages.User.PASSWORD_RESET.SUCCESS);
    }
  });

  return {
    loading,
    handleSubmit,
  };
}

import React from 'react';
import { useRoleRequestTypes } from '../../role-request/role-requests-api';
import { StepTypeForm } from './step-type-types';

export function StepTypeLabel({ stepValues }: { stepValues?: StepTypeForm }) {
  const types = useRoleRequestTypes();

  return (
    <>
      Typ žádosti:{' '}
      {<b>{types.items.find((type) => stepValues?.type === type.id)?.name}</b>}
    </>
  );
}

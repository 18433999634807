import React, { useContext, useState, ComponentType, forwardRef } from 'react';
import { findIndex } from 'lodash';
import {
  TableSearchbar,
  TableContext,
  useEventCallback,
  useDebouncedCallback,
  Filter,
  ApiFilterOperation,
  UserContext,
  TableSearchbarHandle,
} from '@eas/common-web';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Me } from '../../../../models';

export const useStyles = makeStyles(() => ({
  rowWrapper: {
    width: 'calc(100% - 20px)',
    '& > div': {
      marginLeft: '0 !important',
      marginRight: '0 !important',

      '& > div': {
        marginLeft: '0 !important',
        marginRight: '0 !important',
      },
    },
  },
  fieldWrapper: {
    width: '100%',
  },
  filterRow: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterComponent: {
    width: 289,
    padding: '3px 5px',
    display: 'flex',
    flexDirection: 'column',
  },
  textfield: {
    height: 32,
  },
  toggle: {
    margin: '10px 0',
  },
  reset: {
    margin: '10px',
  },
  input: {
    backgroundColor: '#fff',
    padding: '3px 8px',
    height: 32,
  },
}));

interface FilterCellProps {
  value: string;
  onChange: (value: any) => void;
}

export interface SpecialFilter {
  label: string;
  filterkey: string;
  operation?: ApiFilterOperation;
  FilterCell: ComponentType<FilterCellProps>;
  createFilter?: (filterKey: string, value: any) => Filter;
}

function deriveFiltersState(filters: SpecialFilter[]) {
  return filters.map((filter) =>
    filter.createFilter
      ? filter.createFilter(filter.filterkey, null)
      : ({
          value: null,
          operation: filter?.operation ?? ApiFilterOperation.CONTAINS,
          field: filter.filterkey,
          asciiFolding: false,
        } as Filter)
  );
}

export function historySearchBarFactory({
  fields,
  permission,
}: {
  fields: SpecialFilter[];
  permission: string;
}) {
  return forwardRef<TableSearchbarHandle, any>(function HistorySearchBar(
    _,
    ref
  ) {
    /**
     * Styles
     */
    const classes = useStyles();

    const [showSpecialFilters, setShowSpecialFilters] = useState(false);

    const { hasPermission } = useContext<UserContext<Me>>(UserContext);

    /**
     * Internal state of inputs.
     */
    const [filters, setFilters] = useState<Filter[]>(
      deriveFiltersState(fields)
    );
    const isAnyFilterActive = !filters.find((filter) => !!filter.value);

    const { setPreFilters } = useContext(TableContext);

    /**
     * Saves current filters states.
     */
    const handleSave = useEventCallback(() => {
      setPreFilters(filters);
    });

    const [handleDebouncedSave] = useDebouncedCallback(handleSave, 500);

    /**
     * Changes the value of the filter.
     */
    const handleFilterValueChange = useEventCallback(
      (index: number, value: any) => {
        const field = fields[index];

        let filter = { ...filters[index], value } as Filter;
        if (field.createFilter) {
          filter = field.createFilter(field.filterkey, value);
        }

        setFilters((filters) => [
          ...filters.slice(0, index),
          filter,
          ...filters.slice(index + 1),
        ]);

        handleDebouncedSave();
      }
    );

    const handleResetFilters = useEventCallback(() => {
      setFilters((filters) => [...filters.map((f) => ({ ...f, value: null }))]);

      handleDebouncedSave();
    });

    if (!hasPermission(permission)) {
      return <TableSearchbar ref={ref} />;
    }

    return (
      <div className={classes.rowWrapper}>
        <TableSearchbar ref={ref} />
        <Button
          variant="contained"
          size="small"
          disableElevation
          className={classes.toggle}
          onClick={() => setShowSpecialFilters((visible) => !visible)}
        >
          {showSpecialFilters ? 'Skrýt filtry' : 'Zobrazit filtry'}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          disableElevation
          className={classes.reset}
          onClick={handleResetFilters}
          disabled={isAnyFilterActive}
        >
          Zrušit filtry
        </Button>
        {showSpecialFilters && (
          <>
            <div className={classes.filterRow}>
              {fields.map((filter) => {
                const index = findIndex(
                  fields,
                  (f) => f.filterkey === filter.filterkey
                );

                return (
                  <div key={index} className={classes.filterComponent}>
                    <Typography variant="body1" component="div">
                      {filter.label}
                      <div className={classes.fieldWrapper}>
                        <filter.FilterCell
                          value={filters[index]?.value}
                          onChange={(value: string) => {
                            handleFilterValueChange(index, value);
                          }}
                        />
                      </div>
                    </Typography>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  });
}

import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { PageKey, EvidenceAPI, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import DictionaryRuianRegionFields from './dict-ruian-region-fields';
import { Region } from '../../models';
import { useColumns } from './dict-ruian-region-columns';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';

export function DictionaryRuianRegions() {
  const evidence = useRuianEvidence<Region>({
    identifier: PageKey.DICTIONARY_RUIAN_REGIONS,
    apiProps: {
      url: EvidenceAPI.ADDRESS_REGION,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'RUIAN - Vyšší územní samoprávní celky',
      reportTag: ExportTags.DICTIONARY_REGION,
    },
    detailProps: {
      entityName: EntityName.RUIAN_REGION,
      FieldsComponent: DictionaryRuianRegionFields,
      toolbarProps: {
        title: 'RUIAN - Vyšší územní samoprávní celek (nový kraj)',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import React, { useMemo, useContext } from 'react';
import {
  TableFieldColumn,
  InlineTableFieldCells,
  DictionaryAutocomplete,
  FormContext,
  useEventCallback,
} from '@eas/common-web';
import { useIrzActivities } from '../../dict-irz-activity/dict-irz-activities-api';
import { useEPrtrActivities } from '../../dict-e-prtr-activity/dict-e-prtr-activities-api';
import { ActivityRecord } from '../../../models';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import {
  useIppcActivities,
  getIppcActivity,
} from '../../dict-ippc-activity/dict-ippc-activities-api';

export function useSecondaryEPrtrActivityColumns(): TableFieldColumn<
  ActivityRecord
>[] {
  const { getFieldValues, setFieldValue } = useContext(FormContext);

  const handleActivityChange = useEventCallback(async (index) => {
    const activity = getFieldValues()?.['secondaryEprtrActivities']?.[index]
      .activity;

    if (activity) {
      const wiredIppcActivities = await getIppcActivity(activity?.id);
      if (wiredIppcActivities.length === 1) {
        setFieldValue(
          `secondaryEprtrActivities[${index}].ippcActivity`,
          wiredIppcActivities[0]
        );
      }
    } else {
      setFieldValue(
        `secondaryEprtrActivities[${index}].ippcActivity`,
        undefined
      );
    }
  });

  const CountCell = InlineTableFieldCells.useInlineNumberFieldFactory<
    ActivityRecord
  >({
    collectionDatakey: 'secondaryEprtrActivities',
  });

  const useActivityCell = (notifyChange: () => void) =>
    InlineTableFieldCells.useInlineAutocompleteCellFactory<
      ActivityRecord,
      DictionaryAutocomplete
    >({
      dataHook: useEPrtrActivities,
      collectionDatakey: 'secondaryEprtrActivities',
      labelMapper: dictionaryLabelMapper,
      notifyChange,
    });

  const IppcActivityCell = InlineTableFieldCells.useInlineAutocompleteCellFactory<
    ActivityRecord,
    DictionaryAutocomplete
  >({
    dataHook: useIppcActivities,
    collectionDatakey: 'secondaryEprtrActivities',
    labelMapper: dictionaryLabelMapper,
  });

  return useMemo(
    () => [
      {
        name: 'Vedlejší E-PRTR činnost',
        datakey: 'activity',
        width: 400,
        CellComponent: function Cell(props) {
          const ActivityCell = useActivityCell(() =>
            handleActivityChange(props.index)
          );

          return <ActivityCell {...props} />;
        },
      },
      {
        name: 'Počet zařízení',
        datakey: 'facilityCount',
        width: 100,
        CellComponent: CountCell,
      },
      {
        name: 'Činnost IPPC',
        datakey: 'ippcActivity',
        width: 200,
        CellComponent: IppcActivityCell,
      },
    ],
    []
  );
}

export function useSecondaryIrzActivityColumns(): TableFieldColumn<
  ActivityRecord
>[] {
  const CountCell = InlineTableFieldCells.useInlineNumberFieldFactory<
    ActivityRecord
  >({
    collectionDatakey: 'secondaryIrzActivities',
  });

  const ActivityCell = InlineTableFieldCells.useInlineAutocompleteCellFactory<
    ActivityRecord,
    DictionaryAutocomplete
  >({
    dataHook: useIrzActivities,
    collectionDatakey: 'secondaryIrzActivities',
    labelMapper: dictionaryLabelMapper,
  });

  return useMemo(
    () => [
      {
        name: 'Vedlejší činnost dle zákona o IRZ a ISPOP',
        datakey: 'activity',
        width: 400,
        CellComponent: ActivityCell,
      },
      {
        name: 'Počet zařízení',
        datakey: 'facilityCount',
        width: 200,
        CellComponent: CountCell,
      },
    ],
    []
  );
}

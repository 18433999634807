import React from 'react';
import {
  useFormSelector,
  FormPanel,
  FormCustomField,
  FormTextField,
  FormAutocomplete,
  FormTableField,
  TableFieldColumn,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import {
  IrzFacilityRequest,
  SimpleLog,
  IrzRequestState,
} from '../../../models';
import { SubjectFormPanel } from '../../../composite/subject-fields/subject-form-panel';
import { CompareIrzRequestFields } from '../../../composite/compare-irz-request/compare-irz-request-fields';
import { EvidenceUrl } from '../../../enums';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import {
  dictionaryLabelMapper,
  dictionaryNoCodeLabelMapper,
} from '../../../components/form/mappers/label-mappers';
import { useCzRiverBasins } from '../../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { AddressLabel } from '../../../components/form/address-field/address-label';
import { DetailNavigationButton } from '../../../components/detail-navigation-button/detail-navigation-button';
import { HelpLabel } from '../../../components/help/help-label';
import { historyContentCell } from '../../../components/evidence/history-evidence/history-fields';
import { useHistoryDescriptors } from './../../irz-facility/irz-facilities-history';

export function ChangeFields() {
  const naceCzItems = useNaceCzItems();
  const czRiverBasins = useCzRiverBasins();

  const diffDescriptors = useHistoryDescriptors();

  const columns: TableFieldColumn<SimpleLog>[] = [
    {
      name: 'Typ změny',
      datakey: 'content.key',
      width: 250,
      CellComponent: function Cell({ value }) {
        const changeDescriptor = diffDescriptors.find((d) => d.key === value);

        return (
          <Typography variant="body1">
            {changeDescriptor?.label || value}
          </Typography>
        );
      },
    },
    {
      name: 'Změna',
      datakey: 'content',
      width: 350,
      CellComponent: ({ value }) => historyContentCell(diffDescriptors, value),
    },
  ];

  const { irzFacility, requestFacilityData, state } = useFormSelector(
    ({ irzFacility, requestFacilityData, state }: IrzFacilityRequest) => ({
      irzFacility,
      requestFacilityData,
      state,
    })
  );
  return (
    <>
      <FormPanel label="Provozovna">
        <FormTextField
          name="irzFacility.name"
          label={
            <HelpLabel
              label="Název"
              code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_NAME"
            />
          }
        />
        <FormTextField
          name="irzFacility.facilityId"
          label={
            <HelpLabel
              label="IČP"
              code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_FACILITY_ID"
            />
          }
        />
        <FormAutocomplete
          name="irzFacility.czNace"
          label={
            <HelpLabel
              label="CZ-NACE"
              code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_CZ_NACE"
            />
          }
          source={naceCzItems}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          name="irzFacility.czRiverBasinDistrict"
          label={
            <HelpLabel
              label="Oblast povodí ČR"
              code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_CZ_RIVER_BASIN_DISTRICT"
            />
          }
          source={czRiverBasins}
          tooltipMapper={dictionaryNoCodeLabelMapper}
          labelMapper={dictionaryNoCodeLabelMapper}
        />
        <FormCustomField>
          <DetailNavigationButton
            url={EvidenceUrl.IRZ_FACILITIES}
            id={irzFacility?.id}
          />
        </FormCustomField>
        <AddressLabel
          name="irzFacility.address"
          code="IRZ_FACILITY_REQUEST_IRZ_FACILITY_ADDRESS"
        />
      </FormPanel>
      <SubjectFormPanel
        label="Provozovatel"
        codePrefix="IRZ_FACILITY_REQUESTS_IRZ_FACILITY"
        autocomplete={{
          name: 'irzFacility.operator',
          label: 'Název provozovatele',
        }}
        addressLabel="Adresa sídla"
      />
      <FormPanel label="Změněné údaje">
        {state !== IrzRequestState.APPROVED && (
          <CompareIrzRequestFields
            originalData={irzFacility}
            requestData={requestFacilityData}
            showOnlyAddress
          />
        )}
        {state === IrzRequestState.APPROVED && (
          <FormCustomField>
            <FormTableField<SimpleLog>
              name="diff"
              labelOptions={{
                hide: true,
              }}
              layoutOptions={{
                noSpacing: true,
              }}
              showToolbar={false}
              showRadioCond={() => false}
              showDetailBtnCond={() => false}
              visibleActionsColumn={false}
              maxRows={15}
              disabled={true}
              columns={columns}
            />
          </FormCustomField>
        )}
      </FormPanel>
    </>
  );
}

import React, { useContext } from 'react';
import {
  useMainActivities,
  useEPrtrAndIppcActivities,
} from '../../irz-facility/irz-facilities-api';
import { useIrzActivities } from '../../dict-irz-activity/dict-irz-activities-api';
import {
  FormContext,
  useFormSelector,
  FormAutocomplete,
  FormTextField,
  FormInlineTableField,
  FormPanel,
  useEventCallback,
  FormCheckboxGroup,
} from '@eas/common-web';
import {
  useSecondaryEPrtrActivityColumns,
  useSecondaryIrzActivityColumns,
} from './step-activities-columns';
import { MainActivityType, ActivityRecord } from '../../../models';
import { StepActivitiesForm } from './step-activities-types';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { ProcessForm } from '../irz-facility-split-types';
import { useStepActivities } from './step-activities-hook';
import { HelpLabel } from '../../../components/help/help-label';
import { cloneDeep } from 'lodash';

export function StepActivitiesContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  useStepActivities(allValues);

  const types = useMainActivities();
  const irzActivities = useIrzActivities();
  const { ePrtrActivities, ippcActivities } = useEPrtrAndIppcActivities(
    'activities'
  );

  const { setFieldValue } = useContext(FormContext);

  const {
    activityTypes,
    mainIrzActivity,
    secondaryIrzActivities,
  } = useFormSelector(({ activities }: StepActivitiesForm) => ({
    activityTypes: activities?.activityTypes,
    mainIrzActivity: activities?.mainIrzActivity,
    secondaryIrzActivities: activities?.secondaryIrzActivities ?? [],
  }));

  const handleMainActivityTypeChanged = useEventCallback(() => {
    if (activityTypes?.includes(MainActivityType.E_PRTR)) {
      if (mainIrzActivity) {
        const newSecondaryIrzActivities = cloneDeep(secondaryIrzActivities);
        newSecondaryIrzActivities?.push(mainIrzActivity);

        setFieldValue('activities.secondaryIrzActivities', [
          ...newSecondaryIrzActivities,
        ]);
        setFieldValue('activities.mainIrzActivity', undefined);
      }
    }
    if (!activityTypes?.includes(MainActivityType.IRZ)) {
      setFieldValue('activities.mainIrzActivity', undefined);
      setFieldValue('activities.secondaryIrzActivities', undefined);
    }
    if (!activityTypes?.includes(MainActivityType.E_PRTR)) {
      setFieldValue('activities.mainEprtrActivity', undefined);
      setFieldValue('activities.secondaryEprtrActivities', undefined);
    }
  });

  const secondaryIrzActivitiesColumns = useSecondaryIrzActivityColumns();
  const secondaryEPrtrActivitiesColumns = useSecondaryEPrtrActivityColumns();

  return (
    <FormPanel label="Vyplňte">
      <FormCheckboxGroup
        label={
          <HelpLabel
            label="Typ činnosti"
            code="IRZ_FACILITY_SPLIT_ACTIVITIES_ACTIVITY_TYPES"
            required
          />
        } // Povinné pole
        source={types}
        notifyChange={handleMainActivityTypeChanged}
        name="activities.activityTypes"
      />
      {activityTypes?.includes(MainActivityType.E_PRTR) && (
        <>
          <FormAutocomplete
            name="activities.mainEprtrActivity.activity"
            label={
              <HelpLabel
                label="Hlavní E-PRTR činnost"
                code="IRZ_FACILITY_SPLIT_ACTIVITIES_MAIN_EPRTR_ACTIVITY_ACTIVITY"
                required
              />
            } // Povinné pole
            source={ePrtrActivities}
            labelMapper={dictionaryLabelMapper}
            tooltipMapper={dictionaryLabelMapper}
          />

          <FormTextField
            name="activities.mainEprtrActivity.facilityCount"
            label={
              <HelpLabel
                label="Počet zařízení hlavní E-PRTR činnosti"
                code="IRZ_FACILITY_SPLIT_ACTIVITIES_MAIN_EPRTR_ACTIVITY_FACILITY_COUNT"
                required
              />
            } // Povinné pole
          />
          <FormInlineTableField<ActivityRecord>
            name="activities.secondaryEprtrActivities"
            label={
              <HelpLabel
                label="Seznam vedlejších E-PRTR činností"
                code="IRZ_FACILITY_SPLIT_ACTIVITIES_SECONDARY_EPRTR_ACTIVITIES"
              />
            } // Povinné pole, nesmí obsahovat hlavní činnost
            columns={secondaryEPrtrActivitiesColumns}
            maxRows={5}
          />
        </>
      )}

      {activityTypes?.includes(MainActivityType.IRZ) && (
        <>
          {!activityTypes?.includes(MainActivityType.E_PRTR) && (
            <>
              <FormAutocomplete
                name="activities.mainIrzActivity.activity"
                label={
                  <HelpLabel
                    label="Hlavní činnost dle přílohy zákona o IRZ a ISPOP"
                    code="IRZ_FACILITY_SPLIT_ACTIVITIES_MAIN_IRZ_ACTIVITY_ACTIVITY"
                    required
                  />
                } // Povinné pole
                source={irzActivities}
                labelMapper={dictionaryLabelMapper}
                tooltipMapper={dictionaryLabelMapper}
              />

              <FormTextField
                name="activities.mainIrzActivity.facilityCount"
                label={
                  <HelpLabel
                    label="Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP"
                    code="IRZ_FACILITY_SPLIT_ACTIVITIES_MAIN_IRZ_ACTIVITY_FACILITY_COUNT"
                    required
                  />
                } // Povinné pole, nejméně 1 zařízení
              />
            </>
          )}
          <FormInlineTableField<ActivityRecord>
            name="activities.secondaryIrzActivities"
            label={
              <HelpLabel
                label="Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP"
                code="IRZ_FACILITY_SPLIT_ACTIVITIES_SECONDARY_IRZ_ACTIVITIES"
              />
            } // Povinné pole, nesmí obsahovat hlavní činnost
            columns={secondaryIrzActivitiesColumns}
            maxRows={5}
          />
        </>
      )}

      <FormAutocomplete
        name="activities.ippcActivity"
        label={
          <HelpLabel
            label="Činnost IPPC"
            code="IRZ_FACILITY_SPLIT_ACTIVITIES_IPPC_ACTIVITY"
          />
        }
        source={ippcActivities}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

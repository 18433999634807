import React, { useState, useContext, useRef } from 'react';
import * as Yup from 'yup';
import {
  FormContext,
  useEventCallback,
  useFormSelector,
  usePrompts,
  FormTextField,
  PromptContext,
} from '@eas/common-web';
import { Wgs84Coordinates } from '../../../models';
import { Address } from '../../../models/address';
import { get } from 'lodash';
import { AddressFieldHandle } from '../address-field/address-field-types';
import { HelpLabel } from '../../help/help-label';
import { dms2dd, parse } from '../../../utils/latlng';

export function useEditLocationPanel({
  addressName,
  locationName,
  disableForeignCountry,
}: {
  locationName: string;
  addressName?: string;
  disableForeignCountry: boolean;
}) {
  /**
   *
   */
  const [mapMode, setMapMode] = useState<'POINT_ADD' | 'PREVIEW'>('PREVIEW');
  const addressFieldRef = useRef<AddressFieldHandle>(null);

  /**
   * Ctx stuff.
   */
  const { testPrompt } = useContext(PromptContext);
  const { setFieldValue, editing } = useContext(FormContext);

  const { addressWgs84Coordinates, country } = useFormSelector(
    (data: { [key: string]: Address }) => ({
      country: addressName ? get(data, addressName)?.country : undefined,
      addressWgs84Coordinates: addressName
        ? get(data, addressName)?.addressPlaceRuian?.wgs84Coordinates
        : undefined,
    })
  );

  usePrompts(
    [
      {
        key: 'FILL_COORDINATES',
        dialogTitle: 'Zadat souřadnice',
        dialogText: 'Vyplňte hodnoty zeměpisné šířky a délky.',
        FormFields: function Body() {
          return (
            <>
              <FormTextField
                name="lat"
                label={
                  <HelpLabel
                    label="Zeměpisná šířka"
                    code="LOCATION_LATITUDE"
                    required
                  />
                }
              />
              <FormTextField
                name="lon"
                label={
                  <HelpLabel
                    label="Zeměpisná délka"
                    code="LOCATION_LATITUDE"
                    required
                  />
                }
              />
            </>
          );
        },
        dialogWidth: 600,
        formValidationSchema: Yup.object().shape({
          lat: Yup.mixed().test('', '', function (val) {
            const { createError } = this;

            try {
              dms2dd(parse(val));
            } catch (error) {
              return createError({
                path: 'lat',
                message: 'Nesplňuje pravidla zápisu, například 50° 4\' 31.8"',
              });
            }

            return true;
          }),
          lon: Yup.mixed().test('', '', function (val) {
            const { createError } = this;

            try {
              dms2dd(parse(val));
            } catch (error) {
              return createError({
                path: 'lon',
                message:
                  'Nesplňuje pravidla zápisu, například 14° 26\' 12.9901"',
              });
            }

            return true;
          }),
        }),
      },
    ],
    []
  );

  /**
   * Toolbar actions.
   */
  const addPoint = useEventCallback((coords: Wgs84Coordinates) => {
    setFieldValue(locationName, coords);
    setMapMode('PREVIEW');
  });

  const clearPoint = useEventCallback(() => {
    setFieldValue(locationName, undefined);
  });

  const setAddPointMode = useEventCallback(() => {
    setMapMode('POINT_ADD');
  });

  const fillMap = useEventCallback(() => {
    if (addressWgs84Coordinates) {
      setFieldValue(locationName, addressWgs84Coordinates);
    }
  });

  const fillCoordinates = useEventCallback(() => {
    testPrompt({
      key: 'FILL_COORDINATES',
      submitCallback: async (values) => {
        setFieldValue(locationName, {
          lat: dms2dd(parse(values.lat)),
          lon: dms2dd(parse(values.lon)),
        });
      },
    });
  });

  const canFillMap = !!addressWgs84Coordinates;

  const clearAddress = useEventCallback(() => {
    if (addressName) {
      if (disableForeignCountry) {
        setFieldValue(addressName, { country });
      } else {
        setFieldValue(addressName, {});
      }
    }
  });

  const clearAll = useEventCallback(() => {
    clearPoint();
    clearAddress();
  });

  return {
    mapMode,
    addPoint,
    clearPoint,
    setAddPointMode,
    clearAll,
    fillMap,
    fillCoordinates,
    canFillMap,
    editing,
    addressFieldRef,
  };
}

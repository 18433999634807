import { useContext, useRef } from 'react';
import {
  SnackbarContext,
  PromptContext,
  usePrompts,
  AbortableFetch,
  useEventCallback,
  abortableFetch,
} from '@eas/common-web';
import { UserExternalId } from '../../models';
import { useIdentityProviders } from '../../components/identity-provider/identity-provider-api';
import { EvidenceAPI, Messages } from '../../enums';

function callApi(id: string) {
  return abortableFetch(`${EvidenceAPI.USERS}/external-id/${id}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
}

const PROMPT_KEY = 'REMOVE_IDP';

export function useIdpRemoveDialog(
  userExternalId: UserExternalId | undefined,
  reloadProfile: VoidFunction = () => {}
) {
  /**
   * Context stuff.
   */
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const idpSource = useIdentityProviders();

  const currentIdp = idpSource.items.find(
    (i) => i.id === userExternalId?.identityProvider
  );
  const currentIdpName = currentIdp?.name;

  usePrompts(
    [
      {
        key: `${PROMPT_KEY} - ${currentIdp?.id}`,
        dialogWidth: 600,
        dialogTitle: `Odstranění propojení s ${currentIdpName}`,
        dialogText: `Skutečně chcete zrušit propojení s externím poskytovatelem identity - ${currentIdpName}?`,
      },
    ],
    [currentIdpName]
  );

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleRemoveConnection = useEventCallback(() => {
    testPrompt({
      key: `${PROMPT_KEY} - ${currentIdp?.id}`,
      submitCallback: async () => {
        try {
          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = await callApi(userExternalId?.id ?? '');
          await fetch.current.raw();
          showSnackbar(...Messages.Profile.REMOVE_IDP.SUCCESS);
          reloadProfile();
        } catch (err) {
          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Profile.REMOVE_IDP.ERROR);
          }
        }
      },
    });
  });

  return {
    handleRemoveConnection,
  };
}

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import logo1 from './logo1.svg';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 120,
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      flexShrink: 0,
      zIndex: 1000,
    },
    logoWrapper: {
      width: '100%',
      height: 80,
      display: 'flex',
      justifyItems: 'center',
      justifyContent: 'center',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
    },
    logo: {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
    },
    bottom: {
      height: 40,
      alignSelf: 'flex-end',
      backgroundColor: theme.palette.primary.dark,
    },
    bottomText: {
      padding: '6px 0',
    },
  })
);

export function Footer() {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Hidden smDown>
        <Grid item md={2} xs={1} />
      </Hidden>
      <Grid item md={8} xs={12}>
        <Typography component="nav" className={classes.logoWrapper}>
          <Link to="/" className={classes.link}>
            <img src={logo1} alt="logo" className={classes.logo} />
          </Link>
        </Typography>
      </Grid>
      <Hidden smDown>
        <Grid item xs={1} md={2} />
      </Hidden>
      <Grid item xs={1} md={2} className={classes.bottom} />
      <Grid item xs={10} md={8} className={classes.bottom}>
        <Typography
          align="center"
          variant="body1"
          className={classes.bottomText}
        >
          Všechna práva vyhrazena © 2020
        </Typography>
      </Grid>
      <Grid item xs={1} md={2} className={classes.bottom} />
    </Grid>
  );
}

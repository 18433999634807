import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import {
  useNotificationEvents,
  useNotificationTypes,
} from './notification-templates-api';
import { NotificationTemplate } from '../../models';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export function useColumns(): TableColumn<NotificationTemplate>[] {
  return [
    {
      datakey: 'type',
      filterkey: 'type.id',
      sortkey: 'type.name',
      name: 'Typ',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useNotificationTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useNotificationTypes),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'event',
      sortkey: 'event.name',
      filterkey: 'event.id',
      name: 'Událost',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useNotificationEvents
      ),
      valueMapper: TableCells.useSelectCellFactory(useNotificationEvents),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'subject',
      name: 'Předmět',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'active',
      name: 'Aktivní',
      width: 100,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
    },
  ];
}

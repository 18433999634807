import React from 'react';
import { ProcessForm } from '../production-site-merge-types';

export function StepMergingProductionSiteLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const productionSite = allValues?.mergingProductionSite;

  return (
    <>
      Průmyslové místo ke sloučení: <b>{productionSite?.name}</b>
    </>
  );
}

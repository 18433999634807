import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useEmpowermentStates } from './empowerment-requests-api';
import { useSubjectIdentificators } from '../subject/subjects-api';
import { eqFilterParams } from '@eas/common-web';
import { useAgendas } from '../agenda/agendas-api';
import { historyValueMapper } from '../../components/evidence/history-evidence/history-utils';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const empowermentStates = useEmpowermentStates();
  const subjectIdentificators = useSubjectIdentificators();
  const params = eqFilterParams({ field: 'empowerable', value: true });
  const agendas = useAgendas(params);

  return [
    {
      key: 'author',
      label: 'Autor žádosti',
    },
    {
      key: 'authorRole',
      label: 'Role autora žádosti',
    },
    {
      key: 'otherSubject.subjectType',
      label: 'Typ subjektu',
    },
    {
      key: 'otherSubject.type',
      label: 'Typ identifikátoru subjektu',
      source: subjectIdentificators,
    },
    {
      key: 'otherSubject.value',
      label: 'Číslo identifikátoru',
    },
    {
      key: 'empowerer',
      label: 'Zmocnitel',
    },
    {
      key: 'agent',
      label: 'Zmocněnec',
    },
    {
      key: 'validFrom',
      label: 'Platnost od',
    },
    {
      key: 'validTo',
      label: 'Platnost do',
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
    {
      key: 'state',
      label: 'Stav žádosti',
      source: empowermentStates,
    },
    {
      key: 'empowermentDocument',
      label: 'Příloha (plná moc konvertovaná)',
    },
    {
      key: 'agenda',
      label: 'Agenda',
      source: agendas,
    },
    {
      key: 'specificationRequests',
      label: 'Typ hlášení',
      valueMapper: (change) =>
        historyValueMapper(change, getSpecificationLabel),
    },
    {
      key: 'ceniaDetails',
      label: 'Spisová služba CENIA',
      valueMapper: (change) => historyValueMapper(change, getCeniaDetailsLabel),
    },
  ];
}

function getSpecificationLabel(key: string) {
  switch (key) {
    case 'specification':
      return 'Název';
    case 'approvalState':
      return 'Stav';
  }
}

function getCeniaDetailsLabel(key: string) {
  switch (key) {
    case 'number':
      return 'Ev.č./Č.j.';
    case 'note':
      return 'Poznámka';
  }
}

import React, { useMemo } from 'react';
import { Evidence2 } from '@eas/common-web';
import { SystemsFields } from './systems-fields';
import { useColumns } from './systems-columns';
import { useValidationSchema } from './systems-schema';
import {
  PageKey,
  EvidenceAPI,
  Permission,
  IncludeOptions,
  Messages,
  EntityName,
} from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { SystemsActionbar } from './systems-actionbar';
import { System, Exception } from '../../models';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useHistoryDescriptors } from './systems-history';
import { DictionaryActionbar } from '../../components/evidence/dictionary-evidence/dictionary-actionbar';
import { createItemFactory, updateItemFactory } from '../../utils/custom-crud';
import { getErrorMessage } from '../../utils/get-message';

export function Systems() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission({
    options: {
      NEW: [Permission.System.SYSTEM_CREATE],
      EDIT: [Permission.System.SYSTEM_UPDATE],
      REMOVE: [],
      CHECK: [Permission.System.SYSTEM_CREATE, Permission.System.SYSTEM_UPDATE],
      SAVE: [Permission.System.SYSTEM_CREATE, Permission.System.SYSTEM_UPDATE],
      CLOSE: [Permission.System.SYSTEM_CREATE, Permission.System.SYSTEM_UPDATE],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const include = useMemo(() => [IncludeOptions.DEACTIVATED], []);

  const evidence = useAuthoredEvidence<System>({
    identifier: PageKey.SYSTEMS,
    apiProps: {
      url: EvidenceAPI.SYSTEMS,
      createItem: createItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.System.CREATE,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
      updateItem: updateItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.System.UPDATE,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
    },
    tableProps: {
      defaultSorts: [],
      tableName: 'Přehled AIS',
      columns: useColumns(),
      include,
      reportTag: ExportTags.SYSTEMS,
    },
    detailProps: {
      entityName: EntityName.SYSTEM,
      FieldsComponent: SystemsFields,
      validationSchema,
      toolbarProps: {
        title: 'Systém',
        subtitle: useSubTitle<System>((data) => data?.name ?? ''),
        ActionBar: function ActionBar() {
          return (
            <DictionaryActionbar
              url={EvidenceAPI.SYSTEMS}
              CustomActions={SystemsActionbar}
              invalidatePerm={Permission.System.SYSTEM_INVALIDATE}
              revalidatePerm={Permission.Default.ADMINISTRATOR}
            />
          );
        },
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.System.SYSTEM_LIST,
    },
  });

  return <Evidence2 {...evidence} />;
}

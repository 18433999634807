import React, { useContext } from 'react';
import {
  FormTextField,
  FormPanel,
  FormTextArea,
  FormAutocomplete,
  useFormSelector,
  abortableFetch,
  FormContext,
  DetailContext,
  DetailMode,
  useUpdateEffect,
  ApiFilterOperation,
  Tooltip,
  UserContext,
} from '@eas/common-web';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { AuthoredFields } from '../../components/evidence/authored-evidence/authored-fields';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { HelpLabel } from '../../components/help/help-label';
import { LocationPanel } from '../../components/form/location-panel/location-panel';
import { useIrzFacilitiesWithoutProductionSite } from '../irz-facility/irz-facilities-api';
import { useDistricts } from './production-sites-api';
import { MapMarkers } from './production-site-map-markers';
import { EvidenceAPI, Permission } from '../../enums';
import {
  ProductionSite,
  RuianObject,
  IrzFacility,
  IrzFacilityCoordinate,
  Wgs84Coordinates,
} from '../../models';

export function ProductionSitesFields(props: NotedEvidenceFieldsProps) {
  const { setFieldValue, getFieldValues } = useContext(FormContext);
  const { mode, source } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const facilities = useIrzFacilitiesWithoutProductionSite(source.data.id);

  const {
    wgs84Coordinates,
    irzFacilitiesCoordinates,
    irzFacilities,
  } = useFormSelector((data: ProductionSite) => ({
    wgs84Coordinates: data.wgs84Coordinates,
    irzFacilitiesCoordinates: data.irzFacilitiesCoordinates ?? [],
    irzFacilities: data.facilities ?? [],
  }));

  const districts = useDistricts();

  const guessDisctrict = async (lat: number, lon: number) => {
    const district = await callGuess(lat, lon).json();

    if (district) setFieldValue('district', district);
  };

  const saveCoordinates = (nerbyCoordinates: IrzFacilityCoordinate[]) => {
    setFieldValue(
      'irzFacilitiesCoordinates',
      nerbyCoordinates.map((coord: IrzFacilityCoordinate) => ({
        ...coord,
        assigned: irzFacilities.map((fac) => fac.id).includes(coord.id!),
      }))
    );
  };

  useUpdateEffect(() => {
    if (
      (mode === DetailMode.NEW || mode === DetailMode.EDIT) &&
      wgs84Coordinates?.lat &&
      wgs84Coordinates.lon
    ) {
      guessDisctrict(wgs84Coordinates.lat, wgs84Coordinates.lon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wgs84Coordinates?.lat, wgs84Coordinates?.lon]);

  useUpdateEffect(() => {
    if (mode === DetailMode.NEW || mode === DetailMode.EDIT) {
      if (wgs84Coordinates?.lat && wgs84Coordinates?.lon) {
        loadNearbyIrzFacilitiesCoordinates(
          wgs84Coordinates,
          source.data.id,
          saveCoordinates
        );
      } else {
        setFieldValue('irzFacilitiesCoordinates', []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wgs84Coordinates?.lat?.toString(), wgs84Coordinates?.lon?.toString()]);

  useUpdateEffect(() => {
    if (
      mode === DetailMode.EDIT &&
      wgs84Coordinates?.lat &&
      wgs84Coordinates?.lon
    ) {
      loadNearbyIrzFacilitiesCoordinates(
        wgs84Coordinates,
        source.data.id,
        saveCoordinates
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <>
      <FormPanel label="Průmyslové místo">
        {mode === DetailMode.VIEW && (
          <>
            <FormTextField
              name="inspireId"
              label={
                <HelpLabel
                  label="Identifikátor EU"
                  code="PRODUCTION_SITE_INSPIRE_ID"
                />
              }
              disabled
            />
            <FormTextField
              name="uniqueCode"
              label={
                <HelpLabel
                  label="Jedinečný kód"
                  code="PRODUCTION_SITE_UNIQUE_CODE"
                />
              }
              disabled
            />
          </>
        )}
        <FormTextField
          name="name"
          label={
            <HelpLabel label="Název" code="PRODUCTION_SITE_NAME" required />
          } // Povinné pole
        />
        <FormAutocomplete
          name="facilities"
          label={
            <HelpLabel
              label="Provozovny"
              code="PRODUCTION_SITE_FACILITIES"
              required
            />
          } // Povinné pole, vyberte alespoň 1 provozovnu. V seznamu se nachází pouze dostupné provozovny.
          source={facilities}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          notifyChange={() => {
            const irzFacilitiesIds = getFieldValues().facilities.map(
              (facility: IrzFacility) => facility.id
            );

            setFieldValue(
              'irzFacilitiesCoordinates',
              irzFacilitiesCoordinates?.map((facility) => ({
                ...facility,
                assigned: irzFacilitiesIds.includes(facility.id),
              }))
            );
          }}
          multiple
        />
        <FormTextArea
          name="note"
          label={<HelpLabel label="Poznámka" code="PRODUCTION_SITE_NOTE" />}
        />
        {mode === DetailMode.VIEW && (
          <FormTextField
            name="district.region.name"
            label={<HelpLabel label="Kraj" code="PRODUCTION_SITE_REGION" />}
            disabled
          />
        )}
        <FormAutocomplete<RuianObject>
          name="district"
          source={districts}
          label={
            <HelpLabel label="Okres" code="PRODUCTION_SITE_DISTRICT" required />
          } // Povinné pole
          endAdornment={
            <>
              {wgs84Coordinates?.lat && wgs84Coordinates?.lon && (
                <Tooltip
                  placement="top"
                  title="Aktualizovat okres dle souřadnic"
                >
                  <IconButton
                    size="small"
                    onClick={() =>
                      guessDisctrict(
                        wgs84Coordinates?.lat || 0,
                        wgs84Coordinates?.lon || 0
                      )
                    }
                    style={{ padding: 1, marginRight: '2pt' }}
                    tabIndex={-1}
                  >
                    <AutorenewIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        />
      </FormPanel>
      <LocationPanel
        expandable
        defaultExpanded={false}
        locationName="wgs84Coordinates"
        locationValue={wgs84Coordinates}
        showCoordinates
        showCoordinatesDistance={false}
        showEtrs89Coordinates={
          mode === DetailMode.VIEW &&
          hasPermission(Permission.ProductionSite.PRODUCTION_SITE_UPDATE)
        }
        defaultZoom={15}
        Markers={() => (
          <MapMarkers
            items={irzFacilitiesCoordinates}
            showAddBtn={(id) =>
              mode !== DetailMode.VIEW &&
              !irzFacilitiesCoordinates.find((coord) => coord.id === id)
                ?.assigned
            }
            showRemoveBtn={(id) =>
              mode !== DetailMode.VIEW &&
              !!irzFacilitiesCoordinates.find((coord) => coord.id === id)
                ?.assigned
            }
            onAddBtnClick={(data) => {
              setFieldValue('facilities', [...irzFacilities, data]);
              setFieldValue(
                'irzFacilitiesCoordinates',
                irzFacilitiesCoordinates?.map((coord) =>
                  coord.id === data.id ? { ...coord, assigned: true } : coord
                )
              );
            }}
            onRemoveBtnClick={(id) => {
              setFieldValue(
                'facilities',
                irzFacilities.filter((facility) => facility.id !== id)
              );
              setFieldValue(
                'irzFacilitiesCoordinates',
                irzFacilitiesCoordinates?.map((coord) =>
                  coord.id === id ? { ...coord, assigned: false } : coord
                )
              );
            }}
          />
        )}
      />
      {props.Notes}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <AuthoredFields FieldsComponent={DatedFields} />
      </FormPanel>
    </>
  );
}

const callGuess = function (lat: number, lon: number) {
  return abortableFetch(
    `${EvidenceAPI.ADDRESS_DISCTRICT}/guess?lat=${lat}&lon=${lon}`,
    {
      method: 'GET',
    }
  );
};

const loadNearbyIrzFacilitiesCoordinates = async (
  wgs84Coordinates: Wgs84Coordinates,
  id: string,
  processCoordinates: (coordinates: IrzFacilityCoordinate[]) => void
) => {
  const response = await fetch(`${EvidenceAPI.IRZ_FACILITIES}/coordinates`, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      filters: [
        {
          operation: 'GEO_DISTANCE',
          field: 'wgs84Point',
          point: {
            lat: wgs84Coordinates?.lat,
            lon: wgs84Coordinates?.lon,
          },
          distance: 50,
          unit: 'KILOMETERS',
        },
        {
          operation: ApiFilterOperation.OR,
          filters: [
            {
              operation: ApiFilterOperation.IS_NULL,
              field: 'productionSite.id',
            },
            {
              operation: ApiFilterOperation.EQ,
              field: 'productionSite.id',
              value: id,
            },
          ],
        },
      ],
    }),
  });

  const json = await response.json();

  const nerbyFacilitiesCoordinates = json.items;
  processCoordinates(nerbyFacilitiesCoordinates);
};

import React, { useContext } from 'react';
import { FormikProps } from 'formik';
import {
  FormSelect,
  FormTextField,
  FormPanel,
  FilesProvider,
  FormFileField,
  FormContext,
} from '@eas/common-web';
import { useSubjectTypes } from '../../subject/subjects-api';
import { useIdTypes } from '../empowerment-requests-api';
import { StepOtherSubjectForm } from './step-other-subject-types';
import {
  icoMaxLength,
  identityCardMaxLength,
} from '../../subject/fields/subjects-utils';
import { HelpLabel } from '../../../components/help/help-label';
import { AddressField } from '../../../components/form/address-field/address-field';
import { SubjectRequestFileFields } from '../../subject-request/fields/subject-request-file-fields';
import { ProcessForm } from '../empowerment-requests-types';
import { SubjectType, IdType } from '../../../models';

export function StepOtherSubjectContent({
  values,
}: FormikProps<StepOtherSubjectForm> & { allValues: ProcessForm }) {
  const { setFieldValue } = useContext(FormContext);

  const subjectTypes = useSubjectTypes();
  const idTypes = useIdTypes();

  const hasIco =
    values.empowerer?.type === SubjectType.LEGAL_ENTITY ||
    values.empowerer?.type === SubjectType.BUSINESS_NATURAL_PERSON;

  const hasUID =
    values.empowerer?.type === SubjectType.FOREIGN_NATURAL_PERSON ||
    values.empowerer?.type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO;

  const hasIdOrPassport = values.empowerer?.type === SubjectType.NATURAL_PERSON;

  const isPassport = values.empowerer?.idType === IdType.PASSPORT;
  const isIdCard = values.empowerer?.idType === IdType.ID_CARD;

  return (
    <FormPanel label="Vyplňte">
      <FormSelect
        name="empowerer.type"
        label={
          <HelpLabel
            label="Typ"
            code="EMPOWERMENT_REQUEST_NEW_EMPOWERER_TYPE"
            required
          />
        } // Povinné pole
        source={subjectTypes}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
        notifyChange={() => {
          setFieldValue('foreignSubjectInfo', undefined);
        }}
      />
      {hasIco && (
        <>
          <FormTextField
            name="empowerer.ico"
            label={
              <HelpLabel
                label="IČO"
                code="EMPOWERMENT_REQUEST_NEW_EMPOWERER_ICO"
                required
              />
            } // Povinné pole
            inputProps={{ maxLength: icoMaxLength }}
            onChangeEvent={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/gi, '');
            }}
          />
        </>
      )}
      {hasUID && (
        <>
          <FormTextField
            name="empowerer.artificialId"
            label={
              <HelpLabel
                label="UID"
                code="EMPOWERMENT_REQUEST_NEW_EMPOWERER_ARTIFICIAL_ID"
                required
              />
            } // Povinné pole, formát: ID_xxxxxx
          />
        </>
      )}
      {hasIdOrPassport && (
        <>
          <FormSelect
            name="empowerer.idType"
            label={
              <HelpLabel
                label="Typ dokladu"
                code="EMPOWERMENT_REQUEST_NEW_EMPOWERER_ID_TYPE"
                required
              />
            } // Povinné pole
            source={idTypes}
            tooltipMapper={(o) => o.name}
            valueIsId={true}
          />
          {isPassport && (
            <FormTextField
              name="empowerer.passportNumber"
              label={
                <HelpLabel
                  label="Číslo pasu"
                  code="EMPOWERMENT_REQUEST_NEW_EMPOWERER_PASSPORT_NUMBER"
                  required
                />
              } // Povinné pole
            />
          )}
          {isIdCard && (
            <FormTextField
              name="empowerer.idCardNumber"
              label={
                <HelpLabel
                  label="Číslo OP"
                  code="EMPOWERMENT_REQUEST_NEW_EMPOWERER_ID_CARD_NUMBER"
                  required
                />
              } // Povinné pole
              inputProps={{
                maxLength: identityCardMaxLength,
              }}
            />
          )}
        </>
      )}
      {values?.empowerer?.type === SubjectType.FOREIGN_LEGAL_ENTITY && (
        <>
          <FormTextField
            name="empowerer.name"
            label={
              <HelpLabel
                label="Název"
                code="EMPOWERMENT_REQUEST_NEW_EMPOWERER_NAME"
                required
              /> // Povinné pole
            }
          />
          <AddressField name="empowerer.residence" disableRuian={true} />
          <FormTextField
            name="empowerer.foreignId"
            label={
              <HelpLabel
                label="Zahraniční identifikátor (obdoba IČO)"
                code="EMPOWERMENT_REQUEST_NEW_EMPOWERER_FOREIGN_ID"
                required
              />
            }
          />
          <SubjectRequestFileFields
            type={SubjectType.FOREIGN_LEGAL_ENTITY}
            prefix="empowerer"
          />
        </>
      )}
      {values.empowerer?.type === SubjectType.FOREIGN_NATURAL_PERSON && (
        <FilesProvider url="/api/crzp/archived/file">
          <FormFileField
            name="empowerer.identityDocumentCopy"
            label={
              <HelpLabel
                label="Příloha (kopie dokladu totožnosti)"
                code="EMPOWERMENT_REQUEST_NEW_IDENTITY_DOCUMENT_COPY"
              />
            }
          />
        </FilesProvider>
      )}
    </FormPanel>
  );
}

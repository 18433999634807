import React from 'react';
import {
  FormTextField,
  FormPanel,
  FormNumberField,
  FormAutocomplete,
  FormCheckbox,
} from '@eas/common-web';
import { useDictionaryWaterOrigins } from '../../dict-water-origin/dict-water-origins-api';
import { useDictionaryWaterTreatmentMethods } from '../../dict-water-treatment-method/dict-water-treatment-methods-api';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useDictionaryHydrogeologicalZones } from '../../dict-hydrogeological-zone/dict-hydrogeological-zones-api';
import { HelpLabel } from '../../../components/help/help-label';

export function WaterUsagePlacesGroundWaterAbstractionFields() {
  const waterOrigins = useDictionaryWaterOrigins();
  const hydrogeologicalZones = useDictionaryHydrogeologicalZones();
  const waterTreatmentMethods = useDictionaryWaterTreatmentMethods();

  return (
    <>
      <FormPanel label="Dlouhodobé provozní údaje specifické pro typ MUV">
        <FormTextField
          label={
            <HelpLabel
              label="Název jímacího zařízení"
              code="WATER_USAGE_PLACE_ABSTRACTION_DEVICE_NAME"
            />
          }
          name="abstractionDeviceName"
        />
        <FormAutocomplete
          label={
            <HelpLabel
              label="Hydrogeologický rajon"
              code="WATER_USAGE_PLACE_HYDROGEOLOGICAL_ZONE"
            />
          }
          name="hydrogeologicalZone"
          source={hydrogeologicalZones}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormNumberField
          name="abstractionDevicesCapacity"
          label={
            <HelpLabel
              label="Kapacita jímacích zařízení (litry za sekundu)"
              code="WATER_USAGE_PLACE_ABSTRACTION_DEVICES_CAPACITY"
            />
          }
        />
        <FormAutocomplete
          label={
            <HelpLabel
              label="Původ odebrané vody"
              code="WATER_USAGE_PLACE_ABSTRACTED_WATER_ORIGIN"
            />
          }
          name="abstractedWaterOrigin"
          source={waterOrigins}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          label={
            <HelpLabel
              label="Způsob úpravy vody"
              code="WATER_USAGE_PLACE_WATER_TREATMENT"
            />
          }
          name="waterTreatment"
          source={waterTreatmentMethods}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
      </FormPanel>

      <FormPanel label="Způsob zachycení vody">
        <FormCheckbox
          label={
            <HelpLabel
              label="Pramenní jímka"
              code="WATER_USAGE_PLACE_CAPTURE_METHOD_SPRING_PUMP"
            />
          }
          name="captureMethod.springSump"
        />
        <FormNumberField
          label={
            <HelpLabel
              label="Pramenní jímka - počet"
              code="WATER_USAGE_PLACE_CAPTURE_METHOD_SPRING_PUMP_COUNT"
            />
          }
          name="captureMethod.springSumpCount"
        />
        <FormCheckbox
          label={
            <HelpLabel
              label="Jímací štola"
              code="WATER_USAGE_PLACE_CAPTURE_METHOD_WATER_SUPPLY_TUNNEL_COUNT"
            />
          }
          name="captureMethod.waterSupplyTunnel"
        />
        <FormNumberField
          label={
            <HelpLabel
              label="Jímací štola - počet"
              code="WATER_USAGE_PLACE_CAPTURE_METHOD_WATER_SUPPLY_TUNNEL_COUNT"
            />
          }
          name="captureMethod.waterSupplyTunnelCount"
        />
        <FormCheckbox
          label={
            <HelpLabel
              label="Studna"
              code="WATER_USAGE_PLACE_CAPTURE_METHOD_WELL"
            />
          }
          name="captureMethod.well"
        />
        <FormNumberField
          label={
            <HelpLabel
              label="Studna - počet"
              code="WATER_USAGE_PLACE_CAPTURE_METHOD_WELL_COUNT"
            />
          }
          name="captureMethod.wellCount"
        />
        <FormCheckbox
          label={
            <HelpLabel
              label="Vrt"
              code="WATER_USAGE_PLACE_CAPTURE_METHOD_BOREHOLE"
            />
          }
          name="captureMethod.borehole"
        />
        <FormNumberField
          label={
            <HelpLabel
              label="Vrt - počet"
              code="WATER_USAGE_PLACE_CAPTURE_METHOD_BOREHOLE_COUNT"
            />
          }
          name="captureMethod.boreholeCount"
        />
      </FormPanel>
    </>
  );
}

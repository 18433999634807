import { Me } from '../../models';

/**
 * Returns TRUE if `permission` is found in `securityPermissions` array.
 *
 * @param user
 * @param permission
 */
export function hasSecurityPermission(user: Me, permission: string) {
  return !!user?.securityPermissions?.find((p) => p === permission);
}

/**
 * Returns flatten array of `subjectPermissions` and `empowermentPermissions` from all subjects.
 *
 * @param user
 */
export function flattenSubjectPermissions(user: Me) {
  const keys = Object.keys(user?.subjectPermissions ?? {});

  const subjectPerms: string[] = [];

  // flatten subjectPermissions and empowermentPermissions
  keys.forEach((k) => {
    subjectPerms.push(
      ...(user?.subjectPermissions[k]?.subjectPermissions ?? [])
    );
    subjectPerms.push(
      ...(user?.subjectPermissions[k].empowermentPermissions ?? [])
    );
  });

  return subjectPerms;
}

/**
 * Returns TRUE if `permission` is found at any `subjectPermissions` or `empowermentPermissions`.
 *
 * @param user
 * @param permission
 */
export function hasSubjectPermission(user: Me, permission: string) {
  const subjectPerms = flattenSubjectPermissions(user);

  return !!subjectPerms?.find((p) => p === permission);
}

/**
 * Returns TRUE if `permission` is found at specified `subjectPermissions` or `empowermentPermissions`.
 *
 * @param user
 * @param permission
 * @param subjectId
 */
export function hasPermissionInSubject(
  user: Me,
  permission: string,
  subjectId: string
) {
  const subject = user?.subjectPermissions?.[subjectId];

  if (!subject) {
    return false;
  }

  const hasSubjectPerm = subject?.subjectPermissions?.find(
    (p) => p === permission
  );

  const hasEmpowermentPerm = subject?.empowermentPermissions?.find(
    (p) => p === permission
  );

  return !!(hasSubjectPerm || hasEmpowermentPerm);
}

/**
 * Returns list of subjectIds with `permission` found if `subjectPermissions` or `empowermentPermissions`.
 */
export function getSubjectsWithPermission(user: Me, permission: string) {
  const subjectPermissions = Object.entries(user.subjectPermissions);

  const subjectsWithPermission = subjectPermissions.filter(
    ([, permissionObj]) => {
      const hasSubjectPerm = permissionObj?.subjectPermissions?.find(
        (p) => p === permission
      );

      const hasEmpowermentPerm = permissionObj?.empowermentPermissions?.find(
        (p) => p === permission
      );

      return hasSubjectPerm || hasEmpowermentPerm;
    }
  );

  const ids = subjectsWithPermission.map(([subjectId]) => subjectId);

  return ids;
}

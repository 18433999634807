import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { PublicList } from '../../models/public-list';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { useRoles } from '../roles/roles-api';
import { useStates, useAccessTypes, useDataSources } from './public-lists-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';

export function useColumns(): TableColumn<PublicList>[] {
  return [
    {
      datakey: 'accessType',
      sortkey: 'accessType.name',
      filterkey: 'accessType.id',
      name: 'Omezení přístupu',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useAccessTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.useSelectCellFactory(useAccessTypes),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'allowedRoles',
      sortkey: 'allowedRoles.concatenated',
      filterkey: 'allowedRoles.id',
      name: 'Role',
      width: 250,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useRoles,
        dictionaryLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'dataSource',
      sortkey: 'dataSource.name',
      filterkey: 'dataSource.id',
      name: 'Zdroj dat',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useDataSources
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.useSelectCellFactory(useDataSources),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      valueMapper: TableCells.useSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'publisher.name',
      name: 'Vydavatel',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'firstPublishedDate',
      name: 'První publikace',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'lastPublishedDate',
      name: 'Poslední publikace',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Wgs84Coordinates } from '../../../models';
import { AddressPlace } from '../../../models/address-place';
import HomeIcon from '@material-ui/icons/Home';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { calculateDistance, formatDistance } from './location-utils';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  contentWrapper: {
    minWidth: '500px',
  },
  loaderWrapper: {
    width: '100%',
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
  item: {
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
  },
  text: {
    marginTop: 0,
    marginBottom: 0,
  },
});

export function AddressPlaceListItem({
  addressPlace,
  searchDistance,
}: {
  addressPlace: AddressPlace;
  searchDistance: Wgs84Coordinates;
}) {
  const classes = useStyles();

  const distance = addressPlace.wgs84Coordinates
    ? calculateDistance(addressPlace.wgs84Coordinates, searchDistance)
    : undefined;

  return (
    <ListItem button dense className={classes.item}>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText
        className={classes.text}
        primary={<Typography variant="body1">{addressPlace.label}</Typography>}
        secondary={
          <Typography variant="body2">
            {distance !== undefined ? formatDistance(distance) : undefined}
          </Typography>
        }
      />
    </ListItem>
  );
}

import React from 'react';
import { FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export function DictionaryCompetentAuthoritiesFields() {
  return (
    <>
      <FormTextField
        name="organizationName"
        label={
          <HelpLabel
            label="Název organizace"
            code="DICT_COMPETENT_AUTHORITIES_ORGANIZATION_NAME"
          />
        }
      />
      <FormTextField
        name="email"
        label={
          <HelpLabel label="E-mail" code="DICT_COMPETENT_AUTHORITIES_EMAIL" />
        }
      />
      <FormTextField
        name="phoneNumber"
        label={
          <HelpLabel
            label="Telefonní číslo"
            code="DICT_COMPETENT_AUTHORITIES_PHONE_NUMBER"
          />
        }
      />
      <FormTextField
        name="faxNumber"
        label={
          <HelpLabel label="Fax" code="DICT_COMPETENT_AUTHORITIES_FAX_NUMBER" />
        }
      />
      <FormTextField
        name="city"
        label={
          <HelpLabel label="Město" code="DICT_COMPETENT_AUTHORITIES_CITY" />
        }
      />
      <FormTextField
        name="street"
        label={
          <HelpLabel label="Ulice" code="DICT_COMPETENT_AUTHORITIES_STREET" />
        }
      />
      <FormTextField
        name="descriptiveNumber"
        label={
          <HelpLabel
            label="Číslo popisné"
            code="DICT_COMPETENT_AUTHORITIES_DESCRIPTIVE_NUMBER"
          />
        }
      />
      <FormTextField
        name="orientationNumber"
        label={
          <HelpLabel
            label="Číslo orientační"
            code="DICT_COMPETENT_AUTHORITIES_ORIENTATION_NUMBER"
          />
        }
      />
      <FormTextField
        name="zip"
        label={<HelpLabel label="PSČ" code="DICT_COMPETENT_AUTHORITIES_ZIP" />}
      />
      <FormTextField
        name="dataBox"
        label={
          <HelpLabel
            label="Datová schránka"
            code="DICT_COMPETENT_AUTHORITIES_DATA_BOX"
          />
        }
      />
    </>
  );
}

import { ComponentType } from 'react';
import {
  TableColumn,
  ApiFilterOperation,
  TableCells,
  TableFilterCells,
  TableCellProps,
} from '@eas/common-web';
import { requestColoredFactory } from '../../components/form/request-colored/request-colored';
import { useUsers } from '../user/users-api';
import { RoleRequest, RoleRequestState } from '../../models';
import { useRoleRequestStates, useRoleRequestTypes } from './role-requests-api';

export function roleRequestColumnFactory(
  cell: ComponentType<TableCellProps<RoleRequest>>
) {
  return requestColoredFactory(
    cell,
    (request) => request.state === RoleRequestState.APPROVED,
    (request) => request.state === RoleRequestState.REJECTED,
    (request) => request.state === RoleRequestState.PENDING
  );
}

export function useColumns(): TableColumn<RoleRequest>[] {
  return [
    {
      datakey: 'requester.label',
      filterkey: 'requester.id',
      sortkey: 'requester.name',
      name: 'Žadatel',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: roleRequestColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useUsers
      ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      filterkey: 'state.id',
      sortkey: 'state.name',
      name: 'Stav žádosti',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: roleRequestColumnFactory(TableCells.TextCell),
      valueMapper: TableCells.useSelectCellFactory(useRoleRequestStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useRoleRequestStates
      ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      filterkey: 'type.id',
      sortkey: 'type.name',
      name: 'Typ žádosti',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: roleRequestColumnFactory(TableCells.TextCell),
      valueMapper: TableCells.useSelectCellFactory(useRoleRequestTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useRoleRequestTypes
      ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import { DictionaryIppcActivityAutocomplete } from '../../models';
import {
  useAutocompleteSource,
  ApiFilterOperation,
  abortableFetch,
  Filter,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useIppcActivities(filter?: Filter | null) {
  const params = filter
    ? {
        filters: [filter],
      }
    : undefined;

  return useAutocompleteSource<DictionaryIppcActivityAutocomplete>({
    url: EvidenceAPI.DICTIONARY_IPPC_ACTIVITIES + '/autocomplete',
    params,
  });
}

export async function getIppcActivity(
  ePrtrActivityId: string
): Promise<DictionaryIppcActivityAutocomplete[]> {
  const response = abortableFetch(
    `${EvidenceAPI.DICTIONARY_IPPC_ACTIVITIES}/autocomplete`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        filters: ePrtrActivityId
          ? [
              {
                field: 'eprtrActivities.id',
                operation: ApiFilterOperation.EQ,
                value: ePrtrActivityId,
              },
            ]
          : [],
        size: -1,
      }),
    }
  );

  const json = await response.json();

  return json?.items;
}

import { abortableFetch } from '@eas/common-web';
import { RegistrationData } from './auth-types';

/**
 * Function to log user into CRZP protected app.
 *
 * @param username
 * @param password
 * @param captcha
 */
export function loginUser(username: string, password: string, captcha: string) {
  const formData = new FormData();

  formData.append('username', username);
  formData.append('password', password);
  formData.append('captcha', captcha);

  return abortableFetch('/api/crzp/auth', {
    method: 'POST',
    body: formData,
  });
}

/**
 * Function to send request to register user into CRZP protected app.
 *
 * @param data
 * @param captcha
 */
export function registerUser(data: RegistrationData, captcha: string) {
  return abortableFetch('/api/crzp/user/registration', {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      ...data,
      firstName: {
        value: data.firstName,
      },
      lastName: {
        value: data.lastName,
      },
      email: {
        value: data.email,
      },
      phoneNumber: {
        prefix: data.prefix,
        number: {
          value: data.phoneNumber,
        },
      },
      passwordPlain: data.password,
      captcha,
    }),
  });
}

/**
 * Function to activate users account.
 *
 * @param activationCode
 */
export function completeRegistration(activationCode: string) {
  return abortableFetch(`/api/crzp/user/registration/confirm`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: activationCode,
  });
}

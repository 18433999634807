import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ProcessActionsProps } from '../../../components/process/process-types';
import {
  useEventCallback,
  NavigationContext,
  EvidenceStateAction,
  SnackbarContext,
  PromptContext,
  Prompt,
} from '@eas/common-web';
import { ProcessForm } from '../ovz-facility-new-types';
import { createOvzFacility } from '../ovz-facility-new-api';
import { EvidenceUrl, Messages } from '../../../enums';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function StepSummaryActions({
  handleBack,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const { registerPrompts, unregisterPrompts, testPrompt } = useContext(
    PromptContext
  );
  const { registerPrompt, unregisterPrompt } = useContext(NavigationContext);

  const { navigate } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const prompt: Prompt = {
      key: 'CREATE_REGISTRATION_PROMPT',
      dialogTitle: 'Registrace provozovny OVZ',
      dialogText: 'Skutečně chcete odeslat registraci provozovny OVZ?',
    };

    registerPrompts([prompt]);

    return () => {
      unregisterPrompts([prompt]);
    };
  }, []);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: 'CREATE_REGISTRATION_PROMPT',
      submitCallback: async () => {
        setLoading(true);

        try {
          unregisterPrompt(navigationPrompt);
          const fetch = createOvzFacility(allValues);
          const json = await fetch.json();

          setLoading(false);

          showSnackbar(...Messages.OvzFacilityRequest.REGISTER.SUCCESS);
          navigate(`${EvidenceUrl.OVZ_FACILITIES}`, true, {
            action: EvidenceStateAction.SHOW_ITEM,
            data: json.id!,
          });
        } catch (err) {
          setLoading(false);
          registerPrompt(navigationPrompt);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.OvzFacilityRequest.REGISTER,
              err.code
            );

            showSnackbar(...message);
          }

          throw err;
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Odeslat registraci
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} />
      </div>
    </div>
  );
}

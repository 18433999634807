import React from 'react';
import { Evidence2, auditLogFactory, AlogEvent } from '@eas/common-web';
import { stubFalse } from 'lodash';
import { ExportTags } from '../../enums/export-tags';
import { EvidenceAPI, EntityName } from '../../enums';
import { useNotedEvidence } from '../../components/evidence/noted-evidence/noted-evidence';

export function AuditLog() {
  const evidence = useNotedEvidence<AlogEvent>({
    identifier: 'ALOG',
    apiProps: {
      url: EvidenceAPI.ALOG,
    },
    tableProps: {
      columns: auditLogFactory.useColumns(),
      tableName: 'Přehled auditních logů',
      reportTag: ExportTags.ALOG,
      defaultSorts: [
        { field: 'created', datakey: 'created', order: 'DESC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.ALOG,
      toolbarProps: {
        title: 'Záznam',
        showBtn: stubFalse,
      },
      FieldsComponent: auditLogFactory.Fields,
    },
  });

  return <Evidence2 {...evidence} />;
}

import React from 'react';
import { FormPanel, FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { PhoneField } from '../../components/form/phone-field/phone-field';
import { adjustWhitespaces } from '../user/users-utils';

export function UnconfirmedUserRegistrationsFields() {
  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="username"
          label={
            <HelpLabel
              label="Uživatelské jméno"
              code="UNCONMIFRMED_USER_REGISTRATIONS_USERNAME"
            />
          }
        />
        <FormTextField
          name="firstName.value"
          label={
            <HelpLabel
              label="Jméno"
              code="UNCONMIFRMED_USER_REGISTRATIONS_FIRSTNAME"
            />
          }
          onChangeEvent={adjustWhitespaces}
        />
        <FormTextField
          name="lastName.value"
          label={
            <HelpLabel
              label="Příjmení"
              code="UNCONMIFRMED_USER_REGISTRATIONS_LASTNAME"
            />
          }
          onChangeEvent={adjustWhitespaces}
        />
        <FormTextField
          name="email.value"
          label={
            <HelpLabel
              label="Email"
              code="UNCONMIFRMED_USER_REGISTRATIONS_EMAIL"
            />
          }
        />
        <PhoneField
          name="phoneNumber"
          label={
            <HelpLabel
              label="Telefonní číslo (předvolba, číslo)"
              code="UNCONMIFRMED_USER_REGISTRATIONS_PHONE_NUMBER"
            />
          }
        />
      </FormPanel>
    </>
  );
}

import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import {
  useStaticListSource,
  DictionaryAutocomplete,
  useAutocompleteSource,
  useScrollableSource,
  useFirstRender,
  Filter,
  useListSource,
  ApiFilterOperation,
  Params,
  ResultDto,
  useFetch,
  ListSource,
} from '@eas/common-web';
import { post } from '../../utils/custom-crud';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import {
  Subject,
  SubjectType,
  SubjectIdentificator,
  Role,
  Empowerment,
  SubjectAutocomplete,
  Agenda,
  SubjectRegistration,
  AgendaAutocomplete,
  DocumentType,
  IrzFacility,
  OvzFacility,
  BoilerPermission,
  SystemAutocomplete,
  WaterUsagePlace,
  RelationshipType,
  RelationshipRole,
} from '../../models';
import { EvidenceAPI } from '../../enums';

export function eqFilterParams({
  field,
  value,
  sort = 'created',
  order = 'ASC',
}: {
  field: string;
  value?: any;
  sort?: string;
  order?: 'ASC' | 'DESC';
}) {
  if (value === undefined) {
    return undefined;
  } else {
    return {
      sort: [{ field: sort, order, type: 'FIELD', missing: 'FIRST' }],
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field,
          value,
        },
      ],
      include: [],
      size: -1,
    } as Params;
  }
}

export async function combineGetCalls({
  api,
  itemId,
  signal,
  mainItem,
}: CombineCallsType) {
  const json = {
    ...mainItem,
  };

  const promises: Promise<any>[] = [
    post<Agenda[]>({
      url: `${api}/${itemId}/agenda/list`,
      signal,
      body: {
        sort: [
          { field: 'active', order: 'DESC', type: 'FIELD' },
          { field: 'agenda.name', order: 'ASC', type: 'FIELD' },
        ],
        size: -1,
      },
    }),
    post<ResultDto<AgendaAutocomplete>>({
      url: `${EvidenceAPI.AGENDAS}/assignable-to/${itemId}/autocomplete`,
      signal,
    }),
    post<ResultDto<SubjectRegistration>>({
      url: `${EvidenceAPI.SUBJECT_REQUESTS}/list`,
      signal,
      body: eqFilterParams({ field: 'approvedSubject.id', value: itemId }),
    }),
    post<ResultDto<IrzFacility>>({
      url: `${EvidenceAPI.IRZ_FACILITIES}/operation`,
      signal,
      body: {
        sort: [
          { field: 'validTo', order: 'DESC', type: 'FIELD', missing: 'FIRST' },
          { field: 'validFrom', order: 'DESC', type: 'FIELD' },
        ],
        filters: [
          {
            operation: ApiFilterOperation.EQ,
            field: 'operatorId',
            value: itemId,
          },
        ],
        include: [],
        size: -1,
      } as Params,
    }),
    post<ResultDto<OvzFacility>>({
      url: `${EvidenceAPI.OVZ_FACILITIES}/operation`,
      signal,
      body: {
        sort: [
          { field: 'validTo', order: 'DESC', type: 'FIELD', missing: 'FIRST' },
          { field: 'validFrom', order: 'DESC', type: 'FIELD' },
        ],
        filters: [
          {
            operation: ApiFilterOperation.EQ,
            field: 'operatorId',
            value: itemId,
          },
        ],
        include: [],
        size: -1,
      } as Params,
    }),
    post<ResultDto<Empowerment>>({
      url: `${EvidenceAPI.EMPOWERMENTS}/list`,
      signal,
      body: {
        sort: [{ field: 'empowerer.id', order: 'ASC', type: 'FIELD' }],
        filters: [
          {
            operation: ApiFilterOperation.EQ,
            field: 'empowerer.id',
            value: itemId,
          },
          {
            operation: ApiFilterOperation.EQ,
            field: 'active',
            value: true,
          },
        ],
        include: [],
        size: -1,
      } as Params,
    }),
    post<ResultDto<Empowerment>>({
      url: `${EvidenceAPI.EMPOWERMENTS}/list`,
      signal,
      body: {
        sort: [{ field: 'agent.id', order: 'ASC', type: 'FIELD' }],
        filters: [
          {
            operation: ApiFilterOperation.EQ,
            field: 'agent.id',
            value: itemId,
          },
          {
            operation: ApiFilterOperation.EQ,
            field: 'active',
            value: true,
          },
        ],
        include: [],
        size: -1,
      } as Params,
    }),
    post<ResultDto<WaterUsagePlace>>({
      url: `${EvidenceAPI.WATER_USAGE_PLACES}/operation`,
      signal,
      body: eqFilterParams({
        field: 'operatorId',
        value: itemId,
        sort: 'validFrom',
        order: 'DESC',
      }),
    }),
    post<ResultDto<BoilerPermission>>({
      url: `${EvidenceAPI.BOILER_PERMISSIONS}/list`,
      signal,
      body: eqFilterParams({ field: 'ozoSubject.id', value: itemId }),
    }),
    post<ResultDto<BoilerPermission>>({
      url: `${EvidenceAPI.BOILER_PERMISSIONS}/list`,
      signal,
      body: eqFilterParams({ field: 'manufacturerSubject.id', value: itemId }),
    }),
    post<ResultDto<RelationshipRole>>({
      url: `${EvidenceAPI.SUBJECTS}/${itemId}/user/list`,
      signal,
      body: {
        filters: [
          {
            field: 'role.relationship.id',
            operation: ApiFilterOperation.EQ,
            value: RelationshipType.ADMINISTRATOR,
          },
          {
            field: 'active',
            operation: ApiFilterOperation.EQ,
            value: true,
          },
        ],
        size: 0,
      },
    }),
  ];

  const results = await Promise.all(promises);

  const [
    [agendas],
    [assignableAgendas],
    [subjectReg],
    [irzFacilities],
    [ovzFacilities],
    [asEmpowerer],
    [asAgent],
    [waterUsagePlaces],
    [boilerPermissionsOZOSubject],
    [boilerPermissionsManufacturer],
    [administratorsCount],
  ] = results;

  if (agendas?.length) json['agendas'] = agendas;
  if (assignableAgendas?.items?.length)
    json['assignableAgendas'] = assignableAgendas?.items;
  if (subjectReg?.items?.length)
    json['subjectRegistration'] = subjectReg?.items[0];
  if (irzFacilities?.items?.length)
    json['irzFacilities'] = irzFacilities?.items;
  if (ovzFacilities?.items?.length)
    json['ovzFacilities'] = ovzFacilities?.items;
  if (asEmpowerer?.items?.length)
    json['empowermentsAsEmpowerer'] = asEmpowerer?.items;
  if (asAgent?.items?.length) json['empowermentsAsAgent'] = asAgent?.items;
  if (waterUsagePlaces?.items?.length)
    json['waterUsagePlaces'] = waterUsagePlaces?.items;
  if (boilerPermissionsOZOSubject?.items?.length)
    json['boilerPermissionsOZOSubject'] = boilerPermissionsOZOSubject?.items;
  if (boilerPermissionsManufacturer?.items?.length)
    json['boilerPermissionsManufacturer'] =
      boilerPermissionsManufacturer?.items;
  json['administratorsCount'] = administratorsCount?.count ?? 0;

  const text = JSON.stringify(json);

  return {
    data: json,
    text,
  };
}

export const subjectTypesSource = [
  { id: SubjectType.LEGAL_ENTITY, name: 'Právnická osoba' },
  {
    id: SubjectType.LEGAL_ENTITY_WITHOUT_ICO,
    name: 'Právnická osoba bez IČO',
  },
  { id: SubjectType.NATURAL_PERSON, name: 'Fyzická osoba' },
  {
    id: SubjectType.BUSINESS_NATURAL_PERSON,
    name: 'Fyzická osoba podnikající',
  },
  {
    id: SubjectType.FOREIGN_LEGAL_ENTITY,
    name: 'Zahraniční právnická osoba',
  },
  {
    id: SubjectType.FOREIGN_NATURAL_PERSON,
    name: 'Zahraniční fyzická osoba',
  },
];

export function useSubjectTypes(identificatorType?: SubjectIdentificator) {
  let source = subjectTypesSource;

  switch (identificatorType) {
    case SubjectIdentificator.ICO: {
      source = source.filter((item) =>
        [
          SubjectType.LEGAL_ENTITY,
          SubjectType.BUSINESS_NATURAL_PERSON,
        ].includes(item.id)
      );
      break;
    }
    case SubjectIdentificator.ARTIFICIAL_ID: {
      source = source.filter((item) =>
        [
          SubjectType.FOREIGN_LEGAL_ENTITY,
          SubjectType.LEGAL_ENTITY_WITHOUT_ICO,
          SubjectType.FOREIGN_NATURAL_PERSON,
        ].includes(item.id)
      );
      break;
    }
    case SubjectIdentificator.ID_CARD_NUMBER:
    case SubjectIdentificator.PASSPORT_NUMBER: {
      source = source.filter((item) =>
        [SubjectType.NATURAL_PERSON].includes(item.id)
      );
      break;
    }
  }

  return useStaticListSource<DictionaryAutocomplete>(source);
}

export function useDocumentTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: DocumentType.IDENTITY_CARD,
      name: 'Občanský průkaz',
    },
    { id: DocumentType.PASSPORT, name: 'Pas' },
  ]);
}

export function useSubjectIdentificators() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.EMPOWERMENT_REQUESTS + '/subject-identifier/type/list',
    method: 'GET',
  });
}

export function useSubjects(params?: Params, ownOnly = false) {
  return useAutocompleteSource<SubjectAutocomplete>({
    url: `/api/crzp/subject/autocomplete${ownOnly ? '/own' : ''}`,
    params,
  });
}

export function useIrzSubjects() {
  return useAutocompleteSource<SubjectAutocomplete>({
    url: '/api/crzp/subject/autocomplete/with-irz',
  });
}

export function useSubjectSource(filters: Filter[] = [], ownOnly = false) {
  const subjectSource = useScrollableSource<Subject>({
    url: `/api/crzp/subject/autocomplete${ownOnly ? '/own' : ''}`,
  });

  useFirstRender(() => {
    subjectSource.setParams({
      sort: [{ field: 'created', order: 'ASC', type: 'FIELD' }],
      filters: filters,
      size: 30,
    });

    subjectSource.loadMore();
  });

  return subjectSource;
}

export function useSubjectRoles(
  subject?: Subject,
  onlyAdminCanAssign?: boolean,
  processResult = (roles: Role[]) => roles
) {
  const requestParam = onlyAdminCanAssign
    ? `?onlyAdminCanAssign=${onlyAdminCanAssign}`
    : '';

  const [result, loading, reset] = useFetch<Role[]>(
    `${EvidenceAPI.SUBJECTS}/${subject?.id}/role/user/list${requestParam}`,
    {
      method: 'GET',
      skip: !subject,
    }
  );

  const rolesSource: ListSource<Role> = useMemo(
    () => ({
      items: processResult(result ?? []),
      loading,
      reset,
      loadDetail: async (item) => item,
    }),
    [loading, reset, result, processResult]
  );

  const availableSystems = uniqBy(
    processResult(result ?? []).map(
      (role) => role.registeredFor as SystemAutocomplete
    ),
    'id'
  );

  return { rolesSource, roles: processResult(result ?? []), availableSystems };
}

/**
 * Returns list of agendas that user can assign to subject.
 */
export function useAssignableAgendas(source: AgendaAutocomplete[]) {
  return useStaticListSource<AgendaAutocomplete>(source);
}

/**
 * Returns count of all subjects
 */
export function useSubjectsCount() {
  const [response] = useFetch<ResultDto<Subject>>(
    `${EvidenceAPI.SUBJECTS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        size: 0,
      }),
    }
  );

  return response?.count ?? 0;
}

import { User } from './user';
import { AuthoredObject } from './authored-object';
import { ArchivedFile } from './archived-file';
import { SecurityRole, UserRole, Role } from './role';
import { Subject } from './subject';

/**
 * Žádost o roli
 */
export interface RoleRequest extends AuthoredObject {
  /**
   * Uživatel, který o rolu zažádal
   */
  requester?: User;

  /**
   * Stav žádosti
   */
  state?: RoleRequestState;

  /**
   * Odůvodnění žádosti
   */
  reason?: string;

  /**
   * Dokument k žádosti
   */
  document?: ArchivedFile;

  /**
   * Typ žádosti o roli
   */
  type?: RoleRequestType;

  /**
   * Požadované role
   */
  requestedRoles?: Role[];

  /**
   * Schválené role
   */
  approvedRoles?: Role[];
}

/**
 * Žádost o bezpečnostní roli
 */
export interface SecurityRoleRequest extends RoleRequest {
  /**
   * Požadované bezpečnostní role
   */
  requestedRoles?: SecurityRole[];

  /**
   * Schválené bezpečnostní role
   */
  approvedRoles?: SecurityRole[];
}

/**
 * Žádost o uživatelskou roli
 */
export interface UserRoleRequest extends RoleRequest {
  /**
   * Subjekt
   */
  subject?: Subject;

  /**
   * Požadované uživatelské role
   */
  requestedRoles?: UserRole[];

  /**
   * Schválené uživatelské role
   */
  approvedRoles?: UserRole[];
}

/**
 * Stav žádosti o roli
 */
export enum RoleRequestState {
  /**
   * Čekající na schválení
   */
  PENDING = 'PENDING',

  /**
   * Schválena
   */
  APPROVED = 'APPROVED',

  /**
   * Zamítnuta
   */
  REJECTED = 'REJECTED',
}

/**
 * Typ žádosti o roli
 */
export enum RoleRequestType {
  /**
   * Žádost o bezpečnostní roli
   */
  SECURITY_ROLE_REQUEST = 'SECURITY_ROLE_REQUEST',

  /**
   * Žádost o uživatelskou roli
   */
  USER_ROLE_REQUEST = 'USER_ROLE_REQUEST',
}

import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { Empowerment } from '../../models';
// import { icoValueMapperFactory } from '../../composite/subject-column-mappers/ico-mapper';
// import { artificialIdValueMapperFactory } from '../../composite/subject-column-mappers/artificial-id-mapper';
// import { idValueMapperFactory } from '../../composite/subject-column-mappers/id-mapper';
import { useEmpowerableAgendas } from '../agenda/agendas-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { specificationsValueMapper } from '../../composite/empowerment-column-mappers/specifications-mapper';
import { useAgendaSpecifications } from '../../composite/empowerment-specs-table/specification-api';

export function useColumns(
  canSeeDeactivated: boolean
): TableColumn<Empowerment>[] {
  const columns: TableColumn<Empowerment>[] = [
    {
      datakey: 'agenda.name',
      filterkey: 'agenda.id',
      name: 'Agendy',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useEmpowerableAgendas
      ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'empowerer.name',
      name: 'Zmocnitel - Název',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'empowerer.identifier',
      name: 'Zmocnitel - IČO / UID',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    // {
    //   datakey: 'empowerer.artificialId',
    //   name: 'Zmocnitel - UID',
    //   width: 200,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: inactiveColoredFactory(TableCells.TextCell),
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 2,
    //   valueMapper: artificialIdValueMapperFactory('empowerer'),
    // },
    {
      datakey: 'empowerer.identityDocumentsConcatenated',
      name: 'Zmocnitel - OP / pas',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'agent.name',
      name: 'Zmocněnec - Název',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'agent.identifier',
      name: 'Zmocněnec - IČO / UID',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    // {
    //   datakey: 'agent.artificialId',
    //   name: 'Zmocněnec - UID',
    //   width: 200,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: inactiveColoredFactory(TableCells.TextCell),
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 3,
    //   valueMapper: artificialIdValueMapperFactory('agent'),
    // },
    {
      datakey: 'agent.identityDocumentsConcatenated',
      name: 'Zmocněnec - OP / pas',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    // {
    //   datakey: 'isdsId',
    //   name: 'Identifikátor datové schránky (ISDS)',
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 1
    // },
    {
      datakey: 'specifications',
      name: 'Typ hlášení',
      filterkey: 'specifications.id',
      sortkey: 'specifications.concatenated',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useAgendaSpecifications
      ),
      valueMapper: specificationsValueMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
  if (canSeeDeactivated) {
    columns.push({
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    });
  }
  return columns;
}

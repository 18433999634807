import React, { useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import {
  FormFieldWrapper,
  FormSelect,
  useFormSelector,
  useEventCallback,
  FormContext,
} from '@eas/common-web';
import { useHouseNumberTypes } from '../address-field-api';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';
import { Address } from '../../../../models';
import { HelpLabel } from '../../../help/help-label';

export function HouseNumberTypeCell() {
  const { code, name, disabled, isRuian } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);
  const { setFieldValue } = useContext(FormContext);

  const { ruianCitySelected, addressPlaceRuian } = useFormSelector(
    (data: { [index: string]: Address | undefined }) => ({
      ruianCitySelected: get(data, name)?.municipalityRuian?.id,
      addressPlaceRuian: get(data, name)?.addressPlaceRuian,
    })
  );

  const isDisabled = disabled || (isRuian && !ruianCitySelected);

  const houseNumberTypes = useHouseNumberTypes();

  const notifyChange = useEventCallback(() => {
    if (addressPlaceRuian?.id) {
      unstable_batchedUpdates(() => {
        setFieldValue(`${name}.addressPlaceRuian`, undefined);
        setFieldValue(`${name}.houseNumber`, undefined);
        setFieldValue(`${name}.orientationNumber`, undefined);
        setFieldValue(`${name}.orientationNumberMark`, undefined);
        setFieldValue(`${name}.zip`, undefined);
      });
    }
  });

  return (
    <FormFieldWrapper
      disabled={isDisabled}
      required={false}
      label={
        <HelpLabel
          label={defaults?.houseNumberType?.label ?? 'Typ stavebního objektu'}
          required={defaults?.houseNumberType?.required ?? false}
          code={`${code}_HOUSE_NUMBER_TYPE`}
        />
      }
      labelOptions={{}}
      layoutOptions={{}}
      errorOptions={{}}
    >
      <Grid item xs={8}>
        <FormSelect
          disabled={isDisabled}
          notifyChange={notifyChange}
          name="houseNumberType"
          source={houseNumberTypes}
          labelOptions={{ hide: true }}
          valueIsId={true}
          layoutOptions={{ noUnderline: true, noSpacing: true }}
        />
      </Grid>
    </FormFieldWrapper>
  );
}

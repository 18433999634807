import {
  usePrompts,
  useEventCallback,
  DetailHandle,
  DetailContext,
  PromptContext,
  AbortableFetch,
  SnackbarContext,
} from '@eas/common-web';
import { startCall } from '../job/schedule-jobs-api';
import { unstable_batchedUpdates } from 'react-dom';
import { useContext, useRef } from 'react';
import { ScheduleJob } from '../../../models';
import { Messages } from '../../../enums';

const PROMPT_KEY = 'START';

export function useStartDialog() {
  const { isExisting, source, onPersisted } = useContext<
    DetailHandle<ScheduleJob>
  >(DetailContext);
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const fetch = useRef<AbortableFetch | null>(null);

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Varování',
      dialogText: 'Skutečně chcete spustit úlohu?',
    },
  ]);

  const handleStart = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = startCall(source.url, source.data!.id);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.ScheduleJob.START.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.ScheduleJob.START.ERROR);

            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showStartButton = isExisting && !source.data?.running;

  return { handleStart, showStartButton };
}

import React, { useContext } from 'react';
import {
  FormPanel,
  Form,
  FormSelect,
  FormTextField,
  FormAutocomplete,
  FormCustomField,
  TextField,
  UserContext,
} from '@eas/common-web';
import { useSubjectRegistrationTypes } from '../subject-requests-api';
import { ProcessForm } from '../subject-requests-types';
import { noop } from 'lodash';
import { SubjectRegistrationType, SubjectType } from '../../../models';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { AddressField } from '../../../components/form/address-field/address-field';
import { SubjectRequestFileFields } from '../../subject-request/fields/subject-request-file-fields';
import { useDocumentTypes } from '../../subject/subjects-api';
import { useAgendas } from '../../agenda/agendas-api';

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const { user } = useContext(UserContext);

  const subjectRegistrationTypes = useSubjectRegistrationTypes();
  const documentTypes = useDocumentTypes();
  const agendas = useAgendas();

  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Subjekt">
        <FormSelect
          label="Typ subjektu"
          name="type"
          source={subjectRegistrationTypes}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          valueIsId={true}
        />

        {allValues.type ===
          SubjectRegistrationType.NATURAL_PERSON_REGISTRATION &&
          !user?.hasNiaExternalId && (
            <>
              <FormSelect
                name="identityDocumentType"
                label="Typ dokladu"
                helpLabel="Povinné pole"
                source={documentTypes}
                valueIsId
              />
              <FormTextField
                name="idNumber.value"
                label="Číslo dokladu"
                helpLabel="Povinné pole"
              />
            </>
          )}

        {allValues.type ===
          SubjectRegistrationType.NATURAL_PERSON_REGISTRATION &&
          user?.hasNiaExternalId && (
            <FormCustomField label="Číslo dokladu">
              <TextField value="Na základě údajů z Identity občana" disabled />
            </FormCustomField>
          )}
        {allValues.type ===
          SubjectRegistrationType.BUSINESS_NATURAL_PERSON_REGISTRATION && (
          <>
            <FormTextField label="IČO" name="ico.value" />
          </>
        )}
        {allValues.type ===
          SubjectRegistrationType.FOREIGN_LEGAL_ENTITY_REGISTRATION && (
          <>
            <FormTextField label="Název" name="name" />
            <FormTextField
              name="foreignId"
              label="Zahraniční identifikátor (obdoba IČO)"
            />
            <SubjectRequestFileFields type={SubjectType.FOREIGN_LEGAL_ENTITY} />
            <AddressField name="residence" disabled />
          </>
        )}
        {allValues.type ===
          SubjectRegistrationType.FOREIGN_NATURAL_PERSON_REGISTRATION && (
          <>
            <FormTextField label="Jméno" name="firstName.value" />
            <FormTextField label="Příjmení" name="lastName.value" />
            <SubjectRequestFileFields
              type={SubjectType.FOREIGN_NATURAL_PERSON}
            />
            <AddressField name="residence" disabled />
          </>
        )}
        {allValues.type ===
          SubjectRegistrationType.LEGAL_ENTITY_REGISTRATION && (
          <>
            <FormTextField label="IČO" name="ico" />
          </>
        )}
        {allValues.type ===
          SubjectRegistrationType.LEGAL_ENTITY_WITHOUT_ICO_REGISTRATION && (
          <>
            <FormTextField label="Název" name="name" />
            <SubjectRequestFileFields
              type={SubjectType.LEGAL_ENTITY_WITHOUT_ICO}
            />
            <AddressField name="residence" disabled />
          </>
        )}
        {(allValues.agendas ?? []).length > 0 && (
          <FormAutocomplete
            label="Agenda"
            name="agendas"
            source={agendas}
            multiple
            labelMapper={dictionaryLabelMapper}
            tooltipMapper={dictionaryLabelMapper}
          />
        )}
        {(allValues.agendas ?? []).length === 0 && (
          <FormCustomField label="Agenda">
            <TextField value="Bez agendy" disabled />
          </FormCustomField>
        )}
      </FormPanel>
    </Form>
  );
}

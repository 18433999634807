import React from 'react';
import { StepOtherSubjectForm } from './step-other-subject-types';
import { AuthorRole, SubjectType, IdType } from '../../../models';
import { useSubjectTypes } from '../../subject/subjects-api';
import { ProcessForm } from '../empowerment-requests-types';

export function StepOtherSubjectLabel({
  stepValues,
  allValues,
}: {
  stepValues?: StepOtherSubjectForm;
  allValues?: ProcessForm;
}) {
  const { empowerer } = stepValues!;
  /**
   * Name of selected type of subject.
   */
  const subjectTypes = useSubjectTypes();
  const subjectType = subjectTypes.items.find((s) => s.id === empowerer?.type)
    ?.name;

  /**
   * Name of selected type of ID card.
   */
  let idType = '';
  if (empowerer?.idType === IdType.ID_CARD) {
    idType = 'Číslo OP';
  } else if (empowerer?.idType === IdType.PASSPORT) {
    idType = 'Číslo pasu';
  }

  /**
   * Label composition if LEGAL_ENTITY: ico
   */
  const hasIco =
    empowerer?.type === SubjectType.LEGAL_ENTITY ||
    empowerer?.type === SubjectType.BUSINESS_NATURAL_PERSON;

  /**
   * Label composition if NO_ICO_LEGAL_ENTITY: artificialId
   */
  const hasUID =
    empowerer?.type === SubjectType.FOREIGN_LEGAL_ENTITY ||
    empowerer?.type === SubjectType.FOREIGN_NATURAL_PERSON ||
    empowerer?.type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO;

  /**
   * Label composition if NATURAL_PERSON: typeOfId [ID_CARD || PASSPORT] + NUMBER_OF_CARD
   */
  const hasIdOrPassport = empowerer?.type === SubjectType.NATURAL_PERSON;

  /**
   * Role of user filling the request. [EMPOWERER || AGENT]
   */
  const authorRole = allValues?.authorRole;

  /**
   * Main label of step [Výběr subjektu || Výběr zmocněnce || Výběr zmocnitele]
   */
  const label =
    authorRole === AuthorRole.EMPOWERER
      ? 'zmocněnce'
      : authorRole === AuthorRole.AGENT
      ? 'zmocnitele'
      : 'subjektu';

  /**
   * Number of card.
   */
  const idNumber =
    empowerer?.idType === IdType.ID_CARD
      ? empowerer?.idCardNumber
      : empowerer?.idType === IdType.PASSPORT
      ? empowerer?.passportNumber
      : undefined;

  return (
    <>
      Výběr {label}: {subjectType && <b>{subjectType}</b>}
      {hasIco && empowerer?.ico && (
        <>
          ; IČO: <b>{empowerer?.ico}</b>
        </>
      )}
      {hasUID && empowerer?.artificialId && (
        <>
          ; UID: <b>{empowerer?.artificialId}</b>
        </>
      )}
      {hasIdOrPassport && idType && idNumber && (
        <>
          ; {idType}: <b>{idNumber}</b>
        </>
      )}
    </>
  );
}

import React from 'react';
import {
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
  FormPanel,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export default function DictionaryRuianRegionFields() {
  const prefix = 'RUIAN_REGION';

  return (
    <>
      <FormPanel label="Vyšší územní samoprávní celek (nový kraj)">
        <FormTextField
          name="nutsLauCode"
          label={
            <HelpLabel label="Kód NUTS/LAU" code={`DICT_${prefix}_CODE`} />
          }
        />
      </FormPanel>
      <FormPanel label="Region soudržnosti">
        <FormTextField
          name="cohesionRegion.name"
          label={
            <HelpLabel
              label="Název"
              code={`DICT_${prefix}_COHESION_REGION_NAME`}
            />
          }
        />
        <FormTextField
          name="cohesionRegion.isuiCode"
          label={
            <HelpLabel
              label="Kód v ISÚI"
              code={`DICT_${prefix}_COHESION_REGION_CODE`}
            />
          }
        />
        <FormCheckbox
          name="cohesionRegion.active"
          label={
            <HelpLabel
              label="Aktivní"
              code={`DICT_${prefix}_COHESION_REGION_ACTIVE`}
            />
          }
        />
        <FormDateTimeField
          name="cohesionRegion.created"
          label={
            <HelpLabel
              label="Vytvoření"
              code={`DICT_${prefix}_COHESION_REGION_CREATED`}
            />
          }
        />
      </FormPanel>
    </>
  );
}

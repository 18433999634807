import {
  ApiFilterOperation,
  FilesProvider,
  FormAutocomplete,
  FormContext,
  FormFileField,
  FormPanel,
  FormSelect,
  FormTextArea,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { uniqBy } from 'lodash';
import React, { useContext } from 'react';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { EvidenceAPI } from '../../../enums';
import {
  RoleRequestType,
  RoleType,
  SubjectAutocomplete,
  UserRole,
  UserRoleRequest,
} from '../../../models';
import { useRoles } from '../../roles/roles-api';
import { useSubjectRoles, useSubjects } from '../../subject/subjects-api';
import { ProcessForm } from '../role-requests-type';
import { StepInfoForm } from './step-info-types';

export function StepInfoContent({ allValues }: { allValues: ProcessForm }) {
  const { setFieldValue } = useContext(FormContext);
  const subjects = useSubjects(undefined, true);

  const { system, subject } = useFormSelector((data: StepInfoForm) => ({
    system: data.system,
    subject: (data as UserRoleRequest).subject,
  }));

  const { rolesSource: subjectRolesSource, roles } = useSubjectRoles(
    subject,
    true,
    (roles) =>
      roles.filter(
        (r) =>
          r?.type === RoleType.USER_ROLE && (r as UserRole)?.allowedToRequest
      )
  );

  const systems = useStaticListSource(
    uniqBy(
      subjectRolesSource.items.map((role) => role.registeredFor!),
      'id'
    )
  );

  const subjectRolesForSelectedSystem = useStaticListSource(
    roles.filter((role) => role?.registeredFor?.id === system?.id)
  );

  const securityRoles = useRoles([
    {
      field: 'type.id',
      value: RoleType.SECURITY_ROLE,
      operation: ApiFilterOperation.EQ,
    },
    {
      field: 'registeredFor.id',
      value: system?.id,
      operation: ApiFilterOperation.EQ,
    },
    {
      field: 'allowedToRequest',
      value: true,
      operation: ApiFilterOperation.EQ,
    },
  ]);

  return (
    <FormPanel label="Vyplňte informace">
      {allValues.type === RoleRequestType.USER_ROLE_REQUEST && (
        <FormAutocomplete<SubjectAutocomplete>
          source={subjects}
          name="subject"
          label="Subjekt"
          required
          labelMapper={dictionaryLabelMapper}
          notifyChange={() => {
            setFieldValue('system', undefined);
            setFieldValue('requestedRoles', undefined);
          }}
        />
      )}
      <FormSelect
        name="system"
        label="Systém, který eviduje danou roli"
        source={systems}
        required
        notifyChange={() => {
          setFieldValue('requestedRoles', undefined);
        }}
      />
      {allValues.type === RoleRequestType.SECURITY_ROLE_REQUEST && (
        <FormAutocomplete
          source={securityRoles}
          name="requestedRoles"
          label="Požadované role" // Povinné pole
          multiline
          multiple
          required
          labelMapper={dictionaryLabelMapper}
          disabled={!system}
        />
      )}
      {allValues.type === RoleRequestType.USER_ROLE_REQUEST && (
        <FormSelect
          source={subjectRolesForSelectedSystem}
          name="requestedRoles"
          label="Požadované role" // Povinné pole
          multiple
          required
          disabled={!system}
        />
      )}
      <FormTextArea
        name="reason"
        label="Odůvodnění žádosti" // Povinné pole
        required
      />
      <FilesProvider url={EvidenceAPI.ARCHIVED_FILES}>
        <FormFileField name="document" label="Dokument" />
      </FilesProvider>
    </FormPanel>
  );
}

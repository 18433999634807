import React from 'react';
import { FormAutocomplete, FormPanel } from '@eas/common-web';
import { useStepFacility } from './step-facility-hook';
import { HelpLabel } from '../../../components/help/help-label';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';

export function StepFacilityContent() {
  const irzFacilities = useStepFacility();

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete
        name="irzFacility"
        label={
          <HelpLabel
            label="Provozovna IRZ"
            code="IRZ_FACILITY_REQUEST_CHANGE_IRZ_FACILITY"
            required
          />
        } // Povinné pole
        source={irzFacilities}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

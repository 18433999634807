import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Table, TableHandle, useScrollableSource } from '@eas/common-web';
import { Outage } from '../../models/outage';
import { PageKey, EvidenceAPI } from '../../enums';
import { usePublicColumns } from './outages-columns';

export function OutagesPublic() {
  const tableRef = useRef<TableHandle<Outage>>(null);

  const columns = usePublicColumns();

  const tableSource = useScrollableSource<Outage>({
    url: EvidenceAPI.OUTAGES_PUBLIC + '/list',
  });

  return (
    <Box flexGrow={1} flexShrink={1}>
      <AutoSizer disableWidth={true}>
        {({ height }) => (
          <div style={{ height }}>
            <Table<Outage>
              tableId={`${PageKey.OUTAGES_PUBLIC}` + '_TABLE'}
              ref={tableRef}
              source={tableSource}
              columns={columns}
              tableName="Odstávky a výpadky"
              defaultSorts={[]}
              showSelectBox={false}
              showBulkActionButton={false}
              showReportButton={false}
              height={height}
            />
          </div>
        )}
      </AutoSizer>
    </Box>
  );
}

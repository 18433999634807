import { useContext } from 'react';
import {
  abortableFetch,
  UserContext,
  DetailHandle,
  DetailContext,
} from '@eas/common-web';
import { EvidenceAPI, Permission } from './../../../enums';
import { Me, ProtectedDepositArea } from './../../../models';
import { ChluState } from '../../../models/protected-deposit-area';

export function useCancelDialog() {
  const { source } = useContext<DetailHandle<ProtectedDepositArea>>(
    DetailContext
  );
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = (id: string) => {
    return abortableFetch(
      `${EvidenceAPI.PROTECTED_DEPOSIT_AREAS}/${id}/cancel`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    );
  };

  const canCancel =
    hasPermission(Permission.Chlu.CHLU_CANCEL) &&
    source?.data?.state === ChluState.CONCEPT &&
    source?.data?.inRuian;

  return { callApi, canCancel };
}

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: `${theme.spacing(8)}px 0`,
    },
    text: {
      fontWeight: 400,
      lineHeight: '2em',
    },
    underline: {
      display: 'inline-block',
      boxShadow: `0px 13px 0px -10px ${theme.palette.primary.main}`,
    },
  })
);

export function Title({ name }: { name: string }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h1" align="center" className={classes.text}>
          <span className={classes.underline}>{name}</span>
        </Typography>
      </Grid>
    </Grid>
  );
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
    {
      key: 'contact.name',
      label: 'Jméno',
    },
    {
      key: 'contact.email',
      label: 'E-mail',
    },
    {
      key: 'contact.phone',
      label: 'Telefon',
    },
  ];
}

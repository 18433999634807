import { jsx } from 'slate-hyperscript';
import { Descendant } from 'slate';

export function deserialize(
  node: HTMLElement
): Descendant[] | Descendant | string {
  if (node.nodeType === Node.TEXT_NODE) {
    /**
     * The actual Text inside an Element or Attr.
     */
    return node?.textContent ?? '';
  } else if (node.nodeType !== Node.ELEMENT_NODE) {
    /**
     * An Element node like <p> or <div>.
     */
    return '';
  }

  let children = Array.from(node.childNodes).map((node) =>
    deserialize(node as HTMLElement)
  );

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  switch (node.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children);
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    case 'STRONG':
      return jsx('text', { type: 'bold', bold: true }, children);
    case 'EM':
      return jsx('text', { type: 'italic', italic: true }, children);
    case 'U':
      return jsx('text', { type: 'underline', underline: true }, children);
    case 'CODE':
      return jsx('text', { type: 'code', code: true }, children);
    case 'A':
      return jsx(
        'text',
        {
          type: 'link',
          link: {
            url: node.getAttribute('href'),
            name: node.getAttribute('data-name'),
            contentType: node.getAttribute('data-content-type'),
            size: node.getAttribute('data-size'),
          },
        },
        children
      );
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote' }, children);
    case 'H1':
      return jsx('element', { type: 'heading-one' }, children);
    case 'H2':
      return jsx('element', { type: 'heading-two' }, children);
    default:
      return node?.textContent ?? '';
  }
}

import React from 'react';
import {
  FormTextField,
  FormPanel,
  FormSelect,
  FormDateTimeField,
} from '@eas/common-web';
import { useSoapExecutionStatuses } from './soap-execution-logs-api';
import { HelpLabel } from '../../components/help/help-label';
import { useWebServices } from '../web-service/web-services-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';

export function SoapExecutionLogsFields() {
  const statusSource = useSoapExecutionStatuses();
  const webServices = useWebServices();

  return (
    <FormPanel label="Základní informace">
      <FormSelect
        name="service"
        source={webServices}
        label={
          <HelpLabel label="Název služby" code="SOAP_EXECUTION_LOG_SERVICE" />
        }
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
        valueIsId={true}
      />
      <FormTextField
        name="localPart"
        label={
          <HelpLabel label="Operace" code="SOAP_EXECUTION_LOG_LOCAL_PART" />
        }
      />
      <FormTextField
        name="version"
        label={
          <HelpLabel
            label="Verze služby (rozhraní)"
            code="SOAP_EXECUTION_LOG_VERSION"
          />
        }
      />
      <FormTextField
        name="duration"
        label={
          <HelpLabel
            label="Trvání operace (ms)"
            code="SOAP_EXECUTION_LOG_DURATION"
          />
        }
      />
      <FormSelect
        name="status"
        valueIsId={true}
        label={
          <HelpLabel
            label="Výsledek operace"
            code="SOAP_EXECUTION_LOG_STATUS"
          />
        }
        source={statusSource}
      />
      <FormTextField
        name="exception"
        label={
          <HelpLabel
            label="Chybové hlášení"
            code="SOAP_EXECUTION_LOG_EXCEPTION"
          />
        }
      />
      <FormTextField
        name="createdBy.name"
        label={
          <HelpLabel
            label="Autor volání"
            code="SOAP_EXECUTION_LOG_CREATED_BY_NAME"
          />
        }
      />
      <FormDateTimeField
        name="created"
        label={
          <HelpLabel
            label="Datum a čas volání"
            code="SOAP_EXECUTION_LOG_CREATED"
          />
        }
      />
    </FormPanel>
  );
}

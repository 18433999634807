import React, { ChangeEvent, useMemo } from 'react';
import { uniqBy } from 'lodash';
import { SelectProps, DomainObject, useEventCallback } from '@eas/common-web';
import MuiSelect from '@material-ui/core/Select';
import MuiMenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { useStyles } from './public-select-styles';

export function publicSelectFactory<OPTION extends DomainObject>({
  label,
}: {
  label: string;
}) {
  return function PublicSelect({
    form,
    disabled,
    source,
    value,
    onChange,
    valueIsId = false,
    clearable = true,
    idMapper = (option: OPTION) => option.id,
    labelMapper = (option: OPTION) => (option as any).name,
    multiple = false,
    error,
    helperText,
  }: SelectProps<OPTION> & { helperText?: string; error?: boolean }) {
    // fix undefined value
    value = value ?? null;

    type VALUE = OPTION | string;
    type VALUES = OPTION[] | string[];

    const { list, item, adornment, paper } = useStyles();

    const valueToId = useEventCallback((value: VALUE) => {
      return valueIsId ? (value as string) : idMapper(value as OPTION);
    });

    const valuesToIds = useEventCallback((values: VALUES) => {
      return (values as Array<OPTION | string>).map((value) =>
        valueToId(value)
      );
    });

    // in some cases the value is not included in the options, adds it at the end
    const options: OPTION[] = useMemo(() => {
      if (value && !valueIsId) {
        if (multiple) {
          return uniqBy([...source.items, ...(value as OPTION[])], valueToId);
        } else {
          return uniqBy([...source.items, value as OPTION], valueToId);
        }
      } else {
        return source.items;
      }
    }, [value, source, valueIsId, valueToId, multiple]);

    const idToValue = useEventCallback((id: string) => {
      return valueIsId
        ? id
        : options.find((option) => idMapper(option) === id)!;
    });

    const idsToValues = useEventCallback((ids: string[]) => {
      return ids.map((id) => idToValue(id)) as VALUES;
    });

    const handleChange = useEventCallback(
      async (e: ChangeEvent<{ name?: string; value: unknown }>) => {
        const ids = e.target.value;

        let values: VALUE | VALUES | null;
        if (ids === undefined || ids === '') {
          values = null;
        } else if (multiple) {
          values = idsToValues(ids as string[]);
        } else {
          values = idToValue(ids as string);
          if (!valueIsId) {
            values = await source.loadDetail(values as any);
          }
        }

        onChange(values);
      }
    );

    const handleClear = useEventCallback(() => {
      onChange(null);
    });

    function getLocalValue(value: VALUE | VALUE[] | null) {
      if (!value) {
        if (multiple) {
          return [];
        } else {
          return '';
        }
      }

      const optionIds = options.map((option) => idMapper(option));

      if (multiple) {
        const ids = valuesToIds(value as VALUES);
        return ids.filter((id) => optionIds.includes(id));
      } else {
        const id = valueToId(value as VALUE);
        return optionIds.includes(id) ? id : '';
      }
    }

    const showClearButton = clearable && !disabled && value !== null;

    return (
      <FormControl fullWidth={true}>
        <InputLabel error={error}>{label}</InputLabel>
        <MuiSelect
          endAdornment={
            <InputAdornment position="end" classes={{ root: adornment }}>
              {showClearButton && (
                <IconButton size="small" onClick={handleClear}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </InputAdornment>
          }
          inputProps={{
            form,
          }}
          error={error}
          label={label}
          multiple={multiple}
          disabled={disabled}
          autoWidth={true}
          value={getLocalValue(value)}
          onChange={handleChange}
          MenuProps={{
            classes: { list },
            PopoverClasses: {
              paper,
            },
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            getContentAnchorEl: null,
          }}
        >
          {options.map((option) => (
            <MuiMenuItem
              key={idMapper(option)}
              value={idMapper(option)}
              classes={{ root: item }}
            >
              {labelMapper(option)}
            </MuiMenuItem>
          ))}
        </MuiSelect>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    );
  };
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const REQUEST_SUBMIT: MessageType<'SUCCESS' | 'ERROR' | 'OVZ_PENDING_TRANSFER' | 'ENTITY_NOT_EXIST'> = {
  SUCCESS: [
    'Nabídka změny provozovatele byla úspěšně odeslána.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    'Nastala chyba při odesílaní nabídky změny provozovatele.',
    SnackbarVariant.ERROR,
  ],
  OVZ_PENDING_TRANSFER: [
    'Nastala chyba při odesílaní nabídky změny provozovatele. Některá z provozoven již má nabídku na změnu provozovatele, která dosud nebyla provedena.',
    SnackbarVariant.ERROR,
  ],
  ENTITY_NOT_EXIST: ['Nastala chyba při odesílaní nabídky změny provozovatele. Nový provozovatel není v systému CRŽP evidován.', SnackbarVariant.ERROR]
};

const REJECT: MessageType = {
  SUCCESS: ['Nabídka byla úspěšně zamítnuta.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zamítnutí nabídky.', SnackbarVariant.ERROR],
};

const APPROVE: MessageType<'SUCCESS' | 'ERROR' | 'FUTURE_OPERATION_PRESENT'> = {
  SUCCESS: ['Nabídka byla úspěšně schválena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při schvalování nabídky.', SnackbarVariant.ERROR],
  FUTURE_OPERATION_PRESENT: [
    'Chyba při změně provozovatele. V daném datu již vazba na provozovatele existuje.',
    SnackbarVariant.ERROR,
  ],
};

const REGISTER: MessageType & MessageType<'NAME_NOT_UNIQUE'> = {
  SUCCESS: [
    'Provozovna OVZ byla úspěšně zaregistrována.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: ['Chyba při registraci provozovny.', SnackbarVariant.ERROR],
  NAME_NOT_UNIQUE: ['Název provozovny není unikátní', SnackbarVariant.ERROR],
};

export default {
  REQUEST_SUBMIT,
  REJECT,
  APPROVE,
  REGISTER,
};

import React from 'react';
import {
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
  FormSelect,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useHouseNumberTypes } from '../../components/form/address-field/address-field-api';

export default function DictionaryRuianAddressPlaceFields() {
  const prefix = 'RUIAN_ADDRESS_PLACE';
  const houseNumberTypes = useHouseNumberTypes();

  return (
    <>
      <FormPanel label="Adresní místo">
        <FormTextField
          name="houseNumber"
          label={
            <HelpLabel
              label="Číslo domovní"
              code={`DICT_${prefix}_HOUSE_NUMBER`}
            />
          }
        />
        <FormTextField
          name="zip"
          label={<HelpLabel label="PSČ" code={`DICT_${prefix}_ZIP`} />}
        />
        <FormSelect
          name="houseNumberType"
          source={houseNumberTypes}
          valueIsId
          label={
            <HelpLabel
              label="Typ stavebního objektu"
              code={`DICT_${prefix}_HOUSE_NUMBER_TYPE`}
            />
          }
        />
      </FormPanel>
      <FormPanel label="Část obce">
        <FormTextField
          name="municipalityPart.name"
          label={
            <HelpLabel
              label="Název"
              code={`DICT_${prefix}_MUNICIPALITY_PART_NAME`}
            />
          }
        />
        <FormTextField
          name="municipalityPart.isuiCode"
          label={
            <HelpLabel
              label="Kód v ISÚI"
              code={`DICT_${prefix}_MUNICIPALITY_PART_CODE`}
            />
          }
        />
        <FormCheckbox
          name="municipalityPart.active"
          label={
            <HelpLabel
              label="Aktivní"
              code={`DICT_${prefix}_MUNICIPALITY_PART_ACTIVE`}
            />
          }
        />
        <FormDateTimeField
          name="municipalityPart.created"
          label={
            <HelpLabel
              label="Vytvoření"
              code={`DICT_${prefix}_MUNICIPALITY_PART_CREATED`}
            />
          }
        />
      </FormPanel>
      <FormPanel label="Souřadnice JTSK">
        <FormTextField
          name="jtskCoordinates.x"
          label={
            <HelpLabel
              label="Souřadnice X"
              code={`DICT_${prefix}_JTSK_COORDINATE_X`}
            />
          }
        />
        <FormTextField
          name="jtskCoordinates.y"
          label={
            <HelpLabel
              label="Souřadnice Y"
              code={`DICT_${prefix}_JTSK_COORDINATE_Y`}
            />
          }
        />
      </FormPanel>
      <FormPanel label="Souřadnice WGS-84">
        <FormTextField
          name="wgs84Coordinates.lat"
          label={
            <HelpLabel
              label="Zeměpisná šířka (souřadnice 'x')"
              code={`DICT_${prefix}_WGS_COORDINATE_LAT`}
            />
          }
        />
        <FormTextField
          name="wgs84Coordinates.lon"
          label={
            <HelpLabel
              label="Zeměpisná délka (souřadnice 'y')"
              code={`DICT_${prefix}_WGS_COORDINATE_LAN`}
            />
          }
        />
      </FormPanel>
    </>
  );
}

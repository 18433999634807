import * as Yup from 'yup';
import { FileRef } from '@eas/common-web';
import { Empowerment, Subject, AgendaUnion } from '../../models';
import { validateSpecifications } from './empowerments-utils';

export function useValidationSchema() {
  return Yup.object<Empowerment>().shape({
    empowerer: Yup.mixed<Subject>()
      .nullable()
      .required('Vyberte jednu z hodnot tabulky'),
    agent: Yup.mixed<Subject>()
      .nullable()
      .required('Vyberte jednu z hodnot tabulky'),
    agenda: Yup.mixed<AgendaUnion>().nullable().required('Povinné pole'),
    empowermentDocument: Yup.mixed<FileRef>()
      .nullable()
      .required('Povinné pole'),
    validFrom: Yup.string().required('Povinné pole'),
    validTo: Yup.string()
      .nullable()
      .when('withoutDay', {
        is: (withoutDay?: boolean) => !withoutDay,
        then: Yup.string().nullable().required('Povinné pole'),
      }),
    specifications: validateSpecifications,
  }) as Yup.Schema<Empowerment>;
}

import { noop } from 'lodash';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  FormPanel,
  Form,
  FormTextField,
  FormTextArea,
  FormDateField,
  FormAutocomplete,
  FormInlineTableField,
  FormNumberField,
  FormCheckbox,
  FormCheckboxGroup,
  eqFilterParams,
  EmptyComponent,
  TextField,
  FormCustomField,
} from '@eas/common-web';
import { useNaceItems } from '../../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import { useCzRiverBasins } from '../../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { useEuRiverBasins } from '../../dict-river-basin-eu-district/dict-river-basin-eu-district-api';
import { useIrzActivities } from '../../dict-irz-activity/dict-irz-activities-api';
import { useEPrtrActivities } from '../../dict-e-prtr-activity/dict-e-prtr-activities-api';
import { useIppcActivities } from '../../dict-ippc-activity/dict-ippc-activities-api';
import { useMainActivities } from '../../irz-facility/irz-facilities-api';
import { MainActivityType } from '../../../models';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { ProcessForm } from '../irz-facility-requests-new-types';
import {
  dictionaryLabelMapper,
  dictionaryNoCodeLabelMapper,
} from '../../../components/form/mappers/label-mappers';
import { useTradesInEmissionsColumns } from '../../irz-facility/irz-facilities-columns';
import { useCompetentControlAuthorities } from '../step-control-authorities/step-control-authorities-api';
import { useOvzFacilities } from '../../ovz-facility/ovz-facilities-api';

const useStyles = makeStyles(() => ({
  mainEprtrActivity: {
    maxWidth: '60%',
  },
}));

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const classes = useStyles();

  const naceItems = useNaceItems();
  const naceCzItems = useNaceCzItems();
  const types = useMainActivities();
  const czRiverBasins = useCzRiverBasins();
  const euRiverBasins = useEuRiverBasins();
  const irzActivities = useIrzActivities();
  const ePrtrActivities = useEPrtrActivities();
  const ippcActivities = useIppcActivities();
  const authoritiesSource = useCompetentControlAuthorities();
  const ovzFacilities = useOvzFacilities(
    eqFilterParams({ field: 'operator.id', value: allValues.operator?.id })
  );

  const emmissionColumns = useTradesInEmissionsColumns();

  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Základní informace">
        <FormTextField label="Název provozovny" name="name" />
        <FormTextField label="Web stránka" name="webSite" />
        <FormTextField label="Veřejný e-mail" name="publicEmail" />
        <FormTextField label="Veřejný telefon" name="publicPhoneNumber" />
        <FormTextArea label="Poznámky" name="note" />
        <FormDateField
          label="Datum uvedení do provozu"
          name="commissioningDate"
        />
      </FormPanel>

      <LocationPanel
        locationName="wgs84Coordinates"
        locationValue={allValues?.wgs84Coordinates}
        disabled={true}
        addressName="address"
        locationPanelLabel="Poloha provozovny"
        addressPanelLabel="Adresa provozovny"
        prefillCoordinates={false}
      />

      <FormPanel label="Klasifikace, Identifikátory">
        <FormAutocomplete
          label="CZ-NACE"
          name="czNace"
          source={naceCzItems}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          label="NACE"
          name="nace"
          source={naceItems}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          label="Oblast povodí ČR"
          name="czRiverBasinDistrict"
          source={czRiverBasins}
          labelMapper={dictionaryNoCodeLabelMapper}
          tooltipMapper={dictionaryNoCodeLabelMapper}
        />
        <FormAutocomplete
          label="Oblast povodí Evropy"
          name="euRiverBasinDistrict"
          source={euRiverBasins}
          labelMapper={dictionaryNoCodeLabelMapper}
          tooltipMapper={dictionaryNoCodeLabelMapper}
        />
        <FormAutocomplete
          name="ovzFacility"
          label="IČP provozovny ovzduší"
          source={ovzFacilities}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
        <FormCheckbox
          name="integratedPermission.hasPermission"
          label="Provozovna/Zařízení má Integrované povolení"
        />
        {allValues.integratedPermission?.hasPermission && (
          <FormInlineTableField<string>
            label="Seznam PID (IPPC)"
            name="integratedPermission.ippcPids"
            withRemove={false}
            ToolbarComponent={EmptyComponent}
            columns={[
              {
                name: 'PID (IPPC)',
                datakey: '',
                width: 600,
                CellComponent: function Cell({ rowValue }) {
                  return <TextField value={rowValue} disabled={true} />;
                },
              },
            ]}
          />
        )}
        <FormCheckbox
          name="emissionsTradingSystem.tradesInEmissions"
          label="Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi"
        />
        {allValues?.emissionsTradingSystem?.tradesInEmissions === true && (
          <FormInlineTableField<string>
            name="emissionsTradingSystem.ids"
            label="ID zařízení (EU ETS)"
            columns={emmissionColumns}
            initNewItem={() => ''}
            helpLabel="Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9"
          />
        )}
      </FormPanel>
      <FormPanel label="Kompetentní kontrolní a ostatní orgány">
        <FormAutocomplete
          label="Kompetentní kontrolní a ostatní orgány"
          source={authoritiesSource}
          name="competentControlAuthorities"
          multiple
        />
      </FormPanel>
      <FormPanel label="Činnosti">
        <FormCheckboxGroup
          label="Typ činnosti"
          name="activityTypes"
          source={types}
        />

        {allValues?.activityTypes?.includes(MainActivityType.E_PRTR) && (
          <>
            <FormCustomField label="Hlavní E-PRTR činnost">
              <span className={classes.mainEprtrActivity}>
                <FormAutocomplete
                  name="mainEprtrActivity.activity"
                  source={ePrtrActivities}
                  layoutOptions={{ noUnderline: true }}
                  labelOptions={{ hide: true }}
                  tooltipMapper={dictionaryLabelMapper}
                  labelMapper={dictionaryLabelMapper}
                />
              </span>
              <FormTextField
                name="mainEprtrActivity.facilityCount"
                type="number"
                layoutOptions={{ noUnderline: true }}
                label="Počet zařízení"
              />
            </FormCustomField>
            <FormAutocomplete
              name="ippcActivity"
              label="Činnost IPPC"
              source={ippcActivities}
              tooltipMapper={dictionaryLabelMapper}
              labelMapper={dictionaryLabelMapper}
            />
            <FormInlineTableField
              label="Seznam vedlejších E-PRTR činností"
              name="secondaryEprtrActivities"
              withRemove={false}
              columns={[
                {
                  name: 'Vedlejší E-PRTR činnost',
                  datakey: 'activity.label',
                  width: 400,
                },
                {
                  name: 'Počet zařízení',
                  datakey: 'facilityCount',
                  width: 100,
                },
                {
                  name: 'Činnost IPPC',
                  datakey: 'ippcActivity.label',
                  width: 200,
                },
              ]}
              maxRows={5}
            />
          </>
        )}
        {allValues?.activityTypes?.includes(MainActivityType.IRZ) && (
          <>
            {!allValues?.activityTypes?.includes(MainActivityType.E_PRTR) && (
              <>
                <FormAutocomplete
                  label="Hlavní činnost dle přílohy zákona o IRZ a ISPOP"
                  name="mainIrzActivity.activity"
                  source={irzActivities}
                  labelMapper={dictionaryLabelMapper}
                  tooltipMapper={dictionaryLabelMapper}
                />
                <FormNumberField
                  label="Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP"
                  name="mainIrzActivity.facilityCount"
                />
              </>
            )}

            <FormInlineTableField
              label="Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP"
              name="secondaryIrzActivities"
              withRemove={false}
              columns={[
                {
                  name: 'Vedlejší činnost dle zákona o IRZ a ISPOP',
                  datakey: 'activity.label',
                  width: 400,
                },
                {
                  name: 'Počet zařízení',
                  datakey: 'facilityCount',
                  width: 200,
                },
              ]}
              maxRows={5}
            />
          </>
        )}
        {!allValues?.activityTypes?.includes(MainActivityType.E_PRTR) && (
          <FormAutocomplete
            label="Činnost IPPC"
            name="ippcActivity"
            source={ippcActivities}
            labelMapper={dictionaryLabelMapper}
            tooltipMapper={dictionaryLabelMapper}
          />
        )}
      </FormPanel>
    </Form>
  );
}

import { useContext } from 'react';
import {
  HookedSource,
  useScrollableSource,
  UserContext,
} from '@eas/common-web';
import { EvidenceAPI, Permission } from '../../enums';
import { Subject } from '../../models';

export const SUBJECT_USER_SOURCE = 'SUBJECT_USER_SOURCE';

function useUserSource(): HookedSource<Subject> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [SUBJECT_USER_SOURCE]: {
      source,
      shouldLoad: ({ id }) => {
        return (
          hasPermission(Permission.Subject.SUBJECT_DETAIL_OWN, {
            subjectId: id,
            searchOnlyInSubjectPerms: true,
          }) ||
          hasPermission(Permission.Subject.SUBJECT_DETAIL_ALL, {
            searchInSubjectPerms: true,
          })
        );
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceAPI.SUBJECTS}/${id}/user/list`);
      },
    },
  };
}

export function useSubjectHookedSources(): HookedSource<Subject> {
  const users = useUserSource();

  return users;
}

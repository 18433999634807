import { AuthoredObject } from './authored-object';
import { Subject } from './subject';
import { OvzFacility } from './ovz-facility';

export interface OvzFacilityRequest extends AuthoredObject {
  /**
   * Stav nabídky
   */

  state?: OvzFacilityRequestState;

  /**
   * Datum a čas vyřízení nabídky
   */
  decided?: string;

  /**
   * Datum odkdy daná změna platí
   */
  date?: string;

  /**
   * Poznámka k nabídce
   */
  note?: string;

  /**
   * Původní provozovatel
   */
  oldOperator?: Subject;

  /**
   * Odkaz na nového provozovatele který má být nastavený na OVZ
   */
  newOperator?: Subject;

  /**
   * Identifikace nového provozovatele který má být nastavený na OVZ
   */
  newOperatorIdentifier?: string;

  /**
   * Původní IČP (identifikační číslo provozovny)
   */
  originalFacilityId?: string;

  /**
   * Mapování zmeny jmen na provozovny
   */
  nameMappings?: OvzFacilityIdToNameMapping[];

  /**
   * Provozovny OVZ
   */
  ovzFacilities?: OvzFacility[];
}

export interface OvzFacilityIdToNameMapping {
  id?: string;
  name?: string;
}

export enum OvzFacilityRequestState {
  /**
   * Čeká na schválení
   */
  WAITING_FOR_AUTHORIZATION = 'WAITING_FOR_AUTHORIZATION',

  /**
   * Schválena
   */
  APPROVED = 'APPROVED',

  /**
   * Změna z nabídky byla provedena
   */
  COMPLETE = 'COMPLETE',

  /**
   * Zamítnuta
   */
  REJECTED = 'REJECTED',
}

import React, { useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import {
  FormFieldWrapper,
  FormTextField,
  useFormSelector,
  FormContext,
  useEventCallback,
} from '@eas/common-web';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';
import { HelpLabel } from '../../../help/help-label';
import { Address, HouseNumberType } from '../../../../models';

export function HouseNumberCell() {
  const { code, name, disabled, isRuian } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);
  const { setFieldValue, errors } = useContext(FormContext);

  const {
    ruianCitySelected,
    houseNumberType,
    addressPlaceRuian,
  } = useFormSelector((data: { [index: string]: Address | undefined }) => ({
    ruianCitySelected: get(data, name)?.municipalityRuian?.id,
    houseNumberType: get(data, name)?.houseNumberType,
    addressPlaceRuian: get(data, name)?.addressPlaceRuian,
  }));

  const isDisabled = disabled || (isRuian && !ruianCitySelected);

  const label =
    houseNumberType === HouseNumberType.REGISTRATION_NUMBER
      ? 'Číslo domovní'
      : 'Číslo popisné';

  const notifyChange = useEventCallback(() => {
    if (addressPlaceRuian?.id) {
      unstable_batchedUpdates(() => {
        setFieldValue(`${name}.addressPlaceRuian`, undefined);
        setFieldValue(`${name}.orientationNumber`, undefined);
        setFieldValue(`${name}.orientationNumberMark`, undefined);
        setFieldValue(`${name}.zip`, undefined);
      });
    }
  });

  return (
    <FormFieldWrapper
      disabled={isDisabled}
      required={false}
      label={
        <HelpLabel
          label={defaults?.houseNumber?.label ?? label}
          required={defaults?.houseNumber?.required ?? !!houseNumberType}
          code={`${code}_HOUSE_NUMBER`}
        />
      }
      labelOptions={{}}
      layoutOptions={{}}
      errorOptions={{ hide: true }}
      errors={errors.filter((error) => error.key === `${name}.houseNumber`)}
    >
      <Grid item xs={3}>
        <FormTextField
          disabled={isDisabled}
          notifyChange={notifyChange}
          name="houseNumber"
          labelOptions={{ hide: true }}
          layoutOptions={{ noUnderline: true, noSpacing: true }}
        />
      </Grid>
    </FormFieldWrapper>
  );
}

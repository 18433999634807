import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormFieldWrapperProps } from './protected-field-wrapper-types';
import { FormattedMessage } from 'react-intl';
import { useStylesWrapper } from './protected-field-styles';

export function ProtectedFieldWrapper({
  children,
  label,
  disabled,
  errors,
}: PropsWithChildren<
  Pick<FormFieldWrapperProps, 'label' | 'disabled' | 'errors'>
>) {
  const {
    wrapper,
    spacing,
    labelText,
    labelRoot,
    labelDisabled,
    labelError,
    boxRoot,
    boxError,
  } = useStylesWrapper();

  const hasError = (errors?.length ?? 0) > 0;

  return (
    <Grid item xs={12} className={wrapper}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        classes={{ root: spacing }}
      >
        <Grid item xs={12} sm={4}>
          <Box className={clsx(boxRoot, { [boxError]: hasError })}>
            <InputLabel
              disabled={disabled}
              classes={{
                root: labelRoot,
                disabled: labelDisabled,
              }}
            >
              <Typography
                variant="body2"
                classes={{
                  root: clsx(labelText, {
                    [labelError]: hasError,
                  }),
                }}
              >
                {label}
              </Typography>
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box display="flex">{children}</Box>
          {errors?.map((error, index) => (
            <FormHelperText key={index} error={hasError}>
              <FormattedMessage id={error.value} />
            </FormHelperText>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import { ProcessForm } from '../production-site-split-types';

export function StepOriginalProductionSiteLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const productionSite = allValues?.originalProductionSite;

  return (
    <>
      Průmyslové místo k rozdělení: <b>{productionSite?.name}</b>
    </>
  );
}

import { useRef, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import {
  FormHandle,
  useEventCallback,
  AbortableFetch,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFormError } from './form-error-hook';
import { loginUser } from '../auth-api';
import { LoginData } from '../auth-types';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../components/auth/auth-context';
import { AuthType } from '../../../components/auth/auth-types';
import { Messages } from '../../../enums';

export function useLogin() {
  const { showSnackbar } = useContext(SnackbarContext);
  const { type, loginUrl } = useContext(AuthContext);

  const history = useHistory();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { setErrors, getFieldError } = useFormError();

  const fetch = useRef<AbortableFetch | null>(null);

  const ref = useRef<FormHandle<LoginData>>(null);

  const validationSchema = Yup.object<LoginData>().shape({
    username: Yup.string()
      .nullable()
      .required('Uživatelské jméno musí být vyplněné'),
    password: Yup.string().nullable().required('Heslo musí být vyplněné'),
  });

  const handleSubmit = useEventCallback(async (data: LoginData) => {
    const captcha = await executeRecaptcha!('requesterLogin');

    if (fetch.current !== null) {
      fetch.current.abort();
    }

    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      setErrors(errors);

      if (errors.length) {
        return;
      }

      fetch.current = loginUser(data.username, data.password, captcha);

      const response = await fetch.current.response;

      if (response.status === 502) {
        showSnackbar(...Messages.User.LOGIN.ERROR_502);
      } else if (response.status === 503) {
        showSnackbar(...Messages.User.LOGIN.ERROR_503);
      } else if (
        response.redirected &&
        response.ok &&
        !response.url.includes('public?error')
      ) {
        window.location.href = response.url;
      } else {
        if (response.url.includes('?error=locked')) {
          showSnackbar(...Messages.User.LOGIN.MANY_REQUESTS);
        } else if (response.url.includes('?error=inactive')) {
          showSnackbar(
            `Uživatel ${data.username} byl zneplatěn`,
            SnackbarVariant.ERROR
          );
        } else {
          showSnackbar(...Messages.User.LOGIN.USERNAME_PASSWORD);
        }
      }
    }
  });

  /**
   * Redirect to SAML login if current type is not allowed
   */
  useEffect(() => {
    if (type === AuthType.SAML) {
      window.location.replace(loginUrl ?? '');
    }
    if (type === AuthType.NONE) {
      history.replace('/');
    }
  }, [type]);

  return {
    ref,
    handleSubmit,
    validationSchema,
    getFieldError,
    type,
  };
}

import React, { useContext } from 'react';
import { get } from 'lodash';
import EmailIcon from '@material-ui/icons/Email';
import {
  useEventCallback,
  NavigationContext,
  DashboardCardAction,
  DashboardCardProps,
} from '@eas/common-web';
import { EvidenceUrl } from '../../../enums';
import { CustomCardBase } from './custom-card-base';

export function CardEmpowerments({ report, definitionId }: DashboardCardProps) {
  const { navigate } = useContext(NavigationContext);

  const count = get(report, 'data[0].count');
  const requestCount = get(report, 'data[1].count');

  const navigateMain = useEventCallback(() => {
    navigate(EvidenceUrl.EMPOWERMENTS);
  });

  const navigateRequests = useEventCallback(() => {
    navigate(EvidenceUrl.EMPOWERMENT_REQUESTS);
  });

  const navigateNewRegistration = useEventCallback(() => {
    navigate(EvidenceUrl.EMPOWERMENT_REQUESTS_NEW);
  });

  return (
    <CustomCardBase
      title="Registr zmocnění"
      definitionId={definitionId}
      value={count}
      icon={<EmailIcon />}
      navigate={navigateMain}
    >
      <DashboardCardAction
        navigate={navigateRequests}
        title="Nevyřešené žádosti:"
        value={requestCount}
      />
      <DashboardCardAction
        navigate={navigateNewRegistration}
        title="Nová žádost"
      />
    </CustomCardBase>
  );
}

import { Transforms, BaseRange, Editor } from 'slate';
import { CustomEditor } from '../../../global';

type LinkData = {
  url: string;
  name?: string;
  contentType?: string;
  size?: number;
};

export const wrapLink = (
  editor: CustomEditor,
  selection: BaseRange,
  data: LinkData
) => {
  Transforms.select(editor, selection);
  Editor.addMark(editor, 'link', data);
};

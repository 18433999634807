import React from 'react';
import { FormPanel, FormAutocomplete } from '@eas/common-web';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useIrzFacilities } from '../../irz-facility/irz-facilities-api';
import { HelpLabel } from '../../../components/help/help-label';

export function StepMergingFacilityContent() {
  const irzFacilities = useIrzFacilities();

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete
        name="mergingFacility"
        label={
          <HelpLabel
            label="Provozovna"
            code="IRZ_FACILITY_MERGE_MERGING_FACILITY"
          />
        } // Vyberte provozovnu, kterou chcete sloučit s jinou provozovnu.
        source={irzFacilities}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

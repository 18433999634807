import * as Yup from 'yup';
import {
  validateEmailNotRequired,
  validatePhoneNumber,
} from './../../profile/profile-utils';
import { StepBasicInfoForm, BasicInfoData } from './step-basic-info-types';
import { validateWebsite } from '../../profile/profile-utils';

export function stepBasicInfoSchema() {
  return Yup.object<StepBasicInfoForm>().shape({
    basicInfo: Yup.object<BasicInfoData>().shape({
      name: Yup.string().nullable().required('Povinné pole'),
      webSite: validateWebsite,
      publicEmail: validateEmailNotRequired(),
      publicPhoneNumber: validatePhoneNumber,
    }),
  });
}

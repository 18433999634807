import { useContext, useEffect, useState } from 'react';
import {
  NavigationContext,
  EvidenceStateAction,
  useEventCallback,
  DictionaryObject,
  UserContext,
  LocaleContext,
} from '@eas/common-web';
import { EvidenceUrl, Permission } from '../../enums';
import { fetchHelpItem } from './help-api';
import { Me, PermissionsState } from '../../models';
import { useIntl } from 'react-intl';

export function useAdminLink({ code, title }: { code: string; title: string }) {
  const { navigate } = useContext(NavigationContext);
  const { refresh } = useContext(LocaleContext);
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const intl = useIntl();
  const hasHelp = intl.messages[code];

  intl.wrapRichTextChunksInFragment;

  /**
   * Detail of help item.
   */
  const [helpItem, setHelpItem] = useState<DictionaryObject>();

  /**
   * Has logged user right permissions?
   */
  const showEditButton = hasPermission(Permission.Help.HELP_LIST);

  /**
   * Fetch detail of help on every code change.
   */
  const getHelpItem = async () => {
    try {
      const response = await fetchHelpItem(code).json();

      setHelpItem(response);
    } catch (err) {
      setHelpItem(undefined);
    }
  };

  useEffect(() => {
    if (showEditButton && hasHelp) {
      getHelpItem();
    } else {
      setHelpItem(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleRefresh = useEventCallback(() => {
    refresh();
  });

  /**
   * Redirect to NEW or EDIT of help item.
   */
  const handleRedirect = useEventCallback(() => {
    if (helpItem) {
      navigate(`${EvidenceUrl.TRANSLATIONS}`, true, {
        action: EvidenceStateAction.SHOW_ITEM,
        data: helpItem?.id,
      });
    } else {
      navigate(`${EvidenceUrl.TRANSLATIONS}`, true, {
        action: EvidenceStateAction.NEW_ITEM,
        data: {
          code,
          name: title,
        },
      });
    }
  });

  return {
    handleRedirect,
    handleRefresh,
    showEditButton,
  };
}

export enum PermissionIspopDocument {
  /**
   * Přehled logů ESS
   */
  ISPOP_DOCUMENT_LIST = 'ISPOP_DOCUMENT_LIST',

  /**
   * Detail logu ESS
   */
  ISPOP_DOCUMENT_DETAIL = 'ISPOP_DOCUMENT_DETAIL',
}

import React, { useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {
  UserContext,
  NavigationContext,
  useEventCallback,
  Dashboard,
} from '@eas/common-web';
import { Title } from '../../components/title/title';
import { EvidenceUrl } from '../../enums';
import { Me } from '../../models';
import { useStyles as useTranslationStyles } from '../../components/help/help-block';
import { ProtectedPage } from '../../components/protected-page/protected-page';

import { useIntl } from 'react-intl';
import { CardSubjects } from './custom-cards/card-subjects';
import { CardUsers } from './custom-cards/card-users';
import { CardEmpowerments } from './custom-cards/card-empowerments';
import { CardIRZ } from './custom-cards/card-irz';
import { CardOVZ } from './custom-cards/card-ovz';
import { CardMUV } from './custom-cards/card-muv';
import { CardOZO } from './custom-cards/card-ozo';

function cardFactory(definitionId: string) {
  switch (definitionId) {
    case 'REPS_0':
      return CardSubjects;
    case 'REPU_0':
      return CardUsers;
    case 'REPZ_0':
      return CardEmpowerments;
    case 'REPI_0':
      return CardIRZ;
    case 'REPO_0':
      return CardOVZ;
    case 'REPM_0':
      return CardMUV;
    case 'REPB_0':
      return CardOZO;
    default:
      return undefined;
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 180px)',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cardUniversalValue: {
      fontFamily: 'montserrat',
    },
    cardActionValue: {
      fontFamily: 'montserrat',
    },
  })
);

export function CrzpDashboard() {
  const intl = useIntl();
  const classes = useStyles();
  const helpStyles = useTranslationStyles();

  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { navigate } = useContext(NavigationContext);

  const handleNavigate = useEventCallback(() => {
    navigate(EvidenceUrl.SUBJECT_REQUESTS_NEW);
  });

  const isNewUser =
    user?.securityPermissions.length === 1 &&
    user?.securityPermissions[0] === 'REPORT' &&
    Object.keys(user?.subjectPermissions ?? {}).length === 0;

  return (
    <ProtectedPage>
      <div className={classes.root}>
        <div className={classes.content}>
          <Container maxWidth="lg">
            <Grid item xs={12}>
              <Title name="Vítejte v CRŽP" />
              <Typography
                variant="body1"
                component="div"
                className={helpStyles.editor}
                style={{
                  backgroundColor: 'inherit',
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: 'DASHBOARD',
                      defaultMessage: 'Definujte text pod klíčem DASHBOARD',
                    }),
                  }}
                />
              </Typography>
              {!isNewUser && hasPermission('DASHBOARD_READ') && (
                <Dashboard
                  cardFactory={cardFactory}
                  classes={{
                    cardUniversalValue: classes.cardUniversalValue,
                    cardActionValue: classes.cardActionValue,
                  }}
                />
              )}
              {isNewUser && (
                <>
                  <br />
                  <br />
                  <Typography variant="body1" align="center">
                    <Button
                      size="large"
                      onClick={handleNavigate}
                      variant="contained"
                      disableElevation
                    >
                      ZAČNĚTE REGISTRACÍ SUBJEKTU
                    </Button>
                  </Typography>
                </>
              )}
            </Grid>
          </Container>
        </div>
      </div>
    </ProtectedPage>
  );
}

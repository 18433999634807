import { AuthoredObject } from './authored-object';
import { DomainObject } from '@eas/common-web';
import { DictionaryNace } from './dict-nace';
import { DictionaryNaceCz } from './dict-nace-cz';
import { DictionaryRiverBasinCzDistrict } from './dict-river-basin-cz-district';
import { DictionaryRiverBasinEuDistrict } from './dict-river-basin-eu-district';
import {
  Wgs84Coordinates,
  Etrs89Coordinates,
  JtskCoordinates,
} from './coordinate';
import {
  ActivityRecord,
  IrzFacility,
  MainActivityType,
  EuStateRecord,
} from './irz-facility';
import { DictionaryCountry } from './dict-country';
import { Subject, SubjectUnion } from './subject';
import { DictionaryCompetentControlAuthority } from './dict-competent-control-authority';
import { Address } from './address';
import { PersonalData } from './personal-data';
import { IrzEmissionsTradingSystem } from './irz-emissions-trading-system';
import { SimpleLog } from './simple-log';
import { IrzFacilityIntegratedPermission } from './irz-facility-integrated-permission';
import { DictionaryIppcActivity } from './dict-ippc-activity';

export interface IrzFacilityRequest extends AuthoredObject {
  /**
   * Evidenční číslo
   */
  number?: string;

  /**
   * Stav žádosti
   */
  state?: IrzRequestState;

  /**
   * Typ žádosti
   */
  type: IrzRequestType;

  /**
   * Email odesílatele žádosti
   */
  userEmail?: PersonalData;

  /**
   * Datum a čas vyřízení žádosti
   */
  decided?: string;
  /**
   * Poznámka k žádosti
   */
  note?: string;

  /**
   * Subjekt kterého se tato žádost týká
   */
  relatedSubject?: SubjectUnion;

  /**
   * Nový stav dat IRZ
   */
  requestFacilityData?: IrzFacilityRequestFacilityData;

  /**
   * Odkaz na objekt provozovny IRZ
   */
  irzFacility?: IrzFacility;

  /**
   * Datum od kterého daná změna platí (v momentě schválení žádosti nesmí být v budoucnu)
   */
  date?: string;

  /**
   * Diff
   */
  diff?: SimpleLog;
}

export enum IrzRequestState {
  /**
   * Čeká na schválení
   */
  WAITING_FOR_AUTHORIZATION = 'WAITING_FOR_AUTHORIZATION',

  /**
   * Schválena
   */
  APPROVED = 'APPROVED',

  /**
   * Zamítnuta
   */
  REJECTED = 'REJECTED',
}

export enum IrzRequestType {
  /**
   * Registrace provozovny
   */
  REGISTRATION = 'REGISTRATION',

  /**
   * Změna údajů provozovny
   */
  CHANGE = 'CHANGE',

  /**
   * Změna provozovatele provozovny
   */
  OPERATOR_CHANGE = 'OPERATOR_CHANGE',

  /**
   * Zneplatnění provozovny
   */
  INVALIDATION = 'INVALIDATION',
}

export interface IrzFacilityRequestFacilityData extends DomainObject {
  /**
   * UUID provozovny
   */
  irzId?: string;
  /**
   * Název provozovny
   */
  name?: string;

  /**
   * Adresa provozovny (výběr z RÚIAN)
   */
  address?: Address;

  /**
   * IČP (identifikační číslo provozovny)
   */
  facilityId?: string;

  /**
   * NACE
   */
  nace?: DictionaryNace;

  /**
   * CZ-NACE
   */
  czNace?: DictionaryNaceCz;

  /**
   * Oblast povodí ČR
   */
  czRiverBasinDistrict?: DictionaryRiverBasinCzDistrict;

  /**
   * Oblast povodí Evropy
   */
  euRiverBasinDistrict?: DictionaryRiverBasinEuDistrict;

  /**
   * Poloha WGS-84
   */
  wgs84Coordinates?: Wgs84Coordinates;

  /**
   * Poloha ETRS-89
   */
  etrs89Coordinates?: Etrs89Coordinates;

  /**
   * Poloha S-JTSK
   */
  jtskCoordinates?: JtskCoordinates;

  /**
   * Typ hlavní činnosti
   */
  activityTypes?: MainActivityType[];

  /**
   * Hlavní E-PRTR činnost
   */
  mainEprtrActivity?: ActivityRecord;

  /**
   * Seznam vedlejších E-PRTR činností
   */
  secondaryEprtrActivities?: ActivityRecord[];

  /**
   * Činnost IPPC
   */
  ippcActivity?: DictionaryIppcActivity;

  /**
   * Integrované povolení provozovny
   */
  integratedPermission?: IrzFacilityIntegratedPermission;

  /**
   * Hlavní činnost dle přílohy zákona o IRZ a ISPOP
   */
  mainIrzActivity?: ActivityRecord;

  /**
   * Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP
   */
  secondaryIrzActivities?: ActivityRecord[];

  /**
   * Stát/země
   */
  country?: DictionaryCountry;

  /**
   * Stav EU k poslednímu roku, za který bylo podáno hlášení
   */
  euStateRecord?: EuStateRecord[];

  /**
   * Web stránka
   */
  webSite?: string;

  /**
   * Veřejný e-mail
   */
  publicEmail?: string;

  /**
   * Veřejný telefon
   */
  publicPhoneNumber?: string;
  /**
    /**
   * Provozovatel
   */
  operator?: Subject;

  /**
   * Datum platnosti
   */
  validTo?: string;

  // todo historie

  /**
   * Poznámky
   */
  note?: string;

  /**
   * Kompetentní kontrolní a ostatní orgány
   */
  competentControlAuthorities?: DictionaryCompetentControlAuthority[];

  /**
   * Datum uvedení do provozu
   */
  commissioningDate?: string;

  /**
   * Identifikátor systému obchodování s emisemi
   */
  emissionsTradingSystem?: IrzEmissionsTradingSystem;
}

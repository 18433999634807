import React from 'react';
import {
  abortableFetch,
  useEventCallback,
  FormFileField,
} from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';

export function useAddAttachmentDialog() {
  const callApi = useEventCallback((id: string, { file }) => {
    return abortableFetch(`${EvidenceAPI.DIRP_RECORDS}/${id}/attachments`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: file.id,
    });
  });

  const AttachmentDialog = () => (
    <FormFileField name="file" label="Soubor" autoFocus={true} />
  );

  return {
    callApi,
    AttachmentDialog,
  };
}

import { ChluState } from './../../../models/protected-deposit-area';
import { useContext } from 'react';
import {
  abortableFetch,
  UserContext,
  DetailHandle,
  DetailContext,
} from '@eas/common-web';
import { EvidenceAPI, Permission } from './../../../enums';
import { Me, ProtectedDepositArea } from './../../../models';

export function useDiscardDialog() {
  const { source } = useContext<DetailHandle<ProtectedDepositArea>>(
    DetailContext
  );
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = (id: string) => {
    return abortableFetch(
      `${EvidenceAPI.PROTECTED_DEPOSIT_AREAS}/${id}/discard`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    );
  };

  const canDiscard =
    hasPermission(Permission.Chlu.CHLU_DISCARD) &&
    source?.data?.state === ChluState.CONCEPT &&
    !source?.data?.inRuianOrEntering;

  return { callApi, canDiscard };
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useAddressFieldsFactory } from '../../components/form/address-field/address-field-history-fields';
import {
  historyValueMapper,
  coordinatesValueMapper,
} from '../../components/evidence/history-evidence/history-utils';
import { useNaceItems } from '../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../dict-nace-cz-item/dict-nace-cz-items-api';
import { useCzRiverBasins } from '../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { useIrzActivities } from '../dict-irz-activity/dict-irz-activities-api';
import { useEPrtrActivities } from '../dict-e-prtr-activity/dict-e-prtr-activities-api';
import { useIppcActivities } from '../dict-ippc-activity/dict-ippc-activities-api';
import { useEuStates } from './irz-facilities-api';
import {
  HistoryFieldUnion,
  DictionaryAutocomplete,
  HistoryFieldType,
} from '../../models';
import { ListSource } from '@eas/common-web';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const naceItems = useNaceItems();
  const naceCzItems = useNaceCzItems();
  const czRiverBasins = useCzRiverBasins();
  const euRiverBasins = useCzRiverBasins();
  const irzActivities = useIrzActivities();
  const ePrtrActivities = useEPrtrActivities();
  const ippcActivities = useIppcActivities();
  const euStates = useEuStates();

  return [
    ...useAddressFieldsFactory('address'),
    {
      key: 'name',
      label: 'Název provozovny',
    },
    {
      key: 'facilityId',
      label: 'IČP',
    },
    {
      key: 'inspireIdNamespace',
      label: 'Inspire ID namespace',
    },
    {
      key: 'emissionsTradingSystem.tradesInEmissions',
      label:
        'Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi',
    },
    {
      key: 'emissionsTradingSystem.ids',
      label: 'ID zařízení (EU ETS)',
    },
    {
      key: 'nace',
      label: 'NACE',
      source: naceItems,
    },
    {
      key: 'czNace',
      label: 'CZ-NACE',
      source: naceCzItems,
    },
    {
      key: 'czRiverBasinDistrict',
      label: 'Oblast povodí ČR',
      source: czRiverBasins,
    },
    {
      key: 'euRiverBasinDistrict',
      label: 'Oblast povodí Evropy',
      source: euRiverBasins,
    },
    {
      key: 'jtskCoordinates.x',
      label: 'Poloha S-JTSK - X',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'jtskCoordinates.y',
      label: 'Poloha S-JTSK - Y',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'wgs84Coordinates.lat',
      label: 'Zeměpisná šířka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'wgs84Coordinates.lon',
      label: 'Zeměpisná délka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'ovzFacility',
      label: 'IČP Ovzduší',
    },
    {
      key: 'mainEprtrActivity.activity',
      label: 'Hlavní E-PRTR činnost',
      source: ePrtrActivities,
    },
    {
      key: 'mainEprtrActivity.facilityCount',
      label: 'Počet zařízení hlavní E-PRTR činnosti',
    },
    {
      key: 'secondaryEprtrActivities',
      label: 'Seznam vedlejších E-PRTR činností',
      valueMapper: (change) =>
        historyValueMapper(change, getSecondaryActivitiesLabel),
    },
    {
      key: 'ippcActivity',
      label: 'Činnost IPPC',
      source: ippcActivities,
    },
    {
      key: 'integratedPermission.hasPermission',
      label: 'Provozovna/Zařízení má Integrované povolení',
    },
    {
      key: 'integratedPermission.ippcPids',
      label: 'PID (IPPC)',
    },
    {
      key: 'mainIrzActivity.activity',
      label: 'Hlavní činnost dle přílohy zákona o IRZ a ISPOP',
      source: irzActivities,
    },
    {
      key: 'mainIrzActivity.facilityCount',
      label: 'Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP',
    },
    {
      key: 'secondaryIrzActivities',
      label: 'Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP',
      valueMapper: (change) =>
        historyValueMapper(change, getSecondaryActivitiesLabel),
    },
    {
      key: 'facilityCount',
      label: 'Celkový počet zařízení',
    },
    // {
    //   key: 'euStateRecord.euState',
    //   label: 'Stav EU k předchozímu roku podání',
    //   source: euStates,
    // },
    // {
    //   key: 'euStateRecord.year',
    //   label: 'Předchozí rok podání EU',
    // },
    {
      key: 'euStateRecords',
      label: 'Stav EU',
      valueMapper: (historyField) => euStateValueMapper(historyField, euStates),
    },
    {
      key: 'webSite',
      label: 'Web stránka',
    },
    {
      key: 'publicEmail',
      label: 'Veřejný e-mail',
    },
    {
      key: 'publicPhoneNumber',
      label: 'Veřejný telefon',
    },
    {
      key: 'operator',
      label: 'Provozovatel',
    },
    {
      key: 'registrationDate',
      label: 'Datum registrace',
    },
    {
      key: 'validFrom',
      label: 'Datum obnovení',
    },
    {
      key: 'validTo',
      label: 'Datum zneplatnění',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'note',
      label: 'Poznámky',
    },
    {
      key: 'competentAuthority',
      label: 'Kompetentní orgán - IRZ',
    },
    {
      key: 'competentControlAuthorities',
      label: 'Kompetentní kontrolní a ostatní orgány',
    },
    {
      key: 'commissioningDate',
      label: 'Datum uvedení do provozu',
    },
    {
      key: 'productionSite',
      label: 'Průmyslové místo',
    },
    {
      key: 'etrs89Coordinates.lat',
      label: 'Zeměpisná šířka (ETRS-89)',
    },
    {
      key: 'etrs89Coordinates.lon',
      label: 'Zeměpisná délka (ETRS-89)',
    },
  ];
}

export function getSecondaryActivitiesLabel(key: string) {
  switch (key) {
    case 'activity':
      return 'Činnost';
    case 'facilityCount':
      return 'Počet zařízení';
  }
}

function euStateValueMapper(
  change: HistoryFieldUnion,
  euStates: ListSource<DictionaryAutocomplete>
) {
  let value = '';
  switch (change.type) {
    case HistoryFieldType.ID_LABEL: {
      value = `Stav: ${change.label}, Rok: `;
      break;
    }
    case HistoryFieldType.STRING: {
      const euState = euStates.items.find((state) => state.id === change.value);
      value = euState?.name ?? change.value;
      break;
    }
    case HistoryFieldType.NESTED:
      Object.entries(change.map).forEach(([_, nestedValue]) => {
        value += euStateValueMapper(nestedValue, euStates);
      });
  }
  return value;
}

export enum PermissionReportingAccessRule {
  /** Přehled nastavení reportů */
  REPORTING_ACCESS_RULE_LIST = 'REPORTING_ACCESS_RULE_LIST',

  /** Detail nastavení reportů */
  REPORTING_ACCESS_RULE_DETAIL = 'REPORTING_ACCESS_RULE_DETAIL',

  /** Editace nastavení reportů */
  REPORTING_ACCESS_RULE_UPDATE = 'REPORTING_ACCESS_RULE_UPDATE',
}

export enum PermissionReporting {
  /** Přehled, generování a export reportů */
  REPORTING_DETAIL = 'REPORTING_DETAIL',

  /** Přehled, generování a export reportů z vlastních subjektů */
  REPORTING_DETAIL_OWN = 'REPORTING_DETAIL_OWN',
}

import { useContext } from 'react';
import { AuthContext } from '../auth/auth-context';
import { AuthType } from '../auth/auth-types';

export interface MenuItem {
  label: string;
  href: string;
  primary?: boolean;
  external?: boolean;
  secondary?: boolean;
  showToUnlogged?: boolean;
  showToLogged?: boolean;
}

export function useMenuItems(): MenuItem[] {
  const { loginUrl, type } = useContext(AuthContext);

  return [
    {
      label: 'Přihlášení',
      href: loginUrl ?? '/prihlaseni',
      external: type === AuthType.SAML,
      secondary: true,
      showToUnlogged: true,
      showToLogged: false,
    },
    {
      label: 'Registrace',
      href: '/registrace',
      primary: true,
      showToUnlogged: true,
      showToLogged: false,
    },
  ];
}

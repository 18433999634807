import React, { useContext, forwardRef, Ref, memo, ReactElement } from 'react';
import { get, last } from 'lodash';
import {
  TableFieldRowProps,
  TableFieldContext,
  TableFieldCells,
  TableFieldColumnState,
  useMeasure,
  composeRefs,
} from '@eas/common-web';
import { useStyles } from './specification-table-styles';

function computeWidth(columnsState: TableFieldColumnState[], rowWidth: number) {
  const minWidth =
    columnsState.map((col) => col.width).reduce((a, b) => a + b, 0) + 1;

  return rowWidth - minWidth;
}

export const SpecificationTableFieldRow = memo(
  forwardRef(function InlineTableFieldRow<OBJECT>(
    { index, value }: TableFieldRowProps<OBJECT>,
    ref: Ref<HTMLDivElement>
  ) {
    const classes = useStyles();

    const { filteredColumns, columnsState } = useContext<
      TableFieldContext<OBJECT>
    >(TableFieldContext);

    const [measureRef, { width: rowWidth }] = useMeasure();
    const rowRef = composeRefs<any>(ref, measureRef);

    return (
      <div className={classes.row} ref={rowRef}>
        {filteredColumns.map((column, i) => {
          const { CellComponent = TableFieldCells.TextCell } = column;

          const isLast = last(columnsState)?.datakey === column.datakey;

          const width =
            isLast && last(columnsState)?.width === -1
              ? computeWidth(columnsState, rowWidth)
              : columnsState.find((col) => col.datakey === column.datakey)
                  ?.width ?? '100px';

          return (
            <div
              key={i}
              className={classes.cellWrapper}
              style={{
                width,
              }}
            >
              <CellComponent
                index={index}
                value={get(value, column.datakey, '')}
                rowValue={value}
                column={column as any}
              />
            </div>
          );
        })}
      </div>
    );
  }) as <OBJECT>(p: TableFieldRowProps<OBJECT>) => ReactElement
);

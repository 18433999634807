import { AuthoredObject } from './authored-object';
import { DictionaryObject } from './dictionary-object';
import { PersonalData } from './personal-data';
import { Address } from './address';
import { DomainObjectAutocomplete } from './domain-object';
import { Role } from './role';
import { PhoneNumber } from './phone-number';

/**
 * Typ subjektu
 */
export enum SubjectType {
  /**
   * Právnická osoba
   */
  LEGAL_ENTITY = 'LEGAL_ENTITY',

  /**
   * Právnická osoba bez IČO
   */
  LEGAL_ENTITY_WITHOUT_ICO = 'LEGAL_ENTITY_WITHOUT_ICO',

  /**
   * Fyzická osoba
   */
  NATURAL_PERSON = 'NATURAL_PERSON',

  /**
   * Fyzická osoba podnikající
   */
  BUSINESS_NATURAL_PERSON = 'BUSINESS_NATURAL_PERSON',

  /**
   * Zahraniční právnická osoba
   */
  FOREIGN_LEGAL_ENTITY = 'FOREIGN_LEGAL_ENTITY',

  /**
   * Zahraniční fyzická osoba
   */
  FOREIGN_NATURAL_PERSON = 'FOREIGN_NATURAL_PERSON',
}

/**
 * Typ dokladu
 */
export enum DocumentType {
  /**
   * OP
   */
  IDENTITY_CARD = 'IDENTITY_CARD',

  /**
   * Pas
   */
  PASSPORT = 'PASSPORT',
}

/**
 * Typ identifikátoru subujektu
 */
export enum SubjectIdentificator {
  /**
   * IČO
   */
  ICO = 'ICO',

  /**
   * Číslo pasu
   */
  PASSPORT_NUMBER = 'PASSPORT_NUMBER',

  /**
   * Číslo občanky
   */
  ID_CARD_NUMBER = 'ID_CARD_NUMBER',

  /**
   * Umelý identifikátor
   */
  ARTIFICIAL_ID = 'ARTIFICIAL_ID',
}

/**
 * Identifikátor subjektu
 */
export interface SubjectIdentifier {
  /**
   * Typ identifikátoru subjektu
   */
  type: SubjectIdentificator;

  /**
   * Hodnota
   */
  value: string;
}

export interface SubjectAutocomplete extends DomainObjectAutocomplete {
  /**
   * Typ subjektu
   */
  type: SubjectType;

  label?: string;

  ico?: string;
}

/**
 * Subjekt
 */
export interface Subject extends AuthoredObject {
  /**
   * Umělý identifikátor ("ID_xxxxxx")
   */
  artificialId?: string;

  /**
   * Telefonní číslo
   */
  phoneNumber?: PhoneNumber;

  /**
   * E-mail
   */
  email?: PersonalData;

  /**
   * Datum registrace
   */
  inCrzpSince?: string;

  /**
   * Identifikátory datové schránky (ISDS)
   */
  isdsIds?: string[];

  /**
   * Příznak povolení synchronizace s ISZR ( automatická synchronizace )
   * TODO: BE - What is this? Probably it will be removed...
   */
  allowIszrSync?: boolean;

  /**
   * Datum poslední synchronizace s ISZR
   */
  lastIszrSync?: string;

  /**
   * Příznak synchronizace s ISZR
   */
  iszrSync?: boolean;

  /**
   * Poznámky
   */
  note?: string;

  /**
   * Typ subjektu
   */
  type?: SubjectType;

  /**
   * Název subjektu
   */
  name?: string;

  /**
   * Popisek
   */
  label?: string;

  /**
   * Subjektové role
   */
  roles?: Role[];

  /**
   * Schválen (při registraci)
   */
  approved?: boolean;

  /**
   * Příznak platnosti
   */
  active?: boolean;

  /**
   * Adresa
   */
  residence?: Address;

  /**
   * Počet správců subjektu
   */
  administratorsCount?: number;
}

/**
 * Zahraniční právnická osoba
 */
export interface ForeignLegalEntity extends Subject {
  /**
   * Název
   */
  name?: string;

  /**
   * Popisek
   */
  label?: string;

  /**
   * Datum vzniku
   */
  originDate?: string;

  /**
   * Datum zániku
   */
  endDate?: string;

  /**
   * Typ subjektu
   */
  type: SubjectType.FOREIGN_LEGAL_ENTITY;
}

/**
 * Právnická osoba
 */
export interface LegalEntity extends Subject {
  /**
   * IČO
   */
  ico?: string;

  /**
   * Název
   */
  name?: string;

  /**
   * Právní forma
   */
  legalForm?: DictionaryObject;

  /**
   * Datum vzniku
   */
  originDate?: string;

  /**
   * Datum zániku
   */
  endDate?: string;

  /**
   * Typ subjektu
   */
  type: SubjectType.LEGAL_ENTITY;
}

/**
 * Právnická osoba bez IČO
 */
export interface LegalEntityWithoutIco extends Subject {
  /**
   * Název
   */
  name?: string;

  /**
   * Právní forma
   */
  legalForm?: DictionaryObject;

  /**
   * Datum vzniku
   */
  originDate?: string;

  /**
   * Datum zániku
   */
  endDate?: string;

  /**
   * Typ subjektu
   */
  type: SubjectType.LEGAL_ENTITY_WITHOUT_ICO;
}

/**
 * Fyzická osoba podnikající
 */
export interface BusinessNaturalPerson extends Subject {
  /**
   * IČO
   */
  ico?: PersonalData;

  /**
   * Název
   */
  name?: string;

  /**
   * Datum vzniku
   */
  originDate?: string;

  /**
   * Datum zániku
   */
  endDate?: string;

  /**
   * Typ subjektu
   */
  type: SubjectType.BUSINESS_NATURAL_PERSON;
}

/**
 * Zahraniční fyzická osoba
 */
export interface ForeignNaturalPerson extends Subject {
  /**
   * Titul před
   */
  degreeBefore?: PersonalData;

  /**
   * Jméno
   */
  firstName?: PersonalData;

  /**
   * Příjmení
   */
  lastName?: PersonalData;

  /**
   * Celé jméno
   */
  fullName?: PersonalData;

  /**
   * Titul za
   */
  degreeAfter?: PersonalData;

  /**
   * Datum narození
   */
  birthDate?: PersonalData;

  /**
   * Datum úmrtí
   */
  deceaseDate?: PersonalData;

  /**
   * Typ subjektu
   */
  type: SubjectType.FOREIGN_NATURAL_PERSON;
}

/**
 * Doklad totožnosti
 */
export interface IdentityDocument {
  /**
   * Typ dokladu
   */
  type: DocumentType;

  /**
   * Číslo dokladu
   */
  idNumber: PersonalData;

  /**
   * Platnost informace od (neodpovídá platnosti na dokladu)
   */
  validFrom: string;

  /**
   * Platnost informace do (neodpovídá platnosti na dokladu)
   */
  validTo: string;
}

/**
 * Fyzická osoba
 */
export interface NaturalPerson extends Subject {
  /**
   * Titul před
   */
  degreeBefore?: PersonalData;

  /**
   * Jméno
   */
  firstName?: PersonalData;

  /**
   * Příjmení
   */
  lastName?: PersonalData;

  /**
   * Celé jméno
   */
  fullName?: PersonalData;

  /**
   * Titul za
   */
  degreeAfter?: PersonalData;

  /**
   * Datum narození
   */
  birthDate?: PersonalData;

  /**
   * Datum úmrtí
   */
  deceaseDate?: PersonalData;

  /**
   * Sekce Doklady
   */
  identityDocuments?: IdentityDocument[];

  /**
   * Typ subjektu
   */
  type: SubjectType.NATURAL_PERSON;
}

export interface ConceptStatusDto {
  /**
   * Kód
   */
  code?: string;

  /**
   * Zpráva
   */
  message?: string;
}

export type SubjectUnion =
  | LegalEntity
  | LegalEntityWithoutIco
  | NaturalPerson
  | BusinessNaturalPerson
  | ForeignNaturalPerson
  | ForeignLegalEntity;

import React from 'react';
import { useSubjectRegistrationTypes } from '../subject-requests-api';
import { StepTypeForm } from './step-type-types';
import { ProcessForm } from '../subject-requests-types';

export function StepTypeLabel({
  stepValues,
}: {
  stepValues?: StepTypeForm;
  allValues?: ProcessForm;
}) {
  const subjectRegistrationTypes = useSubjectRegistrationTypes();

  const typeLabel = subjectRegistrationTypes.items.find(
    (role) => role.id === stepValues?.type
  );

  return (
    <>
      Typ subjektu: <b>{typeLabel?.name}</b>
    </>
  );
}

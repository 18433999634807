import React from 'react';
import {
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export default function DictionaryRuianAdministrativeDistrictPragueFields() {
  const prefix = 'RUIAN_ADMINISTRATIVE_DISTRICT_PRAGUE';

  return (
    <>
      <FormPanel label="Městský obvod/městská část">
        <FormTextField
          name="cityDistrict.name"
          label={
            <HelpLabel
              label="Název"
              code={`DICT_${prefix}_CITY_DISTRICT_NAME`}
            />
          }
        />
        <FormTextField
          name="cityDistrict.isuiCode"
          label={
            <HelpLabel
              label="Kód v ISÚI"
              code={`DICT_${prefix}_CITY_DISTRICT_CODE`}
            />
          }
        />
        <FormCheckbox
          name="cityDistrict.active"
          label={
            <HelpLabel
              label="Aktivní"
              code={`DICT_${prefix}_CITY_DISTRICT_ACTIVE`}
            />
          }
        />
        <FormDateTimeField
          name="cityDistrict.created"
          label={
            <HelpLabel
              label="Vytvoření"
              code={`DICT_${prefix}_CITY_DISTRICT_CREATED`}
            />
          }
        />
      </FormPanel>
    </>
  );
}

import { useContext } from 'react';
import { DetailContext, useEventCallback, DetailHandle } from '@eas/common-web';
import { SubjectRegistration } from '../../../models';

export function useDatabox() {
  /**
   * Context stuff.
   */
  const { source } = useContext<DetailHandle<SubjectRegistration>>(
    DetailContext
  );

  const handleDatabox = useEventCallback(() => {
    window.open(
      `https://www.mojedatovaschranka.cz/as/login?atsId=d03ec8392861488b&appToken=${source.data?.documentSignature?.signedFile?.file?.id}`,
      '_blank'
    );
  });

  return {
    handleDatabox,
  };
}

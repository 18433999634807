import { useAuth } from './auth-hook';
import React from 'react';
import { AuthContext } from './auth-context';

export function AuthProvider({ children }: { children?: React.ReactNode }) {
  const contextData = useAuth();
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
}

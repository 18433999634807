import { PhoneNumber } from '../../../models';
import { get, set } from 'lodash';

/**
 * Strips the prefix in case the number is not filled
 *
 * @param item
 * @param phoneNumberKey
 */
export function preprocessPhoneNumber<T>(
  item: T,
  phoneNumberKey = 'phoneNumber'
): T {
  const phoneNumber = get(item, phoneNumberKey) as PhoneNumber | undefined;

  if (item && !!phoneNumber?.prefix && !phoneNumber?.number) {
    set(item as any, phoneNumberKey, undefined);
  }

  return item;
}

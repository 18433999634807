import { ThemeOptions } from '@material-ui/core';

export const themeOptions: ThemeOptions = {
  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  typography: {
    fontFamily: ['"Open Sans"', 'Roboto'].join(','),
    h1: {
      fontSize: '32px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '30px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '28px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '24px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '22px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '12px',
      fontWeight: 600,
    },
    body1: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '2em',
    },
    body2: {
      fontWeight: 600,
      fontSize: '11px',
      lineHeight: '2em',
    },
  },
  palette: {
    primary: {
      main: '#1DB198',
      light: '#21C79B',
      dark: '#009E84',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff0000',
      light: '#ff3333',
      dark: '#b20000',
    },
    text: {
      primary: '#000000',
      secondary: '#777777',
    },
    editing: '#FEFFB2',
    highlight: '#fefeb28a',
  },
  shape: {
    borderRadius: 20,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          margin: '0px',
          padding: '0px',
          '-webkit-font-smoothing': 'auto',
        },
        '*:focus': {
          outline: 'none !important',
        },
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          overscrollBehavior: 'none',
          overflow: 'hidden',
        },
        a: {
          textDecoration: 'none',
        },
        '#root': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        '.grecaptcha-badge': {
          zIndex: 9999,
        },
        '.smap .cluster div': {
          boxSizing: 'initial',
        },
        blockquote: {
          borderLeft: '2px solid #ddd',
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 10,
          color: '#aaa',
          fontStyle: 'italic',
        },
      },
    },
    MuiMenuItem: {
      root: {
        lineHeight: '1.49em',
      },
    },
    MuiStepIcon: {
      text: {
        fill: '#fff',
      },
    },
  },
  eas: {
    shadow: ['0px 0px 10px #ccc', '0px 5px 5px #ddd'],
  },
};

import React from 'react';
import { FormPanel } from '@eas/common-web';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { OvzFacilityAutocomplete } from '../../../models/ovz-facility';
import { ProcessForm } from '../ovz-facility-request-operator-change-types';
import { useOvzFacilities } from './step-ovz-facilities-api';
import { FormPreFilledAutocomplete } from '../../../components/form/pre-filled-autocomplete/pre-filled-autocomplete';
import { HelpLabel } from '../../../components/help/help-label';

export function StepOvzFacilitiesContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  const ovzFacilitySource = useOvzFacilities(allValues?.oldOperator?.id);
  return (
    <FormPanel label="Vyplňte">
      <FormPreFilledAutocomplete<OvzFacilityAutocomplete>
        name="ovzFacilities"
        source={ovzFacilitySource}
        multiple={true}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
        label={
          <HelpLabel
            label="Provozovny"
            code="OVZ_FACILITY_REQUEST_OPERATOR_CHANGE_OVZ_FACILITIES"
            required
          />
        } // Povinné pole
      />
    </FormPanel>
  );
}

import { useMemo } from 'react';
import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { PersonalData, PersonalDataProcessingPurpose } from '../../models';
import { useDataType, useFields } from './personal-data-api';
import { nameValueMapper } from './value-mappers/name-value-mapper';
import { userNameValueMapper } from './value-mappers/user-name-value-mapper';
import { numberValueMapper } from './value-mappers/number-value-mapper';

export function useColumns(): TableColumn<PersonalData>[] {
  const columns: TableColumn<PersonalData>[] = [
    {
      datakey: 'dataType',
      sortkey: 'dataType.name',
      filterkey: 'dataType.id',
      name: 'Datový typ',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useDataType),
      valueMapper: TableCells.useSelectCellFactory(useDataType),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'entityReference.field.name',
      filterkey: 'entityReference.field.id',
      name: 'Atribút',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useFields),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'value',
      name: 'Hodnota',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'entityReference.name',
      displaykey: 'entityReference.name',
      sortkey: 'entityReference.name',
      filterkey: 'entityReference.name',
      name: 'Název subjektu',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      valueMapper: nameValueMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'entityReference.username',
      displaykey: 'entityReference.username',
      sortkey: 'entityReference.username',
      filterkey: 'entityReference.username',
      name: 'Uživatelské jméno',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      valueMapper: userNameValueMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'entityReference.number',
      displaykey: 'entityReference.number',
      sortkey: 'entityReference.number',
      filterkey: 'entityReference.number',
      name: 'Číslo OZO',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      valueMapper: numberValueMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    // {
    //   datakey: 'processingPurposes',
    //   sortkey: 'processingPurposes.name',
    //   filterkey: 'processingPurposes.name',
    //   name: 'Účely zpracování',
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   valueMapper: processingPurposesValueMapper,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 1,
    // },
    {
      datakey: 'obtained',
      name: 'Datum uchování od',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'keepTo',
      name: 'Datum uchování do',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];

  return columns;
}

export function useProcessingPurposeTableColumns(): TableFieldColumn<
  PersonalDataProcessingPurpose
>[] {
  return useMemo(
    () => [
      {
        name: 'Účel zpracování',
        datakey: 'processingPurpose.name',
        CellComponent: TableFieldCells.TextCell,
        width: 200,
      },
      {
        name: 'Datum uchování od',
        datakey: 'obtained',
        CellComponent: TableFieldCells.DateCell,
        width: 200,
      },
      {
        name: 'Datum uchování do',
        datakey: 'keepTo',
        CellComponent: TableFieldCells.DateCell,
        width: 200,
      },
    ],
    []
  );
}

import React, { useContext } from 'react';
import {
  FormPanel,
  FormAutocomplete,
  ApiFilterOperation,
  FormContext,
} from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';
import { useAgendas } from '../../../modules/agenda/agendas-api';
import { AgendaAutocomplete } from '../../../models';

export function StepAgendaContent() {
  const { getFieldValues, setFieldValue } = useContext(FormContext);

  const withoutAgendaId = 'WITHOUT_AGENDA';

  const agendas = useAgendas({
    filters: [
      {
        field: 'selfAssignable',
        operation: ApiFilterOperation.EQ,
        value: true,
      },
    ],
  });

  const handleAgendaChange = () => {
    const agendas = getFieldValues()?.agendas;
    if (
      agendas?.length > 1 &&
      agendas.find(
        (agenda: AgendaAutocomplete) => agenda.id === withoutAgendaId
      )
    ) {
      setFieldValue(
        'agendas',
        agendas.filter(
          (agenda: AgendaAutocomplete) => agenda.id !== withoutAgendaId
        )
      );
    }
  };

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete
        name="agendas"
        label={
          <HelpLabel
            label="Agenda"
            code="SUBJECT_REGISTRATION_NEW_AGENDA"
            required
          /> // Povinné pole
        }
        source={{
          ...agendas,
          items: [
            { id: withoutAgendaId, name: 'Bez agendy' },
            ...agendas.items,
          ],
        }}
        tooltipMapper={(o) => o.name}
        multiple
        notifyChange={handleAgendaChange}
      />
    </FormPanel>
  );
}

import {
  abortableFetch,
  AbortableFetch,
  useEventCallback,
  SnackbarContext,
} from '@eas/common-web';
import { EvidenceAPI, Messages } from '../../enums';
import { useRef, useContext } from 'react';

function callApi(secret: string) {
  return abortableFetch(
    `${EvidenceAPI.NOTIFICATION_PREFERENCES}/unsubscribe?secret=${secret}`,
    {
      method: 'PUT',
    }
  );
}

export function useUnsubscribe() {
  const fetch = useRef<AbortableFetch | null>(null);
  const { showSnackbar } = useContext(SnackbarContext);

  const unsubscribe = useEventCallback(async (secret: string) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(secret);
      const response = await fetch.current.json();

      if (response.mandatory) {
        showSnackbar(...Messages.Notification.UNSUBSCRIBE.MANDATORY);
      } else {
        const state: 'UNSUBSCRIBED' | 'ALREADY_UNSUBSCRIBED' =
          response.state ?? 'UNSUBSCRIBED';

        showSnackbar(...Messages.Notification.UNSUBSCRIBE[state]);
      }
    } catch (err) {
      showSnackbar(...Messages.Notification.UNSUBSCRIBE.ERROR);
    }
  });

  return { unsubscribe };
}

import { ListSource } from '@eas/common-web';
import { noop } from 'lodash';

export function useLocalListSource<T>(items: T[]): ListSource<T> {
  return {
    loading: false,
    reset: noop,
    items,
    loadDetail: async (item) => {
      return item;
    },
  };
}

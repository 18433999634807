import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { inactiveColoredFactory } from '../../../../components/form/inactive-colored/inactive-colored';
import { TextCell } from '../../../user/overrides/text-cell';
import { allRolesValueMapper } from '../../../../composite/user-column-mappers/all-roles-mapper';
import { redirectToDetailCellFactory } from '../../../../composite/subject-cells/redirect-to-detail-cell';
import { RoleInstance } from '../../../../models';
import { EvidenceUrl } from '../../../../enums';

export function useColumns(showRedirect: boolean): TableColumn<RoleInstance>[] {
  let columns: TableColumn<RoleInstance>[] = [
    {
      datakey: 'subject.name',
      name: 'Název',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      datakey: 'subject.identifier',
      name: 'IČO / UID',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      datakey: 'userRoleInstances',
      name: 'Uživatelské role',
      width: 300,
      CellComponent: inactiveColoredFactory(TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      valueMapper: allRolesValueMapper,
      sortable: false,
      filterable: false,
      asciiFolding: false,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];

  if (showRedirect) {
    columns = [
      {
        name: 'Odkaz',
        datakey: 'id',
        width: 60,
        CellComponent: redirectToDetailCellFactory(
          EvidenceUrl.SUBJECTS,
          'subject.id'
        ),
      },
      ...columns,
    ];
  }

  return columns;
}

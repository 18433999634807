import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useDictionaryBoilerBrands } from '../dict-boiler-brand/dict-boiler-brands-api';
import { useDictionaryBoilerTypes } from '../dict-boiler-type/dict-boiler-types-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const boilerBrands = useDictionaryBoilerBrands();
  const boilerTypes = useDictionaryBoilerTypes();

  return [
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platný od',
    },
    {
      key: 'validTo',
      label: 'Platný do',
    },
    {
      key: 'code',
      label: 'Kód',
    },
    {
      key: 'brand',
      label: 'Značka',
      source: boilerBrands,
    },
    {
      key: 'types',
      label: 'Typy kotle',
      source: boilerTypes,
    },
    {
      key: 'description',
      label: 'Popis',
    },
    {
      key: 'manufacturer',
      label: 'Výrobce',
    },
  ];
}

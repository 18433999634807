import React from 'react';
import { FormPanel, FormAutocomplete } from '@eas/common-web';
import { useProductionSites } from '../../production-site/production-sites-api';
import { HelpLabel } from '../../../components/help/help-label';

export function StepMergingProductionSiteContent() {
  const productionSites = useProductionSites();

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete
        name="mergingProductionSite"
        label={
          <HelpLabel
            label="Průmyslové místo"
            code="PRODUCTION_SITE_MERGE_MERGING_PRODUCTION_SITE"
          />
        } // Průmyslové místo, které bude s níže vybraným průmyslovým místem sloučeno
        source={productionSites}
      />
    </FormPanel>
  );
}

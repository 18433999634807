import React, { useContext, useMemo } from 'react';
import { AddressFieldCell } from '../address-field-cell';
import { EvidenceAPI } from '../../../../enums';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';

export function RegionCell() {
  const { code } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);

  const descendantNames = useMemo(
    () =>
      [
        'districtRuian',
        'municipalityRuian',
        'municipalityPartRuian',
        'cityDistrictRuian',
        'streetRuian',
        'addressPlaceRuian',
        'houseNumber',
        'houseNumberType',
        'orientationNumber',
        'orientationNumberMark',
        'zip',
      ] as const,
    []
  );

  return (
    <AddressFieldCell
      label={defaults?.region?.label ?? 'Kraj'}
      required={defaults?.region?.required ?? true}
      name="regionRuian"
      code={`${code}_REGION`}
      url={EvidenceAPI.ADDRESS_REGION}
      descendantNames={descendantNames}
    />
  );
}

import * as Yup from 'yup';
import { StepLocationForm, LocationData } from './step-location-types';
import {
  addressFieldSchema,
  wgs84CoordinatesSchema,
} from '../../../components/form/address-field/address-field-schema';

export const stepLocationSchema = () =>
  Yup.object<StepLocationForm>().shape({
    location: Yup.object<LocationData>().shape({
      address: addressFieldSchema(),
      wgs84Coordinates: wgs84CoordinatesSchema(),
    }),
  });

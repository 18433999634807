import React from 'react';
import { FormPanel, FormTextField, FormDateTimeField } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export function DictInfosFields() {
  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="source"
          label={<HelpLabel label="Zdroj" code="DICT_INFO_SOURCE" />}
        />
        {/* <FormTextArea name="description" label={<HelpLabel label="Popis" code="DICT_INFO_DESCRIPTION" />} /> */}

        <FormDateTimeField
          name="lastUpdate"
          label={
            <HelpLabel
              label="Poslední aktualizace"
              code="DICT_INFO_LAST_UPDATE"
            />
          }
        />
      </FormPanel>
    </>
  );
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useSubjectRoles, useSecurityRoles, useRoles } from './roles-api';
import { useSystems } from '../system/systems-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const subjectRoles = useSubjectRoles();
  const securityRoles = useSecurityRoles();
  const systems = useSystems();
  const roles = useRoles();

  return [
    {
      key: 'type',
      label: 'Typ',
    },
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'code',
      label: 'Kód',
    },
    {
      key: 'description',
      label: 'Popis',
    },
    {
      key: 'registeredFor',
      label: 'Systém, který eviduje danou roli',
      source: systems,
    },
    {
      key: 'assignsRoles',
      label: 'Role, které mohou být přiřazené touto rolí',
      source: roles,
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'relationship',
      label: 'Vztah',
    },
    {
      key: 'permissions',
      label: 'Oprávnění',
    },
    {
      key: 'includedRoles',
      label: 'Agregované role',
      source: securityRoles,
    },
    {
      key: 'rppAttributes.activityRole',
      label: 'Činnostní role',
    },
    {
      key: 'rppAttributes.agenda',
      label: 'Agenda',
    },
    {
      key: 'rppAttributes.legalReference',
      label: 'Zákonná reference',
    },
    {
      key: 'parentSubjectRole',
      label: 'Nadřazená subjektová role',
      source: subjectRoles,
    },
    {
      key: 'onlyAdminCanAssign',
      label: 'Může přidělit jen admin',
    },
    {
      key: 'stateAdministrationRole',
      label: 'Příznak, zda-li se jedná o roli pro výkon státní správy',
    },
    {
      key: 'allowSepnoDetails',
      label: 'Umožnit nastavit detaily SEPNO',
    },
    {
      key: 'autoAssignToSubjectAdmin',
      label: 'Automatické přiřazení role k správci subjektu',
    },
    {
      key: 'userConsentRequired',
      label: 'Vyžaduj souhlas s nahlížením na data',
    },
    {
      key: 'allowedToRequest',
      label: 'Příznak, zda-li je možné o tuto roli zažádat',
    },
  ];
}

import { TableColumn, TableCells } from '@eas/common-web';
import { District } from '../../models';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

// It shouldn't be named useColumns, because it's not a hook,
// but I'm following the convention in this project.
export const useColumns = () => {
  const columns: TableColumn<District, any>[] = [
    {
      datakey: 'nutsLauCode',
      name: 'Kód NUTS/LAU',
      width: 150,
      CellComponent: inactiveColoredFactory<District>(TableCells.TextCell),
    },
  ];

  return columns;
};

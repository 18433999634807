import React from 'react';
import { ProcessForm } from '../boiler-manufacturer-new-types';
import { noop } from 'lodash';
import { Form, FormPanel, FormSelect, FormTextField } from '@eas/common-web';
import {
  useSubjectTypes,
  useSubjectIdentificators,
} from '../../subject/subjects-api';

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const subjectTypes = useSubjectTypes();
  const identificators = useSubjectIdentificators();
  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Výrobce OZO">
        <FormSelect
          name="subjectType"
          valueIsId
          source={subjectTypes}
          label="Typ subjektu"
        />
        <FormSelect
          name="type"
          valueIsId
          source={identificators}
          label="Typ identifikátoru"
        />
        <FormTextField name="value" label="Identifikátor" />
      </FormPanel>
    </Form>
  );
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const CREATE: MessageType<'ROLE_INSTANCE_ALREADY_EXISTS' | 'SUCCESS' | 'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE'> = {
  ROLE_INSTANCE_ALREADY_EXISTS: ['Vybraný uživatel má již požadovanou roli přidělenou.', SnackbarVariant.ERROR],
  SUCCESS: ['Role úspěšně přiřazena.', SnackbarVariant.SUCCESS],
  ERROR: ['Roli se nepodařilo přiřadit.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
};

const DELETE: MessageType<'SUCCESS' | 'ERROR'> = {
  SUCCESS: ['Role úspěšně odebrána.', SnackbarVariant.SUCCESS],
  ERROR: ['Roli se nepodařilo odebrat.', SnackbarVariant.ERROR],
};

const UPDATE: MessageType<'INVALID_ENTITY_VALIDITY_RANGE' | 'ERROR'> = {
  ERROR: ['Chyba při editaci role.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
}

export default { CREATE, DELETE, UPDATE };

import React, { forwardRef, useContext, useRef } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {
  Dialog,
  DialogHandle,
  DomainObject,
  DetailContext,
  DetailHandle,
  useEventCallback,
  Tooltip,
  SnackbarContext,
} from '@eas/common-web';
import { ExternalShareDialogProps } from './external-share-dialog-types';
import { EvidenceUrl, Messages } from '../../../enums';

export const ExternalShareDialog = forwardRef<
  DialogHandle,
  ExternalShareDialogProps
>(function ShareDialog({ onCancel, listId }, ref) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { source } = useContext<DetailHandle<DomainObject>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const handleFocus = useEventCallback((e) => {
    e.preventDefault();
    if (inputRef.current) {
      const last = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, last);
    }
  });

  const handleCopy = useEventCallback((e) => {
    e.preventDefault();

    if (inputRef.current) {
      handleFocus(e);

      document.execCommand('copy');

      showSnackbar(...Messages.PublicList.COPY.SUCCESS);
    }
  });

  const title = 'Získat externí odkaz';

  const text = 'Sdílet s ostatními:';

  const copyTooltip = 'Kopírovat';

  return (
    <Dialog ref={ref} title={title} showConfirm={false} onCancel={onCancel}>
      {() => (
        <>
          <Typography style={{ width: 900 }}>{text}</Typography>
          <br />
          {source.data?.id && (
            <TextField
              variant="outlined"
              fullWidth
              onClick={handleFocus}
              value={`${
                window.location.origin
              }${EvidenceUrl.PUBLIC_RECORDS_PUBLIC.replace(
                ':listId',
                listId ?? ''
              )}`}
              inputRef={inputRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="copy"
                      onClick={handleCopy}
                      edge="end"
                    >
                      <Tooltip title={copyTooltip} placement="right">
                        <FileCopyIcon />
                      </Tooltip>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </>
      )}
    </Dialog>
  );
});

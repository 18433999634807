import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { RoleInstance } from '../../../../models';
import { useLocalRoleInstanceTypes } from '../../../role-instance/role-instances-api';
import { inactiveColoredFactory } from '../../../../components/form/inactive-colored/inactive-colored';

export function useColumns(): TableColumn<RoleInstance>[] {
  return [
    {
      datakey: 'role.registeredFor.name',
      name: 'Systém',
      width: 100,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      name: 'Typ',
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      valueMapper: TableCells.useSelectCellFactory(useLocalRoleInstanceTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useLocalRoleInstanceTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'role.name',
      name: 'Role',
      width: 300,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      datakey: 'subject.name',
      name: 'Subjekt',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      datakey: 'subject.identifier',
      name: 'IČO / UID',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}

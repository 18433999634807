import { useRef, useState, useContext } from 'react';
import * as Yup from 'yup';
import {
  FormHandle,
  useEventCallback,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { PasswordChangeForm, PasswordChangeRequest } from './profile-types';
import { changePassword } from './profile-api';
import { validatePassword } from './profile-utils';
import { Messages } from '../../enums';

export function usePasswordChangeDialog(closeDialog: () => void) {
  const { showSnackbar } = useContext(SnackbarContext);

  const ref = useRef<FormHandle<PasswordChangeForm>>(null);

  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  const handleConfirm = useEventCallback(async () => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      if (errors.length > 0) {
        showSnackbar(...Messages.Profile.UPDATE.ERROR_FORM);
        setLoading(false);
        return false;
      }
    }

    ref.current?.submitForm();

    return false;
  });

  const handleSubmit = useEventCallback(async (values: PasswordChangeForm) => {
    try {
      setLoading(true);

      const passwords: PasswordChangeRequest = {
        newPassword: values.newPassword,
      };
      if (values.oldPassword) {
        passwords.oldPassword = values.oldPassword;
      }

      await changePassword(user?.id || '', passwords).raw();

      closeDialog();
      showSnackbar(...Messages.Profile.CHANGE_PASSWORD.SUCCESS);
      setLoading(false);
    } catch (err) {
      // TODO: trash, rework
      if (err.message.includes('oldPassword')) {
        const error =
          err.code === 'MISSING_OLD_PASSWORD'
            ? 'MISSING_OLD_PASSWORD'
            : 'ERROR_OLD';
        ref.current?.setErrors([
          ...ref.current.errors,
          {
            key: 'oldPassword',
            label: '',
            value: Messages.Profile.CHANGE_PASSWORD[error][0],
          },
        ]);
        showSnackbar(...Messages.Profile.CHANGE_PASSWORD[error]);
        setLoading(false);
        throw err;
      } else if (err.name !== 'AbortError') {
        showSnackbar(...Messages.Profile.CHANGE_PASSWORD.ERROR);
        setLoading(false);

        throw err;
      }
    }
  });

  const validationSchema = Yup.object<PasswordChangeForm>().shape({
    newPassword: validatePassword,
    newPasswordAgain: Yup.string()
      .nullable()
      .required('Nové heslo znovu musí být vyplněné')
      .oneOf(
        [Yup.ref('newPassword')],
        'Zopakované heslo se neshoduje s heslem'
      ),
  });

  return {
    ref,
    loading,
    validationSchema,
    handleConfirm,
    handleSubmit,
  };
}

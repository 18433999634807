import React from 'react';

export function FormPanelText({ value }: { value: string }) {
  return (
    <div
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      [{' '}
      <span
        style={{
          fontWeight: 400,
        }}
      >
        {value}
      </span>{' '}
      ]
    </div>
  );
}

import React, { useMemo } from 'react';
import {
  FormInlineTableField,
  TableFieldColumn,
  InlineTableFieldCells,
  FormFieldContext,
} from '@eas/common-web';
import { HelpLabel } from './../../components/help/help-label';

export function useIsdsColumns(dataKey: string): TableFieldColumn<string>[] {
  const IdentifierCell = InlineTableFieldCells.useInlineTextFieldFactory<
    string
  >({
    collectionDatakey: dataKey,
    isSubkey: false,
  });
  return useMemo(
    () => [
      {
        name: 'Identifikátor',
        datakey: 'ids',
        width: 400,
        CellComponent: IdentifierCell,
      },
    ],
    [IdentifierCell]
  );
}

export function IsdsIds({
  prefix,
  codePrefix = 'SUBJECT',
  disabled,
}: {
  prefix?: string;
  codePrefix?: string;
  disabled?: boolean;
}) {
  const names = {
    identityDocuments: prefix ? `${prefix}.isdsIds` : 'isdsIds',
  };

  const columns = useIsdsColumns(names.identityDocuments);

  return (
    <FormFieldContext.Provider value={{ disabled }}>
      <FormInlineTableField
        name={names.identityDocuments}
        label={
          <HelpLabel
            label="Identifikátory datové schránky"
            code={`${codePrefix}_ISDS_ID`}
          />
        }
        columns={columns}
        initNewItem={() => ''}
        disabled={disabled}
      />
    </FormFieldContext.Provider>
  );
}

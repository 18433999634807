import {
  TableColumn,
  TableCells,
  TableFilterCells,
  PersonalEvent,
  useStaticListSource,
  DictionaryAutocomplete,
} from '@eas/common-web';

export function usePersonalEventTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'LOGIN_SUCCESSFUL', name: 'Úspěšné přihlášení' },
    { id: 'LOGIN_FAILED', name: 'Neúspěšné přihlášení' },
    { id: 'LOGOUT', name: 'Odhlášení' },
    { id: 'LOGOUT_AUTOMATIC', name: 'Automatické odhlášení' },
  ]);
}

export function useColumns(): TableColumn<PersonalEvent>[] {
  return [
    {
      datakey: 'created',
      name: 'Vytvoření',
      width: 150,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Událost',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        usePersonalEventTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(usePersonalEventTypes),
      sortable: true,
      filterable: true,
    },
  ];
}

import * as Yup from 'yup';
import { DictionaryCountry } from '../../models';

export function useValidationSchema() {
  return Yup.object<DictionaryCountry>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    code: Yup.string().nullable().required('Povinné pole'),
    alpha2: Yup.string().nullable().required('Povinné pole'),
    alpha3: Yup.string().nullable().required('Povinné pole'),
  });
}

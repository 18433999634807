import React, { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailHandle,
  PromptContext,
  usePrompts,
  FormTextArea,
  DetailMode,
  abortableFetch,
  EvidenceContext,
  UserContext,
} from '@eas/common-web';
import { RoleInstance } from '../../../models';
import { Messages, EvidenceAPI, Permission } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
function callApi(id: string, values: { note?: string }) {
  return abortableFetch(`${EvidenceAPI.ROLE_INSTANCES}/${id}/active`, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(values),
  });
}

/**
 * Approve subject registration.
 */
const PROMPT_KEY = 'DELETE';

export function useDeleteDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, formRef, source, setMode, mode } = useContext<
    DetailHandle<RoleInstance & { note: string }>
  >(DetailContext);
  const { tableRef } = useContext(EvidenceContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Odstranit roli',
        dialogText: 'Skutečně chcete smazat přiřazenou roli?',
        FormFields: function Body() {
          return (
            <>
              <FormTextArea name="note" label="Poznámka" />
            </>
          );
        },
        dialogWidth: 600,
      },
    ],
    []
  );

  const handleDelete = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { note: string }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(source.data!.id, values);

          await fetch.current.raw();

          source.reset();
          formRef?.resetValidation();
          tableRef?.current?.resetSelection();
          setMode(DetailMode.NONE);
          onPersisted(null);

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.RoleInstance.DELETE.SUCCESS);
            source.setLoading(false);
          });
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.RoleInstance.DELETE.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showBtn =
    mode === DetailMode.VIEW &&
    [
      Permission.Role.ROLE_INSTANCE_LIST,
      Permission.Role.ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE,
      Permission.Role.ROLE_INSTANCE_LIST_SECURITY_ROLE,
      Permission.Role.ROLE_INSTANCE_LIST_SYSTEM_ROLE,
      Permission.Role.ROLE_INSTANCE_LIST_USER_ROLE,
      Permission.Role.ROLE_INSTANCE_LIST_SELECTED,
    ].some((perm) => hasPermission(perm));

  const disableBtn = !source?.data?.active;

  return {
    handleDelete,
    showBtn,
    disableBtn,
  };
}

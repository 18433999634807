import React from 'react';
import { FormPanel, FormAutocomplete, FormNumberField } from '@eas/common-web';
import { useExportTemplatesAutocomplete, useRoles } from '../export-api';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { HelpLabel } from '../../../components/help/help-label';

export function ExportAccessRuleFields() {
  const attributes = useExportTemplatesAutocomplete();
  const roles = useRoles();

  return (
    <>
      <FormPanel label="Základní informace">
        <FormAutocomplete
          name="template"
          label={
            <HelpLabel
              label="Šablona"
              code="REPORT_ACCESS_RULE_TEMPLATE"
              required
            />
          }
          source={attributes}
          tooltipMapper={(o) => o.name}
        />
        <FormAutocomplete
          name="allowedRoles"
          label={
            <HelpLabel
              label="Role"
              code="REPORT_ACCESS_RULE_ALLOWED_ROLES"
              required
            />
          }
          multiple={true}
          source={roles}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormNumberField name="maximumSize" label="Maximální počet záznamů" />
      </FormPanel>
    </>
  );
}

import { MessageType } from './message-type';
import { SnackbarVariant } from '@eas/common-web';

const CHANGE_PASSWORD: MessageType<
  'ERROR' | 'ERROR_OLD' | 'SUCCESS' | 'MISSING_OLD_PASSWORD'
> = {
  SUCCESS: ['Heslo bylo úspěšně změněno.', SnackbarVariant.SUCCESS],
  ERROR_OLD: ['Staré heslo je zadáno chybně.', SnackbarVariant.ERROR],
  ERROR: ['Chyba při změně hesla.', SnackbarVariant.ERROR],
  MISSING_OLD_PASSWORD: [
    'Staré heslo musí být vyplněno.',
    SnackbarVariant.ERROR,
  ],
};

const UPDATE: MessageType<'SUCCESS' | 'ERROR' | 'ERROR_FORM'> = {
  SUCCESS: ['Váš profil byl úspěšně upraven.', SnackbarVariant.SUCCESS],
  ERROR_FORM: ['Ve formuláři se nacházejí chyby.', SnackbarVariant.ERROR],
  ERROR: ['Chyba při upravování profilu.', SnackbarVariant.ERROR],
};

const GET: MessageType<'ERROR'> = {
  ERROR: ['Chyba při načítání profilu.', SnackbarVariant.ERROR],
};

const CLEAR_USER_SETTINGS: MessageType<'SUCCESS'> = {
  SUCCESS: [
    'Nastavení přehledů bylo úspěšně obnoveno.',
    SnackbarVariant.SUCCESS,
  ],
};

const REMOVE_IDP: MessageType = {
  SUCCESS: ['Externí poskytovatel identity odebrán.', SnackbarVariant.SUCCESS],
  ERROR: [
    'Chyba při odebrání externího poskytovatele identity.',
    SnackbarVariant.ERROR,
  ],
};

const ADD_IDP: MessageType<
  'SUCCESS' | 'ERROR' | 'IDENTITY_PROVIDER_EXTERNAL_ID_ALREADY_REGISTERED'
> = {
  SUCCESS: ['Služba propojena.', SnackbarVariant.SUCCESS],
  ERROR: ['Nastala chyba při propojování účtu.', SnackbarVariant.ERROR],
  IDENTITY_PROVIDER_EXTERNAL_ID_ALREADY_REGISTERED: [
    'Účet není možné propojit. Identifikátor externího poskytovatele identity je již v CRŽP evidován u jiného účtu.',
    SnackbarVariant.ERROR,
  ],
};

export default {
  CHANGE_PASSWORD,
  UPDATE,
  GET,
  CLEAR_USER_SETTINGS,
  REMOVE_IDP,
  ADD_IDP,
};

import { Editor, Element as SlateElement } from 'slate';
import { CustomEditor } from '../../../global';
import { AllFormats } from '../rich-editor-types';

export const isBlockActive = (editor: CustomEditor, format: AllFormats) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

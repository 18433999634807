/**
 * Api urls
 */
export enum EvidenceAPI {
  // RUIAN
  ADDRESS_CADASTRAL_TERRITORY = '/api/crzp/ruian/cadastral-territory',
  ADDRESS_COUNTRY = '/api/crzp/country',
  ADDRESS_DISCTRICT = '/api/crzp/ruian/district',
  ADDRESS_DISCTRICT_CITY = '/api/crzp/ruian/district/city',
  ADDRESS_DISCTRICT_CITY_PRAGUE = '/api/crzp/ruian/district/city/prague',
  ADDRESS_MUNICIPALITY = '/api/crzp/ruian/municipality',
  ADDRESS_MUNICIPALITY_PART = '/api/crzp/ruian/municipality/part',
  ADDRESS_PLACE = '/api/crzp/ruian/address-place',
  ADDRESS_REGION = '/api/crzp/ruian/region',
  ADDRESS_STREET = '/api/crzp/ruian/street',
  ADDRESS_ADMINISTRATIVE_DISTRICT_PRAGUE = '/api/crzp/ruian/district/administration/prague',
  ADDRESS_EXTENDED_COMPETENCE_MUNICIPALITY = '/api/crzp/ruian/municipality/orp',
  ADDRESS_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY = '/api/crzp/ruian/municipality/pou',
  ADDRESS_COHESION_REGION = '/api/crzp/ruian/cohesion-region',

  ADDRESS_HOUSE_NUMBER = '/api/crzp/address/house-number',
  AGENDAS = '/api/crzp/agenda',
  ALOG = '/api/crzp/alog',
  AUTH_TYPE = '/api/crzp/auth/type',
  BASIN_ENTERPIRSES = '/api/crzp/water-usage-place/basin-enterprise',
  BOILER_MANUFACTURERS = '/api/crzp/boiler/manufacturer',
  BOILER_PERMISSIONS = '/api/crzp/boiler/permission',
  DICTIONARY_BALANCE_UNITS = '/api/crzp/water-usage-place/decision/indicator/balance-unit',
  DICTIONARY_BOILER_BRANDS = '/api/crzp/boiler/brand',
  DICTIONARY_BOILER_MODELS = '/api/crzp/boiler/model',
  DICTIONARY_BOILER_TYPES = '/api/crzp/boiler/type',
  DICTIONARY_COUNTRIES = '/api/crzp/country',
  DICTIONARY_COMPETENT_AUTHORITIES = '/api/crzp/competent-authority',
  DICTIONARY_COMPETENT_CONTROL_AUTHORITIES = '/api/crzp/competent-control-authority',
  DICTIONARY_CZ_NACES = '/api/crzp/nace/cz',
  DICTIONARY_DECISION_TYPES = '/api/crzp/water-usage-place/decision/type',
  DICTIONARY_DEPOSITS = '/api/crzp/deposit',
  DICTIONARY_DIRP_AREAS = '/api/crzp/dirp/area',
  DICTIONARY_DIRP_AUTHORS = '/api/crzp/dirp/author',
  DICTIONARY_DIRP_LAWS = '/api/crzp/dirp/law',
  DICTIONARY_DIRP_LAW_PARAGRAPHS = '/api/crzp/dirp/law-paragraph',
  DICTIONARY_DIRP_TYPES = '/api/crzp/dirp/type',
  DICTIONARY_EPRTR_ACTIVITIES = '/api/crzp/production/facility/irz/eprtr-activity',
  DICTIONARY_INDICATORS = '/api/crzp/water-usage-place/decision/indicator',
  DICTIONARY_INFOS = '/api/crzp/dictionary-info',
  DICTIONARY_IPPC_ACTIVITIES = '/api/crzp/production/facility/irz/ippc-activity',
  DICTIONARY_IRZ_ACTIVITIES = '/api/crzp/production/facility/irz/irz-activity',
  DICTIONARY_HYDROGEOLOGICAL_ZONES = '/api/crzp/water-usage-place/hydrogeological-zone',
  DICTIONARY_LEGAL_FORMS = '/api/crzp/subject/legal-form',
  DICTIONARY_MINERAL_GROUP = '/api/crzp/mineral-group',
  DICTIONARY_NACES = '/api/crzp/nace',
  DICTIONARY_OVSS = '/api/crzp/ovss',
  DICTIONARY_PROCESSING_PURPOSE = '/api/crzp/personal-data/processing-purpose',
  DICTIONARY_RIVER_BASIN_CZ_DISTRICTS = '/api/crzp/river-basin/district/cz',
  DICTIONARY_RIVER_BASIN_EU_DISTRICTS = '/api/crzp/river-basin/district/eu',
  DICTIONARY_RIVER_SIDES = '/api/crzp/water-usage-place/river-side',
  DICTIONARY_SAMPLE_TYPES = '/api/crzp/water-usage-place/decision/indicator/sample-type',
  DICTIONARY_SEQUENCES = '/api/crzp/sequences',
  DICTIONARY_SEWER_TYPES = '/api/crzp/water-usage-place/sewer-type',
  DICTIONARY_TERRITORIAL_TECHNICAL_UNITS = '/api/crzp/territorial-technical-unit',
  DICTIONARY_WATER_FLOWS = '/api/crzp/water-usage-place/water-flow',
  DICTIONARY_WATER_ORIGINS = '/api/crzp/water-usage-place/water-origin',
  DICTIONARY_WATER_TREATMENT_METHODS = '/api/crzp/water-usage-place/water-treatment-method',
  DIRP_RECORDS = '/api/crzp/dirp/record',
  EMPOWERMENTS = '/api/crzp/empowerment',
  EMPOWERMENT_REQUESTS = '/api/crzp/empowerment/request',
  EXPORT = '/api/crzp/export',
  EXPORT_ACCESS_RULES = '/api/crzp/export/access-rules',
  FILES = '/api/crzp/files',
  ARCHIVED_FILES = '/api/crzp/archived/file',
  IRZ_FACILITIES = '/api/crzp/production/facility/irz',
  IRZ_FACILITY_REQUESTS = '/api/crzp/production/facility/irz/request',
  ISPOP_DOCUMENTS = '/api/crzp/ispop-document',
  MAILING = '/api/crzp/mailing/campaign',
  NOTES = '/api/crzp/note',
  NOTIFICATIONS = '/api/crzp/notification',
  NOTIFICATION_RECEIVED = '/api/crzp/notification/received',
  NOTIFICATION_BATCHES = '/api/crzp/notification/batch',
  NOTIFICATION_EVENTS = '/api/crzp/notification/event',
  NOTIFICATION_PREFERENCES = '/api/crzp/notification/preference',
  NOTIFICATION_TEMPLATES = '/api/crzp/notification/template',
  OUTAGES = '/api/crzp/outage',
  OUTAGES_PUBLIC = '/api/crzp/outage/public',
  OVZ_FACILITIES = '/api/crzp/production/facility/ovz',
  OVZ_FACILITY_REQUESTS = '/api/crzp/production/facility/ovz/request',
  PERSONAL_DATA = '/api/crzp/personal-data',
  PRODUCTION_SITES = '/api/crzp/production/site',
  PROTECTED_DEPOSIT_AREAS = '/api/crzp/chlu',
  PUBLIC_LISTS = '/api/crzp/public-list',
  PUBLIC_LIST_PUBLISHINGS = '/api/crzp/public-list-publishing',
  PUBLIC_RECORDS = '/api/crzp/public-list/{id}/records',
  REPORTING = '/api/crzp/reporting',
  REPORTING_ACCESS_RULES = '/api/crzp/reporting/access-rules',
  ROLES = '/api/crzp/role',
  ROLE_REQUESTS = '/api/crzp/role/request',
  ROLE_INSTANCES = '/api/crzp/role/instance',
  SOAP_EXECUTIONS = '/ws/execution/log',
  SOAP_MESSAGE_LOGS = '/ws/msg/log',
  SOAP_MESSAGES = '/api/crzp/soap/logger/messages',
  SCHEDULE_JOBS = '/api/crzp/schedule/jobs',
  SCHEDULE_RUNS = '/api/crzp/schedule/runs',
  SUBJECTS = '/api/crzp/subject',
  SUBJECT_REQUESTS = '/api/crzp/subject/registration',
  SYSTEMS = '/api/crzp/system/ais',
  THIRD_PARTY_SYSTEMS = '/api/crzp/system/third-party',
  TRANSLATIONS = '/api/crzp/translations',
  USERS = '/api/crzp/user',
  USERS_REGISTRATIONS = '/api/crzp/user/registration',
  WATER_MANAGEMENT_DECISIONS = '/api/crzp/water-usage-place/decision',
  WATER_MANAGEMENT_DECISION_PARTS = '/api/crzp/water-usage-place/decision/part',
  WATER_USAGE_PLACES = '/api/crzp/water-usage-place',
  WEB_SERVICES = '/ws/evidence',
}

import {
  useStaticListSource,
  DictionaryAutocomplete,
  ResultDto,
  eqFilterParams,
} from '@eas/common-web';
import { EmpowermentType, EmpowermentRequest } from '../../models';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import { EvidenceAPI } from '../../enums';
import { post } from '../../utils/custom-crud';

export function useEmpowermentTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: EmpowermentType.BASIC,
      name: 'Bez specifikace',
    },
    {
      id: EmpowermentType.SPECIFIED,
      name: 'Se specifikacemi',
    },
  ]);
}

export async function combineGetCalls({
  itemId,
  signal,
  mainItem,
}: CombineCallsType) {
  const json = {
    ...mainItem,
  };

  /**
   * Append empowerment registration by itemId
   */
  const [empowermentReg] = await post<ResultDto<EmpowermentRequest>>({
    url: `${EvidenceAPI.EMPOWERMENT_REQUESTS}/list`,
    signal,
    body: eqFilterParams({ field: 'empowerment.id', value: itemId }),
  });

  if (empowermentReg?.items?.length) {
    json['empowermentRequest'] = empowermentReg?.items[0];
  }

  const text = JSON.stringify(json);

  return {
    data: json,
    text,
  };
}

import React, { useContext } from 'react';
import {
  FormPanel,
  FormAutocomplete,
  DetailMode,
  DetailContext,
} from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';
import { SubjectFormPanel } from '../../../composite/subject-fields/subject-form-panel';
import {
  dictionaryNoCodeLabelMapper,
  userLabelMapper,
} from '../../../components/form/mappers/label-mappers';
import { useAssignableRelationshipRoles } from '../../roles/roles-api';
import { useUsers } from '../../user/users-api';

export function RelationshipRoleFields() {
  const { mode } = useContext(DetailContext);

  const relationshipRoles = useAssignableRelationshipRoles();
  const users = useUsers();

  return (
    <>
      <SubjectFormPanel
        codePrefix="ROLE_INSTANCES"
        label="Informace o subjektu"
        disabled={mode === DetailMode.EDIT}
        autocomplete={{
          name: 'subject',
          label: 'Subjekt', // Povinné pole
          required: true,
        }}
      />

      <FormPanel label="Vztahová role">
        <FormAutocomplete
          disabled={mode === DetailMode.EDIT}
          name="role"
          label={<HelpLabel label="Role" code="ROLE_INSTANCES_ROLE" required />} // Povinné pole
          source={relationshipRoles}
          labelMapper={dictionaryNoCodeLabelMapper}
          tooltipMapper={dictionaryNoCodeLabelMapper}
        />
        <FormAutocomplete
          disabled={mode === DetailMode.EDIT}
          name="user"
          label={
            <HelpLabel label="Uživatel" code="ROLE_INSTANCES_USER" required />
          } // Povinné pole
          source={users}
          labelMapper={userLabelMapper}
          tooltipMapper={userLabelMapper}
        />
      </FormPanel>
    </>
  );
}

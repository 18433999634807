import { stubFalse } from 'lodash';
import { HistoryFieldUnion, HistoryFieldType } from '../../../models';
import { parseISO, format } from 'date-fns';
import { dd2dms } from '../../../utils/latlng';

export function historyValueMapper(
  change: HistoryFieldUnion,
  keyMapper: (key: string) => string | undefined = (key) => key,
  skipNestedKey: (key: string) => boolean = stubFalse
) {
  const value = [];
  switch (change.type) {
    case HistoryFieldType.ID_LABEL: {
      value.push(change.label);
      break;
    }
    case HistoryFieldType.STRING: {
      value.push(change.value);
      break;
    }
    case HistoryFieldType.BOOLEAN: {
      value.push(change.value ? 'Ano' : 'Ne');
      break;
    }
    case HistoryFieldType.LOCAL_DATE: {
      value.push(format(parseISO(change.value), 'dd.MM.yyyy'));
      break;
    }
    case HistoryFieldType.LOCAL_DATE_TIME: {
      value.push(format(parseISO(change.value), 'dd.MM.yyyy HH:mm:ss'));
      break;
    }
    case HistoryFieldType.NESTED:
      Object.entries(change.map).forEach(([nestedKey, nestedValue]) => {
        if (!skipNestedKey(nestedKey)) {
          value.push(
            `${keyMapper(nestedKey)} - ${historyValueMapper(
              nestedValue,
              keyMapper
            )}`
          );
        }
      });
  }

  return value.join(', ');
}

export function coordinatesValueMapper(change: HistoryFieldUnion) {
  if (change.type === HistoryFieldType.STRING) {
    const numberCoordinates = Number(change.value);
    if (Number.isNaN(numberCoordinates)) {
      return change.value;
    }
    const parsed = dd2dms(numberCoordinates);
    return `${parsed.degrees}°${parsed.minutes}'${parsed.seconds}"`;
  }

  return '';
}

import React, { useRef, useState, useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormContext,
  useEventCallback,
  SnackbarContext,
  AbortableFetch,
  abortableFetch,
  PromptContext,
  usePrompts,
  NavigationContext,
} from '@eas/common-web';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { EvidenceAPI, Messages } from '../../../enums';
import { IrzFacility } from '../../../models';
import { ProcessForm } from '../irz-facility-merge-types';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

interface MergeFacilityApiCall {
  mainFacilityId?: string;
  endingFacilityId?: string;
  updatedIrzFacility?: IrzFacility;
  date?: string;
  note?: string;
}

/**
 * Api call
 *
 * @param body
 */
function callApi({
  endingFacilityId,
  mainFacilityId,
  updatedIrzFacility,
  note,
  date,
}: MergeFacilityApiCall) {
  return abortableFetch(
    `${EvidenceAPI.IRZ_FACILITIES}/${mainFacilityId}/merge/${endingFacilityId}${
      date ? `?date=${date}` : ''
    }`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({ updatedIrzFacility, note }),
    }
  );
}

const PROMPT_KEY = 'MERGE_FACILITIES';

export function StepSummaryActions({
  handleNext,
  handleBack,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Loading state.
   */
  const [loading, setLoading] = useState(false);

  /**
   * Context stuff.
   */
  const { setFieldValue } = useContext(FormContext);
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { unregisterPrompt } = useContext(NavigationContext);

  /**
   * Fetch reference.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Prompt dialog.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Sloučení provozoven IRZ',
      dialogText: 'Skutečně chcete sloučiť vybrané provozovny IRZ?',
    },
  ]);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const {
            editData,
            mainFacility,
            mergingFacility,
            date,
            note,
          } = allValues;

          fetch.current = callApi({
            endingFacilityId: mergingFacility?.id,
            mainFacilityId: mainFacility?.id,
            updatedIrzFacility: editData,
            note,
            date,
          });

          const mergedFacility: IrzFacility = await fetch.current.json();
          showSnackbar(...Messages.IrzFacility.MERGE.SUCCESS);
          setFieldValue('mergedFacility', mergedFacility);
          setFieldValue('isSent', true);
          unregisterPrompt(navigationPrompt);

          handleNext();
        } catch (err) {
          setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.IrzFacility.MERGE,
              err.code
            );

            showSnackbar(...message);

            throw err;
          }
        } finally {
          setLoading(false);
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Sloučit provozovny
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
      </div>
    </div>
  );
}

import { MessageType } from './message-type';
import { SnackbarVariant } from '@eas/common-web';

const IMPORT: MessageType<'WARNING' | 'ERROR'> = {
  ERROR: ['Chyba formátu.', SnackbarVariant.ERROR],
  WARNING: [
    'Počet uživatelů, kteří nedali souhlas se zasíláním mailových kampaní:',
    SnackbarVariant.WARNING,
  ],
};

const CHECK_EMAILS: MessageType<
  'SUCCESS' | 'ERROR_BAD' | 'ERROR_NOT_EXIST' | 'ERROR_NO_CONSENT'
> = {
  SUCCESS: ['Všechny adresy jsou v pořádku', SnackbarVariant.SUCCESS],
  ERROR_BAD: ['Počet adres ve špatném formátu:', SnackbarVariant.ERROR],
  ERROR_NOT_EXIST: [
    'Počet adres které nejsou v systému:',
    SnackbarVariant.ERROR,
  ],
  ERROR_NO_CONSENT: ['Počet adres bez souhlasu:', SnackbarVariant.ERROR],
};

export default { IMPORT, CHECK_EMAILS };

import { useEventCallback } from '@eas/common-web';
import { useContext } from 'react';
import { MapEvidenceContext } from '../../../components/evidence/map-evidence/map-context';

export function useShowOnMap() {
  /**
   * Context stuff.
   */
  const { locationPanelRef } = useContext(MapEvidenceContext);

  const showShowOnMapButton = true;

  const handleShowOnMap = useEventCallback(() => {
    locationPanelRef.current?.setExpanded(true);
    locationPanelRef.current?.scrollIntoView();
  });

  return {
    showShowOnMapButton,
    handleShowOnMap,
  };
}

import React, { useContext } from 'react';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButtonType,
  DetailActionbarButton,
} from '@eas/common-web';
import { useCreateNoteDialog } from '../../components/evidence/noted-evidence/dialog-actions/create-note-hook';
import { EntityType } from '../../enums/entity-type';
import { useRejectDialog } from './dialog-actions/reject-hook';
import { useApproveDialog } from './dialog-actions/approve-hook';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function IrzFacilityRequestsActionbar() {
  const { mode } = useContext(DetailContext);
  const { handleReject, showRejectButton } = useRejectDialog();
  const { handleApprove, showApproveButton } = useApproveDialog();

  const {
    handleCreateNote,
    showCreateButton: showCreateNote,
  } = useCreateNoteDialog({
    entityType: EntityType.IRZ_FACILITY_REQUEST,
  });

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          {showApproveButton && (
            <DetailActionbarButton
              startIcon={<DoneIcon fontSize="small" />}
              label="Schválit"
              onClick={handleApprove}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {showRejectButton && (
            <DetailActionbarButton
              startIcon={<CloseIcon fontSize="small" />}
              label="Zamítnout"
              onClick={handleReject}
              type={DetailToolbarButtonType.SECONDARY}
            />
          )}

          {showCreateNote && (
            <DetailActionbarButton
              startIcon={<NoteAddIcon fontSize="small" />}
              label="Přidat poznámku"
              onClick={handleCreateNote}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
        </div>
      )}
    </>
  );
}

import { useMemo } from 'react';
import { StepNewOperatorLabel } from './step-new-operator/step-new-operator-label';
import { StepNewOperatorContent } from './step-new-operator/step-new-operator-content';
import { StepOriginalOperatorLabel } from './step-original-operator/step-original-operator-label';
import { StepOriginalOperatorContent } from './step-original-operator/step-original-operator-content';
import { StepOriginalOperatorActions } from './step-original-operator/step-original-operator-actions';
import { StepFacilityLabel } from './step-facility/step-facility-label';
import { StepFacilityContent } from './step-facility/step-facility-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepFinishActions } from './step-finish/step-finish-actions';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { stepNewOperatorSchema } from './step-new-operator/step-new-operator-schema';
import { stepOriginalOperatorSchema } from './step-original-operator/step-original-operator-schema';
import { stepFacilitySchema } from './step-facility/step-facility-schema';
import { StepNoteDateLabel } from './step-note-date/step-note-date-label';
import { StepNoteDateContent } from './step-note-date/step-note-date-content';
import { StepNameChangeLabel } from './step-name-change/step-name-change-label';
import { stepNameChangeSchema } from './step-name-change/step-name-change-schema';
import { StepNameChangeContent } from './step-name-change/step-name-change-content';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { stepNoteDateSchema } from './step-note-date/step-note-date-schema';
import { formatISO } from 'date-fns';

export function useIrzFacilityRequestOperatorChange() {
  const steps = useMemo(
    () => [
      {
        Label: StepNewOperatorLabel,
        Content: StepNewOperatorContent,
        validationSchema: stepNewOperatorSchema,
      },
      {
        Label: StepOriginalOperatorLabel,
        Content: StepOriginalOperatorContent,
        validationSchema: stepOriginalOperatorSchema,
        Actions: StepOriginalOperatorActions,
      },
      {
        Label: StepFacilityLabel,
        Content: StepFacilityContent,
        validationSchema: stepFacilitySchema,
      },
      {
        Label: StepNameChangeLabel,
        Content: StepNameChangeContent,
        validationSchema: stepNameChangeSchema,
      },
      {
        Label: StepNoteDateLabel,
        Content: StepNoteDateContent,
        validationSchema: stepNoteDateSchema,
        initialValues: {
          date: formatISO(new Date(), { representation: 'date' }),
        },
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    []
  );

  return { steps };
}

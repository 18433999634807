import { useContext, useRef } from 'react';
import {
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailContext,
  abortableFetch,
  PromptContext,
  usePrompts,
  UserContext,
} from '@eas/common-web';
import { unstable_batchedUpdates } from 'react-dom';
import { Messages } from '../../../../enums';
import { Me } from '../../../../models';
import { getErrorMessage } from '../../../../utils/get-message';

/**
 * Api call
 *
 * @param id
 * @param values
 */
export function callApi(url: string, id: string) {
  return abortableFetch(`${url}/${id}/active`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
}

/**
 * Obnovení platnosti provozovny IRZ
 */
const PROMPT_KEY = 'ACTIVATE';

export function useActivateDialog({
  url,
  revalidatePerm,
}: {
  url: string;
  revalidatePerm: string;
}) {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Varování',
      dialogText: 'Přejete si obnovit platnost záznamu?',
    },
  ]);

  const handleActivate = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callApi(url, source.data!.id);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Dictionary.ACTIVATE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.Dictionary.ACTIVATE,
              err.code
            );

            showSnackbar(...message);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showActivateButton =
    !source.data?.active && hasPermission(revalidatePerm);

  return {
    handleActivate,
    showActivateButton,
  };
}

import React, { useContext, useMemo } from 'react';
import {
  useEventCallback,
  ApiFilterOperation,
  FormContext,
} from '@eas/common-web';
import { AddressFieldCell } from '../address-field-cell';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';
import { EvidenceAPI } from '../../../../enums';

export function CountryCell({
  disableForeign = false,
  disableRuian = false,
}: {
  disableForeign?: boolean;
  disableRuian?: boolean;
}) {
  /**
   * Context stuff.
   */
  const { code } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);
  const { editing } = useContext(FormContext);

  /**
   * Compose autocomplete source filter.
   */
  const createFilter = useEventCallback(() => {
    if (disableForeign) {
      return [
        {
          operation: ApiFilterOperation.EQ,
          field: 'ruian',
          value: true,
        },
      ];
    } else if (disableRuian) {
      return [
        {
          operation: ApiFilterOperation.EQ,
          field: 'ruian',
          value: false,
        },
      ];
    }
    return [];
  });

  const disabled = useMemo(() => () => disableForeign, [disableForeign]);

  const filterDeps = useEventCallback(() => [
    disableForeign,
    disableRuian,
    editing,
  ]);

  const descendantNames = useMemo(
    () =>
      [
        'regionRuian',
        'region',
        'districtRuian',
        'district',
        'municipalityRuian',
        'municipality',
        'cityDistrictPrague',
        'cityDistrictRuian',
        'cityDistrict',
        'municipalityPartRuian',
        'municipalityPart',
        'streetRuian',
        'street',
        'houseNumberType',
        'houseNumber',
        'orientationNumber',
        'orientationNumberMark',
        'addressPlaceRuian',
        'addressPlace',
        'zip',
      ] as const,
    []
  );

  return (
    <AddressFieldCell
      label={defaults?.country?.label ?? 'Stát'}
      required={defaults?.country?.required ?? true}
      name="country"
      code={`${code}_COUNTRY`}
      url={EvidenceAPI.ADDRESS_COUNTRY}
      createFilter={createFilter}
      disabled={disabled}
      filterDeps={filterDeps}
      descendantNames={descendantNames}
    />
  );
}

import { useRef, useContext } from 'react';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  abortableFetch,
  PromptContext,
  usePrompts,
  useFormSelector,
} from '@eas/common-web';
import { Me } from '../../../../models';
import { Messages, EvidenceAPI, Permission } from '../../../../enums';
import { unstable_batchedUpdates } from 'react-dom';
import { SystemCertificate } from '../../../../models/system-certificate';
import { System } from '../../../../models/system';

const PROMPT_KEY = 'ACIVATE_CERTIFICATE';

function callApi(certificate?: SystemCertificate, system?: System) {
  return abortableFetch(
    `${EvidenceAPI.SYSTEMS}/${system?.id}/certificate/${certificate?.id}/active`,
    {
      method: 'PUT',
    }
  );
}

export function useActivateSystemCertificateDialog({
  selectedIndex,
}: {
  selectedIndex: number;
}) {
  const { source } = useContext(DetailContext);

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const { showSnackbar } = useContext(SnackbarContext);

  const { testPrompt } = useContext(PromptContext);

  const fetch = useRef<AbortableFetch | null>(null);

  const { certificates } = useFormSelector((data: System) => {
    return {
      certificates: data?.certificates,
    };
  });

  const isActive = certificates?.[selectedIndex]?.active;

  const canActivateCertificate =
    hasPermission(Permission.System.SYSTEM_CERTIFICATE_REVALIDATE) && !isActive;

  const handleActivateCertificate = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(certificates?.[selectedIndex], source.data!);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.System.ACTIVATE_CERTIFICATE.SUCCESS);
            source.setLoading(false);
          });

          await source.refresh();
        } catch (e) {
          source.setLoading(false);

          if (e.name !== 'AbortError') {
            switch (e.status) {
              case 403:
                showSnackbar(
                  ...Messages.System.ACTIVATE_CERTIFICATE.ALREADY_INACTIVE
                );
                break;
              case 404:
                showSnackbar(...Messages.System.ACTIVATE_CERTIFICATE.NOT_FOUND);
                break;
              default:
                showSnackbar(...Messages.System.ACTIVATE_CERTIFICATE.ERROR);
                break;
            }
            throw e;
          }
          return undefined;
        }
      },
    });
  });

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Aktivace systémového certifikátu',
      dialogText: 'Opravdu si přejete aktivovat tento certifikát?',
    },
  ]);

  return {
    canActivateCertificate,
    handleActivateCertificate,
  };
}

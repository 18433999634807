import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryBalanceUnit } from '../../models';
import { useValidationSchema } from './dict-balance-units-schema';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';

export function DictionaryBalanceUnits() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryBalanceUnit>(
    {
      identifier: PageKey.DICTIONARY_BALANCE_UNITS,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_BALANCE_UNITS,
      },
      tableProps: {
        tableName: 'Jednotky bilance',
        reportTag: ExportTags.DICTIONARY_BALANCE_UNITS,
        defaultSorts: [],
      },
      detailProps: {
        entityName: EntityName.BALANCE_UNIT,
        codePrefix: 'BALANCE_UNIT',
        validationSchema,
        toolbarProps: {
          title: 'Jednotka bilance',
        },
      },
    },
    Permission.MuvBalanceUnit.MUV_BALANCE_UNIT_INVALIDATE,
    Permission.MuvBalanceUnit.MUV_BALANCE_UNIT_REVALIDATE
  );

  return <Evidence2 {...evidence} />;
}

import { useRef, useContext, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  FormHandle,
  AbortableFetch,
  SnackbarContext,
  useEventCallback,
  abortableFetch,
} from '@eas/common-web';
import { useFormError } from './form-error-hook';
import * as Yup from 'yup';
import { unstable_batchedUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Messages } from '../../../enums';
import { PasswordResetData } from '../auth-types';
import { getErrorMessage } from '../../../utils/get-message';

export function usePasswordReset() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { setErrors, getFieldError } = useFormError();

  const { push } = useHistory();

  const { showSnackbar } = useContext(SnackbarContext);

  const [loading, setLoading] = useState<boolean>(false);

  const ref = useRef<FormHandle<PasswordResetData>>(null);

  const fetch = useRef<AbortableFetch | null>(null);

  const validationSchema = Yup.object<PasswordResetData>().shape(
    {
      username: Yup.string().when('firstName', {
        is: (firstName) => !firstName,
        then: Yup.string().required('Uživatelské jméno musí být vyplněné'),
      }),
      firstName: Yup.string().when('username', {
        is: (username) => !username,
        then: Yup.string().required('Jméno musí být vyplněné'),
      }),
      lastName: Yup.string().when('username', {
        is: (username) => !username,
        then: Yup.string().required('Příjmení musí být vyplněné'),
      }),
      email: Yup.string().when('username', {
        is: (username) => !username,
        then: Yup.string().required('Email musí být vyplněný'),
      }),
    },
    [
      ['username', 'firstName'],
      ['username', 'lastName'],
      ['username', 'email'],
    ]
  );

  const handleNavigateBack = useEventCallback(() => {
    push('/prihlaseni');
  });

  const callSubmit = (data: PasswordResetData, captcha: string) => {
    return abortableFetch('/api/crzp/user/password/reset', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        ...data,
        captcha,
      }),
    });
  };

  const submitPasswordReset = useEventCallback(
    async (data: PasswordResetData) => {
      try {
        setLoading(true);

        const captcha = await executeRecaptcha!('password_reset');

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callSubmit(data, captcha);

        await fetch.current.raw();

        unstable_batchedUpdates(() => {
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);

        if (err.name !== 'AbortError') {
          const message = getErrorMessage(
            Messages.User.PASSWORD_RESET,
            err.code
          );

          showSnackbar(...message);

          throw err;
        }
        return undefined;
      }
    }
  );

  const handleSubmit = useEventCallback(async (data: PasswordResetData) => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      setErrors(errors);

      if (errors.length) {
        return;
      }

      await submitPasswordReset(data);

      push('/obnova-hesla/zadost-odeslana', true);
    }
  });

  return {
    ref,
    validationSchema,
    loading,
    handleNavigateBack,
    handleSubmit,
    getFieldError,
    setErrors,
  };
}

import React from 'react';
import { FormTextField, FormDateField } from '@eas/common-web';
import { HelpLabel } from '../../../../components/help/help-label';

export function NaturalPersonAdvancedFields({
  prefix,
  codePrefix = 'SUBJECT',
  disabled,
}: {
  prefix?: string;
  codePrefix?: string;
  disabled?: boolean;
}) {
  const names = {
    degreeBefore: prefix
      ? `${prefix}.degreeBefore.value`
      : 'degreeBefore.value',
    degreeAfter: prefix ? `${prefix}.degreeAfter.value` : 'degreeAfter.value',
    birthDate: prefix ? `${prefix}.birthDate.value` : 'birthDate.value',
    deceaseDate: prefix ? `${prefix}.deceaseDate.value` : 'deceaseDate.value',
  };

  return (
    <>
      <FormTextField
        name={names.degreeBefore}
        label={
          <HelpLabel label="Titul před" code={`${codePrefix}_DEGREE_BEFORE`} />
        }
        disabled={disabled}
      />
      <FormTextField
        name={names.degreeAfter}
        label={
          <HelpLabel label="Titul za" code={`${codePrefix}_DEGREE_AFTER`} />
        }
        disabled={disabled}
      />
      <FormDateField
        name={names.birthDate}
        label={
          <HelpLabel label="Datum narození" code={`${codePrefix}_BIRTH_DATE`} />
        }
        disabled={disabled}
      />
      <FormDateField
        name={names.deceaseDate}
        label={
          <HelpLabel label="Datum úmrtí" code={`${codePrefix}_DECEASE_DATE`} />
        }
        disabled={disabled}
      />
    </>
  );
}

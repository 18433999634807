import { abortableFetch } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function fetchHelpItem(id: string) {
  return abortableFetch(`${EvidenceAPI.TRANSLATIONS}/coded/${id}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  });
}

import { useMemo } from 'react';

import { StepOzoSubjectLabel } from './step-ozo-subject/step-ozo-subject-label';
import { StepOzoSubjectContent } from './step-ozo-subject/step-ozo-subject-content';
import { stepOzoSubjectSchema } from './step-ozo-subject/step-ozo-subject-schema';
import { StepOzoSubjectActions } from './step-ozo-subject/step-ozo-subject-actions';
import { StepManufacturerSubjectLabel } from './step-manufacturer-subject/step-manufacturer-subject-label';
import { StepManufacturerSubjectContent } from './step-manufacturer-subject/step-manufacturer-subject-content';
import { stepManufacturerSubjectSchema } from './step-manufacturer-subject/step-manufacturer-subject-schema';
import { StepInfoLabel } from './step-info/step-info-label';
import { StepInfoContent } from './step-info/step-info-content';
import { stepInfoSchema } from './step-info/step-info-schema';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';

export function useBoilerPermissions() {
  const steps = useMemo(
    () => [
      {
        Label: StepOzoSubjectLabel,
        Content: StepOzoSubjectContent,
        validationSchema: stepOzoSubjectSchema,
        Actions: StepOzoSubjectActions,
      },
      {
        Label: StepManufacturerSubjectLabel,
        Content: StepManufacturerSubjectContent,
        validationSchema: stepManufacturerSubjectSchema,
      },
      {
        Label: StepInfoLabel,
        Content: StepInfoContent,
        validationSchema: stepInfoSchema,
        initialValues: {
          phoneNumber: {
            prefix: '+420',
          },
        },
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
    ],
    []
  );

  return { steps };
}

export enum PermissionMuvHydrogeologicalZone {
  /** Přehled hydrogeologických rajonů */
  MUV_HYDROGEOLOGICAL_ZONE_LIST = 'MUV_HYDROGEOLOGICAL_ZONE_LIST',

  /** Autocomplete výběr hydrogeologických rajonů */
  MUV_HYDROGEOLOGICAL_ZONE_AUTOCOMPLETE = 'MUV_HYDROGEOLOGICAL_ZONE_AUTOCOMPLETE',

  /** Detail hydrogeologického rajónu */
  MUV_HYDROGEOLOGICAL_ZONE_DETAIL = 'MUV_HYDROGEOLOGICAL_ZONE_DETAIL',

  /** Vytvoření nového hydrogeologického rajónu */
  MUV_HYDROGEOLOGICAL_ZONE_CREATE = 'MUV_HYDROGEOLOGICAL_ZONE_CREATE',

  /** Editace hydrogeologického rajónu */
  MUV_HYDROGEOLOGICAL_ZONE_UPDATE = 'MUV_HYDROGEOLOGICAL_ZONE_UPDATE',

  /** Mazání hydrogeologického rajónu */
  MUV_HYDROGEOLOGICAL_ZONE_DELETE = 'MUV_HYDROGEOLOGICAL_ZONE_DELETE',

  /** Zneplatnění hydrogeologického rajónu */
  MUV_HYDROGEOLOGICAL_ZONE_INVALIDATE = 'MUV_HYDROGEOLOGICAL_ZONE_INVALIDATE',

  /** Obnovení hydrogeologického rajónu */
  MUV_HYDROGEOLOGICAL_ZONE_REVALIDATE = 'MUV_HYDROGEOLOGICAL_ZONE_REVALIDATE',
}

import * as Yup from 'yup';
import {
  RoleInstance,
  RoleInstanceType,
  Role,
  User,
  Subject,
} from '../../models';
import { System } from '../../models/system';

export const iczFormat = /^(CZ|CS)[ASULKHEPCJBMTZ][0-9]{5}$/g;
export const icpFormat = /^(?!(\s+|CZ|CS|CO|0+$))\w{1,12}(?!\s+)$/g;

const getRowIndex = (path: string, key: string) => {
  const regex = new RegExp(`${key}\\[(\\d+)\\]`);
  const match = path.match(regex);

  if (match && match[1]) {
    const numberInsideBrackets = match[1];

    return Number(numberInsideBrackets);
  }

  return 0;
};

export const validateSepnoDetails = (getPath: (index: number) => string) =>
  Yup.array()
    .of(
      Yup.object().shape({
        identifier: Yup.object().shape({
          type: Yup.string().test('', '', function (value) {
            const { path, createError } = this;
            const type = value as string;

            const index = getRowIndex(path, 'userRoleSepnoDetails');
            const row = getRowIndex(path, 'sepnoDetails');
            const errorPath = getPath(index);

            if (!type) {
              return createError({
                path: errorPath,
                message: `Řádek ${row + 1}: Identifikátor je povinný`,
              });
            }

            return true;
          }),
          value: Yup.string().test('', '', function (value) {
            const { path, createError, parent } = this;
            const valueStr = value as string;

            const index = getRowIndex(path, 'userRoleSepnoDetails');
            const row = getRowIndex(path, 'sepnoDetails');
            const errorPath = getPath(index);

            if (!valueStr) {
              return createError({
                path: errorPath,
                message: `Řádek ${row + 1}: Hodnota je povinná`,
              });
            }

            const type = parent?.type as string;

            if (type === 'ICP' && !valueStr.match(icpFormat)) {
              return createError({
                path: errorPath,
                message: `Řádek ${
                  row + 1
                }: IČP může mít maximálně 12 alfanumerických znaků (nesmí nabývat hodnoty 0), nesmí začínat ani končit mezerou nebo řadou mezer, nesmí začínat písmeny CZ, CS ani CO`,
              });
            }

            if (type === 'ICZ' && !valueStr.match(iczFormat)) {
              return createError({
                path: errorPath,
                message: `Řádek ${
                  row + 1
                }: IČZ musí obsahovat právě 8 znaků, formát CZXYYYYY nebo CSXYYYYY; kde X (x=1) je písmeno a smí nabývat pouze hodnot "A; S; U; L; K; H; E; P; C; J; B; M; T; Z" a YYYYY je pořadové číslo evidovaného zařízení smí nabývat hodnot 0 - 9 (y=5)`,
              });
            }

            return true;
          }),
        }),
        validFrom: Yup.date().nullable(),
        validTo: Yup.date().nullable(),
      })
    )
    .test('', '', function (value) {
      const { path, createError, parent } = this;

      const sepnoDetails = value as any[];

      const index = getRowIndex(path, 'userRoleSepnoDetails');
      const errorPath = getPath(index);

      if (
        parent?.role?.allowSepnoDetails &&
        (!sepnoDetails || sepnoDetails.length === 0)
      ) {
        return createError({
          path: errorPath,
          message: 'Musí být vyplněn alespoň jeden IČZ nebo IČP',
        });
      }

      return true;
    });

export function useValidationSchema() {
  return Yup.object<RoleInstance>().shape({
    type: Yup.mixed<RoleInstanceType>().nullable().required('Povinné pole'),
    role: Yup.mixed<Role>().nullable().required('Povinné pole'),
    user: Yup.mixed<User>().when('type', {
      is: (type) =>
        type === RoleInstanceType.RELATIONSHIP_ROLE_INSTANCE ||
        type === RoleInstanceType.SECURITY_ROLE_INSTANCE ||
        type === RoleInstanceType.USER_ROLE_INSTANCE,
      then: Yup.mixed<User>().nullable().required('Povinné pole'),
    }),
    subject: Yup.mixed<Subject>().when('type', {
      is: (type) => type === RoleInstanceType.RELATIONSHIP_ROLE_INSTANCE,
      then: Yup.mixed<Subject>().nullable().required('Povinné pole'),
    }),
    system: Yup.mixed<System>().when('type', {
      is: (type) => type === RoleInstanceType.SYSTEM_ROLE_INSTANCE,
      then: Yup.mixed<System>().nullable().required('Povinné pole'),
    }),
    sepnoDetails: validateSepnoDetails(() => 'sepnoDetails'),
  }) as Yup.Schema<RoleInstance>;
}

import { useContext, useMemo, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  useEventCallback,
  SnackbarContext,
  DetailContext,
  UserContext,
  DetailHandle,
  abortableFetch,
  AbortableFetch,
  PromptContext,
  usePrompts,
} from '@eas/common-web';
import { Permission, EvidenceAPI, Messages } from '../../../enums';
import { OvzFacilityRequest, Me, PermissionsState } from '../../../models';
import { OvzFacilityRequestState } from '../../../models/ovz-facility-request';

function apiCall(id: string) {
  return abortableFetch(`${EvidenceAPI.OVZ_FACILITY_REQUESTS}/${id}`, {
    method: 'DELETE',
  });
}

const PROMPT_KEY = 'CANCEL';

export function useCancelDialog() {
  const { source, onPersisted, setActive } = useContext<
    DetailHandle<OvzFacilityRequest>
  >(DetailContext);

  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const fetch = useRef<AbortableFetch | null>(null);

  const canCancel = useMemo(
    () =>
      hasPermission(Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_SUBMIT, {
        subjectId: source.data?.oldOperator?.id,
        searchOnlyInSubjectPerms: true,
      }),
    [source.data?.oldOperator?.id, hasPermission]
  );

  const showCancelButton =
    canCancel &&
    source.data?.state === OvzFacilityRequestState.WAITING_FOR_AUTHORIZATION;

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Zrušení nabídky',
      dialogText: 'Skutečně chcete zrušit nabídku změny provozovatele?',
    },
  ]);

  const handleCancel = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        if (source.data) {
          try {
            source.setLoading(true);

            if (fetch.current !== null) {
              fetch.current.abort();
            }

            fetch.current = apiCall(source.data.id);

            await fetch.current.raw();

            unstable_batchedUpdates(() => {
              showSnackbar(...Messages.OvzFacilityRequest.REJECT.SUCCESS);
              source.setLoading(false);
            });

            source.reset();
            onPersisted(null);
            await source.refresh();

            setActive(null);
          } catch (e) {
            source.setLoading(false);

            if (e.name !== 'AbortError') {
              showSnackbar(...Messages.OvzFacilityRequest.REJECT.ERROR);
              throw e;
            }

            return undefined;
          }
        }
      },
    });
  });

  return {
    handleCancel,
    showCancelButton,
  };
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useAddressFieldsFactory } from '../../components/form/address-field/address-field-history-fields';
import { useSubjectRegistrationState } from './subject-requests-api';
import { useSubjectRegistrationTypes } from '../subject-request-new/subject-requests-api';
import { historyValueMapper } from '../../components/evidence/history-evidence/history-utils';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const subjectRegistrationState = useSubjectRegistrationState();
  const subjectRegistrationTypes = useSubjectRegistrationTypes();

  return [
    ...useAddressFieldsFactory('residence'),
    {
      key: 'evidenceNumber',
      label: 'Evidenční číslo',
    },
    {
      key: 'phoneNumber',
      label: 'Telefonní číslo',
    },
    {
      key: 'email',
      label: 'E-mail',
    },
    {
      key: 'webSite',
      label: 'Webová stránka',
    },
    {
      key: 'state',
      label: 'Stav žádosti',
      source: subjectRegistrationState,
    },
    {
      key: 'document.userUploadedFile',
      label: 'Podepsané PDF',
    },
    {
      key: 'document.signedFile',
      label: 'Autorizace - potvrzení',
    },
    {
      key: 'registrationSubject',
      label: 'Subjekt registrace',
    },
    {
      key: 'authorized',
      label: 'Datum autorizace',
    },
    {
      key: 'type',
      label: 'Typ subjektu',
      source: subjectRegistrationTypes,
    },
    {
      key: 'ico',
      label: 'IČO',
    },
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'foreignId',
      label: 'Zahraniční identifikátor (obdoba IČO)',
    },
    {
      key: 'affidavit',
      label: 'Příloha (čestné prohlášení)',
    },
    {
      key: 'letterOfAttorney',
      label: 'Příloha (plná moc)',
    },
    {
      key: 'registerExtract',
      label:
        'Příloha (výpis z živnostenského nebo obchodního rejstříku nebo podobný dokument)',
    },
    {
      key: 'identityDocumentType',
      label: 'Typ dokladu',
    },
    {
      key: 'idNumber',
      label: 'Číslo dokladu',
    },
    {
      key: 'firstName',
      label: 'Jméno',
    },
    {
      key: 'lastName',
      label: 'Příjmení',
    },
    {
      key: 'identityDocumentCopy',
      label: 'Kopie dokladu totožnosti',
    },
    {
      key: 'ceniaDetails',
      label: 'Spisová služba CENIA',
      valueMapper: (change) => historyValueMapper(change, getCeniaDetailsLabel),
    },
    {
      key: 'dataBoxAuthorizationOnly',
      label: 'Autorizace pouze datovou schránkou',
    },
  ];
}

function getCeniaDetailsLabel(key: string) {
  switch (key) {
    case 'number':
      return 'Ev.č./Č.j.';
    case 'note':
      return 'Poznámka';
  }
}

import React, { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailHandle,
  PromptContext,
  usePrompts,
  FormTextArea,
  DetailMode,
  NavigationContext,
} from '@eas/common-web';
import { RoleInstance } from '../../../models';
import { Messages } from '../../../enums';

/**
 * Save role instance with additional note.
 */
const PROMPT_KEY = 'SAVE';

export function useSaveDialog() {
  /**
   * Context stuff.
   */
  const {
    onPersisted,
    formRef,
    source,
    validate,
    mode,
    setActive,
    setMode,
  } = useContext<DetailHandle<RoleInstance & { note: string }>>(DetailContext);
  const { unregisterPrompt, prompts } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Přiřadit roli',
        dialogText: 'Skutečně chcete uložit přiřazenou roli?',
        FormFields: function Body() {
          return (
            <>
              <FormTextArea name="note" label="Poznámka" />
            </>
          );
        },
        dialogWidth: 600,
      },
    ],
    []
  );

  const handleSave = useEventCallback(async () => {
    const errors = await validate();
    if (errors.length > 0) {
      return;
    }

    if (mode === DetailMode.EDIT) {
      try {
        source.setLoading(true);

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        const formValues = formRef?.getFieldValues();
        const data = await source.update(
          {
            ...formValues!,
          },
          {} as any
        );

        unregisterPrompt(prompts[0]);

        if (data !== undefined) {
          setMode(DetailMode.VIEW);
          onPersisted(data.id);
        }

        unstable_batchedUpdates(() => {
          showSnackbar(...Messages.RoleInstance.CREATE.SUCCESS);
          source.setLoading(false);
        });

        return;
      } catch (err) {
        source.setLoading(false);

        if (err.name !== 'AbortError') {
          showSnackbar(...Messages.RoleInstance.CREATE.ERROR);
          throw err;
        }
        return undefined;
      }
    }

    if (mode === DetailMode.NEW) {
      testPrompt({
        key: PROMPT_KEY,
        submitCallback: async (values: { note: string }) => {
          try {
            source.setLoading(true);

            if (fetch.current !== null) {
              fetch.current.abort();
            }
            const formValues = formRef?.getFieldValues();
            const data = await source.create({
              ...formValues!,
              note: values.note,
            });

            unregisterPrompt(prompts[0]);

            if (data !== undefined) {
              unstable_batchedUpdates(() => {
                setActive(data.id);
                setMode(DetailMode.VIEW);
                onPersisted(data.id);
              });
            }

            unstable_batchedUpdates(() => {
              showSnackbar(...Messages.RoleInstance.CREATE.SUCCESS);
              source.setLoading(false);
            });
          } catch (err) {
            source.setLoading(false);

            if (err.name !== 'AbortError') {
              showSnackbar(
                err?.message ?? Messages.RoleInstance.CREATE.ERROR[0],
                Messages.RoleInstance.CREATE.ERROR[1]
              );

              throw err;
            }
            return undefined;
          }
        },
      });
    }
  });

  return {
    handleSave,
  };
}

import { Exception } from '../../models';
import { CombineCallsFunction } from './custom-crud-types';
import { get } from './get';

export function getItemFactory<TYPE>({
  postprocessCall = () => {},
  combineGetCalls,
}: {
  postprocessCall?: (item: TYPE | Exception, response: Response) => void;
  combineGetCalls: CombineCallsFunction;
}) {
  return function getItem(api: string, itemId: string) {
    const controller = new AbortController();
    const signal = controller.signal;

    return {
      abort: () => controller.abort(),
      response: new Promise<Response>(() => {}),
      json: async () => {
        const [item, response] = await get<TYPE | Exception>({
          url: `${api}/${itemId}`,
          signal,
        });

        postprocessCall(item!, response!);

        const { data } = await combineGetCalls({
          api,
          itemId,
          signal,
          mainItem: item,
        });

        return data;
      },
      // not needed
      raw: async () => new Promise<Response>(() => {}),
      text: async () => {},
      none: async () => {},
    };
  };
}

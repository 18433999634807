import React, { useContext } from 'react';
import { AddressFieldContext } from '../address-field-context';
import { FormTextField, useFormSelector } from '@eas/common-web';
import { HelpLabel } from '../../../help/help-label';
import { get } from 'lodash';
import { Address } from '../../../../models';

export function UnstructuredAddress() {
  const { name, code } = useContext(AddressFieldContext);

  const value = useFormSelector((data: { [index: string]: Address }) => {
    const address = get(data, name);
    return address?.addressPlace;
  });

  if (!value) {
    return null;
  }

  return (
    <FormTextField
      name="addressPlace"
      disabled={true}
      label={
        <HelpLabel
          label="Nestrukturovaná adresa"
          code={`${code}_NON_STRUCTURED`}
          required={false}
        />
      }
    />
  );
}

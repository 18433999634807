import React from 'react';
import {
  DetailToolbarButtonType,
  DetailActionbarButton,
} from '@eas/common-web';
import { useAddSystemCertificateDialog } from './system-certificates/dialog-actions/add-system-certificate-hook';
import ControlPointIcon from '@material-ui/icons/ControlPoint';

export function SystemsActionbar() {
  const {
    canAddCertificate,
    handleAddCertificate,
  } = useAddSystemCertificateDialog();

  return (
    <>
      {canAddCertificate && (
        <DetailActionbarButton
          startIcon={<ControlPointIcon fontSize="small" />}
          label="Přidat certifikát"
          onClick={handleAddCertificate}
          type={DetailToolbarButtonType.PRIMARY}
        />
      )}
    </>
  );
}

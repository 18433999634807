import React from 'react';
import Typography from '@material-ui/core/Typography';

export function StepFinishContent() {
  return (
    <Typography variant="body1">
      Proces rozdelení provozven byl úspěšně dokončen.
    </Typography>
  );
}

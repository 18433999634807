import React from 'react';
import { FormTextField, FormPanel, FormAutocomplete } from '@eas/common-web';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useDictionaryHydrogeologicalZones } from '../../dict-hydrogeological-zone/dict-hydrogeological-zones-api';
import { HelpLabel } from '../../../components/help/help-label';

export function WaterUserPlaceUndergroundDischargeFields() {
  const hydrogeologicalZones = useDictionaryHydrogeologicalZones();

  return (
    <FormPanel label="Dlouhodobé provozní údaje specifické pro typ MUV">
      <FormTextField
        label={
          <HelpLabel
            label="Zdroj vypouštění"
            code="WATER_USAGE_PLACE_DISCHARGE_SOURCE"
          />
        }
        name="dischargeSource"
      />
      <FormAutocomplete
        label={
          <HelpLabel
            label="Hydrogeologický rajon"
            code="WATER_USAGE_PLACE_HYDROGEOLOGICAL_ZONE"
          />
        }
        name="hydrogeologicalZone"
        source={hydrogeologicalZones}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import { BreadcrumbCtx } from './breadcrumb-ctx';

const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      margin: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    },
  })
);

export function Breadcrumb() {
  const classes = useStyles();

  const { breadcrumbs } = useContext(BreadcrumbCtx);

  return (
    <Breadcrumbs
      separator="›"
      aria-label="breadcrumb"
      classes={{ root: classes.breadcrumbs }}
    >
      {breadcrumbs.map((b) =>
        b.to ? (
          <Link to={b.to} key={b.key}>
            <Typography color="primary">{b.title}</Typography>
          </Link>
        ) : (
          <Typography color="textPrimary" key={b.key}>
            {b.title}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
}

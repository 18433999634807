import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const CREATE: MessageType = {
  SUCCESS: ['Poznámka byla úspěšně vytvořena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při vyváření poznámky.', SnackbarVariant.ERROR],
};

const UPDATE: MessageType = {
  SUCCESS: ['Poznámka byla úspěšně upravena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při úpravě poznámky.', SnackbarVariant.ERROR],
};

const DELETE: MessageType = {
  SUCCESS: ['Poznámka byla úspěšně smazána.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při mazání poznámky.', SnackbarVariant.ERROR],
};

export default { CREATE, UPDATE, DELETE };

import React from 'react';
import { FormCustomField } from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textAdded: {
      lineHeight: '24px',
      padding: '0 12px',
      backgroundColor: `${theme.palette.primary.main}40`,
    },
    textDeleted: {
      lineHeight: '24px',
      padding: '0 12px',
      color: theme.palette.grey[500],
      textDecoration: 'line-through',
      marginRight: 5,
      backgroundColor: `${theme.palette.error.main}40`,
    },
    row: {
      // backgroundColor: theme.palette.primary.light,
    },
    content: {
      display: 'flex',
    },
  })
);

export function CompareText({
  originalValue,
  newValue,
  label,
}: {
  originalValue?: string | number | null;
  newValue?: string | number | null;
  label?: string;
}) {
  const classes = useStyles();
  if (originalValue === newValue) {
    return null;
  } else {
    return (
      <FormCustomField label={label}>
        <Typography variant="body1" style={{ display: 'flex' }}>
          <span className={classes.textDeleted}>{originalValue ?? ''}</span>
          <span className={classes.textAdded}>{newValue ?? ''}</span>
        </Typography>
      </FormCustomField>
    );
  }
}

import React from 'react';
import { ProcessForm } from '../irz-facility-request-change-types';

export function StepFacilityLabel({ allValues }: { allValues?: ProcessForm }) {
  const irzFacility = allValues?.irzFacility;

  return (
    <>
      Provozovna: <b>{irzFacility?.label}</b>
    </>
  );
}

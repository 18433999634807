import React, { useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ProcessActionsProps } from '../../../components/process/process-types';
import {
  useEventCallback,
  useFormSelector,
  FormContext,
} from '@eas/common-web';
import { formatIco } from '../../subject/fields/subjects-utils';
import { ProcessForm } from '../empowerment-requests-types';
import { StepOtherSubjectForm } from './step-other-subject-types';
import { ExitProcessAction } from '../../../components/process/process-actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function StepOtherSubjectActions({
  activeStep,
  handleNext,
  handleBack,
  handleExit,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  const { ico } = useFormSelector((values: StepOtherSubjectForm) => ({
    ico: values?.empowerer?.ico,
  }));
  const { setFieldValue } = useContext(FormContext);

  const handleNextWithCheck = useEventCallback(() => {
    if (typeof ico === 'string') {
      setFieldValue('empowerer.ico', formatIco(ico));
    }

    handleNext();
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithCheck}
          className={classes.button}
          disableElevation
        >
          Další
        </Button>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          disableElevation
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} />
      </div>
    </div>
  );
}

import { useStaticListSource, DictionaryAutocomplete } from '@eas/common-web';
import { SoapExecutionLogStatus } from '../../models';

export function useSoapExecutionStatuses() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: SoapExecutionLogStatus.SUCCESS,
      name: 'Úspěšne vykonáno',
    },
    {
      id: SoapExecutionLogStatus.FAILURE,
      name: 'Chyba',
    },
  ]);
}

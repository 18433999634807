import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  TableFieldToolbarButton,
  TableFieldToolbarProps,
  useFormSelector,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useAddBrandDialog } from './dialog-actions/add-brand-hook';
import { useDeactivateBrandDialog } from './dialog-actions/deactivate-brand-hook';
import { useActivateBrandDialog } from './dialog-actions/activate-brand-hook';
import { BoilerManufacturer } from '../../models';

export const useStyles = makeStyles((theme) => ({
  textButton: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableActions: {
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    // height: 30,
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 16px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  buttonGroup: {
    display: 'flex',
    marginBottom: 8,
    // marginLeft: -5, // offset first icon left padding

    '& button:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.grey['400']}`,
    },
  },
}));

export function BoilerBrandsToolbar({
  selectedIndex,
  setSelectedIndex,
}: TableFieldToolbarProps) {
  const classes = useStyles();

  const { boilerBrands } = useFormSelector((data: BoilerManufacturer) => ({
    boilerBrands: data?.boilerBrands,
  }));

  const { handleAddBrand, canAddBrand, canEditBrand } = useAddBrandDialog({
    boilerBrands,
    selectedIndex,
  });

  const {
    handleDeactivateBrand,
    canDeactivateBrand,
  } = useDeactivateBrandDialog({
    boilerBrands: boilerBrands!,
    selectedIndex: selectedIndex!,
    setSelectedIndex,
  });

  const { handleActivateBrand, canActivateBrand } = useActivateBrandDialog({
    boilerBrands: boilerBrands!,
    selectedIndex: selectedIndex!,
    setSelectedIndex,
  });

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButton
          color="primary"
          variant="contained"
          title="Přidání značky k výrobci"
          disabled={!canAddBrand || selectedIndex !== undefined}
          show={true}
          IconComponent={ControlPointIcon}
          onClick={handleAddBrand}
        >
          <Typography variant="body2">Přidat</Typography>
        </TableFieldToolbarButton>
        <TableFieldToolbarButton
          color="default"
          variant="text"
          title="Editace značky"
          disabled={!canEditBrand || selectedIndex === undefined}
          show={true}
          IconComponent={EditIcon}
          onClick={handleAddBrand}
        >
          <Typography variant="body2">Upravit</Typography>
        </TableFieldToolbarButton>
        <TableFieldToolbarButton
          color="secondary"
          variant="text"
          IconComponent={CloseIcon}
          disabled={!canDeactivateBrand || selectedIndex === undefined}
          show={canDeactivateBrand || selectedIndex === undefined}
          title="Zneplatnění značky"
          onClick={handleDeactivateBrand}
        >
          <Typography variant="body2">Zneplatnit</Typography>
        </TableFieldToolbarButton>
        <TableFieldToolbarButton
          color="primary"
          variant="text"
          IconComponent={RefreshIcon}
          disabled={!canActivateBrand || selectedIndex === undefined}
          show={canActivateBrand}
          title="Obnovení značky"
          onClick={handleActivateBrand}
        >
          <Typography variant="body2">Obnovit</Typography>
        </TableFieldToolbarButton>
      </ButtonGroup>
    </div>
  );
}

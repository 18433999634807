import React from 'react';
import BuildIcon from '@material-ui/icons/Build';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ProtectedPage } from '../../components/protected-page/protected-page';

export function UnderConstruction() {
  return (
    <ProtectedPage>
      <Grid
        container={true}
        alignItems="center"
        justify="center"
        style={{ height: '100%' }}
      >
        <Grid item={true} xs={6}>
          <Box>
            <Typography align="center" style={{ fontSize: '6em' }}>
              <BuildIcon fontSize="inherit" />
            </Typography>
            <Typography align="center" variant="h1">
              <Box>Na tomto ještě pracujeme!</Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ProtectedPage>
  );
}

import React from 'react';
import { useSubjectRegistrationTypes } from '../subject-requests-api';
import { ProcessForm } from '../subject-requests-types';

export function StepSubjectLabel({ allValues }: { allValues?: ProcessForm }) {
  const subjectRegistrationTypes = useSubjectRegistrationTypes();

  const typeLabel = subjectRegistrationTypes.items.find(
    (role) => role.id === allValues?.type
  );

  return <>{typeLabel?.name ? `${typeLabel.name}:` : 'Subjekt'} </>;
}

import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import {
  TableToolbarButton,
  useEventCallback,
  NavigationContext,
  EvidenceStateAction,
  EvidenceContext,
  TableColumn,
  UserContext,
} from '@eas/common-web';
import {
  PublicList,
  PublicListAccessType,
  PublicListDataSource,
  PublicListState,
  PublicListParams,
  DynamicFieldType,
} from '../../models/public-list';
import { compact } from 'lodash';
import { Me } from '../../models';
import { Permission } from '../../enums';

export function publicListToolbarButtonFactory({
  name,
  dataSource,
}: {
  name: string;
  dataSource: PublicListDataSource;
}) {
  return function PublicListToolbarButton() {
    const { navigate } = useContext(NavigationContext);
    const { tableRef, tableSource } = useContext(EvidenceContext);
    const { hasPermission } = useContext<UserContext<Me>>(UserContext);

    const createPublicList = useEventCallback(() => {
      const table = tableRef.current;
      if (table == null) {
        return;
      }

      const params: PublicListParams = {
        columns: compact(
          table.columnsState.map((state, i) => {
            if (state.visible === false) {
              return undefined;
            }

            const column = table.columns[i];

            return {
              datakey: column.datakey,
              displaykey: column.displaykey,
              name: column.name,
              width: state.width,
              sortable: column.sortable,
              filterable: column.filterable,
              filterGroup: column.filterGroup,
              type: translateColumnName(column),
              valueMapperName:
                column.valueMapper?.displayName ?? column.valueMapper?.name,
              valueMapperData: (column.valueMapper as any)?.data,
            };
          })
        ),
        dataParams: tableSource.getParams(),
      };

      const data: PublicList = {
        id: uuidv4(),
        name,
        accessType: PublicListAccessType.PUBLIC,
        allowedRoles: [],
        dataSource,
        state: PublicListState.CONCEPT,
        params: JSON.stringify(params),
      };

      navigate('/crzp/verejne-seznamy', false, {
        action: EvidenceStateAction.NEW_ITEM,
        data,
      });
    });

    const canCreatePublicList = hasPermission(
      Permission.PublicList.PUBLIC_LIST_CREATE
    );

    return canCreatePublicList ? (
      <>
        <TableToolbarButton
          label={<PlaylistAddIcon />}
          onClick={createPublicList}
          tooltip="Vytvořit veřejný seznam"
        />
      </>
    ) : null;
  };

  function translateColumnName(column: TableColumn<any, any>) {
    const cellComponentName =
      column.CellComponent.displayName ?? column.CellComponent.name;

    switch (cellComponentName) {
      case 'BooleanCell':
        return DynamicFieldType.BOOLEAN;
      case 'NumberCell':
        return DynamicFieldType.NUMBER;
      case 'DateCell':
        return DynamicFieldType.DATE;
      case 'DateTimeCell':
        return DynamicFieldType.DATETIME;
      case 'TimeCell':
        return DynamicFieldType.TIME;
      case 'TextCell':
      default:
        return DynamicFieldType.TEXT;
    }
  }
}

import React from 'react';
import { Address } from '../../models';
import { TableFieldCellProps, TableFieldCells } from '@eas/common-web';
import { useAddressLabel } from '../../components/form/address-field/hooks/address-label-hook';

export function AddressCell(props: TableFieldCellProps<any>) {
  const address: Address = props.rowValue?.address;
  const addressLabel = useAddressLabel(address);

  return <TableFieldCells.TextCell {...props} value={addressLabel} />;
}

import React, { useEffect, useContext } from 'react';
import { uniqBy, differenceBy } from 'lodash';
import {
  FormSelect,
  FormPanel,
  FormTextField,
  FormTextArea,
  FormDateField,
  FormAutocomplete,
  useFormSelector,
  DetailContext,
  DetailMode,
  FormContext,
  FormDecimalField2,
} from '@eas/common-web';
import { AuthoredFields } from '../../components/evidence/authored-evidence/authored-fields';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { HistoryEvidenceFieldsProps } from '../../components/evidence/history-evidence/history-types';
import { HelpLabel } from '../../components/help/help-label';
import { useOvss } from '../dict-ovss/dict-ovss-api';
import { useDeposits } from '../dict-deposit/dict-deposits-api';
import { useMineralGroups } from '../dict-mineral-group/dict-mineral-groups-api';
import {
  useAreaTypes,
  useChluStates,
  useChluRuianStates,
} from './protected-deposit-areas-api';
import { ProtectedDepositAreasMap } from './map/protected-deposit-areas-map';
import {
  ProtectedDepositArea,
  AreaType,
  ChluRuianState,
  ChluState,
  DictionaryDeposit,
} from '../../models';

const depositLabelMapper = (value: DictionaryDeposit) =>
  `${value.code} - ${value.name}`;

export function ProtectedDepositAreasFields(props: HistoryEvidenceFieldsProps) {
  const { mode, formRef } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);
  const {
    type,
    deposits,
    mineralGroups,
    inRuian,
    ruianState,
    state,
  } = useFormSelector((data: ProtectedDepositArea) => ({
    type: data.type,
    deposits: data.deposits,
    mineralGroups: data.mineralGroups,
    inRuian: data.inRuian,
    ruianState: data.ruianState,
    state: data.state,
  }));

  const types = useAreaTypes();
  const states = useChluStates();
  const ruianStates = useChluRuianStates();
  const ovss = useOvss();
  const depositsSource = useDeposits();
  const mineralGroupsSource = useMineralGroups();

  useEffect(() => {
    if (
      mode === DetailMode.EDIT &&
      state === ChluState.APPROVED &&
      ruianState === ChluRuianState.ACCEPTED &&
      inRuian
    ) {
      setFieldValue('referenceNumber', undefined);
      setFieldValue('dateIssued', undefined);
      setFieldValue('dateLegalForce', undefined);
    }
  }, [mode]);

  // If deposit is added, mineral groups are extended by mineral groups of that deposit
  const handleDepositsChange = () => {
    const formDeposits = formRef?.getFieldValues().deposits;
    const newlyAddedDeposits = differenceBy<
      DictionaryDeposit,
      DictionaryDeposit
    >(formDeposits ?? [], deposits ?? [], 'id');

    let groups = mineralGroups ?? [];
    newlyAddedDeposits.forEach((deposit) => {
      groups = [...groups, ...(deposit.mineralGroups ?? [])];
    });

    setFieldValue('mineralGroups', uniqBy(groups, 'id'));
  };

  return (
    <>
      <FormPanel label="Území">
        <FormSelect
          name="type"
          label={
            <HelpLabel
              label="Typ"
              code="PROTECTED_DEPOSIT_AREAS_TYPE"
              required
            />
          }
          source={types}
          disabled={mode !== DetailMode.NEW}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        <FormTextField
          name="name"
          label={
            <HelpLabel
              label="Název"
              code="PROTECTED_DEPOSIT_AREAS_NAME"
              required
            />
          }
        />
        <FormTextField
          name="number"
          label={
            <HelpLabel
              label="Číslo CHLÚ"
              code="PROTECTED_DEPOSIT_AREAS_NUMBER"
            />
          }
          disabled={true}
        />
        <FormSelect
          name="state"
          label={
            <HelpLabel label="Stav" code="PROTECTED_DEPOSIT_AREAS_STATE" />
          }
          source={states}
          disabled={true}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        <FormSelect
          name="ruianState"
          label={
            <HelpLabel
              label="Stav RÚIAN"
              code="PROTECTED_DEPOSIT_AREAS_RUIAN_STATE"
            />
          }
          source={ruianStates}
          disabled={true}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        <FormTextField
          name="referenceNumber"
          label={
            <HelpLabel
              label="Číslo jednací"
              code="PROTECTED_DEPOSIT_AREAS_REFERENCE_NUMBER"
              required
            />
          }
        />
        <FormTextArea
          name="note"
          label={
            <HelpLabel label="Poznámka" code="PROTECTED_DEPOSIT_AREAS_NOTE" />
          }
        />
        <FormTextArea
          name="ruianDescription"
          label={
            <HelpLabel
              label="Popis pro RÚIAN"
              code="PROTECTED_DEPOSIT_AREAS_RUIAN_DESCRIPTION"
            />
          }
        />
        <FormDecimalField2
          name="area"
          label={
            <HelpLabel
              label="Plocha (km2)"
              code="PROTECTED_DEPOSIT_AREAS_REFERENCE_AREA"
              required
            />
          }
          decimalDigits={6}
        />
        <FormDateField
          name="dateIssued"
          label={
            <HelpLabel
              label="Datum vydání rozhodnutí"
              code="PROTECTED_DEPOSIT_AREAS_DATE_ISSUED"
              required
            />
          }
        />
        <FormDateField
          name="dateLegalForce"
          label={
            <HelpLabel
              label="Datum nabytí právní moci rozhodnutí"
              code="PROTECTED_DEPOSIT_AREAS_DATE_LEGAL_FORCE"
              required
            />
          }
        />
        <FormTextArea
          name="coordinates"
          label={
            <HelpLabel
              label="Souřadnice"
              code="PROTECTED_DEPOSIT_AREAS_COORDINATES"
              required
            />
          }
          disabled={true}
        />
        <FormAutocomplete
          name="ovss"
          label={
            <HelpLabel
              label="Odbor výkonu státní správy"
              code="PROTECTED_DEPOSIT_AREAS_OVSS"
            />
          }
          source={ovss}
        />
      </FormPanel>
      <FormPanel label="Poloha" expandable={true} defaultExpanded={true}>
        <ProtectedDepositAreasMap />
      </FormPanel>
      {type === AreaType.CHLU && (
        <FormPanel label="Nerosty, ložiska">
          <FormTextArea
            name="minerals"
            label={
              <HelpLabel
                label="Nerosty ložiska"
                code="PROTECTED_DEPOSIT_AREAS_MINERALS"
                required={type === AreaType.CHLU}
              />
            }
          />
          <FormAutocomplete
            name="deposits"
            label={
              <HelpLabel
                label="Ložiska"
                code="PROTECTED_DEPOSIT_AREAS_DEPOSITS"
                required={type === AreaType.CHLU}
              />
            }
            labelMapper={depositLabelMapper}
            multiple={true}
            source={depositsSource}
            notifyChange={handleDepositsChange}
          />
          <FormAutocomplete
            name="mineralGroups"
            label={
              <HelpLabel
                label="Skupiny nerostů"
                code="PROTECTED_DEPOSIT_AREAS_MINERAL_GROUPS"
                required={type === AreaType.CHLU}
              />
            }
            multiple={true}
            source={mineralGroupsSource}
          />
        </FormPanel>
      )}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <AuthoredFields FieldsComponent={DatedFields} />
      </FormPanel>
    </>
  );
}

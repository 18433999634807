import { AuthoredObject } from './authored-object';
import { DomainObjectAutocomplete } from './domain-object';
import { SystemAutocomplete } from './system';

/**
 * Typ role
 */
export enum RoleType {
  /**
   * Vztahová role
   */
  RELATIONSHIP_ROLE = 'RELATIONSHIP_ROLE',

  /**
   * Bezpečnostní role
   */
  SECURITY_ROLE = 'SECURITY_ROLE',

  /**
   * Uživatelská role
   */
  USER_ROLE = 'USER_ROLE',

  /**
   * Subjektová role
   */
  SUBJECT_ROLE = 'SUBJECT_ROLE',

  /**
   * Systémová role
   */
  SYSTEM_ROLE = 'SYSTEM_ROLE',
}

/**
 * Vztah pro vztahovou roli
 */
export enum RelationshipType {
  /**
   * Správce subjektu
   */
  ADMINISTRATOR = 'ADMINISTRATOR',

  /**
   * Běžný uživatel subjektu
   */
  USER = 'USER',
}

/**
 * Atributy RPP (Registr práv a povinností)
 */
export interface RPP {
  /**
   * Činnostní role
   * <p>
   * např. "CR8637"
   */
  activityRole?: string;

  /**
   * Agenda
   * <p>
   * např. "A1186"
   */
  agenda?: string;

  /**
   * Zákonná reference
   * <p>
   * např. "§ 78-2-b či 76-1-d zákona č. 185/2001 Sb., o odpadech."
   */
  legalReference?: string;
}

export interface RoleAutocomplete extends DomainObjectAutocomplete {
  /**
   * Typ role
   */
  type?: RoleType;

  /**
   * Popisek
   */
  label?: string;

  /**
   * Systém, který eviduje danou roli
   */
  registeredFor?: SystemAutocomplete;
}

/**
 * Role
 */
export interface Role extends AuthoredObject {
  /**
   * Název
   */
  name?: string;

  /**
   * Popisek
   */
  label?: string;

  /**
   * Kód
   */
  code?: string;

  /**
   * Popis
   */
  description?: string;

  /**
   * Systém, který eviduje danou roli.
   */
  registeredFor?: SystemAutocomplete;

  /**
   * Typ role
   */
  type?: RoleType;

  /**
   * Oprávnění
   */
  permissions?: string[];

  /**
   * Příznak platnosti
   */
  active?: boolean;

  /**
   * Role, které mohou přiřazovat tuto roli
   */
  assignerRoles?: Role[];

  /**
   * Role, které mohou být přiřazeny touto rolí
   */
  assignesRoles?: Role[];

  /**
   * Může přidělit jen admin
   */
  onlyAdminCanAssign?: boolean;

  /**
   * Příznak, zda-li je možné instanci role nastavit detaily SEPNO
   */
  allowSepnoDetails?: boolean;
}

/**
 * Vztahová role
 */
export interface RelationshipRole extends Role {
  /**
   * Vztah
   */
  relationship: RelationshipType;

  /**
   * Typ role
   */
  type: RoleType.RELATIONSHIP_ROLE;
}

/**
 * Bezpečnostní role
 */
export interface SecurityRole extends Role {
  /**
   * Agregované role
   */
  includedRoles?: SecurityRole[];

  /**
   * Atributy RPP (Registr práv a povinností)
   */
  rppAttributes?: RPP;

  /**
   * Typ role
   */
  type: RoleType.SECURITY_ROLE;

  /**
   * Příznak, zda-li je nutný uživatelský souhlas po přiřazení role
   */
  userConsentRequired?: boolean;

  /**
   * Příznak, zda-li je možné o tuto roli zažádat
   */
  allowedToRequest?: boolean;
}

/**
 * Subjektová role
 */
export interface SubjectRole extends Role {
  /**
   * Typ role
   */
  type: RoleType.SUBJECT_ROLE;
}

/**
 * Systémová role
 */
export interface SystemRole extends Role {
  /**
   * Typ role
   */
  type: RoleType.SYSTEM_ROLE;
}

/**
 * Uživatelská role
 */
export interface UserRole extends Role {
  /**
   * Nadřazená subjektová role
   */
  parentSubjectRole?: SubjectRole;

  /**
   * Atributy RPP (Registr práv a povinností)
   */
  rppAttributes?: RPP;

  /**
   * Typ role
   */
  type: RoleType.USER_ROLE;

  /**
   * Příznak, zda-li se jedná o roli pro výkon státní správy
   */
  stateAdministrationRole?: boolean;

  /**
   * Příznak automatického priřazení role k správci subjektu
   * pokud je true, role se automaticky přiřadí k uživateli při jeho přiřazení ako správce subjektu
   */
  autoAssignToSubjectAdmin?: boolean;

  /**
   * Příznak, zda-li je nutný uživatelský souhlas po přiřazení role
   */
  userConsentRequired?: boolean;

  /**
   * Příznak, zda-li je možné o tuto roli zažádat
   */
  allowedToRequest?: boolean;
}

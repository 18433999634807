import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryProcessingPurpose } from '../../models';
import { useValidationSchema } from './dict-processing-purposes-schema';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { DictProcessingPurposeFields } from './dict-processing-purpose-fields';
import { useColumns } from './dict-processing-purpose-columns';

export function DictionaryProcessingPurposes() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryProcessingPurpose>({
    identifier: PageKey.DICTIONARY_PROCESSING_PURPOSE,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_PROCESSING_PURPOSE,
    },
    tableProps: {
      tableName: 'Účely zpracování',
      reportTag: ExportTags.DICTIONARY_PROCESSING_PURPOSE,
      defaultSorts: [],
      columns: useColumns(),
    },
    detailProps: {
      entityName: EntityName.PROCESSING_PURPOSE,
      codePrefix: 'PROCESSING_PURPOSE',
      validationSchema,
      GeneralFieldsComponent: DictProcessingPurposeFields,
      toolbarProps: {
        title: 'Účel zpracování',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

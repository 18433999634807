/**
 * CHLU (protected deposit areas) permissions
 */
export enum PermissionChlu {
  /**
   * Přehled CHLÚ
   */
  CHLU_LIST = 'CHLU_LIST',

  /**
   * Detail CHLÚ
   */
  CHLU_DETAIL = 'CHLU_DETAIL',

  /**
   * Vytvoření CHLÚ
   */
  CHLU_CREATE = 'CHLU_CREATE',

  /**
   * Editace CHLÚ
   */
  CHLU_UPDATE = 'CHLU_UPDATE',

  /**
   * Smazání CHLÚ
   */
  CHLU_DELETE = 'CHLU_DELETE',

  /**
   * Odeslání CHLÚ do RÚIAN
   */
  CHLU_PUBLISH = 'CHLU_PUBLISH',

  /**
   * Zrušení CHLÚ
   */
  CHLU_CANCEL = 'CHLU_CANCEL',

  /**
   * Zahození CHLÚ
   */
  CHLU_DISCARD = 'CHLU_DISCARD',
}

import React, { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { MenuItem } from './items';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 300,
    },
    primaryListItem: {
      backgroundColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    defaultText: {
      color: 'black',
      textTransform: 'initial',
    },
    primaryText: {
      color: 'white',
      textTransform: 'initial',
    },
  })
);

export function HeaderMobileMenu({ items }: { items: MenuItem[] }) {
  const classes = useStyles();

  const history = useHistory();

  /**
   *
   */
  const [openDrawer, setOpenDrawer] = useState(false);

  /**
   *
   */
  const handleNavigate = useCallback(
    (href: string, external?: boolean) => {
      if (external) {
        window.location.replace(href);
      } else {
        setOpenDrawer(false);

        history.push(href);
      }
    },
    [history]
  );

  return (
    <>
      <IconButton onClick={() => setOpenDrawer(true)}>
        <MenuIcon />
      </IconButton>

      <Drawer
        variant="temporary"
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <div>
          <IconButton onClick={() => setOpenDrawer(false)}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <List component="nav" dense={true} className={classes.list}>
          {items.map((item) => (
            <ListItem
              button
              key={item.label}
              className={clsx({
                [classes.primaryListItem]: item.primary,
              })}
              onClick={() => handleNavigate(item.href, item.external)}
            >
              <Link to={item.href}>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    variant: 'body1',
                  }}
                  className={clsx({
                    [classes.primaryText]: item.primary,
                    [classes.defaultText]: !item.primary,
                  })}
                />
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}

import React, { useContext } from 'react';
import {
  exportRequestsFactory,
  Evidence2,
  ExportContext,
  ExportRequest,
  EmptyComponent,
} from '@eas/common-web';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { Permission } from '../../enums';

export function DictionaryExportRequests() {
  const { url } = useContext(ExportContext);

  const permissions = usePermission({
    options: {
      NEW: [Permission.Export.EXPORT_REQUEST_LIST],
      EDIT: [Permission.Export.EXPORT_ADMIN],
      REMOVE: [Permission.Export.EXPORT_ADMIN],
      CHECK: [
        Permission.Export.EXPORT_REQUEST_LIST,
        Permission.Export.EXPORT_ADMIN,
      ],
      SAVE: [
        Permission.Export.EXPORT_REQUEST_LIST,
        Permission.Export.EXPORT_ADMIN,
      ],
      CLOSE: [
        Permission.Export.EXPORT_REQUEST_LIST,
        Permission.Export.EXPORT_ADMIN,
      ],
    },
  });

  const evidence = useAuthoredEvidence<ExportRequest>({
    identifier: 'EXPORT_REQUESTS',
    apiProps: {
      url: `${url}/requests`,
    },
    tableProps: {
      columns: exportRequestsFactory.useColumns(),
      tableName: 'Fronta exportu',
    },
    detailProps: {
      toolbarProps: {
        title: 'Záznam',
        ActionBar: EmptyComponent,
        showBtn: permissions,
      },
      FieldsComponent: exportRequestsFactory.Fields,
    },
  });

  return <Evidence2 {...evidence} />;
}

import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryWaterTreatmentMethod } from '../../models';
import { useValidationSchema } from './dict-water-treatment-methods-schema';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';

export function DictionaryWaterTreatmentMethods() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<DictionaryWaterTreatmentMethod>({
    options: {
      NEW: [Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_CREATE],
      EDIT: [Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_UPDATE],
      REMOVE: [Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_DELETE],
      CHECK: [
        Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_CREATE,
        Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_UPDATE,
      ],
      SAVE: [
        Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_CREATE,
        Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_UPDATE,
      ],
      CLOSE: [
        Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_CREATE,
        Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_UPDATE,
      ],
    },
  });

  const evidence = useDictionaryEvidence<DictionaryWaterTreatmentMethod>(
    {
      identifier: PageKey.DICTIONARY_WATER_TREATMENT_METHODS,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_WATER_TREATMENT_METHODS,
      },
      tableProps: {
        tableName: 'Způsoby úpravy vody',
        reportTag: ExportTags.DICTIONARY_WATER_TREATMENT_METHODS,
        defaultSorts: [],
      },
      detailProps: {
        entityName: EntityName.WATER_TREATMENT_METHOD,
        codePrefix: 'WATER_TREATMENT_METHOD',
        validationSchema,
        toolbarProps: {
          title: 'Způsob úpravy vody',
          showBtn: permissions,
        },
      },
    },
    Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_INVALIDATE,
    Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_REVALIDATE
  );

  return <Evidence2 {...evidence} />;
}

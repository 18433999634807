import React from 'react';
import { FormAutocomplete, FormTextField } from '@eas/common-web';
import { useDictionaryLegalForms } from '../../../dict-legal-form/dict-legal-forms-api';
import { dictionaryLabelMapper } from '../../../../components/form/mappers/label-mappers';
import { HelpLabel } from '../../../../components/help/help-label';

export function LegalEntityWithoutIcoBasicFields({
  prefix,
  codePrefix = 'SUBJECT',
  disabled,
  hideUid = false,
}: {
  prefix?: string;
  codePrefix?: string;
  disabled?: boolean;
  /**
   * UID is hidden in registration, because UID is generated AFTER approved registration
   */
  hideUid?: boolean;
}) {
  const names = {
    artificialId: prefix ? `${prefix}.artificialId` : 'artificialId',
    name: prefix ? `${prefix}.name` : 'name',
    legalForm: prefix ? `${prefix}.legalForm` : 'legalForm',
  };

  const legalForms = useDictionaryLegalForms();

  return (
    <>
      <FormAutocomplete
        name={names.legalForm}
        source={legalForms}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
        label={
          <HelpLabel label="Právní forma" code={`${codePrefix}_LEGAL_FORM`} />
        }
        disabled={disabled}
      />
      {!hideUid && (
        <FormTextField
          name={names.artificialId}
          label={<HelpLabel label="UID" code={`${codePrefix}_UID`} />} // Formát: ID_xxxxxx
          disabled
        />
      )}
      <FormTextField
        name={names.name}
        label={<HelpLabel label="Název" code={`${codePrefix}_NAME`} required />} // Povinné pole
        disabled={disabled}
      />
    </>
  );
}

import React, { useEffect, useState, useContext } from 'react';
import {
  useFormSelector,
  FormPanel,
  FormAutocomplete,
  FormContext,
  abortableFetch,
  ApiFilterOperation,
  AutocompleteSource,
} from '@eas/common-web';
import { dictionaryLabelMapper } from '../../mappers/label-mappers';
import { useAddresses } from '../address-field-api';
import { HelpLabel } from '../../../help/help-label';
import { useAbortableFetch } from '../../../../utils/abortable-fetch';
import { EvidenceAPI } from '../../../../enums';

export function ROSAddressPanel({ operatorId }: { operatorId?: string }) {
  /**
   * Ctx stuff. This component is possible to use only inside of FormContext.
   */
  const { setFieldValue } = useContext(FormContext);

  /**
   * If no ROS addresses is enable to fill, hide autocomplete.
   */
  const [isROShidden, hideROS] = useState(true);

  /**
   * Autocomplete source.
   */
  const ROSaddresses = useAddresses(operatorId!);

  /**
   * Select picked ROSaddress from formik.
   */
  const { ROSaddress } = useFormSelector((data: { ROSaddress: any }) => ({
    ROSaddress: data?.ROSaddress,
  }));

  const getROSaddresses = useAbortableFetch<AutocompleteSource<any>>(
    async (fetch) => {
      fetch = abortableFetch(
        `${EvidenceAPI.SUBJECTS}/establishment/autocomplete`,
        {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({
            filters: [
              {
                field: 'subject.id',
                operation: ApiFilterOperation.EQ,
                value: operatorId,
              },
            ],
          }),
        }
      );

      const data = await fetch.json();

      return data;
    }
  );

  /**
   * Load ROS addresses on mount.
   */
  useEffect(() => {
    const init = async () => {
      const response = await getROSaddresses?.();

      if (response?.count && response.count > 0) {
        hideROS(false);
      } else {
        hideROS(true);
      }
    };

    init();
    // ROSaddresses.loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Update ROS visibility based on available addresses.
   */
  /*
  useEffect(() => {
    if (ROSaddresses.count > 0) {
      hideROS(false);
    } else {
      hideROS(true);
    }
  }, [ROSaddresses.count]);
  */

  /**
   * Prefill address with selected ROS address.
   */
  useEffect(() => {
    if (ROSaddress?.address) {
      setFieldValue('address', ROSaddress.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ROSaddress]);

  return (
    <>
      {!isROShidden && (
        <FormPanel label="ROS adresa">
          <FormAutocomplete
            name="ROSaddress"
            label={<HelpLabel label="ROS adresa" code="ROS_ADDRESS" />}
            source={ROSaddresses}
            labelMapper={dictionaryLabelMapper}
            tooltipMapper={dictionaryLabelMapper}
          />
        </FormPanel>
      )}
    </>
  );
}

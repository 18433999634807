import { useMemo } from 'react';

import { StepTypeContent } from './step-type/step-type-content';
import { StepTypeLabel } from './step-type/step-type-label';
import { stepTypeSchema } from './step-type/step-type-schema';

import { StepInfoLabel } from './step-info/step-info-label';
import { StepInfoContent } from './step-info/step-info-content';
import { stepInfoSchema } from './step-info/step-info-schema';

import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';

import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepFinishActions } from './step-finish/step-finish-actions';

export function useRoleRequests() {
  const steps = useMemo(
    () => [
      {
        Label: StepTypeLabel,
        Content: StepTypeContent,
        validationSchema: stepTypeSchema,
      },
      {
        Label: StepInfoLabel,
        Content: StepInfoContent,
        validationSchema: stepInfoSchema,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    []
  );

  return { steps };
}

import React from 'react';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { DetailToolbarButtonMenuItem } from '@eas/common-web';
import { useRedirect } from './actions/redirect-hook';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import { DetailToolbarButtonMenu } from '../../components/evidence/evidence/detail-toolbar-button-menu';

type IconType = OverridableComponent<
  SvgIconTypeMap<Record<string, unknown>, 'svg'>
>;

function useToolbarItems() {
  /**
   * Array of OVZ ACTIONS.
   */
  const actionItems: DetailToolbarButtonMenuItem[] = [];

  const addAction = (label: string, Icon: IconType, onClick: () => void) =>
    actionItems.push({
      label,
      Icon: <Icon fontSize="small" htmlColor="black" />,
      onClick,
    });

  /**
   * Redirect actions.
   */
  const {
    handleAllRequests,
    handleNewRegistration,
    showAllRequestsButton,
    showNewRegistrationButton,
  } = useRedirect();

  if (showNewRegistrationButton) {
    addAction('Nová registrace provozovny', PlusOneIcon, handleNewRegistration);
  }
  if (showAllRequestsButton) {
    addAction('Všechny nabídky', ListAltIcon, handleAllRequests);
  }

  return {
    actionItems,
  };
}

export function OvzFacilitiesToolbar() {
  const { actionItems } = useToolbarItems();
  return (
    <>
      <DetailToolbarButtonMenu label="Otevřít" items={actionItems} />
    </>
  );
}

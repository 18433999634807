import { useAutocompleteSource } from '@eas/common-web';
import { SystemAutocomplete } from '../../models';
import { EvidenceAPI } from '../../enums';

export function useSystems() {
  return useAutocompleteSource<SystemAutocomplete>({
    url: `${EvidenceAPI.SYSTEMS}/autocomplete`,
    params: {
      sort: [{ type: 'FIELD', field: 'crzp', order: 'DESC' }],
    },
  });
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useTypes } from './outages-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const types = useTypes();

  return [
    {
      key: 'type',
      label: 'Typ',
      source: types,
    },
    {
      key: 'startTime',
      label: 'Začátek',
    },
    {
      key: 'endTime',
      label: 'Konec',
    },
    {
      key: 'reason',
      label: 'Důvod',
    },
    {
      key: 'notificationText',
      label: 'Text notifikace',
    },
    {
      key: 'sendNotification',
      label: 'Odeslat notifikaci',
    },
    {
      key: 'processed',
      label: 'Zpracováno',
    },
  ];
}

import { useContext, useRef } from 'react';
import {
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailContext,
  abortableFetch,
  UserContext,
  PromptContext,
  usePrompts,
} from '@eas/common-web';
import { unstable_batchedUpdates } from 'react-dom';
import { EvidenceAPI, Permission, Messages } from '../../../enums';
import { StringDecoder } from 'string_decoder';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call
 *
 * @param id
 * @param values
 */
export function callApi(id: StringDecoder) {
  return abortableFetch(`${EvidenceAPI.BOILER_PERMISSIONS}/${id}/active`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
}

/**
 * Obnovení platnosti oprávnění OZO
 */
const PROMPT_KEY = 'ACTIVATE';

export function useActivateDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Obnovení platnosti oprávnění OZO',
      dialogText: 'Skutečně chcete obnovit platnost oprávnění OZO?',
      dialogWidth: 600,
    },
  ]);

  const handleActivate = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callApi(source.data!.id);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.OzoPermission.ACTIVATE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.OzoPermission.ACTIVATE,
              err.code
            );

            showSnackbar(...message);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showActivateButton =
    (hasPermission(Permission.Ozo.OZO_PERMISSION_UPDATE_ALL) ||
      hasPermission(Permission.Ozo.OZO_PERMISSION_UPDATE_OWN, {
        searchInSubjectPerms: true,
      })) &&
    !source.data?.active;

  return {
    handleActivate,
    showActivateButton,
  };
}

import { isEqual, isEqualWith } from 'lodash';
import { IrzFacility } from '../../models';

function customizer(objValue: IrzFacility, othValue: IrzFacility) {
  if (objValue?.name !== othValue?.name) {
    return false;
  }

  if (objValue?.webSite !== othValue?.webSite) {
    return false;
  }

  if (objValue?.publicEmail !== othValue?.publicEmail) {
    return false;
  }

  if (objValue?.publicPhoneNumber !== othValue?.publicPhoneNumber) {
    return false;
  }

  if (!isEqual(objValue?.wgs84Coordinates, othValue?.wgs84Coordinates)) {
    return false;
  }

  if (objValue?.nace?.id !== othValue?.nace?.id) {
    return false;
  }

  if (objValue?.czNace?.id !== othValue?.czNace?.id) {
    return false;
  }

  if (
    !isEqual(
      objValue?.integratedPermission?.ippcPids,
      othValue?.integratedPermission?.ippcPids
    )
  ) {
    return false;
  }

  if (
    objValue?.emissionsTradingSystem?.tradesInEmissions !==
    othValue?.emissionsTradingSystem?.tradesInEmissions
  ) {
    return false;
  }

  if (
    !isEqual(
      objValue?.emissionsTradingSystem?.ids,
      othValue?.emissionsTradingSystem?.ids
    )
  ) {
    return false;
  }

  if (
    !isEqual(
      objValue?.competentControlAuthorities,
      othValue?.competentControlAuthorities
    )
  ) {
    return false;
  }

  if (
    objValue?.mainIrzActivity?.activity?.id !==
    othValue?.mainIrzActivity?.activity?.id
  ) {
    return false;
  }

  if (
    objValue?.mainIrzActivity?.facilityCount !==
    othValue?.mainIrzActivity?.facilityCount
  ) {
    return false;
  }

  if (
    !isEqual(objValue?.secondaryIrzActivities, othValue?.secondaryIrzActivities)
  ) {
    return false;
  }

  if (
    objValue?.mainEprtrActivity?.activity?.id !==
    othValue?.mainEprtrActivity?.activity?.id
  ) {
    return false;
  }

  if (
    objValue?.mainEprtrActivity?.facilityCount !==
    othValue?.mainEprtrActivity?.facilityCount
  ) {
    return false;
  }

  if (
    !isEqual(
      objValue?.secondaryEprtrActivities,
      othValue?.secondaryEprtrActivities
    )
  ) {
    return false;
  }

  if (objValue?.ippcActivity?.id !== othValue?.ippcActivity?.id) {
    return false;
  }

  if (objValue?.address?.country?.id !== othValue?.address?.country?.id) {
    return false;
  }

  if (
    objValue?.address?.regionRuian?.id !== othValue?.address?.regionRuian?.id
  ) {
    return false;
  }

  if (objValue?.address?.region !== othValue?.address?.region) {
    return false;
  }

  if (
    objValue?.address?.districtRuian?.id !==
    othValue?.address?.districtRuian?.id
  ) {
    return false;
  }

  if (objValue?.address?.district !== othValue?.address?.district) {
    return false;
  }

  if (
    objValue?.address?.municipalityRuian?.id !==
    othValue?.address?.municipalityRuian?.id
  ) {
    return false;
  }

  if (objValue?.address?.municipality !== othValue?.address?.municipality) {
    return false;
  }

  if (
    objValue?.address?.cityDistrictRuian?.id !==
    othValue?.address?.cityDistrictRuian?.id
  ) {
    return false;
  }

  if (objValue?.address?.cityDistrict !== othValue?.address?.cityDistrict) {
    return false;
  }

  if (
    objValue?.address?.municipalityPartRuian?.id !==
    othValue?.address?.municipalityPartRuian?.id
  ) {
    return false;
  }

  if (
    objValue?.address?.municipalityPart !== othValue?.address?.municipalityPart
  ) {
    return false;
  }

  if (
    objValue?.address?.streetRuian?.id !== othValue?.address?.streetRuian?.id
  ) {
    return false;
  }

  if (objValue?.address?.street !== othValue?.address?.street) {
    return false;
  }

  if (
    objValue?.address?.addressPlaceRuian?.id !==
    othValue?.address?.addressPlaceRuian?.id
  ) {
    return false;
  }

  if (objValue?.address?.houseNumber !== othValue?.address?.houseNumber) {
    return false;
  }

  if (
    objValue?.address?.orientationNumber !==
    othValue?.address?.orientationNumber
  ) {
    return false;
  }

  if (
    objValue?.address?.orientationNumberMark !==
    othValue?.address?.orientationNumberMark
  ) {
    return false;
  }

  if (objValue?.address?.zip !== othValue?.address?.zip) {
    return false;
  }

  return true;
}

export function isIrzEqual(
  objValue: Partial<IrzFacility> | undefined,
  othValue: Partial<IrzFacility> | undefined
) {
  return isEqualWith(objValue, othValue, customizer);
}

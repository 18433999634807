import { TableColumn, TableCells, TableFilterCells } from '@eas/common-web';
import { ReportAccessRule } from '../../../models/reporting';
import { useReportDefinitionsAutocomplete } from '../reporting-api';

export function useColumns(): TableColumn<ReportAccessRule>[] {
  return [
    {
      datakey: 'definitionId',
      name: 'Report',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useReportDefinitionsAutocomplete
      ),
      valueMapper: TableCells.useSelectCellFactory(
        useReportDefinitionsAutocomplete
      ),
      sortable: false,
      filterable: false,
    },
  ];
}

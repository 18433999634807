import React, { useContext } from 'react';
import { useValidationSchema } from './translations-schema';
import { Translation } from './translations-types';
import { useColumns } from './translations-columns';
import { TranslationsFields } from './translations-fields';
import {
  Evidence2,
  abortableFetch,
  FileRef,
  LocaleContext,
} from '@eas/common-web';
import { EvidenceAPI, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import {
  getItemFactory,
  createItemFactory,
  updateItemFactory,
  deleteItemFactory,
} from '../../utils/custom-crud';
import { combineGetCalls } from './translations-api';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { TranslationsActionbar } from './translations-actionbar';

function callUploadFile(fileUrl: string, file: File) {
  const formData = new FormData();
  formData.append('file', file, file.name);

  return abortableFetch(`${fileUrl}`, {
    method: 'POST',
    body: formData,
  });
}

export function Translations() {
  const validationSchema = useValidationSchema();
  const { refresh } = useContext(LocaleContext);

  const postprocessCall = async () => {
    await refresh();
  };

  const evidence = useDatedEvidence<Translation>({
    identifier: 'TRANSLATIONS',
    apiProps: {
      url: EvidenceAPI.TRANSLATIONS,
      getItem: getItemFactory({ combineGetCalls }),
      createItem: createItemFactory({
        combineGetCalls,
        preprocessCall: async (data) => {
          const file = new File(
            [
              (data as Translation)?.textContent ??
                `{"${data.code?.trim()}": "<p>Doplňte...</p>"}`,
            ],
            'textace.json',
            {
              type: 'text/json',
            }
          );

          const fileRef: FileRef = await callUploadFile(
            '/api/crzp/files',
            file
          ).json();

          const newData = {
            ...data,
            active: true,
            language: 'CZECH',
            content: fileRef,
          };

          return newData;
        },
        postprocessCall,
      }),
      deleteItem: deleteItemFactory({ postprocessCall }),
      updateItem: updateItemFactory({
        combineGetCalls,
        preprocessCall: async (data) => {
          let file: File;
          if (data.code !== 'GLOBAL_TEXTS') {
            const json = JSON.parse((data as Translation)?.textContent);
            const content = json[Object.keys(json)[0]];

            const result = {
              [data.code!.trim()]:
                content !== '<p></p>' ? content : '<p>Doplňte...</p>',
            };

            file = new File([JSON.stringify(result)], 'textace.json', {
              type: 'text/json',
            });
          } else {
            file = new File(
              [(data as Translation)?.textContent],
              'textace.json',
              {
                type: 'text/json',
              }
            );
          }

          const fileRef: FileRef = await callUploadFile(
            '/api/crzp/files',
            file
          ).json();

          const newData = {
            ...data,
            content: fileRef,
          };

          return newData;
        },
        postprocessCall,
      }),
    },
    tableProps: {
      columns: useColumns() as any,
      tableName: 'Přehled nápověd',
      reportTag: ExportTags.TRANSLATIONS,
    },
    detailProps: {
      entityName: EntityName.TRANSLATION,
      FieldsComponent: TranslationsFields,
      validationSchema,
      toolbarProps: {
        title: 'Nápověda',
        ActionBar: TranslationsActionbar,
        // showBtn: (button) => {
        //   if (button === 'NEW') {
        //     return false;
        //   }

        //   return true;
        // },
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

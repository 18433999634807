export enum PermissionPublicList {
  /** Výpis veřejných seznamů */
  PUBLIC_LIST_LIST = 'PUBLIC_LIST_LIST',

  /** Vytvoření veřejného seznamu */
  PUBLIC_LIST_CREATE = 'PUBLIC_LIST_CREATE',

  /** Úprava veřejného seznamu */
  PUBLIC_LIST_UPDATE = 'PUBLIC_LIST_UPDATE',

  /** Smazání veřejného seznamu */
  PUBLIC_LIST_DELETE = 'PUBLIC_LIST_DELETE',

  /** Zaktivnění veřejného seznamu */
  PUBLIC_LIST_INVALIDATE = 'PUBLIC_LIST_INVALIDATE',

  /** Zneaktivnění veřejného seznamu */
  PUBLIC_LIST_REVALIDATE = 'PUBLIC_LIST_REVALIDATE',

  /** Publikování veřejného seznamu */
  PUBLIC_LIST_PUBLISH = 'PUBLIC_LIST_PUBLISH',
}

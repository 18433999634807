import * as Yup from 'yup';
import { DictionarySequence } from '../../models';

export function useValidationSchema() {
  return Yup.object<DictionarySequence>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    code: Yup.string().nullable().required('Povinné pole'),
    format: Yup.string().nullable().required('Povinné pole'),
    counter: Yup.number().nullable().required('Povinné pole'),
  });
}

export async function put<TYPE>({
  url,
  item,
  signal,
}: {
  url: string;
  item: TYPE;
  signal: AbortSignal;
}) {
  try {
    const response = await fetch(`${url}`, {
      method: 'PUT',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(item),
      signal,
    });

    const json = await response.json();

    return [json, response] as const;
  } catch (e) {
    return [undefined];
  }
}

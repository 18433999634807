export enum PermissionMuvDecisionType {
  /** Přehled druhů rozhodnutí */
  MUV_DECISION_TYPE_LIST = 'MUV_DECISION_TYPE_LIST',

  /** Autocomplete výběr druhů rozhodnutí */
  MUV_DECISION_TYPE_AUTOCOMPLETE = 'MUV_DECISION_TYPE_AUTOCOMPLETE',

  /** Detail druhu rozhodnutí */
  MUV_DECISION_TYPE_DETAIL = 'MUV_DECISION_TYPE_DETAIL',

  /** Vytvoření nového druhu rozhodnutí */
  MUV_DECISION_TYPE_CREATE = 'MUV_DECISION_TYPE_CREATE',

  /** Editace druhu rozhodnutí */
  MUV_DECISION_TYPE_UPDATE = 'MUV_DECISION_TYPE_UPDATE',

  /** Mazání druhu rozhodnutí */
  MUV_DECISION_TYPE_DELETE = 'MUV_DECISION_TYPE_DELETE',

  /** Zneplatnění druhu rozhodnutí */
  MUV_DECISION_TYPE_INVALIDATE = 'MUV_DECISION_TYPE_INVALIDATE',

  /** Obnovení druhu rozhodnutí */
  MUV_DECISION_TYPE_REVALIDATE = 'MUV_DECISION_TYPE_REVALIDATE',
}

import React, { useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import { useStyles } from './snackbar-styles';
import Typography from '@material-ui/core/Typography';
import { ConsentSnackbarContext } from './consent-snackbar-context';
import { Link } from 'react-router-dom';
import { useEventCallback } from '@eas/common-web';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export function ConsentSnackbarComponent() {
  const classes = useStyles();

  const { open, setOpen } = useContext(ConsentSnackbarContext);

  const hideSnackbar = useEventCallback(() => {
    setOpen(false);
  });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{ root: classes.snackbar }}
      open={open}
    >
      <SnackbarContent
        classes={{
          root: classes.content,
          message: classes.message,
          action: classes.action,
        }}
        aria-describedby="consent-snackbar"
        message={
          <div id="consent-snackbar" className={classes.messageInner}>
            <ErrorIcon className={classes.icon} />
            <Typography variant="body1">
              U {}
              <Link to="/crzp/profil">
                <Typography
                  component="span"
                  variant="body1"
                  color="inherit"
                  className={classes.link}
                >
                  Vašeho účtu
                </Typography>
              </Link>
              {} není potvrzen Souhlas se zpracováním osobních údajů. Přejděte
              prosím do {}
              <Link to="/crzp/profil">
                <Typography
                  component="span"
                  variant="body1"
                  color="inherit"
                  className={classes.link}
                >
                  uživatelského profilu
                </Typography>
              </Link>
              , kde můžete tento souhlas udělit. V rámci udělování souhlasu
              můžete být vyzváni k doplnění dalších povinných údajů.
            </Typography>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={hideSnackbar}
            size="small"
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

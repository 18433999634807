import React, { useMemo } from 'react';
import { noop } from 'lodash';
import { CompareIrzRequestFields } from '../../../composite/compare-irz-request/compare-irz-request-fields';
import {
  FormPanel,
  FormAutocomplete,
  Form,
  FormTextField,
  FormCustomField,
  FormDateField,
  FormInlineTableField,
  FormCheckboxGroup,
  FormTextArea,
  FormCheckbox,
} from '@eas/common-web';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ProcessForm } from '../irz-facility-split-types';
import {
  useIrzFacilities,
  useMainActivities,
  useEPrtrAndIppcActivities,
} from '../../irz-facility/irz-facilities-api';
import { useSubjects } from '../../subject/subjects-api';
import {
  dictionaryLabelMapper,
  dictionaryNoCodeLabelMapper,
} from '../../../components/form/mappers/label-mappers';
import {
  SubjectAutocomplete,
  MainActivityType,
  DictionaryNaceAutocomplete,
  DictionaryNaceCzAutocomplete,
  ActivityRecord,
} from '../../../models';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { useNaceItems } from '../../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import { useCzRiverBasins } from '../../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { useEuRiverBasins } from '../../dict-river-basin-eu-district/dict-river-basin-eu-district-api';
import { useIppcPidColumns } from '../step-identificators/step-identificators-columns';
import { useIrzActivities } from '../../dict-irz-activity/dict-irz-activities-api';
import { isIrzEqual } from '../../../composite/compare-irz-request/compare-irz-request-diff';

const useStyles = makeStyles((theme) => ({
  warning: {
    lineHeight: 'inherit',
    padding: '0 2pt',
    color: theme.palette.error.main,
  },
}));

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const classes = useStyles();

  const irzFacilities = useIrzFacilities();
  const subjectSource = useSubjects();
  const naceItems = useNaceItems();
  const naceCzItems = useNaceCzItems();
  const czRiverBasins = useCzRiverBasins();
  const euRiverBasins = useEuRiverBasins();
  const types = useMainActivities();
  const irzActivities = useIrzActivities();
  const { ePrtrActivities, ippcActivities } = useEPrtrAndIppcActivities();

  const ippcPidColumns = useIppcPidColumns();

  const isAnyValueUpdated = useMemo(() => {
    return !isIrzEqual(allValues?.editData, allValues?.mainFacility);
  }, [allValues?.mainFacility, allValues?.editData]);

  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Provozovna k rozdělení">
        <FormAutocomplete
          name="mainFacility"
          label="Provozovna"
          source={irzFacilities}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormTextField name="mainFacility.facilityId" label="IČP" />
      </FormPanel>

      <FormPanel label="Provozovna k rozdělení - Editace">
        {isAnyValueUpdated ? (
          <CompareIrzRequestFields
            originalData={allValues?.mainFacility}
            requestData={allValues?.editData}
          />
        ) : (
          <FormCustomField>
            <Typography variant="body1" className={classes.warning}>
              Beze změny
            </Typography>
          </FormCustomField>
        )}
      </FormPanel>

      <FormPanel label="Nová provozovna - Výběr provozovatele">
        <FormAutocomplete<SubjectAutocomplete>
          name="operator"
          label="Provozovatel"
          source={subjectSource}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
      </FormPanel>

      <FormPanel label="Nová provozovna - Údaje o provozovně">
        <FormTextField name="basicInfo.name" label="Název provozovny" />
        <FormTextField name="basicInfo.webSite" label="Web stránka" />
        <FormTextField name="basicInfo.publicEmail" label="Veřejný e-mail" />
        <FormTextField
          name="basicInfo.publicPhoneNumber"
          label="Veřejný telefon"
        />
        <FormDateField
          name="basicInfo.commissioningDate"
          label="Datum uvedení do provozu"
        />
      </FormPanel>

      <LocationPanel
        locationPanelLabel="Nová provozovna - Poloha"
        locationName="location.wgs84Coordinates"
        locationValue={allValues?.location?.wgs84Coordinates}
        addressPanelLabel="Nová provozovna - Adresa"
        addressName="location.address"
        prefillCoordinates={false}
      />

      <FormPanel label="Nová provozovna - Klasifikace, Identifikátory">
        <FormAutocomplete<DictionaryNaceCzAutocomplete>
          name="identificators.czNace"
          label="CZ-NACE"
          source={naceCzItems}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete<DictionaryNaceAutocomplete>
          name="identificators.nace"
          label="NACE"
          source={naceItems}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          name="identificators.czRiverBasinDistrict"
          label="Oblast povodí ČR"
          source={czRiverBasins}
          labelMapper={dictionaryNoCodeLabelMapper}
          tooltipMapper={dictionaryNoCodeLabelMapper}
        />
        <FormAutocomplete
          name="identificators.euRiverBasinDistrict"
          label="Oblast povodí Evropy"
          source={euRiverBasins}
          labelMapper={dictionaryNoCodeLabelMapper}
          tooltipMapper={dictionaryNoCodeLabelMapper}
        />
        <FormCheckbox
          name="identificators.integratedPermission.hasPermission"
          label="Provozovna/Zařízení má Integrované povolení"
        />
        {allValues.identificators?.integratedPermission?.hasPermission && (
          <FormInlineTableField<string>
            name="identificators.integratedPermission.ippcPids"
            label="Seznam PID (IPPC)"
            columns={ippcPidColumns}
            initNewItem={() => ''}
          />
        )}
      </FormPanel>
      <FormPanel label="Nová provozovna - Činnosti">
        <FormCheckboxGroup
          label="Typ činnosti"
          source={types}
          name="activities.activityTypes"
          labelMapper={dictionaryLabelMapper}
        />
        {allValues.activities?.activityTypes?.includes(
          MainActivityType.E_PRTR
        ) && (
          <>
            <FormAutocomplete
              name="activities.mainEprtrActivity.activity"
              label="Hlavní E-PRTR činnost"
              source={ePrtrActivities}
              labelMapper={dictionaryLabelMapper}
              tooltipMapper={dictionaryLabelMapper}
            />

            <FormTextField
              name="activities.mainEprtrActivity.facilityCount"
              label="Počet zařízení hlavní E-PRTR činnosti"
            />
            <FormInlineTableField<ActivityRecord>
              name="activities.secondaryEprtrActivities"
              label="Seznam vedlejších E-PRTR činností"
              columns={[
                {
                  name: 'Vedlejší E-PRTR činnost',
                  datakey: 'activity.label',
                  width: 400,
                },
                {
                  name: 'Počet zařízení',
                  datakey: 'facilityCount',
                  width: 200,
                },
              ]}
              maxRows={5}
            />
          </>
        )}
        {allValues.activities?.activityTypes?.includes(
          MainActivityType.IRZ
        ) && (
          <>
            {!allValues.activities?.activityTypes?.includes(
              MainActivityType.E_PRTR
            ) && (
              <>
                <FormAutocomplete
                  name="activities.mainIrzActivity.activity"
                  label="Hlavní činnost dle přílohy zákona o IRZ a ISPOP"
                  source={irzActivities}
                  labelMapper={dictionaryLabelMapper}
                  tooltipMapper={dictionaryLabelMapper}
                />

                <FormTextField
                  name="activities.mainIrzActivity.facilityCount"
                  label="Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP"
                />
              </>
            )}
            <FormInlineTableField<ActivityRecord>
              name="activities.secondaryIrzActivities"
              label="Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP"
              columns={[
                {
                  name: 'Vedlejší činnost dle zákona o IRZ a ISPOP',
                  datakey: 'activity.label',
                  width: 400,
                },
                {
                  name: 'Počet zařízení',
                  datakey: 'facilityCount',
                  width: 200,
                },
              ]}
              maxRows={5}
            />
          </>
        )}

        <FormAutocomplete
          name="activities.ippcActivity"
          label="Činnost IPPC"
          source={ippcActivities}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
      </FormPanel>
      <FormPanel label="Ostatní informace">
        <FormTextArea name="note" label="Poznámka" />
      </FormPanel>
    </Form>
  );
}

import {
  useStaticListSource,
  DictionaryAutocomplete,
  abortableFetch,
} from '@eas/common-web';
import { AuthorRole, IdType, EmpowermentRequestUnion } from '../../models';
import { EvidenceAPI } from '../../enums';

export function useAuthorRoles() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: AuthorRole.AGENT, name: 'Zmocněnec' },
    {
      id: AuthorRole.EMPOWERER,
      name: 'Zmocnitel',
    },
  ]);
}

export function useIdTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: IdType.ID_CARD,
      name: 'Občanský průkaz',
    },
    { id: IdType.PASSPORT, name: 'Cestovní pas' },
  ]);
}

export function getOtherSubject(id: string) {
  return abortableFetch(`/api/crzp/empowerment/request/${id}/other-subject`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  });
}

export function createEmpowerementRequest(body: EmpowermentRequestUnion) {
  return abortableFetch(`${EvidenceAPI.EMPOWERMENT_REQUESTS}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(body),
  });
}

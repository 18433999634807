import * as Yup from 'yup';
import { ProductionSite, RuianObject, IrzFacility } from '../../models';
import { wgs84CoordinatesSchema } from '../../components/form/address-field/address-field-schema';

export const validateDistrict = Yup.mixed<RuianObject>()
  .nullable()
  .required('Povinné pole');

export function useValidationSchema() {
  return Yup.object<ProductionSite>().shape({
    wgs84Coordinates: wgs84CoordinatesSchema(),
    name: Yup.string().nullable().required('Povinné pole'),
    facilities: Yup.array<IrzFacility>().nullable().required('Povinné pole'),
    district: validateDistrict,
  });
}

import React, { useContext } from 'react';
import * as Yup from 'yup';
import { noop } from 'lodash';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  FormContext,
  useFormSelector,
  TableFieldActionButton,
  PrimaryDetailActionbarButton,
  FormEditor,
} from '@eas/common-web';
import { parseCoordinates } from './protected-deposit-areas-map';
import { ProtectedDepositArea } from '../../../models';

const useStyles = makeStyles(() => ({
  tableActions: {
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 16px 8px 10px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  buttonTitle: {
    display: 'flex',
  },
}));

export function MapToolbar() {
  const { setFieldValue, editing } = useContext(FormContext);
  const { coordinates } = useFormSelector((data: ProtectedDepositArea) => ({
    coordinates: data?.coordinates ?? '',
  }));

  const classes = useStyles();
  const validationSchema = useValidationSchema();

  return (
    <div className={classes.tableActions}>
      <TableFieldActionButton
        FormFields={FormFields}
        apiCall={noop as any}
        submitCallback={(values) => {
          setFieldValue('coordinates', values.coordinates);
        }}
        promptKey="EDIT_COORDINATES"
        buttonLabel={
          <div className={classes.buttonTitle}>
            <EditLocationIcon />
            Upravit souřadnice
          </div>
        }
        dialogTitle="Souřadnice"
        dialogText="Zadejte souřadnice"
        IconComponent={EditLocationIcon}
        buttonDisabled={!editing}
        ButtonComponent={PrimaryDetailActionbarButton}
        formInitialValues={{ coordinates }}
        formValidationSchema={validationSchema}
        dialogWidth={600}
      />
    </div>
  );
}

function FormFields() {
  return (
    <FormEditor
      name="coordinates"
      language="html"
      labelOptions={{ hide: true }}
      options={{ lineNumbers: (num: number) => (num - 1).toString() }}
      height="45vh"
    />
  );
}

function useValidationSchema() {
  return Yup.object().shape({
    coordinates: Yup.string()
      .test('', '', function (value) {
        const { createError } = this;

        if (value) {
          const { parsedConfig, lastPolygon, allPolygons } = parseCoordinates(
            value
          );

          if (
            parsedConfig.length !== 2 ||
            ((parsedConfig[0] !== 'x' || parsedConfig[1] !== 'y') &&
              (parsedConfig[0] !== 'y' || parsedConfig[1] !== 'x'))
          ) {
            return createError({
              message: 'Špatné označení sloupců. Musí být X Y nebo Y X.',
            });
          }

          if (lastPolygon.length > 0) {
            return createError({
              message:
                'Byl zadán neukončený polygon (první a poslední souřadnice musí být stejné).',
            });
          }

          if (allPolygons.length === 0) {
            return createError({
              message: 'Nebyl zadán žádný validní polygon.',
            });
          }

          for (const polygon of allPolygons) {
            for (const coordinate of polygon) {
              if (coordinate.x < 0 || coordinate.y < 0) {
                return createError({
                  message: 'Všechny souřadnice musí být kladná čísla.',
                });
              }
            }
          }
        }

        return true;
      })
      .required('Musí být vyplněny souřadnice alespoň jednoho polygonu.'),
  });
}

import React from 'react';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useState, useContext, useRef } from 'react';
import {
  FormContext,
  useEventCallback,
  SnackbarContext,
  abortableFetch,
  AbortableFetch,
  usePrompts,
  PromptContext,
  NavigationContext,
} from '@eas/common-web';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IrzFacilityRequest } from '../../../models';
import { v4 as uuidv4 } from 'uuid';
import { IrzRequestType } from '../../../models/irz-facility-request';
import { ProcessForm } from '../irz-facility-request-change-types';
import { EvidenceAPI, Messages } from '../../../enums';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function apiCall(body: IrzFacilityRequest) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITY_REQUESTS}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(body),
  });
}

const PROMPT_KEY = 'CREATE_REQUEST_PROMPT';

export function StepSummaryActions({
  handleNext,
  handleBack,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Context stuff
   */
  const { testPrompt } = useContext(PromptContext);
  const { setFieldValue } = useContext(FormContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { unregisterPrompt } = useContext(NavigationContext);

  const [loading, setLoading] = useState(false);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Změna údajů provozovny IRZ',
      dialogText:
        'Skutečně chcete odeslat žádost o změnu údajů provozovny IRZ?',
    },
  ]);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);
          const { requestFacilityData, note, irzFacility } = allValues;
          const requestData: IrzFacilityRequest = {
            id: uuidv4(),
            type: IrzRequestType.CHANGE,
            note,
            requestFacilityData: {
              ...requestFacilityData,
              id: irzFacility?.id as string,
            },
            irzFacility,
          };
          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = await apiCall(requestData);

          const requestResponseData = await fetch.current.json();

          setLoading(false);
          showSnackbar(
            ...Messages.IrzFacilityRequest.REQUEST_CHANGE_SUBMIT.SUCCESS
          );
          setFieldValue('request', requestResponseData);
          setFieldValue('isSent', true);
          unregisterPrompt(navigationPrompt);
          handleNext();
        } catch (err) {
          setLoading(false);
          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.IrzFacilityRequest.REQUEST_CHANGE_SUBMIT,
              err.code
            );

            showSnackbar(...message);

            throw err;
          }
          return undefined;
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Odeslat žádost
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} disabled={loading} />
      </div>
    </div>
  );
}

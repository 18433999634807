import React from 'react';
import clsx from 'clsx';
import { useSlate } from 'slate-react';
import { Range, BaseRange } from 'slate';
import LinkOffIcon from '@material-ui/icons/LinkOff';
// utils
import { unwrapLink } from '../utils/unwrap-link';
import { isMarkActive } from '../utils/is-mark-active';
import { useStyles } from './button-styles';

export function UnwrapLinkButton() {
  const classes = useStyles();
  const editor = useSlate();

  const active =
    editor.selection &&
    !Range.isCollapsed(editor.selection as BaseRange) &&
    isMarkActive(editor, 'link');

  const disabled =
    !editor.selection ||
    Range.isCollapsed(editor.selection as BaseRange) ||
    !isMarkActive(editor, 'link');

  return (
    <button
      disabled={disabled}
      onMouseDown={(event) => {
        event.preventDefault();

        unwrapLink(editor);
      }}
      className={clsx(classes.button, {
        [classes.active]: active,
        [classes.disabled]: disabled,
      })}
    >
      <LinkOffIcon fontSize="small" />
    </button>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import logo from './logo.svg';
import { useMenuItems } from './items';
import { HeaderDesktopMenu } from './header-desktop-menu';
import { HeaderMobileMenu } from './header-mobile-menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: theme.shadows[1],
      backgroundColor: 'white',
      position: 'fixed',
      top: 0,
      zIndex: 1000,
    },
    logoWrapper: {
      width: '100%',
      height: 80,
      display: 'flex',
      justifyItems: 'center',
    },
    logo: {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
    },
    menuWrapper: {
      width: '100%',
      height: 80,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',

      '& > *': {
        margin: theme.spacing(2),
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
    },
  })
);

export function Header() {
  const classes = useStyles();

  /**
   * Filter out items hidden for (un)logged user.
   */
  // let items: MenuItem[] = [];
  // if (userInfo) {
  //   items = menuItems.filter((item) => item.showToLogged);
  // } else {
  //   items = menuItems.filter((item) => item.showToUnlogged);
  // }

  const items = useMenuItems();

  return (
    <Grid container className={classes.root}>
      <Grid item md={1} />
      <Grid item md={2} xs={6}>
        <Typography component="nav" className={classes.logoWrapper}>
          <Link to="/" className={classes.link}>
            <img src={logo} alt="logo" className={classes.logo} />
          </Link>
        </Typography>
      </Grid>
      <Grid item md={8} xs={6}>
        <div className={classes.menuWrapper}>
          <div className={classes.buttonsWrapper}>
            <Hidden mdDown>
              <HeaderDesktopMenu items={items} />
            </Hidden>
            <Hidden lgUp>
              <HeaderMobileMenu items={items} />
            </Hidden>
          </div>
        </div>
      </Grid>
      <Grid item md={1} />
    </Grid>
  );
}

import React from 'react';
import { mailingToolbarButtonFactory } from '../../composite/mailing-toolbar-button/mailing-toolbar-button';
import { RecipientsSource } from '../../models/mail-campaign';

const MailingToolbarButton = mailingToolbarButtonFactory({
  source: RecipientsSource.THIRD_PARTY,
});

export function ThirdPartySystemsTableToolbar() {
  return (
    <>
      <MailingToolbarButton />
    </>
  );
}

import React, { ComponentType } from 'react';
import clsx from 'clsx';
import { TableCellProps } from '@eas/common-web';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { stubFalse } from 'lodash';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    displayOverride: {
      display: 'flex',
      '& > p': {
        display: 'block !important',
      },
    },
    colorApproved: {
      color: `${theme.palette.primary.main}`,
    },
    colorRejected: {
      color: `${theme.palette.error.main}`,
    },
    colorWaitingForAuthorization: {
      color: blue[700],
    },
  })
);

export function requestColoredFactory<T>(
  Component: ComponentType<TableCellProps<T>>,
  isApproved: (d: T) => boolean,
  isRejected: (d: T) => boolean,
  isWaitingForAuthorization: (d: T) => boolean = stubFalse,
  withDisplayOverride = true
) {
  const WrapedComponent = function Cell(props: TableCellProps<T>) {
    const classes = useStyles();

    return (
      <div
        className={clsx(classes.root, {
          [classes.displayOverride]: withDisplayOverride,
          [classes.colorApproved]: isApproved(props.rowValue) === true,
          [classes.colorRejected]: isRejected(props.rowValue) === true,
          [classes.colorWaitingForAuthorization]:
            isWaitingForAuthorization(props.rowValue) === true,
        })}
      >
        <Component {...props} />
      </div>
    );
  };

  WrapedComponent.displayName = Component.displayName ?? Component.name;
  return WrapedComponent;
}

import { useAutocompleteSource } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { DictionaryRiverBasinCzDistrictAutocomplete } from '../../models';

export function useCzRiverBasins(apiUrl?: EvidenceAPI) {
  return useAutocompleteSource<DictionaryRiverBasinCzDistrictAutocomplete>({
    url: EvidenceAPI.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS + '/autocomplete',
    apiUrl,
  });
}

import React, { useEffect, useContext, useMemo } from 'react';
import {
  FormAutocomplete,
  FormPanel,
  FormInlineTableField,
  useFormSelector,
  FormContext,
  FormCheckbox,
  eqFilterParams,
} from '@eas/common-web';
import { useNaceItems } from '../../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import { useCzRiverBasins } from '../../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { useEuRiverBasins } from '../../dict-river-basin-eu-district/dict-river-basin-eu-district-api';

import { useIppcPidColumns } from './step-identificators-columns';
import {
  DictionaryNaceCzAutocomplete,
  DictionaryNaceAutocomplete,
} from '../../../models';
import {
  dictionaryLabelMapper,
  dictionaryNoCodeLabelMapper,
} from '../../../components/form/mappers/label-mappers';
import { HelpLabel } from '../../../components/help/help-label';
import { StepIdentificatorsForm } from './step-identificators-types';
import { ProcessForm } from '../irz-facility-requests-new-types';
import { useTradesInEmissionsColumns } from '../../irz-facility/irz-facilities-columns';
import { useOvzFacilities } from '../../ovz-facility/ovz-facilities-api';

export function StepIdentificatorsContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  const { setFieldValue } = useContext(FormContext);
  const naceItems = useNaceItems();
  const naceCzItems = useNaceCzItems();
  const czRiverBasins = useCzRiverBasins();
  const euRiverBasins = useEuRiverBasins();
  const ovzFacilities = useOvzFacilities(
    eqFilterParams({ field: 'operator.id', value: allValues.operator?.id })
  );

  const tradesInEmissionsColumns = useTradesInEmissionsColumns();
  const ippcPidColumns = useIppcPidColumns();

  const {
    czRiverBasinDistrict,
    czNace,
    tradesInEmissions,
    hasIntegratedPermission,
  } = useFormSelector((data: StepIdentificatorsForm) => ({
    czRiverBasinDistrict: data?.czRiverBasinDistrict,
    czNace: data?.czNace,
    tradesInEmissions: data?.emissionsTradingSystem?.tradesInEmissions,
    hasIntegratedPermission: data?.integratedPermission?.hasPermission,
  }));

  useEffect(() => {
    if (allValues.czRiverBasinDistrict !== czRiverBasinDistrict) {
      if (czRiverBasinDistrict?.euRiverBasinDistrict) {
        setFieldValue(
          'euRiverBasinDistrict',
          czRiverBasinDistrict.euRiverBasinDistrict
        );
      }
    }
  }, [czRiverBasinDistrict]);

  useEffect(() => {
    setFieldValue('nace', czNace?.nace);
  }, [czNace]);

  const EmmissionTradingSystem = useMemo(
    () =>
      function EmmissionTradingSystem() {
        return (
          <FormInlineTableField<string>
            name="emissionsTradingSystem.ids"
            label={
              <HelpLabel
                label="ID zařízení (EU ETS)"
                code="IRZ_FACILITY_REQUEST_NEW_EMISSIONS_TRADING_SYSTEM_IDS"
              />
            } // Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9
            columns={tradesInEmissionsColumns}
            initNewItem={() => ''}
          />
        );
      },
    []
  );

  const IppcPids = useMemo(
    () =>
      function IppcPids() {
        return (
          <FormInlineTableField<string>
            name="integratedPermission.ippcPids"
            label={
              <HelpLabel
                label="Seznam PID (IPPC)"
                code="IRZ_FACILITY_REQUEST_NEW_IPPC_PIDS"
              />
            }
            columns={ippcPidColumns}
            initNewItem={() => ''}
          />
        );
      },
    []
  );

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete<DictionaryNaceCzAutocomplete>
        name="czNace"
        label={
          <HelpLabel
            label="CZ-NACE"
            code="IRZ_FACILITY_REQUEST_NEW_CZ_NACE"
            required
          />
        } // Povinné pole
        source={naceCzItems}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
      />
      <FormAutocomplete<DictionaryNaceAutocomplete>
        name="nace"
        label={
          <HelpLabel
            label="NACE"
            code="IRZ_FACILITY_REQUEST_NEW_NACE"
            required
          />
        } // Povinné pole
        source={naceItems}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
        disabled={true}
      />
      <FormAutocomplete
        name="czRiverBasinDistrict"
        label={
          <HelpLabel
            label="Oblast povodí ČR"
            code="IRZ_FACILITY_REQUEST_NEW_CZ_RIVER_BASIN_DISTRICT"
            required
          />
        } // Povinné pole
        source={czRiverBasins}
        tooltipMapper={dictionaryNoCodeLabelMapper}
        labelMapper={dictionaryNoCodeLabelMapper}
      />
      <FormAutocomplete
        name="euRiverBasinDistrict"
        label={
          <HelpLabel
            label="Oblast povodí Evropy"
            code="IRZ_FACILITY_REQUEST_NEW_EU_RIVER_BASIN_DISTRICT"
            required
          />
        } // Povinné pole
        source={euRiverBasins}
        labelMapper={dictionaryNoCodeLabelMapper}
        tooltipMapper={dictionaryNoCodeLabelMapper}
      />
      <FormAutocomplete
        name="ovzFacility"
        label={
          <HelpLabel
            label="IČP provozovny ovzduší"
            code="IRZ_FACILITY_REQUEST_OVZ_FACILITY"
          />
        }
        source={ovzFacilities}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
      />
      <FormCheckbox
        name="integratedPermission.hasPermission"
        label={
          <HelpLabel
            label="Provozovna/Zařízení má Integrované povolení"
            code="IRZ_FACILITY_REQUEST_NEW_INTEGRATED_PERMISSION_HAS_PERMISSION"
          />
        }
      />
      {hasIntegratedPermission && <IppcPids />}
      <FormCheckbox
        name="emissionsTradingSystem.tradesInEmissions"
        label={
          <HelpLabel
            label="Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi"
            code="IRZ_FACILITY_REQUEST_NEW_EMISSIONS_TRADING_SYSTEM_TRADES_IN_EMISSIONS"
          />
        }
      />
      {tradesInEmissions === true && <EmmissionTradingSystem />}
    </FormPanel>
  );
}

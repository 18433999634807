import { useState, useContext, useEffect, useMemo } from 'react';
import { MapContext } from '../map-ctx';
import { AbortableFetch } from '@eas/common-web';

export function useMarkerData<DATA>({
  getData,
}: {
  getData: (id: string) => AbortableFetch;
}) {
  const [data, setData] = useState<DATA | undefined>();

  const { map, isLoaded } = useContext(MapContext);

  useEffect(() => {
    if (isLoaded) {
      const loadFacilityData = async (id: string) => {
        setData(undefined);
        const facilityData = await getData(id).json();

        if (facilityData) {
          setData(facilityData);
        }
      };

      const markerClickListener = map
        ?.getSignals()
        .addListener(window, 'marker-click', function (e) {
          const marker = (e as any).target;
          const signalFacilityId = marker.getId();

          loadFacilityData(signalFacilityId);
        });

      return () => {
        if (markerClickListener) {
          map?.getSignals().removeListener(markerClickListener);
        }
      };
    }
  }, [isLoaded]);

  return useMemo(
    () => ({
      data,
    }),
    [data]
  );
}

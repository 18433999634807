import { useListSource } from '@eas/common-web';
import { DictionaryAutocomplete } from '../../models';
import { EvidenceAPI } from '../../enums';

export function useDataType() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.PERSONAL_DATA + '/data-type/list',
    method: 'GET',
  });
}

export function useFields() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.PERSONAL_DATA + '/entity-reference/field/list',
    method: 'GET',
  });
}

import React, { useMemo } from 'react';
import {
  FormPanel,
  FormTextField,
  FormDateField,
  FormSelect,
  useFormSelector,
  FormInlineTableField,
  FormCustomField,
  TextField,
  FormDateTimeField,
} from '@eas/common-web';
import { PersonalDataType, PersonalData } from '../../models';
import { useDataType } from './personal-data-api';
import { useProcessingPurposeTableColumns } from './personal-data-columns';
import { HelpLabel } from '../../components/help/help-label';

export function PersonalDataFields() {
  const dataTypes = useDataType();

  const { type, entityReference } = useFormSelector((data: PersonalData) => ({
    type: data.dataType,
    entityReference: data.entityReference,
  }));

  const entityReferenceLabel = useMemo(() => {
    if (entityReference?.subject) {
      return 'Subjekt';
    } else if (entityReference?.subjectRegistration) {
      return 'Registrace subjektu';
    } else if (entityReference?.user) {
      return 'Uživatel';
    } else if (entityReference?.userRegistration) {
      return 'Registrace uživatele';
    } else if (entityReference?.boilerPermission) {
      return 'Oprávnění OZO';
    }

    return '-';
  }, [entityReference]);

  return (
    <FormPanel label="Základní informace">
      <FormSelect
        name="dataType"
        label={<HelpLabel label="Datový typ" code="PERSONAL_DATA_DATA_TYPE" />}
        source={dataTypes}
        valueIsId={true}
      />
      {type === PersonalDataType.DATE && (
        <FormDateField
          name="value"
          label={<HelpLabel label="Hodnota" code="PERSONAL_DATA_VALUE" />}
        />
      )}
      {type === PersonalDataType.DATETIME && (
        <FormDateField
          name="value"
          label={<HelpLabel label="Hodnota" code="PERSONAL_DATA_VALUE" />}
        />
      )}
      {type !== PersonalDataType.DATETIME && type !== PersonalDataType.DATE && (
        <FormTextField
          name="value"
          label={<HelpLabel label="Hodnota" code="PERSONAL_DATA_VALUE" />}
        />
      )}
      <FormCustomField
        label={
          <HelpLabel label="Typ entity" code="PERSONAL_DATA_ENTITY_TYPE" />
        }
      >
        <TextField onChange={() => {}} value={entityReferenceLabel} disabled />
      </FormCustomField>
      <FormTextField
        name="entityReference.field.name"
        label={
          <HelpLabel label="Atribút" code="PERSONAL_DATA_ENTITY_ATTRIBUTE" />
        }
      />
      {entityReference?.subject && (
        <FormTextField
          name="entityReference.subject.name"
          label={<HelpLabel label="Název" code="PERSONAL_DATA_ENTITY_VALUE" />}
        />
      )}
      {entityReference?.subjectRegistration && (
        <FormTextField
          name="entityReference.subjectRegistration.name"
          label={<HelpLabel label="Název" code="PERSONAL_DATA_ENTITY_VALUE" />}
        />
      )}
      {entityReference?.user && (
        <FormTextField
          name="entityReference.user.username"
          label={
            <HelpLabel
              label="Uživatelské jméno"
              code="PERSONAL_DATA_ENTITY_VALUE"
            />
          }
        />
      )}
      {entityReference?.userRegistration && (
        <FormTextField
          name="entityReference.userRegistration.username"
          label={
            <HelpLabel
              label="Uživatelské jméno"
              code="PERSONAL_DATA_ENTITY_VALUE"
            />
          }
        />
      )}
      {entityReference?.boilerPermission && (
        <FormTextField
          name="entityReference.boilerPermission.number"
          label={<HelpLabel label="Název" code="PERSONAL_DATA_ENTITY_VALUE" />}
        />
      )}
      <FormInlineTableField
        name="processingPurposes"
        label={
          <HelpLabel
            label="Účely zpracování"
            code="PERSONAL_DATA_PROCESSING_PURPOSES"
          />
        }
        showToolbar={false}
        columns={useProcessingPurposeTableColumns()}
      />
      <FormDateTimeField
        name="obtained"
        label={
          <HelpLabel label="Datum uchování od" code="PERSONAL_DATA_OBTAINED" />
        }
      />
      <FormDateTimeField
        name="keepTo"
        label={
          <HelpLabel label="Datum uchování do" code="PERSONAL_DATA_KEEP_TO" />
        }
      />
    </FormPanel>
  );
}

import React from 'react';
import { FormDateField } from '@eas/common-web';
import { HelpLabel } from '../../../../components/help/help-label';

export function BusinessNaturalPersonAdvancedFields({
  prefix,
  codePrefix = 'SUBJECT',
  disabled,
}: {
  prefix?: string;
  codePrefix?: string;
  disabled?: boolean;
}) {
  const names = {
    originDate: prefix ? `${prefix}.originDate` : 'originDate',
    endDate: prefix ? `${prefix}.endDate` : 'endDate',
  };

  return (
    <>
      <FormDateField
        name={names.originDate}
        label={
          <HelpLabel label="Datum vzniku" code={`${codePrefix}_ORIGIN_DATE`} />
        }
        disabled={disabled}
      />
      <FormDateField
        name={names.endDate}
        label={
          <HelpLabel label="Datum zániku" code={`${codePrefix}_END_DATE`} />
        }
        disabled={disabled}
      />
    </>
  );
}

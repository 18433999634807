import { SpecialFilter } from '../../components/evidence/history-evidence/overrides/table-searchbar';
import { SpecialFilterTextCell } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-text-cell';
import {
  useSpecialFilterSelectCellFactory,
  constructMultiFilter,
} from '../../components/evidence/history-evidence/special-filter-cells/special-filter-select-cell';
import { useTypes } from './water-usage-places-api';
import { ApiFilterOperation } from '@eas/common-web';

export function useSpecialFilters(): SpecialFilter[] {
  return [
    {
      label: 'Provozovatel - IČO / UID',
      filterkey: 'operator.identifier',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Provozovatel - Název',
      filterkey: 'operator.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Číslo VHB',
      filterkey: 'vhbNumber',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Typ',
      filterkey: 'type.id',
      operation: ApiFilterOperation.EQ,
      FilterCell: useSpecialFilterSelectCellFactory(useTypes, true),
      createFilter: constructMultiFilter,
    },
    {
      label: 'Obec',
      filterkey: 'municipality.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Katastrální území',
      filterkey: 'cadastralTerritory.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Povinný subjekt - Název',
      filterkey: 'obligatorySubject.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Povinný subjekt - IČO / UID',
      filterkey: 'obligatorySubject.identifier',
      FilterCell: SpecialFilterTextCell,
    },
  ];
}

import React from 'react';
import { FormFileField, FilesProvider, useFormSelector } from '@eas/common-web';
import { SubjectType } from '../../../models';
import { HelpLabel } from '../../../components/help/help-label';
import { EvidenceAPI } from '../../../enums';

export function SubjectRequestFileFields({
  type,
  disabled,
  prefix,
}: {
  type: SubjectType;
  disabled?: boolean;
  prefix?: string;
}) {
  const { affidavit, letterOfAttorney } = useFormSelector((data: any) => ({
    affidavit: data?.affidavit,
    letterOfAttorney: data?.letterOfAttorney,
  }));

  const isForeigner =
    type === SubjectType.FOREIGN_LEGAL_ENTITY ||
    type === SubjectType.FOREIGN_NATURAL_PERSON;

  const path = prefix ? `${prefix}.` : '';

  return (
    <FilesProvider url={EvidenceAPI.ARCHIVED_FILES}>
      {((!isForeigner && type !== SubjectType.LEGAL_ENTITY_WITHOUT_ICO) ||
        affidavit) && (
        <FormFileField
          name={`${path}affidavit`}
          label={
            <HelpLabel
              label="Příloha (čestné prohlášení)"
              code="SUBJECT_REGISTRATION_AFFIDAVIT"
            />
          }
          disabled={disabled}
        />
      )}
      {(!isForeigner || letterOfAttorney) && (
        <FormFileField
          name={`${path}letterOfAttorney`}
          label={
            <HelpLabel
              label="Příloha (plná moc)"
              code="SUBJECT_REGISTRATION_LETTER_OF_ATTORNEY"
            />
          }
          disabled={disabled}
        />
      )}
      {type === SubjectType.FOREIGN_NATURAL_PERSON && (
        <FormFileField
          name={`${path}identityDocumentCopy`}
          label={
            <HelpLabel
              label="Kopie dokladu totožnosti"
              code="SUBJECT_REGISTRATION_IDENTITY_DOCUMENT_COPY"
              required
            />
          }
          disabled={disabled}
        />
      )}
      {(type === SubjectType.FOREIGN_LEGAL_ENTITY ||
        type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO) && (
        <FormFileField
          name={`${path}registerExtract`}
          label={
            <HelpLabel
              label="Příloha (výpis z živnostenského nebo obchodního rejstříku nebo podobný dokument)"
              code="SUBJECT_REGISTRATION_REGISTER_EXTRACT"
              required={type === SubjectType.FOREIGN_LEGAL_ENTITY}
            />
          }
          disabled={disabled}
        />
      )}
      {(type === SubjectType.FOREIGN_LEGAL_ENTITY ||
        type === SubjectType.FOREIGN_NATURAL_PERSON) && (
        <FormFileField
          name={`${path}powerOfAttorney`}
          label={
            <HelpLabel
              label="Příloha (pověření k zastupování)"
              code="SUBJECT_REGISTRATION_POWER_OF_ATTORNEY"
            />
          }
          disabled={disabled}
        />
      )}
    </FilesProvider>
  );
}

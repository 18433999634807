import { IncludeOptions } from './../../enums/include-option';
import {
  useListSource,
  DictionaryAutocomplete,
  useAutocompleteSource,
  ApiFilterOperation,
} from '@eas/common-web';
import { RoleAutocomplete, RoleType } from '../../models';

export function useReportDefinitionsAutocomplete() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/crzp/reporting/definitions/autocomplete',
  });
}

export function useRoles() {
  return useAutocompleteSource<RoleAutocomplete>({
    url: '/api/crzp/role/autocomplete',
    params: {
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'registeredForCrzp',
          value: 'true',
        },
        {
          operation: ApiFilterOperation.IN,
          field: 'type.id',
          values: [RoleType.SECURITY_ROLE, RoleType.USER_ROLE],
        },
      ],
      include: [IncludeOptions.ALL],
    },
  });
}

import { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AbortableFetch,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { unstable_batchedUpdates } from 'react-dom';
import { Exception } from '../../../models';

interface RouteParams {
  token: string;
}

export function useVerifyEmail() {
  const { token } = useParams<RouteParams>();

  const fetch = useRef<AbortableFetch | null>(null);

  const [loading, setLoading] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState<boolean | undefined>(
    undefined
  );
  const [error, setError] = useState<Exception>();

  const callSubmit = () => {
    return abortableFetch('/api/crzp/user/email/verify', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: token,
    });
  };

  const verifyEmail = useEventCallback(async () => {
    try {
      setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callSubmit();

      await fetch.current.raw();

      unstable_batchedUpdates(() => {
        setEmailIsValid(true);
        setLoading(false);
      });
    } catch (e) {
      unstable_batchedUpdates(() => {
        setEmailIsValid(false);
        setLoading(false);
      });
      if (e.name !== 'AbortError') {
        setError(e);

        throw e;
      }
      return undefined;
    }
  });

  useEffect(() => {
    verifyEmail();
  }, []);

  return {
    emailIsValid,
    loading,
    error,
  };
}

import { useMemo, useContext } from 'react';
import { Permission } from '../../../enums';
import { NavigationContext, UserContext, UserBtnAction } from '@eas/common-web';
import { Me } from '../../../models';
import { AuthContext } from '../../auth/auth-context';
import { AuthType } from '../../auth/auth-types';
import { useSamlLogoutAction } from './saml-logout-menu-bar-action';

export function useMenuBarUserActions() {
  const { navigate } = useContext(NavigationContext);
  const { hasPermission, logout } = useContext<UserContext<Me>>(UserContext);
  const { type } = useContext(AuthContext);
  const { handleSamlLogout } = useSamlLogoutAction();

  const userBtnActions = useMemo(() => {
    const actions: UserBtnAction[] = [
      {
        label: 'Profil',
        action: () => {
          navigate('/crzp/profil');
        },
      },
    ];

    if (type === AuthType.SAML) {
      actions.push({
        label: 'Odhlásit',
        action: handleSamlLogout,
      });
    } else {
      actions.push({
        label: 'Odhlásit',
        action: logout,
      });
    }

    return actions;
  }, [navigate, type]);

  const getUserBtnActions = () => {
    return hasPermission(Permission.Default.TWO_FACTOR_PRE_AUTH)
      ? []
      : userBtnActions;
  };

  return {
    userBtnActions: getUserBtnActions(),
  };
}

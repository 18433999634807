import React from 'react';

import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { StepLocationForm } from './step-location-types';
import { useFormSelector } from '@eas/common-web';
import { ProcessForm } from '../irz-facility-split-types';
import { useStepLocation } from './step-location-hook';

export function StepLocationContent({ allValues }: { allValues: ProcessForm }) {
  useStepLocation(allValues);
  const { wgs84Coordinates } = useFormSelector(
    ({ location }: StepLocationForm) => ({
      wgs84Coordinates: location?.wgs84Coordinates,
    })
  );

  return (
    <LocationPanel
      locationName="location.wgs84Coordinates"
      locationValue={wgs84Coordinates}
      addressName="location.address"
      disableForeignCountry={true}
      locationPanelLabel="Poloha provozovny"
      addressPanelLabel="Adresa provozovny"
      prefillCoordinates={false}
    />
  );
}

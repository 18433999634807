import React from 'react';
import { FormTextField, FormDateField } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';

export function GeneralFields() {
  return (
    <>
      <FormTextField
        name="author.username"
        label={
          <HelpLabel
            label="Uživatel"
            code="SUBJECT_REGISTRATION_AUTHOR_USERNAME"
          />
        }
        disabled
      />
      <FormTextField
        name="authorFirstName.value"
        label={
          <HelpLabel
            label="Jméno"
            code="SUBJECT_REGISTRATION_AUTHOR_FIRSTNAME"
          />
        }
        disabled
      />
      <FormTextField
        name="authorLastName.value"
        label={
          <HelpLabel
            label="Příjmení"
            code="SUBJECT_REGISTRATION_AUTHOR_LASTNAME"
          />
        }
        disabled
      />
      <FormTextField
        name="authorEmail.value"
        label={
          <HelpLabel label="E-mail" code="SUBJECT_REGISTRATION_AUTHOR_EMAIL" />
        }
        disabled
      />
      <FormDateField
        name="authorized"
        label={
          <HelpLabel
            label="Datum autorizace"
            code="SUBJECT_REGISTRATION_AUTHORIZED"
          />
        }
        disabled
      />
    </>
  );
}

/**
 * Water usage place permissions
 */
export enum PermissionWaterUsagePlace {
  /** Přehled MUV s vazbou na všechny subjekty */
  WATER_USAGE_PLACE_LIST_ALL = 'WATER_USAGE_PLACE_LIST_ALL',

  /** Přehled MUV s vazbou na své subjekty */
  WATER_USAGE_PLACE_LIST_OWN = 'WATER_USAGE_PLACE_LIST_OWN',

  /** Autocomplete výběr MUV všech subjektů */
  WATER_USAGE_PLACE_AUTOCOMPLETE_ALL = 'WATER_USAGE_PLACE_AUTOCOMPLETE_ALL',

  /** Autocomplete výběr MUV mých subjektů */
  WATER_USAGE_PLACE_AUTOCOMPLETE_OWN = 'WATER_USAGE_PLACE_AUTOCOMPLETE_OWN',

  /** Detail MUV s vazbou na všechny subjekty */
  WATER_USAGE_PLACE_DETAIL_ALL = 'WATER_USAGE_PLACE_DETAIL_ALL',

  /** Detail MUV s vazbou na své subjekty */
  WATER_USAGE_PLACE_DETAIL_OWN = 'WATER_USAGE_PLACE_DETAIL_OWN',

  /** Vytvoření (založení) MUV */
  WATER_USAGE_PLACE_CREATE = 'WATER_USAGE_PLACE_CREATE',

  /** Editace MUV */
  WATER_USAGE_PLACE_UPDATE = 'WATER_USAGE_PLACE_UPDATE',

  /** Zneplatnění MUV */
  WATER_USAGE_PLACE_INVALIDATE = 'WATER_USAGE_PLACE_INVALIDATE',

  /** Obnovení MUV */
  WATER_USAGE_PLACE_REVALIDATE = 'WATER_USAGE_PLACE_REVALIDATE',

  /** Přehled VHR s vazbou na všechny subjekty */
  WATER_USAGE_PLACE_DECISION_LIST_ALL = 'WATER_USAGE_PLACE_DECISION_LIST_ALL',

  /** Přehled VHR s vazbou na své subjekty */
  WATER_USAGE_PLACE_DECISION_LIST_OWN = 'WATER_USAGE_PLACE_DECISION_LIST_OWN',

  /** Autocomplete výběr VHR všech subjektů */
  WATER_USAGE_PLACE_DECISION_AUTOCOMPLETE_ALL = 'WATER_USAGE_PLACE_DECISION_AUTOCOMPLETE_ALL',

  /** Autocomplete výběr VHR mých subjektů */
  WATER_USAGE_PLACE_DECISION_AUTOCOMPLETE_OWN = 'WATER_USAGE_PLACE_DECISION_AUTOCOMPLETE_OWN',

  /** Detail VHR s vazbou na všechny subjekty */
  WATER_USAGE_PLACE_DECISION_DETAIL_ALL = 'WATER_USAGE_PLACE_DECISION_DETAIL_ALL',

  /** Detail VHR s vazbou na své subjekty */
  WATER_USAGE_PLACE_DECISION_DETAIL_OWN = 'WATER_USAGE_PLACE_DECISION_DETAIL_OWN',

  /** Vytvoření VHR */
  WATER_USAGE_PLACE_DECISION_CREATE = 'WATER_USAGE_PLACE_DECISION_CREATE',

  /** Editace VHR */
  WATER_USAGE_PLACE_DECISION_UPDATE = 'WATER_USAGE_PLACE_DECISION_UPDATE',

  /** Smazání VHR */
  WATER_USAGE_PLACE_DECISION_DELETE = 'WATER_USAGE_PLACE_DECISION_DELETE',

  /** Přehled částí VHR s vazbou na všechny subjekty */
  WATER_USAGE_PLACE_DECISION_PART_LIST_ALL = 'WATER_USAGE_PLACE_DECISION_PART_LIST_ALL',

  /** Přehled částí VHR s vazbou na své subjekty */
  WATER_USAGE_PLACE_DECISION_PART_LIST_OWN = 'WATER_USAGE_PLACE_DECISION_PART_LIST_OWN',

  /** Autocomplete výběr částí VHR všech subjektů */
  WATER_USAGE_PLACE_DECISION_PART_AUTOCOMPLETE_ALL = 'WATER_USAGE_PLACE_DECISION_PART_AUTOCOMPLETE_ALL',

  /** Autocomplete výběr částí VHR mých subjektů */
  WATER_USAGE_PLACE_DECISION_PART_AUTOCOMPLETE_OWN = 'WATER_USAGE_PLACE_DECISION_PART_AUTOCOMPLETE_OWN',

  /** Detail části VHR s vazbou na všechny subjekty */
  WATER_USAGE_PLACE_DECISION_PART_DETAIL_ALL = 'WATER_USAGE_PLACE_DECISION_PART_DETAIL_ALL',

  /** Detail části VHR s vazbou na své subjekty */
  WATER_USAGE_PLACE_DECISION_PART_DETAIL_OWN = 'WATER_USAGE_PLACE_DECISION_PART_DETAIL_OWN',

  /** Vytvoření (založení) části VHR */
  WATER_USAGE_PLACE_DECISION_PART_CREATE = 'WATER_USAGE_PLACE_DECISION_PART_CREATE',

  /** Editace části VHR */
  WATER_USAGE_PLACE_DECISION_PART_UPDATE = 'WATER_USAGE_PLACE_DECISION_PART_UPDATE',

  /** Smazání části VHR */
  WATER_USAGE_PLACE_DECISION_PART_DELETE = 'WATER_USAGE_PLACE_DECISION_PART_DELETE',
}

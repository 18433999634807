import React, { useRef, useContext, useState, useEffect, useMemo } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import {
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  abortableFetch,
  PromptContext,
  usePrompts,
  FormTextField,
  FormInlineTableField,
  EmptyComponent,
  TableFieldCells,
  FormPanel,
  FormDateField,
  FormTextArea,
  EvidenceContext,
  UserContext,
  FormSubmitButton,
  FormContext,
  useFormSelector,
  TableFieldColumn,
  InlineTableFieldCells,
} from '@eas/common-web';
import { SubjectRegistration } from '../../../models';
import { EvidenceAPI, Messages, Permission } from '../../../enums';
import AddIcon from '@material-ui/icons/Add';
// import RemoveIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiButton from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useSubjectRegistrationState } from '../subject-requests-api';
import { getErrorMessage } from '../../../utils/get-message';
// import Button from '@material-ui/core/Button';

/**
 * Styles
 */
const useStyles = makeStyles((theme) =>
  createStyles({
    buttonAdd: {
      height: '1.5em',
      minWidth: 40,
      width: 'max-content',
      cursor: 'pointer',
      padding: '0px 10px',
      border: 0,
      textTransform: 'none',
      boxShadow: '0 0 0',
      color: 'black',
    },
    buttonRemove: {
      height: '1.5em',
      minWidth: 40,
      width: 'max-content',
      cursor: 'pointer',
      padding: '0px 10px',
      border: 0,
      textTransform: 'none',
      boxShadow: '0 0 0',
      color: theme.palette.error.main,
    },
    actions: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      padding: '10px 24px',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    buttonLabel: {
      textTransform: 'uppercase',
    },
    successMessage: {
      color: theme.palette.primary.main,
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
    delimiter: { width: '100%', marginBottom: 50 },
  })
);

/**
 * Api call to fetch SubjectRegistration by evidenceNumber (will be encoded in barcode)
 *
 * @param evidenceNumber
 */
function callFindRegistration(evidenceNumber: string) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECT_REQUESTS}?evidenceNumber=${evidenceNumber}`,
    {
      method: 'GET',
    }
  );
}

/**
 * Api call
 *
 * @param id
 */
function callAuthorize(
  id: string,
  date: string,
  note?: string,
  ceniaNumber?: string
) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECT_REQUESTS}/${id}/authorize/letter-received/approve`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        authorized: date,
        note: note,
        ceniaNumber: ceniaNumber,
      }),
    }
  );
}

/**
 * Bulk authorization of subject registration.
 */
const PROMPT_KEY = 'BULK_AUTHORIZE';

/**
 * Function to strip prefix from barcode to create evidenceNumber.
 *
 * @param barcode
 */
function formatBarCode(barcode: string) {
  let formatted = barcode;

  if (formatted.length === 13) {
    formatted = barcode.slice(3, -1);
  }

  return formatted;
}

function useRequestColumns(): TableFieldColumn<unknown>[] {
  const stateCell = TableFieldCells.useSelectCellFactory(
    useSubjectRegistrationState
  );

  const ceniaNumberCell = InlineTableFieldCells.useInlineTextFieldFactory({
    collectionDatakey: 'registrations',
  });

  return useMemo(
    () => [
      {
        datakey: 'evidenceNumber',
        name: 'Evidenční číslo',
        width: 200,
      },
      {
        datakey: 'identifier',
        name: 'IČO / UID',
        width: 200,
      },
      {
        datakey: 'state',
        name: 'Stav vyřešení',
        width: 300,
        CellComponent: stateCell,
      },
      {
        datakey: 'ceniaNumber',
        name: 'Spisová služba CENIA (Ev.č./Č.j.)',
        width: 300,
        CellComponent: ceniaNumberCell,
      },
    ],
    []
  );
}

function useResponseColumns(): TableFieldColumn<unknown>[] {
  const classes = useStyles();

  return [
    {
      datakey: 'evidenceNumber',
      name: 'Evidenční číslo',
      width: 150,
    },
    {
      datakey: 'identifier',
      name: 'IČO / UID',
      width: 200,
    },
    {
      datakey: 'state',
      name: 'Stav vyřešení',
      width: 150,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useSubjectRegistrationState
      ),
    },
    {
      datakey: 'date',
      name: 'Datum autorizace',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'response',
      name: 'Výsledek akce',
      width: 600,
      CellComponent: function Cell(props) {
        return (
          <div
            className={clsx({
              [classes.errorMessage]: !props.value.ok,
              [classes.successMessage]: props.value.ok,
            })}
          >
            <TableFieldCells.TextCell {...props} value={props.value.message} />
          </div>
        );
      },
    },
  ];
}

export function useBulkAuthorizeDialog() {
  /**
   * Context stuff.
   */
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { tableRef, detailRef } = useContext(EvidenceContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Reference to `code` input.
   */
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Hromadná autorizace registrací',
      dialogText: '',
      dialogShowConfirm: false,
      dialogShowClose: false,
      dialogWidth: 1000,
      formOnSubmit: async (
        values: { barcode: string; registrations: any[] },
        formik
      ) => {
        try {
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          // find subject registration
          fetch.current = callFindRegistration(formatBarCode(values.barcode));

          const registration = await fetch.current.json();

          if (
            values?.registrations?.some(
              (reg) => reg.evidenceNumber === registration.evidenceNumber
            )
          ) {
            showSnackbar(
              ...Messages.SubjectRegistration.BULK_AUTHORIZE.ALREADY_REGISTERED
            );
          } else {
            // append registration into list of registrations to authorize
            formik.setFieldValue('registrations', [
              ...(values?.registrations ?? []),
              registration,
            ]);
          }
          formik.setFieldValue('barcode', '');
          inputRef.current?.focus();
        } catch (err) {
          formik.setFieldValue('barcode', '');
          inputRef.current?.focus();

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.SubjectRegistration.BULK_AUTHORIZE,
              err.code
            );

            showSnackbar(...message);
            throw err;
          }
          return undefined;
        }
      },
      FormFields: function Body({ onConfirm, onCancel }) {
        /**
         * Styles.
         */
        const classes = useStyles();

        /**
         * Columns to table of authorized documents.
         */
        const requestColumns = useRequestColumns();
        const responseColumns = useResponseColumns();

        /**
         * Form stuff.
         */
        const { validateForm, setFieldValues } = useContext(FormContext);

        const { barcode, registrations } = useFormSelector(
          (data: {
            barcode: string;
            registrations: { created: string }[];
          }) => ({
            barcode: data.barcode,
            registrations: data.registrations ?? [],
          })
        );

        /**
         * If `true` switch input dialog into resposne dialog view.
         */
        const [submitted, setSubmitted] = useState(false);

        /**
         * Handlers
         */
        const handleClose = useEventCallback(() => {
          tableRef.current?.refresh();
          detailRef.current?.refresh();

          if (onCancel) {
            onCancel();
          }
        });

        const handleSubmit = useEventCallback(async (e) => {
          const errors = await validateForm();
          const isValid = errors.length === 0;

          if (isValid) {
            onConfirm!(e);
            setSubmitted(true);
          }
        });

        // const handleRemove = useEventCallback(() => {
        //   setFieldValue('barcode', '');
        //   inputRef.current?.focus();
        // });

        const handleRefresh = useEventCallback(() => {
          setSubmitted(false);
          setFieldValues({});
          requestAnimationFrame(() => {
            inputRef.current?.focus();
          });
        });

        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <>
            {!submitted && (
              <>
                <Typography variant="body1">Naskenujte čárové kódy.</Typography>
                <FormTextField
                  inputProps={{
                    ref: inputRef,
                  }}
                  name="barcode"
                  label="Evidenční číslo"
                  layoutOptions={{ noUnderline: true }}
                  endAdornment={
                    <>
                      <FormSubmitButton
                        type="submit"
                        variant="text"
                        color="primary"
                        disabled={!barcode}
                        startIcon={<AddIcon />}
                        className={classes.buttonAdd}
                      >
                        Přidat
                      </FormSubmitButton>
                      {/* <Button
                        type="submit"
                        variant="text"
                        color="primary"
                        disabled={!barcode}
                        startIcon={<RemoveIcon />}
                        className={classes.buttonRemove}
                        onClick={handleRemove}
                      >
                        Smazat
                      </Button> */}
                    </>
                  }
                />
                <br />
                <br />

                <FormPanel label="Vyplňte">
                  <FormDateField
                    name="date"
                    label="Datum"
                    helpLabel="Povinné pole"
                    disabled={!registrations?.length}
                    minDatePicker={
                      registrations?.length
                        ? registrations.reduce((reg1, reg2) =>
                            reg1.created > reg2.created ? reg1 : reg2
                          ).created
                        : undefined
                    }
                  />
                  <FormTextArea name="note" label="Poznámka" />
                </FormPanel>
                <FormPanel label="Dokumenty vložené k autorizaci">
                  <FormInlineTableField
                    name="registrations"
                    ToolbarComponent={EmptyComponent}
                    labelOptions={{
                      hide: true,
                    }}
                    layoutOptions={{ noSpacing: true }}
                    columns={requestColumns}
                  />
                </FormPanel>
              </>
            )}

            {submitted && (
              <FormPanel label="Dokumenty odeslané k autorizaci">
                <FormInlineTableField
                  name="registrations"
                  ToolbarComponent={EmptyComponent}
                  disabled
                  labelOptions={{
                    hide: true,
                  }}
                  layoutOptions={{ noSpacing: true }}
                  columns={responseColumns}
                />
              </FormPanel>
            )}

            <div className={classes.delimiter} />

            <MuiDialogActions classes={{ root: classes.actions }}>
              <ButtonGroup size="small" variant="outlined">
                {!submitted && (
                  <MuiButton
                    type="submit"
                    onClick={handleSubmit}
                    variant="outlined"
                    color="primary"
                    disabled={registrations.length === 0}
                  >
                    <Typography classes={{ root: classes.buttonLabel }}>
                      Autorizovat
                    </Typography>
                  </MuiButton>
                )}
                {submitted && (
                  <MuiButton
                    onClick={handleRefresh}
                    variant="outlined"
                    color="primary"
                  >
                    <Typography classes={{ root: classes.buttonLabel }}>
                      Načíst další
                    </Typography>
                  </MuiButton>
                )}
                <MuiButton variant="outlined" onClick={handleClose}>
                  <Typography classes={{ root: classes.buttonLabel }}>
                    Zavřít
                  </Typography>
                </MuiButton>
              </ButtonGroup>
            </MuiDialogActions>
          </>
        );
      },
      formValidationSchema: Yup.object().shape({
        date: Yup.mixed().nullable().required('Povinné pole'),
      }),
    },
  ]);

  const handleAuthorize = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (
        values: {
          registrations: (SubjectRegistration & { ceniaNumber?: string })[];
          date: string;
          note?: string;
        },
        formRef
      ) => {
        const registrations = [];

        // call authorize endpoint for every row of list
        for (let i = 0; i < values.registrations?.length ?? 0; i++) {
          try {
            const reg = values.registrations[i];

            await callAuthorize(
              reg.id,
              values.date,
              values.note,
              reg.ceniaNumber
            ).raw();

            registrations.push({
              ...reg,
              state: 'MANUAL_FINISH_REQUIRED',
              date: values.date,
              response: {
                ok: true,
                message: 'OK',
              },
            });
          } catch (err) {
            const originalReg = values.registrations[i];

            const error: 'SUBJECT_APPROVED_DATE_BEFORE_CREATED_DATE' | 'ERROR' =
              err?.code ?? 'ERROR';

            registrations.push({
              ...originalReg,
              response: {
                ok: false,
                message: Messages.SubjectRegistration.BULK_AUTHORIZE[error][0],
              },
            });
          }
        }

        formRef?.current?.setFieldValue('registrations', registrations);

        unstable_batchedUpdates(() => {
          showSnackbar(
            Messages.SubjectRegistration.BULK_AUTHORIZE.SUCCESS[0],
            Messages.SubjectRegistration.BULK_AUTHORIZE.SUCCESS[1],
            true
          );
          // tableRef.current?.refresh();
          // detailRef.current?.refresh();
        });

        return true;
      },
    });
  });

  const showAuthorizeButton = hasPermission(
    Permission.Subject.SUBJECT_REQUEST_AUTHORIZE_ALL
  );

  return {
    handleAuthorize,
    showAuthorizeButton,
  };
}

import React, { useContext, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Map } from './../../components/map/map';
import { MarkerLayer } from '../../components/map/marker-layer';
import { Markers } from '../../components/map/markers';
import { OvzFacility } from '../../models';
import { EvidenceAPI } from '../../enums';
import { MapContext } from '../../components/map/map-ctx';
import { useMarkerData } from '../../components/map/hook/marker-data-hook';
import { fetchOvzFacility, useLandPlotTypes } from './ovz-facilities-api';
import { DetailContext, useEventCallback } from '@eas/common-web';
import {
  MapEvidenceContext,
  MapEvidenceView,
} from '../../components/evidence/map-evidence/map-context';

export function FacilityMarkerTitle({ name }: { name: string }) {
  return (
    <>
      <Typography variant="body2">
        Název provozovny: <b>{name}</b>
      </Typography>
    </>
  );
}

export function FacilityMarkerBody({
  data,
  setActive,
}: {
  data: OvzFacility & { landPlotTypeLabel: string };
  setActive: (id: string) => void;
}) {
  return (
    <>
      <Typography variant="body2">
        IČP: <b>{data?.facilityId}</b>
      </Typography>
      <br />
      <Typography variant="body2">
        Typ parcely: <b>{data?.landPlotTypeLabel}</b>
      </Typography>
      <Typography variant="body2">
        Číslo parcely: <b>{data?.primaryLandPlotNumber ?? '-'}</b>
      </Typography>
      <Typography variant="body2">
        KÚ:&nbsp;
        <b>
          {data.cadastralTerritory
            ? `${data.cadastralTerritory.isuiCode} - ${data.cadastralTerritory.name}`
            : '-'}
        </b>
      </Typography>
      <Typography variant="body2">
        Adresní místo: <b>{data?.address?.addressPlaceRuian?.label}</b>
      </Typography>
      <Typography component="div" variant="body2">
        <>
          <p>
            Zeměpisná šířka: <b>{data?.wgs84Coordinates?.lat}</b>
          </p>
          <p>
            Zeměpisná délka: <b>{data?.wgs84Coordinates?.lon}</b>
          </p>
        </>
      </Typography>
      <br />
      <Button
        size="small"
        variant="contained"
        onClick={() => setActive(data.id)}
      >
        Spravovat
      </Button>
      <br />
      <br />
    </>
  );
}

export function OvzFacilitiesMap() {
  return (
    <Map api={EvidenceAPI.OVZ_FACILITIES}>
      <OvzFacilitiesMapBody />
    </Map>
  );
}

function OvzFacilitiesMapBody() {
  const { items, map } = useContext(MapContext);
  const { data } = useMarkerData<OvzFacility>({ getData: fetchOvzFacility });
  const { setActive, onPersisted } = useContext(DetailContext);
  const { setView } = useContext(MapEvidenceContext);

  const maxZoom = map?.getZoom() === map?.getZoomRange()[1];

  const markers = useMemo(() => {
    if (maxZoom) {
      const markers = items.map((item) => ({
        id: item.id,
        lat: item.lat,
        lng: item.lon,
        active: item.active,
      }));

      const object: { [key: string]: number } = {};
      const result: typeof markers = [];

      markers.forEach((marker) => {
        const key = `${marker.lat}+${marker.lng}`;
        if (object[key] === undefined) {
          object[key] = 0;
          result.push(marker);
        } else {
          object[key] += 1;
          result.push({ ...marker, lng: marker.lng + 0.0001 * object[key] });
        }
      });

      return result;
    } else {
      return items.map((item) => ({
        id: item.id,
        lat: item.lat,
        lng: item.lon,
        active: item.active,
      }));
    }
  }, [items, maxZoom]);

  const handleSetActive = useEventCallback((id: string) => {
    setActive(id);
    onPersisted(id);
    setView(MapEvidenceView.DETAIL);
  });

  const landPlotTypes = useLandPlotTypes();
  const landPlotType =
    landPlotTypes.items.find((i) => i.id === data?.landPlotType)?.name ?? '-';

  const cardData = useMemo(() => {
    return {
      title: <FacilityMarkerTitle name={data?.name ?? ''} />,
      body: (
        <FacilityMarkerBody
          data={{ ...data!, landPlotTypeLabel: landPlotType }}
          setActive={handleSetActive}
        />
      ),
    };
  }, [data, handleSetActive]);

  return (
    <MarkerLayer>
      <Markers markers={markers} card={cardData} />
    </MarkerLayer>
  );
}

import {
  abortableFetch,
  DetailContext,
  DetailHandle,
  UserContext,
} from '@eas/common-web';
import { useContext } from 'react';
import { EvidenceAPI, Permission } from './../../../enums';
import { Me, RoleRequest, RoleRequestState } from './../../../models';

export function useApproveDialog() {
  const { source } = useContext<DetailHandle<RoleRequest>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = (id: string) => {
    return abortableFetch(`${EvidenceAPI.ROLE_REQUESTS}/${id}/approve`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
  };

  const canApprove =
    (hasPermission(Permission.RoleRequest.ROLE_REQUEST_FINISH_ALL) ||
      hasPermission(Permission.RoleRequest.ROLE_REQUEST_FINISH_OWN)) &&
    source?.data?.state === RoleRequestState.PENDING;

  return { callApi, canApprove };
}

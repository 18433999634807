import React from 'react';
import {
  Evidence2,
  soapMessagesFactory,
  EmptyComponent,
} from '@eas/common-web';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { EvidenceAPI, Permission, EntityName } from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';

export function SoapMessages() {
  const permissions = usePermission({
    options: {
      NEW: [Permission.SOAPMessage.WS_CLIENT_MESSAGE_DETAIL],
      EDIT: [Permission.SOAPMessage.WS_CLIENT_MESSAGE_DETAIL],
      REMOVE: [],
      CHECK: [Permission.SOAPMessage.WS_CLIENT_MESSAGE_DETAIL],
      SAVE: [Permission.SOAPMessage.WS_CLIENT_MESSAGE_DETAIL],
      CLOSE: [Permission.SOAPMessage.WS_CLIENT_MESSAGE_DETAIL],
    },
  });

  const evidence = useAuthoredEvidence({
    identifier: 'SOAP_MESSAGES',
    apiProps: {
      url: EvidenceAPI.SOAP_MESSAGES,
    },
    tableProps: {
      columns: soapMessagesFactory.useColumns(EvidenceAPI.SOAP_MESSAGES),
      tableName: 'SOAP komunikace',
      showReportButton: false,
    },
    detailProps: {
      entityName: EntityName.SOAP_MESSAGE,
      toolbarProps: {
        title: 'SOAP komunikace',
        ActionBar: EmptyComponent,
        showBtn: permissions,
      },
      FieldsComponent: soapMessagesFactory.Fields,
    },
  });

  return <Evidence2 {...evidence} />;
}

import React, { forwardRef, Ref, useContext, useMemo } from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio/Radio';

import {
  TableFieldRowProps,
  TableFieldContext,
  TableFieldCells,
  useEventCallback,
} from '@eas/common-web';

const useStyles = makeStyles((theme) => ({
  rowWrapper: {
    background: '#e0e2e3',
    borderBottom: '1px solid #cdcdcd',
    '&:hover': {
      backgroundColor: theme.palette.highlight,
    },
  },
  row: {
    boxSizing: 'border-box',
    minWidth: '100%',
    minHeight: 30,
    padding: '2px 16px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  cellWrapper: {
    flexShrink: 0,
    display: 'flex',
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  tableRowActions: {
    flexShrink: 0,
    width: 35,
    height: 20,
    verticalAlign: 'top',
    display: 'inline-block',
    padding: 0,
    overflow: 'hidden',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  radioButton: {
    padding: 0,
  },
}));

export const InactiveColoredTableFieldRow = forwardRef(function TableFieldRow(
  {
    index,
    value,
    selected,
  }: TableFieldRowProps<{ id: string; validTo?: string; active?: boolean }>,
  ref: Ref<HTMLDivElement>
) {
  const classes = useStyles();
  const { filteredColumns, showRadioCond, onSelect, selectRow } = useContext(
    TableFieldContext
  );

  const showRadioButton = showRadioCond(value);

  const isActive = useMemo(
    () => (value.validTo ? new Date(value.validTo) >= new Date() : true),
    [value]
  );

  const handleSelectClick = useEventCallback(() => {
    if (value.active) {
      onSelect?.(value, index);
      selectRow(index);
    }
  });

  return (
    <div ref={ref} className={classes.rowWrapper} onClick={handleSelectClick}>
      <div className={classes.row}>
        {showRadioButton && (
          <div className={classes.tableRowActions}>
            <Radio
              disabled={!value.active}
              className={classes.radioButton}
              checked={selected}
              onChange={handleSelectClick}
              color="primary"
            />
          </div>
        )}
        {filteredColumns.map((c, i) => {
          const { CellComponent = TableFieldCells.TextCell } = c;
          return (
            <div
              key={i}
              className={classes.cellWrapper}
              style={{ width: c.width, color: isActive ? 'inherit' : 'gray' }}
            >
              <CellComponent
                index={index}
                value={get(value, c.datakey, '')}
                rowValue={value}
                column={c as any}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});

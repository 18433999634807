import {
  abortableFetch,
  NavigationContext,
  useEventCallback,
  SnackbarContext,
  AbortableFetch,
} from '@eas/common-web';
import { EvidenceAPI, Messages } from '../../enums';
import { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { IrzFacility } from '../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { IrzSplitStateAction } from './irz-facility-split-types';
import { StepMainFacilityLabel } from './step-main-facility/step-main-facility-label';
import { StepMainFacilityContent } from './step-main-facility/step-main-facility-content';
import { StepMainFacilityActions } from './step-main-facility/step-main-facility-actions';
import { stepMainFacilitySchema } from './step-main-facility/step-main-facility-schema';
import { StepSubjectLabel } from './step-subject/step-subject-label';
import { StepSubjectContent } from './step-subject/step-subject-content';
import { stepSubjectSchema } from './step-subject/step-subject-schema';
import { StepBasicInfoLabel } from './step-basic-info/step-basic-info-label';
import { StepBasicInfoContent } from './step-basic-info/step-basic-info-content';
import { stepBasicInfoSchema } from './step-basic-info/step-basic-info-schema';
import { StepLocationLabel } from './step-location/step-location-label';
import { StepLocationContent } from './step-location/step-location-content';
import { stepLocationSchema } from './step-location/step-location-schema';
import { StepIdentificatorsLabel } from './step-identificators/step-identificators-label';
import { StepIdentificatorsContent } from './step-identificators/step-identificators-content';
import { stepIdentificatorsSchema } from './step-identificators/step-identificators-schema';
import { StepActivitiesLabel } from './step-activities/step-activities-label';
import { StepActivitiesContent } from './step-activities/step-activities-content';
import { stepActivitiesSchema } from './step-activities/step-activities-schema';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepFinishActions } from './step-finish/step-finish-actions';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepMainFacilityEditLabel } from './step-main-facility-edit/step-main-facility-edit-label';
import { stepMainFacilityEditSchema } from './step-main-facility-edit/step-main-facility-edit-schema';
import { StepMainFacilityEditContent } from './step-main-facility-edit/step-main-facility-edit-content';
import { StepOtherInformationLabel } from './step-other-information/step-other-information-label';
import { StepOtherInformationContent } from './step-other-information/step-other-information-content';

function fetchIrzFacility(id: string) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITIES}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export function useIrzFacilitySplit() {
  /**
   * Context stuff
   */
  const { stateAction } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);

  /**
   * IRZ facility preloading state
   */
  const [isLoading, setLoading] = useState(
    stateAction?.action === IrzSplitStateAction.INIT_MAIN_IRZ
  );
  const [initialValues, setInitialValues] = useState<{
    mainFacility: IrzFacility;
  }>();

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Pre-loads irz facility based on the NavigationContext stateAction data
   */
  const loadIrz = useEventCallback(async () => {
    try {
      setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }
      fetch.current = fetchIrzFacility(stateAction?.data);
      const mainFacility: IrzFacility = await fetch.current.json();

      unstable_batchedUpdates(() => {
        setLoading(false);
        setInitialValues({
          mainFacility,
        });
      });
    } catch (err) {
      setLoading(false);
      if (err.name !== 'AbortError') {
        showSnackbar(...Messages.IrzFacilityRequest.IRZ_FACILITY_PRELOAD.ERROR);
        throw err;
      }
      return undefined;
    }
  });

  useEffect(() => {
    if (stateAction?.action === IrzSplitStateAction.INIT_MAIN_IRZ) {
      loadIrz();
    }
  }, []);

  const steps = useMemo(
    () => [
      {
        Label: StepMainFacilityLabel,
        Content: StepMainFacilityContent,
        Actions: StepMainFacilityActions,
        validationSchema: stepMainFacilitySchema,
        initialValues,
      },
      {
        Label: StepMainFacilityEditLabel,
        Content: StepMainFacilityEditContent,
        validationSchema: stepMainFacilityEditSchema,
      },
      {
        Label: StepSubjectLabel,
        Content: StepSubjectContent,
        validationSchema: stepSubjectSchema,
      },
      {
        Label: StepBasicInfoLabel,
        Content: StepBasicInfoContent,
        validationSchema: stepBasicInfoSchema,
      },
      {
        Label: StepLocationLabel,
        Content: StepLocationContent,
        validationSchema: stepLocationSchema,
      },
      {
        Label: StepIdentificatorsLabel,
        Content: StepIdentificatorsContent,
        validationSchema: stepIdentificatorsSchema,
      },
      {
        Label: StepActivitiesLabel,
        Content: StepActivitiesContent,
        validationSchema: stepActivitiesSchema,
      },
      {
        Label: StepOtherInformationLabel,
        Content: StepOtherInformationContent,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    [initialValues]
  );

  return {
    steps,
    isLoading,
  };
}

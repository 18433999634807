/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * IRZ facility messages
 */
const ACTIVATE: MessageType = {
  SUCCESS: ['Provozovna byla úspěšně obnovena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při obnovení provozovny.', SnackbarVariant.ERROR],
};

const CREATE: MessageType<'COORDINATES_NOT_UNIQUE'> = {
  COORDINATES_NOT_UNIQUE: [
    'Chyba při vytváření provozovny. Provozovatel již má přiřazenou provozovnu se stejnými souřadnicemi.',
    SnackbarVariant.ERROR,
  ],
};

const UPDATE: MessageType<
  'COORDINATES_NOT_UNIQUE' | 'FUTURE_OPERATION_PRESENT' | 'ERROR'
> = {
  COORDINATES_NOT_UNIQUE: [
    'Chyba při editaci provozovny. Provozovatel již má přiřazenou provozovnu se stejnými souřadnicemi.',
    SnackbarVariant.ERROR,
  ],
  FUTURE_OPERATION_PRESENT: [
    'Nelze upravit provozovnu, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
  ERROR: ['Při ukládání změn provozovny došlo k chybě', SnackbarVariant.ERROR],
};

const DEACTIVATE: MessageType<
  'SUCCESS' | 'ERROR' | 'FUTURE_OPERATION_PRESENT'
> = {
  SUCCESS: ['Provozovna byla úspěšně zneplatněna.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zneplatnění provozovny.', SnackbarVariant.ERROR],
  FUTURE_OPERATION_PRESENT: [
    'Nelze zneplatnit provozovnu, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
};

const OPERATOR_CHANGE: MessageType<
  'SUCCESS' | 'ERROR' | 'COORDINATES_NOT_UNIQUE' | 'FUTURE_OPERATION_PRESENT'
> = {
  SUCCESS: ['Provozovatel byl úspěšně změněn.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při změně provozovatele.', SnackbarVariant.ERROR],
  COORDINATES_NOT_UNIQUE: [
    'Chyba při změně provozovatele. Provozovatel již má přiřazenou provozovnu se stejnými souřadnicemi.',
    SnackbarVariant.ERROR,
  ],
  FUTURE_OPERATION_PRESENT: [
    'Chyba při změně provozovatele. V daném datu již vazba na provozovatele existuje.',
    SnackbarVariant.ERROR,
  ],
};

const EU_STATE_CHANGE: MessageType = {
  SUCCESS: ['Stav EU byl úspěšně změněn.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při změně stavu EU.', SnackbarVariant.ERROR],
};

const SPLIT: MessageType<'SUCCESS' | 'ERROR' | 'COORDINATES_NOT_UNIQUE'> = {
  SUCCESS: ['Provozovny byly úspěšně rozděleny.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při rozdělení provozoven.', SnackbarVariant.ERROR],
  COORDINATES_NOT_UNIQUE: [
    'Chyba při rozdělení provozoven. Provozovatel již má přiřazenou provozovnu se stejnými souřadnicemi.',
    SnackbarVariant.ERROR,
  ],
};

const MERGE: MessageType<
  | 'SUCCESS'
  | 'ERROR'
  | 'COORDINATES_NOT_UNIQUE'
  | 'REQUEST_DATE_CANT_BE_IN_FUTURE'
  | 'FUTURE_OPERATION_PRESENT'
> = {
  SUCCESS: ['Provozovny byly úspěšně sloučeny.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při slučování provozoven.', SnackbarVariant.ERROR],
  COORDINATES_NOT_UNIQUE: [
    'Chyba při slučování provozoven. Provozovatel již má přiřazenou provozovnu se stejnými souřadnicemi.',
    SnackbarVariant.ERROR,
  ],
  REQUEST_DATE_CANT_BE_IN_FUTURE: ['Datum nesmí být v budoucnu.', SnackbarVariant.ERROR],
  FUTURE_OPERATION_PRESENT: [
    'Nelze sloučit provozovny, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
};

const REQUEST_DEACTIVATE_SUBMIT: MessageType<'ERROR'> = {
  ERROR: [
    'Chyba při vytváření žádosti o zneplatnění provozovny.',
    SnackbarVariant.ERROR,
  ],
};

export default {
  ACTIVATE,
  DEACTIVATE,
  OPERATOR_CHANGE,
  EU_STATE_CHANGE,
  MERGE,
  SPLIT,
  REQUEST_DEACTIVATE_SUBMIT,
  UPDATE,
  CREATE,
};

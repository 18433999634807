import React from 'react';
import { FormPanel, FormTextArea, FormDateField } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';

export function StepOtherInformationContent() {
  const todayISO = new Date().toISOString();

  return (
    <FormPanel label="Ostatní informace">
      <FormDateField
        name="date"
        label={
          <HelpLabel label="Datum sloučení" code="IRZ_FACILITY_MERGE_DATE" />
        }
        maxDate={todayISO}
      />
      <FormTextArea
        name="note"
        label={<HelpLabel label="Poznámka" code="IRZ_FACILITY_MERGE_NOTE" />}
      />
    </FormPanel>
  );
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Ozo messages
 */
const GET_FOREIGN_SUBJECT: MessageType<'SUCCESS' | 'MISSING' | 'ERROR'> = {
  SUCCESS: ['Subjekt OZO byl úspěšně vyhledán.', SnackbarVariant.SUCCESS],
  MISSING: [
    'Zahraniční subjekt neexistuje. Subjekt je potřeba před vytvořením oprávnění zaregistrovat.',
    SnackbarVariant.ERROR,
  ],
  ERROR: [
    'Chyba při vyhledání zahraničního subjektu OZO.',
    SnackbarVariant.ERROR,
  ],
};

const CREATE: MessageType<'SUCCESS' | 'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'DUPLICATE_OZO_PERMISSION_FOUND'> = {
  SUCCESS: ['Oprávnění OZO bylo úspěšně vytvořeno.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při vytváření oprávnění OZO.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  DUPLICATE_OZO_PERMISSION_FOUND: ['Oprávnění se stejným číslem a platností již existuje.', SnackbarVariant.ERROR],
};


const UPDATE: MessageType<'DUPLICATE_OZO_PERMISSION_FOUND' | 'ERROR'> = {
  ERROR: ['Chyba při editaci záznamu.', SnackbarVariant.ERROR],
  DUPLICATE_OZO_PERMISSION_FOUND: ['Oprávnění se stejným číslem a platností již existuje.', SnackbarVariant.ERROR],
};

const DEACTIVATE: MessageType = {
  SUCCESS: ['Oprávnění bylo úspěšně zneplatněno.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zneplatnění oprávnění.', SnackbarVariant.ERROR],
};

const ACTIVATE: MessageType<'OZO_SUBJECT_NOT_APPROVED' | 'ERROR' | 'SUCCESS'> = {
  SUCCESS: ['Oprávnění bylo úspěšně obnoveno.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při obnovení oprávnění.', SnackbarVariant.ERROR],
  OZO_SUBJECT_NOT_APPROVED: ['Nelze obnovit oprávnění s doposud neregistrovaným subjektem OZO.', SnackbarVariant.ERROR],
};

export default { GET_FOREIGN_SUBJECT, CREATE, DEACTIVATE, ACTIVATE, UPDATE };

import { useAutocompleteSource } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { DictionaryDeposit } from './../../models';

export function useDeposits() {
  return useAutocompleteSource<DictionaryDeposit>({
    url: `${EvidenceAPI.DICTIONARY_DEPOSITS}/autocomplete`,
    apiUrl: EvidenceAPI.DICTIONARY_DEPOSITS,
  });
}

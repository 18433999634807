import React, { useState, useContext, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ProcessActionsProps } from '../../../components/process/process-types';
import {
  useEventCallback,
  abortableFetch,
  FormContext,
  SnackbarContext,
  AbortableFetch,
  useFormSelector,
} from '@eas/common-web';
import { ProcessForm } from '../boiler-permission-new-types';
import { EvidenceAPI, Messages } from '../../../enums';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StepOzoSubjectForm } from './step-ozo-subject-types';
import { SubjectType } from '../../../models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

/**
 * Find foreign subject api call
 *
 * @param idNumber
 */
function callApi(idNumber: string) {
  return abortableFetch(
    `${EvidenceAPI.BOILER_PERMISSIONS}/find-foreign-subject?artificialId=${idNumber}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    }
  );
}

export function StepOzoSubjectActions({
  handleNext,
  handleBack,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Loading state.
   */
  const [loading, setLoading] = useState(false);

  /**
   * Context stuff.
   */
  const { setFieldValue } = useContext(FormContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { idNumber, ozoSubjectType } = useFormSelector(
    (values: StepOzoSubjectForm) => ({
      idNumber: values.idNumber,
      ozoSubjectType: values.ozoSubjectType,
    })
  );

  /**
   * Fetch reference.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleNextWithCheck = useEventCallback(async () => {
    try {
      setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }
      if (ozoSubjectType === SubjectType.FOREIGN_NATURAL_PERSON && idNumber) {
        fetch.current = callApi(idNumber!);
        const subjectId = await fetch.current.text();
        if (subjectId) {
          setFieldValue('ozoSubjectId', subjectId);
          handleNext();
        } else {
          showSnackbar(...Messages.OzoPermission.GET_FOREIGN_SUBJECT.MISSING);
        }
      } else {
        handleNext();
      }
    } catch (err) {
      if (err.name !== 'AbortError') {
        showSnackbar(...Messages.OzoPermission.GET_FOREIGN_SUBJECT.ERROR);
      }
      return undefined;
    } finally {
      setLoading(false);
    }
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithCheck}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Další
        </Button>
        <Button
          disabled={true}
          onClick={handleBack}
          className={classes.button}
          disableElevation
        >
          Předchozí
        </Button>
      </div>
    </div>
  );
}

import React from 'react';
import { ProcessForm } from '../irz-facility-request-operator-change-types';

export function StepSummaryLabel({ allValues }: { allValues?: ProcessForm }) {
  const state = allValues?.isSent ? 'ČEKÁ NA SCHVÁLENÍ' : 'NEODESLÁNO';

  return (
    <>
      Stav žádosti: <b>{state}</b>
    </>
  );
}

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { DictionaryProcessingPurpose } from '../../models';
import {
  useProcessingPurposeTypes,
  useProcessingPurposeDomains,
} from './dict-processing-purposes-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export function useColumns(): TableColumn<DictionaryProcessingPurpose>[] {
  return [
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useProcessingPurposeTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.useSelectCellFactory(useProcessingPurposeTypes),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'domain',
      sortkey: 'domain.name',
      filterkey: 'domain.id',
      name: 'Oblast',
      width: 200,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useProcessingPurposeDomains
      ),

      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      valueMapper: TableCells.useSelectCellFactory(useProcessingPurposeDomains),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

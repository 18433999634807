import React from 'react';
import { FormPanel } from '@eas/common-web';
import { SubjectAutocomplete } from '../../../models';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useOperator } from '../../irz-facility-request-new/step-basic-info/step-basic-info-hook';
import { FormPreFilledAutocomplete } from '../../../components/form/pre-filled-autocomplete/pre-filled-autocomplete';
import { HelpLabel } from '../../../components/help/help-label';

export function StepNewOperatorContent() {
  const subjectSource = useOperator();

  return (
    <FormPanel label="Vyplňte">
      <FormPreFilledAutocomplete<SubjectAutocomplete>
        name="newOperator"
        label={
          <HelpLabel
            label="Provozovatel"
            code="IRZ_FACILITY_REQUEST_OPERATOR_CHANGE_NEW_OPERATOR"
          />
        } // Subjekt automaticky přidělen
        source={subjectSource}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

/**
 * Production Site permissions
 */
export enum PermissionProductionSite {
  /** Přehled průmyslových míst */
  PRODUCTION_SITE_LIST = 'PRODUCTION_SITE_LIST',

  /** Autocomplete výber hodnot průmyslových míst */
  PRODUCTION_SITE_AUTOCOMPLETE = 'PRODUCTION_SITE_AUTOCOMPLETE',

  /** Detail průmyslového místa */
  PRODUCTION_SITE_DETAIL = 'PRODUCTION_SITE_DETAIL',

  /** Vytvoření průmyslového místa */
  PRODUCTION_SITE_CREATE = 'PRODUCTION_SITE_CREATE',

  /** Editace průmyslového místa */
  PRODUCTION_SITE_UPDATE = 'PRODUCTION_SITE_UPDATE',

  /** Zneplatnění průmyslového místa */
  PRODUCTION_SITE_INVALIDATE = 'PRODUCTION_SITE_INVALIDATE',

  /** Obnovení průmyslového místa */
  PRODUCTION_SITE_REVALIDATE = 'PRODUCTION_SITE_REVALIDATE',

  /** Rozdělení průmyslového místa */
  PRODUCTION_SITE_SPLIT = 'PRODUCTION_SITE_SPLIT',

  /** Sloučení průmyslového místa */
  PRODUCTION_SITE_MERGE = 'PRODUCTION_SITE_MERGE',

  /** Export průmyslových míst */
  PRODUCTION_SITE_EXPORT = 'PRODUCTION_SITE_EXPORT',
}

import { validateEmailNotRequired } from './../../profile/profile-utils';
import * as Yup from 'yup';
import { StepInfoForm } from './step-info-types';
import { DictionaryBoilerBrand, DictionaryBoilerType } from '../../../models';
import { addressFieldSchema } from '../../../components/form/address-field/address-field-schema';
import { validatePhoneNotRequired } from '../../profile/profile-utils';

export const stepInfoSchema = () =>
  Yup.object<StepInfoForm>().shape({
    boilerBrand: Yup.mixed<DictionaryBoilerBrand>()
      .nullable()
      .required('Povinné pole'),
    boilerTypes: Yup.array<DictionaryBoilerType>()
      .nullable()
      .min(1, 'Zadejte alespoň 1 typ kotle')
      .required('Povinné pole'),
    address: addressFieldSchema({ addressPlace: { required: true } }),
    ozoIco: Yup.object({
      value: Yup.string().nullable().matches(/\d{8}/, 'Nesprávný formát'),
    }).nullable(),
    validFrom: Yup.string().nullable().required('Povinné pole'),
    validTo: Yup.string().when('withoutEnd', {
      is: (val) => !val,
      then: Yup.string()
        .nullable()
        .required('Zaznačte platnost na neurčito, nebo vyplňte platnost'),
    }),
    phoneNumber: validatePhoneNotRequired,
    email: Yup.object().shape({
      value: validateEmailNotRequired(),
    }),
    number: Yup.string().nullable().required('Povinné pole'),
  });

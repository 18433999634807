import React from 'react';
import { CompareProductionSiteProps } from './compare-production-site-types';
import { CompareText } from '../comparator-fields/compare-text';
import { CompareLocation } from '../comparator-fields/compare-location';
import { CompareTable } from '../comparator-fields/compare-table';
import { IrzFacility } from '../../models';

export function CompareProductionSiteFields({
  originalData,
  requestedData: requestData,
}: CompareProductionSiteProps) {
  return (
    <>
      <CompareText
        label="Identifikátor EU"
        originalValue={originalData?.inspireId}
        newValue={requestData?.inspireId}
      />
      <CompareText
        label="Název"
        originalValue={originalData?.name}
        newValue={requestData?.name}
      />
      <CompareText
        label="Jedinečný kód"
        originalValue={originalData?.uniqueCode}
        newValue={requestData?.uniqueCode}
      />
      <CompareTable<IrzFacility>
        label="Provozovny"
        originalValues={originalData?.facilities ?? []}
        newValues={requestData?.facilities ?? []}
        columns={[{ name: 'Provozovna', datakey: 'name', width: 300 }]}
        comparator={(f1, f2) => f1?.id === f2?.id}
      />

      <CompareText
        label="Provozovny"
        originalValue={originalData?.district?.name}
        newValue={requestData?.district?.name}
      />
      <CompareText
        label="Poznámka"
        originalValue={originalData?.note}
        newValue={requestData?.note}
      />
      <CompareLocation
        originalLocation={originalData?.wgs84Coordinates}
        newLocation={requestData?.wgs84Coordinates}
      />
    </>
  );
}

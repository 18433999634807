/**
 * Role permissions
 */
export enum PermissionRole {
  /** Přehled rolí */
  ROLE_LIST = 'ROLE_LIST',

  /** Autocomplete výber hodnot rolí */
  ROLE_AUTOCOMPLETE = 'ROLE_AUTOCOMPLETE',

  /** Detail role */
  ROLE_DETAIL = 'ROLE_DETAIL',

  /** Vytvoření role */
  ROLE_CREATE = 'ROLE_CREATE',

  /** Editace role */
  ROLE_UPDATE = 'ROLE_UPDATE',

  /** Zneplatnění role */
  ROLE_INVALIDATE = 'ROLE_INVALIDATE',

  /** Přiřazení role */
  ROLE_ASSIGN_RELATIONSHIP_ROLE = 'ROLE_ASSIGN_RELATIONSHIP_ROLE',

  /** Přiřazení bezpečnostní role */
  ROLE_ASSIGN_SECURITY_ROLE = 'ROLE_ASSIGN_SECURITY_ROLE',

  /** Přiřazení systémové role */
  ROLE_ASSIGN_SYSTEM_ROLE = 'ROLE_ASSIGN_SYSTEM_ROLE',

  /** Přiřazení uživatelské role */
  ROLE_ASSIGN_USER_ROLE = 'ROLE_ASSIGN_USER_ROLE',

  /** Odmazání role */
  ROLE_UNASSIGN_RELATIONSHIP_ROLE = 'ROLE_UNASSIGN_RELATIONSHIP_ROLE',

  /** Odmazání bezpečnostní role */
  ROLE_UNASSIGN_SECURITY_ROLE = 'ROLE_UNASSIGN_SECURITY_ROLE',

  /** Odmazání systémové role */
  ROLE_UNASSIGN_SYSTEM_ROLE = 'ROLE_UNASSIGN_SYSTEM_ROLE',

  /** Odmazání uživatelské role */
  ROLE_UNASSIGN_USER_ROLE = 'ROLE_UNASSIGN_USER_ROLE',

  /** Přehled přiřazených rolí */
  ROLE_INSTANCE_LIST = 'ROLE_INSTANCE_LIST',

  /** Přehled přiřazených vztahových rolí */
  ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE = 'ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE',

  /** Přehled přiřazených bezpečnostních rolí */
  ROLE_INSTANCE_LIST_SECURITY_ROLE = 'ROLE_INSTANCE_LIST_SECURITY_ROLE',

  /** Přehled přiřazených systémových rolí */
  ROLE_INSTANCE_LIST_SYSTEM_ROLE = 'ROLE_INSTANCE_LIST_SYSTEM_ROLE',

  /** Přehled přiřazených uživatelských rolí */
  ROLE_INSTANCE_LIST_USER_ROLE = 'ROLE_INSTANCE_LIST_USER_ROLE',

  /** Detail přiřazené role */
  ROLE_INSTANCE_DETAIL = 'ROLE_INSTANCE_DETAIL',

  /** Detail přiřazené vztahové role */
  ROLE_INSTANCE_DETAIL_RELATIONSHIP_ROLE = 'ROLE_INSTANCE_DETAIL_RELATIONSHIP_ROLE',

  /** Detail přiřazené bezpečnostní role */
  ROLE_INSTANCE_DETAIL_SECURITY_ROLE = 'ROLE_INSTANCE_DETAIL_SECURITY_ROLE',

  /** Detail přiřazené systémové role */
  ROLE_INSTANCE_DETAIL_SYSTEM_ROLE = 'ROLE_INSTANCE_DETAIL_SYSTEM_ROLE',

  /** Detail přiřazené uživatelské role */
  ROLE_INSTANCE_DETAIL_USER_ROLE = 'ROLE_INSTANCE_DETAIL_USER_ROLE',

  /** Autocomplete výber hodnot rolí - jenom vybrané role (atribut "assignsRoles"). Nedostupné pro role (přiřazuje se jiným mechanizmem) */
  ROLE_AUTOCOMPLETE_SELECTED = 'ROLE_AUTOCOMPLETE_SELECTED',

  /** Přiřazení role - jenom vybrané role (atribut "assignsRoles"). Nedostupné pro role (přiřazuje se jiným mechanizmem) */
  ROLE_ASSIGN_SELECTED = 'ROLE_ASSIGN_SELECTED',

  /** Odebrání role - jenom vybrané role (atribut "assignsRoles"). Nedostupné pro role (přiřazuje se jiným mechanizmem) */
  ROLE_UNASSIGN_SELECTED = 'ROLE_UNASSIGN_SELECTED',

  /** Přehled přiřazených rolí - jenom vybrané role (atribut "assignsRoles"). Nedostupné pro role (přiřazuje se jiným mechanizmem) */
  ROLE_INSTANCE_LIST_SELECTED = 'ROLE_INSTANCE_LIST_SELECTED',

  /** Detail přiřazené role - jenom vybrané role (atribut "assignsRoles"). Nedostupné pro role (přiřazuje se jiným mechanizmem) */
  ROLE_INSTANCE_DETAIL_SELECTED = 'ROLE_INSTANCE_DETAIL_SELECTED',
}

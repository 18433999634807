import React, { useContext } from 'react';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { ProcessForm } from '../subject-requests-types';
import { EvidenceAPI, EvidenceUrl } from '../../../enums';
import Button from '@material-ui/core/Button';
import {
  useEventCallback,
  FilesProvider,
  FormFileField,
  ActionButton,
  abortableFetch,
  FileRef,
  NavigationContext,
  EvidenceStateAction,
  UserContext,
} from '@eas/common-web';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { SubjectRegistrationType } from '../../../models';

export const useStyles = makeStyles({
  button: {
    minWidth: 300,
    whiteSpace: 'nowrap',
  },
  section: {
    display: 'flex',
    gap: '20px',
    marginTop: 20,
    alignItems: 'center',
  },
  delimeter: {
    marginTop: 20,
  },
  bold: { marginTop: 30, fontWeight: 800 },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});

interface Props {
  allValues: ProcessForm;
}

export function StepAuthorizationContent({ allValues }: Props) {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  const Delim = () => (
    <Typography variant="body1" className={classes.delimeter}>
      nebo
    </Typography>
  );

  return (
    <div className={classes.wrapper}>
      <>
        <DownloadSection allValues={allValues} />
        {!(
          user?.hasNiaExternalId &&
          allValues?.type ===
            SubjectRegistrationType?.NATURAL_PERSON_REGISTRATION
        ) && (
          <>
            <Typography variant="body1" className={classes.bold}>
              Nyní je nutné zaslanou žádost autorizovat jednou z následujících
              možností:
            </Typography>
            <SendToDataBoxSection allValues={allValues} />
            <Delim />
            <UploadSection allValues={allValues} />
            <Delim />
            <RedirectSection allValues={allValues} />
            <Typography variant="body1" className={classes.bold}>
              Autorizaci zaslané žádosti o registraci lze dokončit i kdykoliv
              později ve stejném kalendářním roce, ve kterém byla žádost podána.
              Žádost je k dispozici v systému CRŽP prostřednictvím záložky
              „Subjekt“ -&gt; „Přehled žádostí o registraci subjektu“.
            </Typography>
          </>
        )}
      </>
    </div>
  );
}

const DownloadSection = ({ allValues }: Props) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  const evidenceNumber = allValues?.subject?.evidenceNumber;

  const downloadLink = allValues?.subject
    ? `${EvidenceAPI.ARCHIVED_FILES}/${allValues.subject?.documentSignature?.signedFile?.id}`
    : undefined;

  return (
    <div className={classes.section}>
      {user?.hasNiaExternalId &&
      allValues?.type ===
        SubjectRegistrationType?.NATURAL_PERSON_REGISTRATION ? (
        <Typography variant="body1">
          Žádost o registraci{' '}
          {evidenceNumber ? `s ev. č. ${evidenceNumber}` : ''} byla úspěšně
          odeslána a na základě údajů z Identity občana automaticky
          autorizována. Dokument „Potvrzení registrace do CRŽP“ (autorizační
          potvrzení) je možné pro vlastní potřebu stáhnout přes „STÁHNOUT
          AUTORIZAČNÍ POTVRZENÍ“.
        </Typography>
      ) : (
        <Typography variant="body1">
          Žádost o registraci{' '}
          {evidenceNumber ? `s ev. č. ${evidenceNumber}` : ''} byla úspěšně
          odeslána a čeká na autorizaci. Dokument „Potvrzení registrace do CRŽP“
          (autorizační potvrzení) je možné stáhnout přes „STÁHNOUT AUTORIZAČNÍ
          POTVRZENÍ“.
        </Typography>
      )}
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        disableElevation
        href={downloadLink}
        // download={!!downloadLink ?? undefined}
      >
        Stáhnout autorizační potvrzení
      </Button>
    </div>
  );
};

const SendToDataBoxSection = ({ allValues }: Props) => {
  const classes = useStyles();

  const handleSendToDatabox = useEventCallback(() => {
    window.open(
      `https://www.mojedatovaschranka.cz/as/login?atsId=d03ec8392861488b&appToken=${allValues.subject?.documentSignature?.signedFile?.file?.id}`,
      '_blank'
    );
  });

  return (
    <>
      <div className={classes.section}>
        <Typography variant="body1">
          <b>1)</b> Odesláním dokumentu prostřednictvím datové schránky
          tlačítkem „ODESLAT DO DATOVÉ SCHRÁNKY“. Budete vyzváni k přihlášení se
          do Vaší datové schránky a následně vyberete z dostupných datových
          schránek schránku subjektu, který registrujete. Automaticky Vám bude
          založen koncept zprávy, kterou stačí pouze odeslat.
        </Typography>
        <Button
          variant="contained"
          onClick={handleSendToDatabox}
          component="label"
          color="primary"
          disableElevation
          className={classes.button}
        >
          Odeslat do datové schránky
        </Button>
      </div>
      <br />
      <Typography variant="body1">
        Případně můžete dokument poslat přímo z datové schránky. Stažený
        dokument zašlete jako přílohu datové zprávy bez elektronického podpisu,
        bez průvodních dopisů a jiných textových zpráv.
      </Typography>
    </>
  );
};

function callApi(id: string, documentId: string) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECT_REQUESTS}/${id}/authorize/signed-document/${documentId}`,
    {
      method: 'POST',
    }
  );
}

const UploadSection = ({ allValues }: Props) => {
  const classes = useStyles();
  const { navigate } = useContext(NavigationContext);

  const validationSchema = Yup.object().shape({
    documentId: Yup.mixed<FileRef>().nullable().required(),
  });

  return (
    <div className={classes.section}>
      <Typography variant="body1">
        <b>2)</b> Stažený dokument „Potvrzení registrace do CRŽP“ (tzv.
        autorizační potvrzení) podepište uznávaným nebo kvalifikovaným
        elektronickým podpisem a dokument nahrajte zpět do systému přes tlačítko
        „NAHRÁT“.
      </Typography>
      <ActionButton
        promptKey="UPLOAD_FILE"
        buttonLabel="Nahrát"
        dialogTitle="Potvrzení registrace do CRŽP"
        dialogText="Nahrajte podepsané autorizační potvrzení."
        ButtonComponent={({ label, ...props }) => (
          <Button
            {...props}
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
          >
            {label}
          </Button>
        )}
        apiCall={(data) =>
          callApi(
            allValues.subject!.id,
            (data as { documentId: FileRef })!.documentId!.id
          )
        }
        formValidationSchema={validationSchema}
        FormFields={() => (
          <FilesProvider url={EvidenceAPI.ARCHIVED_FILES}>
            <FormFileField
              name="documentId"
              labelOptions={{
                hide: true,
              }}
              layoutOptions={{
                noUnderline: true,
              }}
            />
          </FilesProvider>
        )}
        onSuccess={async () =>
          navigate(`${EvidenceUrl.SUBJECT_REQUESTS}`, true, {
            action: EvidenceStateAction.SHOW_ITEM,
            data: allValues!.subject!.id,
          })
        }
      />
    </div>
  );
};

const RedirectSection = ({ allValues }: Props) => {
  const classes = useStyles();

  const subjectRegistrationId = allValues?.subject?.id;
  const linkTarget = `${EvidenceUrl.SUBJECT_REQUESTS}/${
    subjectRegistrationId ?? `/${subjectRegistrationId}`
  }`;

  return (
    <div className={classes.section}>
      <Typography variant="body1">
        <b>3)</b> Stažený dokument „Potvrzení registrace do CRŽP“ (tzv.
        autorizační potvrzení) vytiskněte, podepište a odešlete poštou na adresu
        provozovatele systému - Česká informační agentura živnotního prostředí
        (CENIA) uvedenou vpravo dole na potvrzení.
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to={linkTarget}
        color="default"
        className={classes.button}
        disableElevation
      >
        Přejít na přehled žádostí
      </Button>
    </div>
  );
};

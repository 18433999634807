import React from 'react';
import { Evidence2, ApiFilterOperation } from '@eas/common-web';
import { OvzFacility, Subject, AgendaTag } from '../../models';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { ExportTags } from '../../enums/export-tags';
import { EntityType } from '../../enums/entity-type';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';
// import { useSpecialFilters } from '../subject/subjects-special-filters';
import { OzoSubjectsField } from './ozo-subjects-fields';
import { useColumns } from './ozo-subjects-columns';

export function OzoSubjects() {
  const permissions = usePermission<OvzFacility>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useDatedEvidence<Subject>({
    noteEntityType: EntityType.SUBJECT,
    identifier: PageKey.SUBJECTS,
    apiProps: {
      url: EvidenceAPI.SUBJECTS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled subjektů OZO',
      reportTag: ExportTags.SUBJECTS,
      defaultSorts: [],
      defaultPreFilters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'subjectAgendas.agenda.tags',
          value: AgendaTag.OZO_PERSON,
        },
      ],
      // specialFilters: useSpecialFilters(),
      // specialFiltersPerm: Permission.Subject.SUBJECT_LIST_ALL,
    },
    detailProps: {
      entityName: EntityName.SUBJECT,
      FieldsComponent: OzoSubjectsField,
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Subjekt OZO',
        subtitle: useSubTitle<Subject>((data) => data?.label ?? ''),
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * OVZ facility messages
 */
const ACTIVATE: MessageType = {
  SUCCESS: ['Provozovna byla úspěšně obnovena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při obnovení provozovny.', SnackbarVariant.ERROR],
};

const DEACTIVATE: MessageType & MessageType<'FUTURE_OPERATION_PRESENT'> = {
  SUCCESS: ['Provozovna byla úspěšně zneplatněna.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zneplatnění provozovny.', SnackbarVariant.ERROR],
  FUTURE_OPERATION_PRESENT: [
    'Provozovnu nelze zneplatnit, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
};

const OPERATOR_CHANGE: MessageType = {
  SUCCESS: ['Provozovatel byl úspěšně změněn.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při změně provozovatele.', SnackbarVariant.ERROR],
};

const DELETE: MessageType<'FUTURE_OPERATION_PRESENT'> = {
  FUTURE_OPERATION_PRESENT: [
    'Provozovnu nelze smazat, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
};

const UPDATE: MessageType<
  'NAME_NOT_UNIQUE' | 'FUTURE_OPERATION_PRESENT' | 'ERROR' | 'IRZ_FACILITY_NOT_VALID'
> = {
  NAME_NOT_UNIQUE: ['Název provozovny není unikátní', SnackbarVariant.ERROR],
  FUTURE_OPERATION_PRESENT: [
    'Provozovnu nelze upravit, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
  IRZ_FACILITY_NOT_VALID: ['Vybraná IRZ provozovna je neplatná.', SnackbarVariant.ERROR],
  ERROR: ['Chyba při ukládání změn', SnackbarVariant.ERROR],
};

export default { ACTIVATE, DEACTIVATE, OPERATOR_CHANGE, DELETE, UPDATE };

import React, { useMemo, useState, useEffect, useContext } from 'react';
import { noop, get } from 'lodash';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {
  FormPanel,
  FormCustomField,
  useFormSelector,
  TextField,
  composeRefs,
  FormTextField,
  FormContext,
} from '@eas/common-web';
import { Map } from '../../map/map';
import { MarkerLayer } from '../../map/marker-layer';
import { Marker } from '../../map/marker';
import { AddressField } from '../address-field/address-field';
import { LocationPanelProps } from './location-panel-types';
import { LocationPanelToolbar } from './location-panel-toolbar';
import { useEditLocationPanel } from './location-panel-edit-hook';
import { FormPanelText } from '../form-panel-summary/form-panel-text';
import { useAddressLabel } from '../address-field/hooks/address-label-hook';
import { useExpandableFormPanel } from '../../../composite/form-panel/expandable-form-panel-hook';
import { Address, Wgs84Coordinates } from '../../../models';
import { formatDistance, calculateDistance } from './location-utils';
import { HelpLabel } from '../../help/help-label';
import { MapEvidenceContext } from '../../evidence/map-evidence/map-context';
import { dd2dms } from '../../../utils/latlng';

const useStyles = makeStyles((theme) => ({
  distance: {
    lineHeight: 'inherit',
    padding: '0 2pt',
  },
  warning: {
    color: theme.palette.error.main,
  },
}));

export function LocationPanel({
  locationPanelLabel = 'Poloha',
  locationName,
  locationValue,
  addressPanelLabel = 'Adresa',
  addressName,
  address,
  showCoordinates = true,
  showMap = true,
  showAddress = true,
  disabled = false,
  disableForeignCountry = false,
  disableRuian = false,
  expandable = false,
  defaultExpanded = true,
  showCoordinatesDistance = true,
  Markers,
  showEtrs89Coordinates = false,
  etrs89CoordinatesPath = 'etrs89Coordinates',
  showLocationPanel = true,
  locationToolbarProps,
  prefillCoordinates = true,
  defaultZoom = 7,
}: LocationPanelProps) {
  const classes = useStyles();

  const mapContext = useContext(MapEvidenceContext);
  const { setFieldValue } = useContext(FormContext);

  const {
    addPoint,
    clearPoint,
    mapMode,
    setAddPointMode,
    clearAll,
    editing,
    canFillMap,
    fillMap,
    fillCoordinates,
    addressFieldRef,
  } = useEditLocationPanel({
    locationName,
    addressName,
    disableForeignCountry,
  });

  /**
   * Calculate distance from AddressPlace to wsg84 coors in map.
   *
   * In case of distance > 0,5km, show warning
   */
  const [distance, setDistance] = useState(0);

  const { lat, lon, mapCoors, addressPlaceCoors } = useFormSelector(
    (
      data: { [key: string]: Address } & { [key: string]: Wgs84Coordinates }
    ) => ({
      lat: get(data, locationName)?.lat,
      lon: get(data, locationName)?.lon,
      mapCoors: get(data, locationName),
      addressPlaceCoors: get(data, addressName as string)?.addressPlaceRuian
        ?.wgs84Coordinates,
    })
  );

  useEffect(() => {
    if (mapCoors && addressPlaceCoors) {
      const distance = calculateDistance(mapCoors, addressPlaceCoors);

      setDistance(distance);
    } else {
      setDistance(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapCoors, addressPlaceCoors]);

  const latText = useMemo(() => {
    if (!lat) {
      return '-';
    }

    const parsed = dd2dms(lat);
    return `${parsed.degrees}°${parsed.minutes}'${parsed.seconds}"`;
  }, [lat]);

  const lonText = useMemo(() => {
    if (!lon) {
      return '-';
    }

    const parsed = dd2dms(lon);
    return `${parsed.degrees}°${parsed.minutes}'${parsed.seconds}"`;
  }, [lon]);

  const addressLabel = useAddressLabel(address);
  const { ref: addressPanelRef } = useExpandableFormPanel();
  const { ref: locationPanelRef } = useExpandableFormPanel();

  const addressPanelComposedRef = composeRefs(
    mapContext?.addressPanelRef,
    addressPanelRef
  );
  const locationPanelComposedRef = composeRefs(
    mapContext?.locationPanelRef,
    locationPanelRef
  );

  return (
    <>
      <FormPanel
        label={addressPanelLabel}
        expandable={expandable}
        defaultExpanded={defaultExpanded}
        ref={
          expandable && !defaultExpanded ? addressPanelComposedRef : undefined
        }
        summary={address && <FormPanelText value={addressLabel} />}
      >
        {showAddress && addressName && (
          <AddressField
            ref={addressFieldRef}
            name={addressName}
            disabled={disabled}
            disableForeignCountry={disableForeignCountry}
            disableRuian={disableRuian}
            notifyPrefill={(addressPlace) => {
              if (addressPlace.wgs84Coordinates && prefillCoordinates) {
                setFieldValue(locationName, addressPlace.wgs84Coordinates);
              }
            }}
          />
        )}
        {showCoordinates && (
          <>
            <FormCustomField
              label={
                <HelpLabel
                  label="Zeměpisná šířka"
                  code="LOCATION_LATITUDE"
                  required
                />
              }
              name={`${locationName}.lat`}
            >
              <TextField value={latText} onChange={noop} disabled={true} />
            </FormCustomField>
            <FormCustomField
              label={
                <HelpLabel
                  label="Zeměpisná délka"
                  code="LOCATION_LONGITUDE"
                  required
                />
              }
              name={`${locationName}.lon`}
            >
              <TextField value={lonText} onChange={noop} disabled={true} />
            </FormCustomField>
          </>
        )}
        {showEtrs89Coordinates && (
          <>
            <FormTextField
              name={`${etrs89CoordinatesPath}.lat`}
              label={
                <HelpLabel
                  label="Zeměpisná šířka (ETRS-89)"
                  code="LOCATION_PANEL_ETRS_89_COORDINATES_LAT"
                />
              }
              disabled
            />
            <FormTextField
              name={`${etrs89CoordinatesPath}.lon`}
              label={
                <HelpLabel
                  label="Zeměpisná délka (ETRS-89)"
                  code="LOCATION_PANEL_ETRS_89_COORDINATES_LON"
                />
              }
              disabled
            />
          </>
        )}
        {showCoordinates && showCoordinatesDistance && (
          <FormCustomField
            name="warning"
            label="Vzdálenost bodu na mapě od adresního místa"
          >
            <Typography
              variant="body1"
              className={clsx(classes.distance, {
                [classes.warning]: distance > 500,
              })}
            >
              {distance > 500
                ? `Upozornění: Poloha provozovny je zadána ve větší vzdálenosti než 500m od adresního místa (${formatDistance(
                    distance
                  )})`
                : !addressPlaceCoors || !mapCoors
                ? 'Vzdálenost není možné určit'
                : formatDistance(distance)}
            </Typography>
          </FormCustomField>
        )}
      </FormPanel>

      {showLocationPanel && (
        <FormPanel
          label={locationPanelLabel}
          expandable={expandable}
          defaultExpanded={defaultExpanded}
          ref={
            expandable && !defaultExpanded
              ? locationPanelComposedRef
              : undefined
          }
        >
          {showMap && (
            <>
              <LocationPanelToolbar
                handleSetAddPointMode={setAddPointMode}
                handleClearPoint={clearPoint}
                handleClearAll={clearAll}
                handleFillAddress={addressFieldRef?.current?.prefillAddress}
                handleFillMap={fillMap}
                handleFillCoordinates={fillCoordinates}
                canFillMap={canFillMap}
                canFillAddress={!!locationValue}
                locationValue={locationValue}
                disabled={disabled}
                {...locationToolbarProps}
              />
              <FormCustomField
                labelOptions={{ hide: true }}
                layoutOptions={{ noUnderline: true }}
                name={locationName}
                // helpLabel="Povinné pole"
              >
                <Map
                  height={350}
                  onPointAdded={addPoint}
                  mode={mapMode}
                  defaultWgs84Position={locationValue}
                  defaultZoom={defaultZoom}
                >
                  <MarkerLayer enableClusterer={false}>
                    <Marker
                      lat={locationValue?.lat ?? ''}
                      lng={locationValue?.lon ?? ''}
                      draggable={editing}
                      onDragEnd={addPoint}
                    />
                    {Markers && <Markers />}
                  </MarkerLayer>
                </Map>
              </FormCustomField>
            </>
          )}
        </FormPanel>
      )}
    </>
  );
}

import { UserAutocomplete } from './../../../models/user';
import { WaterManagementDecisionAutocomplete } from './../../../models/water-management-decision';
import { DictionaryAutocomplete } from '../../../models/dictionary-object';
import { AgendaAutocomplete } from '../../../models/agenda';

export function dictionaryLabelMapper(value: DictionaryAutocomplete) {
  if (value.label) {
    return value.label;
  } else if (value.code) {
    return `${value.code} - ${value.name}`;
  } else if (value.name) {
    return value.name;
  }

  return '-';
}

export function dictionaryNoCodeLabelMapper(value: DictionaryAutocomplete) {
  if (value.label) {
    return value.label;
  } else if (value.name) {
    return value.name;
  }

  return '-';
}

export function userLabelMapper(value: UserAutocomplete) {
  if (value.label) {
    return value.label;
  }
  if (value.name && value.username) {
    return `${value.name} (${value.username})`;
  }

  return '-';
}

export function agendaLabelMapper(agenda: AgendaAutocomplete) {
  return agenda.name;
}

export function waterManagementDecisionLabelMapper(
  decision: WaterManagementDecisionAutocomplete
) {
  if (decision.referenceNumber) {
    return decision.referenceNumber;
  } else if (decision.label) {
    return decision.label;
  }

  return '-';
}

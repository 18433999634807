import { UserContext } from '@eas/common-web';
import { useContext, useMemo } from 'react';
import { useIrzFacilities } from '../../irz-facility/irz-facilities-api';
import { useUserIrzFacilities } from '../irz-facility-request-change-api';

export function useStepFacility() {
  const userFacilitiesSource = useUserIrzFacilities();
  const allFacilitiesSource = useIrzFacilities();

  const { hasPermission } = useContext(UserContext);

  const isAdmin = useMemo(
    () => hasPermission('ADMINISTRATOR') || hasPermission('CENIA_ADMIN'),
    [hasPermission]
  );

  return isAdmin ? allFacilitiesSource : userFacilitiesSource;
}

import { TableColumn, TableCells } from '@eas/common-web';
import { DictionaryInfo } from '../../models/dictionary-info';

export function useColumns(): TableColumn<DictionaryInfo>[] {
  return [
    {
      datakey: 'source',
      name: 'Zdroj',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    // {
    //   datakey: 'description',
    //   name: 'Popis',
    //   width: 300,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 1,
    // },

    {
      datakey: 'lastUpdate',
      name: 'Poslední aktualizace',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

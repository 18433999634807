import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { historyValueMapper } from '../../components/evidence/history-evidence/history-utils';
import {
  useDictionaryDecisionTypes,
  useDecisionTypeGroups,
} from '../dict-decision-type/dict-decision-types-api';
import {
  useBasinEnterprises,
  useWaterUsagePlaces,
} from '../water-usage-place/water-usage-places-api';
import { useWaterManagementDecisions } from '../water-management-decision/water-management-decisions-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const types = useDictionaryDecisionTypes();
  const typeGroups = useDecisionTypeGroups();
  const basinEnterprises = useBasinEnterprises();
  const waterUsagePlaces = useWaterUsagePlaces();
  const waterManagementDecisions = useWaterManagementDecisions();

  return [
    {
      key: 'ppId.id',
      label: 'ID v databázi PP',
    },
    {
      key: 'ppId.enterprise',
      label: 'Podnik povodí',
      source: basinEnterprises,
    },
    {
      key: 'decision',
      label: 'Rozhodnutí',
      source: waterManagementDecisions,
    },
    {
      key: 'waterUsagePlace',
      label: 'Místo užívání vody',
      source: waterUsagePlaces,
    },
    {
      key: 'type',
      label: 'Druh rozhodnutí',
      source: types,
    },
    {
      key: 'typeGroup',
      label: 'Skupina typu rozhodnutí',
      source: typeGroups,
    },
    {
      key: 'validFrom',
      label: 'Datum platnosti: od',
    },
    {
      key: 'validTo',
      label: 'Datum platnosti: do',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'mostValid',
      label:
        'Příznak zda-li se jedná o "nejplatnější" rozhodnutí (k aktuálnímu dni)',
    },
    {
      key: 'limits.thousandM3PerYear',
      label: 'tis. m3 za rok',
    },
    {
      key: 'limits.thousandM3PerMonth',
      label: 'tis. m3 za měsíc',
    },
    {
      key: 'limits.m3PerDayAverage',
      label: 'm3 za den průměrně',
    },
    {
      key: 'limits.m3PerDayMax',
      label: 'm3 za den maximálně',
    },
    {
      key: 'limits.litrePerSecondAverage',
      label: 'litrů za sekundu průměrně',
    },
    {
      key: 'limits.litrePerSecondMax',
      label: 'litrů za sekundu maximálně',
    },
    {
      key: 'indicatorRecords',
      label: 'Hodnota ukazatele při vypouštění vody',
      valueMapper: (change) =>
        historyValueMapper(change, getIndicationRecordLabel),
    },
  ];
}

function getIndicationRecordLabel(key: string) {
  switch (key) {
    case 'p':
      return 'Hodnota "p"';
    case 'm':
      return 'Hodnota "m"';
    case 'balance':
      return 'Bilance';
    case 'balanceUnit':
      return 'Jednotka bilance';
    case 'indicator':
      return 'Ukazatel';
    case 'rate':
      return 'Četnost';
    case 'sampleType':
      return 'Typ vzorku';
    default:
      return key;
  }
}

import React from 'react';
import { PublicListDataSource } from '../../models/public-list';
import { publicListToolbarButtonFactory } from '../../composite/public-list-toolbar-button/public-list-toolbar-button';

const PublicListToolbarButton = publicListToolbarButtonFactory({
  dataSource: PublicListDataSource.OVZ_EVIDENCE,
  name: 'Veřejný seznam provozoven OVZ',
});

export function OvzFacilitiesTableToolbar() {
  return (
    <>
      <PublicListToolbarButton />
    </>
  );
}

import React, { useContext, useRef } from 'react';
import * as Yup from 'yup';
import { isToday } from 'date-fns';
import {
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailContext,
  FormTextArea,
  FormDateField,
  abortableFetch,
  UserContext,
  PromptContext,
  usePrompts,
  DetailHandle,
} from '@eas/common-web';
import { unstable_batchedUpdates } from 'react-dom';
import { EvidenceAPI, Permission, Messages } from '../../../enums';
import { Empowerment } from '../../../models';

/**
 * Api call
 *
 * @param id
 * @param values
 */
export function callApi(
  id: string,
  values: { note?: string; validTo?: string }
) {
  return abortableFetch(`${EvidenceAPI.EMPOWERMENTS}/${id}/active`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
    body: JSON.stringify(values),
  });
}

const today = new Date();
today.setHours(0, 0, 0, 0);

/**
 * Zneplatnění zmocnění
 */
const PROMPT_KEY = 'DEACTIVATE_EMPOWERMENT';

export function useDeactivateDialog() {
  /**
   * Context stuff.
   */
  const { source, onPersisted } = useContext<DetailHandle<Empowerment>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Zneplatnit zmocnění',
      dialogText:
        'Skutečně chcete zneplatnit zmocnění? Tato akce je nevratná. Pokud ano, vyplňte pole poznámka.',
      FormFields: function Body() {
        return (
          <>
            <FormTextArea
              name="note"
              label="Poznámka"
              helpLabel="Povinné pole"
            />
            <FormDateField
              name="validTo"
              label="Datum"
              helpLabel="Datum ukončení platnosti. Pokud pole zůstane nevyplněné, zmocnění se zneplatní okamžitě."
            />
          </>
        );
      },
      formValidationSchema: Yup.object().shape({
        note: Yup.mixed().nullable().required('Povinné pole'),
        validTo: Yup.date().min(
          today,
          'Datum zneplatnění nemůže být v minulosti.'
        ),
      }),
      dialogWidth: 600,
    },
  ]);

  const handleDeactivate = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { note?: string; validTo?: string }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          if (values?.validTo && isToday(new Date(values.validTo))) {
            values.validTo = undefined;
          }

          fetch.current = callApi(source.data!.id, values);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Empowerment.DEACTIVATE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Empowerment.DEACTIVATE.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const isValid = Boolean(source?.data?.active);
  const canInvalidate =
    hasPermission(Permission.Empowerment.EMPOWERMENT_INVALIDATE_ALL) ||
    hasPermission(Permission.Empowerment.EMPOWERMENT_INVALIDATE_OWN, {
      subjectId: source.data?.empowerer?.id,
    }) ||
    hasPermission(Permission.Empowerment.EMPOWERMENT_INVALIDATE_OWN, {
      subjectId: source.data?.agent?.id,
    });
  const willBeValidInTheFuture = source?.data?.validFrom
    ? new Date(source?.data?.validFrom) > new Date()
    : false;

  const showDeactivateButton =
    (isValid || willBeValidInTheFuture) && canInvalidate;

  return {
    handleDeactivate,
    showDeactivateButton,
  };
}

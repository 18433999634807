import React from 'react';
import { FormPanel, FormTextField, FormTextArea } from '@eas/common-web';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { HelpLabel } from '../../components/help/help-label';

export function ThirdPartySystemsFields(props: NotedEvidenceFieldsProps) {
  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="name"
          label={
            <HelpLabel label="Název" code="THIRD_PARTY_SYSTEM_NAME" required />
          }
          // Povinné pole
        />
        <FormTextArea
          name="note"
          label={<HelpLabel label="Poznámka" code="THIRD_PARTY_SYSTEM_NOTE" />}
        />
      </FormPanel>
      <FormPanel label="Kontaktní osoba">
        <FormTextField
          name="contact.name"
          label={
            <HelpLabel label="Jméno" code="THIRD_PARTY_SYSTEM_CONTACT_NAME" />
          }
        />
        <FormTextField
          name="contact.email"
          label={
            <HelpLabel label="E-mail" code="THIRD_PARTY_SYSTEM_CONTACT_EMAIL" />
          }
        />
        <FormTextField
          name="contact.phone"
          label={
            <HelpLabel
              label="Telefon"
              code="THIRD_PARTY_SYSTEM_CONTACT_PHONE"
            />
          }
        />
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

import React, {
  useContext,
  forwardRef,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { stubFalse, noop } from 'lodash';
import Typography from '@material-ui/core/Typography';
import {
  abortableFetch,
  UserContext,
  FileRef,
  Dialog,
  DialogHandle,
  useFormSelector,
  TableFieldColumn,
  TableFieldCells,
  TableField,
  useEventCallback,
  AbortableFetch,
  SnackbarContext,
  EvidenceContext,
} from '@eas/common-web';
import { useSubjectIdentificators } from '../../subject/subjects-api';
import { getErrorMessage } from '../../../utils/get-message';
import { Me, PermissionsState, SubjectIdentificator } from '../../../models';
import { EvidenceAPI, Permission, Messages } from '../../../enums';

interface ResultDialogProps {
  title: ReactNode;
  content: ReactNode;
}

interface ResultDTO {
  subjectIdentifierType?: SubjectIdentificator;
  identityDocumentNumber?: string;
  ozoIco?: string;
  permissionNumber?: string;
  validFrom?: string;
  validTo?: string;
  existingSubjectName?: string;
}

const useResultDialog = (
  callConfirmApi: () => AbortableFetch,
  callCancelApi: () => AbortableFetch
) =>
  forwardRef<DialogHandle, ResultDialogProps>(function ResultDialog(
    { title, content },
    ref
  ) {
    const { tableRef } = useContext(EvidenceContext);
    const { showSnackbar } = useContext(SnackbarContext);

    const fetch = useRef<AbortableFetch | null>(null);

    const [loading, setLoading] = useState(false);

    const handleConfirmResult = useEventCallback(async () => {
      try {
        setLoading(true);
        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callConfirmApi();
        await fetch.current.raw();

        unstable_batchedUpdates(() => {
          showSnackbar(...Messages.Ozo.BULK_PERMISSION_IMPORT.SUCCESS);
          tableRef.current?.refresh();
        });

        setLoading(false);
      } catch (err) {
        if (err) {
          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.Ozo.BULK_PERMISSION_IMPORT,
              err.code
            );

            showSnackbar(...message);
          }
          setLoading(false);
          return undefined;
        }
      }
    });

    const handleCancelResult = useEventCallback(async () => {
      try {
        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callCancelApi();
        await fetch.current.raw();
      } catch (err) {
        return undefined;
      }
    });

    return (
      <Dialog
        ref={ref}
        title={title}
        onConfirm={handleConfirmResult}
        onCancel={handleCancelResult}
        showClose={true}
        loading={loading}
      >
        {() => <>{content}</>}
      </Dialog>
    );
  });

export function useBulkImportDialog() {
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const fileId = useRef<string | null>(null);

  const callImportApi = (dryrun: boolean, file?: FileRef) => {
    if (dryrun && file) {
      fileId.current = file.id;
    }

    return abortableFetch(
      `${EvidenceAPI.BOILER_PERMISSIONS}/bulk${dryrun ? '/dryrun' : ''}?file=${
        fileId.current
      }`,
      {
        method: 'POST',
      }
    );
  };

  const callDeleteFile = () =>
    abortableFetch(`${EvidenceAPI.FILES}/${fileId.current}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

  const canPerformBulkImport =
    hasPermission(Permission.Ozo.OZO_PERMISSION_UPDATE_OWN, {
      searchOnlyInSubjectPerms: true,
    }) || hasPermission(Permission.Ozo.OZO_PERMISSION_UPDATE_ALL);

  const ResultDialog = useResultDialog(
    () => callImportApi(false),
    callDeleteFile
  );

  return {
    callImportApi,
    canPerformBulkImport,
    ResultDialog,
  };
}

export const ResultFormFields = () => {
  const data = useFormSelector((data: ResultDTO[]) => data);

  const columns: TableFieldColumn<ResultDTO>[] = [
    {
      datakey: 'subjectIdentifierType',
      name: 'Typ dokladu OZO',
      CellComponent: TableFieldCells.useSelectCellFactory(
        useSubjectIdentificators
      ),
      width: 200,
    },
    {
      datakey: 'identityDocumentNumber',
      name: 'Číslo dokladu OZO',
      width: 200,
    },
    {
      datakey: 'ozoIco',
      name: 'IČO OZO',
      width: 200,
    },
    {
      datakey: 'existingSubjectName',
      name: 'Název subjektu',
      width: 200,
    },
    {
      datakey: 'permissionNumber',
      name: 'Číslo oprávnění',
      width: 200,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost oprávnění od',
      CellComponent: TableFieldCells.DateCell,
      width: 200,
    },
    {
      datakey: 'validTo',
      name: 'Platnost oprávnění do',
      CellComponent: TableFieldCells.DateCell,
      width: 200,
    },
  ];

  return (
    <>
      {data?.length > 0 && (
        <>
          <Typography variant="body1">
            Pokud si přejete importovat tato oprávnění, klikněte na POTVRDIT.
          </Typography>
          <TableField
            value={data}
            showToolbar={false}
            showRadioCond={stubFalse}
            onChange={noop}
            columns={columns}
          />
        </>
      )}
      {data?.length === 0 && (
        <Typography variant="body1">
          Nebyla nalezena žádná oprávnění k importu.
        </Typography>
      )}
    </>
  );
};

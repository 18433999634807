import React, { useContext, useRef } from 'react';
import * as Yup from 'yup';
import {
  useEventCallback,
  SnackbarContext,
  DetailContext,
  UserContext,
  DetailHandle,
  abortableFetch,
  AbortableFetch,
  PromptContext,
  usePrompts,
  FormSelect,
  FormNumberField,
} from '@eas/common-web';
import { Permission, EvidenceAPI, Messages } from '../../../enums';
import { IrzFacility } from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { EuState } from '../../../models';
import { useEuStates } from '../irz-facilities-api';
import { HelpLabel } from '../../../components/help/help-label';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call
 *
 * @param id
 */
export function apiCall(
  facilityId: string,
  euStateRecord: { euState?: EuState; year?: string }
) {
  return abortableFetch(
    `${EvidenceAPI.IRZ_FACILITIES}/${facilityId}/eu-status`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify({
        euState: euStateRecord.euState,
        year: euStateRecord.year,
      }),
    }
  );
}

function useValidationSchema() {
  return Yup.object().shape({
    euStateRecord: Yup.object().shape({
      euState: Yup.mixed().nullable().required('Povinné pole'),
      year: Yup.mixed().nullable().required('Povinné pole'),
    }),
  });
}

const PROMPT_KEY = 'EU_STATE_CHANGE';

export function useEuStateChangeDialog() {
  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, onPersisted } = useContext<DetailHandle<IrzFacility>>(
    DetailContext
  );
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Změna stavu EU',
      dialogText: '',
      FormFields,
      formValidationSchema: useValidationSchema(),
      dialogWidth: 600,
    },
  ]);

  const handleEuStateChange = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async ({
        euStateRecord,
      }: {
        euStateRecord: {
          euState: EuState;
          year: string;
        };
      }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = apiCall(source.data!.id, euStateRecord);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.IrzFacility.EU_STATE_CHANGE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.IrzFacility.EU_STATE_CHANGE,
              err.code
            );

            showSnackbar(...message);
          }

          return true;
        }
      },
    });
  });

  const showEuStateChangeButton =
    hasPermission(Permission.IrzFacility.IRZ_UPDATE_EU_STATUS) &&
    Boolean(source.data?.active);

  return {
    showEuStateChangeButton,
    handleEuStateChange,
  };
}

function FormFields() {
  const euStates = useEuStates();

  return (
    <>
      <FormNumberField
        name="euStateRecord.year"
        label={
          <HelpLabel
            label="Rok podání EU"
            code="IRZ_FACILITY_REQUEST_EU_STATE_CHANGE_DIALOG_YEAR"
            required
          />
        }
        disabled={false}
      />
      <FormSelect
        name="euStateRecord.euState"
        label={
          <HelpLabel
            label="Stav EU"
            code="IRZ_FACILITY_REQUEST_EU_STATE_CHANGE_DIALOG_EU_STATE"
            required
          />
        }
        source={euStates}
        valueIsId={true}
        disabled={false}
        tooltipMapper={dictionaryLabelMapper}
        labelMapper={dictionaryLabelMapper}
      />
    </>
  );
}

import React from 'react';
import {
  BooleanHistoryField as BooleanHistoryFieldProps,
  HistoryFieldType,
} from '../../../../models';
import { HistoryFieldProps } from '../history-types';
import { SimpleHistoryField } from './simple-history-field';

export function BooleanHistoryField({
  valueAfter,
  valueBefore,
}: HistoryFieldProps<BooleanHistoryFieldProps>) {
  return (
    <SimpleHistoryField
      valueAfter={
        valueAfter
          ? {
              type: HistoryFieldType.STRING,
              value: valueAfter?.value ? 'Ano' : 'Ne',
            }
          : undefined
      }
      valueBefore={
        valueBefore
          ? {
              type: HistoryFieldType.STRING,
              value: valueBefore?.value ? 'Ano' : 'Ne',
            }
          : undefined
      }
    />
  );
}

import React from 'react';
import {
  FormTextField,
  FormPanel,
  FormCheckbox,
  FormTableField,
  TableFieldCells,
  EmptyComponent,
  FormCustomField,
  useFormSelector,
  TextArea,
} from '@eas/common-web';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import { HelpLabel } from '../../components/help/help-label';
import { WebService } from '../../models';

export function WebServicesFields() {
  const { wsdlUrls, htmlUrls } = useFormSelector((data: WebService) => ({
    wsdlUrls: data.wsdlUrls,
    htmlUrls: data.htmlUrls,
  }));

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="name"
          label={<HelpLabel label="Název služby" code="WEB_SERVICES_NAME" />}
        />
        <FormCheckbox
          name="status.database"
          label={
            <HelpLabel
              label="Stav - databáze"
              code="WEB_SERVICES_STATUS_DATABASE"
            />
          }
        />
        <FormCheckbox
          name="status.elasticSearch"
          label={
            <HelpLabel
              label="Stav - elasticsearch"
              code="WEB_SERVICES_STATUS_ELASTIC_SEARCH"
            />
          }
        />
        <FormCheckbox
          name="status.mainModule"
          label={
            <HelpLabel
              label="Stav - hlavní modul"
              code="WEB_SERVICES_STATUS_MAIN_MODULE"
            />
          }
        />
        <FormCheckbox
          name="enabled"
          label={
            <HelpLabel label="Služba je zapnutá" code="WEB_SERVICES_ENABLED" />
          }
        />
      </FormPanel>
      <FormPanel label="URL adresy">
        <FormTextField
          name="soapUrl"
          label={<HelpLabel label="SOAP" code="WEB_SERVICES_SOAP_URL" />}
        />
        <FormCustomField
          name="htmlUrls"
          label={<HelpLabel label="HTML" code="WEB_SERVICES_HTML_URLS" />}
        >
          <TextArea disabled value={htmlUrls?.join(', ')} />
        </FormCustomField>
        <FormCustomField
          name="wsdlUrl"
          label={<HelpLabel label="WSDL" code="WEB_SERVICES_WSDL_URLS" />}
        >
          <TextArea disabled value={wsdlUrls?.join(', ')} />
        </FormCustomField>
      </FormPanel>
      <FormPanel
        label="Historie volání"
        expandable
        summary={<FormPanelCountByName name="wsExecutions" />}
      >
        <FormTableField
          name="wsExecutions"
          labelOptions={{
            hide: true,
          }}
          layoutOptions={{
            noSpacing: true,
          }}
          maxRows={15}
          showRadioCond={() => false}
          showDetailBtnCond={() => false}
          visibleActionsColumn={false}
          ToolbarComponent={EmptyComponent}
          columns={[
            {
              name: 'Datum a čas volání',
              datakey: 'created',
              CellComponent: TableFieldCells.DateTimeCell,
              width: 200,
            },
            {
              name: 'Název operace',
              datakey: 'localPart',
              width: 200,
            },
            {
              name: 'Vykonavatel operace',
              datakey: 'createdBy.name',
              width: 200,
            },
          ]}
        />
      </FormPanel>
    </>
  );
}

import * as Yup from 'yup';
import React, { useContext } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {
  TableActionButton,
  FormFileField,
  SnackbarContext,
} from '@eas/common-web';
import { publicListToolbarButtonFactory } from '../../composite/public-list-toolbar-button/public-list-toolbar-button';
import { getErrorMessage } from '../../utils/get-message';
import { mailingToolbarButtonFactory } from '../../composite/mailing-toolbar-button/mailing-toolbar-button';
import {
  useBulkImportDialog,
  ResultFormFields,
} from './dialog-actions/bulk-import-hook';
import { RecipientsSource } from '../../models/mail-campaign';
import { PublicListDataSource } from '../../models/public-list';
import { Messages } from '../../enums';

const PublicListToolbarButton = publicListToolbarButtonFactory({
  dataSource: PublicListDataSource.OZO_P_EVIDENCE,
  name: 'Veřejný seznam oprávnění OZO',
});

const MailingToolbarButton = mailingToolbarButtonFactory({
  source: RecipientsSource.OZO_PERMISSION,
});

export function BoilerPermissionsTableToolbar() {
  const { showSnackbar } = useContext(SnackbarContext);
  const {
    canPerformBulkImport,
    callImportApi,
    ResultDialog,
  } = useBulkImportDialog();

  return (
    <>
      <PublicListToolbarButton />
      <MailingToolbarButton />
      {canPerformBulkImport && (
        <TableActionButton
          promptKey="BULK_IMPORT"
          buttonLabel={<PublishIcon />}
          buttonTooltip="Hromadný import oprávnění"
          dialogTitle="Hromadný import oprávnění"
          dialogText={
            <>
              <Typography variant="body1">Požadovaný formát: </Typography>
              <Typography variant="body1">
                <ul style={{ marginLeft: '20px' }}>
                  <li>Typ souboru: .xlsx</li>
                  <li>První řádek obsahuje hlavičku</li>
                  <li>
                    Soubor obsahuje sloupce: <br />
                    Typ dokladu OZO, Číslo dokladu OZO, IČO OZO, Email, Tel.
                    předvolba, Telefon, Kód adresního místa, Číslo oprávnění,
                    Platnost oprávnění od, Platnost oprávnění do, Značka, Kódy
                    typu kotle oddělené čárkou
                  </li>
                  <li>
                    <Link
                      download
                      target="_blank"
                      href="/sablona-ozo-import-opravneni.xlsx"
                    >
                      Stáhnout šablonu
                    </Link>
                  </li>
                </ul>
              </Typography>
              <br />
              <Typography variant="body1">
                Dokončete import nahráním vyplněné šablony.
              </Typography>
            </>
          }
          FormFields={() => (
            <FormFileField
              name="file"
              accept={['.xlsx']}
              labelOptions={{ hide: true }}
            />
          )}
          dialogWidth={600}
          formValidationSchema={Yup.object().shape({
            file: Yup.mixed().required('Soubor je povinný'),
          })}
          onError={async (err) => {
            const message = getErrorMessage(
              Messages.Ozo.BULK_PERMISSION_IMPORT,
              err.code
            );

            showSnackbar(...message);
          }}
          resultDialog={{
            title: 'Import oprávnění',
            FormFields: ResultFormFields,
            DialogComponent: ResultDialog,
          }}
          apiCall={(params, selected, FormData) =>
            callImportApi(true, FormData.file)
          }
        />
      )}
    </>
  );
}

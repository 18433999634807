import { SubjectType } from '../../models';
import {
  useStaticListSource,
  DictionaryAutocomplete,
  useListSource,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useSubjectTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: SubjectType.LEGAL_ENTITY, name: 'Právnická osoba' },
    {
      id: SubjectType.LEGAL_ENTITY_WITHOUT_ICO,
      name: 'Právnická osoba bez IČO',
    },
    { id: SubjectType.NATURAL_PERSON, name: 'Fyzická osoba' },
    {
      id: SubjectType.BUSINESS_NATURAL_PERSON,
      name: 'Fyzická osoba podnikající',
    },
    {
      id: SubjectType.FOREIGN_LEGAL_ENTITY,
      name: 'Zahraniční právnická osoba',
    },
    {
      id: SubjectType.FOREIGN_NATURAL_PERSON,
      name: 'Zahraniční fyzická osoba',
    },
  ]);
}

export function useAuthorizationMethods() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.SUBJECT_REQUESTS}/authorization-method/list`,
    method: 'GET',
  });
}

export function useSubjectRegistrationState() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.SUBJECTS + '/registration/state/list',
    method: 'GET',
  });
}

import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { EvidenceAPI } from '../../enums';
import { useFormSelector } from '@eas/common-web';
import { DirpRecord } from '../../models';
import { makeStyles } from '@material-ui/core/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const useStyles = makeStyles({
  blankScreen: {
    position: 'absolute',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 186px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    overflowX: 'auto',
  },
});

export function DirpRecordsFileViewer() {
  const classes = useStyles();

  const [numPages, setNumPages] = useState<number | null>(null);

  const { verdict } = useFormSelector((data: DirpRecord) => ({
    verdict: data.verdict,
  }));

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div className={classes.wrapper}>
      <Document
        file={{
          url: `${EvidenceAPI.FILES}/${verdict?.id}`,
        }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {[...Array(numPages)].map((el, index) => (
          <Page key={`page_${index}`} width={900} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
}

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { useScriptTypes } from '../schedule-api';
import { ScheduleJob } from '../../../models';
import { inactiveColoredFactory } from '../../../components/form/inactive-colored/inactive-colored';

export function useColumns(
  canSeeDeactivated: boolean
): TableColumn<ScheduleJob>[] {
  const columns: TableColumn<ScheduleJob>[] = [
    {
      datakey: 'timer',
      name: 'Časovač',
      width: 150,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory<ScheduleJob>(TableCells.TextCell),
      FilterComponent: TableFilterCells.FilterTextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'running',
      name: 'Běží',
      width: 100,
      CellComponent: inactiveColoredFactory<ScheduleJob>(
        TableCells.BooleanCell
      ),
      sortable: false,
      filterable: false, // neni možno filtrovat a sortovat, protože informace se dopočítavá za běhu
    },
    {
      datakey: 'useTransaction',
      name: 'Spustit skript v transakci',
      width: 100,
      CellComponent: inactiveColoredFactory<ScheduleJob>(
        TableCells.BooleanCell
      ),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'last',
      name: 'Poslední spuštění',
      width: 150,
      CellComponent: inactiveColoredFactory<ScheduleJob>(
        TableCells.DateTimeCell
      ),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: false,
      filterable: false, // neni možno filtrovat a sortovat, protože informace se dopočítavá za běhu
      filterGroup: 10,
    },
    {
      datakey: 'next',
      name: 'Příští spuštění',
      width: 150,
      CellComponent: inactiveColoredFactory<ScheduleJob>(
        TableCells.DateTimeCell
      ),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: false,
      filterable: false, // neni možno filtrovat a sortovat, protože informace se dopočítavá za běhu
      filterGroup: 10,
    },
    {
      datakey: 'scriptType',
      sortkey: 'scriptType.name',
      filterkey: 'scriptType.id',
      name: 'Skriptovací jazyk',
      width: 150,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory<ScheduleJob>(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useScriptTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useScriptTypes),
      sortable: true,
      filterable: true,
    },
  ];

  if (canSeeDeactivated) {
    columns.push({
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory<ScheduleJob>(
        TableCells.BooleanCell
      ),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
    });
  }

  return columns;
}

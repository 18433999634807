import React, { useContext, useMemo } from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormTextField,
  FormPanel,
  FormSelect,
  FormContext,
  useFormSelector,
  FormTextArea,
  FormAutocomplete,
  DetailContext,
  useUpdateEffect,
  FormCheckbox,
  DetailMode,
  FormCheckboxGroup,
  FormCustomField,
  TextArea,
  Checkbox,
} from '@eas/common-web';
import {
  usePermissions,
  useRoleTypes,
  useSubjectRoles,
  useRoles,
  useCreateableRoleTypes,
  useSecurityRoles,
  Permission,
} from './roles-api';
import { Role, RoleType, RoleAutocomplete } from '../../models';
import {
  dictionaryNoCodeLabelMapper,
  dictionaryLabelMapper,
} from '../../components/form/mappers/label-mappers';
import { useSystems } from '../system/systems-api';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { HelpLabel } from '../../components/help/help-label';
import { FormPanelCount } from '../../components/form/form-panel-summary/form-panel-count';

const useStyles = makeStyles(() => ({
  generateButton: {
    padding: '0px 20px',
  },
}));

const idMapper = (item: Permission) => item.code;
const labelMapper = (item: Permission) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <span style={{ fontWeight: 600 }}>{item.label}</span>
    <span>{`- ${item.description}`}</span>
  </div>
);

const subjectRoleLabelMapper = (role: Role) =>
  role?.label ?? `${role.name} (${role.registeredFor?.name})`;

const getTypeLabel = (type?: RoleType) => {
  switch (type) {
    case RoleType.RELATIONSHIP_ROLE:
      return 'VZ';
    case RoleType.SECURITY_ROLE:
      return 'BEZ';
    case RoleType.SUBJECT_ROLE:
      return 'SUB';
    case RoleType.SYSTEM_ROLE:
      return 'SYS';
    case RoleType.USER_ROLE:
      return 'UZ';
  }
};

export function RolesFields(props: NotedEvidenceFieldsProps) {
  const classes = useStyles();

  const permissions = usePermissions();
  const types = useRoleTypes();
  const createableTypes = useCreateableRoleTypes();
  const subjectRoles = useSubjectRoles();
  const securityRoles = useSecurityRoles();
  const systems = useSystems();

  const { isExisting, mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  const roles = useRoles();

  const data = useFormSelector((data: Role) => ({
    type: data.type,
    permissions: data.permissions,
    registeredFor: data.registeredFor,
    name: data.name,
    onlyAdminCanAssign: data.onlyAdminCanAssign,
  }));

  useUpdateEffect(() => {
    if (mode === DetailMode.EDIT || mode === DetailMode.NEW) {
      setFieldValue('relationship', null);
      setFieldValue('parentSubjectRole', null);
      setFieldValue('rppAttributes.activityRole', null);
      setFieldValue('rppAttributes.agenda', null);
      setFieldValue('rppAttributes.legalReference', null);
    }
  }, [data.type]);

  const showPermissions = useMemo(() => {
    return (
      data.type &&
      data.type !== RoleType.SUBJECT_ROLE &&
      data.registeredFor?.crzp
    );
  }, [data.registeredFor?.crzp, data.type]);

  const normalizeAndCapitalizeText = (text: string) =>
    text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '_')
      .toUpperCase();

  const generateCode = () => {
    const formattedSystem = normalizeAndCapitalizeText(
      data?.registeredFor?.label ?? ''
    );

    const formattedName = normalizeAndCapitalizeText(data.name ?? '');

    setFieldValue(
      'code',
      `ROLE_${formattedSystem}_${getTypeLabel(data?.type)}_${formattedName}`
    );
  };

  return (
    <>
      <FormPanel label="Role">
        <FormTextField
          name="name"
          label={<HelpLabel label="Název" code="ROLE_NAME" required />}
          // Povinné pole
        />
        {mode === DetailMode.VIEW && (
          <FormCheckbox
            name="active"
            label={<HelpLabel label="Aktivní" code="ROLE_ACTIVE" />}
            disabled
          />
        )}
        <FormSelect
          name="type"
          label={<HelpLabel label="Typ" code="ROLE_TYPE" required />} // Povinné pole
          source={isExisting ? types : createableTypes}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled={isExisting}
        />
        <FormTextArea
          name="description"
          label={<HelpLabel label="Popis" code="ROLE_DESCRIPTION" />}
        />
        <FormAutocomplete
          name="registeredFor"
          label={
            <HelpLabel
              label="Systém, který eviduje danou roli"
              code="ROLE_REGISTERED_FOR"
              required
            />
          } // Povinné pole
          source={systems}
          disabled={isExisting}
          labelMapper={dictionaryNoCodeLabelMapper}
          tooltipMapper={dictionaryNoCodeLabelMapper}
        />
        <FormTextField
          name="code"
          label={<HelpLabel label="Kód" code="ROLE_CODE" required />} // Povinné pole
          after={
            DetailMode.NEW === mode && (
              <Button
                onClick={generateCode}
                size="small"
                startIcon={<SyncIcon />}
                className={classes.generateButton}
                disabled={!data.type || !data.registeredFor || !data.name}
              >
                Generovat
              </Button>
            )
          }
        />
        {mode === DetailMode.VIEW && (
          <FormAutocomplete
            name="assignerRoles"
            disabled
            label={
              <HelpLabel
                label="Dodatečné přiřazující role pro tuto roli"
                code="ROLE_ASSIGNER_ROLES"
              />
            }
            source={roles}
            multiple
          />
        )}
        <FormAutocomplete
          name="assignsRoles"
          label={
            <HelpLabel
              label="Role, které může tato rola přiřazovat bez oprávnění"
              code="ROLE_ASSIGNS_ROLES"
            />
          }
          source={roles}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          multiple
        />
        {data.type === RoleType.SUBJECT_ROLE && (
          <FormCheckbox
            name="stateAdministrationRole"
            label={
              <HelpLabel
                label="Příznak, zda-li se jedná o roli pro výkon státní správy"
                code="ROLE_STATE_ADMINISTRATION_ROLE"
              />
            }
          />
        )}
        {data.type === RoleType.USER_ROLE && (
          <>
            <FormAutocomplete<RoleAutocomplete>
              name="parentSubjectRole"
              label={
                <HelpLabel
                  label="Nadřazená subjektová role"
                  code="ROLE_PARENT_SUBJECT_ROLE"
                  required
                />
              } // Povinné pole
              source={subjectRoles}
              labelMapper={subjectRoleLabelMapper}
              tooltipMapper={subjectRoleLabelMapper}
            />
            <FormTextField
              name="rppAttributes.activityRole"
              label={
                <HelpLabel
                  label="Činnostní role RPP"
                  code="ROLE_RPP_ATTRIBUTES_ACTIVITY_ROLE"
                />
              }
            />
            <FormTextField
              name="rppAttributes.agenda"
              label={
                <HelpLabel
                  label="Agenda dle RPP"
                  code="ROLE_RPP_ATTRIBUTES_AGENDA"
                />
              }
            />
            <FormTextField
              name="rppAttributes.legalReference"
              label={
                <HelpLabel
                  label="Zákonná reference"
                  code="ROLE_RPP_ATTRIBUTES_LEGAL_REFERENCE"
                />
              }
            />
            <FormCheckbox
              name="onlyAdminCanAssign"
              label={
                <HelpLabel
                  label="Může přidělit jen admin"
                  code="ROLE_ONLY_ADMIN_CAN_ASSIGN"
                />
              }
              notifyChange={(value) => {
                if (value === false) {
                  setFieldValue('allowedToRequest', false);
                }
              }}
            />
            <FormCheckbox
              name="autoAssignToSubjectAdmin"
              label={
                <HelpLabel
                  label="Automatické přiřazení role k správci subjektu"
                  code="ROLE_AUTO_ASSIGN_TO_SUBJECT_ADMIN"
                />
              }
            />
            <FormCheckbox
              name="userConsentRequired"
              label={
                <HelpLabel
                  label="Vyžaduj souhlas s nahlížením na data"
                  code="ROLE_USER_CONSENT_REQUIRED"
                />
              }
            />
            {data.onlyAdminCanAssign && (
              <FormCheckbox
                name="allowedToRequest"
                label={
                  <HelpLabel
                    label="Příznak, zda-li je možné o tuto roli zažádat"
                    code="ROLE_USER_CONSENT_ALLOWED_TO_REQUEST"
                  />
                }
              />
            )}
            <FormCheckbox
              name="allowSepnoDetails"
              label={
                <HelpLabel
                  label="Umožnit nastavit detaily SEPNO"
                  code="ROLE_ONLY_ADMIN_ALLOW_SEPNO_DETAILS"
                />
              }
            />
          </>
        )}
        {data.type === RoleType.SECURITY_ROLE && (
          <>
            <FormAutocomplete
              name="includedRoles"
              label={
                <HelpLabel label="Agregované role" code="ROLE_INCLUDED_ROLES" />
              }
              source={securityRoles}
              labelMapper={dictionaryLabelMapper}
              tooltipMapper={dictionaryLabelMapper}
              multiple
            />
            <FormTextField
              name="rppAttributes.activityRole"
              label={
                <HelpLabel
                  label="Činnostní role RPP"
                  code="ROLE_RPP_ATTRIBUTES_ACTIVITY_ROLE"
                />
              }
            />
            <FormTextField
              name="rppAttributes.agenda"
              label={
                <HelpLabel
                  label="Agenda dle RPP"
                  code="ROLE_RPP_ATTRIBUTES_AGENDA"
                />
              }
            />
            <FormTextField
              name="rppAttributes.legalReference"
              label={
                <HelpLabel
                  label="Zákonná reference"
                  code="ROLE_RPP_ATTRIBUTES_LEGAL_REFERENCE"
                />
              }
            />
            <FormCheckbox
              name="userConsentRequired"
              label={
                <HelpLabel
                  label="Vyžaduj souhlas s nahlížením na data"
                  code="ROLE_USER_CONSENT_REQUIRED"
                />
              }
            />
            <FormCheckbox
              name="allowedToRequest"
              label={
                <HelpLabel
                  label="Příznak, zda-li je možné o tuto roli zažádat"
                  code="ROLE_USER_CONSENT_ALLOWED_TO_REQUEST"
                />
              }
            />
          </>
        )}
      </FormPanel>
      {showPermissions && permissions && (
        <>
          {permissions.map((source) => {
            const permissionsCodes = source.permissions.items.map(
              (perm) => perm.code
            );

            const areAllSelected = permissionsCodes.every((perm) =>
              data.permissions?.includes(perm)
            );

            const handleAllPermissionsCheck = () => {
              if (areAllSelected) {
                setFieldValue(
                  'permissions',
                  data.permissions?.filter(
                    (perm) => !permissionsCodes.includes(perm)
                  )
                );
              } else {
                setFieldValue('permissions', [
                  ...(data.permissions ?? []),
                  ...permissionsCodes,
                ]);
              }
            };

            return (
              <React.Fragment key={source.code}>
                <FormPanel
                  label={`Oprávnění - ${source.label}`}
                  expandable={true}
                  defaultExpanded={false}
                  unmountOnExit={true}
                  summary={
                    <FormPanelCount
                      count={`${
                        permissionsCodes.filter((perm) =>
                          data.permissions?.includes(perm)
                        ).length
                      } / ${source.permissions.items.length}`}
                    />
                  }
                >
                  <FormCustomField label="Popis">
                    <TextArea value={source.description} disabled={true} />
                  </FormCustomField>
                  <FormCustomField label="Označit všechna oprávnění">
                    <Checkbox
                      value={areAllSelected}
                      onChange={handleAllPermissionsCheck}
                      disabled={mode === DetailMode.VIEW}
                    />
                  </FormCustomField>
                  <FormCheckboxGroup<Permission>
                    name="permissions"
                    label={
                      <HelpLabel label="Oprávnění" code="ROLE_PERMISSIONS" />
                    }
                    source={source.permissions}
                    idMapper={idMapper}
                    labelMapper={labelMapper}
                  />
                </FormPanel>
              </React.Fragment>
            );
          })}
        </>
      )}
      {props.HistoryRecords}
    </>
  );
}

import React from 'react';
import { ProcessForm } from '../irz-facility-request-operator-change-types';

export function StepNewOperatorLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const subject = allValues?.newOperator;

  return (
    <>
      Nový provozovatel: <b>{subject?.label}</b>
    </>
  );
}

import React from 'react';
import Typography from '@material-ui/core/Typography';

export function StepFinishContent() {
  return (
    <Typography variant="body1">
      Průmyslová místa byla úspěšně sloučena.
    </Typography>
  );
}

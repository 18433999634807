import React from 'react';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { DictionaryDepositsFields } from './dict-deposits-fields';
import { useValidationSchema } from './dict-deposits-schema';
import { useColumns } from './dict-deposits-columns';
import { DictionaryDeposit } from '../../models';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';

export function DictionaryDeposits() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission({
    options: {
      NEW: [Permission.Deposit.DEPOSIT_CREATE],
      EDIT: [Permission.Deposit.DEPOSIT_UPDATE],
      REMOVE: [Permission.Deposit.DEPOSIT_DELETE],
      CHECK: [
        Permission.Deposit.DEPOSIT_CREATE,
        Permission.Deposit.DEPOSIT_UPDATE,
      ],
      SAVE: [
        Permission.Deposit.DEPOSIT_UPDATE,
        Permission.Deposit.DEPOSIT_CREATE,
      ],
      CLOSE: [
        Permission.Deposit.DEPOSIT_UPDATE,
        Permission.Deposit.DEPOSIT_CREATE,
      ],
    },
  });

  const evidence = useDictionaryEvidence<DictionaryDeposit>({
    version: 2,
    identifier: PageKey.DICTIONARY_DEPOSITS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_DEPOSITS,
    },
    tableProps: {
      tableName: 'Ložiska',
      reportTag: ExportTags.DICTIONARY_DEPOSITS,
      columns: useColumns(),
      showColumn: (col) => !['ORDER', 'NAME', 'CODE'].includes(col),
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.DEPOSIT,
      codePrefix: 'DEPOSIT',
      validationSchema,
      hideGeneralFields: true,
      FieldsComponent: DictionaryDepositsFields,
      toolbarProps: {
        title: 'Ložisko',
        showBtn: permissions,
      },
    },
  });

  return <Evidence {...evidence} />;
}

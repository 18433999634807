import React, { useContext, useRef } from 'react';
import { noop } from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButtonAction,
  DetailHandle,
  DetailToolbarButtonType,
  useEventCallback,
  NavigationContext,
  DialogHandle,
  DetailActionbarButton,
} from '@eas/common-web';
import { publish } from './public-lists-api';
import { PublicList, PublicListState } from '../../models/public-list';
import { DictionaryActionbar } from '../../components/evidence/dictionary-evidence/dictionary-actionbar';
import { Permission, EvidenceAPI, EvidenceUrl } from '../../enums';
import { ExternalShareDialog } from './share-dialog/external-share-dialog';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function PublicListsActionbar() {
  const { mode, source } = useContext<DetailHandle<PublicList>>(DetailContext);

  const { navigate } = useContext(NavigationContext);

  const externalShareDeleteDialog = useRef<DialogHandle>(null);

  const showValues = useEventCallback(() => {
    const list = source.data;

    if (list != null) {
      navigate(EvidenceUrl.PUBLIC_RECORDS.replace(':listId', list.id));
    }
  });

  const showExternalShare = useEventCallback(() => {
    const list = source.data;

    if (list != null) {
      externalShareDeleteDialog.current?.open();
    }
  });

  const listId = source.data?.id;

  return (
    <>
      <DictionaryActionbar
        url={EvidenceAPI.PUBLIC_LISTS}
        invalidatePerm={Permission.PublicList.PUBLIC_LIST_INVALIDATE}
        revalidatePerm={Permission.PublicList.PUBLIC_LIST_REVALIDATE}
      />
      {mode === DetailMode.VIEW && (
        <DetailToolbarButtonAction
          ButtonComponent={DetailActionbarButton}
          buttonProps={{
            type: DetailToolbarButtonType.PRIMARY,
          }}
          promptKey="PUBLIC_LIST_PUBLISH"
          apiCall={publish}
          formInitialValues={{}}
          buttonLabel="Publikovat"
          buttonTooltip="Otevrě dialog s potvrzením"
          dialogTitle="Varování"
          dialogText="Skutečně chcete publikovat seznam ?"
        />
      )}
      {mode === DetailMode.VIEW &&
        source.data?.state === PublicListState.PUBLISHED &&
        source.data?.active && (
          <DetailActionbarButton
            label="Hodnoty"
            tooltip="Otevrě přehled publikovaného seznamu"
            onClick={showValues}
            type={DetailToolbarButtonType.NORMAL}
          />
        )}
      {mode === DetailMode.VIEW &&
        source.data?.state === PublicListState.PUBLISHED &&
        source.data?.active && (
          <DetailActionbarButton
            label="Externí odkaz"
            type={DetailToolbarButtonType.NORMAL}
            onClick={showExternalShare}
          />
        )}
      <ExternalShareDialog
        ref={externalShareDeleteDialog}
        onCancel={noop}
        listId={listId}
      />
    </>
  );
}

import React from 'react';
import { ProcessForm } from '../irz-facility-split-types';

export function StepMainFacilityLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const irzFacility = allValues?.mainFacility;

  return (
    <>
      Provozovna k rozdělení:{' '}
      <b>
        {irzFacility?.name} - {irzFacility?.facilityId}
      </b>
    </>
  );
}

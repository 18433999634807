import React from 'react';
import {
  FormPanel,
  FormInlineTableField,
  TableFieldCells,
} from '@eas/common-web';
import { useNotificationTypes } from '../notification-templates/notification-templates-api';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';

export function NotificationBatchesFields() {
  return (
    <>
      <FormPanel
        label="Notifikace"
        summary={<FormPanelCountByName name="notifications" />}
      >
        <FormInlineTableField
          name="notifications"
          columns={[
            {
              name: 'Příjemce',
              datakey: 'recipient.name',
              width: 200,
            },
            {
              name: 'Předmět',
              datakey: 'subject',
              width: 200,
              CellComponent: function Cell({ value }) {
                return (
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                );
              },
            },
            {
              name: 'Přečteno',
              datakey: 'read',
              width: 100,
              CellComponent: TableFieldCells.BooleanCell,
            },
            {
              name: 'Typ',
              datakey: 'type',
              width: 200,
              CellComponent: TableFieldCells.useSelectCellFactory(
                useNotificationTypes
              ),
            },
          ]}
          labelOptions={{
            hide: true,
          }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}

import React, { useContext, useEffect } from 'react';
import {
  FormPanel,
  FormTextField,
  FormDateField,
  FormTextArea,
  FormCheckbox,
  useFormSelector,
  FormContext,
} from '@eas/common-web';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { FormPreFilledAutocomplete } from '../../../components/form/pre-filled-autocomplete/pre-filled-autocomplete';
import { HelpLabel } from '../../../components/help/help-label';
import { useOperator } from './step-basic-info-hook';
import { StepBasicInfoForm } from './step-basic-info-types';
import {
  SubjectAutocomplete,
  LegalEntity,
  BusinessNaturalPerson,
} from '../../../models';

export function StepBasicInfoContent() {
  const operatorSource = useOperator();
  const { setFieldValue } = useContext(FormContext);
  const { withoutWebSite, operator } = useFormSelector(
    (data: StepBasicInfoForm) => ({
      withoutWebSite: data?.withoutWebSite,
      operator: data?.operator,
    })
  );
  const hasOperatorIco = operator && 'ico' in operator;

  useEffect(() => {
    const website =
      withoutWebSite && hasOperatorIco
        ? `https://wwwinfo.mfcr.cz/cgi-bin/ares/sh_reg.cgi?ico=${
            (operator as LegalEntity | BusinessNaturalPerson).ico
          }`
        : '';

    setFieldValue('webSite', website);
  }, [withoutWebSite, operator]);

  return (
    <FormPanel label="Vyplňte">
      <FormPreFilledAutocomplete<SubjectAutocomplete>
        name="operator"
        label={
          <HelpLabel
            label="Provozovatel"
            code="IRZ_FACILITY_REQUEST_NEW_OPERATOR"
            required
          />
        } // Povinné pole
        source={operatorSource}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
        // preFilledHelpLabel={'Subjekt automaticky přidělen'}
      />
      <FormTextField
        name="name"
        label={
          <HelpLabel
            label="Název provozovny"
            code="IRZ_FACILITY_REQUEST_NEW_NAME"
            required
          />
        } // Povinné pole
      />
      {hasOperatorIco && (
        <FormCheckbox
          name="withoutWebSite"
          label={
            <HelpLabel
              label="Nemá web stránku"
              code="IRZ_FACILITY_REQUEST_NEW_WITHOUT_WEB_SITE"
            />
          }
        />
      )}
      <FormTextField
        name="webSite"
        label={
          <HelpLabel
            label="Web stránka"
            code="IRZ_FACILITY_REQUEST_NEW_WEB_SITE"
            required={hasOperatorIco}
          />
        } // Povinné pole, musí být platný odkaz url
      />
      <FormTextField
        name="publicEmail"
        label={
          <HelpLabel
            label="Veřejný e-mail"
            code="IRZ_FACILITY_REQUEST_NEW_PUBLIC_EMAIL"
          />
        }
      />
      <FormTextField
        name="publicPhoneNumber"
        label={
          <HelpLabel
            label="Veřejný telefon"
            code="IRZ_FACILITY_REQUEST_NEW_PUBLIC_PHONE_NUMBER"
          />
        }
      />
      <FormTextArea
        name="note"
        label={
          <HelpLabel label="Poznámky" code="IRZ_FACILITY_REQUEST_NEW_NOTE" />
        }
      />
      <FormDateField
        name="commissioningDate"
        label={
          <HelpLabel
            label="Datum uvedení do provozu"
            code="IRZ_FACILITY_REQUEST_NEW_COMMISSIONING_DATE"
          />
        }
      />
    </FormPanel>
  );
}

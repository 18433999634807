import React from 'react';
import { ProcessForm } from '../irz-facility-request-operator-change-types';

export function StepFacilityLabel({ allValues }: { allValues?: ProcessForm }) {
  return (
    <>
      Provozovna: <b>{allValues?.facility?.label}</b>
    </>
  );
}

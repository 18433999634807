import React from 'react';
import { useConsentSnackbar } from './consent-snackbar-hook';
import { ConsentSnackbarContext } from './consent-snackbar-context';
import { ConsentSnackbarComponent } from './consent-snackbar';

export function ConsentSnackbarProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { context } = useConsentSnackbar();

  return (
    <ConsentSnackbarContext.Provider value={context}>
      <ConsentSnackbarComponent />
      {children}
    </ConsentSnackbarContext.Provider>
  );
}

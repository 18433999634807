import { createContext, Dispatch, SetStateAction } from 'react';
import { PanelHandle } from '@eas/common-web';

export enum MapEvidenceView {
  DETAIL = 1,
  MAP,
}

export interface MapContextType {
  view: MapEvidenceView;
  setView: Dispatch<SetStateAction<MapEvidenceView>>;
  withFilters: boolean;
  locationPanelRef: React.RefObject<PanelHandle>;
  addressPanelRef: React.RefObject<PanelHandle>;
}

export const MapEvidenceContext = createContext<MapContextType>(
  // tslint:disable-next-line: no-any
  undefined as any
);

import * as Yup from 'yup';
import { validateIdentityCard } from './../../subject/fields/subjects-utils';
import { StepOzoSubjectForm } from './step-ozo-subject-types';
import { SubjectType, DocumentType } from '../../../models';

export const stepOzoSubjectSchema = () =>
  Yup.object<StepOzoSubjectForm>().shape({
    ozoSubjectType: Yup.string().nullable().required('Povinné pole'),
    idNumber: Yup.string().when('identityDocumentType', {
      is: (type) => type === DocumentType.IDENTITY_CARD,
      then: validateIdentityCard,
      otherwise: Yup.string().nullable().required('Povinné pole'),
    }),
    identityDocumentType: Yup.mixed().when('ozoSubjectType', {
      is: SubjectType.NATURAL_PERSON,
      then: Yup.string().nullable().required('Povinné pole'),
    }),
  });

import React, { useContext } from 'react';
import {
  FormTextArea,
  FormLocalDateField,
  FormPanel,
  FormTextField,
  FormCheckbox,
  AuthoredFields,
  DatedFields,
  DetailContext,
  DetailMode,
  FormAutocomplete,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useDirpAreas } from '../dict-dirp-area/dict-dirp-areas-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';

export function DictionaryDirpLawsFields() {
  const { mode } = useContext(DetailContext);

  const areas = useDirpAreas();

  return (
    <FormPanel label="Obecné">
      <FormTextField
        name="code"
        label={
          <HelpLabel
            label="Číslo předpisu"
            code="DICT_DIRP_LAWS_CODE"
            required
          />
        }
        disabled={mode !== DetailMode.NEW}
      />
      <FormTextField
        name="name"
        label={
          <HelpLabel
            label="Název předpisu"
            code="DICT_DIRP_LAWS_NAME"
            required
          />
        }
      />
      <FormTextArea
        name="description"
        label={<HelpLabel label="Popis" code="DICT_DIRP_LAWS_DESCRIPTION" />}
      />
      <FormAutocomplete
        name="area"
        source={areas}
        label={<HelpLabel label="Oblast" code="DICT_DIRP_LAWS_AREA" />}
        labelMapper={dictionaryLabelMapper}
      />
      <FormLocalDateField
        name="dateIssued"
        label={
          <HelpLabel label="Datum vydání" code="DICT_DIRP_LAWS_DATE_ISSUED" />
        }
      />
      <FormLocalDateField
        name="effectiveFrom"
        label={
          <HelpLabel label="Účinnost od" code="DICT_DIRP_LAWS_EFFECTIVE_FROM" />
        }
      />
      <FormCheckbox
        name="active"
        label={<HelpLabel label="Aktivní" code="DICT_DIRP_LAWS_ACTIVE" />}
        disabled={true}
      />
      <AuthoredFields />
      <DatedFields />
    </FormPanel>
  );
}

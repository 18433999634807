import React from 'react';
import { RuianEvidenceProps } from './ruian-types';
import { useDatedEvidence } from '../dated-evidence/dated-evidence';
import { stubFalse } from 'lodash';
import { DetailEmptyActionbar } from '../evidence/detail-empty-actionbar';
import { createColumns } from './ruian-columns';
import { GeneralRuianFields, MunicipalityFormPanel } from './ruian-fields';
import { RuianObject } from '../../../models';
import { useMemo } from 'react';
import { EmptyComponent } from '@eas/common-web';

type RuianEvidenceOptions = {
  hasMunicipality: boolean;
};

const DEFAULT_RUIAN_OPTIONS: RuianEvidenceOptions = {
  hasMunicipality: false,
};

export function useRuianEvidence<OBJECT extends RuianObject>(
  options: RuianEvidenceProps<OBJECT>,
  { hasMunicipality }: RuianEvidenceOptions = DEFAULT_RUIAN_OPTIONS
) {
  const FieldsRuianComponent = useMemo(() => {
    const FieldsComponent =
      options.detailProps.FieldsComponent ?? EmptyComponent;

    return function Component() {
      return (
        <>
          <FieldsComponent />
          {hasMunicipality && <MunicipalityFormPanel />}
        </>
      );
    };
  }, [options.detailProps.FieldsComponent, hasMunicipality]);

  return useDatedEvidence({
    ...options,
    tableProps: {
      ...options.tableProps,
      columns: createColumns(options.tableProps),
    },
    detailProps: {
      ...options.detailProps,
      FieldsComponent: FieldsRuianComponent,
      GeneralFieldsComponent: GeneralRuianFields,

      toolbarProps: {
        ...options.detailProps?.toolbarProps,
        ActionBar: DetailEmptyActionbar,

        // Making RUIAN evidence read-only
        showBtn: stubFalse,
      },
    },
  });
}

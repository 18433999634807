import { MessageType } from './message-type';
import { SnackbarVariant } from '@eas/common-web';

const ADD_CERTIFICATE: MessageType<
  | 'SUCCESS'
  | 'ERROR'
  | 'DUPLICATE_ERROR'
  | 'INVALID_CERTIFICATE'
  | 'SYSTEM_NOT_FOUND'
> = {
  SUCCESS: ['Certifikát byl úspěšně přidán.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při přidávání certifikátu.', SnackbarVariant.ERROR],
  DUPLICATE_ERROR: [
    'Vybraný certifikát je již přiřazený tomuto nebo jinému systému.',
    SnackbarVariant.ERROR,
  ],
  INVALID_CERTIFICATE: ['Neplatný certifikát.', SnackbarVariant.ERROR],
  SYSTEM_NOT_FOUND: [
    'Systém s požadovaným id nebyl nalezen.',
    SnackbarVariant.ERROR,
  ],
};

const DEACTIVATE_CERTIFICATE: MessageType<
  'SUCCESS' | 'ERROR' | 'ALREADY_INACTIVE' | 'NOT_FOUND'
> = {
  SUCCESS: ['Certifikát byl úspěšně deaktivován.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při deaktivaci certifikátu.', SnackbarVariant.ERROR],
  ALREADY_INACTIVE: ['Certifikát je již neplatný.', SnackbarVariant.ERROR],
  NOT_FOUND: ['Certifikát nebyl nalezen.', SnackbarVariant.ERROR],
};

const ACTIVATE_CERTIFICATE: typeof DEACTIVATE_CERTIFICATE = {
  SUCCESS: ['Certifikát byl úspěšně aktivován.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při aktivaci certifikátu.', SnackbarVariant.ERROR],
  ALREADY_INACTIVE: ['Certifikát je již aktivovaný.', SnackbarVariant.ERROR],
  NOT_FOUND: ['Certifikát nebyl nalezen.', SnackbarVariant.ERROR],
};

const DELETE_CERTIFICATE: typeof DEACTIVATE_CERTIFICATE = {
  SUCCESS: ['Certifikát byl úspěšně odstraněn.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při odstranění certifikátu.', SnackbarVariant.ERROR],
  ALREADY_INACTIVE: ['Certifikát již byl odstraněn.', SnackbarVariant.ERROR],
  NOT_FOUND: ['Certifikát nebyl nalezen.', SnackbarVariant.ERROR],
};

const CREATE: MessageType<'INVALID_ENTITY_VALIDITY_RANGE' | 'ERROR'> = {
  ERROR: ['Chyba při vytváření systému.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: [
    'Konec platnosti nesmí být před začátkem platnosti.',
    SnackbarVariant.ERROR,
  ],
};

const UPDATE: MessageType<'INVALID_ENTITY_VALIDITY_RANGE' | 'ERROR'> = {
  ERROR: ['Chyba při editaci systému.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: [
    'Konec platnosti nesmí být před začátkem platnosti.',
    SnackbarVariant.ERROR,
  ],
};

export default {
  ADD_CERTIFICATE,
  DEACTIVATE_CERTIFICATE,
  ACTIVATE_CERTIFICATE,
  DELETE_CERTIFICATE,
  CREATE,
  UPDATE,
};

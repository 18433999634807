import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { usePreferredChannelTypes } from '../profile/profile-api';
import { historyValueMapper } from '../../components/evidence/history-evidence/history-utils';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'username',
      label: 'Uživatelské jméno',
    },
    {
      key: 'active',
      label: 'Příznak platnosti',
    },
    {
      key: 'validTo',
      label: 'Platný do',
    },
    {
      key: 'degreeBefore',
      label: 'Titul před',
    },
    {
      key: 'firstName',
      label: 'Jméno',
    },
    {
      key: 'lastName',
      label: 'Příjmení',
    },
    {
      key: 'degreeAfter',
      label: 'Titul za',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'personalDataProcessingConsent',
      label: 'Souhlas se zpracováním osobních údajů',
    },
    {
      key: 'mailingConsent',
      label: 'Souhlas se zasíláním kampaní',
    },
    {
      key: 'phoneNumber.prefix',
      label: 'Předvolba',
    },
    {
      key: 'phoneNumber.number',
      label: 'Telefonní číslo',
    },
    {
      key: 'password',
      label: 'Heslo',
    },
    {
      key: 'twoFactorDetails.email',
      label: 'Email (Dvoufaktorové ověření)',
    },
    {
      key: 'twoFactorDetails.phoneNumber.number',
      label: 'Telefonní číslo (Dvoufaktorové ověření)',
    },
    {
      key: 'twoFactorDetails.phoneNumber.prefix',
      label: 'Předvolba (Dvoufaktorové ověření)',
    },
    {
      key: 'twoFactorDetails.preferredChannel',
      label: 'Preferovaný způsob ověření (Dvoufaktorové ověření)',
      source: usePreferredChannelTypes(),
    },
    {
      key: 'securityRole',
      label: 'Změna bezpečnostní role',
    },
    {
      key: 'userRole',
      label: 'Změna uživatelské role',
      valueMapper: (change) => historyValueMapper(change, getRoleLabel),
    },
    {
      key: 'relationshipRole',
      label: 'Změna vztahové role',
      valueMapper: (change) => historyValueMapper(change, getRoleLabel),
    },
    {
      key: 'externalIds',
      label: 'Externí IdP',
      valueMapper: (change) =>
        historyValueMapper(
          change,
          getExternalIdLabel,
          (key) => key == 'externalId'
        ),
    },
    {
      key: 'systemAccount',
      label: 'Systémový účet',
    },
    {
      key: 'roleConsent',
      label: 'Souhlas s nahlížením na data',
    },
  ];
}

function getRoleLabel(key: string) {
  switch (key) {
    case 'subject':
      return 'Subjekt';
    case 'role':
      return 'Role';
  }
}

function getExternalIdLabel(key: string) {
  switch (key) {
    case 'identityProvider':
      return 'Poskytovatel identity';
  }
}

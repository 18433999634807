import React, { useMemo, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  useEventCallback,
  ApiFilterOperation,
  FormContext,
} from '@eas/common-web';
import { AddressFieldCell } from '../address-field-cell';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';
import { EvidenceAPI } from '../../../../enums';
import { Address } from '../../../../models';

export function CityDistrictCell() {
  const { code, name } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);
  const { setFieldValue } = useContext(FormContext);

  const createFilter = useEventCallback((address?: Address) => {
    if (address?.municipalityRuian?.id) {
      return [
        {
          field: 'municipality.id',
          operation: ApiFilterOperation.EQ,
          value: address?.municipalityRuian?.id,
        },
      ];
    } else {
      return [];
    }
  });

  const disabled = useMemo(
    () => (address?: Address) => !address?.municipalityRuian,
    []
  );

  const filterDeps = useEventCallback((address?: Address) => [
    address?.municipalityRuian?.id,
  ]);

  const notifyChange = useEventCallback((address?: Address) => {
    if (address?.addressPlaceRuian?.id) {
      unstable_batchedUpdates(() => {
        setFieldValue(`${name}.addressPlaceRuian`, undefined);
        setFieldValue(`${name}.houseNumberType`, undefined);
        setFieldValue(`${name}.houseNumber`, undefined);
        setFieldValue(`${name}.orientationNumber`, undefined);
        setFieldValue(`${name}.orientationNumberMark`, undefined);
        setFieldValue(`${name}.zip`, undefined);
      });
    }
  });

  return (
    <AddressFieldCell
      label={defaults?.cityDistrict?.label ?? 'Městská část'}
      required={defaults?.cityDistrict?.required ?? false}
      name="cityDistrictRuian"
      customName="cityDistrict"
      parentName="municipalityRuian"
      code={`${code}_CITY_DISTRICT`}
      url={EvidenceAPI.ADDRESS_DISCTRICT_CITY}
      notifyChange={notifyChange}
      parentUrl={EvidenceAPI.ADDRESS_MUNICIPALITY}
      createFilter={createFilter}
      filterDeps={filterDeps}
      disabled={disabled}
    />
  );
}

import { TableFieldColumn, InlineTableFieldCells } from '@eas/common-web';
import { useMemo } from 'react';

export function useIppcPidColumns(): TableFieldColumn<string>[] {
  const IppcPidCell = InlineTableFieldCells.useInlineTextFieldFactory<string>({
    collectionDatakey: 'integratedPermission.ippcPids',
    isSubkey: false,
  });

  return useMemo(
    () => [
      {
        name: 'PID (IPPC)',
        datakey: '',
        width: 600,
        CellComponent: IppcPidCell,
      },
    ],
    [IppcPidCell]
  );
}

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formBox: {
      width: '100%',
      border: '1px solid #cdcdcd',
      borderRadius: 6,
    },
    formHeader: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2.5),
      paddingBottom: theme.spacing(2),
      fontSize: 17,
    },
    formHeaderWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid #cdcdcd',
      alignItems: 'center',
    },
    bordered: {
      borderBottom: '1px solid #cdcdcd',
    },
    eventsHeader: {
      fontSize: 17,
      paddingTop: theme.spacing(4.5),
      // paddingLeft: theme.spacing(2.5),
      paddingBottom: theme.spacing(1),
    },
    boxLoading: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
    },
    boxAppendix: {
      marginTop: -10,
      marginBottom: 24,
    },
    notificationRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      height: 30,
      marginRight: 20,
    },
    buttonGroup: {
      height: 30,
      marginRight: 20,
      display: 'flex',
      flexDirection: 'row',
    },
  })
);

import React, { useRef, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormContext,
  useEventCallback,
  SnackbarContext,
  AbortableFetch,
  abortableFetch,
  PromptContext,
  usePrompts,
  NavigationContext,
} from '@eas/common-web';
import { ProcessForm } from '../irz-facility-requests-new-types';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { IrzFacilityRequest, IrzRequestType } from '../../../models';
import { EvidenceAPI, Messages } from '../../../enums';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

/**
 * Api call
 *
 * @param body
 */
function callApi(body: IrzFacilityRequest) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITY_REQUESTS}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(body),
  });
}

const PROMPT_KEY = 'CREATE_REGISTRATION_PROMPT';

export function StepSummaryActions({
  handleNext,
  handleBack,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Loading state.
   */
  const [loading, setLoading] = useState(false);

  /**
   * Context stuff.
   */
  const { setFieldValue } = useContext(FormContext);
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { unregisterPrompt } = useContext(NavigationContext);

  /**
   * Fetch reference.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Prompt dialog.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Registrace provozovny IRZ',
      dialogText: 'Skutečně chcete odeslat žádost o registraci provozovny IRZ?',
    },
  ]);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { request, isSent, note, ...facilityData } = allValues;

          const data: IrzFacilityRequest = {
            id: uuidv4(),
            type: IrzRequestType.REGISTRATION,
            note,
            requestFacilityData: {
              ...facilityData,
              operator: allValues?.operator,
              id: uuidv4(),
            },
          };

          fetch.current = callApi(data);

          const response: IrzFacilityRequest = await fetch.current.json();

          setFieldValue('request', response);
          setFieldValue('isSent', true);
          unregisterPrompt(navigationPrompt);

          handleNext();
        } catch (err) {
          setLoading(false);

          const message = getErrorMessage(
            Messages.IrzFacilityRequest.REQUEST_CREATE_SUBMIT,
            err.code
          );

          showSnackbar(...message);

          throw err;
        } finally {
          setLoading(false);
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Odeslat žádost
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} />
      </div>
    </div>
  );
}

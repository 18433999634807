import { Role } from '../../models';
import { orderBy } from 'lodash';
import { TableSort } from '@eas/common-web';

export const subjectRolesValueMapper = function subjectRolesValueMapper({
  value,
  sorts,
}: {
  value: Role[];
  sorts: TableSort[];
}) {
  const sort = sorts.find((s) => s.datakey === 'roles');
  const order = sort?.order === 'ASC' ? 'asc' : 'desc';

  const sortedRoles = orderBy(value, (role) => role?.name ?? '-', [order]).map(
    (r) => r?.name
  );

  const uniqueRoles = [...new Set(sortedRoles)];

  return uniqueRoles.join(', ');
};

subjectRolesValueMapper.displayName = 'subjectRolesValueMapper';

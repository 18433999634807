import { WebService } from '../../models';
import { TableColumn, TableCells } from '@eas/common-web';

export function useColumns(): TableColumn<WebService>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'status.database',
      name: 'Stav - databáze',
      width: 200,
      CellComponent: TableCells.BooleanCell,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'status.elasticSearch',
      name: 'Stav - elasticsearch',
      width: 200,
      CellComponent: TableCells.BooleanCell,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'status.mainModule',
      name: 'Stav - hlavní modul',
      width: 200,
      CellComponent: TableCells.BooleanCell,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'enabled',
      name: 'Služba je zapnutá',
      width: 200,
      CellComponent: TableCells.BooleanCell,
      sortable: false,
      filterable: false,
    },
  ];
}

export enum PermissionMuvRiverSide {
  /** Přehled břehů */
  MUV_RIVER_SIDE_LIST = 'MUV_RIVER_SIDE_LIST',

  /** Autocomplete výběr břehů */
  MUV_RIVER_SIDE_AUTOCOMPLETE = 'MUV_RIVER_SIDE_AUTOCOMPLETE',

  /** Detail břehu */
  MUV_RIVER_SIDE_DETAIL = 'MUV_RIVER_SIDE_DETAIL',

  /** Vytvoření nového břehu */
  MUV_RIVER_SIDE_CREATE = 'MUV_RIVER_SIDE_CREATE',

  /** Editace břehu */
  MUV_RIVER_SIDE_UPDATE = 'MUV_RIVER_SIDE_UPDATE',

  /** Mazání břehu */
  MUV_RIVER_SIDE_DELETE = 'MUV_RIVER_SIDE_DELETE',

  /** Zneplatnění břehu */
  MUV_RIVER_SIDE_INVALIDATE = 'MUV_RIVER_SIDE_INVALIDATE',

  /** Obnovení břehu */
  MUV_RIVER_SIDE_REVALIDATE = 'MUV_RIVER_SIDE_REVALIDATE',
}

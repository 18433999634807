import React from 'react';
import { Process } from '../../components/process/process';
import { ProcessForm } from './irz-facility-request-operator-change-types';
import { useIrzFacilityRequestOperatorChange } from './irz-facility-request-operator-change-hook';
import { TranslationKeys } from '../../enums';

export function IrzFacilityRequestOperatorChange() {
  const { steps } = useIrzFacilityRequestOperatorChange();

  return (
    <Process<ProcessForm>
      title="Změna provozovatele provozovny IRZ"
      steps={steps}
      translationKey={TranslationKeys.IRZ_FACILITY_REQUEST_OPERATOR_CHANGE}
    />
  );
}

import React from 'react';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { useApproveDialog } from './dialog-actions/approve-hook';
import { useRejectDialog } from './dialog-actions/reject-hook';

export function RoleRequestsActionbar() {
  const { canApprove, callApi: approveApiCall } = useApproveDialog();
  const { canReject, callApi: rejectApiCall } = useRejectDialog();

  return (
    <>
      {canApprove && (
        <DetailActionButton
          promptKey="APPROVE"
          apiCall={approveApiCall}
          buttonLabel="Schválit"
          dialogTitle="Schválení žádosti"
          dialogText="Skutečně chcete schválit žádost?"
          ButtonComponent={(props) => (
            <PrimaryDetailActionbarButton {...props} startIcon={<SendIcon />} />
          )}
        />
      )}
      {canReject && (
        <DetailActionButton
          promptKey="REJECT"
          apiCall={rejectApiCall}
          buttonLabel="Zamítnout"
          dialogTitle="Zamítnutí žádosti"
          dialogText="Skutečně chcete zamítnout žádost?"
          ButtonComponent={(props) => (
            <SecondaryDetailActionbarButton
              {...props}
              startIcon={<DeleteIcon />}
            />
          )}
        />
      )}
    </>
  );
}

import * as Yup from 'yup';
import { AgendaSpecification, AgendaType } from '../../models';

export const validateSpecifications = Yup.mixed<AgendaSpecification[]>().when(
  'agenda',
  {
    is: (agenda) => agenda?.type === AgendaType.SPECIFIED,
    then: Yup.array<AgendaSpecification>().test('', '', function (
      specifications
    ) {
      const { createError } = this;
      const filledFields = specifications?.filter((spec) => spec?.id);

      if (!specifications?.length) {
        return createError({
          message: 'Přidejte alespoň 1 typ hlášení',
        });
      } else if (specifications?.length !== filledFields?.length) {
        return createError({
          message: 'Přidaný řádek pro výběr typu hlášení musí být vyplněný',
        });
      }

      return true;
    }),
  }
);

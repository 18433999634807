import React from 'react';
import { FormPanel, FormTextArea } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';

export function StepOtherInformationContent() {
  return (
    <FormPanel label="Ostatní informace">
      <FormTextArea
        name="note"
        label={<HelpLabel label="Poznámka" code="IRZ_FACILITY_SPLIT_NOTE" />}
      />
    </FormPanel>
  );
}

import React, { useContext } from 'react';
import {
  PromptContext,
  usePrompts,
  FormCheckbox,
  useEventCallback,
} from '@eas/common-web';
import { AuthContext } from '../../auth/auth-context';

const PROMPT_KEY = 'SAML_LOGOUT';

export function useSamlLogoutAction() {
  const { logout } = useContext(AuthContext);
  const { testPrompt } = useContext(PromptContext);

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Skutečně se chcete odhlásit?',
      dialogText: '',
      formInitialValues: { logoutFromEnviIam: true },
      FormFields: function FormFields() {
        return (
          <FormCheckbox
            layoutOptions={{ noUnderline: true }}
            name="logoutFromEnviIam"
            label="Odhlásit z EnviIAM?"
          />
        );
      },
    },
  ]);

  const handleSamlLogout = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async ({
        logoutFromEnviIam,
      }: {
        logoutFromEnviIam?: boolean;
      }) => {
        logout(false, logoutFromEnviIam);
      },
    });
  });

  return {
    handleSamlLogout,
  };
}

import React, { useImperativeHandle, forwardRef, useMemo } from 'react';
import { FormFieldContext } from '@eas/common-web';

import { AddressFieldContext } from './address-field-context';
import { useAddressField } from './hooks/address-field-hook';
import { AddressFieldHandle, AddressFieldProps } from './address-field-types';

import { CountryCell } from './fields/country-cell';
import { RegionCell } from './fields/region-cell';
import { DistrictCell } from './fields/district-cell';
import { MunicipalityCell } from './fields/municipality-cell';
import { MunicipalityPartCell } from './fields/municipality-part-cell';
import { CityDistrictCell } from './fields/city-district-cell';
import { StreetCell } from './fields/street-cell';
import { AddressPlaceCell } from './fields/address-place-cell';
import { HouseNumberTypeCell } from './fields/house-number-type-cell';
import { HouseNumberCell } from './fields/house-number-cell';
import { OrientationNumberCell } from './fields/orientation-number-cell';
import { OrientationNumberMarkCell } from './fields/orientation-number-mark-cell';
import { ZipCell } from './fields/zip-cell';
import { UnstructuredAddress } from './fields/unstrucured-address-cell';

export const AddressField = forwardRef<AddressFieldHandle, AddressFieldProps>(
  function AddressField(
    {
      code = 'ADDRESS',
      name,
      disableForeignCountry,
      disableRuian = false,
      disabled,
      showUnstructured = false,
      notifyPrefill,
    },
    ref
  ) {
    const { isForeign, isRuian, prefillAddress } = useAddressField({
      name,
      disableRuian,
      notifyPrefill,
    });

    useImperativeHandle(
      ref,
      () => ({
        prefillAddress,
      }),
      [prefillAddress]
    );

    const context = useMemo(
      () => ({
        code,
        name,
        disabled,
        isRuian,
        isForeign,
        prefillAddress,
      }),
      [code, isRuian, prefillAddress, name, disabled, isForeign]
    );

    return (
      <FormFieldContext.Provider value={{ prefix: name }}>
        <AddressFieldContext.Provider value={context}>
          <CountryCell
            disableForeign={disableForeignCountry}
            disableRuian={disableRuian}
          />
          {isRuian && <RegionCell />}
          {isRuian && <DistrictCell />}
          <MunicipalityCell />
          {isRuian && <MunicipalityPartCell />}
          {isRuian && <CityDistrictCell />}
          <StreetCell />
          {isRuian && <AddressPlaceCell />}
          {isRuian && <HouseNumberTypeCell />}
          {isRuian && <HouseNumberCell />}
          {isRuian && <OrientationNumberCell />}
          {isRuian && <OrientationNumberMarkCell />}
          <ZipCell />
          {showUnstructured && <UnstructuredAddress />}
        </AddressFieldContext.Provider>
      </FormFieldContext.Provider>
    );
  }
);

import React from 'react';
import { FormPanel, FormTextArea } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';

export function StepNoteContent() {
  return (
    <FormPanel label="Vyplňte">
      <FormTextArea
        name="note"
        label={
          <HelpLabel label="Poznámka" code="IRZ_FACILITY_REQUEST_CHANGE_NOTE" />
        }
      />
    </FormPanel>
  );
}

import React from 'react';
import { ProcessForm } from '../ovz-facility-request-operator-change-types';

export function StepOriginalOperatorLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const subject = allValues?.oldOperator;

  return (
    <>
      Původní provozovatel:{' '}
      <b>
        {subject?.ico ? `${subject.name} (IČO ${subject.ico})` : subject?.label}
      </b>
    </>
  );
}

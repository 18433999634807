import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MenuItem } from './items';
import { useCallback } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
    },
    primary: {
      color: 'white',
      fontWeight: 600,
    },
  })
);

export function HeaderDesktopMenu({ items }: { items: MenuItem[] }) {
  const classes = useStyles();

  const handleNavigateIfExternal = useCallback(
    (href: string, external?: boolean) => {
      if (external) {
        window.location.replace(href);
      }
    },
    []
  );

  return (
    <>
      {items.map((item) => (
        <Link key={item.label + item.href} to={item.href}>
          <Button
            component="span"
            onClick={() => handleNavigateIfExternal(item.href, item.external)}
            variant={item.primary || item.secondary ? 'contained' : 'text'}
            disableElevation
            color={item.primary ? 'primary' : 'default'}
          >
            <Typography
              component="span"
              variant="body1"
              className={clsx(classes.button, {
                [classes.primary]: item.primary,
              })}
            >
              {item.label}
            </Typography>
          </Button>
        </Link>
      ))}
    </>
  );
}

import React, { useMemo, useState, useContext, forwardRef } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton/IconButton';
import {
  AuthoredObject,
  EvidenceProps,
  EvidenceContext,
  useEventCallback,
  TableHeader,
  ApiFilterOperation,
  DetailToolbar2,
} from '@eas/common-web';
import { HistoryEvidenceProps } from './history-types';
import {
  historyGetFactory,
  historyUpdateFactory,
  historyCreateFactory,
} from './history-api';
import { historyContainerFactory } from './overrides/detail-container';
import { historySearchBarFactory } from './overrides/table-searchbar';
import { ToolbarExportButton } from './overrides/toolbar-export-button';
import { Permission } from '../../../enums';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selectBoxVisibilitySwitch: {
    flexShrink: 0,
    width: 0,
    verticalAlign: 'top',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    backgroundColor: `${theme.palette.primary.light}40`,

    '& svg': {
      width: 18,
    },
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    width: '100%',
  },
}));

export function SelectBoxVisiblitySwitch({
  showSelectBox,
  setShowSelectBox,
}: {
  showSelectBox: boolean;
  setShowSelectBox: (value: boolean) => void;
}) {
  const classes = useStyles();
  const { tableRef } = useContext(EvidenceContext);

  const handleHideSelect = useEventCallback(() => {
    tableRef.current?.resetSelection();
    setShowSelectBox(false);
  });

  const handleShowSelect = useEventCallback(() => {
    setShowSelectBox(true);
  });

  return (
    <div className={classes.selectBoxVisibilitySwitch}>
      {!showSelectBox && (
        <IconButton size="small" onClick={handleShowSelect}>
          <ChevronRightIcon />
        </IconButton>
      )}
      {showSelectBox && (
        <IconButton size="small" onClick={handleHideSelect}>
          <ChevronLeftIcon />
        </IconButton>
      )}
    </div>
  );
}

export function useHistoryEvidence<OBJECT extends AuthoredObject>({
  detailProps,
  tableProps,
  apiProps,
  ...options
}: HistoryEvidenceProps<OBJECT>): EvidenceProps<OBJECT> {
  const classes = useStyles();
  const [showSelectBox, setShowSelectBox] = useState(false);

  const historyVisible = !!options.historyProps;

  const HistorySearchBar = useMemo(
    () =>
      historySearchBarFactory({
        fields: tableProps?.specialFilters ?? [],
        permission: tableProps?.specialFiltersPerm ?? '',
      }),
    []
  );

  const HistoryContainer = useMemo(
    () =>
      historyContainerFactory<OBJECT>({
        detailProps,
        historyVisible,
        historyDescriptors: options.historyProps?.descriptors,
        historyPermission:
          options.historyProps?.permission ?? Permission.Default.ADMINISTRATOR,
      }),
    []
  );

  const Header = forwardRef<HTMLDivElement>(function Header(props, ref) {
    return (
      <div className={classes.headerWrapper}>
        <SelectBoxVisiblitySwitch
          setShowSelectBox={setShowSelectBox}
          showSelectBox={showSelectBox}
        />
        <div className={classes.header}>
          <TableHeader ref={ref} {...props} />
        </div>
      </div>
    );
  });

  const columns = tableProps?.columns?.map((column) =>
    column?.filterOperation === ApiFilterOperation.CONTAINS
      ? {
          ...column,
          asciiFolding: false,
        }
      : column
  );

  return {
    ...options,
    version: 64,
    apiProps: {
      ...apiProps,
      getItem: historyVisible
        ? historyGetFactory(apiProps.getItem)
        : apiProps.getItem,
      updateItem: historyVisible
        ? historyUpdateFactory(apiProps.updateItem)
        : apiProps.updateItem,
      createItem: historyVisible
        ? historyCreateFactory(apiProps.createItem)
        : apiProps.createItem,
    },
    switcherProps: {
      leftLabel: 'Přehled',
      rightLabel: 'Detail',
      hideMenuTools: true,
    },
    tableProps: {
      ...tableProps,
      columns,
      SearchbarComponent: HistorySearchBar,
      showReportButton: false,
      toolbarProps: {
        after: tableProps?.showReportButton !== false && (
          <>
            <ToolbarExportButton />
            {tableProps?.toolbarProps?.after}
          </>
        ),
      },
      showSelectBox: showSelectBox,
      HeaderComponent: Header,
    },
    detailProps: {
      ...detailProps,
      resetScrollbarPosition: true,
      ToolbarComponent: detailProps?.ToolbarComponent ?? DetailToolbar2,
      ContainerComponent: HistoryContainer,
    },
  };
}

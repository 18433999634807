import React, { useContext } from 'react';
import { PublicListDataSource } from '../../models/public-list';
import { publicListToolbarButtonFactory } from '../../composite/public-list-toolbar-button/public-list-toolbar-button';
import { TableToolbarButton, UserContext } from '@eas/common-web';
import { PermissionsState, Me } from '../../models';
import { Permission } from '../../enums';

const PublicListToolbarButton = publicListToolbarButtonFactory({
  dataSource: PublicListDataSource.PROD_EVIDENCE,
  name: 'Veřejný seznam průmyslových míst',
});

export function ProductionSitesTableToolbar() {
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const canSeeEuExportButton = hasPermission(
    Permission.ProductionSite.PRODUCTION_SITE_EXPORT
  );

  return (
    <>
      {canSeeEuExportButton && (
        <TableToolbarButton
          label="EU"
          tooltip="Export průmyslových míst dle nařízení EU"
          onClick={() => {}}
          href="/api/crzp/production/site/export"
        />
      )}
      <PublicListToolbarButton />
    </>
  );
}

import {
  AbortableFetch,
  FormHandle,
  SnackbarContext,
  useEventCallback,
} from '@eas/common-web';
import { useContext, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { IdentityProviderContext } from '../../../components/identity-provider/identity-provider-context';
import { parseIDPPhoneNumber } from '../../../components/identity-provider/identity-provider-utils';
import { Messages } from '../../../enums';
import { PreferredChannelType } from '../../../models';
import { getErrorMessage } from '../../../utils/get-message';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
  validatePhoneNumberRequired,
  validatePhonePrefixRequired,
  validateUsername,
} from '../../profile/profile-utils';
import { removeTrailingWhitespaces } from '../../user/users-utils';
import { registerUser } from '../auth-api';
import { RegistrationData } from '../auth-types';
import { useFormError } from './form-error-hook';

export function useRegistration() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const idpTicket = new URLSearchParams(window.location.search).get('ticket');
  const [tokenDataLoading, setTokenDataLoading] = useState(!!idpTicket);
  const [initialData, setInitialData] = useState<RegistrationData>({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    prefix: '+420',
    twoFactorDetails: {
      phoneNumber: {
        prefix: '+420',
      },
    },
    phoneNumber: '',
    password: '',
    passwordAgain: '',
    personalDataProcessingConsent: true,
    mailingConsent: true,
  });
  const { getDataFromToken } = useContext(IdentityProviderContext);

  const { showSnackbar } = useContext(SnackbarContext);

  const ref = useRef<FormHandle<RegistrationData>>(null);

  const { setErrors, getFieldError } = useFormError();

  const fetch = useRef<AbortableFetch | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const { push } = useHistory();

  const validationSchema = Yup.object().shape({
    username: validateUsername(),
    firstName: validateFirstName,
    lastName: validateLastName,
    prefix: validatePhonePrefixRequired,
    phoneNumber: validatePhoneNumberRequired,
    email: validateEmail({ requiredMessage: 'Email musí být vyplněný' }),
    password: Yup.string().when('externalIdp.sub', {
      is: (val?: string) => !val,
      then: validatePassword,
    }),
    passwordAgain: Yup.string().when('externalIdp.sub', {
      is: (val?: string) => !val,
      then: Yup.string()
        .nullable()
        .required('Heslo znovu musí být vyplněné')
        .oneOf([Yup.ref('password')], 'Zopakované heslo se neshoduje s heslem'),
    }),
    twoFactorDetails: Yup.object().shape({
      preferredChannel: Yup.mixed<PreferredChannelType>()
        .nullable()
        .required('Musí být vyplněno'),
      email: Yup.object({
        value: validateEmail({ requiredMessage: 'Email musí být vyplněný' }),
      }),
      phoneNumber: Yup.object({
        prefix: validatePhonePrefixRequired,
        number: Yup.object({
          value: validatePhoneNumberRequired,
        }),
      }),
    }),
  });

  const handleNavigateBack = useEventCallback(() => {
    push('/registrace');
  });

  const submitRegistration = useEventCallback(
    async (data: RegistrationData) => {
      try {
        setLoading(true);

        const captcha = await executeRecaptcha!('registration');

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = registerUser(data, captcha);

        await fetch.current.raw();

        unstable_batchedUpdates(() => {
          showSnackbar(...Messages.User.REGISTRATION.SUCCESS);
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);

        if (err.name !== 'AbortError') {
          const message = getErrorMessage(Messages.User.REGISTRATION, err.code);

          showSnackbar(...message);

          throw err;
        }
        return undefined;
      }
    }
  );

  const handleSubmit = useEventCallback(async (data: RegistrationData) => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      setErrors(errors);

      if (errors.length) {
        return;
      }

      if (data.firstName) {
        data.firstName = removeTrailingWhitespaces(data.firstName);
      }

      if (data.lastName) {
        data.lastName = removeTrailingWhitespaces(data.lastName);
      }

      await submitRegistration({
        ...data,
        mailingConsent: true,
        personalDataProcessingConsent: true,
      });

      push('/registrace/dokonceni', true);
    }
  });

  const handleLoadUserDataFromTicket = useEventCallback(async () => {
    if (idpTicket) {
      try {
        setTokenDataLoading(true);
        const { phone, ...userData } = await getDataFromToken(idpTicket);
        const parsedPhone = parseIDPPhoneNumber(phone);
        unstable_batchedUpdates(() => {
          setInitialData({
            username: '',
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            email: userData?.email,
            prefix: parsedPhone?.prefix,
            phoneNumber: parsedPhone?.number,
            personalDataProcessingConsent: true,
            mailingConsent: true,
            externalIdp: {
              sub: userData?.sub,
              identityProvider: userData?.clientName,
            },
          });
          setTokenDataLoading(false);
        });
      } catch (err) {
        if (err.code === 'IDENTITY_PROVIDER_EXTERNAL_ID_ALREADY_REGISTERED') {
          showSnackbar(
            ...Messages.User.IDP_PREFILL
              .IDENTITY_PROVIDER_EXTERNAL_ID_ALREADY_REGISTERED
          );
        } else {
          showSnackbar(...Messages.User.IDP_PREFILL.ERROR);
        }
        push('/registrace');
      }
    }
  });

  useEffect(() => {
    if (idpTicket) {
      handleLoadUserDataFromTicket();
    }
  }, [idpTicket]);

  return {
    ref,
    validationSchema,
    loading,
    handleNavigateBack,
    handleSubmit,
    getFieldError,
    tokenDataLoading,
    initialData,
  };
}

import {
  ApiFilterOperation,
  FilesProvider,
  FormAutocomplete,
  FormFileField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  useFormSelector,
  FormCustomField,
  TextField,
  UserContext,
} from '@eas/common-web';
import React, { useContext } from 'react';
import { FormPanelCount } from '../../components/form/form-panel-summary/form-panel-count';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { HelpLabel } from '../../components/help/help-label';
import { DetailNavigationButton } from '../../components/detail-navigation-button/detail-navigation-button';
import { AdminRoles } from '../profile/fields/role-admin/role-admin';
import { UserRoles } from '../profile/fields/role-user/role-user';
import { useRoles } from '../roles/roles-api';
import { useStyles } from '../user/overrides/role-styles';
import { useRoleRequestStates, useRoleRequestTypes } from './role-requests-api';
import { noop } from 'lodash';
import { EvidenceAPI, Permission, EvidenceUrl } from '../../enums';
import { RoleRequest, RoleRequestType, User } from '../../models';

export function RoleRequestFields() {
  const { hasPermission } = useContext(UserContext);

  const states = useRoleRequestStates();
  const types = useRoleRequestTypes();
  const roles = useRoles();

  const {
    type,
    asAdministratorCount,
    asUserCount,
    requester,
    requestedRoles,
  } = useFormSelector(
    (
      data: RoleRequest & { asAdministratorCount: number; asUserCount: number }
    ) => ({
      type: data.type,
      asAdministratorCount: data.asAdministratorCount,
      asUserCount: data.asUserCount,
      requester: data.requester,
      requestedRoles: data.requestedRoles ?? [],
    })
  );

  const requestedRoleSource = useRoles([
    {
      operation: ApiFilterOperation.IDS,
      ids: requestedRoles.map((role) => role.id),
    },
  ]);

  return (
    <>
      <FormPanel label="Obecné">
        <FormSelect
          source={states}
          name="state"
          label={<HelpLabel label="Stav žádosti" code="ROLE_REQUEST_STATE" />}
          valueIsId
          disabled
        />
        <FormSelect
          source={types}
          name="type"
          label={
            <HelpLabel label="Typ žádosti o roli" code="ROLE_REQUEST_TYPE" />
          }
          valueIsId
          disabled
        />
        <FormAutocomplete
          source={roles}
          name="requestedRoles"
          label={
            <HelpLabel
              label="Požadované role"
              code="ROLE_REQUEST_REQUESTED_ROLES"
            />
          }
          labelMapper={dictionaryLabelMapper}
          multiple
          disabled
        />
        <FormAutocomplete
          source={requestedRoleSource}
          name="approvedRoles"
          label={
            <HelpLabel
              label="Schválené role"
              code="ROLE_REQUEST_APPROVVED_ROLES"
            />
          }
          labelMapper={dictionaryLabelMapper}
          multiple
          required
        />
        <FormTextArea
          name="reason"
          label={
            <HelpLabel label="Odůvodnění žádosti" code="ROLE_REQUEST_REASON" />
          }
          disabled
        />
        <FilesProvider url={EvidenceAPI.ARCHIVED_FILES}>
          <FormFileField
            name="document"
            label={
              <HelpLabel
                label="Dokument k žádosti"
                code="ROLE_REQUEST_DOCUMENT"
              />
            }
            disabled
          />
        </FilesProvider>
        {type === RoleRequestType.USER_ROLE_REQUEST && (
          <FormTextField
            name="subject.label"
            label={<HelpLabel label="Subjekt" code="ROLE_REQUEST_SUBJECT" />}
            disabled
          />
        )}
      </FormPanel>
      <FormPanel label="Žadatel">
        <FormTextField
          name="requester.firstName.value"
          label={
            <HelpLabel label="Jméno" code="ROLE_REQUEST_REQUESTER_FIRST_NAME" />
          }
          disabled
        />
        <FormTextField
          name="requester.lastName.value"
          label={
            <HelpLabel
              label="Příjmení"
              code="ROLE_REQUEST_REQUESTER_LAST_NAME"
            />
          }
          disabled
        />
        <FormTextField
          name="requester.email.value"
          label={
            <HelpLabel label="E-mail" code="ROLE_REQUEST_REQUESTER_EMAIL" />
          }
          disabled
        />
        <FormCustomField
          label={
            <HelpLabel label="Telefon" code="ROLE_REQUEST_REQUESTER_PHONE" />
          }
        >
          <TextField
            value={`${requester?.phoneNumber?.prefix}${requester?.phoneNumber?.number?.value}`}
            onChange={noop}
            disabled
          />
        </FormCustomField>
        {requester?.id &&
          (hasPermission(Permission.User.USER_LIST_ALL) ||
            hasPermission(Permission.User.USER_LIST_OWN)) && (
            <FormCustomField>
              <DetailNavigationButton
                url={EvidenceUrl.USERS}
                id={requester?.id}
              />
            </FormCustomField>
          )}
      </FormPanel>
      <FormPanel
        label="Správce subjektu"
        expandable
        defaultExpanded={false}
        summary={<FormPanelCount count={asAdministratorCount} />}
      >
        <AdminRoles profile={requester as User} useStyles={useStyles} />
      </FormPanel>
      <FormPanel
        label="Běžný uživatel subjektu"
        expandable
        defaultExpanded={false}
        summary={<FormPanelCount count={asUserCount} />}
      >
        <UserRoles profile={requester as User} useStyles={useStyles} />
      </FormPanel>
    </>
  );
}

import React, { useRef, useContext } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  DomainObject,
  FormTextArea,
  useFormSelector,
} from '@eas/common-web';
import { Note } from '../../../../models/note';
import { EvidenceAPI, Messages, Permission } from '../../../../enums';
import { EntityType } from '../../../../enums/entity-type';
import { unstable_batchedUpdates } from 'react-dom';

/**
 * Api call
 */
function callApi(note: Note) {
  return abortableFetch(`${EvidenceAPI.NOTES}/${note.id}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: note.text,
  });
}

/**
 * Editace poznámky
 */
const PROMPT_KEY = 'UPDATE_NOTE';

export function useUpdateNoteDialog({
  entityType,
  selectedIndex,
}: {
  entityType: EntityType;
  selectedIndex?: number;
}) {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext<DetailHandle<DomainObject>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { reload, hasPermission } = useContext(UserContext);

  /**
   * Notes table data.
   */
  const { notes } = useFormSelector((data: { notes: Note[] }) => ({
    notes: data?.notes,
  }));

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Editace poznámky',
        dialogText: 'Vyplňte text poznámky',
        FormFields: function Body() {
          return (
            <>
              <FormTextArea labelOptions={{ hide: true }} name="noteText" />
            </>
          );
        },
        formInitialValues: {
          noteText:
            selectedIndex !== undefined ? notes[selectedIndex]?.text : '',
        },
        formValidationSchema: Yup.object().shape({
          noteText: Yup.string().nullable().required('Text je povinný'),
        }),
        dialogWidth: 600,
      },
    ],
    [selectedIndex, notes]
  );

  const handleUpdateNote = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { noteText: string }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callApi({
            ...notes[selectedIndex!],
            text: values.noteText,
          });

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Note.UPDATE.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.Note.UPDATE.ERROR);
          }

          return true;
        }
      },
    });
  });

  const showUpdateButton = hasPermission(Permission.Note.NOTES_UPDATE);

  return {
    handleUpdateNote,
    showUpdateButton,
  };
}

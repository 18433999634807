import React, { useContext, useRef } from 'react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import {
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailContext,
  FormTextArea,
  FormDateField,
  abortableFetch,
  NavigationContext,
  EvidenceStateAction,
  UserContext,
  PromptContext,
  usePrompts,
} from '@eas/common-web';
import { EvidenceAPI, EvidenceUrl, Permission, Messages } from '../../../enums';
import { IrzFacilityRequest } from '../../../models';
import { IrzRequestType } from '../../../models/irz-facility-request';
import { HelpLabel } from '../../../components/help/help-label';

/**
 * Api call
 *
 * @param id
 * @param values
 */
export function callApi(body: IrzFacilityRequest) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITY_REQUESTS}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(body),
  });
}

/**
 * Vytvoření žádosti o zneplatnění provozovny IRZ
 */
const PROMPT_KEY = 'DEACTIVATE_REQUEST';

export function useDeactivateRequestDialog() {
  /**
   * Context stuff.
   */
  const { source } = useContext(DetailContext);
  const { navigate } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Žádost',
      dialogText:
        'Skutečně chcete odeslat žádost o zneplatnění provozovny IRZ?',
      FormFields: function Body() {
        return (
          <>
            <FormTextArea
              name="note"
              label={
                <HelpLabel
                  label="Poznámka"
                  code="IRZ_FACILITY_REQUEST_DEACTIVATE_DIALOG_NOTE"
                />
              }
            />
            <FormDateField
              name="validTo"
              label={
                <HelpLabel
                  label="Datum"
                  code="IRZ_FACILITY_REQUEST_DEACTIVATE_DIALOG_VALID_TO"
                  required
                />
              }
            />
          </>
        );
      },
      formValidationSchema: Yup.object().shape({
        validTo: Yup.string().nullable().required('Povinné pole'),
      }),
      dialogWidth: 600,
    },
  ]);

  const handleDeactivateRequest = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { note?: string; validTo?: string }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          const irzFacilityRequest = {
            id: uuidv4(),
            type: IrzRequestType.INVALIDATION,
            note: values?.note,
            requestFacilityData: {
              ...source.data,
              id: uuidv4(), // O_o interesting structure
              irzId: source.data?.id,
              validTo: values?.validTo,
            },
            irzFacility: source.data!,
          };

          fetch.current = callApi(irzFacilityRequest);

          const response = await fetch.current.json();

          source.setLoading(false);

          navigate(`${EvidenceUrl.IRZ_FACILITY_REQUESTS}`, true, {
            action: EvidenceStateAction.SHOW_ITEM,
            data: response.id,
          });
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(
              ...Messages.IrzFacility.REQUEST_DEACTIVATE_SUBMIT.ERROR
            );
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  // Invalidation request can be created only if no undecided invalidation is present
  const showDeactivateRequestButton =
    hasPermission(Permission.IrzFacility.IRZ_REQUEST_INVALIDATE_SUBMIT, {
      subjectId: source.data?.operator?.id,
    }) &&
    source.data?.active &&
    !source.data?.activeInvalidation;

  return {
    handleDeactivateRequest,
    showDeactivateRequestButton,
  };
}

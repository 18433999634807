import React from 'react';
import {
  FormPanel,
  useFormSelector,
  FormCustomField,
  TextArea,
} from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';
import { FormPreFilledAutocomplete } from '../../../components/form/pre-filled-autocomplete/pre-filled-autocomplete';
import { IrzFacilityAutocomplete } from '../../../models';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useIrzFacilities } from '../../irz-facility/irz-facilities-api';
import { EvidenceAPI, IncludeOptions } from '../../../enums';
import { eqFilterParams } from '../../subject/subjects-api';
import { ProcessForm } from '../irz-facility-request-operator-change-types';
import { useAddressLabel } from '../../../components/form/address-field/hooks/address-label-hook';
import { StepFacilityForm } from './step-facility-types';

export function StepFacilityContent({ allValues }: { allValues: ProcessForm }) {
  const address = useFormSelector(
    (data: StepFacilityForm) => data?.facility?.address
  );
  const addressLabel = useAddressLabel(address);

  const irzFacilities = useIrzFacilities(
    {
      ...eqFilterParams({
        field: 'operator.id',
        value: allValues.subject?.id,
      }),
      include: [IncludeOptions.DEACTIVATED],
    },
    EvidenceAPI.IRZ_FACILITIES
  );

  return (
    <>
      <FormPanel label="Provozovna">
        <FormPreFilledAutocomplete<IrzFacilityAutocomplete>
          name="facility"
          label={
            <HelpLabel
              label="Provozovna"
              code="IRZ_FACILITY_REQUEST_OPERATOR_CHANGE_FACILITY"
            />
          }
          source={irzFacilities}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormCustomField label="Adresa">
          <TextArea value={addressLabel} disabled={true} />
        </FormCustomField>
      </FormPanel>
    </>
  );
}

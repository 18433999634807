import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import React from 'react';
import {
  FormTextArea,
  FormPanel,
  DatedFields,
  AuthoredFields,
} from '@eas/common-web';
import { DictionaryFields } from '../../components/evidence/dictionary-evidence/dictionary-fields';
import { HelpLabel } from '../../components/help/help-label';

export function DictionaryBoilerTypesFields(props: NotedEvidenceFieldsProps) {
  return (
    <>
      <FormPanel label="Obecné">
        <DatedFields
          FieldsComponent={() => (
            <AuthoredFields
              FieldsComponent={() => (
                <DictionaryFields
                  codePrefix="BOILER_TYPE"
                  FieldsComponent={() => (
                    <FormTextArea
                      name="description"
                      label={
                        <HelpLabel
                          label="Popis"
                          code="DICT_BOILER_TYPE_DESCRIPTION"
                        />
                      }
                    />
                  )}
                />
              )}
            />
          )}
        />
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

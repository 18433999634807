import React from 'react';
import { FormPanel, FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export default function DictionaryRuianCohesionRegionFields() {
  const prefix = 'RUIAN_COHESION_REGION';

  return (
    <>
      <FormPanel label="Adresní místo">
        <FormTextField
          name="nutsLauCode"
          label={
            <HelpLabel label="Kód NUTS/LAU" code={`DICT_${prefix}_CODE`} />
          }
        />
      </FormPanel>
    </>
  );
}

import { DatedObject } from './dated-object';
import { DictionaryObject, RunState } from '@eas/common-web';

export enum ScriptType {
  GROOVY = 'GROOVY',
  JAVASCRIPT = 'JAVASCRIPT',
}

export interface ScheduleJob extends DictionaryObject {
  timer: string;
  scriptType: ScriptType;
  script: string;
  running: boolean;
  useTransaction: boolean;
}

export interface ScheduleRun extends DatedObject {
  job: ScheduleJob;
  console: string;
  result: string;
  state: RunState;
  startTime: string;
  endTime: string;
}

import React from 'react';
import { FormAutocomplete } from '@eas/common-web';
import { useSubjects } from '../../modules/subject/subjects-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { SubjectAutocomplete as SubjectAutocompleteType } from '../../models';
import { HelpLabel } from '../../components/help/help-label';

export interface SubjectAutocompleteProps {
  name: string;
  label: string;
  codePrefix: string;
  disabled?: boolean;
  required?: boolean;
  ownOnly?: boolean;
  notifyChange?: (value?: unknown) => void;
}

export function SubjectAutocomplete({
  name,
  label,
  codePrefix,
  disabled,
  required,
  ownOnly = false,
  notifyChange,
}: SubjectAutocompleteProps) {
  const subjects = useSubjects(undefined, ownOnly);

  return (
    <FormAutocomplete<SubjectAutocompleteType>
      name={name}
      label={
        <HelpLabel
          label={label}
          code={`${codePrefix}_AUTOCOMPLETE`}
          required={required}
        />
      }
      source={subjects}
      labelMapper={dictionaryLabelMapper}
      tooltipMapper={dictionaryLabelMapper}
      disabled={disabled}
      notifyChange={notifyChange}
    />
  );
}

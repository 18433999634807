import { UserContext, MenubarContext } from '@eas/common-web';
import { useContext, useEffect } from 'react';
import { Permission } from '../../../enums';
import { Me } from '../../../models';
import { intersection } from 'lodash';
import { flattenSubjectPermissions } from '../../permission/perm-utils';
import { useMenuBarItems, CrzpMenuItem } from '../items/menu-bar-items';

export const useMenuChanger = () => {
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { modifyItems } = useContext(MenubarContext);
  const { crzpDeveloperToolItems, items } = useMenuBarItems();

  function filterItems(array: CrzpMenuItem[], permissions: string[]) {
    const isAdmin = user?.securityPermissions?.find(
      (p) => p === 'ADMINISTRATOR' || p === 'CENIA_ADMIN'
    );

    /**
     *
     * @param result
     * @param object
     */
    const getNodes = (result: CrzpMenuItem[], object: CrzpMenuItem) => {
      if (hasPermission(Permission.Default.TWO_FACTOR_PRE_AUTH)) {
        return result;
      }

      if (isAdmin) {
        result.push(object);
        return result;
      }

      if (object.displayItem) {
        result.push(object);
        return result;
      }

      if (
        intersection(object?.permission ?? [], [Permission.Default.LOGGED_USER])
          .length
      ) {
        result.push(object);
        return result;
      }

      if (intersection(object?.permission ?? [], permissions).length) {
        result.push(object);
        return result;
      }

      if (Array.isArray(object.items)) {
        const items = object.items.reduce(getNodes, []);
        if (items.length || isAdmin) result.push({ ...object, items });
      }
      return result;
    };

    return array.reduce(getNodes, []);
  }

  const securityPermissions = user?.securityPermissions ?? [];

  const subjectPermissions = flattenSubjectPermissions(user!);

  const filteredItems = filterItems(
    items,
    [...securityPermissions, ...subjectPermissions] ?? []
  );

  useEffect(() => {
    modifyItems((items) => {
      const displayMenuItem = items.find(
        (i) => (i as any)?.label === 'Zobrazení'
      );
      const developerToolsMenuItem = items.find(
        (i) => (i as any)?.label?.props?.id === 'EAS_MENU_ITEM_DEVTOOLS'
      );

      if (developerToolsMenuItem) {
        developerToolsMenuItem.items?.push(...crzpDeveloperToolItems);
        filteredItems.push(developerToolsMenuItem);
      }
      if (displayMenuItem) {
        filteredItems.push(displayMenuItem);
      }

      return filteredItems;
    });
    return () => {
      modifyItems((items) => {
        const developerToolsMenuItem = items.find(
          (i) => (i as any)?.label?.props?.id === 'EAS_MENU_ITEM_DEVTOOLS'
        );
        //REMOVE ALL added developer items so they do not get duplicated
        if (developerToolsMenuItem) {
          const updatedDevItems =
            developerToolsMenuItem.items?.filter((item) => {
              for (const crzpDeveloperToolItem of crzpDeveloperToolItems) {
                if (crzpDeveloperToolItem.href === item.href) {
                  return false;
                }
              }
              return true;
            }) ?? [];
          developerToolsMenuItem.items = updatedDevItems;
        }
        return items;
      });
    };
  }, [user, filteredItems, crzpDeveloperToolItems]);
};

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  abortableFetch,
  PromptContext,
  usePrompts,
  AbortableFetch,
  FormContext,
  SnackbarContext,
  useEventCallback,
  NavigationContext,
} from '@eas/common-web';
import { EvidenceAPI, Messages } from '../../../enums';
import { ProcessForm } from '../ovz-facility-request-operator-change-types';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { useState, useContext, useRef } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

/**
 * Api call
 *
 * @param body
 */
function callApi(body: ProcessForm) {
  return abortableFetch(`${EvidenceAPI.OVZ_FACILITY_REQUESTS}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(body),
  });
}

const PROMPT_KEY = 'CREATE_OPERATOR_CHANGE_REQUEST_PROMPT';

export function StepSummaryActions({
  handleBack,
  handleNext,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Context stuff
   */
  const { testPrompt } = useContext(PromptContext);
  const { setFieldValue } = useContext(FormContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { unregisterPrompt } = useContext(NavigationContext);

  const [loading, setLoading] = useState(false);

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Změna provozovatele provozoven OVZ',
      dialogText:
        'Skutečně chcete odeslat nabídku změny provozovatele provozoven OVZ?',
    },
  ]);

  /**
   * Fetch reference.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(allValues);

          await fetch.current.raw();

          setFieldValue('isSent', true);
          showSnackbar(...Messages.OvzFacilityRequest.REQUEST_SUBMIT.SUCCESS);
          setLoading(false);
          unregisterPrompt(navigationPrompt);
          handleNext();
        } catch (err) {
          setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.OvzFacilityRequest.REQUEST_SUBMIT,
              err.code
            );

            showSnackbar(...message);
            throw err;
          }

          return undefined;
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Odeslat nabídku
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} />
      </div>
    </div>
  );
}

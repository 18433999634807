import { useContext, useRef } from 'react';
import {
  useEventCallback,
  SnackbarContext,
  DetailContext,
  UserContext,
  DetailHandle,
  AbortableFetch,
  PromptContext,
  usePrompts,
  activateItem,
} from '@eas/common-web';
import { Permission, Messages } from '../../../enums';
import { OvzFacility } from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';

const PROMPT_KEY = 'ACTIVATE';

export function useActivateDialog() {
  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, onPersisted } = useContext<DetailHandle<OvzFacility>>(
    DetailContext
  );
  const { hasPermission } = useContext(UserContext);

  /**
   * Dialogs
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Obnovení provozovny',
      dialogText: 'Skutečně chcete obnovit provozovnu?',
    },
  ]);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleActivate = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        if (source.data) {
          try {
            source.setLoading(true);
            if (fetch.current !== null) {
              fetch.current.abort();
            }
            fetch.current = activateItem(source.url, source.data!.id);

            await fetch.current.raw();

            unstable_batchedUpdates(() => {
              showSnackbar(...Messages.OvzFacility.ACTIVATE.SUCCESS);
              source.setLoading(false);
            });
            await source.refresh();
            onPersisted(source.data.id);
          } catch (err) {
            source.setLoading(false);
            if (err.name !== 'AbortError') {
              showSnackbar(...Messages.OvzFacility.ACTIVATE.ERROR);
              throw err;
            }
            return undefined;
          }
        }
      },
    });
  });

  const canActivate =
    hasPermission(Permission.OvzFacility.OVZ_REVALIDATE_ALL) ||
    hasPermission(Permission.OvzFacility.OVZ_REVALIDATE_OWN, {
      subjectId: source.data?.operator?.id,
    });

  const showActivateButton = canActivate && !source.data?.active;

  return {
    showActivateButton,
    handleActivate,
  };
}

import React, { useEffect, useRef } from 'react';
import {
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormSelect,
  useFormSelector,
  TextField,
  FormCustomField,
  TextArea,
  Select,
  Checkbox,
  FormEditor,
  useMonaco,
} from '@eas/common-web';
import {
  useNotificationEvents,
  useNotificationTypes,
  useNotificationTemplateTypes,
} from './notification-templates-api';
import { NotificationTemplate, NotificationType } from '../../models';
import { useNotificationEvent } from './actions/pick-event-hook';
import { noop } from 'lodash';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { HelpLabel } from '../../components/help/help-label';

export function NotificationTemplatesFields(props: NotedEvidenceFieldsProps) {
  const suggestionRef = useRef<any>(null);

  const { eventId, type } = useFormSelector((data: NotificationTemplate) => ({
    eventId: data.event,
    type: data.type,
  }));

  const typeSource = useNotificationTypes();
  const templateTypeSource = useNotificationTemplateTypes();
  const eventSource = useNotificationEvents();
  const { keys, event, isEventLoaded } = useNotificationEvent(eventId);

  const monaco = useMonaco();

  useEffect(() => {
    if (monaco) {
      if (suggestionRef.current) {
        (suggestionRef.current as any)?.dispose();
      }

      suggestionRef.current = monaco.languages.registerCompletionItemProvider(
        'html',
        {
          triggerCharacters: ['$'],
          provideCompletionItems: (model: any, position: any) => {
            const wordBeforePosition = model.getWordUntilPosition({
              lineNumber: position.lineNumber,
              column: position.column - 1,
            });

            const wordUntilPosition = model.getWordUntilPosition(position);

            let range: any = undefined;

            if (!wordUntilPosition.word && !wordBeforePosition.word) {
              range = {
                startLineNumber: position.lineNumber,
                endLineNumber: position.lineNumber,
                startColumn: position.column - 1,
                endColumn: position.column,
              };
            }

            if (
              wordBeforePosition.word.trim() === '' ||
              wordUntilPosition.word.trim() === ''
            ) {
              return {
                suggestions: keys.map((key) => ({
                  label: key.freeMarkerKey,
                  insertText: key.freeMarkerKey,
                  detail: key.description,
                  kind: monaco.languages.CompletionItemKind.Field,
                  range: range,
                })),
              };
            }
          },
        }
      );
    }
  }, [monaco, keys]);

  return (
    <>
      <FormPanel label="Událost">
        <FormSelect
          name="event"
          label={
            <HelpLabel
              label="Událost"
              code="NOTIFICATION_TEMPLATE_EVENT"
              required
            />
          } // Povinné pole
          source={eventSource}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        {isEventLoaded && (
          <>
            <FormCustomField
              label={
                <HelpLabel
                  label="ID události"
                  code="NOTIFICATION_TEMPLATE_EVENT_ID"
                />
              }
            >
              <TextField onChange={noop} value={event?.id} disabled={true} />
            </FormCustomField>
            <FormCustomField
              label={
                <HelpLabel
                  label="Popis"
                  code="NOTIFICATION_TEMPLATE_EVENT_DESCRIPTION"
                />
              }
            >
              <TextArea
                onChange={noop}
                value={event?.description}
                disabled={true}
              />
            </FormCustomField>
            <FormCustomField
              label={
                <HelpLabel
                  label="Seznam povolených typů notifikací pro tuto událost"
                  code="NOTIFICATION_TEMPLATE_EVENT_ALLOWED_TYPES"
                />
              }
            >
              <Select
                onChange={noop}
                value={event?.allowedTypes}
                source={typeSource}
                disabled={true}
                multiple={true}
                valueIsId={true}
              />
            </FormCustomField>
            <FormCustomField
              label={
                <HelpLabel
                  label="Povinná notifikace"
                  code="NOTIFICATION_TEMPLATE_EVENT_CAN_BE_OPTIONAL"
                />
              }
            >
              <Checkbox
                onChange={noop}
                value={event?.canBeOptional}
                disabled={true}
              />
            </FormCustomField>
          </>
        )}
      </FormPanel>
      <FormPanel label="Šablona notifikací">
        <FormTextField
          name="name"
          label={
            <HelpLabel
              label="Název"
              code="NOTIFICATION_TEMPLATE_NAME"
              required
            />
          }
          // Povinné pole
        />
        <FormTextField
          name="subject"
          label={
            <HelpLabel
              label="Předmět"
              code="NOTIFICATION_TEMPLATE_SUBJECT"
              required
            />
          } // Povinné pole
        />
        <FormEditor
          name="content"
          language={'html'}
          label={
            <HelpLabel
              label="Text"
              code="NOTIFICATION_TEMPLATE_CONTENT"
              required
            />
          } // Povinné pole
          height={500}
        />
        <FormSelect
          name="type"
          label={
            <HelpLabel label="Typ" code="NOTIFICATION_TEMPLATE_TYPE" required />
          } // Povinné pole
          source={templateTypeSource}
          valueIsId={true}
        />
        {type === NotificationType.EMAIL && (
          <FormCheckbox
            name="html"
            label={
              <HelpLabel
                label="Příznak, zda-li je obsah (tělo) notifikace formátované pomocí HTML"
                code="NOTIFICATION_TEMPLATE_HTML"
              />
            }
          />
        )}
        <FormCheckbox
          name="active"
          disabled
          label={
            <HelpLabel label="Aktivní" code="NOTIFICATION_TEMPLATE_ACTIVE" />
          }
        />
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

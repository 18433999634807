import { Municipality } from './municipality';
import { MunicipalityPart } from './municipality-part';
import { CityDistrict } from './city-district';
import { Street } from './street';
import { DomainObject } from './domain-object';
import { DictionaryCountry } from './dict-country';
import { Region } from './region';
import { District } from './district';
import { AddressPlace } from './address-place';

/**
 * Adresní místo
 */
export interface Address extends DomainObject {
  /**
   * Stát
   */
  country?: DictionaryCountry;

  /**
   * Kraj (RÚIAN)
   */
  regionRuian?: Region;

  /**
   * Kraj (vlastní)
   */
  region?: string;

  /**
   * Okres (RÚIAN)
   */
  districtRuian?: District;

  /**
   * Okres (vlastní)
   */
  district?: string;

  /**
   * Obec (RÚIAN)
   */
  municipalityRuian?: Municipality;

  /**
   * Obec (vlastní)
   */
  municipality?: string;

  /**
   * Městský obvod v hlavním městě Praze (MOP) (vlastní)
   */
  cityDistrictPrague?: string;

  /**
   * Městská část (RÚIAN)
   */
  cityDistrictRuian?: CityDistrict;

  /**
   * Městská část (vlastní)
   */
  cityDistrict?: string;

  /**
   * Část obce (RÚIAN)
   */
  municipalityPartRuian?: MunicipalityPart;

  /**
   * Část obce (vlastní)
   */
  municipalityPart?: string;

  /**
   * Ulice (RÚIAN)
   */
  streetRuian?: Street;

  /**
   * Ulice (vlastní)
   */
  street?: string;

  /**
   * Typ čísla domovního
   */
  houseNumberType?: HouseNumberType;

  /**
   * Číslo domovní ( evidenční x popisné )
   */
  houseNumber?: string | null;

  /**
   * Číslo orientační
   */
  orientationNumber?: string;

  /**
   * Znak čísla orientačního
   */
  orientationNumberMark?: string;

  /**
   * Číslo orientační se znakem
   */
  orientationNumberWithMark?: string;

  /**
   * Adresní místo (RÚIAN)
   */
  addressPlaceRuian?: AddressPlace;

  /**
   * Adresní místo (získá se ze ZR ROS adresaTextem)
   */
  addressPlace?: string;

  /**
   * PSČ
   */
  zip?: string;
}

/**
 * Typ stavebního objektu
 */
export enum HouseNumberType {
  /**
   * č.p. - číslo popisné stavebního objektu
   */
  DESCRIPTIVE_NUMBER = 'DESCRIPTIVE_NUMBER',

  /**
   * č.ev. - číslo evidenční stavebního objektu
   */
  REGISTRATION_NUMBER = 'REGISTRATION_NUMBER',
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Ozo messages
 */
const ADD_BRAND: MessageType<'SUCCESS' | 'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE'> = {
  SUCCESS: ['Značka byla úspěšně přidána k výrobci.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při přidávání značky.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Chyba při přidávání značky: Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR]
}

const DEACTIVATE_BRAND: MessageType = {
  SUCCESS: ['Značka byla úspěšně zneplatněna.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zneplatnění značky.', SnackbarVariant.ERROR],
}

const ACTIVATE_BRAND: MessageType = {
  SUCCESS: ['Značka byla úspěšně obnovena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při obnovení značky.', SnackbarVariant.ERROR],
}

const ADD_MODEL: MessageType<'SUCCESS' | 'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'BOILER_BRAND_NOT_VALID'> = {
  SUCCESS: ['Model byl úspěšně přidán k výrobci.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při přidávání modelu.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Chyba při přidávání modelu: Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  BOILER_BRAND_NOT_VALID: ['Nelze založit model kotle k neplatné značce kotlů.', SnackbarVariant.ERROR]
}

const DEACTIVATE_MODEL: MessageType = {
  SUCCESS: ['Model byl úspěšně zneplatněn.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zneplatnění modelu.', SnackbarVariant.ERROR],
}

const ACTIVATE_MODEL: MessageType = {
  SUCCESS: ['Model byl úspěšně obnoven.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při obnovení modelu.', SnackbarVariant.ERROR],
}

const BULK_MODEL_IMPORT: MessageType<'SUCCESS' | 'ERROR' | 'SIZE_TOO_BIG' | 'BRAND_NOT_FOUND' | 'TYPE_NOT_FOUND_FOR_BRAND' | 'NOT_ALLOWED'> = {
  SUCCESS: ['Modely byly úspěšně importovány.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při importování modelů.', SnackbarVariant.ERROR],
  SIZE_TOO_BIG: ['Soubor překračuje maximální velikost 10MB.', SnackbarVariant.ERROR],
  BRAND_NOT_FOUND: ['Značka ze souboru nebyla nalezena.', SnackbarVariant.ERROR],
  TYPE_NOT_FOUND_FOR_BRAND: ['Značka nemá povolený typ kotle.', SnackbarVariant.ERROR],
  NOT_ALLOWED: ['Ke značkám kotlů nemáte dostatečné oprávnění.', SnackbarVariant.ERROR]
}

const BULK_PERMISSION_IMPORT: MessageType<'SUCCESS' | 'TYPE_NOT_FOUND_FOR_BRAND' | 'ERROR' | 'SIZE_TOO_BIG' | 'BRAND_NOT_FOUND' | 'NOT_OWN' | 'NOT_ALLOWED' | 'ACCESS_DENIED'> = {
  SUCCESS: ['Oprávnění byla úspěšně importována.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při importu oprávnění.', SnackbarVariant.ERROR],
  SIZE_TOO_BIG: ['Soubor překračuje maximální velikost 10MB.', SnackbarVariant.ERROR],
  BRAND_NOT_FOUND: ['Značka ze souboru nebyla nalezena.', SnackbarVariant.ERROR],
  NOT_OWN: ['K výrobci kotlů ze souboru nemáte dostatečné oprávnění.', SnackbarVariant.ERROR],
  NOT_ALLOWED: ['Ke značkám kotlů nemáte dostatečné oprávnění.', SnackbarVariant.ERROR],
  ACCESS_DENIED: ['Přístup zamítnut.', SnackbarVariant.ERROR],
  TYPE_NOT_FOUND_FOR_BRAND: ['Značka nemá povolený typ kotle.', SnackbarVariant.ERROR],
}

export default { ADD_BRAND, DEACTIVATE_BRAND, ACTIVATE_BRAND, ADD_MODEL, DEACTIVATE_MODEL, ACTIVATE_MODEL, BULK_MODEL_IMPORT, BULK_PERMISSION_IMPORT };

import React from 'react';
import { merge } from 'lodash';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  SnackbarProvider,
  NavigationProvider,
  UserProvider,
  FilesProvider,
  FormAnchorProvider,
  useEventCallback,
  PromptProvider,
} from '@eas/common-web';

import { BreadcrumbProvider } from './components/breadcrumb/breadcrumb-provider';

import { PublicApp } from './app-public';
import { ProtectedApp } from './app-protected';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { themeOptions } from './theme';
import { Me } from './models';
import {
  hasSecurityPermission,
  hasPermissionInSubject,
  hasSubjectPermission,
} from './components/permission/perm-utils';
import { PublicRecords, PublicTranslation } from './modules';
import { AuthProvider } from './components/auth/auth-provider';
import { IdentityProviderProvider } from './components/identity-provider/identity-provider-provider';
import { BoilerPermissionProvider } from './components/boiler-permission/boiler-permission-provider';

export function App() {
  const theme = createMuiTheme(
    merge(themeOptions, {
      shape: { borderRadius: 0 },
      overrides: {
        MuiInput: {
          root: {
            backgroundColor: '#FEFFB2',
            transition: 'width 0.1s ease-in-out',

            '&.Mui-disabled': {
              backgroundColor: 'inherit',
              color: 'inherit',
            },
          },
        },
      },
    })
  );

  const checkPermission = useEventCallback(
    (
      user: Me,
      permission: string,
      state: {
        subjectId?: string;
        searchInSubjectPerms?: boolean;
        searchOnlyInSubjectPerms?: boolean;
      }
    ) => {
      let securityPermission = false;
      let subjectPermission = false;

      if (state?.searchOnlyInSubjectPerms && state?.subjectId) {
        return hasPermissionInSubject(user, permission, state.subjectId);
      }

      if (state?.searchOnlyInSubjectPerms) {
        return hasSubjectPermission(user, permission);
      }

      securityPermission = hasSecurityPermission(user, permission);

      if (state?.subjectId) {
        subjectPermission = hasPermissionInSubject(
          user,
          permission,
          state.subjectId
        );
      }

      if (state?.searchInSubjectPerms) {
        subjectPermission =
          subjectPermission || hasSubjectPermission(user, permission);
      }

      return securityPermission || subjectPermission;
    }
  );

  return (
    <SnackbarProvider timeout={3000}>
      <BreadcrumbProvider>
        <FormAnchorProvider>
          <IdentityProviderProvider>
            <AuthProvider>
              <Router>
                <UserProvider
                  meUrl="/api/crzp/user/me"
                  logoutUrl="/api/crzp/logout"
                  checkPermission={checkPermission}
                >
                  <FilesProvider url="/api/crzp/files">
                    <Switch>
                      <Route path="/verejne-seznamy/:listId/hodnoty/:id?">
                        <ThemeProvider theme={theme}>
                          <NavigationProvider>
                            <PublicRecords />
                          </NavigationProvider>
                        </ThemeProvider>
                      </Route>
                      <Route path="/textace/odkaz/:id?">
                        <ThemeProvider theme={theme}>
                          <NavigationProvider>
                            <PublicTranslation />
                          </NavigationProvider>
                        </ThemeProvider>
                      </Route>
                      <Route path="/crzp">
                        <ThemeProvider theme={theme}>
                          <NavigationProvider>
                            <PromptProvider>
                              <BoilerPermissionProvider>
                                <ProtectedApp />
                              </BoilerPermissionProvider>
                            </PromptProvider>
                          </NavigationProvider>
                        </ThemeProvider>
                      </Route>
                      <Route path="/">
                        <PublicApp />
                      </Route>
                    </Switch>
                  </FilesProvider>
                </UserProvider>
              </Router>
            </AuthProvider>
          </IdentityProviderProvider>
        </FormAnchorProvider>
      </BreadcrumbProvider>
    </SnackbarProvider>
  );
}

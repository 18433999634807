import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import {
  FormPanel,
  FormAutocomplete,
  FormFileField,
  FormCheckbox,
  useFormSelector,
  DetailHandle,
  DetailContext,
  DetailMode,
  FormContext,
  FormCustomField,
  Checkbox,
  eqFilterParams,
  useEventCallback,
  FilesProvider,
  UserContext,
  FormTextField,
  FormDateTimeField,
  Tooltip,
} from '@eas/common-web';
import CheckIcon from '@material-ui/icons/Check';
import { useAgendas } from '../agenda/agendas-api';
import {
  AgendaType,
  Empowerment,
  SpecifiedEmpowermentRequest,
  Me,
  PermissionsState,
  ValidationResult,
} from '../../models';
import { SpecificationTable } from '../../composite/empowerment-specs-table/specification-table';
import { SubjectFormPanel } from '../../composite/subject-fields/subject-form-panel';
import { noop } from 'lodash';
import { agendaLabelMapper } from '../../components/form/mappers/label-mappers';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { HelpLabel } from '../../components/help/help-label';
import { EvidenceAPI, Permission } from '../../enums';
import {
  getValidationResultMessage,
  useStyles,
} from '../empowerment-request/empowerment-requests-fields';

export function EmpowermentsFields(props: NotedEvidenceFieldsProps) {
  const classes = useStyles();

  const { setFieldValue } = useContext(FormContext);
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );
  const { mode } = useContext<DetailHandle<SpecifiedEmpowermentRequest>>(
    DetailContext
  );

  const params = eqFilterParams({ field: 'empowerable', value: true });
  const agendas = useAgendas(params);
  agendas.loadDetail = async (item) => item;

  const {
    id,
    agenda,
    withoutDay,
    validTo,
    empowermentDocument,
  } = useFormSelector((data: Empowerment & { withoutDay: boolean }) => ({
    id: data?.id,
    agenda: data?.agenda,
    validTo: data?.validTo,
    withoutDay: data?.withoutDay,
    empowermentDocument: data?.empowermentDocument,
  }));

  const notifyAgendaChanged = useEventCallback(() => {
    setFieldValue('specifications', []);
  });

  const notifyWithoutDayChanged = useEventCallback(() => {
    setFieldValue('validTo', null);
  });

  const isAdmin = hasPermission(Permission.Empowerment.EMPOWERMENT_LIST_ALL, {
    searchInSubjectPerms: true,
  });

  useEffect(() => {
    if (mode === DetailMode.EDIT && !validTo) {
      setFieldValue('withoutDay', true);
    }
  }, [mode, setFieldValue, validTo]);

  return (
    <>
      <SubjectFormPanel
        label="Informace o zmocniteli"
        codePrefix="EMPOWERMENT_EMPOWERER"
        autocomplete={{
          name: 'empowerer',
          label: 'Zmocnitel', // Povinné pole
          required: true,
        }}
        disabled={mode == DetailMode.EDIT}
      />
      <SubjectFormPanel
        label="Informace o zmocněnci"
        codePrefix="EMPOWERMENT_AGENT"
        autocomplete={{
          name: 'agent',
          label: 'Zmocněnec', // Povinné pole
          required: true,
        }}
        disabled={mode == DetailMode.EDIT}
      />
      <FormPanel label="Zmocnění">
        <FormAutocomplete
          name="agenda"
          label={
            <HelpLabel label="Agenda" code="EMPOWERMENT_AGENDA" required />
          } // Povinné pole
          source={agendas}
          notifyChange={notifyAgendaChanged}
          labelMapper={agendaLabelMapper}
          tooltipMapper={agendaLabelMapper}
          disabled={mode == DetailMode.EDIT}
        />
        <FilesProvider url={EvidenceAPI.ARCHIVED_FILES}>
          <FormFileField
            name="empowermentDocument"
            label={
              <HelpLabel
                label="Příloha (plná moc konvertovaná)"
                code="EMPOWERMENT_EMPOWERMENT_DOCUMENT"
                required
              />
            } // Povinné pole
            accept={['.pdf']}
            after={
              empowermentDocument?.validationResult && (
                <Tooltip
                  title={getValidationResultMessage(
                    empowermentDocument.validationResult
                  )}
                >
                  <CheckIcon
                    className={clsx(classes.validationResult, {
                      [classes.invalid]:
                        empowermentDocument?.validationResult !==
                        ValidationResult.VALID,
                      [classes.valid]:
                        empowermentDocument?.validationResult ===
                        ValidationResult.VALID,
                    })}
                  />
                </Tooltip>
              )
            }
            disabled={mode == DetailMode.EDIT}
          />
        </FilesProvider>
        <FormDateTimeField
          name="validFrom"
          label={
            <HelpLabel
              label="Platnost od"
              code="EMPOWERMENT_VALID_FROM"
              required
            />
          } // Povinné pole
          disabled={mode == DetailMode.EDIT}
        />

        <FormDateTimeField
          name="validTo"
          label={
            <HelpLabel
              label="Platnost do"
              code="EMPOWERMENT_VALID_TO"
              required={!withoutDay}
            />
          }
          disabled={withoutDay || mode === DetailMode.EDIT}
        />
        {mode !== DetailMode.NEW && (
          <FormCustomField label="Na neurčito">
            <Checkbox onChange={noop} value={!validTo} disabled={true} />
          </FormCustomField>
        )}
        {mode === DetailMode.NEW && (
          <FormCheckbox
            name="withoutDay"
            label={
              <HelpLabel label="Na neurčito" code="EMPOWERMENT_WITHOUT_DAY" />
            }
            notifyChange={notifyWithoutDayChanged}
          />
        )}
        {agenda?.type === AgendaType.SPECIFIED && (
          <SpecificationTable
            key={id + mode}
            disabled={mode === DetailMode.VIEW || mode === DetailMode.NONE}
          />
        )}
      </FormPanel>

      {isAdmin && (
        <FormPanel
          label="Dokumenty ve spisové službě"
          expandable
          defaultExpanded={false}
        >
          <FormTextField
            name="empowermentDocument.sequenceNumber"
            label={
              <HelpLabel
                label="Příloha (plná moc konvertovaná)"
                code="EMPOWERMENT_EMPOWERMENT_DOCUMENT"
              />
            }
            disabled
          />
        </FormPanel>
      )}

      {props.Notes}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <DatedFields />
      </FormPanel>
    </>
  );
}

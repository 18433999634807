import { UserExternalId } from '../../models';

export const externalIdsValueMapper = ({
  value,
}: {
  value: UserExternalId[];
}) =>
  value
    ? value.map((externalId) => externalId.identityProvider).join(', ')
    : '';

externalIdsValueMapper.displayName = 'externalIdsValueMapper';

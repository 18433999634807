/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Notification messages
 */
const SET_OPTIONAL: MessageType<'ERROR'> = {
  ERROR: [
    'Notifikační událost není možné označit jako volitelnou.',
    SnackbarVariant.ERROR,
  ],
};

const CREATE: MessageType<
  | 'SYSTEM_EVENT_NOTIFICATION_TEMPLATE_CREATION_NOT_ALLOWED'
  | 'NOTIFICATION_TEMPLATE_TYPE_NOT_ALLOWED'
  | 'ERROR'
> = {
  ERROR: ['Chyba při vytváření notifikační šablony.', SnackbarVariant.ERROR],
  SYSTEM_EVENT_NOTIFICATION_TEMPLATE_CREATION_NOT_ALLOWED: [
    'Pro systémovou událost nelze vytvořit novou šablonu.',
    SnackbarVariant.ERROR,
  ],
  NOTIFICATION_TEMPLATE_TYPE_NOT_ALLOWED: [
    'Pro danou událost nelze vytvořit šablonu vybraného typu.',
    SnackbarVariant.ERROR,
  ],
};

const EDIT: MessageType<
  | 'NOTIFICATION_TEMPLATE_TYPE_NOT_ALLOWED'
  | 'ERROR'
  | 'SYSTEM_NOTIFICATION_TEMPLATE_EVENT_CHANGE_NOT_ALLOWED'
> = {
  ERROR: ['Chyba při upravování notifikační šablony.', SnackbarVariant.ERROR],
  NOTIFICATION_TEMPLATE_TYPE_NOT_ALLOWED: [
    'Pro danou událost nelze vytvořit šablonu vybraného typu.',
    SnackbarVariant.ERROR,
  ],
  SYSTEM_NOTIFICATION_TEMPLATE_EVENT_CHANGE_NOT_ALLOWED: [
    'Událost systémové notifikační šablony nelze změnit.',
    SnackbarVariant.ERROR,
  ],
};

const SUBSCRIBE: MessageType<'ERROR' | 'SUBSCRIBED' | 'ALREADY_SUBSCRIBED'> = {
  SUBSCRIBED: ['Zrušení odběru bylo úspěšně zrušeno.', SnackbarVariant.SUCCESS],
  ALREADY_SUBSCRIBED: ['Odběr notifikace již byl zahájen.', SnackbarVariant.ERROR],
  ERROR: ['Chyba při zahájení odběru notifikace.', SnackbarVariant.ERROR],
}

const UNSUBSCRIBE: MessageType<'ERROR' | 'ALREADY_UNSUBSCRIBED' | 'UNSUBSCRIBED' | 'MANDATORY'> = {
  UNSUBSCRIBED: ['Odběr notifikace úspěšně odhlášen.', SnackbarVariant.SUCCESS],
  ALREADY_UNSUBSCRIBED: ['Odběr notifikace již byl odhlášen.', SnackbarVariant.ERROR],
  MANDATORY: ['Odběr notifikace nelze odhlásit, notifikace je povinná. Preference o odhlášení uložena.', SnackbarVariant.WARNING],
  ERROR: ['Chyba při odhlášení odběru notifikace.', SnackbarVariant.ERROR],
}

export default { SET_OPTIONAL, CREATE, EDIT, UNSUBSCRIBE, SUBSCRIBE };

import { RoleInstance } from '../../models';

export const allRolesValueMapper = function allRolesValueMapper({
  value,
}: {
  value: RoleInstance[];
}) {
  const roles = value.map((roleInstance) => {
    let validTo = '';
    if (roleInstance.validTo) {
      validTo = ` (do ${new Date(roleInstance.validTo).toLocaleDateString()})`;
    }

    return `${roleInstance.role?.name}${validTo}`;
  });

  return roles.join(', ');
};

allRolesValueMapper.displayName = 'allRolesValueMapper';

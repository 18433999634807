import {
  validateEmailNotRequired,
  validateWebsite,
} from './../profile/profile-utils';
import * as Yup from 'yup';
import {
  IrzFacility,
  ActivityRecord,
  DictionaryNace,
  DictionaryNaceCz,
  DictionaryRiverBasinCzDistrict,
  DictionaryRiverBasinEuDistrict,
  MainActivityType,
  DictionaryEPrtrActivity,
  DictionaryIrzActivity,
  SubjectUnion,
  IrzEmissionsTradingSystem,
  DictionaryCompetentControlAuthority,
} from '../../models';
import {
  addressFieldSchema,
  wgs84CoordinatesSchema,
} from '../../components/form/address-field/address-field-schema';

export function useValidationSchema() {
  return Yup.object<IrzFacility>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    nace: Yup.mixed<DictionaryNace>().nullable().required('Povinné pole'),
    czNace: Yup.mixed<DictionaryNaceCz>().nullable().required('Povinné pole'),
    czRiverBasinDistrict: Yup.mixed<DictionaryRiverBasinCzDistrict>()
      .nullable()
      .required('Povinné pole'),
    euRiverBasinDistrict: Yup.mixed<DictionaryRiverBasinEuDistrict>()
      .nullable()
      .required('Povinné pole'),
    webSite: Yup.string()
      .nullable()
      .when(['withoutWebSite', 'operator.ico'], {
        is: (withoutWebSite, ico) => withoutWebSite != true && ico != undefined,
        then: validateWebsite,
      }),
    publicEmail: validateEmailNotRequired(),
    operator: Yup.mixed<SubjectUnion>().nullable().required('Povinné pole'),
    /*
    competentControlAuthorities: Yup.mixed<
      DictionaryCompetentControlAuthority[]
    >()
      .nullable()
      .required('Povinné pole'),
    */
    integratedPermission: Yup.object().shape({
      ippcPids: Yup.array()
        .of<string>(
          Yup.mixed<string>().test('', '', function (val) {
            const { createError, parent } = this;
            const isInvalid = !val || !/^MZP[A-Z0-9]{9}$/.test(val);

            if (isInvalid) {
              return createError({
                path: 'integratedPermission.ippcPids',
                message: `Musí začínat písmeny MZP a následovat 9 velkých alfanumerických znaků`,
              });
            }

            const duplicates = (parent as string[]).filter(
              (item, index) => parent.indexOf(item) !== index
            );

            if (duplicates.length > 0) {
              return createError({
                path: 'integratedPermission.ippcPids',
                message: `Každý PID je možné vyplnit pouze jednou ${JSON.stringify(
                  duplicates
                )}`,
              });
            }

            return true;
          })
        )
        .test('', '', function (val) {
          const { createError, parent } = this;
          const { hasPermission } = parent;

          const isInvalid = hasPermission && (!val || val.length < 1);

          if (isInvalid) {
            return createError({
              path: 'integratedPermission.ippcPids',
              message:
                'Pokud má provozovna integrované povolení, je nutné přidat alespoň 1 PID',
            });
          }
          return true;
        }),
    }),
    activityTypes: Yup.array<MainActivityType[]>()
      .default([])
      .nullable()
      .min(1, 'Povinné pole'),
    mainIrzActivity: Yup.mixed<ActivityRecord>().when('activityTypes', {
      is: (type) =>
        type?.includes(MainActivityType.IRZ) &&
        !type?.includes(MainActivityType.E_PRTR),
      then: activityRecordSchema(),
    }),
    mainEprtrActivity: Yup.mixed<ActivityRecord>().when('activityTypes', {
      is: (type) => type?.includes(MainActivityType.E_PRTR),
      then: activityRecordSchema(),
    }),
    secondaryIrzActivities: Yup.mixed<ActivityRecord[]>().when(
      'activityTypes',
      {
        is: (type) => type?.includes(MainActivityType.IRZ),
        then: Yup.array()
          .of(activityRecordTableSchema('secondaryIrzActivities'))
          .default([])
          .test('', '', function () {
            const { createError, parent } = this;
            const allValues: IrzFacility = parent;

            const mainIrzActivity = allValues.mainIrzActivity;
            const secondaryIrzActivities = allValues.secondaryIrzActivities;

            const containsMainActivity = secondaryIrzActivities?.find(
              (activity) =>
                activity?.activity?.id === mainIrzActivity?.activity?.id
            );

            if (containsMainActivity) {
              return createError({
                path: 'secondaryIrzActivities',
                message: `Nesmí obsahovat hlavní činnost`,
              });
            }

            const isEmpty = secondaryIrzActivities?.length === 0;

            if (!mainIrzActivity && isEmpty) {
              return createError({
                path: 'secondaryIrzActivities',
                message: `Musí obsahovat alespoň 1 činnost`,
              });
            }

            return true;
          }),
      }
    ),
    competentControlAuthorities: Yup.array().of(
      Yup.object<DictionaryCompetentControlAuthority>()
        .test('', '', function (val) {
          const { createError } = this;
          if (!val?.id) {
            return createError({
              path: 'competentControlAuthorities',
              message: `Pole Kompetentní kontrolní / jiný orgán nesmí být prázdné`,
            });
          }
          return true;
        })
        .required('Povinné pole')
    ),
    secondaryEprtrActivities: Yup.mixed<ActivityRecord[]>().when(
      'activityTypes',
      {
        is: (type) => type?.includes(MainActivityType.E_PRTR),
        then: Yup.array()
          .of(activityRecordTableSchema('secondaryEprtrActivities'))
          .default([])
          .test('', '', function () {
            const { path, createError, parent } = this;
            const allValues: IrzFacility = parent;

            const mainEprtrActivity = allValues.mainEprtrActivity;
            const secondaryEprtrActivities = allValues.secondaryEprtrActivities;

            const isInvalid = secondaryEprtrActivities?.find(
              (activity) =>
                activity?.activity?.id === mainEprtrActivity?.activity?.id
            );

            if (isInvalid) {
              return createError({
                path: path,
                message: `Nesmí obsahovat hlavní činnost`,
              });
            }

            return true;
          }),
      }
    ),
    emissionsTradingSystem: Yup.object().shape({
      ids: Yup.array()
        .of(
          Yup.string().test('', '', function (val) {
            const { createError, parent } = this;

            const isInvalid = !val || !/CZ-[0-9]{4}$/.test(val);

            if (isInvalid) {
              return createError({
                path: 'emissionsTradingSystem.ids',
                message: `Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9`,
              });
            }

            const duplicates = (parent as string[]).filter(
              (item, index) => parent.indexOf(item) !== index
            );

            if (duplicates.length > 0) {
              return createError({
                path: 'emissionsTradingSystem.ids',
                message: `Každý PID je možné vyplnit pouze jednou ${JSON.stringify(
                  duplicates
                )}`,
              });
            }

            return true;
          })
        )
        .test('', '', function (val) {
          const { createError, parent } = this;
          const { tradesInEmissions }: IrzEmissionsTradingSystem = parent;

          /**
           * If tradesInEmissions is filled, table needs to contain at least one item
           */
          const isInvalid = tradesInEmissions && (!val || val.length < 1);

          if (isInvalid) {
            return createError({
              path: 'emissionsTradingSystem.ids',
              message: `Při obchodování s emisemi je potřeba přidat alespoň 1 identifikátor`,
            });
          }
          return true;
        }),
    }),
    address: addressFieldSchema(),
    wgs84Coordinates: wgs84CoordinatesSchema(),
  }) as Yup.ObjectSchema<IrzFacility>;
}

const activityRecordSchema = () =>
  Yup.object<ActivityRecord>({
    activity: Yup.mixed<DictionaryEPrtrActivity | DictionaryIrzActivity>()
      .nullable()
      .required('Činnost musí být vyplněna'),
    facilityCount: Yup.number()
      .typeError(`Počet zařízení je povinné`)
      .min(1, `Počet zařízení musí být alespoň 1`)
      .required(`Počet zařízení je povinné`),
  });

const activityRecordTableSchema = (path: string) =>
  Yup.mixed().test('', '', function (value) {
    const { createError } = this;

    const activityRecord: ActivityRecord = value;

    if (!activityRecord?.activity) {
      return createError({
        path,
        message: 'Činnost musí být vyplněna',
      });
    }

    if (activityRecord?.facilityCount === undefined) {
      return createError({
        path,
        message: 'Počet zařízení je povinné',
      });
    }

    if (activityRecord?.facilityCount < 1) {
      return createError({
        path,
        message: 'Počet zařízení musí být alespoň 1',
      });
    }

    return true;
  });

import React from 'react';
import Typography from '@material-ui/core/Typography';

export function StepFinishContent() {
  return (
    <Typography variant="body1">
      Žádost o změnu údajů provozovny IRZ byla úspěšně odeslána.
    </Typography>
  );
}

import React, { useContext, useMemo } from 'react';
import {
  EvidenceStateAction,
  NavigationContext,
  useEventCallback,
} from '@eas/common-web';
import {
  FacilityMarkerBody as IrzFacilityMarkerBody,
  FacilityMarkerTitle as IrzFacilityMarkerTitle,
} from '../../irz-facility/irz-facilities-map';
import { useLandPlotTypes } from '../../ovz-facility/ovz-facilities-api';
import {
  FacilityMarkerBody as OvzFacilityMarkerBody,
  FacilityMarkerTitle as OvzFacilityMarkerTitle,
} from '../../ovz-facility/ovz-facilities-map';
import {
  ProductionSiteMarkerBody,
  ProductionSiteMarkerTitle,
} from '../../production-site/production-sites-map';
import { Area } from '../overview-map-types';
import { useMarkerData } from './marker-data-hook';
import { IrzFacility, OvzFacility, ProductionSite } from '../../../models';
import { EvidenceUrl } from '../../../enums';

export function useCardData() {
  const { navigate } = useContext(NavigationContext);
  const landPlotTypes = useLandPlotTypes();
  const { data } = useMarkerData<
    (IrzFacility | OvzFacility | ProductionSite) & { type: Area }
  >();

  const handleSetActiveIrz = useEventCallback((id: string) => {
    navigate(EvidenceUrl.IRZ_FACILITIES, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: id,
    });
  });

  const handleSetActiveOvz = useEventCallback((id: string) => {
    navigate(EvidenceUrl.OVZ_FACILITIES, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: id,
    });
  });

  const handleSetActiveProductionSite = useEventCallback((id: string) => {
    navigate(EvidenceUrl.PRODUCTION_SITES, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: id,
    });
  });

  const cardData = useMemo(() => {
    if (data?.type === Area.IRZ_FACILITY) {
      return {
        title: <IrzFacilityMarkerTitle name={data?.name ?? ''} />,
        body: (
          <IrzFacilityMarkerBody data={data!} setActive={handleSetActiveIrz} />
        ),
      };
    }

    if (data?.type === Area.OVZ_FACILITY) {
      return {
        title: <OvzFacilityMarkerTitle name={data?.name ?? ''} />,
        body: (
          <OvzFacilityMarkerBody
            data={{
              ...data,
              landPlotTypeLabel:
                landPlotTypes.items.find(
                  (i) => i.id === (data as OvzFacility)?.landPlotType
                )?.name ?? '-',
            }}
            setActive={handleSetActiveOvz}
          />
        ),
      };
    }

    return {
      title: <ProductionSiteMarkerTitle name={data?.name ?? ''} />,
      body: (
        <ProductionSiteMarkerBody
          data={data!}
          setActive={handleSetActiveProductionSite}
        />
      ),
    };
  }, [
    data,
    handleSetActiveIrz,
    handleSetActiveOvz,
    handleSetActiveProductionSite,
  ]);

  return { cardData };
}

import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { useStyles } from './detail-styles';
import {
  DetailToolbarButtonType,
  DetailToolbarButtonProps,
  Tooltip,
} from '@eas/common-web';

export function DetailToolbarButton({
  label,
  tooltip,
  disabled,
  onClick,
  href,
  type = DetailToolbarButtonType.NORMAL,
  startIcon,
  endIcon,
  buttonType,
  isLocked,
  ...restProps
}: DetailToolbarButtonProps) {
  const classes = useStyles();

  const buttonProps: ButtonProps = { ...restProps };

  if (type === DetailToolbarButtonType.PRIMARY) {
    buttonProps.color = 'primary';
    buttonProps.variant = 'outlined';
    buttonProps.classes = {
      root: classes.toolbarMainButton,
      disabled: classes.toolbarDisabledButton,
      label: classes.toolbarButtonLabel,
      startIcon: classes.toolbarButtonIcon,
    };
  } else if (type === DetailToolbarButtonType.SECONDARY) {
    buttonProps.color = 'secondary';
    buttonProps.variant = 'outlined';
    buttonProps.classes = {
      root: classes.toolbarWarningButton,
      disabled: classes.toolbarDisabledButton,
      label: classes.toolbarButtonLabel,
      startIcon: classes.toolbarButtonIcon,
    };
  }

  return (
    <Tooltip title={tooltip} placement="top-start">
      <Button
        onClick={onClick}
        href={href}
        disabled={disabled}
        classes={{
          root: classes.toolbarButton,
          disabled: classes.toolbarDisabledButton,
          label: classes.toolbarButtonLabel,
          startIcon: classes.toolbarButtonIcon,
        }}
        variant="outlined"
        {...buttonProps}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {label}
      </Button>
    </Tooltip>
  );
}

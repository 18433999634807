/**
 * Deposit permissions
 */
export enum PermissionDeposit {
  /** Prehled ložisek */
  DEPOSIT_LIST = 'DEPOSIT_LIST',

  /** Detail ložiska */
  DEPOSIT_DETAIL = 'DEPOSIT_DETAIL',

  /** Vytvoření ložiska */
  DEPOSIT_CREATE = 'DEPOSIT_CREATE',

  /** Editace ložiska */
  DEPOSIT_UPDATE = 'DEPOSIT_UPDATE',

  /** Mazání ložiska */
  DEPOSIT_DELETE = 'DEPOSIT_DELETE',

  /** Zneplatnění ložiska */
  DEPOSIT_INVALIDATE = 'DEPOSIT_INVALIDATE',

  /** Obnovení ložiska */
  DEPOSIT_REVALIDATE = 'DEPOSIT_REVALIDATE',
}

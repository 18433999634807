import React, { useContext, useEffect } from 'react';
import {
  UserContext,
  NavigationContext,
  SnackbarContext,
} from '@eas/common-web';
import { RouteProps, Route } from 'react-router-dom';
import { Me, PermissionsState } from '../../models';
import { Permission, Messages } from '../../enums';

export function ProtectedRoute(
  props: RouteProps & { permissions: string[]; enableRedirect?: boolean }
) {
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );
  const { navigate } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const permitted = !props.permissions.some((perm) =>
      hasPermission(perm, { searchInSubjectPerms: true })
    );

    const isAdmin =
      hasPermission(Permission.Default.ADMINISTRATOR) ||
      hasPermission(Permission.Default.CENIA_ADMIN);

    const isLogged = props.permissions[0] === Permission.Default.LOGGED_USER;

    if (permitted && !isAdmin && !isLogged && props.enableRedirect !== true) {
      navigate('/crzp');
      const warning = Messages.ProtectedRoute.ACCESS.WARNING;
      showSnackbar(warning[0], warning[1], true);
    }
  });

  return <Route {...props} />;
}

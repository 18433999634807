import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { coordinatesValueMapper } from '../../components/evidence/history-evidence/history-utils';
import { useIrzFacilitiesWithoutProductionSite } from '../irz-facility/irz-facilities-api';
import { useAutocompleteSource, DictionaryAutocomplete } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const facilities = useIrzFacilitiesWithoutProductionSite();
  const districts = useAutocompleteSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.ADDRESS_DISCTRICT}/autocomplete`,
  });

  return [
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'uniqueCode',
      label: 'Jedinečný kód',
    },
    {
      key: 'inspireIdNamespace',
      label: 'Identifikátor EU',
    },
    {
      key: 'wgs84Coordinates.lat',
      label: 'Zeměpisná šířka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'wgs84Coordinates.lon',
      label: 'Zeměpisná délka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'facilities',
      label: 'Provozovny',
      source: facilities,
    },
    {
      key: 'district',
      label: 'Okres',
      source: districts,
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
  ];
}

import { AddressPlace } from '../../models';
import { TableColumn, TableCells, ApiFilterOperation } from '@eas/common-web';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export const useColumns = () => {
  const columns: TableColumn<AddressPlace, any>[] = [
    {
      datakey: 'houseNumber',
      name: 'Číslo domovní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory<AddressPlace>(TableCells.TextCell),
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'zip',
      name: 'PSČ',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory<AddressPlace>(TableCells.TextCell),

      filterable: true,
      sortable: true,
    },
  ];

  return columns;
};

import React, { useContext, useMemo } from 'react';
import {
  FormTextField,
  FormPanel,
  DetailContainer,
  useFormSelector,
  FormCheckbox,
  DetailContext,
  DetailMode,
  UserContext,
  FormSelect,
  FormInlineTableField,
  FormInstantField,
  FormCustomField,
  TextField,
} from '@eas/common-web';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { Me, User } from '../../models';
import { Permission } from '../../enums';
import { FormPanelCount } from '../../components/form/form-panel-summary/form-panel-count';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { useExpandableFormPanel } from '../../composite/form-panel/expandable-form-panel-hook';
import { PhoneField } from '../../components/form/phone-field/phone-field';
import { HelpLabel } from '../../components/help/help-label';
import { adjustWhitespaces } from './users-utils';
import { AdminRoles } from '../profile/fields/role-admin/role-admin';
import { UserRoles } from '../profile/fields/role-user/role-user';
import { OtherRoles } from '../profile/fields/role-other/role-other';
import { useStyles } from './overrides/role-styles';
import { usePreferredChannelTypes } from '../profile/profile-api';
import { useExternalIdsColumns } from './users-columns';
import { EmpoweredRoles } from '../profile/fields/role-empowered/role-empowered';

interface EnhancedUser extends User {
  asAdministratorCount: number;
  asUserCount: number;
  rolesCount: number;
  empoweredRolesCount: number;
  lastLoginTimestamp?: string;
}

const useCustomStyles = makeStyles((theme) => ({
  granted: {
    fill: theme.palette.primary.main,
  },
  notGranted: {
    fill: theme.palette.error.main,
  },
}));

export function UsersFields(props: NotedEvidenceFieldsProps) {
  const classes = useCustomStyles();

  const {
    id,
    asAdministratorCount,
    asUserCount,
    rolesCount,
    empoweredRolesCount,
    systemAccount,
    roleConsent,
  } = useFormSelector((data: EnhancedUser) => ({
    id: data.id,
    asAdministratorCount: data.asAdministratorCount,
    asUserCount: data.asUserCount,
    rolesCount: data.rolesCount,
    empoweredRolesCount: data.empoweredRolesCount,
    systemAccount: data.systemAccount,
    roleConsent: data.roleConsent,
  }));

  const externalIdsColumns = useExternalIdsColumns();
  const preferredChannelTypes = usePreferredChannelTypes();

  const { mode } = useContext(DetailContext);

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const isAdmin = useMemo(
    () => hasPermission(Permission.User.USER_DETAIL_ALL),
    [hasPermission]
  );

  const { ref: contactPanelRef } = useExpandableFormPanel();

  return (
    <DetailContainer>
      <FormPanel label="Základní informace">
        <FormTextField
          name="username"
          label={
            <HelpLabel
              label="Uživatelské jméno"
              code="USERS_USERNAME"
              required
            />
          } // Povinné pole
          disabled={mode !== DetailMode.NEW}
        />
        {isAdmin && mode !== DetailMode.NEW && (
          <>
            <FormTextField
              name="id"
              label={<HelpLabel label="UUID" code="USERS_UUID" />}
              disabled
            />
            <FormCheckbox
              name="mailingConsent"
              label={
                <HelpLabel
                  label="Souhlas s odesíláním kampaní"
                  code="USERS_MAILING_CONSENT"
                />
              }
              disabled
            />
            <FormCheckbox
              name="twoFactorEnabled"
              label={
                <HelpLabel
                  label="Dvoufaktorová autentizace"
                  code="USERS_TWO_FACTOR_ENABLED"
                />
              }
              disabled
            />
            <FormInlineTableField
              name="externalIds"
              label={
                <HelpLabel label="Externí IdP" code="USERS_EXTERNAL_IDS" />
              }
              columns={externalIdsColumns}
              showToolbar={false}
              disabled
            />
          </>
        )}
        <FormCheckbox
          name="active"
          label={<HelpLabel label="Aktivní" code="USERS_ACTIVE" />}
          disabled
        />
        <FormInstantField
          name="lastLogin.timestamp"
          label={
            <HelpLabel
              label="Poslední přihlášení"
              code="USERS_LAST_LOGIN_TIMESTAMP"
            />
          }
          disabled
        />
        <FormCheckbox
          name="systemAccount"
          label={
            <HelpLabel label="Systémový účet" code="USERS_SYSTEM_ACCOUNT" />
          }
          disabled={!hasPermission(Permission.User.USER_UPDATE_ALL)}
        />
        {!systemAccount && (
          <FormCustomField
            label={
              <HelpLabel
                label="Souhlas s nahlížením na data"
                code="USERS_ROLE_CONSENT"
              />
            }
            before={
              roleConsent ? (
                <CheckIcon className={classes.granted} />
              ) : (
                <CloseIcon className={classes.notGranted} />
              )
            }
          >
            {roleConsent ? (
              <TextField value="Udělen" disabled />
            ) : (
              <TextField value="Neudělen" disabled />
            )}
          </FormCustomField>
        )}
      </FormPanel>
      <FormPanel
        label="Kontaktní údaje"
        expandable
        defaultExpanded={false}
        ref={contactPanelRef}
      >
        <FormTextField
          name="degreeBefore.value"
          label={<HelpLabel label="Titul před" code="USERS_DEGREE_BEFORE" />}
        />
        <FormTextField
          name="firstName.value"
          label={<HelpLabel label="Jméno" code="USERS_FIRSTNAME" required />} // Povinné pole
          onChangeEvent={adjustWhitespaces}
        />
        <FormTextField
          name="lastName.value"
          label={<HelpLabel label="Příjmení" code="USERS_LASTNAME" required />} // Povinné pole
          onChangeEvent={adjustWhitespaces}
        />
        <FormTextField
          name="degreeAfter.value"
          label={<HelpLabel label="Titul za" code="USERS_DEGREE_AFTER" />}
        />
        <FormTextField
          name="email.value"
          label={<HelpLabel label="Email" code="USERS_EMAIL" required />} // Povinné pole
        />
        <PhoneField
          name="phoneNumber"
          label={
            <HelpLabel
              label="Telefonní číslo (předvolba, číslo)"
              code="USERS_PHONE_NUMBER"
              required
            />
          }
        />
        {isAdmin && mode !== DetailMode.NEW && (
          <>
            <FormSelect
              name="twoFactorDetails.preferredChannel"
              label={
                <HelpLabel
                  label="Preferovaný způsob ověření"
                  code="USERS_PREFERRED_CHANNEL"
                />
              }
              source={preferredChannelTypes}
              tooltipMapper={(o) => o?.name ?? '-'}
              labelMapper={(o) => o?.name ?? '-'}
              valueIsId={true}
            />
            <FormTextField
              name="twoFactorDetails.email.value"
              label={<HelpLabel label="2FA E-mail" code="USERS_2FA_EMAIL" />}
            />
            <PhoneField
              name="twoFactorDetails.phoneNumber"
              label={<HelpLabel label="2FA Telefon" code="USERS_2FA_EMAIL" />}
            />
          </>
        )}
      </FormPanel>
      {mode === DetailMode.VIEW && (
        <>
          <FormPanel
            label="Správce subjektu"
            expandable
            defaultExpanded={false}
            summary={<FormPanelCount count={asAdministratorCount} />}
          >
            <AdminRoles profile={{ id } as User} useStyles={useStyles} />
          </FormPanel>
          <FormPanel
            label="Běžný uživatel subjektu"
            expandable
            defaultExpanded={false}
            summary={<FormPanelCount count={asUserCount} />}
          >
            <UserRoles profile={{ id } as User} useStyles={useStyles} />
          </FormPanel>

          <FormPanel
            label="Přiřazené role"
            expandable
            defaultExpanded={false}
            summary={<FormPanelCount count={rolesCount} />}
          >
            <OtherRoles profile={{ id } as User} useStyles={useStyles} />
          </FormPanel>

          <FormPanel
            label="Role nabyté zmocněním"
            expandable
            defaultExpanded={false}
            summary={<FormPanelCount count={empoweredRolesCount} />}
          >
            <EmpoweredRoles profile={{ id } as User} useStyles={useStyles} />
          </FormPanel>
        </>
      )}

      {props.Notes}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <DatedFields />
      </FormPanel>
    </DetailContainer>
  );
}

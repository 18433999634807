import { makeStyles } from '@material-ui/core/styles';
import React, { forwardRef, Ref, useContext, useMemo } from 'react';
import {
  TableFieldRowProps,
  TableFieldContext,
  TableFieldCells,
} from '@eas/common-web';
import { get, cloneDeep } from 'lodash';
import { SubjectRelationship } from '../subjects-types';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import Typography from '@material-ui/core/Typography';
import { UserRoleInstance } from '../../../models';

const useStyles = makeStyles((theme) => ({
  rowWrapper: {
    background: '#e0e2e3',
    '&:hover': {
      backgroundColor: theme.palette.highlight,
    },
  },
  row: {
    boxSizing: 'border-box',
    minWidth: '100%',
    minHeight: 30,
    padding: '2px 16px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  rowDetails: {
    width: '100%',
    padding: '2px 16px',
    borderBottom: '1px solid #cdcdcd',
  },
  tableRowActions: {
    flexShrink: 0,
    width: 35,
    height: 20,
    verticalAlign: 'top',
    display: 'inline-block',
    padding: 0,
    overflow: 'hidden',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  cellWrapper: {
    flexShrink: 0,
    display: 'flex',
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '11px',
    fontWeight: 600,
    marginLeft: 10,
  },
  content: {
    display: 'flex',
  },
  icon: {
    float: 'left',
    marginTop: -4,
    marginRight: 10,
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  radioButton: {
    padding: 0,
  },
}));

export const UserRelationshipTableFieldRow = forwardRef(
  function UsersTableFieldRow(
    { index, value }: TableFieldRowProps<SubjectRelationship>,
    ref: Ref<HTMLDivElement>
  ) {
    const classes = useStyles();
    const { filteredColumns } = useContext<
      TableFieldContext<SubjectRelationship>
    >(TableFieldContext);

    const roleInstances = useMemo(() => {
      const enhancedEmpoweredRoleInstances = (
        value?.empoweredUserRoles ?? []
      ).map((roleInstance) => {
        const enhanced = cloneDeep(roleInstance);
        enhanced.role.name = `[Z] ${roleInstance.role.name}`;

        return enhanced;
      });

      const roleInstances = [
        ...(value?.userRoleInstances ?? []),
        ...enhancedEmpoweredRoleInstances,
      ];

      const sortedRoleInstances = sortRoleInstances(roleInstances);

      return sortedRoleInstances;
    }, [value?.empoweredUserRoles, value?.userRoleInstances]);

    return (
      <div ref={ref} className={classes.rowWrapper}>
        <div className={classes.row}>
          {filteredColumns.map((column, i) => {
            const { CellComponent = TableFieldCells.TextCell } = column;
            return (
              <div
                key={i}
                className={classes.cellWrapper}
                style={{
                  width: column.width,
                  color: value?.active ? 'inherit' : 'gray',
                }}
              >
                <CellComponent
                  index={index}
                  value={get(value, column.datakey, '')}
                  rowValue={value}
                  column={column as any}
                />
              </div>
            );
          })}
        </div>
        <div className={classes.rowDetails}>
          {roleInstances.map((roleInstance, i) => (
            <div key={i} className={classes.cellWrapper}>
              <div
                style={{
                  width: filteredColumns[0].width,
                  color: roleInstance?.active ? 'inherit' : 'gray',
                }}
                key={roleInstance.id}
              >
                <Typography variant="body1" component="div">
                  <ul
                    style={{
                      width: filteredColumns[0].width,
                    }}
                  >
                    <span
                      className={classes.label}
                      key={roleInstance.role.name}
                    >
                      <SubdirectoryArrowRightIcon
                        fontSize="small"
                        className={classes.icon}
                      />
                      <span className={classes.text}>
                        {roleInstance.role.name} (
                        {roleInstance.role.registeredFor?.name})
                      </span>
                    </span>
                  </ul>
                </Typography>
              </div>
              <div
                style={{
                  width: filteredColumns[1].width,
                  color: roleInstance?.active ? 'inherit' : 'gray',
                }}
              >
                <TableFieldCells.DateTimeCell
                  index={index}
                  column={{} as any}
                  value={get(roleInstance, 'validFrom', '')}
                  rowValue={value}
                />
              </div>
              <div
                style={{
                  width: filteredColumns[2].width,
                  color: roleInstance?.active ? 'inherit' : 'gray',
                }}
              >
                <TableFieldCells.DateTimeCell
                  index={index}
                  column={{} as any}
                  value={get(roleInstance, 'validTo', '')}
                  rowValue={value}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export const sortRoleInstances = (roleInstances: UserRoleInstance[]) =>
  roleInstances?.sort((firstRole, secondRole) => {
    if (
      firstRole!.role.registeredFor!.name < secondRole!.role.registeredFor!.name
    ) {
      return -1;
    }
    if (
      firstRole!.role.registeredFor!.name > secondRole!.role.registeredFor!.name
    ) {
      return 1;
    }
    return 0;
  });

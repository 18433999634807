import { useMemo, ComponentType } from 'react';
import {
  TableColumn,
  TableCells,
  TableFilterCells,
  TableFieldColumn,
  InlineTableFieldCells,
  DictionaryAutocomplete,
  ApiFilterOperation,
  TableCellProps,
  TableFieldCells,
} from '@eas/common-web';
import {
  EmpowermentRequest,
  EmpowermentSpecificationRequest,
  EmpowermentRequestState,
} from '../../models';
import {
  useEmpowermentSpecificationStates,
  useAuthorRoles,
  useEmpowermentStates,
} from './empowerment-requests-api';
import { useEmpowerableAgendas, useAgendaTypes } from '../agenda/agendas-api';
import { useAgendaSpecifications } from '../../composite/empowerment-specs-table/specification-api';
import { requestColoredFactory } from '../../components/form/request-colored/request-colored';
import { specificationRequestsValueMapper } from '../../composite/empowerment-column-mappers/specification-requests-mapper';

function isApproved(req: EmpowermentRequest) {
  return req?.state === EmpowermentRequestState.APPROVED;
}
function isRejected(req: EmpowermentRequest) {
  return req?.state === EmpowermentRequestState.REJECTED;
}

function isAwaitingDecision(req: EmpowermentRequest) {
  return req?.state === EmpowermentRequestState.AWAITING_DECISION;
}

export function empowermentRequestColumnFactory(
  cell: ComponentType<TableCellProps<EmpowermentRequest>>
) {
  return requestColoredFactory(
    cell,
    isApproved,
    isRejected,
    isAwaitingDecision
  );
}

export function useColumns(): TableColumn<EmpowermentRequest>[] {
  return [
    {
      datakey: 'state',
      filterkey: 'state.id',
      sortkey: 'state.name',
      name: 'Stav',
      width: 200,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useEmpowermentStates
      ),
      valueMapper: TableCells.useSelectCellFactory(useEmpowermentStates),
      sortable: true,
      filterable: true,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ agendy',
      width: 200,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useAgendaTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.useSelectCellFactory(useAgendaTypes),
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'specificationRequests',
      name: 'Typ hlášení',
      width: 200,
      sortkey: 'specificationRequests.specification.concatenated',
      filterkey: 'specificationRequests.specification.id',
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useAgendaSpecifications
      ),
      valueMapper: specificationRequestsValueMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'agenda',
      sortkey: 'agenda.name',
      filterkey: 'agenda.id',
      name: 'Agenda',
      width: 200,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useEmpowerableAgendas
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'authorRole',
      sortkey: 'authorRole.name',
      filterkey: 'authorRole.id',
      name: 'Autor',
      width: 200,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useAuthorRoles
      ),
      valueMapper: TableCells.useSelectCellFactory(useAuthorRoles),
      sortable: true,
      filterable: true,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 2,
    },
    {
      datakey: 'empowerer.name',
      name: 'Zmocnitel - Název',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },

    {
      datakey: 'empowerer.identifier',
      name: 'Zmocnitel - IČO / UID',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
      // valueMapper: icoValueMapperFactory('empowerer'),
    },
    // {
    //   datakey: 'empowerer.artificialId',
    //   name: 'Zmocnitel - UID',
    //   width: 300,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 2,
    //   valueMapper: artificialIdValueMapperFactory('empowerer'),
    // },
    {
      datakey: 'empowerer.identityDocumentsConcatenated',
      name: 'Zmocnitel - OP / pas',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'agent.name',
      name: 'Zmocněnec - Název',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'agent.identifier',
      name: 'Zmocněnec - IČO / UID',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
      // valueMapper: icoValueMapperFactory('agent'),
    },
    // {
    //   datakey: 'agent.artificialId',
    //   name: 'Zmocněnec - UID',
    //   width: 300,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 2,
    //   valueMapper: artificialIdValueMapperFactory('agent'),
    // },
    {
      datakey: 'agent.identityDocumentsConcatenated',
      name: 'Zmocněnec - OP / pas',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: empowermentRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      width: 200,
      CellComponent: empowermentRequestColumnFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      width: 200,
      CellComponent: empowermentRequestColumnFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

export function useEmpowermentSpecificationRequestColumns({
  disabledState = false,
}: {
  disabledState?: boolean | ((index: number) => boolean);
}): TableFieldColumn<EmpowermentSpecificationRequest>[] {
  const StateCell = InlineTableFieldCells.useInlineSelectCellFactory<
    EmpowermentSpecificationRequest,
    DictionaryAutocomplete
  >({
    dataHook: useEmpowermentSpecificationStates,
    collectionDatakey: 'specificationRequests',
    valueIsId: true,
    disabled: disabledState,
  });

  return useMemo(
    () => [
      {
        name: 'Název',
        datakey: 'specification.name',
        width: 200,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        name: 'Stav',
        datakey: 'approvalState',
        width: 200,
        CellComponent: StateCell,
      },
      {
        name: 'Popis',
        datakey: 'specification.description',
        width: 400,
        CellComponent: TableFieldCells.TextCell,
      },
    ],
    [StateCell]
  );
}

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { Role } from '../../models';
import { useRoleTypes, useRoles } from './roles-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { useSystems } from '../system/systems-api';

export function useColumns(canSeeDeactivated: boolean): TableColumn<Role>[] {
  const columns: TableColumn<Role>[] = [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    // due to http://git.inqool.cz/eas/projects/crzp/-/issues/1042
    {
      datakey: 'name',
      filterkey: 'id',
      name: 'Role',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useRoles,
        (role) => role?.label ?? role.name
      ),
      filterOperation: ApiFilterOperation.EQ,
      visible: false,
      sortable: false,
      filterable: true,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useRoleTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useRoleTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'code',
      name: 'Kód',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'registeredFor',
      filterkey: 'registeredFor.id',
      sortkey: 'registeredFor.id',
      name: 'Systém',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSystems,
        (system) => system?.label ?? system.name
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];

  if (canSeeDeactivated) {
    columns.push({
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
    });
  }
  return columns;
}

export interface AuthContextType {
  type?: AuthType;
  twoFactorEnabled?: boolean;
  loginUrl?: string;
  baseEnviiamUrl?: string;
  logout: (automatic?: boolean, samlLogout?: boolean) => void;
}

export interface AuthTypeResponse {
  type?: AuthType;
  loginUrl?: string;
  baseEnviiamUrl?: string;
  twoFactorEnabled?: boolean;
}

export enum AuthType {
  SAML = 'SAML',
  FORM = 'FORM',
  NONE = 'NONE',
}

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  TableFieldColumn,
  InlineTableFieldCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { Agenda, AgendaSpecification, RoleAutocomplete } from '../../models';
import { useAgendaTypes } from './agendas-api';
import { useMemo } from 'react';
import { useSubjectRoles } from '../roles/roles-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';

export function useColumns(): TableColumn<Agenda>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ agendy',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useAgendaTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useAgendaTypes),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'autoAssign',
      name: 'Automaticky přidelená',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'selfAssignable',
      name: 'Subjekt může agendu vykonávat',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'empowerable',
      name: 'Může být předmětem zmocnění',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
    },
  ];
}

export function useSpecificationColumns(): TableFieldColumn<
  AgendaSpecification
>[] {
  const TextCell = InlineTableFieldCells.useInlineTextFieldFactory<
    AgendaSpecification
  >({
    collectionDatakey: 'specifications',
  });

  const RoleCell = InlineTableFieldCells.useInlineAutocompleteCellFactory<
    AgendaSpecification,
    RoleAutocomplete
  >({
    dataHook: useSubjectRoles,
    collectionDatakey: 'specifications',
    labelMapper: dictionaryLabelMapper,
    multiple: true,
  });

  return useMemo(
    () => [
      {
        datakey: 'name',
        name: 'Název',
        width: 200,
        CellComponent: TextCell,
      },
      {
        datakey: 'description',
        name: 'Popis',
        width: 200,
        CellComponent: TextCell,
      },
      {
        datakey: 'subjectRoles',
        name: 'Subjektová role',
        width: 500,
        CellComponent: RoleCell,
      },
    ],
    [RoleCell, TextCell]
  );
}

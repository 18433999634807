import React from 'react';
import { get } from 'lodash';
import {
  TableFieldCellProps,
  NavigationContext,
  EvidenceStateAction,
  useEventCallback,
  TableCellProps,
} from '@eas/common-web';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useContext } from 'react';

export function redirectToDetailCellFactory(url: string, path?: string) {
  return function Cell(props: TableFieldCellProps<any> | TableCellProps<any>) {
    const { navigate } = useContext(NavigationContext);

    const id = path ? get(props.rowValue, path) : props.value;

    const handleOpenDetail = useEventCallback((e: React.MouseEvent) => {
      e.preventDefault();
      navigate(url, false, {
        action: EvidenceStateAction.SHOW_ITEM,
        data: id,
      });
    });

    return (
      <IconButton
        onClick={handleOpenDetail}
        size="small"
        component="a"
        href={`${url}/${id}`}
      >
        <OpenInNewIcon fontSize="small" style={{ padding: '1pt' }} />
      </IconButton>
    );
  };
}

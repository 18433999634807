import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
  editor: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: 'white',
    width: '100%',
    padding: '0 6px',
    '& *': {
      lineHeight: '2em',
      minHeight: '2em',
    },
    '& ul, & ol': {
      marginLeft: '20px',
    },
  },
});

export function PublicTranslation() {
  const params = useParams<{ id?: string }>();
  const intl = useIntl();

  const classes = useStyles();

  return (
    <Typography variant="body1" component="div" className={classes.editor}>
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: params?.id ?? '',
            defaultMessage: 'Nedefinováno',
          }),
        }}
      />
    </Typography>
  );
}

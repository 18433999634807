import {
  useStaticListSource,
  DictionaryAutocomplete,
  abortableFetch,
} from '@eas/common-web';

export function useAccessTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'PUBLIC', name: 'Veřejný' },
    { id: 'FOR_ROLE', name: 'Soukromý pro roli' },
  ]);
}

export function useDataSources() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'USER_EVIDENCE', name: 'Registr Uživatelů' },
    { id: 'SUBJECT_EVIDENCE', name: 'Registr Subjektů' },
    { id: 'IRZ_EVIDENCE', name: 'Registr Provozoven IRZ' },
    { id: 'OVZ_EVIDENCE', name: 'Registr Provozoven OVZ' },
    { id: 'WUP_EVIDENCE', name: 'Registr MUV' },
    { id: 'PROD_EVIDENCE', name: 'Registr Průmyslových míst' },
    { id: 'OZO_M_EVIDENCE', name: 'Registr OZO - Výrobci' },
    { id: 'OZO_P_EVIDENCE', name: 'Registr OZO - Oprávnění' },
  ]);
}

export function useStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'CONCEPT', name: 'Koncept' },
    { id: 'PUBLISHED', name: 'Publikován' },
  ]);
}

export function publish(id: string) {
  return abortableFetch(`/api/crzp/public-list/${id}/publish`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}

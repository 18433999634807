import { DomainObject } from './domain-object';
import { DictionaryObject } from './dictionary-object';
import { Subject } from './subject';
import { SubjectRegistration } from './subject-registration';
import { User } from './user';
import { BoilerPermission } from './boiler-permission';

/**
 * Typ osobního údaje
 */
export enum PersonalDataType {
  /**
   *
   */
  EMAIL = 'EMAIL',

  /**
   *
   */
  DATE = 'DATE',

  /**
   *
   */
  DATETIME = 'DATETIME',

  /**
   *
   */
  NUMBER = 'NUMBER',

  /**
   *
   */
  PHONE = 'PHONE',

  /**
   *
   */
  TEXT = 'TEXT',
}

export interface PersonalDataProcessingPurpose extends DomainObject {
  /**
   * Účel zpracovaní
   */
  processingPurpose?: DictionaryObject;

  /**
   * Doba uchování: od
   */
  obtained?: string;

  /**
   * Doba uchování: do
   */
  keepTo?: string;
}

/**
 * Osobní údaj
 */
export interface PersonalData extends DomainObject {
  /**
   * Hodnota
   */
  value?: string;

  /**
   * Datový typ
   */
  dataType?: PersonalDataType;

  /**
   * Odkaz na vlastnící entitu
   */
  entityReference?: {
    field?: {
      id?: string;

      label?: string;
    };

    subject?: Subject;

    subjectRegistration?: SubjectRegistration;

    user?: User;

    userRegistration?: any; // TODO: add UserRegistration

    boilerPermission?: BoilerPermission;
  };

  /**
   * Účel zpracování
   */
  processingPurposes?: PersonalDataProcessingPurpose[];

  /**
   * Doba uchování: od
   */
  obtained?: string;

  /**
   * Doba uchování: do
   */
  keepTo?: string;

  /**
   * Čas zmazání
   */
  deleted?: string;
}

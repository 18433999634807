import React, { useContext } from 'react';
import { cloneDeep } from 'lodash';
import {
  useMainActivities,
  useEPrtrAndIppcActivities,
} from '../../irz-facility/irz-facilities-api';
import { useIrzActivities } from '../../dict-irz-activity/dict-irz-activities-api';
import {
  FormContext,
  useFormSelector,
  FormAutocomplete,
  FormTextField,
  FormInlineTableField,
  FormPanel,
  useEventCallback,
  FormCheckboxGroup,
} from '@eas/common-web';
import {
  useSecondaryEPrtrActivityColumns,
  useSecondaryIrzActivityColumns,
} from './step-activities-columns';
import { MainActivityType, ActivityRecord } from '../../../models';
import { StepActivitiesForm } from './step-activities-types';
import { HelpLabel } from '../../../components/help/help-label';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';

export function StepActivitiesContent() {
  const types = useMainActivities();
  const irzActivities = useIrzActivities();
  const { ePrtrActivities, ippcActivities } = useEPrtrAndIppcActivities();

  const { setFieldValue } = useContext(FormContext);

  const {
    activityTypes,
    mainIrzActivity,
    secondaryIrzActivities,
  } = useFormSelector((requestFacilityData: StepActivitiesForm) => ({
    activityTypes: requestFacilityData?.activityTypes,
    mainIrzActivity: requestFacilityData?.mainIrzActivity,
    secondaryIrzActivities: requestFacilityData?.secondaryIrzActivities ?? [],
  }));

  const secondaryIrzActivitiesColumns = useSecondaryIrzActivityColumns();
  const secondaryEPrtrActivitiesColumns = useSecondaryEPrtrActivityColumns();

  const handleMainActivityTypeChanged = useEventCallback(() => {
    if (activityTypes?.includes(MainActivityType.E_PRTR)) {
      if (mainIrzActivity) {
        const newSecondaryIrzActivities = cloneDeep(secondaryIrzActivities);
        newSecondaryIrzActivities?.push(mainIrzActivity);

        setFieldValue('secondaryIrzActivities', [...newSecondaryIrzActivities]);
        setFieldValue('mainIrzActivity', undefined);
      }
    }
    if (!activityTypes?.includes(MainActivityType.IRZ)) {
      setFieldValue('mainIrzActivity', undefined);
      setFieldValue('secondaryIrzActivities', undefined);
    }
    if (!activityTypes?.includes(MainActivityType.E_PRTR)) {
      setFieldValue('mainEprtrActivity', undefined);
      setFieldValue('secondaryEprtrActivities', undefined);
    }
  });

  return (
    <FormPanel label="Činnosti">
      <FormCheckboxGroup
        label={
          <HelpLabel
            label="Typ činnosti"
            code="IRZ_FACILITY_REQUEST_NEW_ACTIVITY_TYPES"
            required
          />
        } // Povinné pole
        source={types}
        name="activityTypes"
        notifyChange={handleMainActivityTypeChanged}
      />
      {activityTypes?.includes(MainActivityType.E_PRTR) && (
        <>
          <FormAutocomplete
            name="mainEprtrActivity.activity"
            label={
              <HelpLabel
                label="Hlavní E-PRTR činnost"
                code="IRZ_FACILITY_MAIN_EPRTR_ACTIVITY"
              />
            }
            source={ePrtrActivities}
            layoutOptions={{ noUnderline: true }}
            tooltipMapper={dictionaryLabelMapper}
            labelMapper={dictionaryLabelMapper}
          />
          <FormTextField
            name="mainEprtrActivity.facilityCount"
            type="number"
            layoutOptions={{ noUnderline: true }}
            label={
              <HelpLabel
                label="Počet zařízení"
                code="IRZ_FACILITY_MAIN_EPRTR_ACTIVITY_COUNT"
              />
            }
          />
          <FormAutocomplete
            name="ippcActivity"
            label={
              <HelpLabel
                label="Činnost IPPC"
                code="IRZ_FACILITY_IPPC_ACTIVITY"
              />
            }
            source={ippcActivities}
            tooltipMapper={dictionaryLabelMapper}
            labelMapper={dictionaryLabelMapper}
          />
          <FormInlineTableField<ActivityRecord>
            name="secondaryEprtrActivities"
            label={
              <HelpLabel
                label="Seznam vedlejších E-PRTR činností"
                code="IRZ_FACILITY_REQUEST_NEW_SECONDARY_EPRTR_ACTIVITIES"
              />
            } // Povinné pole, nesmí obsahovat hlavní činnost
            columns={secondaryEPrtrActivitiesColumns}
            maxRows={5}
          />
        </>
      )}
      {activityTypes?.includes(MainActivityType.IRZ) && (
        <>
          {!activityTypes?.includes(MainActivityType.E_PRTR) && (
            <>
              <FormAutocomplete
                name="mainIrzActivity.activity"
                label={
                  <HelpLabel
                    label="Hlavní činnost dle přílohy zákona o IRZ a ISPOP"
                    code="IRZ_FACILITY_REQUEST_NEW_MAIN_IRZ_ACTIVITY_ACTIVITY"
                    required
                  />
                } // Povinné pole
                source={irzActivities}
                labelMapper={dictionaryLabelMapper}
                tooltipMapper={dictionaryLabelMapper}
              />

              <FormTextField
                name="mainIrzActivity.facilityCount"
                label={
                  <HelpLabel
                    label="Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP"
                    code="IRZ_FACILITY_REQUEST_NEW_MAIN_IRZ_ACTIVITY_FACILITY_COUNT"
                    required
                  />
                } // Povinné pole, nejméně 1 zařízení
              />
            </>
          )}
          <FormInlineTableField<ActivityRecord>
            name="secondaryIrzActivities"
            label={
              <HelpLabel
                label="Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP"
                code="IRZ_FACILITY_REQUEST_NEW_SECONDARY_IRZ_ACTIVITIES"
              />
            } // Povinné pole, nesmí obsahovat hlavní činnost
            columns={secondaryIrzActivitiesColumns}
            maxRows={5}
          />
        </>
      )}
      {!activityTypes?.includes(MainActivityType.E_PRTR) && (
        <FormAutocomplete
          name="ippcActivity"
          label={
            <HelpLabel
              label="Činnost IPPC"
              code="IRZ_FACILITY_REQUEST_NEW_IPPC_ACTIVITY"
            />
          }
          source={ippcActivities}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
      )}
    </FormPanel>
  );
}

import { useState, useContext, useEffect, useMemo } from 'react';
import { UserContext, SnackbarVariant, SnackbarContext } from '@eas/common-web';
import { fetchProfile } from '../../modules/profile/profile-api';
import { ProfileForm } from '../../modules/profile/profile-types';
import { ConsentSnackbarContextType } from './consent-snackbar-types';
import { useLocation } from 'react-router-dom';
import { useAbortableFetch } from '../../utils/abortable-fetch';

export function useConsentSnackbar() {
  const { user } = useContext(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const [open, setOpen] = useState<boolean>(false);

  const [consentIsGranted, setConsentIsGranted] = useState<boolean | null>(
    null
  );

  const { pathname } = useLocation();

  /**
   * Fetch profile to detect id `personalDataProcessingConsent` is granted.
   */
  const getProfile = useAbortableFetch(async (fetch) => {
    try {
      if (!user.id) {
        return;
      }

      fetch = fetchProfile(user.id);

      const data: ProfileForm = await fetch.json();

      return data;
    } catch (err) {
      showSnackbar(`Chyba načtení dat: ${err.message}`, SnackbarVariant.ERROR);

      throw err;
    }
  });

  useEffect(() => {
    const get = async () => {
      const response = await getProfile?.();
      setConsentIsGranted(!!response?.personalDataProcessingConsent);
    };

    if (user !== undefined && user.id !== null) {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (consentIsGranted === true) {
      setOpen(false);
    } else if (consentIsGranted === false) {
      setOpen(true);
    }
  }, [consentIsGranted, pathname]);

  const context: ConsentSnackbarContextType = useMemo(
    () => ({
      open,
      setOpen,
      setConsentIsGranted,
    }),
    [open, setOpen, setConsentIsGranted]
  );

  return { context, setOpen, setConsentIsGranted };
}

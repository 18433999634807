import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  TableColumn,
  TableCells,
  TableFieldColumn,
  InlineTableFieldCells,
  ApiFilterOperation,
  TableFilterCells,
  TableFieldCells,
  FilterComponentProps,
  Select,
  useStaticListSource,
  useEventCallback,
} from '@eas/common-web';
import { emissionsTradingValueMapper } from '../../composite/irz-facility-column-mappers/emissions-trading-mapper';
import { ippcPidsValueMapper } from '../../composite/irz-facility-column-mappers/ippc-pids-mapper';
import { roundedNumberValueMapper } from '../../composite/facility-column-mappers/rounded-number-mapper';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { addressValueMapperFactory } from '../../composite/subject-column-mappers/address-mapper';
import { coordinateValueMapper } from '../../composite/facility-column-mappers/coordinate-mapper';
import { useRequestStates } from '../ovz-facility-request/ovz-facility-requests-api';
import { useRegions } from '../production-site/production-sites-api';
import { OvzFacility, OvzFacilityRequest } from '../../models';
import { useCadastralTerritories } from '../../components/form/address-field/address-field-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { useLandPlotTypes } from './ovz-facilities-api';

export function IsuiCodeFilterCell({
  disabled,
  value,
  state,
  onChangeState,
}: FilterComponentProps) {
  const intl = useIntl();

  const handleChange = useEventCallback((value: any) => {
    if (value === 'null') {
      onChangeState({
        ...state,
        operation: ApiFilterOperation.IS_NULL,
        value,
      });
    } else {
      onChangeState({
        ...state,
        operation: ApiFilterOperation.NOT_NULL,
        value,
      });
    }
  });

  return (
    <Select<{ id: string; name: string }>
      disabled={disabled}
      source={useStaticListSource([
        {
          id: 'not_null',
          name: intl.formatMessage({
            id: 'CRZP_ISUI_CODE_FILTER_CELL_OPTION_NOT_NULL',
            defaultMessage: 'Vyplněn',
          }),
        },
        {
          id: 'null',
          name: intl.formatMessage({
            id: 'CRZP_ISUI_CODE_FILTER_CELL_OPTION_NULL',
            defaultMessage: 'Nevyplněn',
          }),
        },
      ])}
      value={value}
      onChange={handleChange}
      valueIsId={true}
    />
  );
}

export function AddressPlaceFilterCell({
  disabled,
  value,
  state,
  onChangeState,
}: FilterComponentProps) {
  const intl = useIntl();

  const handleChange = useEventCallback((value: any) => {
    if (value === 'true' || value === 'false') {
      onChangeState({
        ...state,
        operation: ApiFilterOperation.EQ,
        value: value,
      });
    } else {
      onChangeState({
        ...state,
        operation: ApiFilterOperation.IS_NULL,
        value,
      });
    }
  });

  return (
    <Select<{ id: string; name: string }>
      disabled={disabled}
      source={useStaticListSource([
        {
          id: 'true',
          name: intl.formatMessage({
            id: 'CRZP_ADDRESS_PLACE_FILTER_CELL_OPTION_TRUE',
            defaultMessage: 'Platné',
          }),
        },
        {
          id: 'false',
          name: intl.formatMessage({
            id: 'CRZP_ADDRESS_PLACE_FILTER_CELL_OPTION_FALSE',
            defaultMessage: 'Neplatné',
          }),
        },
        {
          id: 'null',
          name: intl.formatMessage({
            id: 'CRZP_ADDRESS_PLACE_FILTER_CELL_OPTION_NULL',
            defaultMessage: 'Neexistuje',
          }),
        },
      ])}
      value={value}
      onChange={handleChange}
      valueIsId={true}
    />
  );
}

export function useColumns(): TableColumn<OvzFacility>[] {
  return [
    {
      datakey: 'name',
      name: 'Název provozovny',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'facilityId',
      name: 'IČP provozovny OVZ',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'originalFacilityId',
      name: 'Původní IČP provozovny OVZ',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'irzFacility.facilityId',
      name: 'IČP provozovny IRZ',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'operator.name',
      name: 'Provozovatel - Název',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'operator.identifier',
      name: 'Provozovatel - IČO / UID',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'operator.identityDocumentsConcatenated',
      name: 'Provozovatel - OP / pas',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'multipleTTU',
      name: 'Areál OVZ na více ÚTJ',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'cadastralTerritory',
      sortkey: 'cadastralTerritory.name',
      filterkey: 'cadastralTerritory.id',
      name: 'Katastrální území',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useCadastralTerritories,
        dictionaryLabelMapper
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'landPlotType',
      sortkey: 'landPlotType.name',
      filterkey: 'landPlotType.id',
      name: 'Druh číslování parcel',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useLandPlotTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useLandPlotTypes),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'primaryLandPlotNumber',
      name: 'Kmenové číslo parcely',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.NumberCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'secondaryLandPlotNumber',
      name: 'Pořadové číslo parcely',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.NumberCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'distanceFromTerritorialTechnicalUnit',
      name: 'Vzdálenost od středu ÚTJ (m)',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.NumberCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      valueMapper: roundedNumberValueMapper,
      sortable: true,
      filterable: true,
      filterGroup: 5,
    },
    {
      datakey: 'distanceFromAddressPlace',
      name: 'Vzdálenost od adresního místa (m)',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.NumberCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      valueMapper: roundedNumberValueMapper,
      sortable: true,
      filterable: true,
      filterGroup: 5,
    },
    {
      datakey: 'address.region',
      sortkey: 'address.region.name',
      filterkey: 'address.region.id',
      name: 'Kraj',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useRegions
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.municipality',
      sortkey: 'address.municipality.name',
      filterkey: 'address.municipality.name',
      name: 'Obec',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.cityDistrict',
      sortkey: 'address.cityDistrict.name',
      filterkey: 'address.cityDistrict.name',
      name: 'Městská část',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.street',
      sortkey: 'address.street.name',
      filterkey: 'address.street.name',
      name: 'Ulice',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.houseNumber',
      name: 'Číslo popisné',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.orientationNumber',
      name: 'Číslo orientační',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.zip',
      name: 'PSČ adresy OVZ',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'address.addressPlace.active',
      filterkey: 'address.addressPlace.active',
      name: 'Adresní místo',
      width: 200,
      CellComponent: TableCells.BooleanCell,
      FilterComponent: AddressPlaceFilterCell,
      filterable: true,
      visible: false,
      hidden: true,
      filterGroup: 3,
    },
    {
      datakey: 'address.addressPlaceRuian.isuiCode',
      sortkey: 'address.addressPlace.isuiCode',
      filterkey: 'address.addressPlace.isuiCode',
      name: 'Kód adresního místa',
      width: 200,
      CellComponent: TableCells.NumberCell,
      FilterComponent: IsuiCodeFilterCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'parcel',
      name: 'Parcela',
      width: 150,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'wgs84Coordinates.lat',
      name: 'Zeměpisná šířka',
      width: 200,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      sortable: true,
      filterable: false,
      filterGroup: 3,
      valueMapper: coordinateValueMapper,
    },
    {
      datakey: 'wgs84Coordinates.lon',
      name: 'Zeměpisná délka',
      width: 200,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      sortable: true,
      filterable: false,
      filterGroup: 3,
      valueMapper: coordinateValueMapper,
    },
    {
      datakey: 'integratedPermission.ippcPids',
      sortkey: 'integratedPermission.ippcPids',
      filterkey: 'integratedPermission.hasPermission',
      name: 'PID (IPPC)',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: ippcPidsValueMapper,
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'emissionsTradingSystem.ids',
      sortkey: 'emissionsTradingSystem.ids',
      filterkey: 'emissionsTradingSystem.tradesInEmissions',
      name: 'ID ZAŘÍZENÍ (EU ETS)',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: emissionsTradingValueMapper,
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'registrationDate',
      name: 'Datum registrace',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

export function useIppcPidColumns(): TableFieldColumn<string>[] {
  const IppcPidCell = InlineTableFieldCells.useInlineTextFieldFactory<string>({
    collectionDatakey: 'integratedPermission.ippcPids',
    isSubkey: false,
  });

  return useMemo(
    () => [
      {
        name: 'PID (IPPC)',
        datakey: '',
        width: 600,
        CellComponent: IppcPidCell,
      },
    ],
    [IppcPidCell]
  );
}

export function useRequestColumns(): TableFieldColumn<OvzFacilityRequest>[] {
  return [
    {
      datakey: 'state',
      name: 'Stav',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useRequestStates),
    },
    {
      datakey: 'oldOperator.name',
      name: 'Původní provozovatel',
      width: 200,
    },
    {
      datakey: 'newOperator.name',
      name: 'Nový provozovatel',
      width: 200,
    },
    {
      datakey: 'decided',
      name: 'Datum vyřízení',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'date',
      name: 'Datum přesunu',
      width: 200,
      CellComponent: function Cell(props) {
        return (
          <TableFieldCells.DateCell
            {...props}
            value={props.value ?? props.rowValue.decided}
          />
        );
      },
    },
  ];
}

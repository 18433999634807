import React, { useMemo } from 'react';
import { Evidence2 } from '@eas/common-web';
import { RoleInstance, Exception } from '../../models';
import { RoleInstancesFields } from './role-instances-fields';
import { useColumns } from './role-instances-columns';
import { useValidationSchema } from './role-instances-schema';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  IncludeOptions,
  Messages,
  EntityName,
} from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { ExportTags } from '../../enums/export-tags';
import {
  getItemFactory,
  updateItemFactory,
  createItemFactory,
} from '../../utils/custom-crud';
import { combineGetCalls, preprocessCall } from './role-instances-api';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { useHistoryDescriptors } from './role-instances-history';
import { RoleInstancesToolbar } from './role-instances-toolbar';
import { RoleInstancesActionbar } from './role-instances-actionbar';
import { getErrorMessage } from '../../utils/get-message';

export function RoleInstances() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<RoleInstance>({
    options: {
      NEW: [
        Permission.Role.ROLE_INSTANCE_LIST,
        Permission.Role.ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SECURITY_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SYSTEM_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_USER_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SELECTED,
      ],
      EDIT: [
        Permission.Role.ROLE_INSTANCE_LIST,
        Permission.Role.ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SECURITY_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SYSTEM_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_USER_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SELECTED,
      ],
      REMOVE: [],
      CHECK: [
        Permission.Role.ROLE_INSTANCE_LIST,
        Permission.Role.ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SECURITY_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SYSTEM_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_USER_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SELECTED,
      ],
      SAVE: [
        Permission.Role.ROLE_INSTANCE_LIST,
        Permission.Role.ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SECURITY_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SYSTEM_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_USER_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SELECTED,
      ],
      CLOSE: [
        Permission.Role.ROLE_INSTANCE_LIST,
        Permission.Role.ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SECURITY_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SYSTEM_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_USER_ROLE,
        Permission.Role.ROLE_INSTANCE_LIST_SELECTED,
      ],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const include = useMemo(() => [IncludeOptions.DEACTIVATED], []);

  const evidence = useAuthoredEvidence<RoleInstance>({
    version: 2,
    identifier: PageKey.ROLE_INSTANCES,
    apiProps: {
      url: EvidenceAPI.ROLE_INSTANCES,
      getItem: getItemFactory({ combineGetCalls }),
      createItem: createItemFactory({
        combineGetCalls,
        preprocessCall,
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            if (response?.status === 409) {
              throw {
                message:
                  Messages.RoleInstance.CREATE.ROLE_INSTANCE_ALREADY_EXISTS[0],
              };
            } else {
              const message = getErrorMessage(
                Messages.RoleInstance.CREATE,
                (data as Exception).code
              )[0];

              throw { message };
            }
          }
        },
      }),
      updateItem: updateItemFactory({
        combineGetCalls,
        preprocessCall,
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.RoleInstance.UPDATE,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled přiřazených rolí',
      reportTag: ExportTags.ROLE_INSTANCES,
      include,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.ROLE_INSTANCE,
      FieldsComponent: RoleInstancesFields,
      validationSchema,
      toolbarProps: {
        title: 'Přiřazené role',
        showBtn: permissions,
        ActionBar: RoleInstancesActionbar,
      },
      ToolbarComponent: RoleInstancesToolbar,
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Role.ROLE_LIST,
    },
  });

  return <Evidence2 {...evidence} />;
}

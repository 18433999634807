import { AuthoredObject } from '@eas/common-web';
import { EntityType } from '../enums/entity-type';

export interface Note extends AuthoredObject {
  /**
   * Text poznámky
   */
  text?: string;

  /**
   * Odkaz na vlastnící entitu
   */
  entityReference?: {
    id?: string;
    entityType?: EntityType;
  };

  /**
   * Typ poznámky - veřejná / soukromá
   */
  type: NoteType;
}

export enum NoteType {
  PUBLIC,
  PRIVATE,
}

import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { RoleInstance } from '../../../../models';
import { inactiveColoredFactory } from '../../../../components/form/inactive-colored/inactive-colored';
import { useRoleTypes } from '../../../roles/roles-api';

export function useColumns(): TableColumn<RoleInstance>[] {
  return [
    {
      datakey: 'role.registeredFor.name',
      name: 'Systém',
      width: 100,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      name: 'Typ',
      datakey: 'role.type',
      sortkey: 'role.type.name',
      filterkey: 'role.type.id',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      valueMapper: TableCells.useSelectCellFactory(useRoleTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useRoleTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'role.name',
      name: 'Role',
      width: 300,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      datakey: 'empowerment.empowerer.label',
      sortkey: 'empowerment.empowerer.name',
      filterkey: 'empowerment.empowerer.name',
      name: 'Zmocnitel',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      datakey: 'empowerment.agent.label',
      sortkey: 'empowerment.agent.name',
      filterkey: 'empowerment.agent.name',
      name: 'Zmocněnec',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      asciiFolding: false,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}

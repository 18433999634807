import React, { useRef, useContext } from 'react';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  SnackbarVariant,
} from '@eas/common-web';
import { SubjectRegistration, NotificationTemplate } from '../../../models';
import { EvidenceAPI, Messages } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
export function callApi(template: NotificationTemplate) {
  return abortableFetch(`${EvidenceAPI.NOTIFICATION_TEMPLATES}/test`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      template,
    }),
  });
}

export function useTestNotification() {
  /**
   * Context stuff.
   */
  const { source, isExisting } = useContext<DetailHandle<SubjectRegistration>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { formRef, validate } = useContext(DetailContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleTestNotification = useEventCallback(async () => {
    try {
      source.setLoading(true);

      const errors = await validate();

      if (errors.length === 0) {
        const values = formRef?.getFieldValues();

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callApi(values);

        const response = await fetch.current.json();

        showSnackbar(
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <u>Titulek:</u>
            <div dangerouslySetInnerHTML={{ __html: response.subject }} />
            <br />
            <u>Obsah:</u>
            <iframe
              id="test"
              name="test"
              sandbox="allow-same-origin"
              style={{
                width: 600,
                height: 600,
                border: 0,
              }}
              srcDoc={response.content}
            />
          </div>,
          SnackbarVariant.BLANK
        );
      }
    } catch (err) {
      if (err.name !== 'AbortError') {
        showSnackbar(...Messages.Notification.SET_OPTIONAL.ERROR);
        throw err;
      }
      return undefined;
    } finally {
      source.setLoading(false);
    }
  });

  const showTestNotificationButton = isExisting;

  return {
    handleTestNotification,
    showTestNotificationButton,
  };
}

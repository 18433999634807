import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { BoilerPermission } from '../../models';
// import { idValueMapperFactory } from '../../composite/subject-column-mappers/id-mapper';
import { useDictionaryBoilerBrands } from '../dict-boiler-brand/dict-boiler-brands-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export function useColumns(
  canSeeDeactivated: boolean
): TableColumn<BoilerPermission>[] {
  const columns: TableColumn<BoilerPermission>[] = [
    {
      datakey: 'number',
      name: 'Číslo oprávnění',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'boilerBrand.name',
      filterkey: 'boilerBrand.id',
      name: 'Značka kotle',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDictionaryBoilerBrands
      ),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      FilterComponent: TableFilterCells.FilterDateCell,
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'ozoSubject.name',
      name: 'Subjekt OZO - Název ',
      filterOperation: ApiFilterOperation.CONTAINS,
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'ozoSubject.identifier',
      name: 'Subjekt OZO - IČO / UID',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'ozoSubject.identityDocumentsConcatenated',
      name: 'Subjekt OZO - OP / pas',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'manufacturerSubject.name',
      name: 'Výrobce - Název',
      filterOperation: ApiFilterOperation.CONTAINS,
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'manufacturerSubject.identifier',
      name: 'Výrobce - IČO / UID',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'manufacturerSubject.identityDocumentsConcatenated',
      name: 'Výrobce - OP / pas',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
  ];
  if (canSeeDeactivated) {
    columns.push({
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    });
  }
  return columns;
}

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { ScheduleRun } from '../../../models';
import { inactiveColoredFactory } from '../../../components/form/inactive-colored/inactive-colored';
import { useJobsAll, useRunStates } from '../schedule-api';

export function useColumns(): TableColumn<ScheduleRun>[] {
  return [
    {
      datakey: 'job',
      sortkey: 'job.name',
      filterkey: 'job.id',
      name: 'Úloha',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory<ScheduleRun>(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useJobsAll),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 150,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory<ScheduleRun>(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useRunStates
      ),
      valueMapper: TableCells.useSelectCellFactory(useRunStates),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'startTime',
      name: 'Začátek',
      width: 150,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: inactiveColoredFactory<ScheduleRun>(
        TableCells.DateTimeCell
      ),
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'endTime',
      name: 'Konec',
      width: 150,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: inactiveColoredFactory<ScheduleRun>(
        TableCells.DateTimeCell
      ),
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      sortable: true,
      filterable: true,
    },
  ];
}

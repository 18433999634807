import { useContext, useRef } from 'react';
import {
  DetailHandle,
  DetailContext,
  useEventCallback,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { Subject, Me, SubjectType } from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { Permission, EvidenceAPI, Messages } from '../../../enums';

/**
 * Api call
 *
 * @param subject
 * @param enable
 */
function callApiFactory({ currentState }: { currentState: boolean }) {
  return function callApi(id: string) {
    return abortableFetch(
      `${EvidenceAPI.SUBJECTS}/${id}/sync/${
        currentState ? 'disable' : 'enable'
      }`,
      {
        method: 'PUT',
      }
    );
  };
}

/**
 * Api call
 *
 * @param id
 */
function callApi(id: string) {
  return abortableFetch(`${EvidenceAPI.SUBJECTS}/${id}/sync`, {
    method: 'POST',
  });
}

export enum ApiResult {
  SUCCESS = 'SUCCESS',
  NOT_FOUND = 'NOT_FOUND',
  FAILED = 'FAILED',
}

export function useSync() {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext<DetailHandle<Subject>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const allowIszrSync = source.data?.allowIszrSync ?? false;

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleSynchronize = useEventCallback(async () => {
    try {
      source.setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApiFactory({ currentState: !!allowIszrSync })(
        source.data!.id
      );

      await fetch.current.raw();

      unstable_batchedUpdates(() => {
        const snackbar = allowIszrSync
          ? Messages.Subject.SYNC_AUTO.DISABLE_SUCCESS
          : Messages.Subject.SYNC_AUTO.ENABLE_SUCCESS;

        showSnackbar(...snackbar);

        source.setLoading(false);
      });

      source.refresh();
      onPersisted(source.data!.id);
    } catch (err) {
      source.setLoading(false);

      if (err.name !== 'AbortError') {
        showSnackbar(...Messages.Subject.SYNC_AUTO.ERROR);

        throw err;
      }
      return undefined;
    }
  });

  const handleManualSynchronize = useEventCallback(async () => {
    try {
      source.setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(source.data!.id);

      const result: ApiResult = await fetch.current.json();

      unstable_batchedUpdates(() => {
        showSnackbar(...Messages.Subject.SYNC_MANUAL[result]);

        source.setLoading(false);
      });

      onPersisted(source.data!.id);
      await source.refresh();
    } catch (err) {
      source.setLoading(false);

      if (err.name !== 'AbortError') {
        showSnackbar(...Messages.Subject.SYNC_MANUAL.ERROR);
        throw err;
      }
      return undefined;
    }
  });

  // Právnická osoba, Fyzická osoba, Fyzická osoba podnikajíci
  const canBeSynchronized =
    source?.data?.active &&
    (source.data?.type === SubjectType.LEGAL_ENTITY ||
      source.data?.type === SubjectType.NATURAL_PERSON ||
      source.data?.type === SubjectType.BUSINESS_NATURAL_PERSON);

  const showSyncButton =
    canBeSynchronized && hasPermission(Permission.Subject.SUBJECT_SYNC_TOGGLE);

  const showManualSyncButton =
    canBeSynchronized && hasPermission(Permission.Subject.SUBJECT_SYNC);

  return {
    handleSynchronize,
    handleManualSynchronize,
    showSyncButton,
    showManualSyncButton,
    allowIszrSync,
  };
}

import React, { useEffect } from 'react';
import MuiTextField from '@material-ui/core/TextField';
import MuiInputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import MuiSearchIcon from '@material-ui/icons/Search';
import { useEventCallback } from '@eas/common-web';
import { useRef, useContext } from 'react';
import { useStyles } from './map-styles';
import { MapContext } from './map-ctx';
import { SuggestData } from './map-types';

/**
 * Tries to predict correct zoom based on the object description
 * @param desc Second row description from search item
 */
function getZoomByDescription(desc: string) {
  if (desc.startsWith('Kraj')) {
    return 10;
  }
  if (desc.startsWith('Okres')) {
    return 11;
  }
  if (desc.startsWith('Hlavní město')) {
    return 12;
  }
  if (desc.startsWith('Statutární město')) {
    return 13;
  }
  if (desc.startsWith('Město')) {
    return 14;
  }
  if (desc.startsWith('Obec') || desc.startsWith('Městys')) {
    return 15;
  }
  if (desc.startsWith('Ulice')) {
    return 16;
  }
  if (desc.startsWith('Adresa')) {
    return 17;
  }
  return 15;
}

export function MapSuggest() {
  const classes = useStyles();

  const suggesterRef = useRef<HTMLDivElement>(null);

  const { map } = useContext(MapContext);

  const updateMapPosition = useEventCallback((props: SuggestData) => {
    if (map) {
      const coords = window.SMap.Coords.fromWGS84(
        Number(props.data.longitude),
        Number(props.data.latitude)
      );
      const zoom = getZoomByDescription(props.data.secondRow);
      map.setCenterZoom(coords, zoom);
    }
  });

  const setupSuggester = useEventCallback(() => {
    if (window.SMap?.SuggestProvider && suggesterRef.current) {
      const suggest = new window.SMap.Suggest(suggesterRef.current, {
        provider: new window.SMap.SuggestProvider({
          updateParams: (params) => {
            // Omezeni pro celou CR
            params.bounds = '48.5370786,12.0921668|51.0746358,18.8927040';
            params.langFilter = 'cs';

            //Omezeni vysledku na urcite kategorie
            params.category =
              'address_cz, district_cz, municipality_cz, region_cz, street_cz';
          },
        }),
      });
      suggest.addListener('suggest', updateMapPosition);
      return () => {
        suggest.removeListener('suggest');
      };
    }
  });

  useEffect(() => {
    return setupSuggester();
  }, [window.SMap]);

  return (
    <MuiTextField
      inputRef={suggesterRef}
      classes={{ root: classes.searchTextField }}
      autoComplete="off"
      type="search"
      margin="dense"
      placeholder="Vyhledat v mapě"
      variant="outlined"
      InputProps={{
        classes: { root: classes.searchTextFieldInput },
        endAdornment: (
          <MuiInputAdornment position="end">
            <MuiSearchIcon classes={{ root: classes.searchIcon }} />
          </MuiInputAdornment>
        ),
        style: {
          boxShadow: 'none',
          paddingRight: 0,
        },
      }}
    />
  );
}

import escapeHtml from 'escape-html';
import { Text, Descendant } from 'slate';
import { CustomElement, LinkElement } from '../../../global';

export function serialize(node: Descendant[] | Descendant) {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }
    if (node.link) {
      let fileAttibutes = '';
      if (node.link.name) {
        fileAttibutes = `data-name=${node.link.name}`;
      }
      if (node.link.contentType) {
        fileAttibutes = `${fileAttibutes} data-content-type=${node.link.contentType} `;
      }
      if (node.link.size) {
        fileAttibutes = `${fileAttibutes} data-size=${node.link.size} `;
      }
      string = `<a href=${node.link.url} ${fileAttibutes} target=_blank rel=noreferrer>${string}</a>`;
    }
    return string;
  }

  let children: Descendant[];
  if ((node as CustomElement).children?.length !== undefined) {
    children = (node as CustomElement).children;
  } else if ((node as Descendant[])?.length !== undefined) {
    children = node as Descendant[];
  } else {
    children = [];
  }

  const strigifiedChildren: string = children.map((n) => serialize(n)).join('');

  switch ((node as CustomElement).type) {
    case 'bulleted-list':
      return `<ul>${strigifiedChildren}</ul>`;
    case 'numbered-list':
      return `<ol>${strigifiedChildren}</ol>`;
    case 'list-item':
      return `<li>${strigifiedChildren}</li>`;
    case 'bold':
      return `<strong>${strigifiedChildren}</strong>`;
    case 'italic':
      return `<em>${strigifiedChildren}</em>`;
    case 'underline':
      return `<u>${strigifiedChildren}</u>`;
    case 'code':
      return `<code>${strigifiedChildren}</code>`;
    case 'link':
      return `<a href=${
        (node as LinkElement).link.url
      } target=_blank rel=noreferrer>${strigifiedChildren}</a>`;
    case 'paragraph':
      return `<p>${strigifiedChildren}</p>`;
    case 'block-quote':
      return `<blockquote>${strigifiedChildren}</blockquote>`;
    case 'heading-one':
      return `<h1>${strigifiedChildren}</h1>`;
    case 'heading-two':
      return `<h2>${strigifiedChildren}</h2>`;
    default:
      return strigifiedChildren;
  }
}

import React from 'react';
import { StepBasicInfoForm } from './step-basic-info-types';

export function StepBasicInfoLabel({
  stepValues,
}: {
  stepValues?: StepBasicInfoForm;
}) {
  return (
    <>
      Základní informace
      {stepValues?.operator?.label && (
        <>
          {': '}
          <strong>{stepValues.operator?.label}</strong>
        </>
      )}
    </>
  );
}

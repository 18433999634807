import { useRef } from 'react';
import { AbortableFetch } from '@eas/common-web';

export function useAbortableFetch<T>(
  callback: (fetch: AbortableFetch | null) => Promise<T>
) {
  const fetch = useRef<AbortableFetch | null>(null);

  try {
    if (fetch.current !== null) {
      fetch.current.abort();
    }

    return () => callback(fetch.current);
  } catch (err) {
    if (err.name !== 'AbortError') {
      throw err;
    }
    return undefined;
  }
}

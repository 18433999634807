import React, { useState } from 'react';
import {
  FormPanel,
  FormTextField,
  FormSelect,
  useFormSelector,
  FormCustomField,
} from '@eas/common-web';
import {
  useNotificationTypes,
  useNotificationEvents,
} from '../notification-templates/notification-templates-api';
import { HelpLabel } from '../../components/help/help-label';
import { Notification, NotificationType } from '../../models';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createPortal } from 'react-dom';
import Typography from '@material-ui/core/Typography';

export const useStyles = makeStyles(() => ({
  notificationContent: {
    whiteSpace: 'pre-wrap',
  },
}));

export const IFrame = ({
  children,
  ...props
}: {
  children: React.ReactNode;
  [key: string]: any;
}) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe {...props} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

export function NotificationsFields() {
  const typeSource = useNotificationTypes();
  const eventSource = useNotificationEvents();

  const { mailContent, type, subject, content } = useFormSelector(
    (data: Notification) => ({
      mailContent: data?.mail?.content,
      type: data?.type,
      subject: data?.subject,
      content: data?.content,
    })
  );

  return (
    <>
      <FormPanel label="Notifikace">
        <FormSelect
          name="type"
          label={<HelpLabel label="Typ" code="NOTIFICATION_TYPE" />}
          source={typeSource}
          valueIsId
          disabled
        />
        <FormSelect
          name="event"
          label={<HelpLabel label="Událost" code="NOTIFICATION_EVENT" />}
          source={eventSource}
          valueIsId
          disabled
        />
        <FormTextField
          name="subject"
          label={<HelpLabel label="Předmět" code="NOTIFICATION_SUBJECT" />}
          disabled
        />
      </FormPanel>
      <FormPanel label="Příjemce">
        <FormTextField
          name="recipient.label"
          label={
            <HelpLabel label="Příjemce" code="NOTIFICATION_RECIPIENT_NAME" />
          }
          disabled
        />
        <FormTextField
          name="recipient.email.value"
          label={
            <HelpLabel label="Email" code="NOTIFICATION_RECIPIENT_EMAIL" />
          }
          disabled
        />
      </FormPanel>

      {type === NotificationType.EMAIL && (
        <FormPanel label="Email">
          <FormTextField
            name="mail.subject"
            label={
              <HelpLabel label="Předmět" code="NOTIFICATION_MAIL_SUBJECT" />
            }
            disabled
          />
          <FormTextField
            name="mail.to"
            label={<HelpLabel label="Komu" code="NOTIFICATION_MAIL_TO" />}
            disabled
          />

          <IFrame
            style={{
              width: '100%',
              height: '600px',
              border: 'none',
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: mailContent! }} />
          </IFrame>
        </FormPanel>
      )}

      {type === NotificationType.APPLICATION && (
        <FormPanel label="Aplikační notifikace">
          <FormCustomField
            name="subject"
            label={
              <HelpLabel
                label="Předmět"
                code="NOTIFICATION_APPLICATION_CONTENT"
              />
            }
          >
            <Typography variant="body1">
              <span dangerouslySetInnerHTML={{ __html: subject! }} />
            </Typography>
          </FormCustomField>

          <FormCustomField
            name="content"
            label={
              <HelpLabel
                label="Obsah"
                code="NOTIFICATION_APPLICATION_CONTENT"
              />
            }
          >
            <Typography variant="body1">
              <span dangerouslySetInnerHTML={{ __html: content! }} />
            </Typography>
          </FormCustomField>
        </FormPanel>
      )}
    </>
  );
}

import React, { useContext } from 'react';
import { DetailContext, DetailMode } from '@eas/common-web';
import { DetailToolbarButton } from '../../components/evidence/evidence/detail-toolbar-button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';

export function Toolbar({
  view,
  setView,
}: {
  view: 'FORM' | 'FILE';
  setView: (view: 'FORM' | 'FILE') => void;
}) {
  const { mode, source } = useContext(DetailContext);

  return (
    <>
      {source?.data?.verdict &&
        view === 'FORM' &&
        (mode === DetailMode.VIEW || mode === DetailMode.NONE) && (
          <DetailToolbarButton
            startIcon={<PictureAsPdfIcon />}
            label="Náhled"
            onClick={() => setView('FILE')}
            disabled={!source?.data?.verdict?.name?.endsWith('.pdf')}
          />
        )}
      {source?.data?.verdict && view === 'FILE' && (
        <DetailToolbarButton
          startIcon={<VerticalSplitIcon />}
          label="Formulář"
          onClick={() => setView('FORM')}
        />
      )}
    </>
  );
}

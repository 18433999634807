import React, { useState, useRef, ComponentType } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  DomainObject,
  useScrollableSource,
  ApiFilterOperation,
  useFetch,
  useWebsocketEffect,
  InfiniteList,
  InfiniteListHandle,
  useEventCallback,
  Tooltip,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { getEnvironmentColor } from '../../app-protected';
import { Notification } from './notification';
import { useReadAll } from './actions/read-all-hook';
import { Notification as NotificationType } from '../../models';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: '10px 16px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: `${theme.palette.primary.main}33`,
  },
  notificationIcon: {
    color: 'white',
    width: '20px',
    height: '20px',
    padding: 0,
    margin: 0,
  },
  button: {
    height: 33,
    minWidth: 40,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: getEnvironmentColor(theme),
    '&:hover': {
      backgroundColor: getEnvironmentColor(theme, true),
    },
  },
  menu: {
    height: 'fit-content',
    width: 500,
    overflow: 'hidden',
  },
  loaderWrapper: {
    width: '100%',
    height: 'calc(100% - 50px)',
    position: 'absolute',
    backgroundColor: '#e1e2e340',
    zIndex: 1000,
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
}));

export function NotificationBtn({
  NotificationComponent = Notification,
}: {
  NotificationComponent?: ComponentType<{
    item: DomainObject;
    refreshNotifications: () => void;
  }>;
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [withRead, setWithRead] = React.useState<boolean>(false);

  const infiniteList = useRef<InfiniteListHandle<DomainObject>>(null);

  const source = useScrollableSource<NotificationType>({
    url: EvidenceAPI.NOTIFICATION_RECEIVED + '/list',
    params: {
      filters: [
        { field: 'read', operation: ApiFilterOperation.EQ, value: false },
      ],
    },
  });

  const [count, , resetCount] = useFetch<number>(
    EvidenceAPI.NOTIFICATION_RECEIVED + '/count',
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = useEventCallback(() => {
    source.reset();
    resetCount();
    source.loadMore();
  });

  const handleShowAll = useEventCallback(() => {
    setWithRead((val) => !val);
    if (withRead) {
      source.setParams({
        filters: [
          { field: 'read', operation: ApiFilterOperation.EQ, value: false },
        ],
      });
    } else {
      source.setParams({
        filters: [],
      });
    }
    source.reset();
    source.loadMore();
    setAnchorEl(null);
  });

  useWebsocketEffect(() => {
    resetCount();
  }, ['NOTIFICATION_SENT']);

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [opened, setOpened] = useState<boolean>(false);

  const openMenu = useEventCallback(() => {
    source.reset();
    setOpened(true);
  });

  const closeMenu = useEventCallback(() => {
    setOpened(false);
  });

  const { handleReadAll } = useReadAll({ refresh: handleReset });

  return (
    <>
      <Tooltip title="Notifikace">
        <Button
          ref={anchorRef}
          variant="contained"
          color="primary"
          onClick={openMenu}
          disableElevation
          className={classes.button}
        >
          <Badge
            badgeContent={count}
            color="error"
            showZero={false}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <NotificationsIcon className={classes.notificationIcon} />
          </Badge>
        </Button>
      </Tooltip>

      <MuiMenu
        getContentAnchorEl={null}
        anchorEl={anchorRef.current}
        open={opened}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          className: clsx(classes.menu),
        }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <div style={{ height: source.loading ? 500 : 'fit-content' }}>
          <MuiMenu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleShowAll}>
              {withRead ? 'Skrýt' : 'Zobrazit'} přečtené
            </MenuItem>
            <MenuItem onClick={handleReadAll}>
              Označit vše jako přečtené
            </MenuItem>
          </MuiMenu>
          <Typography
            component="div"
            variant="body1"
            className={classes.header}
          >
            Notifikace
            {!source.loading && <> ({source.count})</>}
            <IconButton size="small" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Typography>
          {source.loading && (
            <div className={classes.loaderWrapper}>
              <CircularProgress disableShrink className={classes.loader} />
            </div>
          )}
          {opened && (
            <InfiniteList
              ref={infiniteList}
              source={source}
              maxListHeight={450}
              labelMapper={(item) => (
                <NotificationComponent
                  item={item}
                  refreshNotifications={handleReset}
                />
              )}
            />
          )}
          {source.count === 0 && !source.loading && (
            <Typography variant="body1" style={{ padding: 10 }}>
              Vše přečteno
            </Typography>
          )}
        </div>
      </MuiMenu>
    </>
  );
}

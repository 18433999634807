import { useEffect, useContext } from 'react';
import {
  FormContext,
  useFormSelector,
  useEventCallback,
} from '@eas/common-web';
import { StepBasicInfoForm, BasicInfoData } from './step-basic-info-types';
import { ProcessForm } from '../irz-facility-split-types';

export function useStepBasicInfoHook(allValues: ProcessForm) {
  const { setFieldValue } = useContext(FormContext);

  const { irzId } = useFormSelector(
    ({ basicInfoDefaultIrzId }: StepBasicInfoForm) => ({
      irzId: basicInfoDefaultIrzId,
    })
  );

  const resetValues = useEventCallback(() => {
    setFieldValue('basicInfoDefaultIrzId', allValues.mainFacility?.id);
    const basicInfoData: BasicInfoData = {
      commissioningDate: allValues.mainFacility?.commissioningDate,
      name: allValues.mainFacility?.name,
      publicEmail: allValues.mainFacility?.publicEmail,
      publicPhoneNumber: allValues.mainFacility?.publicPhoneNumber,
      webSite: allValues.mainFacility?.webSite,
    };
    setFieldValue('basicInfo', basicInfoData);
  });

  /**
   * If the facility was changed in the first step, we need to reset the form,
   * to have correct default values.
   */
  useEffect(() => {
    if (irzId !== allValues.mainFacility?.id) {
      resetValues();
    }
  }, [allValues.mainFacility?.id]);
}

import { get } from '../../utils/custom-crud';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';

export async function combineGetCalls({ signal, mainItem }: CombineCallsType) {
  const json = {
    ...mainItem,
  };

  const [textContent] = await get({
    url: `/api/crzp/files/${mainItem?.content?.id}`,
    signal,
  });

  if (textContent) {
    json['textContent'] = JSON.stringify(textContent, null, 2);
  }

  const text = JSON.stringify(json);

  return {
    data: json,
    text,
  };
}

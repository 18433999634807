import * as Yup from 'yup';
import { Outage, OutageType } from '../../models/outage';

export function useValidationSchema() {
  return Yup.object<Outage>().shape({
    type: Yup.mixed<OutageType>().nullable().required('Povinné pole'),
    startTime: Yup.string().nullable().required('Povinné pole'),
    endTime: Yup.string().nullable().required('Povinné pole'),
    reason: Yup.string().nullable().required('Povinné pole'),
  });
}

import * as Yup from 'yup';
import { StepNameChangeForm } from './step-name-change-types';

export const stepNameChangeSchema = () =>
  Yup.object<StepNameChangeForm>().shape({
    newName: Yup.string().when('keepOriginalName', {
      is: (val) => !val,
      then: Yup.string()
        .nullable()
        .required(
          'Zadejte nový název, nebo zvolte možnost "Ponechat původní název"'
        ),
    }),
  });

import {
  identityCardRegex,
  identityCardMessage,
} from './../../subject/fields/subjects-utils';
import * as Yup from 'yup';
import { StepOtherSubjectForm } from './step-other-subject-types';
import { SubjectType, IdType, Address } from '../../../models';
import { icoRegexp, icoRules } from '../../subject/fields/subjects-utils';
import { addressFieldSchema } from '../../../components/form/address-field/address-field-schema';
import { FileRef } from '@eas/common-web';

export const stepOtherSubjectSchema = () =>
  Yup.object<StepOtherSubjectForm>().shape({
    empowerer: Yup.object().shape({
      type: Yup.mixed().nullable().required('Vyberte jednu z hodnot'),
      residence: Yup.mixed<Address>()
        .nullable()
        .when('type', {
          is: (type) => type === SubjectType.FOREIGN_LEGAL_ENTITY,
          then: addressFieldSchema,
        }),
      name: Yup.string().nullable().when('type', {
        is: SubjectType.FOREIGN_LEGAL_ENTITY,
        then: Yup.string().nullable().required(),
      }),
      foreignId: Yup.string().nullable().when('type', {
        is: SubjectType.FOREIGN_LEGAL_ENTITY,
        then: Yup.string().nullable().required(),
      }),
      registerExtract: Yup.mixed<FileRef>().nullable().when('type', {
        is: SubjectType.FOREIGN_LEGAL_ENTITY,
        then: Yup.mixed<FileRef>().nullable().required(),
      }),
      id: Yup.object()
        .nullable()
        .test('Identifikátor', '', function () {
          const { createError, parent } = this;
          const allValues: StepOtherSubjectForm['empowerer'] = parent;

          const type = allValues?.type;
          const idType = allValues?.idType;

          const hasIco =
            type === SubjectType.LEGAL_ENTITY ||
            type === SubjectType.BUSINESS_NATURAL_PERSON;

          const hasUID =
            type === SubjectType.FOREIGN_NATURAL_PERSON ||
            type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO;

          const hasIdOrPassport = type === SubjectType.NATURAL_PERSON;

          const isPassport = idType === IdType.PASSPORT;
          const isIdCard = idType === IdType.ID_CARD;
          const requiredErrorMsg = 'Povinné pole';

          if (hasIco) {
            if (!allValues?.ico) {
              return createError({
                path: 'empowerer.ico',
                message: requiredErrorMsg,
              });
            }
            const isInvalid =
              !allValues?.ico || !icoRegexp.test(allValues?.ico);

            if (isInvalid) {
              return createError({
                path: 'empowerer.ico',
                message: `Nesplňuje pravidla: ${icoRules}`,
              });
            }
          }

          if (hasUID && !allValues?.artificialId) {
            return createError({
              path: 'empowerer.artificialId',
              message: requiredErrorMsg,
            });
          }

          if (hasIdOrPassport && !idType) {
            return createError({
              path: 'empowerer.idType',
              message: requiredErrorMsg,
            });
          }

          if (hasIdOrPassport && isPassport && !allValues?.passportNumber) {
            return createError({
              path: 'empowerer.passportNumber',
              message: requiredErrorMsg,
            });
          }

          if (hasIdOrPassport && isIdCard && !allValues?.idCardNumber) {
            return createError({
              path: 'empowerer.idCardNumber',
              message: requiredErrorMsg,
            });
          }

          if (allValues?.idCardNumber) {
            const isInvalidIdCardNumber = !identityCardRegex.test(
              allValues?.idCardNumber
            );

            if (isInvalidIdCardNumber) {
              return createError({
                path: 'empowerer.idCardNumber',
                message: identityCardMessage,
              });
            }
          }

          return true;
        }),
    }),
  });

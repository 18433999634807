import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { coordinatesValueMapper } from '../../components/evidence/history-evidence/history-utils';
import { useTypes } from '../outage/outages-api';
import { useDictionaryWaterFlows } from '../dict-water-flow/dict-water-flows-api';
import { useDictionaryRiverSides } from '../dict-river-side/dict-river-sides-api';
import { useNaceCzItems } from '../dict-nace-cz-item/dict-nace-cz-items-api';
import {
  useMunicipalities,
  useCadastralTerritories,
} from '../../components/form/address-field/address-field-api';
import { useBasinEnterprises } from './water-usage-places-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const types = useTypes();
  const waterFlows = useDictionaryWaterFlows();
  const riverSides = useDictionaryRiverSides();
  const naceCzItems = useNaceCzItems();
  const municipalities = useMunicipalities();
  const cadastralTerritories = useCadastralTerritories();
  const basinEnterprises = useBasinEnterprises();

  return [
    {
      key: 'type',
      label: 'Typ místa',
      source: types,
    },
    {
      key: 'ppId.enterprise',
      label: 'Podnik povodí',
      source: basinEnterprises,
    },
    {
      key: 'ppId.id',
      label: 'ID v databázi PP',
    },
    {
      key: 'ispopId',
      label: 'ID v systému ISPOP',
    },
    {
      key: 'vhbNumber',
      label: 'Číslo VHB',
    },
    {
      key: 'name',
      label: 'Název místa',
    },
    {
      key: 'obligatorySubject.ico',
      label: 'Povinný subjekt - IČO',
    },
    {
      key: 'obligatorySubject.companyName',
      label: 'Povinný subjekt - Název',
    },
    {
      key: 'obligatorySubject.branchName',
      label: 'Povinný subjekt - Název pobočky',
    },
    {
      key: 'obligatorySubject.address',
      label: 'Povinný subjekt - Adresa',
    },
    {
      key: 'operator',
      label: 'Provozovatel',
    },
    {
      key: 'cadastralTerritory',
      label: 'Katastrální území',
      source: cadastralTerritories,
    },
    {
      key: 'municipality',
      label: 'Obec',
      source: municipalities,
    },
    {
      key: 'czNace',
      label: 'CZ-NACE',
      source: naceCzItems,
    },
    {
      key: 'waterFlow',
      label: 'Vodní tok',
      source: waterFlows,
    },
    {
      key: 'riverKm',
      label: 'Říční kilometr',
    },
    {
      key: 'riverSide',
      label: 'Břeh',
      source: riverSides,
    },
    {
      key: 'jtskCoordinates.x',
      label: 'Poloha S-JTSK - X',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'jtskCoordinates.y',
      label: 'Poloha S-JTSK - Y',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'wgs84Coordinates.lat',
      label: 'Poloha WGS-84 - zeměpisná šířka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'wgs84Coordinates.lon',
      label: 'Poloha WGS-84 - zeměpisná délka',
      valueMapper: coordinatesValueMapper,
    },
    {
      key: 'hydrologicalSequenceNumber',
      label: 'Číslo hydrologického pořadí',
    },
    {
      key: 'requireVhPermission',
      label: 'Požadovat VH povolení',
    },
    {
      key: 'requireVhProtectionZonePermission',
      label: 'Požadovat VH povolení k ochranným pásmům',
    },
    {
      key: 'requireMap',
      label: 'Požadovat mapu',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'abstractionDeviceName',
      label: 'Název jímacího zařízení',
    },
    {
      key: 'abstractionDevicesCapacity',
      label: 'Kapacita jímacích zařízení',
    },
    {
      key: 'waterTreatment',
      label: 'Způsob úpravy vody',
    },
    {
      key: 'abstractedWaterOrigin',
      label: 'Původ odebrané vody',
    },
    {
      key: 'captureMethod.springSump',
      label: 'Pramenní jímka',
    },
    {
      key: 'captureMethod.springSumpCount',
      label: 'Pramenní jímka - počet',
    },
    {
      key: 'captureMethod.waterSupplyTunnel',
      label: 'Jímací štola',
    },
    {
      key: 'captureMethod.waterSupplyTunnelCount',
      label: 'Jímací štola - počet',
    },
    {
      key: 'captureMethod.well',
      label: 'Studna',
    },
    {
      key: 'captureMethod.wellCount',
      label: 'Studna - počet',
    },
    {
      key: 'captureMethod.borehole',
      label: 'Vrt',
    },
    {
      key: 'captureMethod.boreholeCount',
      label: 'Vrt - počet',
    },
    {
      key: 'abstractionPlace',
      label: 'Místo odběru',
    },
    {
      key: 'hydrogeologicalZone',
      label: 'Hydrogeologický rajon',
    },
    {
      key: 'abstractionDeviceAltitude',
      label: 'Kóta odběrného zařízení',
    },
    {
      key: 'waterTreatmentPlant',
      label: 'Čistírna odpadních vod',
    },
    {
      key: 'chemicalTreatment',
      label: 'Chemické čištění odpadních vod',
    },
    {
      key: 'mechanicalTreatment',
      label: 'Mechanické čištění odpadních vod',
    },
    {
      key: 'biologicalTreatment',
      label: 'Biologické čištění odpadních vod',
    },
    {
      key: 'sewerType',
      label: 'Typ kanalizace',
    },
    {
      key: 'dischargeSource',
      label: 'Zdroj vypouštění',
    },
    {
      key: 'hydrogeologicalZone',
      label: 'Hydrogeologický rajon',
    },
    {
      key: 'hydrologicalData.qa',
      label: 'Hydrologická data - Qa',
    },
    {
      key: 'hydrologicalData.mq',
      label: 'Hydrologická data - MQ',
    },
    {
      key: 'hydrologicalData.mzp',
      label: 'Hydrologická data - MZP',
    },
    {
      key: 'permanentArea.waterAltitude',
      label: 'Stálý prostor - Kóta hladiny',
    },
    {
      key: 'permanentArea.volume',
      label: 'Stálý prostor - Objem',
    },
    {
      key: 'permanentArea.floodedArea',
      label: 'Stálý prostor - Zatopená plocha',
    },
    {
      key: 'stockArea.waterAltitude',
      label: 'Zásobní prostor - Kóta hladiny',
    },
    {
      key: 'stockArea.volume',
      label: 'Zásobní prostor - Objem',
    },
    {
      key: 'stockArea.floodedArea',
      label: 'Zásobní prostor - Zatopená plocha',
    },
    {
      key: 'totalArea.waterAltitude',
      label: 'Celkový ovládatelný prostor- Kóta hladiny',
    },
    {
      key: 'totalArea.volume',
      label: 'Celkový ovládatelný prostor - Objem',
    },
    {
      key: 'totalArea.floodedArea',
      label: 'Celkový ovládatelný prostor - Zatopená plocha',
    },
  ];
}

import React, { useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Breadcrumb } from '../breadcrumb/breadcrumb';
import { Title } from '../title/title';
import { PageKey } from '../../enums';
import { BreadcrumbCtx } from '../breadcrumb/breadcrumb-ctx';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 80px)',
      marginTop: 80,
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    content: {
      flex: '1 0 auto',
      marginBottom: 50,
    },
  })
);

const titles = {
  [PageKey.HOME]:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ENVIRONMENT === 'test'
      ? 'Nacházíte se na TESTOVACÍM PROSTŘEDÍ CRŽP'
      : 'Vítejte v CRŽP',
  [PageKey.DATABOX_VERIFICATION]: 'Ověření datové schránky',
  [PageKey.LOGIN]: 'Přihlášení',
  [PageKey.EMAIL_VERIFICATION]: 'Ověření e-mailu',
  [PageKey.REGISTRATION]: 'Zvolte si typ registrace',
  [PageKey.REGISTRATION_FORM]: 'Vyplňte registrační údaje',
  [PageKey.REGISTRATION_EXTERNAL]: 'Registrace přes EnviIAM',
  [PageKey.REGISTRATION_COMPLETED]: 'Dokončení registrace',
  [PageKey.PASSWORD_RESET]: 'Vytvoření nového hesla',
  [PageKey.PASSWORD_RESET_REQUEST_SENT]: 'Žádost odeslána',
  [PageKey.PASSWORD_RESET_COMPLETED]: 'Heslo změněno',
};

export function PublicPage({ children }: { children: React.ReactNode }) {
  const classes = useStyles();

  const { currentPage } = useContext(BreadcrumbCtx);

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.content}>
        <Grid container>
          <Hidden smDown>
            <Grid item md={1} />
          </Hidden>
          <Grid item md={10} xs={12}>
            <Breadcrumb />
            <Title name={titles[currentPage as keyof typeof titles]} />
            {children}
          </Grid>
          <Hidden smDown>
            <Grid item md={1} />
          </Hidden>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}

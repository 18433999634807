import React, { ComponentType } from 'react';
import { FormDateTimeField } from '@eas/common-web';
import { HelpLabel } from '../../help/help-label';

export function DatedFields({
  code = 'DATED',
  FieldsComponent,
  hideCreated,
  hideUpdated,
}: {
  code?: string;
  FieldsComponent?: ComponentType;
  hideCreated?: boolean;
  hideUpdated?: boolean;
}) {
  return (
    <>
      {FieldsComponent && <FieldsComponent />}
      {!hideCreated && (
        <FormDateTimeField
          name="created"
          label={<HelpLabel label="Vytvoření" code={`${code}_CREATED`} />}
          disabled={true}
        />
      )}
      {!hideUpdated && (
        <FormDateTimeField
          name="updated"
          label={<HelpLabel label="Poslední úprava" code={`${code}_UPDATED`} />}
          disabled={true}
        />
      )}
    </>
  );
}

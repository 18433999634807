import { useState, useContext, useRef, useEffect } from 'react';
import { Draft04 as Core } from 'json-schema-library';
import {
  abortableFetch,
  DetailContext,
  AbortableFetch,
  DetailMode,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';
import { NotificationEvent } from '../../../models';
import { flatten } from '../../../utils/flatten';

/**
 * Api call
 *
 * @param itemId
 */
function callApi(itemId: string) {
  return abortableFetch(`${EvidenceAPI.NOTIFICATION_EVENTS}/${itemId}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export function useNotificationEvent(itemId?: string) {
  /**
   *
   */
  const [event, setEvent] = useState<NotificationEvent | undefined>(undefined);

  /**
   *
   */
  const [keys, setKeys] = useState<
    { freeMarkerKey: string; jsonSchemaKey: string; description: string }[]
  >([]);

  const { source, mode } = useContext(DetailContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const getNotificationEvent = useEventCallback(async () => {
    try {
      source.setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }
      fetch.current = callApi(itemId!);

      const response: NotificationEvent = await fetch.current.json();

      setEvent(response);

      const core = new Core(response.schema);

      const template = core.getTemplate({});

      const keys = Object.keys(flatten(template)).map((key) => ({
        jsonSchemaKey: `#/${key.replace('.', '/')}`,
        freeMarkerKey: `$\{(${key})!}`,
        description: core.getSchema(`#/${key.replace('.', '/')}`, {})[
          'description'
        ],
      }));

      setKeys(keys);
    } catch (err) {
      console.log(err);
      return undefined;
    } finally {
      source.setLoading(false);
    }
  });

  useEffect(() => {
    if (itemId && mode === DetailMode.EDIT) {
      getNotificationEvent();
    } else {
      setKeys([]);
      setEvent(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (itemId && mode === DetailMode.NEW) {
      getNotificationEvent();
    } else {
      setKeys([]);
      setEvent(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return {
    keys,
    event,
    isEventLoaded:
      !!event && (mode === DetailMode.EDIT || mode === DetailMode.NEW),
  };
}

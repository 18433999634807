import React, { useContext } from 'react';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PublishIcon from '@material-ui/icons/Publish';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButtonType,
  DetailHandle,
  DetailActionbarButton,
} from '@eas/common-web';
import { useAddBrandDialog } from './dialog-actions/add-brand-hook';
import { useAddModelDialog } from './dialog-actions/add-model-hook';
import { BoilerManufacturer } from '../../models';
import { useCreateNoteDialog } from '../../components/evidence/noted-evidence/dialog-actions/create-note-hook';
import { EntityType } from '../../enums/entity-type';
import { useBulkImportDialog } from './dialog-actions/bulk-model-import';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

export function BoilerManufacturersActionbar() {
  const { mode } = useContext(DetailContext);

  const { source } = useContext<DetailHandle<BoilerManufacturer>>(
    DetailContext
  );

  const { handleAddBrand, canAddBrand } = useAddBrandDialog({
    boilerBrands: [],
    selectedIndex: undefined,
    inDetailToolbar: true,
  });

  const { handleAddModel, canAddModel } = useAddModelDialog({
    boilerModels: [],
    boilerBrands: source?.data?.boilerBrands,
    selectedIndex: undefined,
    inDetailToolbar: true,
  });

  const {
    handleCreateNote,
    showCreateButton: showCreateNote,
  } = useCreateNoteDialog({
    entityType: EntityType.BOILER_MANUFACTURER,
  });

  const { canPerformBulkImport, handleImport } = useBulkImportDialog();

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          {canAddBrand && (
            <DetailActionbarButton
              startIcon={<PlaylistAddIcon fontSize="small" />}
              label="Přidat značku"
              onClick={handleAddBrand}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {canAddModel && (
            <DetailActionbarButton
              startIcon={<PlaylistAddIcon fontSize="small" />}
              label="Přidat model"
              onClick={handleAddModel}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {canPerformBulkImport && (
            <DetailActionbarButton
              startIcon={<PublishIcon fontSize="small" />}
              label="Hromadný import modelů"
              onClick={handleImport}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {showCreateNote && (
            <DetailActionbarButton
              startIcon={<NoteAddIcon fontSize="small" />}
              label="Přidat poznámku"
              onClick={handleCreateNote}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
        </div>
      )}
    </>
  );
}

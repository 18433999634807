import { SpecialFilter } from '../../../components/evidence/history-evidence/overrides/table-searchbar';
import { SpecialFilterTextCell } from '../../../components/evidence/history-evidence/special-filter-cells/special-filter-text-cell';

export function useSpecialFilters(): SpecialFilter[] {
  return [
    {
      label: 'IČO / UID',
      filterkey: 'identifier',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Název',
      filterkey: 'name',
      FilterCell: SpecialFilterTextCell,
    },
  ];
}

import {
  abortableFetch,
  NavigationContext,
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceAPI, Messages } from '../../enums';
import { useContext, useState, useRef, useEffect, useMemo } from 'react';
import {
  ProductionSiteMergeStateAction,
  ProcessForm,
} from './production-site-merge-types';
import { ProductionSite } from '../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { StepFinishActions } from './step-finish/step-finish-actions';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepMainProductionSiteLabel } from './step-main-production-site/step-main-production-site-label';
import { StepMainProductionSiteContent } from './step-main-production-site/step-main-production-site-content';
import { StepMainProductionSiteActions } from './step-main-production-site/step-main-production-site-actions';
import { stepMainProductionSiteSchema } from './step-main-production-site/step-main-production-site-schema';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepMergingProductionSiteLabel } from './step-merging-production-site/step-merging-production-site-label';
import { StepMergingProductionSiteContent } from './step-merging-production-site/step-merging-production-site-content';
import { StepMergingProductionSiteActions } from './step-merging-production-site/step-merging-production-site-actions';
import { stepMergingProductionSiteSchema } from './step-merging-production-site/step-merging-production-site-schema';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepEditLabel } from './step-edit/step-edit-label';
import { StepEditContent } from './step-edit/step-edit-content';
import { useStepEditSchema } from '../production-site-merge/step-edit/step-edit-schema';

function fetchProductionSite(id: string) {
  return abortableFetch(`${EvidenceAPI.PRODUCTION_SITES}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export function useProductionSiteMerge() {
  const { stateAction } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(
    stateAction?.action === ProductionSiteMergeStateAction.INIT_MAIN_PS
  );
  const [initialValues, setInitialValues] = useState<ProcessForm>();
  const fetch = useRef<AbortableFetch | null>(null);

  const loadProductionSite = useEventCallback(async () => {
    try {
      setIsLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = fetchProductionSite(stateAction?.data);
      const productionSite: ProductionSite = await fetch.current.json();

      unstable_batchedUpdates(() => {
        setIsLoading(false);

        setInitialValues({
          mergingProductionSite: productionSite,
        });
      });
    } catch (e) {
      setIsLoading(false);

      if (e.name !== 'AbortError') {
        showSnackbar(...Messages.ProductionSite.PRODUCTION_SITE_PRELOAD.ERROR);
        throw e;
      }

      return undefined;
    }
  });

  useEffect(() => {
    if (stateAction?.action === ProductionSiteMergeStateAction.INIT_MAIN_PS) {
      loadProductionSite();
    }
  }, []);

  const steps = useMemo(
    () => [
      {
        Label: StepMergingProductionSiteLabel,
        Content: StepMergingProductionSiteContent,
        Actions: StepMergingProductionSiteActions,
        validationSchema: stepMergingProductionSiteSchema,
        initialValues,
      },
      {
        Label: StepMainProductionSiteLabel,
        Content: StepMainProductionSiteContent,
        Actions: StepMainProductionSiteActions,
        validationSchema: stepMainProductionSiteSchema,
      },
      {
        Label: StepEditLabel,
        Content: StepEditContent,
        validationSchema: useStepEditSchema,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    [initialValues]
  );

  return {
    steps,
    isLoading,
  };
}

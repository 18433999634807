import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  usePrompts,
  useEventCallback,
  PromptContext,
  useFormSelector,
  FormTableField,
  TableFieldCells,
  EmptyComponent,
  FormPanel,
  TableFieldColumn,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import { SubjectUserDto, SubjectRelationship } from '../subjects-types';
import { useRoleRelationships } from '../../roles/roles-api';
import {
  UserRelationshipTableFieldRow,
  sortRoleInstances,
} from '../overrides/subjects-row-user-relationship';
import { FormPanelCountByName } from '../../../components/form/form-panel-summary/form-panel-count';
import { Permission } from '../../../enums';
import { Subject } from '../../../models';

/**
 * Detail vazby uživatele k subjektu
 */
const PROMPT_KEY = 'DETAIL_USER';

export function useUserDetailDialog({
  users,
  selectedIndex,
}: {
  users: SubjectUserDto[];
  selectedIndex: number | undefined;
}) {
  /**
   * Context stuff.
   */
  const { source } = useContext<DetailHandle<Subject>>(DetailContext);
  const { testPrompt } = useContext(PromptContext);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: `Detail vazby uživatele na subjekt`,
        dialogText: '',
        formInitialValues:
          selectedIndex !== undefined ? users?.[selectedIndex] : {},
        FormFields: function Body() {
          const { dto } = useFormSelector((dto: SubjectUserDto) => ({
            dto,
          }));

          const columns: TableFieldColumn<SubjectRelationship>[] = [
            {
              name: 'Vztah',
              datakey: 'relationship',
              width: 200,
              CellComponent: TableFieldCells.useSelectCellFactory(
                useRoleRelationships,
                function DisabledSelect({ value }: { value: string | null }) {
                  return <Typography variant="body1">{value}</Typography>;
                }
              ),
            },
            {
              name: 'Přiřazen k subjektu',
              datakey: 'validFrom',
              width: 200,
              CellComponent: TableFieldCells.DateTimeCell,
            },
            {
              name: 'Vazba ukončena',
              datakey: 'validTo',
              width: 200,
              CellComponent: TableFieldCells.DateTimeCell,
            },
          ];

          return (
            <>
              <Typography variant="body1">
                Uživatelské jméno: <b>{dto?.user?.username}</b>
                <br />
                Jméno:{' '}
                <b>
                  {dto?.user?.firstName} {dto?.user?.lastName}
                </b>
                <br />
                Email: <b>{dto?.user?.email}</b>
                <br />
                <br />
              </Typography>
              <FormPanel
                label="Historie vztahů:"
                summary={
                  <FormPanelCountByName
                    name="relationshipRoleInstances"
                    columns={[
                      ...columns,
                      {
                        name: 'Role',
                        datakey: 'userRoleInstances',
                        width: 200,
                        CellComponent: function Cell(props) {
                          const sortedRoleInstances = sortRoleInstances(
                            props.value ?? []
                          );

                          const value = sortedRoleInstances
                            .map(
                              (userRole) =>
                                `${userRole.role.name} (${userRole.role?.registeredFor?.name})`
                            )
                            .join('\n');

                          return (
                            <TableFieldCells.TextCell
                              {...props}
                              value={value}
                            />
                          );
                        },
                      },
                    ]}
                    permission={Permission.Subject.SUBJECT_LIST_ALL}
                  />
                }
              >
                <FormTableField<SubjectRelationship>
                  name="relationshipRoleInstances"
                  labelOptions={{
                    hide: true,
                  }}
                  layoutOptions={{
                    noSpacing: true,
                  }}
                  maxRows={10}
                  showDetailBtnCond={() => false}
                  visibleActionsColumn={false}
                  ToolbarComponent={EmptyComponent}
                  RowComponent={UserRelationshipTableFieldRow}
                  columns={columns}
                />
              </FormPanel>
            </>
          );
        },
        dialogShowConfirm: false,
        dialogWidth: 1000,
      },
    ],
    [source.data, selectedIndex, users]
  );

  const handleUserDetail = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: () => {},
    });
  });

  return {
    handleUserDetail,
  };
}

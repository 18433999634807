import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryLegalForm } from '../../models';
import { useValidationSchema } from './dict-legal-forms-schema';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';

export function DictionaryLegalForms() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryLegalForm>({
    identifier: PageKey.DICTIONARY_LEGAL_FORMS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_LEGAL_FORMS,
    },
    tableProps: {
      tableName: 'Právní formy',
      reportTag: ExportTags.DICTIONARY_LEGAL_FORMS,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.LEGAL_FORM,
      codePrefix: 'LEGAL_FORM',
      validationSchema,
      toolbarProps: {
        title: 'Právní forma',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

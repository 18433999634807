import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { useDirpAreas } from '../dict-dirp-area/dict-dirp-areas-api';
import { DictionaryLaw } from './../../models';

export function useColumns(): TableColumn<DictionaryLaw>[] {
  return [
    {
      datakey: 'code',
      name: 'Číslo předpisu',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryLaw>(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'name',
      name: 'Název předpisu',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryLaw>(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'description',
      name: 'Popis',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryLaw>(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'area',
      sortkey: 'area.name',
      filterkey: 'area.id',
      name: 'Oblast',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDirpAreas
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'dateIssued',
      name: 'Datum vydání',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryLaw>(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'effectiveFrom',
      name: 'Účinnost od',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryLaw>(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { App } from './app';
import {
  initHotreload,
  LocaleProvider,
  LocaleName,
  ValidationProvider,
} from '@eas/common-web';
import { themeOptions } from './theme';

const theme = createMuiTheme(themeOptions);

if (process.env.NODE_ENV === 'development') {
  initHotreload('ws://localhost:8099');
}

render(
  <ThemeProvider theme={theme}>
    <LocaleProvider
      defaultLocale={LocaleName.cs}
      translationsUrl="/api/crzp/translations"
    >
      <ValidationProvider>
        <CssBaseline />
        <App />
      </ValidationProvider>
    </LocaleProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

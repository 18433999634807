import * as Yup from 'yup';

import { StepControlAuthoritiesForm } from './step-control-authorities-types';

export function stepControlAuthoritiesSchema() {
  return Yup.object<StepControlAuthoritiesForm>().shape({
    competentControlAuthorities: Yup.array()
      .of(
        Yup.mixed().test('', '', function (val) {
          const { createError } = this;

          if (!val?.id) {
            return createError({
              path: 'competentControlAuthorities',
              message: `Pole Kompetentní kontrolní / jiný orgán nesmí být prázdné`,
            });
          }

          return true;
        })
      )
      .nullable(),
  });
}

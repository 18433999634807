import { Filter } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export enum Area {
  IRZ_FACILITY = 'IRZ_FACILITY',
  OVZ_FACILITY = 'OVZ_FACILITY',
  PRODUCTION_SITE = 'PRODUCTION_SITE',
}

export interface Source {
  api: EvidenceAPI;
  type: Area;
  filters: Filter[];
  shouldLoad: boolean;
}

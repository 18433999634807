import React, { useContext } from 'react';
import {
  FormPanel,
  FormAutocomplete,
  DetailMode,
  DetailContext,
  FormSelect,
  useFormSelector,
} from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';
import { userLabelMapper } from '../../../components/form/mappers/label-mappers';
import { Role, UserRoleInstance } from '../../../models';
import { useSubjectRoles } from '../../subject/subjects-api';
import { useUsers } from '../../user/users-api';

export function UserRoleFields() {
  const { mode } = useContext(DetailContext);

  const { subject } = useFormSelector((values: UserRoleInstance) => ({
    subject: values?.subject ?? {},
  }));

  const { rolesSource } = useSubjectRoles(subject);
  const users = useUsers();

  return (
    <>
      <FormPanel label="Uživatelská role">
        <FormSelect<Role>
          disabled={mode === DetailMode.EDIT}
          name="role"
          label={<HelpLabel label="Role" code="ROLE_INSTANCES_ROLE" required />} // Povinné pole
          source={rolesSource}
          tooltipMapper={(o) => o.name}
        />
        <FormAutocomplete
          disabled={mode === DetailMode.EDIT}
          name="user"
          label={
            <HelpLabel label="Uživatel" code="ROLE_INSTANCES_USER" required />
          } // Povinné pole
          source={users}
          labelMapper={userLabelMapper}
          tooltipMapper={userLabelMapper}
        />
      </FormPanel>
    </>
  );
}

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import CloseIcon from '@material-ui/icons/Close';
import { useAddSystemCertificateDialog } from './dialog-actions/add-system-certificate-hook';
import { useDeactivateSystemCertificateDialog } from './dialog-actions/deactivate-system-certificate-hook';
import { useActivateSystemCertificateDialog } from './dialog-actions/activate-system-certificate-hook';
import { useDeleteSystemCertificateDialog } from './dialog-actions/delete-system-certificate-hook';

const useStyles = makeStyles(() => ({
  tableActions: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    height: 30,
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 12px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  buttonGroup: {
    display: 'flex',
    marginLeft: -5, // offset first icon left padding
  },
}));

export function SystemCertificatesToolbar({
  selectedIndex,
  setSelectedIndex,
}: TableFieldToolbarProps) {
  const classes = useStyles();

  const {
    canAddCertificate,
    handleAddCertificate,
  } = useAddSystemCertificateDialog();

  const {
    canActivateCertificate,
    handleActivateCertificate,
  } = useActivateSystemCertificateDialog({
    selectedIndex: selectedIndex!,
  });

  const {
    canDeactivateCertificate,
    handleDeactivateCertificate,
  } = useDeactivateSystemCertificateDialog({
    selectedIndex: selectedIndex!,
    setSelectedIndex,
  });

  const {
    canDeleteSystemCertificate,
    handleDeleteCertificate,
  } = useDeleteSystemCertificateDialog({
    selectedIndex: selectedIndex!,
    setSelectedIndex,
  });

  return (
    <div className={classes.tableActions}>
      <ButtonGroup
        size="small"
        variant="outlined"
        className={classes.buttonGroup}
      >
        <TableFieldToolbarButton
          color="primary"
          title="Přidat certifikát"
          disabled={!canAddCertificate}
          show={true}
          IconComponent={ControlPointIcon}
          onClick={handleAddCertificate}
        />
        <TableFieldToolbarButton
          color="primary"
          title="Aktivovat certifikát"
          disabled={selectedIndex === undefined || !canActivateCertificate}
          show={true}
          IconComponent={RefreshIcon}
          onClick={handleActivateCertificate}
        />
        <TableFieldToolbarButton
          color="primary"
          title="Deaktivovat certifikát"
          disabled={selectedIndex === undefined || !canDeactivateCertificate}
          show={true}
          IconComponent={CloseIcon}
          onClick={handleDeactivateCertificate}
        />
        <TableFieldToolbarButton
          color="primary"
          title="Odstranit certifikát"
          disabled={selectedIndex === undefined || !canDeleteSystemCertificate}
          show={true}
          IconComponent={DeleteIcon}
          onClick={handleDeleteCertificate}
        />
      </ButtonGroup>
    </div>
  );
}

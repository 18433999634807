import React from 'react';
import { useContext } from 'react';
import {
  NavigationContext,
  useEventCallback,
  EvidenceStateAction,
} from '@eas/common-web';
import MuiButton from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      minWidth: '0',
      marginLeft: '2pt',
      boxShadow: '0 0 0',
      padding: '2pt',
      height: 23,
      textDecoration: 'underline !important',
    },
  })
);

export function DetailNavigationButton({
  id,
  url,
  buttonText = 'OTEVŘÍT',
}: {
  url: string;
  id?: string;
  buttonText?: string;
}) {
  const { navigate } = useContext(NavigationContext);
  const classes = useStyles();

  const handleRedirect = useEventCallback((e: React.MouseEvent) => {
    e.preventDefault();
    navigate(url, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: id,
    });
  });

  return (
    <MuiButton
      onClick={handleRedirect}
      component="a"
      href={`${url}/${id}`}
      size="small"
      classes={{ root: classes.button }}
      variant="text"
    >
      {buttonText}
    </MuiButton>
  );
}

import React, { useContext, useMemo } from 'react';
import {
  FormPanel,
  FormFileField,
  FormEditor,
  useFormSelector,
  FormContext,
  DetailContext,
  DetailHandle,
  FormCustomField,
  DetailMode,
  FormTextField,
} from '@eas/common-web';
import { Translation } from './translations-types';
import Typography from '@material-ui/core/Typography';
import { RichEditor } from '../../components/rich-editor/rich-editor';
import { useStyles as useTranslationStyles } from '../../components/help/help-block';
import { HelpLabel } from '../../components/help/help-label';

export function TranslationsFields() {
  /**
   * Styles
   */
  const classes = useTranslationStyles();

  /**
   * Rich editor manipulation stuff.
   */
  const { setFieldValue, editing } = useContext(FormContext);
  const { source, mode, isExisting } = useContext<DetailHandle<Translation>>(
    DetailContext
  );

  const { code } = useFormSelector((data: Translation) => ({
    code: data.code!,
  }));

  const isGlobalTexts = code === 'GLOBAL_TEXTS';

  const editorValue = useMemo(() => {
    try {
      const json = JSON.parse(source.data!.textContent!);

      return json;
    } catch (e) {
      return {};
    }
  }, [source.data]);

  return (
    <FormPanel label="Překlad">
      <FormTextField
        name="code"
        disabled={isGlobalTexts}
        label={<HelpLabel label="Kód" code="TRANSLATION_CODE" required />}
      />
      <FormTextField
        name="name"
        label={<HelpLabel label="Název" code="TRANSLATION_NAME" required />} // Povinné pole
      />
      {isExisting && (
        <FormFileField
          disabled={isGlobalTexts}
          label={<HelpLabel label="Soubor" code="TRANSLATION_CONTENT" />}
          name="content"
        />
      )}
      {isExisting && isGlobalTexts && (
        <FormEditor
          name="textContent"
          language={'json'}
          label="Text"
          height={500}
        />
      )}
      {isExisting && !isGlobalTexts && (
        <FormCustomField
          label={<HelpLabel label="Text" code="TRANSLATION_TEXT" />}
        >
          {mode === DetailMode.EDIT && (
            <RichEditor
              initialValue={editorValue[code]}
              onChange={(v) => {
                const val = {
                  [code]: v,
                };

                setFieldValue('textContent', JSON.stringify(val));
              }}
              disabled={!editing}
            />
          )}
          {mode === DetailMode.VIEW && (
            <Typography
              variant="body1"
              component="div"
              className={classes.editor}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: editorValue[code],
                }}
              />
            </Typography>
          )}
        </FormCustomField>
      )}
    </FormPanel>
  );
}

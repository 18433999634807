import React, { useContext, useMemo } from 'react';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import {
  TableToolbarButton,
  useEventCallback,
  NavigationContext,
  EvidenceStateAction,
  TableToolbarButtonAction,
  UserContext,
} from '@eas/common-web';
import { RecipientsSource, MailCampaign } from '../../models/mail-campaign';
import { createFromEvidenceApiFactory } from './mailing-toolbar-button-api';
import { Me } from '../../models';
import { Permission } from '../../enums';

export function mailingToolbarButtonFactory({
  source,
}: {
  source: RecipientsSource;
}) {
  return function MailingToolbarButton() {
    const { navigate } = useContext(NavigationContext);
    const { hasPermission } = useContext<UserContext<Me>>(UserContext);

    const createFromEvidenceApi = useMemo(
      () => createFromEvidenceApiFactory(source),
      []
    );

    const handleNavigation = useEventCallback(
      async (campaign: MailCampaign) => {
        navigate('/crzp/mailing', false, {
          action: EvidenceStateAction.SHOW_ITEM,
          data: campaign.id,
        });
      }
    );

    const canCreateMailingCampaign = hasPermission(
      Permission.Mailing.MAILING_CREATE
    );

    return canCreateMailingCampaign ? (
      <>
        <TableToolbarButtonAction
          ButtonComponent={TableToolbarButton}
          promptKey="MAIL_CAMPAIGN_CREATE_FROM_EVIDENCE"
          apiCall={createFromEvidenceApi}
          onResult={handleNavigation}
          buttonLabel={<ContactMailIcon />}
          buttonTooltip="Vytvořit mailovou kampaň"
          dialogTitle="Varování"
          dialogText="Bude založena nová mailová kampaň. Pokud jste vybrali mnoho záznamů, vytvoření může chvíli trvat. Skutečně chcete pokračovat ?"
        />
      </>
    ) : null;
  };
}

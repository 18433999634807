import React, { memo, ReactElement, useContext, forwardRef, Ref } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import {
  TableFieldContext,
  TableFieldRowProps,
  TableFieldCells,
} from '@eas/common-web';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      boxSizing: 'border-box',
      borderBottom: '1px solid #cdcdcd',
      minWidth: '100%',
      height: 30,
      padding: '2px 12px',
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
    },
    rowApprove: {
      color: theme.palette.primary.dark,
    },
    rowReject: {
      color: theme.palette.error.dark,
    },
    tableRowActions: {
      flexShrink: 0,
      width: 35,
      height: 20,
      verticalAlign: 'top',
      display: 'inline-block',
      padding: 0,
      overflow: 'hidden',
      '& svg': {
        cursor: 'pointer',
        width: 20,
        height: 20,
        padding: 0,
      },
    },
    cellWrapper: {
      flexShrink: 0,
      display: 'flex',
      paddingRight: 10,
      boxSizing: 'border-box',
    },
  })
);

export function highlightTableFieldRowFactory({
  isApprovedRow,
  isRejectedRow,
  hideRowActions = false,
}: {
  isApprovedRow: (values: any) => boolean;
  isRejectedRow: (values: any) => boolean;
  hideRowActions?: boolean;
}) {
  return memo(
    forwardRef(function TableFieldRow<OBJECT>(
      { index, value }: TableFieldRowProps<OBJECT>,
      ref: Ref<HTMLDivElement>
    ) {
      const classes = useStyles();

      const { filteredColumns } = useContext<TableFieldContext<OBJECT>>(
        TableFieldContext
      );

      return (
        <div
          className={clsx(classes.row, {
            [classes.rowApprove]: isApprovedRow(value),
            [classes.rowReject]: isRejectedRow(value),
          })}
          ref={ref}
        >
          {!hideRowActions && <div className={classes.tableRowActions} />}
          {filteredColumns.map((column, i) => {
            const { CellComponent = TableFieldCells.TextCell } = column;
            return (
              <div
                key={i}
                className={classes.cellWrapper}
                style={{
                  width: column.width,
                }}
              >
                <CellComponent
                  index={index}
                  value={get(value, column.datakey, '')}
                  rowValue={value}
                  column={column as any}
                />
              </div>
            );
          })}
        </div>
      );
    }) as <OBJECT>(p: TableFieldRowProps<OBJECT>) => ReactElement
  );
}

import React, { useState, useRef, useEffect } from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { TextFieldProps, useEventCallback } from '@eas/common-web';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export function EmptyWrapper({ children }: { children?: React.ReactNode }) {
  return <>{children}</>;
}

export function publicFieldFactory(
  props: {
    label?: string;
    inputProps?: Record<string, unknown>;
    autoComplete?: 'off' | 'new-password' | 'none';
    fullWidth?: boolean;
    onChangeEvent?: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
  } & React.HTMLAttributes<HTMLInputElement> = {
    label: '',
    inputProps: {},
    autoComplete: 'off',
  }
) {
  return function PublicField({
    onChange,
    value,
    name,
    error,
    helperText,
    form,
    className,
  }: Pick<TextFieldProps, 'onChange' | 'value' | 'name' | 'error' | 'form'> & {
    helperText?: string;
  } & React.HTMLAttributes<HTMLInputElement>) {
    const [showPassword, setShowPassword] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClickShowPassword = () => {
      const el = inputRef.current;

      if (el && typeof el.selectionEnd === 'number') {
        setCursorPosition(el.selectionEnd);
      }

      setShowPassword(!showPassword);
    };

    const handleChange = useEventCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onChangeEvent) {
          props.onChangeEvent(e);
        }

        if (onChange) {
          onChange(e.target.value);
        }
      }
    );

    useEffect(() => {
      const el = inputRef.current;

      if (
        el &&
        el.setSelectionRange &&
        el.value.length &&
        props.inputProps?.type === 'password'
      ) {
        el.focus();
        el.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, [cursorPosition, showPassword]);

    const handleMouseDownPassword = (event: React.ChangeEvent<any>) => {
      event.preventDefault();
    };

    const showPasswordButton = props.inputProps?.type === 'password' && value;
    const resolvedInputProps = showPasswordButton
      ? { ...props.inputProps, type: showPassword ? 'text' : 'password' }
      : { ...props.inputProps };

    return (
      <MuiTextField
        className={className}
        name={name}
        label={props.label}
        value={value}
        error={error}
        helperText={helperText}
        fullWidth={props.fullWidth ?? true}
        onChange={handleChange}
        inputRef={inputRef}
        InputProps={{
          endAdornment: showPasswordButton && (
            <InputAdornment position="end">
              <IconButton
                style={{ marginBottom: 16 }}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          ...resolvedInputProps,
          autoComplete: props.autoComplete,
          form,
        }}
      />
    );
  };
}

import * as Yup from 'yup';
import {
  Address,
  Region,
  District,
  Municipality,
  Wgs84Coordinates,
  DictionaryCountry,
  HouseNumberType,
  AddressPlace,
} from '../../../models';
import { AddressOptions } from './address-field-types';

export const addressLatRules = 'číslo s desetinnou tečkou, rozsah −90 až 90';
export const addressLonRules = 'číslo s desetinnou tečkou, rozsah -180 až 180';
export const zipRules = '5 číslic';

export const addressSchemaDefault: AddressOptions = {
  country: { required: true },
  region: { required: true },
  district: { required: true },
  municipality: { required: true },
  municipalityPart: { required: false },
  cityDistrict: { required: false },
  street: { required: false },
  addressPlace: { required: false },
  houseNumberType: { required: true },
  houseNumber: { required: true },
  orientationNumber: { required: false },
  orientationNumberMark: { required: false },
  zip: { required: true },
};

export const addressFieldSchema = (addressOptions: AddressOptions = {}) => {
  const options = {
    ...addressSchemaDefault,
    ...addressOptions,
  };

  return Yup.object<Address>().shape(
    {
      country: options?.country?.required
        ? Yup.object<DictionaryCountry>().nullable().required('Povinné pole')
        : Yup.object().nullable().notRequired(),

      regionRuian: options?.region?.required
        ? Yup.object<Region>()
            .nullable()
            .when('country', {
              is: (country?: DictionaryCountry) => country?.ruian,
              then: Yup.object<Region>().nullable().required('Povinné pole'),
            })
        : Yup.object().nullable().notRequired(),

      districtRuian: options?.district?.required
        ? Yup.object<District>()
            .nullable()
            .when('country', {
              is: (country?: DictionaryCountry) => country?.ruian,
              then: Yup.object<District>().nullable().required('Povinné pole'),
            })
        : Yup.object().nullable().notRequired(),

      municipality: options?.municipality?.required
        ? Yup.string()
            .nullable()
            .when('country', {
              is: (country?: DictionaryCountry) => !country?.ruian,
              then: Yup.string().nullable().required('Povinné pole'),
            })
        : Yup.object().nullable().notRequired(),

      municipalityRuian: options?.municipality?.required
        ? Yup.object<Municipality>()
            .nullable()
            .when('country', {
              is: (country?: DictionaryCountry) => country?.ruian,
              then: Yup.object<Municipality>()
                .nullable()
                .required('Povinné pole'),
            })
        : Yup.object().nullable().notRequired(),

      addressPlaceRuian: options?.addressPlace?.required
        ? Yup.object<AddressPlace>()
            .nullable()
            .when('country', {
              is: (country?: DictionaryCountry) => country?.ruian,
              then: Yup.object<AddressPlace>()
                .nullable()
                .required('Povinné pole'),
            })
        : Yup.object().nullable().nullable().notRequired(),

      houseNumberType: options?.houseNumberType?.required
        ? Yup.mixed<HouseNumberType>()
            .nullable()
            .when(['country', 'houseNumber'], {
              is: (country, houseNumber) => country?.ruian && !!houseNumber,
              then: Yup.mixed<HouseNumberType>()
                .nullable()
                .required('Povinné pole'),
            })
        : Yup.object().nullable().notRequired(),

      houseNumber: options?.houseNumber?.required
        ? Yup.string()
            .nullable()
            .when('houseNumberType', {
              is: (type) => !!type,
              then: Yup.string().nullable().required('Povinné pole'),
            })
            .nullable()
        : Yup.object().nullable().notRequired(),

      zip: options?.zip?.required
        ? Yup.string()
            .nullable()
            .when('country', {
              is: (country?: DictionaryCountry) => !!country?.ruian,
              then: Yup.string()
                .nullable()
                .required('Povinné pole')
                .matches(/^\d{5}$/, `Nesplňuje pravidla: ${zipRules}`),
            })
        : Yup.object().nullable().notRequired(),
    },
    [
      ['region', 'regionRuian'],
      ['district', 'districtRuian'],
      ['municipality', 'municipalityRuian'],
      ['houseNumber', 'houseNumberType'],
    ]
  );
};

export const wgs84CoordinatesSchema = () =>
  Yup.object<Wgs84Coordinates>()
    .shape({
      lat: Yup.number()
        .typeError(`Nesplňuje pravidla: ${addressLatRules}`)
        .min(-90, `Nesplňuje pravidla: ${addressLatRules}`)
        .max(90, `Nesplňuje pravidla: ${addressLatRules}`)
        .nullable()
        .required(
          'Povinné pole (použijte "Zadat bod do mapy" a následně označte bod na mapě - doporučená varianta, příp. "Zadat souřadnice" - ruční zadání)'
        ),
      lon: Yup.number()
        .typeError(`Nesplňuje pravidla: ${addressLonRules}`)
        .min(-180, `Nesplňuje pravidla: ${addressLonRules}`)
        .max(180, `Nesplňuje pravidla: ${addressLonRules}`)
        .nullable()
        .required(
          'Povinné pole (použijte "Zadat bod do mapy" a následně označte bod na mapě - doporučená varianta, příp. "Zadat souřadnice" - ruční zadání)'
        ),
    })
    .optional();

import { useContext, useEffect } from 'react';
import {
  UserContext,
  ApiFilterOperation,
  Params,
  FormContext,
} from '@eas/common-web';
import { useSubjects } from '../../subject/subjects-api';
import { Me, PermissionsState } from '../../../models';
import { getSubjectsWithPermission } from '../../../components/permission/perm-utils';
import { Permission } from '../../../enums';

export function useStepOriginalOperator(field: string) {
  /** Context stuff */
  const { user, hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );
  const { setFieldValue } = useContext(FormContext);

  /**
   * TRUE if logged user has ADMIN permission.
   */
  const isAdmin =
    hasPermission(Permission.Default.ADMINISTRATOR) ||
    hasPermission(Permission.Default.CENIA_ADMIN);

  /**
   * If logged user is not ADMIN and has OVZ_REQUEST_CHANGE_OPERATOR_SUBMIT only for 1 subject, prefill this subject.
   */
  const subjectIds = getSubjectsWithPermission(
    user!,
    Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_SUBMIT
  );

  const isOnlySubject = !isAdmin && subjectIds.length === 1;

  const params: Params = {
    filters: [],
  };
  if (!isAdmin) {
    params.filters?.push({
      operation: ApiFilterOperation.IDS,
      ids: subjectIds,
    });
  }

  const subjectSource = useSubjects(params);

  useEffect(() => {
    const getOperator = async () => {
      await subjectSource.loadMore();
    };

    if (isOnlySubject) {
      getOperator();
    }

    if (isOnlySubject && subjectSource.items.length === 1) {
      setFieldValue(field, subjectSource.items[0]);
    }
  }, [isOnlySubject, subjectSource.items]);

  return {
    subjectSource: subjectSource,
    isOnlySubject,
  };
}

import React, { useState } from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { TextFieldProps } from '@eas/common-web';
import { useStyles } from './protected-field-styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export function protectedFieldFactory(
  {
    required,
    inputProps,
    InputProps,
  }: {
    required?: boolean;
    inputProps?: Record<string, unknown>;
    InputProps?: Record<string, unknown>;
  } = {
    required: false,
    inputProps: {},
    InputProps: {},
  }
) {
  return function ProtectedField({
    onChange,
    value,
    name,
    error,
    helperText,
    disabled,
    form,
  }: Pick<
    TextFieldProps,
    'onChange' | 'value' | 'name' | 'error' | 'disabled' | 'form'
  > & {
    helperText?: string;
  }) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.ChangeEvent<any>) => {
      event.preventDefault();
    };

    const showPasswordButton =
      inputProps?.type === 'password' && !disabled && value;
    const resolvedInputProps = showPasswordButton
      ? { ...inputProps, type: showPassword ? 'text' : 'password' }
      : { ...inputProps };

    return (
      <MuiTextField
        name={name}
        value={value ?? ''}
        required={required}
        error={error}
        helperText={helperText}
        fullWidth={true}
        onChange={(e) => onChange?.(e.target.value)}
        disabled={disabled}
        InputProps={{
          classes,
          disableUnderline: true,
          endAdornment: showPasswordButton && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        inputProps={{
          ...resolvedInputProps,
          autoComplete: 'off',
          form,
        }}
      />
    );
  };
}

import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip, UserContext } from '@eas/common-web';
import HelpOutline from '@material-ui/icons/HelpOutline';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { PermissionsState, Me } from '../../models';
import { Permission } from '../../enums';
import { useAdminLink } from './help-admin-link-hook';
import clsx from 'clsx';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  star: {
    color: theme.palette.error.main,
  },
  link: {
    display: 'block',
    marginTop: 30,
    color: theme.palette.error.main,
    lineHeight: '20px',
    height: 20,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  icon: {
    fontSize: 14,
    cursor: 'pointer',
    marginLeft: 4,
  },
  emptyIcon: {
    color: theme.palette.error.main,
  },
  editor: {
    display: 'block',
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: 'transparent',
    width: '100%',
    '& *': {
      lineHeight: '2em',
      minHeight: '2em',
    },
    '& ul, & ol': {
      marginLeft: '20px',
    },
  },
}));

function Label({ code, title }: { code: string; title: string }) {
  const classes = useStyles();

  const intl = useIntl();

  const { handleRedirect, showEditButton } = useAdminLink({ code, title });

  return (
    <>
      <Typography variant="body1" component="div" className={classes.editor}>
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: code,
              defaultMessage: `Definujte text pod klíčem ${code}`,
            }),
          }}
        />
      </Typography>
      {showEditButton && (
        <a className={classes.link} onClick={handleRedirect}>
          Upravit nápovědu
        </a>
      )}
    </>
  );
}

export function HelpLabel({
  label,
  code,
  required,
}: {
  label: string;
  code: string;
  required?: boolean;
}) {
  const classes = useStyles();

  const intl = useIntl();
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  /**
   * Has logged user right permissions?
   */
  const showEditButton = hasPermission(Permission.Help.HELP_LIST);

  const hasHelp = intl.messages[code];

  if (!hasHelp && !showEditButton) {
    return (
      <p className={classes.root}>
        {label}
        {required && <span className={classes.star}>*</span>}
      </p>
    );
  }

  return (
    <div className={classes.root}>
      {label}
      {required && <span className={classes.star}>*</span>}
      <Tooltip
        title={<Label code={code} title={label} />}
        placement="top-start"
        type="CLICKABLE"
      >
        <HelpOutline
          className={clsx(classes.icon, { [classes.emptyIcon]: !hasHelp })}
        />
      </Tooltip>
    </div>
  );
}

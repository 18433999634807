import React from 'react';
import { PublicListDataSource } from '../../models/public-list';
import { publicListToolbarButtonFactory } from '../../composite/public-list-toolbar-button/public-list-toolbar-button';

const PublicListToolbarButton = publicListToolbarButtonFactory({
  dataSource: PublicListDataSource.WUP_EVIDENCE,
  name: 'Veřejný seznam míst užívání vody',
});

export function WaterUsagePlacesTableToolbar() {
  return (
    <>
      <PublicListToolbarButton />
    </>
  );
}

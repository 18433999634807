import {
  TableColumn,
  TableCells,
  TableFieldColumn,
  TableFieldCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { System } from '../../models/system';
import { Role } from '../../models';
import { SystemCertificate } from '../../models/system-certificate';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export function useColumns(): TableColumn<System>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'location',
      name: 'Umístění',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'supportedAgenda',
      name: 'Podporovaná agenda dle zákona',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'administrator',
      name: 'Správce - kontaktní osoba',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'earliestCertificateStartDate',
      name: 'Platnost certifikátu od',
      width: 200,
      CellComponent: TableCells.DateCell,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'latestCertificateEndDate',
      name: 'Platnost certifikátu do',
      width: 200,
      CellComponent: TableCells.DateCell,
      sortable: false,
      filterable: false,
    },
  ];
}

export function useRoleColumns(): TableFieldColumn<Role>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
    },
  ];
}

export function useCertificateColumns(): TableFieldColumn<SystemCertificate>[] {
  return [
    {
      datakey: 'description',
      name: 'Popis',
      width: 300,
    },
    {
      datakey: 'active',
      name: 'Aktivní',
      CellComponent: TableFieldCells.BooleanCell,
      width: 200,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      CellComponent: TableFieldCells.DateCell,
      width: 200,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      CellComponent: TableFieldCells.DateCell,
      width: 200,
    },
  ];
}

import React, { useContext, useRef } from 'react';
import {
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailContext,
  FormTextArea,
  FormDateField,
  abortableFetch,
  UserContext,
  PromptContext,
  usePrompts,
} from '@eas/common-web';
import { unstable_batchedUpdates } from 'react-dom';
import { EvidenceAPI, Permission, Messages } from '../../../enums';
import { HelpLabel } from '../../../components/help/help-label';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call
 *
 * @param id
 * @param values
 */
export function callApi(
  id: string,
  values: { note?: string; validTo?: string }
) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITIES}/${id}/active`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
    body: JSON.stringify(values),
  });
}

/**
 * Zneplatnění provozovny IRZ
 */
const PROMPT_KEY = 'DEACTIVATE';

export function useDeactivateDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Zneplatnění provozovny IRZ',
      dialogText: 'Skutečně chcete zneplatnit provozovnu IRZ?',
      FormFields: function Body() {
        return (
          <>
            <FormTextArea
              name="note"
              label={
                <HelpLabel
                  label="Poznámka"
                  code="IRZ_FACILITY_DEACTIVATE_DIALOG_NOTE"
                />
              }
            />
            <FormDateField
              name="validTo"
              label={
                <HelpLabel
                  label="Datum"
                  code="IRZ_FACILITY_DEACTIVATE_DIALOG_VALID_TO"
                />
              }
            />
          </>
        );
      },
      dialogWidth: 600,
    },
  ]);

  const handleDeactivate = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { note?: string; validFrom?: string }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callApi(source.data!.id, values);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.IrzFacility.DEACTIVATE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.IrzFacility.DEACTIVATE,
              err.code
            );

            showSnackbar(...message);

            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showDeactivateButton =
    hasPermission(Permission.IrzFacility.IRZ_INVALIDATE) && source.data?.active;

  return {
    handleDeactivate,
    showDeactivateButton,
  };
}

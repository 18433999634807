import React from 'react';
import { FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export function DictionaryIndicatorsFields() {
  return (
    <>
      <FormTextField
        name="description"
        label={<HelpLabel label="Popis" code="DICT_INDICATORS_DESCRIPTION" />}
      />
      <FormTextField
        name="unit"
        label={<HelpLabel label="Jednotka" code="DICT_INDICATORS_UNIT" />}
      />
    </>
  );
}

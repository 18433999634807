import { IspopDocument } from './ispop-document';
import { AuthoredObject } from './authored-object';
import { DocumentSignature } from './document-signature';
import { Subject, SubjectUnion } from './subject';
import { Address } from './address';
import { PersonalData } from './personal-data';
import { PhoneNumber } from './phone-number';
import { DatedObject } from './dated-object';
import { ArchivedFile } from './archived-file';
import { DomainObject } from './domain-object';
import { TwoFactorDetails } from './two-factor-details';

/**
 * Typ subjektu pro registraci
 */
export enum SubjectRegistrationType {
  /**
   * Právnická osoba
   */
  LEGAL_ENTITY_REGISTRATION = 'LEGAL_ENTITY_REGISTRATION',

  /**
   * Právnická osoba bez IČO
   */
  LEGAL_ENTITY_WITHOUT_ICO_REGISTRATION = 'LEGAL_ENTITY_WITHOUT_ICO_REGISTRATION',

  /**
   * Fyzická osoba
   */
  NATURAL_PERSON_REGISTRATION = 'NATURAL_PERSON_REGISTRATION',

  /**
   * Fyzická osoba podnikající
   */
  BUSINESS_NATURAL_PERSON_REGISTRATION = 'BUSINESS_NATURAL_PERSON_REGISTRATION',

  /**
   * Zahraniční právnická osoba
   */
  FOREIGN_LEGAL_ENTITY_REGISTRATION = 'FOREIGN_LEGAL_ENTITY_REGISTRATION',

  /**
   * Zahraniční fyzická osoba
   */
  FOREIGN_NATURAL_PERSON_REGISTRATION = 'FOREIGN_NATURAL_PERSON_REGISTRATION',
}

/**
 * Stav žádosti o registraci subjektu
 */
export enum SubjectRegistrationState {
  /**
   * Čeká na autorizaci
   */
  WAITING_FOR_AUTHORIZATION = 'WAITING_FOR_AUTHORIZATION',

  /**
   * Vyžadováno manuální dokončení
   */
  MANUAL_FINISH_REQUIRED = 'MANUAL_FINISH_REQUIRED',

  /**
   * Čeká na synchronizaci se ZR
   */
  WAITING_FOR_ZR_SYNC = 'WAITING_FOR_ZR_SYNC',

  /**
   * Schválena
   */
  APPROVED = 'APPROVED',

  /**
   * Zamítnuta
   */
  REJECTED = 'REJECTED',

  /**
   * Zamítnuto pro existující správcovství
   */
  REJECTED_FOR_EXISTING_OWNERSHIP = 'REJECTED_FOR_EXISTING_OWNERSHIP',

  /**
   * Není zpracovatelné systémem, legacy stav z ISPOP 1
   */
  NOT_PROCESSABLE = 'NOT_PROCESSABLE',

  /**
   * Není autorizovatelné, legacy stav z ISPOP 1
   */
  NOT_AUTHORIZABLE = 'NOT_AUTHORIZABLE',
}

export enum AuthorizationMethod {
  /**
   * Listinná autorizace
   */
  LETTER_FORM = 'LETTER_FORM',

  /**
   * Autorizace podepsaným dokumentem
   */
  SIGNED_DOCUMENT = 'SIGNED_DOCUMENT',

  /**
   * Autorizace odesláním datovou schránkou
   */
  DATA_BOX = 'DATA_BOX',
}

/**
 * Autor žádosti o registraci subjektu
 */
export interface SubjectRegistrationAuthor extends DatedObject {
  email?: PersonalData;
  firstName?: PersonalData;
  lastName?: PersonalData;
  name?: string;
  username?: string;
  phoneNumber?: {
    prefix?: string;
    number?: PersonalData;
  };
}

export interface CeniaDetails extends DomainObject {
  /**
   * Ev.č./Č.j.
   */
  number?: string;

  /**
   * Poznámka
   */
  note?: string;

  /**
   * Příznak, zda-li je možné editovat poznámku
   */
  allowNoteUpdate?: boolean;
}

/**
 *  Žádost o registraci subjektu
 */
export interface SubjectRegistration extends AuthoredObject {
  /**
   * Název
   */
  name?: string;

  /**
   * Telefonní číslo
   */
  phoneNumber?: PhoneNumber;

  /**
   * E-mail
   */
  email?: PersonalData;

  /**
   * Webová stránka
   */
  webSite?: string;

  /**
   * Stav žádosti
   */
  state?: SubjectRegistrationState;

  /**
   * Dokument registrace
   */
  documentSignature?: DocumentSignature;

  /**
   * Subjekt registrace
   */
  registrationSubject?: SubjectUnion;

  /**
   * Subjekt (vytvořen nový nebo již existující platný)
   */
  approvedSubject?: Subject;

  /**
   * Typ subjektu
   */
  type?: SubjectRegistrationType;

  /**
   * Evidenční číslo
   */
  evidenceNumber?: number;

  /**
   * ISPOP dokument
   */
  ispopDocument?: IspopDocument;

  /**
   * Způsob autorizace
   */
  authorizationMethod?: AuthorizationMethod;

  /**
   * Autor
   */
  author?: SubjectRegistrationAuthor;

  /**
   * Původní jméno autora
   */
  authorFirstName?: PersonalData;

  /**
   * Původní příjmení autora
   */
  authorLastName?: PersonalData;

  /**
   * Původní email autora
   */
  authorEmail?: PersonalData;

  /**
   * Počet příloh
   */
  attachmentCount?: number;

  /**
   * Registrace může být autorizována pouze datovou schránkou
   */
  dataBoxAuthorizationOnly?: boolean;

  /**
   * Spisová služba CENIA
   */
  ceniaDetails?: CeniaDetails[];

  /*
   * Detaily dvoufaktorové autentizace
   */
  twoFactorDetails?: TwoFactorDetails;
}

/**
 * Registrace fyzické osoby
 */
export interface NaturalPersonRegistration extends SubjectRegistration {
  /**
   * Číslo OP nebo pasu
   */
  idNumber?: PersonalData;

  /**
   * Typ subjektu
   */
  type?: SubjectRegistrationType.NATURAL_PERSON_REGISTRATION;
}

/**
 * Registrace fyzické osoby podnikající
 */
export interface BusinessNaturalPersonRegistration extends SubjectRegistration {
  /**
   * IČO
   */
  ico?: PersonalData;

  /**
   * Typ subjektu
   */
  type?: SubjectRegistrationType.BUSINESS_NATURAL_PERSON_REGISTRATION;
}

/**
 * Registrace zahraniční právnické osoby
 */
export interface ForeignLegalEntityRegistration extends SubjectRegistration {
  /**
   * Název
   */
  name?: string;

  /**
   * Zahraniční identifikátor ( obdoba IČO )
   */
  foreignId?: string;

  /**
   * Zahraniční adresa (mimo adresy RÚIAN)
   */
  residence?: Address;

  /**
   * Čestné prohlášení
   */
  affidavit?: ArchivedFile;

  /**
   * Plná moc
   */
  letterOfAttorney?: ArchivedFile;

  /**
   * Výpis z živnostenského nebo obchodního rejstříku (nebo podobný dokument)
   */
  registerExtract?: ArchivedFile;

  /**
   * Typ subjektu
   */
  type?: SubjectRegistrationType.FOREIGN_LEGAL_ENTITY_REGISTRATION;
}

/**
 * Registrace zahraniční právnické osoby
 */
export interface ForeignNaturalPersonRegistration extends SubjectRegistration {
  /**
   * Jméno
   */
  firstName?: PersonalData;

  /**
   * Příjmení
   */
  lastName?: PersonalData;

  /**
   * Zahraniční adresa (mimo adresy RÚIAN)
   */
  residence?: Address;

  /**
   * Čestné prohlášení
   */
  affidavit?: ArchivedFile;

  /**
   * Plná moc
   */
  letterOfAttorney?: ArchivedFile;

  /**
   * Kópia dokladu totožnosti
   */
  identityDocumentCopy?: ArchivedFile;

  /**
   * Typ subjektu
   */
  type?: SubjectRegistrationType.FOREIGN_NATURAL_PERSON_REGISTRATION;
}

/**
 * Registrace právnické osoby
 */
export interface LegalEntityRegistration extends SubjectRegistration {
  /**
   * IČO
   */
  ico?: string;

  /**
   * Typ subjektu
   */
  type?: SubjectRegistrationType.LEGAL_ENTITY_REGISTRATION;
}

/**
 * Registrace právnické osoby bez IČO
 */
export interface LegalEntityWithoutIcoRegistration extends SubjectRegistration {
  /**
   * Název
   */
  name?: string;

  /**
   * Sídlo (adresa RÚIAN)
   */
  residence?: Address;

  /**
   * Plná moc
   */
  letterOfAttorney?: ArchivedFile;

  /**
   * Výpis z živnostenského nebo obchodního rejstříku (nebo podobný dokument)
   */
  registerExtract?: ArchivedFile;

  /**
   * Typ subjektu
   */
  type?: SubjectRegistrationType.LEGAL_ENTITY_WITHOUT_ICO_REGISTRATION;
}

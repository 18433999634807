import { DictionaryEPrtrActivityAutocomplete } from '../../models';
import {
  useAutocompleteSource,
  ApiFilterOperation,
  abortableFetch,
  Filter,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useEPrtrActivities(filter?: Filter | null) {
  const params = filter
    ? {
        filters: [filter],
      }
    : undefined;

  return useAutocompleteSource<DictionaryEPrtrActivityAutocomplete>({
    url: EvidenceAPI.DICTIONARY_EPRTR_ACTIVITIES + '/autocomplete',
    params,
  });
}

export async function getEPrtrActivity(
  ippcActivityId: string
): Promise<DictionaryEPrtrActivityAutocomplete[]> {
  const response = abortableFetch(
    `${EvidenceAPI.DICTIONARY_EPRTR_ACTIVITIES}/autocomplete`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        filters: ippcActivityId
          ? [
              {
                field: 'ippcActivities.id',
                operation: ApiFilterOperation.EQ,
                value: ippcActivityId,
              },
            ]
          : [],
        size: -1,
      }),
    }
  );

  const json = await response.json();

  return json?.items;
}

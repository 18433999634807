import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { EvidenceAPI, PageKey, EntityName } from '../../../enums';
import { useDatedEvidence } from '../../../components/evidence/dated-evidence/dated-evidence';
import { ScheduleRun } from '../../../models';
import { useMemo } from 'react';
import { useColumns } from './schedule-runs-columns';
import { ScheduleRunsFields } from './schedule-runs-fields';
import { ScheduleRunsContext } from './schedule-runs-context';
import { usePermission } from '../../../components/permission/perm-evidence-hook';
import { DetailEmptyActionbar } from '../../../components/evidence/evidence/detail-empty-actionbar';

export function ScheduleRuns() {
  const permissions = usePermission({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useDatedEvidence<ScheduleRun>({
    identifier: PageKey.SCHEDULE_RUNS,
    apiProps: {
      url: EvidenceAPI.SCHEDULE_RUNS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Běhy pravidelně spouštěných úloh',
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.SCHEDULE_RUN,
      FieldsComponent: ScheduleRunsFields,
      toolbarProps: {
        title: 'Běh úlohy',
        ActionBar: DetailEmptyActionbar,
        showBtn: permissions,
      },
    },
  });

  const context: ScheduleRunsContext = useMemo(
    () => ({
      jobsUrl: EvidenceAPI.SCHEDULE_JOBS,
    }),
    []
  );

  return (
    <ScheduleRunsContext.Provider value={context}>
      <Evidence2 {...evidence} />
    </ScheduleRunsContext.Provider>
  );
}

import * as Yup from 'yup';
import { ExportAccessRule } from '../../../models/dict-export';
import { ExportTemplate } from '@eas/common-web';
import { Role } from '../../../models';

export function useValidationSchema() {
  return Yup.object<ExportAccessRule>().shape({
    template: Yup.object<ExportTemplate>().nullable().required('Povinné pole'),
    allowedRoles: Yup.array<Role>().nullable().required('Povinné pole'),
  });
}

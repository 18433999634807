import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import { Table, TableHandle } from '@eas/common-web';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { useColumns as useIrzFacilityColumns } from '../irz-facility/irz-facilities-columns';
import { useColumns as useOvzFacilityColumns } from '../ovz-facility/ovz-facilities-columns';
import { useColumns as useProductionSiteColumns } from '../production-site/production-sites-columns';
import { OverviewMapContext } from './overview-map-provider';
import { Area } from './overview-map-types';

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: '100%',
  },
  tab: {
    width: '100%',
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export function Tables({ height }: { height: number }) {
  const classes = useStyles();

  const {
    irzFacilitySource,
    ovzFacilitySource,
    productionSiteSource,
    canSeeIrzFacilities,
    canSeeOvzFacilities,
    canSeeProductionSites,
  } = useContext(OverviewMapContext);

  const [view, setView] = useState<Area>(
    canSeeIrzFacilities
      ? Area.IRZ_FACILITY
      : canSeeOvzFacilities
      ? Area.OVZ_FACILITY
      : Area.PRODUCTION_SITE
  );

  const irzTableRef = useRef<TableHandle<any>>(null);
  const irzFacilityColumns = useIrzFacilityColumns();

  const ovzTableRef = useRef<TableHandle<any>>(null);
  const ovzFacilityColumns = useOvzFacilityColumns();

  const productionSiteTableRef = useRef<TableHandle<any>>(null);
  const productionSiteColumns = useProductionSiteColumns(false);

  const Tabs = () => (
    <>
      {[canSeeIrzFacilities, canSeeOvzFacilities, canSeeProductionSites].filter(
        (p) => p === true
      ).length > 1 && (
        <ButtonGroup
          className={classes.tabs}
          variant="contained"
          color="primary"
        >
          {canSeeIrzFacilities && (
            <Button
              className={clsx(classes.tab, {
                [classes.selectedTab]: view === Area.IRZ_FACILITY,
              })}
              onClick={() => setView(Area.IRZ_FACILITY)}
              color="primary"
            >
              IRZ provozovny
            </Button>
          )}
          {canSeeOvzFacilities && (
            <Button
              className={clsx(classes.tab, {
                [classes.selectedTab]: view === Area.OVZ_FACILITY,
              })}
              onClick={() => setView(Area.OVZ_FACILITY)}
              color="primary"
            >
              OVZ provozovny
            </Button>
          )}
          {canSeeProductionSites && (
            <Button
              className={clsx(classes.tab, {
                [classes.selectedTab]: view === Area.PRODUCTION_SITE,
              })}
              onClick={() => setView(Area.PRODUCTION_SITE)}
            >
              Průmyslová místa
            </Button>
          )}
        </ButtonGroup>
      )}
    </>
  );

  return (
    <>
      {view === Area.IRZ_FACILITY && canSeeIrzFacilities && (
        <Table
          tableId="IRZ_FACILITY_OVEERVIEW_TABLE"
          version={1}
          tableName="IRZ provozovny"
          ref={irzTableRef}
          columns={irzFacilityColumns}
          source={irzFacilitySource}
          height={height}
          SearchbarComponent={Tabs}
          showReportButton={false}
          showBulkActionButton={false}
        />
      )}
      {view === Area.OVZ_FACILITY && canSeeOvzFacilities && (
        <Table
          tableId="OVZ_FACILITY_OVEERVIEW_TABLE"
          version={1}
          tableName="OVZ provozovny"
          ref={ovzTableRef}
          columns={ovzFacilityColumns}
          source={ovzFacilitySource}
          height={height}
          SearchbarComponent={Tabs}
          showReportButton={false}
          showBulkActionButton={false}
        />
      )}
      {view === Area.PRODUCTION_SITE && canSeeProductionSites && (
        <Table
          tableId="PM_FACILITY_OVEERVIEW_TABLE"
          version={1}
          tableName="Průmyslová místa"
          ref={productionSiteTableRef}
          columns={productionSiteColumns}
          source={productionSiteSource}
          height={height}
          SearchbarComponent={Tabs}
          showReportButton={false}
          showBulkActionButton={false}
        />
      )}
    </>
  );
}

import {
  DialogHandle,
  FormHandle,
  PromptContext,
  SnackbarContext,
  SnackbarVariant,
  useEventCallback,
  usePrompts,
  UserContext,
  UserSettingsContext,
} from '@eas/common-web';
import { noop, set } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import * as Yup from 'yup';
import { ConsentSnackbarContext } from '../../components/consent-snackbar/consent-snackbar-context';
import { preprocessPhoneNumber } from '../../components/form/phone-field/phone-field-utils';
import { Messages } from '../../enums';
import { PersonalData, PreferredChannelType } from '../../models';
import { useAbortableFetch } from '../../utils/abortable-fetch';
import { getErrorMessage } from '../../utils/get-message';
import {
  fetchNotificationPreferences,
  fetchProfile,
  subscribeToEvent,
  updateProfile,
} from './profile-api';
import { ProfileForm } from './profile-types';
import {
  validateEmail,
  validatePersonalDataProcessingConsent,
  validatePhone,
} from './profile-utils';

const UPDATE_EMAIL_KEY = 'UPDATE_EMAIL_KEY';

export function useProfile() {
  const { showSnackbar } = useContext(SnackbarContext);
  const { user } = useContext(UserContext);
  const { clear } = useContext(UserSettingsContext);
  const { testPrompt } = useContext(PromptContext);

  const refContactInfo = useRef<
    FormHandle<Omit<ProfileForm, 'twoFactorDetails'>>
  >(null);
  const refSecondFactorInfo = useRef<
    FormHandle<Pick<ProfileForm, 'twoFactorDetails'>>
  >(null);
  const refLoginForm = useRef<FormHandle>(null);
  const pwdChangeDialog = useRef<DialogHandle>(null);
  const [profile, setProfile] = useState<ProfileForm | undefined>(undefined);
  const [notificationPreferences, setNotificationPreferences] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [contactInfoEditing, setContactInfoEditing] = useState(false);
  const [secondFactorInfoEditing, setSecondFactorInfoEditing] = useState(false);

  usePrompts(
    [
      {
        key: UPDATE_EMAIL_KEY,
        dialogTitle: 'Změna e-mailu',
        dialogText:
          'Byl nastaven nový e-mail, který je nutné ověřit. Na nově zadanou e-mailovou adresu byl zaslán potvrzovací e-mail s odkazem na jeho ověření.',
        dialogWidth: 600,
        dialogShowClose: false,
      },
    ],
    []
  );

  const { setConsentIsGranted } = useContext(ConsentSnackbarContext);

  const contactInfoValidationSchema = Yup.object<ProfileForm>().shape({
    email: Yup.object<PersonalData>()
      .required('Povinné pole')
      .shape({
        value: validateEmail({ requiredMessage: 'Email musí být vyplněný' }),
      }),
    phoneNumber: validatePhone,
    personalDataProcessingConsent: validatePersonalDataProcessingConsent,
  }) as Yup.Schema<ProfileForm>;

  const secondFactorInfoValidationSchema = Yup.object<ProfileForm>().shape({
    twoFactorDetails: Yup.object<PersonalData>().shape({
      email: Yup.object().shape({
        value: validateEmail({ requiredMessage: 'Email musí být vyplněný' }),
      }),
      phoneNumber: validatePhone,
      preferredChannel: Yup.mixed<PreferredChannelType>()
        .required('Vyberte jednu z možností')
        .oneOf(
          [...Object.values(PreferredChannelType)],
          'Vyberte jednu z možností'
        ),
    }),
    personalDataProcessingConsent: validatePersonalDataProcessingConsent,
  }) as Yup.Schema<ProfileForm>;

  const getProfile = useAbortableFetch(async (fetch) => {
    try {
      setLoading(true);

      if (!user.id) {
        return;
      }

      fetch = fetchProfile(user.id);
      const profile: ProfileForm = await fetch.json();

      fetch = fetchNotificationPreferences();
      const notificationPreferences: {
        unsubscribedEvents: string[];
      } = await fetch.json();

      unstable_batchedUpdates(() => {
        setProfile({
          ...profile,
        });
        setNotificationPreferences(notificationPreferences.unsubscribedEvents);
        setLoading(false);
      });

      return profile;
    } catch (err) {
      setLoading(false);

      showSnackbar(...Messages.Profile.GET.ERROR);

      throw err;
    }
  });

  const updateContactInfo = useAbortableFetch(async (fetch) => {
    try {
      setLoading(true);

      if (!user.id || !refContactInfo.current) {
        return;
      }

      const values = refContactInfo.current.getFieldValues();

      fetch = updateProfile(user.id, values);

      const data: ProfileForm = await fetch.json();

      unstable_batchedUpdates(() => {
        showSnackbar(...Messages.Profile.UPDATE.SUCCESS);
        setLoading(false);
        setConsentIsGranted(!!data?.personalDataProcessingConsent);

        if (values?.email?.value !== profile?.email?.value) {
          testPrompt({
            key: UPDATE_EMAIL_KEY,
            submitCallback: noop,
          });
        }

        setProfile(data);
      });

      return data;
    } catch (err) {
      setLoading(false);

      if (err.name !== 'AbortError') {
        const message = getErrorMessage(Messages.User.UPDATE, err.code);

        showSnackbar(...message);
        throw err;
      }
    }
  });

  const updateSecondFactorInfo = useAbortableFetch(async (fetch) => {
    try {
      setLoading(true);

      if (!user.id || !refSecondFactorInfo.current) {
        return;
      }

      const values = refSecondFactorInfo?.current?.getFieldValues();

      if (!values?.twoFactorDetails?.phoneNumber?.prefix) {
        set(values, 'twoFactorDetails.phoneNumber.prefix', null);
      }
      if (!values?.twoFactorDetails?.phoneNumber?.number?.value) {
        set(values, 'twoFactorDetails.phoneNumber.number', null);
      }

      const preprocessedValues = preprocessPhoneNumber(
        values,
        'twoFactorDetails.phoneNumber'
      );

      fetch = updateProfile(user.id, preprocessedValues);

      const data: ProfileForm = await fetch.json();

      unstable_batchedUpdates(() => {
        showSnackbar(...Messages.Profile.UPDATE.SUCCESS);
        setLoading(false);
        setConsentIsGranted(!!data?.personalDataProcessingConsent);

        if (
          values?.twoFactorDetails?.email?.value !==
          profile?.twoFactorDetails?.email?.value
        ) {
          testPrompt({
            key: UPDATE_EMAIL_KEY,
            submitCallback: noop,
          });
        }

        setProfile(data);
      });

      return data;
    } catch (err) {
      setLoading(false);

      if (err.name !== 'AbortError') {
        const invalidPhone =
          err?.details && Array.isArray(err?.details)
            ? err?.details?.find((detail: { field: string }) =>
                detail?.field?.includes('phoneNumber')
              )
            : false;

        const message: [string, SnackbarVariant] = invalidPhone
          ? [
              'Chyba při editaci údajů: Nejprve je nutné doplnit hlavní kontaktní telefon (sekce Kontaktní údaje). Následně bude možné editovat údaje pro dvoufaktorové ověření.',
              SnackbarVariant.ERROR,
            ]
          : getErrorMessage(Messages.User.UPDATE, err.code);

        showSnackbar(...message);

        throw err;
      }
    }
  });

  const handleStartContactInfoEditing = useEventCallback(() => {
    setContactInfoEditing(true);
  });

  const handleStartSecondFactorInfoEditing = useEventCallback(() => {
    if (!profile?.twoFactorDetails?.phoneNumber?.prefix) {
      refContactInfo.current?.setFieldValue(
        'twoFactorDetails.phoneNumber.prefix',
        '+420'
      );
    }
    setSecondFactorInfoEditing(true);
  });

  const handleCancelContactInfoEditing = useEventCallback(() => {
    setContactInfoEditing(false);
    refContactInfo.current?.setFieldValues(profile!);
    refContactInfo.current?.resetValidation();
  });

  const handleCancelSecondFactorInfoEditing = useEventCallback(() => {
    setSecondFactorInfoEditing(false);
    refSecondFactorInfo.current?.setFieldValues(profile!);
    refSecondFactorInfo.current?.resetValidation();
  });

  const handleUserSettingsClear = useEventCallback(async () => {
    try {
      setLoading(true);
      await clear();
      showSnackbar(...Messages.Profile.CLEAR_USER_SETTINGS.SUCCESS);
    } finally {
      setLoading(false);
    }
  });

  const handleSubscribeToEvent = useEventCallback(async (event: string) => {
    try {
      setLoading(true);
      const response = await subscribeToEvent(event).json();

      await getProfile?.();

      const state: 'SUBSCRIBED' | 'ALREADY_SUBSCRIBED' =
        response.state ?? 'SUBSCRIBED';
      showSnackbar(...Messages.Notification.SUBSCRIBE[state]);
    } catch (err) {
      showSnackbar(...Messages.Notification.SUBSCRIBE.ERROR);
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    const get = async () => {
      const response = await getProfile?.();
      refContactInfo.current?.setFieldValues(response!);
      refSecondFactorInfo.current?.setFieldValues(response!);
      refLoginForm.current?.setFieldValues(response!);
    };

    if (user !== undefined && user.id !== null) {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitContactInfo = useEventCallback(async () => {
    if (refContactInfo.current != undefined) {
      const errors = await refContactInfo.current.validateForm();

      if (errors.length > 0) {
        showSnackbar(...Messages.Profile.UPDATE.ERROR_FORM);
        setLoading(false);
        return;
      }

      const profile = await updateContactInfo?.();
      refContactInfo.current.setFieldValues(profile!);
      refSecondFactorInfo.current?.setFieldValues(profile!);
      setContactInfoEditing(false);
    }
  });

  const handleSubmitSecondarFactorInfo = useEventCallback(async () => {
    if (refSecondFactorInfo.current != undefined) {
      const errors = await refSecondFactorInfo.current.validateForm();

      if (errors.length > 0) {
        showSnackbar(...Messages.Profile.UPDATE.ERROR_FORM);
        setLoading(false);
        return;
      }

      const profile = await updateSecondFactorInfo?.();
      refContactInfo.current?.setFieldValues(profile!);
      refSecondFactorInfo.current?.setFieldValues(profile!);
      setSecondFactorInfoEditing(false);
    }
  });

  const handlePwdChange = useEventCallback(() => {
    pwdChangeDialog.current?.open();
  });

  return {
    notificationPreferences,
    loading,
    contactInfoEditing,
    secondFactorInfoEditing,
    refContactInfo,
    refSecondFactorInfo,
    refLoginForm,
    getProfile,
    handleSubmitContactInfo,
    handleSubmitSecondarFactorInfo,
    handleStartContactInfoEditing,
    handleStartSecondFactorInfoEditing,
    handleCancelContactInfoEditing,
    handleCancelSecondFactorInfoEditing,
    handleUserSettingsClear,
    handlePwdChange,
    handleSubscribeToEvent,
    pwdChangeDialog,
    contactInfoValidationSchema,
    secondFactorInfoValidationSchema,
    profile,
  };
}

import { DictionaryDecisionTypeAutocomplete } from '../../models';
import {
  useAutocompleteSource,
  useListSource,
  DictionaryAutocomplete,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useDictionaryDecisionTypes() {
  return useAutocompleteSource<DictionaryDecisionTypeAutocomplete>({
    url: EvidenceAPI.DICTIONARY_DECISION_TYPES + '/autocomplete',
  });
}

export function useDecisionTypeGroups() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.DICTIONARY_DECISION_TYPES}/group/list`,
    method: 'GET',
  });
}

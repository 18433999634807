import { post } from '../../utils/custom-crud';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import {
  ResultDto,
  ApiFilterOperation,
  useListSource,
  DictionaryAutocomplete,
} from '@eas/common-web';
import { SoapExecutionLog, WebService } from '../../models';
import { EvidenceAPI } from '../../enums';

export async function combineGetCalls({ signal, mainItem }: CombineCallsType) {
  const json = {
    ...mainItem,
  };

  const [wsExecutions] = await post<ResultDto<SoapExecutionLog>>({
    url: `${EvidenceAPI.SOAP_EXECUTIONS}/list`,
    signal,
    body: {
      filters: [
        {
          field: 'service.id',
          operation: ApiFilterOperation.EQ,
          value: (mainItem as WebService).id,
        },
      ],
      sort: [{ field: 'created', order: 'DESC', type: 'FIELD' }],
      size: 100,
    },
  });

  if (wsExecutions?.items?.length) {
    json['wsExecutions'] = wsExecutions?.items;
  }

  const text = JSON.stringify(json);

  return {
    data: json,
    text,
  };
}

export function useWebServices() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.WEB_SERVICES}/service/list`,
    method: 'GET',
  });
}

export function useWebOperations() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.WEB_SERVICES}/operation/list`,
    method: 'GET',
  });
}

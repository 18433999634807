import React, { useState, useRef } from 'react';

import { useEffect, useContext } from 'react';

import {
  FormAutocomplete,
  FormContext,
  DomainObject,
  AutocompleteProps,
  FormFieldProps,
  useEventCallback,
  AutocompleteSource,
} from '@eas/common-web';

export interface PreFilledAutocompleteProps<OPTION extends DomainObject>
  extends FormFieldProps<AutocompleteProps<OPTION>> {
  preFilledHelpLabel?: string;
}
export function FormPreFilledAutocomplete<OPTION extends DomainObject>({
  source,
  name,
  disabled,
  helpLabel: defaultHelpLabel,
  preFilledHelpLabel = defaultHelpLabel,
  ...props
}: PreFilledAutocompleteProps<OPTION>) {
  const { setFieldValue } = useContext(FormContext);

  const [isPreFilled, setPreFilled] = useState(false);
  const helpLabel = isPreFilled ? preFilledHelpLabel : defaultHelpLabel;

  const sourceRef = useRef<AutocompleteSource<OPTION>>(source);

  const preFill = useEventCallback(async () => {
    await sourceRef.current.loadMore();

    if (sourceRef.current.count === 1) {
      if (props.multiple) {
        setFieldValue(name, [sourceRef.current.items[0]]);
      } else {
        const option = await source.loadDetail(sourceRef.current.items[0]);
        setFieldValue(name, option);
      }
      setPreFilled(true);
    }
  });

  useEffect(() => {
    preFill();
  }, []);

  return (
    <FormAutocomplete
      name={name}
      source={source}
      helpLabel={helpLabel}
      disabled={isPreFilled}
      {...props}
    />
  );
}

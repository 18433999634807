import React, { useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {
  TableFieldToolbarButton,
  FormContext,
  useFormSelector,
  useEventCallback,
} from '@eas/common-web';
import {
  EmpowermentSpecificationApprovalState,
  EmpowermentSpecificationRequest,
  SpecifiedEmpowermentRequest,
} from '../../models';

const useStyles = makeStyles(() =>
  createStyles({
    tableActions: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      height: 30,
      width: '100%',
      boxSizing: 'border-box',
      verticalAlign: 'top',
      display: 'flex',
      padding: '0 12px',
      '& svg': {
        cursor: 'pointer',
        width: 20,
        height: 20,
        padding: '2pt',
      },
    },
    buttonGroup: {
      display: 'flex',
      marginLeft: -5, // offset first icon left padding
    },
  })
);

export function EmpowermentSpecificationToolbarFactory(
  state: EmpowermentSpecificationApprovalState
) {
  return function EmpowermentSpecificationToolbar() {
    const classes = useStyles();
    const { setFieldValue } = useContext(FormContext);

    const { specificationRequests } = useFormSelector(
      (data: SpecifiedEmpowermentRequest) => ({
        specificationRequests: data.specificationRequests,
      })
    );

    const handleCheckAll = useEventCallback(() => {
      const editedRequests = specificationRequests.map(
        (item: EmpowermentSpecificationRequest) => ({
          ...item,
          approvalState: state,
        })
      );
      setFieldValue('specificationRequests', editedRequests);
    });

    const handleUncheckAll = useEventCallback(() => {
      const editedRequests = specificationRequests.map(
        (item: EmpowermentSpecificationRequest) => ({
          ...item,
          approvalState: EmpowermentSpecificationApprovalState.NONE,
        })
      );
      setFieldValue('specificationRequests', editedRequests);
    });

    return (
      <div className={classes.tableActions}>
        <ButtonGroup
          size="small"
          variant="outlined"
          className={classes.buttonGroup}
        >
          <TableFieldToolbarButton
            color="primary"
            title="Označit vše"
            IconComponent={DoneIcon}
            onClick={handleCheckAll}
            show={true}
            disabled={false}
          />
          <TableFieldToolbarButton
            color="primary"
            title="Odznačit vše"
            IconComponent={CloseIcon}
            onClick={handleUncheckAll}
            show={true}
            disabled={false}
          />
        </ButtonGroup>
      </div>
    );
  };
}

import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: 0,
  },
  item: {
    paddingTop: '2pt',
    paddingBottom: '2pt',
    height: 45,
  },
  adornment: {
    right: 24,
    position: 'absolute',
  },
  paper: {
    borderRadius: '0',
  },
}));

import React, { useMemo, useContext } from 'react';
import {
  TableColumn,
  TableCells,
  TableFilterCells,
  TableFieldColumn,
  InlineTableFieldCells,
  DictionaryAutocomplete,
  ApiFilterOperation,
  TableFieldCells,
  useEventCallback,
  FormContext,
  FilterComponentProps,
  useStaticListSource,
  TableContext,
  Select,
} from '@eas/common-web';
import {
  IrzFacility,
  ActivityRecord,
  DictionaryCompetentControlAuthority,
} from '../../models';
import { useCzRiverBasins } from '../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { useIrzActivities } from '../dict-irz-activity/dict-irz-activities-api';
import { useEPrtrActivities } from '../dict-e-prtr-activity/dict-e-prtr-activities-api';
import { coordinateValueMapper } from '../../composite/facility-column-mappers/coordinate-mapper';
import { booleanValueMapper } from '../../composite/facility-column-mappers/boolean-mapper';
import { ippcPidsValueMapper } from '../../composite/irz-facility-column-mappers/ippc-pids-mapper';
import { emissionsTradingValueMapper } from '../../composite/irz-facility-column-mappers/emissions-trading-mapper';
// import { idValueMapperFactory } from '../../composite/subject-column-mappers/id-mapper';
import { useCompetentControlAuthorities } from '../dict-competent-control-authority/dict-competent-control-authorities-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { addressValueMapperFactory } from '../../composite/subject-column-mappers/address-mapper';
import { useNaceItems } from '../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../dict-nace-cz-item/dict-nace-cz-items-api';
import { useRegions } from '../production-site/production-sites-api';
import { useEuStates } from './irz-facilities-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { EuStateRecord } from '../../models/irz-facility';
import {
  useIppcActivities,
  getIppcActivity,
} from '../dict-ippc-activity/dict-ippc-activities-api';
import {
  AddressPlaceFilterCell,
  IsuiCodeFilterCell,
} from '../ovz-facility/ovz-facilities-columns';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';

function EPRTRFacilityFilterCell({
  filter,
  state,
  onChangeState,
  ...rest
}: FilterComponentProps) {
  const intl = useIntl();
  const { classes } = useContext(TableContext);

  const operations = useStaticListSource([
    {
      id: 'NOT_NULL',
      name: intl.formatMessage({
        id: 'CRZP_EPRTR_FACILITY_FILTER_CELL_OPTION_NOT_NULL',
        defaultMessage: 'Vyplněné',
      }),
    },
    {
      id: 'IS_NULL',
      name: intl.formatMessage({
        id: 'CRZP_EPRTR_FACILITY_FILTER_CELL_OPTION_NULL',
        defaultMessage: 'Nevyplněné',
      }),
    },
    {
      id: 'EQ',
      name: intl.formatMessage({
        id: 'CRZP_EPRTR_FACILITY_FILTER_CELL_OPTION_VALUE',
        defaultMessage: 'Číselník',
      }),
    },
  ]);

  const FilterAutocompleteCell = TableFilterCells.useFilterAutocompleteCellFactory(
    useEPrtrActivities,
    dictionaryLabelMapper
  );

  const handleOperationChange = useEventCallback(
    (operation: ApiFilterOperation) => {
      onChangeState({
        ...state,
        operation: operation,
        value: operation !== ApiFilterOperation.EQ ? null : state.value,
        object: operation !== ApiFilterOperation.EQ ? null : state.object,
      });
    }
  );

  return (
    <>
      <div className={classes.filterDialogItemSubWrapper}>
        <Typography>Operace</Typography>
        <div className={classes.filterDialogItemValueWrapper}>
          <Select
            source={operations}
            value={state.operation}
            onChange={handleOperationChange as any}
            valueIsId={true}
          />
        </div>
      </div>
      {state.operation === ApiFilterOperation.EQ && (
        <div className={classes.filterDialogItemSubWrapper}>
          <FilterAutocompleteCell
            {...rest}
            filter={filter}
            state={state}
            disabled={false}
            onChangeState={onChangeState}
          />
        </div>
      )}
    </>
  );
}

export function useColumns(): TableColumn<IrzFacility>[] {
  return [
    {
      datakey: 'name',
      name: 'Název provozovny',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'facilityId',
      name: 'IČP',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'operator.name',
      name: 'Provozovatel - Název',
      width: 300,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'operator.identifier',
      name: 'Provozovatel - IČO / UID',
      width: 300,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'operator.identityDocumentsConcatenated',
      name: 'Provozovatel - OP / pas',
      width: 300,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'integratedPermission.ippcPids',
      name: 'PID (IPPC)',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      filterable: true,
      sortable: true,
      filterGroup: 6,
      valueMapper: ippcPidsValueMapper,
    },
    {
      datakey: 'integratedPermission.hasPermission',
      name: 'Integrované povolení',
      width: 200,
      CellComponent: TableCells.BooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: false,
      visible: false,
      hidden: true,
      filterGroup: 6,
    },
    {
      datakey: 'emissionsTradingSystem.ids',
      name: 'ID zařízení (EU ETS)',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      filterable: true,
      sortable: true,
      filterGroup: 6,
      valueMapper: emissionsTradingValueMapper,
    },
    {
      datakey: 'emissionsTradingSystem.tradesInEmissions',
      name: 'Obchodování s emisemi',
      width: 200,
      CellComponent: TableCells.BooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: false,
      visible: false,
      hidden: true,
      filterGroup: 6,
    },
    {
      datakey: 'address.municipality',
      sortkey: 'address.municipality.name',
      filterkey: 'address.municipality.name',
      name: 'Obec',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.cityDistrict',
      sortkey: 'address.cityDistrict.name',
      filterkey: 'address.cityDistrict.name',
      name: 'Městská část',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.street',
      sortkey: 'address.street.name',
      filterkey: 'address.street.name',
      name: 'Ulice',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.houseNumber',
      name: 'Číslo popisné',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.orientationNumber',
      name: 'Číslo orientační',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.region',
      sortkey: 'address.region.name',
      filterkey: 'address.region.id',
      name: 'Kraj',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useRegions
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'address.addressPlace.active',
      filterkey: 'address.addressPlace.active',
      name: 'Adresní místo',
      width: 200,
      CellComponent: TableCells.BooleanCell,
      FilterComponent: AddressPlaceFilterCell,
      filterable: true,
      visible: false,
      hidden: true,
      filterGroup: 3,
    },
    {
      datakey: 'address.addressPlaceRuian.isuiCode',
      sortkey: 'address.addressPlace.isuiCode',
      filterkey: 'address.addressPlace.isuiCode',
      name: 'Kód adresního místa',
      width: 200,
      CellComponent: TableCells.NumberCell,
      FilterComponent: IsuiCodeFilterCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'wgs84Coordinates.lat',
      name: 'Zeměpisná šířka',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: false,
      filterGroup: 3,
      valueMapper: coordinateValueMapper,
    },
    {
      datakey: 'wgs84Coordinates.lon',
      name: 'Zeměpisná délka',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: false,
      filterGroup: 3,
      valueMapper: coordinateValueMapper,
    },
    {
      datakey: 'mainEprtrActivity.activity.name',
      filterkey: 'mainEprtrActivity.activity.id',
      name: 'Hlavní E-PRTR činnost',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: EPRTRFacilityFilterCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'mainIrzActivity.activity.name',
      filterkey: 'mainIrzActivity.activity.id',
      name: 'Hl. činnost dle přílohy zákona o IRZ',
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useIrzActivities,
        dictionaryLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      width: 200,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'czRiverBasinDistrict',
      filterkey: 'czRiverBasinDistrict.id',
      sortkey: 'czRiverBasinDistrict.name',
      name: 'Oblast povodí ČR',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useCzRiverBasins
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'nace',
      filterkey: 'nace.id',
      sortkey: 'nace.name',
      name: 'NACE',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useNaceItems
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'czNace',
      filterkey: 'czNace.id',
      sortkey: 'czNace.name',
      name: 'CZ-NACE',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useNaceCzItems,
        dictionaryLabelMapper
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'productionSite.id',
      name: 'Má průmyslové místo',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterNullableBooleanCell,
      valueMapper: booleanValueMapper,
      sortable: true,
      filterable: true,
      filterGroup: 5,
    },
    {
      datakey: 'productionSite.uniqueCode',
      name: 'Průmyslové místo - Identifikátor',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 5,
    },
    {
      datakey: 'productionSite.name',
      name: 'Průmyslové místo - Název',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 5,
    },
    {
      datakey: 'lastEuState.euState',
      filterkey: 'lastEuStateRecord.euState.id',
      sortkey: 'lastEuStateRecord.euState.name',
      name: 'Stav provozovny',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useEuStates),
      valueMapper: TableCells.useSelectCellFactory(useEuStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'registrationDate',
      name: 'Datum registrace',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

export function useSecondaryIrzActivityColumns(
  dataKey?: string
): TableFieldColumn<ActivityRecord>[] {
  const CountCell = InlineTableFieldCells.useInlineNumberFieldFactory<
    ActivityRecord
  >({
    collectionDatakey: dataKey ?? 'secondaryIrzActivities',
  });

  const ActivityCell = InlineTableFieldCells.useInlineAutocompleteCellFactory<
    ActivityRecord,
    DictionaryAutocomplete
  >({
    dataHook: useIrzActivities,
    collectionDatakey: dataKey ?? 'secondaryIrzActivities',
    labelMapper: dictionaryLabelMapper,
  });

  return useMemo(
    () => [
      {
        name: 'Vedlejší činnost dle zákona o IRZ a ISPOP',
        datakey: 'activity',
        width: 400,
        CellComponent: ActivityCell,
      },
      {
        name: 'Počet zařízení',
        datakey: 'facilityCount',
        width: 200,
        CellComponent: CountCell,
      },
    ],
    []
  );
}

export function useTradesInEmissionsColumns(
  dataKey?: string
): TableFieldColumn<string>[] {
  const IdentifierCell = InlineTableFieldCells.useInlineTextFieldFactory<
    string
  >({
    collectionDatakey: dataKey ?? 'emissionsTradingSystem.ids',
    isSubkey: false,
  });
  return useMemo(
    () => [
      {
        name: 'Identifikátor',
        datakey: 'ids',
        width: 400,
        CellComponent: IdentifierCell,
      },
    ],
    [IdentifierCell]
  );
}

export function useSecondaryEPrtrActivityColumns(
  dataKey?: string
): TableFieldColumn<ActivityRecord>[] {
  const { getFieldValues, setFieldValue } = useContext(FormContext);

  const handleActivityChange = useEventCallback(async (index) => {
    const activity = getFieldValues()?.[
      dataKey ?? 'secondaryEprtrActivities'
    ]?.[index].activity;

    if (activity) {
      const wiredIppcActivities = await getIppcActivity(activity?.id);
      if (wiredIppcActivities.length === 1) {
        setFieldValue(
          `${dataKey ?? 'secondaryEprtrActivities'}[${index}].ippcActivity`,
          wiredIppcActivities[0]
        );
      }
    } else {
      setFieldValue(
        `${dataKey ?? 'secondaryEprtrActivities'}[${index}].ippcActivity`,
        undefined
      );
    }
  });

  const CountCell = InlineTableFieldCells.useInlineNumberFieldFactory<
    ActivityRecord
  >({
    collectionDatakey: dataKey ?? 'secondaryEprtrActivities',
  });

  const useActivityCell = (notifyChange: () => void) =>
    InlineTableFieldCells.useInlineAutocompleteCellFactory<
      ActivityRecord,
      DictionaryAutocomplete
    >({
      dataHook: useEPrtrActivities,
      collectionDatakey: dataKey ?? 'secondaryEprtrActivities',
      labelMapper: dictionaryLabelMapper,
      notifyChange,
    });

  const IppcActivityCell = InlineTableFieldCells.useInlineAutocompleteCellFactory<
    ActivityRecord,
    DictionaryAutocomplete
  >({
    dataHook: useIppcActivities,
    collectionDatakey: dataKey ?? 'secondaryEprtrActivities',
    labelMapper: dictionaryLabelMapper,
  });

  return useMemo(
    () => [
      {
        name: 'Vedlejší E-PRTR činnost',
        datakey: 'activity',
        width: 400,
        CellComponent: function Cell(props) {
          const ActivityCell = useActivityCell(() =>
            handleActivityChange(props.index)
          );

          return <ActivityCell {...props} />;
        },
      },
      {
        name: 'Počet zařízení',
        datakey: 'facilityCount',
        width: 100,
        CellComponent: CountCell,
      },
      {
        name: 'Činnost IPPC',
        datakey: 'ippcActivity',
        width: 200,
        CellComponent: IppcActivityCell,
      },
    ],
    []
  );
}

export function useCompetentControlAuthoritiesColumns(
  collectionDatakey = 'competentControlAuthorities'
): TableFieldColumn<DictionaryCompetentControlAuthority>[] {
  const ChooseCell = InlineTableFieldCells.useInlineAutocompleteCellFactory<
    DictionaryCompetentControlAuthority,
    DictionaryAutocomplete
  >({
    dataHook: useCompetentControlAuthorities,
    collectionDatakey,
    isSubkey: false,
  });

  return useMemo(
    () => [
      {
        name: 'Kompetentní kontrolní / jiný orgán',
        datakey: '',
        width: 200,
        CellComponent: ChooseCell,
      },
      ...authoritiesColumns,
    ],
    [ChooseCell]
  );
}

export const authoritiesColumns = [
  {
    name: 'Název',
    datakey: 'name',
    width: 200,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'Název organizace',
    datakey: 'organizationName',
    width: 200,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'E-mail',
    datakey: 'email',
    width: 150,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'Telefonní číslo',
    datakey: 'phoneNumber',
    width: 150,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'Fax',
    datakey: 'faxNumber',
    width: 150,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'Ulice',
    datakey: 'street',
    width: 150,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'Č. p.',
    datakey: 'descriptiveNumber',
    width: 50,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'Č. o.',
    datakey: 'orientationNumber',
    width: 50,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'Obec',
    datakey: 'city',
    width: 100,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'PSČ',
    datakey: 'zip',
    width: 100,
    CellComponent: TableFieldCells.TextCell,
  },
  {
    name: 'Datová schránka',
    datakey: 'dataBox',
    width: 100,
    CellComponent: TableFieldCells.TextCell,
  },
];

export function useIppcPidColumns(): TableFieldColumn<string>[] {
  const IppcPidCell = InlineTableFieldCells.useInlineTextFieldFactory<string>({
    collectionDatakey: 'integratedPermission.ippcPids',
    isSubkey: false,
  });

  return useMemo(
    () => [
      {
        name: 'PID (IPPC)',
        datakey: '',
        width: 600,
        CellComponent: IppcPidCell,
      },
    ],
    [IppcPidCell]
  );
}

export function useEuStatesColumns(): TableFieldColumn<EuStateRecord>[] {
  return [
    {
      name: 'Rok',
      datakey: 'year',
      width: 100,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Stav',
      datakey: 'euState',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useEuStates),
    },
  ];
}

import { useEffect, useState } from 'react';
import { useEventCallback } from '@eas/common-web';

export function useMap() {
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Extra security measure if script has been already loaded.
   */
  const scriptAlreadyLoaded = useEventCallback(() => {
    return !!window.SMap;
  });

  /**
   * Interval to check, if the value is already loaded
   * Bugfix, if multiple maps were present, and window.Loader.load notified only first
   */
  const checkIfLoadedInterval = useEventCallback(() => {
    if (scriptAlreadyLoaded() && !isLoaded) {
      setIsLoaded(true);
    } else if (!scriptAlreadyLoaded()) {
      setTimeout(checkIfLoadedInterval, 100);
    }
  });

  const scriptAlreadyAppended = useEventCallback(() => {
    return document.querySelector('script#mapy-cz') !== null;
  });

  /**
   * Utility to fetch main Mapy.cz script.
   */
  const appendMapScript = useEventCallback(() => {
    const script = document.createElement('script');
    script.id = 'mapy-cz';
    script.src = 'https://api.mapy.cz/loader.js';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.onload = () => {
      window.Loader.async = true;
      window.Loader.load(null, { suggest: true }, () => setIsLoaded(true));
    };
    return script;
  });

  /**
   * Runs first time when component is mounted and adds the script into the document.
   */
  useEffect(() => {
    if (scriptAlreadyAppended() === false) {
      const script = appendMapScript();

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      checkIfLoadedInterval();
    }
  }, []);

  return {
    isLoaded,
  };
}

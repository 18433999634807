import { orderBy } from 'lodash';
import { TableSort } from '@eas/common-web';

export const ippcPidsValueMapper = function ippcPidsValueMapper({
  value,
  sorts,
}: {
  value: string[];
  sorts: TableSort[];
}) {
  const sort = sorts.find((s) => s.datakey === 'ippcPids');
  const order = sort?.order === 'ASC' ? 'asc' : 'desc';

  const sortedPids = orderBy(value, (pid) => pid, [order]);

  return sortedPids.join(', ');
};

ippcPidsValueMapper.displayName = 'ippcPidsValueMapper';

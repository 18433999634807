/**
 * DIRP permissions
 */
export enum PermissionDirp {
  /** Přehled DIRP */
  DIRP_LIST = 'DIRP_LIST',

  /** Detail DIRP */
  DIRP_DETAIL = 'DIRP_DETAIL',

  /** Vytvoření DIRP */
  DIRP_CREATE = 'DIRP_CREATE',

  /** Editace DIRP */
  DIRP_UPDATE = 'DIRP_UPDATE',

  /** Editace komentáře DIRP */
  DIRP_UPDATE_ALL = 'DIRP_UPDATE_ALL',

  /** Smazání DIRP */
  DIRP_DELETE = 'DIRP_DELETE',

  /** Zneplatnění DIRP */
  DIRP_INVALIDATE = 'DIRP_INVALIDATE',

  /** Obnovení DIRP */
  DIRP_REVALIDATE = 'DIRP_REVALIDATE',

  /** Čtení předpisů DIRP */
  DIRP_LAW_READ = 'DIRP_LAW_READ',

  /** Editace předpisů DIRP */
  DIRP_LAW_UPDATE = 'DIRP_LAW_UPDATE',

  /** Zneplatnění předpisů DIRP */
  DIRP_LAW_INVALIDATE = 'DIRP_LAW_INVALIDATE',

  /** Obnovení předpisů DIRP */
  DIRP_LAW_REVALIDATE = 'DIRP_LAW_REVALIDATE',
}

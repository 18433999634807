import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { TextFieldProps, useEventCallback } from '@eas/common-web';
import { useStyles } from './protected-phone-styles';
import { cloneDeep, set, noop } from 'lodash';
import { PhoneNumber } from '../../../models';

export function protectedPhoneFactory(
  {
    required,
    inputProps,
    InputProps,
  }: {
    required?: boolean;
    inputProps?: Record<string, unknown>;
    InputProps?: Record<string, unknown>;
  } = {
    required: false,
    inputProps: {},
    InputProps: {},
  }
) {
  return function ProtectedField({
    onChange,
    value,
    name,
    error,
    helperText,
    disabled,
    form,
  }: Pick<TextFieldProps, 'name' | 'error' | 'disabled' | 'form'> & {
    helperText?: string;
    value: PhoneNumber;
    onChange?: (value: PhoneNumber) => void;
  }) {
    const classes = useStyles();

    /**
     * phoneNumber.prefix onChange handler
     */
    const handlePrefixValue = useEventCallback((prefix: string) => {
      const newValue = cloneDeep(value ?? {});

      if (prefix?.length > 4) {
        prefix = prefix.slice(0, 4);
      }

      set(newValue, 'prefix', prefix);
      onChange!(newValue);
    });

    /**
     * phoneNumber.number onChange handler
     */
    const handleNumberValue = useEventCallback((number: string) => {
      const newValue = cloneDeep(value ?? {});

      if (number?.length > 12) {
        number = number.slice(0, 12);
      }

      set(newValue, 'number.value', number);
      onChange!(newValue);
    });

    return (
      <>
        {!disabled && (
          <>
            <MuiTextField
              name={`${name}.prefix`}
              value={value?.prefix ?? ''}
              required={required}
              error={error}
              helperText={helperText}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^+0-9]/gi, '');
                handlePrefixValue(e.target.value);
              }}
              disabled={disabled}
              className={classes.prefix}
              InputProps={{
                classes: {
                  root: classes.prefixInput,
                },
                disableUnderline: true,
                ...InputProps,
              }}
              inputProps={{
                ...inputProps,
                autoComplete: 'off',
                form,
              }}
            />
            <MuiTextField
              name={`${name}.number.value`}
              value={value?.number?.value ?? ''}
              required={required}
              error={error}
              helperText={helperText}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/gi, '');
                handleNumberValue(e.target.value);
              }}
              disabled={disabled}
              className={classes.number}
              InputProps={{
                classes: {
                  root: classes.numberInput,
                },
                disableUnderline: true,
                ...InputProps,
              }}
              inputProps={{
                ...inputProps,
                autoComplete: 'off',
                form,
              }}
            />
          </>
        )}
        {disabled && (
          <MuiTextField
            name={`${name}.number.value`}
            value={`${value?.prefix ?? ''} ${value?.number?.value ?? ''}`}
            required={required}
            error={error}
            helperText={helperText}
            onChange={noop}
            disabled={true}
            className={classes.number}
            InputProps={{
              classes: {
                root: classes.numberInput,
              },
              disableUnderline: true,
              ...InputProps,
            }}
            inputProps={{
              ...inputProps,
              autoComplete: 'off',
              form,
            }}
          />
        )}
      </>
    );
  };
}

import React from 'react';

import {
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export default function DictionaryRuianMunicipalityFields() {
  const prefix = 'RUIAN_MUNICIPALITY';

  return (
    <>
      <FormPanel label="Obec s pověřeným obecním úřadem">
        <FormTextField
          name="commissionedLocalAuthorityMunicipality.name"
          label={
            <HelpLabel
              label="Název"
              code={`DICT_${prefix}_COMMISSIONED_LOCAL_AUTHORITY_NAME`}
            />
          }
        />
        <FormTextField
          name="commissionedLocalAuthorityMunicipality.isuiCode"
          label={
            <HelpLabel
              label="Kód v ISÚI"
              code={`DICT_${prefix}_COMMISSIONED_LOCAL_AUTHORITY_CODE`}
            />
          }
        />
        <FormCheckbox
          name="commissionedLocalAuthorityMunicipality.active"
          label={
            <HelpLabel
              label="Aktivní"
              code={`DICT_${prefix}_COMMISSIONED_LOCAL_AUTHORITY_ACTIVE`}
            />
          }
        />
        <FormDateTimeField
          name="commissionedLocalAuthorityMunicipality.created"
          label={
            <HelpLabel
              label="Vytvoření"
              code={`DICT_${prefix}_COMMISSIONED_LOCAL_AUTHORITY_CREATED`}
            />
          }
        />
      </FormPanel>
      <FormPanel label="Okres">
        <FormTextField
          name="district.name"
          label={
            <HelpLabel label="Název" code={`DICT_${prefix}_DISTRICT_NAME`} />
          }
        />
        <FormTextField
          name="district.isuiCode"
          label={
            <HelpLabel
              label="Kód v ISÚI"
              code={`DICT_${prefix}_DISTRICT_CODE`}
            />
          }
        />
        <FormCheckbox
          name="district.active"
          label={
            <HelpLabel
              label="Aktivní"
              code={`DICT_${prefix}_DISTRICT_ACTIVE`}
            />
          }
        />
        <FormDateTimeField
          name="district.created"
          label={
            <HelpLabel
              label="Vytvoření"
              code={`DICT_${prefix}_DISTRICT_CREATED`}
            />
          }
        />
      </FormPanel>
    </>
  );
}

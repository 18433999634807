/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Agenda messages
 */
const CREATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'NAME_NOT_UNIQUE'> = {
  ERROR: ['Chyba při vytváření záznamu.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  NAME_NOT_UNIQUE: ['Agenda, kterou se snažíte založit má duplicitní název k již existující agendě.', SnackbarVariant.ERROR],
}

const UPDATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'NAME_NOT_UNIQUE'> = {
  ERROR: ['Chyba při editaci záznamu.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  NAME_NOT_UNIQUE: ['Agenda, kterou se snažíte upravit má duplicitní název k již existující agendě.', SnackbarVariant.ERROR],
}

export default { CREATE, UPDATE };

import React, { useContext, useRef, useMemo } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import {
  useEventCallback,
  ApiFilterOperation,
  AbortableFetch,
  abortableFetch,
  Tooltip,
  FormContext,
} from '@eas/common-web';
import { EvidenceAPI } from '../../../../enums';
import { Address, AddressPlace } from '../../../../models';
import { AddressFieldCell } from '../address-field-cell';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';

function callApi(addressPlaceId: string) {
  return abortableFetch(`${EvidenceAPI.ADDRESS_PLACE}/${addressPlaceId}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  });
}

export function AddressPlaceCell() {
  const { code, prefillAddress, name } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);
  const { setFieldValue, editing } = useContext(FormContext);

  const createFilter = useEventCallback((address?: Address) => {
    const filter = [];

    if (address?.cityDistrictRuian?.id) {
      filter.push({
        field: 'cityDistrict.id',
        operation: ApiFilterOperation.EQ,
        value: address?.cityDistrictRuian?.id,
      });
    }
    if (address?.municipalityPartRuian?.id) {
      filter.push({
        field: 'municipalityPart.id',
        operation: ApiFilterOperation.EQ,
        value: address?.municipalityPartRuian?.id,
      });
    }
    if (address?.municipalityRuian?.id) {
      filter.push({
        field: 'municipality.id',
        operation: ApiFilterOperation.EQ,
        value: address?.municipalityRuian?.id,
      });
    }
    if (address?.streetRuian?.id) {
      filter.push({
        field: 'street.id',
        operation: ApiFilterOperation.EQ,
        value: address?.streetRuian?.id,
      });
    }
    if (address?.houseNumberType) {
      filter.push({
        field: 'houseNumberType',
        operation: ApiFilterOperation.EQ,
        value: address?.houseNumberType,
      });
    }
    if (address?.houseNumber) {
      filter.push({
        field: 'houseNumber',
        operation: ApiFilterOperation.EQ,
        value: address?.houseNumber,
      });
    }
    if (address?.orientationNumber) {
      filter.push({
        field: 'orientationNumber',
        operation: ApiFilterOperation.EQ,
        value: address?.orientationNumber,
      });
    }
    if (address?.orientationNumberMark) {
      filter.push({
        field: 'orientationNumberMark',
        operation: ApiFilterOperation.EQ,
        value: address?.orientationNumberMark,
      });
    }
    if (address?.zip) {
      filter.push({
        field: 'zip',
        operation: ApiFilterOperation.EQ,
        value: address?.zip,
      });
    }

    return filter;
  });

  const disabled = useMemo(
    () => (address?: Address) => !address?.municipalityRuian,
    []
  );

  const filterDeps = useEventCallback((address?: Address) => [
    address?.cityDistrictRuian?.id,
    address?.municipalityPartRuian?.id,
    address?.municipalityRuian?.id,
    address?.streetRuian?.id,
    address?.houseNumberType,
    address?.houseNumber,
    address?.orientationNumber,
    address?.orientationNumberMark,
    address?.zip,
  ]);

  const changeFetchRef = useRef<AbortableFetch | null>(null);

  const notifyChange = useEventCallback(
    async (address: Address | undefined) => {
      const addressPlaceId = address?.addressPlaceRuian?.id;
      try {
        if (changeFetchRef.current !== null) {
          changeFetchRef.current.abort();
        }

        if (addressPlaceId) {
          changeFetchRef.current = callApi(addressPlaceId);
          const addressPlace: AddressPlace = await changeFetchRef.current.json();

          prefillAddress(addressPlace);
        } else {
          const descendants = [
            'municipalityPartRuian',
            'cityDistrictRuian',
            'streetRuian',
            'houseNumber',
            'houseNumberType',
            'orientationNumber',
            'orientationNumberMark',
            'zip',
          ];
          unstable_batchedUpdates(() => {
            for (const descName of descendants) {
              setFieldValue(`${name}.${descName}`, null);
            }
          });
        }
      } catch (err) {
        if (err.name !== 'AbortError') {
          throw err;
        }
      }
    }
  );

  const VDPRedirect = (address: Address | undefined) =>
    address?.addressPlaceRuian?.id ? (
      <Tooltip title="Odkaz do VDP">
        <IconButton
          size="small"
          component="a"
          target="_blank"
          href={`https://vdp.cuzk.cz/vdp/ruian/adresnimista/${address?.addressPlaceRuian?.isuiCode}`}
        >
          <OpenInNewIcon fontSize="small" style={{ padding: '1pt' }} />
        </IconButton>
      </Tooltip>
    ) : null;

  return (
    <AddressFieldCell
      label={defaults?.addressPlace?.label ?? 'Adresní místo'}
      required={defaults?.addressPlace?.required ?? false}
      name="addressPlaceRuian"
      parentName="streetRuian"
      code={`${code}_ADDRESS_PLACE`}
      url={EvidenceAPI.ADDRESS_PLACE}
      parentUrl={EvidenceAPI.ADDRESS_STREET}
      before={!editing ? VDPRedirect : undefined}
      disablePrefill={true}
      createFilter={createFilter}
      notifyChange={notifyChange}
      filterDeps={filterDeps}
      disabled={disabled}
    />
  );
}

import { MessageType } from './message-type';
import { SnackbarVariant } from '@eas/common-web';

const ACTIVATE: MessageType = {
  SUCCESS: ['Průmyslové místo bylo úspěšně obnoveno.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při obnovení průmyslového místa.', SnackbarVariant.ERROR],
};

const DEACTIVATE: MessageType = {
  SUCCESS: [
    'Průmyslové místo bylo úspěšně zneplatněno.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: ['Chyba při zneplatnění průmyslového místa.', SnackbarVariant.ERROR],
};

const SPLIT: MessageType<'SUCCESS' | 'NAME_NOT_UNIQUE' | 'ERROR'> = {
  SUCCESS: [
    'Průmyslová místa byla úspěšně rozdělena.',
    SnackbarVariant.SUCCESS,
  ],
  NAME_NOT_UNIQUE: [
    'Průmyslové místo se zadaným názvem již existuje.',
    SnackbarVariant.ERROR,
  ],
  ERROR: ['Chyba při rozdělení průmyslových míst.', SnackbarVariant.ERROR],
};

const MERGE: MessageType = {
  SUCCESS: ['Průmyslová místa byla úspěšně sloučena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při slučování průmyslových míst.', SnackbarVariant.ERROR],
};

const PRODUCTION_SITE_PRELOAD: MessageType<'ERROR'> = {
  ERROR: ['Chyba při načítání průmyslového místa.', SnackbarVariant.ERROR],
};

const CREATE: MessageType<'NAME_NOT_UNIQUE' | 'ERROR'> = {
  NAME_NOT_UNIQUE: [
    'Průmyslové místo se zadaným názvem již existuje.',
    SnackbarVariant.ERROR,
  ],
  ERROR: ['Chyba při vytváření průmyslového místa.', SnackbarVariant.ERROR],
};

const EDIT: MessageType<'NAME_NOT_UNIQUE' | 'ERROR'> = {
  NAME_NOT_UNIQUE: [
    'Průmyslové místo se zadaným názvem již existuje.',
    SnackbarVariant.ERROR,
  ],
  ERROR: ['Chyba při editaci průmyslového místa.', SnackbarVariant.ERROR],
};

export default {
  ACTIVATE,
  DEACTIVATE,
  SPLIT,
  MERGE,
  PRODUCTION_SITE_PRELOAD,
  CREATE,
  EDIT,
};

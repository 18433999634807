import React from 'react';
import { Evidence2, EmptyComponent } from '@eas/common-web';
import { DictionaryTerritorialTechnicalUnit } from '../../models';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { TerritorialTechnicalUnitsFields } from './dict-territorial-technical-units-fields';
import { useColumns } from './dict-territorial-technical-units-columns';
import { stubFalse } from 'lodash';

export function DictionaryTerritorialTechnicalUnits() {
  const columns = useColumns();

  const evidence = useDictionaryEvidence<DictionaryTerritorialTechnicalUnit>({
    identifier: PageKey.DICTIONARY_TERRITORIAL_TECHNICAL_UNITS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_TERRITORIAL_TECHNICAL_UNITS,
    },
    tableProps: {
      tableName: 'Územně technické jednotky (ÚTJ)',
      reportTag: ExportTags.DICTIONARY_TERRITORIAL_TECHNICAL_UNITS,
      defaultSorts: [],
      columns,
      showColumn: stubFalse,
    },
    detailProps: {
      entityName: EntityName.TERRITORIAL_TECHNICAL_UNIT,
      FieldsComponent: TerritorialTechnicalUnitsFields,
      codePrefix: 'TERRITORIAL_TECHNICAL_UNIT',
      toolbarProps: {
        title: 'Územně technická jednotka (ÚTJ)',
        ActionBar: EmptyComponent,
        showBtn: () => false,
      },
      hideGeneralFields: true,
    },
  });

  return <Evidence2 {...evidence} />;
}

import React, {
  createContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useSubjectsCount } from './subjects-api';

export interface SubjectsDataContextType {
  subjectsCount: number;
  setSubjectsCount: (count: number) => void;
}

export const SubjectsDataContext = createContext<SubjectsDataContextType>({
  subjectsCount: 0,
  setSubjectsCount: () => ({}),
});

export function SubjectsDataProvider({ children }: { children: ReactNode }) {
  const initialCount = useSubjectsCount();
  const [subjectsCount, setSubjectsCount] = useState(0);

  useEffect(() => {
    setSubjectsCount(initialCount);
  }, [initialCount]);

  const context = useMemo(
    () => ({
      subjectsCount: subjectsCount ?? 0,
      setSubjectsCount: setSubjectsCount,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subjectsCount]
  );

  return (
    <SubjectsDataContext.Provider value={context}>
      {children}
    </SubjectsDataContext.Provider>
  );
}

import React, { useContext, useMemo } from 'react';
import { Evidence2, UserContext, getItemFactory } from '@eas/common-web';
import { v4 as uuidv4 } from 'uuid';
import { BoilerPermission, Me, Exception } from '../../models';
import { BoilerPermissionsFields } from './boiler-permissions-fields';
import { useColumns } from './boiler-permissions-columns';
import { useValidationSchema } from './boiler-permissions-schema';
import {
  EvidenceAPI,
  PageKey,
  IncludeOptions,
  Permission,
  Messages,
  EntityName,
} from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { BoilerPermissionActionbar } from './boiler-permissions-actionbar';
import { BoilerPermissionsTableToolbar } from './boiler-permissions-table-toolbar';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useHistoryDescriptors } from './boiler-permissions-history';
import { updateItemFactory, createItemFactory } from '../../utils/custom-crud';
import { preprocessPhoneNumber } from '../../components/form/phone-field/phone-field-utils';
import { getErrorMessage } from '../../utils/get-message';

export function BoilerPermissions() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<BoilerPermission>({
    options: {
      EDIT: [
        Permission.Ozo.OZO_PERMISSION_UPDATE_ALL,
        Permission.Ozo.OZO_PERMISSION_UPDATE_OWN,
      ],
      NEW: [],
      REMOVE: [],
      CHECK: [
        Permission.Ozo.OZO_PERMISSION_UPDATE_ALL,
        Permission.Ozo.OZO_PERMISSION_UPDATE_OWN,
      ],
      SAVE: [
        Permission.Ozo.OZO_PERMISSION_UPDATE_ALL,
        Permission.Ozo.OZO_PERMISSION_UPDATE_OWN,
      ],
      CLOSE: [
        Permission.Ozo.OZO_PERMISSION_UPDATE_ALL,
        Permission.Ozo.OZO_PERMISSION_UPDATE_OWN,
      ],
    },
    dataCheck: {
      EDIT: (data) => !!data?.active,
    },
  });

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const canSeeDeactivated = useMemo(
    () =>
      hasPermission(Permission.Ozo.OZO_PERMISSION_UPDATE_ALL) ||
      hasPermission(Permission.Ozo.OZO_PERMISSION_UPDATE_OWN, {
        searchInSubjectPerms: true,
      }),
    [hasPermission]
  );

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<BoilerPermission>({
    identifier: PageKey.BOILER_PERMISSIONS,
    apiProps: {
      url: EvidenceAPI.BOILER_PERMISSIONS,
      getItem: getItemFactory({
        postProcess: (data: BoilerPermission) => {
          return {
            ...data,
            withoutEnd: !data?.validTo,
          };
        },
      }),
      updateItem: updateItemFactory({
        preprocessCall: async (item) => preprocessPhoneNumber(item),
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.OzoPermission.UPDATE,
              (data as Exception).code
            )[0];

            throw { message };
          }

          (data as any)['withoutEnd'] = !(data as BoilerPermission)?.validTo;
        },
      }),
      createItem: createItemFactory({
        preprocessCall: async (item) => preprocessPhoneNumber(item),
      }),
    },
    tableProps: {
      columns: useColumns(canSeeDeactivated),
      tableName: 'Přehled oprávnění OZO',
      reportTag: ExportTags.BOILER_PERMISSIONS,
      defaultSorts: [],
      include,
      toolbarProps: {
        after: <BoilerPermissionsTableToolbar />,
      },
    },
    detailProps: {
      entityName: EntityName.BOILER_PERMISSION,
      FieldsComponent: BoilerPermissionsFields,
      validationSchema,
      initNewItem: () => ({
        id: uuidv4(),
        phoneNumber: {
          prefix: '+420',
        },
      }),
      toolbarProps: {
        title: 'Oprávnění OZO',
        subtitle: useSubTitle<BoilerPermission>((data) => data?.name ?? ''),
        ActionBar: BoilerPermissionActionbar,
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Ozo.OZO_PERMISSION_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}

import React, { useRef } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Form, SplitScreen } from '@eas/common-web';
import { noop } from 'lodash';
import { Title } from '../title/title';
import { ProcessProps } from './process-types';
import { ProcessStepper } from './process-stepper';
import { useProcess } from './process-hook';
import { HelpBlock } from '../help/help-block';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flexGrow: 1,
      flexShrink: 1,
      borderTop: '5px solid #fafafa',
      height: 'calc(100vh - 67px)',
    },
    wrapperLeft: {
      height: '100%',
      backgroundColor: 'white',
      overflowY: 'auto',
      minWidth: 400,
      direction: 'rtl',
    },
    innerWrapperLeft: {
      padding: 12,
      height: '100%',
      direction: 'ltr',
    },
    wrapperRight: {
      height: '100%',
      minWidth: 400,
      overflowY: 'auto',
    },
  })
);

export function Process<FORM>({
  steps,
  title,
  translationKey,
}: ProcessProps<FORM>) {
  const classes = useStyles();

  const defaultTitle = useRef(title);

  const {
    activeStep,
    process,
    processValues,
    navigationPrompt,
    handleNext,
    handleMoveToStep,
    handleBack,
    handleExit,
  } = useProcess<FORM>(steps);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <SplitScreen
          leftLabel="Nápověda"
          rightLabel="Proces"
          rightMinWidth={600}
          defaultTitle={defaultTitle.current}
        >
          <div className={classes.wrapperLeft}>
            <div className={classes.innerWrapperLeft}>
              <Title name={title} />
              <HelpBlock
                code={`${translationKey}_STEP_${activeStep + 1}`}
                title={title}
                defaultMessage="Nápověda nebyla definována."
              />
            </div>
          </div>
          <div className={classes.wrapperRight}>
            <Form initialValues={{} as any} onSubmit={noop} editing={true}>
              <ProcessStepper<FORM>
                steps={steps}
                activeStep={activeStep}
                process={process}
                processValues={processValues}
                navigationPrompt={navigationPrompt}
                handleNext={handleNext}
                handleBack={handleBack}
                handleExit={handleExit}
                handleMoveToStep={handleMoveToStep}
              />
            </Form>
          </div>
        </SplitScreen>
      </div>
    </div>
  );
}

import React from 'react';
import {
  FormPanel,
  FormAutocomplete,
  Form,
  FormTextField,
  useAutocompleteSource,
  FormTextArea,
} from '@eas/common-web';
import { ProcessForm } from '../production-site-split-types';
import { useProductionSites } from '../../production-site/production-sites-api';
import { noop } from 'lodash';
import { useIrzFacilitiesWithoutProductionSite } from '../../irz-facility/irz-facilities-api';
import { EvidenceAPI } from '../../../enums';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { RuianObject } from '../../../models';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const productionSites = useProductionSites();
  const facilities = useIrzFacilitiesWithoutProductionSite();
  const districts = useAutocompleteSource({
    url: `${EvidenceAPI.ADDRESS_DISCTRICT}/autocomplete`,
  });

  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Průmyslové místo k rozdělení">
        <FormAutocomplete
          name="originalProductionSite"
          label="Průmyslové místo"
          helpLabel="Průmyslové místo, které bude rozděleno"
          source={productionSites}
        />
      </FormPanel>
      <FormPanel label="Nové průmyslové místo - Základní informace">
        <FormTextField name="newProductionSite.name" label="Název" disabled />
        <FormAutocomplete
          name="newProductionSite.facilities"
          label="Provozovny"
          source={facilities}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          multiple={true}
          disabled
        />
        <FormTextArea name="newProductionSite.note" label="Poznámka" disabled />
        <FormAutocomplete<RuianObject>
          name="newProductionSite.district"
          source={districts}
          disabled
          label="Okres"
        />
      </FormPanel>
      <LocationPanel
        locationName="newProductionSite.wgs84Coordinates"
        locationValue={allValues.newProductionSite?.wgs84Coordinates}
        addressPanelLabel="Nové průmyslové místo - Souřadnice"
        locationPanelLabel="Nové průmyslové místo - Poloha"
        showCoordinates={true}
        disabled
        showCoordinatesDistance={false}
      />
    </Form>
  );
}

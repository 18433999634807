export enum PermissionMuvIndicator {
  /** Přehled ukazatelů */
  MUV_INDICATOR_LIST = 'MUV_INDICATOR_LIST',

  /** Autocomplete výběr ukazatelů */
  MUV_INDICATOR_AUTOCOMPLETE = 'MUV_INDICATOR_AUTOCOMPLETE',

  /** Detail ukazatele */
  MUV_INDICATOR_DETAIL = 'MUV_INDICATOR_DETAIL',

  /** Vytvoření nového ukazatele  */
  MUV_INDICATOR_CREATE = 'MUV_INDICATOR_CREATE',

  /** Editace ukazatele */
  MUV_INDICATOR_UPDATE = 'MUV_INDICATOR_UPDATE',

  /** Mazání ukazatele */
  MUV_INDICATOR_DELETE = 'MUV_INDICATOR_DELETE',

  /** Zneplatnění ukazatele */
  MUV_INDICATOR_INVALIDATE = 'MUV_INDICATOR_INVALIDATE',

  /** Obnovení ukazatele */
  MUV_INDICATOR_REVALIDATE = 'MUV_INDICATOR_REVALIDATE',
}

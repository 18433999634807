import { useContext } from 'react';
import { UserContext, ApiFilterOperation, Params } from '@eas/common-web';
import { useSubjects } from '../../subject/subjects-api';
import { Me, PermissionsState } from '../../../models';
import { getSubjectsWithPermission } from '../../../components/permission/perm-utils';
import { Permission } from '../../../enums';

export function useOperator() {
  /** Context stuff */
  const { user, hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const availableSubjectIds = getSubjectsWithPermission(
    user!,
    Permission.IrzFacility.IRZ_REQUEST_CREATE_SUBMIT
  );

  const params: Params = {
    filters: [],
  };

  if (!hasPermission(Permission.IrzFacility.IRZ_AUTOCOMPLETE_ALL)) {
    params.filters?.push({
      operation: ApiFilterOperation.IDS,
      ids: availableSubjectIds,
    });
  }

  return useSubjects(params);
}

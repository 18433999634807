import { useMemo } from 'react';
import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { inactiveColoredFactory } from '../../form/inactive-colored/inactive-colored';
import { DictionaryObject } from '../../../models';

export function useColumns<OBJECT extends DictionaryObject>({
  columns,
  canSeeDeactivated,
  showColumn = () => true,
  columnLabelMapper = () => undefined,
}: {
  columns?: TableColumn<OBJECT>[];
  canSeeDeactivated: boolean;
  showColumn?: (
    col: 'ORDER' | 'NAME' | 'VALID_FROM' | 'VALID_TO' | 'CODE' | 'ACTIVE'
  ) => boolean;
  columnLabelMapper?: (
    col: 'ORDER' | 'NAME' | 'VALID_FROM' | 'VALID_TO' | 'CODE' | 'ACTIVE'
  ) => string | undefined;
}) {
  const dictionaryColumns = useMemo(() => {
    const base = columns ?? [];

    if (showColumn('ORDER')) {
      base.push({
        datakey: 'order',
        name: columnLabelMapper('ORDER') ?? 'Pořadí',
        width: 100,
        filterOperation: ApiFilterOperation.EQ,
        CellComponent: inactiveColoredFactory<OBJECT>(TableCells.NumberCell),
        sortable: true,
        filterable: true,
      });
    }

    if (showColumn('NAME')) {
      base.push({
        datakey: 'name',
        name: columnLabelMapper('NAME') ?? 'Název',
        width: 200,
        filterOperation: ApiFilterOperation.CONTAINS,
        CellComponent: inactiveColoredFactory<OBJECT>(TableCells.TextCell),
        sortable: true,
        filterable: true,
      });
    }

    if (showColumn('VALID_FROM')) {
      base.push({
        datakey: 'validFrom',
        name: columnLabelMapper('VALID_FROM') ?? 'Platnost od',
        width: 150,
        FilterComponent: TableFilterCells.FilterDateCell,
        CellComponent: inactiveColoredFactory<OBJECT>(TableCells.DateTimeCell),
        sortable: true,
        filterable: true,
      });
    }

    if (showColumn('VALID_TO')) {
      base.push({
        datakey: 'validTo',
        name: columnLabelMapper('VALID_TO') ?? 'Platnost do',
        width: 150,
        FilterComponent: TableFilterCells.FilterDateCell,
        CellComponent: inactiveColoredFactory<OBJECT>(TableCells.DateTimeCell),
        sortable: true,
        filterable: true,
      });
    }

    if (showColumn('CODE')) {
      base.push({
        datakey: 'code',
        name: columnLabelMapper('CODE') ?? 'Kód',
        width: 100,
        filterOperation: ApiFilterOperation.CONTAINS,
        CellComponent: inactiveColoredFactory<OBJECT>(TableCells.TextCell),
        sortable: true,
        filterable: true,
      });
    }

    if (canSeeDeactivated && showColumn('ACTIVE')) {
      base.push({
        datakey: 'active',
        name: columnLabelMapper('ACTIVE') ?? 'Aktivní',
        width: 100,
        filterOperation: ApiFilterOperation.EQ,
        CellComponent: inactiveColoredFactory<OBJECT>(
          TableCells.BooleanCell,
          false
        ),
        FilterComponent: TableFilterCells.FilterBooleanCell,
        sortable: true,
        filterable: true,
      });
    }

    return base;
  }, [canSeeDeactivated, columnLabelMapper, columns, showColumn]);

  return dictionaryColumns;
}

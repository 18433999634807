import {
  useStaticListSource,
  DictionaryAutocomplete,
  abortableFetch,
  useListSource,
} from '@eas/common-web';
import {
  AuthorRole,
  EmpowermentSpecificationApprovalState,
} from '../../models';
import { EvidenceAPI } from '../../enums';

export function useAuthorRoles() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: AuthorRole.EMPOWERER,
      name: 'Zmocnitel',
    },
    { id: AuthorRole.AGENT, name: 'Zmocněnec' },
  ]);
}

export function useEmpowermentStates() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.EMPOWERMENT_REQUESTS + '/state/list',
    method: 'GET',
  });
}

export function useEmpowermentSpecificationStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: EmpowermentSpecificationApprovalState.APPROVED,
      name: 'Schválena',
    },
    { id: EmpowermentSpecificationApprovalState.REJECTED, name: 'Zamítnuta' },
    {
      id: EmpowermentSpecificationApprovalState.NONE,
      name: 'Nerozhodnuto',
    },
  ]);
}

export function getOtherSubject(id: string) {
  return abortableFetch(
    `${EvidenceAPI.EMPOWERMENT_REQUESTS}/${id}/other-subject`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    }
  );
}

import {
  useAutocompleteSource,
  abortableFetch,
  DictionaryAutocomplete,
  useListSource,
  ResultDto,
  ApiFilterOperation,
  Params,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { OvzFacilityRequest, OvzFacilityAutocomplete } from '../../models';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import { post } from '../../utils/custom-crud';

export function useLandPlotTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.OVZ_FACILITIES + '/land-plot/type/list',
    method: 'GET',
  });
}

export function useOvzFacilities(params?: Params) {
  return useAutocompleteSource<OvzFacilityAutocomplete>({
    url: `${EvidenceAPI.OVZ_FACILITIES}/autocomplete`,
    params,
  });
}

export function fetchOvzFacility(id: string) {
  return abortableFetch(`${EvidenceAPI.OVZ_FACILITIES}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export async function combineGetCalls({ signal, mainItem }: CombineCallsType) {
  const json = {
    ...mainItem,
  };

  /**
   * Append newOperator to request
   */
  const [ovzFacilityRequest, request] = await post<
    ResultDto<OvzFacilityRequest>
  >({
    url: `${EvidenceAPI.OVZ_FACILITY_REQUESTS}/list`,
    signal,
    body: {
      filters: [
        {
          operation: ApiFilterOperation.CONTAINS,
          field: 'facilities.id',
          value: mainItem?.id,
        },
      ],
    },
  });

  if (request?.ok && ovzFacilityRequest?.items) {
    json['requests'] = ovzFacilityRequest.items;
  }

  const text = JSON.stringify(json);

  return {
    data: json,
    text,
  };
}

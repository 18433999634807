import React from 'react';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { useValidationSchema } from './dict-dirp-types-schema';
import { DictionaryDirpType } from '../../models';
import { PageKey, EvidenceAPI, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';

export function DictionaryDirpTypes() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryDirpType>({
    identifier: PageKey.DICTIONARY_DIRP_TYPES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_DIRP_TYPES,
    },
    tableProps: {
      tableName: 'Typy',
      reportTag: ExportTags.DICTIONARY_DIRP_TYPES,
      columns: [],
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.DIRP_TYPE,
      codePrefix: 'DIRP_TYPE',
      validationSchema,
      toolbarProps: {
        title: 'Typ',
      },
    },
  });

  return <Evidence {...evidence} />;
}

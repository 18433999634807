import React from 'react';
import { TableFieldCellProps, TableFieldCells } from '@eas/common-web';
import { AgendaSpecification } from '../../models';

export function AllSpecificationsCell(props: TableFieldCellProps<any>) {
  const specifications: AgendaSpecification[] = props.rowValue?.specifications;

  return (
    <TableFieldCells.TextCell
      {...props}
      value={specifications
        .map((item) => {
          return item.name;
        })
        .join(', ')}
    />
  );
}

import { abortableFetch, Params } from '@eas/common-web';
import {
  CreateFromEvidenceDto,
  RecipientsSource,
} from '../../models/mail-campaign';

export function createFromEvidenceApiFactory(source: RecipientsSource) {
  return function createFromEvidenceApi(params: Params, selected: string[]) {
    const dto: CreateFromEvidenceDto = {
      params,
      selected,
      source,
    };

    return abortableFetch('/api/crzp/mailing/campaign/from-evidence', {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(dto),
    });
  };
}

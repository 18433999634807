import { useContext } from 'react';
import {
  UserContext,
  DetailContext,
  DetailToolbarBtnName,
} from '@eas/common-web';
import { Me, PermissionsState, DomainObject } from '../../models';

type BtnName = Exclude<DetailToolbarBtnName, 'REFRESH' | 'SHARE'>;

type UsePermissionParams<OBJECT> = {
  options?: {
    [key in BtnName]?: string[];
  };
  dataCheck?: { [key in BtnName]?: (data: OBJECT) => boolean };
};

// Without any params gives permissions only for REFRESH and SHARE buttons
export function usePermission<OBJECT extends DomainObject>({
  options = {},
  dataCheck = {},
}: UsePermissionParams<OBJECT> = {}) {
  return function usePermissionIntern(button: DetailToolbarBtnName) {
    const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
      UserContext
    );
    const { source } = useContext(DetailContext);

    if (button === 'REFRESH' || button === 'SHARE') {
      return true;
    }

    if (Object.keys(options).length === 0) {
      return false;
    }

    return (options?.[button] ?? [])?.some((perm) => {
      if (dataCheck?.[button]) {
        return (
          hasPermission(perm, {
            searchInSubjectPerms: true,
            subjectId: source?.data?.id,
          }) && dataCheck?.[button]?.(source.data)
        );
      } else {
        return hasPermission(perm, {
          searchInSubjectPerms: true,
          subjectId: source?.data?.id,
        });
      }
    });
  };
}

import React from 'react';
import {
  FormTextField,
  FormPanel,
  FormDateTimeField,
  FormEditor,
  FormSelect,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useWebServices } from '../web-service/web-services-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';

export function SoapMessageLogsFields() {
  const webServices = useWebServices();

  return (
    <FormPanel label="Základní informace">
      <FormSelect
        name="service"
        source={webServices}
        label={
          <HelpLabel label="Název služby" code="SOAP_MESSAGE_LOG_SERVICE" />
        }
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
        valueIsId={true}
      />
      <FormTextField
        name="localPart"
        label={<HelpLabel label="Operace" code="SOAP_MESSAGE_LOG_LOCAL_PART" />}
      />
      <FormTextField
        name="version"
        label={
          <HelpLabel
            label="Verze služby (rozhraní)"
            code="SOAP_MESSAGE_LOG_VERSION"
          />
        }
      />
      <FormTextField
        name="namespace"
        label={
          <HelpLabel label="Jmenný prostor" code="SOAP_MESSAGE_LOG_NAMESPACE" />
        }
      />
      <FormDateTimeField
        name="created"
        label={
          <HelpLabel label="Datum vytvoření" code="SOAP_MESSAGE_LOG_CREATED" />
        }
      />
      <FormEditor
        name="request"
        height={500}
        language="xml"
        label={
          <HelpLabel label="Tělo požadavku" code="SOAP_MESSAGE_LOG_REQUEST" />
        }
      />
      <FormEditor
        name="response"
        height={500}
        language="xml"
        label={
          <HelpLabel label="Tělo opovědi" code="SOAP_MESSAGE_LOG_RESPONSE" />
        }
      />
      <FormTextField
        name="createdBy.name"
        label={
          <HelpLabel
            label="Autor volání"
            code="SOAP_MESSAGE_LOG_CREATED_BY_NAME"
          />
        }
      />
      <FormTextField
        name="duration"
        label={
          <HelpLabel
            label="Trvání operace (ms)"
            code="SOAP_MESSAGE_LOG_DURATION"
          />
        }
      />
      <FormTextField
        name="transactionId"
        label={
          <HelpLabel
            label="Id operace"
            code="SOAP_MESSAGE_LOG_TRANSACTION_ID"
          />
        }
      />
    </FormPanel>
  );
}

import React, { useContext, useMemo } from 'react';
import { Evidence2, UserContext } from '@eas/common-web';
import { Empowerment, Me, PermissionsState, Exception } from '../../models';
import { EmpowermentsFields } from './empowerments-fields';
import { useColumns } from './empowerments-columns';
import { useValidationSchema } from './empowerments-schema';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  IncludeOptions,
  Messages,
  EntityName,
} from '../../enums';
import { EmpowermentToolbar } from './empowerments-toolbar';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { ExportTags } from '../../enums/export-tags';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { EmpowermentActionbar } from './empowerments-actionbar';
import { EntityType } from '../../enums/entity-type';
import { useSpecialFilters } from './empowerments-special-filters';
import {
  getItemFactory,
  updateItemFactory,
  createItemFactory,
} from '../../utils/custom-crud';
import { combineGetCalls } from './empowerments-api';
import { useHistoryDescriptors } from './empowerments-history';
import { getErrorMessage } from '../../utils/get-message';

export function Empowerments() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission({
    options: {
      NEW: [Permission.Empowerment.EMPOWERMENT_CREATE],
      EDIT: [Permission.Empowerment.EMPOWERMENT_UPDATE_ALL], // Edit permission
      REMOVE: [],
      CHECK: [Permission.Empowerment.EMPOWERMENT_CREATE],
      SAVE: [Permission.Empowerment.EMPOWERMENT_CREATE],
      CLOSE: [Permission.Empowerment.EMPOWERMENT_CREATE],
    },
  });

  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );
  const canSeeDeactivated = useMemo(
    () =>
      hasPermission(Permission.Empowerment.EMPOWERMENT_INVALIDATE_ALL) ||
      hasPermission(Permission.Empowerment.EMPOWERMENT_INVALIDATE_OWN, {
        searchInSubjectPerms: true,
      }),
    [hasPermission]
  );

  const include = useMemo(
    () =>
      canSeeDeactivated
        ? [IncludeOptions.INVALID, IncludeOptions.DEACTIVATED]
        : [],
    [canSeeDeactivated]
  );

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useDatedEvidence<Empowerment>({
    identifier: PageKey.EMPOWERMENTS,
    noteEntityType: EntityType.EMPOWERMENT,
    apiProps: {
      url: EvidenceAPI.EMPOWERMENTS,
      getItem: getItemFactory({ combineGetCalls }),
      updateItem: updateItemFactory({ combineGetCalls }),
      createItem: createItemFactory({
        combineGetCalls,
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.Empowerment.CREATE,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
    },
    tableProps: {
      columns: useColumns(canSeeDeactivated),
      tableName: 'Přehled zmocnění',
      reportTag: ExportTags.EMPOWERMENTS,
      include,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.Empowerment.EMPOWERMENT_LIST_ALL,
    },
    detailProps: {
      entityName: EntityName.EMPOWERMENT,
      FieldsComponent: EmpowermentsFields,
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Zmocnění',
        After: EmpowermentToolbar,
        ActionBar: EmpowermentActionbar,
        showBtn: permissions,
      },
      validationSchema,
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Empowerment.EMPOWERMENT_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}

import React from 'react';
import { ExtendedCompetenceMunicipality } from '../../models';
import { PageKey, EvidenceAPI, EntityName } from '../../enums';

import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';

export function DictionaryRuianExtendedCompetenceMunicipality() {
  const evidence = useRuianEvidence<ExtendedCompetenceMunicipality>(
    {
      identifier: PageKey.DICTIONARY_RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY,
      apiProps: {
        url: EvidenceAPI.ADDRESS_EXTENDED_COMPETENCE_MUNICIPALITY,
      },
      tableProps: {
        tableName: 'RUIAN - Obce s rozšířenou působností (ORP)',
        reportTag: ExportTags.DICTIONARY_MUNICIPALITY_ORP,
      },
      detailProps: {
        entityName: EntityName.RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY,
        toolbarProps: {
          title: 'RUIAN - Správní obvod obce s rozšířenou působností (ORP)',
        },
      },
    },
    {
      hasMunicipality: true,
    }
  );

  return <Evidence2 {...evidence} />;
}

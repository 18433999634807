import { SpecialFilter } from '../../components/evidence/history-evidence/overrides/table-searchbar';
import { SpecialFilterTextCell } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-text-cell';
import { useSpecialFilterSelectCellFactory } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-select-cell';
import { useRequestStates } from './ovz-facility-requests-api';
import { ApiFilterOperation } from '@eas/common-web';

export function useSpecialFilters(): SpecialFilter[] {
  return [
    {
      label: 'Původní provozovatel - IČO / UID',
      filterkey: 'oldOperator.identifier',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Původní provozovatel - Název',
      filterkey: 'oldOperator.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'IČP',
      filterkey: 'ovzFacilitiesConcatenated',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Obec',
      filterkey: 'facilities.address.municipality.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Nový provozovatel - IČO / UID',
      filterkey: 'newOperator.identifier',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Nový provozovatel - Název',
      filterkey: 'newOperator.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Stav nabídky',
      filterkey: 'state.id',
      operation: ApiFilterOperation.EQ,
      FilterCell: useSpecialFilterSelectCellFactory(useRequestStates),
    },
  ];
}

import * as Yup from 'yup';
import { DictionaryBoilerBrand } from '../../models';

export function useValidationSchema() {
  return Yup.object<DictionaryBoilerBrand>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    manufacturer: Yup.mixed<DictionaryBoilerBrand>()
      .nullable()
      .required('Povinné pole'),
  });
}

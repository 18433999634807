import { RoleInstance } from '../../models';
import { orderBy } from 'lodash';
import { TableSort } from '@eas/common-web';

export const rolesValueMapper = function rolesValueMapper({
  value,
  sorts,
}: {
  value: RoleInstance[];
  sorts: TableSort[];
}) {
  const sort = sorts.find((s) => s.datakey === 'roleInstances');
  const order = sort?.order === 'ASC' ? 'asc' : 'desc';

  const sortedRoles = orderBy(
    value,
    (roleInstance) => roleInstance?.role?.name ?? '-',
    [order]
  ).map((r) => r?.role?.name);

  const uniqueRoles = [...new Set(sortedRoles)];

  return uniqueRoles.join(', ');
};

rolesValueMapper.displayName = 'rolesValueMapper';

import React from 'react';
import {
  FormPanel,
  TextField,
  FormCustomField,
  Form,
  FormTextField,
  FormSelect,
  FormTextArea,
  Checkbox,
  useFormSelector,
  TextArea,
  FormDateTimeField,
} from '@eas/common-web';
import { ProcessForm } from '../boiler-permission-new-types';
import { AddressField } from '../../../components/form/address-field/address-field';
import { noop } from 'lodash';
import { useBoilerModelAllowedTypes } from '../../dict-boiler-brand/dict-boiler-brands-api';
import { DictionaryBoilerTypeAutocomplete, SubjectType } from '../../../models';
import { useOzoSubjectType } from '../step-ozo-subject/step-ozo-subject-api';
import { StepOzoSubjectForm } from '../step-ozo-subject/step-ozo-subject-types';
import { PhoneField } from '../../../components/form/phone-field/phone-field';
import { dd2dms } from '../../../utils/latlng';

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const boilerTypes = useBoilerModelAllowedTypes(
    (allValues?.boilerBrand?.allowedTypes ||
      []) as DictionaryBoilerTypeAutocomplete[]
  );
  const ozoSubjectTypes = useOzoSubjectType();
  const { subjectType } = useFormSelector((data: StepOzoSubjectForm) => ({
    subjectType: data.ozoSubjectType,
  }));

  const subjectTypeValue = ozoSubjectTypes.items.find(
    (item) => item.id === allValues.ozoSubjectType
  )?.name;

  const identifierLabel =
    subjectType === SubjectType.FOREIGN_NATURAL_PERSON
      ? 'UID'
      : 'Číslo OP/dokladu';

  const parseCoordinate = (coordinate?: number) => {
    if (!coordinate) {
      return '-';
    }

    const parsed = dd2dms(coordinate);
    return `${parsed.degrees}°${parsed.minutes}'${parsed.seconds}"`;
  };

  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Informace o subjektu OZO">
        <FormCustomField label="Typ subjektu">
          <TextField
            value={subjectTypeValue}
            onChange={() => {}}
            disabled={true}
          />
        </FormCustomField>

        <FormCustomField label={identifierLabel}>
          <TextField
            value={allValues.idNumber}
            onChange={() => {}}
            disabled={true}
          />
        </FormCustomField>
      </FormPanel>

      <FormPanel label="Informace o subjektu výrobce">
        <FormCustomField label="Subjekt výrobce">
          <TextField
            value={allValues.boilerManufacturer?.name}
            onChange={() => {}}
            disabled={true}
          />
        </FormCustomField>
      </FormPanel>

      <FormPanel label="Informace o kotli">
        <FormTextField name="boilerBrand.name" label="Značka kotle" />
        <FormSelect
          name="boilerTypes"
          label="Typy kotle"
          multiple={true}
          source={boilerTypes}
          DisabledComponent={TextArea}
        />
      </FormPanel>

      <FormPanel label="Základní informace">
        <FormTextField name="number" label="Číslo oprávnění" />
        <FormTextField name="ozoIco.value" label="IČO OZO" />
        <FormTextArea name="description" label="Popis" />
        <FormDateTimeField name="validFrom" label="Platnost od" />
        <FormDateTimeField name="validTo" label="Platnost do" />
        <FormCustomField label="Na neurčito">
          <Checkbox
            value={!allValues?.validTo}
            onChange={noop}
            disabled={true}
          />
        </FormCustomField>
      </FormPanel>

      <FormPanel label="Kontaktní údaje">
        <FormTextField name="email.value" label="E-mail" />
        <PhoneField
          name="phoneNumber"
          label="Telefonní číslo (předvolba, číslo)"
        />
      </FormPanel>

      <FormPanel label="Adresa podnikání">
        <AddressField name="address" />
        {allValues?.wgs84Coordinates && (
          <>
            <FormCustomField label="Zeměpisná šířka">
              <TextField
                value={parseCoordinate(allValues?.wgs84Coordinates?.lat)}
                onChange={noop}
                disabled={true}
              />
            </FormCustomField>
            <FormCustomField label="Zeměpisná délka">
              <TextField
                value={parseCoordinate(allValues?.wgs84Coordinates?.lon)}
                onChange={noop}
                disabled={true}
              />
            </FormCustomField>
          </>
        )}
      </FormPanel>
    </Form>
  );
}

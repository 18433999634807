import { AuthoredObject } from './authored-object';
import { DictionaryAutocomplete } from './dictionary-object';

export interface NotificationOption {
  [key: string]: boolean;
}

export interface NotificationEvent extends DictionaryAutocomplete {
  /**
   * Název události
   */
  name: string;

  /**
   * Popis události
   */
  description?: string;

  /**
   * Seznam povolených typů notifikací pro tuto událost
   */
  allowedTypes?: string[];

  /**
   * Příznak, zda-li je možné nastavit notifikaci ako nepovinnou
   */
  canBeOptional?: boolean;

  /**
   * Typ modelu pro zástupné položky
   */
  modelType?: any;

  /**
   * JSON schema definující zástupné položky
   */
  schema?: any;
}

export enum NotificationType {
  /**
   * Aplikační notifikace
   */
  APPLICATION = 'APPLICATION',

  /**
   * Emailová notifikace
   */
  EMAIL = 'EMAIL',
}
export interface NotificationTemplate extends AuthoredObject {
  /**
   * Název šablony
   */
  name?: string;

  /**
   * Událost
   */
  event?: NotificationEvent['id'];

  /**
   * Předmět notifikace
   */
  subject?: string;

  /**
   * Obsah (tělo) notifikace
   */
  content?: string;

  /**
   * Příznak platnosti
   */
  active?: boolean;

  /**
   * Typ notifikační šablony
   */
  type?: NotificationType;
}

export interface EmailNotificationTemplate extends NotificationTemplate {
  /**
   * Příznak, zda-li je obsah (tělo) notifikace formátované pomocí HTML
   */
  html?: boolean;

  /**
   * Typ notifikační šablony
   */
  type: NotificationType.EMAIL;
}

export interface ApplicationNotificationTemplate extends NotificationTemplate {
  /**
   * Typ notifikační šablony
   */
  type: NotificationType.APPLICATION;
}

import React from 'react';
import { FormPanel, FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';

export function StepOriginalOperatorContent() {
  return (
    <FormPanel label="Původní provozovatel">
      <FormTextField
        name="subjectIdentifier"
        label={
          <HelpLabel
            label="IČO/ID"
            code="IRZ_FACILITY_REQUEST_OPERATOR_CHANGE_SUBJECT_ICO_ID"
          />
        }
      />
    </FormPanel>
  );
}

import * as Yup from 'yup';
import { StepAgendaForm } from './step-agenda-types';

export const stepAgendaSchema = () =>
  Yup.object<StepAgendaForm>().shape(
    {
      agendas: Yup.array()
        .when('withoutAgenda', {
          is: (withoutAgenda) => !withoutAgenda,
          then: Yup.array().nullable().min(1, 'Povinné pole').required(),
        })
        .nullable(),
      withoutAgenda: Yup.boolean()
        .when('agendas', {
          is: (agendas) =>
            agendas === undefined || (agendas ?? []).length === 0,
          then: Yup.boolean()
            .nullable()
            .required(
              'Tato možnost musí být označena, pokud nechcete přidělit žádnou agendu'
            ),
        })
        .nullable(),
    },
    [['agendas', 'withoutAgenda']]
  );

import { DictionaryProcessingPurposeAutocomplete } from '../../models';
import {
  useAutocompleteSource,
  DictionaryAutocomplete,
  useListSource,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useProcessingPurposes() {
  return useAutocompleteSource<DictionaryProcessingPurposeAutocomplete>({
    url: EvidenceAPI.DICTIONARY_PROCESSING_PURPOSE + '/autocomplete',
  });
}

export function useProcessingPurposeTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.DICTIONARY_PROCESSING_PURPOSE}/type/list`,
    method: 'GET',
  });
}

export function useProcessingPurposeDomains() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.DICTIONARY_PROCESSING_PURPOSE}/domain/list`,
    method: 'GET',
  });
}

/**
 * Third Party System Permissions
 */
export enum PermissionThirdPartySystem {
  /** Přehled systémů třetích stran */
  THIRD_PARTY_SYSTEM_LIST = 'THIRD_PARTY_SYSTEM_LIST',

  /**Detail systému třetí strany */
  THIRD_PARTY_SYSTEM_DETAIL = 'THIRD_PARTY_SYSTEM_DETAIL',

  /** Vytvoření systému třetí strany */
  THIRD_PARTY_SYSTEM_CREATE = 'THIRD_PARTY_SYSTEM_CREATE',

  /** Editace systému třetí strany */
  THIRD_PARTY_SYSTEM_UPDATE = 'THIRD_PARTY_SYSTEM_UPDATE',

  /** Mazání systému třetí strany */
  THIRD_PARTY_SYSTEM_DELETE = 'THIRD_PARTY_SYSTEM_DELETE',
}

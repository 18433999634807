import React, { useRef, useState, useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormContext,
  useEventCallback,
  SnackbarContext,
  AbortableFetch,
  abortableFetch,
  PromptContext,
  usePrompts,
  NavigationContext,
} from '@eas/common-web';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { EvidenceAPI, Messages } from '../../../enums';
import { ProductionSite } from '../../../models';
import { ProcessForm } from '../production-site-split-types';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

interface SplitProductionSiteApiCall {
  originalProductionSite?: ProductionSite;
  newProductionSite?: ProductionSite;
}

/**
 * Api call
 *
 * @param body
 */
function callApi({
  originalProductionSite,
  newProductionSite,
}: SplitProductionSiteApiCall) {
  return abortableFetch(
    `${EvidenceAPI.PRODUCTION_SITES}/${originalProductionSite?.id}/split`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        originalProductionSite,
        newProductionSite,
      }),
    }
  );
}

const PROMPT_KEY = 'SPLIT_PRODUCTION_SITES';

export function StepSummaryActions({
  handleNext,
  handleBack,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { setFieldValue } = useContext(FormContext);
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const fetch = useRef<AbortableFetch | null>(null);
  const { unregisterPrompt } = useContext(NavigationContext);

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Rozdělení průmyslového místa',
      dialogText: 'Opravdu chcete rozdělit vybrané průmyslové místo?',
    },
  ]);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi({ ...allValues });

          const createdProductionSite = await fetch.current.json();

          showSnackbar(...Messages.ProductionSite.SPLIT.SUCCESS);

          setFieldValue('isSent', true);
          setFieldValue('newProductionSite', createdProductionSite);
          unregisterPrompt(navigationPrompt);

          handleNext();
        } catch (err) {
          const message = getErrorMessage(
            Messages.ProductionSite.SPLIT,
            err.code
          );

          showSnackbar(...message);

          throw err;
        } finally {
          setLoading(false);
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Rozdělit průmyslové místo
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <Button
          onClick={handleExit}
          className={classes.button}
          disableElevation
        >
          Zrušit
        </Button>
      </div>
    </div>
  );
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * IRZ facility request messages
 */
const REQUEST_CREATE_SUBMIT: MessageType<'ERROR' | 'COORDINATES_NOT_UNIQUE' | 'NOT_ALLOWED'> = {
  ERROR: [
    'Chyba při vytváření žádosti o registraci provozovny.',
    SnackbarVariant.ERROR,
  ],
  COORDINATES_NOT_UNIQUE: [
    'Chyba při vytváření žádosti. Provozovatel již má přiřazenou provozovnu se stejnými souřadnicemi.',
    SnackbarVariant.ERROR,
  ],
  NOT_ALLOWED: [
    'Pro vybraný subjekt nemáte oprávnění vytvořit žádost o registraci provozovny IRZ.',
    SnackbarVariant.ERROR
  ]
};

const REQUEST_CHANGE_SUBMIT: MessageType<
  'ERROR' | 'SUCCESS' | 'NO_CHANGES' | 'COORDINATES_NOT_UNIQUE'
> = {
  SUCCESS: [
    'Žádost o změnu údajů provozovny IRZ byla úspěšně odeslána.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    'Nastala chyba při odesílaní žádosti o změnu údajů.',
    SnackbarVariant.ERROR,
  ],
  NO_CHANGES: ['Provozovna je bez změny.', SnackbarVariant.ERROR],
  COORDINATES_NOT_UNIQUE: [
    'Chyba při vytváření žádosti. Provozovatel již má přiřazenou provozovnu se stejnými souřadnicemi.',
    SnackbarVariant.ERROR,
  ],
};

const REQUEST_OPERATOR_CHANGE_SUBMIT: MessageType<
  | 'SUCCESS'
  | 'ERROR'
  | 'OPERATOR_IDENTIFIER_DOES_NOT_MATCH'
  | 'COORDINATES_NOT_UNIQUE'
  | 'FACILITY_NOT_FOUND'
  | 'FUTURE_OPERATION_PRESENT'
  | 'SUBJECT_DOEST_NOT_EXIST'
> = {
  SUCCESS: [
    'Žádost o změnu provozovatele byla úspěšně odeslána.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    'Nastala chyba při odesílaní žádosti o změnu provozovatele.',
    SnackbarVariant.ERROR,
  ],
  OPERATOR_IDENTIFIER_DOES_NOT_MATCH: [
    'Identifikátor provozovatele se neshoduje s provozovatelem zadané provozovny.',
    SnackbarVariant.ERROR,
  ],
  COORDINATES_NOT_UNIQUE: [
    'Chyba při vytváření žádosti. Provozovatel již má přiřazenou provozovnu se stejnými souřadnicemi.',
    SnackbarVariant.ERROR,
  ],
  FACILITY_NOT_FOUND: [
    'Zadané údaje nebo jejich kombinace nejsou správné.',
    SnackbarVariant.ERROR,
  ],
  FUTURE_OPERATION_PRESENT: [
    'Nelze podat žádost o změnu provozovatele, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
  SUBJECT_DOEST_NOT_EXIST: [
    'Subjekt se zadaným identifikátorem není v systému CRŽP evidován.',
    SnackbarVariant.ERROR,
  ]
};

const REJECT: MessageType = {
  SUCCESS: ['Žádost byla úspěšně zamítnuta.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zamítnutí žádosti.', SnackbarVariant.ERROR],
};

const APPROVE: MessageType<
  'SUCCESS' | 'ERROR' | 'FUTURE_OPERATION_PRESENT' | 'COORDINATES_NOT_UNIQUE' | 'REQUEST_DATE_CANT_BE_IN_FUTURE'
> = {
  SUCCESS: ['Žádost byla úspěšně schválena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při schvalování žádosti.', SnackbarVariant.ERROR],
  COORDINATES_NOT_UNIQUE: [
    'Chyba při schválení žádosti. Provozovatel již má přiřazenou provozovnu se stejnými souřadnicemi.',
    SnackbarVariant.ERROR,
  ],
  FUTURE_OPERATION_PRESENT: [
    'Žádost nelze schválit, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
  REQUEST_DATE_CANT_BE_IN_FUTURE: ['Vybrané datum nesmí být starší než dnešní datum.', SnackbarVariant.ERROR],
};

const IRZ_FACILITY_PRELOAD: MessageType<'ERROR'> = {
  ERROR: ['Chyba při načítaní provozovny.', SnackbarVariant.ERROR],
};

export default {
  REQUEST_CREATE_SUBMIT,
  REQUEST_CHANGE_SUBMIT,
  REQUEST_OPERATOR_CHANGE_SUBMIT,
  REJECT,
  APPROVE,
  IRZ_FACILITY_PRELOAD,
};

import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  BreadcrumbCtx,
  initialBreadcrumbs,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { useVerifyEmail } from './hook/verify-email-hook';
import { PageKey } from '../../enums';
import { UserContext } from '@eas/common-web';

export const useStyles = makeStyles((theme) =>
  createStyles({
    actions: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '18px',
    },
    buttonText: {
      color: 'white',
      fontWeight: 600,
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
    },
    warning: {
      color: theme.palette.error.main,
      fontWeight: 600,
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '20px',
    },
  })
);

export function EmailVerification() {
  const classes = useStyles();

  const { setBreadcrumbs } = useContext(BreadcrumbCtx);
  const { user } = useContext(UserContext);

  const { push } = useHistory();
  const { loading, emailIsValid, error } = useVerifyEmail();

  useEffect(() => {
    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Ověření e-mailu',
        key: PageKey.EMAIL_VERIFICATION,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = () => {
    push(user ? '/crzp' : '/prihlaseni');
  };

  return (
    <>
      {!emailIsValid && !loading && (
        <Typography variant="body1" align="center" className={classes.warning}>
          {error?.code === 'USER_DETAILS_NOT_UNIQUE'
            ? 'Potvrzením emailu by vznikl duplicitní uživatel, nelze akci dokončit.'
            : `Při ověřování e-mailu došlo k chybě: ${error?.message}`}
        </Typography>
      )}
      {emailIsValid && !loading && (
        <Typography variant="body1" align="center">
          E-mail byl úspěšně ověřen, pokračujte přihlášením do aplikace.
        </Typography>
      )}
      <div className={classes.actions}>
        <Button
          onClick={handleRedirect}
          variant="contained"
          disableElevation
          color="primary"
        >
          <Typography
            component="span"
            variant="body1"
            className={classes.buttonText}
          >
            {user ? 'Přejít do aplikace' : 'Přejít k přihlášení'}
          </Typography>
        </Button>
      </div>
    </>
  );
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * DIRP messages
 */
const LAW_CREATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'DICTIONARY_CODE_NOT_UNIQUE'> = {
  ERROR: ['Chyba při vytváření záznamu.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  DICTIONARY_CODE_NOT_UNIQUE: ['Zákon se zadaným číslem již existuje.', SnackbarVariant.ERROR]
}

const LAW_UPDATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'DICTIONARY_CODE_NOT_UNIQUE'> = {
  ERROR: ['Chyba při editaci záznamu.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  DICTIONARY_CODE_NOT_UNIQUE: ['Zákon se zadaným číslem již existuje.', SnackbarVariant.ERROR]
}

const PARAGRAPH_CREATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'NAME_NOT_UNIQUE'> = {
  ERROR: ['Chyba při vytváření záznamu.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  NAME_NOT_UNIQUE: ['Pro vybraný zákon již paragraf se zadaným číslem existuje.', SnackbarVariant.ERROR],
}
  
const PARAGRAPH_UPDATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'NAME_NOT_UNIQUE'> = {
  ERROR: ['Chyba při editaci záznamu.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  NAME_NOT_UNIQUE: ['Pro vybraný zákon již paragraf se zadaným číslem existuje.', SnackbarVariant.ERROR],
}
  
export default { LAW_CREATE, LAW_UPDATE, PARAGRAPH_CREATE, PARAGRAPH_UPDATE };

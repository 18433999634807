import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useRef,
  RefAttributes,
  ReactElement,
} from 'react';
import { Form, FormHandle } from '@eas/common-web';
import { ProcessStepProps } from './process-types';

export const ProcessStep = forwardRef(function ProcessStep<FORM>(
  props: ProcessStepProps<FORM>,
  ref: Ref<any>
) {
  const formRef = useRef<FormHandle>(null);

  useImperativeHandle(
    ref,
    () => ({
      validate: async () => {
        if (formRef.current) {
          return await formRef.current.validateForm();
        }
      },
      getValues: () => {
        if (formRef.current) {
          return formRef.current.getFieldValues();
        }
      },
    }),
    []
  );

  return (
    <>
      <Form<Partial<FORM>>
        editing={true}
        ref={formRef}
        initialValues={props.stepValues}
        onSubmit={() => {}}
        validationSchema={props.validationSchema}
      >
        {(formikProps) => <>{props.children(formikProps as any)}</>}
      </Form>
    </>
  );
}) as <FORM>(
  props: ProcessStepProps<FORM> & RefAttributes<unknown>
) => ReactElement;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Title } from '../../components/title/title';
import { formFieldFactory, FormSubmitButton, Form } from '@eas/common-web';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTwoFactorCode } from './two-factor-code-hook';
import { TwoFactorCodeData } from './two-factor-code-types';
import { protectedFieldFactory } from '../../components/form/protected-field/protected-field';
import { ProtectedFieldWrapper } from '../../components/form/protected-field/protected-field-wrapper';
import Box from '@material-ui/core/Box';
import { useStyles as useStylesForm } from '../profile/profile-styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { ProtectedPage } from '../../components/protected-page/protected-page';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonText: {
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
    },
    buttonTextPrimary: {
      fontWeight: 600,
      color: 'white',
    },
    input: {
      background: 'none',
    },
  })
);

export function TwoFactorCode() {
  const classes = useStyles();
  const classesForm = useStylesForm();

  const CodeField = formFieldFactory(
    protectedFieldFactory(),
    ProtectedFieldWrapper
  );

  const {
    validationSchema,
    ref,
    loading,
    handleSubmit,
    handleNavigateBack,
  } = useTwoFactorCode();

  return (
    <ProtectedPage>
      <Container maxWidth="sm">
        <Title name="Dvoufaktorové ověření" />

        <Form<TwoFactorCodeData>
          ref={ref}
          editing={true}
          validationSchema={validationSchema}
          initialValues={{ code: '' }}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={4} justify="center">
            <Grid item xs={12} sm={10}>
              <Typography align="center">
                Zadejte prosím kód pro přihlášení, který jsme vám poslali na
                e-mail nebo telefon (podle vašeho nastavení).
              </Typography>
            </Grid>

            <Box className={classesForm.formBox} mb={3}>
              <CodeField name="code" label="Ověřovací kód" required />
            </Box>

            <Grid item xs={12}>
              <Typography align="center" component="div">
                <Button
                  variant="contained"
                  color="default"
                  disableElevation
                  onClick={handleNavigateBack}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.buttonText}
                  >
                    Znovu se přihlásit
                  </Typography>
                </Button>
                <FormSubmitButton
                  variant="contained"
                  disableElevation
                  disabled={loading}
                  color="primary"
                  type="submit"
                  style={{
                    marginLeft: 32,
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(
                      classes.buttonText,
                      classes.buttonTextPrimary
                    )}
                  >
                    Pokračovat
                  </Typography>
                </FormSubmitButton>
              </Typography>
            </Grid>
          </Grid>
        </Form>
      </Container>
    </ProtectedPage>
  );
}

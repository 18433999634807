import React, { useEffect, useContext } from 'react';
import {
  FormPanel,
  ApiFilterOperation,
  FormContext,
  FormAutocomplete,
  useFormSelector,
} from '@eas/common-web';
import { ProcessForm } from '../irz-facility-requests-new-types';
import { useCompetentControlAuthorities } from './step-control-authorities-api';
import { StepControlAuthoritiesForm } from './step-control-authorities-types';

export function StepControlAuthoritiesContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  const { setFieldValue } = useContext(FormContext);
  const { competentControlAuthorities } = useFormSelector(
    (data: StepControlAuthoritiesForm) => ({
      competentControlAuthorities: data.competentControlAuthorities,
    })
  );

  const allAuthorities = useCompetentControlAuthorities();
  const districtAuthorities = useCompetentControlAuthorities({
    filters: [
      {
        field: 'districts.id',
        operation: ApiFilterOperation.EQ,
        value: allValues.address?.districtRuian?.id,
      },
    ],
  });

  useEffect(() => {
    const getAuthorities = async () => {
      await districtAuthorities.loadMore();
    };

    getAuthorities();

    if (districtAuthorities.items.length > 0 && !competentControlAuthorities) {
      setFieldValue('competentControlAuthorities', districtAuthorities.items);
    }
  }, [districtAuthorities]);

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete
        label="Kompetentní kontrolní a ostatní orgány"
        source={allAuthorities}
        name="competentControlAuthorities"
        multiple
      />
    </FormPanel>
  );
}

import { TableColumn, ApiFilterOperation, TableCells } from '@eas/common-web';
import { UserRegistration } from '../../models';

export function useColumns(): TableColumn<UserRegistration>[] {
  return [
    {
      datakey: 'username',
      name: 'Uživatelské jméno',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },

    {
      datakey: 'firstName.value',
      name: 'Jméno',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'lastName.value',
      name: 'Příjmení',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'phoneNumber.number.value',
      name: 'Telefonní číslo',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'email.value',
      name: 'Email',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

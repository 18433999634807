import React, { useEffect, useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Form, formFieldFactory, FormSubmitButton } from '@eas/common-web';

import {
  initialBreadcrumbs,
  BreadcrumbCtx,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { useLogin } from './hook/login-hook';

import { PageKey } from '../../enums';
import {
  publicFieldFactory,
  EmptyWrapper,
} from '../../components/form/public-field/public-field';
import { CaptchaWrapper } from '../../components/captcha/captcha-wrapper';
import { LoginData } from './auth-types';
import { Link } from 'react-router-dom';
import { AuthType } from '../../components/auth/auth-types';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonText: {
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
      color: 'white',
      fontWeight: 600,
    },
  })
);

const LoginFields = {
  UsernameField: formFieldFactory(
    publicFieldFactory({ label: 'Uživatelské jméno' }),
    EmptyWrapper
  ),
  PasswordField: formFieldFactory(
    publicFieldFactory({
      label: 'Heslo',
      inputProps: {
        type: 'password',
      },
    }),
    EmptyWrapper
  ),
};

export function Login() {
  return (
    <CaptchaWrapper>
      <LoginWithoutCaptcha />
    </CaptchaWrapper>
  );
}

function LoginWithoutCaptcha() {
  const classes = useStyles();

  const { setBreadcrumbs } = useContext(BreadcrumbCtx);

  useEffect(() => {
    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Přihlášení',
        key: PageKey.LOGIN,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    ref,
    handleSubmit,
    validationSchema,
    getFieldError,
    type,
  } = useLogin();

  if (type === AuthType.SAML) {
    return null;
  }

  return (
    <>
      <Form<LoginData>
        ref={ref}
        editing={true}
        validationSchema={validationSchema}
        initialValues={{ username: '', password: '' }}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={5}>
          <Grid item md={4} xs={1} />
          <Grid item md={4} xs={10}>
            <LoginFields.UsernameField
              name="username"
              error={getFieldError('username') ? true : false}
              helperText={getFieldError('username')}
            />
          </Grid>
          <Grid item md={4} xs={1} />

          <Grid item md={4} xs={1} />
          <Grid item md={4} xs={10}>
            <LoginFields.PasswordField
              name="password"
              error={getFieldError('password') ? true : false}
              helperText={getFieldError('password')}
            />
          </Grid>
          <Grid item md={4} xs={1} />
          {process.env.NODE_ENV === 'development' && (
            <>
              <Grid item md={4} xs={1} />
              <Grid item md={4} xs={10}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  align="center"
                >
                  <table style={{ width: '100%', textAlign: 'left' }}>
                    <tbody>
                      <tr>
                        <td>Username:</td>
                        <td>
                          <pre>superadmin</pre>
                        </td>
                      </tr>
                      <tr>
                        <td>Password:</td>
                        <td>
                          <pre>A8~/bhsdRVJUzuP7Ca*G</pre>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Typography>
              </Grid>
              <Grid item md={4} xs={1} />
            </>
          )}

          <Grid item md={4} xs={1} />
          <Grid item md={4} xs={10}>
            <Link to="/obnova-hesla">
              <Typography
                variant="subtitle2"
                color="textPrimary"
                align="center"
                style={{ textDecoration: 'underline' }}
              >
                Zapomněli jste heslo?
              </Typography>
            </Link>
          </Grid>
          <Grid item md={4} xs={1} />

          <Grid item md={4} xs={1} />
          <Grid item md={4} xs={10}>
            <Typography align="center">
              <FormSubmitButton
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
              >
                <Typography
                  component="span"
                  variant="body1"
                  className={classes.buttonText}
                >
                  Přihlásit se
                </Typography>
              </FormSubmitButton>
            </Typography>
          </Grid>
          <Grid item md={4} xs={1} />
        </Grid>
      </Form>
    </>
  );
}

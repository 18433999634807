import React from 'react';
import { CompareIrzRequestProps } from './compare-irz-request-types';
import { CompareText } from '../comparator-fields/compare-text';
import { CompareLocation } from '../comparator-fields/compare-location';
import { CompareTable } from '../comparator-fields/compare-table';
import { CompareBoolean } from '../comparator-fields/compare-boolean';

export function CompareIrzRequestFields({
  originalData,
  requestData,
  showOnlyAddress = false,
}: CompareIrzRequestProps) {
  if (showOnlyAddress) {
    return (
      <>
        <CompareLocation
          newLocation={requestData?.wgs84Coordinates}
          originalLocation={originalData?.wgs84Coordinates}
        />
        <CompareText
          label="Stát"
          originalValue={originalData?.address?.country?.name}
          newValue={requestData?.address?.country?.name}
        />

        <CompareText
          label="Kraj (RÚIAN)"
          originalValue={originalData?.address?.regionRuian?.name}
          newValue={requestData?.address?.regionRuian?.name}
        />

        <CompareText
          label="Kraj (vlastní)"
          originalValue={originalData?.address?.region}
          newValue={requestData?.address?.region}
        />

        <CompareText
          label="Okres (RÚIAN)"
          originalValue={originalData?.address?.districtRuian?.name}
          newValue={requestData?.address?.districtRuian?.name}
        />

        <CompareText
          label="Okres (vlastní)"
          originalValue={originalData?.address?.district}
          newValue={requestData?.address?.district}
        />

        <CompareText
          label="Obec (RÚIAN)"
          originalValue={originalData?.address?.municipalityRuian?.name}
          newValue={requestData?.address?.municipalityRuian?.name}
        />

        <CompareText
          label="Obec (vlastní)"
          originalValue={originalData?.address?.municipality}
          newValue={requestData?.address?.municipality}
        />

        <CompareText
          label="Městská část (RÚIAN)"
          originalValue={originalData?.address?.cityDistrictRuian?.name}
          newValue={requestData?.address?.cityDistrictRuian?.name}
        />

        <CompareText
          label="Městská část (vlastní)"
          originalValue={originalData?.address?.cityDistrict}
          newValue={requestData?.address?.cityDistrict}
        />

        <CompareText
          label="Část obce (RÚIAN)"
          originalValue={originalData?.address?.municipalityPartRuian?.name}
          newValue={requestData?.address?.municipalityPartRuian?.name}
        />

        <CompareText
          label="Část obce (vlastní)"
          originalValue={originalData?.address?.municipalityPart}
          newValue={requestData?.address?.municipalityPart}
        />

        <CompareText
          label="Ulice (RÚIAN)"
          originalValue={originalData?.address?.streetRuian?.name}
          newValue={requestData?.address?.streetRuian?.name}
        />

        <CompareText
          label="Ulice (vlastní)"
          originalValue={originalData?.address?.street}
          newValue={requestData?.address?.street}
        />

        <CompareText
          label="Adresní místo (RÚIAN)"
          originalValue={originalData?.address?.addressPlaceRuian?.label}
          newValue={requestData?.address?.addressPlaceRuian?.label}
        />
        <CompareText
          label="Číslo popisné"
          originalValue={originalData?.address?.houseNumber}
          newValue={requestData?.address?.houseNumber}
        />

        <CompareText
          label="Číslo orientační"
          originalValue={originalData?.address?.orientationNumber}
          newValue={requestData?.address?.orientationNumber}
        />

        <CompareText
          label="Znak čísla orientačního"
          originalValue={originalData?.address?.orientationNumberMark}
          newValue={requestData?.address?.orientationNumberMark}
        />

        <CompareText
          label="PSČ"
          originalValue={originalData?.address?.zip}
          newValue={requestData?.address?.zip}
        />
      </>
    );
  }

  return (
    <>
      <CompareText
        label="Název"
        originalValue={originalData?.name}
        newValue={requestData?.name}
      />
      <CompareText
        label="Webová stránka"
        originalValue={originalData?.webSite}
        newValue={requestData?.webSite}
      />
      <CompareText
        label="Veřejný email"
        originalValue={originalData?.publicEmail}
        newValue={requestData?.publicEmail}
      />
      <CompareText
        label="Veřejný telefon"
        originalValue={originalData?.publicPhoneNumber}
        newValue={requestData?.publicPhoneNumber}
      />
      <CompareLocation
        newLocation={requestData?.wgs84Coordinates}
        originalLocation={originalData?.wgs84Coordinates}
      />

      <CompareText
        label="CZ-NACE"
        originalValue={originalData?.czNace?.name}
        newValue={requestData?.czNace?.name}
      />

      <CompareText
        label="NACE"
        originalValue={originalData?.nace?.name}
        newValue={requestData?.nace?.name}
      />

      <CompareBoolean
        label="Provozovna/Zařízení má Integrované povolení"
        originalValue={originalData?.integratedPermission?.hasPermission}
        newValue={requestData?.integratedPermission?.hasPermission}
      />
      <CompareTable
        label="Seznam PID (IPPC)"
        originalValues={originalData?.integratedPermission?.ippcPids ?? []}
        newValues={requestData?.integratedPermission?.ippcPids ?? []}
        columns={[
          {
            name: 'PID (IPPC)',
            datakey: '',
            width: 300,
          },
        ]}
        comparator={(o1, o2) => o1 === o2}
      />

      <CompareBoolean
        label="Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi"
        originalValue={originalData?.emissionsTradingSystem?.tradesInEmissions}
        newValue={requestData?.emissionsTradingSystem?.tradesInEmissions}
      />

      <CompareTable
        label="Id zařízení (EU ETS)"
        originalValues={originalData?.emissionsTradingSystem?.ids ?? []}
        newValues={requestData?.emissionsTradingSystem?.ids ?? []}
        columns={[
          {
            name: 'Identifikátor',
            datakey: '',
            width: 300,
          },
        ]}
        comparator={(e1, e2) => e1 === e2}
      />

      <CompareTable
        label="Kompetentní kontrolní a ostatní orgány"
        originalValues={originalData?.competentControlAuthorities ?? []}
        newValues={requestData?.competentControlAuthorities ?? []}
        columns={[
          {
            name: 'Název',
            datakey: 'name',
            width: 300,
          },
        ]}
        comparator={(o1, o2) => o1?.id === o2?.id}
      />

      <CompareText
        label="Hlavní činnost dle přílohy zákona o IRZ a ISPOP"
        originalValue={originalData?.mainIrzActivity?.activity?.name}
        newValue={requestData?.mainIrzActivity?.activity?.name}
      />

      <CompareText
        label="Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP"
        originalValue={originalData?.mainIrzActivity?.facilityCount}
        newValue={requestData?.mainIrzActivity?.facilityCount}
      />

      <CompareTable
        label="Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP"
        originalValues={originalData?.secondaryIrzActivities ?? []}
        newValues={requestData?.secondaryIrzActivities ?? []}
        columns={[
          {
            name: 'Vedlejší činnost',
            datakey: 'activity.label',
            width: 300,
          },
          {
            name: 'Počet zařízení',
            datakey: 'facilityCount',
            width: 200,
          },
        ]}
        comparator={(a1, a2) =>
          a1?.activity?.id === a2?.activity?.id &&
          a1?.facilityCount === a2?.facilityCount
        }
      />

      <CompareText
        label="Hlavní E-PRTR činnost"
        originalValue={originalData?.mainEprtrActivity?.activity?.name}
        newValue={requestData?.mainEprtrActivity?.activity?.name}
      />

      <CompareText
        label="Počet zařízení hlavní E-PRTR činnosti"
        originalValue={originalData?.mainEprtrActivity?.facilityCount}
        newValue={requestData?.mainEprtrActivity?.facilityCount}
      />

      <CompareTable
        label="Seznam vedlejších E-PRTR činností"
        originalValues={originalData?.secondaryEprtrActivities ?? []}
        newValues={requestData?.secondaryEprtrActivities ?? []}
        columns={[
          {
            name: 'Vedlejší činnost',
            datakey: 'activity.label',
            width: 300,
          },
          {
            name: 'Počet zařízení',
            datakey: 'facilityCount',
            width: 200,
          },
        ]}
        comparator={(a1, a2) =>
          a1?.activity?.id === a2?.activity?.id &&
          a1?.facilityCount === a2?.facilityCount
        }
      />

      <CompareText
        label="Činnost IPPC"
        originalValue={originalData?.ippcActivity?.name}
        newValue={requestData?.ippcActivity?.name}
      />

      <CompareText
        label="Stát"
        originalValue={originalData?.address?.country?.name}
        newValue={requestData?.address?.country?.name}
      />

      <CompareText
        label="Kraj (RÚIAN)"
        originalValue={originalData?.address?.regionRuian?.name}
        newValue={requestData?.address?.regionRuian?.name}
      />

      <CompareText
        label="Kraj (vlastní)"
        originalValue={originalData?.address?.region}
        newValue={requestData?.address?.region}
      />

      <CompareText
        label="Okres (RÚIAN)"
        originalValue={originalData?.address?.districtRuian?.name}
        newValue={requestData?.address?.districtRuian?.name}
      />

      <CompareText
        label="Okres (vlastní)"
        originalValue={originalData?.address?.district}
        newValue={requestData?.address?.district}
      />

      <CompareText
        label="Obec (RÚIAN)"
        originalValue={originalData?.address?.municipalityRuian?.name}
        newValue={requestData?.address?.municipalityRuian?.name}
      />

      <CompareText
        label="Obec (vlastní)"
        originalValue={originalData?.address?.municipality}
        newValue={requestData?.address?.municipality}
      />

      <CompareText
        label="Městská část (RÚIAN)"
        originalValue={originalData?.address?.cityDistrictRuian?.name}
        newValue={requestData?.address?.cityDistrictRuian?.name}
      />

      <CompareText
        label="Městská část (vlastní)"
        originalValue={originalData?.address?.cityDistrict}
        newValue={requestData?.address?.cityDistrict}
      />

      <CompareText
        label="Část obce (RÚIAN)"
        originalValue={originalData?.address?.municipalityPartRuian?.name}
        newValue={requestData?.address?.municipalityPartRuian?.name}
      />

      <CompareText
        label="Část obce (vlastní)"
        originalValue={originalData?.address?.municipalityPart}
        newValue={requestData?.address?.municipalityPart}
      />

      <CompareText
        label="Ulice (RÚIAN)"
        originalValue={originalData?.address?.streetRuian?.name}
        newValue={requestData?.address?.streetRuian?.name}
      />

      <CompareText
        label="Ulice (vlastní)"
        originalValue={originalData?.address?.street}
        newValue={requestData?.address?.street}
      />

      <CompareText
        label="Adresní místo (RÚIAN)"
        originalValue={originalData?.address?.addressPlaceRuian?.label}
        newValue={requestData?.address?.addressPlaceRuian?.label}
      />
      <CompareText
        label="Číslo popisné"
        originalValue={originalData?.address?.houseNumber}
        newValue={requestData?.address?.houseNumber}
      />

      <CompareText
        label="Číslo orientační"
        originalValue={originalData?.address?.orientationNumber}
        newValue={requestData?.address?.orientationNumber}
      />

      <CompareText
        label="Znak čísla orientačního"
        originalValue={originalData?.address?.orientationNumberMark}
        newValue={requestData?.address?.orientationNumberMark}
      />

      <CompareText
        label="PSČ"
        originalValue={originalData?.address?.zip}
        newValue={requestData?.address?.zip}
      />
    </>
  );
}

/**
 * Agenda permissions
 */
export enum PermissionAgenda {
  /** Přehled agend */
  AGENDA_LIST = 'AGENDA_LIST',

  /** Přehled agend, které může subjekt získat vlastním rozhodnutím */
  AGENDA_LIST_SELF_ASSIGNABLE = 'AGENDA_LIST_SELF_ASSIGNABLE',

  /** Autocomplete výběr agend */
  AGENDA_AUTOCOMPLETE = 'AGENDA_AUTOCOMPLETE',

  /** Autocomplete výběr agend, které může subjekt získat vlastním rozhodnutím */
  AGENDA_AUTOCOMPLETE_SELF_ASSIGNABLE = 'AGENDA_AUTOCOMPLETE_SELF_ASSIGNABLE',

  /** Detail agendy */
  AGENDA_DETAIL = 'AGENDA_DETAIL',

  /** Vytvoření agendy */
  AGENDA_CREATE = 'AGENDA_CREATE',

  /** Editace agendy */
  AGENDA_UPDATE = 'AGENDA_UPDATE',

  /** Přiřazení agendy */
  AGENDA_ASSIGN_ALL = 'AGENDA_ASSIGN_ALL',

  /** Přiřazení agendy vlastní */
  AGENDA_ASSIGN_SELF_ASSIGNABLE = 'AGENDA_ASSIGN_SELF_ASSIGNABLE',

  /** Odebrání agendy */
  AGENDA_UNASSIGN = 'AGENDA_UNASSIGN',

  /** Zneplatnění agendy */
  AGENDA_INVALIDATE = 'AGENDA_INVALIDATE',

  /** Obnovení agendy */
  AGENDA_REVALIDATE = 'AGENDA_REVALIDATE',
}

export enum PermissionMuvSampleType {
  /** Přehled typů vzorků */
  MUV_SAMPLE_TYPE_LIST = 'MUV_SAMPLE_TYPE_LIST',

  /** Autocomplete výběr typů vzorků */
  MUV_SAMPLE_TYPE_AUTOCOMPLETE = 'MUV_SAMPLE_TYPE_AUTOCOMPLETE',

  /** Detail typu vzorku */
  MUV_SAMPLE_TYPE_DETAIL = 'MUV_SAMPLE_TYPE_DETAIL',

  /** Vytvoření nového typu vzorku */
  MUV_SAMPLE_TYPE_CREATE = 'MUV_SAMPLE_TYPE_CREATE',

  /** Editace typu vzorku */
  MUV_SAMPLE_TYPE_UPDATE = 'MUV_SAMPLE_TYPE_UPDATE',

  /** Mazání typu vzorku */
  MUV_SAMPLE_TYPE_DELETE = 'MUV_SAMPLE_TYPE_DELETE',

  /** Zneplatnění typu vzorku */
  MUV_SAMPLE_TYPE_INVALIDATE = 'MUV_SAMPLE_TYPE_INVALIDATE',

  /** Obnovení typu vzorku */
  MUV_SAMPLE_TYPE_REVALIDATE = 'MUV_SAMPLE_TYPE_REVALIDATE',
}

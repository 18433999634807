import * as Yup from 'yup';
import { StepBasicInfoForm } from './step-basic-info-types';
import {
  validateWebsite,
  validateEmailNotRequired,
} from '../../profile/profile-utils';

export function stepBasicInfoSchema() {
  return Yup.object<StepBasicInfoForm>().shape({
    operator: Yup.mixed().nullable().required('Povinné pole'),
    name: Yup.string().nullable().required('Povinné pole'),
    webSite: Yup.string()
      .nullable()
      .when('withoutWebSite', {
        is: (value) => !value,
        then: validateWebsite,
      }),
    publicEmail: validateEmailNotRequired(),
  });
}

import * as Yup from 'yup';
import {
  WaterUsagePlace,
  WaterUsagePlacesType,
  Municipality,
  Subject,
  Wgs84Coordinates,
} from '../../models';
import { BasinEnterpriseIdentifier } from '../../models/water-usage-place';
import { dms2dd, parse } from '../../utils/latlng';

export function useValidationSchema() {
  return Yup.object<WaterUsagePlace>().shape({
    vhbNumber: Yup.number().nullable().required('Povinné pole'),
    name: Yup.string().nullable().required('Povinné pole'),
    type: Yup.mixed<WaterUsagePlacesType>().nullable().required('Povinné pole'),
    ppId: Yup.object<BasinEnterpriseIdentifier>().shape({
      id: Yup.string().nullable().required('Povinné pole'),
      enterprise: Yup.string().nullable().required('Povinné pole'),
    }),
    municipality: Yup.object<Municipality>()
      .nullable()
      .required('Povinné pole'),
    operator: Yup.mixed<Subject>().nullable().required('Povinné pole'),
    wgs84Coordinates: Yup.object<Wgs84Coordinates>().shape({
      lat: Yup.string().test('', '', function (val) {
        if (!val) {
          return true;
        }

        const { createError } = this;

        try {
          dms2dd(parse(val));
        } catch (error) {
          return createError({
            path: 'wgs84Coordinates.lat',
            message: 'Nesplňuje pravidla zápisu, například 50° 4\' 31.8"',
          });
        }

        return true;
      }),
      lon: Yup.string().test('', '', function (val) {
        if (!val) {
          return true;
        }

        const { createError } = this;

        try {
          dms2dd(parse(val));
        } catch (error) {
          return createError({
            path: 'wgs84Coordinates.lon',
            message: 'Nesplňuje pravidla zápisu, například 14° 26\' 12.9901"',
          });
        }

        return true;
      }),
    }),
    // obligatorySubject: Yup.mixed<Subject>().nullable().required('Povinné pole'),
  });
}

import * as Yup from 'yup';
import {
  Role,
  DictionaryAutocomplete,
  RoleType,
  RelationshipType,
} from '../../models';

export function useValidationSchema() {
  return Yup.object<Role>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    code: Yup.string().nullable().required('Povinné pole'),
    registeredFor: Yup.mixed<DictionaryAutocomplete>()
      .nullable()
      .required('Povinné pole'),
    type: Yup.mixed<RoleType>().nullable().required('Povinné pole'),
    parentSubjectRole: Yup.mixed().when('type', {
      is: (type) => type === RoleType.USER_ROLE,
      then: Yup.mixed<Role>().nullable().required('Povinné pole'),
    }),
    relationship: Yup.mixed().when('type', {
      is: (type) => type === RoleType.RELATIONSHIP_ROLE,
      then: Yup.mixed<RelationshipType>().nullable().required('Povinné pole'),
    }),
  });
}

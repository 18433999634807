import React, { useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { ProcessForm } from '../subject-requests-types';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { UserContext } from '@eas/common-web';
import { SubjectRegistrationType } from '../../../models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function StepAgendaActions({
  activeStep,
  handleNext,
  handleBack,
  handleExit,
  handleMoveToStep,
  allValues,
}: ProcessActionsProps<ProcessForm>) {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          disableElevation
        >
          Další
        </Button>
        <Button
          disabled={activeStep === 0}
          onClick={() => {
            if (
              user?.hasNiaExternalId &&
              allValues?.type ===
                SubjectRegistrationType?.NATURAL_PERSON_REGISTRATION
            ) {
              handleMoveToStep(0);
            } else {
              handleBack();
            }
          }}
          className={classes.button}
          disableElevation
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} />
      </div>
    </div>
  );
}

import {
  TableColumn,
  ExportTemplate,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
  exportTemplatesFactory,
} from '@eas/common-web';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export function useColumns(): TableColumn<ExportTemplate>[] {
  return [
    {
      datakey: 'label',
      name: 'Označení',
      width: 200,
      CellComponent: inactiveColoredFactory<ExportTemplate>(
        TableCells.TextCell
      ),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'dataProvider',
      sortkey: 'dataProvider.name',
      filterkey: 'dataProvider.id',
      name: 'Zdroj dat',
      width: 200,
      CellComponent: inactiveColoredFactory<ExportTemplate>(
        TableCells.TextCell
      ),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        exportTemplatesFactory.useExportDataProviders
      ),
      valueMapper: TableCells.useSelectCellFactory(
        exportTemplatesFactory.useExportDataProviders
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'designProvider',
      sortkey: 'designProvider.name',
      filterkey: 'designProvider.id',
      name: 'Zdroj designu',
      width: 200,
      CellComponent: inactiveColoredFactory<ExportTemplate>(
        TableCells.TextCell
      ),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        exportTemplatesFactory.useExportDesignProviders
      ),
      valueMapper: TableCells.useSelectCellFactory(
        exportTemplatesFactory.useExportDesignProviders
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}

import {
  DetailActionbarButton,
  DetailActionButton,
  DetailMode,
  DetailToolbarButtonType,
  FormCheckboxGroup,
  PrimaryDetailActionbarButton,
  useStaticListSource,
} from '@eas/common-web';
import CloseIcon from '@material-ui/icons/Close';
import MapIcon from '@material-ui/icons/Map';
import TuneIcon from '@material-ui/icons/Tune';
import React, { useContext } from 'react';
import { OverviewMapContext } from './overview-map-provider';
import { Area } from './overview-map-types';

const useMapAreas = ({
  canSeeOvzFacilities,
  canSeeIrzFacilities,
  canSeeProductionSites,
}: {
  canSeeOvzFacilities: boolean;
  canSeeIrzFacilities: boolean;
  canSeeProductionSites: boolean;
}) => {
  const areas = [];

  if (canSeeIrzFacilities) {
    areas.push({ id: Area.IRZ_FACILITY, name: 'IRZ provozovny' });
  }

  if (canSeeOvzFacilities) {
    areas.push({ id: Area.OVZ_FACILITY, name: 'OVZ provozovny' });
  }

  if (canSeeProductionSites) {
    areas.push({ id: Area.PRODUCTION_SITE, name: 'Průmyslová místa' });
  }

  return useStaticListSource(areas);
};

export function ActionBar() {
  const {
    irzFacilitySource,
    ovzFacilitySource,
    productionSiteSource,
    setSource,
    source,
    defaultValues,
    withFilters,
    setWithFilters,
    canSeeIrzFacilities,
    canSeeOvzFacilities,
    canSeeProductionSites,
  } = useContext(OverviewMapContext);

  const areas = useMapAreas({
    canSeeIrzFacilities,
    canSeeOvzFacilities,
    canSeeProductionSites,
  });

  const getFilters = (area: Area) => {
    switch (area) {
      case Area.IRZ_FACILITY:
        return irzFacilitySource.getParams().filters ?? [];
      case Area.OVZ_FACILITY:
        return ovzFacilitySource.getParams().filters ?? [];
      case Area.PRODUCTION_SITE:
        return productionSiteSource.getParams().filters ?? [];
      default:
        return [];
    }
  };

  return (
    <>
      <DetailActionbarButton
        startIcon={<TuneIcon />}
        label="Aplikovat filtry z přehledů"
        onClick={() => {
          setWithFilters(true);
          const irzFilters = getFilters(Area.IRZ_FACILITY);
          const ovzFilters = getFilters(Area.OVZ_FACILITY);
          const productionSiteFilters = getFilters(Area.PRODUCTION_SITE);

          setSource((prev) =>
            prev.map((s) => ({
              ...s,
              filters:
                s.type === Area.IRZ_FACILITY
                  ? irzFilters
                  : s.type === Area.OVZ_FACILITY
                  ? ovzFilters
                  : productionSiteFilters,
            }))
          );
        }}
        type={DetailToolbarButtonType.PRIMARY}
      />
      {[canSeeIrzFacilities, canSeeOvzFacilities, canSeeProductionSites].filter(
        (p) => p === true
      ).length > 1 && (
        <DetailActionButton
          promptKey="CHANGE_AREA"
          buttonLabel="Upravit zobrazné oblasti"
          dialogTitle="Upravit zobrazné oblasti"
          dialogText={null}
          ButtonComponent={(props) => (
            <PrimaryDetailActionbarButton {...props} startIcon={<MapIcon />} />
          )}
          modes={[
            DetailMode.VIEW,
            DetailMode.NEW,
            DetailMode.NONE,
            DetailMode.EDIT,
          ]}
          FormFields={() => (
            <FormCheckboxGroup source={areas} name="areas" label="Oblasti" />
          )}
          submitCallback={({ areas }) => {
            const result = [];

            for (const area of areas) {
              const foundArea = source.find((a) => area === a.type);

              if (foundArea) {
                result.push(foundArea);
              } else {
                result.push({
                  ...defaultValues[area],
                  filters: withFilters ? getFilters(area) : [],
                });
              }
            }

            setSource(result);
          }}
          formInitialValues={{ areas: source.map((a) => a.type) }}
        />
      )}
      <DetailActionbarButton
        startIcon={<CloseIcon />}
        label="Zrušit filtry"
        onClick={() => {
          setWithFilters(false);
          setSource(Object.values(defaultValues));
        }}
        disabled={!withFilters}
        type={DetailToolbarButtonType.SECONDARY}
      />
    </>
  );
}

import { useDebounce } from 'use-debounce/lib';
import { useComponentSize } from '@eas/common-web';
import { useRef, useState, useLayoutEffect, RefObject } from 'react';

export function useHistoryFieldTooltip() {
  const ref = useRef<HTMLSpanElement>(null);
  const [useTooltip, setUseTooltip] = useState(false);
  const { width } = useComponentSize(ref);
  const [debounceWidth] = useDebounce(width, 250);

  useLayoutEffect(() => {
    setUseTooltip(isEllipsisActive(ref));
  }, [debounceWidth]);

  return {
    ref,
    useTooltip,
  };
}

function isEllipsisActive(e: RefObject<HTMLSpanElement>) {
  const current = e.current;
  return current !== null ? current.offsetWidth < current.scrollWidth : false;
}

import React, { useContext, useEffect } from 'react';
import {
  WebsocketContext,
  UserContext,
  useWebsocketEffect,
} from '@eas/common-web';
import { useMenuChanger } from './hook/menu-changer-hook';
import { AuthContext } from '../auth/auth-context';

/**
 * Needs to be inside of MenuBarContext.
 */
export function MenuBarItemsChanger() {
  useMenuChanger();

  const { client } = useContext(WebsocketContext);
  const { reload } = useContext(UserContext);
  const { logout } = useContext(AuthContext);

  // logout when session expired
  useEffect(() => {
    if (client.current) {
      client.current.onWebSocketError = (iframe) => {
        console.log('onWebSocketError', iframe);
      };
      client.current.onStompError = (iframe) => {
        console.log('onStompError', iframe);
      };
      client.current.onWebSocketClose = (event) => {
        if (event.code === 1008) {
          console.log(event);
          // sessoin has expired, logout
          logout();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useWebsocketEffect(() => {
    reload();
    // console.log('message SESSION_REFRESHED');
  }, ['SESSION_REFRESHED']);

  useWebsocketEffect(() => {
    logout();
  }, ['SESSION_DESTROYED']);

  // useWebsocketEffect(() => {
  //   console.log('message NOTIFICATION_SENT');
  // }, ['NOTIFICATION_SENT'])

  return <></>;
}

import { useRef, useContext, useState } from 'react';
import {
  FormHandle,
  AbortableFetch,
  SnackbarContext,
  useEventCallback,
  abortableFetch,
  NavigationContext,
  UserContext,
} from '@eas/common-web';
import { TwoFactorCodeData } from './two-factor-code-types';
import * as Yup from 'yup';
import { unstable_batchedUpdates } from 'react-dom';
import { AuthContext } from '../../components/auth/auth-context';
import { Messages } from '../../enums';

export function useTwoFactorCode() {
  const { showSnackbar } = useContext(SnackbarContext);
  const { navigate } = useContext(NavigationContext);
  const { reload } = useContext(UserContext);
  const { logout } = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);

  const ref = useRef<FormHandle<TwoFactorCodeData>>(null);

  const fetch = useRef<AbortableFetch | null>(null);

  const validationSchema = Yup.object<TwoFactorCodeData>().shape({
    code: Yup.string().nullable().required('Ověřovací kód musí být vyplněný'),
  });

  const callSubmit = (data: TwoFactorCodeData) => {
    return abortableFetch(`/api/crzp/two-factor/${data.code}/challenge`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        ...data,
      }),
    });
  };

  const submitCode = useEventCallback(async (data: TwoFactorCodeData) => {
    try {
      setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callSubmit(data);

      await fetch.current.raw();

      unstable_batchedUpdates(() => {
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);

      if (e.name !== 'AbortError') {
        showSnackbar(...Messages.User.TWO_FACTOR_AUTH.AUTH_ERROR);

        throw e;
      }
      return undefined;
    }
  });

  const handleSubmit = useEventCallback(async (data: TwoFactorCodeData) => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      if (errors.length > 0) {
        showSnackbar(...Messages.User.TWO_FACTOR_AUTH.ERROR);
        setLoading(false);
        return;
      }

      await submitCode(data);

      showSnackbar(...Messages.User.TWO_FACTOR_AUTH.SUCCESS);

      await reload();

      navigate('/crzp');
    }
  });

  const handleNavigateBack = useEventCallback(() => {
    logout();
  });

  return {
    ref,
    validationSchema,
    loading,
    handleSubmit,
    handleNavigateBack,
  };
}

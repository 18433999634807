import { useContext, useMemo } from 'react';
import { get, noop } from 'lodash';
import {
  useFormSelector,
  useEventCallback,
  FormContext,
} from '@eas/common-web';
import { Address, AddressPlace } from '../../../../models';
import { unstable_batchedUpdates } from 'react-dom';

export function useAddressField({
  name,
  disableRuian,
  notifyPrefill = noop,
}: {
  name: string;
  disableRuian: boolean;
  notifyPrefill?: (place: AddressPlace) => void;
}) {
  const { setFieldValue } = useContext(FormContext);

  const { isRuian, isForeign } = useFormSelector(
    (data: { [key: string]: Address }) => ({
      isRuian: (get(data, name) as Address)?.country?.ruian ?? false,
      isForeign: (get(data, name) as Address)?.country?.ruian === false,
    })
  );

  /**
   * Callback to preFill the address based on the addressPlace
   */
  const prefillAddress = useEventCallback((addressPlace: AddressPlace) => {
    if (addressPlace) {
      unstable_batchedUpdates(() => {
        setFieldValue(`${name}.addressPlaceRuian`, {
          ...addressPlace,
          parent: addressPlace.street?.id,
        });

        setFieldValue(`${name}.zip`, addressPlace.zip);
        setFieldValue(
          `${name}.orientationNumberMark`,
          addressPlace.orientationNumberMark
        );
        setFieldValue(
          `${name}.orientationNumber`,
          addressPlace.orientationNumber
        );
        setFieldValue(`${name}.houseNumberType`, addressPlace.houseNumberType);
        setFieldValue(`${name}.houseNumber`, addressPlace.houseNumber);

        setFieldValue(`${name}.street`, undefined);
        if (addressPlace.street) {
          setFieldValue(`${name}.streetRuian`, {
            ...addressPlace.street,
            parent: addressPlace.municipality?.id,
          });
        } else {
          setFieldValue(`${name}.streetRuian`, undefined);
        }

        setFieldValue(`${name}.municipalityPart`, undefined);
        if (addressPlace.municipalityPart) {
          setFieldValue(`${name}.municipalityPartRuian`, {
            ...addressPlace.municipalityPart,
            parent: addressPlace.municipality?.id,
          });
        } else {
          setFieldValue(`${name}.municipalityPartRuian`, undefined);
        }

        setFieldValue(`${name}.cityDistrict`, undefined);
        if (addressPlace.cityDistrict) {
          setFieldValue(`${name}.cityDistrictRuian`, {
            ...addressPlace.cityDistrict,
            parent: addressPlace.municipality?.id,
          });
        } else {
          setFieldValue(`${name}.cityDistrictRuian`, undefined);
        }

        notifyPrefill(addressPlace);
      });
    }
  });

  return useMemo(
    () => ({
      isRuian,
      isForeign: isForeign || disableRuian,
      prefillAddress,
    }),
    [isForeign, disableRuian, isRuian, prefillAddress]
  );
}

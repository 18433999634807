import React from 'react';
import {
  FormPanel,
  FormTextField,
  useFormSelector,
  FormAutocomplete,
  FormInlineTableField,
  FormCheckbox,
  FormCheckboxGroup,
} from '@eas/common-web';
import { StepEditForm } from './step-edit-types';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { useNaceItems } from '../../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import { MainActivityType, ActivityRecord } from '../../../models';
import { useMainActivities } from '../../irz-facility/irz-facilities-api';
import {
  useSecondaryEPrtrActivityColumns,
  useSecondaryIrzActivityColumns,
  useTradesInEmissionsColumns,
  useIppcPidColumns,
} from './step-edit-columns';
import { ProcessForm } from '../irz-facility-request-change-types';
import { useStepEdit } from './step-edit-hook';
import { useCompetentControlAuthoritiesColumns } from '../../irz-facility/irz-facilities-columns';
import { HelpLabel } from '../../../components/help/help-label';

export function StepEditContent({ allValues }: { allValues: ProcessForm }) {
  useStepEdit(allValues);

  const types = useMainActivities();
  const naceItems = useNaceItems();
  const naceCzItems = useNaceCzItems();

  const {
    wgs84Coordinates,
    activityTypes,
    irzId,
    tradesInEmissions,
    competentControlAuthorities,
  } = useFormSelector(({ requestFacilityData }: StepEditForm) => ({
    wgs84Coordinates: requestFacilityData?.wgs84Coordinates,
    activityTypes: requestFacilityData?.activityTypes,
    irzId: requestFacilityData?.irzId,
    competentControlAuthorities:
      requestFacilityData?.competentControlAuthorities,
    tradesInEmissions:
      requestFacilityData?.emissionsTradingSystem?.tradesInEmissions,
  }));

  const secondaryEPrtrActivitiesColumns = useSecondaryEPrtrActivityColumns();
  const secondaryIrzActivitiesColumns = useSecondaryIrzActivityColumns();
  const ippcPidColumns = useIppcPidColumns();
  const tradesInEmissionsColumns = useTradesInEmissionsColumns();
  const competentControlAuthoritiesColumns = useCompetentControlAuthoritiesColumns(
    'requestFacilityData.competentControlAuthorities'
  );

  return (
    <>
      {irzId && (
        <>
          <FormPanel label="Základní informace">
            <FormTextField
              name="requestFacilityData.name"
              label={
                <HelpLabel
                  label="Název"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_NAME"
                />
              } // Povinné pole
              disabled
            />
            <FormTextField
              name="requestFacilityData.webSite"
              label={
                <HelpLabel
                  label="Web stránka"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_WEB_SITE"
                />
              } // Povinné pole, musí být platný odkaz url
              disabled
            />
            <FormTextField
              name="requestFacilityData.publicEmail"
              label={
                <HelpLabel
                  label="Veřejný e-mail"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_PUBLIC_EMAIL"
                />
              }
              disabled
            />
            <FormTextField
              name="requestFacilityData.publicPhoneNumber"
              label={
                <HelpLabel
                  label="Veřejný telefon"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_PUBLIC_PHONE_NUMBER"
                />
              }
              disabled
            />
          </FormPanel>
          <LocationPanel
            locationName="requestFacilityData.wgs84Coordinates"
            locationValue={wgs84Coordinates}
            addressName="requestFacilityData.address"
            disableForeignCountry={true}
            locationPanelLabel="Poloha provozovny"
            addressPanelLabel="Adresa provozovny"
            prefillCoordinates={false}
          />
          <FormPanel label="Klasifikace, Identifikátory">
            <FormAutocomplete
              name="requestFacilityData.czNace"
              label={
                <HelpLabel
                  label="CZ-NACE"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_CZ_NACE"
                />
              } // Povinné pole
              source={naceCzItems}
              tooltipMapper={(o) => o.name}
              disabled
            />
            <FormAutocomplete
              name="requestFacilityData.nace"
              label={
                <HelpLabel
                  label="NACE"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_NACE"
                />
              } // Povinné pole
              source={naceItems}
              tooltipMapper={(o) => o.name}
              disabled
            />
            <FormInlineTableField<string>
              name="requestFacilityData.integratedPermission.ippcPids"
              label={
                <HelpLabel
                  label="Seznam IPPC PID"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_IPPC_PIDS"
                />
              } // Musí začínat písmeny 'MZP' a následovat 9 velkých alfanumerických znaků
              columns={ippcPidColumns}
              initNewItem={() => ''}
              disabled
            />
            <FormCheckbox
              name="requestFacilityData.emissionsTradingSystem.tradesInEmissions"
              label={
                <HelpLabel
                  label="Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_EMISSIONS_TRADING_SYSTEM_TRADES_IN_EMISSIONS"
                />
              }
              disabled
            />
            {tradesInEmissions === true && (
              <FormInlineTableField<string>
                name="requestFacilityData.emissionsTradingSystem.ids"
                label={
                  <HelpLabel
                    label="ID zařízení (EU ETS)"
                    code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_EMISSIONS_TRADING_SYSTEM_IDS"
                  />
                } // Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9
                columns={tradesInEmissionsColumns}
                initNewItem={() => ''}
                disabled
              />
            )}
          </FormPanel>

          <FormPanel label="Činnosti">
            <FormCheckboxGroup
              label={
                <HelpLabel
                  label="Typ činnosti"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_ACTIVITY_TYPES"
                />
              } // Povinné pole
              source={types}
              name="requestFacilityData.activityTypes"
              disabled
            />
            {activityTypes?.includes(MainActivityType.E_PRTR) && (
              <>
                <FormTextField
                  name="requestFacilityData.mainEprtrActivity.activity.label"
                  label={
                    <HelpLabel
                      label="Hlavní E-PRTR činnost"
                      code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_MAIN_EPRTR_ACTIVITY_ACTIVITY"
                    />
                  } // Povinné pole
                  disabled
                />
                <FormTextField
                  name="requestFacilityData.mainEprtrActivity.facilityCount"
                  label={
                    <HelpLabel
                      label="Počet zařízení hlavní E-PRTR činnosti"
                      code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_MAIN_EPRTR_ACTIVITY_FACILITY_COUNT"
                    />
                  }
                  disabled
                />
                <FormInlineTableField<ActivityRecord>
                  name="requestFacilityData.secondaryEprtrActivities"
                  label={
                    <HelpLabel
                      label="Seznam vedlejších E-PRTR činností"
                      code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_SECONDARY_EPRTR_ACTIVITIES"
                    />
                  } // Povinné pole, nesmí obsahovat hlavní činnost
                  columns={secondaryEPrtrActivitiesColumns}
                  maxRows={5}
                  disabled
                />
              </>
            )}
            {activityTypes?.includes(MainActivityType.IRZ) && (
              <>
                {!activityTypes?.includes(MainActivityType.E_PRTR) && (
                  <>
                    <FormTextField
                      name="requestFacilityData.mainIrzActivity.activity.label"
                      label={
                        <HelpLabel
                          label="Hlavní činnost dle přílohy zákona o IRZ a ISPOP"
                          code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_MAIN_IRZ_ACTIVITY_ACTIVITY"
                        />
                      } // Povinné pole
                      disabled
                    />
                    <FormTextField
                      name="requestFacilityData.mainIrzActivity.facilityCount"
                      label={
                        <HelpLabel
                          label="Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP"
                          code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_MAIN_IRZ_ACTIVITY_FACILITY_COUNT"
                        />
                      } // Povinné pole, nejméně 1 zařízení
                      disabled
                    />
                  </>
                )}
                <FormInlineTableField<ActivityRecord>
                  name="requestFacilityData.secondaryIrzActivities"
                  label={
                    <HelpLabel
                      label="Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP"
                      code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_SECONDARY_IRZ_ACTIVITIES"
                    />
                  } // Povinné pole, nesmí obsahovat hlavní činnost
                  columns={secondaryIrzActivitiesColumns}
                  maxRows={5}
                  disabled
                />
              </>
            )}

            <FormTextField
              name="requestFacilityData.ippcActivity.label"
              label={
                <HelpLabel
                  label="Činnost IPPC"
                  code="IRZ_FACILITY_REQUEST_CHANGE_REQUEST_FACILITY_DATA_IPPC_ACTIVITY"
                />
              }
              disabled
            />
            <FormPanel label="Kompetentní kontrolní a ostatní orgány">
              <FormInlineTableField
                name="requestFacilityData.competentControlAuthorities"
                labelOptions={{
                  hide: true,
                }}
                layoutOptions={{ noSpacing: true }}
                disabledAdd={
                  competentControlAuthorities &&
                  competentControlAuthorities?.length >= 3
                }
                maxRows={3}
                columns={competentControlAuthoritiesColumns}
                disabled
              />
            </FormPanel>
          </FormPanel>
        </>
      )}
    </>
  );
}

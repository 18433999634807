import React, { useContext, useRef } from 'react';
import {
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailContext,
  FormDateField,
  abortableFetch,
  UserContext,
  PromptContext,
  usePrompts,
} from '@eas/common-web';
import { unstable_batchedUpdates } from 'react-dom';
import { EvidenceAPI, Permission, Messages } from '../../../enums';

/**
 * Api call
 *
 * @param id
 * @param values
 */
export function callApi(id: string, values: { validTo?: string }) {
  return abortableFetch(`${EvidenceAPI.BOILER_PERMISSIONS}/${id}/active`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
    body: JSON.stringify(values),
  });
}

/**
 * Zneplatnění oprávnění OZO
 */
const PROMPT_KEY = 'DEACTIVATE';

export function useDeactivateDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Zneplatnění oprávnění OZO',
      dialogText: 'Skutečně chcete zneplatnit oprávnění OZO?',
      FormFields: function Body() {
        return (
          <>
            <FormDateField
              name="validTo"
              label="Datum odkdy bude oprávnění zneplatněno"
            />
          </>
        );
      },
      dialogWidth: 600,
    },
  ]);

  const handleDeactivate = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { validTo?: string }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callApi(source.data!.id, values);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.OzoPermission.DEACTIVATE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.OzoPermission.DEACTIVATE.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showDeactivateButton =
    (hasPermission(Permission.Ozo.OZO_PERMISSION_UPDATE_ALL) ||
      hasPermission(Permission.Ozo.OZO_PERMISSION_UPDATE_OWN, {
        searchInSubjectPerms: true,
      })) &&
    source.data?.active;

  return {
    handleDeactivate,
    showDeactivateButton,
  };
}

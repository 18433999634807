import { useContext, useEffect } from 'react';
import {
  CrudSource,
  SnackbarContext,
  useEventCallback,
  deactivateItem,
  activateItem,
  PromptContext,
  Prompt,
  UserContext,
  NavigationContext,
} from '@eas/common-web';
import { ProductionSite } from '../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { useAbortableFetch } from '../../utils/abortable-fetch';
import { Permission, EvidenceUrl, Messages } from '../../enums';
import { ProductionSiteMergeStateAction } from '../production-site-merge/production-site-merge-types';
import { ProductionSiteSplitStateAction } from '../production-site-split/production-site-split-types';

export function useProductionSite({
  source,
  onPersisted,
}: {
  source: CrudSource<ProductionSite>;
  onPersisted: (id: string | null) => void;
}) {
  const { showSnackbar } = useContext(SnackbarContext);
  const { registerPrompts, unregisterPrompts, testPrompt } = useContext(
    PromptContext
  );
  const { navigate } = useContext(NavigationContext);
  const { hasPermission } = useContext(UserContext);

  const isActive = source.data?.active;

  const canInvalidate = hasPermission(
    Permission.ProductionSite.PRODUCTION_SITE_INVALIDATE
  );

  const canRevalidate = hasPermission(
    Permission.ProductionSite.PRODUCTION_SITE_REVALIDATE
  );

  const showMergeButton =
    isActive && hasPermission(Permission.ProductionSite.PRODUCTION_SITE_MERGE);

  const showSplitButton =
    isActive && hasPermission(Permission.ProductionSite.PRODUCTION_SITE_SPLIT);

  useEffect(() => {
    const promptDeactivate: Prompt = {
      key: 'DEACTIVATE_PRODUCTION_SITE',
      dialogTitle: 'Zneplatnit průmyslové místo',
      dialogText: 'Skutečně chcete zneplatnit průmyslové místo?',
    };
    const promptAactivate: Prompt = {
      key: 'ACTIVATE_PRODUCTION_SITE',
      dialogTitle: 'Obnovit průmyslové místo',
      dialogText: 'Skutečně chcete obnovit průmyslové místo?',
    };
    registerPrompts([promptDeactivate, promptAactivate]);

    return () => {
      unregisterPrompts([promptDeactivate, promptAactivate]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMerge = useEventCallback(() => {
    navigate(EvidenceUrl.PRODUCTION_SITE_MERGE, false, {
      action: ProductionSiteMergeStateAction.INIT_MAIN_PS,
      data: source.data?.id,
    });
  });

  const handleSplit = useEventCallback(() => {
    navigate(EvidenceUrl.PRODUCTION_SITE_SPLIT, false, {
      action: ProductionSiteSplitStateAction.INIT_MAIN_PS,
      data: source.data?.id,
    });
  });

  const handleDeactivate = useEventCallback(() => {
    testPrompt({
      key: 'DEACTIVATE_PRODUCTION_SITE',
      submitCallback: () => {
        deactivate();
      },
    });
  });

  const handleActivate = useEventCallback(() => {
    testPrompt({
      key: 'ACTIVATE_PRODUCTION_SITE',
      submitCallback: () => {
        activate();
      },
    });
  });

  const sendDeactivate = useAbortableFetch(async (fetch) => {
    try {
      source.setLoading(true);

      fetch = deactivateItem(source.url, source.data!.id);

      await fetch.raw();

      unstable_batchedUpdates(() => {
        showSnackbar(...Messages.ProductionSite.DEACTIVATE.SUCCESS);
        source.setLoading(false);
      });

      onPersisted(source.data!.id);
      await source.refresh();
    } catch (err) {
      source.setLoading(false);

      showSnackbar(...Messages.ProductionSite.DEACTIVATE.ERROR);

      throw err;
    }
  });

  const deactivate = useEventCallback(async () => {
    await sendDeactivate?.();
  });

  const sendActivate = useAbortableFetch(async (fetch) => {
    try {
      source.setLoading(true);

      fetch = activateItem(source.url, source.data!.id);

      await fetch.raw();

      unstable_batchedUpdates(() => {
        showSnackbar(...Messages.ProductionSite.ACTIVATE.SUCCESS);
        source.setLoading(false);
      });

      onPersisted(source.data!.id);
      await source.refresh();
    } catch (err) {
      source.setLoading(false);

      showSnackbar(...Messages.ProductionSite.ACTIVATE.ERROR);

      throw err;
    }
  });

  const activate = useEventCallback(async () => {
    await sendActivate?.();
  });

  return {
    showMergeButton,
    showSplitButton,
    canInvalidate,
    canRevalidate,
    handleMerge,
    handleSplit,
    handleDeactivate,
    handleActivate,
  };
}

import React, { useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import {
  useCrudSource,
  Table,
  useScrollableSource,
  TableHandle,
} from '@eas/common-web';
import AutoSizer from 'react-virtualized-auto-sizer';
import { EvidenceAPI, PageKey } from '../../../enums';
import { PublicRecord, PublicList } from '../../../models/public-list';
import { useParams } from 'react-router-dom';
import { useColumns } from './public-records-columns';

export function PublicRecords() {
  const { listId } = useParams();

  const listSource = useCrudSource<PublicList>({
    url: EvidenceAPI.PUBLIC_LISTS,
  });

  useEffect(() => {
    listSource.get(listId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId]);

  if (listSource.data != null) {
    return <PublicRecordsInner publicList={listSource.data} />;
  } else {
    return <></>;
  }
}

function PublicRecordsInner({ publicList }: { publicList: PublicList }) {
  const { tableName, tableSource, tableRef, columns } = usePublicList(
    publicList
  );

  return (
    <Box flexGrow={1} flexShrink={1}>
      <AutoSizer disableWidth={true}>
        {({ height }) => (
          <div style={{ height, display: 'flex' }}>
            <Table<PublicRecord>
              tableId={`${PageKey.PUBLIC_RECORDS}-${publicList.id}` + '_TABLE'}
              ref={tableRef}
              source={tableSource}
              columns={columns}
              tableName={tableName}
              defaultSorts={[]}
              showSelectBox={false}
              showBulkActionButton={false}
              showReportButton={false}
              height={height}
            />
          </div>
        )}
      </AutoSizer>
    </Box>
  );
}

function usePublicList(publicList: PublicList) {
  const columns = useColumns(publicList.params);

  const tableSource = useScrollableSource<PublicRecord>({
    url: EvidenceAPI.PUBLIC_RECORDS.replace('{id}', publicList.id) + '/list',
  });

  const tableRef = useRef<TableHandle<PublicRecord>>(null);

  return {
    tableSource,
    tableRef,
    tableName: publicList.name,
    columns,
  };
}

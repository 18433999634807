import { EvidenceAPI } from './../../../../enums/evidence-api';
import { ObtainedSimpleLog, SimpleLog } from './../../../../models/simple-log';
import { useColumns } from './history-columns';
import { useScrollableSource, ListSource, Params } from '@eas/common-web';
import { post } from '../../../../utils/custom-crud';
import { parseHistoryItems } from '../../../../components/evidence/history-evidence/history-api';
import { User } from '../../../../models';

export function useHistory(profile: User) {
  const controller = new AbortController();
  const signal = controller.signal;

  const source = useScrollableSource<SimpleLog>({
    url: '',
    listItems: () => ({
      json: async () => {
        const [history] = await post<ListSource<ObtainedSimpleLog>>({
          url: `${EvidenceAPI.USERS}/${profile.id}/history`,
          signal,
          body: {
            sort: [
              {
                type: 'FIELD',
                field: 'created',
                order: 'DESC',
              },
            ],
            size: -1,
          } as Params,
        });

        // parse history
        if (history?.items) {
          history.items = history.items.map((v) => ({
            ...v,
            content: JSON.parse((v?.content ?? '[]') as any),
          }));
        }

        const historyItems = history?.items
          ? parseHistoryItems(history.items)
          : [];

        return {
          items: historyItems,
          count: historyItems.length,
        };
      },
      response: new Promise<Response>(() => {}),
      text: async () => {
        throw new Error('Unsupported operation');
      },
      raw: async () => {
        throw new Error('Unsupported operation');
      },
      none: async () => {
        throw new Error('Unsupported operation');
      },
      abort: async () => {
        throw new Error('Unsupported operation');
      },
    }),
  });

  const columns = useColumns();

  return {
    source,
    columns,
  };
}

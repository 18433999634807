import { SpecialFilter } from '../../components/evidence/history-evidence/overrides/table-searchbar';
import { SpecialFilterTextCell } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-text-cell';
import { ApiFilterOperation } from '@eas/common-web';
import { useSpecialFilterSelectCellFactory } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-select-cell';
import { useBooleans } from './production-sites-api';

export function useSpecialFilters(): SpecialFilter[] {
  return [
    {
      label: 'Identifikátor EU',
      filterkey: 'inspireId',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Název',
      filterkey: 'name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Kraj',
      filterkey: 'district.region.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Aktivní',
      filterkey: 'active',
      operation: ApiFilterOperation.EQ,
      FilterCell: useSpecialFilterSelectCellFactory(useBooleans),
    },
  ];
}

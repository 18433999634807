import { useMemo, ComponentType } from 'react';
import {
  TableColumn,
  TableCells,
  TableFilterCells,
  TableCellProps,
} from '@eas/common-web';
import { inactiveColoredFactory } from '../../form/inactive-colored/inactive-colored';
import { DatedObject } from '../../../models';

export function useColumns<OBJECT extends DatedObject>({
  columns,
  showColumn = () => true,
  columnLabelMapper = () => undefined,
  columnWrapper = inactiveColoredFactory,
}: {
  columns?: TableColumn<OBJECT>[];
  showColumn?: (col: 'CREATED' | 'UPDATED') => boolean;
  columnLabelMapper?: (col: 'CREATED' | 'UPDATED') => string | undefined;
  columnWrapper?: (
    Component: ComponentType<TableCellProps<OBJECT>>
  ) => ComponentType<TableCellProps<OBJECT>>;
}) {
  const datedColumns = useMemo(() => {
    const base = columns ?? [];

    if (showColumn('CREATED')) {
      base.push({
        datakey: 'created',
        name: columnLabelMapper('CREATED') ?? 'Vytvoření',
        width: 150,
        CellComponent: columnWrapper(TableCells.DateTimeCell),
        FilterComponent: TableFilterCells.FilterDateCell,
        sortable: true,
        filterable: true,
        filterGroup: 10,
      });
    }

    if (showColumn('UPDATED')) {
      base.push({
        datakey: 'updated',
        name: columnLabelMapper('UPDATED') ?? 'Poslední úprava',
        width: 150,
        CellComponent: columnWrapper(TableCells.DateTimeCell),
        FilterComponent: TableFilterCells.FilterDateCell,
        sortable: true,
        filterable: true,
        filterGroup: 10,
      });
    }
    return base;
  }, [columns, columnLabelMapper, showColumn]) as TableColumn<OBJECT>[];

  return datedColumns;
}

import React, { useContext, useMemo } from 'react';
import {
  FormTextField,
  FormPanel,
  FormSelect,
  useFormSelector,
  DetailMode,
  DetailHandle,
  FormCustomField,
  DetailContext,
  UserContext,
  FormDateField,
  FormCheckbox,
  FormInlineTableField,
  EmptyComponent,
  FormAutocomplete,
  ApiFilterOperation,
} from '@eas/common-web';
import {
  useSubjectRegistrationState,
  useSubjectTypes,
  useAuthorizationMethods,
} from './subject-requests-api';
import { useSubjectRegistrationTypes } from '../subject-request-new/subject-requests-api';

import { IspopDocumentFields } from './fields/ispop-document-fields';
import { GeneralFields } from './fields/general-fields';
import { SubjectRequestFileFields } from './fields/subject-request-file-fields';

import { DetailNavigationButton } from '../../components/detail-navigation-button/detail-navigation-button';
import { AddressField } from '../../components/form/address-field/address-field';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { useAddressLabel } from '../../components/form/address-field/hooks/address-label-hook';
import { FormPanelText } from '../../components/form/form-panel-summary/form-panel-text';
import { AuthoredFields } from '../../components/evidence/authored-evidence/authored-fields';
import { useExpandableFormPanel } from '../../composite/form-panel/expandable-form-panel-hook';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';

import { BusinessNaturalPersonBasicFields } from '../subject/fields/business-natural-person-fields/business-natural-person-basic-fields';
import { LegalEntityBasicFields } from '../subject/fields/legal-entity-fields/legal-entity-basic-fields';
import { BusinessNaturalPersonAdvancedFields } from '../subject/fields/business-natural-person-fields/business-natural-person-advanced-fields';
import { ForeignNaturalPersonAdvancedFields } from '../subject/fields/foreign-natural-person-fields/foreign-natural-person-advanced-fields';
import { LegalEntityAdvancedFields } from '../subject/fields/legal-entity-fields/legal-entity-advanced-fields';
import { ForeignLegalEntityAdvancedFields } from '../subject/fields/foreign-legal-entity-fields/foreign-legal-entity-advanced-fields';
import { NaturalPersonAdvancedFields } from '../subject/fields/natural-person-fields/natural-person-advanced-fields';

import {
  SubjectRegistration,
  SubjectRegistrationState,
  SubjectType,
  SubjectUnion,
  Me,
  PermissionsState,
} from '../../models';
import { EvidenceUrl, Permission } from '../../enums';
import { LegalEntityWithoutIcoBasicFields } from '../subject/fields/legal-entity-without-ico-fields/legal-entity-without-ico-basic-fields';
import { NaturalPersonBasicFields } from '../subject/fields/natural-person-fields/natural-person-basic-fields';
import { ForeignLegalEntityBasicFields } from '../subject/fields/foreign-legal-entity-fields/foreign-legal-entity-basic-fields';
import { ForeignNaturalPersonBasicFields } from '../subject/fields/foreign-natural-person-fields/foreign-natural-person-basic-fields';
import { LegalEntityWithoutIcoAdvancedFields } from '../subject/fields/legal-entity-without-ico-fields/legal-entity-without-ico-advanced-fields';
import { PhoneField } from '../../components/form/phone-field/phone-field';
import { HelpLabel } from '../../components/help/help-label';
import { IsdsIds } from '../../composite/subject-fields/subject-isds';
import { useAgendas } from '../agenda/agendas-api';

export function SubjectRequestsFields(props: NotedEvidenceFieldsProps) {
  const { mode } = useContext<DetailHandle<SubjectRegistration>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );
  const { ref: addressPanelRef } = useExpandableFormPanel();
  const { ref: advancedFieldsPanelRef } = useExpandableFormPanel();

  const subjectRegistrationState = useSubjectRegistrationState();
  const subjectRegistrationTypes = useSubjectRegistrationTypes();
  const subjectTypes = useSubjectTypes();
  const authorizationMethods = useAuthorizationMethods();

  const agendas = useAgendas({
    filters: [
      {
        field: 'selfAssignable',
        operation: ApiFilterOperation.EQ,
        value: true,
      },
    ],
  });

  const {
    approvedSubject,
    type,
    state,
    residence,
    ispopDocument,
    subjectType,
  } = useFormSelector((values: SubjectRegistration) => ({
    approvedSubject: values?.approvedSubject,
    type: values?.registrationSubject?.type,
    state: values.state,
    residence: values?.registrationSubject?.residence,
    ispopDocument: values?.ispopDocument,
    subjectType: values?.registrationSubject?.type,
  }));

  const { allowIszrSync } = useFormSelector((data: SubjectUnion) => ({
    allowIszrSync: data.allowIszrSync,
  }));

  const prefix =
    state === SubjectRegistrationState.MANUAL_FINISH_REQUIRED ||
    SubjectRegistrationState.APPROVED
      ? 'registrationSubject'
      : undefined;

  const showRedirectButton =
    mode === DetailMode.VIEW && state === SubjectRegistrationState.APPROVED;
  const isEditing = mode === DetailMode.EDIT;
  const isCreating = mode === DetailMode.NEW;

  const isAdmin = hasPermission(Permission.Subject.SUBJECT_UPDATE_ALL, {
    searchInSubjectPerms: true,
  });

  const lockedByPerms = !isAdmin || (!isCreating && !isEditing);
  const lockedBySync = lockedByPerms || allowIszrSync;

  const addressLabel = useAddressLabel(residence);

  const ceniaDetailsColumns = useMemo(
    () => [
      {
        datakey: 'number',
        name: 'Ev.č./Č.j.',
        width: 300,
      },
      {
        datakey: 'note',
        name: 'Poznámka',
        width: 300,
      },
    ],
    []
  );

  const withoutAgendaId = 'WITHOUT_AGENDA';

  return (
    <>
      <FormPanel label="Žádost o registraci subjektu">
        <FormTextField
          name="evidenceNumber"
          label={
            <HelpLabel
              label="Evidenční číslo"
              code="SUBJECT_REGISTRATION_EVIDENCE_NUMBER"
            />
          }
          disabled={true}
        />
        <FormSelect
          name="state"
          label={
            <HelpLabel label="Stav žádosti" code="SUBJECT_REGISTRATION_STATE" />
          }
          source={subjectRegistrationState}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled={true}
        />
        <FormDateField
          name="submitted"
          label={
            <HelpLabel
              label="Datum podání"
              code="SUBJECT_REGISTRATION_SUBMITTED"
            />
          }
          disabled={true}
        />
        <FormSelect
          name="type"
          label={
            <HelpLabel label="Typ subjektu" code="SUBJECT_REGISTRATION_TYPE" />
          }
          source={subjectRegistrationTypes}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled
        />
        <FormSelect
          name="authorizationMethod"
          label={
            <HelpLabel
              label="Způsob autorizace"
              code="SUBJECT_REGISTRATION_AUTHORIZATION_METHOD"
            />
          }
          source={authorizationMethods}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled
        />
        <FormCheckbox
          name="dataBoxAuthorizationOnly"
          label={
            <HelpLabel
              label="Autorizace pouze datovou schránkou"
              code="SUBJECT_REGISTRATION_DATA_BOX_AUTHORIZATION_ONLY"
            />
          }
          disabled
        />
        <FormAutocomplete
          name="agendas"
          label={
            <HelpLabel
              label="Agenda"
              code="SUBJECT_REGISTRATION_NEW_AGENDA"
              required
            /> // Povinné pole
          }
          source={{
            ...agendas,
            items: [
              { id: withoutAgendaId, name: 'Bez agendy' },
              ...agendas.items,
            ],
          }}
          tooltipMapper={(o) => o.name}
          multiple
          disabled
        />

        {/* Pravnicka osoba / basic fields */}
        {type === SubjectType.LEGAL_ENTITY && (
          <LegalEntityBasicFields
            prefix={prefix}
            codePrefix="SUBJECT_REGISTRATION"
            disabledIco={isEditing}
            disabled={lockedBySync}
          />
        )}

        {type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO && (
          <LegalEntityWithoutIcoBasicFields
            prefix={prefix}
            codePrefix="SUBJECT_REGISTRATION"
            disabled={lockedBySync}
            hideUid={true}
          />
        )}

        {type === SubjectType.FOREIGN_LEGAL_ENTITY && (
          <ForeignLegalEntityBasicFields
            prefix={prefix}
            codePrefix="SUBJECT_REGISTRATION"
            disabled={lockedBySync}
            hideUid={true}
          />
        )}

        {/* Fyzicka osoba / basic fields */}
        {type === SubjectType.NATURAL_PERSON && (
          <NaturalPersonBasicFields
            prefix={prefix}
            codePrefix="SUBJECT_REGISTRATION"
            disabledDocuments={isEditing}
            disabled={lockedBySync}
            hideUid={true}
          />
        )}

        {type === SubjectType.BUSINESS_NATURAL_PERSON && (
          <BusinessNaturalPersonBasicFields
            prefix={prefix}
            codePrefix="SUBJECT_REGISTRATION"
            disabledIco={isEditing}
            disabled={lockedBySync}
          />
        )}

        {type === SubjectType.FOREIGN_NATURAL_PERSON && (
          <ForeignNaturalPersonBasicFields
            disabled={lockedBySync}
            hideUid={true}
            codePrefix="SUBJECT_REGISTRATION"
          />
        )}
      </FormPanel>

      <FormPanel
        label="Rozšířené informace k subjektu"
        expandable={true}
        defaultExpanded={false}
        ref={advancedFieldsPanelRef}
      >
        {type === SubjectType.FOREIGN_LEGAL_ENTITY && (
          <FormTextField
            name={`${prefix}.foreignId`}
            label={
              <HelpLabel
                label="Zahraniční identifikátor (obdoba IČO)"
                code="SUBJECT_REGISTRATION_FOREIGN_ID"
                required
              />
            }
          />
        )}
        <PhoneField
          name={`${prefix}.phoneNumber`}
          label={
            <HelpLabel
              label="Telefonní číslo (předvolba, číslo)"
              code="SUBJECT_REGISTRATION_PHONENUMBER"
            />
          }
        />
        <FormTextField
          name={`${prefix}.email.value`}
          label={<HelpLabel label="E-mail" code="SUBJECT_REGISTRATION_EMAIL" />}
        />
        <FormTextField
          name={`${prefix}.webSite`}
          label={
            <HelpLabel
              label="Webová stránka"
              code="SUBJECT_REGISTRATION_WEBSITE"
            />
          }
        />

        <IsdsIds
          prefix={prefix}
          codePrefix="SUBJECT_REGISTRATION"
          disabled={lockedBySync}
        />

        {/* Pravnicka osoba / advanced fields */}
        {type === SubjectType.LEGAL_ENTITY && (
          <LegalEntityAdvancedFields
            prefix={prefix}
            codePrefix="SUBJECT_REGISTRATION"
            disabled={lockedBySync}
          />
        )}

        {type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO && (
          <>
            <LegalEntityWithoutIcoAdvancedFields
              prefix={prefix}
              codePrefix="SUBJECT_REGISTRATION"
              disabled={lockedBySync}
            />
            <SubjectRequestFileFields type={type} disabled={true} />
          </>
        )}

        {type === SubjectType.FOREIGN_LEGAL_ENTITY && (
          <>
            <ForeignLegalEntityAdvancedFields
              prefix={prefix}
              codePrefix="SUBJECT_REGISTRATION"
              disabled={lockedBySync}
            />
            <SubjectRequestFileFields type={type} disabled={true} />
          </>
        )}

        {/* Fyzicka osoba / advanced fields */}
        {type === SubjectType.NATURAL_PERSON && (
          <NaturalPersonAdvancedFields
            prefix={prefix}
            codePrefix="SUBJECT_REGISTRATION"
            disabled={lockedBySync}
          />
        )}

        {type === SubjectType.BUSINESS_NATURAL_PERSON && (
          <BusinessNaturalPersonAdvancedFields
            prefix={prefix}
            codePrefix="SUBJECT_REGISTRATION"
            disabled={lockedBySync}
          />
        )}

        {type === SubjectType.FOREIGN_NATURAL_PERSON && (
          <>
            <ForeignNaturalPersonAdvancedFields
              prefix={prefix}
              codePrefix="SUBJECT_REGISTRATION"
              disabled={lockedBySync}
            />
            <SubjectRequestFileFields type={type} disabled={true} />
          </>
        )}
      </FormPanel>

      <FormPanel
        label="Adresa"
        expandable
        defaultExpanded={false}
        ref={addressPanelRef}
        summary={<FormPanelText value={addressLabel} />}
      >
        <AddressField
          name={`${prefix}.residence`}
          disabled={lockedByPerms}
          disableForeignCountry={
            subjectType === SubjectType.LEGAL_ENTITY ||
            subjectType === SubjectType.LEGAL_ENTITY_WITHOUT_ICO ||
            subjectType === SubjectType.NATURAL_PERSON ||
            subjectType === SubjectType.BUSINESS_NATURAL_PERSON
          }
          disableRuian={subjectType === SubjectType.FOREIGN_LEGAL_ENTITY}
        />
      </FormPanel>
      {approvedSubject && (
        <FormPanel label="Navázaný subjekt" expandable defaultExpanded={false}>
          <FormSelect
            name="approvedSubject.type"
            label={
              <HelpLabel
                label="Typ subjektu"
                code="SUBJECT_REGISTRATION_TYPE"
              />
            }
            source={subjectTypes}
            tooltipMapper={(o) => o.name}
            valueIsId={true}
            disabled
          />
          <FormTextField
            name="approvedSubject.name"
            label={<HelpLabel label="Název" code="SUBJECT_NAME" />}
            disabled
          />
          {approvedSubject.id && showRedirectButton && (
            <FormCustomField>
              <DetailNavigationButton
                url={EvidenceUrl.SUBJECTS}
                id={approvedSubject?.id}
              />
            </FormCustomField>
          )}
        </FormPanel>
      )}
      {isAdmin && (
        <FormPanel
          label="Dokumenty ve spisové službě"
          expandable
          defaultExpanded={false}
        >
          <FormTextField
            name="documentSignature.signedFile.sequenceNumber"
            label={
              <HelpLabel
                label="Originál registrace"
                code="SUBJECT_DOCUMENT_SIGNATURE_SIGNED_FILE"
              />
            }
            disabled
          />
          <FormTextField
            name="documentSignature.userUploadedFile.sequenceNumber"
            label={
              <HelpLabel
                label="Potvrzení s el. podpisem"
                code="SUBJECT_DOCUMENT_USER_UPLOADED_FILE"
              />
            }
            disabled
          />
          <FormTextField
            name="ispopDocument.file.sequenceNumber"
            label={
              <HelpLabel
                label="Potvrzení z DS"
                code="SUBJECT_ISPOP_DOCUMENT_FILE"
              />
            }
            disabled
          />
          <FormTextField
            name="affidavit.sequenceNumber"
            label={
              <HelpLabel label="Čestné prohlášení" code="SUBJECT_AFFIDAVIT" />
            }
            disabled
          />
          <FormTextField
            name="letterOfAttorney.sequenceNumber"
            label={
              <HelpLabel label="Plná moc" code="SUBJECT_LETTER_OF_ATTORNEY" />
            }
            disabled
          />
          <FormTextField
            name="registerExtract.sequenceNumber"
            label={
              <HelpLabel
                label="Výpis z živnostenského nebo obchodního rejstříku"
                code="SUBJECT_REGISTER_EXTRACT"
              />
            }
            disabled
          />
          <FormTextField
            name="identityDocumentCopy.sequenceNumber"
            label={
              <HelpLabel
                label="Kopie dokladu totožnosti"
                code="SUBJECT_IDENTITY_DOCUMENT_COPY"
              />
            }
            disabled
          />
          <FormInlineTableField
            name="ceniaDetails"
            label={
              <HelpLabel
                label="Spisová služba CENIA"
                code="SUBJECT_CENIA_DETAILS"
              />
            }
            disabled={true}
            ToolbarComponent={EmptyComponent}
            columns={ceniaDetailsColumns}
            showDetailBtnCond={() => false}
            showRadioCond={() => false}
          />
        </FormPanel>
      )}
      {ispopDocument && <IspopDocumentFields />}

      {props.Notes}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={true}>
        <AuthoredFields
          FieldsComponent={() => (
            <>
              <GeneralFields />
              <DatedFields />
            </>
          )}
        />
      </FormPanel>
    </>
  );
}

import React, { useMemo } from 'react';
import { Evidence2 } from '@eas/common-web';
import { WaterUsagePlace } from '../../models';
import { WaterUsagePlacesFields } from './water-usage-places-fields';
import { useColumns } from './water-usage-places-columns';
import { useValidationSchema } from './water-usage-places-schema';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  IncludeOptions,
  EntityName,
} from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { EntityType } from '../../enums/entity-type';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { DictionaryActionbar } from '../../components/evidence/dictionary-evidence/dictionary-actionbar';
import { useSpecialFilters } from './water-usage-places-special-filters';
import { WaterUsagePlacesTableToolbar } from './water-usage-places-table-toolbar';
import { useHistoryDescriptors } from './water-usage-places-history';
import {
  updateItemFactory,
  createItemFactory,
  getItemFactory,
} from '../../utils/custom-crud';
import { dms2dd, parse } from '../../utils/latlng';
import { formatIco } from '../subject/fields/subjects-utils';
import { combineGetCalls } from './water-usage-places-api';

export function WaterUsagePlaces() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission({
    options: {
      NEW: [Permission.WaterUsagePlace.WATER_USAGE_PLACE_CREATE],
      EDIT: [Permission.WaterUsagePlace.WATER_USAGE_PLACE_UPDATE],
      REMOVE: [],
      CHECK: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_CREATE,
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_UPDATE,
      ],
      SAVE: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_CREATE,
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_UPDATE,
      ],
      CLOSE: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_CREATE,
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_UPDATE,
      ],
    },
  });

  const include = useMemo(() => [IncludeOptions.DEACTIVATED], []);
  const historyDescriptors = useHistoryDescriptors();

  const formatObligatorySubjectIco = (item: WaterUsagePlace) => {
    if (item.obligatorySubject?.ico) {
      item.obligatorySubject.ico = formatIco(item.obligatorySubject.ico);
    }
    if (item.wgs84Coordinates?.lat) {
      item.wgs84Coordinates.lat = dms2dd(
        parse(item.wgs84Coordinates?.lat.toString())
      );
    }
    if (item.wgs84Coordinates?.lon) {
      item.wgs84Coordinates.lon = dms2dd(
        parse(item.wgs84Coordinates?.lon.toString())
      );
    }
    return item;
  };

  const evidence = useAuthoredEvidence<WaterUsagePlace>({
    version: 2,
    noteEntityType: EntityType.WUP,
    identifier: PageKey.WATER_USAGE_PLACES,
    apiProps: {
      url: EvidenceAPI.WATER_USAGE_PLACES,
      updateItem: updateItemFactory({
        combineGetCalls,
        preprocessCall: async (item) => formatObligatorySubjectIco(item),
      }),
      getItem: getItemFactory({
        combineGetCalls,
      }),
      createItem: createItemFactory({
        combineGetCalls,
        preprocessCall: async (item) => formatObligatorySubjectIco(item),
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled míst užívání vody',
      reportTag: ExportTags.WATER_USAGE_PLACES,
      include,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.WaterUsagePlace.WATER_USAGE_PLACE_LIST_ALL,
      toolbarProps: {
        after: <WaterUsagePlacesTableToolbar />,
      },
    },
    detailProps: {
      entityName: EntityName.WATER_USAGE_PLACE,
      FieldsComponent: WaterUsagePlacesFields,
      validationSchema,
      toolbarProps: {
        title: 'Místo užívání vody',
        subtitle: useSubTitle<WaterUsagePlace>((data) => data?.name ?? ''),
        ActionBar: function ActionBar() {
          return (
            <DictionaryActionbar
              url={EvidenceAPI.WATER_USAGE_PLACES}
              invalidatePerm={
                Permission.WaterUsagePlace.WATER_USAGE_PLACE_INVALIDATE
              }
              revalidatePerm={
                Permission.WaterUsagePlace.WATER_USAGE_PLACE_REVALIDATE
              }
            />
          );
        },
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.WaterUsagePlace.WATER_USAGE_PLACE_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}

import { Translation } from './translations-types';
import { TableCells, TableColumn, ApiFilterOperation } from '@eas/common-web';

export function useColumns(): TableColumn<Translation>[] {
  return [
    {
      datakey: 'code',
      name: 'Kód',
      width: 100,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
  ];
}

import React from 'react';
import {
  abortableFetch,
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  FormContext,
  NavigationContext,
} from '@eas/common-web';
import { EvidenceAPI, Messages } from '../../../enums';
import { ProcessForm } from '../boiler-manufacturer-new-types';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useState, useContext, useRef } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call.
 * @param identification
 */
function createBoilerManufacturerApiCall(identification: ProcessForm) {
  return abortableFetch(
    `${EvidenceAPI.BOILER_MANUFACTURERS}/for-identification`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(identification),
    }
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function StepSummaryActions({
  handleBack,
  handleNext,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Loading state.
   */
  const [loading, setLoading] = useState(false);

  /**
   * Context stuff.
   */
  const { showSnackbar } = useContext(SnackbarContext);
  const { setFieldValue } = useContext(FormContext);
  const { unregisterPrompt } = useContext(NavigationContext);

  /**
   * Fetch reference.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleNextWithApiCall = useEventCallback(async () => {
    try {
      setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = createBoilerManufacturerApiCall(allValues);
      const response = await fetch.current.json();

      setFieldValue('createdManufacturer', response);
      unregisterPrompt(navigationPrompt);
      showSnackbar(...Messages.BoilerManufacturer.CREATE.SUCCESS);
      handleNext();
    } catch (err) {
      if (err.name !== 'AbortError') {
        const message = getErrorMessage(
          Messages.BoilerManufacturer.CREATE,
          err.code
        );

        showSnackbar(...message);
      }
      return undefined;
    } finally {
      setLoading(false);
    }
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithApiCall}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Vytvořit výrobce
        </Button>
        <Button
          disabled={loading}
          onClick={handleBack}
          className={classes.button}
          disableElevation
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} />
      </div>
    </div>
  );
}

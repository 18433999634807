import React, {
  createContext,
  ReactNode,
  useState,
  SetStateAction,
  Dispatch,
  useContext,
} from 'react';
import {
  ScrollableSource,
  useScrollableSource,
  UserContext,
} from '@eas/common-web';
import { Area, Source } from './overview-map-types';
import { EvidenceAPI, Permission } from '../../enums';
import { IrzFacility, OvzFacility, ProductionSite } from '../../models';

export interface OverviewMapContextType {
  irzFacilitySource: ScrollableSource<IrzFacility>;
  ovzFacilitySource: ScrollableSource<OvzFacility>;
  productionSiteSource: ScrollableSource<ProductionSite>;
  source: Source[];
  setSource: Dispatch<SetStateAction<Source[]>>;
  defaultValues: { [key: string]: Source };
  withFilters: boolean;
  setWithFilters: Dispatch<SetStateAction<boolean>>;
  canSeeIrzFacilities: boolean;
  canSeeOvzFacilities: boolean;
  canSeeProductionSites: boolean;
}

export const OverviewMapContext = createContext<OverviewMapContextType>(
  undefined as any
);

export function OverviewMapProvider({ children }: { children: ReactNode }) {
  const [withFilters, setWithFilters] = useState(false);
  const { hasPermission } = useContext(UserContext);

  const canSeeIrzFacilities =
    hasPermission(Permission.IrzFacility.IRZ_LIST_ALL) ||
    hasPermission(Permission.IrzFacility.IRZ_LIST_OWN);

  const canSeeOvzFacilities =
    hasPermission(Permission.OvzFacility.OVZ_LIST_ALL) ||
    hasPermission(Permission.OvzFacility.OVZ_LIST_OWN);

  const canSeeProductionSites = hasPermission(
    Permission.ProductionSite.PRODUCTION_SITE_LIST
  );

  const defaultValues = {
    [Area.IRZ_FACILITY]: {
      api: EvidenceAPI.IRZ_FACILITIES,
      type: Area.IRZ_FACILITY,
      filters: [],
      shouldLoad: canSeeIrzFacilities,
    },
    [Area.OVZ_FACILITY]: {
      api: EvidenceAPI.OVZ_FACILITIES,
      type: Area.OVZ_FACILITY,
      filters: [],
      shouldLoad: canSeeOvzFacilities,
    },
    [Area.PRODUCTION_SITE]: {
      api: EvidenceAPI.PRODUCTION_SITES,
      type: Area.PRODUCTION_SITE,
      filters: [],
      shouldLoad: canSeeProductionSites,
    },
  };

  const [source, setSource] = useState<Source[]>(Object.values(defaultValues));

  const irzFacilitySource = useScrollableSource<IrzFacility>({
    url: EvidenceAPI.IRZ_FACILITIES + '/list',
  });

  const ovzFacilitySource = useScrollableSource<OvzFacility>({
    url: EvidenceAPI.OVZ_FACILITIES + '/list',
  });

  const productionSiteSource = useScrollableSource<ProductionSite>({
    url: EvidenceAPI.PRODUCTION_SITES + '/list',
  });

  return (
    <OverviewMapContext.Provider
      value={{
        irzFacilitySource,
        ovzFacilitySource,
        productionSiteSource,
        source,
        setSource,
        defaultValues,
        withFilters,
        setWithFilters,
        canSeeIrzFacilities,
        canSeeOvzFacilities,
        canSeeProductionSites,
      }}
    >
      {children}
    </OverviewMapContext.Provider>
  );
}

import { PersonalData } from '../../../models';

export const userNameValueMapper = function userNameValueMapper({
  rowValue,
}: {
  rowValue: PersonalData;
}) {
  if (rowValue.entityReference?.user) {
    return rowValue.entityReference?.user.username;
  }

  if (rowValue.entityReference?.userRegistration) {
    return rowValue.entityReference?.userRegistration.username;
  }

  return '-';
};

userNameValueMapper.displayName = 'userNameValueMapper';

import React, { useContext } from 'react';
import { TableToolbarButton, TableContext } from '@eas/common-web';
import GetAppIcon from '@material-ui/icons/GetApp';

export function ToolbarExportButton() {
  const { disabledReportButton, openExportDialog } = useContext(TableContext);

  return (
    <TableToolbarButton
      label={<GetAppIcon />}
      disabled={disabledReportButton}
      onClick={openExportDialog}
      tooltip="Stáhnout a uložit"
    />
  );
}

import React from 'react';
import {
  FormPanel,
  FormCustomField,
  TextField,
  Autocomplete,
  FileField,
  InlineTableField,
  DateField,
  Checkbox,
} from '@eas/common-web';
import { ProcessForm } from '../empowerment-requests-types';
import { noop } from 'lodash';
import {
  AuthorRole,
  IdType,
  SubjectType,
  AgendaType,
  AgendaSpecification,
} from '../../../models';
import { useAgendas } from '../../agenda/agendas-api';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { AgendaAutocomplete } from '../../../models/agenda';

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const agendas = useAgendas();

  const authorRole = allValues?.authorRole;

  const authorLabel =
    authorRole === AuthorRole.EMPOWERER
      ? 'Zmocnitel'
      : authorRole === AuthorRole.AGENT
      ? 'Zmocněnec'
      : 'Subjekt';

  let otherSubjectLabel =
    authorRole === AuthorRole.EMPOWERER
      ? 'Zmocněnec'
      : authorRole === AuthorRole.AGENT
      ? 'Zmocnitel'
      : 'Subjekt';

  const hasIco =
    allValues.empowerer?.type === SubjectType.LEGAL_ENTITY ||
    allValues.empowerer?.type === SubjectType.BUSINESS_NATURAL_PERSON;

  const hasUID =
    allValues.empowerer?.type === SubjectType.FOREIGN_NATURAL_PERSON ||
    allValues.empowerer?.type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO;

  const hasIdOrPassport =
    allValues.empowerer?.type === SubjectType.NATURAL_PERSON;

  const isPassport = allValues.empowerer?.idType === IdType.PASSPORT;
  const isIdCard = allValues.empowerer?.idType === IdType.ID_CARD;

  let empowerer: string | undefined = '';
  if (hasIco) {
    otherSubjectLabel += ' (IČO)';
    empowerer = allValues.empowerer?.ico;
  }
  if (allValues.empowerer?.type === SubjectType.FOREIGN_LEGAL_ENTITY) {
    empowerer = `${allValues.empowerer.name} (${allValues.empowerer?.foreignId})`;
  }
  if (hasUID) {
    otherSubjectLabel += ' (UID)';
    empowerer = allValues.empowerer?.artificialId;
  }
  if (hasIdOrPassport && isPassport) {
    otherSubjectLabel += ' (Číslo pasu)';
    empowerer = allValues.empowerer?.passportNumber;
  }
  if (hasIdOrPassport && isIdCard) {
    otherSubjectLabel += ' (Číslo OP)';
    empowerer = allValues.empowerer?.idCardNumber;
  }

  return (
    <FormPanel label="Zmocnění">
      <FormCustomField label={authorLabel}>
        <TextField
          disabled={true}
          value={allValues.author?.label}
          onChange={noop}
        />
      </FormCustomField>
      <FormCustomField label={otherSubjectLabel}>
        <TextField disabled={true} value={empowerer} onChange={noop} />
      </FormCustomField>
      {allValues.empowerer?.type === SubjectType.FOREIGN_LEGAL_ENTITY && (
        <FormCustomField label="Zmocněnec - Adresa">
          <TextField
            disabled={true}
            value={`${allValues.empowerer?.residence?.street}, ${allValues.empowerer?.residence?.zip} ${allValues.empowerer?.residence?.municipality}, ${allValues.empowerer?.residence?.country?.label}`}
            onChange={noop}
          />
        </FormCustomField>
      )}
      {allValues.empowerer?.identityDocumentCopy && (
        <FormCustomField label="Příloha (kopie dokladu totožnosti)">
          <FileField
            value={allValues.empowerer.identityDocumentCopy}
            onChange={noop}
            disabled={true}
          />
        </FormCustomField>
      )}
      {allValues.certificateOfIncorporation && (
        <FormCustomField label="Příloha (výpis z obchodního rejstříku)">
          <FileField
            value={allValues.certificateOfIncorporation}
            onChange={noop}
            disabled={true}
          />
        </FormCustomField>
      )}
      {allValues.empowerer?.registerExtract && (
        <FormCustomField label="Příloha (výpis z živnostenského nebo obchodního rejstříku nebo podobný dokument)">
          <FileField
            value={allValues.empowerer?.registerExtract}
            onChange={noop}
            disabled={true}
          />
        </FormCustomField>
      )}
      {allValues.empowerer?.powerOfAttorney && (
        <FormCustomField label="Příloha (pověření k zastupování)">
          <FileField
            value={allValues.empowerer?.powerOfAttorney}
            onChange={noop}
            disabled={true}
          />
        </FormCustomField>
      )}
      {(allValues.subForms || []).map((item, index) => {
        return (
          <Grid item xs={12} key={index}>
            <FormCustomField label="Agenda">
              <Autocomplete<AgendaAutocomplete>
                source={agendas}
                value={item.agenda}
                onChange={noop}
                disabled={true}
              />
            </FormCustomField>
            <FormCustomField label="Příloha (plná moc konvertovaná)">
              <FileField
                value={item.empowermentDocument}
                onChange={noop}
                disabled={true}
              />
            </FormCustomField>
            <FormCustomField label="Platnost od">
              <DateField
                value={item.validFrom}
                onChange={noop}
                disabled={true}
              />
            </FormCustomField>
            <FormCustomField label="Platnost do">
              <DateField value={item.validTo} onChange={noop} disabled={true} />
            </FormCustomField>
            <FormCustomField label="Na neurčito">
              <Checkbox
                value={item.withoutDay}
                onChange={noop}
                disabled={true}
              />
            </FormCustomField>

            {item.agenda?.type === AgendaType.SPECIFIED && (
              <FormCustomField label="Typ hlášení">
                <InlineTableField<AgendaSpecification>
                  value={item.specifications}
                  onChange={noop}
                  disabled={true}
                  withRemove={false}
                  columns={[
                    {
                      name: 'Název',
                      datakey: 'name',
                      width: 200,
                    },
                    {
                      name: 'Popis',
                      datakey: 'description',
                      width: 400,
                    },
                  ]}
                />
              </FormCustomField>
            )}
            <Box mb={4} style={{ width: '100%' }} />
          </Grid>
        );
      })}
    </FormPanel>
  );
}

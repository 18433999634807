import React, { ComponentType } from 'react';
import clsx from 'clsx';
import { TableCellProps, DomainObject } from '@eas/common-web';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    displayOverride: {
      display: 'flex',
      '& > p': {
        display: 'block !important',
      },
    },
    colorGray: {
      color: '#a0a0a0',
    },
  })
);

export function inactiveColoredFactory<
  T extends DomainObject & {
    active?: boolean;
    validTo?: string;
    validFrom?: string;
  }
>(Component: ComponentType<TableCellProps<T>>, withDisplayOverride = true) {
  const WrapedComponent = function Cell(props: TableCellProps<T>) {
    const classes = useStyles();
    let isActive = false;

    if (props.rowValue?.active !== undefined) {
      isActive = !!props.rowValue?.active;
    } else if (
      props.rowValue?.validTo !== undefined ||
      props.rowValue?.validFrom !== undefined
    ) {
      const isValidFrom = props.rowValue?.validFrom
        ? new Date(props.rowValue.validFrom) <= new Date()
        : true;

      const isValidTo = props.rowValue?.validTo
        ? new Date(props.rowValue.validTo) >= new Date()
        : true;

      isActive = isValidFrom && isValidTo;
    }

    return (
      <div
        className={clsx(classes.root, {
          [classes.displayOverride]: withDisplayOverride,
          [classes.colorGray]: !isActive,
        })}
      >
        <Component {...props} />
      </div>
    );
  };

  WrapedComponent.displayName = Component.displayName ?? Component.name;
  return WrapedComponent;
}

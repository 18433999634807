import * as Yup from 'yup';
import { StepEmpowermentForm } from './step-empowerment-types';
import { validateSpecifications } from '../../empowerment/empowerments-utils';

export const stepEmpowermentSchema = () =>
  Yup.object<StepEmpowermentForm>().shape({
    subForms: Yup.array().of(
      Yup.object().shape({
        agenda: Yup.mixed().nullable().required('Povinné pole'),
        empowermentDocument: Yup.mixed().nullable().required('Povinné pole'),
        validFrom: Yup.string().nullable().required('Povinné pole'),
        specifications: validateSpecifications,
      })
    ),
  });

import { MessageType } from '../enums/messages/message-type';
import { SnackbarVariant } from '@eas/common-web';

export function getErrorMessage(
  messages: MessageType<string>,
  code?: string
): [string, SnackbarVariant] {
  if (!code) {
    return messages.ERROR;
  } else if (!messages?.[code]) {
    return [`Neznámý kód chyby: ${code}.`, SnackbarVariant.ERROR];
  } else {
    return messages[code];
  }
}
export function getSuccessMessage(
  messages: MessageType<string>
): [string, SnackbarVariant] {
  return (
    messages?.SUCCESS ?? ['Operace úspěšně dokončena.', SnackbarVariant.SUCCESS]
  );
}

import {
  DictionaryAutocomplete,
  useListSource,
  useStaticListSource,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useScriptTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'GROOVY', name: 'Groovy' },
    { id: 'JAVASCRIPT', name: 'Javascript' },
  ]);
}

export function useRunStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'STARTED', name: 'Běží' },
    { id: 'ERROR', name: 'Chyba' },
    { id: 'FINISHED', name: 'Skončil' },
  ]);
}

export function useJobs() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.SCHEDULE_JOBS}/autocomplete/full`,
  });
}

export function useJobsAll() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.SCHEDULE_JOBS}/autocomplete/full/all`,
  });
}

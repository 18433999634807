import React from 'react';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { useSendToRuianDialog } from './dialog-actions/send-to-ruian-hook';
import { useDiscardDialog } from './dialog-actions/discard-hook';
import { useCancelDialog } from './dialog-actions/cancel-hook';

export function ProtectedDepositAreasActionbar() {
  const {
    canApproveForRuian,
    canEditForRuian,
    callApi: sendToRuianApiCall,
  } = useSendToRuianDialog();

  const { canDiscard, callApi: discardApiCall } = useDiscardDialog();
  const { canCancel, callApi: cancelApiCall } = useCancelDialog();

  return (
    <>
      {canApproveForRuian && (
        <DetailActionButton
          promptKey="APPROVE_FOR_RUIAN"
          apiCall={sendToRuianApiCall}
          buttonLabel="Schválit pro RÚIAN"
          dialogTitle="Schválení CHLÚ do RÚIAN"
          dialogText="Skutečně chcete vybrané ložiskové území schválit pro RÚIAN?"
          ButtonComponent={(props) => (
            <PrimaryDetailActionbarButton {...props} startIcon={<SendIcon />} />
          )}
        />
      )}
      {canEditForRuian && (
        <DetailActionButton
          promptKey="EDIT_FOR_RUIAN"
          apiCall={sendToRuianApiCall}
          buttonLabel="Schválit editaci pro RÚIAN"
          dialogTitle="Schválení editace CHLÚ pro RÚIAN"
          dialogText="Skutečně chcete schválit editaci vybraného ložiskového území pro RÚIAN?"
          ButtonComponent={(props) => (
            <PrimaryDetailActionbarButton {...props} startIcon={<SendIcon />} />
          )}
        />
      )}
      {canDiscard && (
        <DetailActionButton
          promptKey="DISCARD"
          apiCall={discardApiCall}
          buttonLabel="Zahodit"
          dialogTitle="Zahození CHLÚ"
          dialogText="Skutečně chcete vybrané ložiskové území zahodit?"
          ButtonComponent={(props) => (
            <SecondaryDetailActionbarButton
              {...props}
              startIcon={<DeleteIcon />}
            />
          )}
        />
      )}
      {canCancel && (
        <DetailActionButton
          promptKey="CANCEL"
          apiCall={cancelApiCall}
          buttonLabel="Schválit zrušení pro RÚIAN"
          dialogTitle="Schválení zrušení pro RÚIAN"
          dialogText="Skutečně chcete schválit zrušení vybraného ložiskového území pro RÚIAN?"
          ButtonComponent={(props) => (
            <SecondaryDetailActionbarButton
              {...props}
              startIcon={<CloseIcon />}
            />
          )}
        />
      )}
    </>
  );
}

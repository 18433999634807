import React, { ComponentType } from 'react';
import { FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../help/help-label';

export function AuthoredFields({
  code = 'AUTHORED',
  FieldsComponent,
  hideCreatedBy,
  hideUpdatedBy,
}: {
  code?: string;
  FieldsComponent?: ComponentType<{
    FieldsComponent?: ComponentType;
    hideCreated?: boolean;
    hideUpdated?: boolean;
  }>;
  hideCreatedBy?: boolean;
  hideUpdatedBy?: boolean;
}) {
  return (
    <>
      {FieldsComponent && <FieldsComponent />}
      {!hideCreatedBy && (
        <FormTextField
          name="createdBy.name"
          label={<HelpLabel label="Autor" code={`${code}_CREATED_BY`} />}
          disabled={true}
        />
      )}
      {!hideUpdatedBy && (
        <FormTextField
          name="updatedBy.name"
          label={<HelpLabel label="Autor úpravy" code={`${code}_UPDATED_BY`} />}
          disabled={true}
        />
      )}
    </>
  );
}

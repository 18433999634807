import { EmpowermentSpecificationRequest } from '../../models';

export const specificationRequestsValueMapper = ({
  value,
}: {
  value: EmpowermentSpecificationRequest[];
}) =>
  value ? value.map((request) => request.specification.name).join(', ') : '';

specificationRequestsValueMapper.displayName = 'specificationRequestsMapper';

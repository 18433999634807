import React, { useContext, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useSlate } from 'slate-react';
import * as Yup from 'yup';
import LinkIcon from '@material-ui/icons/Link';
import {
  usePrompts,
  PromptContext,
  FormTextField,
  FormFileField,
  FilesContext,
  FileRef,
} from '@eas/common-web';
// utils
import { wrapLink } from '../utils/wrap-link';
import { useStyles } from './button-styles';
// types
import { BaseRange, Range, Editor } from 'slate';

const PROMPT_KEY = 'LINK_OPTIONS';

export function WrapLinkButton() {
  const classes = useStyles();
  const editor = useSlate();

  const { getFileUrl } = useContext(FilesContext);
  const { testPrompt } = useContext(PromptContext);

  const initUrl = useRef<string>();
  const initFileRef = useRef<FileRef>();
  const mark = Editor.marks(editor);

  useEffect(() => {
    if (mark?.link?.url) {
      const regex = /\/api\/crzp\/files\/(?<id>.*)/;

      const result = mark?.link?.url.match(regex);

      if (result?.groups?.id) {
        initUrl.current = undefined;
        initFileRef.current = {
          id: result?.groups?.id,
          name: mark.link.name!,
          contentType: mark.link.contentType!,
          size: parseInt(mark.link.size!, 10),
        };
      } else {
        initUrl.current = mark?.link?.url;
        initFileRef.current = undefined;
      }
    }
  }, [mark?.link?.url]);

  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Vložit odkaz',
        dialogText: 'Určite chcete vytvoriť link?',
        FormFields: function Body() {
          return (
            <>
              <FormTextField label="URL adresa" name="url" />
              <FormFileField label="Soubor" name="file" />
            </>
          );
        },
        formInitialValues: {
          url: initUrl.current,
          file: initFileRef.current,
        },
        formValidationSchema: Yup.object().shape(
          {
            url: Yup.mixed().when('file', {
              is: (file) => !file,
              then: Yup.mixed().required(
                '1 z polí "URL adresa" / "Soubor" je povinné'
              ),
              otherwise: Yup.mixed().notRequired(),
            }),
            file: Yup.mixed().when('url', {
              is: (url) => !url,
              then: Yup.mixed().required(
                '1 z polí "URL adresa" / "Soubor" je povinné'
              ),
              otherwise: Yup.mixed().notRequired(),
            }),
          },
          [['url', 'file']]
        ),
      },
    ],
    [initUrl.current, initFileRef.current]
  );

  const disabled =
    !editor.selection || Range.isCollapsed(editor.selection as BaseRange);

  return (
    <button
      disabled={disabled}
      onMouseDown={(event) => {
        event.preventDefault();

        const selection = editor.selection as BaseRange;

        testPrompt({
          key: PROMPT_KEY,
          submitCallback: async ({ url, file }: { url: string; file?: FileRef }) => {
            if (file?.id) {
              wrapLink(editor, selection, {
                url: getFileUrl(file.id),
                name: file.name,
                contentType: file.contentType,
                size: file.size,
              });
            } else {
              wrapLink(editor, selection, { url });
            }
          },
        });
      }}
      className={clsx(classes.button, {
        [classes.disabled]: disabled,
      })}
    >
      <LinkIcon fontSize="small" />
    </button>
  );
}

import {
  AbortableFetch,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { ConceptStatusDto } from '../../../models';

export function useVerifyDataboxAndPrepareConcept() {
  const sentToken = 'concept-sent';
  const rejectedCode = '2305';
  const sentCode = '0000';

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [conceptStatus, setConceptStatus] = useState<
    'SENT' | 'REJECTED' | 'PREPARED' | undefined
  >(undefined);

  const fetch = useRef<AbortableFetch | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const prepareConceptApiCall = () => {
    return abortableFetch(
      `/api/crzp/isds/login-and-prepare-concept?sessionId=${params.get(
        'sessionId'
      )}&fileId=${params.get('appToken')}`,
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      }
    );
  };

  const checkConceptStatusApiCall = () => {
    return abortableFetch(
      `/api/crzp/isds/check-concept-status?sessionId=${params.get(
        'sessionId'
      )}`,
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      }
    );
  };

  const checkConceptStatus = useEventCallback(async () => {
    try {
      setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = checkConceptStatusApiCall();

      const { code, message }: ConceptStatusDto = await fetch.current?.json();

      unstable_batchedUpdates(() => {
        setLoading(false);
        setConceptStatus(
          code === rejectedCode
            ? 'REJECTED'
            : code === sentCode
            ? 'SENT'
            : undefined
        );

        if (code && ![rejectedCode, sentCode].includes(code)) {
          setError(message);
        }
      });
    } catch (e) {
      unstable_batchedUpdates(() => {
        setLoading(false);
      });

      if (e.name !== 'AbortError') {
        setError(e.message);

        throw e;
      }
      return undefined;
    }
  });

  const loginAndPrepareConcept = useEventCallback(async () => {
    try {
      setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = prepareConceptApiCall();

      const concept = await fetch.current.text();

      unstable_batchedUpdates(() => {
        setLoading(false);
        setConceptStatus('PREPARED');

        if (concept) {
          window.open(
            `https://www.mojedatovaschranka.cz/as/koncept/view?konceptId=${concept}&appToken=${sentToken}`,
            '_self'
          );
        }
      });
    } catch (e) {
      unstable_batchedUpdates(() => {
        setLoading(false);
      });
      if (e.name !== 'AbortError') {
        setError(JSON.parse(e)?.message);

        throw e;
      }
      return undefined;
    }
  });

  useEffect(() => {
    const appToken = params.get('appToken');

    if (appToken === sentToken) {
      checkConceptStatus();
    } else {
      loginAndPrepareConcept();
    }
  }, []);

  return {
    loading,
    error,
    conceptStatus,
  };
}

import {
  TableFieldColumn,
  FormCustomField,
  InlineTableField,
  EmptyComponent,
} from '@eas/common-web';
import React, { useMemo } from 'react';
import { highlightTableFieldRowFactory } from '../../components/form/inline-table-field/highlight-table-field-row';
import { noop } from 'lodash';

type CompareRow<RecordType> = {
  changeType: 'ADD' | 'DELETE';
  changeLabel: string;
  value: RecordType;
};

export function CompareTable<RecordType>({
  comparator,
  newValues,
  originalValues,
  columns,
  label,
}: {
  originalValues: RecordType[];
  newValues: RecordType[];
  comparator: (r1?: RecordType, r2?: RecordType) => boolean;
  columns: TableFieldColumn<CompareRow<RecordType>>[];
  label: string;
}) {
  const deleted: CompareRow<RecordType>[] = useMemo(() => {
    const deletedRaw = originalValues.filter(
      (originalValue) =>
        !newValues.find((newValue) => comparator(originalValue, newValue))
    );
    return deletedRaw.map((row) => ({
      value: row,
      changeType: 'DELETE',
      changeLabel: '-',
    }));
  }, [originalValues, newValues]);

  const added: CompareRow<RecordType>[] = useMemo(() => {
    const addedRaw = newValues.filter(
      (newValue) =>
        !originalValues.find((originalValue) =>
          comparator(originalValue, newValue)
        )
    );
    return addedRaw.map((row) => ({
      value: row,
      changeType: 'ADD',
      changeLabel: '+',
    }));
  }, [originalValues, newValues]);

  if (deleted.length === 0 && added.length === 0) {
    return null;
  } else {
    return (
      <FormCustomField label={label}>
        <InlineTableField<CompareRow<RecordType>>
          value={[...deleted, ...added]}
          onChange={noop}
          disabled={true}
          withRemove={false}
          RowComponent={highlightTableFieldRowFactory({
            hideRowActions: true,
            isApprovedRow: (val: CompareRow<RecordType>) =>
              val.changeType === 'ADD',
            isRejectedRow: (val: CompareRow<RecordType>) =>
              val.changeType === 'DELETE',
          })}
          ToolbarComponent={EmptyComponent}
          columns={[
            {
              name: '+/-',
              datakey: 'changeLabel',
              width: 50,
            },
            ...columns.map((col) => ({
              ...col,
              datakey: col.datakey ? `value.${col.datakey}` : 'value',
            })),
          ]}
        />
      </FormCustomField>
    );
  }
}

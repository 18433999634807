import { round } from 'lodash';
import { getPreciseDistance } from 'geolib';
import { Wgs84Coordinates } from '../../../models';

export function calculateDistance(x: Wgs84Coordinates, y: Wgs84Coordinates) {
  return getPreciseDistance(x as any, y as any);
}

export function formatDistance(distInMeters: number) {
  if (distInMeters < 1000) {
    return `${distInMeters}m`;
  } else return `${round(distInMeters / 1000, 2)}km`;
}

import { Editor } from 'slate';
import { CustomEditor } from '../../../global';
import { TextFormat } from '../rich-editor-types';
import { isMarkActive } from './is-mark-active';

export const toggleMark = (editor: CustomEditor, format: TextFormat) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Subject messages
 */
const AGENDA_ASSIGN: MessageType<'SUCCESS' | 'ERROR' | 'AGENDA_ALREADY_ASSIGNED'> = {
  SUCCESS: ['Agenda byla úspěšně přidělena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při přidělování agendy.', SnackbarVariant.ERROR],
  AGENDA_ALREADY_ASSIGNED: [
    'Chyba při přidělování agendy. Subjekt tuhle agendu již přiřazenou má.',
    SnackbarVariant.ERROR,
  ],
};

const AGENDA_UNASSIGN: MessageType = {
  SUCCESS: ['Agenda byla úspěšně odebraná.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při odebíraní agendy.', SnackbarVariant.ERROR],
};

const USER_ASSIGN: MessageType<'SUCCESS' | 'RELATIONSHIP_CHANGED' | 'ERROR' | 'ENTITY_NOT_EXIST' | 'USER_ALREADY_ASSIGNED' | 'USER_ALREADY_HAS_REQUESTED_ROLES'> = {
  SUCCESS: [
    'Uživatel byl do subjektu úspěšně přidán.',
    SnackbarVariant.SUCCESS,
  ],
  RELATIONSHIP_CHANGED: [
    'Uživateli byl úspěšně změněn vztah k subjektu.', 
    SnackbarVariant.SUCCESS
  ],
  ERROR: [
    'Chyba při přidávání uživatele do subjektu.',
    SnackbarVariant.ERROR,
  ],
  ENTITY_NOT_EXIST: [
    'Chyba při přidávání uživatele do subjektu. Uživatel neexistuje.',
    SnackbarVariant.ERROR,
  ],
  USER_ALREADY_ASSIGNED: [
    'Chyba při přidávání uživatele do subjektu. Uživatel již v subjektu přidán je.',
    SnackbarVariant.ERROR,
  ],
  USER_ALREADY_HAS_REQUESTED_ROLES: ['Uživatel se zadaným uživatelským jménem, vztahem a rolí je již k tomuto subjektu přiřazen.', SnackbarVariant.ERROR]
};

const USER_UNASSIGN: MessageType = {
  SUCCESS: [
    'Uživatel byl ze subjektu úspěšně odebrán.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: ['Chyba při odebíraní uživatele.', SnackbarVariant.ERROR],
};

const SYNC_AUTO: MessageType<'ENABLE_SUCCESS' | 'DISABLE_SUCCESS' | 'ERROR'> = {
  ENABLE_SUCCESS: [
    'Pravidelná synchronizace vůči ISZR zapnuta.',
    SnackbarVariant.SUCCESS,
  ],
  DISABLE_SUCCESS: [
    'Pravidelná synchronizace vůči ISZR vypnuta.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    'Chyba při nastavení pravidelné synchronizace subjektu.',
    SnackbarVariant.ERROR,
  ],
};

const SYNC_MANUAL: MessageType<'SUCCESS' | 'NOT_FOUND' | 'FAILED' | 'ERROR'> = {
  SUCCESS: ['Subjekt byl úspěšně synchronizován.', SnackbarVariant.SUCCESS],
  NOT_FOUND: ['Subjekt nebyl nalezen v ISZR.', SnackbarVariant.ERROR],
  FAILED: [
    'Při pokusu o synchronizaci subjektu z ISZR došlo k chybě.',
    SnackbarVariant.ERROR,
  ],
  ERROR: ['Chyba synchronizace.', SnackbarVariant.ERROR],
};

const CREATE: MessageType< 'ERROR' | 'ICO_NOT_UNIQUE'> = {
  ICO_NOT_UNIQUE: [
    'Subjekt se zadaným IČO je již registrován.',
    SnackbarVariant.ERROR,
  ],
  ERROR: ['Chyba při vytváření subjektu.', SnackbarVariant.ERROR],
};

const ACTIVATE: MessageType = {
  SUCCESS: [
    'Platnost subjektu byla úspěšně obnovena.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: ['Chyba při obnovení platnosti subjektu.', SnackbarVariant.ERROR],
};

const DEACTIVATE: MessageType<
  'SUCCESS' | 'ERROR' | 'FUTURE_OPERATION_PRESENT'
> = {
  SUCCESS: ['Subjekt byl úspěšně zneplatněn.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zneplatnění subjektu.', SnackbarVariant.ERROR],
  FUTURE_OPERATION_PRESENT: [
    'Nelze zneplatnit subjekt, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
};

export default {
  AGENDA_ASSIGN,
  AGENDA_UNASSIGN,
  USER_ASSIGN,
  USER_UNASSIGN,
  SYNC_AUTO,
  SYNC_MANUAL,
  CREATE,
  ACTIVATE,
  DEACTIVATE,
};

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platný od',
    },
    {
      key: 'validTo',
      label: 'Platný do',
    },
    {
      key: 'code',
      label: 'Kód',
    },
    {
      key: 'description',
      label: 'Popis',
    },
  ];
}

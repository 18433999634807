import { AuthoredObject, Params, FileRef } from '@eas/common-web';
import { User } from './user';

/**
 * Mailová kampaň
 */
export interface MailCampaign extends AuthoredObject {
  /**
   * Název.
   */
  name?: string;

  /**
   * Předmět emailu.
   */
  emailSubject: string;

  /**
   * HTML obsah emailu.
   */
  emailContent: string;

  /**
   * Stav.
   */
  state: MailCampaignState;

  /**
   * Uživatel, který publikoval seznam.
   */
  publisher?: User;

  /**
   * Začátek.
   */
  startTime?: string;

  /**
   * Konec.
   */
  endTime?: string;

  /**
   * Seznam příjemců.
   */
  recipients?: (string | undefined)[];

  /**
   * Seznam příjemců bez souhlasu.
   */
  recipientsNoConsent?: (string | undefined)[];

  /**
   * Seznam příjemců, kterých adresa není v systému CRŽP.
   */
  recipientsNotExist?: (string | undefined)[];

  /**
   * Seznam příjemců, kterí nemají správnou emailovou adresu.
   */
  recipientsBad?: (string | undefined)[];

  /**
   * Seznam příjemců, kterým se nepovedlo doručit zdělení.
   */
  recipientsFailed?: string[];

  /**
   * Zaslat i bez udělení souhlasu.
   */
  forced?: boolean;
}

export enum MailCampaignState {
  /**
   * Koncept.
   */
  CONCEPT = 'CONCEPT',

  /**
   * Spuštěná.
   */
  RUNNING = 'RUNNING',

  /**
   * Ukončená
   */
  FINISHED = 'FINISHED',

  /**
   * Zrušená
   */
  CANCELED = 'CANCELED',
}

export interface MailCampaignTestDto {
  /**
   * Email
   */
  email: string;
}

/**
 * Zdroj příjemců.
 */
export enum RecipientsSource {
  /**
   * Registr Uživatelů
   */
  USER_EVIDENCE = 'USER_EVIDENCE',

  /**
   * Registr Subjektů
   */
  SUBJECT_EVIDENCE = 'SUBJECT_EVIDENCE',

  /**
   * Provozovny IRZ.
   */
  IRZ = 'IRZ',

  /**
   * Registr OZO - Výrobci.
   */
  OZO_MANUFACTURER = 'OZO_MANUFACTURER',

  /**
   * Registr OZO - Oprávnění.
   */
  OZO_PERMISSION = 'OZO_PERMISSION',

  /**
   * Systémy třetích stran
   */
  THIRD_PARTY = 'THIRD_PARTY',
}

export interface CreateFromEvidenceDto {
  source: RecipientsSource;
  params: Params;
  selected: string[];
}

export interface ImportDto {
  content: FileRef | null;
}

export interface ImportResultDto {
  entity: MailCampaign;
  withoutConsent: number;
}

export interface CheckResultDto {
  validCount: number;
  badCount: number;
  notExistCount: number;
  noConsentCount: number;
}

import { useMemo } from 'react';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepFinishActions } from './step-finish/step-finish-actions';
import { StepBasicInfoLabel } from './step-basic-info/step-basic-info-label';
import { StepBasicInfoContent } from './step-basic-info/step-basic-info-content';
import { stepBasicInfoSchema } from './step-basic-info/step-basic-info-schema';
import { stepLocationSchema } from './step-location/step-location-schema';
import { StepLocationLabel } from './step-location/step-location-label';
import { StepLocationContent } from './step-location/step-location-content';
import { StepIdentificatorsLabel } from './step-identificators/step-identificators-label';
import { StepIdentificatorsContent } from './step-identificators/step-identificators-content';
import { stepIdentificatorsSchema } from './step-identificators/step-identificators-schema';
import { StepActivitiesContent } from './step-activities/step-activities-content';
import { stepActivitiesSchema } from './step-activities/step-activities-schema';
import { StepActivitiesLabel } from './step-activities/step-activities-label';
import { StepControlAuthoritiesLabel } from './step-control-authorities/step-control-authorities-label';
import { StepControlAuthoritiesContent } from './step-control-authorities/step-control-authorities-content';
import { stepControlAuthoritiesSchema } from './step-control-authorities/step-control-authorities-schema';

export function useIrzFacilityRequestNew() {
  const steps = useMemo(
    () => [
      {
        Label: StepBasicInfoLabel,
        Content: StepBasicInfoContent,
        validationSchema: stepBasicInfoSchema,
      },
      {
        Label: StepLocationLabel,
        Content: StepLocationContent,
        validationSchema: stepLocationSchema,
      },
      {
        Label: StepIdentificatorsLabel,
        Content: StepIdentificatorsContent,
        validationSchema: stepIdentificatorsSchema,
      },
      {
        Label: StepActivitiesLabel,
        Content: StepActivitiesContent,
        validationSchema: stepActivitiesSchema,
      },
      {
        Label: StepControlAuthoritiesLabel,
        Content: StepControlAuthoritiesContent,
        validationSchema: stepControlAuthoritiesSchema,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    []
  );

  return { steps };
}

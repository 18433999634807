import React, { useContext } from 'react';
import { get } from 'lodash';
import {
  FormPanel,
  FormTextField,
  useFormSelector,
  FormCustomField,
  DetailContext,
  DetailMode,
  UserContext,
  PanelProps,
} from '@eas/common-web';
import {
  SubjectAutocomplete,
  SubjectAutocompleteProps,
} from './subject-autocomplete';
import { Subject, SubjectType, Me, PermissionsState } from '../../models';
import { EvidenceUrl, Permission } from '../../enums';
import { AddressLabel } from '../../components/form/address-field/address-label';
import { DetailNavigationButton } from '../../components/detail-navigation-button/detail-navigation-button';
import { HelpLabel } from '../../components/help/help-label';

interface SubjectFormPanelProps extends PanelProps {
  codePrefix: string;
  autocomplete: Omit<SubjectAutocompleteProps, 'codePrefix'>;
  hideIdentifier?: boolean;
  disabled?: boolean;
  addressLabel?: string;
}

export function SubjectFormPanel({
  codePrefix = 'SUBJECT',
  autocomplete,
  hideIdentifier,
  disabled,
  addressLabel,
  ...panelProps
}: SubjectFormPanelProps) {
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );
  const { mode } = useContext(DetailContext);

  const { subject } = useFormSelector((data: { [key: string]: Subject }) => ({
    subject: get(data, autocomplete.name, undefined),
  }));

  const showRedirect =
    hasPermission(Permission.Subject.SUBJECT_LIST_ALL) ||
    hasPermission(Permission.Subject.SUBJECT_LIST_OWN, {
      subjectId: subject?.id,
    });

  return (
    <FormPanel {...panelProps}>
      <SubjectAutocomplete
        name={autocomplete.name}
        label={autocomplete.label}
        required={autocomplete.required}
        codePrefix={codePrefix}
        disabled={disabled}
        notifyChange={autocomplete.notifyChange}
      />
      {(mode === DetailMode.VIEW || disabled) && (
        <>
          {!hideIdentifier && (
            <>
              {subject?.type === SubjectType.LEGAL_ENTITY && (
                <FormTextField
                  name={`${autocomplete.name}.ico`}
                  label={<HelpLabel label="IČO" code={`${codePrefix}_ICO`} />}
                  disabled
                />
              )}

              {subject?.type === SubjectType.BUSINESS_NATURAL_PERSON && (
                <FormTextField
                  name={`${autocomplete.name}.ico.value`}
                  label={<HelpLabel label="IČO" code={`${codePrefix}_ICO`} />}
                  disabled
                />
              )}

              {(subject?.type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO ||
                subject?.type === SubjectType.FOREIGN_LEGAL_ENTITY ||
                subject?.type === SubjectType.FOREIGN_NATURAL_PERSON ||
                subject?.type === SubjectType.NATURAL_PERSON) && (
                <FormTextField
                  name={`${autocomplete.name}.artificialId`}
                  label={
                    <HelpLabel
                      label="UID"
                      code={`${codePrefix}_ARTIFICIAL_ID`}
                    />
                  }
                  disabled
                />
              )}
            </>
          )}

          <AddressLabel
            name={`${autocomplete.name}.residence`}
            code={`${codePrefix}_RESIDENCE`}
            fieldLabel={addressLabel}
          />
          {subject?.id && showRedirect && (
            <FormCustomField>
              <DetailNavigationButton
                url={EvidenceUrl.SUBJECTS}
                id={subject?.id}
              />
            </FormCustomField>
          )}
        </>
      )}
    </FormPanel>
  );
}

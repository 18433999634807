import { IdentityProvider, EvidenceAPI } from '../../enums';
import { abortableFetch, useListSource } from '@eas/common-web';

export function getRedirectUrl(
  identityProvider: IdentityProvider,
  redirectPath: string,
  baseEnviiamUrlPath: string
) {
  return `${baseEnviiamUrlPath}cas/clientredirect?service=${window.location.origin}${redirectPath}&client_name=${identityProvider}&registration=true`;
}

export function parseTicket(ticket: string) {
  return abortableFetch(
    `${EvidenceAPI.USERS}/registration/idp/parse?ticket=${ticket}`
  );
}

export function addProviderToUser(ticket: string) {
  return abortableFetch(
    `${EvidenceAPI.USERS}/external-id/create?token=${ticket}`,
    {
      method: 'POST',
    }
  );
}

export function useIdentityProviders() {
  return useListSource<{ id: IdentityProvider; name: string }>({
    url: `${EvidenceAPI.USERS}/external-id/identity-provider/list`,
    method: 'GET',
  });
}

import React, { useMemo } from 'react';
import {
  FormPanel,
  FormInlineTableField,
  FormCheckbox,
  useFormSelector,
  FormAutocomplete,
  eqFilterParams,
  FormTextField,
} from '@eas/common-web';
import { useIppcPidColumns } from '../../ovz-facility/ovz-facilities-columns';
import { HelpLabel } from '../../../components/help/help-label';
import { StepIdentifiersForm } from './step-identifiers-types';
import { useTradesInEmissionsColumns } from '../../irz-facility/irz-facilities-columns';
import { EvidenceAPI } from '../../../enums';
import { ProcessForm } from '../ovz-facility-new-types';
import { useIrzFacilities } from '../../irz-facility/irz-facilities-api';
import { IrzFacility, DictionaryAutocomplete } from '../../../models';
import { DomainObjectAutocomplete } from '../../../models/domain-object';

const irzFacilityLabelMapper = (facility: DomainObjectAutocomplete) =>
  (facility as IrzFacility).facilityId ??
  (facility as DictionaryAutocomplete).label ??
  '';

export function StepIdentifiersContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  const ippcPidColumns = useIppcPidColumns();
  const tradesInEmissionsColumns = useTradesInEmissionsColumns();

  const { hasIntegratedPermission, tradesInEmissions } = useFormSelector(
    (data: StepIdentifiersForm) => ({
      hasIntegratedPermission: data.integratedPermission?.hasPermission,
      tradesInEmissions: data.emissionsTradingSystem?.tradesInEmissions,
    })
  );

  const irzFacilities = useIrzFacilities(
    eqFilterParams({
      field: 'operator.id',
      value: allValues.operator?.id,
    }),
    EvidenceAPI.IRZ_FACILITIES
  );

  const IppcIds = useMemo(
    () =>
      function IppcIds() {
        return (
          <FormInlineTableField<string>
            name="integratedPermission.ippcPids"
            label={
              <HelpLabel
                label="Seznam PID (IPPC)"
                code="OVZ_FACILITY_REQUEST_NEW_IPPC_PIDS"
              />
            }
            columns={ippcPidColumns}
            initNewItem={() => ''}
          />
        );
      },
    []
  );

  const EmissionsTradingSystems = useMemo(
    () =>
      function EmissionsTradingSystems() {
        return (
          <FormInlineTableField<string>
            name="emissionsTradingSystem.ids"
            label={
              <HelpLabel
                label="ID zařízení (EU ETS)"
                code="OVZ_FACILITY_REQUEST_NEW_EMISSIONS_TRADING_SYSTEM_IDS"
              />
            } // Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9
            columns={tradesInEmissionsColumns}
            initNewItem={() => ''}
          />
        );
      },
    []
  );

  return (
    <>
      <FormPanel label="Identifikátory">
        <FormAutocomplete
          source={irzFacilities}
          name="irzFacility"
          label={
            <HelpLabel
              label="IČP provozovny IRZ"
              code="OVZ_FACILITY_REQUEST_NEW_IRZ_FACILITY_ID"
            />
          }
          labelMapper={irzFacilityLabelMapper}
        />
        <FormTextField
          name="originalFacilityId"
          label={
            <HelpLabel
              label="Původní IČP provozovny OVZ"
              code="OVZ_FACILITY_REQUEST_NEWL_FACILITY_ID"
            />
          }
        />
        <FormCheckbox
          name="integratedPermission.hasPermission"
          label={
            <HelpLabel
              label="Provozovna/Zařízení má Integrované povolení"
              code="OVZ_FACILITY_REQUEST_NEW_INTEGRATED_PERMISSION_HAS_PERMISSION"
            />
          }
        />
        {hasIntegratedPermission && <IppcIds />}
        <FormCheckbox
          name="emissionsTradingSystem.tradesInEmissions"
          label={
            <HelpLabel
              label="Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi"
              code="OVZ_FACILITY_REQUEST_NEW_EMISSIONS_TRADING_SYSTEM_TRADES_IN_EMISSIONS"
            />
          }
        />
        {tradesInEmissions && <EmissionsTradingSystems />}
      </FormPanel>
    </>
  );
}

import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { DictionarySequence } from '../../models';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export function useColumns(): TableColumn<DictionarySequence>[] {
  return [
    {
      datakey: 'description',
      name: 'Popis',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory<DictionarySequence>(
        TableCells.TextCell
      ),
      FilterComponent: TableFilterCells.FilterTextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'format',
      name: 'Formát',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory<DictionarySequence>(
        TableCells.TextCell
      ),
      FilterComponent: TableFilterCells.FilterTextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'counter',
      name: 'Počítadlo',
      width: 200,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: inactiveColoredFactory<DictionarySequence>(
        TableCells.NumberCell
      ),
      FilterComponent: TableFilterCells.FilterNumberCell,
      sortable: true,
      filterable: true,
    },
  ];
}

import { SpecialFilter } from '../../components/evidence/history-evidence/overrides/table-searchbar';
import { SpecialFilterTextCell } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-text-cell';
import { useSubjectRegistrationState } from './subject-requests-api';
import { useSpecialFilterSelectCellFactory } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-select-cell';
import { ApiFilterOperation } from '@eas/common-web';

export function useSpecialFilters(): SpecialFilter[] {
  return [
    {
      label: 'Evidenční číslo',
      filterkey: 'evidenceNumber',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Stav žádosti',
      filterkey: 'state.id',
      operation: ApiFilterOperation.EQ,
      FilterCell: useSpecialFilterSelectCellFactory(
        useSubjectRegistrationState
      ),
    },
    {
      label: 'Název',
      filterkey: 'name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'E-mail - Autor',
      filterkey: 'author.email.value',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Autor',
      filterkey: 'createdBy.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'IČO / UID',
      filterkey: 'identifier',
      FilterCell: SpecialFilterTextCell,
    },
  ];
}

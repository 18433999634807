import { Exception } from '../../models';
import { CombineCallsFunction } from './custom-crud-types';
import { deleteRequest } from './delete';

export function deleteItemFactory<TYPE>({
  postprocessCall = () => {},
  combineDeleteCalls = async ({ mainItem }) => ({ data: mainItem, text: '' }),
}: {
  postprocessCall?: (item: TYPE | Exception, response: Response) => void;
  combineDeleteCalls?: CombineCallsFunction;
}) {
  return function deleteItem(api: string, itemId: string) {
    const controller = new AbortController();
    const signal = controller.signal;

    return {
      abort: () => controller.abort(),
      response: new Promise<Response>(() => {}),
      json: async () => {
        const [item, response] = await deleteRequest<TYPE | Exception>({
          url: `${api}/${itemId}`,
          signal,
        });

        postprocessCall(item!, response!);

        const { data } = await combineDeleteCalls({
          api,
          itemId,
          signal,
          mainItem: item,
        });

        return data;
      },
      raw: async () => new Promise<Response>(() => {}),
      text: async () => {},
      none: async () => {
        const [item, response] = await deleteRequest<TYPE | Exception>({
          url: `${api}/${itemId}`,
          signal,
        });

        postprocessCall(item!, response!);
      },
    };
  };
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useDictionaryBoilerModels } from '../dict-boiler-model/dict-boiler-models-api';
import { useDictionaryBoilerTypes } from '../dict-boiler-type/dict-boiler-types-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const boilerModels = useDictionaryBoilerModels();
  const boilerTypes = useDictionaryBoilerTypes();

  return [
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platný od',
    },
    {
      key: 'validTo',
      label: 'Platný do',
    },
    {
      key: 'code',
      label: 'Kód',
    },
    {
      key: 'description',
      label: 'Popis',
    },
    {
      key: 'models',
      label: 'Modely kotlů',
      source: boilerModels,
    },
    {
      key: 'manufacturer',
      label: 'Výrobce',
    },
    {
      key: 'allowedTypes',
      label: 'Typy kotlů',
      source: boilerTypes,
    },
  ];
}

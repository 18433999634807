import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { WaterManagementDecisionPart } from '../../models';
import { useColumns } from './water-management-decisions-columns';
import { WaterManagementDecisionFields } from './water-management-decisions-fields';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';
import { useHistoryDescriptors } from './water-management-decisions-history';
import { useValidationSchema } from './water-management-decisions-schema';

export function WaterManagementDecisions() {
  const permissions = usePermission({
    options: {
      NEW: [Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_CREATE],
      EDIT: [Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_UPDATE],
      REMOVE: [Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_DELETE],
      CHECK: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_CREATE,
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_UPDATE,
      ],
      SAVE: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_CREATE,
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_UPDATE,
      ],
      CLOSE: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_CREATE,
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_UPDATE,
      ],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<WaterManagementDecisionPart>({
    identifier: PageKey.WATER_MANAGEMENT_DECISIONS,
    apiProps: {
      url: EvidenceAPI.WATER_MANAGEMENT_DECISIONS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled rozhodnutí MUV',
      reportTag: ExportTags.WATER_MANAGEMENT_DECISIONS,
      defaultSorts: [],
      showColumn: () => false,
    },
    detailProps: {
      entityName: EntityName.WATER_MANAGEMENT_DECISION,
      validationSchema: useValidationSchema(),
      FieldsComponent: WaterManagementDecisionFields,
      toolbarProps: {
        title: 'Rozhodnutí MUV',
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission:
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}

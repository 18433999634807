import React from 'react';
import { FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../../../components/help/help-label';

export function ForeignNaturalPersonBasicFields({
  prefix,
  codePrefix = 'SUBJECT',
  disabled,
  hideUid = false,
}: {
  prefix?: string;
  codePrefix?: string;
  disabled?: boolean;
  /**
   * UID is hidden in registration, because UID is generated AFTER approved registration
   */
  hideUid?: boolean;
}) {
  const names = {
    artificialId: prefix ? `${prefix}.artificialId` : 'artificialId',
    degreeBefore: prefix
      ? `${prefix}.degreeBefore.value`
      : 'degreeBefore.value',
    firstName: prefix ? `${prefix}.firstName.value` : 'firstName.value',
    lastName: prefix ? `${prefix}.lastName.value` : 'lastName.value',
    degreeAfter: prefix ? `${prefix}.degreeAfter.value` : 'degreeAfter.value',
    birthDate: prefix ? `${prefix}.birthDate.value` : 'birthDate.value',
    deceaseDate: prefix ? `${prefix}.deceaseDate.value` : 'deceaseDate.value',
  };

  return (
    <>
      {!hideUid && (
        <FormTextField
          name={names.artificialId}
          label={<HelpLabel label="UID" code={`${codePrefix}_UID`} />} // Formát: ID_xxxxxx
          disabled
        />
      )}
      <FormTextField
        name={names.firstName}
        label={
          <HelpLabel label="Jméno" code={`${codePrefix}_FIRSTNAME`} required />
        } // Povinné pole
        disabled={disabled}
      />
      <FormTextField
        name={names.lastName}
        label={
          <HelpLabel
            label="Příjmení"
            code={`${codePrefix}_LASTNAME`}
            required
          />
        } // Povinné pole
        disabled={disabled}
      />
    </>
  );
}

import React from 'react';
import { ProcessForm } from '../irz-facility-merge-types';

export function StepMergingFacilityLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  const irzFacility = allValues?.mergingFacility;

  return (
    <>
      Provozovna ke sloučení: <b>{irzFacility?.name}</b>
    </>
  );
}

import React, { useContext, useEffect } from 'react';
import { ProcessForm } from '../production-site-split-types';
import { useStepNewProductionSite } from './step-new-production-site-hook';
import {
  useFormSelector,
  FormPanel,
  FormTextField,
  FormAutocomplete,
  FormTextArea,
  useAutocompleteSource,
  abortableFetch,
  FormContext,
} from '@eas/common-web';
import { StepNewProductionSiteForm } from './step-new-production-site-types';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { useIrzFacilitiesWithoutProductionSite } from '../../irz-facility/irz-facilities-api';
import { EvidenceAPI } from '../../../enums';
import { RuianObject } from '../../../models';
import { HelpLabel } from '../../../components/help/help-label';

const callGuess = function (lat: number, lon: number) {
  return abortableFetch(
    `${EvidenceAPI.ADDRESS_DISCTRICT}/guess?lat=${lat}&lon=${lon}`,
    {
      method: 'GET',
    }
  );
};

export function StepNewProductionSiteContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  useStepNewProductionSite(allValues);
  const { setFieldValue } = useContext(FormContext);

  const facilities = useIrzFacilitiesWithoutProductionSite();
  const districts = useAutocompleteSource({
    url: `${EvidenceAPI.ADDRESS_DISCTRICT}/autocomplete`,
  });

  const { wgs84Coordinates } = useFormSelector(
    ({ newProductionSite }: StepNewProductionSiteForm) => ({
      wgs84Coordinates: newProductionSite?.wgs84Coordinates,
    })
  );

  const guessDisctrict = async (lat: number, lon: number) => {
    const district = await callGuess(lat, lon).json();

    if (district) setFieldValue('newProductionSite.district', district);
  };

  useEffect(() => {
    if (wgs84Coordinates?.lat && wgs84Coordinates.lon) {
      guessDisctrict(wgs84Coordinates.lat, wgs84Coordinates.lon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wgs84Coordinates?.lat, wgs84Coordinates?.lon]);

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="newProductionSite.name"
          label={
            <HelpLabel
              label="Název"
              code="PRODUCTION_SITE_SPLIT_NEW_PRODUCTION_SITE_NAME"
              required
            />
          } // Povinné pole
        />
        <FormAutocomplete
          name="newProductionSite.facilities"
          label={
            <HelpLabel
              label="Provozovny"
              code="PRODUCTION_SITE_SPLIT_NEW_PRODUCTION_SITE_FACILITIES"
            />
          }
          source={facilities}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          multiple={true}
        />
        <FormTextArea name="newProductionSite.note" label="Poznámka" />
        <FormAutocomplete<RuianObject>
          name="newProductionSite.district"
          source={districts}
          label={
            <HelpLabel
              label="Okres"
              code="PRODUCTION_SITE_SPLIT_NEW_PRODUCTION_SITE_DISTRICT"
              required
            />
          } // Povinné pole
        />
      </FormPanel>
      <LocationPanel
        locationName="newProductionSite.wgs84Coordinates"
        locationValue={wgs84Coordinates}
        addressPanelLabel="Souřadnice"
        showCoordinates={true}
        showCoordinatesDistance={false}
      />
    </>
  );
}

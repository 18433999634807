import React, { useMemo, useContext } from 'react';
import { useAdminRoles } from './role-admin-hook';
import {
  Table,
  ApiFilterOperation,
  TableHandle,
  useUpdateEffect,
  useEventCallback,
  DetailMode,
  DetailContext,
} from '@eas/common-web';
import { User, RoleInstance } from '../../../../models';
import { ExportTags } from '../../../../enums/export-tags';
import { ToolbarExportButton } from '../../../../components/evidence/history-evidence/overrides/toolbar-export-button';

export function AdminRoles({
  tableName = 'Správce subjektu',
  profile,
  useStyles,
  showReportButton = true,
}: {
  tableName?: string;
  profile: User;
  useStyles?: () => TableHandle<RoleInstance>['classes'];
  showReportButton?: boolean;
}) {
  const detailCtx = useContext(DetailContext);
  const { source, columns } = useAdminRoles(profile);

  const filters = useMemo(
    () => [
      {
        field: 'role.relationship.id',
        operation: ApiFilterOperation.EQ,
        value: 'ADMINISTRATOR',
      },
    ],
    []
  );

  const include = useMemo(() => ['DEACTIVATED'], []);

  const sorts = useMemo(
    () => [
      {
        field: 'active',
        datakey: 'active',
        order: 'DESC' as const,
        type: 'FIELD' as const,
      },
      {
        field: 'validFrom',
        datakey: 'validFrom',
        order: 'DESC' as const,
        type: 'FIELD' as const,
      },
    ],
    []
  );

  useUpdateEffect(() => {
    if (detailCtx?.mode === DetailMode.VIEW) {
      source.reset();
      source.loadMore();
    }
  }, [profile.id]);

  const exportProvideData = useEventCallback(() => ({
    userId: profile.id,
  }));

  return (
    <Table
      version={7}
      tableId="ROLES_AS_ADMIN"
      tableName={tableName}
      columns={columns}
      source={source}
      height={300}
      showBulkActionButton={false}
      showSelectBox={false}
      showSearchbar={false}
      showReportButton={false}
      showResetSortsButton={true}
      reportTag={ExportTags.USER_ROLES}
      exportProvideData={exportProvideData}
      defaultPreFilters={filters}
      defaultSorts={sorts}
      include={include}
      useStyles={useStyles}
      toolbarProps={{
        after: detailCtx?.mode === DetailMode.VIEW && showReportButton && (
          <ToolbarExportButton />
        ),
      }}
    />
  );
}

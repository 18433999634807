import { Params } from '@eas/common-web';
import { User } from './user';
import { Role } from './role';
import { DictionaryObject, DictionaryAutocomplete } from './dictionary-object';
import { DatedObject } from './dated-object';

/**
 * Veřejný seznam
 */
export interface PublicList extends DictionaryObject {
  /**
   * Příznak definující přístup.
   */
  accessType?: PublicListAccessType;

  /**
   * Role, které mají přístup k soukromému seznamu.
   */
  allowedRoles?: Role[] | undefined;

  /**
   * Datový zdroj.
   */
  dataSource?: PublicListDataSource;

  /**
   * Parametry datového zdroje.
   */
  params?: any;

  /**
   * Interval aktualizace.
   */
  updateInterval?: string;

  /**
   * Stav.
   */
  state?: PublicListState;

  /**
   * Uživatel, který publikoval seznam.
   */
  publisher?: User;

  /**
   * Čas první publikace seznamu.
   */
  firstPublishedDate?: string;

  /**
   * Čas poslední publikace seznamu.
   */
  lastPublishedDate?: string;
}

/**
 * Záznam ve veřejném seznamu.
 */
export interface PublicRecord extends DatedObject {
  /**
   * Veřejný seznam.
   */
  list: PublicList;

  /**
   * Serializovaný obsah.
   */
  data: string;
}

export type PublicListAutocomplete = DictionaryAutocomplete;

export enum PublicListAccessType {
  /**
   * Veřejný
   */
  PUBLIC = 'PUBLIC',

  /**
   * Soukromý pro roli
   */
  FOR_ROLE = 'FOR_ROLE',
}

export enum PublicListDataSource {
  /**
   * Registr Uživatelů
   */
  USER_EVIDENCE = 'USER_EVIDENCE',

  /**
   * Registr Subjektů
   */
  SUBJECT_EVIDENCE = 'SUBJECT_EVIDENCE',

  /**
   * Registr Provozoven IRZ
   */
  IRZ_EVIDENCE = 'IRZ_EVIDENCE',

  /**
   * Registr Provozoven OVZ
   */
  OVZ_EVIDENCE = 'OVZ_EVIDENCE',

  /**
   * Registr MUV
   */
  WUP_EVIDENCE = 'WUP_EVIDENCE',

  /**
   * Registr Průmyslových míst
   */
  PROD_EVIDENCE = 'PROD_EVIDENCE',

  /**
   * Registr OZO - Výrobci
   */
  OZO_M_EVIDENCE = 'OZO_M_EVIDENCE',

  /**
   * Registr OZO - Oprávnění
   */
  OZO_P_EVIDENCE = 'OZO_P_EVIDENCE',
}

export enum PublicListState {
  /**
   * Koncept
   */
  CONCEPT = 'CONCEPT',

  /**
   * Publikován
   */
  PUBLISHED = 'PUBLISHED',
}

export enum DynamicFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  TIME = 'TIME',
  BOOLEAN = 'BOOLEAN',
}

export interface PublicListParamsColumn {
  datakey: string;
  display?: string;
  name: string;
  width?: number;
  sortable?: boolean;
  filterable?: boolean;
  filterGroup?: number;
  type: DynamicFieldType;
  valueMapperName?: string;
  valueMapperData?: DictionaryAutocomplete[];
}

export interface PublicListParams {
  columns: PublicListParamsColumn[];
  dataParams: Params;
}

/**
 * Publikace veřejného seznamu.
 */
export interface Publishing extends DatedObject {
  /**
   * Veřejný seznam.
   */
  list: PublicList;

  /**
   * Stav žádosti.
   */
  state: PublishingState;

  /**
   * Začátek.
   */
  startTime: string;

  /**
   * Konec.
   */
  endTime?: string;

  /**
   * Chybová správa.
   */
  message?: string;
}

export enum PublishingState {
  STARTED = 'STARTED',
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
}

import { useContext, useEffect } from 'react';
import { GeometryLayerContext } from './map-ctx';
import { GeometryEnum, GeometryOptions } from './map-types';
import { JtskCoordinates } from '../../models';

interface PolygonProps {
  points: JtskCoordinates[];
  id?: string;
  options?: GeometryOptions;
}

export function Polygon({ id, points, options = {} }: PolygonProps) {
  const geometryLayer = useContext(GeometryLayerContext);

  useEffect(() => {
    const polygon = new window.SMap.Geometry(
      GeometryEnum.POLYGON,
      id ?? false,
      points.map((point) => window.SMap.Coords.fromJTSK(point.x, point.y)),
      options
    );

    geometryLayer?.addGeometry(polygon);

    return () => {
      geometryLayer?.removeGeometry(polygon);
    };
  }, [geometryLayer, id, options]);

  return null;
}

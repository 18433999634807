import { MessageType } from './message-type';
import { SnackbarVariant } from '@eas/common-web';

const CREATE: MessageType<'ERROR' | 'NAME_NOT_UNIQUE'> = {
  NAME_NOT_UNIQUE: [
    'CHLU se zadaným názvem již existuje.',
    SnackbarVariant.ERROR,
  ],
  ERROR: ['Chyba při vytváření CHLU.', SnackbarVariant.ERROR],
};

export default { CREATE };

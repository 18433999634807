import * as Yup from 'yup';
import {
  Subject,
  SubjectType,
  LegalEntity,
  BusinessNaturalPerson,
} from '../../../models';

export const icoMaxLength = 8;
export const icoRegexp = /^\d{1,8}$/;
export const icoRules = `min. 1 znak (max. ${icoMaxLength} znaků), pouze číslice`;

export const identityCardMaxLength = 9;
export const identityCardRegex = /^\d{9}$/;
export const identityCardMessage = 'Občanský průkaz musí sestávat z 9 číslic';

export const validateIco = Yup.string()
  .nullable()
  .required('Povinné pole')
  .test('', '', function (val) {
    const { createError } = this;

    const isInvalid = !val || !icoRegexp.test(val);

    if (isInvalid) {
      return createError({
        message: `Nesplňuje pravidla: ${icoRules}`,
      });
    }

    return true;
  });

export function formatIco(value: string) {
  return value.padStart(icoMaxLength, '0');
}

export function formatIcoOnSubject(subject: Subject) {
  if (subject?.type === SubjectType.LEGAL_ENTITY) {
    const subjectTyped = { ...subject } as LegalEntity;
    if (typeof subjectTyped.ico === 'string') {
      subjectTyped.ico = formatIco(subjectTyped.ico);
      subject = subjectTyped;
    }
  } else if (subject?.type === SubjectType.BUSINESS_NATURAL_PERSON) {
    const subjectTyped = { ...subject } as BusinessNaturalPerson;
    if (typeof subjectTyped.ico?.value === 'string') {
      subjectTyped.ico.value = formatIco(subjectTyped.ico.value);
      subject = subjectTyped;
    }
  }

  return subject;
}

export const validateIdentityCard = Yup.string()
  .nullable()
  .required(identityCardMessage)
  .matches(identityCardRegex, identityCardMessage);

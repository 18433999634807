import { PersonalData } from '../../../models';

export const nameValueMapper = function nameValueMapper({
  rowValue,
}: {
  rowValue: PersonalData;
}) {
  if (rowValue.entityReference?.subject) {
    return rowValue.entityReference?.subject.name;
  }

  if (rowValue.entityReference?.subjectRegistration) {
    return rowValue.entityReference?.subjectRegistration.name;
  }

  return '-';
};

nameValueMapper.displayName = 'nameValueMapper';

import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { ReportAccessRuleFields } from './report-access-rules-fields';
import { useColumns } from './report-access-rules-columns';
import { EvidenceAPI, PageKey, Permission } from '../../../enums';
import { ExportTags } from '../../../enums/export-tags';
import { usePermission } from '../../../components/permission/perm-evidence-hook';
import { ReportAccessRule } from '../../../models/reporting';
import { useAuthoredEvidence } from '../../../components/evidence/authored-evidence/authored-evidence';
import { useHistoryDescriptors } from './report-access-rules-history';
import { DetailEmptyActionbar } from '../../../components/evidence/evidence/detail-empty-actionbar';

export function ReportAccessRules() {
  const permissions = usePermission<ReportAccessRule>({
    options: {
      NEW: [],
      EDIT: [Permission.ReportingAccessRule.REPORTING_ACCESS_RULE_UPDATE],
      REMOVE: [],
      CHECK: [],
      SAVE: [Permission.ReportingAccessRule.REPORTING_ACCESS_RULE_UPDATE],
      CLOSE: [Permission.ReportingAccessRule.REPORTING_ACCESS_RULE_UPDATE],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<ReportAccessRule>({
    identifier: PageKey.REPORTING_ACCESS_RULES,
    apiProps: {
      url: EvidenceAPI.REPORTING_ACCESS_RULES,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled pravidel přístupu k reportům',
      reportTag: ExportTags.REPORTING_ACCESS_RULES,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: ReportAccessRuleFields,
      toolbarProps: {
        title: 'Pravidlo',
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.ReportingAccessRule.REPORTING_ACCESS_RULE_DETAIL,
    },
  });

  return <Evidence2 {...evidence} />;
}

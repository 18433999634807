import React, { useContext } from 'react';
import {
  AuthoredObject,
  EvidenceProps,
  UserContext,
  DetailContainer,
  EmptyComponent,
} from '@eas/common-web';
import { Permission } from '../../../enums';
import { NotedEvidenceProps } from './noted-types';
import { noteGetFactory, noteUpdateFactory } from './noted-api';
import { useHistoryEvidence } from '../history-evidence/history-evidence';
import { NotedFields } from './noted-fields';

export function useNotedEvidence<OBJECT extends AuthoredObject>({
  detailProps,
  apiProps,
  noteEntityType,
  ...options
}: NotedEvidenceProps<OBJECT>): EvidenceProps<OBJECT> {
  /**
   * Context stuff
   */
  const { hasPermission } = useContext(UserContext);

  const notesVisible =
    hasPermission(Permission.Note.NOTES_LIST) && !!noteEntityType;

  return useHistoryEvidence({
    ...options,
    apiProps: {
      ...apiProps,
      getItem: notesVisible
        ? noteGetFactory(apiProps.getItem, noteEntityType!)
        : apiProps.getItem,
      updateItem: notesVisible
        ? noteUpdateFactory(apiProps.updateItem, noteEntityType!)
        : apiProps.updateItem,
    },
    detailProps: {
      ...detailProps,
      ContainerComponent: function Container(props) {
        const Container = detailProps?.ContainerComponent || DetailContainer;

        return (
          <Container {...props}>
            {React.Children.toArray(props.children).map((child) => {
              if (
                typeof child !== 'string' &&
                typeof child !== 'number' &&
                typeof (child as any).type !== 'string'
              ) {
                return React.cloneElement(child as React.ReactElement, {
                  notesVisible,
                  noteEntityType,
                  Notes:
                    noteEntityType && notesVisible ? (
                      <NotedFields entityType={noteEntityType} />
                    ) : (
                      <EmptyComponent />
                    ),
                });
              } else {
                return child;
              }
            })}
          </Container>
        );
      },
    },
  });
}

import React from 'react';
import {
  FormPanel,
  FormSelect,
  FormDateField,
  useFormSelector,
  FormTextArea,
  FormTextField,
} from '@eas/common-web';
import { RegistrationFields } from './fields/registration-fields';
import { useRequestTypes, useRequestStates } from './irz-facility-requests-api';
import {
  IrzFacilityRequest,
  IrzRequestType,
} from '../../models/irz-facility-request';
import { ChangeFields } from './fields/change-fields';
import { OperatorChangeFields } from './fields/operator-change-fields';
import { InvalidateFields } from './fields/invalidate-fields';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { AuthoredFields } from '../../components/evidence/authored-evidence/authored-fields';
import { DatedFields } from '../../components/evidence/dated-evidence/dated-fields';
import { HelpLabel } from '../../components/help/help-label';

export function IrzFacilityRequestsFields(props: NotedEvidenceFieldsProps) {
  const requestTypes = useRequestTypes();
  const requestStates = useRequestStates();

  const { type } = useFormSelector((request: IrzFacilityRequest) => ({
    type: request.type,
    irzFacility: request.irzFacility,
  }));

  return (
    <>
      <FormPanel label="Žádost">
        <FormTextField
          name="number"
          label={
            <HelpLabel
              label="Evidenční číslo"
              code="IRZ_FACILITY_REQUESTS_EVIDENCE_NUMBER"
            />
          }
          disabled={true}
        />
        <FormSelect
          source={requestTypes}
          name="type"
          label={<HelpLabel label="Typ" code="IRZ_FACILITY_REQUESTS_TYPE" />}
          valueIsId={true}
        />
        <FormSelect
          source={requestStates}
          name="state"
          label={<HelpLabel label="Stav" code="IRZ_FACILITY_REQUESTS_STATE" />}
          valueIsId={true}
        />
        <FormTextArea
          name="note"
          label={
            <HelpLabel label="Poznámka" code="IRZ_FACILITY_REQUESTS_NOTE" />
          }
        />
        {type === IrzRequestType.INVALIDATION && (
          <FormDateField
            name="requestFacilityData.validTo"
            label={
              <HelpLabel
                label="Neplatná od"
                code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_VALID_TO"
              />
            }
            disabled
          />
        )}
        <FormTextField
          name="userEmail.value"
          label={
            <HelpLabel
              label="Email odesílatele"
              code="IRZ_FACILITY_REQUESTS_USER_EMAIL"
            />
          }
        />
        <FormDateField
          name="decided"
          label={
            <HelpLabel
              label="Datum a čas vyřízení"
              code="IRZ_FACILITY_REQUESTS_DECIDED"
            />
          }
        />
      </FormPanel>

      {type === IrzRequestType.REGISTRATION && <RegistrationFields />}
      {type === IrzRequestType.CHANGE && <ChangeFields />}
      {type === IrzRequestType.OPERATOR_CHANGE && <OperatorChangeFields />}
      {type == IrzRequestType.INVALIDATION && <InvalidateFields />}

      {props.Notes}
      {props.HistoryRecords}

      <FormPanel label="Obecné" expandable defaultExpanded={false}>
        <AuthoredFields FieldsComponent={DatedFields} hideUpdatedBy={true} />
      </FormPanel>
    </>
  );
}

import React, { useEffect, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  BreadcrumbCtx,
  initialBreadcrumbs,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { PageKey } from '../../enums';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    actions: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '18px',
    },
    buttonText: {
      color: 'white',
      fontWeight: 600,
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
    },
  })
);

export function PasswordResetCompleted() {
  const classes = useStyles();

  const { push } = useHistory();
  const { setBreadcrumbs } = useContext(BreadcrumbCtx);

  useEffect(() => {
    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Vytvoření nového hesla',
        key: PageKey.PASSWORD_RESET,
        to: '/obnova-hesla',
      },
      {
        title: 'Heslo změněno',
        key: PageKey.PASSWORD_RESET_COMPLETED,
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToLogin = () => {
    push('/prihlaseni');
  };

  return (
    <>
      <Typography variant="body1" align="center">
        Heslo bylo změněno, pokračujte přihlášením do aplikace.
      </Typography>
      <div className={classes.actions}>
        <Button
          onClick={handleGoToLogin}
          variant="contained"
          disableElevation
          color="primary"
        >
          <Typography
            component="span"
            variant="body1"
            className={classes.buttonText}
          >
            Přejít k přihlášení
          </Typography>
        </Button>
      </div>
    </>
  );
}

import { useAutocompleteSource } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { DictionaryDeposit } from './../../models';

export function useDirpTypes() {
  return useAutocompleteSource<DictionaryDeposit>({
    url: `${EvidenceAPI.DICTIONARY_DIRP_TYPES}/autocomplete`,
    apiUrl: EvidenceAPI.DICTIONARY_DIRP_TYPES,
  });
}

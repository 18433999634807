import React, { useContext } from 'react';
import {
  FormPanel,
  FormSelect,
  useFormSelector,
  FormTextField,
  FormDateTimeField,
  FormAutocomplete,
  Button,
  DetailContext,
  DetailMode,
  useEventCallback,
  FormContext,
} from '@eas/common-web';
import { PublicList, PublicListAccessType } from '../../models/public-list';
import { useAccessTypes, useDataSources, useStates } from './public-lists-api';
import { useRoles } from '../roles/roles-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { useStyles } from './public-lists-styles';
import { HelpLabel } from '../../components/help/help-label';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';

export function PublicListsFields(props: NotedEvidenceFieldsProps) {
  const { mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);
  const accessTypes = useAccessTypes();
  const dataSources = useDataSources();
  const states = useStates();

  const roles = useRoles();

  const set1Hour = useEventCallback(() => {
    setFieldValue('updateInterval', '0 0 * * * *');
  });

  const set4Hours = useEventCallback(() => {
    setFieldValue('updateInterval', '0 0 */4 * * *');
  });

  const set12Hours = useEventCallback(() => {
    setFieldValue('updateInterval', '0 0 */12 * * *');
  });

  const set1Day = useEventCallback(() => {
    setFieldValue('updateInterval', '0 0 22 * * *');
  });

  const set1Week = useEventCallback(() => {
    setFieldValue('updateInterval', '0 0 0 * * 0');
  });

  const set1Month = useEventCallback(() => {
    setFieldValue('updateInterval', '0 0 0 1 * *');
  });

  const accessType = useFormSelector((data: PublicList) => data.accessType);

  const classes = useStyles();

  // const cronHelp =
  //   '<pre>Interval se uvádí v rozšířeném CRONtab formátu:<br/>' +
  //   '┌───────── sekundy (0-59)<br/>' +
  //   '│ ┌───────── minuty (0 - 59)<br/>' +
  //   '│ │ ┌───────── hodiny (0 - 23)<br/>' +
  //   '│ │ │ ┌───────── den v měsíci (1 - 31)<br/>' +
  //   '│ │ │ │ ┌───────── měsíc (1 - 12)<br/>' +
  //   '│ │ │ │ │ ┌───────── den v týdnu (1 - 7)<br/>' +
  //   '│ │ │ │ │ │<br/>' +
  //   '* * * * * *<br/>' +
  //   'Příklady:<br/>' +
  //   '"0 0 * * * *" = každou hodinu každý den<br/>' +
  //   '"*/10 * * * * *" = každých 10 vteřin<br/>' +
  //   '"0 0 8-10 * * *" = v 8,9 a 10 každý den<br/>' +
  //   '"0 0 6,19 * * *" = v 6 a v 19 každý den<br/>' +
  //   '"0 0/30 8-9 * * *" = v 8:00, 8:30, 9:00 a 9:30 každý den<br/>' +
  //   '"0 0 0 24 12 ?" = každé Vánoce o půl noci<br/>';

  return (
    <>
      <FormPanel label="Základní informace">
        <FormSelect
          name="accessType"
          label={
            <HelpLabel
              label="Omezení přístupu"
              code="PUBLIC_LISTS_ACCESS_TYPE"
            />
          }
          source={accessTypes}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        {accessType === PublicListAccessType.FOR_ROLE && (
          <FormAutocomplete
            name="allowedRoles"
            required
            label={
              <HelpLabel
                label="Role"
                code="PUBLIC_LISTS_ALLOWED_ROLE"
                required
              />
            } // Povinné pole
            multiple={true}
            source={roles}
            labelMapper={dictionaryLabelMapper}
            tooltipMapper={dictionaryLabelMapper}
          />
        )}
        <FormSelect
          name="dataSource"
          label={
            <HelpLabel label="Datový zdroj" code="PUBLIC_LISTS_DATA_SOURCE" />
          }
          disabled
          source={dataSources}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        {/* <FormEditor name="params" language={'json'} label={<HelpLabel label="Parametry" code="PUBLIC_LISTS_PARAMETERS" />} /> */}
        <FormTextField
          name="updateInterval"
          label={
            <HelpLabel
              label="Interval aktualizace"
              code="PUBLIC_LISTS_UPDATE_INTERVAL"
            />
          }
          // helpLabel={cronHelp}
          after={
            <>
              {(mode === DetailMode.NEW || mode === DetailMode.EDIT) && (
                <>
                  <Button
                    label={<span className={classes.button}>1H</span>}
                    onClick={set1Hour}
                    tooltip="Každou hodinu"
                  />
                  <Button
                    label={<span className={classes.button}>4H</span>}
                    onClick={set4Hours}
                    tooltip="Každé 4 hodiny"
                  />
                  <Button
                    label={<span className={classes.button}>12H</span>}
                    onClick={set12Hours}
                    tooltip="Každých 12 hodin"
                  />
                  <Button
                    label={<span className={classes.button}>1D</span>}
                    onClick={set1Day}
                    tooltip="Každý den"
                  />
                  <Button
                    label={<span className={classes.button}>1T</span>}
                    onClick={set1Week}
                    tooltip="Každý týden"
                  />
                  <Button
                    label={<span className={classes.button}>1M</span>}
                    onClick={set1Month}
                    tooltip="Každý měsíc"
                  />
                </>
              )}
            </>
          }
        />
        <FormSelect
          name="state"
          disabled
          label={<HelpLabel label="Stav" code="PUBLIC_LISTS_STATE" />}
          source={states}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        <FormTextField
          name="publisher.name"
          disabled
          label={
            <HelpLabel label="Vydavatel" code="PUBLIC_LISTS_PUBLISHER_NAME" />
          }
        />
        <FormDateTimeField
          name="firstPublishedDate"
          label={
            <HelpLabel
              label="První publikace"
              code="PUBLIC_LISTS_FIRST_PUBLISHED_DATE"
            />
          }
          disabled
        />
        <FormDateTimeField
          name="lastPublishedDate"
          label={
            <HelpLabel
              label="Poslední publikace"
              code="PUBLIC_LISTS_LAST_PUBLISHED_DATE"
            />
          }
          disabled
        />
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

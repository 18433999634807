import React, { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  DetailMode,
  PromptContext,
  usePrompts,
  FormTextArea,
} from '@eas/common-web';
import { SubjectRegistration, SubjectRegistrationState } from '../../../models';
import { EvidenceAPI, Permission, Messages } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
function callApi(id: string, values: { note?: string }) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECT_REQUESTS}/${id}/authorize/letter-received/reject`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        note: values.note,
      }),
    }
  );
}

/**
 * Approve subject registration.
 */
const PROMPT_KEY = 'CANCEL';

export function useCancelDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source, mode } = useContext<
    DetailHandle<SubjectRegistration>
  >(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { reload, hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Zrušit registraci',
        dialogText: 'Skutečně chcete zrušit registraci subjektu?',
        FormFields: function Body() {
          return (
            <>
              <FormTextArea name="note" label="Poznámka" />
            </>
          );
        },
        dialogWidth: 600,
      },
    ],
    [source.data?.created]
  );

  const handleCancel = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: { note?: string }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(source.data!.id, values);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.SubjectRegistration.REJECT.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.SubjectRegistration.REJECT.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showCancelButton =
    mode === DetailMode.VIEW &&
    source.data?.state === SubjectRegistrationState.WAITING_FOR_AUTHORIZATION &&
    (hasPermission(Permission.Subject.SUBJECT_REQUEST_AUTHORIZE_ALL) ||
      hasPermission(Permission.Subject.SUBJECT_REQUEST_AUTHORIZE_OWN));

  return {
    handleCancel,
    showCancelButton,
  };
}

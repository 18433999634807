import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import { Notification } from '../../models';
import {
  useNotificationEvents,
  useNotificationTypes,
} from '../notification-templates/notification-templates-api';

export function useColumns(): TableColumn<Notification>[] {
  return [
    {
      datakey: 'event',
      sortkey: 'event.name',
      filterkey: 'event.id',
      name: 'Událost',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useNotificationEvents
      ),
      valueMapper: TableCells.useSelectCellFactory(useNotificationEvents),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'recipient.email.value',
      sortkey: 'mail.to',
      filterkey: 'mail.to',
      name: 'Email příjemce',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ notifikace',
      width: 150,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useNotificationTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useNotificationTypes),
      sortable: true,
      filterable: true,
    },
  ];
}

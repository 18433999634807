import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  snackbar: {
    bottom: 40,
  },
  content: {
    maxWidth: 520,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: 'flex-start',
    backgroundColor: theme.palette.error.main,
  },
  message: {
    maxWidth: 'calc(100% - 32px)',
    paddingTop: 0,
    paddingBottom: 0,
  },
  messageInner: {
    display: 'flex',
    alignItems: 'flex-start',
    whiteSpace: 'pre-line',
  },
  link: {
    color: theme.palette.error.contrastText,
    textDecoration: 'underline',
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  action: {
    paddingLeft: 0,
  },
  closeIcon: {
    fontSize: 20,
    opacity: 0.9,
  },
}));

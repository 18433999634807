import React, { useMemo, useContext } from 'react';
import { Markers } from '../../components/map/markers';
import { useCardData } from '../irz-facility/actions/irz-facility-map-hook';
import { NavigationContext, EvidenceStateAction } from '@eas/common-web';
import { EvidenceUrl } from '../../enums';
import { IrzFacility, IrzFacilityCoordinate } from '../../models';
import Button from '@material-ui/core/Button/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  button: {
    boxShadow: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

interface MapMarkersProps {
  items: IrzFacilityCoordinate[];
  onAddBtnClick: (data: IrzFacility) => void;
  onRemoveBtnClick: (id: string) => void;
  showAddBtn: (id: string) => boolean;
  showRemoveBtn: (id: string) => boolean;
}

export function MapMarkers({
  items,
  onAddBtnClick,
  onRemoveBtnClick,
  showAddBtn,
  showRemoveBtn,
}: MapMarkersProps) {
  const { navigate } = useContext(NavigationContext);
  const navigateToIrzFacility = (id: string) => {
    navigate(EvidenceUrl.IRZ_FACILITIES, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: id,
    });
  };

  const Actions = ({ data }: { data?: IrzFacility }) => {
    const classes = useStyles();

    const closeBtn = document.getElementsByClassName('close')[0] as HTMLElement;

    return (
      <>
        {data?.id && showAddBtn(data.id) && (
          <Button
            className={classes.button}
            onClick={() => {
              onAddBtnClick(data);
              closeBtn.click();
            }}
            size="small"
            variant="contained"
          >
            Přidat
          </Button>
        )}
        {data?.id && showRemoveBtn(data.id) && (
          <Button
            className={classes.button}
            onClick={() => {
              onRemoveBtnClick(data.id);
              closeBtn.click();
            }}
            size="small"
            variant="contained"
          >
            Odebrat
          </Button>
        )}
      </>
    );
  };

  const { cardData } = useCardData(navigateToIrzFacility, Actions);

  const markers = useMemo(() => {
    return items.map((item) => ({
      id: item.id,
      key: item.id,
      lat: item.lat!,
      lng: item.lon!,
      active: item.assigned,
      selected: item.assigned,
    }));
  }, [items]);

  return <Markers markers={markers} card={cardData} />;
}

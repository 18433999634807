import { createContext } from 'react';
import { SMap, MarkerLayer, GeometryLayer } from './map-types';

export const MapContext = createContext<{
  map: SMap | null;
  items: any[];
  isLoaded: boolean;
}>({
  map: null,
  items: [],
  isLoaded: false,
});

export const MarkerLayerContext = createContext<MarkerLayer | null>(null);

export const GeometryLayerContext = createContext<GeometryLayer | null>(null);

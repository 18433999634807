import { useEffect, useContext } from 'react';
import {
  FormContext,
  useFormSelector,
  useEventCallback,
} from '@eas/common-web';
import { StepEditForm } from './step-edit-types';
import { ProcessForm } from '../production-site-merge-types';

export function useStepEdit(allValues: ProcessForm) {
  const { setFieldValue } = useContext(FormContext);

  const { productionSiteId } = useFormSelector(
    ({ editData }: StepEditForm) => ({
      productionSiteId: editData?.id,
    })
  );

  const resetValues = useEventCallback(() => {
    setFieldValue('editData', allValues.editData);
  });

  /**
   * If the facility was changed in the first step, we need to reset the form,
   * to have correct default values.
   */
  useEffect(() => {
    if (productionSiteId !== allValues.mainProductionSite?.id) {
      resetValues();
    }
  }, []);
}

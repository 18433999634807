import {
  useStaticListSource,
  DictionaryAutocomplete,
  useListSource,
  useAutocompleteSource,
  ResultDto,
  eqFilterParams,
} from '@eas/common-web';
import {
  WaterUsagePlacesType,
  WaterManagementDecisionPart,
} from '../../models';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import { post } from '../../utils/custom-crud';
import { coordinateValueMapper } from './../../composite/facility-column-mappers/coordinate-mapper';
import { EvidenceAPI } from '../../enums';

export async function combineGetCalls({
  itemId,
  signal,
  mainItem,
}: CombineCallsType) {
  const json = {
    ...mainItem,
  };

  const [waterManagementDecisionParts] = await post<
    ResultDto<WaterManagementDecisionPart>
  >({
    url: `${EvidenceAPI.WATER_MANAGEMENT_DECISION_PARTS}/list`,
    signal,
    body: {
      ...eqFilterParams({ field: 'waterUsagePlace.id', value: itemId }),
      sort: [
        { field: 'mostValid', order: 'DESC', type: 'FIELD' },
        { field: 'validTo', order: 'DESC', type: 'FIELD', missing: 'FIRST' },
        { field: 'validFrom', order: 'DESC', type: 'FIELD' },
      ],
    },
  });

  if (waterManagementDecisionParts?.items?.length) {
    json['waterManagementDecisionParts'] = waterManagementDecisionParts?.items;
  }

  const text = JSON.stringify(json);

  if (json.wgs84Coordinates) {
    json['wgs84Coordinates'] = {
      lat: coordinateValueMapper({ value: json.wgs84Coordinates.lat }),
      lon: coordinateValueMapper({ value: json.wgs84Coordinates.lon }),
    };
  }

  return {
    data: json,
    text,
  };
}

export function useTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: WaterUsagePlacesType.WATER_ACCUMULATION, name: 'Akumulace vody' },
    {
      id: WaterUsagePlacesType.GROUNDWATER_ABSTRACTION,
      name: 'Odběr podzemní vody',
    },
    {
      id: WaterUsagePlacesType.SURFACE_WATER_ABSTRACTION,
      name: 'Odběr povrchové vody',
    },
    {
      id: WaterUsagePlacesType.UNDERGROUND_DISCHARGE,
      name: 'Vypouštění do podzemí',
    },
    { id: WaterUsagePlacesType.STREAM_DISCHARGE, name: 'Vypouštění do toku' },
  ]);
}

export function useBasinEnterprises() {
  return useListSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.BASIN_ENTERPIRSES}/list`,
    method: 'GET',
  });
}

export function useWaterUsagePlaces() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: `${EvidenceAPI.WATER_USAGE_PLACES}/autocomplete`,
  });
}

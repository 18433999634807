/**
 * Role request permissions
 */
export enum PermissionRoleRequest {
  /** Přehled všech žádostí o roli */
  ROLE_REQUEST_LIST_ALL = 'ROLE_REQUEST_LIST_ALL',

  /** Přehled žádostí o roli, které uživatel může posuzovat */
  ROLE_REQUEST_LIST_OWN = 'ROLE_REQUEST_LIST_OWN',

  /** Detail všech žádostí o roli */
  ROLE_REQUEST_DETAIL_ALL = 'ROLE_REQUEST_DETAIL_ALL',

  /** Detail žádostí o roli, které uživatel může posuzovat */
  ROLE_REQUEST_DETAIL_OWN = 'ROLE_REQUEST_DETAIL_OWN',

  /** Editace všech žádostí o roli */
  ROLE_REQUEST_UPDATE_ALL = 'ROLE_REQUEST_UPDATE_ALL',

  /** Editace žádostí o roli, které uživatel může posuzovat */
  ROLE_REQUEST_UPDATE_OWN = 'ROLE_REQUEST_UPDATE_OWN',

  /** Dokončení všech žádostí o roli */
  ROLE_REQUEST_FINISH_ALL = 'ROLE_REQUEST_FINISH_ALL',

  /** Dokončení žádostí o roli, které uživatel může posuzovat */
  ROLE_REQUEST_FINISH_OWN = 'ROLE_REQUEST_FINISH_OWN',
}

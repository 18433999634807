import { TableColumn, TableCells } from '@eas/common-web';
import { Region } from '../../models';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';

export const useColumns = (): TableColumn<Region, any>[] => {
  const columns = [
    {
      datakey: 'nutsLauCode',
      name: 'Kód NUTS/LAU',
      width: 150,
      CellComponent: inactiveColoredFactory<Region>(TableCells.TextCell),
    },
  ];

  return columns;
};

import React, { useEffect, useContext } from 'react';
import {
  FormPanel,
  FormTextField,
  FormRadioGroup,
  useFormSelector,
  FormSelect,
  FormContext,
} from '@eas/common-web';
import { useOzoSubjectType } from './step-ozo-subject-api';
import { StepOzoSubjectForm } from './step-ozo-subject-types';
import { useDocumentTypes } from '../../subject/subjects-api';
import { HelpLabel } from '../../../components/help/help-label';
import { identityCardMaxLength } from '../../subject/fields/subjects-utils';
import { SubjectType, DocumentType } from '../../../models';

export function StepOzoSubjectContent() {
  const { setFieldValue } = useContext(FormContext);
  const { subjectType, identityDocumentType } = useFormSelector(
    (data: StepOzoSubjectForm) => ({
      subjectType: data.ozoSubjectType,
      identityDocumentType: data.identityDocumentType,
    })
  );

  const ozoSubjectTypes = useOzoSubjectType();
  const documentTypes = useDocumentTypes();

  useEffect(() => {
    setFieldValue('identityDocumentType', null);
    setFieldValue('idNumber', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectType]);

  return (
    <FormPanel label="Vyplňte">
      <FormRadioGroup
        source={ozoSubjectTypes}
        valueIsId
        label={
          <HelpLabel
            label="Typ subjektu"
            code="BOILER_PERMISSION_NEW_OZO_SUBJECT_TYPE"
            required
          />
        } // Povinné pole
        name="ozoSubjectType"
      />
      {subjectType === SubjectType.NATURAL_PERSON && (
        <>
          <FormSelect
            name="identityDocumentType"
            label={
              <HelpLabel
                label="Typ dokladu"
                code="BOILER_PERMISSION_NEW_IDENTITY_DOCUMENT_TYPE"
                required
              />
            } // Povinné pole
            source={documentTypes}
            valueIsId
          />
          <FormTextField
            name="idNumber"
            label={
              <HelpLabel
                label="Číslo dokladu"
                code="BOILER_PERMISSION_NEW_ID_NUMBER"
                required
              />
            } // Povinné pole
            inputProps={{
              maxLength:
                identityDocumentType !== DocumentType.PASSPORT
                  ? identityCardMaxLength
                  : undefined,
            }}
          />
        </>
      )}
      {subjectType === SubjectType.FOREIGN_NATURAL_PERSON && (
        <>
          <FormTextField
            name="idNumber"
            label={
              <HelpLabel
                label="UID"
                code="BOILER_PERMISSION_NEW_ID_NUMBER"
                required
              />
            } // Povinné pole
          />
        </>
      )}
    </FormPanel>
  );
}

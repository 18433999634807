import React, { useMemo } from 'react';
import { render } from 'react-dom';
import { useContext, useEffect } from 'react';
import { MarkerLayerContext } from './map-ctx';
import customRedMarker from './assets/bigRedMarker.png';
import customGreyMarker from './assets/bigGreyMarker.png';
import customBlueMarker from './assets/drop-blue.png';
import customDeactivatedMarker from './assets/drop-grey.png';

import {
  MarkerProps,
  MarkerType,
  Card,
  Coords,
  MapLayerEnum,
  MarkerOptions,
} from './map-types';
import { useEventCallback } from '@eas/common-web';
import { noop } from 'lodash';

const CUSTOM_MARKER_WIDTH = 48;
const CUSTOM_MARKER_HEIGHT = 66;

function customMarkerOptions(type: MarkerType, innerText?: string) {
  const markerWrapper = window.JAK.mel('div', null, {
    width: `${CUSTOM_MARKER_WIDTH}px`,
    height: `${CUSTOM_MARKER_HEIGHT}px`,
  });
  const markerImage = window.JAK.mel('img', {
    src: type === MarkerType.CUSTOM_GREY ? customGreyMarker : customRedMarker,
  });
  const textStyles = {
    position: 'absolute',
    width: '100%',
    top: '13px',
    textAlign: 'center',
    fontWeight: 'bold',
  };
  const innerTitleWrapper = window.JAK.mel('div', {}, textStyles);
  if (innerText) {
    innerTitleWrapper.innerHTML = innerText;
  }
  markerWrapper.appendChild(markerImage);
  markerWrapper.appendChild(innerTitleWrapper);
  return {
    url: markerWrapper,
    anchor: { left: CUSTOM_MARKER_WIDTH / 2, bottom: 0 },
    title: '',
  };
}

function selectedMarkerOptions() {
  const markerWrapper = window.JAK.mel('div');

  const markerImage = window.JAK.mel('img', {
    src: customBlueMarker,
  });

  markerWrapper.appendChild(markerImage);

  return {
    url: markerWrapper,
    title: '',
  };
}

function inactiveMarkerOptions() {
  const markerWrapper = window.JAK.mel('div');

  const markerImage = window.JAK.mel('img', {
    src: customDeactivatedMarker,
  });

  markerWrapper.appendChild(markerImage);

  return {
    url: markerWrapper,
    title: '',
  };
}

export function createSMapMarker(
  {
    lat,
    lng,
    body,
    innerTitle,
    title,
    type = MarkerType.DEFAULT,
    id,
    active,
    selected,
    draggable,
  }: MarkerProps,
  card: Card
) {
  const coords = window.SMap.Coords.fromWGS84(Number(lng), Number(lat));
  let options: MarkerOptions =
    type === MarkerType.DEFAULT ? {} : customMarkerOptions(type, innerTitle);
  if (draggable) {
    options.title = 'Přesunte pomocí myši';
  }
  if (active === false) {
    options = inactiveMarkerOptions();
    options.title = 'Neaktivní';
  }
  if (selected) {
    options = selectedMarkerOptions();
  }
  const sMarker = new window.SMap.Marker(coords, id ?? false, options);

  if (draggable) {
    sMarker.decorate(window.SMap.Marker.Feature.Draggable);
  }

  if (title) {
    render(<>{title}</>, card.getHeader());
  }

  if (body) {
    render(<>{body}</>, card.getBody());
  }

  if (title || body) {
    sMarker.decorate(window.SMap.Marker.Feature.Card, card);
  }
  return sMarker;
}

export function Marker({
  id,
  lng,
  lat,
  title,
  body,
  type,
  innerTitle,
  onDragEnd = noop,
  draggable = false,
}: MarkerProps) {
  const markerLayer = useContext(MarkerLayerContext);
  const card = useMemo(() => new window.SMap.Card(), []);
  const handleDragStart = useEventCallback((e) => {
    if (draggable) {
      const node = e.target.getContainer();
      const cursorElement = node[MapLayerEnum.MARKER] as HTMLElement;
      cursorElement.style.cursor = 'grabbing';
    }
  });
  const handleDragStop = useEventCallback((e) => {
    if (draggable) {
      const node = e.target.getContainer();
      const cursorElement = node[MapLayerEnum.MARKER] as HTMLElement;
      cursorElement.style.cursor = '';
      const coords = e.target.getCoords() as Coords;
      const [lon, lat] = coords.toWGS84();
      onDragEnd({ lon, lat });
    }
  });
  useEffect(() => {
    const sMarker = createSMapMarker(
      { lat, lng, body, id, innerTitle, title, type, draggable },
      card
    );
    const signals = markerLayer?.getMap()?.getSignals();
    const dragStartSignal =
      draggable &&
      signals?.addListener(window, 'marker-drag-start', handleDragStart);
    const dragEndSignal =
      draggable &&
      signals?.addListener(window, 'marker-drag-stop', handleDragStop);

    markerLayer?.addMarker(sMarker);
    return () => {
      markerLayer?.removeMarker(sMarker, true);
      if (dragStartSignal) {
        signals?.removeListener(dragStartSignal);
      }
      if (dragEndSignal) {
        signals?.removeListener(dragEndSignal);
      }
    };
  }, [
    body,
    lat,
    lng,
    markerLayer,
    title,
    card,
    innerTitle,
    type,
    id,
    draggable,
  ]);

  return null;
}

import { useMemo } from 'react';
import { useContext, useEffect } from 'react';
import { MarkerLayerContext } from './map-ctx';
import { MarkersProps } from './map-types';
import { createSMapMarker } from './marker';

/**
 * Helper component to increase rendering performance of large amount of markers
 */
export function Markers({ markers, card }: MarkersProps) {
  const markerLayer = useContext(MarkerLayerContext);

  const sCard = useMemo(() => new window.SMap.Card(), []);

  useEffect(() => {
    // if (markers.length) {
    const sMarkers = markers.map((marker) => {
      return createSMapMarker({ ...marker, ...card }, sCard);
    });
    markerLayer?.addMarker(sMarkers);
    return () => {
      markerLayer?.removeMarker(sMarkers, true);
    };
    // }
  });

  return null;
}

import React, { useContext } from 'react';
import { get, noop } from 'lodash';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import {
  useEventCallback,
  NavigationContext,
  DashboardCardAction,
  DashboardCardProps,
} from '@eas/common-web';
import { EvidenceUrl } from '../../../enums';
import { CustomCardBase } from './custom-card-base';

export function CardOZO({ report, definitionId }: DashboardCardProps) {
  const { navigate } = useContext(NavigationContext);

  const manufacturerCount = get(report, 'data[0].count');
  const permissionCount = get(report, 'data[1].count');
  const subjectsCount = get(report, 'data[2].count');

  const navigateManufacturers = useEventCallback(() => {
    navigate(EvidenceUrl.BOILER_MANUFACTURERS);
  });

  const navigatePermissions = useEventCallback(() => {
    navigate(EvidenceUrl.BOILER_PERMISSIONS);
  });

  const navigateSubjects = useEventCallback(() => {
    navigate(EvidenceUrl.OZO_SUBJECTS);
  });

  return (
    <CustomCardBase
      title="Registr OZO"
      value={''}
      definitionId={definitionId}
      icon={<FireplaceIcon />}
      navigate={noop}
    >
      <DashboardCardAction
        navigate={navigateManufacturers}
        title="Výrobci:"
        value={manufacturerCount}
      />
      <DashboardCardAction
        navigate={navigateSubjects}
        title="Subjekty:"
        value={subjectsCount}
      />
      <DashboardCardAction
        navigate={navigatePermissions}
        title="Oprávnění:"
        value={permissionCount}
      />
    </CustomCardBase>
  );
}

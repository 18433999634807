import React from 'react';
import { ProcessForm } from '../ovz-facility-request-operator-change-types';
import {
  Form,
  FormPanel,
  FormInlineTableField,
  FormTextArea,
  FormDateField,
  FormCustomField,
  TextField,
  FormTextField,
} from '@eas/common-web';
import { noop } from 'lodash';
import { OvzFacilityAutocomplete } from '../../../models';
import { SubjectAutocomplete } from '../../../models/subject';

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <SubjectAutocompleteFormPanel
        subject={allValues.oldOperator}
        label="Původní provozovatel"
      />
      <FormPanel label="Provozovny">
        <FormInlineTableField<OvzFacilityAutocomplete>
          label="Provozovny"
          name="ovzFacilities"
          disabled={true}
          showToolbar={false}
          columns={[
            {
              name: 'Název',
              datakey: 'label',
              width: 600,
            },
          ]}
        />
      </FormPanel>
      <FormPanel label="Nový provozovatel">
        <FormTextField name="newOperatorIdentifier" label="Identifikátor" />
      </FormPanel>
      <FormPanel label="Ostatní informace">
        <FormTextArea name="note" label="Poznámka" />
        <FormDateField name="date" label="Datum od kdy změna platí" />
      </FormPanel>
    </Form>
  );
}

function SubjectAutocompleteFormPanel({
  subject,
  label,
}: {
  subject?: SubjectAutocomplete;
  label: string;
}) {
  return (
    <FormPanel label={label}>
      <FormCustomField label="Název">
        <TextField value={subject?.label} disabled={true} />
      </FormCustomField>
    </FormPanel>
  );
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Empowerment request messages
 */
const APPROVE: MessageType<
  | 'SUCCESS'
  | 'SAME_EMPOWERER_AND_AGENT'
  | 'ERROR'
  | 'INCOMPATIBLE_RESOLUTION_TYPE_BASIC'
  | 'INCOMPATIBLE_RESOLUTION_TYPE_SPECIFICATIONS'
> = {
  SUCCESS: [
    'Žádost o zmocnění byla úspěšně schválena.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: ['Chyba při schvalování.', SnackbarVariant.ERROR],
  INCOMPATIBLE_RESOLUTION_TYPE_BASIC: [
    'Žádost o zmocnění s typem agendy Základní agenda nesmí obsahovat typ hlášení.',
    SnackbarVariant.ERROR,
  ],
  INCOMPATIBLE_RESOLUTION_TYPE_SPECIFICATIONS: [
    'Žádost o zmocnění s typem agendy Agenda se specifikacemi musí obsahovat alespoň jeden typ hlášení.',
    SnackbarVariant.ERROR,
  ],
  SAME_EMPOWERER_AND_AGENT: [
    'Chyba při schvalování: Zmocněnec a zmocnitel nesmí být stejný subjekt.',
    SnackbarVariant.ERROR,
  ],
};

const REJECT: MessageType = {
  SUCCESS: [
    'Žádost o zmocnění byla úspěšně zamítnuta.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: ['Chyba při zamítnutí.', SnackbarVariant.ERROR],
};

const SUBMIT_REQUEST: MessageType<
  | 'ERROR'
  | 'INVALID_ENTITY_VALIDITY_RANGE'
  | 'SUBJECT_ARTIFICIAL_ID_NOT_FOUND'
  | 'AGENT_SUBJECT_NOT_FOUND'
  | 'AGENT_WITH_ICO_NOT_FOUND'
  | 'NATURAL_PERSON_AGENT_NOT_FOUND'
  | 'NOT_OWN'
> = {
  ERROR: ['Chyba při odesílaní žádosti.', SnackbarVariant.ERROR],
  NOT_OWN: [
    'Chyba při odesílaní žádosti: Za subjekt který jste vyplnili v kroku 2 nemáte oprávnění vytvářet žádosti o zmocnění.',
    SnackbarVariant.ERROR,
  ],
  AGENT_WITH_ICO_NOT_FOUND: [
    'Chyba při odesílaní žádosti: Subjekt s IČO, který jste vyplnili v kroku 3 v CRŽP neexistuje. Upravte identifikaci subjektu a akci opakujte.',
    SnackbarVariant.ERROR,
  ],
  NATURAL_PERSON_AGENT_NOT_FOUND: [
    'Chyba při odesílaní žádosti: Subjekt s číslem dokladu, které jste vyplnili v kroku 3, v CRŽP neexistuje. Upravte identifikaci subjektu a akci opakujte.',
    SnackbarVariant.ERROR,
  ],
  AGENT_SUBJECT_NOT_FOUND: [
    'Chyba při odesílaní žádosti: Zmocněnec, kterého jste vyplnili v kroku 3 v CRŽP neexistuje. Upravte identifikaci subjektu a akci opakujte.',
    SnackbarVariant.ERROR,
  ],
  SUBJECT_ARTIFICIAL_ID_NOT_FOUND: [
    'Chyba při odesílaní žádosti: Subjekt s UID, které jste vyplnili v kroku 3 v CRŽP neexistuje. Upravte identifikaci subjektu a akci opakujte.',
    SnackbarVariant.ERROR,
  ],
  INVALID_ENTITY_VALIDITY_RANGE: [
    'Konec platnosti nesmí být před začátkem platnosti.',
    SnackbarVariant.ERROR,
  ],
};

const EDIT: MessageType = {
  SUCCESS: ['Žádost o zmocnění byla úspěšně upravena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při úpravě.', SnackbarVariant.ERROR],
};

export default { APPROVE, REJECT, SUBMIT_REQUEST, EDIT };

import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export function CaptchaWrapper({ children }: { children: React.ReactNode }) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Leo0wcaAAAAABgkULl3sbZ9M2z3du4t38tYIyAW"
      language="cs"
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}

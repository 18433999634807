import { StepMainProductionSiteForm } from './step-main-production-site/step-main-production-site-types';
import { StepMergingProductionSiteForm } from './step-merging-production-site/step-merging-production-site-types';
import { StepSummaryForm } from './step-summary/step-summary-types';
import { StepEditForm } from './step-edit/step-edit-types';

export type ProcessForm = StepMainProductionSiteForm &
  StepMergingProductionSiteForm &
  StepEditForm &
  StepSummaryForm;

export enum ProductionSiteMergeStateAction {
  INIT_MAIN_PS = 'INIT_MAIN_PS',
}

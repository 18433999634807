import { subjectTypesSource } from '../../subject/subjects-api';
import { SubjectType } from '../../../models';
import { useStaticListSource } from '@eas/common-web';

export function useOzoSubjectType() {
  const source = subjectTypesSource.filter((item) =>
    [SubjectType.NATURAL_PERSON, SubjectType.FOREIGN_NATURAL_PERSON].includes(
      item.id
    )
  );
  return useStaticListSource(source);
}

import { useContext, useRef } from 'react';
import {
  useEventCallback,
  SnackbarContext,
  DetailContext,
  UserContext,
  DetailHandle,
  AbortableFetch,
  PromptContext,
  usePrompts,
  deactivateItem,
} from '@eas/common-web';
import { Permission, Messages } from '../../../enums';
import { OvzFacility } from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { getErrorMessage } from '../../../utils/get-message';

const PROMPT_KEY = 'DEACTIVATE';

export function useDeactivateDialog() {
  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, onPersisted } = useContext<DetailHandle<OvzFacility>>(
    DetailContext
  );
  const { hasPermission } = useContext(UserContext);

  /**
   * Dialogs
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Zneplatnění provozovny',
      dialogText: 'Skutečně chcete zneplatnit provozovnu?',
    },
  ]);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleDeactivate = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        if (source.data) {
          try {
            source.setLoading(true);
            if (fetch.current !== null) {
              fetch.current.abort();
            }
            fetch.current = deactivateItem(source.url, source.data!.id);

            await fetch.current.raw();

            unstable_batchedUpdates(() => {
              showSnackbar(...Messages.OvzFacility.DEACTIVATE.SUCCESS);
              source.setLoading(false);
            });
            await source.refresh();
            onPersisted(source.data.id);
          } catch (err) {
            source.setLoading(false);
            if (err.name !== 'AbortError') {
              const message = getErrorMessage(
                Messages.OvzFacility.DEACTIVATE,
                err.code
              );

              showSnackbar(...message);
              throw err;
            }
            return undefined;
          }
        }
      },
    });
  });

  const canDeactivate =
    hasPermission(Permission.OvzFacility.OVZ_INVALIDATE_ALL) ||
    hasPermission(Permission.OvzFacility.OVZ_INVALIDATE_OWN, {
      subjectId: source.data?.operator?.id,
    });

  const showDeactivateButton = canDeactivate && source.data?.active;

  return {
    showDeactivateButton,
    handleDeactivate,
  };
}

import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'subject',
      label: 'Subjekt',
    },
    {
      key: 'boilerBrands',
      label: 'Značky kotlů',
    },
    {
      key: 'boilerModels',
      label: 'Modely kotlů',
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
  ];
}

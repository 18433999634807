import React, { useState, useContext, useRef } from 'react';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  FormContext,
  useEventCallback,
  useFormSelector,
  SnackbarContext,
  abortableFetch,
  AbortableFetch,
  FormHandle,
} from '@eas/common-web';
import Button from '@material-ui/core/Button';
import { IrzFacility } from '../../../models';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Messages, EvidenceAPI } from '../../../enums';
import { ProcessForm } from '../irz-facility-split-types';
import { StepMainFacilityForm } from './step-main-facility-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

function apiCall(id: string) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITIES}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export function StepMainFacilityActions({
  handleNext,
  handleExit,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Context stuff
   */
  const { setFieldValue } = useContext(FormContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const mainFacility = useFormSelector(
    ({ mainFacility }: StepMainFacilityForm) => mainFacility
  );

  /**
   * Form ref.
   */
  const formRef = useRef<FormHandle>(null);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleNextWithIrzLoad = useEventCallback(async () => {
    formRef.current?.validateForm();
    if (mainFacility) {
      try {
        setLoading(true);
        if (fetch.current !== null) {
          fetch.current.abort();
        }
        fetch.current = apiCall(mainFacility?.id);
        const irzFacilityFullData: IrzFacility = await fetch.current.json();
        setLoading(false);
        setFieldValue('mainFacility', irzFacilityFullData);
        handleNext();
      } catch (err) {
        if (err.name !== 'AbortError') {
          showSnackbar(
            ...Messages.IrzFacilityRequest.IRZ_FACILITY_PRELOAD.ERROR
          );
          throw err;
        }
        setLoading(false);
        return undefined;
      }
    } else {
      handleNext();
    }
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithIrzLoad}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Další
        </Button>
        <Button
          onClick={handleExit}
          className={classes.button}
          disableElevation
        >
          Zrušit
        </Button>
      </div>
    </div>
  );
}

import React, { useMemo } from 'react';
import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
  TableFieldColumn,
  TableFieldCells,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import {
  Subject,
  Empowerment,
  WaterUsagePlace,
  BoilerPermission,
  SubjectRole,
  IrzFacility,
  OvzFacility,
} from '../../models';
import { useSubjectTypes } from './subjects-api';
// import { idValueMapperFactory } from '../../composite/subject-column-mappers/id-mapper';
import { addressValueMapperFactory } from '../../composite/subject-column-mappers/address-mapper';
import { redirectToDetailCellFactory } from '../../composite/subject-cells/redirect-to-detail-cell';
import { EvidenceUrl } from '../../enums';
// import { anyIdentificatorCellFactory } from '../../composite/subject-cells/any-identificator-cell';
import { AllSpecificationsCell } from '../../composite/subject-cells/all-specifications-cell';
import { AddressCell } from '../../composite/subject-cells/address-cell';
import { useTypes } from '../water-usage-place/water-usage-places-api';
import { useSubjectRoles, useRoleRelationships } from '../roles/roles-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { subjectRolesValueMapper } from '../../composite/subject-column-mappers/subject-roles-mapper';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { SubjectUserDto, SubjectAgendaDto } from './subjects-types';

function DisabledSelect({ value }: { value: string | null }) {
  return <Typography variant="body1">{value}</Typography>;
}

export function useColumns(canSeeDeactivated: boolean): TableColumn<Subject>[] {
  const columns: TableColumn<Subject>[] = [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ subjektu',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useSubjectTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useSubjectTypes),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'roles',
      sortkey: 'roles.name',
      filterkey: 'roles.id',
      name: 'Subjektové role',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSubjectRoles,
        dictionaryLabelMapper
      ),
      valueMapper: subjectRolesValueMapper,
      sortable: false,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'identifier',
      name: 'IČO / UID',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'identityDocumentsConcatenated',
      name: 'OP / pas',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'foreignId',
      name: 'Zahr. identifikátor (obdoba IČO)',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'residence.municipality',
      sortkey: 'residence.municipality.name',
      filterkey: 'residence.municipality.name',
      name: 'Obec',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.cityDistrict',
      sortkey: 'residence.cityDistrict.name',
      filterkey: 'residence.cityDistrict.name',
      name: 'Městská část',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.street',
      sortkey: 'residence.street.name',
      filterkey: 'residence.street.name',
      name: 'Ulice',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.houseNumber',
      name: 'Číslo popisné',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.orientationNumberWithMark',
      name: 'Číslo orientační',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'residence.zip',
      name: 'PSČ',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'residence.addressPlaceRuian.isuiCode',
      sortkey: 'residence.addressPlace.isuiCode',
      filterkey: 'residence.addressPlace.isuiCode',
      name: 'Adresní kód',
      width: 200,
      FilterComponent: TableFilterCells.FilterNumberCell,
      CellComponent: inactiveColoredFactory(TableCells.NumberCell),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'allowIszrSync',
      name: 'Automatická synchronizace se ZR',
      width: 200,
      FilterComponent: TableFilterCells.FilterBooleanCell,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'lastIszrSync',
      name: 'Datum poslední synchronizace',
      width: 200,
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'isdsIdsConcatenated',
      name: 'ISDS',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'sic',
      name: 'SIČ',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'author.username',
      name: 'Autor',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 10,
    },
    {
      datakey: 'inCrzpSince',
      name: 'Datum registrace',
      width: 200,
      FilterComponent: TableFilterCells.useFilterLocalDateCellFactory(
        'local-date-time'
      ),
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];

  if (canSeeDeactivated) {
    columns.push({
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    });
  }

  return columns;
}

export function useAgendaColumns(): TableFieldColumn<SubjectAgendaDto>[] {
  return [
    {
      name: 'Název',
      datakey: 'agenda.name',
      width: 500,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Vazba k subjektu od',
      datakey: 'validFrom',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Vazba k subjektu do',
      datakey: 'validTo',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}

export function useUserColumns(
  showRedirect: boolean
): TableFieldColumn<SubjectUserDto>[] {
  let columns: TableFieldColumn<SubjectUserDto>[] = [
    {
      name: 'Uživatelské jméno',
      datakey: 'user.username',
      sortkey: 'user.username',
      width: 200,
    },
    {
      name: 'Jméno',
      datakey: 'user.name',
      sortkey: 'user.name',
      width: 200,
      CellComponent: function Name(props) {
        return (
          <TableFieldCells.TextCell
            {...props}
            value={`${props.rowValue?.user?.firstName} ${props.rowValue?.user?.lastName}`}
          />
        );
      },
    },
    {
      name: 'Email',
      datakey: 'user.email',
      sortkey: 'user.email',
      width: 200,
    },
    {
      name: 'Vztah',
      datakey: 'relationship',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useRoleRelationships,
        DisabledSelect
      ),
    },
    {
      name: 'Aktivní',
      datakey: '',
      width: 100,
      CellComponent: function Active(props) {
        const hasActiveRole = useMemo(() => {
          return !!props.rowValue.relationshipRoleInstances?.find(
            (roleInstance) => roleInstance.active
          );
        }, [props.rowValue]);

        return (
          <TableFieldCells.TextCell
            {...props}
            value={hasActiveRole ? 'Ano' : 'Ne'}
          />
        );
      },
    },
  ];

  if (showRedirect) {
    columns = [
      {
        name: 'Odkaz',
        datakey: 'id',
        width: 60,
        CellComponent: redirectToDetailCellFactory(
          EvidenceUrl.USERS,
          'user.id'
        ),
      },
      ...columns,
    ];
  }

  return columns;
}

export function useSubjectRoleColumns(): TableFieldColumn<SubjectRole>[] {
  return [
    {
      name: 'Název',
      datakey: 'name',
      width: 500,
    },
    {
      name: 'Systém',
      datakey: 'registeredFor.name',
      width: 300,
    },
  ];
}

export function useIrzFacilitiesColumns(): TableFieldColumn<IrzFacility>[] {
  return [
    {
      name: 'Odkaz',
      datakey: 'id',
      width: 60,
      CellComponent: redirectToDetailCellFactory(
        EvidenceUrl.IRZ_FACILITIES,
        'irzFacility.id'
      ),
    },
    {
      name: 'Název',
      datakey: 'irzFacility.name',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'IČP',
      datakey: 'irzFacility.facilityId',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Vazba k subjektu od',
      datakey: 'validFrom',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Vazba k subjektu do',
      datakey: 'validTo',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}

export function useOvzFacilitiesColumns(): TableFieldColumn<OvzFacility>[] {
  return [
    {
      name: 'Odkaz',
      datakey: 'id',
      width: 60,
      CellComponent: redirectToDetailCellFactory(
        EvidenceUrl.OVZ_FACILITIES,
        'ovzFacility.id'
      ),
    },
    {
      name: 'Název',
      datakey: 'ovzFacility.name',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'IČP',
      datakey: 'ovzFacility.facilityId',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Vazba k subjektu od',
      datakey: 'validFrom',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Vazba k subjektu do',
      datakey: 'validTo',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}

export function useEmpowermentsAsEmpowererColumns(): TableFieldColumn<
  Empowerment
>[] {
  return [
    {
      name: 'Odkaz',
      datakey: 'id',
      width: 60,
      CellComponent: redirectToDetailCellFactory(EvidenceUrl.EMPOWERMENTS),
    },
    {
      name: 'Zmocněnec',
      datakey: 'agent.name',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Zmocněnec IČO / UID',
      datakey: 'agent.identifier',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Agenda',
      datakey: 'agenda.name',
      width: 300,
    },
    {
      name: 'Platnost od',
      datakey: 'validFrom',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Platnost do',
      datakey: 'validTo',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Typ hlášení',
      datakey: 'specifications',
      width: 200,
      CellComponent: AllSpecificationsCell,
    },
  ];
}

export function useEmpowermentsAsAgentColumns(): TableFieldColumn<
  Empowerment
>[] {
  return [
    {
      name: 'Odkaz',
      datakey: 'id',
      width: 60,
      CellComponent: redirectToDetailCellFactory(EvidenceUrl.EMPOWERMENTS),
    },
    {
      name: 'Zmocnitel',
      datakey: 'empowerer.name',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Zmocnitel IČO / UID',
      datakey: 'empowerer.identifier',
      width: 300,
    },
    {
      name: 'Agenda',
      datakey: 'agenda.name',
      width: 300,
    },
    {
      name: 'Platnost od',
      datakey: 'validFrom',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Platnost do',
      datakey: 'validTo',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Typ hlášení',
      datakey: 'specifications',
      width: 200,
      CellComponent: AllSpecificationsCell,
    },
  ];
}

export function useWaterUsagePlacesColumns(): TableFieldColumn<
  WaterUsagePlace
>[] {
  return [
    {
      name: 'Odkaz',
      datakey: 'id',
      width: 60,
      CellComponent: redirectToDetailCellFactory(
        EvidenceUrl.WATER_USAGE_PLACES,
        'waterUsagePlace.id'
      ),
    },
    {
      datakey: 'waterUsagePlace.type',
      name: 'Typ',
      width: 300,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useTypes,
        DisabledSelect
      ),
    },
    {
      datakey: 'waterUsagePlace.name',
      name: 'Název',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Vazba k subjektu od',
      datakey: 'validFrom',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Vazba k subjektu do',
      datakey: 'validTo',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    // {
    //   datakey: 'vhbNumber',
    //   name: 'Číslo VHB',
    //   width: 200,
    //   CellComponent: TableFieldCells.TextCell,
    // },
  ];
}

export function useBoilerPermissionsOZOColumns(): TableFieldColumn<
  BoilerPermission
>[] {
  return [
    {
      name: 'Odkaz',
      datakey: 'id',
      width: 60,
      CellComponent: redirectToDetailCellFactory(
        EvidenceUrl.BOILER_PERMISSIONS
      ),
    },
    {
      datakey: 'number',
      name: 'Číslo oprávnění',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Výrobce - IČO / UID',
      datakey: 'manufacturerSubject.identifier',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'manufacturerSubject.name',
      name: 'Výrobce',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'boilerBrand.name',
      name: 'Značka kotle',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}

export function useBoilerPermissionsManufacturerColumns(): TableFieldColumn<
  BoilerPermission
>[] {
  return [
    {
      name: 'Odkaz',
      datakey: 'id',
      width: 60,
      CellComponent: redirectToDetailCellFactory(
        EvidenceUrl.BOILER_PERMISSIONS
      ),
    },
    {
      datakey: 'number',
      name: 'Číslo oprávnění',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'ozoIco.value',
      name: 'IČO OZO',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'ozoSubject.name',
      name: 'Subjekt OZO',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'address',
      name: 'Adresa podnikání',
      width: 300,
      CellComponent: AddressCell,
    },
    {
      datakey: 'boilerBrand.name',
      name: 'Značka kotle',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}

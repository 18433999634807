import React from 'react';
import {
  useFormSelector,
  FormPanel,
  FormTextField,
  FormSelect,
  FormEditor,
  FormDateTimeField,
  FormCheckbox,
} from '@eas/common-web';
import { ScheduleJob } from '../../../models';
import { useScriptTypes } from '../schedule-api';
import { ScriptType } from '../../../models/schedule-types';
import { HelpLabel } from '../../../components/help/help-label';

export function ScheduleJobsFields() {
  const scriptType = useFormSelector((data: ScheduleJob) => data.scriptType);

  const types = useScriptTypes();

  return (
    <FormPanel label="Úloha">
      <FormTextField
        name="timer"
        label={<HelpLabel label="Časovač" code="SCHEDULE_JOB_TIMER" />}
      />
      <FormCheckbox
        name="running"
        label={<HelpLabel label="Běží" code="SCHEDULE_JOB_RUNNING" />}
        disabled={true}
      />
      <FormDateTimeField
        name="last"
        label={<HelpLabel label="Poslední spuštění" code="SCHEDULE_JOB_LAST" />}
        disabled={true}
      />
      <FormDateTimeField
        name="next"
        label={<HelpLabel label="Příští spuštění" code="SCHEDULE_JOB_NEXT" />}
        disabled={true}
      />
      <FormTextField
        name="description"
        label={<HelpLabel label="Popis" code="SCHEDULE_JOB_DESCRIPTION" />}
      />
      <FormSelect
        name="scriptType"
        required
        label={
          <HelpLabel
            label="Skriptovací jazyk"
            code="SCHEDULE_JOB_SCRIPT_TYPE"
            required
          />
        }
        source={types}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
      />
      <FormEditor
        name="script"
        required
        disabled={scriptType == null}
        language={scriptType === ScriptType.GROOVY ? 'java' : 'javascript'}
        label={<HelpLabel label="Skript" code="SCHEDULE_JOB_SCRIPT" required />}
      />
      <FormCheckbox
        name="useTransaction"
        label={
          <HelpLabel
            label="Spustit skript v transakci"
            code="SCHEDULE_JOB_USE_TRANSACTION"
          />
        }
      />
    </FormPanel>
  );
}

import React, { useMemo } from 'react';
import { noop } from 'lodash';
import {
  FormPanel,
  FormAutocomplete,
  Form,
  FormTextField,
  FormCustomField,
  FormTextArea,
  FormDateField,
} from '@eas/common-web';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { CompareIrzRequestFields } from '../../../composite/compare-irz-request/compare-irz-request-fields';
import { ProcessForm } from '../irz-facility-merge-types';
import { useIrzFacilities } from '../../irz-facility/irz-facilities-api';
import { isIrzEqual } from '../../../composite/compare-irz-request/compare-irz-request-diff';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';

const useStyles = makeStyles((theme) => ({
  warning: {
    lineHeight: 'inherit',
    padding: '0 2pt',
    color: theme.palette.error.main,
  },
}));

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const classes = useStyles();

  const irzFacilities = useIrzFacilities();

  const isAnyValueUpdated = useMemo(() => {
    return !isIrzEqual(allValues?.mainFacility, allValues?.editData);
  }, [allValues?.mainFacility, allValues?.editData]);

  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Provozovna ke sloučení">
        <FormAutocomplete
          name="mergingFacility"
          label="Provozovna ke sloučení"
          helpLabel="Provozovna, která bude s výše vybranou provozovnou sloučena"
          source={irzFacilities}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormTextField name="mergingFacility.facilityId" label="IČP" />
      </FormPanel>
      <FormPanel label="Cílová provozovna">
        <FormAutocomplete
          name="mainFacility"
          label="Cílová provozovna"
          helpLabel="Provozovna, se kterou bude dále zvolená provozovna sloučena"
          source={irzFacilities}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormTextField name="mainFacility.facilityId" label="IČP" />
      </FormPanel>
      <FormPanel label="Změnené údaje">
        {isAnyValueUpdated ? (
          <CompareIrzRequestFields
            originalData={allValues?.mainFacility}
            requestData={allValues?.editData}
          />
        ) : (
          <FormCustomField>
            <Typography variant="body1" className={classes.warning}>
              Beze změny
            </Typography>
          </FormCustomField>
        )}
      </FormPanel>
      <FormPanel label="Ostatní informace">
        <FormDateField name="date" label="Datum sloučení" />
        <FormTextArea name="note" label="Poznámka" />
      </FormPanel>
    </Form>
  );
}

import React from 'react';
import {
  FormAutocomplete,
  FormTextArea,
  useFormSelector,
  FormTextField,
  FormPanel,
  FormCustomField,
  FormCheckbox,
  FormDateField,
  TextArea,
} from '@eas/common-web';
import { useDictionaryBoilerModels } from '../dict-boiler-model/dict-boiler-models-api';
import { useDictionaryBoilerTypes } from '../dict-boiler-type/dict-boiler-types-api';
import { DictionaryBoilerBrand } from '../../models';
import { EvidenceUrl } from '../../enums';
import { DetailNavigationButton } from '../../components/detail-navigation-button/detail-navigation-button';
import { HistoryEvidenceFieldsProps } from '../../components/evidence/history-evidence/history-types';

export function DictionaryBoilerBrandsFields(
  props: HistoryEvidenceFieldsProps
) {
  const boilerModels = useDictionaryBoilerModels();
  const boilerTypes = useDictionaryBoilerTypes();
  const { manufacturer } = useFormSelector((data: DictionaryBoilerBrand) => ({
    manufacturer: data.manufacturer,
  }));

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField name="name" label="Název" />
        <FormAutocomplete
          name="models"
          label="Modely kotlů (Agregované)"
          source={boilerModels}
          disabled
          multiple
        />
        <FormAutocomplete
          name="allowedTypes"
          label="Typy kotlů (Agregované)"
          source={boilerTypes}
          disabled
          multiple
          DisabledComponent={TextArea}
        />
        <FormCheckbox name="active" label="Aktivní" />
        <FormDateField
          name="validFrom"
          label="Platný od"
          representation="instant"
        />
        <FormDateField
          name="validTo"
          label="Platný do"
          representation="instant"
        />
        <FormTextArea name="description" label="Popis" />
      </FormPanel>
      <FormPanel label="Výrobce">
        <FormTextField name="manufacturer.subject.name" label="Název" />
        <FormCustomField>
          <DetailNavigationButton
            url={EvidenceUrl.BOILER_MANUFACTURERS}
            id={manufacturer?.id}
          />
        </FormCustomField>
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

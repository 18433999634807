import React, { useContext } from 'react';
import {
  CrudSource,
  SnackbarContext,
  useEventCallback,
  usePrompts,
  FormTextArea,
  PromptContext,
  abortableFetch,
} from '@eas/common-web';
import { User } from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { Messages, EvidenceAPI } from '../../../enums';

const DEACTIVATE_KEY = 'DEACTIVATE_USER';
const ACTIVATE_KEY = 'ACTIVATE_USER';

function callDeactivate(id: string, values: { note: string }) {
  return abortableFetch(`${EvidenceAPI.USERS}/${id}/active`, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(values),
  });
}

function callActivate(id: string, values: { note: string }) {
  return abortableFetch(`${EvidenceAPI.USERS}/${id}/active`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(values),
  });
}

export function useDeactivateUser({
  source,
  onPersisted,
}: {
  source: CrudSource<User>;
  onPersisted: (id: string | null) => void;
}) {
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);

  usePrompts(
    [
      {
        key: DEACTIVATE_KEY,
        dialogTitle: 'Zneplatnit uživatele',
        dialogText: 'Skutečně chcete zneplatnit uživatele?',
        FormFields: function Body() {
          return (
            <>
              <FormTextArea name="note" label="Poznámka" />
            </>
          );
        },
        dialogWidth: 600,
      },
      {
        key: ACTIVATE_KEY,
        dialogTitle: 'Obnovit uživatele',
        dialogText: 'Skutečně chcete obnovit uživatele?',
        FormFields: function Body() {
          return (
            <>
              <FormTextArea name="note" label="Poznámka" />
            </>
          );
        },
        dialogWidth: 600,
      },
    ],
    []
  );

  const handleDeactivate = useEventCallback(() => {
    testPrompt({
      key: DEACTIVATE_KEY,
      submitCallback: async (values: { note: string }) => {
        try {
          source.setLoading(true);

          const fetch = callDeactivate(source.data!.id, values);

          await fetch.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.User.DEACTIVATE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          showSnackbar(...Messages.User.DEACTIVATE.ERROR);

          throw err;
        }
      },
    });
  });

  const handleActivate = useEventCallback(() => {
    testPrompt({
      key: ACTIVATE_KEY,
      submitCallback: async (values: { note: string }) => {
        try {
          source.setLoading(true);

          const fetch = callActivate(source.data!.id, values);

          await fetch.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.User.ACTIVATE.SUCCESS);
            source.setLoading(false);
          });

          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          showSnackbar(...Messages.User.ACTIVATE.ERROR);

          throw err;
        }
      },
    });
  });

  return { handleDeactivate, handleActivate };
}

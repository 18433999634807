import React, { useMemo } from 'react';
import { FormCustomField } from '@eas/common-web';
import { Wgs84Coordinates } from '../../models';
import { Map } from '../../components/map/map';
import { MarkerLayer } from '../../components/map/marker-layer';
import { Marker } from '../../components/map/marker';
import { GeometryLayer } from '../../components/map/geometry-layer';
import { Line } from '../../components/map/line';
import { MarkerType } from '../../components/map/map-types';

export function CompareLocation({
  newLocation,
  originalLocation,
}: {
  originalLocation?: Wgs84Coordinates;
  newLocation?: Wgs84Coordinates;
}) {
  const defaultMapPosition = useMemo(() => {
    if (newLocation && originalLocation) {
      return [newLocation, originalLocation];
    }
    if (newLocation) {
      return newLocation;
    }
    return undefined;
  }, [newLocation, originalLocation]);

  const isChanged = useMemo(() => {
    return (
      newLocation?.lat !== originalLocation?.lat ||
      newLocation?.lon !== originalLocation?.lon
    );
  }, [newLocation, originalLocation]);

  if (!isChanged) {
    return null;
  } else {
    return (
      <FormCustomField label="Poloha">
        <Map height={250} defaultWgs84Position={defaultMapPosition}>
          <GeometryLayer>
            <MarkerLayer>
              <Line from={newLocation} to={originalLocation} />
              <Marker
                type={MarkerType.CUSTOM_GREY}
                innerTitle="Původ."
                lat={originalLocation?.lat ?? ''}
                lng={originalLocation?.lon ?? ''}
              />

              <Marker
                type={MarkerType.CUSTOM_RED}
                innerTitle="Nové"
                lat={newLocation?.lat ?? ''}
                lng={newLocation?.lon ?? ''}
              />
            </MarkerLayer>
          </GeometryLayer>
        </Map>
      </FormCustomField>
    );
  }
}

import React from 'react';
import {
  FormPanel,
  FormTextField,
  FormAutocomplete,
  FormDateField,
  FormTextArea,
  FormInlineTableField,
  useFormSelector,
  FormContext,
  FormCustomField,
  useEventCallback,
  FormCheckbox,
  FormCheckboxGroup,
  UserContext,
  eqFilterParams,
} from '@eas/common-web';
import {
  MainActivityType,
  ActivityRecord,
  IrzFacilityRequest,
} from '../../../models';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { SubjectFormPanel } from '../../../composite/subject-fields/subject-form-panel';
import { useContext } from 'react';
import { useNaceItems } from '../../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import { useCzRiverBasins } from '../../dict-river-basin-cz-district/dict-river-basin-cz-district-api';
import { useEuRiverBasins } from '../../dict-river-basin-eu-district/dict-river-basin-eu-district-api';
import { useIrzActivities } from '../../dict-irz-activity/dict-irz-activities-api';
import {
  useMainActivities,
  useEPrtrAndIppcActivities,
} from '../../irz-facility/irz-facilities-api';
import {
  useSecondaryIrzActivityColumns,
  useSecondaryEPrtrActivityColumns,
  useTradesInEmissionsColumns,
  useCompetentControlAuthoritiesColumns,
} from '../../irz-facility/irz-facilities-columns';
import { useIppcPidColumns } from '../irz-facility-requests-columns';
import {
  dictionaryLabelMapper,
  dictionaryNoCodeLabelMapper,
} from '../../../components/form/mappers/label-mappers';

import { EvidenceUrl, Permission } from '../../../enums';
import { DetailNavigationButton } from '../../../components/detail-navigation-button/detail-navigation-button';
import { HelpLabel } from '../../../components/help/help-label';
import { useOvzFacilities } from '../../ovz-facility/ovz-facilities-api';

export function RegistrationFields() {
  const naceItems = useNaceItems();
  const naceCzItems = useNaceCzItems();
  const types = useMainActivities();
  const czRiverBasins = useCzRiverBasins();
  const euRiverBasins = useEuRiverBasins();
  const irzActivities = useIrzActivities();
  const { ePrtrActivities, ippcActivities } = useEPrtrAndIppcActivities(
    'requestFacilityData'
  );

  const { setFieldValue } = useContext(FormContext);
  const { hasPermission } = useContext(UserContext);

  const {
    activityTypes,
    secondaryEprtrActivities,
    secondaryIrzActivities,
    wgs84Coordinates,
    irzFacility,
    tradesInEmissions,
    address,
    operator,
    hasIntegratedPermission,
  } = useFormSelector((data: IrzFacilityRequest) => ({
    activityTypes: data.requestFacilityData?.activityTypes,
    secondaryIrzActivities: data.requestFacilityData?.secondaryIrzActivities,
    secondaryEprtrActivities:
      data.requestFacilityData?.secondaryEprtrActivities,
    wgs84Coordinates: data.requestFacilityData?.wgs84Coordinates,
    irzFacility: data.irzFacility,
    tradesInEmissions:
      data?.requestFacilityData?.emissionsTradingSystem?.tradesInEmissions,
    address: data?.requestFacilityData?.address,
    operator: data?.requestFacilityData?.operator,
    hasIntegratedPermission:
      data?.requestFacilityData?.integratedPermission?.hasPermission,
  }));

  const ovzFacilities = useOvzFacilities(
    eqFilterParams({ field: 'operator.id', value: operator?.id })
  );

  const secondaryIrzActivitiesColumns = useSecondaryIrzActivityColumns(
    'requestFacilityData.secondaryIrzActivities'
  );
  const secondaryEPrtrActivitiesColumns = useSecondaryEPrtrActivityColumns(
    'requestFacilityData.secondaryEprtrActivities'
  );

  const ippcPidColumns = useIppcPidColumns(
    'requestFacilityData.integratedPermission.ippcPids'
  );
  const emmissionColumns = useTradesInEmissionsColumns(
    'requestFacilityData.emissionsTradingSystem.ids'
  );

  const handleMainActivityTypeChanged = useEventCallback(() => {
    if (activityTypes?.includes(MainActivityType.E_PRTR)) {
      setFieldValue('requestFacilityData.mainIrzActivity', undefined);
    }
    if (!activityTypes?.includes(MainActivityType.IRZ)) {
      setFieldValue('requestFacilityData.mainIrzActivity', undefined);
      setFieldValue('requestFacilityData.secondaryIrzActivities', undefined);
    }
    if (!activityTypes?.includes(MainActivityType.E_PRTR)) {
      setFieldValue('requestFacilityData.mainEprtrActivity', undefined);
      setFieldValue('requestFacilityData.secondaryEprtrActivities', undefined);
    }
  });

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="requestFacilityData.name"
          label={
            <HelpLabel
              label="Název provozovny"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_NAME"
            />
          }
        />
        <FormTextField
          name="requestFacilityData.webSite"
          label={
            <HelpLabel
              label="Web stránka"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_WEB_SITE"
            />
          }
        />
        <FormTextField
          name="requestFacilityData.publicEmail"
          label={
            <HelpLabel
              label="Veřejný e-mail"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_PUBLIC_EMAIL"
            />
          }
        />
        <FormTextField
          name="requestFacilityData.publicPhoneNumber"
          label={
            <HelpLabel
              label="Veřejný telefon"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_PUBLIC_PHONE_NUMBER"
            />
          }
        />
        <FormTextArea
          name="requestFacilityData.note"
          label={
            <HelpLabel
              label="Poznámky provozovny"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_NOTE"
            />
          }
        />
        <FormDateField
          name="requestFacilityData.commissioningDate"
          label={
            <HelpLabel
              label="Datum uvedení do provozu"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_COMMISSIONING_DATE"
            />
          }
        />
      </FormPanel>
      {irzFacility && irzFacility?.id && (
        <FormPanel label="Provozovna">
          <FormTextField
            name="irzFacility.name"
            label={
              <HelpLabel
                label="Název"
                code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_NAME"
              />
            }
          />
          <FormTextField
            name="irzFacility.facilityId"
            label={
              <HelpLabel
                label="IČP"
                code="IRZ_FACILITY_REQUESTS_IRZ_FACILITY_FACILITY_ID"
              />
            }
          />
          <FormCustomField>
            <DetailNavigationButton
              url={EvidenceUrl.IRZ_FACILITIES}
              id={irzFacility?.id}
            />
          </FormCustomField>
        </FormPanel>
      )}
      <SubjectFormPanel
        label="Provozovatel"
        codePrefix="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA"
        autocomplete={{
          name: 'requestFacilityData.operator',
          label: 'Název provozovatele',
        }}
        addressLabel="Adresa sídla"
      />
      <LocationPanel
        locationName="requestFacilityData.wgs84Coordinates"
        locationValue={wgs84Coordinates}
        addressName="requestFacilityData.address"
        address={address}
        expandable
        defaultExpanded={false}
        locationPanelLabel="Poloha provozovny"
        addressPanelLabel="Adresa provozovny"
        showEtrs89Coordinates={hasPermission(
          Permission.IrzFacility.IRZ_REQUEST_LIST_ALL
        )}
        etrs89CoordinatesPath="requestFacilityData.etrs89Coordinates"
        prefillCoordinates={false}
      />
      <FormPanel label="Klasifikace, Identifikátory">
        <FormAutocomplete
          name="requestFacilityData.czNace"
          label={
            <HelpLabel
              label="CZ-NACE"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_CZ_NACE"
            />
          }
          source={naceCzItems}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          name="requestFacilityData.nace"
          label={
            <HelpLabel
              label="NACE"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_NACE"
            />
          }
          source={naceItems}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
        <FormAutocomplete
          name="requestFacilityData.czRiverBasinDistrict"
          label={
            <HelpLabel
              label="Oblast povodí ČR"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_CZ_RIVER_BASIN_DISTRICT"
            />
          }
          source={czRiverBasins}
          tooltipMapper={dictionaryNoCodeLabelMapper}
          labelMapper={dictionaryNoCodeLabelMapper}
        />
        <FormAutocomplete
          name="requestFacilityData.euRiverBasinDistrict"
          label={
            <HelpLabel
              label="Oblast povodí Evropy"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_EU_RIVER_BASIN_DISTRICT"
            />
          }
          source={euRiverBasins}
          tooltipMapper={dictionaryNoCodeLabelMapper}
          labelMapper={dictionaryNoCodeLabelMapper}
        />
        <FormAutocomplete
          name="ovzFacility"
          label={
            <HelpLabel
              label="IČP provozovny ovzduší"
              code="IRZ_FACILITY_REQUEST_OVZ_FACILITY"
            />
          }
          source={ovzFacilities}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
        <FormCheckbox
          name="requestFacilityData.integratedPermission.hasPermission"
          label={
            <HelpLabel
              label="Provozovna/Zařízení má Integrované povolení"
              code="IRZ_FACILITY_REQUEST_INTEGRATED_PERMISSION_HAS_PERMISSION"
            />
          }
        />
        {hasIntegratedPermission && (
          <FormInlineTableField<string>
            name="requestFacilityData.integratedPermission.ippcPids"
            label={
              <HelpLabel
                label="Seznam PID (IPPC)"
                code="IRZ_FACILITY_REQUEST_FACILITY_DATA_IPPC_PIDS"
              />
            }
            columns={ippcPidColumns}
            initNewItem={() => ''}
          />
        )}
        <FormCheckbox
          name="requestFacilityData.emissionsTradingSystem.tradesInEmissions"
          label={
            <HelpLabel
              label="Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_EMISSIONS_TRADING_SYSTEM_TRADES_IN_EMISSIONS"
            />
          }
        />
        {tradesInEmissions === true && (
          <FormInlineTableField<string>
            name="requestFacilityData.emissionsTradingSystem.ids"
            label={
              <HelpLabel
                label="ID zařízení (EU ETS)"
                code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_EMISSIONS_TRADING_SYSTEM_IDS"
              />
            } // Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9
            columns={emmissionColumns}
            initNewItem={() => ''}
          />
        )}
      </FormPanel>
      <FormPanel label="Činnosti">
        <FormCheckboxGroup
          label={
            <HelpLabel
              label="Typ činnosti"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_ACTIVITY_TYPES"
            />
          }
          source={types}
          name="requestFacilityData.activityTypes"
          labelMapper={dictionaryLabelMapper}
          notifyChange={handleMainActivityTypeChanged}
        />
        {activityTypes?.includes(MainActivityType.E_PRTR) && (
          <>
            <FormAutocomplete
              name="requestFacilityData.mainEprtrActivity.activity"
              label={
                <HelpLabel
                  label="Hlavní E-PRTR činnost"
                  code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_MAIN_EPRTR_ACTIVITY_ACTIVITY"
                />
              }
              source={ePrtrActivities}
              tooltipMapper={dictionaryLabelMapper}
              labelMapper={dictionaryLabelMapper}
            />

            <FormTextField
              name="requestFacilityData.mainEprtrActivity.facilityCount"
              label={
                <HelpLabel
                  label="Počet zařízení hlavní E-PRTR činnosti"
                  code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_MAIN_EPRTR_ACTIVITY_FACILITY_COUNT"
                />
              }
            />
          </>
        )}
        {!!secondaryEprtrActivities?.length && (
          <FormInlineTableField<ActivityRecord>
            name="requestFacilityData.secondaryEprtrActivities"
            label={
              <HelpLabel
                label="Seznam vedlejších E-PRTR činností"
                code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_SECONDARY_EPRTR_ACTIVITIES"
              />
            }
            columns={secondaryEPrtrActivitiesColumns}
            maxRows={5}
          />
        )}
        {activityTypes?.includes(MainActivityType.IRZ) && (
          <>
            {!activityTypes?.includes(MainActivityType.E_PRTR) && (
              <>
                <FormAutocomplete
                  name="requestFacilityData.mainIrzActivity.activity"
                  label={
                    <HelpLabel
                      label="Hlavní činnost dle přílohy zákona o IRZ a ISPOP"
                      code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_MAIN_IRZ_ACTIVITY_ACTIVITY"
                    />
                  }
                  source={irzActivities}
                  tooltipMapper={dictionaryLabelMapper}
                  labelMapper={dictionaryLabelMapper}
                />
                <FormTextField
                  name="requestFacilityData.mainIrzActivity.facilityCount"
                  label={
                    <HelpLabel
                      label="Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP"
                      code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_MAIN_IRZ_ACTIVITY_FACILITY_COUNT"
                    />
                  }
                />
              </>
            )}
          </>
        )}
        {!!secondaryIrzActivities?.length && (
          <FormInlineTableField<ActivityRecord>
            name="requestFacilityData.secondaryIrzActivities"
            label={
              <HelpLabel
                label="Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP"
                code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_SECONDARY_IRZ_ACTIVITIES"
              />
            }
            columns={secondaryIrzActivitiesColumns}
            maxRows={5}
          />
        )}

        <FormAutocomplete
          name="requestFacilityData.ippcActivity"
          label={
            <HelpLabel
              label="Činnost IPPC"
              code="IRZ_FACILITY_REQUESTS_REQUEST_FACILITY_DATA_IPPC_ACTIVITY"
            />
          }
          source={ippcActivities}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
      </FormPanel>
      <FormPanel label="Kompetentní kontrolní a ostatní orgány">
        <FormInlineTableField
          name="requestFacilityData.competentControlAuthorities"
          labelOptions={{
            hide: true,
          }}
          layoutOptions={{ noSpacing: true }}
          withRemove={false}
          maxRows={3}
          columns={useCompetentControlAuthoritiesColumns(
            'requestFacilityData.competentControlAuthorities'
          )}
        />
      </FormPanel>
    </>
  );
}

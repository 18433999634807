/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const SUBMIT_REQUEST: MessageType<'ROLE_INSTANCE_ALREADY_EXISTS' | 'SUCCESS' | 'ERROR' > = {
  SUCCESS: ['Žádost o roli byla úspěšně vytvořena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při vytváření žádosti o roli.', SnackbarVariant.ERROR],
  ROLE_INSTANCE_ALREADY_EXISTS: ['Role, o kterou žádáte, již byla přiřazena.', SnackbarVariant.ERROR],
};

export default { SUBMIT_REQUEST };

import * as Yup from 'yup';
import {
  OvzFacility,
  DictionaryTerritorialTechnicalUnit,
  Subject,
} from '../../models';
import {
  addressFieldSchema,
  wgs84CoordinatesSchema,
} from '../../components/form/address-field/address-field-schema';

export function useValidationSchema() {
  return Yup.object<OvzFacility>().shape({
    name: validateOvzFacilityName,
    territorialTechnicalUnit: Yup.mixed<DictionaryTerritorialTechnicalUnit>()
      .nullable()
      .required('Povinné pole'),
    operator: Yup.mixed<Subject>().nullable().required('Povinné pole'),
    integratedPermission: Yup.object().shape({
      ippcPids: Yup.array()
        .of<string>(
          Yup.mixed<string>().test('', '', function (val) {
            const { createError, parent } = this;
            const isInvalid = !val || !/^MZP[A-Z0-9]{9}$/.test(val);

            if (isInvalid) {
              return createError({
                path: 'integratedPermission.ippcPids',
                message: `Musí začínat písmeny MZP a následovat 9 velkých alfanumerických znaků`,
              });
            }

            const duplicates = (parent as string[]).filter(
              (item, index) => parent.indexOf(item) !== index
            );

            if (duplicates.length > 0) {
              return createError({
                path: 'integratedPermission.ippcPids',
                message: `Každý PID je možné vyplnit pouze jednou ${JSON.stringify(
                  duplicates
                )}`,
              });
            }

            return true;
          })
        )
        .test('', '', function (val) {
          const { createError, parent } = this;
          const { hasPermission } = parent;

          const isInvalid = hasPermission && (!val || val.length < 1);

          if (isInvalid) {
            return createError({
              path: 'integratedPermission.ippcPids',
              message:
                'Pokud má provozovna integrované povolení, je nutné přidat alespoň 1 PID',
            });
          }
          return true;
        }),
    }),
    emissionsTradingSystem: Yup.object().shape({
      ids: Yup.array()
        .of(
          Yup.string().test('', '', function (val) {
            const { createError, parent } = this;

            const isInvalid = !val || !/CZ-[0-9]{4}$/.test(val);

            if (isInvalid) {
              return createError({
                path: 'emissionsTradingSystem.ids',
                message:
                  'Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9',
              });
            }

            const duplicates = (parent as string[]).filter(
              (item, index) => parent.indexOf(item) !== index
            );

            if (duplicates.length > 0) {
              return createError({
                path: 'emissionsTradingSystem.ids',
                message: `Každý PID je možné vyplnit pouze jednou ${JSON.stringify(
                  duplicates
                )}`,
              });
            }

            return true;
          })
        )
        .test('', '', function (val) {
          const { createError, parent } = this;
          const { tradesInEmissions } = parent;

          const isInvalid = tradesInEmissions && (!val || val.length < 1);

          if (isInvalid) {
            return createError({
              path: 'emissionsTradingSystem.ids',
              message:
                'Při obchodování s emisemi je potřeba přidat alespoň 1 identifikátor',
            });
          }
          return true;
        }),
    }),
    cadastralTerritory: Yup.mixed().when('address.addressPlaceRuian', {
      is: (type) => !type,
      then: Yup.mixed()
        .nullable()
        .required('Povinné pole (pokud není vyplněno adresní místo)'),
    }),
    landPlotType: Yup.mixed().when('address.addressPlaceRuian', {
      is: (type) => !type,
      then: Yup.mixed()
        .nullable()
        .required('Povinné pole (pokud není vyplněno adresní místo)'),
    }),
    primaryLandPlotNumber: Yup.mixed().when('address.addressPlaceRuian', {
      is: (type) => !type,
      then: Yup.mixed()
        .nullable()
        .required('Povinné pole (pokud není vyplněno adresní místo)'),
    }),
    address: addressFieldSchema(),
    wgs84Coordinates: wgs84CoordinatesSchema(),
  }) as Yup.Schema<OvzFacility>;
}

export const validateOvzFacilityName = Yup.mixed<string>()
  .nullable()
  .test('', '', function () {
    const { parent, createError } = this;
    const facilityName = parent.name;
    const operatorName = parent.operator?.name;

    if (!facilityName || !operatorName) {
      return createError({
        path: 'name',
        message:
          'Povinné pole, prvních 5 znaků v názvu provozovny musí odpovídat názvu provozovatele (subjektu)',
      });
    }

    const operatorNamePrefix =
      operatorName.length > 5
        ? operatorName?.substring(0, 5)
        : operatorName?.substring(0, 5).trim();

    if (!facilityName.startsWith(operatorNamePrefix)) {
      return createError({
        path: 'name',
        message: `Prvních 5 znaků v názvu provozovny musí odpovídat názvu subjektu - "${operatorNamePrefix}"`,
      });
    }

    return true;
  });

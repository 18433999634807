import * as Yup from 'yup';
import { StepLocationForm } from './step-location-types';
import {
  addressFieldSchema,
  wgs84CoordinatesSchema,
} from '../../../components/form/address-field/address-field-schema';

export const stepLocationSchema = () =>
  Yup.object<StepLocationForm>().shape({
    address: addressFieldSchema(),
    wgs84Coordinates: wgs84CoordinatesSchema(),
  });

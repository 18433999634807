import React from 'react';
import {
  FormTextField,
  useFormSelector,
  FormCustomField,
  TextField,
} from '@eas/common-web';
import { useAddressLabel } from '../../../components/form/address-field/hooks/address-label-hook';
import { Address, EmpowermentRequest, SubjectType } from '../../../models';
import { get } from 'lodash';
import { icoMaxLength } from '../../subject/fields/subjects-utils';
import { HelpLabel } from '../../../components/help/help-label';

export function SubjectBasicFields({
  prefix,
  codePrefix,
  isAuthor,
}: {
  prefix: string;
  codePrefix: string;
  isAuthor: boolean;
}) {
  const names = {
    ico: prefix ? `${prefix}.ico` : 'ico',
    icoPersonal: prefix ? `${prefix}.ico.value` : 'ico.value',
    name: prefix ? `${prefix}.name` : 'name',
    residence: prefix ? `${prefix}.residence` : 'residence',
    approved: prefix ? `${prefix}.approved` : 'approved',
    artificialId: prefix ? `${prefix}.artificialId` : 'artificialId',
    type: prefix ? `${prefix}.type` : 'type',
  };

  const { residence, ico, approved, type } = useFormSelector(
    (data: EmpowermentRequest) => ({
      residence: get(data, names.residence) as Address,
      ico: get(data, names.icoPersonal) || get(data, names.ico),
      artificialId: get(data, names.artificialId),
      approved: get(data, names.approved),
      type: get(data, names.type),
    })
  );

  const addressLabel = useAddressLabel(residence);

  return (
    <>
      {ico && (
        <FormCustomField
          label={
            <HelpLabel
              label="IČO"
              code={`${codePrefix}_${prefix.toUpperCase()}_ICO`}
            />
          }
        >
          <TextField
            value={ico}
            disabled={true}
            inputProps={{ maxLength: icoMaxLength }}
          />
        </FormCustomField>
      )}
      {!isAuthor && !approved && type === SubjectType.FOREIGN_LEGAL_ENTITY && (
        <FormTextField
          name="otherSubject.foreignSubjectInfo.foreignId"
          label={
            <HelpLabel
              label="Zahraniční identifikátor (obdoba IČO)"
              code={`${codePrefix}_${prefix.toUpperCase()}_FOREIGN_ID`}
            />
          }
          disabled
        />
      )}

      <FormTextField
        name={names.name}
        label={
          <HelpLabel
            label="Název"
            code={`${codePrefix}_${prefix.toUpperCase()}_NAME`}
            required
          />
        } // Povinné pole
        disabled={true}
      />
      <FormCustomField
        label={
          <HelpLabel
            label="Adresa"
            code={`${codePrefix}_${prefix.toUpperCase()}_ADDRESS`}
          />
        }
      >
        <TextField value={addressLabel} disabled />
      </FormCustomField>
    </>
  );
}

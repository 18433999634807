import React, { useContext } from 'react';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TelegramIcon from '@material-ui/icons/Telegram';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DetailContext, DetailToolbarButtonMenuItem } from '@eas/common-web';
import { useRedirect } from './actions/redirect-hook';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import { DetailToolbarButtonMenu } from '../../components/evidence/evidence/detail-toolbar-button-menu';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

type IconType = OverridableComponent<
  SvgIconTypeMap<Record<string, unknown>, 'svg'>
>;

function useToolbarItems() {
  /**
   * Context stuff.
   */
  const { source } = useContext(DetailContext);

  /**
   * Array of SUBJECT ACTIONS.
   */
  const actionItems: DetailToolbarButtonMenuItem[] = [];

  const addAction = (label: string, Icon: IconType, onClick: () => void) =>
    actionItems.push({
      label,
      Icon: <Icon fontSize="small" htmlColor="black" />,
      onClick,
    });

  /**
   * Redirect actions.
   */
  const {
    handleAllRegistrations,
    handleNewRegistration,
    handleRedirect,
  } = useRedirect();

  addAction('Nová registrace subjektu', PlusOneIcon, handleNewRegistration);
  if (source.data?.subjectRegistration) {
    addAction('Žádost', TelegramIcon, handleRedirect);
  }
  addAction(
    'Přehled žádostí o registraci subjektu',
    ListAltIcon,
    handleAllRegistrations
  );

  return {
    actionItems,
  };
}

export function SubjectsToolbar() {
  const { actionItems } = useToolbarItems();

  return (
    <>
      <DetailToolbarButtonMenu label="Otevřít" items={actionItems} />
    </>
  );
}

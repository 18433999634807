import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prefix: {
      minWidth: '100px',
      width: '100px',
      paddingRight: theme.spacing(1),
    },
    prefixInput: {
      padding: theme.spacing(1),
      height: 32,
    },
    number: {
      minWidth: 'calc(100% - 100px)',
      width: 'calc(100% - 100px)',
    },
    numberInput: {
      padding: theme.spacing(1),
      height: 32,
    },
  })
);

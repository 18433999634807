import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useRequestStates } from './ovz-facility-requests-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const requestStates = useRequestStates();

  return [
    {
      key: 'state',
      label: 'Stav',
      source: requestStates,
    },
    {
      key: 'decided',
      label: 'Datum vyřízení',
    },
    {
      key: 'date',
      label: 'Datum přesunu',
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
    {
      key: 'oldOperator',
      label: 'Původní provozovatel',
    },
    {
      key: 'newOperator',
      label: 'Nový provozovatel',
    },
    {
      key: 'newOperatorIdentifier',
      label: 'Identifikátor',
    },
    {
      key: 'ovzFacilities',
      label: 'Provozovny',
    },
  ];
}

import React from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { TableToolbarButton } from '@eas/common-web';
import { useBulkAuthorizeDialog } from './dialog-actions/bulk-authorize-hook';

export function AuthorizeToolbarButton() {
  const { handleAuthorize, showAuthorizeButton } = useBulkAuthorizeDialog();

  return (
    <>
      {showAuthorizeButton && (
        <TableToolbarButton
          label={<DoneAllIcon />}
          tooltip="Hromadná autorizace"
          onClick={handleAuthorize}
        />
      )}
    </>
  );
}

export function SubjectRequestsTableToolbar() {
  return (
    <>
      <AuthorizeToolbarButton />
    </>
  );
}

/**
 * Notification permissions
 */
export enum PermissionNotification {
  /** Přehled notifikací (pro správce) */
  NOTIFICATION_LIST = 'NOTIFICATION_LIST',

  /** Detail notifikace (pro správce) */
  NOTIFICATION_DETAIL = 'NOTIFICATION_DETAIL',

  /** Přehled notifikačních dávek */
  NOTIFICATION_BATCH_LIST = 'NOTIFICATION_BATCH_LIST',

  /** Detail notifikační dávky */
  NOTIFICATION_BATCH_DETAIL = 'NOTIFICATION_BATCH_DETAIL',

  /** Přehled notifikačních událostí */
  NOTIFICATION_EVENT_LIST = 'NOTIFICATION_EVENT_LIST',

  /** Detail notifikační události */
  NOTIFICATION_EVENT_DETAIL = 'NOTIFICATION_EVENT_DETAIL',

  /** Nastavení možností notifikační události */
  NOTIFICATION_EVENT_OPTION_SET = 'NOTIFICATION_EVENT_OPTION_SET',

  /** Přehled notifikačních šablon */
  NOTIFICATION_TEMPLATE_LIST = 'NOTIFICATION_TEMPLATE_LIST',

  /** Detail notifikační šablony */
  NOTIFICATION_TEMPLATE_DETAIL = 'NOTIFICATION_TEMPLATE_DETAIL',

  /** Vytvoření notifikační šablony */
  NOTIFICATION_TEMPLATE_CREATE = 'NOTIFICATION_TEMPLATE_CREATE',

  /** Editace notifikační šablony */
  NOTIFICATION_TEMPLATE_UPDATE = 'NOTIFICATION_TEMPLATE_UPDATE',

  /** Smazání notifikační šablony */
  NOTIFICATION_TEMPLATE_DELETE = 'NOTIFICATION_TEMPLATE_DELETE',

  /** Zneplatnění notifikační šablony */
  NOTIFICATION_TEMPLATE_INVALIDATE = 'NOTIFICATION_TEMPLATE_INVALIDATE',

  /** Obnovení notifikační šablony */
  NOTIFICATION_TEMPLATE_REVALIDATE = 'NOTIFICATION_TEMPLATE_REVALIDATE',

  /** Test notifikační šablony */
  NOTIFICATION_TEMPLATE_TEST = 'NOTIFICATION_TEMPLATE_TEST',
}

import React from 'react';
import { ProcessForm } from '../irz-facility-requests-new-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    sent: {
      color: theme.palette.primary.main,
    },
    unsent: {
      color: theme.palette.error.main,
    },
  })
);

export function StepSummaryLabel({ allValues }: { allValues?: ProcessForm }) {
  const classes = useStyles();

  const state = allValues?.isSent ? 'ČEKÁ NA SCHVÁLENÍ' : 'NEODESLÁNO';

  return (
    <>
      Stav registrace:{' '}
      <b className={allValues?.isSent ? classes.sent : classes.unsent}>
        {state}
      </b>
    </>
  );
}

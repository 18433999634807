import { useAutocompleteSource, Params } from '@eas/common-web';
import { DictionaryLawParagraphAutocomplete } from './../../models';
import { EvidenceAPI } from '../../enums';

export function useDirpLawParagraphs(params?: Params) {
  return useAutocompleteSource<DictionaryLawParagraphAutocomplete>({
    url: `${EvidenceAPI.DICTIONARY_DIRP_LAW_PARAGRAPHS}/autocomplete`,
    params,
    apiUrl: EvidenceAPI.DICTIONARY_DIRP_LAW_PARAGRAPHS,
  });
}

import React, { useContext } from 'react';
import {
  FormPanel,
  FormAutocomplete,
  DetailMode,
  DetailContext,
} from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useAssignableSystemRoles } from '../../roles/roles-api';
import { useSystems } from '../../system/systems-api';

export function SystemRoleFields() {
  const { mode } = useContext(DetailContext);

  const systemRoles = useAssignableSystemRoles();
  const systems = useSystems();

  return (
    <FormPanel label="Systémová role">
      <FormAutocomplete
        disabled={mode === DetailMode.EDIT}
        name="role"
        label={<HelpLabel label="Role" code="ROLE_INSTANCES_ROLE" required />} // Povinné pole
        source={systemRoles}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
      <FormAutocomplete
        disabled={mode === DetailMode.EDIT}
        name="system"
        label={
          <HelpLabel label="Systém" code="ROLE_INSTANCES_SYSTEM" required />
        } // Povinné pole
        source={systems}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

import { useContext, useMemo } from 'react';
import { useScrollableSource, UserContext } from '@eas/common-web';
import { useColumns } from './role-admin-columns';
import { User, RoleInstance } from '../../../../models';
import { EvidenceAPI, Permission } from '../../../../enums';

export function useAdminRoles(profile: User) {
  const { hasPermission } = useContext(UserContext);
  const source = useScrollableSource<RoleInstance>({
    url: `${EvidenceAPI.USERS}/${profile.id}/role/instance/relationship/list`,
  });

  const isAdmin = useMemo(
    () => hasPermission(Permission.User.USER_DETAIL_ALL),
    [hasPermission]
  );

  const columns = useColumns(isAdmin);

  return {
    source,
    columns,
  };
}

import {
  Form,
  FormPanel,
  FormSelect,
  FormTextField,
  FormAutocomplete,
  FormTextArea,
  FilesProvider,
  FormFileField,
} from '@eas/common-web';
import { noop } from 'lodash';
import React from 'react';
import { useRoleRequestTypes } from '../../role-request/role-requests-api';
import { ProcessForm } from '../role-requests-type';
import { RoleRequestType } from '../../../models';
import { useRoles } from '../../roles/roles-api';
import { EvidenceAPI } from '../../../enums';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const types = useRoleRequestTypes();
  const roles = useRoles();

  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Základní informace">
        <FormSelect source={types} label="Typ žádosti" name="type" valueIsId />
        {allValues.type === RoleRequestType.USER_ROLE_REQUEST && (
          <FormTextField label="Subjekt" name="subject.label" />
        )}
        <FormTextField label="Systém" name="system.name" />
        <FormAutocomplete
          source={roles}
          label="Požadované role"
          name="requestedRoles"
          multiline
          multiple
          labelMapper={dictionaryLabelMapper}
        />
        <FormTextArea label="Odůvodnění žádosti" name="reason" />
        <FilesProvider url={EvidenceAPI.ARCHIVED_FILES}>
          <FormFileField name="document" label="Dokument" />
        </FilesProvider>
      </FormPanel>
    </Form>
  );
}

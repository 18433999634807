import * as Yup from 'yup';
import { DictionaryLaw } from './../../models';

export function useValidationSchema() {
  return Yup.object<DictionaryLaw>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    code: Yup.string()
      .test('', '', function (val) {
        const { createError } = this;

        const isInvalid =
          !val ||
          (!/^\d*\/\d{4}$/.test(val) &&
            !/^\d*\/\d{4} Sb.$/.test(val) &&
            !/^\d{4}\/\d*$/.test(val));

        if (isInvalid) {
          return createError({
            path: 'code',
            message: `Číslo předpisu musí být ve tvaru {rok předpisu}/{číslo předpisu} (např. 2022/1778) nebo {číslo předpisu}/{rok předpisu} (např. 1/2019, v případě sbírek 19/2002 Sb.)`,
          });
        }

        return true;
      })
      .nullable()
      .required('Povinné pole'),
  });
}

import * as Yup from 'yup';
import { StepIdentifiersForm } from './step-identifiers-types';

export const stepIdentifiersSchema = () =>
  Yup.object<StepIdentifiersForm>().shape({
    integratedPermission: Yup.object().shape({
      ippcPids: Yup.array()
        .of<string>(
          Yup.mixed<string>().test('', '', function (val) {
            const { createError, parent } = this;
            const isInvalid = !val || !/^MZP[A-Z0-9]{9}$/.test(val);

            if (isInvalid) {
              return createError({
                path: 'integratedPermission.ippcPids',
                message: `Musí začínat písmeny MZP a následovat 9 velkých alfanumerických znaků`,
              });
            }

            const duplicates = (parent as string[]).filter(
              (item, index) => parent.indexOf(item) !== index
            );

            if (duplicates.length > 0) {
              return createError({
                path: 'integratedPermission.ippcPids',
                message: `Každý PID je možné vyplnit pouze jednou ${JSON.stringify(
                  duplicates
                )}`,
              });
            }

            return true;
          })
        )
        .test('', '', function (val) {
          const { createError, parent } = this;
          const { hasPermission } = parent;

          const isInvalid = hasPermission && (!val || val.length < 1);

          if (isInvalid) {
            return createError({
              path: 'integratedPermission.ippcPids',
              message:
                'Pokud má provozovna integrované povolení, je nutné přidat alespoň 1 PID',
            });
          }
          return true;
        }),
    }),
    emissionsTradingSystem: Yup.object().shape({
      ids: Yup.array()
        .of(
          Yup.string().test('', '', function (val) {
            const { createError, parent } = this;

            const isInvalid = !val || !/CZ-[0-9]{4}$/.test(val);

            if (isInvalid) {
              return createError({
                path: 'emissionsTradingSystem.ids',
                message:
                  'Formát identifikátoru: CZ-XXXX, kde XXXX představuje čtyřmístné číslo od 0 do 9',
              });
            }

            const duplicates = (parent as string[]).filter(
              (item, index) => parent.indexOf(item) !== index
            );

            if (duplicates.length > 0) {
              return createError({
                path: 'emissionsTradingSystem.ids',
                message: `Každý PID je možné vyplnit pouze jednou ${JSON.stringify(
                  duplicates
                )}`,
              });
            }

            return true;
          })
        )
        .test('', '', function (val) {
          const { createError, parent } = this;
          const { tradesInEmissions } = parent;

          const isInvalid = tradesInEmissions && (!val || val.length < 1);

          if (isInvalid) {
            return createError({
              path: 'emissionsTradingSystem.ids',
              message:
                'Při obchodování s emisemi je potřeba přidat alespoň 1 identifikátor',
            });
          }
          return true;
        }),
    }),
  });

import React from 'react';
import { FormPanel, FormTextArea, FormDateField } from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';

export function StepNoteDateContent() {
  const todayISO = new Date().toISOString();
  return (
    <FormPanel label="Vyplňte">
      <FormTextArea
        name="note"
        label={
          <HelpLabel
            label="Poznámka"
            code="IRZ_FACILITY_REQUEST_OPERATOR_CHANGE_NOTE"
          />
        }
      />
      <FormDateField
        name="date"
        label={
          <HelpLabel
            label="Datum změny provozovatele"
            code="IRZ_FACILITY_REQUEST_OPERATOR_CHANGE_DATE"
            required
          />
        }
        maxDatePicker={todayISO}
      />
    </FormPanel>
  );
}

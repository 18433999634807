export { PermissionAgenda as Agenda } from './permissions/perm-agenda';
export { PermissionChlu as Chlu } from './permissions/perm-chlu';
export { PermissionDeposit as Deposit } from './permissions/perm-deposit';
export { PermissionDirp as Dirp } from './permissions/perm-dirp';
export { PermissionEmpowerment as Empowerment } from './permissions/perm-empowerment';
export { PermissionIrzFacility as IrzFacility } from './permissions/perm-irz-facility';
export { PermissionNote as Note } from './permissions/perm-note';
export { PermissionOvzFacility as OvzFacility } from './permissions/perm-ovz-facility';
export { PermissionPersonalData as PersonalData } from './permissions/perm-personal-data';
export { PermissionProductionSite as ProductionSite } from './permissions/perm-production-site';
export { PermissionRole as Role } from './permissions/perm-role';
export { PermissionRoleRequest as RoleRequest } from './permissions/perm-role-request';
export { PermissionSubject as Subject } from './permissions/perm-subject';
export { PermissionUser as User } from './permissions/perm-user';
export { PermissionScheduledTask as ScheduledTask } from './permissions/perm-scheduled-task';
export { PermissionAction as Action } from './permissions/perm-action';
export { PermissionSystem as System } from './permissions/perm-system';
export { PermissionThirdPartySystem as ThirdPartySystem } from './permissions/perm-third-party-system';
export { PermissionWaterUsagePlace as WaterUsagePlace } from './permissions/perm-water-usage-place';
export { PermissionDictionary as Dictionary } from './permissions/perm-dictionary';
export { PermissionNotification as Notification } from './permissions/perm-notification';
export { PermissionOzo as Ozo } from './permissions/perm-ozo';
export { PermissionWSExecution as WSExecution } from './permissions/perm-ws-execution';
export { PermissionPublicList as PublicList } from './permissions/perm-public-list';
export { PermissionOutage as Outage } from './permissions/perm-outage';
export { PermissionMailing as Mailing } from './permissions/perm-mailing';
export { PermissionReporting as Reporting } from './permissions/perm-reporting';
export { PermissionReportingAccessRule as ReportingAccessRule } from './permissions/perm-reporting';
export { PermissionAlog as Alog } from './permissions/perm-alog';
export { PermissionMapOverview as MapOverview } from './permissions/perm-map-overview';
export { PermissionExportAccessRule as ExportAccessRule } from './permissions/perm-export';
export { PermissionExport as Export } from './permissions/perm-export';
export { PermissionWebService as WebService } from './permissions/perm-web-service';
export { PermissionHelp as Help } from './permissions/perm-help';
export { PermissionIspopDocument as IspopDocument } from './permissions/perm-ispop-document';
export { PermissionMuvBalanceUnit as MuvBalanceUnit } from './permissions/perm-muv-balance-unit';
export { PermissionMuvDecisionType as MuvDecisionType } from './permissions/perm-muv-decision-type';
export { PermissionMuvHydrogeologicalZone as MuvHydrogeologicalZone } from './permissions/perm-muv-hydrogeological-zone';
export { PermissionMuvIndicator as MuvIndicator } from './permissions/perm-muv-indicator';
export { PermissionMuvRiverSide as MuvRiverSide } from './permissions/perm-muv-river-side';
export { PermissionMuvSampleType as MuvSampleType } from './permissions/perm-muv-sample-type';
export { PermissionMuvSewerType as MuvSewerType } from './permissions/perm-muv-sewer-type';
export { PermissionMuvWaterFlow as MuvWaterFlow } from './permissions/perm-muv-water-flow';
export { PermissionMuvWaterOrigin as MuvWaterOrigin } from './permissions/perm-muv-water-origin';
export { PermissionMuvWaterTreatment as MuvWaterTreatment } from './permissions/perm-muv-water-treatment';
export { PermissionSOAPMessage as SOAPMessage } from './permissions/perm-soap-message';
export enum Default {
  LOGGED_USER = 'LOGGED_USER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  CENIA_ADMIN = 'CENIA_ADMIN',
  TWO_FACTOR_PRE_AUTH = 'TWO_FACTOR_PRE_AUTH',
}

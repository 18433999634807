import {
  TableColumn,
  TableCells,
  TableFilterCells,
  TableFieldColumn,
  InlineTableFieldCells,
  ApiFilterOperation,
  TableCellProps,
} from '@eas/common-web';
import { IrzFacilityRequest, IrzRequestState } from '../../models';
// import { idValueMapperFactory } from '../../composite/subject-column-mappers/id-mapper';
import { useRequestStates, useRequestTypes } from './irz-facility-requests-api';
import { useMemo, ComponentType } from 'react';
import { addressValueMapperFactory } from '../../composite/subject-column-mappers/address-mapper';
import { requestColoredFactory } from '../../components/form/request-colored/request-colored';
import { coordinateValueMapper } from '../../composite/facility-column-mappers/coordinate-mapper';

function isApproved(req: IrzFacilityRequest) {
  return req?.state === IrzRequestState.APPROVED;
}
function isRejected(req: IrzFacilityRequest) {
  return req?.state === IrzRequestState.REJECTED;
}

function isWaitingForAuthorization(req: IrzFacilityRequest) {
  return req?.state === IrzRequestState.WAITING_FOR_AUTHORIZATION;
}

export function irzFacilityRequestColumnFactory(
  cell: ComponentType<TableCellProps<IrzFacilityRequest>>
) {
  return requestColoredFactory(
    cell,
    isApproved,
    isRejected,
    isWaitingForAuthorization
  );
}

export function useColumns(): TableColumn<IrzFacilityRequest>[] {
  return [
    {
      datakey: 'number',
      name: 'Evidenční číslo',
      width: 200,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ žádosti',
      width: 200,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useRequestTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.useSelectCellFactory(useRequestTypes),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav žádosti',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useRequestStates
      ),
      valueMapper: TableCells.useSelectCellFactory(useRequestStates),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'userEmail.value',
      sortkey: 'userEmail',
      filterkey: 'userEmail',
      name: 'E-mail žadatele',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'requestFacilityData.name',
      name: 'Název provozovny',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'requestFacilityData.facilityId',
      name: 'IČP',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'requestFacilityData.operator.identifier',
      name: 'Provozovatel - IČO / UID',
      width: 300,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'requestFacilityData.operator.identityDocumentsConcatenated',
      name: 'Provozovatel - OP / pas',
      width: 300,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'requestFacilityData.operator.name',
      name: 'Provozovatel - Název',
      width: 300,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'requestFacilityData.address.municipality',
      sortkey: 'requestFacilityData.address.municipality.name',
      filterkey: 'requestFacilityData.address.municipality.name',
      name: 'Obec',
      width: 200,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'requestFacilityData.address.cityDistrict',
      sortkey: 'requestFacilityData.address.cityDistrict.name',
      filterkey: 'requestFacilityData.address.cityDistrict.name',
      name: 'Městská část',
      width: 200,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'requestFacilityData.address.street',
      sortkey: 'requestFacilityData.address.street.name',
      filterkey: 'requestFacilityData.address.street.name',
      name: 'Ulice',
      width: 200,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'requestFacilityData.address.houseNumber',
      name: 'Číslo popisné',
      width: 200,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'requestFacilityData.address.orientationNumber',
      name: 'Číslo orientační',
      width: 200,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
      valueMapper: addressValueMapperFactory(),
    },
    {
      datakey: 'requestFacilityData.wgs84Coordinates.lat',
      name: 'Zeměpisná šířka',
      width: 200,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: false,
      filterGroup: 3,
      valueMapper: coordinateValueMapper,
    },
    {
      datakey: 'requestFacilityData.wgs84Coordinates.lon',
      name: 'Zeměpisná délka',
      width: 200,
      CellComponent: irzFacilityRequestColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: false,
      filterGroup: 3,
      valueMapper: coordinateValueMapper,
    },
  ];
}

export function useIppcPidColumns(
  dataKey?: string
): TableFieldColumn<string>[] {
  const IppcPidCell = InlineTableFieldCells.useInlineTextFieldFactory<string>({
    collectionDatakey: dataKey ?? 'integratedPermission.ippcPids',
    isSubkey: false,
  });

  return useMemo(
    () => [
      {
        name: 'PID (IPPC)',
        datakey: '',
        width: 600,
        CellComponent: IppcPidCell,
      },
    ],
    [IppcPidCell]
  );
}

import { useRef, useContext } from 'react';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  abortableFetch,
  PromptContext,
  usePrompts,
  useFormSelector,
} from '@eas/common-web';
import { Me } from '../../../../models';
import { Messages, EvidenceAPI, Permission } from '../../../../enums';
import { unstable_batchedUpdates } from 'react-dom';
import { SystemCertificate } from '../../../../models/system-certificate';
import { System } from '../../../../models/system';

const PROMPT_INACTIVE_KEY = 'DELETE_INACTIVE_CERTIFICATE';
const PROMPT_ACTIVE_KEY = 'DELETE_ACTIVE_CERTIFICATE';

function callApi(certificate: SystemCertificate, system: System) {
  return abortableFetch(
    `${EvidenceAPI.SYSTEMS}/${system.id}/certificate/${certificate.id}`,
    {
      method: 'DELETE',
    }
  );
}

export function useDeleteSystemCertificateDialog({
  selectedIndex,
  setSelectedIndex,
}: {
  selectedIndex: number;
  setSelectedIndex: (index: number | undefined) => void;
}) {
  const { source } = useContext(DetailContext);

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const { showSnackbar } = useContext(SnackbarContext);

  const { testPrompt } = useContext(PromptContext);

  const fetch = useRef<AbortableFetch | null>(null);

  const { certificates } = useFormSelector((data: System) => ({
    certificates: data?.certificates,
  }));

  const isActive = certificates?.[selectedIndex]?.active;

  const canDeleteSystemCertificate = hasPermission(
    Permission.System.SYSTEM_CERTIFICATE_DELETE
  );

  const handleDeleteCertificate = useEventCallback(async () => {
    testPrompt({
      key: isActive ? PROMPT_ACTIVE_KEY : PROMPT_INACTIVE_KEY,
      submitCallback: async () => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(certificates?.[selectedIndex]!, source.data!);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.System.DELETE_CERTIFICATE.SUCCESS);
            source.setLoading(false);
          });

          await source.refresh();
          setSelectedIndex(undefined);
        } catch (e) {
          source.setLoading(false);

          if (e.name !== 'AbortError') {
            switch (e.status) {
              case 403:
                showSnackbar(
                  ...Messages.System.DELETE_CERTIFICATE.ALREADY_INACTIVE
                );
                break;
              case 404:
                showSnackbar(...Messages.System.DELETE_CERTIFICATE.NOT_FOUND);
                break;
              default:
                showSnackbar(...Messages.System.DELETE_CERTIFICATE.ERROR);
                break;
            }
            throw e;
          }
          return undefined;
        }
      },
    });
  });

  usePrompts([
    {
      key: PROMPT_INACTIVE_KEY,
      dialogTitle: 'Odstranění systémového certifikátu',
      dialogText: 'Opravdu si přejete odstranit tento certifikát?',
    },
    {
      key: PROMPT_ACTIVE_KEY,
      dialogTitle: 'Odstranění aktivního systémového certifikátu',
      dialogText:
        'Pozor, tento certifikát je aktivní, jeho odstranění odstaví daný systém. Opravdu si přejete jej odstranit?',
    },
  ]);

  return {
    canDeleteSystemCertificate,
    handleDeleteCertificate,
  };
}

import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import {
  FormPanel,
  FormTextField,
  useFormSelector,
  FormAutocomplete,
  FormInlineTableField,
  FormContext,
  useEventCallback,
  FormCheckboxGroup,
} from '@eas/common-web';
import { StepEditForm } from './step-edit-types';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { useNaceItems } from '../../dict-nace-item/dict-nace-items-api';
import { useNaceCzItems } from '../../dict-nace-cz-item/dict-nace-cz-items-api';
import { useContext } from 'react';
import { MainActivityType, ActivityRecord } from '../../../models';
import {
  useMainActivities,
  useEPrtrAndIppcActivities,
} from '../../irz-facility/irz-facilities-api';
import { useIrzActivities } from '../../dict-irz-activity/dict-irz-activities-api';

import { useStepEdit } from './step-edit-hook';
import { ProcessForm } from '../irz-facility-merge-types';
import {
  useSecondaryEPrtrActivityColumns,
  useSecondaryIrzActivityColumns,
} from './step-edit-columns';
import { HelpLabel } from '../../../components/help/help-label';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';

export function StepEditContent({ allValues }: { allValues: ProcessForm }) {
  useStepEdit(allValues);

  const types = useMainActivities();
  const naceItems = useNaceItems();
  const naceCzItems = useNaceCzItems();
  const { ePrtrActivities, ippcActivities } = useEPrtrAndIppcActivities(
    'editData'
  );
  const irzActivities = useIrzActivities();

  const {
    wgs84Coordinates,
    activityTypes,
    mainIrzActivity,
    secondaryIrzActivities,
    czNace,
  } = useFormSelector(({ editData }: StepEditForm) => ({
    wgs84Coordinates: editData?.wgs84Coordinates,
    activityTypes: editData?.activityTypes,
    mainIrzActivity: editData?.mainIrzActivity,
    secondaryIrzActivities: editData?.secondaryIrzActivities ?? [],
    czNace: editData?.czNace,
  }));

  const { setFieldValue } = useContext(FormContext);

  const handleMainActivityTypeChanged = useEventCallback(() => {
    if (activityTypes?.includes(MainActivityType.E_PRTR)) {
      if (mainIrzActivity) {
        const newSecondaryIrzActivities = cloneDeep(secondaryIrzActivities);
        newSecondaryIrzActivities?.push(mainIrzActivity);

        setFieldValue('editData.secondaryIrzActivities', [
          ...newSecondaryIrzActivities,
        ]);
        setFieldValue('editData.mainIrzActivity', undefined);
      }
    }
    if (!activityTypes?.includes(MainActivityType.IRZ)) {
      setFieldValue('editData.mainIrzActivity', undefined);
      setFieldValue('editData.secondaryIrzActivities', undefined);
    }
    if (!activityTypes?.includes(MainActivityType.E_PRTR)) {
      setFieldValue('editData.mainEprtrActivity', undefined);
      setFieldValue('editData.secondaryEprtrActivities', undefined);
    }
  });

  const secondaryEPrtrActivitiesColumns = useSecondaryEPrtrActivityColumns();
  const secondaryIrzActivitiesColumns = useSecondaryIrzActivityColumns();

  useEffect(() => {
    setFieldValue('editData.nace', czNace?.nace);
  }, [czNace]);

  return (
    <>
      <>
        <FormPanel label="Základní informace">
          <FormTextField
            name="editData.name"
            label={
              <HelpLabel
                label="Název"
                code="IRZ_FACILITY_MERGE_EDIT_DATA_NAME"
                required
              />
            } // Povinné pole
          />
          <FormTextField
            name="editData.webSite"
            label={
              <HelpLabel
                label="Web stránka"
                code="IRZ_FACILITY_MERGE_EDIT_DATA_WEB_SITE"
              />
            }
          />
          <FormTextField
            name="editData.publicEmail"
            label={
              <HelpLabel
                label="Veřejný email"
                code="IRZ_FACILITY_MERGE_EDIT_DATA_PUBLIC_EMAIL"
              />
            }
          />
          <FormTextField
            name="editData.publicPhoneNumber"
            label={
              <HelpLabel
                label="Veřejný telefon"
                code="IRZ_FACILITY_MERGE_EDIT_DATA_PUBLIC_PHONE_NUMBER"
              />
            }
          />
        </FormPanel>
        <LocationPanel
          locationName="editData.wgs84Coordinates"
          locationValue={wgs84Coordinates}
          addressName="editData.address"
          disableForeignCountry={true}
          locationPanelLabel="Poloha provozovny"
          addressPanelLabel="Adresa provozovny"
          prefillCoordinates={false}
        />
        <FormPanel label="Klasifikace">
          <FormAutocomplete
            name="editData.czNace"
            label={
              <HelpLabel
                label="CZ-NACE"
                code="IRZ_FACILITY_MERGE_EDIT_DATA_CZ_NACE"
                required
              />
            } // Povinné pole
            source={naceCzItems}
            labelMapper={dictionaryLabelMapper}
            tooltipMapper={(o) => o.name}
          />
          <FormAutocomplete
            name="editData.nace"
            label={
              <HelpLabel
                label="NACE"
                code="IRZ_FACILITY_MERGE_EDIT_DATA_NACE"
                required
              />
            } // Povinné pole
            source={naceItems}
            tooltipMapper={(o) => o.name}
            disabled={true}
          />
        </FormPanel>
        <FormPanel label="Činnosti">
          <FormCheckboxGroup
            label={
              <HelpLabel
                label="Typ činnosti"
                code="IRZ_FACILITY_MERGE_EDIT_DATA_ACTIVITY_TYPES"
                required
              />
            } // Povinné pole
            source={types}
            name="editData.activityTypes"
            notifyChange={handleMainActivityTypeChanged}
          />
          {activityTypes?.includes(MainActivityType.E_PRTR) && (
            <>
              <FormAutocomplete
                name="editData.mainEprtrActivity.activity"
                label={
                  <HelpLabel
                    label="Hlavní E-PRTR činnost"
                    code="IRZ_FACILITY_MERGE_EDIT_DATA_MAIN_EPRTR_ACTIVITY_ACTIVITY"
                    required
                  />
                } // Povinné pole
                source={ePrtrActivities}
                labelMapper={dictionaryLabelMapper}
                tooltipMapper={dictionaryLabelMapper}
              />

              <FormTextField
                name="editData.mainEprtrActivity.facilityCount"
                label={
                  <HelpLabel
                    label="Počet zařízení hlavní E-PRTR činnosti"
                    code="IRZ_FACILITY_MERGE_EDIT_DATA_MAIN_EPRTR_ACTIVITY_FACILITY_COUNT"
                    required
                  />
                } // Povinné pole
              />
              <FormInlineTableField<ActivityRecord>
                name="editData.secondaryEprtrActivities"
                label={
                  <HelpLabel
                    label="Seznam vedlejších E-PRTR činností"
                    code="IRZ_FACILITY_MERGE_EDIT_DATA_SECONDARY_EPRTR_ACTIVITIES"
                  />
                } // Povinné pole, nesmí obsahovat hlavní činnost
                columns={secondaryEPrtrActivitiesColumns}
                maxRows={5}
              />
            </>
          )}

          {activityTypes?.includes(MainActivityType.IRZ) && (
            <>
              {!activityTypes?.includes(MainActivityType.E_PRTR) && (
                <>
                  <FormAutocomplete
                    name="editData.mainIrzActivity.activity"
                    label={
                      <HelpLabel
                        label="Hlavní činnost dle přílohy zákona o IRZ a ISPOP"
                        code="IRZ_FACILITY_MERGE_EDIT_DATA_MAIN_IRZ_ACTIVITY_ACTIVITY"
                        required
                      />
                    } // Povinné pole
                    source={irzActivities}
                    labelMapper={dictionaryLabelMapper}
                    tooltipMapper={dictionaryLabelMapper}
                  />
                  <FormTextField
                    name="editData.mainIrzActivity.facilityCount"
                    label={
                      <HelpLabel
                        label="Počet zařízení hlavní činnosti dle přílohy zákona o IRZ a ISPOP"
                        code="IRZ_FACILITY_MERGE_EDIT_DATA_MAIN_IRZ_ACTIVITY_FACILITY_COUNT"
                        required
                      />
                    } // Povinné pole, nejméně 1 zařízení
                  />
                </>
              )}

              <FormInlineTableField<ActivityRecord>
                name="editData.secondaryIrzActivities"
                label={
                  <HelpLabel
                    label="Seznam vedlejších činností dle přílohy zákona o IRZ a ISPOP"
                    code="IRZ_FACILITY_MERGE_EDIT_DATA_SECONDARY_IRZ_ACTIVITIES"
                  />
                } // Povinné pole, nesmí obsahovat hlavní činnost
                columns={secondaryIrzActivitiesColumns}
                maxRows={5}
              />
            </>
          )}

          <FormAutocomplete
            name="editData.ippcActivity"
            label={
              <HelpLabel
                label="Činnost IPPC"
                code="IRZ_FACILITY_MERGE_EDIT_DATA_IPPC_ACTIVITY"
              />
            }
            source={ippcActivities}
            tooltipMapper={(o) => o.name}
          />
        </FormPanel>
      </>
    </>
  );
}

import * as Yup from 'yup';
import { isBefore } from 'date-fns';

export function stepNoteDateSchema() {
  return Yup.object().shape({
    note: Yup.string().nullable(),
    date: Yup.date()
      .test('', '', function (dateValue) {
        const { createError, path } = this;
        const maxDate = new Date();
        if (dateValue && isBefore(maxDate, dateValue)) {
          return createError({
            path,
            message: 'Datum nesmí být v budoucnosti',
          });
        }
        return true;
      })
      .nullable()
      .required('Datum změny provozovatele provozovny IRZ musí být vyplněn.'),
  });
}

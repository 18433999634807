import { useEffect, useContext } from 'react';
import {
  FormContext,
  useFormSelector,
  useEventCallback,
} from '@eas/common-web';
import { ProcessForm } from '../irz-facility-request-change-types';
import { StepEditForm } from './step-edit-types';

export function useStepEdit(allValues: ProcessForm) {
  const { setFieldValue } = useContext(FormContext);

  const { irzId } = useFormSelector(
    ({ requestFacilityData }: StepEditForm) => ({
      irzId: requestFacilityData?.irzId,
    })
  );

  const resetValues = useEventCallback(() => {
    setFieldValue('requestFacilityData', allValues.irzFacility);
    setFieldValue('requestFacilityData.irzId', allValues.irzFacility?.id);
  });

  /**
   * If the facility was changed in the first step, we need to reset the form,
   * to have correct default values.
   */
  useEffect(() => {
    if (irzId !== allValues.irzFacility?.id) {
      resetValues();
    }
  }, []);
}

import React from 'react';
import {
  DetailContainer,
  FormTextField,
  FormPanel,
  FormDateField,
  FormTextArea,
  FormSelect,
  useFormSelector,
} from '@eas/common-web';
import { useSubjectTypes } from '../subject/subjects-api';
import { SubjectUnion, SubjectType } from '../../models';
import { AddressField } from '../../components/form/address-field/address-field';
import { FormPanelText } from '../../components/form/form-panel-summary/form-panel-text';
import { useAddressLabel } from '../../components/form/address-field/hooks/address-label-hook';
import { LegalEntityBasicFields } from '../subject/fields/legal-entity-fields/legal-entity-basic-fields';
import { LegalEntityAdvancedFields } from '../subject/fields/legal-entity-fields/legal-entity-advanced-fields';
import { NaturalPersonBasicFields } from '../subject/fields/natural-person-fields/natural-person-basic-fields';
import { NaturalPersonAdvancedFields } from '../subject/fields/natural-person-fields/natural-person-advanced-fields';
import { LegalEntityWithoutIcoBasicFields } from '../subject/fields/legal-entity-without-ico-fields/legal-entity-without-ico-basic-fields';
import { LegalEntityWithoutIcoAdvancedFields } from '../subject/fields/legal-entity-without-ico-fields/legal-entity-without-ico-advanced-fields';
import { BusinessNaturalPersonBasicFields } from '../subject/fields/business-natural-person-fields/business-natural-person-basic-fields';
import { BusinessNaturalPersonAdvancedFields } from '../subject/fields/business-natural-person-fields/business-natural-person-advanced-fields';
import { ForeignLegalEntityBasicFields } from '../subject/fields/foreign-legal-entity-fields/foreign-legal-entity-basic-fields';
import { ForeignLegalEntityAdvancedFields } from '../subject/fields/foreign-legal-entity-fields/foreign-legal-entity-advanced-fields';
import { ForeignNaturalPersonBasicFields } from '../subject/fields/foreign-natural-person-fields/foreign-natural-person-basic-fields';
import { ForeignNaturalPersonAdvancedFields } from '../subject/fields/foreign-natural-person-fields/foreign-natural-person-advanced-fields';
import { PhoneField } from '../../components/form/phone-field/phone-field';
import { HelpLabel } from '../../components/help/help-label';
import { IsdsIds } from '../../composite/subject-fields/subject-isds';

export function OzoSubjectsField() {
  const types = useSubjectTypes();

  const { type, residence } = useFormSelector((data: SubjectUnion) => ({
    type: data.type,
    residence: data?.residence,
  }));

  const addressLabel = useAddressLabel(residence);

  return (
    <DetailContainer>
      <FormPanel label="Základní informace">
        <FormSelect
          name="type"
          label={<HelpLabel label="Typ" code="OZO_SUBJECT_TYPE" required />} // Povinné pole
          source={types}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        <PhoneField
          name="phoneNumber"
          label={
            <HelpLabel
              label="Telefonní číslo (předvolba, číslo)"
              code="OZO_SUBJECT_PHONE_NUMBER"
            />
          }
        />
        <FormTextField
          name="email.value"
          label={<HelpLabel label="E-mail" code="OZO_SUBJECT_EMAIL" />}
        />
        <FormTextField
          name="webSite"
          label={
            <HelpLabel label="Webová stránka" code="OZO_SUBJECT_WEB_SITE" />
          }
        />
        <FormDateField
          name="inCrzpSince"
          label={
            <HelpLabel
              label="Datum registrace v CRŽP"
              code="OZO_SUBJECT_IN_CRZP_SINCE"
            />
          }
          disabled={true}
        />
        <IsdsIds codePrefix="OZO_SUBJECT" />
        <FormTextArea
          name="note"
          label={<HelpLabel label="Poznámky" code="OZO_SUBJECT_NOTE" />}
        />
      </FormPanel>
      {/* fyzicka osoba */}
      {type === SubjectType.BUSINESS_NATURAL_PERSON && (
        <FormPanel label="Fyzická osoba podnikající">
          <BusinessNaturalPersonBasicFields codePrefix="OZO_SUBJECT" />
          <BusinessNaturalPersonAdvancedFields codePrefix="OZO_SUBJECT" />
        </FormPanel>
      )}
      {type === SubjectType.FOREIGN_NATURAL_PERSON && (
        <FormPanel label="Zahraniční fyzická osoba">
          <ForeignNaturalPersonBasicFields codePrefix="OZO_SUBJECT" />
          <ForeignNaturalPersonAdvancedFields codePrefix="OZO_SUBJECT" />
        </FormPanel>
      )}
      {type === SubjectType.NATURAL_PERSON && (
        <FormPanel label="Fyzická osoba">
          <NaturalPersonBasicFields codePrefix="OZO_SUBJECT" />
          <NaturalPersonAdvancedFields codePrefix="OZO_SUBJECT" />
        </FormPanel>
      )}

      {/* pravnicka osoba */}
      {type === SubjectType.FOREIGN_LEGAL_ENTITY && (
        <FormPanel label="Zahraniční právnická osoba">
          <ForeignLegalEntityBasicFields codePrefix="OZO_SUBJECT" />
          <FormTextField
            name="foreignId"
            label={
              <HelpLabel
                label="Zahraniční identifikátor (obdoba IČO)"
                code="OZO_SUBJECT_FOREIGN_ID"
              />
            }
          />
          <ForeignLegalEntityAdvancedFields codePrefix="OZO_SUBJECT" />
        </FormPanel>
      )}
      {type === SubjectType.LEGAL_ENTITY && (
        <FormPanel label="Právnická osoba">
          <LegalEntityBasicFields codePrefix="OZO_SUBJECT" />
          <LegalEntityAdvancedFields codePrefix="OZO_SUBJECT" />
        </FormPanel>
      )}
      {type === SubjectType.LEGAL_ENTITY_WITHOUT_ICO && (
        <FormPanel label="Právnická osoba bez IČO">
          <LegalEntityWithoutIcoBasicFields codePrefix="OZO_SUBJECT" />
          <LegalEntityWithoutIcoAdvancedFields codePrefix="OZO_SUBJECT" />
        </FormPanel>
      )}
      <FormPanel
        label="Adresa"
        expandable
        defaultExpanded={false}
        summary={<FormPanelText value={addressLabel} />}
      >
        <AddressField name="residence" />
      </FormPanel>
    </DetailContainer>
  );
}

import React from 'react';
import { ProcessForm } from '../irz-facility-request-operator-change-types';

export function StepOriginalOperatorLabel({
  allValues,
}: {
  allValues?: ProcessForm;
}) {
  let label = allValues?.subjectIdentifier;

  if (allValues?.subject && 'ico' in allValues.subject) {
    label = `${label} - ${allValues.subject.name}`;
  }

  return (
    <>
      Původní provozovatel: <b>{label}</b>
    </>
  );
}

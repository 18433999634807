import React, { useContext, useMemo } from 'react';
import { useEventCallback, ApiFilterOperation } from '@eas/common-web';
import { AddressFieldCell } from '../address-field-cell';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';
import { EvidenceAPI } from '../../../../enums';
import { Address } from '../../../../models';

export function DistrictCell() {
  const { code } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);

  const createFilter = useEventCallback((address?: Address) => {
    const filter = [];

    if (address?.regionRuian?.id) {
      filter.push({
        field: 'region.id',
        operation: ApiFilterOperation.EQ,
        value: address?.regionRuian?.id,
      });
    }

    return filter;
  });

  const filterDeps = useEventCallback((address?: Address) => [
    address?.regionRuian?.id,
    address?.municipalityRuian?.id,
  ]);

  const descendantNames = useMemo(
    () =>
      [
        'municipalityRuian',
        'municipalityPartRuian',
        'cityDistrictRuian',
        'streetRuian',
        'addressPlaceRuian',
        'houseNumber',
        'houseNumberType',
        'orientationNumber',
        'orientationNumberMark',
        'zip',
      ] as const,
    []
  );

  return (
    <AddressFieldCell
      label={defaults?.district?.label ?? 'Okres'}
      required={defaults?.district?.required ?? true}
      name="districtRuian"
      customName="district"
      parentName="regionRuian"
      descendantNames={descendantNames}
      code={`${code}_DISTRICT`}
      url={EvidenceAPI.ADDRESS_DISCTRICT}
      parentUrl={EvidenceAPI.ADDRESS_REGION}
      createFilter={createFilter}
      filterDeps={filterDeps}
    />
  );
}

import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryCompetentControlAuthority } from '../../models';
import { DictionaryCompetentControlAuthoritiesFields } from './dict-competent-control-authorities-fields';
import { useValidationSchema } from './dict-competent-control-authorities-schema';
import { PageKey, EvidenceAPI, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';

export function DictionaryCompetentControlAuthorities() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryCompetentControlAuthority>({
    identifier: PageKey.DICTIONARY_COMPETENT_CONTROL_AUTHORITIES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_COMPETENT_CONTROL_AUTHORITIES,
    },
    tableProps: {
      tableName: 'Komp. kontrolní a ostatní orgány',
      reportTag: ExportTags.DICTIONARY_COMPETENT_CONTROL_AUTHORITIES,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.COMPETENT_CONTROL_AUTHORITY,
      codePrefix: 'COMPETENT_CONTROL_AUTHORITY',
      GeneralFieldsComponent: DictionaryCompetentControlAuthoritiesFields,
      validationSchema,
      toolbarProps: {
        title: 'Kompetentní kontrolní a ostatní orgány',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

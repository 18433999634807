import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
  TableCellProps,
} from '@eas/common-web';
import { SubjectRegistration, SubjectRegistrationState } from '../../models';
// import { icoValueMapperFactory } from '../../composite/subject-column-mappers/ico-mapper';
// import { idValueMapperFactory } from '../../composite/subject-column-mappers/id-mapper';
import { useSubjectRegistrationTypes } from '../subject-request-new/subject-requests-api';
import {
  useSubjectRegistrationState,
  useAuthorizationMethods,
} from './subject-requests-api';
import { requestColoredFactory } from '../../components/form/request-colored/request-colored';
import { ComponentType } from 'react';

function isApproved(s: SubjectRegistration) {
  return s.state === SubjectRegistrationState.APPROVED;
}

function isRejected(s: SubjectRegistration) {
  return (
    s.state === SubjectRegistrationState.REJECTED ||
    s.state === SubjectRegistrationState.REJECTED_FOR_EXISTING_OWNERSHIP
  );
}

function isWaitingForVerification(s: SubjectRegistration) {
  return [SubjectRegistrationState.MANUAL_FINISH_REQUIRED].includes(s.state!);
}

export function subjectRegistrationColumnFactory(
  cell: ComponentType<TableCellProps<SubjectRegistration>>
) {
  return requestColoredFactory(
    cell,
    isApproved,
    isRejected,
    isWaitingForVerification
  );
}

export function useColumns(): TableColumn<SubjectRegistration>[] {
  return [
    {
      datakey: 'evidenceNumber',
      name: 'Evidenční číslo',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: subjectRegistrationColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      filterkey: 'state.id',
      sortkey: 'state.name',
      name: 'Stav žádosti',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: subjectRegistrationColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useSubjectRegistrationState
      ),
      valueMapper: TableCells.useSelectCellFactory(useSubjectRegistrationState),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ subjektu',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: subjectRegistrationColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useSubjectRegistrationTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useSubjectRegistrationTypes),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: subjectRegistrationColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'identifier',
      name: 'IČO / UID',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: subjectRegistrationColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
      // valueMapper: icoValueMapperFactory(),
    },
    {
      datakey: 'idNumber.value',
      filterkey: 'idNumber',
      sortkey: 'idNumber',
      name: 'OP / pas',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: subjectRegistrationColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'foreignId',
      name: 'Zahr. identifikátor (obdoba IČO)',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: subjectRegistrationColumnFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'authorizationMethod',
      sortkey: 'authorizationMethod.name',
      filterkey: 'authorizationMethod.id',
      name: 'Způsob autorizace',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: subjectRegistrationColumnFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useAuthorizationMethods
      ),
      valueMapper: TableCells.useSelectCellFactory(useAuthorizationMethods),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'submitted',
      name: 'Datum podání',
      width: 150,
      CellComponent: subjectRegistrationColumnFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'authorized',
      name: 'Datum autorizace',
      width: 150,
      CellComponent: subjectRegistrationColumnFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
    },
    {
      name: 'E-mail - Autor',
      datakey: 'author.email.value',
      width: 200,
      CellComponent: subjectRegistrationColumnFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 10,
    },
  ];
}

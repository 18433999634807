import { useMemo, useContext, useState, useEffect, useRef } from 'react';
import { StepFacilityContent } from './step-facility/step-facility-content';
import { stepFacilitySchema } from './step-facility/step-facility-schema';
import { StepFacilityLabel } from './step-facility/step-facility-label';
import { StepEditLabel } from './step-edit/step-edit-label';
import { StepEditContent } from './step-edit/step-edit-content';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepFinishActions } from './step-finish/step-finish-actions';
import { stepEditSchema } from './step-edit/step-edit-schema';
import { StepFacilityActions } from './step-facility/step-facility-actions';
import {
  NavigationContext,
  useEventCallback,
  SnackbarContext,
  AbortableFetch,
  abortableFetch,
} from '@eas/common-web';
import { IrzChangeRequestStateAction } from './irz-facility-request-change-types';
import { IrzFacility } from '../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { StepNoteContent } from './step-note/step-note-content';
import { StepNoteLabel } from './step-note/step-note-label';
import { Messages, EvidenceAPI } from '../../enums';
import { StepEditActions } from './step-edit/step-edit-actions';

function fetchIrzFacility(id: string) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITIES}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export function useIrzFacilityRequestChange() {
  /**
   * Context stuff
   */
  const { stateAction } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);

  /**
   * IRZ facility preloading state
   */
  const [isLoading, setLoading] = useState(
    stateAction?.action === IrzChangeRequestStateAction.INIT_IRZ
  );
  const [initialValues, setInitialValues] = useState<{
    irzFacility: IrzFacility;
  }>();

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Pre-loads irz facility based on the NavigationContext stateAction data
   */
  const loadIrz = useEventCallback(async () => {
    try {
      setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }
      fetch.current = fetchIrzFacility(stateAction?.data);
      const irzFacility: IrzFacility = await fetch.current.json();

      unstable_batchedUpdates(() => {
        setLoading(false);
        setInitialValues({
          irzFacility,
        });
      });
    } catch (err) {
      setLoading(false);
      if (err.name !== 'AbortError') {
        showSnackbar(...Messages.IrzFacilityRequest.IRZ_FACILITY_PRELOAD.ERROR);
        throw err;
      }
      return undefined;
    }
  });

  useEffect(() => {
    if (stateAction?.action === IrzChangeRequestStateAction.INIT_IRZ) {
      loadIrz();
    }
  }, []);

  const steps = useMemo(
    () => [
      {
        Label: StepFacilityLabel,
        Content: StepFacilityContent,
        validationSchema: stepFacilitySchema,
        Actions: StepFacilityActions,
        initialValues,
      },
      {
        Label: StepEditLabel,
        Content: StepEditContent,
        validationSchema: stepEditSchema,
        Actions: StepEditActions,
      },
      {
        Label: StepNoteLabel,
        Content: StepNoteContent,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    [initialValues]
  );

  return { steps, isLoading };
}

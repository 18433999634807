import { SubjectType, SubjectRegistrationType } from '../../models';
import { useStaticListSource, DictionaryAutocomplete } from '@eas/common-web';

export function useSubjectTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: SubjectType.LEGAL_ENTITY, name: 'Právnická osoba' },
    {
      id: SubjectType.LEGAL_ENTITY_WITHOUT_ICO,
      name: 'Právnická osoba bez IČO',
    },
    { id: SubjectType.NATURAL_PERSON, name: 'Fyzická osoba' },
    {
      id: SubjectType.BUSINESS_NATURAL_PERSON,
      name: 'Fyzická osoba podnikající',
    },
    {
      id: SubjectType.FOREIGN_LEGAL_ENTITY,
      name: 'Zahraniční právnická osoba',
    },
    {
      id: SubjectType.FOREIGN_NATURAL_PERSON,
      name: 'Zahraniční fyzická osoba',
    },
  ]);
}

export function useSubjectRegistrationTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: SubjectRegistrationType.LEGAL_ENTITY_REGISTRATION,
      name: 'Právnická osoba',
    },
    {
      id: SubjectRegistrationType.LEGAL_ENTITY_WITHOUT_ICO_REGISTRATION,
      name: 'Právnická osoba bez IČO',
    },
    {
      id: SubjectRegistrationType.NATURAL_PERSON_REGISTRATION,
      name: 'Fyzická osoba',
    },
    {
      id: SubjectRegistrationType.BUSINESS_NATURAL_PERSON_REGISTRATION,
      name: 'Fyzická osoba podnikající',
    },
    {
      id: SubjectRegistrationType.FOREIGN_LEGAL_ENTITY_REGISTRATION,
      name: 'Zahraniční právnická osoba',
    },
    {
      id: SubjectRegistrationType.FOREIGN_NATURAL_PERSON_REGISTRATION,
      name: 'Zahraniční fyzická osoba',
    },
  ]);
}

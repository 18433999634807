/**
 * Empowerment permissions
 */
export enum PermissionEmpowerment {
  /** Přehled zmocnění všech subjektů */
  EMPOWERMENT_LIST_ALL = 'EMPOWERMENT_LIST_ALL',

  /** Přehled zmocnění mých subjektů */
  EMPOWERMENT_LIST_OWN = 'EMPOWERMENT_LIST_OWN',

  /** Detail zmocnění všech subjektů */
  EMPOWERMENT_DETAIL_ALL = 'EMPOWERMENT_DETAIL_ALL',

  /** Detail zmocnění mých subjektů */
  EMPOWERMENT_DETAIL_OWN = 'EMPOWERMENT_DETAIL_OWN',

  /** Vytvoření zmocnění (bez žádosti) */
  EMPOWERMENT_CREATE = 'EMPOWERMENT_CREATE',

  /** Úprava zmocnění */
  EMPOWERMENT_UPDATE_ALL = 'EMPOWERMENT_UPDATE_ALL',

  /** Zneplatnění zmocnění */
  EMPOWERMENT_INVALIDATE_ALL = 'EMPOWERMENT_INVALIDATE_ALL',

  /** Zneplatnění zmocnění všech subjektů */
  EMPOWERMENT_INVALIDATE_OWN = 'EMPOWERMENT_INVALIDATE_OWN',

  /** Obnovení zmocnění mých subjektů */
  EMPOWERMENT_REVALIDATE = 'EMPOWERMENT_REVALIDATE',

  /** Přehled žádostí o zmocnění všech subjektů */
  EMPOWERMENT_REQUEST_LIST_ALL = 'EMPOWERMENT_REQUEST_LIST_ALL',

  /** Přehled žádostí o zmocnění mých subjektů */
  EMPOWERMENT_REQUEST_LIST_OWN = 'EMPOWERMENT_REQUEST_LIST_OWN',

  /** Detail žádosti o zmocnění všech subjektů */
  EMPOWERMENT_REQUEST_DETAIL_ALL = 'EMPOWERMENT_REQUEST_DETAIL_ALL',

  /** Detail žádosti o zmocnění mých subjektů */
  EMPOWERMENT_REQUEST_DETAIL_OWN = 'EMPOWERMENT_REQUEST_DETAIL_OWN',

  /** Vytvoření žádosti o zmocnění za všechny subjekty */
  EMPOWERMENT_REQUEST_CREATE_ALL = 'EMPOWERMENT_REQUEST_CREATE_ALL',

  /** Vytvoření žádosti o zmocnění za své subjekty */
  EMPOWERMENT_REQUEST_CREATE_OWN = 'EMPOWERMENT_REQUEST_CREATE_OWN',

  /** Dokončení žádosti o zmocnění */
  EMPOWERMENT_REQUEST_FINISH = 'EMPOWERMENT_REQUEST_FINISH',
}

/**
 * Ovz Facility permissions
 */
export enum PermissionOvzFacility {
  /** Přehled provozoven OVZ s vazbou na všechny subjekty */
  OVZ_LIST_ALL = 'OVZ_LIST_ALL',

  /** Přehled provozoven OVZ s vazbou na své subjekty */
  OVZ_LIST_OWN = 'OVZ_LIST_OWN',

  /** Autocomplete výber hodnot provozoven OVZ s vazbou na všechny subjekty */
  OVZ_AUTOCOMPLETE_ALL = 'OVZ_AUTOCOMPLETE_ALL',

  /** Autocomplete výber hodnot provozoven OVZ s vazbou na své subjekty */
  OVZ_AUTOCOMPLETE_OWN = 'OVZ_AUTOCOMPLETE_OWN',

  /** Detail provozovny OVZ s vazbou na všechny subjekty */
  OVZ_DETAIL_ALL = 'OVZ_DETAIL_ALL',

  /** Detail provozovny OVZ s vazbou na své subjekty */
  OVZ_DETAIL_OWN = 'OVZ_DETAIL_OWN',

  /** Vytvoření (registrace) provozovny OVZ s vazbou na všechny subjekty */
  OVZ_CREATE_ALL = 'OVZ_CREATE_ALL',

  /** Vytvoření (registrace) provozovny OVZ s vazbou na svůj subjekt */
  OVZ_CREATE_OWN = 'OVZ_CREATE_OWN',

  /** Editace provozovny OVZ s vazbou na všechny subjekty */
  OVZ_UPDATE_ALL = 'OVZ_UPDATE_ALL',

  /** Editace provozovny OVZ s vazbou na své subjekty */
  OVZ_UPDATE_OWN = 'OVZ_UPDATE_OWN',

  /** Zneplatnění provozovny OVZ s vazbou na všechny subjekty */
  OVZ_INVALIDATE_ALL = 'OVZ_INVALIDATE_ALL',

  /** Zneplatnění provozovny OVZ s vazbou na své subjekty */
  OVZ_INVALIDATE_OWN = 'OVZ_INVALIDATE_OWN',

  /** Obnovení provozovny OVZ s vazbou na všechny subjekty */
  OVZ_REVALIDATE_ALL = 'OVZ_REVALIDATE_ALL',

  /** Obnovení provozovny OVZ s vazbou na své subjekty */
  OVZ_REVALIDATE_OWN = 'OVZ_REVALIDATE_OWN',

  /** Změna provozovatele provozoven OVZ bez vytvoření nabídky */
  OVZ_CHANGE_OPERATOR = 'OVZ_CHANGE_OPERATOR',

  /** Přehled nabídek provozoven OVZ s vazbou na všechny subjekty */
  OVZ_REQUEST_LIST_ALL = 'OVZ_REQUEST_LIST_ALL',

  /** Přehledu nabídek provozoven OVZ s vazbou na své subjekty */
  OVZ_REQUEST_LIST_OWN = 'OVZ_REQUEST_LIST_OWN',

  /** Detail nabídky provozovny OVZ s vazbou na všechny subjekty */
  OVZ_REQUEST_DETAIL_ALL = 'OVZ_REQUEST_DETAIL_ALL',

  /** Detail nabídky provozovny OVZ s vazbou na své subjekty */
  OVZ_REQUEST_DETAIL_OWN = 'OVZ_REQUEST_DETAIL_OWN',

  /** Vytvoření nabídky změny provozovatele provozovny OVZ */
  OVZ_REQUEST_CHANGE_OPERATOR_SUBMIT = 'OVZ_REQUEST_CHANGE_OPERATOR_SUBMIT',

  /** Dokončení nabídky změny provozovatele provozovny OVZ s vazbou na všechny subjekty */
  OVZ_REQUEST_CHANGE_OPERATOR_FINISH_ALL = 'OVZ_REQUEST_CHANGE_OPERATOR_FINISH_ALL',

  /** Dokončení nabídky změny provozovatele provozovny OVZ s vazbou na své subjekty */
  OVZ_REQUEST_CHANGE_OPERATOR_FINISH_OWN = 'OVZ_REQUEST_CHANGE_OPERATOR_FINISH_OWN',
}

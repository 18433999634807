import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { SoapExecutionLog } from '../../models';
import { SoapExecutionLogsFields } from './soap-execution-logs-fields';
import { useColumns } from './soap-execution-logs-columns';
import { EvidenceAPI, EntityName } from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';

export function SoapExecutionLogs() {
  const permissions = usePermission({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useDatedEvidence<SoapExecutionLog>({
    identifier: 'SOAP_EXECUTION_LOGS',
    apiProps: {
      url: EvidenceAPI.SOAP_EXECUTIONS,
    },
    tableProps: {
      columns: useColumns(),
      showColumn: (col: string) => {
        if (col === 'UPDATED') {
          return false;
        }
        return true;
      },
      tableName: 'Přehled logů SOAP volání',
      showReportButton: false,
    },
    detailProps: {
      entityName: EntityName.SOAP_EXECUTION_LOG,
      hideGeneralFields: true,
      FieldsComponent: SoapExecutionLogsFields,
      toolbarProps: {
        showBtn: permissions,
        title: 'SOAP volání',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

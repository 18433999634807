import { FormPanel, FormSelect } from '@eas/common-web';
import React from 'react';
import { useRoleRequestTypes } from '../../role-request/role-requests-api';

export function StepTypeContent() {
  const types = useRoleRequestTypes();

  return (
    <FormPanel label="Vyberte typ žádosti">
      <FormSelect
        source={types}
        name="type"
        label="Typ žádosti" // Povinné pole
        required
        valueIsId
      />
    </FormPanel>
  );
}

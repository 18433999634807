import React from 'react';
import { Table } from '@eas/common-web';
import { useHistory } from './history-hook';
import { User } from '../../../../models';

export function History({ profile }: { profile: User }) {
  const { source, columns } = useHistory(profile);

  return (
    <Table
      tableName="Historie"
      columns={columns}
      source={source}
      height={300}
      showBulkActionButton={false}
      showSelectBox={false}
      showSearchbar={false}
      showFilterButton={false}
      showReportButton={false}
      showResetSortsButton={false}
    />
  );
}

/**
 * System permissions
 */
export enum PermissionSystem {
  /** Přehled AIS */
  SYSTEM_LIST = 'SYSTEM_LIST',

  /** Autocomplete výběr AIS */
  SYSTEM_AUTOCOMPLETE = 'SYSTEM_AUTOCOMPLETE',

  /** Detail AIS */
  SYSTEM_DETAIL = 'SYSTEM_DETAIL',

  /** Vytvoření AIS */
  SYSTEM_CREATE = 'SYSTEM_CREATE',

  /** Editace AIS */
  SYSTEM_UPDATE = 'SYSTEM_UPDATE',

  /** Zneplatnění AIS */
  SYSTEM_INVALIDATE = 'SYSTEM_INVALIDATE',

  /** Napojení AIS */
  SYSTEM_ASSIGN = 'SYSTEM_ASSIGN',

  /** Aktivace certifikátu */
  SYSTEM_CERTIFICATE_REVALIDATE = 'SYSTEM_CERTIFICATE_REVALIDATE',

  /** Deaktivace certifikátu */
  SYSTEM_CERTIFICATE_INVALIDATE = 'SYSTEM_CERTIFICATE_INVALIDATE',

  /** Přidání certifikátu */
  SYSTEM_CERTIFICATE_CREATE = 'SYSTEM_CERTIFICATE_CREATE',

  /** Odstranění certifikátu */
  SYSTEM_CERTIFICATE_DELETE = 'SYSTEM_CERTIFICATE_DELETE',
}

import { useMemo } from 'react';

import { StepRoleLabel } from './step-role/step-role-label';
import { StepRoleContent } from './step-role/step-role-content';
import { stepRoleSchema } from './step-role/step-role-schema';
import { StepRoleActions } from './step-role/step-role-actions';

import { StepAuthorLabel } from './step-author/step-author-label';
import { StepAuthorContent } from './step-author/step-author-content';
import { stepAuthorSchema } from './step-author/step-author-schema';

import { StepOtherSubjectLabel } from './step-other-subject/step-other-subject-label';
import { StepOtherSubjectContent } from './step-other-subject/step-other-subject-content';
import { stepOtherSubjectSchema } from './step-other-subject/step-other-subject-schema';
import { StepOtherSubjectActions } from './step-other-subject/step-other-subject-actions';

import { StepEmpowermentLabel } from './step-empowerment/step-empowerment-label';
import { StepEmpowermentContent } from './step-empowerment/step-empowerment-content';
import { stepEmpowermentSchema } from './step-empowerment/step-empowerment-schema';

import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';

import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepFinishContent } from './step-finish/step-finish-content';
import { StepFinishActions } from './step-finish/step-finish-actions';
import { AuthorRole } from '../../models';

export function useEmpowermentRequests() {
  const steps = useMemo(
    () => [
      {
        Label: StepRoleLabel,
        Content: StepRoleContent,
        validationSchema: stepRoleSchema,
        initialValues: {
          authorRole: AuthorRole.AGENT,
        },
        Actions: StepRoleActions,
      },
      {
        Label: StepAuthorLabel,
        Content: StepAuthorContent,
        validationSchema: stepAuthorSchema,
      },
      {
        Label: StepOtherSubjectLabel,
        Content: StepOtherSubjectContent,
        validationSchema: stepOtherSubjectSchema,
        Actions: StepOtherSubjectActions,
      },
      {
        Label: StepEmpowermentLabel,
        Content: StepEmpowermentContent,
        validationSchema: stepEmpowermentSchema,
        initialValues: { subForms: [{}] },
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: StepFinishActions,
      },
    ],
    []
  );

  return { steps };
}

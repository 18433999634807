import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useEventCallback } from '@eas/common-web';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(() =>
  createStyles({
    labelPlacementStart: {
      marginLeft: '0px',
    },
  })
);

interface PublicCheckboxProps {
  label: React.ReactNode;
  name: string;
  onChange: (value: boolean | null) => void;
  value?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export function publicCheckboxFactory(label: string, required = false) {
  return function PublicCheckbox({
    name,
    value,
    onChange,
    disabled,
    error,
    helperText,
  }: PublicCheckboxProps) {
    const classes = useStyles();

    const handleChange = useEventCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
      }
    );

    const handleClick = useEventCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
      }
    );

    return (
      <FormControl required={required} error={error} component="fieldset">
        <FormControlLabel
          className={classes.labelPlacementStart}
          label={label}
          labelPlacement="start"
          control={
            <Checkbox
              name={name}
              color="primary"
              disableRipple={true}
              checked={value}
              disabled={disabled}
              onClick={handleClick}
              onChange={handleChange}
            />
          }
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  };
}

import * as Yup from 'yup';
import { StepInfoForm } from './step-info-types';
import { System } from '../../../models';

export const stepInfoSchema = () =>
  Yup.object<StepInfoForm>().shape({
    reason: Yup.string().nullable().required('Povinné pole'),
    requestedRoles: Yup.array().nullable().required('Povinné pole'),
    system: Yup.mixed<System>().nullable().required('Povinné pole'),
  });

import { TableColumn, TableCells, ApiFilterOperation } from '@eas/common-web';
import { WaterManagementDecisionPart } from '../../models';

export function useColumns(): TableColumn<WaterManagementDecisionPart>[] {
  return [
    {
      datakey: 'referenceNumber',
      name: 'Číslo jednací',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'issuedDate',
      name: 'Datum vydání',
      width: 200,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'issuedBy',
      name: 'Vydal',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

import { isEqualWith, isEqual } from 'lodash';
import { ProductionSite } from '../../models';

function customizer(
  objValue: ProductionSite | undefined,
  othValue: ProductionSite | undefined
) {
  if (objValue?.inspireId !== othValue?.inspireId) {
    return false;
  }

  if (objValue?.uniqueCode !== othValue?.uniqueCode) {
    return false;
  }

  if (objValue?.name !== othValue?.name) {
    return false;
  }

  if (objValue?.note !== othValue?.note) {
    return false;
  }

  if (objValue?.district?.id !== othValue?.district?.id) {
    return false;
  }

  if (!isEqual(objValue?.wgs84Coordinates, othValue?.wgs84Coordinates)) {
    return false;
  }

  return true;
}

export function isProductionSiteEqual(
  objValue: Partial<ProductionSite> | undefined,
  othValue: Partial<ProductionSite> | undefined
) {
  return isEqualWith(objValue, othValue, customizer);
}

import { makeStyles } from '@material-ui/core/styles';
import React, { forwardRef, Ref, useContext, useMemo } from 'react';
import {
  TableFieldRowProps,
  TableFieldContext,
  TableFieldCells,
  FormCustomField,
  useEventCallback,
} from '@eas/common-web';
import { get } from 'lodash';
import { SubjectUserDto } from '../subjects-types';
import Radio from '@material-ui/core/Radio/Radio';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  rowWrapper: {
    cursor: 'pointer',
    background: '#e0e2e3',
    '&:hover': {
      backgroundColor: theme.palette.highlight,
    },
  },
  row: {
    boxSizing: 'border-box',
    minWidth: '100%',
    minHeight: 30,
    padding: '2px 16px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  rowDetails: {
    width: '100%',
    padding: '2px 10px',
    borderBottom: '1px solid #cdcdcd',
  },
  tableRowActions: {
    flexShrink: 0,
    width: 35,
    height: 20,
    verticalAlign: 'top',
    display: 'inline-block',
    padding: 0,
    overflow: 'hidden',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  cellWrapper: {
    flexShrink: 0,
    display: 'flex',
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '11px',
    fontWeight: 600,
    marginRight: 20,
    marginLeft: 140,
  },
  content: {
    display: 'flex',
  },
  icon: {
    float: 'left',
    marginTop: -4,
    marginRight: 10,
  },
  radioButton: {
    padding: 0,
  },
}));

export const UsersTableFieldRow = forwardRef(function UsersTableFieldRow(
  { index, value, selected }: TableFieldRowProps<SubjectUserDto>,
  ref: Ref<HTMLDivElement>
) {
  const classes = useStyles();
  const { filteredColumns, onSelect, selectRow } = useContext<
    TableFieldContext<SubjectUserDto>
  >(TableFieldContext);

  const handleSelectClick = useEventCallback(() => {
    onSelect?.(value, index);
    selectRow(index);
  });

  const hasActiveRole = useMemo(() => {
    return !!value.relationshipRoleInstances?.find(
      (roleInstance) => roleInstance.active
    );
  }, [value]);

  return (
    <div ref={ref} onClick={handleSelectClick} className={classes.rowWrapper}>
      <div className={classes.row}>
        <div className={classes.tableRowActions}>
          <Radio
            className={classes.radioButton}
            checked={selected}
            onChange={handleSelectClick}
            color="primary"
          />
        </div>
        {filteredColumns.map((column, i) => {
          const { CellComponent = TableFieldCells.TextCell } = column;
          return (
            <div
              key={i}
              className={classes.cellWrapper}
              style={{
                width: column.width,
                color: hasActiveRole ? 'inherit' : 'gray',
              }}
            >
              <CellComponent
                index={index}
                value={get(value, column.datakey, '')}
                rowValue={value}
                column={column as any}
              />
            </div>
          );
        })}
      </div>
      <div className={classes.rowDetails}>
        <FormCustomField
          layoutOptions={{ noUnderline: true }}
          labelOptions={{ hide: true }}
        >
          <Typography
            variant="body1"
            className={classes.content}
            component="span"
            style={{ color: hasActiveRole ? 'inherit' : 'gray' }}
          >
            <ul>
              <span className={classes.label}>
                <SubdirectoryArrowRightIcon
                  fontSize="small"
                  className={classes.icon}
                />
                Přístup k systému:{' '}
                {value?.systems?.map((s) => s.name).join(', ')}
              </span>
            </ul>
          </Typography>
        </FormCustomField>
      </div>
    </div>
  );
});

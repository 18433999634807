import {
  useEventCallback,
  NavigationContext,
  DetailContext,
  EvidenceStateAction,
  UserContext,
} from '@eas/common-web';
import { useContext } from 'react';
import { EvidenceUrl, Permission } from '../../../enums';
import { IrzChangeRequestStateAction } from '../../irz-facility-request-change/irz-facility-request-change-types';
import { IrzSplitStateAction } from '../../irz-facility-split/irz-facility-split-types';
import { IrzMergeStateAction } from '../../irz-facility-merge/irz-facility-merge-types';

export function useRedirect() {
  /**
   * Context stuff.
   */
  const { navigate } = useContext(NavigationContext);
  const { source } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Callback to redirect into IRZ registration detail.
   */
  const handleRedirect = useEventCallback(() => {
    navigate(EvidenceUrl.IRZ_FACILITY_REQUESTS, false, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: source.data?.facilityRegistration?.id,
    });
  });

  const showRedirectButton = !!source.data?.facilityRegistration;

  /**
   * Callback to redirect into new registration.
   */
  const handleNewRegistration = useEventCallback(() => {
    navigate(EvidenceUrl.IRZ_FACILITY_REQUEST_NEW);
  });

  const showNewRegistrationButton = hasPermission(
    Permission.IrzFacility.IRZ_REQUEST_CREATE_SUBMIT,
    {
      searchInSubjectPerms: true,
    }
  );

  /**
   * Callback to redirect into change request.
   */
  const handleChangeRequest = useEventCallback(() => {
    navigate(EvidenceUrl.IRZ_FACILITY_REQUEST_CHANGE, false, {
      action: IrzChangeRequestStateAction.INIT_IRZ,
      data: source.data.id,
    });
  });

  const showChangeRequestButton =
    hasPermission(Permission.IrzFacility.IRZ_REQUEST_UPDATE_SUBMIT, {
      subjectId: source.data?.operator?.id,
    }) && source.data?.active;

  /**
   * Callback to redirect into merge process.
   */
  const handleMerge = useEventCallback(() => {
    navigate(EvidenceUrl.IRZ_FACILITY_MERGE, false, {
      action: IrzMergeStateAction.INIT_MAIN_IRZ,
      data: source.data.id,
    });
  });

  const showMergeButton =
    hasPermission(Permission.IrzFacility.IRZ_MERGE) && source.data?.active;

  /**
   * Callback to redirect into evidence of IRZ requests.
   */
  const handleAllRequests = useEventCallback(() => {
    navigate(EvidenceUrl.IRZ_FACILITY_REQUESTS);
  });

  /**
   * Callback to redirect into split process.
   */
  const handleSplit = useEventCallback(() => {
    navigate(EvidenceUrl.IRZ_FACILITY_SPLIT, false, {
      action: IrzSplitStateAction.INIT_MAIN_IRZ,
      data: source.data.id,
    });
  });

  const showSplitButton =
    hasPermission(Permission.IrzFacility.IRZ_SPLIT) && source?.data?.active;

  return {
    handleRedirect,
    showRedirectButton,
    handleNewRegistration,
    showNewRegistrationButton,
    handleChangeRequest,
    showChangeRequestButton,
    handleAllRequests,
    handleSplit,
    showSplitButton,
    handleMerge,
    showMergeButton,
  };
}

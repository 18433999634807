import React, { useContext, useMemo } from 'react';
import { useEventCallback, ApiFilterOperation, Filter } from '@eas/common-web';
import { EvidenceAPI } from '../../../../enums';
import { Address } from '../../../../models';
import { AddressFieldCell } from '../address-field-cell';
import { AddressFieldContext } from '../address-field-context';
import { AddressDefaultsContext } from '../address-field-defaults-context';

export function MunicipalityCell() {
  const { code } = useContext(AddressFieldContext);
  const defaults = useContext(AddressDefaultsContext);

  const createFilter = useEventCallback((address?: Address) => {
    const filter: Filter[] = [];

    if (address?.districtRuian?.id) {
      filter.push({
        field: 'district.id',
        operation: ApiFilterOperation.EQ,
        value: address?.districtRuian?.id,
      });
    }
    if (address?.regionRuian?.id) {
      filter.push({
        field: 'region.id',
        operation: ApiFilterOperation.EQ,
        value: address?.regionRuian?.id,
      });
    }

    return filter;
  });

  const filterDeps = useEventCallback((address?: Address) => [
    address?.districtRuian?.id,
    address?.regionRuian?.id,
  ]);

  const descendantNames = useMemo(
    () =>
      [
        'municipalityPartRuian',
        'cityDistrictRuian',
        'streetRuian',
        'addressPlaceRuian',
        'houseNumber',
        'houseNumberType',
        'orientationNumber',
        'orientationNumberMark',
      ] as const,
    []
  );

  return (
    <AddressFieldCell
      label={defaults?.municipality?.label ?? 'Obec'}
      required={defaults?.municipality?.required ?? true}
      name="municipalityRuian"
      customName="municipality"
      parentName="districtRuian"
      descendantNames={descendantNames}
      code={`${code}_MUNICIPALITY`}
      url={EvidenceAPI.ADDRESS_MUNICIPALITY}
      parentUrl={EvidenceAPI.ADDRESS_DISCTRICT}
      createFilter={createFilter}
      filterDeps={filterDeps}
    />
  );
}

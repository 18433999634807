import { useRef } from 'react';
import {
  useEventCallback,
  AbortableFetch,
  abortableFetch,
} from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';

/**
 * Api call
 *
 * @param id
 */
function callApi(id: string) {
  return abortableFetch(`${EvidenceAPI.NOTIFICATION_RECEIVED}/${id}/unread`, {
    method: 'PUT',
  });
}

export function useUnread({ refresh }: { refresh: () => void }) {
  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleUnread = useEventCallback(async (id: string) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(id);

      await fetch.current.raw();

      refresh();
    } catch (err) {
      if (err.name !== 'AbortError') {
        throw err;
      }
      return undefined;
    }
  });

  return { handleUnread };
}

import React from 'react';
import {
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export default function DictionaryRuianCityDistrictFields() {
  const prefix = 'RUIAN_CITY_DISTRICT';

  return (
    <>
      <FormPanel label="Městský obvod/městská část územně členěného statutárního města (MOMC)">
        <FormTextField
          name="ruianOrder"
          label={
            <HelpLabel label="Pořadí" code={`DICT_${prefix}_RUIAN_ORDER`} />
          }
        />
      </FormPanel>
      <FormPanel label="Městský obvod v hlavním městě Praze">
        <FormTextField
          name="cityDistrictPrague.name"
          label={
            <HelpLabel
              label="Název"
              code={`DICT_${prefix}_CITY_DISTRIC_PRAGUE_NAME`}
            />
          }
        />
        <FormTextField
          name="cityDistrictPrague.isuiCode"
          label={
            <HelpLabel
              label="Kód v ISÚI"
              code={`DICT_${prefix}_CITY_DISTRIC_PRAGUE_CODE`}
            />
          }
        />
        <FormCheckbox
          name="cityDistrictPrague.active"
          label={
            <HelpLabel
              label="Aktivní"
              code={`DICT_${prefix}_CITY_DISTRIC_PRAGUE_ACTIVE`}
            />
          }
        />
        <FormDateTimeField
          name="cityDistrictPrague.created"
          label={
            <HelpLabel
              label="Vytvoření"
              code={`DICT_${prefix}_CITY_DISTRIC_PRAGUE_CREATED`}
            />
          }
        />
      </FormPanel>
      <FormPanel label="Správní obvod v hlavním městě Praze">
        <FormTextField
          name="administrativeDistrictPrague.name"
          label={
            <HelpLabel
              label="Název"
              code={`DICT_${prefix}_ADMINISTRATIVE_DISTRIC_PRAGUE_NAME`}
            />
          }
        />
        <FormTextField
          name="administrativeDistrictPrague.isuiCode"
          label={
            <HelpLabel
              label="Kód v ISÚI"
              code={`DICT_${prefix}_ADMINISTRATIVE_DISTRIC_PRAGUE_CODE`}
            />
          }
        />
        <FormCheckbox
          name="administrativeDistrictPrague.active"
          label={
            <HelpLabel
              label="Aktivní"
              code={`DICT_${prefix}_ADMINISTRATIVE_DISTRIC_PRAGUE_ACTIVE`}
            />
          }
        />
        <FormDateTimeField
          name="administrativeDistrictPrague.created"
          label={
            <HelpLabel
              label="Vytvoření"
              code={`DICT_${prefix}_ADMINISTRATIVE_DISTRIC_PRAGUE_CREATED`}
            />
          }
        />
      </FormPanel>
    </>
  );
}

import React from 'react';
import {
  FormPanel,
  FormAutocomplete,
  useAutocompleteSource,
  ApiFilterOperation,
} from '@eas/common-web';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { IrzFacilityAutocomplete, IrzFacility } from '../../../models';
import { EvidenceAPI } from '../../../enums';
import { ProcessForm } from '../irz-facility-merge-types';
import { HelpLabel } from '../../../components/help/help-label';

/**
 * Data source for Merging Irz facilities, that excludes currently selected
 * mainFacility from results and orders the results with by distance to mainFacility
 */
function useIrzFacilities(mainFacility?: IrzFacility) {
  return useAutocompleteSource<IrzFacilityAutocomplete>({
    url: `${EvidenceAPI.IRZ_FACILITIES}/autocomplete`,
    params: {
      filters: [
        {
          operation: ApiFilterOperation.NOT,
          filters: [
            {
              operation: ApiFilterOperation.EQ,
              field: 'id',
              value: mainFacility?.id,
            },
          ],
        },
      ],
      sort: [
        {
          type: 'GEO_DISTANCE',
          field: 'wgs84Point',
          order: 'ASC',
          points: [{ ...mainFacility?.wgs84Coordinates }],
          sortMode: 'MIN',
        },
      ],
    },
  });
}

export function StepMainFacilityContent({
  allValues,
}: {
  allValues: ProcessForm;
}) {
  const irzFacilities = useIrzFacilities(allValues.mergingFacility);

  return (
    <FormPanel label="Vyplňte">
      <FormAutocomplete
        name="mainFacility"
        label={
          <HelpLabel
            label="Provozovna"
            code="IRZ_FACILITY_MERGE_MAIN_FACILITY"
          />
        } // Provozovna, se kterou bude dále zvolená provozovna sloučena
        source={irzFacilities}
        labelMapper={dictionaryLabelMapper}
        tooltipMapper={dictionaryLabelMapper}
      />
    </FormPanel>
  );
}

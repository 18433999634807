/**
 * OZO permissions
 */
export enum PermissionOzo {
  /** Editace typu kotlů (číselník) */
  OZO_BOILER_TYPE_UPDATE = 'OZO_BOILER_TYPE_UPDATE',

  /** Detail značky kotlů */
  OZO_BRAND_DETAIL = 'OZO_BRAND_DETAIL',

  /** Přehled značek kotlů s vazbou na všechny subjekty */
  OZO_BRAND_LIST_ALL = 'OZO_BRAND_LIST_ALL',

  /** Přehled značek kotlů s vazbou na své subjekty */
  OZO_BRAND_LIST_OWN = 'OZO_BRAND_LIST_OWN',

  /** Editace značky kotlů */
  OZO_BRAND_UPDATE = 'OZO_BRAND_UPDATE',

  /** Detail výrobce kotlů s vazbou na všechny subjekty */
  OZO_MANUFACTURER_DETAIL_ALL = 'OZO_MANUFACTURER_DETAIL_ALL',

  /** Detail výrobce kotlů s vazbou na své subjekty */
  OZO_MANUFACTURER_DETAIL_OWN = 'OZO_MANUFACTURER_DETAIL_OWN',

  /** Přehled výrobců kotlů s vazbou na všechny subjekty */
  OZO_MANUFACTURER_LIST_ALL = 'OZO_MANUFACTURER_LIST_ALL',

  /** Přehled výrobců kotlů s vazbou na své subjekty */
  OZO_MANUFACTURER_LIST_OWN = 'OZO_MANUFACTURER_LIST_OWN',

  /** Editace výrobce kotlů */
  OZO_MANUFACTURER_UPDATE = 'OZO_MANUFACTURER_UPDATE',

  /** Detail modelu kotlů */
  OZO_MODEL_DETAIL = 'OZO_MODEL_DETAIL',

  /** Přehled modelů kotlů s vazbou na všechny subjekty */
  OZO_MODEL_LIST_ALL = 'OZO_MODEL_LIST_ALL',

  /** Přehled modelů kotlů s vazbou na své subjekty */
  OZO_MODEL_LIST_OWN = 'OZO_MODEL_LIST_OWN',

  /** Editace modelu kotlů */
  OZO_MODEL_UPDATE = 'OZO_MODEL_UPDATE',

  /** Přiřazení agendy přes get-or-create-ozo-subject */
  OZO_PERMISSION_AGENDA_ASSIGN = 'OZO_PERMISSION_AGENDA_ASSIGN',

  OZO_PERMISSION_AGENDA_BULK_ASSIGN = 'OZO_PERMISSION_AGENDA_BULK_ASSIGN',

  /** Detail OZO oprávnění s vazbou na všechny subjekty */
  OZO_PERMISSION_DETAIL_ALL = 'OZO_PERMISSION_DETAIL_ALL',

  /** Detail OZO oprávnění s vazbou na své subjekty */
  OZO_PERMISSION_DETAIL_OWN = 'OZO_PERMISSION_DETAIL_OWN',

  /** Přehled OZO oprávnění s vazbou na všechny subjekty */
  OZO_PERMISSION_LIST_ALL = 'OZO_PERMISSION_LIST_ALL',

  /** Přehled OZO oprávnění s vazbou na své subjekty */
  OZO_PERMISSION_LIST_OWN = 'OZO_PERMISSION_LIST_OWN',

  /** Editace OZO oprávnění s vazbou na všechny subjekty */
  OZO_PERMISSION_UPDATE_ALL = 'OZO_PERMISSION_UPDATE_ALL',

  /** Editace OZO oprávnění s vazbou na své subjekty */
  OZO_PERMISSION_UPDATE_OWN = 'OZO_PERMISSION_UPDATE_OWN',

  /** Detail vlastních OZO oprávnění */
  OZO_PERSONAL_PERMISSION_DETAIL_OWN = 'OZO_PERSONAL_PERMISSION_DETAIL_OWN',

  OZO_PERSONAL_PERMISSION_LIST_OWN = 'OZO_PERSONAL_PERMISSION_LIST_OWN',
}

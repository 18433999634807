import React from 'react';
import { Process } from '../../components/process/process';
import { useSubjectRequests } from './subject-requests-hook';
import { ProcessForm } from './subject-requests-types';
import { TranslationKeys } from '../../enums';

export function SubjectRequestsNew() {
  const { steps } = useSubjectRequests();

  return (
    <Process<ProcessForm>
      steps={steps}
      title="Registrace subjektu"
      translationKey={TranslationKeys.SUBJECT_REGISTRATION}
    />
  );
}

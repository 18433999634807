import { AuthoredObject } from './authored-object';
import { Subject } from './subject';
import { JtskCoordinates } from './coordinate';
import { DictionaryWaterOrigin } from './dict-water-origin';
import { DictionaryWaterTreatmentMethod } from './dict-water-treatment-method';
import { DictionaryWaterFlow } from './dict-water-flow';
import { DictionaryRiverSide } from './dict-river-side';
import { DictionarySewerType } from './dict-sewer-type';
import { Municipality } from './municipality';
import { CadastralTerritory } from './cadastral-territory';
import { DictionaryHydrogeologicalZone } from './dict-hydrogeological-zone';
import { DictionaryNaceCz } from './dict-nace-cz';
import { WaterManagementDecisionPart } from '.';

/**
 * Typ místa užívání vody
 */
export enum WaterUsagePlacesType {
  /**
   * Odběr podzemní vody
   */
  GROUNDWATER_ABSTRACTION = 'GROUNDWATER_ABSTRACTION',

  /**
   * Odběr povrchové vody
   */
  SURFACE_WATER_ABSTRACTION = 'SURFACE_WATER_ABSTRACTION',

  /**
   * Vypouštění do toku
   */
  STREAM_DISCHARGE = 'STREAM_DISCHARGE',

  /**
   * Vypouštění do podzemí
   */
  UNDERGROUND_DISCHARGE = 'UNDERGROUND_DISCHARGE',

  /**
   * Akumulace vody
   */
  WATER_ACCUMULATION = 'WATER_ACCUMULATION',
}

/**
 * Způsob zachycení vody
 */
export interface WaterCaptureMethod {
  /**
   * Pramenní jímka
   */
  springSump?: boolean;

  /**
   * Pramenní jímka - počet
   */
  springSumpCount?: number;

  /**
   * Jímací štola
   */
  waterSupplyTunnel?: boolean;

  /**
   * Jímací štola - počet
   */
  waterSupplyTunnelCount?: number;

  /**
   * Studna
   */
  well?: boolean;

  /**
   * Studna - počet
   */
  wellCount?: number;

  /**
   * Vrt
   */
  borehole?: boolean;

  /**
   * Vrt - počet
   */
  boreholeCount?: number;
}

/**
 * Hydrologická data
 */
export interface HydrologicalData {
  /**
   * Q a - dlouhodobý průměrný průtok
   */
  qa?: number;

  /**
   * MQ - minimální průtok pod vodním dílem
   */
  mq?: number;

  /**
   * MZP - minimální zůstatkový průtok (jednotky m3/s)
   */
  mzp?: number;
}

/**
 * Prostor akumulace vody
 */
export interface WaterAccumulationArea {
  /**
   * Kóta hladiny
   */
  waterAltitude?: number;

  /**
   * Objem
   */
  volume?: number;

  /**
   * Zatopená plocha
   */
  floodedArea?: number;
}

export interface BasinEnterpriseIdentifier {
  /**
   * Podnik povodí
   */
  enterprise?: string;

  /**
   * Identifikátor
   */
  id?: string;
}

/**
 * Povinný subjekt
 */
export interface ObligatorySubject {
  /**
   * IČO
   */
  ico?: string;

  /**
   * Název firmy
   */
  companyName?: string;

  /**
   * Název pobočky
   */
  branchName?: string;

  /**
   * Sídlo
   */
  address?: string;
}

/**
 * Místo užívání vody (MUV)
 */
export interface WaterUsagePlace extends AuthoredObject {
  /**
   * Identifikátor v systému Podniků povodí
   */
  ppId?: BasinEnterpriseIdentifier;

  /**
   * Číslo VHB
   */
  vhbNumber?: number;

  /**
   * Název místa
   */
  name?: string;

  /**
   * Povinný subjekt
   */
  obligatorySubject?: ObligatorySubject;

  /**
   * Provozovatel
   */
  operator?: Subject;

  /**
   * Katastrální území
   */
  cadastralTerritory?: CadastralTerritory;

  /**
   * Obec
   */
  municipality?: Municipality;

  /**
   * Klasifikace ekonomických činností (NACE)
   */
  czNace?: DictionaryNaceCz;

  /**
   * Tok
   */
  waterFlow?: DictionaryWaterFlow;

  /**
   * ř.km (říční kilometr)
   */
  riverKm?: number;

  /**
   * Břeh
   */
  riverSide?: DictionaryRiverSide;

  /**
   * Poloha S-JTSK
   */
  jtskCoordinates?: JtskCoordinates;

  /**
   * Poloha WGS-84
   */
  wgs84Coordinates?: {
    lat?: number | string;
    lon?: number | string;
  };

  /**
   * Požadovat VH povolení
   */
  requireVhPermission?: false;

  /**
   * Požadovat VH povolení k ochranným pásmům
   */
  requireVhProtectionZonePermission?: false;

  /**
   * Požadovat mapu
   */
  requireMap?: false;

  /**
   * Číslo hydrologického pořadí
   */
  hydrologicalSequenceNumber?: string;

  /**
   * Typ místa užívání vody
   */
  type?: WaterUsagePlacesType;

  /**
   * Příznak platnosti
   */
  active?: boolean;

  /**
   * Datum platnosti
   */
  validTo?: string;

  /**
   * Části rozhodnutí MUV
   */
  waterManagementDecisionParts?: WaterManagementDecisionPart[];

  /**
   * ID v systému ISPOP
   */
  ispopId?: string;
}

/**
 * Odběr podzemní vody
 */
export interface GroundwaterAbstraction extends WaterUsagePlace {
  /**
   * Název jímacího zařízení
   */
  abstractionDeviceName?: string;

  /**
   * Hydrogeologický rajón
   */
  hydrogeologicalZone?: DictionaryHydrogeologicalZone;

  /**
   * Kapacita jímacích zařízení
   */
  abstractionDevicesCapacity?: number;

  /**
   * Způsob zachycení vody
   */
  captureMethod?: WaterCaptureMethod;

  /**
   * Původ odebrané vody
   */
  abstractedWaterOrigin?: DictionaryWaterOrigin;

  /**
   * Způsob úpravy vody
   */
  waterTreatment?: DictionaryWaterTreatmentMethod;

  /**
   * Typ místa užívání vody
   */
  type?: WaterUsagePlacesType.GROUNDWATER_ABSTRACTION;
}

/**
 * Odběr povrchové vody
 */
export interface SurfaceWaterAbstraction extends WaterUsagePlace {
  /**
   * Místo odběru
   */
  abstractionPlace?: string;

  /**
   * Hydrogeologický rajón
   */
  hydrogeologicalZone?: DictionaryHydrogeologicalZone;

  /**
   * Kóta odběrného zařízení
   */
  abstractionDeviceAltitude?: number;

  /**
   * Typ místa užívání vody
   */
  type?: WaterUsagePlacesType.SURFACE_WATER_ABSTRACTION;
}

/**
 * Vypouštění do toku
 */
export interface StreamDischarge extends WaterUsagePlace {
  /**
   * Zdroj vypouštění
   */
  dischargeSource?: string;

  /**
   * Hydrogeologický rajón
   */
  hydrogeologicalZone?: DictionaryHydrogeologicalZone;

  /**
   * Čistírna odpadových vod
   */
  waterTreatmentPlant?: boolean;

  /**
   * Chemické čištění odpadových vod
   */
  chemicalTreatment?: boolean;

  /**
   * Mechanické čištění odpadových vod
   */
  mechanicalTreatment?: boolean;

  /**
   * Biologické čištění odpadových vod
   */
  biologicalTreatment?: boolean;

  /**
   * Typ kanalizace
   */
  sewerType?: DictionarySewerType;

  /**
   * Typ místa užívání vody
   */
  type?: WaterUsagePlacesType.STREAM_DISCHARGE;
}

/**
 * Akumulace vody
 */
export interface WaterAccumulation extends WaterUsagePlace {
  /**
   * Hydrologická data
   */
  hydrologicalData?: HydrologicalData;

  /**
   * Stálý prostor
   */
  permanentArea?: WaterAccumulationArea;

  /**
   * Zásobní prostor
   */
  stockArea?: WaterAccumulationArea;

  /**
   * Celkový ovládatelný prostor
   */
  totalArea?: WaterAccumulationArea;

  /**
   * Typ místa užívání vody
   */
  type?: WaterUsagePlacesType.WATER_ACCUMULATION;
}

/**
 * Vypouštění do podzemí
 */
export interface UndergroundDischarge extends WaterUsagePlace {
  /**
   * Zdroj vypouštění
   */
  dischargeSource?: string;

  /**
   * Hydrogeologický rajón
   */
  hydrogeologicalZone?: DictionaryHydrogeologicalZone;

  /**
   * Typ místa užívání vody
   */
  type?: WaterUsagePlacesType.UNDERGROUND_DISCHARGE;
}

export type WaterUsagePlaceUnion = GroundwaterAbstraction &
  SurfaceWaterAbstraction &
  StreamDischarge &
  WaterAccumulation &
  UndergroundDischarge;

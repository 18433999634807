import React from 'react';
import {
  FormPanel,
  FormSelect,
  FormTextField,
  FormDateTimeField,
  FormEditor,
  FormInlineTableField,
  FormCheckbox,
} from '@eas/common-web';
import { useStates } from './mail-campaigns-api';
import { useRecipientsColumns } from './mail-campaigns-columns';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { HelpLabel } from '../../components/help/help-label';

export function MailCampaignsFields(props: NotedEvidenceFieldsProps) {
  const states = useStates();

  const recipientColumns = useRecipientsColumns('recipients');
  const recipientsNoConsentColumns = useRecipientsColumns(
    'recipientsNoConsent'
  );
  const recipientBadColumns = useRecipientsColumns('recipientsBad');
  const recipientFailedColumns = useRecipientsColumns('recipientsFailed');
  const recipientNotExistColumns = useRecipientsColumns('recipientsNotExist');

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="name"
          label={
            <HelpLabel label="Název" code="MAILING_CAMPAIGN_NAME" required />
          }
        />
        <FormSelect
          name="state"
          label={<HelpLabel label="Stav" code="MAILING_CAMPAIGN_STATE" />}
          source={states}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled
        />
        <FormCheckbox
          name="forced"
          label={<HelpLabel label="Povinný" code="MAILING_CAMPAIGN_FORCED" />} // Email bude zaslán i uživatelům, kteří neudělili souhlas se zasláním emailů
        />
      </FormPanel>
      <FormPanel label="Obsah">
        <FormTextField
          name="emailSubject"
          label={
            <HelpLabel
              label="Předmět emailu"
              code="MAILING_CAMPAIGN_EMAIL_SUBJECT"
              required
            />
          }
        />
        <FormEditor
          labelOptions={{ hide: true }}
          name="emailContent"
          language="html"
        />
      </FormPanel>
      <FormPanel
        label="Příjemci"
        summary={<FormPanelCountByName name="recipients" />}
      >
        <FormInlineTableField<string>
          name="recipients"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={recipientColumns}
          initNewItem={() => ''}
        />
      </FormPanel>
      <FormPanel
        label="Příjemci bez uděleného souhlasu"
        summary={<FormPanelCountByName name="recipientsNoConsent" />}
      >
        <FormInlineTableField<string>
          name="recipientsNoConsent"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={recipientsNoConsentColumns}
          initNewItem={() => ''}
          showToolbar={false}
        />
      </FormPanel>
      <FormPanel
        label="Chyba adresy"
        summary={<FormPanelCountByName name="recipientsBad" />}
      >
        <FormInlineTableField<string>
          name="recipientsBad"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={recipientBadColumns}
          initNewItem={() => ''}
          showToolbar={false}
        />
      </FormPanel>
      <FormPanel
        label="Neexistující adresy"
        summary={<FormPanelCountByName name="recipientsNotExist" />}
      >
        <FormInlineTableField<string>
          name="recipientsNotExist"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={recipientNotExistColumns}
          initNewItem={() => ''}
          showToolbar={false}
        />
      </FormPanel>
      <FormPanel
        label="Chyba doručení"
        summary={<FormPanelCountByName name="recipientsFailed" />}
      >
        <FormInlineTableField<string>
          name="recipientsFailed"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={recipientFailedColumns}
          initNewItem={() => ''}
          showToolbar={false}
          disabled
        />
      </FormPanel>
      <FormPanel label="Odeslání">
        <FormTextField
          name="publisher.username"
          label={
            <HelpLabel
              label="Odesílatel"
              code="MAILING_CAMPAIGN_PUBLISHER_USERNAME"
            />
          }
          disabled
        />
        <FormDateTimeField
          name="startTime"
          label={
            <HelpLabel label="Začátek" code="MAILING_CAMPAIGN_START_TIME" />
          }
          disabled
        />
        <FormDateTimeField
          name="endTime"
          label={<HelpLabel label="Konec" code="MAILING_CAMPAIGN_END_TIME" />}
          disabled
        />
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

import React from 'react';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { TableToolbarButton, TableToolbarButtonAction } from '@eas/common-web';
import { exportZip } from './dict-info-api';

export function DictInfoTableToolbar() {
  return (
    <>
      <TableToolbarButtonAction
        ButtonComponent={TableToolbarButton}
        promptKey="DICT_INFO_EXPORT_ALL"
        apiCall={exportZip}
        buttonLabel={<AllInboxIcon />}
        buttonTooltip="Exportovat všechny hodnoty číselníků"
        dialogTitle="Varování"
        dialogText="Budou vygenerovány exporty všech číselníků. Vytvoření může chvíli trvat. O výsledku budete informováni notifikací. Skutečně chcete pokračovat ?"
      />
    </>
  );
}

import { HistoryFieldDescriptor } from '../../evidence/history-evidence/history-types';
import { useHouseNumberTypes } from './address-field-api';

export const useAddressFieldsFactory = (prefix: string) =>
  [
    {
      key: `${prefix}.country`,
      label: 'Stát',
    },
    {
      key: `${prefix}.regionRuian`,
      label: 'Kraj (RÚIAN)',
    },
    {
      key: `${prefix}.region`,
      label: 'Kraj (vlastní)',
    },
    {
      key: `${prefix}.districtRuian`,
      label: 'Okres (RÚIAN)',
    },
    {
      key: `${prefix}.district`,
      label: 'Okres (vlastní)',
    },
    {
      key: `${prefix}.municipalityRuian`,
      label: 'Město (RÚIAN)',
    },
    {
      key: `${prefix}.municipality`,
      label: 'Město (vlastní)',
    },
    {
      key: `${prefix}.cityDistrictRuian`,
      label: 'Městská část (RÚIAN)',
    },
    {
      key: `${prefix}.cityDistrict`,
      label: 'Městská část (vlastní)',
    },
    {
      key: `${prefix}.streetRuian`,
      label: 'Ulice (RÚIAN)',
    },
    {
      key: `${prefix}.street`,
      label: 'Ulice (vlastní)',
    },
    {
      key: `${prefix}.houseNumber`,
      label: 'Číslo stavebního objektu',
    },
    {
      key: `${prefix}.houseNumberType`,
      label: 'Typ stavebního objektu',
      source: useHouseNumberTypes(),
    },
    {
      key: `${prefix}.orientationNumber`,
      label: 'Číslo orientační',
    },
    {
      key: `${prefix}.orientationNumberMark`,
      label: 'Znak čísla orientačního',
    },
    {
      key: `${prefix}.zip`,
      label: 'PSČ',
    },
    {
      key: `${prefix}.addressPlaceRuian`,
      label: 'Adresní místo (RÚIAN)',
    },
  ] as HistoryFieldDescriptor[];

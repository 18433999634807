import React from 'react';
import { FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export function DictionaryCountryFields() {
  return (
    <>
      <FormTextField
        name="alpha2"
        label={
          <HelpLabel
            label="Kód alpha-2"
            code="DICT_COUNTRIES_ALPHA_2"
            required
          />
        }
      />
      <FormTextField
        name="alpha3"
        label={
          <HelpLabel
            label="Kód alpha-3"
            code="DICT_COUNTRIES_ALPHA_3"
            required
          />
        }
      />
    </>
  );
}

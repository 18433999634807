import React from 'react';
import { forwardRef, useRef } from 'react';
import { Wgs84Coordinates } from '../../../models';
import {
  DialogHandle,
  Dialog,
  useScrollableSource,
  InfiniteListHandle,
  InfiniteList,
} from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';
import { AddressPlace } from '../../../models/address-place';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AddressPlaceListItem } from './address-place-list-item';
import Typography from '@material-ui/core/Typography';

interface FillAddressDialogProps {
  wgsCoordinates: Wgs84Coordinates;
  fillAddress: (addressPlace: AddressPlace) => void;
}

const useStyles = makeStyles({
  contentWrapper: {
    width: '500px',
  },
  loaderWrapper: {
    width: '100%',
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
});

export const FillAddressDialog = forwardRef<
  DialogHandle,
  FillAddressDialogProps
>(function FillAddressDialog(props, ref) {
  return (
    <Dialog ref={ref} title="Vyplnit adresu dle bodu" showConfirm={false}>
      {() => <FillAddressDialogContent {...props} />}
    </Dialog>
  );
});

function FillAddressDialogContent({
  wgsCoordinates,
  fillAddress,
}: FillAddressDialogProps) {
  const classes = useStyles();

  const source = useScrollableSource<AddressPlace>({
    url: `${EvidenceAPI.ADDRESS_PLACE}/autocomplete`,
    params: {
      sort: [
        {
          type: 'GEO_DISTANCE',
          field: 'wgs84Point',
          order: 'ASC',
          points: [{ ...wgsCoordinates }],
          sortMode: 'MIN',
        },
      ],
    },
  });

  const infiniteList = useRef<InfiniteListHandle<AddressPlace>>(null);

  return (
    <div className={classes.contentWrapper}>
      {source.loading && (
        <div className={classes.loaderWrapper}>
          <CircularProgress disableShrink className={classes.loader} />
        </div>
      )}
      <Typography variant="body1">
        Adresní místa jsou seřazeny dle vzdálenosti od bodu na mapě. Vyberte
        adresní místo z kterého chcete načíst adresu.
      </Typography>
      <br />
      <InfiniteList
        ref={infiniteList}
        source={source}
        maxListHeight={450}
        onItemClick={fillAddress}
        labelMapper={(item) => (
          <AddressPlaceListItem
            addressPlace={item}
            searchDistance={wgsCoordinates}
          />
        )}
      />
    </div>
  );
}

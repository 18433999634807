import * as Yup from 'yup';
import {
  MainActivityType,
  ActivityRecord,
  DictionaryEPrtrActivity,
  DictionaryIrzActivity,
} from '../../../models';
import { StepActivitiesForm, ActivitiesData } from './step-activities-types';

export function stepActivitiesSchema() {
  return Yup.object<StepActivitiesForm>().shape({
    activities: Yup.object<ActivitiesData>().shape({
      activityTypes: Yup.array<MainActivityType[]>()
        .default([])
        .nullable()
        .min(1, 'Povinné pole'),
      mainIrzActivity: Yup.mixed<ActivityRecord>().when('activityTypes', {
        is: (type) =>
          type?.includes(MainActivityType.IRZ) &&
          !type?.includes(MainActivityType.E_PRTR),
        then: activityRecordSchema(),
      }),
      mainEprtrActivity: Yup.mixed<ActivityRecord>().when('activityTypes', {
        is: (type) => type?.includes(MainActivityType.E_PRTR),
        then: activityRecordSchema(),
      }),
      secondaryIrzActivities: Yup.mixed<ActivityRecord[]>().when(
        'activityTypes',
        {
          is: (type) => type?.includes(MainActivityType.IRZ),
          then: Yup.array()
            .of(activityRecordTableSchema('activities.secondaryIrzActivities'))
            .default([])
            .test('', '', function () {
              const { createError, parent } = this;
              const allValues: ActivitiesData = parent;

              const mainIrzActivity = allValues.mainIrzActivity;
              const secondaryIrzActivities = allValues.secondaryIrzActivities;

              const containsMainActivity = secondaryIrzActivities?.find(
                (activity) =>
                  activity?.activity?.id === mainIrzActivity?.activity?.id
              );

              if (containsMainActivity) {
                return createError({
                  path: 'activities.secondaryIrzActivities',
                  message: `Nesmí obsahovat hlavní činnost`,
                });
              }

              const isEmpty = secondaryIrzActivities?.length === 0;

              if (!mainIrzActivity && isEmpty) {
                return createError({
                  path: 'activities.secondaryIrzActivities',
                  message: `Musí obsahovat alespoň 1 činnost`,
                });
              }

              return true;
            }),
        }
      ),
      secondaryEprtrActivities: Yup.mixed<ActivityRecord[]>().when(
        'activityTypes',
        {
          is: (type) => type?.includes(MainActivityType.E_PRTR),
          then: Yup.array()
            .of(
              activityRecordTableSchema('activities.secondaryEprtrActivities')
            )
            .default([])
            .test('', '', function () {
              const { createError, parent } = this;
              const allValues: ActivitiesData = parent;

              const mainEprtrActivity = allValues.mainEprtrActivity;
              const secondaryEprtrActivities =
                allValues.secondaryEprtrActivities;

              const isInvalid = secondaryEprtrActivities?.find(
                (activity) =>
                  activity?.activity?.id === mainEprtrActivity?.activity?.id
              );

              if (isInvalid) {
                return createError({
                  path: 'activities.secondaryEprtrActivities',
                  message: `Nesmí obsahovat hlavní činnost`,
                });
              }

              return true;
            }),
        }
      ),
    }),
  });
}

const activityRecordSchema = () =>
  Yup.object<ActivityRecord>({
    activity: Yup.mixed<DictionaryEPrtrActivity | DictionaryIrzActivity>()
      .nullable()
      .required('Činnost musí být vyplněna'),
    facilityCount: Yup.number()
      .typeError(`Počet zařízení je povinné`)
      .min(1, `Počet zařízení musí být alespoň 1`)
      .required(`Počet zařízení je povinné`),
  });

const activityRecordTableSchema = (path: string) =>
  Yup.mixed().test('', '', function (value) {
    const { createError } = this;

    const activityRecord: ActivityRecord = value;

    if (!activityRecord?.activity) {
      return createError({
        path,
        message: 'Činnost musí být vyplněna',
      });
    }

    if (activityRecord?.facilityCount === undefined) {
      return createError({
        path,
        message: 'Počet zařízení je povinné',
      });
    }

    if (activityRecord?.facilityCount < 1) {
      return createError({
        path,
        message: 'Počet zařízení musí být alespoň 1',
      });
    }

    return true;
  });

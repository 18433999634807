import { SpecialFilter } from '../../components/evidence/history-evidence/overrides/table-searchbar';
import { SpecialFilterTextCell } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-text-cell';

export function useSpecialFilters(): SpecialFilter[] {
  return [
    {
      label: 'Uživatelské jméno',
      filterkey: 'username',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Jméno',
      filterkey: 'firstName.value',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Příjmení',
      filterkey: 'lastName.value',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Email',
      filterkey: 'email.value',
      FilterCell: SpecialFilterTextCell,
    },
  ];
}

/**
 * Actions permissions
 */
export enum PermissionAction {
  /** Přehled akce */
  ACTIONS_LIST = 'ACTIONS_LIST',

  /** Detail akce */
  ACTIONS_DETAIL = 'ACTIONS_DETAIL',
}

import {
  useAutocompleteSource,
  useListSource,
  ApiFilterOperation,
} from '@eas/common-web';
import { DictionaryAutocomplete } from '../../../models/dictionary-object';
import { RuianObjectAutocomplete } from '../../../models';
import { EvidenceAPI } from '../../../enums';

export function useHouseNumberTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.ADDRESS_HOUSE_NUMBER + '/type/list',
    method: 'GET',
  });
}

export function useMunicipalities() {
  return useAutocompleteSource<RuianObjectAutocomplete>({
    url: `${EvidenceAPI.ADDRESS_MUNICIPALITY}/autocomplete`,
  });
}

export function useCadastralTerritories() {
  return useAutocompleteSource<RuianObjectAutocomplete>({
    url: `${EvidenceAPI.ADDRESS_CADASTRAL_TERRITORY}/autocomplete`,
  });
}

export function useAddresses(subjectId: string) {
  return useAutocompleteSource<any>({
    url: `${EvidenceAPI.SUBJECTS}/establishment/autocomplete`,
    params: {
      filters: [
        {
          field: 'subject.id',
          operation: ApiFilterOperation.EQ,
          value: subjectId,
        },
      ],
    },
    apiUrl: `${EvidenceAPI.SUBJECTS}/establishment`,
  });
}

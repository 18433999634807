import React, { useContext } from 'react';
import {
  TableColumn,
  ApiFilterOperation,
  TableCells,
  TableFilterCells,
  FileRef,
  TableFieldColumn,
  FilesContext,
  TableFieldCells,
} from '@eas/common-web';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useDirpTypes } from '../dict-dirp-type/dict-dirp-types-api';
import { useDirpAuthors } from '../dict-dirp-author/dict-dirp-authors-api';
import { useDirpLaws } from '../dict-dirp-law/dict-dirp-laws-api';
import { useDirpLawParagraphs } from '../dict-dirp-law-paragraph/dict-dirp-law-paragraphs-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { useDirpAreas } from '../dict-dirp-area/dict-dirp-areas-api';
import { DirpRecord } from '../../models';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

export function useColumns(): TableColumn<DirpRecord>[] {
  return [
    {
      datakey: 'evidenceNumber',
      name: 'Evidenční číslo DIRP',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'referenceNumber',
      name: 'Číslo jednací',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDirpTypes
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'challengedVerdictReferenceNumber',
      name: 'Č. j. napadeného rozhodnutí',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'author',
      sortkey: 'author.name',
      filterkey: 'author.id',
      name: 'Autor',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDirpAuthors
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    // {
    //   datakey: 'authorNote',
    //   name: 'Poznámka autora',
    //   width: 200,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 1,
    // },
    // {
    //   datakey: 'comment',
    //   name: 'Komentář',
    //   width: 200,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 1,
    // },
    {
      datakey: 'areas',
      filterkey: 'areas.id',
      name: 'Oblasti',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDirpAreas
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: false,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'issuedDate',
      name: 'Datum vydání',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.DateCell),
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'laws',
      filterkey: 'laws.id',
      name: 'Dotčené předpisy – Předpis',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDirpLaws
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: false,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'paragraphs',
      filterkey: 'paragraphs.id',
      name: 'Dotčené předpisy – Paragraf',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDirpLawParagraphs
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: false,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'important',
      name: 'Rozhodnutí pro rozhodovací praxi',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'challengedAtCourt',
      name: 'Rozhodnutí napadeno u soudu',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'active',
      name: 'Platný záznam',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

const useStyles = makeStyles(() => ({
  downloadButton: {
    minWidth: '0',
    marginLeft: '2pt',
    boxShadow: '0 0 0',
    padding: '2pt',
    height: 23,
    '& p': {
      // contradict the default 'none'
      display: 'block',
    },
  },
}));

export function useAttachmentsColumns(): TableFieldColumn<FileRef>[] {
  const classes = useStyles();

  const { getFileUrl } = useContext(FilesContext);

  return [
    {
      datakey: 'download',
      name: '',
      width: 42,
      CellComponent: function Cell(props) {
        return (
          <Button
            download={true}
            size="small"
            classes={{ root: classes.downloadButton }}
            variant="text"
            onClick={(e: any) => e.stopPropagation()}
            disabled={!props.rowValue?.id}
            href={getFileUrl(props.rowValue?.id)}
          >
            <GetAppIcon />
          </Button>
        );
      },
    },
    {
      name: 'Datum vytvoření',
      datakey: 'created',
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: 'Název',
      datakey: 'name',
      width: 500,
    },
  ];
}

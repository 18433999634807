import { validateEmailNotRequired } from './../profile/profile-utils';
import * as Yup from 'yup';
import { DictionaryCompetentControlAuthority } from '../../models';

const phoneRules = 'předvolba ( znak "+" a 1-3 číslice ), 8-12 číslic ';

export function useValidationSchema() {
  return Yup.object<DictionaryCompetentControlAuthority>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    email: validateEmailNotRequired('Zadejte platnou emailovou adresu'),
    code: Yup.string().nullable().required('Povinné pole'),
    dataBox: Yup.string()
      .nullable()
      .matches(
        /^[abcdefghijkmnpqrstuvwxyz23456789]{7}$/,
        'Musí obsahovat 7 znaků z písmen malé anglické abecedy (bez znaků "o", "l") a číslic 2-9'
      ),
    phoneNumber: Yup.string()
      .nullable()
      .matches(
        /^(\+\d{1,3})\d{8,12}/,
        `Číslo nesplňuje pravidla: ${phoneRules}`
      ),
    faxNumber: Yup.string()
      .nullable()
      .matches(
        /^(\+\d{1,3})\d{8,12}/,
        `Číslo nesplňuje pravidla: ${phoneRules}`
      ),
  });
}

import {
  BulkAction,
  BulkActionDialogHandle,
  bulkActionDialogFactory,
  EmptyComponent,
} from '@eas/common-web';
import { PersonalData } from '../../../models';
import { EvidenceAPI } from '../../../enums';
import { bulkDeleteApiCall } from './bulk-delete';

export const DeleteDialog = bulkActionDialogFactory<PersonalData>({
  url: EvidenceAPI.PERSONAL_DATA,
  apiCall: bulkDeleteApiCall,
  paramsMapper: (data) => data!,
  title: 'Smazat položky',
  Fields: EmptyComponent,
});

export const bulkActions: BulkAction<BulkActionDialogHandle>[] = [
  {
    action: (handle) => handle.openDialog(),
    label: 'Smazat položky',
    Component: DeleteDialog,
    disableFilteredBulkAction: true,
  },
];

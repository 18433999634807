import React from 'react';
import {
  FormSelect,
  FormTextField,
  FormNumberField,
  FormTextArea,
} from '@eas/common-web';
import {
  useProcessingPurposeTypes,
  useProcessingPurposeDomains,
} from './dict-processing-purposes-api';
import { HelpLabel } from '../../components/help/help-label';

export function DictProcessingPurposeFields() {
  const typesSource = useProcessingPurposeTypes();
  const domainSource = useProcessingPurposeDomains();

  return (
    <>
      <FormSelect
        name="type"
        label={<HelpLabel label="Typ" code="DICT_PROCESSING_PURPOSE_TYPE" />}
        source={typesSource}
        valueIsId
      />
      <FormSelect
        name="domain"
        label={
          <HelpLabel label="Oblast" code="DICT_PROCESSING_PURPOSE_DOMAIN" />
        }
        valueIsId
        source={domainSource}
      />
      <FormTextArea
        name="legalReference"
        label={
          <HelpLabel
            label="Zákonná reference nebo důvod"
            code="DICT_PROCESSING_PURPOSE_LEGAL_REFERENCE"
          />
        }
      />
      <FormNumberField
        negative={false}
        name="shreddingPeriodDays"
        label={
          <HelpLabel
            label="Skartační lhůta (v dnech)"
            code="DICT_PROCESSING_PURPOSE_SHREDDING_PERIOD_DAYS"
          />
        }
      />
      <FormTextField
        name="shreddingMark"
        label={
          <HelpLabel
            label="Skartační znak"
            code="DICT_PROCESSING_PURPOSE_SHREDDING_MARK"
          />
        }
      />
    </>
  );
}

import React, { useContext } from 'react';
import { get } from 'lodash';
import {
  FormAutocomplete,
  FormFileField,
  useFormSelector,
  FormPanel,
  FormDateField,
  FormCheckbox,
  FormContext,
  useUpdateEffect,
  Params,
  ApiFilterOperation,
  FilesProvider,
} from '@eas/common-web';
import { useAgendas } from '../../agenda/agendas-api';
import { StepEmpowermentForm } from './step-empowerment-types';
import { AgendaType, Agenda } from '../../../models';
import { SpecificationTable } from '../../../composite/empowerment-specs-table/specification-table';
import { HelpLabel } from '../../../components/help/help-label';
import { EvidenceAPI } from '../../../enums';

export function StepEmpowermentSubForm({ name }: { name: string }) {
  const { setFieldValue } = useContext(FormContext);

  const { agenda, withoutDay, pickedAgendas } = useFormSelector(
    (data: StepEmpowermentForm) => {
      const agenda = get(data, `${name}.agenda`) as Agenda | undefined;
      return {
        agenda,
        withoutDay: get(data, `${name}.withoutDay`),
        pickedAgendas: (data.subForms ?? [])
          .map((form) => form.agenda?.id)
          .filter((item) => item && item !== agenda?.id),
      };
    }
  );

  useUpdateEffect(() => {
    setFieldValue(`${name}.validTo`, null);
  }, [withoutDay]);

  useUpdateEffect(() => {
    setFieldValue(`${name}.specifications`, []);
  }, [agenda]);

  const params: Params = {
    filters: [
      { field: 'empowerable', operation: ApiFilterOperation.EQ, value: true },
    ],
  };

  if (pickedAgendas.length !== 0) {
    params.filters?.push({
      operation: ApiFilterOperation.NOT,
      filters: [
        {
          field: 'id',
          operation: ApiFilterOperation.IN,
          values: pickedAgendas,
        },
      ],
    });
  }

  const agendas = useAgendas(params);
  agendas.loadDetail = async (item) => item;

  return (
    <>
      <FormPanel label="Vyplňte">
        <FormAutocomplete
          name={`${name}.agenda`}
          label={
            <HelpLabel
              label="Agenda"
              code="EMPOWERMENT_REQUEST_NEW_AGENDA"
              required
            />
          } // Povinné pole
          source={agendas}
        />
        <FilesProvider url={EvidenceAPI.ARCHIVED_FILES}>
          <FormFileField
            name={`${name}.empowermentDocument`}
            label={
              <HelpLabel
                label="Příloha (plná moc konvertovaná)"
                code="EMPOWERMENT_REQUEST_NEW_EMPOWERMENT_DOCUMENT"
                required
              />
            } // Povinné pole
            accept={['.pdf']}
          />
        </FilesProvider>
        <FormDateField
          name={`${name}.validFrom`}
          label={
            <HelpLabel
              label="Platnost od"
              code="EMPOWERMENT_REQUEST_NEW_VALID_FROM"
              required
            />
          } // Povinné pole
        />

        <FormDateField
          name={`${name}.validTo`}
          label={
            <HelpLabel
              label="Platnost do"
              code="EMPOWERMENT_REQUEST_NEW_VALID_TO"
              required={!withoutDay}
            />
          }
          disabled={withoutDay}
        />
        <FormCheckbox
          name={`${name}.withoutDay`}
          label={
            <HelpLabel
              label="Na neurčito"
              code="EMPOWERMENT_REQUEST_NEW_WITHOUT_DAY"
            />
          }
        />
      </FormPanel>
      {agenda?.type === AgendaType.SPECIFIED && (
        <FormPanel label="Typ hlášení">
          <SpecificationTable
            disabled={false}
            fieldPrefix={name}
            hideLabel={true}
            noSpacing={true}
          />
        </FormPanel>
      )}
    </>
  );
}

/**
 * Personal data permissions
 */
export enum PermissionPersonalData {
  /** Přehled OÚ s vazbou na všechny subjekty */
  PERSONAL_DATA_LIST_ALL = 'PERSONAL_DATA_LIST_ALL',

  /** Přehled OÚ s vazbou na své subjekty */
  PERSONAL_DATA_LIST_OWN = 'PERSONAL_DATA_LIST_OWN',

  /** Detail OÚ s vazbou na všechny subjekty */
  PERSONAL_DATA_DETAIL_ALL = 'PERSONAL_DATA_DETAIL_ALL',

  /** Detail OÚ s vazbou na své subjekty */
  PERSONAL_DATA_DETAIL_OWN = 'PERSONAL_DATA_DETAIL_OWN',

  /** Editace OÚ */
  PERSONAL_DATA_UPDATE = 'PERSONAL_DATA_UPDATE',

  /** Mazání OÚ */
  PERSONAL_DATA_DELETE = 'PERSONAL_DATA_DELETE',

  /** Export OÚ */
  PERSONAL_DATA_EXPORT = 'PERSONAL_DATA_EXPORT',

  /** Anonymizace OÚ */
  PERSONAL_DATA_ANONYMIZE = 'PERSONAL_DATA_ANONYMIZE',
}

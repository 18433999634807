export enum PermissionMuvWaterOrigin {
  /** Přehled původů odebrané vody */
  MUV_WATER_ORIGIN_LIST = 'MUV_WATER_ORIGIN_LIST',

  /** Autocomplete výběr původů odebrané vody */
  MUV_WATER_ORIGIN_AUTOCOMPLETE = 'MUV_WATER_ORIGIN_AUTOCOMPLETE',

  /** Detail původu odebrané vody */
  MUV_WATER_ORIGIN_DETAIL = 'MUV_WATER_ORIGIN_DETAIL',

  /** Vytvoření nového původu odebrané vody */
  MUV_WATER_ORIGIN_CREATE = 'MUV_WATER_ORIGIN_CREATE',

  /** Editace původu odebrané vody */
  MUV_WATER_ORIGIN_UPDATE = 'MUV_WATER_ORIGIN_UPDATE',

  /** Mazání původu odebrané vody */
  MUV_WATER_ORIGIN_DELETE = 'MUV_WATER_ORIGIN_DELETE',

  /** Zneplatnění původu odebrané vody */
  MUV_WATER_ORIGIN_INVALIDATE = 'MUV_WATER_ORIGIN_INVALIDATE',

  /** Obnovení původu odebrané vody */
  MUV_WATER_ORIGIN_REVALIDATE = 'MUV_WATER_ORIGIN_REVALIDATE',
}

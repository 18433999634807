import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Schedule job messages
 */
const START: MessageType = {
  SUCCESS: ['Úloha byla úspěšně spuštěna.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při spouštění úlohy.', SnackbarVariant.ERROR],
};

const CANCEL: MessageType = {
  SUCCESS: ['Úloha byla úspěšně zrušena.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při rušení úlohy.', SnackbarVariant.ERROR],
};

export default { START, CANCEL };

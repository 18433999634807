import {
  useEventCallback,
  NavigationContext,
  UserContext,
} from '@eas/common-web';
import { useContext } from 'react';
import { EvidenceUrl, Permission } from '../../../enums';

export function useRedirect() {
  /**
   * Context stuff.
   */
  const { navigate } = useContext(NavigationContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Callback to redirect into new registration.
   */
  const handleNewRegistration = useEventCallback(() => {
    navigate(EvidenceUrl.OVZ_FACILITY_NEW);
  });

  const showNewRegistrationButton = hasPermission(
    Permission.OvzFacility.OVZ_CREATE_OWN
  );

  /**
   * Callback to redirect into evidence of OVZ requests
   */
  const handleAllRequests = useEventCallback(() => {
    navigate(EvidenceUrl.OVZ_FACILITY_REQUESTS);
  });

  const showAllRequestsButton =
    hasPermission(Permission.OvzFacility.OVZ_REQUEST_LIST_ALL) ||
    hasPermission(Permission.OvzFacility.OVZ_REQUEST_LIST_OWN);

  return {
    handleNewRegistration,
    showNewRegistrationButton,
    handleAllRequests,
    showAllRequestsButton,
  };
}

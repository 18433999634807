import React from 'react';
import {
  FormTextField,
  FormDateField,
  FormPanel,
  FormTableField,
  EmptyComponent,
  TableFieldCells,
  TableFieldColumn,
  FormSelect,
} from '@eas/common-web';
import { redirectToDetailCellFactory } from '../../composite/subject-cells/redirect-to-detail-cell';
import { EvidenceUrl } from '../../enums';
import { WaterManagementDecisionPart } from '../../models';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import {
  useBasinEnterprises,
  useTypes,
} from '../water-usage-place/water-usage-places-api';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { HelpLabel } from '../../components/help/help-label';

export function usePartColumns(): TableFieldColumn<
  WaterManagementDecisionPart
>[] {
  return [
    {
      name: 'Odkaz',
      datakey: 'id',
      width: 60,
      CellComponent: redirectToDetailCellFactory(
        EvidenceUrl.WATER_MANAGEMENT_DECISION_PARTS
      ),
    },
    {
      name: 'Typ MUV',
      datakey: 'waterUsagePlace.type',
      width: 300,
      CellComponent: TableFieldCells.useSelectCellFactory(useTypes),
    },
    {
      name: 'Název MUV',
      datakey: 'waterUsagePlace.name',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Číslo VHB',
      datakey: 'waterUsagePlace.vhbNumber',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Druh rozhodnutí',
      datakey: 'type.name',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Datum platnosti: od',
      datakey: 'validFrom',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Datum platnosti: do',
      datakey: 'validTo',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Poslední platná',
      datakey: 'mostValid',
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
  ];
}

export function WaterManagementDecisionFields(props: NotedEvidenceFieldsProps) {
  const columns = usePartColumns();
  const basinEnterprises = useBasinEnterprises();

  return (
    <>
      <FormPanel label="Vodohospodářské rozhodnutí (VHR)">
        <FormTextField
          name="ppId.id"
          label={
            <HelpLabel
              label="ID v databázi PP"
              code="WATER_MANAGEMENT_DECISION_PPID_ID"
              required
            />
          }
        />
        <FormSelect
          source={basinEnterprises}
          name="ppId.enterprise"
          label={
            <HelpLabel
              label="Podnik povodí"
              code="WATER_MANAGEMENT_DECISION_ENTERPRISE"
              required
            />
          }
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
          valueIsId={true}
        />
        <FormTextField
          name="referenceNumber"
          label={
            <HelpLabel
              label="Číslo jednací"
              code="WATER_MANAGEMENT_DECISION_REFERENCE_NUMBER"
            />
          }
        />
        <FormDateField
          name="issuedDate"
          label={
            <HelpLabel
              label="Datum vydání"
              code="WATER_MANAGEMENT_DECISION_ISSUED_DATE"
            />
          }
        />
        <FormTextField
          name="issuedBy"
          label={
            <HelpLabel
              label="Vydal"
              code="WATER_MANAGEMENT_DECISION_ISSUED_BY"
            />
          }
        />
      </FormPanel>
      <FormPanel
        label="Části rozhodnutí MUV"
        summary={<FormPanelCountByName name="parts" />}
      >
        <FormTableField
          name="parts"
          ToolbarComponent={EmptyComponent}
          labelOptions={{
            hide: true,
          }}
          layoutOptions={{
            noSpacing: true,
          }}
          maxRows={15}
          showDetailBtnCond={() => false}
          showRadioCond={() => false}
          visibleActionsColumn={false}
          columns={columns}
        />
      </FormPanel>
      {props.HistoryRecords}
    </>
  );
}

import {
  useRef,
  useState,
  useMemo,
  createRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import {
  useEventCallback,
  useFirstRender,
  NavigationContext,
  NavigationPrompt,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';

import { StepData, ProcessStepConfiguration } from './process-types';

export function useProcess<FORM>(steps: ProcessStepConfiguration<FORM>[]) {
  const { showSnackbar } = useContext(SnackbarContext);

  const location = useLocation();
  const stepCount = steps.length;

  /**
   * Holds array of step data structure.
   */
  const processRef = useRef<StepData<FORM>[]>([]);

  /**
   * Creates initial values object by merging initial values from all steps.
   */
  const initialValues = useMemo(() => {
    let initialValues: Partial<FORM> = {};
    for (const step of steps) {
      initialValues = { ...initialValues, ...step.initialValues };
    }
    return initialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Holds values from all steps merged into single json.
   */
  const processValues = useRef<Partial<FORM>>(initialValues);

  /**
   * Currently active step index.
   */
  const [activeStep, setActiveStep] = useState(0);

  const intl = useIntl();

  const { registerPrompt, unregisterPrompt, navigate } = useContext(
    NavigationContext
  );

  const confirmTitle = intl.formatMessage({
    id: 'EAS_DETAIL_LEAVE_DIALOG_TITLE',
    defaultMessage: 'Zanechání změn',
  });
  const confirmText = intl.formatMessage({
    id: 'EAS_DETAIL_LEAVE_DIALOG_TEXT',
    defaultMessage: 'Skutečně chcete opustit rozpracované změny?',
  });

  const navigationPrompt: NavigationPrompt = useMemo(
    () => ({
      title: confirmTitle,
      text: confirmText,
    }),
    [confirmTitle, confirmText]
  );

  /**
   * Validates the form, sets error and returns boolean validity indicator
   */
  const validateForm = useEventCallback(async () => {
    if (processRef.current) {
      const errors = await processRef.current[
        activeStep
      ].stepRef.current?.validate();

      if (errors?.length) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });

  const moveForward = async (step?: number) => {
    if (processRef.current) {
      // validate form
      const isValid = await validateForm();
      if (isValid) {
        const values = processRef.current[
          activeStep
        ].stepRef.current?.getValues();

        // set values into array structure
        processRef.current[activeStep].values = values!;

        processValues.current = {};

        // merge values into result bag
        for (const i in processRef.current) {
          processValues.current = {
            ...processValues.current,
            ...processRef.current[i].values,
          };
        }

        if (activeStep + 1 !== stepCount) {
          setActiveStep((prevActiveStep) => step ?? prevActiveStep + 1);
        }
      } else {
        const message = intl.formatMessage({
          id: 'EAS_DETAIL_VALIDATION_MSG_ERROR',
          defaultMessage: 'Formulář obsahuje chyby.',
        });
        showSnackbar(message, SnackbarVariant.ERROR, true);
      }
    }
  };

  const moveBackward = (step?: number) => {
    setActiveStep((prevActiveStep) => step ?? prevActiveStep - 1);
  };

  /**
   * Handler to move to next step.
   */
  const handleNext = useEventCallback(async () => {
    moveForward();
  });

  /**
   * Handler to move to step.
   */
  const handleMoveToStep = useEventCallback(async (step: number) => {
    if (step < activeStep) {
      moveBackward(step);
    } else {
      moveForward(step);
    }
  });

  /**
   * Handler to move to previous step.
   */
  const handleBack = useEventCallback(() => {
    moveBackward();
  });

  const handleExit = useEventCallback(() => {
    navigate('/crzp');
  });

  /**
   * Handler to reset all values and move to the first step.
   */
  const handleReset = useEventCallback(() => {
    setActiveStep(0);

    for (let i = 0; i < stepCount; i++) {
      processRef.current[i].values = {};
    }

    processValues.current = {};
  });

  useEffect(() => {
    registerPrompt(navigationPrompt);

    return () => {
      handleReset();
      unregisterPrompt(navigationPrompt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  /**
   * Initialize processRef.
   */
  useFirstRender(() => {
    for (let i = 0; i < stepCount; i++) {
      processRef.current.push({
        values: steps[i].initialValues ?? {},
        stepRef: createRef(),
      });
    }
  });

  return useMemo(
    () => ({
      activeStep,
      handleNext,
      handleBack,
      handleReset,
      handleExit,
      handleMoveToStep,
      process: processRef.current,
      processValues: processValues.current,
      navigationPrompt,
    }),
    [
      activeStep,
      navigationPrompt,
      handleBack,
      handleNext,
      handleReset,
      handleExit,
      handleMoveToStep,
    ]
  );
}

import { DomainObject } from '../../../models';
import React, { ComponentType } from 'react';
import { TableCellProps } from '@eas/common-web';

export function basicColoredFactory<
  T extends DomainObject & {
    active?: boolean;
    validTo?: string;
    validFrom?: string;
  }
>(Component: ComponentType<TableCellProps<T>>) {
  const WrapedComponent = function Cell(props: TableCellProps<T>) {
    return (
      <div>
        <Component {...props} />
      </div>
    );
  };

  WrapedComponent.displayName = Component.displayName ?? Component.name;
  return WrapedComponent;
}

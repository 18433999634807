import React, { useContext, useMemo, useState, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import {
  DetailContext,
  DetailHandle,
  TableFieldToolbarButton,
  UserContext,
  TableFieldToolbarProps,
  RemoteTableFieldContext,
  TableFieldToolbarButtons,
  ApiFilterOperation,
  FieldFilter,
  DetailActionButton,
  ActionButtonHandle,
} from '@eas/common-web';
import {
  Subject,
  Me,
  PermissionsState,
  RelationshipType,
} from '../../../models';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import { Permission } from '../../../enums';
import { useAssignUserDialog } from '../dialog-actions/assign-user/assign-user-hook';
import { useUnassignUserDialog } from '../dialog-actions/usassign-user-hook';
import { useUserDetailDialog } from '../dialog-actions/detail-user-hook';
import { AssignUserDialog } from '../dialog-actions/assign-user/assign-user-dialog';
import { SubjectUserDto } from '../subjects-types';

export const useStyles = makeStyles((theme) => ({
  textButton: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableActions: {
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    // height: 30,
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 16px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  buttonGroup: {
    display: 'flex',
    marginBottom: 8,
    // marginLeft: -5, // offset first icon left padding

    '& button:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.grey['400']}`,
    },
  },
}));

export function SubjectsUsersToolbar({
  selectedIndex,
  setSelectedIndex,
}: TableFieldToolbarProps) {
  const classes = useStyles();

  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const users: SubjectUserDto[] = tableSource.items;

  const [assignUserFormData, setAssignUserFormData] = useState<any>();
  const resultRef = useRef<ActionButtonHandle>(null);

  const {
    handleAssignUser,
    canAssign,
    formInitialValues: assignUserIntialValues,
    submitCallback: assignUserSubmitCallback,
    formValidationSchema: assignUserSchema,
  } = useAssignUserDialog({
    users,
    selectedIndex,
  });
  const { handleUnassignUser } = useUnassignUserDialog({
    selectedIndex: selectedIndex!,
    setSelectedIndex,
  });
  const { handleUserDetail } = useUserDetailDialog({
    users,
    selectedIndex,
  });

  const { source } = useContext<DetailHandle<Subject>>(DetailContext);
  const { hasPermission, user } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const canUnassign =
    source?.data?.active &&
    ([
      Permission.User.USER_UNASSIGN_ALL,
      Permission.User.USER_UNASSIGN_OWN,
    ].some((perm) => hasPermission(perm, { subjectId: source.data?.id })) ||
      (selectedIndex !== undefined &&
        users?.[selectedIndex]?.user?.id === user?.id));

  const hasActiveRole = useMemo(() => {
    if (selectedIndex === undefined) {
      return false;
    }

    return !!users?.[selectedIndex]?.relationshipRoleInstances?.find(
      (roleInstance) => roleInstance.active
    );
  }, [users, selectedIndex]);

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButton
          color="primary"
          variant="contained"
          title="Vytvoření vazby uživatele k subjektu"
          disabled={!canAssign || selectedIndex !== undefined}
          show={true}
          IconComponent={ControlPointIcon}
          onClick={handleAssignUser}
        >
          <Typography variant="body2">Přidat</Typography>
        </TableFieldToolbarButton>
        <DetailActionButton
          promptKey="ASSIGN_USER"
          dialogTitle="Editace vazby uživatele na subjekt"
          dialogText="Vyplňte údaje:"
          buttonLabel="Upravit"
          FormFields={AssignUserDialog}
          formInitialValues={assignUserIntialValues}
          dialogWidth={1000}
          formValidationSchema={assignUserSchema}
          submitCallback={async (values) => {
            if (
              selectedIndex !== undefined &&
              user?.name === values?.username &&
              source?.data?.administratorsCount === 1 &&
              values?.relationship === RelationshipType.USER &&
              users?.[selectedIndex]?.relationship ===
                RelationshipType.ADMINISTRATOR
            ) {
              setAssignUserFormData(values);
              resultRef.current?.executeAction();

              return true;
            } else {
              await assignUserSubmitCallback(values);
            }
          }}
          ButtonComponent={({ label, ...props }) => (
            <TableFieldToolbarButton
              {...props}
              IconComponent={EditIcon}
              color="default"
              variant="text"
              title="Editace vazby uživatele k subjektu"
              show={true}
              disabled={
                !canAssign || selectedIndex === undefined || !hasActiveRole
              }
            >
              <Typography variant="body2">{label}</Typography>
            </TableFieldToolbarButton>
          )}
        />
        <DetailActionButton
          ref={resultRef}
          promptKey="ASSIGN_USER_RESULT"
          dialogTitle="Editace vazby uživatele na subjekt"
          dialogText="Jste posledním správcem tohoto subjektu. Opravdu si přejete změnit vztah na běžného uživatele subjektu?"
          buttonLabel={null}
          submitCallback={async () => {
            await assignUserSubmitCallback(assignUserFormData);
          }}
          modes={[]}
        />
        <TableFieldToolbarButton
          color="default"
          variant="text"
          IconComponent={LaunchIcon}
          disabled={selectedIndex === undefined}
          show={true}
          title="Detail vazby uživatele k subjektu"
          onClick={handleUserDetail}
        >
          <Typography variant="body2">Detail</Typography>
        </TableFieldToolbarButton>
        <TableFieldToolbarButton
          color="secondary"
          variant="text"
          IconComponent={RemoveIcon}
          disabled={
            !canUnassign || selectedIndex === undefined || !hasActiveRole
          }
          show={true}
          title="Zrušení vazby uživatele k subjektu"
          onClick={handleUnassignUser}
        >
          <Typography variant="body2">Smazat</Typography>
        </TableFieldToolbarButton>
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          source={tableSource}
          filtersFields={[
            {
              label: 'Užívatelské jméno',
              datakey: 'user.username',
              filterkey: 'user.username',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Jméno',
              datakey: 'user.name',
              filterkey: 'user.name',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Email',
              datakey: 'user.email',
              filterkey: 'user.email',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
          ]}
        />
      </ButtonGroup>
    </div>
  );
}

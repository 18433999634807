import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { ExportAccessRuleFields } from './export-access-rules-fields';
import { useColumns } from './export-access-rules-columns';
import { EvidenceAPI, PageKey, Permission } from '../../../enums';
import { ExportTags } from '../../../enums/export-tags';
import { usePermission } from '../../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../../components/evidence/authored-evidence/authored-evidence';
import { ExportAccessRule } from '../../../models/dict-export';
import { useValidationSchema } from './export-access-rules-schema';
import { DetailEmptyActionbar } from '../../../components/evidence/evidence/detail-empty-actionbar';

export function ExportAccessRules() {
  const permissions = usePermission<ExportAccessRule>({
    options: {
      NEW: [Permission.ExportAccessRule.EXPORT_ACCESS_RULE_CREATE],
      EDIT: [Permission.ExportAccessRule.EXPORT_ACCESS_RULE_UPDATE],
      REMOVE: [Permission.ExportAccessRule.EXPORT_ACCESS_RULE_DELETE],
      CHECK: [],
      SAVE: [
        Permission.ExportAccessRule.EXPORT_ACCESS_RULE_CREATE,
        Permission.ExportAccessRule.EXPORT_ACCESS_RULE_UPDATE,
      ],
      CLOSE: [
        Permission.ExportAccessRule.EXPORT_ACCESS_RULE_CREATE,
        Permission.ExportAccessRule.EXPORT_ACCESS_RULE_UPDATE,
      ],
    },
  });

  const validationSchema = useValidationSchema();

  const evidence = useAuthoredEvidence<ExportAccessRule>({
    identifier: PageKey.EXPORT_ACCESS_RULES,
    apiProps: {
      url: EvidenceAPI.EXPORT_ACCESS_RULES,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled pravidel přístupu k exportům',
      reportTag: ExportTags.EXPORT_ACCESS_RULES,
      defaultSorts: [],
    },
    detailProps: {
      validationSchema,
      FieldsComponent: ExportAccessRuleFields,
      toolbarProps: {
        title: 'Pravidlo',
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}

import {
  abortableFetch,
  DetailContext,
  SnackbarContext,
  UserContext,
  DetailHandle,
  PromptContext,
  AbortableFetch,
  usePrompts,
  FormFileField,
  useEventCallback,
  FileRef,
} from '@eas/common-web';
import { EvidenceAPI, Messages, Permission } from '../../../enums';
import React, { useContext, useRef } from 'react';
import { Me, BoilerManufacturer, PermissionsState } from '../../../models';
import * as Yup from 'yup';
import { unstable_batchedUpdates } from 'react-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { getErrorMessage } from '../../../utils/get-message';

function callImportApi(file: FileRef) {
  //TODO
  return abortableFetch(
    `${EvidenceAPI.DICTIONARY_BOILER_MODELS}/bulk?file=${file?.id}`,
    {
      method: 'POST',
    }
  );
}

const PROMPT_KEY = 'BULK_IMPORT';

export function useBulkImportDialog() {
  const { onPersisted, source } = useContext<DetailHandle<BoilerManufacturer>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );
  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Hromadný import modelů',
      dialogText: (
        <>
          <Typography variant="h6">Požadovaný formát: </Typography>
          <Typography variant="body1" component="div">
            <ul style={{ marginLeft: '20px' }}>
              <li>Typ souboru: .xlsx</li>
              <li>První řádek obsahuje hlavičku</li>
              <li>
                Soubor obsahuje 4 sloupce: <br />
                Značka, Kód typu kotle, Název modelu, Popis modelu
              </li>
              <li>
                <Link download target="_blank" href="/sablona-ozo-modely.xlsx">
                  Stáhnout šablonu
                </Link>
              </li>
            </ul>
          </Typography>
          <br />
          <Typography variant="body1">
            Dokončete import nahráním vyplněné šablony.
          </Typography>
        </>
      ),
      dialogWidth: 600,
      FormFields: function FormFields() {
        return (
          <>
            <FormFileField
              name="file"
              accept={['.xlsx']}
              labelOptions={{ hide: true }}
            />
          </>
        );
      },
      formValidationSchema: Yup.object().shape({
        file: Yup.mixed().required('Soubor je povinný'),
      }),
    },
  ]);

  const handleImport = useEventCallback(() =>
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async ({ file }: { file: FileRef }) => {
        try {
          source.setLoading(true);
          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callImportApi(file);
          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.Ozo.BULK_MODEL_IMPORT.SUCCESS);
            source.setLoading(false);
          });
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);
          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.Ozo.BULK_MODEL_IMPORT,
              err.code
            );

            showSnackbar(...message);
          }
          return undefined;
        }
      },
    })
  );

  const canPerformBulkImport =
    (source?.data?.boilerBrands?.length ?? 0) > 0 &&
    hasPermission(Permission.Ozo.OZO_MODEL_UPDATE, {
      searchOnlyInSubjectPerms: true,
    });

  return {
    handleImport,
    canPerformBulkImport,
  };
}

import {
  TableColumn,
  TableCells,
  TableFilterCells,
  TableFieldColumn,
  InlineTableFieldCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { useStates } from './mail-campaigns-api';
import { MailCampaign } from '../../models/mail-campaign';
import { useMemo } from 'react';

export function useColumns(): TableColumn<MailCampaign>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'emailSubject',
      name: 'Předmět emailu',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'publisher.username',
      name: 'Odesílatel',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'startTime',
      name: 'Začátek',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'endTime',
      name: 'Konec',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

export function useRecipientsColumns(
  prefix: string
): TableFieldColumn<string>[] {
  const EmailCell = InlineTableFieldCells.useInlineTextFieldFactory<string>({
    collectionDatakey: prefix,
    isSubkey: false,
  });
  return useMemo(
    () => [
      {
        name: 'Email',
        datakey: 'recipients',
        width: 400,
        CellComponent: EmailCell,
      },
    ],
    [EmailCell]
  );
}

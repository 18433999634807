import React from 'react';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { useValidationSchema } from './dict-dirp-areas-schema';
import { DictionaryDirpArea } from '../../models';
import { PageKey, EvidenceAPI, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';

export function DictionaryDirpAreas() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryDirpArea>({
    identifier: PageKey.DICTIONARY_DIRP_AREAS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_DIRP_AREAS,
    },
    tableProps: {
      tableName: 'Oblasti',
      reportTag: ExportTags.DICTIONARY_DIRP_AREAS,
      columns: [],
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.DIRP_AREA,
      codePrefix: 'DIRP_AREA',
      validationSchema,
      toolbarProps: {
        title: 'Oblast',
      },
    },
  });

  return <Evidence {...evidence} />;
}

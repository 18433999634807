import { StepEditForm } from './step-edit/step-edit-types';
import { StepFacilityForm } from './step-facility/step-facility-types';
import { StepSummaryForm } from './step-summary/step-summary-types';
import { StepNoteForm } from './step-note/step-note-types';

export type ProcessForm = StepEditForm &
  StepFacilityForm &
  StepNoteForm &
  StepSummaryForm;

export enum IrzChangeRequestStateAction {
  INIT_IRZ = 'INIT_IRZ',
}

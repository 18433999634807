import * as Yup from 'yup';
import { MailCampaign, MailCampaignState } from '../../models/mail-campaign';

export function useValidationSchema() {
  return Yup.object<MailCampaign>().shape({
    state: Yup.mixed<MailCampaignState>().nullable().required('Povinné pole'),
    name: Yup.string().nullable().required('Povinné pole'),
    emailSubject: Yup.string().nullable().required('Povinné pole'),
    emailContent: Yup.string().nullable().required('Povinné pole'),
  });
}

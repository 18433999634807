import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: 10,
      height: 42,
      width: '100%',
    },
    searchIcon: {
      cursor: 'pointer',
      marginRight: 5,
      color: theme.palette.primary.dark,
    },
    searchTextFieldInput: {
      '&:hover': {
        borderColor: 'red',
      },
    },
    searchTextField: {
      flex: 1,
      maxWidth: '400px',
      marginLeft: 0,
      marginBottom: 0,
      marginTop: 0,
      backgroundColor: '#fff',

      '& > div': {
        fontSize: 14,
        height: 32,
        '& input[type="search"]::-webkit-search-cancel-button': {
          display: 'none',
        },
      },
      '& .smap-suggest': {
        top: 0,
        marginTop: '30px',
      },
    },
  })
);

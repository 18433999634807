import React from 'react';
import { AddressPlace } from '../../models';
import { PageKey, EvidenceAPI, EntityName } from '../../enums';

import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useColumns } from './dict-ruian-address-place-columns';
import DictionaryRuianAddressPlaceFields from './dict-ruian-address-place-fields';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';

export function DictionaryRuianAddressPlace() {
  const evidence = useRuianEvidence<AddressPlace>(
    {
      identifier: PageKey.DICTIONARY_RUIAN_ADDRESS_PLACE,
      apiProps: {
        url: EvidenceAPI.ADDRESS_PLACE,
      },
      tableProps: {
        columns: useColumns(),
        tableName: 'RUIAN - Adresní místa',
        reportTag: ExportTags.DICTIONARY_ADDRESS_PLACES,
      },
      detailProps: {
        entityName: EntityName.RUIAN_ADDRESS_PLACE,
        FieldsComponent: DictionaryRuianAddressPlaceFields,
        toolbarProps: {
          title: 'RUIAN - Adresní místo',
        },
      },
    },
    {
      hasMunicipality: true,
    }
  );

  return <Evidence2 {...evidence} />;
}

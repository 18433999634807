import React, { useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ProcessActionsProps } from '../../../components/process/process-types';
import {
  useEventCallback,
  NavigationContext,
  EvidenceStateAction,
} from '@eas/common-web';
import { EvidenceUrl } from '../../../enums';
import { ProcessForm } from '../irz-facility-request-change-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function StepFinishActions({
  allValues,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  const { navigate } = useContext(NavigationContext);

  const handleFinish = useEventCallback(async () => {
    navigate(`${EvidenceUrl.IRZ_FACILITY_REQUESTS}`, true, {
      action: EvidenceStateAction.SHOW_ITEM,
      data: allValues.request!.id!,
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFinish}
          className={classes.button}
          disableElevation
        >
          Přejít do přehledu
        </Button>
      </div>
    </div>
  );
}

import React, { useContext, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  DetailHandle,
  PromptContext,
  usePrompts,
  FormTextArea,
  DetailMode,
  NavigationContext,
  abortableFetch,
} from '@eas/common-web';
import { Messages } from '../../../enums';
import { User } from '../../../models';
import { removeTrailingWhitespaces } from '../users-utils';
import { preprocessPhoneNumber } from '../../../components/form/phone-field/phone-field-utils';
import { getErrorMessage } from '../../../utils/get-message';

function callCreate(api: string, user: User, note?: string) {
  if (user.lastName?.value) {
    user.lastName.value = removeTrailingWhitespaces(user.lastName.value);
  }
  if (user.firstName?.value) {
    user.firstName.value = removeTrailingWhitespaces(user.firstName.value);
  }

  const processed = preprocessPhoneNumber(user);
  processed.personalDataProcessingConsent = true;

  return abortableFetch(note ? `${api}?note=${note}` : api, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(processed),
  });
}

function callUpdate(api: string, user: User) {
  if (user.lastName?.value) {
    user.lastName.value = removeTrailingWhitespaces(user.lastName.value);
  }
  if (user.firstName?.value) {
    user.firstName.value = removeTrailingWhitespaces(user.firstName.value);
  }

  const processed = preprocessPhoneNumber(user);
  processed.personalDataProcessingConsent = true;

  return abortableFetch(`${api}/${user.id}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: JSON.stringify(processed),
  });
}

const CREATE_KEY = 'CREATE_USER';

export function useSaveDialog() {
  const {
    onPersisted,
    formRef,
    source,
    validate,
    mode,
    setActive,
    setMode,
  } = useContext<DetailHandle<User>>(DetailContext);
  const { unregisterPrompt, prompts } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);

  const fetch = useRef<AbortableFetch | null>(null);

  usePrompts(
    [
      {
        key: CREATE_KEY,
        dialogTitle: 'Vytvoření uživatele',
        dialogText: 'Skutečně chcete vytvořit uživatele?',
        FormFields: function Body() {
          return (
            <>
              <FormTextArea name="note" label="Poznámka" />
            </>
          );
        },
        dialogWidth: 600,
      },
    ],
    []
  );

  const handleSave = useEventCallback(async () => {
    const errors = await validate();
    if (errors.length > 0) return;

    if (mode === DetailMode.EDIT) {
      try {
        source.setLoading(true);

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        const formValues = formRef?.getFieldValues();

        fetch.current = callUpdate(source.url, formValues!);
        const data = await fetch.current.json();

        unregisterPrompt(prompts[0]);

        if (data !== undefined) {
          source.reset(data);
          setMode(DetailMode.VIEW);
          onPersisted(data.id);
        }

        unstable_batchedUpdates(() => {
          showSnackbar(...Messages.User.UPDATE.SUCCESS);
          source.setLoading(false);
        });

        return;
      } catch (err) {
        source.setLoading(false);

        if (err.name !== 'AbortError') {
          const message = getErrorMessage(Messages.User.UPDATE, err.code);

          showSnackbar(...message);
          throw err;
        }

        return undefined;
      }
    }

    if (mode === DetailMode.NEW) {
      testPrompt({
        key: CREATE_KEY,
        submitCallback: async (values: { note?: string }) => {
          try {
            source.setLoading(true);

            if (fetch.current !== null) {
              fetch.current.abort();
            }

            const formValues = formRef?.getFieldValues();

            fetch.current = callCreate(source.url, formValues!, values.note);

            const data = await fetch.current.json();

            unregisterPrompt(prompts[0]);

            if (data !== undefined) {
              unstable_batchedUpdates(() => {
                setActive(data.id);
                setMode(DetailMode.VIEW);
                onPersisted(data.id);
              });
            }

            unstable_batchedUpdates(() => {
              showSnackbar(...Messages.User.CREATE.SUCCESS);
              source.setLoading(false);
            });
          } catch (err) {
            source.setLoading(false);

            if (err.name !== 'AbortError') {
              const message = getErrorMessage(Messages.User.CREATE, err.code);

              showSnackbar(...message);
              throw err;
            }

            return undefined;
          }
        },
      });
    }
  });

  return {
    handleSave,
  };
}

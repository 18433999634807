import React from 'react';
import { CityDistrictPrague } from '../../models';
import { PageKey, EvidenceAPI, EntityName } from '../../enums';

import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';

export function DictionaryRuianCityDistrictPrague() {
  const evidence = useRuianEvidence<CityDistrictPrague>(
    {
      identifier: PageKey.DICTIONARY_RUIAN_CITY_DISTRICT_PRAGUE,
      apiProps: {
        url: EvidenceAPI.ADDRESS_DISCTRICT_CITY_PRAGUE,
      },
      tableProps: {
        tableName: 'RUIAN - Městské obvody v hlavním městě Praze (MOP)',
        reportTag: ExportTags.DICTIONARY_DISTRICT_CITY_PRAGUE,
      },
      detailProps: {
        entityName: EntityName.RUAIN_CITY_DISTRICT_PRAGUE,
        toolbarProps: {
          title: 'RUIAN - Městský obvod v hlavním městě Praze (MOP)',
        },
      },
    },
    {
      hasMunicipality: true,
    }
  );

  return <Evidence2 {...evidence} />;
}

import * as Yup from 'yup';
import 'yup-phone';

export const usernameRules =
  'min. 4 znaky, max. 50 znaků; bez diakritiky a mezer';

export const passwordRules =
  'min. 12 znaků (bez diakritiky a mezer) a alespoň 1 velké písmeno a 1 číslici';

export const prefixRules = 'znak "+" a 1-3 číslic';
export const phoneRules = '8-12 číslic';

export const urlRules =
  'nepovinné http(s)://, nepovinné www., platný formát URL';

export const validateUsername = (regex = /^[a-zA-Z0-9+=_\-()!?.:#*%]{4,50}$/) =>
  Yup.string()
    .nullable()
    .required(`Musí splňovat: ${usernameRules}`)
    .matches(regex, `Musí splňovat: ${usernameRules}`);

export const validateFirstName = Yup.string()
  .nullable()
  .required('Jméno musí být vyplněné');

export const validateLastName = Yup.string()
  .nullable()
  .required('Příjmení musí být vyplněné');

export const validatePhonePrefixRequired = Yup.string()
  .nullable()
  .required('Předvolba musí být vyplněná')
  .matches(/^\+\d{1,3}$/, `Nesplňuje pravidla: ${prefixRules}`);

export const validatePhoneNumberRequired = Yup.string()
  .nullable()
  .required('Číslo musí být vyplněné')
  .matches(/^\d{8,12}$/, `Číslo nesplňuje pravidla: ${phoneRules}`);

export const validatePhonePrefix = Yup.string()
  .nullable()
  .matches(/^\+\d{1,3}$/, `Předvolba nesplňuje pravidla: ${prefixRules}`);

export const validatePhoneNumber = Yup.string()
  .nullable()
  .matches(/^\d{8,12}$/, `Číslo nesplňuje pravidla: ${phoneRules}`);

export const validatePhone = Yup.mixed()
  .nullable()
  .test('', '', async function (value) {
    const { createError } = this;

    const prefix = value?.prefix;
    const number = value?.number?.value;

    // Validate phone
    const isPrefixValid = await validatePhonePrefix.isValid(prefix);
    const isNumberValid = await validatePhoneNumber.isValid(number);

    if (!prefix) {
      return createError({
        message: `Předvolba musí být vyplněná`,
      });
    }

    if (!isPrefixValid) {
      return createError({
        message: `Předvolba nesplňuje pravidla: ${prefixRules}`,
      });
    }

    if (!number) {
      return createError({
        message: `Číslo musí být vyplněné`,
      });
    }

    if (!isNumberValid) {
      return createError({
        message: `Číslo nesplňuje pravidla: ${phoneRules}`,
      });
    }

    return true;
  });

export const validatePhoneNotRequired = Yup.mixed()
  .nullable()
  .test('', '', async function (value) {
    const { createError } = this;

    const prefix = value?.prefix;
    const number = value?.number?.value;

    // Validate phone
    const isPrefixValid = await validatePhonePrefix.isValid(prefix);
    const isNumberValid = await validatePhoneNumber.isValid(number);

    if ((!!prefix && !isPrefixValid) || (!!number && !prefix)) {
      return createError({
        message: `Předvolba nesplňuje pravidla: ${prefixRules}`,
      });
    }

    if (!!number && !isNumberValid) {
      return createError({
        message: `Číslo nesplňuje pravidla: ${phoneRules}`,
      });
    }

    return true;
  });

export const noDiacriticLettersRegex = /^((?![À-ž]).)*$/;

export const validateEmail = ({
  emailMessage = 'Email je ve špatném formátu',
  requiredMessage = 'Povinné pole',
}: {
  emailMessage?: string;
  requiredMessage?: string;
}) =>
  Yup.string()
    .nullable()
    .required(requiredMessage)
    .email(emailMessage)
    .matches(
      noDiacriticLettersRegex,
      'Email nesmí obsahovat písmena s diakritikou'
    );

export const validateEmailNotRequired = (
  emailMessage = 'Email je ve špatném formátu'
) =>
  Yup.string()
    .nullable()
    .email(emailMessage)
    .matches(
      noDiacriticLettersRegex,
      'Email nesmí obsahovat písmena s diakritikou'
    );

export const validatePassword = Yup.string()
  .nullable()
  .required(`Musí splňovat: ${passwordRules}`)
  .test('Heslo', '', function (val) {
    const { createError } = this;
    // Must have length
    const validLength = val && val.length >= 12;

    // Must have at least 2 of 3 rules
    const hasUppercase = val && /(?=.*[A-Z])/.test(val) ? 1 : 0;
    const hasDigit = val && /(?=.*\d)/.test(val) ? 1 : 0;
    const hasSpecial = val && /(?=.*[!@#$%^&*()_+])/.test(val) ? 1 : 0;

    if (!validLength || hasUppercase + hasDigit + hasSpecial < 2) {
      return createError({
        message: `Musí splňovat: ${passwordRules}`,
      });
    }
    return true;
  });

export const validatePersonalDataProcessingConsent = Yup.boolean().oneOf(
  [true],
  'Souhlas se zpracováním osobních údajů musí být potvrzený'
);

export const validateWebsite = Yup.string()
  .nullable()
  .matches(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/,
    `Nesplňuje pravidla: ${urlRules}`
  )
  .required('Povinné pole');

export const validateWebsiteNotRequired = Yup.string()
  .nullable()
  .matches(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/,
    `Nesplňuje pravidla: ${urlRules}`
  );

import React, { forwardRef } from 'react';
import { DialogHandle, Dialog, Form, formFieldFactory } from '@eas/common-web';
import Box from '@material-ui/core/Box';
import { PasswordChangeForm } from './profile-types';
import { usePasswordChangeDialog } from './password-change-dialog-hook';
import { protectedFieldFactory } from '../../components/form/protected-field/protected-field';
import { ProtectedFieldWrapper } from '../../components/form/protected-field/protected-field-wrapper';
import { useStyles } from './profile-styles';

const PasswordChangeFields = {
  OldPasswordField: formFieldFactory(
    protectedFieldFactory({ inputProps: { type: 'password' } }),
    ProtectedFieldWrapper
  ),
  NewPasswordField: formFieldFactory(
    protectedFieldFactory({ inputProps: { type: 'password' } }),
    ProtectedFieldWrapper
  ),
  NewPasswordAgainField: formFieldFactory(
    protectedFieldFactory({ inputProps: { type: 'password' } }),
    ProtectedFieldWrapper
  ),
};

export const PasswordChangeDialog = forwardRef<
  DialogHandle,
  Record<string, unknown>
>(function PasswordChangeDialog(_, dialogRef) {
  const closeDialog = () => {
    if (typeof dialogRef !== 'function') {
      dialogRef?.current?.close();
    }
  };

  const {
    ref,
    loading,
    validationSchema,
    handleConfirm,
    handleSubmit,
  } = usePasswordChangeDialog(closeDialog);
  const classes = useStyles();

  return (
    <Dialog
      ref={dialogRef}
      loading={loading}
      title="Změna hesla"
      onConfirm={handleConfirm}
    >
      {() => (
        <div style={{ width: 500 }}>
          <Form<PasswordChangeForm>
            editing={!loading}
            initialValues={{
              oldPassword: '',
              newPassword: '',
              newPasswordAgain: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            ref={ref}
          >
            <Box mb={3} className={classes.formBox}>
              <PasswordChangeFields.OldPasswordField
                name="oldPassword"
                label="Staré heslo"
                required
              />
              <PasswordChangeFields.NewPasswordField
                name="newPassword"
                label="Nové heslo"
                required
              />
              <PasswordChangeFields.NewPasswordAgainField
                name="newPasswordAgain"
                label="Nové heslo znovu"
                required
              />
            </Box>
          </Form>
        </div>
      )}
    </Dialog>
  );
});

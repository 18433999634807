import React, { useMemo } from 'react';
import {
  FormPanel,
  FormCheckbox,
  TextField,
  FormInlineTableField,
  EmptyComponent,
  FormAutocomplete,
  Form,
  FormTextField,
  FormSelect,
  FormNumberField,
  FormCustomField,
} from '@eas/common-web';
import { noop } from 'lodash';
import { ProcessForm } from '../ovz-facility-new-types';
import { useSubjects } from '../../subject/subjects-api';
import { useTerritorialTechnicalUnits } from '../../dict-territorial-technical-unit/dict-territorial-technical-units-api';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { SubjectAutocomplete, RuianObjectAutocomplete } from '../../../models';
import { useLandPlotTypes } from '../../ovz-facility/ovz-facilities-api';
import { useCadastralTerritories } from '../../../components/form/address-field/address-field-api';
import {
  calculateDistance,
  formatDistance,
} from '../../../components/form/location-panel/location-utils';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  distance: {
    lineHeight: 'inherit',
    padding: '0 2pt',
  },
  warning: {
    color: theme.palette.error.main,
  },
}));

export function StepSummaryContent({ allValues }: { allValues: ProcessForm }) {
  const classes = useStyles();
  const subjects = useSubjects();
  const territorialTechnicalUnits = useTerritorialTechnicalUnits();
  const landPlotTypes = useLandPlotTypes();
  const cadastralTerritories = useCadastralTerritories();

  const ttuFacilityDistance = useMemo(() => {
    const facilityCoords = allValues?.wgs84Coordinates;

    const ttuCoors = allValues?.territorialTechnicalUnit?.wgs84Coordinates;

    if (facilityCoords && ttuCoors) {
      const distance = calculateDistance(facilityCoords, ttuCoors);

      return distance;
    } else {
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form editing={false} onSubmit={noop} initialValues={allValues}>
      <FormPanel label="Základní informace">
        <FormTextField label="Název" name="name" />
        <FormAutocomplete<SubjectAutocomplete>
          label="Provozovatel"
          name="operator"
          source={subjects}
          tooltipMapper={dictionaryLabelMapper}
          labelMapper={dictionaryLabelMapper}
        />
      </FormPanel>

      <FormPanel label="ÚTJ; Lokalita provozovny">
        <FormAutocomplete
          name="territorialTechnicalUnit"
          label="ÚTJ"
          source={territorialTechnicalUnits}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormCheckbox name="multipleTTU" label="Areál se nachází na více ÚTJ" />
        <FormAutocomplete<RuianObjectAutocomplete>
          source={cadastralTerritories}
          label="KÚ"
          name="cadastralTerritory"
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        <FormSelect
          name="landPlotType"
          label="Typ parcely"
          source={landPlotTypes}
          valueIsId
        />
        <FormNumberField
          name="primaryLandPlotNumber"
          label="Kmenové číslo parcely"
        />
        <FormNumberField
          name="secondaryLandPlotNumber"
          label="Pořadové číslo parcely"
        />
        {ttuFacilityDistance && ttuFacilityDistance > 10000 && (
          <FormCustomField
            name="warning"
            label="Vzdálenost polohy provozovny od středu UTJ"
          >
            <Typography
              variant="body1"
              className={clsx(classes.distance, classes.warning)}
            >
              {`Upozornění: Vybrané ÚTJ (střed) se nachází ve větší vzdálenosti než 10km (${formatDistance(
                ttuFacilityDistance
              )}) od polohy provozovny`}
            </Typography>
          </FormCustomField>
        )}
      </FormPanel>

      <LocationPanel
        locationName="wgs84Coordinates"
        locationValue={allValues?.wgs84Coordinates}
        locationPanelLabel="Poloha provozovny (zadání v mapovém náhledu nebo provedení kontroly polohy)"
        disabled={true}
        addressName="address"
        addressPanelLabel="Adresa provozovny"
      />

      <FormPanel label="Identifikátory">
        <FormTextField
          name="irzFacility.facilityId"
          label="IČP provozovny IRZ"
        />
        <FormTextField
          name="originalFacilityId"
          label="Původní IČP provozovny OVZ"
        />
        <FormCheckbox
          name="integratedPermission.hasPermission"
          label="Provozovna/Zařízení má Integrované povolení"
        />
        {allValues.integratedPermission?.hasPermission && (
          <FormInlineTableField<string>
            label="Seznam PID (IPPC)"
            name="integratedPermission.ippcPids"
            withRemove={false}
            ToolbarComponent={EmptyComponent}
            columns={[
              {
                name: 'PID (IPPC)',
                datakey: '',
                width: 600,
                CellComponent: function Cell({ rowValue }) {
                  return <TextField value={rowValue} disabled={true} />;
                },
              },
            ]}
          />
        )}
        <FormCheckbox
          name="emissionsTradingSystem.tradesInEmissions"
          label="Provozovna/Zařízení má přidělen Identifikátor systému obchodování s emisemi"
        />
        {allValues.emissionsTradingSystem?.tradesInEmissions && (
          <FormInlineTableField<string>
            name="emissionsTradingSystem.ids"
            label="ID zařízení (EU ETS)"
            columns={[
              {
                name: 'Identifikátor',
                datakey: 'ids',
                width: 400,
                CellComponent: function Cell({ rowValue }) {
                  return <TextField value={rowValue} disabled={true} />;
                },
              },
            ]}
          />
        )}
      </FormPanel>
    </Form>
  );
}

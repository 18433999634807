import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryHydrogeologicalZone } from '../../models';
import { useValidationSchema } from './dict-hydrogeological-zones-schema';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';

export function DictionaryHydrogeologicalZones() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<DictionaryHydrogeologicalZone>({
    options: {
      NEW: [Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_CREATE],
      EDIT: [Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_UPDATE],
      REMOVE: [
        Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_DELETE,
      ],
      CHECK: [
        Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_CREATE,
        Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_UPDATE,
      ],
      SAVE: [
        Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_CREATE,
        Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_UPDATE,
      ],
      CLOSE: [
        Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_CREATE,
        Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_UPDATE,
      ],
    },
  });

  const evidence = useDictionaryEvidence<DictionaryHydrogeologicalZone>(
    {
      identifier: PageKey.DICTIONARY_HYDROGEOLOGICAL_ZONES,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_HYDROGEOLOGICAL_ZONES,
      },
      tableProps: {
        tableName: 'Hydrogeologické rajóny',
        reportTag: ExportTags.DICTIONARY_HYDROGEOLOGICAL_ZONES,
        defaultSorts: [],
      },
      detailProps: {
        entityName: EntityName.HYDROGEOLOGICAL_ZONE,
        codePrefix: 'HYDROGEOLOGICAL_ZONE',
        validationSchema,
        toolbarProps: {
          title: 'Hydrogeologický rajón',
          showBtn: permissions,
        },
      },
    },
    Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_INVALIDATE,
    Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_REVALIDATE
  );

  return <Evidence2 {...evidence} />;
}

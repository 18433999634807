import React from 'react';
import {
  TableFieldColumn,
  TableFieldCells,
  TableFieldCellProps,
} from '@eas/common-web';
import { DictionaryBoilerBrand } from '../../models';

const BoilerTypesCell = ({
  rowValue,
  ...props
}: TableFieldCellProps<DictionaryBoilerBrand>) => {
  const value =
    rowValue?.allowedTypes?.map((type) => type.name)?.join(', ') ?? '';
  return (
    <TableFieldCells.TextCell {...props} rowValue={rowValue} value={value} />
  );
};

export function useBoilerBrandsColumns(): TableFieldColumn<
  DictionaryBoilerBrand
>[] {
  return [
    {
      name: 'Název',
      datakey: 'name',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Aktivní',
      datakey: 'active',
      width: 100,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      name: 'Povolené typy kotlů',
      datakey: 'allowedTypes',
      width: 200,
      CellComponent: BoilerTypesCell,
    },
    {
      name: 'Platnost od',
      datakey: 'validFrom',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Platnost do',
      datakey: 'validTo',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Popis',
      datakey: 'description',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
  ];
}

import React from 'react';
import { CompareText } from './compare-text';

function booleanToText(b?: boolean) {
  if (b) {
    return 'Ano';
  } else {
    return 'Ne';
  }
}

export function CompareBoolean({
  originalValue,
  newValue,
  label,
}: {
  originalValue?: boolean;
  newValue?: boolean;
  label?: string;
}) {
  return (
    <CompareText
      originalValue={booleanToText(originalValue)}
      newValue={booleanToText(newValue)}
      label={label}
    />
  );
}

import { TableColumn, TableCells, ApiFilterOperation } from '@eas/common-web';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { DictionaryDeposit } from '../../models';

export function useColumns(): TableColumn<DictionaryDeposit>[] {
  return [
    {
      datakey: 'name',
      name: 'Název ložiska',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryDeposit>(
        TableCells.TextCell
      ),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'code',
      name: 'Číslo ložiska',
      width: 200,
      CellComponent: inactiveColoredFactory<DictionaryDeposit>(
        TableCells.TextCell
      ),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
  ];
}

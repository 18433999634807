import React from 'react';
import { flattenDeep } from 'lodash';
import { useFormSelector } from '@eas/common-web';
import { Map } from '../../../components/map/map';
import { GeometryLayer } from '../../../components/map/geometry-layer';
import { Polygon } from '../../../components/map/polygon';
import { MapToolbar } from './protected-deposit-areas-map-toolbar';
import { ProtectedDepositArea, JtskCoordinates } from '../../../models';

export const parseCoordinates = (coordinates: string) => {
  const lines = coordinates.split('\n');
  const [config, ...coords] = lines;

  const parsedConfig = config
    .trim()
    .split(' ')
    .map((item) => item.toLowerCase());
  const xFirst = parsedConfig[0] === 'x';

  let allPolygons: JtskCoordinates[][] = [];
  let lastPolygon: JtskCoordinates[] = [];

  for (const coordinate of coords) {
    const parsed = coordinate.trim().split(/\s+/);

    const first = parseFloat(parsed[0]?.replace(',', '.'));
    const second = parseFloat(parsed[1]?.replace(',', '.'));

    const jtsk = { x: xFirst ? first : second, y: xFirst ? second : first };

    if (!isNaN(first) && !isNaN(second)) {
      if (lastPolygon.some((item) => item.x === jtsk.x && item.y === jtsk.y)) {
        allPolygons = [...allPolygons, lastPolygon];
        lastPolygon = [];
      } else {
        lastPolygon = [...lastPolygon, jtsk];
      }
    }
  }

  return { allPolygons, lastPolygon, parsedConfig };
};

export function ProtectedDepositAreasMap() {
  const coordinates = useFormSelector(
    (data: ProtectedDepositArea) => data?.coordinates
  );

  const { allPolygons } = parseCoordinates(coordinates ?? '');
  const flattenCoordinates = flattenDeep(allPolygons);

  return (
    <>
      <MapToolbar />
      <Map
        height={350}
        defaultJtskPosition={
          flattenCoordinates.length > 0 ? flattenCoordinates : undefined
        }
        coordinatesFormat="JTSK"
      >
        <GeometryLayer>
          {allPolygons.map(
            (polygonCoordinates: JtskCoordinates[], idx: number) => (
              <Polygon
                key={idx}
                options={{ opacity: 0 }}
                points={polygonCoordinates}
              />
            )
          )}
        </GeometryLayer>
      </Map>
    </>
  );
}

import React from 'react';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TelegramIcon from '@material-ui/icons/Telegram';
import { DetailToolbarButtonMenuItem } from '@eas/common-web';
import { useRedirect } from './actions/redirect-hook';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import { DetailToolbarButtonMenu } from '../../components/evidence/evidence/detail-toolbar-button-menu';

type IconType = OverridableComponent<
  SvgIconTypeMap<Record<string, unknown>, 'svg'>
>;

function useToolbarItems() {
  /**
   * Array of IRZ ACTIONS.
   */
  const actionItems: DetailToolbarButtonMenuItem[] = [];

  const addAction = (label: string, Icon: IconType, onClick: () => void) =>
    actionItems.push({
      label,
      Icon: <Icon fontSize="small" htmlColor="black" />,
      onClick,
    });

  const {
    handleRedirect,
    showRedirectButton,
    handleNewRegistration,
    showNewRegistrationButton,
    handleAllRequests,
  } = useRedirect();

  if (showNewRegistrationButton) {
    addAction('Nová registrace provozovny', PlusOneIcon, handleNewRegistration);
  }
  if (showRedirectButton) {
    addAction('Žádost', TelegramIcon, handleRedirect);
  }
  addAction('Všechny žádosti', ListAltIcon, handleAllRequests);

  return {
    actionItems,
  };
}

export function IrzFacilitiesToolbar() {
  const { actionItems } = useToolbarItems();

  return (
    <>
      <DetailToolbarButtonMenu label="Otevřít" items={actionItems} />
    </>
  );
}

import { abortableFetch } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { ProcessForm } from './ovz-facility-new-types';

export function createOvzFacility(body: ProcessForm) {
  return abortableFetch(`${EvidenceAPI.OVZ_FACILITIES}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(body),
  });
}

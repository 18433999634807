import React from 'react';
import {
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export default function DictionaryRuianCommissionedLocalAuthorityMunicipalityFields() {
  const prefix = 'RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY';

  return (
    <>
      <FormPanel label="Správní obvod obce s rozšířenou působností">
        <FormTextField
          name="extendedCompetenceMunicipality.name"
          label={
            <HelpLabel
              label="Název"
              code={`DICT_${prefix}_EXTENDED_COMPETENCE_MUNICIPALITY_NAME`}
            />
          }
        />
        <FormTextField
          name="extendedCompetenceMunicipality.isuiCode"
          label={
            <HelpLabel
              label="Kód v ISÚI"
              code={`DICT_${prefix}_EXTENDED_COMPETENCE_MUNICIPALITY_CODE`}
            />
          }
        />
        <FormCheckbox
          name="extendedCompetenceMunicipality.active"
          label={
            <HelpLabel
              label="Aktivní"
              code={`DICT_${prefix}_EXTENDED_COMPETENCE_MUNICIPALITY_ACTIVE`}
            />
          }
        />
        <FormDateTimeField
          name="extendedCompetenceMunicipality.created"
          label={
            <HelpLabel
              label="Vytvoření"
              code={`DICT_${prefix}_EXTENDED_COMPETENCE_MUNICIPALITY_CREATED`}
            />
          }
        />
      </FormPanel>
    </>
  );
}

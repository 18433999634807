import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { OutagesFields } from './outages-fields';
import { useColumns } from './outages-columns';
import { useValidationSchema } from './outages-schema';
import { EvidenceAPI, PageKey, Permission, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { Outage } from '../../models/outage';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';
import { useHistoryDescriptors } from './outages-history';

export function Outages() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<Outage>({
    options: {
      NEW: [Permission.Outage.OUTAGE_CREATE],
      EDIT: [Permission.Outage.OUTAGE_UPDATE],
      REMOVE: [Permission.Outage.OUTAGE_DELETE],
      CHECK: [Permission.Outage.OUTAGE_CREATE, Permission.Outage.OUTAGE_UPDATE],
      SAVE: [Permission.Outage.OUTAGE_CREATE, Permission.Outage.OUTAGE_UPDATE],
      CLOSE: [Permission.Outage.OUTAGE_CREATE, Permission.Outage.OUTAGE_UPDATE],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<Outage>({
    version: 2,
    identifier: PageKey.OUTAGES,
    apiProps: {
      url: EvidenceAPI.OUTAGES,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled odstávek a výpadků',
      reportTag: ExportTags.OUTAGES,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.OUTAGE,
      FieldsComponent: OutagesFields,
      toolbarProps: {
        title: 'Odstávka/Výpadek',
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
      validationSchema,
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Outage.OUTAGE_LIST,
    },
  });

  return <Evidence2 {...evidence} />;
}

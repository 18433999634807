import { DictionaryNaceCzAutocomplete } from '../../models';
import { useAutocompleteSource, ApiFilterOperation } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useNaceCzItems() {
  return useAutocompleteSource<DictionaryNaceCzAutocomplete>({
    url: EvidenceAPI.DICTIONARY_CZ_NACES + '/autocomplete',
    params: {
      filters: [
        {
          field: 'leaf',
          operation: ApiFilterOperation.EQ,
          value: true,
        },
      ],
    },
  });
}

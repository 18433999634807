import { DictionaryLegalFormAutocomplete } from '../../models';
import { useAutocompleteSource } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';

export function useDictionaryLegalForms() {
  return useAutocompleteSource<DictionaryLegalFormAutocomplete>({
    url: EvidenceAPI.DICTIONARY_LEGAL_FORMS + '/autocomplete',
    params: {
      sort: [{ field: 'name', type: 'FIELD', order: 'ASC' }],
    },
  });
}

import { useContext, useEffect, useMemo, useState } from 'react';
import { MapContext } from '../../../components/map/map-ctx';
import { fetchIrzFacility } from '../../irz-facility/irz-facilities-api';
import { fetchOvzFacility } from '../../ovz-facility/ovz-facilities-api';
import { fetchProductionSite } from '../../production-site/production-sites-api';
import { Area } from '../overview-map-types';

export function useMarkerData<DATA>() {
  const [data, setData] = useState<DATA | undefined>();

  const { map, isLoaded } = useContext(MapContext);

  const fetcher = (area: Area) => {
    switch (area) {
      case Area.IRZ_FACILITY:
        return fetchIrzFacility;
      case Area.OVZ_FACILITY:
        return fetchOvzFacility;
      default:
        return fetchProductionSite;
    }
  };

  useEffect(() => {
    if (isLoaded) {
      const loadFacilityData = async (id: string, area: Area) => {
        setData(undefined);
        const getData = fetcher(area);
        const facilityData = await getData(id).json();

        if (facilityData) {
          setData({ ...facilityData, type: area });
        }
      };

      const markerClickListener = map
        ?.getSignals()
        .addListener(window, 'marker-click', function (e) {
          const marker = (e as any).target;
          const signalFacilityId = marker.getId();

          if (signalFacilityId.toString().includes('type=')) {
            const type = signalFacilityId.substring(
              signalFacilityId.indexOf('=') + 1,
              signalFacilityId.lastIndexOf('&')
            );
            const id = signalFacilityId.substring(
              signalFacilityId.lastIndexOf('=') + 1
            );

            loadFacilityData(id, type);
          }
        });

      return () => {
        if (markerClickListener) {
          map?.getSignals().removeListener(markerClickListener);
        }
      };
    }
  }, [isLoaded]);

  return useMemo(
    () => ({
      data,
    }),
    [data]
  );
}

/**
 * SOAP messages permissions
 */
export enum PermissionSOAPMessage {
  // Přehled zpráv SOAP klienta
  WS_CLIENT_MESSAGE_LIST = 'WS_CLIENT_MESSAGE_LIST',

  // Detail zpráv SOAP klienta
  WS_CLIENT_MESSAGE_DETAIL = 'WS_CLIENT_MESSAGE_DETAIL',
}

import { OvzFacilityAutocomplete } from '../../../models/ovz-facility';
import { useAutocompleteSource, ApiFilterOperation } from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';

/**
 * Source of available ovz facilities for specified subject
 */
export function useOvzFacilities(subjectId?: string) {
  return useAutocompleteSource<OvzFacilityAutocomplete>({
    url: `${EvidenceAPI.OVZ_FACILITIES}/autocomplete`,
    params: {
      filters: [
        {
          field: 'operator.id',
          operation: ApiFilterOperation.EQ,
          value: subjectId,
        },
      ],
    },
    apiUrl: EvidenceAPI.OVZ_FACILITIES,
  });
}

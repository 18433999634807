import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
  UserContext,
} from '@eas/common-web';
import { RoleInstance, SubjectAutocomplete, Me } from '../../models';
import { useRoleInstanceTypes, useRoles } from './role-instances-api';
import { useSubjects } from '../subject/subjects-api';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { useSystems } from '../system/systems-api';
import { useContext } from 'react';
import { Permission } from '../../enums';

export function useColumns(): TableColumn<RoleInstance>[] {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const columns: TableColumn<RoleInstance>[] = [
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useRoleInstanceTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(useRoleInstanceTypes),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'role.name',
      sortkey: 'role.name',
      filterkey: 'role.id',
      name: 'Role',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useRoles,
        dictionaryLabelMapper
      ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];

  const userColumns: TableColumn<RoleInstance>[] = [
    {
      datakey: 'user.username',
      filterkey: 'user.username',
      name: 'Uživatelské jméno',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'user.firstName.value',
      filterkey: 'user.firstName',
      sortkey: 'user.firstName',
      name: 'Jméno',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'user.lastName.value',
      filterkey: 'user.lastName',
      sortkey: 'user.lastName',
      name: 'Příjmení',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
  ];

  const subjectColumns: TableColumn<RoleInstance>[] = [
    {
      datakey: 'subject.name',
      filterkey: 'subject.id',
      sortkey: 'subject.name',
      name: 'Subjekt',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory<
        SubjectAutocomplete
      >(useSubjects, dictionaryLabelMapper),
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'subject.identifier',
      name: 'Subjekt - IČO / UID',
      width: 300,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
  ];

  const systemColumns: TableColumn<RoleInstance>[] = [
    {
      datakey: 'system',
      sortkey: 'system.name',
      filterkey: 'system.id',
      name: 'AIS',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSystems,
        (item) => item?.label ?? '-'
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
    {
      datakey: 'role.registeredFor',
      sortkey: 'role.registeredFor.name',
      filterkey: 'role.registeredFor.id',
      name: 'Systém',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSystems,
        (item) => item?.label ?? '-'
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 4,
    },
  ];

  if (
    hasPermission(Permission.Role.ROLE_ASSIGN_RELATIONSHIP_ROLE) ||
    hasPermission(Permission.Role.ROLE_ASSIGN_USER_ROLE) ||
    hasPermission(Permission.Role.ROLE_ASSIGN_SECURITY_ROLE) ||
    hasPermission(Permission.Role.ROLE_ASSIGN_SELECTED)
  ) {
    columns.push(...userColumns);
  }

  if (
    hasPermission(Permission.Role.ROLE_ASSIGN_RELATIONSHIP_ROLE) ||
    hasPermission(Permission.Role.ROLE_ASSIGN_USER_ROLE) ||
    hasPermission(Permission.Role.ROLE_ASSIGN_SELECTED)
  ) {
    columns.push(...subjectColumns);
  }

  if (
    hasPermission(Permission.Role.ROLE_ASSIGN_SYSTEM_ROLE) ||
    hasPermission(Permission.Role.ROLE_ASSIGN_SELECTED)
  ) {
    columns.push(...systemColumns);
  }

  columns.push(
    {
      datakey: 'validFrom',
      name: 'Platnost od',
      width: 150,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'validTo',
      name: 'Platnost do',
      width: 150,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      CellComponent: inactiveColoredFactory(TableCells.DateTimeCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'active',
      name: 'Aktivní',
      width: 100,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    }
  );

  return columns;
}

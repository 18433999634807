import React, { useContext, useRef, useState } from 'react';
import {
  useEventCallback,
  useFormSelector,
  FormContext,
  FormHandle,
  AbortableFetch,
  abortableFetch,
  ApiFilterOperation,
  SnackbarContext,
} from '@eas/common-web';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { ProcessForm } from '../irz-facility-request-operator-change-types';
import { StepOriginalOperatorForm } from './step-original-operator-types';
import { EvidenceAPI, Messages } from '../../../enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

function apiCall(identifier: string) {
  return abortableFetch(`${EvidenceAPI.SUBJECTS}/autocomplete/with-irz`, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      size: 1,
      filters: [
        {
          operation: ApiFilterOperation.OR,
          filters: [
            {
              field: 'artificialId',
              operation: ApiFilterOperation.EQ,
              value: identifier,
            },
            {
              field: 'ico',
              operation: ApiFilterOperation.EQ,
              value: identifier,
            },
          ],
        },
      ],
    }),
  });
}

export function StepOriginalOperatorActions({
  handleNext,
  handleBack,
  handleExit,
  allSteps,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  const { showSnackbar } = useContext(SnackbarContext);
  const { setFieldValue } = useContext(FormContext);

  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormHandle>(null);
  const fetch = useRef<AbortableFetch | null>(null);

  const { subjectIdentifier } = useFormSelector(
    (values: StepOriginalOperatorForm) => ({
      subjectIdentifier: values?.subjectIdentifier,
    })
  );

  const handleNextWithSubjectLoad = useEventCallback(async () => {
    formRef.current?.validateForm();

    if (subjectIdentifier) {
      try {
        setLoading(true);
        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = apiCall(subjectIdentifier);
        const result = await fetch.current.json();
        setLoading(false);
        if (result.count !== 1) {
          showSnackbar(
            ...Messages.IrzFacilityRequest.REQUEST_OPERATOR_CHANGE_SUBMIT
              .SUBJECT_DOEST_NOT_EXIST
          );
          setFieldValue('subject', undefined);
        } else {
          setFieldValue('subject', result.items[0]);

          if (allSteps[1].values.subject !== result.items[0]) {
            allSteps[2].values = {};
          }
          handleNext();
        }
      } catch (err) {
        if (err.name !== 'AbortError') {
          throw err;
        }
        setLoading(false);
        return undefined;
      }
    }
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithSubjectLoad}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Další
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} />
      </div>
    </div>
  );
}

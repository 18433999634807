import * as Yup from 'yup';
import { IrzFacility } from '../../../models';
import { StepEditForm } from './step-edit-types';
import { wgs84CoordinatesSchema } from '../../../components/form/address-field/address-field-schema';
import { validateDistrict } from '../../production-site/production-sites-schema';

export function useStepEditSchema() {
  return Yup.object<StepEditForm>().shape({
    editData: Yup.object<IrzFacility>().shape({
      wgs84Coordinates: wgs84CoordinatesSchema(),
      inspireId: Yup.string().nullable().required('Povinné pole'),
      name: Yup.string().nullable().required('Povinné pole'),
      district: validateDistrict,
    }),
  });
}

import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';

export const useStyles = makeStyles(() => ({
  textfield: {
    height: 32,
  },
  input: {
    backgroundColor: '#fff',
    padding: '3px 8px',
    height: 32,
  },
}));

interface FilterCellProps {
  value: string;
  onChange: (value: any) => void;
}

export function SpecialFilterTextCell({ value, onChange }: FilterCellProps) {
  const classes = useStyles();

  return (
    <TextField
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      variant="outlined"
      autoComplete="off"
      classes={{ root: classes.textfield }}
      InputProps={{
        classes: { root: classes.input },
        endAdornment: (
          <>
            {!!value && (
              <IconButton size="small" onClick={() => onChange('')}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </>
        ),
      }}
      inputProps={{
        style: {
          padding: '3px 5px',
        },
      }}
    />
  );
}

import React, { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
  DetailMode,
  FormTextArea,
  FormContext,
  useFormSelector,
  TableFieldCellProps,
  Checkbox,
  FormPanel,
  FormInlineTableField,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import {
  EmpowermentRequestUnion,
  EmpowermentRequestState,
  Subject,
  EmpowermentSpecificationApprovalState,
  SpecifiedEmpowermentRequest,
  EmpowermentSpecificationRequest,
} from '../../../models';
import { EvidenceAPI, Messages, Permission } from '../../../enums';
import { EmpowermentSpecificationToolbarFactory } from '../empowerment-specification-toolbar';
import { useValidationSchema } from '../empowerment-requests-schema';

/**
 * Api call
 *
 * @param id
 */
export function callApi(id: string, body: Record<string, any>) {
  return abortableFetch(`${EvidenceAPI.EMPOWERMENT_REQUESTS}/${id}/resolve`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(body),
  });
}

type GeneralRequestForm = EmpowermentRequestUnion & {
  subject: Subject;
};

type SpecifiedRequestForm = SpecifiedEmpowermentRequest & {
  subject: Subject;
};

function createInitialValues(
  data: GeneralRequestForm | SpecifiedRequestForm | null
) {
  const formInitialValues = {
    resolveState: 'REJECT',
    subject: data?.subject ?? {},
    specificationRequests:
      (data as SpecifiedRequestForm)?.specificationRequests
        ?.filter(
          (spec) =>
            spec.approvalState === EmpowermentSpecificationApprovalState.NONE
        )
        .sort((a, b) => {
          if (a.specification.name! < b.specification.name!) {
            return -1;
          }
          if (a.specification.name! > b.specification.name!) {
            return 1;
          }
          return 0;
        }) ?? [],
  };

  return formInitialValues;
}

/**
 * Reject `BASIC Empowerment request`.
 */
const PROMPT_KEY = 'REJECT';

export function useRejectDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source, mode, cancelEditing } = useContext<
    DetailHandle<GeneralRequestForm>
  >(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Zamítnutí žádosti',
        dialogText: 'Skutečně chcete zamítnout žádost o zmocnění?',
        FormFields: function Body() {
          const { editing, setFieldValue } = useContext(FormContext);

          const { specificationRequests } = useFormSelector(
            (data: {
              specificationRequests: EmpowermentSpecificationRequest[];
            }) => ({
              specificationRequests: data.specificationRequests,
            })
          );

          const checked = specificationRequests.filter(
            (spec) =>
              spec.approvalState ===
              EmpowermentSpecificationApprovalState.REJECTED
          );

          const CheckboxCell = ({
            index,
          }: TableFieldCellProps<EmpowermentSpecificationRequest>) => {
            const request = specificationRequests[index];

            const isActive =
              request.approvalState !==
              EmpowermentSpecificationApprovalState.NONE;

            const handleChange = () => {
              if (!isActive) {
                setFieldValue(`specificationRequests[${index}]`, {
                  ...request,
                  approvalState: EmpowermentSpecificationApprovalState.REJECTED,
                });
              } else {
                setFieldValue(`specificationRequests[${index}]`, {
                  ...request,
                  approvalState: EmpowermentSpecificationApprovalState.NONE,
                });
              }
            };

            return (
              <Checkbox
                value={!!isActive}
                onChange={handleChange}
                disabled={!editing}
              />
            );
          };

          return (
            <>
              <Typography>Vyplňte pole poznámka.</Typography>
              <br />
              <br />
              <FormPanel label="Poznámka">
                <FormTextArea
                  name="note"
                  labelOptions={{
                    hide: true,
                  }}
                />
              </FormPanel>
              {specificationRequests?.length !== 0 && (
                <FormPanel
                  label="Typ hlášení"
                  summary={`[${checked.length} / ${specificationRequests?.length}]`}
                  expandable={true}
                  defaultExpanded={true}
                >
                  <FormInlineTableField<EmpowermentSpecificationRequest>
                    name="specificationRequests"
                    disabled
                    labelOptions={{ hide: true }}
                    layoutOptions={{ noSpacing: true }}
                    maxRows={10}
                    ToolbarComponent={EmpowermentSpecificationToolbarFactory(
                      EmpowermentSpecificationApprovalState.REJECTED
                    )}
                    columns={[
                      {
                        name: '',
                        datakey: 'active',
                        width: 50,
                        CellComponent: CheckboxCell,
                      },
                      {
                        name: 'Název',
                        datakey: 'specification.name',
                        width: 200,
                      },
                    ]}
                  />
                </FormPanel>
              )}
            </>
          );
        },
        formValidationSchema: useValidationSchema(),
        formInitialValues: createInitialValues(source.data),
        dialogWidth: 600,
      },
    ],
    [source.data]
  );

  const handleReject = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: {
        resolveState: 'REJECT';
        note?: string;
        specificationRequests: EmpowermentSpecificationRequest[];
      }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          const resolveObj: Record<string, any> = {
            resolveState: values?.resolveState,
            note: values?.note,
          };

          if (values?.specificationRequests?.length) {
            resolveObj[
              'rejectedSpecifications'
            ] = values?.specificationRequests?.filter(
              (spec) =>
                spec.approvalState ===
                EmpowermentSpecificationApprovalState.REJECTED
            );
          }

          fetch.current = callApi(source.data!.id, resolveObj);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.EmpowermentRequest.REJECT.SUCCESS);
            source.setLoading(false);
          });

          cancelEditing();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            showSnackbar(...Messages.EmpowermentRequest.REJECT.ERROR);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showRejectButton =
    mode === DetailMode.VIEW &&
    source.data?.state === EmpowermentRequestState.AWAITING_DECISION &&
    hasPermission(Permission.Empowerment.EMPOWERMENT_REQUEST_FINISH);

  return {
    handleReject,
    showRejectButton,
  };
}

import React, { useContext, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { formFieldFactory, Form, FormSubmitButton } from '@eas/common-web';
import {
  publicFieldFactory,
  EmptyWrapper,
} from '../../components/form/public-field/public-field';
import {
  BreadcrumbCtx,
  initialBreadcrumbs,
} from '../../components/breadcrumb/breadcrumb-ctx';
import { PageKey } from '../../enums';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { usePasswordResetConfirm } from './hook/password-reset-confirm-hook';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { passwordRules } from '../profile/profile-utils';
import { PasswordResetConfirmData } from './auth-types';

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      marginBottom: 50,
    },
    buttonText: {
      textTransform: 'initial',
      padding: `0 ${theme.spacing(2)}px`,
    },
    buttonTextPrimary: {
      fontWeight: 600,
      color: 'white',
    },
    input: {
      fontSize: 22,
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const initialValues: PasswordResetConfirmData = {
  newPassword: '',
  newPasswordAgain: '',
};

const NewPasswordField = formFieldFactory(
  publicFieldFactory({
    label: 'Nové heslo',
    inputProps: { type: 'password' },
  }),
  EmptyWrapper
);

const NewPasswordAgainField = formFieldFactory(
  publicFieldFactory({
    label: 'Nové heslo znovu',
    inputProps: { type: 'password' },
  }),
  EmptyWrapper
);

export function PasswordResetConfirm() {
  const classes = useStyles();

  const { setBreadcrumbs } = useContext(BreadcrumbCtx);

  const {
    validationSchema,
    ref,
    loading,
    handleSubmit,
    handleNavigateBack,
    handleNavigateToReset,
    getFieldError,
    secretIsValid,
  } = usePasswordResetConfirm();

  useEffect(() => {
    setBreadcrumbs([
      ...initialBreadcrumbs.breadcrumbs,
      {
        title: 'Vytvoření nového hesla',
        key: PageKey.PASSWORD_RESET,
        to: '/obnova-hesla',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (secretIsValid === undefined) {
    return null;
  }

  return (
    <>
      {!secretIsValid ? (
        <>
          <Typography variant="body1" align="center">
            Platnost Vašeho odkazu vypršela, nebo už byl využit. Pro opětovné
            vytvoření nového hesla klikněte na tlačítko níže.
          </Typography>
          <Box my={3} display="flex" flexDirection="column" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleNavigateToReset}
            >
              <Typography
                component="span"
                variant="body1"
                className={classes.buttonText}
              >
                Vytvoření nového hesla
              </Typography>
            </Button>
          </Box>
        </>
      ) : (
        <Form<PasswordResetConfirmData>
          ref={ref}
          editing={true}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={5}>
            {/* ROW 1 */}
            <Grid item md={4} />
            <Grid item md={4}>
              <NewPasswordField
                name="newPassword"
                error={getFieldError('newPassword') ? true : false}
                helperText={
                  getFieldError('newPassword') ??
                  `Musí splňovat: ${passwordRules}`
                }
              />
            </Grid>
            <Grid item md={4} />
            {/* ROW 2 */}
            <Grid item md={4} />
            <Grid item md={4}>
              <NewPasswordAgainField
                name="newPasswordAgain"
                error={getFieldError('newPasswordAgain') ? true : false}
                helperText={getFieldError('newPasswordAgain')}
              />
            </Grid>
            <Grid item md={4} />
            {/* ROW 3 */}
            <Grid item md={3} />
            <Grid item md={6}>
              <Typography align="center" component="div">
                <Button
                  variant="contained"
                  color="default"
                  disableElevation
                  onClick={handleNavigateBack}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.buttonText}
                  >
                    Zpět k přihlášení
                  </Typography>
                </Button>
                <FormSubmitButton
                  variant="contained"
                  disableElevation
                  disabled={loading}
                  color="primary"
                  type="submit"
                  style={{
                    marginLeft: 32,
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(
                      classes.buttonText,
                      classes.buttonTextPrimary
                    )}
                  >
                    Pokračovat
                  </Typography>
                </FormSubmitButton>
              </Typography>
            </Grid>
            <Grid item md={3} />
          </Grid>
        </Form>
      )}
    </>
  );
}

import { Address } from '../../../../models';

export function useAddressLabel(address?: Address) {
  const label = [];

  let streetLabel;

  // Obec
  if (address?.municipalityRuian) {
    streetLabel = address?.municipalityRuian.name;
  } else if (address?.municipality) {
    streetLabel = address?.municipality;
  }

  // Ulice
  if (address?.streetRuian) {
    streetLabel += `${streetLabel ? ', ' : ''}${address?.streetRuian.name}`;
  } else if (address?.street) {
    streetLabel += `${streetLabel ? ', ' : ''}${address?.street}`;
  }

  // Číslo domovní, orientační a znak čísla orientačního
  if (address?.houseNumber) {
    streetLabel = `${streetLabel ? streetLabel : ''} ${address.houseNumber}`;

    if (address?.orientationNumber) {
      streetLabel += `/${address.orientationNumber}`;

      if (address?.orientationNumberMark) {
        streetLabel += address?.orientationNumberMark;
      }
    }
  }

  if (streetLabel) {
    label.push(streetLabel);
  }

  // PSČ
  if (address?.zip) {
    const zip = address.zip;
    if (zip.length === 5) {
      label.push(`${zip.substring(0, 3)} ${zip.substring(3, 5)}`);
    } else {
      label.push(zip);
    }
  }

  // Část obce
  if (address?.municipalityPartRuian) {
    label.push(address?.municipalityPartRuian.name);
  } else if (address?.municipalityPart) {
    label.push(address?.municipalityPart);
  }

  // Městská část
  if (address?.cityDistrictRuian) {
    label.push(address?.cityDistrictRuian.name);
  } else if (address?.cityDistrict) {
    label.push(address?.cityDistrict);
  }

  // Okres
  if (address?.districtRuian) {
    label.push(address?.districtRuian.name);
  } else if (address?.district) {
    label.push(address?.district);
  }

  // Kraj
  if (address?.regionRuian) {
    label.push(address?.regionRuian.name);
  } else if (address?.region) {
    label.push(address?.region);
  }

  // Adresní místo
  if (label.length === 0) {
    if (address?.addressPlace) {
      label.push(address.addressPlace);
    } else if (address?.addressPlaceRuian) {
      label.push(address.addressPlaceRuian.label);
    }
  }

  // Stát
  if (address?.country) {
    label.push(address?.country?.name);
  }

  return label.length !== 0 ? label.join(', ') : '-';
}

/**
 * Scheduled task permissions
 */
export enum PermissionScheduledTask {
  /** Přehled pravidelně spuštěných úloh */
  SCHEDULED_TASK_LIST = 'SCHEDULED_TASK_LIST',

  /** Detail pravidelně spuštěných úloh */
  SCHEDULED_TASK_DETAIL = 'SCHEDULED_TASK_DETAIL',

  /** Manuální spuštění úlohy */
  SCHEDULED_TASK_RUN = 'SCHEDULED_TASK_RUN',

  /** Manuální zastavení spuštěné úlohy */
  SCHEDULED_TASK_STOP = 'SCHEDULED_TASK_STOP',

  /** Zneplatnění pravidelně spouštěné úlohy*/
  SCHEDULED_TASK_INVALIDATE = 'SCHEDULED_TASK_INVALIDATE',

  /** Obnovení pravidelně spouštěné úlohy*/
  SCHEDULED_TASK_REVALIDATE = 'SCHEDULED_TASK_REVALIDATE',
}

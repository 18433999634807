import React, { useContext, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  AuthoredObject,
  DetailContainer,
  EmptyComponent,
  DetailContext,
  DetailMode,
} from '@eas/common-web';
import logo from './../../../menu/icon.svg';
import { HistoryFields } from './../history-fields';
import { DetailProps, HistoryFieldDescriptor } from '../history-types';

const useStyles = makeStyles({
  logo: {
    width: '40px',
    height: '40px',
    marginBottom: 25,
  },
  blankScreen: {
    position: 'absolute',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 186px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  indent: {
    height: 100,
    width: '100%',
  },
});

export function historyContainerFactory<OBJECT extends AuthoredObject>({
  detailProps,
  historyVisible,
  historyPermission,
  historyDescriptors,
}: {
  detailProps: DetailProps<OBJECT>;
  historyVisible: boolean;
  historyPermission: string;
  historyDescriptors?: HistoryFieldDescriptor[];
}) {
  return function Container(props: PropsWithChildren<unknown>) {
    const classes = useStyles();
    const { mode } = useContext(DetailContext);

    const Container = detailProps?.ContainerComponent || DetailContainer;

    const canCreate = detailProps?.toolbarProps?.showBtn?.('NEW');

    return mode === DetailMode.NONE ? (
      <>
        <div className={classes.blankScreen}>
          <img src={logo} alt="logo" className={classes.logo} />
          <Typography variant="body1" align="center">
            Vyberte některou z <b>položek ze seznamu vlevo</b>
            {canCreate && (
              <>
                , nebo klikněte na tlačítko <b>NOVÝ</b>
              </>
            )}
            .
          </Typography>
        </div>
      </>
    ) : (
      <>
        <Container {...props}>
          {React.Children.toArray(props.children).map((child) => {
            if (typeof child !== 'string' && typeof child !== 'number') {
              return React.cloneElement(child as React.ReactElement, {
                historyVisible,
                HistoryRecords: historyDescriptors ? (
                  <HistoryFields
                    descriptors={historyDescriptors}
                    permission={historyPermission}
                  />
                ) : (
                  EmptyComponent
                ),
              });
            } else {
              return child;
            }
          })}
          <div className={classes.indent} />
        </Container>
      </>
    );
  };
}

import { SpecialFilter } from '../../components/evidence/history-evidence/overrides/table-searchbar';
import { SpecialFilterTextCell } from '../../components/evidence/history-evidence/special-filter-cells/special-filter-text-cell';

export function useSpecialFilters(): SpecialFilter[] {
  return [
    {
      label: 'Provozovatel - IČO / UID',
      filterkey: 'operator.identifier',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Provozovatel - Název',
      filterkey: 'operator.name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'IČP',
      filterkey: 'facilityId',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Název provozovny',
      filterkey: 'name',
      FilterCell: SpecialFilterTextCell,
    },
    {
      label: 'Obec',
      filterkey: 'address.municipality.name',
      FilterCell: SpecialFilterTextCell,
    },
  ];
}

import React, { useContext, useEffect } from 'react';
import { ProcessForm } from '../production-site-merge-types';
import { useStepEdit } from './step-edit-hook';
import {
  useFormSelector,
  FormPanel,
  FormTextField,
  FormTextArea,
  FormAutocomplete,
  useAutocompleteSource,
  abortableFetch,
  FormContext,
} from '@eas/common-web';
import { StepEditForm } from './step-edit-types';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';
import { RuianObject } from '../../../models';
import { EvidenceAPI } from '../../../enums';
import { HelpLabel } from '../../../components/help/help-label';

const callGuess = function (lat: number, lon: number) {
  return abortableFetch(
    `${EvidenceAPI.ADDRESS_DISCTRICT}/guess?lat=${lat}&lon=${lon}`,
    {
      method: 'GET',
    }
  );
};

export function StepEditContent({ allValues }: { allValues: ProcessForm }) {
  useStepEdit(allValues);
  const { setFieldValue } = useContext(FormContext);

  const districts = useAutocompleteSource({
    url: `${EvidenceAPI.ADDRESS_DISCTRICT}/autocomplete`,
  });

  const { wgs84Coordinates } = useFormSelector(
    ({ editData }: StepEditForm) => ({
      wgs84Coordinates: editData?.wgs84Coordinates,
    })
  );

  const guessDisctrict = async (lat: number, lon: number) => {
    const district = await callGuess(lat, lon).json();

    if (district) setFieldValue('editData.district', district);
  };

  useEffect(() => {
    if (wgs84Coordinates?.lat && wgs84Coordinates.lon) {
      guessDisctrict(wgs84Coordinates.lat, wgs84Coordinates.lon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wgs84Coordinates?.lat, wgs84Coordinates?.lon]);

  return (
    <>
      <FormPanel label="Základní informace">
        <FormTextField
          name="editData.inspireId"
          label={
            <HelpLabel
              label="Identifikátor EU"
              code="PRODUCTION_SITE_MERGE_EDIT_DATA_INSPIRE_ID"
            />
          }
          disabled
        />
        <FormTextField
          name="editData.uniqueCode"
          label={
            <HelpLabel
              label="Jedinečný kód"
              code="PRODUCTION_SITE_MERGE_EDIT_DATA_UNIQUE_CODE"
            />
          }
          disabled
        />
        <FormTextField
          name="editData.name"
          label={
            <HelpLabel
              label="Název"
              code="PRODUCTION_SITE_MERGE_EDIT_DATA_NAME"
              required
            />
          } // Povinné pole
        />
        <FormTextArea
          name="editData.note"
          label={
            <HelpLabel
              label="Poznámka"
              code="PRODUCTION_SITE_MERGE_EDIT_DATA_NOTE"
            />
          }
        />
        <FormAutocomplete<RuianObject>
          name="editData.district"
          source={districts}
          label={
            <HelpLabel
              label="Okres"
              code="PRODUCTION_SITE_MERGE_EDIT_DATA_DISTRICT"
              required
            />
          } // Povinné pole
        />
      </FormPanel>
      <LocationPanel
        locationName="editData.wgs84Coordinates"
        locationValue={wgs84Coordinates}
        showCoordinates={true}
        showCoordinatesDistance={false}
      />
    </>
  );
}

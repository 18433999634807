import { useMemo } from 'react';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepBasicInfoLabel } from './step-basic-info/step-basic-info-label';
import { StepBasicInfoContent } from './step-basic-info/step-basic-info-content';
import { stepBasicInfoSchema } from './step-basic-info/step-basic-info-schema';
import { StepLocationLabel } from './step-location/step-location-label';
import { StepLocationContent } from './step-location/step-location-content';
import { stepLocationSchema } from './step-location/step-location-schema';
import { StepIdentifiersLabel } from './step-identifiers/step-identifiers-label';
import { stepIdentifiersSchema } from './step-identifiers/step-identifiers-schema';
import { StepIdentifiersContent } from './step-identifiers/step-identifiers-content';

export function useOvzFacilityNew() {
  const steps = useMemo(
    () => [
      {
        Label: StepBasicInfoLabel,
        Content: StepBasicInfoContent,
        validationSchema: stepBasicInfoSchema,
      },
      {
        Label: StepLocationLabel,
        Content: StepLocationContent,
        validationSchema: stepLocationSchema,
      },
      {
        Label: StepIdentifiersLabel,
        Content: StepIdentifiersContent,
        validationSchema: stepIdentifiersSchema,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
    ],
    []
  );

  return { steps };
}

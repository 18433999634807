import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { useStates, usePublicLists } from './public-list-publishings-api';
import { Publishing } from '../../../models/public-list';

export function useColumns(): TableColumn<Publishing>[] {
  return [
    {
      datakey: 'list',
      sortkey: 'list.name',
      filterkey: 'list.id',
      name: 'Veřejný seznam',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        usePublicLists
      ),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'startTime',
      name: 'Začátek',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'endTime',
      name: 'Konec',
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}

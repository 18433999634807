import {
  TableColumn,
  TableCells,
  ApiFilterOperation,
  TableFilterCells,
} from '@eas/common-web';
import {
  BoilerManufacturer,
  DictionaryBoilerModel,
  DictionaryBoilerType,
  DictionaryBoilerBrand,
} from '../../models';
// import { idValueMapperFactory } from '../../composite/subject-column-mappers/id-mapper';
import { useDictionaryBoilerBrands } from '../dict-boiler-brand/dict-boiler-brands-api';
import { useDictionaryBoilerTypes } from '../dict-boiler-type/dict-boiler-types-api';
import { useDictionaryBoilerModels } from '../dict-boiler-model/dict-boiler-models-api';
// import { addressValueMapperFactory } from '../../composite/subject-column-mappers/address-mapper';

export function boilerMapperFactory(
  key: 'boilerBrands' | 'boilerTypes' | 'allowedBoilerTypes' | 'boilerModels'
) {
  type BoilerSpec =
    | DictionaryBoilerModel
    | DictionaryBoilerType
    | DictionaryBoilerBrand;

  const valueMapper = ({ rowValue }: { rowValue: BoilerManufacturer }) => {
    if ((rowValue[key] as BoilerSpec[])?.length) {
      return (rowValue[key] as BoilerSpec[])?.map((val) => val.name).join(', ');
    }

    return '';
  };

  valueMapper.displayName = 'boilerMapper';
  valueMapper.data = key;

  return valueMapper;
}

export function useColumns(): TableColumn<BoilerManufacturer>[] {
  return [
    {
      datakey: 'subject.name',
      name: 'Subjekt - Název',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'subject.identifier',
      name: 'Subjekt - IČO / UID',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'subject.identityDocumentsConcatenated',
      name: 'Subjekt - OP / pas',
      width: 300,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'subject.foreignId',
      name: 'Subjekt - Zahr. identifikátor',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'subject.approved',
      name: 'Subjekt - schválen',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    // {
    //   datakey: 'subject.residence.municipality',
    //   sortkey: 'subject.residence.municipality.name',
    //   filterkey: 'subject.residence.municipality.name',
    //   name: 'Subjekt - Obec',
    //   width: 200,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 3,
    //   valueMapper: addressValueMapperFactory(),
    // },
    // {
    //   sortkey: 'subject.residence.cityDistrict',
    //   datakey: 'subject.residence.cityDistrict.name',
    //   filterkey: 'subject.residence.cityDistrict.name',
    //   name: 'Subjekt - Městská část',
    //   width: 200,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 3,
    //   valueMapper: addressValueMapperFactory(),
    // },
    // {
    //   datakey: 'subject.residence.street',
    //   sortkey: 'subject.residence.street.name',
    //   filterkey: 'subject.residence.street.name',
    //   name: 'Subjekt - Ulice',
    //   width: 200,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 3,
    //   valueMapper: addressValueMapperFactory(),
    // },
    // {
    //   datakey: 'subject.residence.houseNumber',
    //   name: 'Subjekt - Číslo popisné',
    //   width: 200,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 3,
    //   valueMapper: addressValueMapperFactory(),
    // },
    // {
    //   datakey: 'subject.residence.orientationNumber',
    //   name: 'Subjekt - Číslo orientační',
    //   width: 200,
    //   filterOperation: ApiFilterOperation.CONTAINS,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 3,
    //   valueMapper: addressValueMapperFactory(),
    // },
    // {
    //   datakey: 'subject.residence.addressPlaceRuian.isuiCode',
    //   sortkey: 'subject.residence.addressPlace.isuiCode',
    //   filterkey: 'subject.residence.addressPlace.isuiCode',
    //   name: 'Subjekt - Adresní kód',
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   sortable: true,
    //   filterable: true,
    //   filterGroup: 3,
    // },
    {
      datakey: 'boilerBrands.id',
      name: 'Značky kotlů',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: boilerMapperFactory('boilerBrands'),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDictionaryBoilerBrands
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'boilerModels.id',
      name: 'Modely kotlů',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: boilerMapperFactory('boilerModels'),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDictionaryBoilerModels
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'allowedBoilerTypes.id',
      name: 'Povolené typy kotlů',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: boilerMapperFactory('allowedBoilerTypes'),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDictionaryBoilerTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'boilerTypes.id',
      name: 'Vyráběné typy kotlů',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: boilerMapperFactory('boilerTypes'),
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDictionaryBoilerTypes
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
  ];
}

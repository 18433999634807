import { useEffect, useContext, useState } from 'react';
import { get } from 'lodash';
import {
  useAutocompleteSource,
  abortableFetch,
  ApiFilterOperation,
  ResultDto,
  useListSource,
  Params,
  useFormSelector,
  FormContext,
  Filter,
} from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import {
  IrzFacilityAutocomplete,
  IrzFacilityRequest,
  DictionaryEPrtrActivity,
  DictionaryIppcActivity,
  IrzFacility,
  MainActivityType,
} from '../../models';
import { DictionaryAutocomplete } from '../../models/dictionary-object';
import { post } from '../../utils/custom-crud';
import {
  IrzRequestType,
  IrzRequestState,
} from '../../models/irz-facility-request';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import {
  useEPrtrActivities,
  getEPrtrActivity,
} from '../dict-e-prtr-activity/dict-e-prtr-activities-api';
import {
  useIppcActivities,
  getIppcActivity,
} from '../dict-ippc-activity/dict-ippc-activities-api';

export function useMainActivities() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.IRZ_FACILITIES + '/main-activity/type/list',
    method: 'GET',
  });
}

export function useIrzFacilities(params?: Params, apiUrl?: string) {
  return useAutocompleteSource<IrzFacilityAutocomplete>({
    url: `${EvidenceAPI.IRZ_FACILITIES}/autocomplete`,
    apiUrl: apiUrl,
    params,
  });
}

export function useIrzFacilitiesWithoutProductionSite(id?: string) {
  const filters = id
    ? [
        {
          operation: ApiFilterOperation.OR,
          filters: [
            {
              field: 'productionSite.id',
              operation: ApiFilterOperation.IS_NULL,
            },
            {
              field: 'productionSite.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        },
      ]
    : [
        {
          field: 'productionSite.id',
          operation: ApiFilterOperation.IS_NULL,
        },
      ];

  return useAutocompleteSource<IrzFacilityAutocomplete>({
    url: `${EvidenceAPI.IRZ_FACILITIES}/autocomplete`,
    params: {
      filters,
    },
  });
}

export function fetchIrzFacility(id: string) {
  return abortableFetch(`${EvidenceAPI.IRZ_FACILITIES}/${id}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

export async function combineGetCalls({ signal, mainItem }: CombineCallsType) {
  const [activeInvalidation] = await post<ResultDto<IrzFacilityRequest>>({
    url: `${EvidenceAPI.IRZ_FACILITY_REQUESTS}/list`,
    signal,
    body: {
      sort: [{ field: 'created', order: 'ASC', type: 'FIELD' }],
      filters: [
        {
          field: 'requestFacilityData.facilityId',
          operation: ApiFilterOperation.EQ,
          value: `${mainItem?.facilityId}`,
        },
        {
          field: 'state.id',
          operation: ApiFilterOperation.EQ,
          value: IrzRequestState.WAITING_FOR_AUTHORIZATION,
        },
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: IrzRequestType.INVALIDATION,
        },
      ],
      include: [],
      size: 1,
    },
  });

  const [facilityReg] = await post<ResultDto<IrzFacilityRequest>>({
    url: `${EvidenceAPI.IRZ_FACILITY_REQUESTS}/list`,
    signal,
    body: {
      sort: [{ field: 'created', order: 'ASC', type: 'FIELD' }],
      filters: [
        {
          field: 'requestFacilityData.facilityId',
          operation: ApiFilterOperation.EQ,
          value: `${mainItem?.facilityId}`,
        },
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: IrzRequestType.REGISTRATION,
        },
      ],
      include: [],
      size: 1,
    },
  });

  const json = {
    ...mainItem,
    facilityRegistration: facilityReg?.items?.[0] ?? undefined,
    activeInvalidation: activeInvalidation?.items?.[0] ?? undefined,
  };

  const text = JSON.stringify(json);

  return {
    data: json,
    text,
  };
}

export function useEuStates() {
  return useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.IRZ_FACILITIES + '/eu-state/list',
    method: 'GET',
  });
}

export function useEPrtrAndIppcActivities(prefix = '') {
  const [eprtrFilter, setEprtrFilter] = useState<Filter | null>(null);
  const [ippcFilter, setIppcFilter] = useState<Filter | null>(null);

  const ePrtrActivities = useEPrtrActivities(eprtrFilter);
  const ippcActivities = useIppcActivities(ippcFilter);

  const { setFieldValue, editing } = useContext(FormContext);

  const prefixPath = prefix ? `${prefix}.` : '';

  const { ePrtrActivity, ippcActivity, activityTypes } = useFormSelector(
    (values: IrzFacility) => {
      return {
        ePrtrActivity: get(
          values,
          `${prefixPath}mainEprtrActivity.activity`
        ) as DictionaryEPrtrActivity,
        ippcActivity: get(
          values,
          `${prefixPath}ippcActivity`
        ) as DictionaryIppcActivity,
        activityTypes: get(
          values,
          `${prefixPath}activityTypes`
        ) as MainActivityType[],
      };
    }
  );

  useEffect(() => {
    const setIppcActivity = async () => {
      const wiredIppcActivities = await getIppcActivity(ePrtrActivity?.id);

      if (wiredIppcActivities.length === 1) {
        setFieldValue(`${prefixPath}ippcActivity`, wiredIppcActivities[0]);
      }

      if (wiredIppcActivities.length === 0) {
        setIppcFilter(null);
      }

      if (wiredIppcActivities.length >= 1) {
        setIppcFilter({
          field: 'eprtrActivities.id',
          operation: ApiFilterOperation.EQ,
          value: ePrtrActivity?.id,
        });
      }
    };

    if (editing && ePrtrActivity?.id) {
      setIppcActivity();
    }

    if (editing && !ePrtrActivity?.id) {
      setIppcFilter(null);
    }
  }, [ePrtrActivity?.id]);

  useEffect(() => {
    const setEprtrActivity = async () => {
      const wiredEprtrActivity = await getEPrtrActivity(ippcActivity?.id);

      if (wiredEprtrActivity.length === 1) {
        setFieldValue(
          `${prefixPath}mainEprtrActivity.activity`,
          wiredEprtrActivity[0]
        );
      }

      if (wiredEprtrActivity.length === 0) {
        setEprtrFilter(null);
      }

      if (wiredEprtrActivity.length >= 1) {
        setEprtrFilter({
          field: 'ippcActivities.id',
          operation: ApiFilterOperation.EQ,
          value: ippcActivity?.id,
        });
      }
    };

    if (
      editing &&
      ippcActivity?.id &&
      activityTypes?.includes(MainActivityType.E_PRTR)
    ) {
      setEprtrActivity();
    }

    if (
      editing &&
      !ippcActivity?.id &&
      activityTypes?.includes(MainActivityType.E_PRTR)
    ) {
      setEprtrFilter(null);
    }
  }, [ippcActivity?.id]);

  return {
    eprtrFilter,
    ippcFilter,
    ePrtrActivities,
    ippcActivities,
  };
}

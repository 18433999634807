import {
  AbortableFetch,
  defaultGetItem,
  defaultUpdateItem,
  DomainObject,
  useStaticListSource,
  ListSource,
  Params,
  defaultCreateItem,
} from '@eas/common-web';
import { post } from '../../../utils/custom-crud';
import {
  SimpleLog,
  SimpleLogEventType,
  HistoryFieldType,
  SimpleLogChange,
  ObtainedSimpleLog,
} from '../../../models';
import { v4 as uuidv4 } from 'uuid';

const parseLogChange = (
  contentItem: SimpleLogChange,
  item: ObtainedSimpleLog
) => {
  const parsedChange = [];

  if (contentItem.valueAfter?.type === HistoryFieldType.COLLECTION) {
    for (const value of contentItem.valueAfter.list) {
      parsedChange.push({
        ...item,
        id: uuidv4(),
        content: {
          key: contentItem.key,
          valueAfter: value,
        },
      });
    }
  }
  if (contentItem.valueBefore?.type === HistoryFieldType.COLLECTION) {
    for (const value of contentItem.valueBefore.list) {
      parsedChange.push({
        ...item,
        id: uuidv4(),
        content: {
          key: contentItem.key,
          valueBefore: value,
        },
      });
    }
  }
  if (
    contentItem.valueBefore?.type !== HistoryFieldType.COLLECTION &&
    contentItem.valueAfter?.type !== HistoryFieldType.COLLECTION
  ) {
    parsedChange.push({
      ...item,
      id: uuidv4(),
      content: contentItem,
    });
  }

  return parsedChange;
};

export const parseHistoryItems = (items: ObtainedSimpleLog[]) => {
  let historyItems: SimpleLog[] = [];
  for (const item of items) {
    if (item.type === SimpleLogEventType.LEGACY) {
      historyItems.push({
        ...item,
        id: uuidv4(),
        content: {
          key: item.content.type,
          value: item.content.info,
        },
      });
    }

    if (item.type === SimpleLogEventType.MIGRATION) {
      historyItems.push({
        ...item,
        id: uuidv4(),
        content: {
          key: 'Migrace z ISPOP',
          value: '',
        },
      });
    }

    if (item.type !== SimpleLogEventType.LEGACY && item.content) {
      for (const contentItem of item?.content) {
        historyItems = [...historyItems, ...parseLogChange(contentItem, item)];
      }
    }
  }

  return historyItems;
};

type GetItemType =
  | ((api: string, itemId: string) => AbortableFetch)
  | undefined;

/**
 * Factory method used in EvidenceProps -> apiProps, that enhances
 * get of entity with loading of history
 * @param originalGet Original getItem function
 */
export const historyGetFactory = (
  originalGet: GetItemType = defaultGetItem
) => (api: string, itemId: string) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const originalFetch = originalGet(api, itemId);

  return {
    abort: () => {
      controller.abort();
      originalFetch.abort();
    },
    response: new Promise<Response>(() => {}),
    json: async () => {
      const originalData = await originalFetch.json();

      const [history] = await post<ListSource<ObtainedSimpleLog>>({
        url: `${api}/${itemId}/history`,
        signal,
        body: {
          sort: [
            {
              type: 'FIELD',
              field: 'created',
              order: 'DESC',
            },
          ],
          size: -1,
        } as Params,
      });

      // parse history
      if (history?.items) {
        history.items = history.items.map((v) => ({
          ...v,
          content: JSON.parse((v?.content ?? '[]') as any),
        }));
      }

      return {
        ...originalData,
        history: history?.items ? parseHistoryItems(history.items) : [],
      };
    },
    // not needed
    raw: async () => new Promise<Response>(() => {}),
    text: async () => {},
    none: async () => {},
  };
};

type CreateItemType<Item> =
  | ((api: string, item: Item) => AbortableFetch)
  | undefined;

/**
 * Factory method used in EvidenceProps -> apiProps, that enhances
 * create of entity with loading of history
 * @param originalCreate Original createItem function
 */
export function historyCreateFactory<Item extends DomainObject>(
  originalCreate: CreateItemType<Item> = defaultCreateItem
) {
  return (api: string, item: Item) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const originalFetch = originalCreate(api, item);

    return {
      abort: () => {
        controller.abort();
        originalFetch.abort();
      },
      response: new Promise<Response>(() => {}),
      json: async () => {
        const originalData = await originalFetch.json();

        const [history] = await post<ListSource<ObtainedSimpleLog>>({
          url: `${api}/${originalData.id}/history`,
          signal,
          body: {
            sort: [
              {
                type: 'FIELD',
                field: 'created',
                order: 'DESC',
              },
            ],
            size: -1,
          } as Params,
        });

        // parse history
        if (history?.items) {
          history.items = history.items.map((v) => ({
            ...v,
            content: JSON.parse((v?.content ?? '[]') as any),
          }));
        }

        return {
          ...originalData,
          history: history?.items ? parseHistoryItems(history.items) : [],
        };
      },
      // not needed
      raw: async () => new Promise<Response>(() => {}),
      text: async () => {},
      none: async () => {},
    };
  };
}

type UpdateItemType<Item> =
  | ((api: string, item: Item, _initialItem: Item) => AbortableFetch)
  | undefined;

/**
 * Factory method used in EvidenceProps -> apiProps, that enhances
 * update call of entity with history loading
 * @param originalUpdate Original update function
 */
export function historyUpdateFactory<Item extends DomainObject>(
  originalUpdate: UpdateItemType<Item> = defaultUpdateItem
) {
  return (api: string, item: Item, _initialItem: Item) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const originalFetch = originalUpdate(api, item, _initialItem);

    return {
      abort: () => {
        controller.abort();
        originalFetch.abort();
      },
      response: new Promise<Response>(() => {}),
      json: async () => {
        const originalData = await originalFetch.json();

        const [history] = await post<ListSource<ObtainedSimpleLog>>({
          url: `${api}/${item.id}/history`,
          signal,
          body: {
            sort: [
              {
                type: 'FIELD',
                field: 'created',
                order: 'DESC',
              },
            ],
            size: -1,
          } as Params,
        });

        // parse history
        if (history?.items) {
          history.items = history.items.map((v) => ({
            ...v,
            content: JSON.parse((v?.content ?? '[]') as any),
          }));
        }

        return {
          ...originalData,
          history: history?.items ? parseHistoryItems(history.items) : [],
        };
      },
      // not needed
      raw: async () => new Promise<Response>(() => {}),
      text: async () => {},
      none: async () => {},
    };
  };
}

export function useSimpleLogEventType() {
  return useStaticListSource([
    { id: SimpleLogEventType.MIGRATION, name: 'Migrace' },
    { id: SimpleLogEventType.CREATE, name: 'Vytvoření' },
    { id: SimpleLogEventType.UPDATE, name: 'Editace' },
    { id: SimpleLogEventType.DELETE, name: 'Smazání' },
    { id: SimpleLogEventType.ACTIVATE, name: 'Obnovení' },
    { id: SimpleLogEventType.DEACTIVATE, name: 'Zneplatnění' },
    { id: SimpleLogEventType.LEGACY, name: 'Zmigrovaná změna' },
  ]);
}

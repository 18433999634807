import React from 'react';
import { ProcessForm } from '../irz-facility-merge-types';

export function StepSummaryLabel({ allValues }: { allValues?: ProcessForm }) {
  const state = allValues?.isSent ? 'ODESLÁNO' : 'NEODESLÁNO';

  return (
    <>
      Stav procesu: <b>{state}</b>
    </>
  );
}

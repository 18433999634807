import React, { useContext, useState, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ProcessActionsProps } from '../../../components/process/process-types';
import {
  useEventCallback,
  FormContext,
  SnackbarContext,
  AbortableFetch,
  abortableFetch,
  PromptContext,
  usePrompts,
  NavigationContext,
  UserContext,
} from '@eas/common-web';
import { ProcessForm } from '../subject-requests-types';
import { EvidenceAPI, Messages } from '../../../enums';
import { Subject, SubjectRegistrationType } from '../../../models';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { preprocessPhoneNumber } from '../../../components/form/phone-field/phone-field-utils';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

/**
 * Api call.
 *
 * @param body
 */
function callCreateRegistrationApi(body: ProcessForm) {
  const processData = (request: ProcessForm) => {
    const processedData = { ...request };

    if (request.agendas?.find((agenda) => agenda.id === 'WITHOUT_AGENDA')) {
      processedData['withoutAgenda'] = true;
      delete processedData.agendas;
    }

    return processedData;
  };

  return abortableFetch(`${EvidenceAPI.SUBJECT_REQUESTS}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(processData(body)),
  });
}

function callCreateSubjectApi(body: ProcessForm) {
  return abortableFetch(`${EvidenceAPI.SUBJECTS}?withUserAifo`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(
      body.agendas?.filter((a) => a.id !== 'WITHOUT_AGENDA')
    ),
  });
}

const REGISTRATION_PROMPT_KEY = 'CREATE_REGISTRATION_PROMPT';
const SUBJECT_PROMPT_KEY = 'CREATE_SUBJECT_PROMPT';

export function StepSummaryActions({
  handleNext,
  handleBack,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const { user } = useContext(UserContext);
  const classes = useStyles();

  /**
   * Loading state.
   */
  const [loading, setLoading] = useState(false);

  /**
   * Context stuff.
   */
  const { setFieldValue } = useContext(FormContext);
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { unregisterPrompt } = useContext(NavigationContext);

  /**
   * Fetch reference.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Create registration prompt dialog.
   */
  usePrompts([
    {
      key: REGISTRATION_PROMPT_KEY,
      dialogTitle: 'Registrace subjektu',
      dialogText: 'Skutečně chcete odeslat registraci subjektu?',
    },
  ]);

  const handleCreateRegistration = useEventCallback(() => {
    testPrompt({
      key: REGISTRATION_PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callCreateRegistrationApi(
            preprocessPhoneNumber(allValues)
          );

          const response: Subject = await fetch.current.json();

          setFieldValue('subject', response);
          setFieldValue('isSent', true);
          unregisterPrompt(navigationPrompt);

          handleNext();
        } catch (err) {
          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.SubjectRegistration.SUBMIT_REQUEST,
              err.code
            );

            showSnackbar(...message);
            // throw err;
          }
          return undefined;
        } finally {
          setLoading(false);
        }
      },
    });
  });

  /**
   * Create user prompt dialog.
   */
  usePrompts([
    {
      key: SUBJECT_PROMPT_KEY,
      dialogTitle: 'Založení subjektu',
      dialogText: 'Skutečně chcete založit subjekt?',
    },
  ]);

  const handleCreateSubject = useEventCallback(() => {
    testPrompt({
      key: SUBJECT_PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callCreateSubjectApi(allValues);

          const response: Subject = await fetch.current.json();

          setFieldValue('subject', response);
          setFieldValue('isSent', true);
          unregisterPrompt(navigationPrompt);

          handleNext();
        } catch (err) {
          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.SubjectRegistration.CREATE_WITH_AIFO,
              err.code
            );

            showSnackbar(...message);
            // throw err;
          }
          return undefined;
        } finally {
          setLoading(false);
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        {user?.hasNiaExternalId &&
        allValues?.type ===
          SubjectRegistrationType.NATURAL_PERSON_REGISTRATION ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateSubject}
            className={classes.button}
            disableElevation
            disabled={loading}
            startIcon={
              loading && <CircularProgress size="20px" color="inherit" />
            }
          >
            Založit subjekt
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateRegistration}
            className={classes.button}
            disableElevation
            disabled={loading}
            startIcon={
              loading && <CircularProgress size="20px" color="inherit" />
            }
          >
            Odeslat registraci
          </Button>
        )}
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} disabled={loading} />
      </div>
    </div>
  );
}

import React, { useContext } from 'react';
import {
  FormPanel,
  FormContext,
  useFormSelector,
  useUpdateEffect,
  FormSelect,
  FormAutocomplete,
  eqFilterParams,
  FormTextField,
  FormTextArea,
  FormCheckbox,
  FormDateTimeField,
} from '@eas/common-web';
import { ProcessForm } from '../boiler-permission-new-types';
import { DictionaryBoilerTypeAutocomplete, SubjectType } from '../../../models';
import {
  useBoilerModelAllowedTypes,
  useDictionaryBoilerBrands,
} from '../../dict-boiler-brand/dict-boiler-brands-api';
import { StepInfoForm } from './step-info-types';
import { PhoneField } from '../../../components/form/phone-field/phone-field';
import { HelpLabel } from '../../../components/help/help-label';
import { icoMaxLength } from '../../subject/fields/subjects-utils';
import { AddressDefaultsContext } from '../../../components/form/address-field/address-field-defaults-context';
import { LocationPanel } from '../../../components/form/location-panel/location-panel';

export function StepInfoContent({ allValues }: { allValues: ProcessForm }) {
  const { setFieldValue } = useContext(FormContext);

  const {
    boilerBrand,
    allowedTypes,
    withoutEnd,
    address,
    wgs84Coordinates,
  } = useFormSelector((data: StepInfoForm) => ({
    boilerBrand: data.boilerBrand,
    allowedTypes: data.boilerBrand?.allowedTypes,
    withoutEnd: data.withoutEnd,
    address: data.address,
    wgs84Coordinates: data.wgs84Coordinates,
  }));

  const params = eqFilterParams({
    field: 'manufacturer.id',
    value: allValues?.boilerManufacturer?.id,
  });

  const boilerBrands = useDictionaryBoilerBrands(params);

  const boilerTypes = useBoilerModelAllowedTypes(
    (allowedTypes || []) as DictionaryBoilerTypeAutocomplete[]
  );

  useUpdateEffect(() => {
    setFieldValue('boilerType', undefined);
  }, [boilerBrand]);

  useUpdateEffect(() => {
    setFieldValue('validTo', undefined);
  }, [withoutEnd]);

  useUpdateEffect(() => {
    if (address?.country?.code === 'CZ') {
      setFieldValue('wgs84Coordinates', undefined);
    }
  }, [address?.country?.code]);

  return (
    <>
      <FormPanel label="Kotel">
        <FormAutocomplete
          name="boilerBrand"
          source={boilerBrands}
          label={
            <HelpLabel
              label="Značka kotle"
              code="BOILER_PERMISSION_NEW_BOILER_BRAND"
              required
            />
          } // Povinné pole
        />
        <FormSelect
          name="boilerTypes"
          multiple={true}
          source={boilerTypes}
          label={
            <HelpLabel
              label="Typy kotle"
              code="BOILER_PERMISSION_NEW_BOILER_TYPES"
              required
            />
          } // Povinné pole. Zobrazené hodnoty odpovídají Povoleným typům kotle pro vybranou značku.
        />
      </FormPanel>

      <FormPanel label="Oprávnění">
        <FormTextField
          name="number"
          label={
            <HelpLabel
              label="Číslo oprávnění"
              code="BOILER_PERMISSION_NEW_NUMBER"
              required
            />
          } // Povinné pole
        />
        <FormTextField
          name="ozoIco.value"
          label={
            <HelpLabel label="IČO OZO" code="BOILER_PERMISSION_NEW_OZO_ICO" />
          }
          inputProps={{ maxLength: icoMaxLength }}
          onChangeEvent={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/gi, '');
          }}
        />
        <FormTextArea
          name="description"
          label={
            <HelpLabel label="Popis" code="BOILER_PERMISSION_NEW_DESCRIPTION" />
          }
        />
        <FormDateTimeField
          name="validFrom"
          label={
            <HelpLabel
              label="Platnost od"
              code="BOILER_PERMISSION_NEW_VALID_FROM"
              required
            />
          }
        />
        <FormDateTimeField
          name="validTo"
          label={
            <HelpLabel
              label="Platnost do"
              code="BOILER_PERMISSION_NEW_VALID_TO"
              required
            />
          }
          disabled={withoutEnd}
        />
        <FormCheckbox
          name="withoutEnd"
          label={
            <HelpLabel
              label="Na neurčito"
              code="BOILER_PERMISSION_NEW_WITHOUT_END"
            />
          }
        />
      </FormPanel>

      <FormPanel label="Kontaktní údaje">
        <FormTextField
          name="email.value"
          label={
            <HelpLabel label="E-mail" code="BOILER_PERMISSION_NEW_EMAIL" />
          }
        />
        <PhoneField
          name="phoneNumber"
          label={
            <HelpLabel
              label="Telefonní číslo (předvolba, číslo)"
              code="BOILER_PERMISSION_NEW_PHONE_NUMBER"
            />
          }
        />
      </FormPanel>

      <AddressDefaultsContext.Provider
        value={{ addressPlace: { required: true } }}
      >
        <LocationPanel
          disableForeignCountry={
            allValues?.ozoSubjectType !== SubjectType.FOREIGN_NATURAL_PERSON
          }
          addressPanelLabel="Adresa podnikání"
          addressName="address"
          locationName="wgs84Coordinates"
          showCoordinates={address?.country?.code !== 'CZ'}
          showCoordinatesDistance={false}
          address={address}
          locationValue={wgs84Coordinates}
          showLocationPanel={address?.country?.code !== 'CZ'}
          locationToolbarProps={{
            showFillAddressBtn: false,
            showFillMapBtn: false,
            showClearAllBtn: false,
          }}
        />
      </AddressDefaultsContext.Provider>
    </>
  );
}

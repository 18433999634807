import { useEffect, useContext } from 'react';
import {
  FormContext,
  useFormSelector,
  useEventCallback,
} from '@eas/common-web';
import { StepLocationForm, LocationData } from './step-location-types';
import { ProcessForm } from '../irz-facility-split-types';

export function useStepLocation(allValues: ProcessForm) {
  const { setFieldValue } = useContext(FormContext);

  const { irzId } = useFormSelector(
    ({ locationDefaultIrzId }: StepLocationForm) => ({
      irzId: locationDefaultIrzId,
    })
  );

  const resetValues = useEventCallback(() => {
    setFieldValue('locationDefaultIrzId', allValues.mainFacility?.id);
    const location: LocationData = {
      address: allValues.mainFacility?.address,
      wgs84Coordinates: allValues?.mainFacility?.wgs84Coordinates,
    };
    setFieldValue('location', location);
  });

  /**
   * If the facility was changed in the first step, we need to reset the form,
   * to have correct default values.
   */
  useEffect(() => {
    if (irzId !== allValues.mainFacility?.id) {
      resetValues();
    }
  }, [allValues.mainFacility?.id]);
}

export enum PermissionMuvWaterFlow {
  /** Přehled vodních toků */
  MUV_WATER_FLOW_LIST = 'MUV_WATER_FLOW_LIST',

  /** Autocomplete výběr vodních toků */
  MUV_WATER_FLOW_AUTOCOMPLETE = 'MUV_WATER_FLOW_AUTOCOMPLETE',

  /** Detail vodního toku */
  MUV_WATER_FLOW_DETAIL = 'MUV_WATER_FLOW_DETAIL',

  /** Vytvoření nového vodního toku */
  MUV_WATER_FLOW_CREATE = 'MUV_WATER_FLOW_CREATE',

  /** Editace vodního toku */
  MUV_WATER_FLOW_UPDATE = 'MUV_WATER_FLOW_UPDATE',

  /** Mazání vodního toku */
  MUV_WATER_FLOW_DELETE = 'MUV_WATER_FLOW_DELETE',

  /** Zneplatnění vodního toku */
  MUV_WATER_FLOW_INVALIDATE = 'MUV_WATER_FLOW_INVALIDATE',

  /** Obnovení vodního toku */
  MUV_WATER_FLOW_REVALIDATE = 'MUV_WATER_FLOW_REVALIDATE',
}

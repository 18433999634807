import { AuthoredObject } from '@eas/common-web';
import { DictionaryDeposit } from './dict-deposit';
import { DictionaryMineralGroup } from './dict-mineral-group';
import { DictionaryOvss } from './dict-ovss';

/**
 * Typ území
 */
export enum AreaType {
  /**
   * Chráněné ložiskové území
   */
  CHLU = 'CHLU',

  /**
   * Chráněné území pro zvláštní zásahy do zemské kůry
   */
  CHUZZZK = 'CHUZZZK',
}

/**
 * Stav CHLÚ
 */
export enum ChluState {
  /**
   * Koncept
   */
  CONCEPT = 'CONCEPT',

  /**
   * Zahozeno
   */
  DISCARDED = 'DISCARDED',

  /**
   * Schváleno
   */
  APPROVED = 'APPROVED',

  /**
   * Stornováno
   */
  CANCELLED = 'CANCELLED',
}

/**
 * Stav RUIAN
 */
export enum ChluRuianState {
  /**
   * Není v RÚIAN
   */
  NONE = 'NONE',

  /**
   * RÚIAN zpracovává žádost
   */
  REQUEST_SENT = 'REQUEST_SENT',

  /**
   * Potvrzeno RÚIAN
   */
  ACCEPTED = 'ACCEPTED',

  /**
   * Zamítnuto RÚIAN
   */
  DENIED = 'DENIED',
}

/**
 * CHLÚ, Chráněné ložiskové území
 */
export interface ProtectedDepositArea extends AuthoredObject {
  type?: AreaType;

  /**
   * Datum zapsání do RUIAN
   */
  ruianEntryTime?: string;

  /**
   * Interní poznámka
   */
  note?: string;

  /**
   * Název
   */
  name?: string;

  /**
   * Číslo CHLÚ
   */
  number?: string;

  /**
   * RUIAN kód CHLÚ
   */
  ruianCode?: string;

  /**
   * Ložiska
   */
  deposits?: DictionaryDeposit[];

  /**
   * Nerosty ložiska
   */
  minerals?: string;

  /**
   * Skupiny nerostů
   */

  mineralGroups?: DictionaryMineralGroup[];

  /**
   * Datum vydání rozhodnutí
   */
  dateIssued?: string;

  /**
   * Datum nabytí právní moci rozhodnutí
   */
  dateLegalForce?: string;

  /**
   * Číslo jednací
   */
  referenceNumber?: string;

  /**
   * Souřadnice
   */
  coordinates?: string;

  /**
   * Plocha
   */
  area?: number;

  /**
   * Popis pro RUIAN
   */
  ruianDescription?: string;

  /**
   * Stav
   */
  state?: ChluState;

  /**
   * Stav RUIAN
   */
  ruianState?: ChluRuianState;

  /**
   * Příznak, zda je CHLU v RUIAN
   */
  inRuian?: boolean;

  /**
   * Příznak, zda je CHLU odesláno nebo uloženo v RUIAN
   */
  inRuianOrEntering?: boolean;

  /**
   * Odbor výkonu státní správy
   */
  ovss?: DictionaryOvss;
}

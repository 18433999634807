import React, { useContext, useRef } from 'react';
import {
  FormContext,
  DialogHandle,
  useEventCallback,
  AbortableFetch,
  abortableFetch,
  TableFieldToolbarButton,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import AddressIcon from '@material-ui/icons/ContactMail';
import MapIcon from '@material-ui/icons/Map';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import ClearLocationIcon from '@material-ui/icons/ExploreOff';
import ClearAllIcon from '@material-ui/icons/Clear';
import { useStyles } from './location-panel-styles';
import { LocationPanelToolbarProps } from './location-panel-types';
import { FillAddressDialog } from './fill-address-dialog';
import { AddressPlace } from '../../../models/address-place';
import { EvidenceAPI } from '../../../enums';

function fetchAddressPlace(id: string) {
  return abortableFetch(`${EvidenceAPI.ADDRESS_PLACE}/${id}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
}

export function LocationPanelToolbar({
  handleSetAddPointMode,
  handleClearAll,
  handleClearPoint,
  handleFillAddress,
  handleFillMap,
  handleFillCoordinates,
  disabled,
  canFillMap = false,
  canFillAddress = false,
  locationValue,
  showFillAddressBtn = true,
  showFillMapBtn = true,
  showAddPointBtn = true,
  showFillCoordinatesBtn = true,
  showClearPointBtn = true,
  showClearAllBtn = true,
}: LocationPanelToolbarProps) {
  const classes = useStyles();
  const { editing } = useContext(FormContext);
  const fillAddressDialogRef = useRef<DialogHandle>(null);

  const loadAddressPlaceFetchRef = useRef<AbortableFetch | null>(null);
  const fillAddressAdapter = useEventCallback(
    async (addressPlaceAutocomplete: AddressPlace) => {
      if (loadAddressPlaceFetchRef.current) {
        loadAddressPlaceFetchRef.current.abort();
      }
      try {
        loadAddressPlaceFetchRef.current = fetchAddressPlace(
          addressPlaceAutocomplete?.id
        );
        const addressPlace = await loadAddressPlaceFetchRef.current.json();
        fillAddressDialogRef.current?.close();
        if (handleFillAddress) {
          handleFillAddress(addressPlace);
        }
      } catch (err) {
        if (err.name !== 'AbortError') {
          throw err;
        }
      }
    }
  );

  const openAddressDialog = useEventCallback(() => {
    fillAddressDialogRef.current?.open();
  });

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        {handleFillAddress && showFillAddressBtn && (
          <TableFieldToolbarButton
            color="primary"
            variant="contained"
            title="Vyplnit adresu dle bodu"
            disabled={!editing || !!disabled || !canFillAddress}
            show={true}
            IconComponent={AddressIcon}
            onClick={openAddressDialog}
          >
            <Typography variant="body2">Vyplnit adresu dle bodu</Typography>
          </TableFieldToolbarButton>
        )}
        {handleFillMap && showFillMapBtn && (
          <TableFieldToolbarButton
            color="primary"
            variant="contained"
            title="Vyplnit bod dle adresy"
            disabled={!editing || !!disabled || !canFillAddress}
            show={true}
            IconComponent={MapIcon}
            onClick={handleFillMap}
          >
            <Typography variant="body2">Vyplnit bod dle adresy</Typography>
          </TableFieldToolbarButton>
        )}
        {showAddPointBtn && (
          <TableFieldToolbarButton
            color="primary"
            variant="contained"
            title="Zadat bod do mapy"
            disabled={!editing || !!disabled}
            show={true}
            IconComponent={EditLocationIcon}
            onClick={handleSetAddPointMode}
          >
            <Typography variant="body2">Zadat bod do mapy</Typography>
          </TableFieldToolbarButton>
        )}

        {handleFillCoordinates && showFillCoordinatesBtn && (
          <TableFieldToolbarButton
            color="primary"
            variant="contained"
            title="Zadat souřadnice"
            disabled={!editing || !!disabled}
            show={true}
            IconComponent={EditLocationIcon}
            onClick={handleFillCoordinates}
          >
            <Typography variant="body2">Zadat souřadnice</Typography>
          </TableFieldToolbarButton>
        )}
        {showClearPointBtn && (
          <TableFieldToolbarButton
            color="secondary"
            variant="text"
            title="Vymazat bod na mapě"
            disabled={!editing || !!disabled || !canFillAddress}
            show={true}
            IconComponent={ClearLocationIcon}
            onClick={handleClearPoint}
          >
            <Typography variant="body2">Vymazat bod na mapě</Typography>
          </TableFieldToolbarButton>
        )}

        {handleClearAll && showClearAllBtn && (
          <TableFieldToolbarButton
            color="secondary"
            variant="text"
            title="Vymazat vše"
            disabled={!editing || !!disabled || !(canFillAddress || canFillMap)}
            show={true}
            IconComponent={ClearAllIcon}
            onClick={handleClearAll}
          >
            <Typography variant="body2">Vymazat vše</Typography>
          </TableFieldToolbarButton>
        )}
      </ButtonGroup>
      {canFillAddress && editing && !disabled && locationValue && (
        <FillAddressDialog
          ref={fillAddressDialogRef}
          fillAddress={fillAddressAdapter}
          wgsCoordinates={locationValue}
        />
      )}
    </div>
  );
}

import * as Yup from 'yup';
import {
  ThirdPartySystem,
  ThirdPartySystemContact,
} from '../../models/third-party-system';
import {
  validatePhoneNumber,
  validateEmailNotRequired,
} from '../profile/profile-utils';

export function useValidationSchema() {
  return Yup.object<ThirdPartySystem>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    contact: Yup.object<ThirdPartySystemContact>().shape({
      phone: validatePhoneNumber,
      email: validateEmailNotRequired(),
    }),
  }) as Yup.Schema<ThirdPartySystem>;
}

import React, { ComponentType } from 'react';
import {
  FormNumberField,
  FormTextField,
  FormCheckbox,
  FormDateTimeField,
} from '@eas/common-web';
import { HelpLabel } from '../../help/help-label';

export function DictionaryFields({
  codePrefix,
  FieldsComponent,
}: {
  codePrefix: string;
  FieldsComponent?: ComponentType;
}) {
  return (
    <>
      <FormNumberField
        name="order"
        label={<HelpLabel label="Pořadí" code={`DICT_${codePrefix}_ORDER`} />}
      />
      <FormTextField
        name="name"
        label={
          <HelpLabel label="Název" code={`DICT_${codePrefix}_NAME`} required />
        } // Povinné pole
      />
      <FormTextField
        name="code"
        label={
          <HelpLabel label="Kód" code={`DICT_${codePrefix}_CODE`} required />
        } // Povinné pole
      />
      <FormCheckbox
        name="active"
        label={<HelpLabel label="Aktivní" code={`DICT_${codePrefix}_ACTIVE`} />}
        disabled={true}
      />
      {FieldsComponent && <FieldsComponent />}
      <FormDateTimeField
        name="validFrom"
        label={
          <HelpLabel label="Platný od" code={`DICT_${codePrefix}_VALID_FROM`} />
        }
      />
      <FormDateTimeField
        name="validTo"
        label={
          <HelpLabel label="Platný do" code={`DICT_${codePrefix}_VALID_TO`} />
        }
      />
    </>
  );
}

import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '8px',
    height: 32,
    lineHeight: '32px',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  item: {
    paddingTop: '2pt',
    paddingBottom: '2pt',
    height: 45,
  },
  input: {
    '&::before': {
      border: 0,
    },
    width: '100%',
  },
  adornment: {
    right: 24,
    position: 'absolute',
  },
}));

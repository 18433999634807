import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { ProductionSite } from '../../models';
import { inactiveColoredFactory } from '../../components/form/inactive-colored/inactive-colored';
import { addressValueMapperFactory } from '../../composite/subject-column-mappers/address-mapper';
import { useDistricts, useRegions } from './production-sites-api';
import { coordinateValueMapper } from '../../composite/facility-column-mappers/coordinate-mapper';

export function useColumns(
  canSeeDeactivated: boolean
): TableColumn<ProductionSite>[] {
  const columns: TableColumn<ProductionSite>[] = [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'inspireId',
      name: 'Identifikátor EU',
      width: 200,
      filterOperation: ApiFilterOperation.CONTAINS,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'district',
      sortkey: 'district.name',
      filterkey: 'district.id',
      name: 'Okres',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDistricts
      ),
      sortable: true,
      filterable: true,
      filterGroup: 2,
      valueMapper: addressValueMapperFactory(''),
    },
    {
      datakey: 'district.region',
      sortkey: 'district.region.name',
      filterkey: 'district.region.id',
      name: 'Kraj',
      width: 200,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useRegions
      ),
      valueMapper: addressValueMapperFactory(''),
    },
    {
      datakey: 'wgs84Coordinates.lat',
      name: 'Zeměpisná šířka',
      width: 200,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      sortable: true,
      filterable: false,
      filterGroup: 3,
      valueMapper: coordinateValueMapper,
    },
    {
      datakey: 'wgs84Coordinates.lon',
      name: 'Zeměpisná délka',
      width: 200,
      filterOperation: ApiFilterOperation.AND,
      CellComponent: inactiveColoredFactory(TableCells.TextCell),
      FilterComponent: TableFilterCells.FilterNumberCell,
      sortable: true,
      filterable: false,
      filterGroup: 3,
      valueMapper: coordinateValueMapper,
    },
  ];
  if (canSeeDeactivated) {
    columns.push({
      datakey: 'active',
      name: 'Aktivní',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: inactiveColoredFactory(TableCells.BooleanCell, false),
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    });
  }
  return columns;
}

/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Dictionary messages
 */
const ACTIVATE: MessageType<
  'SUCCESS' | 'ERROR' | 'FUTURE_OPERATION_PRESENT'
> = {
  SUCCESS: ['Záznam byl úspěšně obnoven.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při obnovení záznamu.', SnackbarVariant.ERROR],
  FUTURE_OPERATION_PRESENT: [
    'Nelze aktivovat entitu, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
};

const DEACTIVATE: MessageType<
  'SUCCESS' | 'ERROR' | 'FUTURE_OPERATION_PRESENT'
> = {
  SUCCESS: ['Záznam byl úspěšně zneplatněn.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při zneplatnění záznamu.', SnackbarVariant.ERROR],
  FUTURE_OPERATION_PRESENT: [
    'Nelze deaktivovat entitu, akce se snaží provést změnu na entitě, ale za daným datumem existuje další operace, která se má projevit v budoucnu',
    SnackbarVariant.ERROR,
  ],
};

const CREATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'DICTIONARY_CODE_NOT_UNIQUE'> = {
  ERROR: ['Chyba při vytváření záznamu.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  DICTIONARY_CODE_NOT_UNIQUE: ['Kód již existuje.', SnackbarVariant.ERROR]
}

const UPDATE: MessageType<'ERROR' | 'INVALID_ENTITY_VALIDITY_RANGE' | 'DICTIONARY_CODE_NOT_UNIQUE'> = {
  ERROR: ['Chyba při editaci záznamu.', SnackbarVariant.ERROR],
  INVALID_ENTITY_VALIDITY_RANGE: ['Konec platnosti nesmí být před začátkem platnosti.', SnackbarVariant.ERROR],
  DICTIONARY_CODE_NOT_UNIQUE: ['Kód již existuje.', SnackbarVariant.ERROR]
}

export default { ACTIVATE, DEACTIVATE, CREATE, UPDATE };

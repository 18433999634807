import React from 'react';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TelegramIcon from '@material-ui/icons/Telegram';
import { DetailToolbarButtonMenuItem } from '@eas/common-web';
import { useRedirect } from './actions/redirect-hook';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import { DetailToolbarButtonMenu } from '../../components/evidence/evidence/detail-toolbar-button-menu';

type IconType = OverridableComponent<
  SvgIconTypeMap<Record<string, unknown>, 'svg'>
>;

function useToolbarItems() {
  /**
   * Array of IRZRequest ACTIONS.
   */
  const actionItems: DetailToolbarButtonMenuItem[] = [];

  const addAction = (label: string, Icon: IconType, onClick: () => void) =>
    actionItems.push({
      label,
      Icon: <Icon fontSize="small" htmlColor="black" />,
      onClick,
    });

  const { handleAllFacilities, handleRedirect, showRedirect } = useRedirect();

  if (showRedirect) {
    addAction('Navázaná provozovna', TelegramIcon, handleRedirect);
  }

  addAction('Všechny provozovny', ListAltIcon, handleAllFacilities);

  return {
    actionItems,
  };
}

export function IrzFacilityRequestsToolbar() {
  const { actionItems } = useToolbarItems();

  return (
    <>
      <DetailToolbarButtonMenu label="Otevřít" items={actionItems} />
    </>
  );
}

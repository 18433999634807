import React, { useState, useContext, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ProcessActionsProps } from '../../../components/process/process-types';
import {
  useEventCallback,
  abortableFetch,
  SnackbarContext,
  AbortableFetch,
  NavigationContext,
  EvidenceStateAction,
} from '@eas/common-web';
import { ProcessForm } from '../boiler-permission-new-types';
import { EvidenceAPI, Messages, EvidenceUrl } from '../../../enums';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SubjectType, BoilerManufacturer, DocumentType } from '../../../models';
import { SubjectIdentificator } from '../../../models/subject';
import { v4 as uuidv4 } from 'uuid';
import { preprocessPhoneNumber } from '../../../components/form/phone-field/phone-field-utils';
import { getErrorMessage } from '../../../utils/get-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

/**
 * Api call.
 *
 * @param idNumber
 */
function createPermissionApiCall(data: any) {
  //todo
  return abortableFetch(
    `${EvidenceAPI.BOILER_PERMISSIONS}/for-identification`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
}

/**
 * Api call.
 *
 * @param idNumber
 */
function getManufacturerDetail(manufacturerId: string) {
  return abortableFetch(
    `${EvidenceAPI.BOILER_MANUFACTURERS}/${manufacturerId}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    }
  );
}

export function StepSummaryActions({
  handleBack,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Loading state.
   */
  const [loading, setLoading] = useState(false);

  /**
   * Context stuff.
   */
  const { showSnackbar } = useContext(SnackbarContext);
  const { navigate } = useContext(NavigationContext);
  const { unregisterPrompt } = useContext(NavigationContext);

  /**
   * Fetch reference.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleNextWithCheck = useEventCallback(async () => {
    try {
      setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      const {
        boilerManufacturer,
        ozoSubjectType,
        identityDocumentType,
        ...permissionData
      } = allValues;

      const manufacturerRequest = await getManufacturerDetail(
        boilerManufacturer?.id ?? ''
      );
      const manufacturer = (await manufacturerRequest.json()) as BoilerManufacturer;

      //Infer identificator type from values
      let identificatorType;
      if (ozoSubjectType === SubjectType.FOREIGN_NATURAL_PERSON) {
        identificatorType = SubjectIdentificator.ARTIFICIAL_ID;
      } else {
        if (identityDocumentType === DocumentType.IDENTITY_CARD) {
          identificatorType = SubjectIdentificator.ID_CARD_NUMBER;
        } else {
          identificatorType = SubjectIdentificator.PASSPORT_NUMBER;
        }
      }

      const data = {
        ...permissionData,

        //Required by API, using random values
        ozoSubject: {
          id: uuidv4(),
          type: SubjectType.NATURAL_PERSON,
        },

        subjectIdentifier: {
          type: identificatorType,
          subjectType: allValues?.ozoSubjectType,
          value: allValues?.idNumber,
        },
        manufacturerSubject: manufacturer?.subject,
      };

      const processedData = preprocessPhoneNumber(data);

      fetch.current = createPermissionApiCall(processedData);
      const response = await fetch.current.json();

      showSnackbar(...Messages.OzoPermission.CREATE.SUCCESS);

      unregisterPrompt(navigationPrompt);
      if (response?.id) {
        navigate(`${EvidenceUrl.BOILER_PERMISSIONS}`, true, {
          action: EvidenceStateAction.SHOW_ITEM,
          data: response?.id,
        });
      }
    } catch (err) {
      if (err.name !== 'AbortError') {
        const message = getErrorMessage(
          Messages.OzoPermission.CREATE,
          err.code
        );

        showSnackbar(...message);
      }
      return undefined;
    } finally {
      setLoading(false);
    }
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithCheck}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Dokončit
        </Button>
        <Button
          disabled={loading}
          onClick={handleBack}
          className={classes.button}
          disableElevation
        >
          Předchozí
        </Button>
      </div>
    </div>
  );
}

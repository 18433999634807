import React, { useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  useEventCallback,
  PromptContext,
  usePrompts,
  useFormSelector,
} from '@eas/common-web';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { ProcessForm } from '../empowerment-requests-types';
import { AuthorRole } from '../../../models';
import { StepRoleForm } from './step-role-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    dialog: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
  })
);

export function ExitProcessAction({
  handleExit,
  disabled,
}: {
  handleExit: () => void;
  disabled?: boolean;
}) {
  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      onClick={handleExit}
      className={classes.button}
      disableElevation
    >
      Zrušit
    </Button>
  );
}

const PROMPT_KEY = 'SELECT_ROLE';

export function StepRoleActions({
  activeStep,
  handleNext,
  handleBack,
  handleExit,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  const { testPrompt } = useContext(PromptContext);

  const { authorRole } = useFormSelector((data: StepRoleForm) => ({
    authorRole: data.authorRole,
  }));

  const handleNextWithConfirm = useEventCallback(() => {
    if (authorRole == AuthorRole.EMPOWERER) {
      testPrompt({
        key: PROMPT_KEY,
        submitCallback: handleNext,
      });
    } else {
      handleNext();
    }
  });

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Upozornění pro uživatele',
      dialogText: (
        <div className={classes.dialog}>
          <span>
            V procesu vytvoření vazby zmocnění mezi dvěma subjekty jste uvedli,
            že vystupujete v roli Zmocnitele. V běžné praxi je tento postup při
            udělování zmocnění nestandardní, obvykle vkládá plnou moc zástupce
            Zmocněnce.
          </span>
          <span>
            Potvrďte, že jste si zvoleného postupu plně vědomi a přejete si
            pokračovat v procesu vložení plné moci.
          </span>
        </div>
      ),
      dialogWidth: 700,
    },
  ]);

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
        >
          Další
        </Button>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          disableElevation
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} />
      </div>
    </div>
  );
}

import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import {
  DetailToolbarButtonType,
  DetailContext,
  DetailHandle,
  DetailMode,
  DetailActionbarButton,
} from '@eas/common-web';

import { useDeactivateDialog } from './dialog-actions/deactivate-hook';
import { useContext } from 'react';
import { Empowerment } from '../../models';
import { useCreateNoteDialog } from '../../components/evidence/noted-evidence/dialog-actions/create-note-hook';
import { EntityType } from '../../enums/entity-type';

export function EmpowermentActionbar() {
  const { mode } = useContext<DetailHandle<Empowerment>>(DetailContext);

  const { handleDeactivate, showDeactivateButton } = useDeactivateDialog();

  const {
    handleCreateNote,
    showCreateButton: showCreateNote,
  } = useCreateNoteDialog({
    entityType: EntityType.EMPOWERMENT,
  });

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div style={{ flexWrap: 'wrap' }}>
          {showDeactivateButton && (
            <DetailActionbarButton
              startIcon={<CloseIcon fontSize="small" />}
              label="Zneplatnit"
              onClick={handleDeactivate}
              type={DetailToolbarButtonType.SECONDARY}
            />
          )}
          {showCreateNote && (
            <DetailActionbarButton
              startIcon={<NoteAddIcon fontSize="small" />}
              label="Přidat poznámku"
              onClick={handleCreateNote}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
        </div>
      )}
    </>
  );
}
